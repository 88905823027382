import { useState } from 'react'
import FilterSubMenu from '../../AddFilterMenu/FilterSubMenu'
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'
import useDebounce from '@/hooks/useDebounce'
import { FilterItem } from '@/types/filters/Filters'
import SearchInputForFilters from '../../../SearchInputFilter'
import CustomerRecordsIdFilterContent from '../common/CustomerRecordsFilterContent'
import useCustomerRecordIdFilters from './useCustomerRecordIdFilters'

interface Props {
  filter: FilterItem
  newFeed?: boolean
}

const AccountIdFilterSubmenu = ({ filter, newFeed = false }: Props) => {
  const [searchValue, setSearchValue] = useState('')
  const [open, setOpen] = useState(false)

  const debouncedSearch = useDebounce(searchValue, 350)

  const {
    apply,
    fetchNextPage,
    hasChanges,
    hasNextPage,
    isChecked,
    isFetchingNextPage,
    isLoading,
    isShowMoreDisabled,
    options,
    selectOption
  } = useCustomerRecordIdFilters({
    customerRecordType: 'account',
    enabled: open,
    filter,
    searchQuery: debouncedSearch,
    newFeed
  })

  useDidUpdateEffect(() => {
    if (!open) {
      setSearchValue('')
    }

    return () => {
      setSearchValue('')
    }
  }, [open])

  const onChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value)
  }

  const onKeyUp: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  const onClear = () => {
    setSearchValue('')
  }

  return (
    <FilterSubMenu
      isDisabled={!hasChanges}
      isLoading={isLoading}
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      previousTitle="Accounts"
      searchComponent={
        <SearchInputForFilters
          clearable
          css={{ mb: '$micro' }}
          onChange={onChange}
          onClear={onClear}
          onKeyUp={onKeyUp}
          placeholder="Search"
          size="small"
          small
          value={searchValue}
        />
      }
      title={filter.displayName}
    >
      <CustomerRecordsIdFilterContent
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isChecked={isChecked}
        isFetchingNextPage={isFetchingNextPage}
        isShowMoreDisabled={isShowMoreDisabled}
        key={filter.key}
        options={options}
        selectOption={selectOption}
      />
    </FilterSubMenu>
  )
}

export default AccountIdFilterSubmenu
