import Button from '@/components/atoms/button'
import { DeleteDialog } from '@/components/atoms/dialog'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import useClassification, {
  CLASSIFICATION_LABELS,
  REFRESH_THEMES_MAX_DELAY,
  REFRESH_THEMES_MIN_DELAY
} from '@/hooks/useClassification'
import useHideIntercom from '@/hooks/useHideIntercom'
import useKeywordsRelations from '@/hooks/useKeywords/useKeywordsRelations'
import useSegment from '@/hooks/useSegment'
import useUser from '@/hooks/useUser'
import KeywordService from '@/services/KeywordService'
import { useKeywordsStore, useUIStore } from '@/store'
import useToastMessageStore from '@/store/useToastMessageStore'
import { Lightning, Plus, TrashSimple, X } from '@phosphor-icons/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { shallow } from 'zustand/shallow'
import { OnApplyCallback } from '../../keyword-editor/KeywordEditor'
import KeywordEditorPopover from '../../keyword-editor/KeywordEditorPopover'
import { ActionsBar } from './KeywordsCollapsibleRow.styles'
import useLogging from '@/hooks/useLogging'
import { makeUniqueArray } from '@/utils/array'

const KeywordsActionBar = () => {
  const { track } = useSegment()

  const { classificationCollapsed } = useUIStore()
  const { userPermissions } = useUser()
  const isManager = userPermissions.topic.includes('manager')
  const canViewFindings = userPermissions.topic.includes('findings')

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const setCheckedKeywords = useKeywordsStore(state => state.setCheckedKeywords)
  const checkedKeywords = useKeywordsStore(state => state.checkedKeywords, shallow)
  const reasonsKeywordHashes = useKeywordsStore(state => state.reasonsKeywordHashes)
  const removeKeywordBatch = useKeywordsStore(state => state.removeKeywordBatch)
  const setReasonsKeywordHashes = useKeywordsStore(state => state.setReasonsKeywordHashes)
  const getHasClusterWithoutReasons = useKeywordsStore(state => state.getHasClusterWithoutReasons)

  const { refreshThemes, getTopicById, classificationLabelsMode } = useClassification()

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const { logException } = useLogging({ context: 'keywords-action-bar' })

  const { applyTopicsToKeywords } = useKeywordsRelations()

  const onClickClose = () => {
    setCheckedKeywords([])
  }

  const onConfirmDelete = async () => {
    const removeToast = addLoadingToast({
      text: `Deleting ${checkedKeywords.length} keyword...`
    })
    try {
      setShowDeleteDialog(false)

      await KeywordService.deleteKeywordBatch(checkedKeywords.map(keyword => keyword.keywordHash))
      removeKeywordBatch(checkedKeywords.map(keyword => keyword.keywordHash))

      setTimeout(() => {
        refreshThemes()
        setCheckedKeywords([])

        removeToast()

        addSuccessToast({
          text: `Deleted ${checkedKeywords.length} successfully.`
        })
      }, REFRESH_THEMES_MIN_DELAY)
    } catch (error) {
      console.error(error)
      removeToast()
      const message = `Failed to delete ${checkedKeywords.length} keywords.`
      logException(error, { message })
      addErrorToast({ text: message })

      setShowDeleteDialog(false)
    }
  }

  const appliedTopicsMap = useMemo(() => {
    return makeUniqueArray(
      'topicId',
      checkedKeywords
        .flatMap(keyword => keyword.topics)
        .filter(topic => !!getTopicById(topic.topicId))
    )
  }, [getTopicById, checkedKeywords])

  const onApplyAddToTopic = useCallback<OnApplyCallback>(
    async (selectedTopics, removedTopics) => {
      const removeToast = addLoadingToast({
        text: 'Applying changes to keywords relations...'
      })

      try {
        await applyTopicsToKeywords({ keywords: checkedKeywords, selectedTopics, removedTopics })

        removeToast()
        setCheckedKeywords([])

        addSuccessToast({
          text: 'All changes applied.'
        })

        setTimeout(() => {
          refreshThemes()
        }, REFRESH_THEMES_MAX_DELAY)

        return 'success'
      } catch (error) {
        console.error(error)
        removeToast()
        const message = 'Failed to apply all changes.'
        logException(error, { message })
        addErrorToast({ text: message })

        return 'error'
      }
    },
    [
      checkedKeywords,
      applyTopicsToKeywords,
      refreshThemes,
      addErrorToast,
      addLoadingToast,
      addSuccessToast,
      logException,
      setCheckedKeywords
    ]
  )

  const onViewReasonsClick = () => {
    setReasonsKeywordHashes(checkedKeywords.map(keyword => keyword.keywordHash))
    track('explore_reason_view_list')
  }

  const hideIntercom = useHideIntercom()

  useEffect(() => {
    if (checkedKeywords.length) {
      hideIntercom(true)
    } else {
      hideIntercom(false)
    }

    return () => hideIntercom(false)
  }, [checkedKeywords.length, hideIntercom])

  const labels: Record<CLASSIFICATION_LABELS, { button: string }> = {
    'topic-group': {
      button: 'Add to topic'
    },
    'default-topic': {
      button: ' Add to subtopic'
    }
  }

  if (!checkedKeywords.length || reasonsKeywordHashes.length) return <></>

  return (
    <>
      <ActionsBar expanded={classificationCollapsed}>
        <FlexContainer alignItems="center" gap="xxs">
          {isManager ? (
            <>
              <KeywordEditorPopover
                appliedTopics={appliedTopicsMap}
                onApply={onApplyAddToTopic}
                singleKeyword={checkedKeywords.length === 1}
              >
                <Text as="p" color="white" typeface="textSemiboldSmall">
                  <Plus size={16} weight="bold" />
                  {labels[classificationLabelsMode].button}
                </Text>
              </KeywordEditorPopover>
              <Text
                as="p"
                color="white"
                onClick={() => setShowDeleteDialog(true)}
                typeface="textSemiboldSmall"
              >
                <TrashSimple size={16} weight="bold" />
                Delete
              </Text>
            </>
          ) : null}

          {!getHasClusterWithoutReasons() && canViewFindings && (
            <Button onClick={onViewReasonsClick} size="small" variant="exception">
              <Lightning weight="fill" />
              <span>View findings</span>
            </Button>
          )}
        </FlexContainer>
        <FlexContainer alignItems="center" gap="xxs">
          <Text as="p" color="white" onClick={onClickClose} typeface="textSemiboldSmall">
            {checkedKeywords.length} selected
            <X size={16} weight="bold" />
          </Text>
        </FlexContainer>
      </ActionsBar>
      <DeleteDialog
        isDeleting={false}
        onConfirmDelete={onConfirmDelete}
        onOpenChange={setShowDeleteDialog}
        open={showDeleteDialog}
      />
    </>
  )
}

export default KeywordsActionBar
