import { useFiltersStore } from '@/store'
import useSelectedFilters from '../filters/useSelectedFilters'
import { FeedbackFilter } from '@/types/filters/FilterRequests'
import { cloneObject } from '@/utils/object'
import AnalyticsService from '@/services/AnalyticsService'
import { TrendingTopicType } from '@/types/analytics/TrendingTopic'
import { trendingTopicTypeMap } from '@/utils/trendingTopics'
import { useQuery } from '@tanstack/react-query'
import useUser from '../useUser'

interface Params {
  topicId: string
  type: TrendingTopicType
  asExploreFeedbackItem?: boolean
}

const useTopicDetails = ({ topicId, type, asExploreFeedbackItem = false }: Params) => {
  const toFeedbackFilterSchema = useFiltersStore(state => state.toAnalyticsFeedbackFilterSchema)
  const { allSelectedFilters } = useSelectedFilters()

  const { userPermissions } = useUser()

  const summaryEnabled = userPermissions.summary.includes('view')
  const rootCausesEnabled = false
  // const rootCausesEnabled =
  //   userPermissions.root_causes.includes('view') && topicDetailsParams.enableRootCauses

  const queryFn = async () => {
    const feedbackFilter = toFeedbackFilterSchema()

    const newFilters: FeedbackFilter = cloneObject(feedbackFilter)
    if (newFilters.filter && !asExploreFeedbackItem) {
      newFilters.filter.topic_groups = [[topicId]]
    }

    const [error, data] = await AnalyticsService.topicDetails({
      feedback_search_schema: newFilters,
      root_causes_enable: rootCausesEnabled,
      count_by: 'ingested_feedback_id',
      search_text: '',
      return_all: false,
      exact_count: false,
      sentiment_count: false,
      sentiment_exact_count: false,
      metric_emerging: false,
      metric_intentions: trendingTopicTypeMap[type].intentions
    })

    if (error) {
      throw error
    }

    return data
  }

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      'analytics',
      'topic',
      'details',
      topicId,
      type,
      allSelectedFilters,
      asExploreFeedbackItem,
      rootCausesEnabled
    ],
    queryFn,
    enabled: summaryEnabled || rootCausesEnabled
  })

  const summary = data?.summary ?? ''
  const rootCauses = data?.rootCauses ?? []

  return {
    summary,
    rootCauses,
    isLoading,
    isError
  }
}

export default useTopicDetails
