import Input from '@/components/atoms/input'
import { CSS, styled } from '@/theme'

export const ModalContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$xs',
  padding: '$xs $sm',
  maxHeight: '65vh',
  overflowY: 'auto'
})

export const ModalSection = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$nano',
  color: '$neutralLowMedium',
  fontSize: '$xxs',

  h2: {
    fontSize: '$xxxs',
    fontWeight: '$regular'
  }
})

const hideCSS: CSS = {
  transform: 'translateX(-100%)',
  opacity: 0,
  height: 0,
  my: '-$micro',
  pointerEvents: 'none'
}

export const MergingTopicRowContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$nano',
  padding: '$nano $micro',
  color: '$brandPrimaryPure',
  fontSize: '$xxs',
  justifyContent: 'space-between',
  height: 20,
  opacity: 1,
  // pointerEvents: 'initial',

  background: '$brandPrimaryLight',
  borderRadius: '$nano',

  cursor: 'pointer',
  transitionDelay: '0s, .2s, .2s',
  transitionDuration: '.2s, .2s, .2s',
  transitionProperty: 'all, height, margin',

  span: { fontSize: '$xxxs' },

  '.icon': {
    position: 'relative',
    size: 16
  },

  '.check-icon': {
    color: '$brandPrimaryPure',
    opacity: 1,
    transition: '.2s'
  },
  '.remove-icon': {
    color: '$feedbackNegativePure',
    position: 'absolute',
    opacity: 0,
    transition: '.2s',
    top: 0,
    left: 0
  },
  '.add-icon': {
    color: '$brandPrimaryPure',
    position: 'absolute',
    opacity: 0,
    transition: '.2s',
    top: 0,
    left: 0
  },

  '&:hover': {
    background: '$feedbackNegativeLight',
    color: '$feedbackNegativePure',

    '.check-icon': { opacity: 0 },
    '.remove-icon': { opacity: 1 }
  },

  variants: {
    removed: {
      true: {
        background: '$feedbackNegativeLight',
        color: '$feedbackNegativePure',
        ...hideCSS,

        '.check-icon': { opacity: 0 },
        '.remove-icon': { opacity: 1 }
      }
    },
    suggestion: {
      true: {
        background: '$feedbackInformativeLight',
        color: '$feedbackInformativePure',

        '.check-icon': { color: '$feedbackInformativePure' },

        '&:hover': {
          background: '$brandPrimaryLight',
          color: '$brandPrimaryPure',

          '.check-icon': { opacity: 0 },
          '.add-icon': { opacity: 1 }
        }
      }
    },
    merging: {
      true: {}
    },
    accepted: {
      true: {
        background: '$brandPrimaryLight',
        color: '$brandPrimaryPure',
        ...hideCSS,

        '.check-icon': { opacity: 0 },
        '.add-icon': { opacity: 1 }
      }
    }
  },

  compoundVariants: [
    {
      merging: true,
      suggestion: true,
      css: {
        '&:hover': {
          background: '$feedbackNegativeLight',
          color: '$feedbackNegativePure'
        }
      }
    }
  ]
})

export const MergedTopicInput = styled(Input, {
  pr: 64,
  position: 'relative'
})

export const MergedTopicCountContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$nano',
  position: 'absolute',
  mr: '$micro',
  pr: '$nano',
  pl: '$nano'
})

export const MergedTopicCount = styled(MergingTopicRowContainer, {
  pointerEvents: 'none'
})
