import { StyledBetaBadge } from './Badge.styles'
import Text from '@/components/atoms/text'

const BetaBadge = () => (
  <StyledBetaBadge>
    <Text fontSize="nano" lineHeight="default" fontWeight="bold">
      BETA
    </Text>
  </StyledBetaBadge>
)

export default BetaBadge
