import Dialog from '@/components/atoms/dialog/Dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import Input from '@/components/atoms/input'
import Text from '@/components/atoms/text'
import { useState } from 'react'
import SavedFilterSelection from './SavedFilterSelection'
import Button from '@/components/atoms/button'
import useDashboardStore from '@/store/useDashboardStore'
import useSegment from '@/hooks/useSegment'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
}

const AddNewChart = ({ open, onOpenChange }: Props) => {
  const { track } = useSegment()

  const { t } = useTranslation()
  const [name, setName] = useState('Untitled')
  const [savedFilters, setSavedFilters] = useState<string[]>([])

  const addNewChart = useDashboardStore(state => state.addNewChart)

  const onAddNewChart = () => {
    onOpenChange(false)
    addNewChart(name, savedFilters)
    track('dashboard_user_add_chart')
  }

  const buttonDisabled = name.length === 0 || savedFilters.length === 0

  return (
    <Dialog align="center" closeIcon onOpenChange={onOpenChange} open={open}>
      <FlexContainer direction="column" gap="xs">
        <Text as="h2" typeface="titleBoldXXS">
          {t('addChart')}
        </Text>
        <FlexContainer direction="column" gap="xxs">
          <Text as="label" typeface="subtitleRegularXXXS">
            {t('selectASavedFilterToBringDataFromIt')}
          </Text>
          <SavedFilterSelection onChange={setSavedFilters} showCaret value={savedFilters} />
        </FlexContainer>
        <Divider line="solid" />
        <FlexContainer direction="column" gap="xxs">
          <Text as="label" typeface="subtitleRegularXXXS">
            {t('giveANameToYourChart')}
          </Text>
          <Input onChange={e => setName(e.currentTarget.value)} value={name} />
        </FlexContainer>
        <Divider line="solid" />
        <Button disabled={buttonDisabled} fullWidth onClick={onAddNewChart} small>
          {t('addNewChart')}
        </Button>
      </FlexContainer>
    </Dialog>
  )
}

export default AddNewChart
