import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import SaveFiltersButton from '@/components/molecules/saved-filters/SaveFiltersButton'
// import UpdateAreaButton from '@/components/molecules/saved-filters/UpdateAreaButton'
import HiddenMetricsModal from '@/components/molecules/home/HiddenMetricsModal'
import useUser from '@/hooks/useUser'
import AreaNotificationModal from '@/components/molecules/notifications/opportunity-plan/AreaNotificationModal'
import { useState } from 'react'
import useOpportunityStore from '@/store/useOpportunityStore'
import OpportunityPageOptions from './OpportunityPageOptions'
import useFilterEntityStore from '@/store/useFiltersStore/useFilterEntityStore'

interface Props {
  hideMetricsModal?: boolean
}

function ExplorationOptions({ hideMetricsModal }: Props) {
  const [open, setOpen] = useState(false)
  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)
  const editMode = useFilterEntityStore(state => state.editMode)

  const { userPermissions } = useUser()
  const isManager = userPermissions.areas.includes('manager')

  if (!isManager && currentInterestArea)
    return <AreaNotificationModal open={open} setOpen={setOpen} />

  if (editMode)
    return (
      <>
        {currentInterestArea && <AreaNotificationModal open={open} setOpen={setOpen} />}
        {!hideMetricsModal && <HiddenMetricsModal />}
        {/* <UpdateAreaButton buttonSize="default" buttonVariant="primary" css={{ mb: 'auto' }} /> */}
      </>
    )

  if (currentOpportunity) return <OpportunityPageOptions opportunity={currentOpportunity} />

  if (currentInterestArea)
    return (
      <>
        <AreaNotificationModal open={open} setOpen={setOpen} />
        {!hideMetricsModal && <HiddenMetricsModal />}
      </>
    )

  return (
    <>
      <SaveFiltersButton
        buttonSize="default"
        buttonVariant="primary"
        css={{ mb: 'auto' }}
        newFeed
      />
    </>
  )
}

export default ExplorationOptions
