import Button from '@/components/atoms/button'
import Select from '@/components/atoms/select'
import { mapInitiative, useInitiatives } from '@/hooks/initiatives/useInitiatives'
import useOpportunityMutations from '@/hooks/opportunity/useOpportunityMutations'
import useUser from '@/hooks/useUser'
import { OpportunityItem, OpportunityStatus } from '@/types/opportunity/Opportunity'
import { OPPORTUNITY_STATUS_OPTIONS } from '@/utils/opportunityUtils'
import { ArrowSquareOut, DotsThreeVertical, Target } from '@phosphor-icons/react'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import OpportunityOptions from '@/components/molecules/exploration/Opportunities/OpportunityTable/OpportunityOptions'
import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import useToastMessageStore from '@/store/useToastMessageStore'
import useRemovingItemsStore from '@/store/useRemoveItemsStore'
import useSegment from '@/hooks/useSegment'
import { MoveOppToSuccessMessage } from '@/components/molecules/exploration/Opportunities/OpportunitiesList/OpportunitiesList.styles'
import useAreaOfInterest from '@/hooks/areaOfInterest/useAreaOfInterest'
import RenameOpportunityDialog from '@/components/molecules/exploration/Opportunities/OpportunitiesList/RenameOpportunityDialog'
import CreateInitiativeDialog from '@/components/molecules/initiatives/modals/CreateInitiativeDialog'
import RelatedInitiativesModal from '../../initiatives/modals/RelatedInitiatives/RelatedInitiativesModal'
import { useNavigate } from 'react-router-dom'
import shortUUID from 'short-uuid'
import useInitiativeStore from '@/store/initiatives/useInitiativeStore'

interface Props {
  opportunity: OpportunityItem
}

const OpportunityPageOptions = ({ opportunity }: Props) => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const navigate = useNavigate()

  const translator = useMemo(() => shortUUID(), [])

  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const removeToast = useToastMessageStore(state => state.removeToast)
  const { pushRemovingItem } = useRemovingItemsStore()

  const { updateOpportunity, isUpdatingOpportunity, moveOpportunity } = useOpportunityMutations()
  const { setArea } = useAreaOfInterest()

  const initiativesLength = opportunity?.initiatives.length
  const isDraft = opportunity.status === OpportunityStatus.Draft

  const { userPermissions } = useUser()
  const isManager = userPermissions.areas.includes('manager')

  const onStatusChange = (newStatus: OpportunityStatus) => {
    if (opportunity.status === newStatus || !newStatus.length) return
    updateOpportunity({ ...opportunity, status: newStatus })
  }

  const setCurrentInitiative = useInitiativeStore(state => state.setCurrentInitiative)
  const { createInitiative } = useInitiatives({ enabled: false })

  const [addToInitiativeOpen, setAddToInitiativeOpen] = useState(false)

  const onAddToInitiativeClick = useCallback(() => {
    setAddToInitiativeOpen(true)
    track('initiative_add_button')
  }, [track])

  const onAddToInitiativeModalClick = useCallback(() => {
    setAddToInitiativeOpen(true)
    track('initiative_add_button_dialog')
  }, [track])

  const onCreateInitiative = useCallback(
    (name: string) => {
      createInitiative(
        { name, opportunityId: opportunity.id },
        {
          onSuccess: ({ newInitiative }) => {
            const shortInitiativeId = translator.fromUUID(newInitiative.initiative_id ?? '')
            const toLink = `/initiatives/${shortInitiativeId}`
            setCurrentInitiative(mapInitiative(newInitiative))
            navigate(toLink)
          }
        }
      )
    },
    [opportunity, createInitiative, translator, setCurrentInitiative, navigate]
  )

  const initiativesButtonLabel = useMemo(
    () => `${initiativesLength} ${initiativesLength === 1 ? t('initiative') : t('initiatives')}`,
    [initiativesLength, t]
  )

  const [initiativesModalOpen, setInitiativesModalOpen] = useState(false)
  const onInitiativesClick = useCallback(() => {
    setInitiativesModalOpen(true)
    track('opportunity_see_initiatives_button')
  }, [track])

  const onConfirmMoveTo = useCallback(
    (newArea: BaseInterestArea) => {
      const removeLoadingToast = addLoadingToast({ text: t('movingOpportunity') })
      pushRemovingItem(opportunity.id)

      moveOpportunity(
        {
          opportunity,
          currentAreaId: opportunity.relations[0] ?? undefined,
          newAreaId: newArea.id
        },
        {
          onSettled: () => removeLoadingToast(),
          onSuccess: () => {
            const toastId = `opp-move-toast-${opportunity.id}`
            track('areas_and_opps_move_opportunity_to', {
              to: newArea.name,
              from: opportunity.relations[0] ?? '',
              name: opportunity.name
            })
            addSuccessToast({
              id: toastId,
              children: (
                <MoveOppToSuccessMessage
                  areaName={newArea.name}
                  onClick={() => {
                    setArea(newArea)
                    removeToast(toastId)
                  }}
                />
              ),
              duration: 4000
            })
          }
        }
      )
    },
    [
      opportunity,
      addLoadingToast,
      pushRemovingItem,
      t,
      addSuccessToast,
      removeToast,
      setArea,
      moveOpportunity,
      track
    ]
  )

  const [isRenaming, setIsRenaming] = useState(false)
  const onConfirmRenameOpportunity = useCallback(
    (newName: string) => {
      const trimmedName = newName.trim()
      if (opportunity.name === trimmedName || !trimmedName.length) return

      updateOpportunity({
        id: opportunity.id,
        name: trimmedName,
        status: opportunity.status
      })
    },
    [opportunity, updateOpportunity]
  )

  const trackOnClose = () => {
    track('initiative_close_dialog')
  }

  return (
    <>
      <Select
        defaultValue={opportunity.status}
        disabled={isUpdatingOpportunity || isDraft || !isManager}
        onValueChange={onStatusChange}
        options={OPPORTUNITY_STATUS_OPTIONS}
        value={opportunity.status}
        width={180}
      />
      {initiativesLength === 0 ? (
        <Button size="small" css={{ height: 40 }} onClick={onAddToInitiativeClick}>
          <Target size={16} />
          <span>{t('addToInitiative')}</span>
        </Button>
      ) : (
        <Button
          size="small"
          css={{ height: 40 }}
          variant="white-bordered"
          onClick={onInitiativesClick}
        >
          <Target size={16} />
          <span>{initiativesButtonLabel}</span>
          <ArrowSquareOut size={16} />
        </Button>
      )}
      <OpportunityOptions
        customButton={
          <Button
            css={{ size: 40, boxSizing: 'border-box', padding: 0 }}
            variant="white-bordered"
            aria-label="options"
            onClick={e => e.stopPropagation()}
          >
            <DotsThreeVertical weight="bold" />
          </Button>
        }
        data={opportunity}
        onConfirmMoveTo={onConfirmMoveTo}
        onRename={() => setIsRenaming(true)}
      />
      {isRenaming && (
        <RenameOpportunityDialog
          onClose={() => setIsRenaming(false)}
          onSave={onConfirmRenameOpportunity}
          opportunityName={opportunity.name}
        />
      )}
      {addToInitiativeOpen && (
        <CreateInitiativeDialog
          open={addToInitiativeOpen}
          onOpenChange={setAddToInitiativeOpen}
          onCreate={onCreateInitiative}
        />
      )}
      {initiativesModalOpen && (
        <RelatedInitiativesModal
          onClose={trackOnClose}
          opportunity={opportunity}
          open={initiativesModalOpen}
          onOpenChange={setInitiativesModalOpen}
          onAddInitiativeClick={onAddToInitiativeModalClick}
        />
      )}
    </>
  )
}

export default OpportunityPageOptions
