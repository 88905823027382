import { CaretDown, X } from '@phosphor-icons/react'
import Select, {
  components,
  DropdownIndicatorProps,
  MultiValue,
  MultiValueRemoveProps,
  SingleValue
} from 'react-select'

import countries from './countries.json'
import { getMultiSelectStyles } from '@/theme/select'

type Option = { label: string; value: string }
interface CountrySelectProps {
  onChange(value: string[]): void
  values: string[]
}

const styles = getMultiSelectStyles<Option>()

const DropdownIndicator = (props: DropdownIndicatorProps<Option>) => (
  <components.DropdownIndicator {...props}>
    <CaretDown color="#8E8E8E" size={16} weight="bold" />
  </components.DropdownIndicator>
)

const MultiValueRemove = (props: MultiValueRemoveProps<Option>) => (
  <components.MultiValueRemove {...props}>
    <X color="#8E8E8E" size={12} weight="bold" />
  </components.MultiValueRemove>
)

function CountrySelect(props: CountrySelectProps) {
  function onSelect(selectedOptions: MultiValue<Option> | SingleValue<Option>) {
    const _selectedOptions = selectedOptions as MultiValue<Option>
    const values = _selectedOptions.map(option => option.value)
    props.onChange(values)
  }

  const valuesAsOptions: Option[] = props.values.map(value => ({ label: value, value }))

  return (
    <Select
      className="basic-multi-select"
      classNamePrefix="select"
      components={{
        DropdownIndicator,
        IndicatorSeparator: null,
        MultiValueRemove
      }}
      isClearable={false}
      isMulti
      menuPortalTarget={document.body}
      name="colors"
      onChange={onSelect}
      options={countries}
      placeholder="Select countries"
      styles={styles}
      value={valuesAsOptions}
    />
  )
}

export default CountrySelect
