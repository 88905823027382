import { useState, useMemo, useEffect, RefObject } from 'react'

function useIsInViewport<T extends HTMLElement>(ref: RefObject<T>) {
  const [isIntersecting, setIsIntersecting] = useState(false)

  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
    []
  )

  useEffect(() => {
    ref.current && observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [ref, observer])

  return isIntersecting
}

export default useIsInViewport
