import type { SwitchProps as SwitchRootProps } from '@radix-ui/react-switch'
import { SwitchRoot, SwitchThumb } from './Switch.styles'
import { CSS } from '@/theme'

export type SwitchProps = SwitchRootProps

const Switch = ({ css, ...props }: SwitchProps & { css?: CSS }) => (
  <SwitchRoot css={css} {...props}>
    <SwitchThumb />
  </SwitchRoot>
)

export default Switch
