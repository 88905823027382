import Loader from '@/components/atoms/loader'
import { RedirectContainer, RedirectText } from './RedirectContainer.styles'

function Redirect() {
  return (
    <RedirectContainer>
      <Loader />
      <RedirectText>Loading...</RedirectText>
    </RedirectContainer>
  )
}

export default Redirect
