import Checkbox from '@/components/atoms/checkbox'
import Text from '@/components/atoms/text'
import { styled } from '@/theme'
import useTopicsStore from '@/store/useTopicsStore'
import { useTranslation } from 'react-i18next'

const UngroupedContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  height: 24,
  width: '100%'
})

const UngroupedContainerOuter = styled(UngroupedContainer, {
  borderRadius: 8,
  padding: 4,
  margin: -4,
  paddingRight: 16,
  width: 342,
  overflow: 'visible',

  variants: {
    noHover: {
      true: {
        '&:hover': {
          background: 'transparent'
        }
      }
    }
  },

  '.theme-options': {
    position: 'relative',
    zIndex: 100,
    overflow: 'visible',
    opacity: 0
  },

  '&:hover': {
    '.theme-options': {
      opacity: 1
    }
  }
})

const UngroupedCheckbox = () => {
  const isInUngrouped = useTopicsStore(state => state.isUngrouped)
  const setIsUngrouped = useTopicsStore(state => state.setIsUngrouped)
  const setSelectedTopicGroups = useTopicsStore(state => state.setSelectedTopicGroups)
  const setDebounceLoading = useTopicsStore(state => state.setDebounceLoading)
  const reset = useTopicsStore(state => state.reset)
  const { t } = useTranslation()

  function onCheck(checked: boolean) {
    setIsUngrouped(checked)
    checked && setSelectedTopicGroups([])
    reset()
    setDebounceLoading(true)
  }

  return (
    <UngroupedContainerOuter noHover>
      <Checkbox checked={isInUngrouped} onCheckedChange={onCheck} value="ungrouped-topics" />

      <UngroupedContainer>
        <Text as="span" maxWidth={200} truncate typeface="subtitleRegularXXXS">
          {t('ungroupedTopics')}
        </Text>
      </UngroupedContainer>
    </UngroupedContainerOuter>
  )
}

export default UngroupedCheckbox
