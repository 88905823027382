import { keyframes, styled } from '@/theme'
import { CaretDown, X } from '@phosphor-icons/react'
import * as Collapsible from '@radix-ui/react-collapsible'
import { VariantProps } from '@stitches/react'

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-collapsible-content-height)' }
})

const slideUp = keyframes({
  from: { height: 'var(--radix-collapsible-content-height)' },
  to: { height: 0 }
})

export const CollapsibleRoot = Collapsible.Root
export const CollapsibleTrigger = styled(Collapsible.Trigger, {
  all: 'unset',
  // disable accidental text select when toggling the collapsible content
  userSelect: 'none'
})

export const CollapsibleContentAnimated = styled(Collapsible.Content, {
  overflow: 'hidden',

  '&[data-state="open"]': { animation: `${slideDown} 100ms ease-out` },
  '&[data-state="closed"]': { animation: `${slideUp} 100ms ease-out` }
})

const StyledTriggerButton = styled(Collapsible.Trigger, {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$micro',
  cursor: 'pointer',
  fontSize: '$xxxs',
  lineHeight: '$xs',
  width: 'fit-content',
  outline: 'none',
  transition: '.2s',
  boxSizing: 'border-box',
  borderRadius: 8,

  color: '$neutralLowPure',
  bAll: '$neutralHighPure',

  height: 32,
  padding: 'calc($micro - 1px) calc($xxs - 1px)',

  '&:hover': {
    color: '$brandPrimaryPure',
    backgroundColor: '$brandPrimaryLight'
  },
  '&:disabled': {
    color: '$neutralLowLight',
    pointerEvents: 'none'
  },

  '.trigger-icon': {
    display: 'block'
  },

  '.close-icon': {
    display: 'none'
  },

  '&[data-state="open"]': {
    color: '$brandPrimaryPure',
    backgroundColor: '$brandPrimaryLight',
    bAll: '$brandPrimaryPure',

    '.trigger-icon': {
      display: 'none'
    },
    '.close-icon': {
      display: 'block'
    }
  },

  variants: {
    variant: {
      primary: {
        backgroundColor: '$brandPrimaryPure',
        color: '$neutralHighLight',

        '&:hover': {
          backgroundColor: '$brandPrimaryMedium'
        },
        '&:disabled': {
          backgroundColor: '$neutralHighMedium',
          color: '$neutralLowLight'
        }
      },
      secondary: {
        backgroundColor: '$neutralHighLight',
        borderColor: '$brandPrimaryPure',
        color: '$brandPrimaryPure',

        '&:hover': {
          backgroundColor: '$brandPrimaryLight'
        },
        '&:disabled': {
          backgroundColor: '$neutralHighMedium',
          borderColor: '$neutralHighPure',
          color: '$neutralLowLight'
        }
      },
      flat: {
        backgroundColor: 'transparent',
        color: '$brandPrimaryPure',

        '&:hover': {
          backgroundColor: '$brandPrimaryLight'
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          color: '$neutralLowLight'
        }
      },
      exception: {
        backgroundColor: '$feedbackWarningPure',
        borderColor: '$brandPrimaryPure',
        color: '$brandPrimaryPure',

        '&:hover': {
          backgroundColor: '$feedbackWarningMedium'
        },
        '&:disabled': {
          backgroundColor: '$neutralHighMedium',
          borderColor: '$neutralLowLight',
          color: '$neutralLowLight'
        }
      },
      danger: {
        backgroundColor: '$feedbackNegativePure',
        color: '$neutralHighLight',

        '&:hover': {
          backgroundColor: '$feedbackNegativeMedium'
        },
        '&:disabled': {
          backgroundColor: '$neutralHighMedium',
          color: '$neutralLowLight'
        }
      },
      'flat-bordered': {
        backgroundColor: 'transparent',
        color: '$neutralLowPure',
        bAll: '$neutralHighPure',

        '&:hover': {
          color: '$brandPrimaryPure',
          backgroundColor: '$brandPrimaryLight'
        },
        '&:disabled': {
          color: '$neutralLowLight'
        },

        '&[data-state="open"]': {
          color: '$brandPrimaryPure',
          backgroundColor: '$brandPrimaryLight'
        }
      },
      'tinted-bordered': {
        backgroundColor: 'transparent',
        color: '$brandPrimaryPure',
        bAll: '$brandPrimaryPure',

        '&:hover': {
          color: '$brandPrimaryPure',
          backgroundColor: '$brandPrimaryLight'
        },
        '&:disabled': {
          color: '$neutralLowLight'
        },

        '&[data-state="open"]': {
          color: '$brandPrimaryPure',
          backgroundColor: '$brandPrimaryLight'
        }
      },
      link: {
        backgroundColor: 'transparent',
        color: '$neutralLowPure',
        padding: 0,
        px: '$xxxs',
        height: 'fit-content',
        border: 'none',

        '&:hover': {
          color: '$brandPrimaryPure',
          background: 'transparent'
        },
        '&:disabled': {
          color: '$neutralHighPure'
        },

        '&[data-state="open"]': {
          border: 'none',
          backgroundColor: 'transparent'
        }
      }
    }
  }
})

export const CollapsibleTriggerButton = ({
  children,
  ...props
}: Collapsible.CollapsibleTriggerProps & VariantProps<typeof StyledTriggerButton>) => (
  <StyledTriggerButton {...props}>
    <div>{children}</div>
    <CaretDown className="trigger-icon" size={12} />
    <X className="close-icon" size={12} />
  </StyledTriggerButton>
)
