import NotificationsService from '@/services/NotificationsService'
import useToastMessageStore from '@/store/useToastMessageStore'
import { useQuery } from '@tanstack/react-query'
import useLogging from './useLogging'

const useSlackChannels = () => {
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { logException } = useLogging({ context: 'slack-channels' })

  const queryFn = async () => {
    const [error, data] = await NotificationsService.getSlackChannels()
    if (error) throw error

    return data
  }

  const { data, isLoading, isError, isRefetching, refetch, ...otherQueryProps } = useQuery({
    queryKey: ['slackChannels'],
    queryFn
  })

  const deleteSlackChannel = async (channelId: string) => {
    const [error] = await NotificationsService.removeChannel(channelId)

    if (error) {
      console.error(error)
      const message = 'Failed to remove Slack channel'
      logException(error, { message })
      addErrorToast({ text: message })
      return false
    }

    refetch()
    addSuccessToast({ text: 'Slack channel removed' })
    return true
  }

  return {
    // query
    slackChannels: data,
    isLoading,
    isError,
    isRefetching,
    refetch,
    ...otherQueryProps,

    deleteSlackChannel
  }
}

export default useSlackChannels
