import { styled } from '@/theme'

export const BadgeDot = styled('div', {
  $$border: '3px',
  background: '$pajarito',
  borderRadius: 22,
  border: '$$border solid $white',
  position: 'absolute',
  display: 'none',

  variants: {
    outside: {
      true: {}
    },
    align: {
      'right-bottom': {
        bottom: 0,
        right: 0
      },
      'right-top': {
        top: 0,
        right: 0
      },
      'left-bottom': {
        bottom: 0,
        left: 0
      },
      'left-top': {
        top: 0,
        left: 0
      }
    },
    size: {
      default: {
        size: '12px'
      },
      small: {
        size: 'calc($xxxs - $$border)'
      },
      large: {
        size: 'calc($xs - $$border)'
      }
    },
    show: {
      true: {
        display: 'block'
      },
      false: {
        display: 'none'
      }
    }
  },

  compoundVariants: [
    {
      outside: true,
      align: 'right-bottom',
      css: {
        bottom: '1px',
        right: '3px'
      }
    },
    {
      outside: true,
      align: 'right-top',
      css: {
        top: '-4px',
        right: '-4px'
      }
    },
    {
      outside: true,
      align: 'left-bottom',
      css: {
        bottom: '-4px',
        left: '-4px'
      }
    },
    {
      outside: true,
      align: 'right-top',
      css: {
        top: '-4px',
        left: '-4px'
      }
    }
  ]
})

export const Badge = styled('span', {
  // padding: '$nano $micro',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$brandPrimaryPure',
  size: 16,
  br: 60,
  color: '$neutralHighLight'
})

export const StyledBetaBadge = styled('div', {
  padding: '$nano',
  maxHeight: '15px',
  maxWidth: '35px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#FFDB5D',
  color: '$brandPrimaryPure',
  br: 4
})

export const NumberBadge = styled('span', {
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
  padding: '$nano $micro',
  br: '$micro',
  height: 'fit-content',

  fontSize: '$micro',
  fontWeight: '$regular',
  lineHeight: '$md',

  maxWidth: 'calc(28px + $xxs)',

  variants: {
    level: {
      high: {
        color: '$neutralHighLight',
        backgroundColor: '$neutralLowMedium'
      },
      medium: {
        color: '$neutralHighLight',
        backgroundColor: '$neutralLowLight'
      },
      low: {
        color: '$neutralLowMedium',
        backgroundColor: '$neutralHighPure'
      },
      volume: {
        color: '$neutralLowMedium',
        backgroundColor: '$neutralHighMedium',
        maxWidth: 'calc(48px + $xxs)'
      }
    }
  },

  defaultVariants: {
    level: 'volume'
  }
})
