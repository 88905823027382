import { FolderStar } from '@phosphor-icons/react'
import Popover from '@/components/atoms/popover'
import { useEffect, useMemo, useRef, useState } from 'react'
import Text from '@/components/atoms/text'
import useSavedFiltersStore from '@/store/useSavedFiltersStore'
import { shallow } from 'zustand/shallow'
import useSavedFilters from '@/hooks/useSavedFilters'
import SavedFilterItem from './SavedFilterItem'
import { FilterList, BlankButton, PopoverContent, SectionTitle } from './SavedFiltersPopover.styles'
import useSegment from '@/hooks/useSegment'
import { DeleteDialog } from '@/components/atoms/dialog'
import useToastMessageStore from '@/store/useToastMessageStore'
import FiltersService from '@/services/FiltersService'
import Tooltip from '@/components/atoms/tooltip'
import FlexContainer from '@/components/atoms/flex-container'
import Divider from '@/components/atoms/divider'
import Search from '../search'
import { SavedFilter } from '@/types/filters/Filters'
import NotificationsService from '@/services/NotificationsService'
import Button from '@/components/atoms/button'
import { Badge } from '@/components/atoms/badge'
import useLogging from '@/hooks/useLogging'
import { useTranslation } from 'react-i18next'
import { sortByKeyString } from '@/utils/array'

interface Props {
  onApplySavedFilter: (filter: SavedFilter) => void
  newFeed?: boolean
}

function SavedFilterPopover({ onApplySavedFilter, newFeed = false }: Props) {
  const { track } = useSegment()
  const { t } = useTranslation()
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)

  const { logException } = useLogging({ context: 'saved-filter-popover' })

  const { onStartBlankFilter, onSaveAsNew, fetchFilters } = useSavedFilters({ newFeed })
  const currentSavedFilter = useSavedFiltersStore(state => state.currentSavedFilter, shallow)

  const [popoverOpen, setPopoverOpen] = useState(false)
  const [filtersToDelete, setFiltersToDelete] = useState<SavedFilter | null>(null)
  const isDeleting = Boolean(filtersToDelete)

  const savedFilters = useSavedFiltersStore(state => state.savedFilters, shallow)

  const sortedViews = savedFilters.sort(sortByKeyString('name'))

  const [searchInput, setSearchInput] = useState('')
  const filteredSavedFilters = useMemo(
    () =>
      sortedViews.filter(savedFilter =>
        savedFilter.name.toLowerCase().includes(searchInput.toLowerCase())
      ),
    [sortedViews, searchInput]
  )

  const onOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      track('explore_user_saved_view_open')
    }
    setPopoverOpen(isOpen)
  }

  const alreadyOpenRef = useRef(false)

  useEffect(() => {
    // Lazy loading phosphor icons css
    if (popoverOpen && !alreadyOpenRef.current) {
      alreadyOpenRef.current = true

      import('@phosphor-icons/web/regular')
      import('@phosphor-icons/web/bold')
    }
  }, [popoverOpen])

  const onClickSavedFilter = (filter: SavedFilter) => {
    onApplySavedFilter(filter)
    setPopoverOpen(false)
  }

  const onClickSaveAsNew = (filter: SavedFilter) => {
    onSaveAsNew(filter)
    onApplySavedFilter(filter)
  }

  const onClickDelete = (filter: SavedFilter) => {
    setFiltersToDelete(filter)
  }

  const onDeleteDialogOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      setFiltersToDelete(null)
    }
  }

  const onConfirmDelete = async () => {
    if (!filtersToDelete) return
    const removeLoading = addLoadingToast({ text: t('deletingSavedFilters') })

    try {
      const deleteFilterPromise = FiltersService.deleteFilter(filtersToDelete.filterId)

      const promises = [deleteFilterPromise]
      if (filtersToDelete.notification?.id) {
        const deleteNotificationPromise = NotificationsService.deleteNotification(
          filtersToDelete.notification.id
        )
        promises.push(deleteNotificationPromise)
      }

      await Promise.all(promises)

      await fetchFilters()
      onStartBlankFilter()

      removeLoading()
      addSuccessToast({ text: t('savedFiltersDeletedSuccessfully') })
    } catch (error) {
      removeLoading()
      const message = t('failedToDeleteSavedFilters')
      logException(error, { message })
      addErrorToast({ text: message })
    }
  }

  const showSearch = searchInput.trim().length > 0

  const searchResultsText = t(
    filteredSavedFilters.length === 1 ? 'searchResults' : 'searchResults_plural',
    {
      count: filteredSavedFilters.length
    }
  )

  return (
    <>
      <Popover
        buttonChildren={
          <Button
            data-state={popoverOpen ? 'open' : 'closed'}
            size="default"
            variant="flat-bordered"
          >
            <Tooltip side="bottom" text="Open">
              <FolderStar size={24} weight="regular" />
            </Tooltip>
            {Boolean(currentSavedFilter) && (
              <Badge>
                <Text
                  color="neutralHighLight"
                  fontSize="xxxs"
                  fontWeight="bold"
                  lineHeight="default"
                >
                  1
                </Text>
              </Badge>
            )}
          </Button>
        }
        contentProps={{ align: 'start' }}
        customButton
        onOpenChange={onOpenChange}
        open={popoverOpen}
      >
        <PopoverContent>
          <Search
            onChange={e => setSearchInput(e.currentTarget.value)}
            placeholder={t('searchSavedFilters')}
            size="small"
            small
            value={searchInput}
          />
          {showSearch && (
            <>
              <Divider line="solid" />
              <FlexContainer direction="column" gap="micro">
                <SectionTitle>{searchResultsText}</SectionTitle>
                <FilterList direction="column">
                  {filteredSavedFilters.map(filter => (
                    <SavedFilterItem
                      filter={filter}
                      key={filter.filterId}
                      onClickDelete={onClickDelete}
                      onClickSaveAsNew={onClickSaveAsNew}
                      onClickSavedFilter={onClickSavedFilter}
                    />
                  ))}
                </FilterList>
              </FlexContainer>
            </>
          )}

          {Boolean(savedFilters.length) && !showSearch && (
            <>
              <Divider line="solid" />
              <FlexContainer direction="column" gap="micro">
                <SectionTitle>Open saved filters</SectionTitle>
                <FilterList direction="column">
                  {sortedViews.map(filter => (
                    <SavedFilterItem
                      applied={currentSavedFilter?.filterId === filter.filterId}
                      filter={filter}
                      key={filter.filterId}
                      onClickDelete={onClickDelete}
                      onClickSaveAsNew={onClickSaveAsNew}
                      onClickSavedFilter={onClickSavedFilter}
                    />
                  ))}
                </FilterList>
              </FlexContainer>
            </>
          )}

          {!savedFilters.length && !showSearch && (
            <>
              <Divider line="solid" />
              <FlexContainer direction="column" gap="micro">
                <SectionTitle>{t('noFiltersSaved')}</SectionTitle>
                <FilterList css={{ mx: '$micro' }} direction="column" gap="xxs">
                  <Text
                    as="p"
                    color="neutralLowLight"
                    lineHeight="md"
                    typeface="paragraphRegularMicro"
                  >
                    {'youDontHaveNothingSavedYetWhenYouStartSavingYourSavedFiltersWillAppearHere'}
                  </Text>
                </FilterList>
              </FlexContainer>
            </>
          )}
          <Divider line="solid" />
          <BlankButton onClick={() => onStartBlankFilter(true)}>
            {t('resetSavedFiltersAppliedOnPage')}
          </BlankButton>
        </PopoverContent>
      </Popover>
      {isDeleting && (
        <DeleteDialog
          confirmText={t('delete')}
          description={t(
            'doYouReallyWantToDeleteYourSavedFiltersYoureOnlyDeletingTheSetOfFiltersYourDataIsSafe'
          )}
          isDeleting={false}
          modal={false}
          onConfirmDelete={onConfirmDelete}
          onOpenChange={onDeleteDialogOpenChange}
          open={isDeleting}
          title={t('deleteSavedFiltersQuestion')}
        />
      )}
    </>
  )
}

export default SavedFilterPopover
