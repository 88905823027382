import Breadcrumb from '@/components/atoms/breadcrumb'
import { AnalyticsFlexContainer } from '@/components/molecules/analytics/Analytics.styles'
import TopicCustomersIndicator from '@/components/molecules/analytics/topic-details/TopicCustomersIndicator'
import TopicFeedbackIndicator from '@/components/molecules/analytics/topic-details/TopicFeedbackIndicator'
import TopicRelatedFeedback from '@/components/molecules/analytics/topic-details/TopicRelatedFeedback'
import TopicSummaryAndCauses from '@/components/molecules/analytics/topic-details/TopicSummaryAndCauses'
import AnalyticsTrendLine from '@/components/molecules/analytics/trend-line/AnalyticsTrendLine'
import useSegment from '@/hooks/useSegment'
import NotFoundPage from '@/pages/not-found/NotFound'
import { TrendingTopicType } from '@/types/analytics/TrendingTopic'
import { isTrendingTopicType, trendingTopicTypeMap } from '@/utils/trendingTopics'
import { Swatches } from '@phosphor-icons/react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const TopicDetailsPage = () => {
  const { track } = useSegment()

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen once
  useEffect(() => {
    track('explore_user_go_to_analytics_topic_drilldown')
  }, [])

  const { topicString, topicType } = useParams()

  if (!topicType || !isTrendingTopicType(topicType) || !topicString) {
    return <NotFoundPage />
  }

  const [topicName, topicId] = topicString.split('@@')
  if (!topicName || !topicId) {
    return <NotFoundPage />
  }

  const title = trendingTopicTypeMap[topicType as TrendingTopicType].title
  const Icon = trendingTopicTypeMap[topicType as TrendingTopicType].Icon
  const IndicatorComponent = trendingTopicTypeMap[topicType as TrendingTopicType].IndicatorComponent

  return (
    <>
      <Breadcrumb.Root>
        <Breadcrumb.Item to="/analytics">
          <Icon />
          <span>{title}</span>
        </Breadcrumb.Item>
        <Breadcrumb.Divider />
        <Breadcrumb.Item active>
          <Swatches />
          <span>{topicName}</span>
        </Breadcrumb.Item>
      </Breadcrumb.Root>

      <AnalyticsFlexContainer justifyContent="stretch">
        <IndicatorComponent topicId={topicId} />
        <TopicFeedbackIndicator topicId={topicId} />
        <TopicCustomersIndicator topicId={topicId} />
      </AnalyticsFlexContainer>

      <TopicSummaryAndCauses topicId={topicId} type={topicType as TrendingTopicType} />

      <AnalyticsTrendLine topicId={topicId} />

      <TopicRelatedFeedback
        topicId={topicId}
        topicName={topicName}
        topicType={topicType as TrendingTopicType}
      />
    </>
  )
}

export default TopicDetailsPage
