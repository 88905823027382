import AudienceService from '@/services/AudienceService'
import { CustomerRecordsDateFieldValue } from '@/types/manage-customers/CustomerRecordsFilters'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

interface Params {
  filterKey: string
  enabled: boolean
}

const useAccountDateFilter = ({ filterKey, enabled }: Params) => {
  const { data, isLoading } = useQuery({
    queryKey: ['filters-accounts-values', filterKey],
    queryFn: async params =>
      AudienceService.getAccountsFiltersValues({ field: filterKey, ...params }),
    enabled,
    refetchOnMount: false,
    retry: false
  })

  const fieldRange = useMemo(() => {
    if (!data) return null
    const [error, result] = data
    if (error) return null

    const fieldData = result as CustomerRecordsDateFieldValue

    return { startDate: fieldData.start_date, endDate: fieldData.end_date }
  }, [data])

  return {
    isLoading,
    fieldRange
  }
}

export default useAccountDateFilter
