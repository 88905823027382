import useFiltersQuery from '@/hooks/filters/useFiltersQuery'
import useLetterCase from '@/hooks/useLetterCase'
import { FilterString } from '@/types/filters/Filters'
import { FilterItemDropdown } from '../FilterItem'
import FeedbackDataTypeContent from '../../FiltersSidebar/FeedbackType/FeedbackDataTypeContent'
import { useState } from 'react'
import { FEEDBACK_DATA_TYPE_KEY } from '@/utils/filters'
import { useStringGenericFilter } from '../../FiltersSidebar/StringGenericFilter'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

interface AppliedFeedbackTypeFiltersEditProps {
  filter: FilterString
}

function AppliedFeedbackTypeFiltersEdit(props: AppliedFeedbackTypeFiltersEditProps) {
  const { capitalizeFirst } = useLetterCase()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const { feedbackDataType } = useFiltersQuery()

  function getTooltipText(filter: FilterString) {
    const selectedOptions = filter.selected
      .map(option => `<b>${capitalizeFirst(option)}</b>`)
      .join(t('or'))

    return i18n.t('isSelectedOptions', { selectedOptions })
  }

  const { hasChanges, apply } = useStringGenericFilter({
    type: 'string',
    name: FEEDBACK_DATA_TYPE_KEY,
    filterKey: FEEDBACK_DATA_TYPE_KEY,
    enabled: open
  })

  if (!feedbackDataType) return <></>

  return (
    <FilterItemDropdown
      content={props.filter.selected.length}
      contentType="count"
      disabled={!hasChanges}
      dropdown={<FeedbackDataTypeContent enabled={open} feedbackDataType={feedbackDataType} />}
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      title={t('feedbackType')}
      tooltip={getTooltipText(props.filter)}
    />
  )
}

export default AppliedFeedbackTypeFiltersEdit
