import { CSS, styled } from '@/theme'

export const DashboardChartCardContainer = styled('article', {
  borderRadius: 8,
  bAll: '$neutralHighPure'
})

export const DashboardChartCardHeader = styled('header', {
  position: 'relative',
  padding: '20px $sm',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'spaceBetween',
  bBottom: '$neutralHighPure',
  transition: '.2s',
  h2: {
    fontSize: '$xxs',
    fontWeight: '$semibold',
    cursor: 'pointer'
  },

  'h2:hover': {
    color: '$brandPrimaryPure'
  },

  '&:has(h2:hover)': {
    background: '$brandPrimaryLight'
  }
})

export const DashboardChartCardOptions = styled('div', {
  display: 'flex',
  alignItems: 'center',
  ml: 'auto',
  gap: '$xxs',
  '.icon-button': {
    background: '$white'
  }
})

export const DashboardChartCardContent = styled('div', {
  padding: '20px $sm',
  minHeight: 440
})

const dashboardSaveFlex: CSS = {
  display: 'flex',
  alignItems: 'center',
  gap: '$nano',
  fontSize: 12
}

export const DashboardChartSavedMessage = styled('div', {
  ...dashboardSaveFlex,
  color: '$neutralLowLight'
})
