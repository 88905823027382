import { createWithEqualityFn } from 'zustand/traditional'

import createInfiniteScrollStore, { InifiniteScrollSlice } from './createInfiniteScrollSlice'
import {
  AccountData,
  AccountFieldType,
  AccountFieldsKey,
  FeedbackAccountResult
} from '@/types/manage-customers'
import { shallow } from 'zustand/shallow'
import AudienceService from '@/services/AudienceService'

type AllAccountsFieldsTypes = Record<AccountFieldsKey, AccountFieldType[]>

interface AccountsState {
  detailedAccount: AccountData | undefined
  hasAccounts: boolean
  allAccountsFields: AllAccountsFieldsTypes
  accountsFeedbacksResults: FeedbackAccountResult[]

  setDetailedAccount: (account?: AccountData) => void
  removeAccount: (accountId: string) => void
  setHasAccounts: (hasAccounts: boolean) => void
  checkHasAccounts: (organizationId?: string) => Promise<boolean>
  setAllAccountsFields: (allAccountsFields: AllAccountsFieldsTypes) => void
  setAccountsFeedbacksResults: (accountsFeedbacksResults: FeedbackAccountResult[]) => void
}

const accountsInfiniteScrollSlice = createInfiniteScrollStore<AccountData>()

const useAccountsStore = createWithEqualityFn<InifiniteScrollSlice<AccountData> & AccountsState>()(
  (set, ...args) => ({
    ...accountsInfiniteScrollSlice(set, ...args),

    detailedAccount: undefined,
    hasAccounts: false,
    allAccountsFields: {
      customer_types: [],
      industries: [],
      plans: [],
      sales_pipeline_stages: []
    },

    accountsFeedbacksResults: [],

    setDetailedAccount: account => set(() => ({ detailedAccount: account })),

    removeAccount: accountId =>
      set(state => ({ data: state.data.filter(account => account.id !== accountId) })),
    setHasAccounts: hasAccounts => set(() => ({ hasAccounts })),

    checkHasAccounts: async organizationId => {
      const [get] = args
      const { hasAccounts } = get()
      if (hasAccounts) return true

      const [error, data] = await AudienceService.getAccounts({
        orgId: organizationId || '',
        per_page: 0
      })
      if (error) return false

      const hasData = data.total > 0
      set(() => ({ hasAccounts: hasData }))

      return hasData
    },

    setAllAccountsFields: allAccountsFields => set(() => ({ allAccountsFields })),

    setAccountsFeedbacksResults: accountsFeedbacksResults =>
      set(() => ({ accountsFeedbacksResults }))
  }),
  shallow
)

export default useAccountsStore
