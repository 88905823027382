import { Badge } from '@/components/atoms/badge'
import Button from '@/components/atoms/button'
import Text from '@/components/atoms/text'
import { CSS } from '@/theme'
import { FunnelSimple } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'

interface Props {
  count: number
  isOpen: boolean
  onClick: () => void
  triggerCSS?: CSS
}

const FiltersButton = ({ count, isOpen, onClick, triggerCSS }: Props) => {
  const hasFilters = count > 0
  const { t } = useTranslation()

  return (
    <Button
      css={{ gap: '$micro', bc: '$neutralHighLight', ...triggerCSS }}
      data-state={isOpen ? 'open' : 'closed'}
      onClick={onClick}
      size="default"
      variant="flat-bordered"
    >
      <FunnelSimple size={16} weight="regular" />
      <Text fontSize="xxxs" fontWeight="regular">
        {t('filters')}
      </Text>
      {hasFilters && (
        <Badge>
          <Text color="neutralHighLight" fontSize="xxxs" fontWeight="bold" lineHeight="default">
            {count}
          </Text>
        </Badge>
      )}
    </Button>
  )
}

export default FiltersButton
