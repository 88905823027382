import { CSSProperties, memo, useEffect, useState } from 'react'
import { FeedbackItemProps } from '../feed/new/feedback/feedback-item/FeedbackItem.types'
import FeedbackItem from '../feed/new/feedback/feedback-item/FeedbackItem'

interface Props extends FeedbackItemProps {
  style: CSSProperties
  index: number
  setRowHeight: (index: number, height: number) => void
}

const AssistantFeedbackRow = ({ style, index, setRowHeight, ...props }: Props) => {
  const [rowRef, setRowRef] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (rowRef) {
      setRowHeight(index, rowRef.clientHeight)
    }
  }, [rowRef, index, setRowHeight])

  return (
    <div className="scroll-on-hover-child" style={style}>
      <FeedbackItem
        {...props}
        css={{ ...props.css, minHeight: 120 }}
        index={index}
        ref={setRowRef}
      />
    </div>
  )
}

export default memo(AssistantFeedbackRow)
