import { BellRinging, BellSimple } from '@phosphor-icons/react'
import { useMemo } from 'react'
import Dialog from '@/components/atoms/dialog/Dialog'
import useUser from '@/hooks/useUser'
import Text from '@/components/atoms/text'
import Button from '@/components/atoms/button'
import Divider from '@/components/atoms/divider'
import DeleteNotificationModal from '../DeleteNotificationModal'
import useAreaNotification from '../useAreaNotification'
import NotificationHeader from './NotificationHeader'
import Styled from './AreaNotificationHeader.styles'
import NotificationForm from './NotificationForm'
import { useTranslation } from 'react-i18next'
import { AlertData } from '@/types/alerts/Alerts'

interface Props {
  hideTrigger?: boolean
  defaultAlert?: AlertData
  defaultNotificationType?: 'area-interest' | 'opportunity'
  open: boolean
  setOpen: (value: boolean) => void
}

const AreaNotificationModal = ({
  defaultAlert,
  open,
  hideTrigger,
  defaultNotificationType,
  setOpen
}: Props) => {
  const { userPermissions } = useUser()

  const { t } = useTranslation()

  const isManager = userPermissions.areas.includes('manager')

  const {
    name,
    setName,

    schedule,
    setSchedule,

    emails,
    setEmails,
    sendToEmail,

    channels,
    setChannels,
    sendToSlack,

    slackChannels,
    isSlackConnected,

    isOwner,
    isSubscribed,
    isApplyDisabled,

    onOpenDeleteDialogChange,
    onClickSubscribe,
    onApply,
    onChangeSendToEmail,
    onChangeSendToSlack,
    onDelete,

    currentAlert,
    isLoading,
    isUpdateLoading,
    isRemoveLoading,

    deleteDialogMode,
    setDeleteDialogMode
  } = useAreaNotification({
    defaultAlert,
    defaultNotificationType,
    open,
    setOpen
  })

  const hasNotification = !!currentAlert && !isLoading

  const hasEmailConfig = useMemo(() => {
    if (!currentAlert) return false

    const emailConfig = currentAlert.notificationConfig.find(config => config.type === 'email')
    return emailConfig && emailConfig.value.length > 0
  }, [currentAlert])

  const getApplyButtonText = () => {
    if (isUpdateLoading || isLoading) return t('loading')

    return !hasNotification ? t('create') : t('save')
  }

  if (!isManager && !hasNotification) return <></>

  return (
    <>
      {!hideTrigger && (
        <Styled.DropdownTriggerButton
          onClick={() => setOpen(true)}
          variant={isSubscribed ? 'white-bordered-active' : 'white-bordered'}
        >
          {isSubscribed ? <BellRinging size={16} weight="fill" /> : <BellSimple size={16} />}
        </Styled.DropdownTriggerButton>
      )}

      <Dialog
        align="top-center"
        closeIcon
        contentProps={{ css: { width: 480, minWidth: 480 } }}
        onOpenChange={setOpen}
        open={open}
        width="fixed"
      >
        <Styled.Container>
          <Styled.ScrollContainer>
            <NotificationHeader
              defaultNotificationType={defaultNotificationType}
              hasEmailConfig={hasEmailConfig}
              hasNotification={hasNotification}
              isManager={isManager}
              isOwner={isOwner}
              isSubscribed={isSubscribed}
              onClickSubscribe={onClickSubscribe}
              setDeleteDialogMode={setDeleteDialogMode}
            />

            <Text fontSize="xxxs" fontWeight="regular">
              {t(
                'birdieWillSendYouASummaryFeaturingTheMostSignificantEventsThatOccurredAtTheScheduledTime'
              )}
            </Text>

            <NotificationForm
              channels={channels}
              emails={emails}
              isManager={isManager}
              isOwner={isOwner}
              isSlackConnected={isSlackConnected}
              name={name}
              onChangeSendToEmail={onChangeSendToEmail}
              onChangeSendToSlack={onChangeSendToSlack}
              schedule={schedule}
              sendToEmail={sendToEmail}
              sendToSlack={sendToSlack}
              setChannels={setChannels}
              setEmails={setEmails}
              setName={setName}
              setSchedule={setSchedule}
              slackChannels={slackChannels}
            />

            {(!isManager || !isOwner) && (
              <Text color="feedbackNegativePure" fontSize="xxxs">
                {isSubscribed
                  ? t('youCantEditThisNotification')
                  : t('youCanOnlySubscribeToThisNotification')}
              </Text>
            )}
          </Styled.ScrollContainer>
          {isManager && isOwner && (
            <>
              <Divider line="solid" orientation="horizontal" />

              <Button
                disabled={isApplyDisabled}
                fullWidth
                onClick={() => onApply()}
                size="small"
                small
              >
                {getApplyButtonText()}
              </Button>
            </>
          )}
        </Styled.Container>

        {deleteDialogMode !== null && (
          <DeleteNotificationModal
            deleteMode={deleteDialogMode}
            isDeleting={isRemoveLoading}
            notification={currentAlert}
            onConfirmDelete={onDelete}
            onOpenChange={onOpenDeleteDialogChange}
            open={deleteDialogMode !== null}
          />
        )}
      </Dialog>
    </>
  )
}

export default AreaNotificationModal
