import FlexContainer from '@/components/atoms/flex-container'
import { navigationBarWidth, styled, topBarHeight } from '@/theme'

export const HeaderContainer = styled('header', {
  display: 'flex',
  // alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$xxs',
  px: '$sm',
  py: '$xxs',

  position: 'sticky',
  top: topBarHeight,

  width: `calc(100vw - ${navigationBarWidth})`,
  bc: 'white',
  zIndex: 2,
  bBottom: '$neutralHighPure',

  fontSize: '$xxxs',
  fontWeight: '$regular',

  variants: {
    opportunityPlan: {
      true: {
        px: '$xs',
        bc: '$brandPrimaryLight'
      }
    }
  }
})

export const FiltersContainer = styled(FlexContainer, {
  gap: '$xxxs',
  flexGrow: 1
})
