import { ActionBar } from '@/components/atoms/action-bar'
import Button from '@/components/atoms/button'
import Text from '@/components/atoms/text'
import { Folder, X } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'

interface Props {
  filterIds: string[]
  onSave: () => void
  onCancel: () => void
}

const SaveCollectionBar = ({ filterIds, onSave, onCancel }: Props) => {
  const { t } = useTranslation()
  if (filterIds.length === 0) {
    return null
  }

  return (
    <ActionBar css={{ width: 'calc(100vw - 120px)' }}>
      <Text as="p" color="neutralHighLight" onClick={onCancel} typeface="textSemiboldSmall">
        {filterIds.length} selected
        <X size={16} weight="bold" />
      </Text>
      <Button onClick={onSave} size="small" variant="exception">
        <Folder size={16} weight="bold" />
        <span>{t('saveCollection')}</span>
      </Button>
    </ActionBar>
  )
}

export default SaveCollectionBar
