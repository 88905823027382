import { mapPropertyToLabel, REMOVED_FILTERS } from '@/components/molecules/filters/filterConsts'
import FiltersService from '@/services/FiltersService'
import { FilterFeedbackTypeItem, FilterItem } from '@/types/filters/Filters'
import {
  CUSTOM_FIELDS_TYPES,
  FEEDBACK_DATA_TYPE_KEY,
  FEEDBACK_DETAILS_TYPES
} from '@/utils/filters'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import useLetterCase from '../useLetterCase'

interface UseFiltersConfig {
  refetch?: boolean
}

const defaultConfig: UseFiltersConfig = {
  refetch: true
}

const useFiltersQuery = ({ refetch = true }: UseFiltersConfig = defaultConfig) => {
  const { snakeToTitle } = useLetterCase()

  const { data, isLoading } = useQuery({
    queryKey: ['filters'],
    queryFn: FiltersService.list,
    refetchOnMount: refetch
  })

  const allFilters: FilterItem[] = useMemo(() => {
    if (!data?.all_filters) return []

    const allFiltersUnique = [
      ...new Map(data.all_filters.map(filter => [JSON.stringify(filter), filter])).values()
    ]
    return allFiltersUnique
      .filter(filter => !REMOVED_FILTERS.includes(filter.name))
      .map(filter => ({
        key: filter.key,
        type: filter.type,
        displayName: mapPropertyToLabel[filter.name] || snakeToTitle(filter.name),
        name: filter.name
      }))
  }, [data, snakeToTitle])

  const feedbackDetailsFilters = useMemo(
    () => allFilters.filter(filter => FEEDBACK_DETAILS_TYPES.includes(filter.type)),
    [allFilters]
  )

  const customFieldsFilters = useMemo(
    () => allFilters.filter(filter => CUSTOM_FIELDS_TYPES.includes(filter.type)),
    [allFilters]
  )

  const feedbackTypes: FilterFeedbackTypeItem[] = useMemo(
    () =>
      data?.feedback_type.map(feedbackType => ({
        feedbackSourceIds: feedbackType.feedback_source_ids,
        value: feedbackType.text.toLowerCase(),
        text: feedbackType.text
      })) ?? [],
    [data]
  )

  const feedbackDataType: FilterItem | undefined = useMemo(() => {
    if (!data?.all_filters) return undefined

    const dataType = data.all_filters.find(filter => filter.key === FEEDBACK_DATA_TYPE_KEY)
    return dataType
      ? {
          key: dataType.key,
          type: dataType.type,
          displayName: mapPropertyToLabel[dataType.name] || snakeToTitle(dataType.name),
          name: dataType.name
        }
      : undefined
  }, [data, snakeToTitle])

  return {
    allFilters,
    feedbackDetailsFilters,
    customFieldsFilters,
    feedbackTypes,
    feedbackDataType,
    isLoading
  }
}

export default useFiltersQuery
