import { StepContentTransiton } from '@/components/atoms/step/Step.styles'
import { ReactNode } from 'react'

interface StepContentProps {
  status: 'preEnter' | 'entering' | 'entered' | 'preExit' | 'exiting' | 'exited' | 'unmounted'
  isMounted: boolean
  children: ReactNode
}

const StepContent = ({ isMounted, status, children }: StepContentProps) => {
  if (!isMounted) return <></>

  return <StepContentTransiton transitionStatus={status}>{children}</StepContentTransiton>
}

export default StepContent
