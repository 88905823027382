import FlexContainer from '@/components/atoms/flex-container'
import Loader from '@/components/atoms/loader/Loader'
import useAccounts from '@/hooks/useAccounts'
import { useInfiniteQuery } from '@tanstack/react-query'
import EmptyAccounts from './EmptyAccounts'
import AccountList from './AccountsList'
import useToastMessageStore from '@/store/useToastMessageStore'
import { useMemo, useState } from 'react'
import ImportButton from '../ImportButton'
import ManageCustomersHeader from '../ManageCustomersHeader'
import Text from '@/components/atoms/text'
import AccountSearch from './AccountsSearch'
import { AccountData } from '@/types/manage-customers'
import { DeleteDialog } from '@/components/atoms/dialog'
import useLogging from '@/hooks/useLogging'
import { useTranslation } from 'react-i18next'

const Accounts = () => {
  const { loadAccounts, deleteAccount } = useAccounts()
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const { t } = useTranslation()

  const { logException } = useLogging({ context: 'accounts' })

  const [isWaiting, setIsWaiting] = useState(false)

  const [searchQuery, setSearchQuery] = useState('')
  const [accountToDelete, setAccountToDelete] = useState<AccountData | undefined>()

  const {
    data,
    isLoading,
    isRefetching,
    isError,
    isSuccess,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  } = useInfiniteQuery({
    queryKey: ['customer-accounts', { q: searchQuery }],
    queryFn: loadAccounts,
    getNextPageParam: lastPage => lastPage?.nextPage,
    retry: false
  })

  const accounts = useMemo(() => {
    if (!data) return []
    return data.pages.flatMap(page => page?.accounts || [])
  }, [data])

  const isSearching = searchQuery.length > 0

  const showLoader = (isLoading || isRefetching || isWaiting) && !isSearching
  const showEmptyAccounts =
    !showLoader && (isError || (isSuccess && !accounts.length && !isSearching))
  const isLoadingSearch = isLoading && isSearching

  const showAccounts =
    !showLoader && !isError && (isSearching || (isSuccess && accounts.length > 0))

  const loadMore = () => {
    !isFetching && !isFetchingNextPage && fetchNextPage()
  }

  const onImport = (result: boolean) => {
    if (result) {
      setIsWaiting(true)
      addSuccessToast({ text: t('importCompletedRefetching') })

      setTimeout(() => {
        setIsWaiting(false)
        refetch()
      }, 5000)
    } else {
      const message = t('failedToImport')
      logException(new Error(message), { message })
      addErrorToast({ text: message })
    }
  }

  const onDeleteAccount = async () => {
    if (!accountToDelete) return

    await deleteAccount(accountToDelete.id)
    setAccountToDelete(undefined)
  }

  const onOpenDeleteChange = (open: boolean) => {
    setAccountToDelete(prevAccount => (open ? prevAccount : undefined))
  }

  return (
    <FlexContainer css={{ overflow: 'hidden', flex: 1, maxWidth: 800 }} direction="column">
      <ManageCustomersHeader
        css={{ mb: '$xs' }}
        rightContent={
          !showEmptyAccounts && !showLoader ? (
            <ImportButton importSheet="accounts" onImport={onImport} />
          ) : undefined
        }
        title={t('accounts')}
      />

      {showLoader && (
        <FlexContainer alignItems="center" justifyContent="center">
          <Loader />
        </FlexContainer>
      )}

      {showEmptyAccounts && <EmptyAccounts onImport={onImport} />}

      {showAccounts && (
        <FlexContainer css={{ overflow: 'hidden' }} direction="column" gap="xxs">
          <Text
            as="p"
            color="neutralLowPure"
            css={{ mb: '$xs' }}
            fontSize="micro"
            fontWeight="regular"
            lineHeight="xxl"
          >
            {t(
              'thisIsYourCurrentListOfAccountsEveryIncomingFeedbackThatMatchesAUserCanNowBeFilteredBasedOnUsersOrUserGroupsMakingItEasierForYouToGetAdditionalContextAndCloseYourFeedbackLoop'
            )}
          </Text>

          <AccountSearch
            count={accounts.length}
            currentSearch={searchQuery}
            saveSearch={setSearchQuery}
            total={data?.pages[0]?.total || 0}
          />

          <AccountList
            accountList={accounts}
            hasMore={hasNextPage}
            isFetchingPage={isFetching || isFetchingNextPage}
            isLoadingSearch={isLoadingSearch}
            isSearching={isSearching}
            loadMore={loadMore}
            onClickDelete={setAccountToDelete}
          />
        </FlexContainer>
      )}

      {accountToDelete && (
        <DeleteDialog
          isDeleting={false}
          onConfirmDelete={onDeleteAccount}
          onOpenChange={onOpenDeleteChange}
          open={!!accountToDelete}
        />
      )}
    </FlexContainer>
  )
}

export default Accounts
