import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import useUser from '@/hooks/useUser'
import Redirect from '@/components/molecules/redirect'
import { APP_ENVIRONEMNT, INTERCOM_ID } from '@/config'
import { UserAccess } from '@/types/auth'
import OrganizationService from '@/services/OrganizationService'
import { usePersistedCollectionStore } from '@/store/useCollectionStore'
import CollectionService from '@/services/CollectionService'
import * as Sentry from '@sentry/react'

function RedirectPage() {
  const {
    setToken,
    getUserFromToken,
    checkFirstAccess,
    currentUserPlan,
    setCurrentUserPlan,
    organization,
    setOrganization
  } = useUser()

  const { isAuthenticated, user } = useAuth0()
  const { hasCollections, setHasCollections } = usePersistedCollectionStore()

  const navigate = useNavigate()
  const loc = useLocation()

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    const redirectWithToken = async () => {
      const params = new URLSearchParams(loc.search)
      const errorParam = params.get('error')

      if (errorParam === 'access_denied') {
        const errorMessage = params.get('error_description')
        navigate('/access-denied', { state: { errorMessage }, replace: true })
        return
      }

      if (errorParam === 'unauthorized') {
        navigate('/')
      }

      if (!user?.email) {
        return
      }

      let completeUser: UserAccess | undefined
      let isFirstAccess = false
      const userFromToken = getUserFromToken()
      if (userFromToken && userFromToken.email === user.email) {
        completeUser = userFromToken
      } else {
        isFirstAccess = await checkFirstAccess()
        completeUser = await setToken(isFirstAccess)
      }

      if (user) {
        if (completeUser) {
          const organizationName = completeUser.email.split('@')?.[1]?.split('.')?.[0] ?? ''

          if (!organization) {
            const [_, organizationData] = await OrganizationService.getOrganization(
              completeUser.organization_id
            )
            if (organizationData) {
              setOrganization(organizationData)
            }
          }

          if (APP_ENVIRONEMNT === 'production') {
            window.Intercom('boot', {
              api_base: 'https://api-iam.intercom.io',
              app_id: INTERCOM_ID,
              user_id: completeUser.user_id,
              name: user.name,
              email: user.email
            })

            const analytics = window.analytics
            if (analytics) {
              analytics.identify(completeUser.user_id, {
                name: completeUser.name,
                email: completeUser.email,
                organization_id: completeUser.organization_id,
                organization_name: organizationName
              })
            }

            Sentry.withScope(scope => {
              scope.setUser({
                id: completeUser.user_id,
                email: completeUser.email
              })
            })
          }

          if (APP_ENVIRONEMNT === 'production' || APP_ENVIRONEMNT === 'staging') {
            const pendo = window.pendo
            if (pendo) {
              pendo.initialize({
                visitor: {
                  id: completeUser.user_id,
                  email: completeUser.email,
                  name: completeUser.name
                },
                account: {
                  id: completeUser.organization_id,
                  name: organizationName
                }
              })
            }
          }
        }

        let defaultPath = '/home'

        if (currentUserPlan === null) {
          if (
            completeUser?.permissions.includes('areas:view') ||
            completeUser?.permissions.includes('areas:manager')
          ) {
            setCurrentUserPlan('opportunity')
          } else if (completeUser?.permissions.includes('topic:view')) {
            defaultPath = '/analytics'
            setCurrentUserPlan('topic')
          }
        } else if (currentUserPlan === 'topic') {
          defaultPath = '/analytics'
        }

        let from = params.get('from')
        params.delete('from')
        params.delete('code')
        params.delete('state')

        const notificationId = params.get('id')

        if (from === '/') {
          from = defaultPath
        }

        // its a opportunity plan notification
        if (from === '/feedback' && notificationId) {
          from = '/exploration'
          params.delete('id')
          params.append('notificationId', notificationId)
        }

        if (from === '/home') {
          if (!isFirstAccess && hasCollections === null) {
            const [_, response] = await CollectionService.search({
              name: '',
              limit: 1
            })

            if (response) {
              const _hasCollections = response?.length > 0
              setHasCollections(_hasCollections)

              if (!_hasCollections) {
                from = '/areas-and-opportunities'
              }
            }
          } else if (!hasCollections) {
            from = '/areas-and-opportunities'
          }
        }

        isFirstAccess
          ? navigate('/signup/questions')
          : navigate({ pathname: from || defaultPath, search: params.toString() })
      }
    }

    redirectWithToken()
  }, [isAuthenticated, user, navigate, currentUserPlan])

  return <Redirect />
}

export default RedirectPage
