import Toast, { ToastProvider, ToastViewport } from '@/components/atoms/toast'
import useToastMessageStore, { ToastMessage } from '@/store/useToastMessageStore'
import { useCallback } from 'react'
import { shallow } from 'zustand/shallow'

const DEFAULT_DURATION = 2500

const ToastMessagesProvider = () => {
  const toasts = useToastMessageStore(state => state.toasts, shallow)
  const safeAreaOn = useToastMessageStore(state => state.safeAreaOn, shallow)
  const onOpenChange = useToastMessageStore(state => state.onOpenChange)

  const handleChange = useCallback(
    (toast: ToastMessage) => (open: boolean) => {
      if (toast.onClose && !open) {
        toast.onClose()
      }
      if (toast.id) {
        onOpenChange(open, toast.id)
      }
    },
    [onOpenChange]
  )

  return (
    <ToastProvider duration={DEFAULT_DURATION} swipeDirection="down">
      {toasts.map((toast, index) => (
        <Toast
          duration={toast.duration || DEFAULT_DURATION}
          key={(toast.id ?? toast.text) + 't' + index}
          onOpenChange={handleChange(toast)}
          open={toast.open}
          status={toast.status ?? 'default'}
        >
          {toast.children ?? toast.text}
        </Toast>
      ))}
      <ToastViewport safeArea={safeAreaOn} />
    </ToastProvider>
  )
}

export default ToastMessagesProvider
