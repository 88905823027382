import FeedbackService from '@/services/FeedbackService'
import { FeedbackListParams, NewFeedback } from '@/types/feedbacks/Feedback'
import { QueryFunctionContext, useInfiniteQuery } from '@tanstack/react-query'
import { useCallback, useMemo, useRef, useState } from 'react'
import useFeedQueryParams from './useFeedQueryParams'
import useRemoveOpportunityFeedbackStore from '@/store/useRemoveOpportunityFeedbackStore'
import { SourceDistributionItem } from '@/types/analytics/Sources'
import useAdvancedFiltersStore from '@/store/useFiltersStore/useAdvancedFiltersStore'
import { shallow } from 'zustand/shallow'
import useOpportunityStore from '@/store/useOpportunityStore'

const useFeedbackSearch = () => {
  const isFetchingContext = useAdvancedFiltersStore(state => state.isFetchingContext)

  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity, shallow)

  const { queryParams } = useFeedQueryParams()

  const setRemovingFeedbacks = useRemoveOpportunityFeedbackStore(
    state => state.setRemovingFeedbacks
  )

  const [currentSource, setCurrentSource] = useState<SourceDistributionItem[]>([])

  const controllerRef = useRef<AbortController>()

  function cancel() {
    controllerRef.current?.abort()
  }

  const queryFn = useCallback(
    async function (
      params: QueryFunctionContext<
        [string, FeedbackListParams, SourceDistributionItem[], string | undefined],
        string
      >
    ) {
      const { pageParam, queryKey, signal } = params

      const currentCursor = pageParam
      const filterParams = queryKey[1]
      const sources = queryKey[2]

      const feedbackListParams: FeedbackListParams = { ...filterParams }

      const [error, response] = await FeedbackService.getFeedbackList(
        {
          ...feedbackListParams,
          kind: sources.length ? sources.map(source => source.key) : filterParams.kind,
          include: ['stats'],
          sort: '-posted_at',
          cursor: currentCursor
        },
        { signal }
      )

      if (error) {
        console.error(error)
        throw error
      }

      setTimeout(() => {
        setRemovingFeedbacks([])
      }, 1000)

      return response
    },
    [setRemovingFeedbacks]
  )

  const { data, isLoading, hasNextPage, error, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['feedback-search', queryParams, currentSource, currentOpportunity?.id],
      queryFn,
      getNextPageParam: lastPage => lastPage?.nextPage
    })

  const feedbackList = useMemo(() => {
    if (!data) return []

    return data.pages.flatMap(page => page.feedbacks)
  }, [data])

  const [selectedFeedback, setSelectedFeedback] = useState<NewFeedback | null>(null)
  const [feedbackToMove, setFeedbackToMove] = useState<NewFeedback | null>(null)

  return {
    feedbackList,
    isLoading: isLoading || isFetchingContext,
    error,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    cancel,
    selectedFeedback,
    setSelectedFeedback,
    currentSource,
    setCurrentSource,
    feedbackToMove,
    setFeedbackToMove
  }
}

export default useFeedbackSearch
