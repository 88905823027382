import { useCallback } from 'react'

import useSegment from '@/hooks/useSegment'
import useToastMessageStore from '@/store/useToastMessageStore'
import { useFiltersStore, useKeywordsStore } from '@/store'
import useTopicsStore from '@/store/useTopicsStore'
import { useSearchParams } from 'react-router-dom'
import { FILTER_QUERY_PARAM, SAVED_FILTER_NAME_QUERY_PARAM } from '@/utils/filters'
import { useFeedbacks } from '../feedback/useFeedbacks'
import useLogging from '../useLogging'

const useFilters = () => {
  const { track } = useSegment()

  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { logException } = useLogging({ context: 'filters' })

  const resetKeywords = useKeywordsStore(state => state.reset)
  const resetTopics = useTopicsStore(state => state.reset)
  const { resetFeedbackForAllStores } = useFeedbacks({ archived: false, pageSize: 0 })

  const setFiltersByURL = useFiltersStore(state => state.setFiltersByURL)

  const reloadData = useCallback(() => {
    resetTopics()
    resetFeedbackForAllStores()
    resetKeywords()
  }, [resetFeedbackForAllStores, resetKeywords, resetTopics])

  const applyAndReload = (callback?: () => void) => {
    track('explore_user_filter_apply')
    try {
      callback?.()
      reloadData()
      addSuccessToast({ text: 'Filters successfully applied.' })
    } catch (error) {
      console.error(error)
      const message = 'Failed to apply filters.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  }

  const removeAndReload = useCallback(
    (callback?: () => void) => {
      try {
        callback?.()
        reloadData()
        addSuccessToast({ text: 'Filters successfully removed.' })
      } catch (error) {
        const message = 'Failed to remove filters.'
        logException(error, { message })
        addErrorToast({ text: message })
      }
    },
    [addErrorToast, addSuccessToast, logException, reloadData]
  )

  const [searchParams, setSearchParams] = useSearchParams()
  const clearFiltersByURL = () => {
    searchParams.delete(FILTER_QUERY_PARAM)
    searchParams.delete(SAVED_FILTER_NAME_QUERY_PARAM)
    setSearchParams(searchParams)
    setFiltersByURL(null)
    reloadData()
  }

  return {
    reloadData,
    applyAndReload,
    removeAndReload,
    clearFiltersByURL
  }
}

export default useFilters
