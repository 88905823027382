import ErrorBoundary from '@/components/atoms/error-boundary'
import useLogging from '@/hooks/useLogging'
import { useTranslation } from 'react-i18next'
import FlexContainer from '@/components/atoms/flex-container'
import OrganizationDetails from './OrganizationDetails'
import Text from '@/components/atoms/text'
import PotentialSavings from './PotentialSavings'
import { useRef, useState } from 'react'
import useUser from '@/hooks/useUser'
import useToastMessageStore from '@/store/useToastMessageStore'
import { OrganizationConfigKeys } from '@/types/organization/Organization'
import Button from '@/components/atoms/button'
import OrganizationService from '@/services/OrganizationService'
import ContactRate from './ContactRate'
import useActiveUsersByDate, { getActiveUsersByDateConfig } from './useActiveUsersByDate'
import { useBlocker } from 'react-router-dom'
import { DeleteDialog } from '@/components/atoms/dialog'
import useUnsavedChangesWarning from '@/hooks/usePreventPageUnload'
import useSegment from '@/hooks/useSegment'
import { SegmentPropertiesDict } from '@/types/segment'
import { makeUniqueArray } from '@/utils/array'

const OrganizationSettings = () => {
  const { logException } = useLogging({ context: 'OrganizationSettings' })

  const { t } = useTranslation()

  const { organization, setOrganization } = useUser()

  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)

  const [configKeysChanged, setConfigKeysChanged] = useState<OrganizationConfigKeys[]>([])

  const { track } = useSegment()

  const addChangedKey = (key: OrganizationConfigKeys) => {
    setConfigKeysChanged(prevKeys => (prevKeys.includes(key) ? prevKeys : [...prevKeys, key]))
  }

  const removeChangedKey = (key: OrganizationConfigKeys) => {
    setConfigKeysChanged(prevKeys => prevKeys.filter(prevKey => prevKey !== key))
  }

  const [currency, setCurrency] = useState(() => {
    if (!organization) return 'US$'

    return organization.config?.ticketCostCurrency ?? 'US$'
  })

  const [ticketCost, setTicketCost] = useState(() => {
    if (!organization) return ''

    return organization.config?.ticketCost ?? ''
  })

  const orgActiveUsersRef = useRef(getActiveUsersByDateConfig(organization))

  const {
    activeUsersToAdd,
    allActiveUsersList,
    hasChanges: hasAllUsersToAddChanges,
    hasUsersToAddHasChanges,
    hasEmptyValue,
    saveActiveUsers,
    onChangeActiveUsersToAdd,
    onChangeAllActiveUsers
  } = useActiveUsersByDate({ organization, orgActiveUsersRef })

  const onChangeCurrency = (value: string) => {
    setCurrency(value)

    const configKey: OrganizationConfigKeys = 'ticketCostCurrency'
    if (!organization?.config?.ticketCostCurrency) {
      addChangedKey(configKey)
    } else if (organization.config.ticketCostCurrency === value) {
      removeChangedKey(configKey)
    } else {
      addChangedKey(configKey)
    }
  }

  const onChangeTicketCost = (value: string) => {
    setTicketCost(value)

    const configKey: OrganizationConfigKeys = 'ticketCost'
    if (!organization?.config?.ticketCost) {
      addChangedKey(configKey)
    } else if (organization.config?.ticketCost === value) {
      removeChangedKey(configKey)
    } else {
      addChangedKey(configKey)
    }
  }

  const hasChanges = configKeysChanged.length > 0

  const trackChanges = (hasActiveUsersError?: boolean) => {
    const settingsChanges: SegmentPropertiesDict = {}
    if (configKeysChanged.includes('ticketCost')) {
      settingsChanges.ticket_cost = ticketCost
    }

    if (configKeysChanged.includes('ticketCostCurrency')) {
      settingsChanges.ticket_cost_currency = ticketCost
    }

    if ((hasAllUsersToAddChanges || hasUsersToAddHasChanges) && !hasActiveUsersError) {
      const filteredActiveUsersByDate = makeUniqueArray(
        'key',
        [...activeUsersToAdd, ...allActiveUsersList].filter(item => item.key !== null)
      )
      settingsChanges.active_users_date_value = filteredActiveUsersByDate.map(
        activeUsersDate => `${activeUsersDate.key}:${activeUsersDate.value}`
      )
    }

    track('settings_edited', settingsChanges)
  }

  const hasAnyUnsavedChanges = hasChanges || hasAllUsersToAddChanges || hasUsersToAddHasChanges

  const save = async () => {
    if (!organization) return

    if (!hasAnyUnsavedChanges) return

    const removeLoadingToast = addLoadingToast({ text: 'Saving...' })

    let hasError = false
    if (configKeysChanged.includes('ticketCost')) {
      const [error] = await OrganizationService.patchOrganizationConfig('ticketCost', ticketCost)
      if (error) {
        logException(error, { message: `Error while patching org config "ticket_cost"` })
        hasError = true
      }
    }

    if (configKeysChanged.includes('ticketCostCurrency')) {
      const [error] = await OrganizationService.patchOrganizationConfig(
        'ticketCostCurrency',
        currency
      )
      if (error) {
        logException(error, { message: `Error while patching org config "ticket_cost_currency"` })
        hasError = true
      }
    }

    const hasActiveUsersError = await saveActiveUsers()

    const [_, organizationData] = await OrganizationService.getOrganization(
      organization.organizationId
    )

    removeLoadingToast()
    if (hasError) {
      addErrorToast({ text: t('failedToSaveOrgSettingsGenericError') })
    }

    if (organizationData && !hasError) {
      setOrganization(organizationData)
      setTicketCost(organizationData.config?.ticketCost ?? '')
      setCurrency(organizationData.config?.ticketCostCurrency ?? 'US$')

      if (!hasActiveUsersError) {
        onChangeAllActiveUsers(getActiveUsersByDateConfig(organizationData))
        orgActiveUsersRef.current = getActiveUsersByDateConfig(organizationData)
      }

      trackChanges(hasActiveUsersError)
    }

    if (!hasError) {
      setConfigKeysChanged([])
      addSuccessToast({
        text: t('saveOrgSettingsSuccessMessage'),
        duration: 5000
      })
    }
  }

  // for internal navigation (links, buttons, tabs)
  const blocker = useBlocker(hasAnyUnsavedChanges)

  // for browser navigation (back button, close tab, refresh page...)
  useUnsavedChangesWarning({ preventPageUnload: hasAnyUnsavedChanges })

  return (
    <ErrorBoundary>
      <FlexContainer direction="column" gap="sm">
        <FlexContainer justifyContent="spaceBetween" alignItems="center">
          <Text as="h2" fontSize="xs" fontWeight="bold">
            {t('organizationSettingsLabel')}
          </Text>
        </FlexContainer>

        <FlexContainer
          direction="column"
          gap="sm"
          css={{ maxHeight: 'calc(100vh - 335px)', overflowX: 'auto', pr: '$nano' }}
        >
          <OrganizationDetails />

          <PotentialSavings
            currency={currency}
            setCurrency={onChangeCurrency}
            ticketCost={ticketCost}
            setTicketCost={onChangeTicketCost}
          />

          <ContactRate
            activeUsersToAdd={activeUsersToAdd}
            allActiveUsersByDateList={allActiveUsersList}
            onChangeAllActiveUsers={onChangeAllActiveUsers}
            onChangeUsersToAdd={onChangeActiveUsersToAdd}
          />
        </FlexContainer>
        <FlexContainer justifyContent="flexEnd" alignItems="center">
          {hasAnyUnsavedChanges && (
            <Button
              onClick={save}
              disabled={hasEmptyValue}
              title={hasEmptyValue ? t('activeUsersHistoryHasEmptyValues') : undefined}
            >
              {t('save')}
            </Button>
          )}
        </FlexContainer>
      </FlexContainer>
      <DeleteDialog
        isDeleting={false}
        onConfirmDelete={() => blocker.proceed?.()}
        onOpenChange={open => !open && blocker.reset?.()}
        confirmText="Leave page"
        cancelText="Cancel"
        title={t('youHaveUnsavedChanges')}
        description={
          <>
            <Text as="p" color="neutralLowMedium" fontSize="xxxs">
              {t('areYouSureWantToLeaveThisPage')}
            </Text>
            <Text as="p" color="neutralLowMedium" fontSize="xxxs">
              {t('changesYouMadeWillNotBeSaved')}
            </Text>
          </>
        }
        open={blocker.state === 'blocked'}
      />
    </ErrorBoundary>
  )
}

export default OrganizationSettings
