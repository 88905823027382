import { useRef } from 'react'

const useEvents = <T>() => {
  const callbackRef = useRef<Record<string, EventListener>>({})

  function on(event: string, callback: (data: T) => void) {
    if (!callbackRef.current[event]) {
      function receivedCallback(e: Event) {
        callback((e as CustomEvent).detail as T)
      }
      callbackRef.current[event] = receivedCallback
    }

    document.addEventListener(event, callbackRef.current[event])
  }

  function dispatch(event: string, data: T) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }))
  }

  function removeListener(event: string) {
    callbackRef.current[event] && document.removeEventListener(event, callbackRef.current[event])
  }

  return { on, dispatch, removeListener }
}

export default useEvents
