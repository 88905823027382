import AudienceService from '@/services/AudienceService'
import { useQuery } from '@tanstack/react-query'

interface Params {
  filterKey: string
  enabled: boolean
}

const useCustomerRecordsFilterQuery = {
  accounts: ({ filterKey, enabled }: Params) =>
    useQuery({
      queryKey: ['filters-accounts-values', filterKey],
      queryFn: async params =>
        AudienceService.getAccountsFiltersValues({ field: filterKey, ...params }),
      enabled,
      refetchOnMount: false,
      retry: false
    }),
  customerUsers: ({ filterKey, enabled }: Params) =>
    useQuery({
      queryKey: ['filters-customer-users-values', filterKey],
      queryFn: async params =>
        AudienceService.getCustomerUsersFiltersValues({ field: filterKey, ...params }),
      enabled,
      refetchOnMount: false,
      retry: false
    })
}

export default useCustomerRecordsFilterQuery
