import { CSS, styled } from '@/theme'
import NavigationItem, { NavigationItemProps } from './NavigationItem'
import { navigationItemStyle, opportunityNavigationItemStyle } from './NavigationItem.styles'

const StyledNavigationItemButton = styled('button', {
  ...navigationItemStyle,
  variants: {
    home: {
      true: {
        color: '$brandPrimaryMedium'
      },
      false: {}
    },
    opportunityPlan: {
      true: {
        ...opportunityNavigationItemStyle
      },
      false: {}
    }
  },

  defaultVariants: {
    home: false
  }
})

interface NavigationItemButtonProps extends NavigationItemProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  css?: CSS
}

function NavigationItemButton({
  onClick,
  title,
  children,
  disabled,
  css,
  home,
  opportunityPlan
}: NavigationItemButtonProps) {
  return (
    <StyledNavigationItemButton
      css={css}
      disabled={disabled}
      home={home}
      onClick={onClick}
      opportunityPlan={opportunityPlan}
    >
      <NavigationItem title={title}>{children}</NavigationItem>
    </StyledNavigationItemButton>
  )
}

export default NavigationItemButton
