import { styled, typefaceVariants } from '@/theme'

export const ChipContainer = styled('span', {
  color: '$pajarito',
  width: 'fit-content',
  display: 'flex',
  flexGrow: 0,
  alignItems: 'center',
  borderRadius: 16,
  padding: '4px 8px',
  background: '$greenBackground',
  bAll: '$greenBackground',
  userSelect: 'none',
  transition: '.2s',
  gap: 2,
  cursor: 'pointer',

  '&:hover': {
    bAll: '$pajarito',
    background: '$white'
  },

  variants: {
    alwaysShowHandle: {
      true: {
        bAll: '$pajarito',
        background: '$white'
      },
      false: {}
    },

    isCluster: {
      true: {
        ...typefaceVariants.textSemiboldXSmall,
        color: '$neutralLowPure',
        border: 'none',
        background: 'transparent',

        '&:hover': {
          border: 'none',
          background: '$greyLight'
        }
      },
      false: {
        ...typefaceVariants.textRegularXSmall,
        fontWeight: 'normal'
      }
    },

    isClusterClickable: {
      true: {
        cursor: 'pointer',
        userSelect: 'auto',
        '&:hover': {
          background: '$stroke'
        }
      }
    }
  }
})
