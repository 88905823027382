import DefaultErrorHandler from '@/services/DefaultError'
import OpportunityService from '@/services/OpportunityService'
import { RequestReturnType } from '@/services/RequestHandlers/NewRequest'
import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import { RawAreaError } from '@/types/area/AreaRequests'
import { CollectionType } from '@/types/collection'
import { OpportunityRequests } from '@/types/opportunity'
import {
  OpportunityItemWithMergedContext,
  OpportunityItemWithMetrics,
  OpportunityStatus
} from '@/types/opportunity/Opportunity'
import { stringToDate } from '@/utils/date'
import { getMergedAreasContexts } from '@/utils/opportunityQueryUtils'

interface Params {
  searchText: string
  currentAreas: BaseInterestArea[]
  onError: (error: DefaultErrorHandler) => void
  limit?: number
  currentCollectionType?: CollectionType
  currentCollectionId?: string
  errorMap: Record<string, RawAreaError>
}

const getOpportunitiesQueryFn = async ({
  searchText,
  currentCollectionId,
  currentAreas,
  limit = 1000,
  errorMap
}: Params): Promise<RequestReturnType<OpportunityItemWithMergedContext[]>> => {
  const searchParams: OpportunityRequests.SearchOpportunityParams = {
    limit,
    name: searchText
  }

  if (currentCollectionId) {
    searchParams.collection_id = currentCollectionId
  }

  const [error, response] = await OpportunityService.searchOpportunities(searchParams)

  if (error) {
    return [error, undefined]
  }

  const data = response.opportunities
    .map(
      (filter): OpportunityItemWithMetrics => ({
        name: filter.name,
        id: filter.opportunity_id,
        status: filter.opportunity_status_id,
        createdAt: stringToDate(filter.created_at),
        metrics: [],
        new: filter.new ?? false,
        opportunityCount: 0,
        filterId: filter.filter_id,
        description: filter.description ?? '',
        createdBy: filter.created_by,
        relations: filter.areas,
        initiatives: filter.initiatives,
        area:
          filter.areas.length === 1
            ? currentAreas.find(area => area.id === filter.areas[0])
            : undefined,
        pinnedFeedback: filter.pinned_feedback ?? [],
        internal_review: filter.internal_review
      })
    )
    .filter(opp => opp.status !== OpportunityStatus.Dismissed)

  const [contextError, contextsData] = await getMergedAreasContexts(data, errorMap)
  if (contextError) {
    return [contextError, undefined]
  }

  const withMergedContext = data.map((opp, index) => {
    return { ...opp, mergedContext: contextsData[index] } as OpportunityItemWithMergedContext
  })

  return [undefined, withMergedContext]
}

export default getOpportunitiesQueryFn
