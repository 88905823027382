import useClassification from '@/hooks/useClassification'
import useDashboardState from '@/hooks/useDashboard/useDashboardState'
import useSavedFilters from '@/hooks/useSavedFilters'
import { useUIStore } from '@/store'
import { useCallback, useEffect, useState } from 'react'
import EmptyDashboard from './EmptyDashboard'
import FlexContainer from '@/components/atoms/flex-container'
import AddNewChart from './AddNewChart'
import Button from '@/components/atoms/button'
import DashboardChartCard from './DashboardChartCard'
import Tooltip from '@/components/atoms/tooltip'
import { styled } from '@/theme'
import useUser from '@/hooks/useUser'
import useFiltersQuery from '@/hooks/filters/useFiltersQuery'

const DisabledButtonWrapper = styled('div', {
  width: 'fit-content',
  ml: 'auto'
})

const ChartList = () => {
  const { charts, getSavedState, limitReached } = useDashboardState()
  const { fetchAll } = useSavedFilters()
  const { isLoading: isInitialFiltersLoading } = useFiltersQuery()
  const { verifyAllTopicAndThemes, isTopicsVerified } = useClassification()
  const setShowFullScreenLoader = useUIStore(state => state.setShowFullScreenLoader)

  const initialLoad = useCallback(async () => {
    try {
      await Promise.all([fetchAll(), verifyAllTopicAndThemes(), getSavedState()])
    } catch (e) {
      console.error(e)
    } finally {
      setShowFullScreenLoader(false)
    }
  }, [setShowFullScreenLoader, getSavedState, fetchAll, verifyAllTopicAndThemes])

  useEffect(() => {
    setShowFullScreenLoader(true)

    if (!isInitialFiltersLoading) {
      initialLoad()
    }
  }, [isInitialFiltersLoading, initialLoad, setShowFullScreenLoader])

  const [addNewChartOpen, setAddNewChartOpen] = useState(false)

  const onAddNewChartClick = () => {
    setAddNewChartOpen(true)
  }

  const { userPermissions } = useUser()
  const isManager = userPermissions.tracking.includes('manager')

  if (isInitialFiltersLoading || !isTopicsVerified) {
    return <></>
  }

  if (charts.length === 0) {
    return (
      <>
        <EmptyDashboard onAddNewChartClick={onAddNewChartClick} />
        <AddNewChart onOpenChange={setAddNewChartOpen} open={addNewChartOpen} />
      </>
    )
  }

  return (
    <FlexContainer css={{ width: '100%', padding: '$md' }} direction="column" gap="md">
      {charts.map(chart => (
        <DashboardChartCard key={chart.id} {...chart} />
      ))}

      {isManager &&
        (limitReached ? (
          <Tooltip side="bottom" text="Can't add more charts. Limit is 10 charts.">
            <DisabledButtonWrapper>
              <Button disabled>Add new chart</Button>
            </DisabledButtonWrapper>
          </Tooltip>
        ) : (
          <Button css={{ ml: 'auto' }} onClick={onAddNewChartClick} small>
            Add new chart
          </Button>
        ))}

      <AddNewChart onOpenChange={setAddNewChartOpen} open={addNewChartOpen} />
    </FlexContainer>
  )
}

export default ChartList
