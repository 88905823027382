import { useState } from 'react'

import useLetterCase from '@/hooks/useLetterCase'
import { FilterString } from '@/types/filters/Filters'
import FiltersSkeleton from '../../FiltersSidebar/AddFilterMenu/FiltersSkeleton'
import useStringGenericFilter from '../../FiltersSidebar/StringGenericFilter/useStringGenericFilterNew'
import { FilterItemDropdown } from '../../AppliedFilters/FilterItem'
import StringFilterContent from '../../FiltersSidebar/StringGenericFilter/StringFilterContentNew'

interface FilterItemDropdownStringProps {
  filter: FilterString
  buttonDisabled?: boolean
}

function FilterItemDropdownString({ filter, buttonDisabled }: FilterItemDropdownStringProps) {
  const { capitalizeFirst, snakeToTitle } = useLetterCase()

  const [enabled, setEnabled] = useState(false)

  const { isLoading, isChecked, selectOption, hasChanges, apply, options } = useStringGenericFilter(
    {
      enabled,
      filterKey: filter.key,
      name: filter.name
    }
  )

  function getTooltipText(stringFilter: FilterString) {
    const selectedOptions = stringFilter.selected
      .map(option => `<b>${capitalizeFirst(option)}</b>`)
      .join(' or ')

    return `Is <b>${snakeToTitle(stringFilter.name)}</b> > ${selectedOptions}`
  }

  return (
    <FilterItemDropdown
      buttonDisabled={buttonDisabled}
      content={filter.selected.length}
      contentType="count"
      disabled={!hasChanges}
      dropdown={
        isLoading ? (
          <FiltersSkeleton />
        ) : (
          <StringFilterContent
            isChecked={isChecked}
            options={options}
            selectOption={selectOption}
          />
        )
      }
      onApply={apply}
      onOpenChange={setEnabled}
      open={enabled}
      title={snakeToTitle(filter.name)}
      tooltip={getTooltipText(filter)}
    />
  )
}

export default FilterItemDropdownString
