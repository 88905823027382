import { DynamicTextItem, TextReplacementFormData } from '@/types/params/TextReplacementParams'
import { ParamsFormProps } from './GenericFeatureToggleForm'
import { ChangeEvent, useState } from 'react'
import Text from '@/components/atoms/text'
import { ParamsContainer, ParamsInputContainer } from './ParamsForms.styles'
import Input from '@/components/atoms/input'
import ParamsButtons from './ParamsButtons'
import FlexContainer from '@/components/atoms/flex-container'
import { CaretDoubleRight } from '@phosphor-icons/react'
import Button from '@/components/atoms/button'
import Switch from '@/components/atoms/switch'

interface TextReplacementItem {
  formKey: keyof TextReplacementFormData
  name: string
  description: string
}

const items: TextReplacementItem[] = [
  {
    formKey: 'companyName',
    name: 'Company name',
    description: 'Replace every occurrence of the company name with this value'
  },
  {
    formKey: 'userName',
    name: 'User name',
    description: 'Replace every occurrence of the user name with this value'
  },
  {
    formKey: 'email',
    name: 'Email',
    description: 'Replace every occurrence of the user email with this value'
  }
]

type Props = ParamsFormProps<TextReplacementFormData>

const TextReplacementForm = ({ data, onSave, onReset }: Props) => {
  const [formData, setFormData] = useState(data)

  const handleEnableChange = (value: boolean) => {
    setFormData(prev => ({ ...prev, enable: value }))
  }

  const handleChange =
    (key: keyof TextReplacementFormData) => (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value
      setFormData(prev => ({ ...prev, [key]: value.length > 0 ? value : null }))
    }

  const handleDynamicTextChange =
    (index: number, key: keyof DynamicTextItem) => (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value
      setFormData(prev => ({
        ...prev,
        dynamicText: prev.dynamicText.map((item, i) =>
          i === index ? { ...item, [key]: value } : item
        )
      }))
    }

  const addNewDynamicText = () => {
    setFormData(prev => ({
      ...prev,
      dynamicText: [...prev.dynamicText, { original: 'Original', replacement: 'Replacement' }]
    }))
  }

  return (
    <ParamsContainer css={{ ml: '$xxl', width: '100%', height: '100%', overflowY: 'auto' }}>
      <ParamsInputContainer direction="row">
        <Text as="label" htmlFor="enableTextReplacement" typeface="textBodySmallRegular">
          Enable
        </Text>
        <Switch checked={formData.enable as boolean} onCheckedChange={handleEnableChange} />
      </ParamsInputContainer>

      {items.map(({ formKey, name, description }) => (
        <ParamsInputContainer key={formKey}>
          <Text as="label" htmlFor={formKey} typeface="textBodySmallRegular">
            {name}
          </Text>
          <Text typeface="textRegularXSmall">{description}</Text>
          <Input
            id={formKey}
            onChange={handleChange(formKey)}
            value={typeof formData[formKey] === 'string' ? (formData[formKey] as string) : ''}
          />
        </ParamsInputContainer>
      ))}

      <ParamsInputContainer css={{ minWidth: 360, width: '60vw', maxWidth: 'unset' }}>
        <Text typeface="textBodySmallRegular">Dynamic text</Text>
        {(formData.dynamicText ?? []).map(({ original, replacement }, index) => (
          <FlexContainer key={index} alignItems="center" gap="micro">
            <Input onChange={handleDynamicTextChange(index, 'original')} value={original} />
            <CaretDoubleRight />
            <Input onChange={handleDynamicTextChange(index, 'replacement')} value={replacement} />
          </FlexContainer>
        ))}
        <Button small variant="flat-bordered" onClick={addNewDynamicText}>
          Add new
        </Button>
      </ParamsInputContainer>

      <ParamsButtons onSave={() => onSave(formData)} onReset={onReset} />
    </ParamsContainer>
  )
}

export default TextReplacementForm
