import { NewFeedback } from '@/types/feedbacks/Feedback'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface RemoveOpportunityFeedbackState {
  feedbackToRemove: NewFeedback | null
  setFeedbackToRemove: (feedback: NewFeedback | null) => void

  removingFeedbacks: string[]
  pushRemovingFeedback: (feedbackId: string) => void
  removeRemovingFeedback: (feedbackId: string) => void
  setRemovingFeedbacks: (removingFeedbacks: string[]) => void
}

const useRemoveOpportunityFeedbackStore = createWithEqualityFn<RemoveOpportunityFeedbackState>(
  set => ({
    feedbackToRemove: null,
    setFeedbackToRemove: feedback => set({ feedbackToRemove: feedback }),

    removingFeedbacks: [],
    pushRemovingFeedback: feedbackId =>
      set(state => ({ removingFeedbacks: [...state.removingFeedbacks, feedbackId] })),
    removeRemovingFeedback: feedbackId =>
      set(state => ({
        removingFeedbacks: state.removingFeedbacks.filter(fId => fId !== feedbackId)
      })),
    setRemovingFeedbacks: removingFeedbacks => set({ removingFeedbacks })
  }),
  shallow
)

export default useRemoveOpportunityFeedbackStore
