import { styled } from '@/theme'

export const UploadInput = styled('input', {
  display: 'none',
  border: 'none',
  textTransform: 'none',
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  opacity: 0,
  '&:focus': { outline: 'none' }
})

export const UploadContainer = styled('div', {
  width: '100%',
  boxSizing: 'border-box',
  position: 'relative'
})

export const UploadButton = styled('button', {
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  pAll: '$xs',
  flexGrow: 0,

  br: '5px',
  border: '1px dashed $neutralHighPure',
  backgroundColor: 'transparent',

  appearance: 'none',
  outline: 'none',
  cursor: 'pointer',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$micro',
  bc: '$neutralHighLight'
})
