import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useSavedFilters from '../useSavedFilters'
import useUser from '../useUser'
import { AreaOfInterestData, BaseInterestArea } from '@/types/area/AreaOfInterest'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/plugins/reactQueryClient'
import useToastMessageStore from '@/store/useToastMessageStore'
import useLogging from '../useLogging'
import { UNMAPPED_AREA_QUERY_KEY } from './useUnmappedAreaQuery'
import useAdvancedFilters from '../advancedFilters/useAdvancedFilters'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import shortUUID from 'short-uuid'
import useCollections from '../collections/useCollections'
import useOpportunityStore from '@/store/useOpportunityStore'
import AreaService from '@/services/AreaService'
import useBasicAreaOfInterestQuery from './useBasicAreaOfInterestQuery'
import useAllAreasQuery, { AREAS_KEY_PREFIX } from './useAllAreasQuery'
import { AreaRequests } from '@/types/area'
import useNavitagateTo from '../useNavigateTo'
import { AdvancedFilterContent } from '@/types/filters/AdvancedFilters'
import { useTranslation } from 'react-i18next'
import useAdvancedFiltersStore from '@/store/useFiltersStore/useAdvancedFiltersStore'
import { SavedFilterContentAdvanced } from '@/types/filters/Filters'
import useAlerts from '../alerts/useAlerts'
import useSegmentationStore from '@/store/useSegmentationStore'
import useSegment from '../useSegment'
import DefaultErrorHandler from '@/services/DefaultError'

export const BASE_AREAS_KEY = 'interest-areas'

interface CreateAreaParams {
  content?: AdvancedFilterContent
  name: string
  description?: string
}

interface UpdateAreaParams {
  area: BaseInterestArea
  content?: AdvancedFilterContent
  name?: string
  description?: string
}

const useAreaOfInterest = () => {
  const { navigateTo: navigate } = useNavitagateTo()
  const { t } = useTranslation()

  const { track } = useSegment()

  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const removeToast = useToastMessageStore(state => state.removeToast)

  const { logException } = useLogging({ context: 'area-of-interest' })

  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)

  const setCurrentAreaOfInterest = useCurrentInterestAreaStore(
    state => state.setCurrentInterestArea
  )
  const setCurrentSegmentation = useSegmentationStore(state => state.setCurrentSegmentation)

  const setLastOrganizationId = useCurrentInterestAreaStore(state => state.setLastOrganizationId)

  const setCurrentOpportunity = useOpportunityStore(state => state.setCurrentOpportunity)

  const { currentUser } = useUser()

  const { applyFilterContent } = useSavedFilters({ newFeed: true })
  const resetAllFilters = useFeedFiltersStore(state => state.resetAll)
  const { applyFilterFromArea, clearFilters, context, buildFilterContent } = useAdvancedFilters()

  const transformIntoAreaFilters = useAdvancedFiltersStore(
    state => state.transformIntoEntityFilters
  )

  const setArea = (area: BaseInterestArea | AreaOfInterestData, preserveOpportunity?: boolean) => {
    setCurrentAreaOfInterest(area)
    setCurrentSegmentation(undefined)
    !preserveOpportunity && setCurrentOpportunity(undefined)

    applyFilterFromArea(area)
    applyFilterContent([], true)
    setLastOrganizationId(currentUser?.organization_id)

    const translator = shortUUID()
    const shortAreaId = translator.fromUUID(area.id)

    navigate(`/area/${shortAreaId}`)
  }

  const setFreeExploration = () => {
    clearFilters()
    resetAllFilters({ keepDate: true })
    setCurrentAreaOfInterest(undefined)
    setCurrentOpportunity(undefined)
    setCurrentSegmentation(undefined)
  }

  const { currentCollection, collectionFilterIds, insertFiltersToCollection } = useCollections({
    enabled: false
  })
  const { queryKey: areasQueryKey } = useBasicAreaOfInterestQuery({
    enabled: false,
    collectionId: currentCollection?.collectionId
  })

  const { queryKey: listAreasQueryKey } = useAllAreasQuery({ enabled: false })

  const { mutate: createAreaOfInterest } = useMutation({
    mutationKey: ['create-interest-area'],
    mutationFn: async (params: CreateAreaParams) => {
      const content = buildFilterContent(params.content)
      const [error, created] = await AreaService.createArea({
        name: params.name,
        content: [content]
      })

      if (error) throw error
      return created
    },
    onMutate: () => {
      addLoadingToast({ text: t('createAreaLoadingMessage'), id: 'create-interest-area' })
    },
    onError: (error: Error, params) => {
      removeToast('create-interest-area')
      console.error(error)
      const message =
        (error as unknown as RequestError).details?.message ??
        error.message ??
        'Failed to create this area.'

      addErrorToast({ text: message })

      const content = buildFilterContent(params.content)

      logException(error, {
        message,
        tags: { content: JSON.stringify(content), name: params.name }
      })
    },
    onSuccess: (data, params) => {
      removeToast('create-interest-area')
      addSuccessToast({ text: t('areaCreatedSuccessMessage') })

      const content = buildFilterContent(params.content)

      track('exploration_area_created', {
        filters: JSON.stringify(content),
        area_name: data.name,
        area_id: data.area_id
      })

      const newArea: BaseInterestArea = {
        id: data.area_id,
        filterId: data.filter_id,
        name: data.name,
        description: data.description,
        content: [content],
        context: context ?? undefined,
        opportunityCount: 0,
        advanced: true,
        useInUnmappedArea: data.is_mapped ?? false,
        createdBy: data.created_by,
        error: data.error || null
      }

      setArea(newArea)
      transformIntoAreaFilters()

      queryClient.invalidateQueries({ queryKey: [AREAS_KEY_PREFIX] })
      queryClient.invalidateQueries({ queryKey: ['area-of-interest-trendline'] })
      queryClient.invalidateQueries({ queryKey: [BASE_AREAS_KEY] })

      if (
        currentCollection &&
        collectionFilterIds &&
        !collectionFilterIds.includes(newArea.filterId)
      ) {
        insertFiltersToCollection({
          collectionId: currentCollection.collectionId,
          filterIds: [...collectionFilterIds, newArea.filterId],
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['collections'], exact: false })
          }
        })
      }

      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [UNMAPPED_AREA_QUERY_KEY], exact: false })
      }, 500)

      return newArea
    }
  })

  const { checkAndUpdateAreaAlert } = useAlerts({ enabled: true })

  const { mutate: updateArea, isLoading: isUpdatingArea } = useMutation({
    mutationKey: ['edit-interest-area'],
    mutationFn: async (params: UpdateAreaParams) => {
      const content = buildFilterContent(params.content)
      const newArea: BaseInterestArea = {
        ...params.area,
        advanced: true,
        content: content ? [content] : params.area.content,
        name: params.name ?? params.area.name,
        description: params.description ?? params.area.description
      }

      const [error] = await AreaService.updateArea(newArea.id, {
        filter_id: newArea.filterId,
        name: newArea.name,
        description: newArea.description,
        content: newArea.content as [SavedFilterContentAdvanced]
      })

      if (error) throw error
      return newArea
    },
    onMutate: () => {
      addLoadingToast({ text: t('updateAreaLoadingMessage'), id: 'edit-interest-area' })
    },
    onError: (error, params) => {
      removeToast('edit-interest-area')
      console.error(error)
      logException(error, {
        message: 'Failed to update area.',
        tags: { area: JSON.stringify(params) }
      })

      addErrorToast({ text: t('updateAreaErrorMessage') })
    },
    onSuccess: async newArea => {
      removeToast('edit-interest-area')
      addSuccessToast({ text: t('updateAreaSuccessMessage') })

      track('area_filter_update', {
        filters: JSON.stringify(newArea.content),
        area_name: newArea.name,
        area_id: newArea.id
      })

      setCurrentAreaOfInterest({
        ...newArea,
        context: context ?? newArea.context
      })

      transformIntoAreaFilters()

      queryClient.invalidateQueries({ queryKey: [AREAS_KEY_PREFIX] })
      queryClient.invalidateQueries({ queryKey: ['area-of-interest-trendline'] })
      queryClient.invalidateQueries({
        queryKey: [BASE_AREAS_KEY],
        exact: false,
        refetchType: 'all'
      })

      const advancedAreas = await queryClient.fetchQuery<AreaRequests.SearchAreasResponse['areas']>(
        {
          queryKey: [BASE_AREAS_KEY, undefined]
        }
      )
      const newAdvancedContent = advancedAreas.find(
        advArea => advArea.area_id === newArea.id
      )?.content

      if (newAdvancedContent) {
        checkAndUpdateAreaAlert({
          filterId: newArea.filterId,
          newContent: newAdvancedContent as [SavedFilterContentAdvanced]
        })
      }

      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [UNMAPPED_AREA_QUERY_KEY], exact: false })
      }, 500)
    }
  })

  const { mutate: renameArea } = useMutation({
    mutationKey: ['rename-area-of-interest'],
    mutationFn: async ({ area, newName }: { area: BaseInterestArea; newName: string }) => {
      const [error] = await AreaService.updateArea(area.id, {
        name: newName,
        filter_id: area.filterId
      })

      if (error) throw error
      return { area, newName }
    },
    onMutate: async ({ area, newName }: { area: BaseInterestArea; newName: string }) => {
      await queryClient.cancelQueries({ queryKey: [AREAS_KEY_PREFIX] })
      await queryClient.cancelQueries({ queryKey: [BASE_AREAS_KEY] })

      const previousAreas = queryClient.getQueryData<AreaOfInterestData[]>(listAreasQueryKey)
      queryClient.setQueryData<AreaOfInterestData[]>(listAreasQueryKey, old => {
        if (!old) return
        return old.map(item => (item.id === area.id ? { ...item, name: newName } : item))
      })

      const previousAdvancedAreas =
        queryClient.getQueryData<AreaRequests.SearchAreasResponse['areas']>(areasQueryKey)
      queryClient.setQueryData<AreaRequests.SearchAreasResponse['areas']>(areasQueryKey, old => {
        if (!old) return
        return old.map(item => (item.area_id === area.id ? { ...item, name: newName } : item))
      })

      return { previousAreas, previousAdvancedAreas }
    },
    onError: (error, __, context) => {
      const message = 'Failed to rename this area. Please try again.'
      logException(error, { message })
      addErrorToast({ text: message })
      queryClient.setQueryData(listAreasQueryKey, context?.previousAreas)
      queryClient.setQueryData(areasQueryKey, context?.previousAdvancedAreas)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [BASE_AREAS_KEY] })
      queryClient.invalidateQueries({ queryKey: [AREAS_KEY_PREFIX] })
    }
  })

  const { mutate: deleteArea, isLoading: isDeleting } = useMutation({
    mutationKey: ['delete-area-of-interest'],
    mutationFn: async (areaId: string) => {
      const [error] = await AreaService.removeArea(areaId)

      if (error) throw error
      return areaId
    },
    onMutate: async (areaId: string) => {
      await queryClient.cancelQueries({ queryKey: [AREAS_KEY_PREFIX] })
      await queryClient.cancelQueries({ queryKey: [BASE_AREAS_KEY] })

      const previousAreas = queryClient.getQueryData<AreaOfInterestData[]>(listAreasQueryKey)
      queryClient.setQueryData<AreaOfInterestData[]>(listAreasQueryKey, old =>
        old?.filter(area => area.id !== areaId)
      )

      const previousAdvancedAreas =
        queryClient.getQueryData<AreaRequests.SearchAreasResponse['areas']>(areasQueryKey)
      queryClient.setQueryData<AreaRequests.SearchAreasResponse['areas']>(areasQueryKey, old => {
        if (!old) return
        return old.filter(area => area.area_id !== areaId)
      })

      return { previousAreas, previousAdvancedAreas }
    },
    onError: (error, areaId, context) => {
      logException(error, { message: 'Failed to delete area', tags: { areaId } })

      const errorData = error as DefaultErrorHandler
      const message =
        errorData.key === 'area_has_opportunities'
          ? t('thisCantBeDeletedBecauseHasOpportunities')
          : errorData.message ?? t('deleteAreaErrorMessage')

      addErrorToast({ text: message })

      queryClient.setQueryData(listAreasQueryKey, context?.previousAreas)
      queryClient.setQueryData(areasQueryKey, context?.previousAdvancedAreas)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [AREAS_KEY_PREFIX] })
      queryClient.invalidateQueries({ queryKey: [BASE_AREAS_KEY] })

      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [UNMAPPED_AREA_QUERY_KEY], exact: false })
      }, 500)
    }
  })

  const { mutate: updateUseInUnmapped } = useMutation({
    mutationKey: ['update-area-use-in-unmapped'],
    mutationFn: async ({
      area,
      newUseInUnmapped
    }: {
      area: BaseInterestArea
      newUseInUnmapped: boolean
    }) => {
      const [error] = await AreaService.updateArea(area.id, {
        name: area.name,
        filter_id: area.filterId,
        is_mapped: newUseInUnmapped
      })

      if (error) throw error
      return { area, newUseInUnmapped }
    },
    onMutate: async ({
      area,
      newUseInUnmapped
    }: {
      area: BaseInterestArea
      newUseInUnmapped: boolean
    }) => {
      await queryClient.cancelQueries({ queryKey: [AREAS_KEY_PREFIX] })
      await queryClient.cancelQueries({ queryKey: [BASE_AREAS_KEY] })

      const previousAreas = queryClient.getQueryData<AreaOfInterestData[]>(listAreasQueryKey)
      queryClient.setQueryData<AreaOfInterestData[]>(listAreasQueryKey, old =>
        old?.map(item =>
          item.id === area.id ? { ...item, useInUnmappedArea: newUseInUnmapped } : item
        )
      )

      const previousAdvancedAreas =
        queryClient.getQueryData<AreaRequests.SearchAreasResponse['areas']>(areasQueryKey)
      queryClient.setQueryData<AreaRequests.SearchAreasResponse['areas']>(areasQueryKey, old => {
        if (!old) return
        return old.map(item =>
          item.area_id === area.id ? { ...item, use_in_unmapped: newUseInUnmapped } : item
        )
      })

      if (currentInterestArea?.id === area.id) {
        setCurrentAreaOfInterest({ ...currentInterestArea, useInUnmappedArea: newUseInUnmapped })
      }

      return { previousAreas, previousAdvancedAreas }
    },
    onError: (error, __, context) => {
      const message = 'Failed to rename this area. Please try again.'
      logException(error, { message })
      addErrorToast({ text: message })
      queryClient.setQueryData(listAreasQueryKey, context?.previousAreas)
      queryClient.setQueryData(areasQueryKey, context?.previousAdvancedAreas)
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [UNMAPPED_AREA_QUERY_KEY], exact: false })
      }, 500)
    }
  })

  return {
    setArea,
    setFreeExploration,
    renameArea,
    deleteArea,
    isDeleting,
    createAreaOfInterest,
    updateArea,
    isUpdatingArea,
    updateUseInUnmapped
  }
}

export default useAreaOfInterest
