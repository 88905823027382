import FlexContainer from '@/components/atoms/flex-container'
import Breadcrumb from '@/components/atoms/breadcrumb'
import PageContentScroll from '@/components/molecules/layout/PageContentScroll'
import Text from '@/components/atoms/text'
import BetaBadge from '@/components/atoms/badge/BetaBadge'
import CalibrateContext from '@/components/molecules/exploration/Opportunities/CalibrateOpportunity/CalibrateContext'
import CalibrateQuestionsModule from '@/components/molecules/exploration/Opportunities/CalibrateOpportunity/CalibrateQuestionsModule'
import useOpportunityStore from '@/store/useOpportunityStore'
import { shallow } from 'zustand/shallow'
import usePageLink from '@/hooks/usePageLink'
import { useEffect, useMemo } from 'react'
import shortUUID from 'short-uuid'
import useUser from '@/hooks/useUser'
import NoPermission from '@/components/molecules/no-permission/NoPermission'
import useOpportunityCalibrationStore from '@/store/useOpportunityCalibrationStore'

const OpportunityCalibrationPage = () => {
  const { userPermissions, currentUser } = useUser()
  usePageLink()
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity, shallow)
  const translator = useMemo(() => shortUUID(), [])
  const shortOpportunityId = useMemo(
    () => (currentOpportunity?.id ? translator.fromUUID(currentOpportunity.id) : ''),
    [currentOpportunity?.id, translator]
  )
  const descriptionWithTags = currentOpportunity?.description || ''
  const description = descriptionWithTags.replace(/<[^>]*>/g, '')

  const resetStore = useOpportunityCalibrationStore(state => state.resetStore)

  useEffect(() => {
    return () => {
      resetStore()
    }
  }, [resetStore])

  if (userPermissions.areas.length === 0 && currentUser) {
    return <NoPermission />
  }

  return (
    <>
      <PageContentScroll
        css={{
          pl: 'calc(56px + $navigationBarWidth)',
          pr: '56px',
          pb: '24px'
        }}
      >
        <FlexContainer>
          <Breadcrumb.Root>
            <Breadcrumb.Item to={`/exploration`}>
              <span>Exploration</span>
            </Breadcrumb.Item>
            <Breadcrumb.Divider />
            <Breadcrumb.Item to={`/opportunity/${shortOpportunityId}`}>
              {currentOpportunity?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Divider />

            <Breadcrumb.Item active>
              <Text fontSize="xxxs" fontWeight="bold">
                Calibrate Opportunity
              </Text>
              <BetaBadge />
            </Breadcrumb.Item>
          </Breadcrumb.Root>
        </FlexContainer>

        <FlexContainer>
          <Text color="neutralLowPure" fontSize="xs" fontWeight="bold" lineHeight="sm">
            {currentOpportunity?.name}
          </Text>
        </FlexContainer>
        <FlexContainer direction="column" style={{ maxWidth: '60%' }} gap="xxs">
          <CalibrateContext context={description} />
          <CalibrateQuestionsModule />
        </FlexContainer>
      </PageContentScroll>
    </>
  )
}

export default OpportunityCalibrationPage
