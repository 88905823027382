import { CSS, styled } from '@/theme'
import FlexContainer from '@/components/atoms/flex-container'

const topicRowStyles: CSS = {
  pl: 'calc($xxs + $micro)', // CaretDown icon width + row gap
  alignItems: 'center',
  width: '100%',
  mb: '$xxs',
  '.topic-options': {
    opacity: 0
  },

  '&:hover': {
    '.topic-options': {
      opacity: 1
    }
  }
}

export const TopicInfoRow = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  marginLeft: 'auto'
  // width: 80
  // paddingRight: 32
})

export const TopicAccordionItem = styled(FlexContainer, {
  ...topicRowStyles,
  mb: 0,
  pr: '$nano',

  variants: {
    noHover: {
      true: {
        '&:hover': {
          background: 'transparent'
        }
      }
    }
  }
})
