import Checkbox from '@/components/atoms/checkbox'
import { ThemeItemData } from '@/types/classification/Theme'
import { CheckedState } from '@radix-ui/react-checkbox'
import { useMemo, useState } from 'react'
import { FilterSubMenuScrollArea, SelectionContainer } from '../AddFilterMenu/AddFilterMenu.styles'
import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import SearchInputForFilters from '../../SearchInputFilter'
import { useTranslation } from 'react-i18next'

export interface SubTopicListProps {
  theme: ThemeItemData
  onTopicCheck: (checkedState: CheckedState, topicId: string, theme?: ThemeItemData) => void
  isTopicChecked: (topicId: string, themeId?: string) => boolean
}

const SubTopicList = ({ theme, onTopicCheck, isTopicChecked }: SubTopicListProps) => {
  const { t } = useTranslation()

  const [searchInput, setSearchInput] = useState('')
  const filteredTopics = useMemo(
    () =>
      theme.topics.filter(topic =>
        topic.topicName.toLowerCase().includes(searchInput.toLowerCase())
      ),
    [theme, searchInput]
  )

  return (
    <>
      <SearchInputForFilters
        css={{ mt: '$micro', mb: '$micro' }}
        onChange={e => setSearchInput(e.currentTarget.value)}
        placeholder={t('search')}
        size="small"
        small
        value={searchInput}
      />
      <FilterSubMenuScrollArea>
        <ScrollAreaViewport css={{ maxHeight: 320 }}>
          <SelectionContainer>
            {filteredTopics.map(topic => (
              <Checkbox
                checked={isTopicChecked(topic.topicId, theme.themeId ?? '')}
                key={topic.topicId}
                onCheckedChange={checkedState => onTopicCheck(checkedState, topic.topicId, theme)}
                text={topic.topicName}
                value={'filter-' + topic.topicId}
              />
            ))}
          </SelectionContainer>
        </ScrollAreaViewport>

        <ScrollAreaScrollbar orientation="vertical">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </FilterSubMenuScrollArea>
    </>
  )
}

export default SubTopicList
