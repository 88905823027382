import { Icon } from '@phosphor-icons/react'
import FlexContainer from '@/components/atoms/flex-container'
import ExplorationNumberVariation from '../ExplorationNumberVariation'
import useComparisonText from '@/hooks/useComparisonText'
import { shallow } from 'zustand/shallow'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { useTranslation } from 'react-i18next'
import { RawMetricError } from '@/types/metrics'
import ExplorationIndicatorCard from './ExplorationIndicatorCard'

interface VariationProps {
  value: number
  variationType: 'percentage' | 'points' | 'count'
  expanded: boolean
}
const MetricVariation = ({ value, variationType, expanded }: VariationProps) => {
  const dateRange = useDateFilterStore(state => state.dateRange, shallow)
  const { text } = useComparisonText({ newFeed: true })

  if (!dateRange) {
    return <ExplorationIndicatorCard.Fallback />
  }

  return (
    <ExplorationIndicatorCard.Paragraph css={{ textAlign: expanded ? 'right' : 'center' }}>
      <ExplorationNumberVariation
        css={{ fontSize: 'inherit' }}
        type={variationType}
        value={value}
      />{' '}
      {expanded && <span>{text}</span>}
    </ExplorationIndicatorCard.Paragraph>
  )
}

interface MetricIndicatorProps {
  value: number
  formatter: (value: number) => string
  icon: Icon
  title: string
  metricName: string
  color: string
  expanded?: boolean
  variation: number
  variationType?: 'percentage' | 'points' | 'count'
  countMetricValue?: string
  hasFilterError?: boolean
  metricError?: RawMetricError
}

const MetricIndicator = ({
  value,
  formatter,
  icon: Icon,
  title,
  metricName,
  color,
  expanded = false,
  variation,
  variationType = 'percentage',
  countMetricValue,
  hasFilterError,
  metricError
}: MetricIndicatorProps) => {
  const hasError = !!metricError || hasFilterError
  const formattedValue = hasError ? '---' : formatter(value)
  const { t } = useTranslation()

  return (
    <ExplorationIndicatorCard.Root
      color="brandPrimaryPure"
      className="scroll-on-hover-child"
      css={{ position: 'relative' }}
      justifyContent="spaceBetween"
    >
      <ExplorationIndicatorCard.Column fullWidth>
        <ExplorationIndicatorCard.Header>
          <ExplorationIndicatorCard.IconBox>
            <Icon />
          </ExplorationIndicatorCard.IconBox>
          <ExplorationIndicatorCard.Title title={title}>{title}</ExplorationIndicatorCard.Title>
        </ExplorationIndicatorCard.Header>

        <FlexContainer fullWidth justifyContent="stretch">
          <FlexContainer css={{ mr: '$micro' }} direction="column">
            <ExplorationIndicatorCard.BigNumber color={color}>
              {formattedValue}
            </ExplorationIndicatorCard.BigNumber>
            <ExplorationIndicatorCard.Paragraph css={{ whiteSpace: 'nowrap' }}>
              <span>
                {countMetricValue && !hasError ? `${t('count')}: ${countMetricValue}` : metricName}
              </span>
            </ExplorationIndicatorCard.Paragraph>
          </FlexContainer>

          <FlexContainer alignItems="end" direction={'column'} fullWidth justifyContent="flexEnd">
            {!hasError && (
              <>
                <MetricVariation
                  expanded={expanded}
                  value={variation}
                  variationType={variationType}
                />
                <ExplorationIndicatorCard.Paragraph css={{ whiteSpace: 'nowrap', height: 24 }} />
              </>
            )}
          </FlexContainer>
        </FlexContainer>
      </ExplorationIndicatorCard.Column>
    </ExplorationIndicatorCard.Root>
  )
}

export default MetricIndicator
