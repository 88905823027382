import { colors } from '@/theme'
import { Intention } from '@/types/reasons'
import { Bug, HandsClapping, Info, Megaphone, Question, StarFour } from '@phosphor-icons/react'
import { ReactNode } from 'react'

export const intentionIcons: Record<Intention, ReactNode> = {
  compliment: <HandsClapping />,
  issue: <Bug />,
  question: <Question />,
  request: <Megaphone />,
  information: <Info />,
  solution: <StarFour />
}

export const intentionIconNames: Record<Intention, string> = {
  issue: 'bug',
  compliment: 'hands-clapping',
  request: 'megaphone',
  question: 'question',
  information: 'info',
  solution: 'star-four'
}

export const INTENTION_LIST = Object.keys(intentionIcons) as Intention[]

export const intentionTextColor: Record<Intention, string> = {
  compliment: colors.feedbackPositivePure,
  issue: colors.feedbackNegativePure,
  request: colors.feedbackInformativePure,
  question: colors.intentionQuestion,
  information: colors.neutralLowMedium,
  solution: colors.brandPrimaryPure
}

export const intentionBgColor: Record<Intention, string> = {
  compliment: colors.feedbackPositiveLight,
  issue: colors.feedbackNegativeLight,
  request: colors.feedbackInformativeLight,
  question: colors.feedbackWarningLight,
  information: colors.neutralHighMedium,
  solution: colors.brandPrimaryLight
}

export const INTENTION_ANALYTICS_BLACKLIST: Intention[] = ['information', 'solution']

export const intentionFeedQueryParam: Record<Intention, string> = {
  issue: 'PROBLEM',
  request: 'REQUEST',
  information: 'INFORMATION',
  solution: 'SOLUTION',
  compliment: 'COMPLIMENT',
  question: 'QUESTION'
}

export const intentionQueryValueToIntention: Record<string, Intention> = {
  PROBLEM: 'issue',
  REQUEST: 'request',
  INFORMATION: 'information',
  SOLUTION: 'solution',
  COMPLIMENT: 'compliment',
  QUESTION: 'question'
}
