import Button from '@/components/atoms/button'
import Dialog from '@/components/atoms/dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import Input, { TextArea } from '@/components/atoms/input'
import Text from '@/components/atoms/text'
import { useState } from 'react'
import { styled } from '@/theme'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../plugins/i18n/i18n'

const descriptionPlaceholder = i18n.t(
  'includeProblemsDoubtsOrRequestsThatCouldBeMentionedByCustomers'
)

const namePlaceholder = i18n.t('ieProblemsWithMoneyTransferring')

const ScrollContainer = styled(FlexContainer, {
  flex: 1,
  overflowY: 'auto',
  flexDirection: 'column',
  gap: '$xs',
  maxHeight: `calc(85vh - 156px)`,
  mx: -24,
  px: 24,
  height: '100%'
})

export interface CreateOpportunityData {
  name: string
  description: string
}

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  onCreate: (data: CreateOpportunityData) => void
}

const CreateOpportunityModal = ({ open, onOpenChange, onCreate }: Props) => {
  const [description, setDescription] = useState('')
  const [name, setName] = useState('')

  const { t } = useTranslation()

  const isDisabled = !name || !description

  const onAddClick = () => {
    onCreate({ name, description })
    onOpenChange(false)
    setDescription('')
    setName('')
  }

  return (
    <Dialog align="center" closeIcon modal onOpenChange={onOpenChange} open={open}>
      <FlexContainer direction="column" fullHeight gap="xs">
        <Text as="h1" typeface="titleBoldXXS">
          {t('addOpportunity')}
        </Text>
        <ScrollContainer>
          <FlexContainer direction="column" gap="xxs">
            <Text as="p" fontSize="xxxs">
              {t('describeTheOpportunityYouWantToQuantify')}
            </Text>
            <TextArea
              css={{ whiteSpace: 'pre-wrap', lineHeight: '$xl' }}
              onChange={e => setDescription(e.currentTarget.value)}
              placeholder={descriptionPlaceholder}
              rows={12}
              value={description}
            />
          </FlexContainer>
          <FlexContainer direction="column" gap="xxs">
            <Text as="p" fontSize="xxxs">
              {t('nameTheOpportunityAsYouWantItDisplayedInTheList')}
            </Text>
            <Input
              onChange={e => setName(e.currentTarget.value)}
              placeholder={namePlaceholder}
              value={name}
            />
          </FlexContainer>
        </ScrollContainer>
        <Divider line="solid" orientation="horizontal" />
        <Button disabled={isDisabled} fullWidth onClick={onAddClick} size="small">
          {t('add')}
        </Button>
      </FlexContainer>
    </Dialog>
  )
}

export default CreateOpportunityModal
