import TopicService from '@/services/TopicService'
import { useClassificationStore, useFiltersStore } from '@/store'
import useTopicsStore from '@/store/useTopicsStore'
import { useCallback, useState } from 'react'
import { shallow } from 'zustand/shallow'
import usePromiseSync from '../usePromiseSync'
import { TopicStatsPayload } from '@/types/classification/TopicRequests'
import {
  TopicCategory,
  TopicData,
  TopicDataWithCategory,
  TopicThemeItem
} from '@/types/classification'
import useToastMessageStore from '@/store/useToastMessageStore'
import ThemeService from '@/services/ThemeService'
import useSegment from '../useSegment'
import useUser from '../useUser'
import { REFRESH_THEMES_MIN_DELAY } from '../useClassification'
import { ThemeItemData } from '@/types/classification/Theme'
import useLogging from '../useLogging'

export function useTopics() {
  const { sync } = usePromiseSync()
  const { track } = useSegment()

  const pushTheme = useClassificationStore(state => state.pushTheme)
  const updateTheme = useClassificationStore(state => state.updateTheme)
  const storeRemoveTheme = useClassificationStore(state => state.removeTheme)

  const pageSize = 50

  const { page, error, isLoading, hasMore, total, data } = useTopicsStore(state => ({
    page: state.page,
    error: state.error,
    isLoading: state.isLoading,
    hasMore: state.hasMore,
    total: state.data.length,
    data: state.data
  }))

  const { selectedTopicGroups, topicSortType, topicSortDirection, isUngrouped } = useTopicsStore(
    state => ({
      selectedTopicGroups: state.selectedTopicGroups,
      topicSortType: state.topicSortType,
      topicSortDirection: state.topicSortDirection,
      isUngrouped: state.isUngrouped
    }),
    shallow
  )

  const hasGroupsSelected = selectedTopicGroups.length > 0

  const push = useTopicsStore(state => state.push)
  const setError = useTopicsStore(state => state.setError)
  const setLoading = useTopicsStore(state => state.setLoading)
  const setDebounceLoading = useTopicsStore(state => state.setDebounceLoading)
  const setTotal = useTopicsStore(state => state.setTotal)
  const setIsLoadingTopicGroups = useTopicsStore(state => state.setIsLoadingTopicGroups)
  const setHasMore = useTopicsStore(state => state.setHasMore)
  const reset = useTopicsStore(state => state.reset)
  const getMaxFrequency = useTopicsStore(state => state.getMaxFrequency)
  const setTopicGroups = useTopicsStore(state => state.setTopicGroups)
  const storeAddTopicGroup = useTopicsStore(state => state.addTopicGroup)
  const replaceTopicGroup = useTopicsStore(state => state.replaceTopicGroup)
  const storeRemoveTopicGroup = useTopicsStore(state => state.removeTopicGroup)
  const storeRemoveTopic = useTopicsStore(state => state.removeTopic)

  const toTopicStatsPayload = useFiltersStore(state => state.toTopicStatsPayload)

  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const { logException } = useLogging({ context: 'topics' })

  const [maxFrequency, setMaxFrequency] = useState(getMaxFrequency() ?? 1)

  const { currentUser } = useUser()

  const load = useCallback(
    async function (forceReset = false) {
      setDebounceLoading(false)

      if (isLoading) {
        return
      }

      setLoading(true)
      setError(null)

      if (!hasMore && !forceReset) {
        setLoading(false)
        return
      }

      const payload: TopicStatsPayload = toTopicStatsPayload(forceReset ? 0 : page, pageSize)
      payload.theme_id_list = selectedTopicGroups
      payload.order_by = topicSortType
      payload.order_direction = topicSortDirection
      if (isUngrouped) {
        payload.return_all = false
      } else {
        payload.return_all = selectedTopicGroups.length ? undefined : true
      }
      const shouldUseUngroupedApi = isUngrouped || !hasGroupsSelected

      await sync(
        'topics',
        shouldUseUngroupedApi
          ? TopicService.ungroupedTopics(payload)
          : TopicService.specificTopics(payload),
        response => {
          const [error, topicsData] = response
          if (error) {
            logException(error, { message: 'Failed to load topics.' })
            setError(new Error(error.message))
            setLoading(false)
            return
          }
          const { total, items } = topicsData
          if (items.length === 0) {
            setHasMore(false)
            setLoading(false)
            return
          }

          push(items)
          setMaxFrequency(prevMax =>
            Math.max(prevMax, getMaxFrequency(), ...items.map(topic => topic.totalFrequency))
          )
          setTotal(total)
          setLoading(false)
        }
      )
    },
    [
      isLoading,
      hasMore,
      page,
      hasGroupsSelected,
      isUngrouped,
      setLoading,
      setError,
      toTopicStatsPayload,
      push,
      getMaxFrequency,
      logException,
      selectedTopicGroups,
      setDebounceLoading,
      setHasMore,
      setTotal,
      sync,
      topicSortDirection,
      topicSortType
    ]
  )

  const loadTopicGroups = useCallback(
    async function (withLoading = true) {
      const payload = TopicService.DEFAULT_PAYLOAD
      withLoading && setIsLoadingTopicGroups(true)
      await sync('topic-groups', TopicService.getGroupedTopics(payload), response => {
        const [error, topicsData] = response
        if (error) {
          withLoading && setIsLoadingTopicGroups(false)
          const message = 'Failed to load topic groups.'
          logException(error, { message })
          addErrorToast({ text: message })
          throw error
        }

        setTopicGroups(topicsData)
        withLoading && setIsLoadingTopicGroups(false)
        return topicsData
      })
    },
    [addErrorToast, logException, setIsLoadingTopicGroups, setTopicGroups, sync]
  )

  async function addTopicGroup(groupName: string, category?: TopicCategory) {
    if (groupName.trim().length === 0) {
      return
    }

    const removeLoadingToast = addLoadingToast({ text: 'Creating new topic group...' })

    const [error, data] = await ThemeService.create({
      name: groupName,
      description: '',
      category: category || 'OTHER'
    })

    if (error) {
      removeLoadingToast()
      logException({ error }, { message: 'Failed to create new topic group.' })

      addErrorToast({
        text: error.message.replace('theme', 'topic group'),
        duration: 3000
      })

      return
    }

    track('explore_user_theme_creation')
    removeLoadingToast()

    addSuccessToast({
      text: 'Topic group created successfully',
      duration: 2000
    })
    storeAddTopicGroup({
      topicId: data.theme_id,
      topicName: groupName,
      totalFrequency: 0,
      category: category || 'OTHER',
      isMergeParent: false,
      sentiment: {
        negativeCount: 0,
        negativePercentage: 0,
        netSentiment: 0,
        neutralCount: 0,
        neutralPercentage: 0,
        positiveCount: 0,
        positivePercentage: 0
      }
    })

    const createdTheme: ThemeItemData = {
      themeId: data.theme_id,
      themeName: groupName,
      topics: [],
      frequency: 0,
      sentimentMetrics: {
        negativeCount: 0,
        negativePercentage: 0,
        netSentiment: 0,
        neutralCount: 0,
        neutralPercentage: 0,
        positiveCount: 0,
        positivePercentage: 0
      }
    }
    pushTheme(createdTheme, category || 'OTHER')
    const topicGroup: TopicThemeItem = {
      themeCategory: category ?? 'OTHER',
      themeId: data.theme_id,
      themeName: groupName
    }

    return topicGroup
  }

  async function renameTopicGroup(changedTopicGroup: TopicData) {
    const removeLoadingToast = addLoadingToast({ text: 'Updating topic group...' })

    const [error] = await ThemeService.update({
      themeId: changedTopicGroup.topicId,
      name: changedTopicGroup.topicName,
      category: changedTopicGroup.category || 'OTHER',
      description: ''
    })

    if (error) {
      removeLoadingToast()

      logException({ error }, { message: 'Failed to rename topic group.' })

      addErrorToast({
        text: error.message,
        duration: 3000
      })

      return
    }
    removeLoadingToast()

    replaceTopicGroup(changedTopicGroup)
    updateTheme({
      themeId: changedTopicGroup.topicId,
      partialTheme: { themeName: changedTopicGroup.topicName }
    })

    addSuccessToast({
      text: 'Topic group updated successfully'
    })
  }

  async function removeTopicGroup(topicGroupId: string) {
    const removeLoadingToast = addLoadingToast({ text: 'Removing topic group...' })
    try {
      await ThemeService.remove(topicGroupId)
      removeLoadingToast()
      storeRemoveTopicGroup(topicGroupId)
      storeRemoveTheme(topicGroupId)
      addSuccessToast({
        text: 'Topic group removed successfully'
      })
    } catch (error) {
      const message = 'Failed to remove topic group.'
      logException(error, { message })
      addErrorToast({
        text: message
      })

      removeLoadingToast()
    }
  }

  async function moveTopicGroup(topicGroup: TopicDataWithCategory, newCategory: TopicCategory) {
    const removeLoadingToast = addLoadingToast({ text: 'Moving topic group...' })

    const [error] = await ThemeService.update({
      themeId: topicGroup.topicId,
      name: topicGroup.topicName,
      category: newCategory,
      description: ''
    })

    if (error) {
      removeLoadingToast()

      logException({ error }, { message: 'Failed to move topic group.' })

      addErrorToast({
        text: error.message,
        duration: 3000
      })

      return
    }

    removeLoadingToast()
    storeRemoveTheme(topicGroup.topicId)
    replaceTopicGroup({ ...topicGroup, category: newCategory })
    addSuccessToast({
      text: 'Topic group moved successfully'
    })
  }

  const removeTopic = async (topics: TopicData | TopicData[]) => {
    const toDelete = Array.isArray(topics) ? topics : [topics]
    const removeLoadingToast = addLoadingToast({
      text: `Removing ${toDelete.length} ${toDelete.length > 1 ? 'topics' : 'topic'}`
    })

    try {
      await Promise.all(
        toDelete.map(async topic => {
          await TopicService.delete(topic.topicId, currentUser?.organization_id ?? '')
          storeRemoveTopic(topic.topicId)
        })
      )

      setTimeout(() => {
        removeLoadingToast()

        addSuccessToast({
          text: 'Removed successfully'
        })
        loadTopicGroups(false)
      }, REFRESH_THEMES_MIN_DELAY)
    } catch (error) {
      const message = 'Failed to remove topic'
      logException(error, { message })
      addErrorToast({ text: message })

      removeLoadingToast()
      throw error
    }
  }

  const topics = data

  return {
    data,
    topics,
    isLoading,
    error,
    hasMore,
    load,
    loadTopicGroups,
    addTopicGroup,
    renameTopicGroup,
    removeTopicGroup,
    moveTopicGroup,
    reset,
    maxFrequency,
    total,
    removeTopic
  }
}
