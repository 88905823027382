import { FilterSubMenuScrollArea } from '../../AddFilterMenu/AddFilterMenu.styles'
import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import Checkbox from '@/components/atoms/checkbox'
import FlexContainer from '@/components/atoms/flex-container'
import { useMemo, useState } from 'react'
import SearchInputForFilters from '../../../SearchInputFilter'
import { CheckedState } from '@radix-ui/react-checkbox'

export interface Props {
  options: string[]
  isChecked: (option: string) => boolean
  selectOption: (checked: CheckedState, option: string) => void
}

const CustomerRecordsStringFilterContent = ({ options, isChecked, selectOption }: Props) => {
  const [searchInput, setSearchInput] = useState('')

  const filteredValues = useMemo(() => {
    if (!searchInput.length) return options

    return options.filter(value => value.toLowerCase().includes(searchInput.toLowerCase()))
  }, [options, searchInput])

  return (
    <>
      <SearchInputForFilters
        css={{ mt: '$micro', mb: '$micro' }}
        onChange={e => setSearchInput(e.currentTarget.value)}
        placeholder="Search"
        size="small"
        small
        value={searchInput}
      />
      <FilterSubMenuScrollArea>
        <ScrollAreaViewport css={{ maxHeight: 320 }}>
          <FlexContainer direction="column" gap="micro">
            {filteredValues.map(option => (
              <Checkbox
                checked={isChecked(option)}
                defaultChecked={isChecked(option)}
                key={'customer-record' + option}
                onCheckedChange={checked => selectOption(checked, option)}
                text={option}
                value={option}
              />
            ))}
          </FlexContainer>
        </ScrollAreaViewport>
        <ScrollAreaScrollbar orientation="vertical">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </FilterSubMenuScrollArea>
    </>
  )
}

export default CustomerRecordsStringFilterContent
