import { useState } from 'react'
import FilterSubMenu from '../../AddFilterMenu/FilterSubMenu'

import { FilterItem } from '@/types/filters/Filters'
import useCustomerUsersStringFilter from './useCustomerUsersStringFilter'
import useCustomerUsersStringFilterSelection from './useCustomerUsersStringFilterSelection'
import CustomerRecordsStringFilterContent from '../common/CustomerRecordsStringFilterContent'

export interface Props {
  filter: FilterItem
  newFeed?: boolean
}

const CustomerUsersStringFilterSubmenu = ({ filter, newFeed = false }: Props) => {
  const [open, setOpen] = useState(false)

  const { options, isLoading } = useCustomerUsersStringFilter({
    filterKey: filter.key,
    enabled: open
  })

  const { apply, hasChanges, isChecked, selectOption } = useCustomerUsersStringFilterSelection({
    filterKey: filter.key,
    newFeed
  })

  return (
    <FilterSubMenu
      isDisabled={!hasChanges}
      isLoading={isLoading}
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      previousTitle="Users"
      title={filter.displayName}
    >
      <CustomerRecordsStringFilterContent
        isChecked={isChecked}
        options={options}
        selectOption={selectOption}
      />
    </FilterSubMenu>
  )
}

export default CustomerUsersStringFilterSubmenu
