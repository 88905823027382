import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import FilterSubMenu from '../AddFilterMenu/FilterSubMenu'
import BooleanFilterContent from './BooleanFilterContent'
import useBooleanGenericFilter from './useBooleanGenericFilter'
import { isAreaOfInterestFilter } from '@/utils/areaOfInterest'

export interface BooleanFilterSubMenuProps {
  title: string
  previousTitle?: string
  type: string
  name: string
  filterKey: string
  newFeed?: boolean
}

const BooleanFilterSubMenu = ({
  title,
  previousTitle,
  type,
  name,
  filterKey,
  newFeed = false
}: BooleanFilterSubMenuProps) => {
  const { onApply, onOptionChange, selectedOption, isDisabled } = useBooleanGenericFilter({
    filterKey,
    type,
    name,
    newFeed
  })

  const areaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  if (
    isAreaOfInterestFilter({
      key: filterKey,
      values: selectedOption,
      areaOfInterest
    })
  )
    return <></>

  return (
    <FilterSubMenu
      isDisabled={isDisabled}
      onApply={onApply}
      previousTitle={previousTitle}
      title={title}
    >
      <BooleanFilterContent onOptionChange={onOptionChange} selectedOption={selectedOption} />
    </FilterSubMenu>
  )
}

export default BooleanFilterSubMenu
