export function checkHasScroll(element: HTMLElement | null) {
  if (!element) return false
  return element.scrollHeight > element.clientHeight
}

export function checkScrollToBottom(element: HTMLElement | null) {
  if (!element) return false
  return element.scrollHeight <= element.clientHeight + element.scrollTop
}

export function checkHasHorizontalScroll(element: HTMLElement | null) {
  if (!element) return false
  return element.scrollWidth > element.clientWidth
}

export function checkScrollToRight(element: HTMLElement | null, offset = 0) {
  if (!element) return false

  return element.scrollWidth <= element.clientWidth + element.scrollLeft + offset
}
