import useAnalyticsIndicators from '@/hooks/analytics/useAnalyticsIndicators'
import { AnalyticsFlexContainer } from '../Analytics.styles'
import FeedbackIndicator from './FeedbackIndicator'
import IntentionIndicator from './IntentionIndicator'
import IndicatorsSkeletons from './IndicatorsSkeletons'

const Indicators = () => {
  const { data, isLoading, isRefetching } = useAnalyticsIndicators()
  const ticketsData = data?.feedbackMetric
  const issueData = data?.metricsByIntention.find(item => item.intention === 'Issue')
  const requestData = data?.metricsByIntention.find(item => item.intention === 'Request')

  if (isLoading || isRefetching) return <IndicatorsSkeletons />

  return (
    <AnalyticsFlexContainer justifyContent="stretch">
      <FeedbackIndicator
        comparisonPercentage={ticketsData?.feedbackPercentage ?? 0}
        count={ticketsData?.currentFeedbackCount ?? 0}
      />
      <IntentionIndicator
        comparisonPercentage={issueData?.detailsPercentage ?? 0}
        count={issueData?.currentFeedbackCount ?? 0}
        intention="issue"
        intentionPercentage={issueData?.feedbackPercentage ?? 0}
      />
      <IntentionIndicator
        comparisonPercentage={requestData?.detailsPercentage ?? 0}
        count={requestData?.currentFeedbackCount ?? 0}
        intention="request"
        intentionPercentage={requestData?.feedbackPercentage ?? 0}
      />
    </AnalyticsFlexContainer>
  )
}

export default Indicators
