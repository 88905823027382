import { memo, useEffect, useState, useCallback } from 'react'

import Bone from '@/components/atoms/bone'
import useIntegrationsStore from '@/store/useIntegrationsStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import { ConnectorArguments, ConnectorArgumentWithValue } from '@/types/integrations'
import ConnectorField from './ConnectorField'
import { FormFieldContainer } from './IntegrationForm.styles'

interface Props {
  connectorId?: string
  fields: Record<string, ConnectorArguments>
  fieldValues: Record<string, ConnectorArgumentWithValue>
  handleChange: (key: string, field: ConnectorArgumentWithValue) => void
}

const ConnectorArgumentsFields = ({ connectorId, handleChange, fields, fieldValues }: Props) => {
  const [loadingArgument, setLoadingArguments] = useState(false)

  const loadConnectorArgument = useIntegrationsStore(state => state.loadConnectorArgument)
  const addToast = useToastMessageStore(state => state.addToast)

  useEffect(() => {
    const getConnector = async () => {
      if (connectorId) {
        setLoadingArguments(true)
        try {
          await loadConnectorArgument(connectorId)
        } catch (error) {
          console.error(error)
          addToast({
            id: 'error-load-connector',
            status: 'error',
            text: 'Failed to load connector fields',
            open: true
          })
        } finally {
          setLoadingArguments(false)
        }
      }
    }

    getConnector()
  }, [connectorId, loadConnectorArgument, addToast])

  const onChange = useCallback(
    (field: string) => (value: string | string[]) => {
      handleChange(field, { ...fields[field], value })
    },
    [handleChange, fields]
  )

  if (loadingArgument) {
    return (
      <>
        <FormFieldContainer>
          <Bone css={{ height: 16.1, width: 80 }} />
          <Bone css={{ height: 42.1, width: '100%' }} />
        </FormFieldContainer>
        <FormFieldContainer>
          <Bone css={{ height: 16.1, width: 80 }} />
          <Bone css={{ height: 42.1, width: '100%' }} />
        </FormFieldContainer>
      </>
    )
  }

  return (
    <>
      {Object.entries(fields).map(([key, field]) => (
        <ConnectorField
          field={field}
          fieldKey={key}
          key={key}
          onChange={onChange(key)}
          value={fieldValues[key]?.value || ''}
        />
      ))}
    </>
  )
}

export default memo(ConnectorArgumentsFields)
