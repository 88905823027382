import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { APP_ENVIRONEMNT, CSVBOX_IMPORT_THEMES_KEY } from '@/config'
import useCsv from '@/hooks/useCsv'
import useSegment from '@/hooks/useSegment'
import useToastMessageStore from '@/store/useToastMessageStore'
import { useEffect, useRef, useState } from 'react'
import IconButton from '@/components/atoms/icon-button'
import {
  DotsThreeVertical,
  FileArrowDown,
  FileArrowUp,
  IconContext,
  Info
} from '@phosphor-icons/react'
import { DropdownMenuContent, DropdownMenuItem } from '@/components/atoms/options-menu'
import ThemeService from '@/services/ThemeService'
import { CSVBoxButton } from '@csvbox/react'
import {
  DropdownMenuButton,
  DropdownMenuSeparator
} from '@/components/atoms/options-menu/OptionsMenu.styles'
import useClassification from '@/hooks/useClassification'
import * as Portal from '@radix-ui/react-portal'
import useUser from '@/hooks/useUser'

interface InvisibleTriggerProps {
  launch: () => void
  launchRef: React.MutableRefObject<(() => void) | undefined>
}

const InvisibleTrigger = ({ launch, launchRef }: InvisibleTriggerProps) => {
  // biome-ignore lint/correctness/useExhaustiveDependencies(launchRef):
  useEffect(() => {
    if (launch) {
      launchRef.current = launch
    }
  }, [launch])

  return <></>
}

const HOW_TO_IMPORT_THEMES_ARTICLE_ID = 6809922

const ClassificationOptions = () => {
  const { track } = useSegment()

  const isProduction = APP_ENVIRONEMNT === 'production'

  const { csvmaker, download } = useCsv('themes')
  const [uploadReady, setUploadReady] = useState(false)
  const [isExportLoading, setIsExportLoading] = useState(false)

  const tokenIdRef = useRef(localStorage.getItem('access_token'))
  const launchRef = useRef<() => void>()

  const { loadThemes } = useClassification()

  const addToast = useToastMessageStore(state => state.addToast)
  const removeToast = useToastMessageStore(state => state.removeToast)

  const { userPermissions } = useUser()
  const isManager = userPermissions.topic.includes('manager')

  function showHowToImportThemes() {
    if (isProduction) {
      window.Intercom('showArticle', HOW_TO_IMPORT_THEMES_ARTICLE_ID)
    }
  }

  const onImport = (result: boolean) => {
    if (!result) {
      addToast({
        id: 'themes-error-import',
        text: 'Failed to import your topics template.',
        open: true,
        status: 'error'
      })
    } else {
      loadThemes()

      track('explore_user_import_tax_file')

      addToast({
        id: 'themes-success-import',
        text: 'Topics template imported!',
        open: true,
        status: 'success'
      })
    }
  }

  const onExport = async () => {
    if (isExportLoading) return

    const toastId = 'exporting-themes'
    addToast({ id: toastId, text: 'Export topics in progress...', status: 'loading', open: true })

    setIsExportLoading(true)

    try {
      const data = await ThemeService.export()
      if (!data || !data.length) {
        removeToast(toastId)
        setIsExportLoading(false)
        addToast({
          id: 'no-themes-to-export',
          text: 'No topics to export',
          status: 'default',
          open: true
        })
        return
      }

      const csv = csvmaker(data)
      download(csv)
    } catch (error) {
      console.error(error)

      removeToast(toastId)
      addToast({
        id: 'exporting-data-error',
        text: 'Error on export topics',
        status: 'error',
        open: true
      })
    } finally {
      setIsExportLoading(false)
      removeToast(toastId)
    }
  }

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    // e.preventDefault()
    e.stopPropagation()
  }

  return (
    <>
      <DropdownMenuPrimitive.Root>
        <DropdownMenuPrimitive.Trigger asChild>
          <IconButton onClick={handleClick}>
            <DotsThreeVertical size={16} weight="bold" />
          </IconButton>
        </DropdownMenuPrimitive.Trigger>

        <DropdownMenuPrimitive.Portal>
          <IconContext.Provider value={{ size: 16 }}>
            <DropdownMenuContent hideWhenDetached sideOffset={5}>
              {isManager ? (
                <DropdownMenuButton
                  disabled={!uploadReady}
                  onClick={launchRef.current}
                  title={uploadReady ? 'Import' : 'Loading import...'}
                >
                  <FileArrowDown /> <span>Import</span>
                </DropdownMenuButton>
              ) : null}
              <DropdownMenuItem onClick={onExport}>
                <FileArrowUp />
                <span>Export</span>
              </DropdownMenuItem>
              {isProduction && isManager && (
                <>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onClick={showHowToImportThemes}>
                    <Info />
                    <span>What is this?</span>
                  </DropdownMenuItem>
                </>
              )}
            </DropdownMenuContent>
          </IconContext.Provider>
        </DropdownMenuPrimitive.Portal>
      </DropdownMenuPrimitive.Root>

      <Portal.Root>
        <CSVBoxButton
          licenseKey={CSVBOX_IMPORT_THEMES_KEY}
          onImport={onImport}
          onReady={() => setUploadReady(true)}
          render={(launch: () => void) => (
            <InvisibleTrigger launch={launch} launchRef={launchRef} />
          )}
          user={{ token_id: tokenIdRef.current }}
        />
      </Portal.Root>
    </>
  )
}

export default ClassificationOptions
