import FlexContainer from '@/components/atoms/flex-container'
import Illustration from '@/assets/drawings/bird_empty_settings.png'
import { useTranslation } from 'react-i18next'
import Text from '@/components/atoms/text'
import Button from '@/components/atoms/button'
import { useInitiatives } from '@/hooks/initiatives/useInitiatives'
import { useCallback, useState } from 'react'
import CreateInitiativeDialog from '../modals/CreateInitiativeDialog'

const EmptyInitiatives = () => {
  const { t } = useTranslation()

  const [createInitiativeOpen, setCreateInitiativeOpen] = useState(false)
  const onCreateInitiativeClick = useCallback(() => {
    setCreateInitiativeOpen(true)
  }, [])

  const onCreateInitiative = useCallback((name: string) => {
    createInitiative({ name })
  }, [])

  const { createInitiative } = useInitiatives({ enabled: false })
  return (
    <FlexContainer alignItems="center" css={{ mt: '$sm', mb: '$xs' }} direction="column" gap="sm">
      <img
        alt="A black and white image of a bird and gears."
        height={251}
        width={399}
        src={Illustration}
      />
      <FlexContainer alignItems="center" direction="column" gap="micro">
        <Text typeface="titleBoldXS">{t('emptyInitiativesTitle')}</Text>
        <Text>{t('emptyInitiativesDescription')}</Text>
        <Button onClick={onCreateInitiativeClick} css={{ m: '16px' }}>
          {t('createInitiative')}
        </Button>
      </FlexContainer>

      {createInitiativeOpen && (
        <CreateInitiativeDialog
          open={createInitiativeOpen}
          onOpenChange={setCreateInitiativeOpen}
          onCreate={onCreateInitiative}
        />
      )}
    </FlexContainer>
  )
}

export default EmptyInitiatives
