import { CSVB0X_UPLOAD_KEY, CSVBOX_NPS_SHEET_KEY, CSVBOX_CONVERSATION_SHEET_KEY } from '@/config'
import useUser from '@/hooks/useUser'
import { FeedbackSource } from '@/types/feedbacks'

const npsSheetKeyTypes = ['nps', 'csat', 'review']
const conversationKeyTypes = ['support_ticket', 'social_media_post', 'issue', 'transcript']

const useCSVUpload = (
  feedbackSource: FeedbackSource | undefined
): {
  licenceKey: string
  customData: Record<string, string | undefined>
} => {
  const { currentUser } = useUser()

  if (!feedbackSource) {
    return {
      licenceKey: CSVB0X_UPLOAD_KEY,
      customData: {}
    }
  }

  if (npsSheetKeyTypes.includes(feedbackSource.id.toLowerCase())) {
    return {
      licenceKey: CSVBOX_NPS_SHEET_KEY,
      customData: {
        user_id: currentUser?.user_id,
        organization_id: currentUser?.organization_id,
        kind: feedbackSource.id
      }
    }
  }

  if (conversationKeyTypes.includes(feedbackSource.id.toLowerCase())) {
    return {
      licenceKey: CSVBOX_CONVERSATION_SHEET_KEY,
      customData: {
        user_id: currentUser?.user_id,
        organization_id: currentUser?.organization_id,
        kind: feedbackSource.id
      }
    }
  }

  return {
    licenceKey: CSVB0X_UPLOAD_KEY,
    customData: {
      user_id: currentUser?.user_id,
      organization_id: currentUser?.organization_id,
      feedback_source_value_id: feedbackSource.id,
      data_type: feedbackSource.name
    }
  }
}

export default useCSVUpload
