import { useFiltersStore } from '@/store'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import { shallow } from 'zustand/shallow'

interface Params {
  newFeed?: boolean
}

const useFiltersCount = ({ newFeed = false }: Params = { newFeed: false }) => {
  const useStore = newFeed ? useFeedFiltersStore : useFiltersStore

  const {
    numericFilters,
    datetimeFilters,
    stringFilters,
    booleanFilters,
    textFilters,
    productAreaTopics,
    otherTopics,
    productAreaThemes,
    otherThemes,
    unclassifiedTopics,
    ungroupedTopicsCount,
    datePeriod,
    search,
    accounts,
    accountDateFilters,
    accountNumericFilters,
    accountBooleanFilters,
    users,
    usersDateFilters,
    usersNumericFilters,
    usersBooleanFilters
  } = useStore(
    state => ({
      numericFilters: state.numericFilters,
      datetimeFilters: state.datetimeFilters,
      stringFilters: state.stringFilters,
      booleanFilters: state.booleanFilters,
      textFilters: state.textFilters,
      productAreaTopics: state.productAreaTopics,
      otherTopics: state.otherTopics,
      productAreaThemes: state.productAreaThemes,
      otherThemes: state.otherThemes,
      unclassifiedTopics: state.unclassifiedTopics,
      datePeriod: state.datePeriod,
      search: state.search,
      ungroupedTopicsCount: state.ungroupedTopics.selected.length,
      accounts: Object.values(state.accountsStringFilters).flatMap(value => value.selected).length,
      accountDateFilters: state.accountsDateFilters,
      accountNumericFilters: state.accountNumericFilters,
      accountBooleanFilters: state.accountBooleanFilters,
      users: Object.values(state.usersStringFilters).flatMap(value => value.selected).length,
      usersDateFilters: state.usersDateFilters,
      usersNumericFilters: state.usersNumericFilters,
      usersBooleanFilters: state.usersBooleanFilters
    }),
    shallow
  )

  const numericFiltersCount = numericFilters.filter(filter => filter.option !== 'all').length
  const datetimeFiltersCount = datetimeFilters.filter(filter => filter.value !== null).length
  const stringFiltersCount = stringFilters.reduce((acc, cur) => acc + cur.selected.length, 0)
  const booleanFiltersCount = booleanFilters.filter(filter => filter.value !== null).length
  const textFiltersCount = textFilters.filter(filter => filter.value !== '').length

  const productAreaTopicsCount = productAreaTopics.selected.length
  const otherTopicsCount = otherTopics.selected.length
  const productAreaThemesCount = productAreaThemes.selected.length
  const otherThemesCount = otherThemes.selected.length
  const unclassifiedTopicsCount = unclassifiedTopics.selected.length

  const topicFiltersCount =
    productAreaTopicsCount +
    otherTopicsCount +
    productAreaThemesCount +
    otherThemesCount +
    unclassifiedTopicsCount +
    ungroupedTopicsCount

  const datePeriodCount = datePeriod === '30days' ? 0 : 1
  const searchCount = search.length > 0 ? 1 : 0

  const accountNumericFiltersCount = accountNumericFilters.filter(
    filter => filter.option !== 'all'
  ).length
  const accountDateFiltersCount = accountDateFilters.filter(
    filter => filter.selected !== null
  ).length
  const accountBooleanFiltersCount = accountBooleanFilters.filter(
    filter => filter.value !== null
  ).length

  const usersNumericFiltersCount = usersNumericFilters.filter(
    filter => filter.option !== 'all'
  ).length
  const usersDateFiltersCount = usersDateFilters.filter(filter => filter.selected !== null).length
  const usersBooleanFiltersCount = usersBooleanFilters.filter(
    filter => filter.value !== null
  ).length

  const customerRecordsCount =
    accounts +
    users +
    accountNumericFiltersCount +
    accountDateFiltersCount +
    accountBooleanFiltersCount +
    usersNumericFiltersCount +
    usersDateFiltersCount +
    usersBooleanFiltersCount

  const totalCount =
    numericFiltersCount +
    datetimeFiltersCount +
    stringFiltersCount +
    booleanFiltersCount +
    textFiltersCount +
    topicFiltersCount +
    datePeriodCount +
    searchCount +
    customerRecordsCount

  return {
    numericFiltersCount,
    datetimeFiltersCount,
    stringFiltersCount,
    booleanFiltersCount,
    textFiltersCount,
    productAreaTopicsCount,
    otherTopicsCount,
    productAreaThemesCount,
    otherThemesCount,
    unclassifiedTopicsCount,
    topicFiltersCount,
    customerRecordsCount,
    totalCount
  }
}

export default useFiltersCount
