import { queryClient } from '@/plugins/reactQueryClient'
import NotificationsService from '@/services/NotificationsService'
import openPopup from '@/utils/openPopup'
import { useCallback, useEffect, useState } from 'react'
import useSegment from './useSegment'
import { delay } from '@/utils/delay'
import { SlackChannel } from '@/types/notifications/Notifications'

const useSlackIntegration = () => {
  const { track } = useSegment()

  const [connectToSlackStatus, setConnectToSlackStatus] = useState<
    'open' | 'idle' | 'done-fetching'
  >('idle')

  const connectToSlack = useCallback(async () => {
    const [error, url] = await NotificationsService.getSlackConnectUrl()

    if (error) {
      console.error(error)
      return
    }

    const windowRef = openPopup({ url, width: 650, height: 750 })
    setConnectToSlackStatus('open')
    const interval = setInterval(() => {
      if (!windowRef || windowRef.closed) {
        clearInterval(interval)
        queryClient.invalidateQueries({ queryKey: ['slackChannels'], refetchType: 'none' })
        setConnectToSlackStatus('done-fetching')
      }
    }, 1000)
  }, [])

  useEffect(() => {
    const getHasConnectedToSlack = async () => {
      if (connectToSlackStatus === 'done-fetching') {
        setConnectToSlackStatus('idle')
        await delay(1000)

        const data = await queryClient.fetchQuery<SlackChannel[]>({ queryKey: ['slackChannels'] })
        if (data.length) {
          track('notification_new_channel_connection')
          track('notification_slack_add_channel')
        }
      }
    }

    getHasConnectedToSlack()
  }, [connectToSlackStatus, track])

  return {
    connectToSlack,
    connectToSlackStatus
  }
}

export default useSlackIntegration
