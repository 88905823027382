import { Sentiment, sentimentToFilter } from '@/utils/sentiments'
import useAdvancedFilters from './useAdvancedFilters'
import { useState } from 'react'

interface Params {
  initialValue?: Sentiment
  index?: number
}

const useSentimentFilter = ({ initialValue, index }: Params = {}) => {
  const { addFilter, updateFilter, removeFilter } = useAdvancedFilters()

  const [selectedOption, setSelectedOption] = useState<Sentiment | undefined>(initialValue)

  const apply = () => {
    if (!selectedOption) return
    addFilter(sentimentToFilter(selectedOption))
  }

  const update = () => {
    if (index === undefined) return
    if (selectedOption === undefined) {
      removeFilter({ index })
      return
    }

    updateFilter({ index, filter: sentimentToFilter(selectedOption) })
  }

  const isDisabled = selectedOption === undefined

  return {
    apply,
    update,
    selectedOption,
    setSelectedOption,
    isDisabled
  }
}

export default useSentimentFilter
