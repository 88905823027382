export const space = {
  nano: '4px',
  micro: '8px',
  xxxs: '12px',
  xxs: '16px',
  xs: '24px',
  sm: '32px',
  md: '40px',
  lg: '48px',
  xl: '64px',
  xxl: '80px',
  xxxl: '96px',
  mega: '120px',
  giga: '200px'
}

export const radii = {
  nano: '4px',
  micro: '8px',
  xxs: '12px',
  xs: '16px',
  md: '32px',
  sm: '40px',
  full: '50%'
}
