import * as SelectPrimitive from '@radix-ui/react-select'
import { styled } from '@/theme'

export const CategorySelectTrigger = styled(SelectPrimitive.SelectTrigger, {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  bAll: '$neutralHighPure',
  background: '$white',
  borderRadius: 8,
  boxSizing: 'border-box',
  height: 40,
  width: '100%',
  color: '$neutralLowPure',
  padding: '0 16px',
  gap: 8,
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '$brandPrimaryLight',
    bAll: '$brandPrimaryMedium',
    color: '$brandPrimaryPure'
  },

  '&:active': {
    background: '$brandPrimaryPure',
    color: '$neutralLowLight'
  },

  '&[data-state="open"]': {
    background: '$brandColorPrimaryPure',
    color: '$neutralColorHighLight'
  }
})

export const CategorySelectContent = styled(SelectPrimitive.Content, {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 8,
  bAll: '$stroke',
  overflow: 'hidden',
  zIndex: 9999,
  position: 'relative',
  boxSizing: 'border-box',
  boxShadow: '$soft'
})

export const CategorySelectItem = styled(SelectPrimitive.Item, {
  all: 'unset',
  padding: '0 16px',
  height: 38,
  bAll: 'transparent',
  background: '$white',
  bBottom: '$stroke',
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  gap: 8,
  width: '100%',

  '&:focus-visible': {
    backgroundColor: '$brandPrimaryLight',
    bAll: '$stroke'
  },

  '&:hover': {
    backgroundColor: '$brandPrimaryLight',
    bAll: 'transparent'
  },

  '&[data-state="checked"]': {
    color: '$brandPrimaryPure'
  },

  '&:last-child': {
    bBottom: 'transparent'
  }
})

export const CategorySelectViewport = styled(SelectPrimitive.Viewport, {
  display: 'flex',
  flexDirection: 'column'
})

export const CategorySelectIcon = styled(SelectPrimitive.SelectIcon, {
  marginLeft: 'auto'
})
