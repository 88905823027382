import { styled } from '@/theme'
import Drawing from '@/assets/drawings/assistant_bird.png'
import AssistantBalloonSvg from '@/assets/drawings/assistant_balloon.svg'
import { useUIStore } from '@/store'
import useUser from '@/hooks/useUser'
import { AssistantImageCircle } from '../assistant/Assistant.styles'

const BalloonWrapper = styled('img', {
  position: 'absolute',
  left: 56,
  bottom: 88,
  zIndex: 2,
  pointerEvents: 'none',
  transition: 'cubic-bezier(.34,.19,.44,.85) .2s',

  variants: {
    visible: {
      true: {
        opacity: 1
      },
      false: {
        opacity: 0
      }
    }
  }
})

interface Props {
  onToggle?: (open: boolean) => void
}
const AssistantFloatingButton = ({ onToggle }: Props) => {
  const { userPermissions, currentUser } = useUser()
  const isAssistantOpen = useUIStore(state => state.isAssistantOpen)
  const toggleAssistant = useUIStore(state => state.toggleAssistantOpen)

  const onToggleAssistant = () => {
    onToggle?.(isAssistantOpen)
    toggleAssistant()
  }

  if (!userPermissions.feedback.includes('chat') && currentUser) {
    return <></>
  }

  return (
    <>
      <BalloonWrapper
        alt={'A speech balloon written: "Ask me a question about your product"'}
        src={AssistantBalloonSvg}
        visible={!isAssistantOpen}
      />
      <AssistantImageCircle
        alt="A drawing of a blue bird"
        onClick={onToggleAssistant}
        role="button"
        src={Drawing}
      />
    </>
  )
}

export default AssistantFloatingButton
