export function copyName(name: string): string {
  const regex = /^(.*?)( \((copy)( \d+)?\))$/
  const match = name.match(regex)

  if (!match) {
    return `${name} (copy)`
  }

  const [, baseName, , , copyNumber] = match

  if (copyNumber) {
    const nextCopyNumber = parseInt(copyNumber) + 1
    return `${baseName} (copy ${nextCopyNumber})`
  }

  return `${baseName} (copy 2)`
}
