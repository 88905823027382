import FlexContainer from '@/components/atoms/flex-container'
import { ScrollAreaScrollbar, ScrollAreaThumb } from '@/components/molecules/scroll-area'
import useClassification from '@/hooks/useClassification'
import useSegment from '@/hooks/useSegment'
import { useFeedbackStore, useUIStore } from '@/store'
import { useEffect } from 'react'
import FeedbackChat from '../../components/molecules/chat/Chat'
import DefaultContent from '@/components/molecules/layout/DefaultContent'
import ChatFeedbacks from '@/components/molecules/chat/ChatFeedbacks'

import {
  ExpandButton,
  FeedScrollArea,
  FeedbackChatInfo,
  FeedbackChatViewport,
  FeedbackListContainer
} from '@/components/molecules/feed/feedbacks/Feedbacks.styles'
import AssistantFloatingButton from '@/components/molecules/chat/AssistantFloatingButton'
import { Info, X } from '@phosphor-icons/react'
import useChatStore from '@/store/useChatStore'
import { shallow } from 'zustand/shallow'
import { useChatFeedbackStore } from '@/store/useFeedbackStore'
import Feedbacks from '@/components/molecules/feed/feedbacks'
import NoPermission from '@/components/molecules/no-permission/NoPermission'
import useUser from '@/hooks/useUser'

function FeedbacksWithChatPage() {
  const { track } = useSegment()
  const { verifyAllTopicAndThemes } = useClassification()
  const { setPageTitle, isAssistantOpen, closeAssistant } = useUIStore()

  const isLoading = useChatFeedbackStore(state => state.isLoading)

  const resetFeedback = useFeedbackStore(state => state.reset)
  const resetChatFeedback = useChatFeedbackStore(state => state.reset)
  const { chatData } = useChatStore(
    state => ({
      chatData: state.chatData
    }),
    shallow
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    track('explore_user_chat-refinement')
    setPageTitle('Feedback')
    verifyAllTopicAndThemes()
  }, [])

  const onCloseAssistant = () => {
    resetFeedback()
    closeAssistant()
  }

  const onToggle = (open: boolean) => {
    if (open) {
      resetFeedback()
    } else {
      resetChatFeedback()
    }
  }
  const { userPermissions, currentUser } = useUser()
  if (userPermissions.feedback.length === 0 && currentUser) {
    return <NoPermission />
  }

  const showInfo = isAssistantOpen && !!chatData && !isLoading

  return (
    <DefaultContent>
      <FlexContainer css={{ position: 'relative' }} fullHeight>
        <FeedbackChat />

        <FeedbackListContainer expanded={!isAssistantOpen}>
          <ExpandButton colorScheme="tinted" onClick={onCloseAssistant} visible={isAssistantOpen}>
            <X size={16} weight="regular" />
          </ExpandButton>
          <FeedScrollArea>
            <FeedbackChatInfo visible={showInfo}>
              <Info size={16} />
              <p>
                Considering <strong>{chatData?.feedbackTakenIntoAccount || 0}</strong> feedback out
                of <strong>{chatData?.feedbackTotalHits || 0}</strong> for assistant answer.{' '}
                <a
                  href="https://help.birdie.ai/en/articles/8337913-how-to-use-the-birdie-chat-assistant"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Learn more
                </a>
              </p>
            </FeedbackChatInfo>
            <FeedbackChatViewport id={`scroll-feedback`} showInfo={showInfo}>
              {isAssistantOpen ? <ChatFeedbacks /> : <Feedbacks />}
            </FeedbackChatViewport>
            <ScrollAreaScrollbar orientation="vertical">
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
          </FeedScrollArea>
        </FeedbackListContainer>
        <AssistantFloatingButton onToggle={onToggle} />
      </FlexContainer>
    </DefaultContent>
  )
}

export default FeedbacksWithChatPage
