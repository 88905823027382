import Text from '@/components/atoms/text'
import { ExtractionTaskStatus, IntegrationSource } from '@/types/integrations'
import { CheckCircle, FileCsv } from '@phosphor-icons/react'
import * as S from './UploadItemCard.styles'
import { dateShortMonthFormatWithTime } from '@/utils/date'
import { Trans } from 'react-i18next'
import i18n from '../../../plugins/i18n/i18n'

const formatDate = dateShortMonthFormatWithTime

const mapStatus: Record<ExtractionTaskStatus, string> = {
  canceled: i18n.t('canceled'),
  error: i18n.t('error'),
  finished: i18n.t('imported'),
  running: i18n.t('running')
}

interface Props {
  item: IntegrationSource
}

const UploadItemCard = ({ item }: Props) => {
  const { status } = item.last_task

  const formatLastImport = (() => {
    if (item.last_task?.updated_at) {
      const date = new Date(item.last_task.updated_at)
      return formatDate(date)
    }
    return '-'
  })()

  return (
    <S.Container>
      <S.InnerLeftContainer>
        <Text typeface="subtitleRegularXXXS">
          <Trans
            components={{ bold: <Text typeface="subtitleSemiBoldXXXS" /> }}
            i18nKey="statusLabel"
            values={{ status: mapStatus[status] }}
          />
        </Text>
        <S.IconContainer>
          <FileCsv size={32} />
          {status === 'finished' && <CheckCircle className="check-icon" size={16} weight="bold" />}
        </S.IconContainer>
      </S.InnerLeftContainer>
      <S.InnerRightContainer>
        <Text as="p" typeface="subtitleRegularXXXS">
          <Trans
            components={{ bold: <Text typeface="subtitleSemiBoldXXXS" /> }}
            i18nKey="feedbackRecordsLabel"
            values={{ records: item.records || '-' }}
          />
        </Text>
        {/* <Text as="p" typeface="subtitleRegularXXXS">
          Imported by: <Text typeface="subtitleSemiBoldXXXS">{item.organization.user || '-'}</Text>
        </Text> */}
        <Text as="p" typeface="subtitleRegularXXXS">
          <Trans
            components={{ bold: <Text typeface="subtitleSemiBoldXXXS" /> }}
            i18nKey="importDateLabel"
            values={{ importDate: formatLastImport }}
          />
        </Text>
      </S.InnerRightContainer>
    </S.Container>
  )
}

export default UploadItemCard
