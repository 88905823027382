import useEvents from '@/hooks/useEvents'
import { useFiltersStore } from '@/store'
import FilterItem, { FilterItemButton } from '../FilterItem'

function AppliedSearchFilters() {
  const search = useFiltersStore(state => state.search)
  const setSearch = useFiltersStore(state => state.setSearch)

  const { dispatch } = useEvents()

  function removeFilter() {
    setSearch('')
  }

  function onClick() {
    dispatch('focus-search-filter', {})
  }

  return [
    search.length > 0 ? (
      <FilterItem
        editButton={
          <FilterItemButton
            content={search}
            contentType="label"
            onClick={onClick}
            tooltip={search}
          />
        }
        name="Search"
        onDelete={removeFilter}
        key="search"
      />
    ) : null
  ].filter(Boolean) as JSX.Element[]
}

export default AppliedSearchFilters
