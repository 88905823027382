import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { RadioItem } from '@/components/atoms/radio-group'
import FlexContainer from '@/components/atoms/flex-container'
import { CollapsibleContentAnimated, CollapsibleRoot } from '@/components/atoms/collapsible'
import Slider from '@/components/atoms/slider/Slider'
import { NumericRangeOption } from '@/types/filters/Filters'
import { CSS } from '@/theme'
import Input from '@/components/atoms/input'
import { useMemo } from 'react'

const sliderCSS: CSS = {
  padding: '$xxxs 0'
}

export interface NumericFilterContentProps {
  onOptionChange: (value: string) => void
  onRangeChange: (range: [number, number]) => void
  onValueChange: (range: [number]) => void
  selectedOption: string
  isRangeOpen: boolean
  rangeText: string
  rangeValue: [number, number]
  isExactValueOpen: boolean
  exactValueText: string
  exactValue: [number]
  min: number
  max: number
  labels: Record<NumericRangeOption, string>
}

const NumericFilterContent = ({
  onOptionChange,
  onRangeChange,
  onValueChange,
  selectedOption,
  isRangeOpen,
  rangeValue,
  isExactValueOpen,
  exactValue,
  exactValueText,
  min,
  max,
  labels
}: NumericFilterContentProps) => {
  const onExactValueInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(e.target.value)
    if (Number.isNaN(value)) value = 0
    onValueChange([value])
  }

  const onRangeMinValueInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(e.target.value)
    if (Number.isNaN(value)) value = 0
    onRangeChange([value, rangeValue[1]])
  }

  const onRangeMaxValueInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(e.target.value)
    if (Number.isNaN(value)) value = max
    onRangeChange([rangeValue[0], value])
  }

  const step = useMemo(() => {
    return (max - min) / 20
  }, [min, max])

  return (
    <RadioGroupPrimitive.Root onValueChange={onOptionChange} value={selectedOption}>
      <RadioItem css={{ mb: 2 }} text={labels.all} value="all" />
      <FlexContainer justifyContent="spaceBetween">
        <RadioItem text={labels.between} value="between" />
        {isRangeOpen && (
          <FlexContainer gap="nano" alignItems="center" justifyContent="spaceBetween">
            <Input
              type="number"
              step="0.01"
              value={rangeValue[0]}
              onChange={onRangeMinValueInputChange}
              numericFilter
            />
            -
            <Input
              type="number"
              step="0.01"
              value={rangeValue[1]}
              onChange={onRangeMaxValueInputChange}
              numericFilter
            />
          </FlexContainer>
        )}
      </FlexContainer>
      <CollapsibleRoot open={isRangeOpen}>
        <CollapsibleContentAnimated>
          <Slider
            css={sliderCSS}
            max={max}
            min={min}
            onValueChange={onRangeChange}
            value={rangeValue}
            step={step}
          />
        </CollapsibleContentAnimated>
      </CollapsibleRoot>
      <FlexContainer justifyContent="spaceBetween">
        <RadioItem text={labels.is} value="is" />
        {isExactValueOpen && (
          <Input
            value={exactValueText}
            type="number"
            step="0.01"
            onChange={onExactValueInputChange}
            numericFilter
          />
        )}
      </FlexContainer>
      <CollapsibleRoot open={isExactValueOpen}>
        <CollapsibleContentAnimated>
          <Slider
            css={sliderCSS}
            max={max}
            min={min}
            onValueChange={onValueChange}
            range={false}
            value={exactValue}
            step={step}
          />
        </CollapsibleContentAnimated>
      </CollapsibleRoot>
      <RadioItem text={labels.null} value="null" />
    </RadioGroupPrimitive.Root>
  )
}

export default NumericFilterContent
