import { useState } from 'react'
import { UserPlus } from '@phosphor-icons/react'
import { useNavigate, useParams } from 'react-router-dom'

import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Input from '@/components/atoms/input'
import Text from '@/components/atoms/text'
import ManageUsersModal from '@/components/molecules/manage-users/ManageUsersModal'
import IntegrationService from '@/services/IntegrationServices'
import { useUserStore } from '@/store'
import useToastMessageStore from '@/store/useToastMessageStore'
import {
  ConnectorArguments,
  ConnectorArgumentWithValue,
  IntegrationConnector,
  IntegrationFormData,
  TestConnectionSuccessResponse,
  isTestConnectionError
} from '@/types/integrations'
import { formatConnectorName, resizeLogoUrl, toTestIntegrationPayload } from '../../utils'
import ConnectorArgumentsFields from '../ConnectorArguments'
import { FormStep } from '../IntegrationForm'
import {
  FormFieldContainer,
  FormStepContainer,
  FormStepSectionWithValidation,
  LinkButton
} from '../IntegrationForm.styles'
import { useTranslation } from 'react-i18next'

interface ConfigureStepProps {
  connector: IntegrationConnector
  connectorFields: Record<string, ConnectorArguments>
  disableSubmit: boolean
  formData: IntegrationFormData
  formError: string | undefined
  handleFieldsChange(key: string, field: ConnectorArgumentWithValue): void
  handleNameChange(event: React.ChangeEvent<HTMLInputElement>): void
  setCurrentStep(step: FormStep): void
  setFormError(error: string): void
  setIntegrationSample(data: TestConnectionSuccessResponse): void
}

function ConfigureStep(props: ConfigureStepProps) {
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()

  const addToast = useToastMessageStore(state => state.addToast)
  const currentUser = useUserStore(state => state.currentUser)

  const [showInviteModal, setShowInviteModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  async function submitConfigureStep() {
    try {
      setIsLoading(true)

      const payload = toTestIntegrationPayload(
        props.formData,
        currentUser?.organization_id as string,
        params.integrationId
      )
      const response = await IntegrationService.testIntegration(payload)

      if (isTestConnectionError(response)) {
        throw new Error(response.error_message)
      }

      props.setIntegrationSample(response)
      props.setCurrentStep('review')
    } catch {
      addToast({
        text: t('integrationErrorTryToSetupAgainOrContactUsForHelp'),
        open: true,
        status: 'error'
      })

      props.setFormError('Review the information.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormStepContainer>
      <FlexContainer css={{ gap: 8 }} direction="column">
        <FlexContainer justifyContent="spaceBetween">
          <Text as="h1" typeface="titleBoldXS">
            {t('1platformAuthentication')}
          </Text>
          <FlexContainer alignItems="center" css={{ gap: 12 }}>
            <img alt="" height={40} src={resizeLogoUrl(props.connector.logo_url, 40)} />
            <Text typeface="subtitleSemiBoldXXXS">{formatConnectorName(props.connector.name)}</Text>
          </FlexContainer>
        </FlexContainer>

        <FormStepSectionWithValidation error={Boolean(props.formError)}>
          <Text as="h2" css={{ lineHeight: 1.25 }} typeface="titleBoldXXS">
            {t('mainInformation')}
          </Text>
          <FormFieldContainer>
            <Text as="label" htmlFor="integrationName" typeface="subtitleRegularXXXS">
              {t('giveNameToYourNewIntegration')}
            </Text>
            <Input
              id="integrationName"
              onChange={props.handleNameChange}
              placeholder="Integration name"
              required
              value={props.formData.integrationName}
            />
          </FormFieldContainer>

          <ConnectorArgumentsFields
            connectorId={props.formData.connectorId}
            fieldValues={props.formData.fields}
            fields={props.connectorFields}
            handleChange={props.handleFieldsChange}
          />

          {props.formError ? (
            <Text
              as="strong"
              color="feedbackNegativePure"
              css={{ position: 'absolute', bottom: -24, left: 0 }}
              typeface="paragraphRegularMicro"
            >
              {props.formError}
            </Text>
          ) : null}
        </FormStepSectionWithValidation>
      </FlexContainer>

      <LinkButton onClick={() => setShowInviteModal(true)} type="button">
        <UserPlus size={16} weight="bold" />
        <Text typeface="titleBoldXXS">{t('inviteTeammateToHelpWithThis')}</Text>
      </LinkButton>
      <ManageUsersModal onOpenChange={setShowInviteModal} open={showInviteModal} />

      <FlexContainer css={{ gap: 16 }} justifyContent="flexEnd">
        <Button onClick={() => navigate(-1)} variant="flat">
          {t('cancel')}
        </Button>
        <Button onClick={submitConfigureStep}>
          {isLoading ? t('loading') : t('saveAndContinue')}
        </Button>
      </FlexContainer>
    </FormStepContainer>
  )
}

export default ConfigureStep
