import { useKeywords } from '@/hooks/useKeywords/useKeywords'
import KeywordService from '@/services/KeywordService'
import TopicService from '@/services/TopicService'
import { useKeywordsStore } from '@/store'
import useToastMessageStore from '@/store/useToastMessageStore'
import { Keyword, KeywordWithTopicIds, TopicRelationEvent } from '@/types/keywords'
import { CheckedState } from '@radix-ui/react-checkbox'
import { useCallback, useMemo } from 'react'
import { shallow } from 'zustand/shallow'
import KeywordsCollapsibleRow from './KeywordsCollapsibleRow'
import useEvents from '@/hooks/useEvents'
import useClassification from '@/hooks/useClassification'

interface KeywordsProps {
  keywordList: Keyword[]
  useKeywordTitle?: boolean
}

const MAX_CHECKED_KEYWORDS = 2000

const Keywords = ({ keywordList, useKeywordTitle }: KeywordsProps) => {
  const { maxFrequency } = useKeywords()
  const { checkedKeywords, clickedKeywords, clusters } = useKeywordsStore(
    state => ({
      checkedKeywords: state.checkedKeywords,
      clickedKeywords: state.clickedKeywords,
      clusters: state.data
    }),
    shallow
  )
  const checkKeyword = useKeywordsStore(state => state.checkKeyword)
  const uncheckKeyword = useKeywordsStore(state => state.uncheckKeyword)
  const removeKeyword = useKeywordsStore(state => state.removeKeyword)
  const removeRelationsFromKeywords = useKeywordsStore(state => state.removeRelationsFromKeywords)
  const setClickedKeyword = useKeywordsStore(state => state.setClickedKeyword)

  const { refreshThemes, getTopicById } = useClassification()

  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)

  const { dispatch } = useEvents<TopicRelationEvent>()

  const keywordsLength = useMemo(
    () => clusters.flatMap(cluster => cluster.keywordList).length,
    [clusters]
  )

  const maxCheckedKeywords = useMemo(
    () => (keywordsLength < MAX_CHECKED_KEYWORDS ? keywordsLength : MAX_CHECKED_KEYWORDS),
    [keywordsLength]
  )

  const hasReachedCheckLimit = useMemo(
    () => checkedKeywords.length >= maxCheckedKeywords,
    [checkedKeywords, maxCheckedKeywords]
  )

  const isKeywordChecked = useCallback(
    (keywordHash: string) =>
      checkedKeywords.find(keyword => keyword.keywordHash === keywordHash) !== undefined,
    [checkedKeywords]
  )

  const handleCheckKeyword = useCallback(
    (keyword: KeywordWithTopicIds, checked: CheckedState) => {
      if (checked) {
        checkKeyword(keyword)
      } else {
        uncheckKeyword(keyword.keywordHash)
      }
    },
    [checkKeyword, uncheckKeyword]
  )

  const onClickRow = useCallback(
    (keyword: Keyword) => () => {
      if (!Array.isArray(clickedKeywords)) {
        setClickedKeyword(
          keyword.keywordHash === clickedKeywords?.keywordHash ? undefined : keyword
        )
      } else {
        setClickedKeyword(keyword)
      }
    },
    [setClickedKeyword, clickedKeywords]
  )

  const deleteKeyword = useCallback(
    (keyword: Keyword) => () => {
      const removeToast = addLoadingToast({ text: 'Deleting keyword...' })

      KeywordService.deleteKeyword(keyword.keywordHash, keyword?.text || '')
        .then(() => {
          refreshThemes()
          removeKeyword(keyword.keywordHash)
          setClickedKeyword(undefined)
          uncheckKeyword(keyword.keywordHash)
        })
        .catch(console.error)
        .finally(() => {
          removeToast()
        })
    },
    [refreshThemes, removeKeyword, addLoadingToast, setClickedKeyword, uncheckKeyword]
  )

  const deleteRelation = useCallback(
    function (topicId: string, keywordHash: string) {
      const removeToast = addLoadingToast({ text: 'Deleting relation...' })
      TopicService.removeKeywordRelation(topicId, keywordHash)
        .then(() => {
          refreshThemes()
          removeRelationsFromKeywords({ ids: keywordHash, topicId })
          dispatch('delete-relation', { keywordHash, topicId, text: '', topicIds: [], topics: [] })
        })
        .catch(console.error)
        .finally(() => {
          removeToast()
        })
    },
    [removeRelationsFromKeywords, refreshThemes, addLoadingToast, dispatch]
  )

  const getKeywordTopics = useCallback(
    (keyword: Keyword) => {
      if (useKeywordTitle) return keyword.topics

      return keyword.topics.filter(topic => !!getTopicById(topic.topicId))
    },
    [getTopicById, useKeywordTitle]
  )

  return (
    <>
      {keywordList.map(keyword => (
        <KeywordsCollapsibleRow
          checkboxDisabled={hasReachedCheckLimit && !isKeywordChecked(keyword.keywordHash)}
          checked={isKeywordChecked(keyword.keywordHash)}
          frequency={keyword.frequency}
          id={keyword.keywordHash}
          isClicked={false}
          key={keyword.keywordHash}
          max={maxFrequency}
          name={keyword.text}
          onCheckedChange={checked => handleCheckKeyword(keyword, checked)}
          onClickRow={onClickRow(keyword)}
          onDeleteKeyword={deleteKeyword(keyword)}
          onDeleteRelation={deleteRelation}
          sentiment={keyword.sentiment}
          topics={getKeywordTopics(keyword)}
        />
      ))}
    </>
  )
}

export default Keywords
