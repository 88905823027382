import { styled, typefaceVariants } from '@/theme'

export const SignupContainer = styled('div', {
  width: '100vw',
  minHeight: '100vh',
  height: 'auto',
  padding: '56px 92px',
  boxSizing: 'border-box',
  overflowY: 'auto',

  '.logo': {
    position: 'relative',
    left: -12
  }
})

export const DrawingImage = styled('img', {
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: -1, // to not be on top of the scrollbar
  width: 535
})

export const FormGroup = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$micro',
  label: typefaceVariants.subtitleRegularXXXS,
  width: '100%'
})

const OnboardingGrid = styled('div', {
  display: 'grid',
  justifyContent: 'start',
  rowGap: '$sm',
  columnGap: '$sm',

  marginTop: '$xxs',
  marginBottom: '$xxs'
})

export const UseCasesGrid = styled(OnboardingGrid, {
  gridTemplateColumns: 'auto auto auto'
})

export const FeedbackTypeGrid = styled(OnboardingGrid, {
  gridTemplateColumns: 'auto auto auto auto'
})
