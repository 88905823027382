import { styled } from '@/theme'
import FlexContainer from '@/components/atoms/flex-container'
import { CaretLeft, CaretRight } from '@phosphor-icons/react'

const ArrowContainer = styled(FlexContainer, {
  maxHeight: 148,
  width: 100,

  position: 'absolute',
  top: 0,
  bottom: 0,
  zIndex: 2,

  alignItems: 'center',

  variants: {
    visible: {
      true: {
        display: 'flex'
      },
      false: {
        display: 'none'
      }
    },
    direction: {
      right: {
        right: 0,
        pr: '$xxs',
        justifyContent: 'flex-end',
        background: 'linear-gradient(to right, transparent, white)'
      },
      left: {
        left: 0,
        pl: '$xxs',
        justifyContent: 'flex-start',
        background: 'linear-gradient(to left, transparent, white)'
      }
    }
  },

  defaultVariants: {
    visible: false
  }
})

const ArrowButton = styled('button', {
  all: 'unset',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  boxSizing: 'border-box',
  padding: '$micro',
  background: '$neutralHighLight',
  bAll: '$neutralHighPure',
  br: '$micro'
})

interface ArrowProps {
  direction: 'right' | 'left'
  visible: boolean
  onClick: () => void
}

const ExplorationIndicatorArrow = ({ direction, onClick, visible }: ArrowProps) => {
  return (
    <ArrowContainer direction={direction} visible={visible}>
      <ArrowButton onClick={onClick}>
        {direction === 'right' ? <CaretRight /> : <CaretLeft />}
      </ArrowButton>
    </ArrowContainer>
  )
}

export default ExplorationIndicatorArrow
