import { Outlet, useNavigate } from 'react-router-dom'

import customFieldsEmpty from '@/assets/drawings/custom-fields-empty.png'
import Button from '@/components/atoms/button'
import ErrorBoundary from '@/components/atoms/error-boundary'
import FlexContainer from '@/components/atoms/flex-container'
import { Table, TableCell, TableContainer, TableHeader } from '@/components/atoms/table'
import Text from '@/components/atoms/text'
import ServerError from '@/components/molecules/server-error'
import useUser from '@/hooks/useUser'
import useCustomFieldsV2Query from '@/components/molecules/custom-fields-v2/useCustomFieldsV2Query'
import CustomFieldsTableRowV2 from '@/components/molecules/custom-fields-v2/CustomFieldsTableRow'
import { ArrowDown } from '@phosphor-icons/react'
import CustomFieldsTableRowSkeletonV2 from '@/components/molecules/custom-fields-v2/CustomFieldsTableRowSkeleton'
import { useTranslation } from 'react-i18next'

function CustomFieldsV2() {
  const navigate = useNavigate()

  const { t } = useTranslation()
  const {
    customFields,
    isLoading,
    hasServerError,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = useCustomFieldsV2Query({ enabled: true })

  function navigateToForm() {
    navigate('/settings/custom_fields/new')
  }

  const { userPermissions } = useUser()
  const isManager = userPermissions.source.includes('manager')

  if (hasServerError) return <ServerError />

  const title = t('customFields')
  const subtitle = t(
    'customFieldsAreUsedToBringAdditionalMetadataAboutYourUsersFeedbackTheyAreExtremelyHelpfulToSegmentUsersByGroupsAndAllowMoreGranularAnalyses'
  )

  return (
    <ErrorBoundary>
      {isLoading || customFields.length > 0 ? (
        <FlexContainer css={{ gap: 24, maxWidth: 800 }} direction="column">
          <FlexContainer justifyContent="spaceBetween">
            <FlexContainer css={{ gap: 16, maxWidth: 560 }} direction="column">
              <Text as="h2" typeface="titleBoldXXS">
                {title}
              </Text>
              <Text as="p" typeface="paragraphRegularMicro">
                {subtitle}
              </Text>
            </FlexContainer>

            <Button disabled={!isManager} onClick={navigateToForm} type="button">
              {t('newCustomField')}
            </Button>
          </FlexContainer>

          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <TableHeader css={{ width: 240 }}>{t('fieldName')}</TableHeader>
                  <TableHeader>{t('fieldType')}</TableHeader>
                  {/* <TableHeader>Use as filter</TableHeader> */}
                  <TableHeader>{t('createdAt')}</TableHeader>
                  <TableHeader css={{ width: 24 }} />
                </tr>
              </thead>
              <tbody>
                {!isLoading ? (
                  <>
                    {customFields.map(field => (
                      <CustomFieldsTableRowV2
                        customField={field}
                        isManager={isManager}
                        key={field.id}
                      />
                    ))}

                    {isFetchingNextPage &&
                      Array.from({ length: 4 }, (_, index) => (
                        <CustomFieldsTableRowSkeletonV2 key={index} />
                      ))}

                    {hasNextPage && (
                      <tr>
                        <TableCell colSpan={4} css={{ textAlign: 'center' }}>
                          <Button
                            disabled={isFetchingNextPage}
                            fullWidth
                            onClick={() => fetchNextPage()}
                            variant="link"
                          >
                            <ArrowDown />
                            <span>{t('loadMore')}</span>
                          </Button>
                        </TableCell>
                      </tr>
                    )}
                  </>
                ) : (
                  Array.from({ length: 4 }, (_, index) => (
                    <CustomFieldsTableRowSkeletonV2 key={index} />
                  ))
                )}
              </tbody>
            </Table>
          </TableContainer>
        </FlexContainer>
      ) : (
        <FlexContainer alignItems="center" css={{ maxWidth: 800 }} direction="column">
          <img alt="" height={298} src={customFieldsEmpty} width={478} />
          <Text as="h2" css={{ fontSize: '$md', lineHeight: '$md', fontWeight: '$bold' }}>
            {title}
          </Text>
          <Text css={{ fontSize: '$xxs', lineHeight: '$xxl', maxWidth: 720, textAlign: 'center' }}>
            {subtitle}
          </Text>
          <Button
            css={{ marginTop: '$xs' }}
            disabled={!isManager}
            onClick={navigateToForm}
            type="button"
            variant="exception"
          >
            {t('addCustomField')}
          </Button>
        </FlexContainer>
      )}

      <Outlet />
    </ErrorBoundary>
  )
}

export default CustomFieldsV2
