import { AxiosRequestConfig } from 'axios'
import BaseRequestHandler, { BaseRequestReturnType } from './BaseRequestHandler'
import { birdieClient } from '../clients'
import refreshToken from './refreshToken'

export default class BirdieRequest {
  static requestHandler: BaseRequestHandler = new BaseRequestHandler(birdieClient)
  static baseUrl = birdieClient.defaults.baseURL as string

  static async get<T = unknown>(
    path: string,
    params?: unknown,
    config?: AxiosRequestConfig<unknown>
  ): Promise<BaseRequestReturnType<T>> {
    const [error, response] = await this.requestHandler.get<T>(path, params, config)
    if (error?.statusCode === 401) {
      await refreshToken()
      return this.requestHandler.get<T>(path, params, config)
    }

    return [error, response] as BaseRequestReturnType<T>
  }

  static async post<T = unknown>(
    path: string,
    payload?: unknown,
    config?: AxiosRequestConfig<unknown>
  ): Promise<BaseRequestReturnType<T>> {
    const [error, response] = await this.requestHandler.post<T>(path, payload, config)
    if (error?.statusCode === 401) {
      await refreshToken()
      return this.requestHandler.post<T>(path, payload, config)
    }

    return [error, response] as BaseRequestReturnType<T>
  }

  static async put<T = unknown>(
    path: string,
    payload?: unknown,
    config?: AxiosRequestConfig<unknown>
  ): Promise<BaseRequestReturnType<T>> {
    const [error, response] = await this.requestHandler.put<T>(path, payload, config)
    if (error?.statusCode === 401) {
      await refreshToken()
      return this.requestHandler.put<T>(path, payload, config)
    }

    return [error, response] as BaseRequestReturnType<T>
  }

  static async del<T = unknown>(
    path: string,
    params?: unknown,
    data?: unknown,
    config?: AxiosRequestConfig<unknown>
  ): Promise<BaseRequestReturnType<T>> {
    const [error, response] = await this.requestHandler.del<T>(path, params, data, config)
    if (error?.statusCode === 401) {
      await refreshToken()
      return this.requestHandler.del<T>(path, params, data, config)
    }

    return [error, response] as BaseRequestReturnType<T>
  }

  static async patch<T = unknown>(
    path: string,
    payload?: unknown,
    config?: AxiosRequestConfig<unknown>
  ): Promise<BaseRequestReturnType<T>> {
    const [error, response] = await this.requestHandler.patch<T>(path, payload, config)
    if (error?.statusCode === 401) {
      await refreshToken()
      return this.requestHandler.patch<T>(path, payload, config)
    }

    return [error, response] as BaseRequestReturnType<T>
  }
}
