import Tabs, { TabItem, TabsContent } from '@/components/atoms/tabs'
import OpportunityPlanForm from '@/components/molecules/params/OpportunityPlanForm'
import ToastMessagesProvider from '@/components/molecules/ToastMessagesProvider'
import useLocalStorage from '@/hooks/useLocalStorage'
import { useUIStore } from '@/store'
import useToastMessageStore from '@/store/useToastMessageStore'
import { OPPORTUNITY_PLAN_KEY, OpportunityPlanFormData } from '@/types/params/OpportunityPlanParams'
import { useEffect } from 'react'
import { UPLOAD_PARAMS_KEY, UploadFormData } from '@/types/params/UploadParams'
import UploadParamsForm from '@/components/molecules/params/UploadParamsForm'
import { styled } from '@/theme'
import FlexContainer from '@/components/atoms/flex-container'
import { SUMMARY_PARAMS_KEY, SummaryParamsData } from '@/types/params/SummaryParams'
import SummaryParamForm from '@/components/molecules/params/SummaryParamForm'
import { CALIBRATION_PARAMS_KEY, CalibrationParamsData } from '@/types/params/CalibrationParams'
import CalibrationForm from '@/components/molecules/params/CalibrationForm'

const tabs: TabItem[] = [
  { text: 'Opportunity plan', value: 'opportunity_plan' },
  { text: 'Upload', value: 'upload' },
  { text: 'Summary', value: 'summary' },
  { text: 'Calibration', value: 'calibration' }
]

export const defaultOpportunityPlanFormData: OpportunityPlanFormData = {
  enableBatchSelection: false,
  enableSegments: false,
  enableMoveFeedback: false
}

const defaultUploadFormData: UploadFormData = {
  enableImportData: false,
  enableImportCustomers: false
}

const defaultSummaryParamsData: SummaryParamsData = {
  enabled: false,
  model: undefined,
  focus: undefined,
  max_words: undefined,
  prompt: undefined,
  sample_per_kind: undefined
}

export const defaultCalibrationParamsData: CalibrationParamsData = {
  enabled: false
}

const ParamsContainer = styled(FlexContainer, {
  height: '100%',
  width: 'calc(100% - $xxl)',
  overflowY: 'auto',
  flexDirection: 'column',
  ml: '$xxl'
})

const ParamsPage = () => {
  const { setPageTitle } = useUIStore()

  const [opportunityPlanParams, setOpportunityPlanParams] = useLocalStorage(
    OPPORTUNITY_PLAN_KEY,
    defaultOpportunityPlanFormData
  )

  const [uploadParams, setUploadParams] = useLocalStorage(UPLOAD_PARAMS_KEY, defaultUploadFormData)

  const [summaryParams, setSummaryParams] = useLocalStorage(
    SUMMARY_PARAMS_KEY,
    defaultSummaryParamsData
  )

  const [calibrationParams, setCalibrationParams] = useLocalStorage(
    CALIBRATION_PARAMS_KEY,
    defaultCalibrationParamsData
  )

  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen once
  useEffect(() => {
    setPageTitle('Params')
  }, [])

  const onSaveOpportunityPlanParams = (formData: OpportunityPlanFormData) => {
    setOpportunityPlanParams(formData)
    addSuccessToast({ text: 'Opportunity plan params saved.' })
  }

  const onSaveUploadParams = (formData: UploadFormData) => {
    setUploadParams(formData)
    addSuccessToast({ text: 'Upload params saved.' })
  }

  const onSaveSummaryParams = (formData: SummaryParamsData) => {
    setSummaryParams(formData)
    addSuccessToast({ text: 'Summary params saved.' })
  }

  const onSaveCalibrationParams = (formData: CalibrationParamsData) => {
    setCalibrationParams(formData)
    addSuccessToast({ text: 'Calibration params saved.' })
  }

  const onResetOpportunityPlanParams = () => {
    setOpportunityPlanParams(defaultOpportunityPlanFormData)
    addSuccessToast({ text: 'Opportunity plan default params saved.' })
  }

  const onResetUploadParams = () => {
    setUploadParams(defaultUploadFormData)
    addSuccessToast({ text: 'Upload default params saved.' })
  }

  const onResetSummaryParams = () => {
    setSummaryParams(defaultSummaryParamsData)
    addSuccessToast({ text: 'Summary default params saved.' })
  }

  const onResetCalibrationParams = () => {
    setCalibrationParams(defaultCalibrationParamsData)
    addSuccessToast({ text: 'Calibration default params saved.' })
  }

  return (
    <ParamsContainer>
      <Tabs defaultValue="opportunity_plan" size="regular" stretch={false} tabs={tabs}>
        <TabsContent asChild value="opportunity_plan">
          <OpportunityPlanForm
            data={opportunityPlanParams}
            onReset={onResetOpportunityPlanParams}
            onSave={onSaveOpportunityPlanParams}
          />
        </TabsContent>
        <TabsContent asChild value="upload">
          <UploadParamsForm
            data={uploadParams}
            onReset={onResetUploadParams}
            onSave={onSaveUploadParams}
          />
        </TabsContent>

        <TabsContent asChild value="summary">
          <SummaryParamForm
            data={summaryParams}
            onReset={onResetSummaryParams}
            onSave={onSaveSummaryParams}
          />
        </TabsContent>

        <TabsContent asChild value="calibration">
          <CalibrationForm
            data={calibrationParams}
            onReset={onResetCalibrationParams}
            onSave={onSaveCalibrationParams}
          />
        </TabsContent>
      </Tabs>
      <ToastMessagesProvider />
    </ParamsContainer>
  )
}

export default ParamsPage
