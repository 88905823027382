import KeywordHighlight from '@/components/atoms/keyword-highlight'
import useClassification from '@/hooks/useClassification'
import useKeywordsRelations from '@/hooks/useKeywords/useKeywordsRelations'
import { useKeywordsStore } from '@/store'
import { KeywordTopic } from '@/types/keywords'
import { useEffect, useMemo } from 'react'
import { OnApplyCallback } from '../keyword-editor/KeywordEditor'
import KeywordEditorPopover from '../keyword-editor/KeywordEditorPopover'
import useCopyToClipboard from '@/hooks/useCopyToClipboard'
import useToastMessageStore from '@/store/useToastMessageStore'
import useLogging from '@/hooks/useLogging'
import { makeUniqueArray } from '@/utils/array'

interface KeywordCreatorProps extends React.PropsWithChildren {
  onCancel: () => void
}

function KeywordCreator({ children, onCancel }: KeywordCreatorProps) {
  const newKeyword = useKeywordsStore(state => state.newKeyword)

  const { createKeywordAndAddRelations } = useKeywordsRelations()

  const { topics } = useClassification()

  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const [copyToClipboard, copyStatus, resetStatus] = useCopyToClipboard()

  const { logException } = useLogging({ context: 'keyword-creator' })

  const copyKeyword = () => {
    if (!newKeyword) return
    copyToClipboard(newKeyword)
  }

  const onKeydownEvent = (e: KeyboardEvent) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
      copyKeyword()
    }
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (copyStatus === 'copied') {
      addSuccessToast({ text: 'Text copied to clipboard.', onClose: resetStatus })
    }

    if (copyStatus === 'error') {
      const message = 'Failed to copy to clipboard.'
      logException(new Error(message), { message })
      addErrorToast({ text: message, onClose: resetStatus })
    }
  }, [copyStatus])

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    document.addEventListener('keydown', onKeydownEvent)

    return () => {
      document.removeEventListener('keydown', onKeydownEvent)
    }
  }, [])

  const onApply: OnApplyCallback = async selectedTopics => {
    if (!newKeyword) return 'error'

    const mergedTopicsToAdd: KeywordTopic[] = topics.reduce((acc, topic) => {
      if (topic.isMerged && topic.mergedTopicList) {
        const _filteredMergedTopics = topic.mergedTopicList
          .filter(merged =>
            selectedTopics.find(selectedTopic => selectedTopic.topicId === merged.id)
          )
          .map(merged => ({ topicId: merged.id, name: merged.name }) as KeywordTopic)

        acc.push(..._filteredMergedTopics)

        return acc
      }

      return acc
    }, [] as KeywordTopic[])

    try {
      await createKeywordAndAddRelations({
        keywordText: newKeyword,
        topics: makeUniqueArray('topicId', selectedTopics),
        mergedTopicParents: mergedTopicsToAdd
      })
      return 'success'
    } catch (e) {
      if ((e as Error).message === 'expansion_limit') {
        logException(e, { message: 'Keyword expansion limit reached.' })
        return 'expansion_limit'
      }
      logException(e, { message: 'Failed to create keyword and add relations.' })
      return 'error'
    }
  }

  const appliedTopics = useMemo(() => [], [])

  return (
    <KeywordEditorPopover
      appliedTopics={appliedTopics}
      customButton
      defaultOpen
      newKeyword
      onApply={onApply}
      onCancel={onCancel}
      onClipboardClick={copyKeyword}
    >
      <KeywordHighlight>{children}</KeywordHighlight>
    </KeywordEditorPopover>
  )
}

export default KeywordCreator
