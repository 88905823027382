import { Fragment, forwardRef } from 'react'
import StyledTableV2, { getCommonPinningStyles } from './styles'
import { TableV2Props } from './types'
import { Column, Header, Table, flexRender } from '@tanstack/react-table'
import { CheckboxState } from '../checkbox'

const TableV2 = forwardRef<HTMLDivElement, TableV2Props>(
  (
    {
      table,
      enableResizeColumn,
      enableSort,
      rootProps,
      sortIndicator,
      tdCss,
      thCss,
      trCss,
      thContainerProps,
      renderSubComponent,
      tableDataRender,
      tableHeaderRender,
      footer,
      dimmed = false
    },
    ref
  ) => {
    const getSortOrder = (header: Header<unknown, unknown>) => {
      const canSort = header.column.getCanSort()
      if (!canSort) return

      return header.column.getNextSortingOrder() === 'asc' ? 'asc' : 'desc'
    }

    const getIsSortActive = (header: Header<unknown, unknown>) => {
      const canSort = header.column.getCanSort()
      if (!canSort) return false

      return header.column.getIsSorted() !== false
    }

    const getHeaderAlignment = (header: Header<unknown, unknown>) => {
      if (header.index === 0) return 'center'

      return header.subHeaders.length > 0 ? 'center' : 'flexEnd'
    }

    const getHeaderIsLastCenteredColumn = (header: Header<unknown, unknown>) => {
      const { column } = header
      let isLastCenteredColumn = column.getIsLastColumn('center')

      if (!isLastCenteredColumn && header.subHeaders.length > 0) {
        isLastCenteredColumn = !!header.subHeaders.find(subHeader =>
          subHeader.column.getIsLastColumn('center')
        )
      }

      return isLastCenteredColumn
    }

    const getIsLastCellOfGroup = (column: Column<unknown, unknown>) => {
      const { parent } = column

      if (!parent) return true
      if (parent.columns.length <= 1) return true
      const lastColumn = parent.columns.filter(col => col.getIsVisible()).at(-1)

      return lastColumn?.id === column.id
    }

    const isHeaderLastCellOfGroup = (column: Column<unknown, unknown>) => {
      const { parent } = column
      if (!parent) return true

      const columns = parent.columns
      if (columns.length <= 1) return true

      const lastColumn = columns.filter(col => col.getIsVisible()).at(-1)
      return lastColumn?.id === column.id
    }

    return (
      <StyledTableV2.RootTable ref={ref} {...rootProps} dimmed={dimmed}>
        <StyledTableV2.Table dimmed={dimmed}>
          <StyledTableV2.TableHead dimmed={dimmed}>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  const { column } = header

                  const isSortActive = getIsSortActive(header)

                  return (
                    <StyledTableV2.TableHeader
                      dimmed={dimmed}
                      colSpan={header.colSpan}
                      css={{
                        minWidth: column.getSize(),
                        maxWidth: column.getSize(),
                        ...getCommonPinningStyles(column, dimmed),
                        ...thCss?.(header)
                      }}
                      depth={header.depth as 1 | 2}
                      id={`tablev2-header-${header.id}-${header.depth}`}
                      isLastColumn={
                        getHeaderIsLastCenteredColumn(header) || column.getIsLastColumn('right')
                      }
                      isLastOfGroup={isHeaderLastCellOfGroup(column)}
                      isPlaceholder={
                        header.isPlaceholder || (header.depth === 1 && header.subHeaders.length > 0)
                      }
                      isSortActive={isSortActive}
                      key={header.id}
                      onClick={
                        enableSort && !header.isPlaceholder
                          ? header.column.getToggleSortingHandler()
                          : undefined
                      }
                      sortEnabled={
                        enableSort && !header.isPlaceholder && header.column.getCanSort()
                      }
                    >
                      {tableHeaderRender ? (
                        tableHeaderRender(header)
                      ) : (
                        <StyledTableV2.HeaderContainer
                          dimmed={dimmed}
                          justifyContent={getHeaderAlignment(header)}
                          {...thContainerProps?.(header)}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}

                          {enableSort && !header.isPlaceholder && (
                            <>
                              <StyledTableV2.SortIndicatorContainer
                                active={isSortActive}
                                order={getSortOrder(header)}
                              >
                                {sortIndicator}
                              </StyledTableV2.SortIndicatorContainer>
                            </>
                          )}
                        </StyledTableV2.HeaderContainer>
                      )}
                      {enableResizeColumn && (
                        <StyledTableV2.Resizer
                          isResizing={column.getIsResizing()}
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler()
                          }}
                        />
                      )}
                    </StyledTableV2.TableHeader>
                  )
                })}
              </tr>
            ))}
          </StyledTableV2.TableHead>
          <tbody>
            {(table.getRowModel()?.rows ?? []).map(row => (
              <Fragment key={row.id}>
                <StyledTableV2.TableRow css={trCss?.(row)} key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    const { column } = cell

                    return (
                      <StyledTableV2.TableData
                        dimmed={dimmed}
                        css={{
                          minWidth: column.getSize(),
                          maxWidth: column.getSize(),
                          // IMPORTANT: This is where the magic happens! (pinning)
                          ...getCommonPinningStyles(column, dimmed),
                          ...tdCss?.(cell)
                        }}
                        isLastColumn={
                          column.getIsLastColumn('center') || column.getIsLastColumn('right')
                        }
                        isLastColumnFromGroup={getIsLastCellOfGroup(column)}
                        isSortActive={column.getIsSorted() !== false}
                        key={cell.id}
                      >
                        {tableDataRender
                          ? tableDataRender(cell)
                          : flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </StyledTableV2.TableData>
                    )
                  })}
                </StyledTableV2.TableRow>
                {row.getIsExpanded() && !!renderSubComponent && renderSubComponent({ row })}
              </Fragment>
            ))}
          </tbody>
        </StyledTableV2.Table>
        {footer}
      </StyledTableV2.RootTable>
    )
  }
)

TableV2.displayName = 'TableV2'

export default TableV2

export const getCheckboxState = <T = unknown>(table: Table<T>): CheckboxState => {
  if (table.getIsAllRowsSelected()) return true
  if (table.getIsSomeRowsSelected()) return 'indeterminate'
  return false
}
