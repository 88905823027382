import { styled } from '@/theme'

const Root = styled('div', {
  height: 4,
  width: '100%',
  background: '$neutralHighMedium',
  display: 'flex',
  justifyContent: 'stretch'
})

const Indicator = styled('div', {
  height: 4,
  flex: 1,
  background: 'transparent',
  transition: '.2s',

  variants: {
    intention: {
      issue: {},
      request: {},
      question: {},
      compliment: {}
    },
    active: {
      true: {},
      false: {}
    }
  },

  compoundVariants: [
    {
      intention: 'issue',
      active: true,
      css: {
        background: '$feedbackNegativePure'
      }
    },
    {
      intention: 'request',
      active: true,
      css: {
        background: '$feedbackInformativePure'
      }
    },
    {
      intention: 'question',
      active: true,
      css: {
        background: '$feedbackWarningPure'
      }
    },
    {
      intention: 'compliment',
      active: true,
      css: {
        background: '$feedbackPositivePure'
      }
    }
  ]
})

const CarouselIndicator = {
  Root,
  Indicator
}

export default CarouselIndicator
