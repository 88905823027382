import { DeleteDialog } from '@/components/atoms/dialog'
import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'
import { AlertData } from '@/types/alerts/Alerts'
import { useTranslation } from 'react-i18next'

const Chip = styled('span', {
  bc: '$neutralHighMedium',
  br: 4,
  padding: '$nano $xxxs $nano $xxxs',
  fontSize: '$micro',
  color: '$neutralLowMedium'
})

interface Props {
  notification: AlertData | null | undefined
  isDeleting: boolean
  deleteMode: 'normal' | 'unsubscribe'
  open: boolean
  onOpenChange: (value: boolean) => void
  onConfirmDelete: () => void
}

const DeleteNotificationModal = ({
  deleteMode,
  notification,
  isDeleting,
  open,
  onOpenChange,
  onConfirmDelete
}: Props) => {
  const { t } = useTranslation()
  const title =
    deleteMode === 'unsubscribe'
      ? t('areYouSureYouWantToUnsubscribe')
      : t('areYouSureYouWantToDeleteNotification')

  const getDescription = () => {
    if (deleteMode === 'unsubscribe')
      return t(
        'youAreTheOnlyUserSubscribedToThisNotificationIfYouProceedWeAreGoingToDeleteThisNotification'
      )

    const notificationEmails =
      notification?.notificationConfig.find(config => config.type === 'email')?.value ?? []

    const notificationChannels =
      notification?.notificationConfig.find(config => config.type === 'slack')?.value ?? []

    return (
      <>
        {t('ifYouProceedThisNotificationsWillBeNoLongerSendTo')}
        <FlexContainer css={{ mt: '$micro', overflow: 'hidden' }} flow="wrap" gap="micro">
          {[...notificationEmails, ...notificationChannels].map(value => (
            <Chip key={value}>{value}</Chip>
          ))}
        </FlexContainer>
      </>
    )
  }

  return (
    <DeleteDialog
      cancelText={t('cancel')}
      confirmText={deleteMode === 'unsubscribe' ? t('unsubscribe') : t('delete')}
      description={getDescription()}
      isDeleting={isDeleting}
      onConfirmDelete={onConfirmDelete}
      onOpenChange={onOpenChange}
      open={open}
      title={title}
      width="medium"
    />
  )
}

export default DeleteNotificationModal
