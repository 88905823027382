import { shallow } from 'zustand/shallow'
import { Info } from '@phosphor-icons/react'
import useChatStore from '@/store/useChatStore'
import FlexContainer from '@/components/atoms/flex-container'
import Popover from '@/components/atoms/popover'
import { colors } from '@/theme'
import Text from '@/components/atoms/text'
import DiscoveryOptions from './DiscoveryOptions'
import { useTranslation } from 'react-i18next'

function DiscoveryAssistantOptions() {
  const { chatData, isLoadingChatData } = useChatStore(
    state => ({ chatData: state.chatData, isLoadingChatData: state.isLoadingChatData }),
    shallow
  )
  const { t } = useTranslation()

  return (
    <>
      <FlexContainer
        alignItems="center"
        css={{
          '& svg': { minWidth: 18, mb: '-3px', ml: '$nano', cursor: 'pointer' },
          maxWidth: 375
        }}
        gap="nano"
      >
        {!isLoadingChatData && (
          <Text
            as="p"
            color="neutralLowLight"
            fontSize="micro"
            fontWeight="regular"
            lineHeight="lg"
          >
            <>
              {t('showingFindingsBasedOn')}{' '}
              <strong>{chatData?.feedbackTakenIntoAccount || 0} feedback </strong>{' '}
              {t('consideredByBirdieAssistantThatMatchesTheFilterSearch')}
              <Popover
                buttonChildren={<Info color={colors.neutralLowLight} size={18} />}
                customButton
              >
                <FlexContainer
                  css={{
                    padding: '$xxs',
                    width: '480px',
                    maxHeight: 'var(--radix-popover-content-available-height)'
                  }}
                  direction="column"
                  gap="micro"
                >
                  <Text as="p" fontSize="xxxs" fontWeight="regular" lineHeight="xl">
                    {t(
                      'ourChatBotEmploysAConceptCalledAContextWindowWhichDeterminesTheAmountOfInformationItCanConsiderWhileGeneratingResponsesToMaintainAccuracyAndEfficiencyWeSelectASubsetOfTheFirst'
                    )}{' '}
                    <strong>{chatData?.feedbackTakenIntoAccount || 0} feedback </strong>
                    {t(
                      'toFitWithinThisContextWindowByDoingSoWeEnsureThatTheChatBotCanProvideInsightfulResponsesBasedOnRelevantInformationWhileTakingIntoAccountTheLimitationsImposedByTheContextWindowThisApproachStrikesABalanceBetweenPerformanceAndResponsivenessEnhancingTheOverallUserExperience'
                    )}
                  </Text>

                  <Text as="p" fontSize="xxxs" fontWeight="regular" lineHeight="xl">
                    {t(
                      'byDoingSoWeEnsureThatTheChatBotCanProvideInsightfulResponsesBasedOnRelevantInformationWhileTakingIntoAccountTheLimitationsImposedByTheContextWindowThisApproachStrikesABalanceBetweenPerformanceAndResponsivenessEnhancingTheOverallUserExperience'
                    )}
                  </Text>
                </FlexContainer>
              </Popover>
            </>
          </Text>
        )}
      </FlexContainer>

      <DiscoveryOptions />
    </>
  )
}

export default DiscoveryAssistantOptions
