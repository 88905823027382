import FlexContainer from '@/components/atoms/flex-container'
import { TopicData } from '@/types/classification'
import { ChatCenteredText, Check, X } from '@phosphor-icons/react'
import { MergingTopicRowContainer } from './MergeTopicsModal.styles'

interface MergingTopicRowProps {
  topic: TopicData
  removed: boolean
  onClick: () => void
  isSuggestion?: boolean
}

const MergingTopicRow = ({
  topic,
  removed,
  onClick,
  isSuggestion = false
}: MergingTopicRowProps) => {
  return (
    <MergingTopicRowContainer merging onClick={onClick} removed={removed} suggestion={isSuggestion}>
      <FlexContainer alignItems="center" gap="nano">
        <div className="icon">
          <Check className="check-icon" />
          <X className="remove-icon" />
        </div>
        <span>{topic.topicName}</span>
      </FlexContainer>
      <FlexContainer alignItems="center" gap="nano">
        <ChatCenteredText />
        <span>{topic.totalFrequency}</span>
      </FlexContainer>
    </MergingTopicRowContainer>
  )
}

export default MergingTopicRow
