import { ReactNode } from 'react'
import { ContentContainer, ExploreContainer, PageContainer } from './styles'

import ToastMessagesProvider from '../ToastMessagesProvider'
import { CSS } from '@/theme'
import AdvancedFiltersHeader from '../filters/advanced/AdvancedFiltersHeader'
import AdvancedFiltersArea from '../filters/advanced/AdvancedFiltersArea'
import { useUIStore } from '@/store'
import { shallow } from 'zustand/shallow'

interface Props {
  children: ReactNode
  searchPlaceholder?: string
  css?: CSS
}

const FeedSearchContent = ({ children, searchPlaceholder, css }: Props) => {
  const collapsed = useUIStore(state => !state.isAdvancedFiltersOpen, shallow)

  return (
    <PageContainer css={css} opportunityPlan>
      <AdvancedFiltersHeader searchPlaceholder={searchPlaceholder} />
      <ExploreContainer>
        <AdvancedFiltersArea />
        <ContentContainer isFiltersOpen={!collapsed}>{children}</ContentContainer>
      </ExploreContainer>
      <ToastMessagesProvider />
    </PageContainer>
  )
}

export default FeedSearchContent
