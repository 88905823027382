import Bone from '@/components/atoms/bone'

const NotificationsSkeleton = () => (
  <>
    <Bone css={{ height: 72 }} />
    <Bone css={{ height: 72 }} />
    <Bone css={{ height: 72 }} />
    <Bone css={{ height: 72 }} />
    <Bone css={{ height: 72 }} />
  </>
)

export default NotificationsSkeleton
