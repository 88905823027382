import {
  API_URL,
  BIRDE_API_URL,
  DASHBOARD_STATE_API,
  DASHBOARD_STATE_KEY,
  FEEDBACK_RIVER_API_URL
} from '@/config'
import axios, { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios'

const addDefaultAuthorizationInterceptor = (config: InternalAxiosRequestConfig) => {
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${localStorage.getItem('access_token')}`
    } as AxiosRequestHeaders
  }
}

const searchClient = axios.create({
  baseURL: API_URL
})
searchClient.interceptors.request.use(addDefaultAuthorizationInterceptor, Promise.reject)

const birdieClient = axios.create({
  baseURL: BIRDE_API_URL
})
birdieClient.interceptors.request.use(addDefaultAuthorizationInterceptor, Promise.reject)

const feedbackRiverClient = axios.create({
  baseURL: FEEDBACK_RIVER_API_URL
})
feedbackRiverClient.interceptors.request.use(addDefaultAuthorizationInterceptor, Promise.reject)

const dashboardStateClient = axios.create({
  baseURL: DASHBOARD_STATE_API
})
dashboardStateClient.interceptors.request.use(config => {
  return {
    ...config,
    headers: {
      ...config.headers,
      'X-Birdie-Psk': DASHBOARD_STATE_KEY,
      'Content-Type': 'application/json'
    } as unknown as AxiosRequestHeaders
  }
}, Promise.reject)

export {
  searchClient,
  birdieClient,
  feedbackRiverClient,
  dashboardStateClient,
  addDefaultAuthorizationInterceptor
}
export default searchClient
