import { useState } from 'react'
import FilterSubMenu from '../AddFilterMenu/FilterSubMenu'

import useStringGenericFilter from './useStringGenericFilterNew'
import StringFilterContent from './StringFilterContentNew'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { isAreaOfInterestFilter } from '@/utils/areaOfInterest'
import SearchInputForFilters from '../../SearchInputFilter'
import { useTranslation } from 'react-i18next'

interface Props {
  previousTitle: string
  title: string
  name: string
  filterKey: string
  disableSearch?: boolean
}

const StringFilterSubMenu = ({
  previousTitle,
  title,
  name,
  filterKey,
  disableSearch = false
}: Props) => {
  const [open, setOpen] = useState(false)

  const { isLoading, isChecked, selectOption, hasChanges, apply, options, selectedOptions } =
    useStringGenericFilter({ filterKey, name, enabled: open })

  const areaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const { t } = useTranslation()

  const [searchText, setSearchText] = useState('')
  const filteredOptions = options.filter(option =>
    option.toLowerCase().includes(searchText.toLowerCase())
  )

  if (
    isAreaOfInterestFilter({
      key: filterKey,
      values: selectedOptions,
      areaOfInterest
    })
  )
    return <></>

  return (
    <FilterSubMenu
      isDisabled={!hasChanges}
      isLoading={isLoading}
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      previousTitle={previousTitle}
      searchComponent={
        !disableSearch && (
          <SearchInputForFilters
            css={{ my: '$micro' }}
            onChange={e => setSearchText(e.currentTarget.value)}
            placeholder={t('search')}
            size="small"
            small
            value={searchText}
          />
        )
      }
      title={title}
    >
      <StringFilterContent
        isChecked={isChecked}
        options={filteredOptions}
        selectOption={selectOption}
      />
    </FilterSubMenu>
  )
}

export default StringFilterSubMenu
