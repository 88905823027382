import useNumberAdvancedFilter from '@/hooks/advancedFilters/useNumberAdvancedFilter'
import { NumericRangeOption } from '@/types/filters/Filters'
import { ReactNode, useState } from 'react'
import FilterSubMenu from '../../FiltersSidebar/AddFilterMenu/FilterSubMenu'
import { NumericFilterContent } from '../../FiltersSidebar/NumericGenericFilter'
import { NumberFilterType } from '@/types/filters/AdvancedFilters'
import i18n from '../../../../../plugins/i18n/i18n'

const defaultLabels: Record<NumericRangeOption, string> = {
  all: i18n.t('allValues'),
  between: i18n.t('range'),
  is: i18n.t('exactlyValue'),
  null: i18n.t('emptyValues')
}

interface Props {
  title: string
  previousTitle?: string
  name: string
  path: string
  labels?: Record<NumericRangeOption, string>
  icon?: ReactNode
  type?: NumberFilterType
}

const NumberFilterSubMenu = ({
  title,
  previousTitle,
  name,
  path,
  icon,
  labels = defaultLabels,
  type = 'number'
}: Props) => {
  const [open, setOpen] = useState(false)

  const {
    exactValue,
    exactValueText,
    isExactValueOpen,
    isLoading,
    isRangeOpen,
    max,
    min,
    apply,
    onOptionChange,
    onRangeChange,
    onValueChange,
    rangeText,
    rangeValue,
    selectedOption
  } = useNumberAdvancedFilter({
    name,
    path,
    enableQuery: open,
    type
  })

  return (
    <FilterSubMenu
      icon={icon}
      isLoading={isLoading}
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      previousTitle={previousTitle}
      title={title}
    >
      <NumericFilterContent
        exactValue={exactValue}
        exactValueText={exactValueText}
        isExactValueOpen={isExactValueOpen}
        isRangeOpen={isRangeOpen}
        labels={labels}
        max={max}
        min={min}
        onOptionChange={onOptionChange}
        onRangeChange={onRangeChange}
        onValueChange={onValueChange}
        rangeText={rangeText}
        rangeValue={rangeValue}
        selectedOption={selectedOption}
      />
    </FilterSubMenu>
  )
}

export default NumberFilterSubMenu
