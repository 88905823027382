import { colors } from '@/theme'
import React, { ReactNode } from 'react'
import {
  ScrollAreaViewport,
  ScrollAreaScrollbar,
  ScrollAreaThumb
} from '@/components/molecules/scroll-area'
import { CheckedState } from '@radix-ui/react-checkbox'
import { useUIStore } from '@/store'
import { shallow } from 'zustand/shallow'
import { CaretDown, CaretUp } from '@phosphor-icons/react'
import Checkbox from '@/components/atoms/checkbox'
import {
  TopicListHeader,
  TopicListHeaderCell,
  TopicListScrollArea,
  TopicListContainer
} from './TopicListWrapper.styles'
import useTopicsStore from '@/store/useTopicsStore'
import { useTopics } from '@/hooks/useTopics/useTopics'

interface TopicListWrapperProps {
  onCheckedAllChange: (checked: CheckedState) => void
  checkedAll: CheckedState
  children: ReactNode
}

const TopicListWrapper = (props: TopicListWrapperProps) => {
  const { reset } = useTopics()

  const topicSortType = useTopicsStore(state => state.topicSortType, shallow)
  const topicSortDirection = useTopicsStore(state => state.topicSortDirection, shallow)
  const setTopicSortType = useTopicsStore(state => state.setTopicSortType)
  const setTopicSortDirection = useTopicsStore(state => state.setTopicSortDirection)
  const isExpanded = useUIStore(state => state.classificationCollapsed, shallow)

  const onClickCell = (type: 'net-sentiment' | 'frequency' | 'merged' | 'topic-group') => () => {
    reset()
    if (type === 'net-sentiment') {
      if (topicSortType !== 'net_sentiment') {
        setTopicSortType('net_sentiment')
        setTopicSortDirection('desc')
        return
      }

      setTopicSortDirection(topicSortDirection === 'desc' ? 'asc' : 'desc')
      return
    }

    if (type === 'frequency') {
      if (topicSortType !== 'volume') {
        setTopicSortType('volume')
        setTopicSortDirection('desc')
        return
      }

      setTopicSortDirection(topicSortDirection === 'desc' ? 'asc' : 'desc')
      return
    }

    if (type === 'merged') {
      if (topicSortType !== 'merged') {
        setTopicSortType('merged')
        setTopicSortDirection('desc')
        return
      }

      setTopicSortDirection(topicSortDirection === 'desc' ? 'asc' : 'desc')
      return
    }

    if (type === 'topic-group') {
      if (topicSortType !== 'topic_group') {
        setTopicSortType('topic_group')
        setTopicSortDirection('desc')
        return
      }

      setTopicSortDirection(topicSortDirection === 'desc' ? 'asc' : 'desc')
    }
  }

  return (
    <React.Fragment>
      <TopicListHeader>
        <TopicListHeaderCell>
          <Checkbox
            checked={props.checkedAll}
            defaultChecked={false}
            onCheckedChange={props.onCheckedAllChange}
            title="Select all"
            value="keywords-all"
          />
          <span>Topics</span>
        </TopicListHeaderCell>
        <TopicListHeaderCell clickable onClick={onClickCell('merged')}>
          Merged
          {topicSortDirection === 'desc' ? (
            <CaretUp
              color={topicSortType === 'merged' ? colors.neutralLowLight : colors.grey}
              size={14}
              weight={topicSortType === 'merged' ? 'bold' : 'regular'}
            />
          ) : (
            <CaretDown
              color={topicSortType === 'merged' ? colors.neutralLowLight : colors.grey}
              size={14}
              weight={topicSortType === 'merged' ? 'bold' : 'regular'}
            />
          )}
        </TopicListHeaderCell>
        <TopicListHeaderCell clickable onClick={onClickCell('topic-group')}>
          Topic group
          {topicSortDirection === 'desc' ? (
            <CaretUp
              color={topicSortType === 'topic_group' ? colors.neutralLowLight : colors.grey}
              size={14}
              weight={topicSortType === 'topic_group' ? 'bold' : 'regular'}
            />
          ) : (
            <CaretDown
              color={topicSortType === 'topic_group' ? colors.neutralLowLight : colors.grey}
              size={14}
              weight={topicSortType === 'topic_group' ? 'bold' : 'regular'}
            />
          )}
        </TopicListHeaderCell>
        <TopicListHeaderCell clickable justifyCenter onClick={onClickCell('net-sentiment')}>
          Net sentiment
          {topicSortDirection === 'desc' ? (
            <CaretUp
              color={topicSortType === 'net_sentiment' ? colors.neutralLowLight : colors.grey}
              size={14}
              weight={topicSortType === 'net_sentiment' ? 'bold' : 'regular'}
            />
          ) : (
            <CaretDown
              color={topicSortType === 'net_sentiment' ? colors.neutralLowLight : colors.grey}
              size={14}
              weight={topicSortType === 'net_sentiment' ? 'bold' : 'regular'}
            />
          )}
        </TopicListHeaderCell>
        <TopicListHeaderCell clickable onClick={onClickCell('frequency')}>
          Volume
          {topicSortDirection === 'desc' ? (
            <CaretUp
              color={topicSortType === 'volume' ? colors.neutralLowLight : colors.grey}
              size={14}
              weight={topicSortType === 'volume' ? 'bold' : 'regular'}
            />
          ) : (
            <CaretDown
              color={topicSortType === 'volume' ? colors.neutralLowLight : colors.grey}
              size={14}
              weight={topicSortType === 'volume' ? 'bold' : 'regular'}
            />
          )}
        </TopicListHeaderCell>
      </TopicListHeader>
      <TopicListScrollArea expanded={isExpanded} type="auto">
        <ScrollAreaViewport id="list-root-keyword">
          <TopicListContainer>{props.children}</TopicListContainer>
        </ScrollAreaViewport>
        <ScrollAreaScrollbar orientation="vertical">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </TopicListScrollArea>
    </React.Fragment>
  )
}

export default TopicListWrapper
