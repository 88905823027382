import { ParamsFormProps } from './GenericFeatureToggleForm'
import { ChangeEvent, useEffect, useState } from 'react'
import Text from '@/components/atoms/text'
import { ParamsContainer, ParamsInputContainer } from './ParamsForms.styles'
import Input, { TextArea } from '@/components/atoms/input'
import ParamsButtons from './ParamsButtons'
import Switch from '@/components/atoms/switch'
import { SummaryParamsData } from '@/types/params/SummaryParams'
import { API_URL } from '@/config'

type Props = ParamsFormProps<SummaryParamsData>

const SummaryParamForm = ({ data, onSave, onReset }: Props) => {
  const [formData, setFormData] = useState(data)

  useEffect(() => setFormData(data), [data])

  const handleEnableChange = (value: boolean) => {
    setFormData(prev => ({ ...prev, enabled: value }))
  }

  const handleChange =
    (key: keyof SummaryParamsData) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.currentTarget.value
      setFormData(prev => ({ ...prev, [key]: value.length > 0 ? String(value) : null }))
    }

  return (
    <ParamsContainer>
      <ParamsInputContainer direction="row" maxWidth="large">
        <Text as="label" htmlFor="enabledSummary" typeface="textBodySmallRegular">
          Enabled?
        </Text>
        <Switch checked={formData.enabled as boolean} onCheckedChange={handleEnableChange} />
      </ParamsInputContainer>

      <Text typeface="textRegularXSmall" css={{ maxWidth: 400 }}>
        {`Check the API document for '/feed/summary' `}
        <Text
          as="a"
          css={{ textDecoration: 'underline !important' }}
          target="_blank"
          rel="noreferrer noopener"
          typeface="textRegularXSmall"
          href={`${API_URL}docs#/Feed/Generate_summary_for_feedback_feed_summary_get`}
        >
          here.
        </Text>
      </Text>

      <ParamsInputContainer maxWidth="large">
        <Text as="label" htmlFor={'model'} typeface="textBodySmallRegular">
          model
        </Text>
        <Input
          id="model"
          disabled={!formData.enabled}
          onChange={handleChange('model')}
          value={typeof formData.model === 'string' ? (formData.model as string) : ''}
        />
      </ParamsInputContainer>

      <ParamsInputContainer maxWidth="large">
        <Text as="label" htmlFor={'focus'} typeface="textBodySmallRegular">
          focus
        </Text>
        {/* <Text typeface="textRegularXSmall">{description}</Text> */}
        <Input
          id="focus"
          disabled={!formData.enabled}
          onChange={handleChange('focus')}
          value={typeof formData.focus === 'string' ? (formData.focus as string) : ''}
        />
      </ParamsInputContainer>

      <ParamsInputContainer maxWidth="large">
        <Text as="label" htmlFor={'max_words'} typeface="textBodySmallRegular">
          max_words
        </Text>
        {/* <Text typeface="textRegularXSmall">{description}</Text> */}
        <Input
          id="max_words"
          type="number"
          disabled={!formData.enabled}
          onChange={handleChange('max_words')}
          value={typeof formData.max_words === 'string' ? `${formData.max_words}` : ''}
        />
      </ParamsInputContainer>

      <ParamsInputContainer maxWidth="large">
        <Text as="label" htmlFor={'prompt'} typeface="textBodySmallRegular">
          prompt
        </Text>
        <Text typeface="textRegularXSmall">{`Defines the prompt to use for the summary, YOU HAVE TO PUT THE FIELDS AS A FORMATABLE STRING : '{organization_name}', '{language}', '{focus}', '{max_words}', '{feedbacks}', is has to have the '{}'`}</Text>
        <TextArea
          id="prompt"
          disabled={!formData.enabled}
          css={{ whiteSpace: 'pre-wrap', lineHeight: '$lg' }}
          onChange={handleChange('prompt')}
          rows={14}
          cols={12}
          value={typeof formData.prompt === 'string' ? (formData.prompt as string) : ''}
        />
      </ParamsInputContainer>

      <ParamsInputContainer maxWidth="large">
        <Text as="label" htmlFor={'sample_per_kind'} typeface="textBodySmallRegular">
          sample_per_kind
        </Text>
        {/* <Text typeface="textRegularXSmall">{description}</Text> */}
        <Input
          id="sample_per_kind"
          type="number"
          disabled={!formData.enabled}
          onChange={handleChange('sample_per_kind')}
          value={typeof formData.sample_per_kind === 'string' ? `${formData.sample_per_kind}` : ''}
        />
      </ParamsInputContainer>

      <ParamsButtons maxWidth="large" onSave={() => onSave(formData)} onReset={onReset} />
    </ParamsContainer>
  )
}

export default SummaryParamForm
