import { ReactNode } from 'react'
import { ContentContainer, ExploreContainer, PageContainer } from './styles'
import FiltersHeader from '@/components/molecules/filters/FiltersHeader'

import ToastMessagesProvider from '../ToastMessagesProvider'
import FiltersArea from '../filters/FiltersSidebar/FiltersArea'
import { CSS } from '@/theme'

export interface DefaultLayoutProps {
  children: ReactNode
  searchPlaceholder?: string
  css?: CSS
}

const DefaultContent = ({ children, searchPlaceholder, css }: DefaultLayoutProps) => {
  return (
    <PageContainer css={css}>
      <FiltersHeader searchPlaceholder={searchPlaceholder} />
      <ExploreContainer>
        <FiltersArea />
        <ContentContainer>{children}</ContentContainer>
      </ExploreContainer>
      <ToastMessagesProvider />
    </PageContainer>
  )
}

export default DefaultContent
