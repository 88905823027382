import React from 'react'
import { Entity, entityMap } from '@/utils/filterEntityUtils'
import Card from '../../card'
import { Container } from '../AppliedFilters/FilterItem/FilterItem.styles'
import Button from '@/components/atoms/button'
import { useTranslation } from 'react-i18next'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import { useMemo, useState } from 'react'
import { FilterNode, GenericFilter } from '@/types/filters/AdvancedFilters'
import FlexContainer from '@/components/atoms/flex-container'
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '../../scroll-area'
import Text from '@/components/atoms/text'
import Input, { TextArea } from '@/components/atoms/input'
import AddAdvancedFilterMenu from './AddAdvancedFilterMenu'
import AndSeparator from '../AndSeparator'
import AppliedAdvancedFilterItem from './AppliedAdvancedFilterItem'
import Tooltip from '@/components/atoms/tooltip'
import { styled } from '@/theme'

const TooltipText = styled('article', {
  maxWidth: 320,

  h1: {
    fontSize: '$micro',
    fontWeight: '$bold'
  },

  p: {
    fontSize: '$micro',
    fontWeight: '$regular',
    mt: '$xxxs'
  }
})

interface Props {
  entity: Entity
  name: string
  description: string
  onUpdate: (params: { name: string; description: string }) => void
  updateDisabled: boolean
  open: boolean
  onOpenChange: (open: boolean) => void
  onCancelClick: () => void
}

const AppliedFilterEntity = ({
  entity,
  name,
  description,
  onUpdate,
  open,
  onOpenChange,
  onCancelClick
}: Props) => {
  const { t } = useTranslation()

  const { filters, removeFilter } = useAdvancedFilters()
  const filterList = useMemo(() => {
    if (filters.operator !== '$and') return []
    return filters.value as (GenericFilter | FilterNode)[]
  }, [filters])

  const [nameInput, setNameInput] = useState(name)
  const [descriptionInput, setDescriptionInput] = useState(description)

  const onUpdateClick = () => {
    onUpdate({ name: nameInput, description: descriptionInput })
    onOpenChange(false)
  }

  const onEditClick = () => {
    onOpenChange(true)
  }

  const tooltipText = useMemo(
    () => (
      <TooltipText>
        <h1>{name}</h1>
        {description.length > 0 && <p>{description}</p>}
      </TooltipText>
    ),
    [name, description]
  )

  const onRemoveClick = (index: number) => {
    removeFilter({ index })
  }

  if (!open)
    return (
      <Tooltip side="bottom" text={tooltipText}>
        <Container>
          <Card.Header>
            <Card.IconBox css={{ bc: '$brandPrimaryPure' }}>{entityMap[entity].icon}</Card.IconBox>
            <Card.Title css={{ color: '$brandPrimaryPure' }}>{entityMap[entity].name}</Card.Title>
          </Card.Header>
          <Button size="small" variant="flat" onClick={onEditClick}>
            {t('edit')}
          </Button>
        </Container>
      </Tooltip>
    )

  return (
    <ScrollAreaRoot>
      <ScrollAreaViewport>
        <FlexContainer direction="column" gap="xxxs">
          <FlexContainer direction="column" gap="micro">
            <Text fontSize="xxxs">{t('name')}</Text>
            <Input value={nameInput} onChange={e => setNameInput(e.currentTarget.value)} />
          </FlexContainer>
          <FlexContainer direction="column" gap="micro">
            <Text fontSize="xxxs">{t('name')}</Text>
            <TextArea
              css={{ whiteSpace: 'pre-wrap', lineHeight: '$xl' }}
              onChange={e => setDescriptionInput(e.currentTarget.value)}
              placeholder={t('entityDescriptionPlaceholder')}
              rows={4}
              value={descriptionInput}
            />
          </FlexContainer>
          <FlexContainer css={{ my: '$xxs' }}>
            <AddAdvancedFilterMenu />
          </FlexContainer>
          {filterList.map((filterItem, index) => (
            <React.Fragment key={index}>
              {index > 0 && filterItem ? <AndSeparator /> : null}
              <AppliedAdvancedFilterItem
                onRemoveClick={() => onRemoveClick(index)}
                filter={filterItem}
                index={index}
              />
            </React.Fragment>
          ))}
          <FlexContainer justifyContent="spaceBetween">
            <Button size="small" variant="flat" onClick={onCancelClick}>
              {t('cancel')}
            </Button>
            <Button size="small" onClick={onUpdateClick}>
              {t('update')}
            </Button>
          </FlexContainer>
        </FlexContainer>
      </ScrollAreaViewport>
      <ScrollAreaScrollbar orientation="vertical">
        <ScrollAreaThumb />
      </ScrollAreaScrollbar>
    </ScrollAreaRoot>
  )
}

export default AppliedFilterEntity
