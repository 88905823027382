import Popover from '@/components/atoms/popover'
import { memo, useState } from 'react'
import * as S from './KeywordTopicPopover.styles'
import { FeedbackTopicChipType } from './FeedbackItem.types'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { Close } from '@radix-ui/react-popover'
import useToastMessageStore from '@/store/useToastMessageStore'
import KeywordService from '@/services/KeywordService'
import { useFeedbacks } from '@/hooks/feedback/useFeedbacks'
import useSegment from '@/hooks/useSegment'
import useLogging from '@/hooks/useLogging'
import { useTranslation } from 'react-i18next'

interface Props {
  children: JSX.Element
  topic: FeedbackTopicChipType
  feedbackId: string
  feedbackSourceId: string
  defaultOpen?: boolean
  disabled: boolean
}

const ChangeSentimentPopover = ({
  children,
  topic,
  defaultOpen,
  feedbackId,
  feedbackSourceId,
  disabled,
  ...props
}: Props) => {
  const { track } = useSegment()
  const { t } = useTranslation()
  const [open, setOpen] = useState(defaultOpen)
  const onOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      track('feedback_user_topic_detail-edit-sentiment')
    }
    setOpen(isOpen)
  }

  const addToast = useToastMessageStore(state => state.addToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const { logException } = useLogging({ context: 'change-sentiment' })

  const { updateSentimentToAllFeedbackStores } = useFeedbacks()

  const updateKeywordSentiment = (newSentiment: number) => async () => {
    const isSameSentiment = topic.sentiment === newSentiment

    if (isSameSentiment) {
      addToast({ text: t('sentimentAlreadyApplied'), status: 'default' })
      return
    }

    const removeToast = addLoadingToast({
      text: t('applyingNewSentimentToTopic')
    })

    try {
      await KeywordService.updateKeywordSentiment(topic.keywordHash, {
        new_sentiment: newSentiment,
        feedback_id: feedbackId,
        feedback_source_id: feedbackSourceId
      })

      updateSentimentToAllFeedbackStores(feedbackId, topic.keywordHash, newSentiment)

      removeToast()
      addSuccessToast({
        text: t('topicSentimentWasEditedSuccessfully')
      })
    } catch (error) {
      removeToast()
      const message = t('failedToEditTopicSentiment')
      logException(error, { message })
      addErrorToast({ text: message })
    }
  }

  if (disabled) return children

  return (
    <Popover
      active={false}
      buttonChildren={children}
      customButton
      defaultOpen={defaultOpen}
      iconButtonCss={{ padding: 0, height: 'auto' }}
      modal
      onOpenChange={onOpenChange}
      open={open}
      text
      {...props}
    >
      <S.Container changeSentiment>
        <FlexContainer direction="column">
          <Close asChild onClick={updateKeywordSentiment(-1)}>
            <S.SentimentItem>
              <S.SentimentDot sentiment="negative" />
              <Text
                fontSize="micro"
                fontWeight={topic.sentiment === -1 ? 'bold' : 'regular'}
                lineHeight="lg"
              >
                {t('negative')}
              </Text>
            </S.SentimentItem>
          </Close>

          <S.SentimentItemSeparator />

          <Close asChild onClick={updateKeywordSentiment(1)}>
            <S.SentimentItem>
              <S.SentimentDot sentiment="positive" />
              <Text
                fontSize="micro"
                fontWeight={topic.sentiment === 1 ? 'bold' : 'regular'}
                lineHeight="lg"
              >
                {t('positive')}
              </Text>
            </S.SentimentItem>
          </Close>

          <S.SentimentItemSeparator />

          <Close asChild onClick={updateKeywordSentiment(0)}>
            <S.SentimentItem>
              <S.SentimentDot sentiment="neutral" />
              <Text
                fontSize="micro"
                fontWeight={topic.sentiment === 0 || topic.sentiment === null ? 'bold' : 'regular'}
                lineHeight="lg"
              >
                {t('neutral')}
              </Text>
            </S.SentimentItem>
          </Close>

          <S.SentimentItemSeparator />
        </FlexContainer>
      </S.Container>
    </Popover>
  )
}

export default memo(ChangeSentimentPopover)
