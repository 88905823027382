import * as Collapsible from '@radix-ui/react-collapsible'
import { CollapsibleContentAnimated } from '@/components/atoms/collapsible'
import FlexContainer from '@/components/atoms/flex-container'
import { CaretRight } from '@phosphor-icons/react'
import Text from '@/components/atoms/text'
import { ReactNode, useState } from 'react'
import useLetterCase from '@/hooks/useLetterCase'
import { CollapisibleDetailRoot, CollapsibleButton } from './CollapsibleDetail.styles'

interface Props {
  option: string
  isOpen: (option: string) => boolean
  onOpenChange: (option: string) => (open: boolean) => void
  children: ReactNode
  subLevel?: boolean
}

const CollapsibleDetail = ({ children, option, subLevel, isOpen, onOpenChange }: Props) => {
  const [uncontrollledOpen, setUncontrolledOpen] = useState(false)
  const { snakeToTitle } = useLetterCase()
  const open = subLevel ? uncontrollledOpen : isOpen(option)
  const onChange = subLevel ? setUncontrolledOpen : onOpenChange(option)

  return (
    <CollapisibleDetailRoot asChild onOpenChange={onChange} open={open} subLevel={subLevel}>
      <FlexContainer direction="column">
        <Collapsible.Trigger asChild>
          <CollapsibleButton subLevel={subLevel} type="button">
            <CaretRight size={subLevel ? 14 : 16} weight="bold" />
            <Text css={{ fontSize: subLevel ? '$xxxs' : '$xxs' }} typeface="titleBoldXXS">
              {snakeToTitle(option)}
            </Text>
          </CollapsibleButton>
        </Collapsible.Trigger>
        <CollapsibleContentAnimated asChild>
          <FlexContainer css={{ ml: '$xs' }} direction="column">
            {children}
          </FlexContainer>
        </CollapsibleContentAnimated>
      </FlexContainer>
    </CollapisibleDetailRoot>
  )
}

export default CollapsibleDetail
