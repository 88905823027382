import Bone from '@/components/atoms/bone'
import { TableCell } from '@/components/atoms/table'
import { styled } from '@/theme'

const DescriptionLineHeight = styled('div', {
  height: 24,
  display: 'flex',
  alignItems: 'center'
})

function CustomFieldsTableRowSkeleton() {
  return (
    <tr data-testid="custom-fields-table-row-skeleton">
      <TableCell>
        <DescriptionLineHeight>
          <Bone css={{ height: 12, width: 140 }} />
        </DescriptionLineHeight>
      </TableCell>
      <TableCell>
        <DescriptionLineHeight>
          <Bone css={{ height: 12, width: 40 }} />
        </DescriptionLineHeight>
      </TableCell>
      <TableCell>
        <DescriptionLineHeight>
          <Bone css={{ height: 12, width: 34 }} />
        </DescriptionLineHeight>
      </TableCell>
      <TableCell>
        <DescriptionLineHeight>
          <Bone css={{ height: 12, width: 140 }} />
        </DescriptionLineHeight>
      </TableCell>
      <TableCell />
    </tr>
  )
}

export default CustomFieldsTableRowSkeleton
