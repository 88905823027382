import { ResponseBase } from '@/types'
import { FeedbackRequests, FeedbackSource } from '@/types/feedbacks'
import Request from './Request'
import { Intention } from '@/types/reasons'
import { cloneObject } from '@/utils/object'
import NewRequest, { RequestReturnType } from './RequestHandlers/NewRequest'
import { API_URL } from '@/config'
import {
  ChatCountData,
  ChatPrompt,
  Feedback,
  FeedbackDateFieldValue,
  FeedbackEnumFieldValue,
  FeedbackList,
  FeedbackMessageList,
  FeedbackNumberFieldValue,
  FeedbackSourceResponse,
  FeedbackStats,
  NewFeedback
} from '@/types/feedbacks/Feedback'
import BirdieRequest from './RequestHandlers/BirdieRequest'
import { getFeedbackKindPrettyName, parseRawFeedback } from '@/utils/feedback'
import { FeedbackDateParams, GenericFeedbackRangeParam } from '@/types/feedbacks/FeedbackRequests'
import DefaultErrorHandler from './DefaultError'
import { TimeSeries } from '@/types/time-series/TimeSeries'
import moment from 'moment'
import { stringToDate } from '@/utils/date'

const FEEDBACK_TYPE_WHITELIST: string[] = []

const defaultSearchPayload: FeedbackRequests.SearchPayload = {
  filter: {
    search_text: '',
    feedback_source_ids: [],
    product_reference: [],
    archived: false,
    sentiment: [],
    feedback_keyword_classes: [],
    schema_fields_datetime: [],
    schema_fields_integer: [],
    schema_fields_number: [],
    schema_fields_string: [],
    custom_fields_datetime: [],
    custom_fields_integer: [],
    custom_fields_number: [],
    custom_fields_string: []
  },
  filter_keyword: {
    topic_ids: [],
    theme_ids: [],
    keyword_hashes: [],
    reason_hashes: []
  },
  source: [],
  size: 100,
  offset: 0,
  order_by: 'posted_at',
  order_direction: 'desc'
}

export const getDefaultSearchPayload = () =>
  cloneObject(defaultSearchPayload) as FeedbackRequests.SearchPayload

const formatFeedbackDate = (value?: string) => {
  if (!value) return undefined
  const formated = value.replaceAll('-', '/').replace('T', ' ')
  const hasHourTimezone = formated.includes('+')
  const formatedDate = new Date(
    hasHourTimezone ? formated.slice(0, formated.indexOf('+')) : formated
  )

  // An invalid date was formated, return undefined to not break other parts of the system
  if (formatedDate instanceof Date && Number.isNaN(formatedDate)) return undefined

  return formatedDate
}

export const mapFeedbackResults = (feedback: FeedbackRequests.FeedbackResult): Feedback => {
  return {
    feedbackId: feedback.feedback_id,
    feedbackSourceId: feedback.feedback_source_id,
    title: feedback.title,
    feedbackType: feedback.feedback_type ?? 'None',
    dataType: feedback.data_type,
    rating: feedback.rating,
    postedAt: formatFeedbackDate(feedback.posted_at),
    archivedAt: formatFeedbackDate(feedback.archived_at),
    archivedBy: feedback.archived_by,
    productReference: feedback.product_reference ?? '',
    text: feedback.text,
    keywordTopicList: feedback.keyword_list.map(keywordTopic => ({
      keywordHash: keywordTopic.keyword_hash,
      sentiment: keywordTopic.sentiment,
      text: keywordTopic.text,
      topics: keywordTopic.topics.map(topic => ({ name: topic.name, topicId: topic.topic_id })),
      overallQuality: keywordTopic.overall_quality,
      keywordQuality: keywordTopic.keyword_quality,
      intention: keywordTopic.feedback_keyword_classes.map(intention =>
        intention.toLowerCase()
      ) as Intention[]
    })),
    ticket: feedback.ticket
      ? {
          id: feedback.ticket.id,
          count: feedback.ticket.qtd,
          searchCount: feedback.ticket.search_text_qtd
        }
      : undefined,
    authorId: feedback.author_id,
    accountId: feedback.account_id,
    threadId: feedback.thread_id,
    schemaFieldsDatetime: feedback.schema_fields_datetime || [],
    schemaFieldsInteger: feedback.schema_fields_integer || [],
    schemaFieldsNumber: feedback.schema_fields_number || [],
    schemaFieldsString: feedback.schema_fields_string || [],
    customFieldsDatetime: feedback.custom_fields_datetime || [],
    customFieldsInteger: feedback.custom_fields_integer || [],
    customFieldsNumber: feedback.custom_fields_number || [],
    customFieldsString: feedback.custom_fields_string || []
  }
}

interface SearchResponse {
  data: Feedback[]
  totalHits: number
  message: string
}

export default class FeedbackService {
  static PREFIX = '/feedback'
  static async search(
    payload: FeedbackRequests.SearchPayload,
    signal?: AbortSignal
  ): Promise<RequestReturnType<SearchResponse>> {
    const [error, data] = await NewRequest.post<ResponseBase<FeedbackRequests.SearchResponse>>(
      `${this.PREFIX}/search`,
      payload,
      { signal }
    )

    if (error) {
      return [error, undefined]
    }

    return [
      undefined,
      {
        data: data.data.feedback_list.map(mapFeedbackResults),
        totalHits: data.data.total_hits,
        message: data.message
      }
    ]
  }

  // deprecated
  static async export(payload: FeedbackRequests.ExportPayload) {
    return await Request.post<FeedbackRequests.ExportResponse[]>(`${this.PREFIX}/export`, payload)
  }

  static async exportAsync(payload: FeedbackRequests.ExportPayload) {
    return NewRequest.post<string | null>(`${this.PREFIX}/export/_async`, payload)
  }

  static async patch(feedbackId: string, payload: FeedbackRequests.PatchPayload) {
    return await Request.patch(`${this.PREFIX}/${feedbackId}`, payload)
  }

  static async getFeedbackSources() {
    return (await Request.get<FeedbackSourceResponse[]>('/feedback_source_value?per_page=50'))
      .filter(source => FEEDBACK_TYPE_WHITELIST.includes(source.name.toLowerCase()))
      .map(
        (source): FeedbackSource => ({
          id: source.feedback_source_value_id,
          name: source.name
        })
      )
  }

  static async updateIntention(params: FeedbackRequests.UpdateIntentionParams) {
    const payload: FeedbackRequests.UpdateIntetionPayload = {
      ingested_feedback_id: params.feedbackId,
      feedback_keyword_classes: params.intentions,
      keyword_hash: params.keywordHash,
      keyword_text: params.keywordText
    }
    return await Request.post<FeedbackRequests.FeedbackResult>(`${this.PREFIX}/intention`, payload)
  }

  static async searchWithChatStream(
    payload: FeedbackRequests.SearchWithChatPayload,
    signal?: AbortSignal
  ) {
    return fetch(`${API_URL}feedback/chat`, {
      method: 'POST',
      body: JSON.stringify(payload),
      signal,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    })
  }

  static async getChatPrompts() {
    return NewRequest.get<ChatPrompt[]>(`${this.PREFIX}/chat/prompts`)
  }

  static async getChatFeedbackCount(
    payload: FeedbackRequests.SearchWithChatPayload,
    signal?: AbortSignal
  ): Promise<RequestReturnType<ChatCountData>> {
    const [error, data] = await NewRequest.post<FeedbackRequests.ChatFeedbackCount>(
      `${this.PREFIX}/chat`,
      payload,
      {
        signal
      }
    )
    if (error) return [error, undefined]

    return [
      undefined,
      {
        feedbackTakenIntoAccount: data.feedback_taken_into_account,
        feedbackTotalHits: data.feedback_total_hits,
        tokenCountFeedbacks: data.token_count_prediction.feedback_list,
        tokenCountHistory: data.token_count_prediction.history,
        tokenTotalCount: data.token_count_prediction.total,
        feedbackList: data.data.feedback_list.map(mapFeedbackResults)
      }
    ]
  }

  // ### New Feedback API

  /** New */
  static async getFeedbackItem(
    feedbackId: string,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<NewFeedback>> {
    const [error, data] = await BirdieRequest.get<FeedbackRequests.FeedbackResponse>(
      `/feed/feedbacks/${feedbackId}`,
      { include: 'stats' },
      {
        signal: options?.signal
      }
    )

    if (error) return [error, undefined]

    return [
      undefined,
      {
        source: data.source,
        kind: {
          ...data.kind,
          prettyName: getFeedbackKindPrettyName(data.kind.name)
        },
        sourceAlias: {
          sourceAliasName: data.source_alias,
          sourceAliasPrettyName: getFeedbackKindPrettyName(data.source_alias),
          kind: { ...data.kind, prettyName: getFeedbackKindPrettyName(data.kind.name) }
        },
        id: data.id,
        language: data.language,
        text: data.text,
        customFields: data.custom_fields,
        updatedAt: stringToDate(data.updated_at)
      }
    ]
  }

  static parseDateToParam(key: 'posted_at' | 'ingested_at', value: GenericFeedbackRangeParam) {
    if (value.at) {
      return { [key]: value.at } as FeedbackDateParams
    }

    const parsedDateParam: Record<string, string> = {}
    Object.entries(value).forEach(([param, value]) => {
      const paramKey = `${key}.${param}`
      parsedDateParam[paramKey] = value
    })

    return parsedDateParam as FeedbackDateParams
  }

  /** New */
  static async getFeedbackList(
    params: FeedbackRequests.FeedbackListQueryParams,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<FeedbackList>> {
    const [error, data] = await BirdieRequest.get<FeedbackRequests.FeedbackListResponse>(
      `/feed/feedbacks`,
      params,
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )

    if (error) return [error, undefined]

    return [
      undefined,
      {
        feedbacks: data.feedbacks.map(parseRawFeedback),
        nextPage: data.next_page
      }
    ]
  }

  static async validateQuery(queryString: string, options?: { signal?: AbortSignal }) {
    return await BirdieRequest.get<FeedbackRequests.FeedbackListResponse>(
      `/feed/feedbacks`,
      {
        'text.feedback': queryString,
        'posted_at.gte': '2000-01-01T00:00:00.000Z',
        per_page: 0
      },
      {
        signal: options?.signal
      }
    )
  }

  static async getFeedbackUniqueValues(
    params: {
      path: string
      name: string
      search?: string
      perPage?: number
      cursor?: string
    },
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<{ values: string[]; nextPage?: string }>> {
    const { path, name, search, perPage, cursor } = params
    const queryParams: FeedbackRequests.FeedbackListQueryParams &
      Record<string, string | number | string[] | undefined> = {
      per_page: perPage ?? 25,
      include: ['stats']
      // sort: name
    }

    if (name !== 'id') {
      queryParams.$exists = name
    }

    if (cursor) {
      queryParams.cursor = cursor
    }

    if (search?.length) {
      queryParams[name] = search
    }

    const [error, data] = await BirdieRequest.get<FeedbackRequests.FeedbackListResponse>(
      `/feed/feedbacks`,
      queryParams,
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )

    if (error) return [error, undefined]

    const pathArray = path.split('.')

    const values = data.feedbacks
      .map(feedback => {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        let currentPath: any = feedback
        pathArray.forEach(entry => {
          currentPath = currentPath?.[entry] ?? undefined
        })
        return currentPath
      })
      .filter(Boolean)

    if (values.length === 0) {
      return [undefined, { values: [] }]
    }

    if (typeof values !== 'object' || typeof values[0] !== 'string') {
      return [new DefaultErrorHandler('Unexpected type'), undefined]
    }

    return [undefined, { values, nextPage: data.next_page }]
  }

  /** New */
  static async getFeedbackStats(
    feedbackId: string,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<FeedbackStats>> {
    const [error, data] = await BirdieRequest.get<FeedbackRequests.FeedbackStatsResponse>(
      `/feed/feedbacks/${feedbackId}/stats`,
      null,
      {
        signal: options?.signal
      }
    )

    if (error) return [error, undefined]

    return [
      undefined,
      {
        accounts: data.accounts,
        users: data.users,
        messages: data.messages
          ? {
              total: data.messages.total,
              firstPostedAt: stringToDate(data.messages.first_posted_at),
              lastPostedAt: stringToDate(data.messages.last_posted_at)
            }
          : undefined
      }
    ]
  }

  /** New */
  static async getFeedbackMessages(
    params: {
      feedbackId: string
      accountId?: string
      userId?: string
      perPage?: number
      cursor?: string
    },
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<FeedbackMessageList>> {
    const queryParams: FeedbackRequests.FeedbackMessagesQueryParams = {
      account_id: params.accountId,
      user_id: params.userId,
      per_page: params.perPage,
      cursor: params.cursor
    }

    const [error, data] = await BirdieRequest.get<FeedbackRequests.FeedbackMessagesResponse>(
      `/feed/feedbacks/${params.feedbackId}/messages`,
      queryParams,
      {
        signal: options?.signal
      }
    )

    if (error) return [error, undefined]

    return [
      undefined,
      {
        messages: data.messages.map(message => ({
          id: message.id,
          source: message.source,
          kind: message.kind,
          language: message.language,
          text: message.text ?? '',
          postedAt: stringToDate(message.posted_at),
          customFields: message.custom_fields ?? {},
          updatedAt: stringToDate(message.updated_at)
        })),
        nextPage: data.next_page
      }
    ]
  }

  /** New */
  static async getFeedbackFieldList(options?: {
    signal?: AbortSignal
  }): Promise<RequestReturnType<FeedbackRequests.FeedbackFieldsResponse>> {
    const [error, data] = await BirdieRequest.get<FeedbackRequests.FeedbackFieldsResponse>(
      `/feed/feedbacks/fields`,
      null,
      {
        signal: options?.signal
      }
    )

    if (error) return [error, undefined]

    return [undefined, data]
  }

  /** New */
  static async getFeedbackFieldValues(
    params: { type: 'number'; fieldName: string },
    options?: { signal?: AbortSignal }
  ): Promise<RequestReturnType<FeedbackNumberFieldValue>>

  /** New */
  static async getFeedbackFieldValues(
    params: { type: 'datetime'; fieldName: string },
    options?: { signal?: AbortSignal }
  ): Promise<RequestReturnType<FeedbackDateFieldValue>>

  /** New */
  static async getFeedbackFieldValues(
    params: { type: 'enum'; fieldName: string },
    options?: { signal?: AbortSignal }
  ): Promise<RequestReturnType<FeedbackEnumFieldValue>>

  /** New */
  static async getFeedbackFieldValues(
    params: {
      type: 'number' | 'datetime' | 'enum'
      fieldName: string
    },
    options?: {
      signal?: AbortSignal
    }
  ): Promise<
    RequestReturnType<FeedbackNumberFieldValue | FeedbackDateFieldValue | FeedbackEnumFieldValue>
  > {
    const [error, data] = await BirdieRequest.get<FeedbackRequests.FeedbackFieldValuesResponse>(
      `/feed/feedbacks/fields/${params.fieldName}`,
      null,
      {
        signal: options?.signal
      }
    )

    if (error) return [error, undefined]

    if (data.type === 'number') {
      return [
        undefined,
        {
          name: data.name,
          type: 'number',
          min: data.min,
          max: data.max
        }
      ]
    }

    if (data.type === 'datetime' || data.type === 'date') {
      return [
        undefined,
        {
          name: data.name,
          type: 'datetime',
          startDate: data.start_date,
          endDate: data.end_date
        }
      ]
    }

    return [undefined, data as FeedbackEnumFieldValue]
  }

  /** New */
  static async getTimeSeries(
    params: FeedbackRequests.TimeSeriesQueryParams,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<TimeSeries>> {
    const [error, data] = await BirdieRequest.get<FeedbackRequests.TimeSeriesResponse>(
      '/feed/feedbacks/timeseries',
      params,
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )

    if (error) return [error, undefined]

    return [
      undefined,
      {
        timestamps: data.timestamps.map(timestamp => moment.unix(timestamp).utc()),
        values: data.values,
        groups: data.groups
      }
    ]
  }

  static async changeFeedbackOpportunityLabel(
    {
      feedbackId,
      opportunityId,
      hasOpportunity
    }: FeedbackRequests.ChangeFeedbackOpportunityLabelParams,
    options?: {
      signal?: AbortSignal
    }
  ) {
    return await NewRequest.put(
      `/feedback/feedback-opportunity/${feedbackId}/${opportunityId}?has_opportunity=${hasOpportunity}`,
      null,
      {
        signal: options?.signal
      }
    )
  }
}
