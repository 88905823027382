import { useEffect } from 'react'

function useScrollToTop(deps: React.DependencyList) {
  // biome-ignore lint/correctness/useExhaustiveDependencies: deps is passed as parameter
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [deps])
}

export default useScrollToTop
