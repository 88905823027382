import FilterSubMenu from '../AddFilterMenu/FilterSubMenu'

import { Swatches } from '@phosphor-icons/react'
import { useMemo, useState } from 'react'
import useUser from '@/hooks/useUser'
import { TopicsFilterSubMenu } from '../TopicsFilter'
import UngroupedTopicFilterContent from './UngroupedTopicFilterSubmenu'
import useDebounce from '@/hooks/useDebounce'
import FullTopicSearchResults from './AllTopicSearchResults/FullTopicSearchResults'
import { useFiltersStore } from '@/store'
import useUngroupedTopicsFilters from './hooks/useUngroupedTopicsFilters'
import SearchInputForFilters from '../../SearchInputFilter'
import { useTranslation } from 'react-i18next'

const TopicGroupsFilterSection = () => {
  const [searchInput, setSearchInput] = useState('')
  const [open, setOpen] = useState(false)
  const { userPermissions } = useUser()

  const { t } = useTranslation()

  const debouncedSearch = useDebounce(searchInput, 500)

  const applyThemesAndTopics = useFiltersStore(state => state.applyThemesAndTopics)

  const { tempTopics, appliedTopics } = useFiltersStore(state => ({
    tempTopics: [...state.productAreaTopics.draftSelected, ...state.otherTopics.draftSelected],
    appliedTopics: [...state.productAreaTopics.selected, ...state.otherTopics.selected]
  }))

  const { tempThemes, appliedThemes } = useFiltersStore(state => ({
    tempThemes: [...state.productAreaTopics.draftSelected, ...state.otherTopics.draftSelected],
    appliedThemes: [...state.productAreaThemes.selected, ...state.otherThemes.selected]
  }))

  const { hasChanges: hasUngroupedChanges } = useUngroupedTopicsFilters({
    searchQuery: '',
    enabled: false
  })

  const hasPermission = userPermissions.topic.length > 0
  const showUngrouped = userPermissions['new-topic'].length > 0

  const hasChanges = useMemo(
    () =>
      JSON.stringify(appliedThemes) !== JSON.stringify(tempThemes) ||
      JSON.stringify(appliedTopics) !== JSON.stringify(tempTopics) ||
      hasUngroupedChanges,
    [tempThemes, tempTopics, hasUngroupedChanges, appliedThemes, appliedTopics]
  )

  const onOpenChange = (currentOpen: boolean) => {
    setOpen(currentOpen)
    if (!currentOpen) {
      setSearchInput('')
    }
  }

  if (!hasPermission) return <></>

  return (
    <FilterSubMenu
      hideButton={!debouncedSearch.length}
      icon={<Swatches />}
      isDisabled={!hasChanges}
      onApply={applyThemesAndTopics}
      onOpenChange={onOpenChange}
      open={open}
      title={t('topics')}
    >
      <SearchInputForFilters
        clearable
        css={{ mt: '$micro', mb: '$micro' }}
        onChange={e => setSearchInput(e.currentTarget.value)}
        onClear={() => setSearchInput('')}
        placeholder={t('search')}
        size="small"
        small
        value={searchInput}
      />
      {/* {hasProductAreasPermission && (
        <TopicGroupSubmenu itemTitle="Product areas" topicCategory="PRODUCT_AREA" />
      )}
      {hasOtherTopicsPermission && <TopicGroupSubmenu itemTitle="Other" topicCategory="OTHER" />} */}

      {debouncedSearch.length ? (
        <FullTopicSearchResults searchQuery={debouncedSearch} />
      ) : (
        <>
          <TopicsFilterSubMenu category="PRODUCT_AREA" />
          <TopicsFilterSubMenu category="OTHER" />
          {showUngrouped && <UngroupedTopicFilterContent />}
        </>
      )}
    </FilterSubMenu>
  )
}

export default TopicGroupsFilterSection
