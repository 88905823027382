import UserService from '@/services/UserService'
import { UserAccess, UserPlan, UserWithInviter, UserWithInvites } from '@/types/auth'
import { Organization } from '@/types/organization/Organization'
import { persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface UserState {
  currentUser: UserAccess | null
  inviter: UserWithInvites | null
  isLoadingInviter: boolean
  isInvited: boolean
  users: UserWithInviter[]
  roles: {
    admin: string
    viewer: string
  }
  organization: Organization | null

  isAdmin: () => boolean

  setCurrentUser: (user: UserAccess | null) => void
  setInviter: (users: UserWithInvites) => void
  setIsLoadingInviter: (isLoading: boolean) => void
  setOrganization: (organization: Organization) => void

  loadUsers: () => Promise<void>
}

const useUserStore = createWithEqualityFn<UserState>(
  (set, get) => ({
    currentUser: null,
    inviter: null,
    isLoadingInviter: false,
    isInvited: false,
    users: [],
    roles: {
      admin: '',
      viewer: ''
    },
    organization: null,

    isAdmin: () => get().currentUser?.role === 'admin',

    setCurrentUser: user => set(() => ({ currentUser: user })),
    setInviter: inviter => set(() => ({ inviter })),
    setIsLoadingInviter: isLoadingInviter => set(() => ({ isLoadingInviter })),
    setOrganization: organization => set(() => ({ organization })),

    loadUsers: async () => {
      const { currentUser } = get()
      if (!currentUser) return

      const [users, rolesData] = await Promise.all([UserService.getUsers(), UserService.getRoles()])

      const adminRoleId = rolesData.find(role => role.name === 'Admin')?.role_id ?? ''
      const viewerRoleId = rolesData.find(role => role.name === 'Viewer')?.role_id ?? ''

      set(() => ({
        users,
        roles: { admin: adminRoleId, viewer: viewerRoleId },
        isLoadingInviter: false
      }))
    }
  }),
  shallow
)

export default useUserStore

interface UserPlanState {
  currentUserPlan: UserPlan | null
  setCurrentUserPlan: (userPlan: UserPlan | null) => void
}

export const useUserPlanStore = createWithEqualityFn<UserPlanState>()(
  persist(
    set => ({
      currentUserPlan: null,
      setCurrentUserPlan: userPlan => set({ currentUserPlan: userPlan })
    }),
    { name: 'user-plan-store', version: 2 }
  ),
  shallow
)
