import { TABLEAU_SERVER_URL } from '@/config'
import useQueryString from '@/hooks/useQueryString'
import TableauService from '@/services/TableauService'
import { CSS, styled } from '@/theme'
import { useMemo, useState, useEffect, useRef } from 'react'
import OptionsMenu from '../options-menu'
import Tableau from 'tableau-api-js'

const TableauVizContainer = styled('div', {
  background: 'transparent',
  width: '100%',

  header: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

export interface TableauVizProps {
  view: string
  options?: Record<string, unknown>
  filters?: Record<string, unknown>
  height?: string
  hideMenu?: boolean
  css?: CSS
}

const TableauViz = ({
  view,
  options = {},
  filters = {},
  height = '400px',
  hideMenu = false,
  css = {}
}: TableauVizProps) => {
  const { makeQueryString } = useQueryString()

  const viewRef = useRef(null)

  const [url, setUrl] = useState('')
  const [viz, setViz] = useState<tableau.Viz | undefined>(undefined)

  const optionsKey = useMemo(() => JSON.stringify(options), [options])

  // biome-ignore lint/correctness/useExhaustiveDependencies: it should restart when view or options change
  useEffect(() => {
    restartViz()
  }, [view, optionsKey])

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    const mountViz = async () => {
      if (Object.keys(viz ?? {}).length) {
        viz?.dispose()
      }

      await updateUrl()

      setTimeout(async () => {
        await initViz(false)
      }, 100)
    }

    mountViz()

    return () => {
      dispose()
    }
  }, [])

  const dispose = () => {
    if (viz && Object.keys(viz).length) {
      viz.dispose()
    }
  }

  const updateUrl = async () => {
    try {
      const ticket = await TableauService.ticket()
      const newUrl = `${TABLEAU_SERVER_URL}/trusted/${ticket}/views/${view}`
      setUrl(newUrl)

      return newUrl
    } catch (e) {
      console.error(e)
    }

    return ''
  }

  const initViz = async (toUpdateUrl = true) => {
    let _url = url
    if (toUpdateUrl) {
      _url = await updateUrl()
    }
    const containerDiv = viewRef.current
    const vizOptions = {
      height,
      width: '100%',
      device: 'desktop',
      ...options
    }

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        options[key.toString()] = value ? String(value) : ''
      }
    }

    if (containerDiv) {
      const newViz = new Tableau.Viz(containerDiv, _url, vizOptions)
      setViz(newViz)
    }
  }

  const restartViz = () => {
    dispose()
    initViz()
  }

  const download = (fileType: string) => {
    const viewUrl = `${TABLEAU_SERVER_URL}/views/${view}.${fileType}`
    const optionsUrl = makeQueryString(viewUrl, options)

    const today = new Date().toISOString().slice(0, 10)
    const a = document.createElement('a')
    a.setAttribute('hidden', '')
    a.setAttribute('href', optionsUrl)
    a.setAttribute('download', `export-${today}.${fileType}`)
    a.setAttribute('target', '_blank')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const menuOptions = [
    {
      text: 'Download CSV',
      onClick: () => download('csv')
    },
    {
      text: 'Download Image',
      onClick: () => download('png')
    }
  ]

  const actualCSS: CSS = useMemo(
    () => ({
      height,
      ...css
    }),
    [height, css]
  )

  return (
    <TableauVizContainer css={actualCSS} key={view}>
      <header>{!hideMenu && <OptionsMenu options={menuOptions} />}</header>
      <div id={view} ref={viewRef} />
    </TableauVizContainer>
  )
}

export default TableauViz
