import { styled } from '@/theme'

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
  maxWidth: 800
})

export const ConnectorContainer = styled('div', {
  alignItems: 'center',
  bAll: '$neutralHighPure',
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  padding: '$md 25%',
  textAlign: 'center'
})

export const Title = styled('h2', {
  fontSize: '$sm',
  fontWeight: '$bold',
  lineHeight: '$default'
})

export const Description = styled('p', {
  color: '$neutralLowLight',
  fontSize: '$xxxs',
  lineHeight: '$xl'
})

export const InterestMessage = styled('p', {
  alignItems: 'center',
  color: '$feedbackPositivePure',
  display: 'flex',
  fontSize: '$micro',
  fontWeight: '$bold',
  gap: 4,
  height: 40,
  lineHeight: '$xxl'
})
