import { CSS } from '@/theme'
import TableauViz from './TableauViz'

export interface TableauVizByUrlProps {
  url: string
  height?: string
  hideMenu?: boolean
  css?: CSS
}

const TableauVizByUrl = ({
  url,
  height = '80vh',
  hideMenu = false,
  css = {}
}: TableauVizByUrlProps) => {
  const params = new URLSearchParams(url.split('?=')?.[1] ?? '')
  const options = Object.fromEntries(params)

  const view = url.split('#/views/')[1]?.split('?=')?.[0] ?? ''

  return <TableauViz css={css} height={height} hideMenu={hideMenu} options={options} view={view} />
}

export default TableauVizByUrl
