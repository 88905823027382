import { Users } from '@phosphor-icons/react'
import IndicatorCard from '../indicators/IndicatorCard'
import useTopicCustomerIndicators from '@/hooks/analytics/useTopicCustomerIndicators'
import { IndicatorCardProps } from './TopicDetails.types'
import TopicIndicatorCardSkeleton from './TopicIndicatorCardSkeleton'

const TopicCustomersIndicator = ({
  topicId,
  asExploreFeedbackItem = false
}: IndicatorCardProps) => {
  const { count, percentage, isLoading } = useTopicCustomerIndicators({
    topicId,
    asExploreFeedbackItem
  })

  return (
    <IndicatorCard.Root alignItems="end" color="brandPrimaryPure">
      <IndicatorCard.Column>
        <IndicatorCard.Header>
          <IndicatorCard.IconBox>
            <Users />
          </IndicatorCard.IconBox>
          <IndicatorCard.Title>Customers</IndicatorCard.Title>
        </IndicatorCard.Header>

        {isLoading ? (
          <TopicIndicatorCardSkeleton.Number />
        ) : (
          <IndicatorCard.BigNumber>{count}</IndicatorCard.BigNumber>
        )}
      </IndicatorCard.Column>

      <IndicatorCard.Paragraph css={{ mt: '$xxs' }}>
        {isLoading ? (
          <TopicIndicatorCardSkeleton.Text />
        ) : (
          <span>
            Mentioned by {count} ({percentage}% of all customers)
          </span>
        )}
      </IndicatorCard.Paragraph>
    </IndicatorCard.Root>
  )
}

export default TopicCustomersIndicator
