import useDatetimeAdvancedFilter from '@/hooks/advancedFilters/useDatetimeAdvancedFilter'
import { useState } from 'react'
import FilterSubMenu from '../../FiltersSidebar/AddFilterMenu/FilterSubMenu'
import RangeCalendar from '@/components/atoms/range-calendar'
import { DateFilterType } from '@/types/filters/AdvancedFilters'

interface Props {
  title: string
  previousTitle: string
  name: string
  path: string
  type?: DateFilterType
}

const DatetimeFilterSubMenu = ({ title, previousTitle, name, path, type = 'datetime' }: Props) => {
  const [open, setOpen] = useState(false)

  const { apply, dateRange, isLoading, maxDate, minDate, setDateRange } = useDatetimeAdvancedFilter(
    {
      name,
      path,
      enableQuery: open,
      type
    }
  )

  return (
    <FilterSubMenu
      contentCss={{ width: 'auto' }}
      isLoading={isLoading}
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      previousTitle={previousTitle}
      title={title}
    >
      <RangeCalendar
        maxValue={maxDate}
        minValue={minDate}
        onChange={setDateRange}
        value={dateRange}
      />
    </FilterSubMenu>
  )
}

export default DatetimeFilterSubMenu
