import { CSS, styled } from '@/theme'

export const Container = styled('article', {
  alignItems: 'center',
  bAll: '$neutralHighPure',
  borderRadius: 8,
  display: 'flex',
  gap: 8,
  justifyContent: 'space-between',
  padding: 16,

  '&:hover': {
    borderColor: '$brandPrimaryPure'
  },
  '& > svg': {
    minWidth: 16
  },

  variants: {
    disabled: {
      true: {
        cursor: 'not-allowed',
        '&:hover': {
          borderColor: '$neutralHighPure'
        }
      }
    }
  }
})

export const AppliedFiltersButton = styled('button', {
  alignItems: 'center',
  backgroundColor: '$brandPrimaryLight',
  border: 'none',
  borderRadius: 4,
  cursor: 'pointer',
  display: 'flex',
  fontFamily: 'inherit',
  gap: 5,
  maxWidth: '60%',
  padding: '4px 6px',

  '&:disabled': {
    cursor: 'not-allowed',
    backgroundColor: '$neutralHighMedium'
  }
})

export const AppliedFiltersText = styled('span', {
  color: '$brandPrimaryPure',
  fontSize: 12,
  fontWeight: 600,
  lineHeight: 1.15,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: 100,

  variants: {
    disabled: {
      true: {
        color: '$neutralLowLight'
      }
    }
  }
})

export const OptionsMenuButton: CSS = {
  all: 'unset',
  alignItems: 'center',
  borderRadius: 4,
  color: '$neutralLowPure',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  size: 24,
  transition: 'background-color .3s',

  '&:hover': {
    backgroundColor: '$brandPrimaryLight',
    color: '$brandPrimaryPure'
  },

  '&[data-state=open]': {
    backgroundColor: '$brandPrimaryLight',
    color: '$brandPrimaryPure'
  },

  '& svg': {
    size: 16
  }
}
