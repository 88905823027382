import FlexContainer from '@/components/atoms/flex-container'
import SentimentFilterContent, { SentimentFilterContentProps } from './SentimentFilterContent'
import FilterSubMenu from '../../FiltersSidebar/AddFilterMenu/FilterSubMenu'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { negativeSentiments, neutralSentiments, positiveSentiments } from '@/utils/sentiments'

interface Props extends SentimentFilterContentProps {
  onApply: () => void
  isDisabled: boolean
}

const SentimetnFilterSelection = ({ onApply, isDisabled, value, onValueChange }: Props) => {
  const { t } = useTranslation()
  const positiveDisabled = useMemo(
    () => (!!value && !positiveSentiments.includes(value)) || isDisabled,
    [value, isDisabled]
  )
  const neutralDisabled = useMemo(
    () => (!!value && !neutralSentiments.includes(value)) || isDisabled,
    [value, isDisabled]
  )
  const negativeDisabled = useMemo(
    () => (!!value && !negativeSentiments.includes(value)) || isDisabled,
    [value, isDisabled]
  )

  return (
    <FlexContainer direction="column">
      <FilterSubMenu
        onApply={onApply}
        title={t('positive')}
        previousTitle={t('sentiment')}
        isDisabled={positiveDisabled}
      >
        <SentimentFilterContent.Positive value={value} onValueChange={onValueChange} />
      </FilterSubMenu>
      <FilterSubMenu
        onApply={onApply}
        title={t('neutral')}
        previousTitle={t('sentiment')}
        isDisabled={neutralDisabled}
      >
        <SentimentFilterContent.Neutral value={value} onValueChange={onValueChange} />
      </FilterSubMenu>
      <FilterSubMenu
        onApply={onApply}
        title={t('negative')}
        previousTitle={t('sentiment')}
        isDisabled={negativeDisabled}
      >
        <SentimentFilterContent.Negative value={value} onValueChange={onValueChange} />
      </FilterSubMenu>
    </FlexContainer>
  )
}

export default SentimetnFilterSelection
