import FlexContainer from '@/components/atoms/flex-container'
import { LightbulbFilament, Plus } from '@phosphor-icons/react'
import Card from '@/components/molecules/card/Card'
import OpportunitiesListFilters from './OpportunityListFilters'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { useCallback, useMemo, useState } from 'react'
import { DeleteDialog } from '@/components/atoms/dialog'
import RenameOpportunityDialog from './RenameOpportunityDialog'
import useSegment from '@/hooks/useSegment'
import { getStatusName, StatusFilter } from '@/utils/opportunityUtils'
import Drawing from '@/assets/drawings/bird_empty_boxes.png'
import { styled } from '@/theme'
import Text from '@/components/atoms/text'
import Button from '@/components/atoms/button'
import CreateOpportunityModal, { CreateOpportunityData } from '../CreateOpportunityModal'
import NewOpportunity from '../NewOpportunity'
import OpportunityListSkeleton from './OpportunityListSkeleton'
import { MoveOppToSuccessMessage, OpportunityItemRow } from './OpportunitiesList.styles'
import useToastMessageStore from '@/store/useToastMessageStore'
import useRemovingItemsStore from '@/store/useRemoveItemsStore'
import useAreaOfInterest from '@/hooks/areaOfInterest/useAreaOfInterest'
import { AreaOfInterestData, BaseInterestArea } from '@/types/area/AreaOfInterest'
import OpportunityTable from '../OpportunityTable/OpportunityTable'
import useOpportunityMutations from '@/hooks/opportunity/useOpportunityMutations'
import useOpportunitiesWithMetricsQuery from '@/hooks/opportunity/useOpportunitiesWithMetricsQuery'
import useUser from '@/hooks/useUser'
import { useTranslation } from 'react-i18next'
import {
  OpportunityItem,
  OpportunityItemWithMetrics,
  OpportunityStatus
} from '@/types/opportunity/Opportunity'
import useBasicAreaOfInterestQuery from '@/hooks/areaOfInterest/useBasicAreaOfInterestQuery'
import CalibrateOpportunityDialog from '../CalibrateOpportunity/CalibrateOpportunityDialog'
import { mapOpportunity } from '@/hooks/opportunity/useOpportunitiesQuery'
import { RawOpportunityDataBase } from '@/types/opportunity/OpportunityRequests'
import useLocalStorage from '@/hooks/useLocalStorage'
import { CALIBRATION_PARAMS_KEY } from '@/types/params/CalibrationParams'
import { defaultCalibrationParamsData } from '@/pages/params'

const DrawingImage = styled('img', {
  width: 236
})

const placeholderOpps: OpportunityItemWithMetrics[] = []

function OpportunitiesListV2() {
  const { track } = useSegment()

  const { t } = useTranslation()

  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)

  const { areas: advancedAreas } = useBasicAreaOfInterestQuery()

  const areas = useMemo(() => {
    let _areas: AreaOfInterestData[] = []
    if (!currentInterestArea) return _areas
    if (!currentInterestArea.opportunityCount) return _areas

    if (currentInterestArea.isUnmappedArea) {
      _areas = [currentInterestArea as AreaOfInterestData]
      return _areas
    }

    if (!advancedAreas.length) return _areas

    const currentAdvancedArea = advancedAreas.find(area => area.id === currentInterestArea.id)

    _areas = [currentAdvancedArea as AreaOfInterestData]
    return _areas
  }, [currentInterestArea, advancedAreas])

  const {
    isLoadingByArea,
    queries: oppsQueries,
    newOpportunitiesCountByAreaId
  } = useOpportunitiesWithMetricsQuery({
    areas,
    enabled: Boolean(currentInterestArea?.opportunityCount),
    fetchOppsMetricsOnMount: true,
    excludeUnmapped: true
  })

  const newOppsCount = useMemo(() => {
    if (!currentInterestArea) return 0

    return newOpportunitiesCountByAreaId[currentInterestArea.id] ?? 0
  }, [newOpportunitiesCountByAreaId, currentInterestArea])

  const isLoading = useMemo(() => {
    if (!currentInterestArea) return false

    return isLoadingByArea[currentInterestArea.id] ?? false
  }, [isLoadingByArea, currentInterestArea])

  const {
    updateOpportunity,
    createOpportunity,
    isUpdatingOpportunity,
    moveOpportunity,
    isMovingOpportunity
  } = useOpportunityMutations({
    area: currentInterestArea,
    metricAreas: areas
  })

  const data: OpportunityItemWithMetrics[] = useMemo(() => {
    // Preserve opps reference so react table does not reprocess the data and enters in infinite rendering
    let _opportunities: OpportunityItemWithMetrics[] = placeholderOpps
    if (!currentInterestArea) return _opportunities

    oppsQueries.forEach(query => {
      if (!query.data) return
      const { data: _data, isLoading: isLoadingQuery } = query
      const { areaId, data: opps } = _data
      if (areaId === currentInterestArea.id && !isLoadingQuery) {
        _opportunities = opps
      }
    })
    return _opportunities
  }, [currentInterestArea?.id, oppsQueries, currentInterestArea])

  const [opportunityToRename, setOportunityToRename] = useState<OpportunityItem | undefined>()
  const [opportunityToDelete, setOportunityToDelete] = useState<OpportunityItem | undefined>()

  const [selectedStatusFilter, setSelectedStatusFilter] = useState<StatusFilter>('ongoing')

  const onStatusChange = useCallback(
    (opportunity: OpportunityItemWithMetrics) => (newStatus: OpportunityStatus) => {
      if (opportunity.status === newStatus || !newStatus.length) return
      updateOpportunity({
        id: opportunity.id,
        name: opportunity.name,
        status: newStatus
      })
      const newStatusName = getStatusName(newStatus)

      track('opportunity_change_status', {
        opportunity_name: opportunity.name,
        opportunity_status: newStatusName
      })
    },
    [track, updateOpportunity]
  )

  const onConfirmRename = useCallback(
    (newName: string) => {
      if (!opportunityToRename) return
      if (opportunityToRename.name === newName || !newName.length) return

      updateOpportunity({
        id: opportunityToRename.id,
        name: newName.trim(),
        status: opportunityToRename.status
      })
    },
    [opportunityToRename, updateOpportunity]
  )

  const [filterNew, setFilterNew] = useState(false)
  const [opportunityToCalibrate, setOpportunityToCalibrate] = useState<OpportunityItem | null>(null)

  const [calibrationParams] = useLocalStorage(CALIBRATION_PARAMS_KEY, defaultCalibrationParamsData)

  const onCreateOpportunity = (data: CreateOpportunityData) => {
    if (!currentInterestArea) return
    createOpportunity(
      { areaId: currentInterestArea.id, ...data },
      {
        onSuccess: (data: RawOpportunityDataBase) => {
          const parsedOpportunity = mapOpportunity({
            ...data,
            areas: [currentInterestArea.id],
            initiatives: []
          })
          calibrationParams.enabled && setOpportunityToCalibrate(parsedOpportunity)
        }
      }
    )
    track('opportunity_add', {
      area_name: currentInterestArea.name,
      opportunity_name: data.name
    })
  }

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  const onAddOpportunityClick = () => {
    setIsCreateModalOpen(true)
  }

  const onCalibrateOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      setOpportunityToCalibrate(null)
      track('calibrate_opp_dialog_close')
    }
  }

  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const removeToast = useToastMessageStore(state => state.removeToast)

  const { setArea } = useAreaOfInterest()

  const { pushRemovingItem } = useRemovingItemsStore()

  const onConfirmMoveTo = useCallback(
    (opportunity: OpportunityItem) => (newArea: BaseInterestArea) => {
      const removeLoadingToast = addLoadingToast({ text: t('movingOpportunity') })
      pushRemovingItem(opportunity.id)

      if (currentInterestArea) {
        moveOpportunity(
          {
            opportunity,
            currentAreaId: currentInterestArea.id,
            newAreaId: newArea.id
          },
          {
            onSettled: () => removeLoadingToast(),
            onSuccess: () => {
              const toastId = `opp-move-toast-${opportunity.id}`
              track('area_move_opportunity_to', {
                to: newArea.name,
                from: currentInterestArea?.name ?? '',
                name: opportunity.name
              })
              addSuccessToast({
                id: toastId,
                children: (
                  <MoveOppToSuccessMessage
                    areaName={newArea.name}
                    onClick={() => {
                      setArea(newArea)
                      removeToast(toastId)
                    }}
                  />
                ),
                duration: 4000
              })
            }
          }
        )
      }
    },
    [
      addLoadingToast,
      addSuccessToast,
      currentInterestArea,
      moveOpportunity,
      pushRemovingItem,
      setArea,
      t,
      removeToast,
      track
    ]
  )

  const { userPermissions } = useUser()
  const isManager = userPermissions.areas.includes('manager')

  if (!currentInterestArea) return null

  if (isLoading) {
    return (
      <Card.Root color="brandPrimaryPure" direction="column" gap="xxs">
        <Card.Header>
          <Card.IconBox>
            <LightbulbFilament />
          </Card.IconBox>
          <Card.Title>{t('opportunity')}</Card.Title>
        </Card.Header>
        <OpportunityListSkeleton />
      </Card.Root>
    )
  }

  if (!isLoading && data.length === 0)
    return (
      <Card.Root color="brandPrimaryPure" direction="column" gap="xxs">
        <Card.Header>
          <Card.IconBox>
            <LightbulbFilament />
          </Card.IconBox>
          <Card.Title>{t('opportunity')}</Card.Title>
        </Card.Header>

        <FlexContainer alignItems="center" direction="column" fullWidth gap="xxxs">
          <DrawingImage alt="A black and white bird looking at empty boxes." src={Drawing} />
          {isManager ? (
            <>
              <Text align="center" as="p" fontSize="xxs">
                {t('startUncoveringOpportunities')}
              </Text>
              <Button onClick={onAddOpportunityClick} size="small">
                {t('addOpportunity')}
              </Button>
            </>
          ) : (
            <Text align="center" as="p" fontSize="xxs">
              {t('askAManagerToAddOpportunities')}
            </Text>
          )}
        </FlexContainer>

        <CreateOpportunityModal
          onCreate={onCreateOpportunity}
          onOpenChange={setIsCreateModalOpen}
          open={isCreateModalOpen}
        />
      </Card.Root>
    )

  return (
    <Card.Root color="brandPrimaryPure" direction="column" gap="xxs">
      <Card.Header css={{ justifyContent: 'space-between' }}>
        <FlexContainer alignItems="center" gap="micro">
          <FlexContainer alignItems="center" gap="nano">
            <Card.IconBox>
              <LightbulbFilament />
            </Card.IconBox>
            <Card.Title>{t('opportunity')}</Card.Title>
          </FlexContainer>
          <NewOpportunity.Filter
            checked={filterNew}
            count={newOppsCount}
            onCheckedChange={setFilterNew}
          />
        </FlexContainer>

        <OpportunitiesListFilters
          selectedStatus={selectedStatusFilter}
          setSelectedStatus={setSelectedStatusFilter}
        />
      </Card.Header>
      <FlexContainer css={{ overflow: 'hidden' }} direction="column">
        <FlexContainer
          css={{
            overflow: 'auto',
            pr: '$nano',
            maxHeight: 400,
            a: { textDecoration: 'none' }
          }}
          direction="column"
        >
          <OpportunityTable
            data={data}
            filterNew={filterNew}
            isLoading={isLoading}
            isUpdating={isUpdatingOpportunity || isMovingOpportunity}
            onConfirmMoveTo={onConfirmMoveTo}
            onRename={setOportunityToRename}
            onStatusChange={onStatusChange}
            selectedStatusFilter={selectedStatusFilter}
          />
        </FlexContainer>
        {isManager && (
          <OpportunityItemRow
            className="scroll-on-hover-child"
            css={{ mt: '$nano' }}
            onClick={onAddOpportunityClick}
            variant="button"
          >
            <Plus />
            <Text typeface="subtitleSemiBoldXXXS">{t('addOpportunity')}</Text>
          </OpportunityItemRow>
        )}
      </FlexContainer>

      <DeleteDialog
        confirmText={t('delete')}
        description={t(
          'deletingWillPermanentlyEraseThisOpportunityAndAnyChartsAssociatedPleaseProceedWithCaution'
        )}
        isDeleting={false}
        onConfirmDelete={() => setOportunityToDelete(undefined)}
        onOpenChange={open => setOportunityToDelete(open ? opportunityToDelete : undefined)}
        open={!!opportunityToDelete}
        title={t('deleteOpportunityQuestion')}
      />

      {!!opportunityToRename && (
        <RenameOpportunityDialog
          onClose={() => setOportunityToRename(undefined)}
          onSave={onConfirmRename}
          opportunityName={opportunityToRename.name}
        />
      )}
      <CreateOpportunityModal
        onCreate={onCreateOpportunity}
        onOpenChange={setIsCreateModalOpen}
        open={isCreateModalOpen}
      />
      <CalibrateOpportunityDialog
        onOpenChange={onCalibrateOpenChange}
        setOpportunityIdToCalibrate={opportunityToCalibrate}
        open={!!opportunityToCalibrate}
      />
    </Card.Root>
  )
}

export default OpportunitiesListV2
