import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import useSegmentationStore from '@/store/useSegmentationStore'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import usePageLink from '@/hooks/usePageLink'
import { useEffect } from 'react'
import ExplorationIndicators from '@/components/molecules/exploration/indicators/ExplorationIndicators'
import FeedSummary from '@/components/molecules/exploration/summary'
import FeedbackListExploration from '@/components/molecules/exploration/feedback/FeedbackListExploration'
import useSegment from '@/hooks/useSegment'
import SegmentMetricsTrendline from '@/components/molecules/metrics-trendline/SegmentMetricsTrendline'
import { EntityBadge } from '@/components/atoms/badge'
import AreasOfSegment from '@/components/molecules/segmentations/AreasOfSegment'
import OpportunitiesOfSegment from '@/components/molecules/segmentations/OpportunitiesOfSegment'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useOpportunityStore from '@/store/useOpportunityStore'
import useLocalStorage from '@/hooks/useLocalStorage'
import { OPPORTUNITY_PLAN_KEY } from '@/types/params/OpportunityPlanParams'
import { defaultOpportunityPlanFormData } from '../params'

const SegmentPage = () => {
  const currentSegmentation = useSegmentationStore(state => state.currentSegmentation)
  const { applyFilterFromSegmentation, context } = useAdvancedFilters()

  const setCurrentArea = useCurrentInterestAreaStore(state => state.setCurrentInterestArea)
  const setCurrentOpportunity = useOpportunityStore(state => state.setCurrentOpportunity)

  usePageLink()

  const { track } = useSegment()

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    if (currentSegmentation) {
      setCurrentArea(undefined)
      setCurrentOpportunity(undefined)

      if (!context) {
        applyFilterFromSegmentation(currentSegmentation)
      }
    }
  }, [])

  const trackSourceChange = (sourceKey: string | undefined) => {
    track('segment_source_filter_on_feedback', { source_key: sourceKey })
  }

  const trackExportFeedback = () => {
    track('segment_export_feedback')
  }

  const trackMetricScroll = (scroll: string) => {
    track('segment_metrics_scroll', { scroll })
  }

  const [opportunityPlanParams] = useLocalStorage(
    OPPORTUNITY_PLAN_KEY,
    defaultOpportunityPlanFormData
  )

  if (!opportunityPlanParams.enableSegments) {
    return (
      <Text fontSize="xs" fontWeight="bold">
        No permission to view this page
      </Text>
    )
  }

  return (
    <>
      {currentSegmentation && (
        <FlexContainer alignItems="center" gap="micro">
          <Text fontSize="xs" fontWeight="bold">
            {currentSegmentation?.name}
          </Text>
          <EntityBadge.Segment />
        </FlexContainer>
      )}

      <ExplorationIndicators onTrackScroll={trackMetricScroll} />
      <FeedSummary />
      <AreasOfSegment />
      <OpportunitiesOfSegment />
      <SegmentMetricsTrendline />
      <FeedbackListExploration
        trackExportFeedback={trackExportFeedback}
        trackSourceChange={trackSourceChange}
      />
    </>
  )
}

export default SegmentPage
