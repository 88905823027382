import {
  FeedbackHeader,
  FeedbackThreadHeader,
  Group,
  FeedbackType,
  FeedbackRating as FeedbackRatingContainer,
  FeedbackDate,
  FeedbackAccountField,
  FeedbackAccountFieldContainer
} from './FeedbackItem.styles'
import { FeedbackItemProps } from './FeedbackItem.types'
import { dateShortMonthFormat } from '@/utils/date'
import FeedbackItemOptions from './FeedbackItemOptions'
import { formatCurrency, isNumberNull } from '@/utils/numbers'
import useAccountsStore from '@/store/useAccountsStore'
import FeedbackAccountSkeleton from './FeedbackAccountSkeleton'
import FlexContainer from '@/components/atoms/flex-container'
import Tooltip from '@/components/atoms/tooltip'
import useCustomerUsersStore from '@/store/useCustomerUsersStore'
import { ReactNode, useMemo } from 'react'
import { AccountFinancials, AccountPlanType } from '@/types/manage-customers'
import { Star } from '@phosphor-icons/react'

interface CustomerRecordFieldProps {
  text: string
  label: string
  size?: 'small' | 'regular'
}

const CustomerRecordField = ({ text, label, size = 'small' }: CustomerRecordFieldProps) => (
  <Tooltip side="bottom" text={text} variant="small">
    <FeedbackAccountFieldContainer size={size}>
      <span>{label}: </span>
      <FeedbackAccountField>{text}</FeedbackAccountField>
    </FeedbackAccountFieldContainer>
  </Tooltip>
)

const SATISFACTION_KINDS = ['csat', 'nps', 'review', 'reviews']
const FeedbackRating = (props: FeedbackItemProps) => {
  const feedbackType = (props.dataType || props.feedbackType || '').toLowerCase()
  if (!SATISFACTION_KINDS.includes(feedbackType)) {
    return <></>
  }

  return (
    <FeedbackRatingContainer>
      : {!isNumberNull(props.rating) && props.rating}
      {['review', 'reviews'].includes(feedbackType) && <Star size={16} weight="fill" />}
    </FeedbackRatingContainer>
  )
}

const dateFormat = dateShortMonthFormat

const FeedbackItemHeader = (props: FeedbackItemProps) => {
  const accountResult = useAccountsStore(state =>
    state.accountsFeedbacksResults.find(account => account.id === props.accountId)
  )
  const usersResult = useCustomerUsersStore(state =>
    state.usersFeedbacksResults.find(user => user.id === props.authorId)
  )
  const hasCustomerRecordResult = useMemo(() => {
    if (!props.showAccountsData) return false

    return !!accountResult || !!usersResult
  }, [accountResult, usersResult, props.showAccountsData])

  const isLoadingCustomerRecords = useMemo(() => {
    if (!props.showAccountsData) return false

    return accountResult?.isLoading || usersResult?.isLoading || false
  }, [accountResult, usersResult, props.showAccountsData])

  const customerRecordsFields = useMemo(() => {
    const fields: ReactNode[] = []
    if (!accountResult?.account && !usersResult?.user) {
      fields.push(<></>)
      return fields
    }

    if (usersResult?.user) {
      const { user } = usersResult
      fields.push(
        <CustomerRecordField key="user.name" label="Name" size="regular" text={user.name} />
      )
      if (user.email) {
        fields.push(<CustomerRecordField key="user.email" label="E-mail" text={user.email} />)
      }
    }

    if (accountResult?.account) {
      const { account } = accountResult
      const { otherFields } = account

      fields.push(
        <CustomerRecordField
          key="account.name"
          label="Company name"
          size="regular"
          text={account.name}
        />
      )
      if (otherFields) {
        if (otherFields.plan && typeof (otherFields.plan as AccountPlanType).name === 'string') {
          fields.push(
            <CustomerRecordField
              key="account.plan_name"
              label="Plan"
              text={(otherFields.plan as AccountPlanType).name}
            />
          )
        }

        const financials = otherFields.financials as AccountFinancials | undefined
        if (
          typeof financials === 'object' &&
          (financials.employees || financials.annual_revenue || financials.spending)
        ) {
          if (financials.spending?.amount && financials.spending.currency) {
            const { amount, currency } = financials.spending
            const formatedAmount = formatCurrency(amount, currency)

            formatedAmount &&
              fields.push(
                <CustomerRecordField
                  key="account.annual_spend"
                  label="Annual Spend ($)"
                  text={formatedAmount}
                />
              )
          }

          if (financials.annual_revenue?.amount && financials.annual_revenue.currency) {
            const { amount, currency } = financials.annual_revenue
            const formatedAmount = formatCurrency(amount, currency)

            formatedAmount &&
              fields.push(
                <CustomerRecordField key="account.arr" label="ARR" text={formatedAmount} />
              )
          }
          if (financials.employees) {
            fields.push(
              <CustomerRecordField
                key="account.employees"
                label="Employees"
                text={`${financials.employees}`}
              />
            )
          }
        }

        if (otherFields.industry && typeof otherFields.industry === 'string') {
          fields.push(
            <CustomerRecordField
              key="account.industry"
              label="Industry"
              text={otherFields.industry as string}
            />
          )
        }

        if (typeof otherFields.plan === 'object') {
          const plan = otherFields.plan as AccountPlanType

          plan.start_date &&
            fields.push(
              <CustomerRecordField
                key="account.plan_start_date"
                label="Contract start date"
                text={plan.start_date}
              />
            )

          plan.end_date &&
            fields.push(
              <CustomerRecordField
                key="account.plan_end_date"
                label="Contract end date"
                text={plan.end_date}
              />
            )
        }
      }
    }

    return fields.slice(0, 3)
  }, [accountResult, usersResult])

  const feedbackType = props.dataType ?? props.feedbackType

  return (
    <FeedbackHeader isThreadItem={props.isThreadItem}>
      <Group>
        {props.isThreadItem ? (
          <FeedbackThreadHeader>
            <strong>{props.threadAuthor || 'Unidentified author'}</strong>
          </FeedbackThreadHeader>
        ) : (
          <>
            {props.enableHighlightOnFocus && props.index !== undefined && (
              <span title={`Index: ${props.index + 1}`}>({props.index + 1})</span>
            )}
            <FlexContainer>
              <FeedbackType>{feedbackType}</FeedbackType>
              <FeedbackRating {...props} />
            </FlexContainer>
          </>
        )}
        {hasCustomerRecordResult && (
          <>
            {isLoadingCustomerRecords ? (
              <FeedbackAccountSkeleton />
            ) : (
              <FlexContainer css={{ ml: '$xxs' }} gap="xs">
                {customerRecordsFields}
              </FlexContainer>
            )}
          </>
        )}
      </Group>

      <Group>
        {props.postedAt ? <FeedbackDate>{dateFormat(props.postedAt, false)}</FeedbackDate> : null}
      </Group>

      <FeedbackItemOptions {...props} />
    </FeedbackHeader>
  )
}

export default FeedbackItemHeader
