import { useEffect, useMemo, useState } from 'react'
import * as Collapsible from '@radix-ui/react-collapsible'
import { CaretDoubleLeft, CaretRight } from '@phosphor-icons/react'
import { useLocation } from 'react-router-dom'

import Button from '@/components/atoms/button'
import { CollapsibleContentAnimated } from '@/components/atoms/collapsible'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import useSegment from '@/hooks/useSegment'
import CustomFieldsService from '@/services/CustomFieldsServices'
import IntegrationService from '@/services/IntegrationServices'
import { useUserStore } from '@/store'
import useIntegrationsStore from '@/store/useIntegrationsStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import {
  ConnectorArguments,
  ConnectorArgumentWithValue,
  CreateFieldsConfigPayload,
  IntegrationConnector,
  IntegrationFormData,
  IntegrationSource,
  TestConnectionSuccessResponse
} from '@/types/integrations'
import {
  formatConnectorName,
  resizeLogoUrl,
  toExtractionPayload,
  toUpdateExtractionPayload
} from '../../utils'
import ConnectorArgumentsFields from '../ConnectorArguments'
import { FormStep } from '../IntegrationForm'
import {
  CollapsibleButton,
  FormStepContainer,
  FormStepSection,
  OtherCustomizationsSectionGrid
} from '../IntegrationForm.styles'
import CustomFieldsSection from './CustomFieldsSection'
import FieldsMatchedSection from './FieldsMatchedSection'
import { useTranslation } from 'react-i18next'

interface ReviewStepProps {
  connector: IntegrationConnector
  connectorFields: Record<string, ConnectorArguments>
  disableSubmit: boolean
  formData: IntegrationFormData
  handleFieldsChange(key: string, field: ConnectorArgumentWithValue): void
  handleScheduleChange(value: string): void
  integration: IntegrationSource | undefined
  isEdit: boolean
  integrationSample: TestConnectionSuccessResponse
  setCurrentStep(step: FormStep): void
  setIntegrationId(id: string): void
}

export type CustomFieldMapPair = Partial<CreateFieldsConfigPayload>

function ReviewStep(props: ReviewStepProps) {
  const { key } = useLocation()
  const { t } = useTranslation()

  const loadIntegrations = useIntegrationsStore(state => state.loadIntegrations)
  const currentUser = useUserStore(state => state.currentUser)
  const addToast = useToastMessageStore(state => state.addToast)

  const { track } = useSegment()

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [customCoreFields, setCustomCoreFields] = useState<Record<string, string | null>>({})

  useEffect(() => {
    if (props.integration && props.integration.core_fields) {
      setCustomCoreFields(props.integration.core_fields)
    }
  }, [props.integration])

  /* TODO: Put relevant information in store */
  const fieldsConfig = props.integration?.fields_config
  const emptyCustomFieldMapPair = { field_id: undefined, key: undefined }

  const existingCustomFieldMaps =
    fieldsConfig?.map(fieldConfig => ({
      field_id: fieldConfig.field.id,
      key: fieldConfig.key
    })) ?? []

  const [customFieldsMap, setCustomFieldsMap] = useState<CustomFieldMapPair[]>([
    ...existingCustomFieldMaps,
    emptyCustomFieldMapPair
  ])

  function createCustomFieldMapPair() {
    setCustomFieldsMap(previousState => [...previousState, emptyCustomFieldMapPair])
  }

  function removeCustomFieldMapPair(position: number) {
    return function () {
      setCustomFieldsMap(previousState => previousState.filter((_, index) => index !== position))
    }
  }

  function updateCustomFieldMapPair(position: number) {
    return function (field: keyof CustomFieldMapPair, value: string) {
      setCustomFieldsMap(previousState =>
        previousState.map((pair, index) => {
          if (position === index) return { ...pair, [field]: value }
          return pair
        })
      )
    }
  }

  async function submitReviewStep() {
    if (props.disableSubmit) return

    try {
      setIsLoading(true)

      if (Array.isArray(fieldsConfig) && fieldsConfig.length > 0) {
        const fieldsConfigIds = fieldsConfig.map(fieldConfig => fieldConfig.id)
        await CustomFieldsService.deleteFieldsConfigBatch(fieldsConfigIds)
      }

      const createFieldsConfigPayload = customFieldsMap.filter(({ field_id: fieldId, key }) => {
        return [fieldId, key].every(Boolean)
      }) as CreateFieldsConfigPayload[]

      let newFieldsConfigIds: string[] = []

      if (createFieldsConfigPayload.length > 0) {
        const newFieldsConfig =
          await CustomFieldsService.createFieldsConfigBatch(createFieldsConfigPayload)

        newFieldsConfigIds = newFieldsConfig.map(fieldConfig => fieldConfig.id)
      }

      if (props.isEdit && props.integration) {
        const payload = toUpdateExtractionPayload(
          props.formData,
          newFieldsConfigIds,
          props.integration.id,
          customCoreFields
        )

        await IntegrationService.updateExtraction(payload)
      } else {
        const payload = toExtractionPayload(
          props.formData,
          newFieldsConfigIds,
          currentUser?.organization_id || '',
          customCoreFields
        )

        const response = await IntegrationService.createExtraction(payload)
        props.setIntegrationId(response.extractions_created[0].id)

        track('sources_user_integration-new_integration_creation', {
          connector_name: props.connector.name
        })
      }

      await loadIntegrations()
      props.setCurrentStep('done')
    } catch {
      addToast({
        id: 'error-extraction',
        status: 'error',
        text: `Failed to ${props.isEdit ? 'edit' : 'create'} integration`,
        open: true
      })
    } finally {
      setIsLoading(false)
    }
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: it should react to key change
  useEffect(() => {
    setShowAdvancedOptions(false)
  }, [key])

  const dateFields = useMemo(() => {
    return Object.fromEntries(
      Object.entries(props.connectorFields).filter(([, value]) => value.type === 'date')
    )
  }, [props.connectorFields])

  const otherFields = useMemo(() => {
    return Object.fromEntries(
      Object.entries(props.connectorFields).filter(([, value]) => value.type !== 'date')
    )
  }, [props.connectorFields])

  return (
    <FormStepContainer>
      <FlexContainer direction="column" gap="micro">
        <FlexContainer justifyContent="spaceBetween">
          <Text as="h1" typeface="titleBoldXS">
            {t('2fieldsSetup')}
          </Text>
          <FlexContainer alignItems="center" gap="xxxs">
            <img alt="" height={40} src={resizeLogoUrl(props.connector.logo_url, 40)} />
            <Text typeface="subtitleSemiBoldXXXS">{formatConnectorName(props.connector.name)}</Text>
          </FlexContainer>
        </FlexContainer>

        <FieldsMatchedSection
          connectorId={props.connector.id}
          connectorName={formatConnectorName(props.connector.name)}
          customCoreFields={customCoreFields}
          integrationSample={props.integrationSample}
          setCustomCoreFields={setCustomCoreFields}
        />
      </FlexContainer>

      <Collapsible.Root onOpenChange={setShowAdvancedOptions} open={showAdvancedOptions}>
        <FlexContainer css={{ gap: 24 }} direction="column">
          <Collapsible.Trigger asChild>
            <CollapsibleButton type="button">
              <CaretRight size={16} weight="bold" />
              <Text typeface="titleBoldXXS">
                {showAdvancedOptions ? t('hideAdvancedOptions') : t('showAdvancedOptions')}
              </Text>
            </CollapsibleButton>
          </Collapsible.Trigger>
          <CollapsibleContentAnimated asChild>
            <FlexContainer css={{ gap: 32 }} direction="column">
              <CustomFieldsSection
                createCustomFieldMapPair={createCustomFieldMapPair}
                customFieldsMap={customFieldsMap}
                integrationName={formatConnectorName(props.connector.name)}
                integrationSample={props.integrationSample}
                removeCustomFieldMapPair={removeCustomFieldMapPair}
                updateCustomFieldMapPair={updateCustomFieldMapPair}
              />

              <FormStepSection>
                <Text as="h2" css={{ lineHeight: 1.25 }} typeface="titleBoldXXS">
                  {t('otherCustomizations')}
                </Text>
                <OtherCustomizationsSectionGrid>
                  <ConnectorArgumentsFields
                    connectorId={props.formData.connectorId}
                    fieldValues={props.formData.fields}
                    fields={dateFields}
                    handleChange={props.handleFieldsChange}
                  />

                  <ConnectorArgumentsFields
                    connectorId={props.formData.connectorId}
                    fieldValues={props.formData.fields}
                    fields={otherFields}
                    handleChange={props.handleFieldsChange}
                  />
                </OtherCustomizationsSectionGrid>
              </FormStepSection>
            </FlexContainer>
          </CollapsibleContentAnimated>
        </FlexContainer>
      </Collapsible.Root>

      <FlexContainer css={{ gap: 16 }} justifyContent="flexEnd">
        <Button onClick={() => props.setCurrentStep('configure')} variant="flat">
          <CaretDoubleLeft size={16} /> {t('back')}
        </Button>
        <Button disabled={props.disableSubmit || isLoading} onClick={submitReviewStep}>
          {isLoading ? t('loading') : t('thatsItFinish')}
        </Button>
      </FlexContainer>
    </FormStepContainer>
  )
}

export default ReviewStep
