import { ResponseBase } from '@/types'
import { KeywordRequests } from '@/types/keywords'
import Request from './Request'

export default class KeywordService {
  static PREFIX = '/keyword'

  static async search(payload: KeywordRequests.SearchPayload) {
    return await Request.post<ResponseBase<KeywordRequests.SearchResponse>>(
      `${this.PREFIX}/search`,
      payload
    )
  }

  static async clusterSearch(payload: KeywordRequests.ClusterSearchPayload) {
    const fullPayload = { ...payload }

    return await Request.post<KeywordRequests.ClusterSearchResponse[]>(
      `${this.PREFIX}/cluster_search`,
      fullPayload
    )
  }

  static async getSuggestionsByTopicId(payload: KeywordRequests.GetKeywordSuggestionsPayload) {
    const fullPayload = { ...payload }

    const { topicId, topTopicKeywords, k, minSimilarity, offset, size } = fullPayload
    const params = new URLSearchParams()

    if (topTopicKeywords) params.append('top_topic_keywords', `${topTopicKeywords}`)
    if (k) params.append('k', `${k}`)
    if (minSimilarity) params.append('min_similarity', `${minSimilarity}`)
    if (offset) params.append('offset', `${offset}`)
    if (size) params.append('size', `${size}`)

    return await Request.get<ResponseBase<KeywordRequests.GetKeywordSuggestionsResponse[]>>(
      `${this.PREFIX}/suggestion/${topicId}?${params.toString()}`
    )
  }

  static async removeKeywordSuggestion({
    keywordHash,
    topicId
  }: KeywordRequests.RemoveKeywordPayload) {
    return await Request.post(`${this.PREFIX}/${keywordHash}/topics/${topicId}/blocklist`)
  }

  static async createKeyword(keywordText: string) {
    return await Request.post<KeywordRequests.CreateResponse>(`${this.PREFIX}/text`, {
      text: keywordText
    })
  }

  static async getById(keywordHash: string, includeFeedback = true) {
    return await Request.get<KeywordRequests.KeywordWithFeedbackList>(
      `${this.PREFIX}/${keywordHash}?include_feedback=${includeFeedback}`
    )
  }

  static async deleteKeyword(keywordHash: string, keywordText: string) {
    return await Request.del(`${this.PREFIX}/${keywordHash}`, undefined, { text: keywordText })
  }

  static async deleteKeywordBatch(keywordHashs: string[]) {
    return await Request.del(`${this.PREFIX}`, undefined, { keyword_hash: keywordHashs })
  }

  static async updateKeywordSentiment(
    keywordHash: string,
    payload: KeywordRequests.UpdateSentimentPayload
  ) {
    return await Request.patch<void>(
      `${this.PREFIX}/${keywordHash}/feedback/${payload.feedback_id}`,
      payload
    )
  }

  static async removeFeedbackKeyword(payload: KeywordRequests.RemoveFeedbackKeywordPayload) {
    return await Request.patch<void>(`${this.PREFIX}/feedback-keyword`, payload)
  }

  static async mergeCluster(payload: KeywordRequests.MergeClusterPayload) {
    return await Request.post<void>(`${this.PREFIX}/merge_cluster`, payload)
  }
}
