import { memo, useMemo } from 'react'
import { DateValue, parseDate } from '@internationalized/date'
import { Question } from '@phosphor-icons/react'
import { useLocation } from 'react-router-dom'

import DatePicker from '@/components/atoms/date-picker'
import FlexContainer from '@/components/atoms/flex-container'
import Input from '@/components/atoms/input'
import Text from '@/components/atoms/text'
import Tooltip from '@/components/atoms/tooltip'
import { ConnectorArguments } from '@/types/integrations'
import { getAbsoluteDateTimeString } from '../utils'
import CountrySelect from './CountrySelect'
import { FormFieldContainer } from './IntegrationForm.styles'

interface Props {
  onChange: (value: string | string[]) => void
  fieldKey: string
  field: ConnectorArguments
  value: string | string[]
}

const ConnectorField = ({ onChange, fieldKey, field, value }: Props) => {
  const { pathname } = useLocation()

  const input = useMemo(() => {
    if (field.type === 'date' && typeof value === 'string') {
      const result = /\d{4}-\d{2}-\d{2}/.exec(value)
      const date = result?.[0] ? parseDate(result[0]) : undefined

      const onChangeDate = (value: DateValue) => onChange(getAbsoluteDateTimeString(value))

      return <DatePicker onChange={onChangeDate} value={date} />
    }

    if (fieldKey === 'countries' && Array.isArray(value)) {
      return <CountrySelect onChange={onChange} values={value} />
    }

    if (field.type === 'secret') {
      const isEdit = pathname.includes('/edit')
      const placeholder = isEdit ? '************' : undefined
      const required = isEdit && value === '' ? undefined : field.required

      return (
        <Input
          id={fieldKey}
          onChange={e => onChange(e.target.value)}
          placeholder={placeholder}
          required={required}
          value={value || ''}
        />
      )
    }

    return (
      <Input
        id={fieldKey}
        onChange={e => onChange(e.target.value)}
        required={field.required}
        value={value || ''}
      />
    )
  }, [onChange, fieldKey, value, field.type, field.required, pathname])

  return (
    <FormFieldContainer key={fieldKey}>
      <FlexContainer alignItems="center" css={{ gap: 4 }}>
        <Text as="label" htmlFor={fieldKey} typeface="subtitleRegularXXXS">
          {field.name}
        </Text>
        {field.description && (
          <Tooltip side="right" text={field.description} variant="small">
            <Question size={16} weight="fill" />
          </Tooltip>
        )}
      </FlexContainer>
      {input}
    </FormFieldContainer>
  )
}

export default memo(ConnectorField)
