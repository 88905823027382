import { SourceDistributionItem } from '@/types/analytics/Sources'
import { useState } from 'react'
import IndicatorCard from './IndicatorCard'
import { Stack } from '@phosphor-icons/react'
import Text from '@/components/atoms/text'
import { roundAndSuffix } from '@/utils/numbers'
import DistributionChart from '@/components/atoms/distribution-chart'
import { CSS } from '@/theme'
import FlexContainer from '@/components/atoms/flex-container'
import Button from '@/components/atoms/button'
import { useTranslation } from 'react-i18next'

interface SourceDistributionProps {
  sources: (SourceDistributionItem & { color?: string })[]
  total: number
  css?: CSS
  distributionContainerCss?: CSS
  highlightSource?: SourceDistributionItem[]
  showResetButton?: boolean
  resetButtonCss?: CSS
  titlePrefix?: string
  onClickSource?: (item: SourceDistributionItem | undefined) => void
}

const ExplorationSourceDistribution = ({
  sources,
  total,
  showIcon = true,
  highlightSource,
  css,
  distributionContainerCss,
  showResetButton,
  resetButtonCss,
  titlePrefix,
  onClickSource
}: SourceDistributionProps & { showIcon?: boolean }) => {
  const { t } = useTranslation()
  const defaultSource: SourceDistributionItem = {
    name: t('allSources'),
    count: total,
    percentage: 100,
    key: 'total'
  }
  const [activeSource, setActiveSource] = useState<SourceDistributionItem[]>([defaultSource])

  const currentSources = (() => {
    if (activeSource.length) return activeSource
    return highlightSource?.length ? highlightSource : [defaultSource]
  })()

  const count = currentSources.reduce((acc, value) => {
    return acc + value.count
  }, 0)

  const isMuted = (source: SourceDistributionItem) => {
    if (currentSources.find(currentSource => currentSource.key === 'total')) return false
    return !currentSources.map(currentSource => currentSource.name).includes(source.name)
  }

  const onSourceHover = (source: SourceDistributionItem) => {
    setActiveSource(prevSources => {
      if (prevSources.find(prevSource => prevSource.key === source.key)) return prevSources
      return [...prevSources, source].filter(prevSource => prevSource.key !== 'total')
    })
  }

  const onSourceLeave = () => {
    setActiveSource(highlightSource?.length ? highlightSource : [defaultSource])
  }

  const resetSource = () => {
    setActiveSource([defaultSource])
    onClickSource?.(undefined)
  }

  const enableResetButton = showResetButton && (highlightSource?.length ?? 0) > 0

  return (
    <IndicatorCard.Column css={css} fullWidth>
      <IndicatorCard.Header spaceBetween={showResetButton}>
        <FlexContainer alignItems="center" gap="nano">
          {showIcon && (
            <IndicatorCard.IconBox>
              <Stack />
            </IndicatorCard.IconBox>
          )}
          <IndicatorCard.Title>
            {titlePrefix} {currentSources.map(source => source.name).join(' + ')}
          </IndicatorCard.Title>
          <Text as="span" color="neutralLowLight" fontSize="xxs" fontWeight="semibold">
            | {roundAndSuffix(count)}
          </Text>
        </FlexContainer>

        {enableResetButton && (
          <Button css={resetButtonCss} onClick={resetSource} size="small" variant="flat">
            {t('reset')}
          </Button>
        )}
      </IndicatorCard.Header>
      <DistributionChart.Root css={distributionContainerCss}>
        {sources.map((source, index) => (
          <DistributionChart.Section
            customColor={source.color}
            index={index}
            key={source.name}
            muted={isMuted(source)}
            onClick={() => onClickSource?.(source)}
            onHover={() => onSourceHover(source)}
            onLeave={onSourceLeave}
            percentage={source.percentage}
          />
        ))}
      </DistributionChart.Root>
    </IndicatorCard.Column>
  )
}

export default ExplorationSourceDistribution
