import { ThemeRequests, TopicRequests } from '@/types/classification'
import { FeedbackRequests } from '@/types/feedbacks'
import { AccountSearchType } from '@/types/feedbacks/FeedbackRequests'
import { FeedbackFilter } from '@/types/filters/FilterRequests'
import { FilterBoolean, FilterNumeric, MultiSelect } from '@/types/filters/Filters'
import { KeywordSortType, KeywordRequests } from '@/types/keywords'
import { ReasonsRequests } from '@/types/reasons'
import {
  getFromStringFilters,
  getFromNumericFilters,
  getFromDatetimeFilters,
  getTopicGroups
} from '@/utils/filters'
import {
  CustomerRecordsDateFilter,
  PayloadParsersProperties,
  UseFiltersState
} from './useFiltersStore.types'

interface ParseAccountsParams {
  accountsStringFilters: Record<string, MultiSelect>
  accountsDateFilters: CustomerRecordsDateFilter[]
  accountNumericFilters: FilterNumeric[]
  accountBooleanFilters: FilterBoolean[]
}

const parseAccountFiltersToPayload = ({
  accountsStringFilters,
  accountsDateFilters,
  accountNumericFilters,
  accountBooleanFilters
}: ParseAccountsParams): AccountSearchType => {
  const accountsStringsPayload: Record<string, unknown> = {}
  const parsedKeysMap: Record<string, keyof AccountSearchType> = {
    plans: 'plan_name',
    plan: 'plan_name',
    industries: 'industry',
    sales_pipeline_stages: 'sales_pipeline_stage',
    customer_types: 'customer_type',
    ingestedId: 'id',
    ingested_id: 'id',
    name: 'id'
  }

  Object.entries(accountsStringFilters).forEach(([key, value]) => {
    const parsedKey: string = parsedKeysMap[key] || key

    accountsStringsPayload[parsedKey] = [
      ...new Set([...((accountsStringsPayload[parsedKey] as string[]) || []), ...value.selected])
    ]
  })

  const parsedDateFilters: Record<
    string,
    {
      gte: string
      lte: string
    } | null
  > = {}

  accountsDateFilters.forEach(dateFilter => {
    if (dateFilter.selected) {
      const value = dateFilter.selected
      const start = value.start.toString()
      const end = value.end.toString()
      parsedDateFilters[dateFilter.key] = {
        gte: start.slice(0, start.indexOf('T')),
        lte: end.slice(0, end.indexOf('T'))
      }
    }
  })
  const { regularNumericFilters } = getFromNumericFilters(accountNumericFilters)

  const parsedBooleanFilters: Record<string, boolean> = {}
  accountBooleanFilters.forEach(booleanFilter => {
    if (booleanFilter.value !== null) {
      parsedBooleanFilters[booleanFilter.key] = booleanFilter.value
    }
  })

  return {
    ...accountsStringsPayload,
    ...regularNumericFilters,
    ...parsedDateFilters,
    ...parsedBooleanFilters
  }
}

interface ParseUsersPrams {
  usersStringFilters: Record<string, MultiSelect>
  usersDateFilters: CustomerRecordsDateFilter[]
  usersNumericFilters: FilterNumeric[]
  usersBooleanFilters: FilterBoolean[]
}

const parseUsersFilters = ({
  usersStringFilters,
  usersDateFilters,
  usersNumericFilters,
  usersBooleanFilters
}: ParseUsersPrams) => {
  const usersStringPayload: Record<string, unknown> = {}
  const parsedKeysMap: Record<string, 'id' | 'email'> = {
    ingestedId: 'id'
  }

  Object.entries(usersStringFilters).forEach(([key, value]) => {
    const parsedKey = parsedKeysMap[key] || key

    usersStringPayload[parsedKey] = [
      ...new Set([...((usersStringPayload[parsedKey] as string[]) || []), ...value.selected])
    ]
  })

  const parsedDateFilters: Record<
    string,
    {
      gte: string
      lte: string
    } | null
  > = {}

  usersDateFilters.forEach(dateFilter => {
    if (dateFilter.selected) {
      const value = dateFilter.selected
      const start = value.start.toString()
      const end = value.end.toString()
      parsedDateFilters[dateFilter.key] = {
        gte: start.slice(0, start.indexOf('T')),
        lte: end.slice(0, end.indexOf('T'))
      }
    }
  })

  const { regularNumericFilters } = getFromNumericFilters(usersNumericFilters)

  const parsedBooleanFilters: Record<string, boolean> = {}
  usersBooleanFilters.forEach(booleanFilter => {
    if (booleanFilter.value !== null) {
      parsedBooleanFilters[booleanFilter.key] = booleanFilter.value
    }
  })

  return {
    ...usersStringPayload,
    ...regularNumericFilters,
    ...parsedDateFilters,
    ...parsedBooleanFilters
  }
}

const payloadParsersProperties = (get: () => UseFiltersState): PayloadParsersProperties => ({
  toKeywordRequestPayload: (page, pageSize, metrics = false) => {
    const {
      search,
      dateRange,
      stringFilters,
      numericFilters,
      datetimeFilters,
      productAreaThemes,
      otherThemes,
      productAreaTopics,
      otherTopics,
      unclassifiedTopics,
      ungroupedTopics,
      volumeBy,
      filtersByURL,
      accountsStringFilters,
      accountNumericFilters,
      accountsDateFilters,
      accountBooleanFilters,
      usersStringFilters,
      usersNumericFilters,
      usersDateFilters,
      usersBooleanFilters
    } = get()

    const {
      regularStringFilters,
      schemaFieldsStringFilters,
      customFieldsStringFilters,
      sentiment,
      feedbackSourceIds,
      dataType
    } = getFromStringFilters(stringFilters)

    const {
      regularNumericFilters,
      schemaFieldsNumberFilters,
      schemaFieldsIntegerFilters,
      customFieldsNumberFilters,
      customFieldsIntegerFilters
    } = getFromNumericFilters(numericFilters)

    const { schemaFieldsDatetimeFilters, customFieldsDatetimeFilters } =
      getFromDatetimeFilters(datetimeFilters)

    const topicGroups = getTopicGroups({
      unclassifiedTopics: unclassifiedTopics.selected,
      selectedProductAreaThemes: productAreaThemes.selected,
      selectedProductAreaTopics: productAreaTopics.selected,
      selectedOtherTopicsThemes: otherThemes.selected,
      selectedOtherTopics: otherTopics.selected,
      selectedUngroupedTopics: ungroupedTopics.selected.map(topic => topic.id)
    })

    const searchText = (filtersByURL ? filtersByURL.filter?.search_text : search) ?? ''
    const postedAt =
      (filtersByURL
        ? filtersByURL.filter?.posted_at
        : dateRange
          ? {
              gte: dateRange.start.toString(),
              lte: dateRange.end.toString()
            }
          : {}) ?? {}
    const actualFeedbackSourceIds =
      (filtersByURL ? filtersByURL.filter?.feedback_source_ids : feedbackSourceIds) ?? []
    const feedbackGroupEnable =
      filtersByURL?.filter?.feedback_group_enable !== undefined
        ? filtersByURL.filter.feedback_group_enable
        : volumeBy === 'ticket'
    const schemaFieldsDatetime = filtersByURL
      ? filtersByURL.filter?.schema_fields_datetime
      : schemaFieldsDatetimeFilters
    const schemaFieldsInteger = filtersByURL
      ? filtersByURL.filter?.schema_fields_integer
      : schemaFieldsIntegerFilters
    const schemaFieldsNumber = filtersByURL
      ? filtersByURL.filter?.schema_fields_number
      : schemaFieldsNumberFilters
    const schemaFieldsString = filtersByURL
      ? filtersByURL.filter?.schema_fields_string
      : schemaFieldsStringFilters
    const customFieldsDatetime = filtersByURL
      ? filtersByURL.filter?.custom_fields_datetime
      : customFieldsDatetimeFilters
    const customFieldsInteger = filtersByURL
      ? filtersByURL.filter?.custom_fields_integer
      : customFieldsIntegerFilters
    const customFieldsNumber = filtersByURL
      ? filtersByURL.filter?.custom_fields_number
      : customFieldsNumberFilters
    const customFieldsString = filtersByURL
      ? filtersByURL.filter?.custom_fields_string
      : customFieldsStringFilters

    const otherFilters = filtersByURL?.filter
      ? {
          ...filtersByURL.filter,
          topic_groups: undefined
        }
      : {
          ...regularNumericFilters,
          ...regularStringFilters
        }

    const actualTopicGroups = filtersByURL?.filter ? filtersByURL.filter.topic_groups : topicGroups

    return {
      search_input_schema: {
        filter: {
          search_text: !metrics ? searchText : '',
          min_frequency: 1,
          min_words: 1,
          feedback_filter: {
            ...otherFilters,
            search_text: metrics ? searchText : '',
            posted_at: postedAt,
            archived: false,
            sentiment,
            feedback_source_ids: actualFeedbackSourceIds,
            data_type: dataType,
            feedback_group_enable: feedbackGroupEnable,
            schema_fields_datetime: schemaFieldsDatetime ?? [],
            schema_fields_integer: schemaFieldsInteger ?? [],
            schema_fields_number: schemaFieldsNumber ?? [],
            schema_fields_string: schemaFieldsString ?? [],
            custom_fields_datetime: customFieldsDatetime ?? [],
            custom_fields_integer: customFieldsInteger ?? [],
            custom_fields_number: customFieldsNumber ?? [],
            custom_fields_string: customFieldsString ?? [],
            account: parseAccountFiltersToPayload({
              accountsDateFilters,
              accountNumericFilters,
              accountsStringFilters,
              accountBooleanFilters
            }),
            user: parseUsersFilters({
              usersDateFilters,
              usersNumericFilters,
              usersStringFilters,
              usersBooleanFilters
            })
          },
          topic_ids: [],
          theme_ids: [],
          topic_groups: metrics ? [] : actualTopicGroups,
          keyword_classes: []
        },
        size: 100,
        offset: page * 100
      },
      n_search_data: 100,
      min_similarity: 0.8,
      k: 20,
      use_graph: true,
      sort_type: KeywordSortType.FREQUENCY,
      metrics,
      embedding_method: 'default',
      reprocess_data: false,
      size: pageSize,
      offset: page * pageSize,
      approximate_count: true
    } as KeywordRequests.ClusterSearchPayload
  },
  toFeedbackRequestPayload: (page = 0, pageSize = 100, archived = false) => {
    const {
      search,
      dateRange,
      stringFilters,
      numericFilters,
      datetimeFilters,
      productAreaThemes,
      otherThemes,
      productAreaTopics,
      otherTopics,
      ungroupedTopics,
      unclassifiedTopics,
      orderBy,
      sortDirection,
      filtersByURL,
      accountsStringFilters,
      accountNumericFilters,
      accountsDateFilters,
      accountBooleanFilters,
      usersStringFilters,
      usersNumericFilters,
      usersDateFilters,
      usersBooleanFilters
    } = get()

    const {
      regularStringFilters,
      schemaFieldsStringFilters,
      customFieldsStringFilters,
      sentiment,
      feedbackSourceIds,
      dataType
    } = getFromStringFilters(stringFilters)

    const {
      regularNumericFilters,
      schemaFieldsNumberFilters,
      schemaFieldsIntegerFilters,
      customFieldsNumberFilters,
      customFieldsIntegerFilters
    } = getFromNumericFilters(numericFilters)

    const { schemaFieldsDatetimeFilters, customFieldsDatetimeFilters } =
      getFromDatetimeFilters(datetimeFilters)

    const topicGroups = getTopicGroups({
      unclassifiedTopics: unclassifiedTopics.selected,
      selectedProductAreaThemes: productAreaThemes.selected,
      selectedProductAreaTopics: productAreaTopics.selected,
      selectedOtherTopicsThemes: otherThemes.selected,
      selectedOtherTopics: otherTopics.selected,
      selectedUngroupedTopics: ungroupedTopics.selected.map(topic => topic.id)
    })

    const getOrderBy = () => {
      if (archived) return 'posted_at'

      return search.length ? orderBy : 'posted_at'
    }

    return {
      filter: filtersByURL?.filter ?? {
        search_text: search,
        search_type: 'contextual',
        posted_at: dateRange
          ? {
              gte: dateRange.start.toString(),
              lte: dateRange.end.toString()
            }
          : {},
        archived,
        sentiment,
        feedback_source_ids: feedbackSourceIds,
        data_type: dataType,
        schema_fields_datetime: schemaFieldsDatetimeFilters,
        schema_fields_integer: schemaFieldsIntegerFilters,
        schema_fields_number: schemaFieldsNumberFilters,
        schema_fields_string: schemaFieldsStringFilters,
        custom_fields_datetime: customFieldsDatetimeFilters,
        custom_fields_integer: customFieldsIntegerFilters,
        custom_fields_number: customFieldsNumberFilters,
        custom_fields_string: customFieldsStringFilters,
        topic_groups: topicGroups,
        account: parseAccountFiltersToPayload({
          accountsDateFilters,
          accountNumericFilters,
          accountsStringFilters,
          accountBooleanFilters
        }),
        user: parseUsersFilters({
          usersDateFilters,
          usersNumericFilters,
          usersStringFilters,
          usersBooleanFilters
        }),
        ...regularStringFilters,
        ...regularNumericFilters
      },
      filter_keyword: {
        topic_ids: [],
        theme_ids: [],
        keyword_hashes: [],
        reason_hashes: []
      },
      source: [],
      size: pageSize,
      offset: page * pageSize,
      order_by: getOrderBy(),
      order_direction: sortDirection
    } as FeedbackRequests.SearchPayload
  },

  toFeedbackExportRequestPayload: () => {
    const {
      search,
      dateRange,
      stringFilters,
      numericFilters,
      datetimeFilters,
      productAreaThemes,
      otherThemes,
      productAreaTopics,
      otherTopics,
      ungroupedTopics,
      unclassifiedTopics,
      sortDirection,
      filtersByURL,
      accountsStringFilters,
      accountNumericFilters,
      accountsDateFilters,
      accountBooleanFilters,
      usersStringFilters,
      usersNumericFilters,
      usersDateFilters,
      usersBooleanFilters
    } = get()

    const {
      regularStringFilters,
      schemaFieldsStringFilters,
      customFieldsStringFilters,
      sentiment,
      feedbackSourceIds,
      dataType
    } = getFromStringFilters(stringFilters)

    const {
      regularNumericFilters,
      schemaFieldsNumberFilters,
      schemaFieldsIntegerFilters,
      customFieldsNumberFilters,
      customFieldsIntegerFilters
    } = getFromNumericFilters(numericFilters)

    const { schemaFieldsDatetimeFilters, customFieldsDatetimeFilters } =
      getFromDatetimeFilters(datetimeFilters)

    const topicGroups = getTopicGroups({
      unclassifiedTopics: unclassifiedTopics.selected,
      selectedProductAreaThemes: productAreaThemes.selected,
      selectedProductAreaTopics: productAreaTopics.selected,
      selectedOtherTopicsThemes: otherThemes.selected,
      selectedOtherTopics: otherTopics.selected,
      selectedUngroupedTopics: ungroupedTopics.selected.map(topic => topic.id)
    })

    return {
      filter: filtersByURL?.filter ?? {
        search_text: search,
        search_type: 'contextual',
        posted_at: dateRange
          ? {
              gte: dateRange.start.toString(),
              lte: dateRange.end.toString()
            }
          : {},
        sentiment,
        feedback_source_ids: feedbackSourceIds,
        data_type: dataType,
        schema_fields_datetime: schemaFieldsDatetimeFilters,
        schema_fields_integer: schemaFieldsIntegerFilters,
        schema_fields_number: schemaFieldsNumberFilters,
        schema_fields_string: schemaFieldsStringFilters,
        custom_fields_datetime: customFieldsDatetimeFilters,
        custom_fields_integer: customFieldsIntegerFilters,
        custom_fields_number: customFieldsNumberFilters,
        custom_fields_string: customFieldsStringFilters,
        topic_groups: topicGroups,
        ...regularStringFilters,
        ...regularNumericFilters,
        account: parseAccountFiltersToPayload({
          accountsDateFilters,
          accountNumericFilters,
          accountsStringFilters,
          accountBooleanFilters
        }),
        user: parseUsersFilters({
          usersDateFilters,
          usersNumericFilters,
          usersStringFilters,
          usersBooleanFilters
        })
      },
      filter_keyword: {
        topic_ids: [],
        theme_ids: []
      },
      size: 10000,
      source: [],
      order_by: 'posted_at',
      order_direction: sortDirection
    } as FeedbackRequests.ExportPayload
  },
  toThemeRequestsPayload: themeType => {
    const {
      search,
      dateRange,
      stringFilters,
      numericFilters,
      datetimeFilters,
      productAreaThemes,
      otherThemes,
      productAreaTopics,
      otherTopics,
      unclassifiedTopics,
      ungroupedTopics,
      volumeBy,
      filtersByURL,
      accountsStringFilters,
      accountNumericFilters,
      accountsDateFilters,
      accountBooleanFilters,
      usersStringFilters,
      usersNumericFilters,
      usersDateFilters,
      usersBooleanFilters
    } = get()

    const {
      regularStringFilters,
      schemaFieldsStringFilters,
      customFieldsStringFilters,
      sentiment,
      feedbackSourceIds,
      dataType
    } = getFromStringFilters(stringFilters)

    const {
      regularNumericFilters,
      schemaFieldsNumberFilters,
      schemaFieldsIntegerFilters,
      customFieldsNumberFilters,
      customFieldsIntegerFilters
    } = getFromNumericFilters(numericFilters)

    const { schemaFieldsDatetimeFilters, customFieldsDatetimeFilters } =
      getFromDatetimeFilters(datetimeFilters)

    const topicGroups =
      filtersByURL?.filter?.topic_groups ??
      getTopicGroups({
        unclassifiedTopics: unclassifiedTopics.selected,
        selectedProductAreaThemes: productAreaThemes.selected,
        selectedProductAreaTopics: productAreaTopics.selected,
        selectedOtherTopicsThemes: otherThemes.selected,
        selectedOtherTopics: otherTopics.selected,
        selectedUngroupedTopics: ungroupedTopics.selected.map(topic => topic.id)
      })

    const filter = filtersByURL?.filter
      ? {
          ...filtersByURL.filter,
          topic_groups: undefined
        }
      : {
          search_text: search,
          posted_at: dateRange
            ? {
                gte: dateRange.start.toString(),
                lte: dateRange.end.toString()
              }
            : {},
          sentiment,
          feedback_source_ids: feedbackSourceIds,
          data_type: dataType,
          feedback_group_enable: volumeBy === 'ticket',
          schema_fields_datetime: schemaFieldsDatetimeFilters,
          schema_fields_integer: schemaFieldsIntegerFilters,
          schema_fields_number: schemaFieldsNumberFilters,
          schema_fields_string: schemaFieldsStringFilters,
          custom_fields_datetime: customFieldsDatetimeFilters,
          custom_fields_integer: customFieldsIntegerFilters,
          custom_fields_number: customFieldsNumberFilters,
          custom_fields_string: customFieldsStringFilters,
          ...regularStringFilters,
          ...regularNumericFilters,
          account: parseAccountFiltersToPayload({
            accountsDateFilters,
            accountNumericFilters,
            accountsStringFilters,
            accountBooleanFilters
          }),
          user: parseUsersFilters({
            usersDateFilters,
            usersNumericFilters,
            usersStringFilters,
            usersBooleanFilters
          })
        }

    return {
      feedback_search_schema: {
        filter,
        filter_keyword: {
          topic_groups: topicGroups,
          theme_type: themeType ?? 'OTHER'
        },
        size: 10000,
        offset: 0,
        order_by: 'relevance',
        order_direction: 'desc'
      }
    } as ThemeRequests.ThemesPayload
  },
  toReasonsRequestInputSchema: () => {
    const {
      search,
      dateRange,
      stringFilters,
      numericFilters,
      datetimeFilters,
      productAreaThemes,
      otherThemes,
      productAreaTopics,
      ungroupedTopics,
      otherTopics,
      unclassifiedTopics,
      volumeBy,
      filtersByURL,
      accountsStringFilters,
      accountNumericFilters,
      accountsDateFilters,
      accountBooleanFilters,
      usersStringFilters,
      usersNumericFilters,
      usersDateFilters,
      usersBooleanFilters
    } = get()

    const {
      regularStringFilters,
      schemaFieldsStringFilters,
      customFieldsStringFilters,
      sentiment,
      feedbackSourceIds,
      dataType
    } = getFromStringFilters(stringFilters)

    const {
      regularNumericFilters,
      schemaFieldsNumberFilters,
      schemaFieldsIntegerFilters,
      customFieldsNumberFilters,
      customFieldsIntegerFilters
    } = getFromNumericFilters(numericFilters)

    const { schemaFieldsDatetimeFilters, customFieldsDatetimeFilters } =
      getFromDatetimeFilters(datetimeFilters)

    const topicGroups =
      filtersByURL?.filter?.topic_groups ??
      getTopicGroups({
        unclassifiedTopics: unclassifiedTopics.selected,
        selectedProductAreaThemes: productAreaThemes.selected,
        selectedProductAreaTopics: productAreaTopics.selected,
        selectedOtherTopicsThemes: otherThemes.selected,
        selectedOtherTopics: otherTopics.selected,
        selectedUngroupedTopics: ungroupedTopics.selected.map(topic => topic.id)
      })

    return {
      filter: {
        search_text: filtersByURL?.filter?.search_text ?? search,
        min_frequency: 1,
        min_words: 1,
        feedback_filter: filtersByURL?.filter ?? {
          search_text: search,
          posted_at: dateRange
            ? {
                gte: dateRange.start.toString(),
                lte: dateRange.end.toString()
              }
            : {},

          sentiment,
          feedback_source_ids: feedbackSourceIds,
          data_type: dataType,
          feedback_group_enable: volumeBy === 'ticket',
          schema_fields_datetime: schemaFieldsDatetimeFilters,
          schema_fields_integer: schemaFieldsIntegerFilters,
          schema_fields_number: schemaFieldsNumberFilters,
          schema_fields_string: schemaFieldsStringFilters,
          custom_fields_datetime: customFieldsDatetimeFilters,
          custom_fields_integer: customFieldsIntegerFilters,
          custom_fields_number: customFieldsNumberFilters,
          custom_fields_string: customFieldsStringFilters,
          topic_groups: topicGroups,
          ...regularStringFilters,
          ...regularNumericFilters,
          account: parseAccountFiltersToPayload({
            accountsDateFilters,
            accountNumericFilters,
            accountsStringFilters,
            accountBooleanFilters
          }),
          user: parseUsersFilters({
            usersDateFilters,
            usersNumericFilters,
            usersStringFilters,
            usersBooleanFilters
          })
        },
        topic_groups: topicGroups,
        topic_ids: [],
        theme_ids: [],
        topics_in_space: []
      },
      offset: 0,
      size: 100
    } as ReasonsRequests.ReasonsSearchInputSchema
  },
  toAnalyticsFeedbackFilterSchema: () => {
    const {
      search,
      dateRange,
      stringFilters,
      numericFilters,
      datetimeFilters,
      productAreaThemes,
      otherThemes,
      productAreaTopics,
      ungroupedTopics,
      otherTopics,
      unclassifiedTopics,
      volumeBy,
      filtersByURL,
      accountsStringFilters,
      accountNumericFilters,
      accountsDateFilters,
      accountBooleanFilters,
      usersStringFilters,
      usersNumericFilters,
      usersDateFilters,
      usersBooleanFilters
    } = get()

    const {
      regularStringFilters,
      schemaFieldsStringFilters,
      customFieldsStringFilters,
      sentiment,
      feedbackSourceIds,
      dataType
    } = getFromStringFilters(stringFilters)

    const {
      regularNumericFilters,
      schemaFieldsNumberFilters,
      schemaFieldsIntegerFilters,
      customFieldsNumberFilters,
      customFieldsIntegerFilters
    } = getFromNumericFilters(numericFilters)

    const { schemaFieldsDatetimeFilters, customFieldsDatetimeFilters } =
      getFromDatetimeFilters(datetimeFilters)

    const topicGroups = getTopicGroups({
      unclassifiedTopics: unclassifiedTopics.selected,
      selectedProductAreaThemes: productAreaThemes.selected,
      selectedProductAreaTopics: productAreaTopics.selected,
      selectedOtherTopicsThemes: otherThemes.selected,
      selectedOtherTopics: otherTopics.selected,
      selectedUngroupedTopics: ungroupedTopics.selected.map(topic => topic.id)
    })

    return (
      filtersByURL ??
      ({
        filter: {
          search_text: search,
          posted_at: dateRange
            ? {
                gte: dateRange.start.toString(),
                lte: dateRange.end.toString()
              }
            : {},
          sentiment,
          feedback_source_ids: feedbackSourceIds,
          data_type: dataType,
          feedback_group_enable: volumeBy === 'ticket',
          schema_fields_datetime: schemaFieldsDatetimeFilters,
          schema_fields_integer: schemaFieldsIntegerFilters,
          schema_fields_number: schemaFieldsNumberFilters,
          schema_fields_string: schemaFieldsStringFilters,
          custom_fields_datetime: customFieldsDatetimeFilters,
          custom_fields_integer: customFieldsIntegerFilters,
          custom_fields_number: customFieldsNumberFilters,
          custom_fields_string: customFieldsStringFilters,
          topic_groups: topicGroups,
          account: parseAccountFiltersToPayload({
            accountsDateFilters,
            accountNumericFilters,
            accountsStringFilters,
            accountBooleanFilters
          }),
          user: parseUsersFilters({
            usersDateFilters,
            usersNumericFilters,
            usersStringFilters,
            usersBooleanFilters
          }),
          ...regularStringFilters,
          ...regularNumericFilters
        }
      } as FeedbackFilter)
    )
  },

  toTopicStatsPayload: (page = 0, pageSize = 100) => {
    const {
      search,
      dateRange,
      stringFilters,
      numericFilters,
      datetimeFilters,
      filtersByURL,
      accountsStringFilters,
      accountNumericFilters,
      accountsDateFilters,
      accountBooleanFilters,
      usersStringFilters,
      usersNumericFilters,
      usersDateFilters,
      usersBooleanFilters
    } = get()
    const {
      regularStringFilters,
      schemaFieldsStringFilters,
      customFieldsStringFilters,
      sentiment,
      feedbackSourceIds,
      dataType
    } = getFromStringFilters(stringFilters)

    const {
      regularNumericFilters,
      schemaFieldsNumberFilters,
      schemaFieldsIntegerFilters,
      customFieldsNumberFilters,
      customFieldsIntegerFilters
    } = getFromNumericFilters(numericFilters)

    const { schemaFieldsDatetimeFilters, customFieldsDatetimeFilters } =
      getFromDatetimeFilters(datetimeFilters)

    // const themeList = [...new Set([...productAreaThemes.selected, ...otherThemes.selected])]

    // const returnAll = (() => {
    //   // return all does not work for specific API
    //   if (themeList.length) return undefined

    //   return true
    // })()

    return {
      feedback_search_schema: {
        filter: filtersByURL?.filter ?? {
          search_text: '',
          posted_at: dateRange
            ? {
                gte: dateRange.start.toString(),
                lte: dateRange.end.toString()
              }
            : {},
          archived: false,
          sentiment,
          feedback_source_ids: feedbackSourceIds,
          data_type: dataType,
          schema_fields_datetime: schemaFieldsDatetimeFilters,
          schema_fields_integer: schemaFieldsIntegerFilters,
          schema_fields_number: schemaFieldsNumberFilters,
          schema_fields_string: schemaFieldsStringFilters,
          custom_fields_datetime: customFieldsDatetimeFilters,
          custom_fields_integer: customFieldsIntegerFilters,
          custom_fields_number: customFieldsNumberFilters,
          custom_fields_string: customFieldsStringFilters,
          account: parseAccountFiltersToPayload({
            accountsDateFilters,
            accountNumericFilters,
            accountsStringFilters,
            accountBooleanFilters
          }),
          user: parseUsersFilters({
            usersDateFilters,
            usersNumericFilters,
            usersStringFilters,
            usersBooleanFilters
          }),
          ...regularStringFilters,
          ...regularNumericFilters
        },
        filter_keyword: {
          theme_ids: [],
          topic_ids: []
        },
        topic_groups: [],
        size: pageSize,
        offset: page * pageSize,
        order_by: 'posted_at',
        order_direction: 'desc'
      },
      topic_structure: [],
      search_text: search,
      return_all: false,
      theme_id_list: [],
      count_by: 'ingested_feedback_id',
      order_by: 'volume',
      order_direction: 'desc',
      exact_count: false,
      sentiment_count: true,
      sentiment_exact_count: false,
      size: pageSize,
      offset: page * pageSize
    } as TopicRequests.TopicStatsPayload
  }
})

export default payloadParsersProperties
