import TextReplacementForm from '@/components/molecules/params/TextReplacementForm'
import useLocalStorage from '@/hooks/useLocalStorage'
import useToastMessageStore from '@/store/useToastMessageStore'
import {
  TEXT_REPLACEMENT_PARAMS_KEY,
  TextReplacementFormData
} from '@/types/params/TextReplacementParams'

export const defaultTextReplacementFormData: TextReplacementFormData = {
  enable: false,
  companyName: null,
  userName: null,
  email: null,
  dynamicText: []
}

const TextReplacementPage = () => {
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const [textReplacementParams, setTextReplacementParams] = useLocalStorage(
    TEXT_REPLACEMENT_PARAMS_KEY,
    defaultTextReplacementFormData
  )

  const onSaveTextReplacementParams = (formData: TextReplacementFormData) => {
    setTextReplacementParams(formData)
    addSuccessToast({ text: 'Text replacement params saved.' })
  }

  const onResetTextReplacementParams = () => {
    setTextReplacementParams(defaultTextReplacementFormData)
    addSuccessToast({ text: 'Text replacement default params saved.' })
  }
  return (
    <TextReplacementForm
      data={textReplacementParams}
      onReset={onResetTextReplacementParams}
      onSave={onSaveTextReplacementParams}
    />
  )
}

export default TextReplacementPage
