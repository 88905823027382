import useToastMessageStore from '@/store/useToastMessageStore'
import { AreaOfInterestData, BaseInterestArea } from '@/types/area/AreaOfInterest'
import useLogging from '../useLogging'
import useRemovingItemsStore from '@/store/useRemoveItemsStore'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { stringToDate } from '@/utils/date'
import { queryClient } from '@/plugins/reactQueryClient'
import useAdvancedFiltersStore from '@/store/useFiltersStore/useAdvancedFiltersStore'
import useOpportunityStore from '@/store/useOpportunityStore'
import { OpportunityRequests } from '@/types/opportunity'
import OpportunityService from '@/services/OpportunityService'
import { OpportunityItem, OpportunityStatus } from '@/types/opportunity/Opportunity'
import { AREAS_KEY_PREFIX } from '../areaOfInterest/useAllAreasQuery'

export const mapOpportunity = (
  rawOpp: OpportunityRequests.RawOpportunityData
): OpportunityItem => ({
  name: rawOpp.name,
  id: rawOpp.opportunity_id,
  filterId: rawOpp.filter_id,
  status: rawOpp.opportunity_status_id,
  description: rawOpp.description,
  createdAt: stringToDate(rawOpp.created_at),
  new: rawOpp.new ?? false,
  createdBy: rawOpp.created_by,
  relations: rawOpp.areas,
  initiatives: rawOpp.initiatives,
  pinnedFeedback: rawOpp.pinned_feedback ?? [],
  internal_review: rawOpp.internal_review
})

interface Params {
  area?: BaseInterestArea | AreaOfInterestData
  useAppliedFilters?: boolean
  enabled?: boolean
  keepPreviousData?: boolean
  collectionId?: string
}

const useOpportunitiesQuery = ({
  area,
  enabled = true,
  keepPreviousData = false,
  collectionId
}: Params) => {
  const isFetchingContext = useAdvancedFiltersStore(state => state.isFetchingContext)

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const { logException: logMutationException } = useLogging({ context: 'opportunity-operation' })

  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)
  const setCurrentOpportunity = useOpportunityStore(state => state.setCurrentOpportunity)

  const setRemovingItems = useRemovingItemsStore(state => state.setRemovingItems)

  const queryKey = useMemo(() => ['opportunities', collectionId, area], [area, collectionId])

  const queryFn = async () => {
    const searchParams: OpportunityRequests.SearchOpportunityParams = {
      limit: 50,
      area_id: area ? [area.id] : []
    }

    if (collectionId) {
      searchParams.collection_id = collectionId
    }

    const [error, response] = await OpportunityService.searchOpportunities(searchParams)
    if (error) throw error

    return response
  }

  const { data, isLoading, ...query } = useQuery({
    queryKey,
    queryFn,
    enabled: !!area && enabled,
    keepPreviousData,
    retry: false
  })

  const opportunities: OpportunityItem[] = useMemo(
    () => data?.opportunities.map(mapOpportunity) ?? [],
    [data]
  )

  const { mutate: updateOpportunity, isLoading: isUpdatingOpportunity } = useMutation({
    mutationKey: ['update-opportunity'],
    mutationFn: async (opportunity: {
      name: string
      id: string
      status: OpportunityStatus
      description?: string
      isMoving?: boolean
    }) => {
      const payload: OpportunityRequests.UpdateOpportunityPayload = {
        name: opportunity.name,
        opportunity_status_id: opportunity.status,
        description: opportunity.description
      }

      const [error] = await OpportunityService.updateOpportunity(opportunity.id, payload)

      if (error) throw error
      return opportunity
    },
    onMutate: () => {
      const previousOpportunities =
        queryClient.getQueryData<OpportunityRequests.SearchOpportunityResponse>(queryKey)
      return { previousOpportunities }
    },
    onSuccess: async opporturnity => {
      await queryClient.cancelQueries({ queryKey })

      queryClient.setQueryData<OpportunityRequests.SearchOpportunityResponse>(queryKey, old => {
        if (!old) return

        if (opporturnity.isMoving) {
          return {
            ...old,
            opportunities: old.opportunities.filter(
              oldOpportunity => oldOpportunity.opportunity_id !== opporturnity.id
            )
          }
        }

        return {
          ...old,
          opportunities: old.opportunities.map(oldOpportunity =>
            oldOpportunity.opportunity_id === opporturnity.id
              ? ({
                  ...oldOpportunity,
                  description: opporturnity.description ?? oldOpportunity.description,
                  name: opporturnity.name,
                  opportunity_status_id: opporturnity.status
                } as OpportunityRequests.SearchOpportunityResponse['opportunities'][number])
              : oldOpportunity
          )
        }
      })

      if (currentOpportunity?.id === opporturnity.id) {
        setCurrentOpportunity({ ...currentOpportunity, ...opporturnity })
      }
    },
    onSettled: opportunity => {
      queryClient.invalidateQueries({ queryKey: ['opportunities'], exact: false })
      if (opportunity && opportunity.isMoving) {
        queryClient.invalidateQueries({
          queryKey: [AREAS_KEY_PREFIX],
          exact: false
        })

        queryClient.invalidateQueries({
          queryKey: ['opportunities'],
          exact: false
        })
      }
    },
    onError: (error, data, context) => {
      const message = 'Failed to update opportunity.'
      logMutationException(error, { message })
      addErrorToast({ text: message })

      if (data.isMoving) {
        setRemovingItems([])
      } else {
        queryClient.setQueryData(queryKey, context?.previousOpportunities)
      }
    }
  })

  const newOpportunitiesCount = useMemo(
    () => opportunities.filter(opportunity => opportunity.new).length,
    [opportunities]
  )

  return {
    ...query,
    queryKey,
    isLoading: isLoading || isFetchingContext,
    opportunities,
    newOpportunitiesCount,
    updateOpportunity,
    isUpdatingOpportunity
  }
}

export default useOpportunitiesQuery
