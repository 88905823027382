import FlexContainer from '@/components/atoms/flex-container'
import useClassification from '@/hooks/useClassification'
import { useUIStore } from '@/store'
import { styled } from '@/theme'
import { useEffect } from 'react'
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import CategorySelect from './CategorySelect'
import ClassificationOptions from './ClassificationOptions'
import ThemeList from './theme/ThemeList'
import TopicsModal from './topic/TopicsModal'
import ClassificationFooter from './ClassificationFooter'

const ClassificationAreaContainer = styled('aside', {
  display: 'flex',
  flexDirection: 'column',
  opacity: 1,
  transition: '.2s',

  gap: '$xxs',
  ml: '$sm',
  pr: '$nano',
  mb: '$sm',

  flexGrow: 2,
  overflow: 'hidden',
  minHeight: 280,

  '& .select-container-opts': {
    gap: '$nano',
    minHeight: '$md'
  },

  variants: {
    collapsed: {
      true: {
        opacity: 0
      }
    }
  }
})

const ClassificationScrollArea = styled(ScrollAreaRoot, {
  minHeight: 1
})

const ClassificationArea = () => {
  const { classificationCollapsed, isFiltersOpen } = useUIStore()
  const { loadAllThemes } = useClassification()

  // biome-ignore lint/correctness/useExhaustiveDependencies: it should happens once
  useEffect(() => {
    loadAllThemes()
  }, [])

  return (
    <ClassificationAreaContainer
      collapsed={classificationCollapsed}
      css={{ pointerEvents: isFiltersOpen ? 'none' : 'unset' }}
    >
      <FlexContainer alignItems="center" className="select-container-opts">
        <CategorySelect />
        <ClassificationOptions />
      </FlexContainer>
      <ClassificationScrollArea>
        <ScrollAreaViewport>
          <ThemeList />
        </ScrollAreaViewport>
        <ScrollAreaScrollbar orientation="vertical">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </ClassificationScrollArea>
      <ClassificationFooter mode="theme" />
      <TopicsModal />
    </ClassificationAreaContainer>
  )
}

export default ClassificationArea
