import { styled, typefaceVariants } from '@/theme'

export const IntegrationFieldContainer = styled('div', {
  ...typefaceVariants.subtitleRegularXXXS,
  backgroundColor: '$neutralHighMedium',
  bAll: '$neutralHighPure',
  borderRadius: 8,
  boxSizing: 'border-box',
  color: '$neutralLowLight',
  overflow: 'hidden',
  padding: '$xxxs $xxs',
  textOverflow: 'ellipsis',
  width: 324
})
