import Dialog from '@/components/atoms/dialog/Dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { FilterItem } from '@/types/filters/Filters'
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '../scroll-area'
import {
  Accordion,
  AccordionCaret,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '../accordion'
import RadioGroup from '@/components/atoms/radio-group'
import useChatStore from '@/store/useChatStore'
import useSegment from '@/hooks/useSegment'
import { useMemo, useState } from 'react'
import { ChatModel } from '@/types/feedbacks/FeedbackRequests'
import { modelName } from '@/utils/chat'
import Button from '@/components/atoms/button'
import { styled } from '@/theme'
import useFiltersQuery from '@/hooks/filters/useFiltersQuery'
import useLetterCase from '@/hooks/useLetterCase'
import Checkbox from '@/components/atoms/checkbox'
import useAccountsFilters from '@/hooks/filters/useAccountsFilters'
import { useTranslation } from 'react-i18next'

const SettingsScrollArea = styled(ScrollAreaRoot, {
  overflow: 'hidden'
})

const SettingsAccordion = styled(Accordion, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$xs'
})

const SettingsAccordionContent = styled(AccordionContent, {
  pl: '$xs'
  // pt: '$xs'
})

const EXCLUDED_FIELDS = [
  'keyword_quality',
  'overall_quality',
  'feedback_keyword_classes',
  'thread_id'
]

const FIELDS_TO_INCLUDE = ['title', 'posted_at', 'feedback_type']

interface Props {
  open: boolean
  setOpen: (value: boolean) => void
  selectedFields: FilterItem[]
  setSelectedFields: (value: FilterItem[]) => void
  noBackdrop?: boolean
}

const ChatSettings = ({
  open,
  setOpen,
  selectedFields,
  setSelectedFields,
  noBackdrop = false
}: Props) => {
  const { track } = useSegment()

  const { allFilters: filters } = useFiltersQuery()
  const { fields: accountFields } = useAccountsFilters()
  const [tempFields, setTempFields] = useState(selectedFields)
  const { snakeToTitle } = useLetterCase()
  const { t } = useTranslation()

  const onOpenChange = (value: boolean) => {
    if (!value) {
      setTempFields(selectedFields)
    }
    setOpen(value)
  }

  const { model } = useChatStore(state => ({ model: state.model }))
  const [tempModel, setTempModel] = useState<string>(model)

  const setModel = useChatStore(state => state.setModel)

  const onSave = () => {
    if (tempModel !== model) {
      setModel(tempModel as ChatModel)
      track('explore_user_chat_refinement_options', {
        options: [tempModel, 'random']
      })
    }

    if (
      JSON.stringify(tempFields.sort((a, b) => a.name.localeCompare(b.name))) !==
      JSON.stringify(selectedFields.sort((a, b) => a.name.localeCompare(b.name)))
    ) {
      setSelectedFields(tempFields)
      track('explore_user_chat_refinement_options_fields', {
        fields: tempFields.map(field => field.name)
      })
    }
    setOpen(false)
  }

  const coreFieldFilters = useMemo(
    () =>
      filters
        .filter(
          filter => !filter.type.includes('custom_fields') && !EXCLUDED_FIELDS.includes(filter.key)
        )
        .sort((a, b) => a.displayName.localeCompare(b.displayName)),
    [filters]
  )
  const customFieldFilters = useMemo(
    () =>
      filters
        .filter(
          filter => filter.type.includes('custom_fields') && !EXCLUDED_FIELDS.includes(filter.key)
        )
        .sort((a, b) => a.displayName.localeCompare(b.displayName)),
    [filters]
  )
  const sortedAccountFields = useMemo(
    () => accountFields.sort((a, b) => a.displayName.localeCompare(b.displayName)),
    [accountFields]
  )

  const findByKey = (key: string) => {
    if (FIELDS_TO_INCLUDE.includes(key)) {
      return { displayName: snakeToTitle(key), key, name: key, type: 'string' } as FilterItem
    }

    return filters.find(field => field.key === key)
  }

  const findAccountFieldByKey = (key: string) => {
    return accountFields.find(field => field.key === key)
  }

  const isChecked = (key: string) => tempFields.find(field => field.key === key) !== undefined

  const onCheckChange = (key: string) => (checked: boolean) => {
    const field = findByKey(key)
    setTempFields(
      checked && field ? [...tempFields, field] : tempFields.filter(temp => temp.key !== key)
    )
  }

  const onCheckAccountFieldChange = (key: string) => (checked: boolean) => {
    const field = findAccountFieldByKey(key)

    setTempFields(
      checked && field ? [...tempFields, field] : tempFields.filter(temp => temp.key !== key)
    )
  }

  const clearCoreField = () => {
    setTempFields(
      tempFields.filter(
        field =>
          !coreFieldFilters.find(filter => filter.key === field.key) &&
          !FIELDS_TO_INCLUDE.includes(field.key)
      )
    )
  }

  const clearCustomFields = () => {
    setTempFields(
      tempFields.filter(field => !customFieldFilters.find(filter => filter.key === field.key))
    )
  }

  const clearAccountFields = () => {
    setTempFields(
      tempFields.filter(field => !accountFields.find(filter => filter.key === field.key))
    )
  }

  return (
    <Dialog
      align="right"
      closeIcon
      enableInteractionsOutside={noBackdrop}
      modal={false}
      noBackdrop={noBackdrop}
      onOpenChange={onOpenChange}
      open={open}
      useFullHeight
    >
      <FlexContainer css={{ padding: '$xs $sm' }} direction="column" fullHeight gap="xs">
        <Text as="h2" typeface="titleBoldXXS">
          {t('settings')}
        </Text>
        <Divider line="solid" />
        <SettingsScrollArea>
          <ScrollAreaViewport>
            <SettingsAccordion type="multiple">
              <AccordionItem value="context">
                <AccordionTrigger>
                  <AccordionCaret size={16} />
                  <Text as="h3" fontSize="xxs" fontWeight="semibold">
                    {t('addSpecificContext')}
                  </Text>
                </AccordionTrigger>
                <SettingsAccordionContent>
                  <FlexContainer direction="column" gap="xs">
                    <Text as="p" color="neutralLowMedium" fontSize="xxxs">
                      {t('selectFieldsToSegmentYourAnalysisIntoASpecificContext')}
                    </Text>
                    <FlexContainer direction="column">
                      <FlexContainer justifyContent="spaceBetween">
                        <Text as="p" fontSize="xxxs" fontWeight="semibold">
                          {t('coreFields')}
                        </Text>
                        <Button onClick={clearCoreField} size={'small'} variant="flat">
                          {t('clearAll')}
                        </Button>
                      </FlexContainer>
                      <FlexContainer css={{ ml: '$nano' }} direction="column" gap="micro">
                        {FIELDS_TO_INCLUDE.map(field => (
                          <Checkbox
                            checked={!!isChecked(field)}
                            key={field}
                            onCheckedChange={onCheckChange(field)}
                            text={snakeToTitle(field)}
                            value={field}
                          />
                        ))}
                        {coreFieldFilters.map(filter => (
                          <Checkbox
                            checked={!!isChecked(filter.key)}
                            key={filter.key}
                            onCheckedChange={onCheckChange(filter.key)}
                            text={filter.displayName}
                            value={filter.key}
                          />
                        ))}
                      </FlexContainer>
                    </FlexContainer>

                    {sortedAccountFields.length > 0 && (
                      <FlexContainer direction="column">
                        <FlexContainer justifyContent="spaceBetween">
                          <Text as="p" fontSize="xxxs" fontWeight="semibold">
                            {t('customerRecordsFields')}
                          </Text>
                          <Button onClick={clearAccountFields} size={'small'} variant="flat">
                            {t('clearAll')}
                          </Button>
                        </FlexContainer>
                        <FlexContainer css={{ ml: '$nano' }} direction="column" gap="micro">
                          {sortedAccountFields.map(filter => (
                            <Checkbox
                              checked={!!isChecked(filter.key)}
                              key={filter.key}
                              onCheckedChange={onCheckAccountFieldChange(filter.key)}
                              text={filter.displayName}
                              value={filter.key}
                            />
                          ))}
                        </FlexContainer>
                      </FlexContainer>
                    )}

                    <FlexContainer direction="column">
                      <FlexContainer justifyContent="spaceBetween">
                        <Text as="p" fontSize="xxxs" fontWeight="semibold">
                          {t('customFields')}
                        </Text>
                        <Button onClick={clearCustomFields} size={'small'} variant="flat">
                          {t('clearAll')}
                        </Button>
                      </FlexContainer>
                      <FlexContainer css={{ ml: '$nano' }} direction="column" gap="micro">
                        {customFieldFilters.map(filter => (
                          <Checkbox
                            checked={!!isChecked(filter.key)}
                            key={filter.key}
                            onCheckedChange={onCheckChange(filter.key)}
                            text={filter.displayName}
                            value={filter.key}
                          />
                        ))}
                      </FlexContainer>
                    </FlexContainer>
                  </FlexContainer>
                </SettingsAccordionContent>
              </AccordionItem>
              <Divider line="solid" />
              <AccordionItem value="model">
                <AccordionTrigger>
                  <AccordionCaret size={16} />

                  <Text as="h3" fontSize="xxs" fontWeight="semibold">
                    {t('speedModel')}
                  </Text>
                </AccordionTrigger>
                <SettingsAccordionContent>
                  <RadioGroup
                    gap="xxxs"
                    onValueChange={setTempModel}
                    options={[
                      { text: modelName.openai, value: 'openai' },
                      { text: modelName.anthropic, value: 'anthropic' }
                    ]}
                    textProps={{ fontSize: 'xxxs', color: 'neutralLowMedium' }}
                    value={tempModel}
                  />
                </SettingsAccordionContent>
              </AccordionItem>
              <Divider line="solid" />
            </SettingsAccordion>
          </ScrollAreaViewport>
          <ScrollAreaScrollbar orientation="vertical">
            <ScrollAreaThumb />
          </ScrollAreaScrollbar>
        </SettingsScrollArea>
        <FlexContainer css={{ mt: 'auto' }} onClick={onSave}>
          <Button>{t('saveChanges')}</Button>
        </FlexContainer>
      </FlexContainer>
    </Dialog>
  )
}

export default ChatSettings
