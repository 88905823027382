import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'

import Drawing from '@/assets/drawings/bird_empty_boxes.png'
import Text from '@/components/atoms/text'
import Button from '@/components/atoms/button'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const DrawingImage = styled('img', {
  width: 433,
  height: 280
})

interface Props {
  onFreeExplorationClick: () => void
}

const HomeEmptyState = ({ onFreeExplorationClick }: Props) => {
  const { t } = useTranslation()

  return (
    <FlexContainer alignItems="center" direction="column" gap="xs">
      <DrawingImage alt="A black and white bird looking at empty boxes." src={Drawing} />
      <Text align="center" as="p" fontSize="xxs">
        {t('noAreasOfInterestCreatedYet')}
      </Text>
      <Link to="/exploration">
        <Button onClick={onFreeExplorationClick}>{t('createNewArea')}</Button>
      </Link>
    </FlexContainer>
  )
}

export default HomeEmptyState
