import FlexContainer from '@/components/atoms/flex-container'
import {
  AnalyticsFlexContainer,
  AnalyticsScrollArea
} from '@/components/molecules/analytics/Analytics.styles'
import FeedbackSearchChat from '@/components/molecules/assistant-new-search/FeedbackSearchChat'
import FeedSearchContent from '@/components/molecules/layout/FeedSearchContent'
import NoPermission from '@/components/molecules/no-permission/NoPermission'
import OpportunityHeader from '@/components/molecules/opportunity-details/OpportunityHeader'
import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import useUser from '@/hooks/useUser'
import { useUIStore } from '@/store'
import { useEffect, useRef } from 'react'
import { Outlet } from 'react-router-dom'
import { shallow } from 'zustand/shallow'
import { useTranslation } from 'react-i18next'
import useOpportunityStore from '@/store/useOpportunityStore'
import useBasicAreaOfInterestQuery from '@/hooks/areaOfInterest/useBasicAreaOfInterestQuery'
import useFilterEntityStore from '@/store/useFiltersStore/useFilterEntityStore'
import useConfigureAllowedMetrics from '@/hooks/metrics/useConfigureAllowedMetrics'

const ExplorationPage = () => {
  const { userPermissions, currentUser } = useUser()

  const { isAssistantOpen, setAssistantOpen } = useUIStore(state => ({
    isAssistantOpen: state.isAssistantOpen,
    setAssistantOpen: state.setAssistantOpen
  }))

  const onOpenChange = (isOpen: boolean) => {
    setAssistantOpen(isOpen)
  }

  const setEditMode = useFilterEntityStore(state => state.setEditMode)

  useConfigureAllowedMetrics()

  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      setEditMode(false)
    }
  })

  useBasicAreaOfInterestQuery({ enabled: true })

  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity, shallow)

  const scrollRef = useRef<HTMLDivElement | null>(null)

  const resetScroll = () => {
    scrollRef.current?.scrollTo(0, 0)
  }

  if (userPermissions.areas.length === 0 && currentUser) {
    return <NoPermission />
  }

  return (
    <>
      <FeedSearchContent searchPlaceholder={t('searchOnBirdie')}>
        {Boolean(currentOpportunity) && <OpportunityHeader resetScroll={resetScroll} />}
        <AnalyticsScrollArea opportunityPlan>
          <ScrollAreaViewport
            asChild
            css={{ display: 'flex !important', justifyContent: 'center' }}
            id="scroll-analytics"
            ref={scrollRef}
          >
            <AnalyticsFlexContainer direction="column" opportunityPlan>
              <Outlet />
              <FlexContainer css={{ minHeight: '$micro' }} />
            </AnalyticsFlexContainer>
          </ScrollAreaViewport>
          <ScrollAreaScrollbar orientation="vertical">
            <ScrollAreaThumb />
          </ScrollAreaScrollbar>
        </AnalyticsScrollArea>
      </FeedSearchContent>
      <FeedbackSearchChat onOpenChange={onOpenChange} open={isAssistantOpen} opportunityPlan />
    </>
  )
}

export default ExplorationPage
