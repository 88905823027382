import { OpportunityPlanFormData } from '@/types/params/OpportunityPlanParams'
import { GenericFeatureToggle } from './types'
import GenericFeatureToggleForm, { ParamsFormProps } from './GenericFeatureToggleForm'

const featureToggles: GenericFeatureToggle<keyof OpportunityPlanFormData>[] = [
  {
    label: 'Enable feedback batch selection',
    name: 'enableBatchSelection'
  },
  {
    label: 'Enable segments?',
    name: 'enableSegments'
  },
  {
    label: 'Enable move feedback dialog?',
    name: 'enableMoveFeedback'
  }
]

const OpportunityPlanForm = ({
  data,
  onSave,
  onReset
}: ParamsFormProps<OpportunityPlanFormData>) => (
  <GenericFeatureToggleForm
    data={data}
    featureToggleList={featureToggles}
    onReset={onReset}
    onSave={onSave}
    title="Opportunity plan"
  />
)

export default OpportunityPlanForm
