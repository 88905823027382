import { DeleteDialog } from '@/components/atoms/dialog'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  onConfirmDelete: () => void
}

const DeleteCollectionAlert = ({ open, onOpenChange, onConfirmDelete }: Props) => {
  const { t } = useTranslation()

  return (
    <DeleteDialog
      cancelText={t('cancel')}
      confirmText={t('delete')}
      description={t('youWillOnlyDeleteTheCollectionFolderAreasAndOpportunitiesWillNotBeAffected')}
      isDeleting={false}
      onConfirmDelete={onConfirmDelete}
      onOpenChange={onOpenChange}
      open={open}
      title={t('doYouWantToDeleteThisCollectionQuestion')}
    />
  )
}

export default DeleteCollectionAlert
