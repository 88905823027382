import { create } from 'zustand'
import createFeedbackStore, { FeedbacksStoreState } from './createFeedbackStore'
import { persist } from 'zustand/middleware'
import { createWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'

interface PersistedFeedbackState {
  feedbackViewMode: 'expanded' | 'compact'
  toggleFeedbackViewMode: () => void
}

export const usePersistedFeedbackStore = createWithEqualityFn<PersistedFeedbackState>()(
  persist(
    set => ({
      feedbackViewMode: 'expanded',
      toggleFeedbackViewMode: () => {
        set(state => ({
          feedbackViewMode: state.feedbackViewMode === 'compact' ? 'expanded' : 'compact'
        }))
      }
    }),
    { name: 'feedback-store' }
  ),
  shallow
)

const useExploreFeedbackStore = create<FeedbacksStoreState>()(createFeedbackStore)
export const useArchivedFeedbackStore = create<FeedbacksStoreState>()(createFeedbackStore)
export const useReasonsFeedbackStore = create<FeedbacksStoreState>()(createFeedbackStore)
export const useKeywordsDetailsFeedbackStore = create<FeedbacksStoreState>()(createFeedbackStore)
export const useThreadFeedbackStore = create<FeedbacksStoreState>()(createFeedbackStore)
export const useChatFeedbackStore = create<FeedbacksStoreState>()(createFeedbackStore)
export const useTopicRelatedFeedbackStore = create<FeedbacksStoreState>()(createFeedbackStore)

export const mapFeedbackStore = {
  explore: useExploreFeedbackStore,
  archived: useArchivedFeedbackStore,
  reasons: useReasonsFeedbackStore,
  keywordsDetails: useKeywordsDetailsFeedbackStore,
  thread: useThreadFeedbackStore,
  chat: useChatFeedbackStore,
  topicRelated: useTopicRelatedFeedbackStore
}

export type FeedbackStoreNames = keyof typeof mapFeedbackStore

export const FEEDBACK_STORE_LIST: FeedbackStoreNames[] = [
  'archived',
  'explore',
  'keywordsDetails',
  'reasons',
  'thread',
  'chat',
  'topicRelated'
]

export default useExploreFeedbackStore
