import FlexContainer from '@/components/atoms/flex-container'

import { useEffect, useRef, useState } from 'react'
import { AccountData } from '@/types/manage-customers'
import {
  HeaderCell,
  HeaderRow,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from './AccountList.styles'
import NoResults from '../NoResults'
import { formatDateTime } from '@/utils/date'
import { CustomerRecordsScrollArea, Fade } from '../ManageCustomers.styles'
import OptionsMenu from '@/components/atoms/options-menu'
import { ListDashes, TrashSimple } from '@phosphor-icons/react'
import useAccountsStore from '@/store/useAccountsStore'
import AccountDetails from './AccountDetails'
import { ScrollAreaScrollbar, ScrollAreaThumb, ScrollAreaViewport } from '../../scroll-area'
import InfiniteScroll from 'react-infinite-scroll-component'
import ScrollLoader from '../../feed/ScrollLoader'
import Loader from '@/components/atoms/loader'

function checkHasScroll(element: HTMLElement | null) {
  if (!element) return false
  return element.scrollHeight > element.clientHeight
}

interface Props {
  accountList: AccountData[]
  isLoadingSearch: boolean
  isSearching: boolean
  hasMore?: boolean
  isFetchingPage?: boolean
  loadMore: () => void
  onClickDelete: (user: AccountData) => void
}

const AccountList = ({
  accountList,
  isLoadingSearch,
  hasMore,
  isFetchingPage,
  isSearching,
  onClickDelete,
  loadMore
}: Props) => {
  const [isScrollAtBottom, setIsScrollAtBottom] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const detailedAccount = useAccountsStore(state => state.detailedAccount)
  const setDetailedAccount = useAccountsStore(state => state.setDetailedAccount)

  useEffect(() => {
    const onScroll = () => {
      if (containerRef.current && accountList.length > 0) {
        if (!checkHasScroll(containerRef.current)) {
          setIsScrollAtBottom(true)
          return
        }

        const { scrollTop, scrollHeight, clientHeight } = containerRef.current
        const isNearBottom = scrollTop + clientHeight >= scrollHeight
        setIsScrollAtBottom(isNearBottom)
      }
    }

    if (containerRef.current && accountList.length > 0) {
      containerRef.current.addEventListener('scroll', onScroll)

      return () => {
        containerRef.current && containerRef.current.removeEventListener('scroll', onScroll)
      }
    }
  }, [accountList.length])

  useEffect(() => {
    if (accountList.length && !checkHasScroll(containerRef.current)) {
      setIsScrollAtBottom(true)
    } else {
      setIsScrollAtBottom(isFetchingPage || false)
    }
  }, [accountList, isFetchingPage])

  if (isLoadingSearch)
    return (
      <FlexContainer alignItems="center" justifyContent="center">
        <Loader />
      </FlexContainer>
    )

  return (
    <>
      {!accountList.length && isSearching ? (
        <NoResults />
      ) : (
        <TableContainer>
          <HeaderRow>
            <HeaderCell>Account ID</HeaderCell>
            <HeaderCell>Account name</HeaderCell>
            <HeaderCell>Import date</HeaderCell>
            <HeaderCell />
          </HeaderRow>

          <CustomerRecordsScrollArea>
            <ScrollAreaViewport id="accounts-viewport" ref={containerRef}>
              <TableBody>
                <InfiniteScroll
                  dataLength={accountList.length}
                  endMessage={<div />}
                  hasMore={hasMore || false}
                  loader={isFetchingPage ? <ScrollLoader /> : ''}
                  next={loadMore}
                  scrollThreshold="250px"
                  scrollableTarget="accounts-viewport"
                >
                  {accountList.map(account => {
                    const formatedDate = formatDateTime(account.importedAt.replace('Z', ''))

                    return (
                      <TableRow key={account.id}>
                        <TableCell title={account.ingestedId}>{account.ingestedId}</TableCell>
                        <TableCell title={account.name}>{account.name}</TableCell>
                        <TableCell title={formatedDate}>{formatedDate}</TableCell>
                        <TableCell className="options">
                          <OptionsMenu
                            buttonStyle={{ color: '$neutralLowPure' }}
                            options={[
                              {
                                text: 'Details',
                                icon: <ListDashes size={16} />,
                                onClick: () => setDetailedAccount(account)
                              },
                              {
                                text: 'Delete',
                                icon: <TrashSimple size={16} />,
                                onClick: () => onClickDelete(account)
                              }
                            ]}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </InfiniteScroll>
              </TableBody>
              <Fade hide={isScrollAtBottom} />
            </ScrollAreaViewport>
            <ScrollAreaScrollbar orientation="vertical">
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
          </CustomerRecordsScrollArea>
        </TableContainer>
      )}

      {detailedAccount && <AccountDetails />}
    </>
  )
}

export default AccountList
