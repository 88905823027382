import { CheckedState } from '@radix-ui/react-checkbox'
import { useState } from 'react'

const useStringSelection = (initialState: string[] = []) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(initialState)
  const selectOption = (checked: CheckedState, option: string) => {
    if (checked) {
      setSelectedOptions([...selectedOptions, option])
    } else {
      setSelectedOptions(selectedOptions.filter(o => o !== option))
    }
  }
  const isChecked = (option: string) => selectedOptions.includes(option)

  return {
    selectedOptions,
    selectOption,
    setSelectedOptions,
    isChecked
  }
}

export default useStringSelection
