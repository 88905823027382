import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import { AllMetricsKey, MetricKey } from '@/types/metrics'
import { buildMetricKey, DEFAULT_ALLOWED_METRICS_PER_SOURCE } from '@/utils/metrics'
import { useCallback, useMemo } from 'react'
import { shallow } from 'zustand/shallow'
import { useUserStore } from '@/store'

const useAllowedMetrics = () => {
  const organization = useUserStore(state => state.organization, shallow)

  const allowedMetricsBySource = useHiddenMetricsStore(
    state => state.allowedMetricsBySource,
    shallow
  )

  const getAllAllowedMetrics = useHiddenMetricsStore(state => state.getAllAllowedMetrics)
  const setAllowedMetrics = useHiddenMetricsStore(state => state.setAllowedMetrics)

  const orgSourceAliasMapping = useMemo(() => {
    return organization?.config?.sourceAlias
  }, [organization?.config?.sourceAlias])

  const allAllowedMetricsList = useMemo(
    () => getAllAllowedMetrics(allowedMetricsBySource),
    [getAllAllowedMetrics, allowedMetricsBySource]
  )

  const isMetricAllowed = useCallback(
    (source: MetricKey, metric: AllMetricsKey) => {
      const allowedMetrics = allowedMetricsBySource[source]

      if (!allowedMetrics) return false

      return allowedMetrics.includes(metric)
    },
    [allowedMetricsBySource]
  )

  const isSourceAllowed = useCallback(
    (source: MetricKey) => {
      const allowedMetrics = allowedMetricsBySource[source]

      if (!allowedMetrics) return false

      return allowedMetrics.length > 0
    },
    [allowedMetricsBySource]
  )

  const isDefaultSource = useCallback((source: MetricKey) => {
    const defaultAllowedMetrics = DEFAULT_ALLOWED_METRICS_PER_SOURCE[source]

    return !!defaultAllowedMetrics && defaultAllowedMetrics.length > 0
  }, [])

  const getDefaultMetricsBySource = useCallback((source: MetricKey) => {
    const defaultAllowedMetrics = DEFAULT_ALLOWED_METRICS_PER_SOURCE[source]

    if (!defaultAllowedMetrics) return undefined

    return defaultAllowedMetrics
  }, [])

  const configureAllowedMetricsForUnknownSources = useCallback(
    (sources: MetricKey[]) => {
      const allowedSourcesToAdd: Record<string, AllMetricsKey[]> = {}

      sources.forEach(source => {
        if (isDefaultSource(source)) return

        // already configured
        if (allowedMetricsBySource[source]) return

        // exists a map kind:source for this source
        if (orgSourceAliasMapping && orgSourceAliasMapping[source]) {
          const sourceKind = orgSourceAliasMapping[source].kind as MetricKey

          const defaultSourceKindAllowedKeys = getDefaultMetricsBySource(sourceKind)
          if (!defaultSourceKindAllowedKeys) {
            console.warn(`Invalid source alias "${source}" and kind mapping "${sourceKind}"`)
            return
          }

          const newSourceKeys: AllMetricsKey[] = defaultSourceKindAllowedKeys.map(key => {
            // should follow the same rule for creating source alias metric keys in useAllMetricItems().sourceAliasMetrics
            const metricKey = buildMetricKey({ source, key, kind: sourceKind })

            return metricKey
          })

          allowedSourcesToAdd[source] = newSourceKeys
          return
        }

        // also should follow the same rule for creating source alias metric keys in useAllMetricItems().sourceAliasMetrics
        allowedSourcesToAdd[source] = [`${source}_count`]
      })

      setAllowedMetrics({ ...allowedMetricsBySource, ...allowedSourcesToAdd })
    },
    [
      allowedMetricsBySource,
      orgSourceAliasMapping,
      isDefaultSource,
      getDefaultMetricsBySource,
      setAllowedMetrics
    ]
  )

  return {
    allAllowedMetricsList,
    allowedMetricsBySource,
    isSourceAllowed,
    isMetricAllowed,
    isDefaultSource,
    getDefaultMetricsBySource,
    configureAllowedMetricsForUnknownSources
  }
}

export default useAllowedMetrics
