import Button from '@/components/atoms/button'
import Dialog from '@/components/atoms/dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import Input, { TextArea } from '@/components/atoms/input'
import Text from '@/components/atoms/text'
import { SavedFilterType } from '@/types/filters/Filters'
import { useTranslation } from 'react-i18next'
import ToggleGroup, { ToggleGroupOption } from '@/components/atoms/toggle-group'
import { FolderOpen, Users } from '@phosphor-icons/react'
import { useState } from 'react'
import useAreaOfInterest from '@/hooks/areaOfInterest/useAreaOfInterest'
import useSegmentation from '@/hooks/segmentation/useSegmentation'
import i18n from '@/plugins/i18n/i18n'

interface SaveFiltersDialogProps {
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

const toggleGroupOptions: ToggleGroupOption[] = [
  {
    text: i18n.t('area'),
    icon: <FolderOpen />,
    value: 'area_interest'
  },
  {
    text: i18n.t('segment'),
    icon: <Users />,
    value: 'segment'
  }
]

const SaveFilterEntityDialog = ({ open, onOpenChange }: SaveFiltersDialogProps) => {
  const { t } = useTranslation()

  const [filterType, setFilterType] = useState<SavedFilterType>('area_interest')

  const { createAreaOfInterest } = useAreaOfInterest()
  const { createSegmentation } = useSegmentation()

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  const saveFiltersAction = () => {
    if (filterType === 'area_interest') {
      createAreaOfInterest({
        name,
        description
      })
    } else if (filterType === 'segment') {
      createSegmentation({
        name,
        description
      })
    }

    onOpenChange?.(false)
  }

  const onKeyUp: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      saveFiltersAction()
    }
  }

  const onClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    saveFiltersAction()
  }

  return (
    <Dialog
      title={t('createNewArea')}
      hiddenTitle
      align="center"
      closeIcon
      onOpenChange={onOpenChange}
      open={open}
    >
      <FlexContainer direction="column" gap="xs">
        <Text as="h1" typeface="titleBoldXXS">
          {t('createNew')}
        </Text>

        <FlexContainer direction="column" gap="micro">
          <Text as="label" typeface="subtitleRegularXXXS">
            {t('createChooseEntityLabel')}
          </Text>
          <ToggleGroup
            value={filterType}
            onValueChange={value => setFilterType(value as SavedFilterType)}
            options={toggleGroupOptions}
          />
        </FlexContainer>

        <FlexContainer direction="column" gap="micro">
          <Text as="label" typeface="subtitleRegularXXXS">
            {t('name')}
          </Text>
          <Input onChange={e => setName(e.currentTarget.value)} onKeyUp={onKeyUp} value={name} />
        </FlexContainer>

        <FlexContainer direction="column" gap="micro">
          <Text as="label" typeface="subtitleRegularXXXS">
            {t('description')}
          </Text>
          <TextArea
            css={{ whiteSpace: 'pre-wrap', lineHeight: '$xl' }}
            onChange={e => setDescription(e.currentTarget.value)}
            placeholder={t('entityDescriptionPlaceholder')}
            rows={6}
            value={description}
          />
        </FlexContainer>

        <Divider line="solid" />
        <Button fullWidth onClick={onClick} size="small">
          {t('create')}
        </Button>
      </FlexContainer>
    </Dialog>
  )
}

export default SaveFilterEntityDialog
