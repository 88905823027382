import { useFiltersStore } from '@/store'
import useSelectedFilters from '../filters/useSelectedFilters'
import { cloneObject } from '@/utils/object'
import { FeedbackFilter } from '@/types/filters/FilterRequests'
import AnalyticsService from '@/services/AnalyticsService'
import { useQuery } from '@tanstack/react-query'
import useComparisonText from '../useComparisonText'
import { formatPercentageWithSignal, roundAndSuffix } from '@/utils/numbers'

interface Params {
  topicId: string
  asExploreFeedbackItem?: boolean
}

const useTopicFeedbackIndicators = ({ topicId, asExploreFeedbackItem = false }: Params) => {
  const toFeedbackFilterSchema = useFiltersStore(state => state.toAnalyticsFeedbackFilterSchema)
  const { allSelectedFilters } = useSelectedFilters()

  const queryFn = async () => {
    const feedbackFilter = toFeedbackFilterSchema()

    const newFilters: FeedbackFilter = cloneObject(feedbackFilter)
    if (newFilters.filter && !asExploreFeedbackItem) {
      newFilters.filter.topic_groups = [[topicId]]
    }

    const [error, data] = await AnalyticsService.indicators({
      feedback_search: newFilters,
      labeling_exact_count: true,
      group_by_exact_count: false,
      posted_at_exact_count: true,
      topic_search_text: feedbackFilter.filter?.search_text ?? '',
      metric_feedback: true,
      metric_customer: false,
      metric_intentions: []
    })

    if (error) {
      throw error
    }

    return data
  }

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      'analytics',
      'topic',
      'indicators',
      topicId,
      'feedback',
      allSelectedFilters,
      asExploreFeedbackItem
    ],
    queryFn
  })

  const feedbackMetrics = data?.feedbackMetric

  const { text } = useComparisonText()

  const count = roundAndSuffix(feedbackMetrics?.currentFeedbackCount ?? 0)
  const percentage = formatPercentageWithSignal(feedbackMetrics?.feedbackPercentage ?? 0)

  return {
    text,
    count,
    percentage,
    isLoading,
    isError
  }
}

export default useTopicFeedbackIndicators
