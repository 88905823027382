import useLogging from '@/hooks/useLogging'
import OrganizationService from '@/services/OrganizationService'
import { ActiveUsersByDateConfigItem, Organization } from '@/types/organization/Organization'
import { makeUniqueArray } from '@/utils/array'
import { parseDate } from '@internationalized/date'
import { MutableRefObject, useCallback, useMemo, useState } from 'react'

const defaultItem: ActiveUsersByDateConfigItem = { key: null, date: null, value: '' }

export const getActiveUsersByDateConfig = (organization?: Organization | null) => {
  if (!organization) return []
  if (!organization.config?.activeUsersByDate) return []

  const entries = Object.entries(organization.config?.activeUsersByDate)

  const list: ActiveUsersByDateConfigItem[] = []

  entries.forEach(([key, value]) => {
    list.push({ key, date: parseDate(key), value })
  })

  return list
}

interface Params {
  orgActiveUsersRef: MutableRefObject<ActiveUsersByDateConfigItem[]>
  organization: Organization | null
}

const useActiveUsersByDate = ({ organization, orgActiveUsersRef }: Params) => {
  const { logException } = useLogging({ context: 'OrganizationSettings' })

  const [allActiveUsersList, setAllActiveUsersList] = useState(() =>
    getActiveUsersByDateConfig(organization)
  )
  const [activeUsersToAdd, setActiveUsersToAdd] = useState<ActiveUsersByDateConfigItem[]>([
    defaultItem
  ])
  const [hasUsersToAddHasChanges, setHasUsersToChanges] = useState(false)

  const onChangeAllActiveUsers = useCallback(
    (newActiveUsersByDate: ActiveUsersByDateConfigItem[]) => {
      setAllActiveUsersList(newActiveUsersByDate)
    },
    []
  )

  const onChangeActiveUsersToAdd = useCallback(
    (newActiveUsersByDate: ActiveUsersByDateConfigItem[]) => {
      setHasUsersToChanges(
        JSON.stringify(activeUsersToAdd) !== JSON.stringify(newActiveUsersByDate)
      )
      setActiveUsersToAdd(newActiveUsersByDate)
    },
    [activeUsersToAdd]
  )

  const hasChanges = useMemo(() => {
    return JSON.stringify(allActiveUsersList) !== JSON.stringify(orgActiveUsersRef.current)
  }, [allActiveUsersList, orgActiveUsersRef])

  const hasEmptyValue = useMemo(() => {
    const emptyValuesCount = makeUniqueArray(
      'key',
      [...activeUsersToAdd, ...allActiveUsersList].filter(item => item.key !== null && !item.value)
    )

    return (hasChanges || hasUsersToAddHasChanges) && emptyValuesCount.length > 0
  }, [hasChanges, hasUsersToAddHasChanges, activeUsersToAdd, allActiveUsersList])

  const saveActiveUsers = useCallback(async () => {
    let hasError = false

    if (hasEmptyValue) {
      hasError = true
      return hasError
    }
    const filteredActiveUsersByDate = makeUniqueArray(
      'key',
      [...activeUsersToAdd, ...allActiveUsersList].filter(item => item.key !== null)
    )
    const originalActiveUserByDateKeys = Object.keys(organization?.config?.activeUsersByDate ?? {})

    // first delete removed entries
    const removedEntries = originalActiveUserByDateKeys.filter(
      key => !filteredActiveUsersByDate.find(item => item.key === key)
    )

    for (const key of removedEntries) {
      const removedKey = `activeUsersByDate.${key}`
      const [error] = await OrganizationService.patchOrganizationConfig(removedKey, null)

      if (error) {
        logException(error, { message: `Error while patching org config "${removedKey}"` })
        hasError = true
      }
    }

    if (filteredActiveUsersByDate.length > 0 && (hasUsersToAddHasChanges || hasChanges)) {
      // save entries using for of (need to be sequential to not override previous values)
      // TODO improve this when a new form of saving org settings is available
      for (const item of filteredActiveUsersByDate) {
        const key = `activeUsersByDate.${item.key}`

        const [error] = await OrganizationService.patchOrganizationConfig(key, item.value)

        if (error) {
          logException(error, { message: `Error while patching org config "${key}"` })
          hasError = true
        }
      }

      if (!hasError) {
        setActiveUsersToAdd([defaultItem])
        setHasUsersToChanges(false)
      }

      return hasError
    }
  }, [
    allActiveUsersList,
    organization,
    activeUsersToAdd,
    hasUsersToAddHasChanges,
    hasChanges,
    hasEmptyValue,
    logException
  ])

  return {
    hasChanges,
    hasUsersToAddHasChanges,
    allActiveUsersList,
    activeUsersToAdd,
    hasEmptyValue,
    onChangeAllActiveUsers,
    onChangeActiveUsersToAdd,
    saveActiveUsers
  }
}

export default useActiveUsersByDate
