import RadioGroup, { RadioGroupOption } from '@/components/atoms/radio-group'
import i18n from '@/plugins/i18n/i18n'
import { Sentiment } from '@/utils/sentiments'

const positiveOptions: RadioGroupOption[] = [
  { text: i18n.t('onlyPositive'), value: Sentiment.OnlyPositive },
  { text: i18n.t('mostlyPositive'), value: Sentiment.MostlyPositive },
  { text: i18n.t('anyPositive'), value: Sentiment.AnyPositive }
]

const neutralOptions: RadioGroupOption[] = [
  { text: i18n.t('onlyNeutral'), value: Sentiment.OnlyNeutral },
  { text: i18n.t('mostlyNeutral'), value: Sentiment.MostlyNeutral },
  { text: i18n.t('anyNeutral'), value: Sentiment.AnyNeutral }
]

const negativeOptions: RadioGroupOption[] = [
  { text: i18n.t('onlyNegative'), value: Sentiment.OnlyNegative },
  { text: i18n.t('mostlyNegative'), value: Sentiment.MostlyNegative },
  { text: i18n.t('anyNegative'), value: Sentiment.AnyNegative }
]

export interface SentimentFilterContentProps {
  value: Sentiment | undefined
  onValueChange: (value: Sentiment) => void
}

const Positive = ({ value, onValueChange }: SentimentFilterContentProps) => (
  <RadioGroup options={positiveOptions} onValueChange={onValueChange} value={value} />
)

const Neutral = ({ value, onValueChange }: SentimentFilterContentProps) => (
  <RadioGroup options={neutralOptions} onValueChange={onValueChange} value={value} />
)

const Negative = ({ value, onValueChange }: SentimentFilterContentProps) => (
  <RadioGroup options={negativeOptions} onValueChange={onValueChange} value={value} />
)

const SentimentFilterContent = {
  Positive,
  Neutral,
  Negative
}

export default SentimentFilterContent
