import Checkbox from '@/components/atoms/checkbox'
import OptionsMenu, { OptionsMenuItem } from '@/components/atoms/options-menu'
import Text from '@/components/atoms/text'
import { TopicCategory, TopicDataWithCategory } from '@/types/classification'
import { useMemo, useState } from 'react'
import MoveTopicMenu from '../move-topic/MoveTopicMenu'
import useUser from '@/hooks/useUser'
import { TopicAccordionItem, TopicInfoRow } from './TopicGroupItem.styles'
import { ArrowSquareRight, NotePencil, TrashSimple } from '@phosphor-icons/react'
import { useTopics } from '@/hooks/useTopics/useTopics'
import NameInput from '@/components/atoms/input/NameInput'
import useTopicsStore from '@/store/useTopicsStore'
import { shallow } from 'zustand/shallow'
import { useTranslation } from 'react-i18next'

interface Props extends TopicDataWithCategory {
  isThemeSelected: boolean
  onClickRemove: (themeId: string) => void
}

const TopicGroupItem = ({ onClickRemove, ...props }: Props) => {
  const { topicName, topicId, totalFrequency } = props

  const selectTopicGroup = useTopicsStore(state => state.selectTopicGroup)
  const unselectTopicGroup = useTopicsStore(state => state.unselectTopicGroup)
  const selectedTopicGroups = useTopicsStore(state => state.selectedTopicGroups, shallow)
  const setDebounceLoading = useTopicsStore(state => state.setDebounceLoading)

  const { renameTopicGroup, moveTopicGroup, reset } = useTopics()
  const { t } = useTranslation()

  const [isEditActive, setIsEditActive] = useState(false)
  const [openOptions, setOpenOptions] = useState(false)

  const { userPermissions } = useUser()
  const isManager = userPermissions.topic.includes('manager')

  const checkedState = useMemo(
    () => selectedTopicGroups.includes(topicId),
    [selectedTopicGroups, topicId]
  )

  const onMoveTheme = (newCategory: string) => {
    moveTopicGroup({ ...props, topicId }, newCategory as TopicCategory)
  }

  function onCheck(checked: boolean) {
    if (checked) {
      selectTopicGroup(topicId)
    } else {
      unselectTopicGroup(topicId)
    }
    reset()
    setDebounceLoading(true)
  }

  function onEditClick() {
    setIsEditActive(true)
  }

  function onEditConfirm(value: string) {
    renameTopicGroup({ ...props, topicId, topicName: value })
    setIsEditActive(false)
  }

  function onEditCancel() {
    setIsEditActive(false)
  }

  const options: OptionsMenuItem[] = [
    {
      text: t('rename'),
      icon: <NotePencil size={16} />,
      onClick: onEditClick
    },
    {
      text: t('delete'),
      icon: <TrashSimple size={16} />,
      onClick: () => {
        setOpenOptions(false)
        onClickRemove(topicId)
      }
    },
    {
      text: t('moveTo'),
      icon: <ArrowSquareRight size={16} />,
      customSubOption: (
        <MoveTopicMenu defaultSelected={props.category} mode={'topic'} onApply={onMoveTheme} />
      )
    }
  ]

  // const selectedTopicsCount = getSelectedTopicCountByTheme(themeId)

  return (
    <TopicAccordionItem noHover={false}>
      {isEditActive ? (
        <NameInput
          confirmLabel={t('apply')}
          initialValue={topicName}
          onCancel={onEditCancel}
          onConfirm={onEditConfirm}
        />
      ) : (
        <>
          <Checkbox
            checked={checkedState}
            css={{ margin: 0 }}
            onCheckedChange={onCheck}
            value={topicId}
          />
          <Text maxWidth={190} title={topicName} truncate typeface="subtitleRegularXXXS">
            {topicName}
          </Text>
          <TopicInfoRow>
            <Text
              align="right"
              color="neutralLowLight"
              css={{ minWidth: 32 }}
              typeface="paragraphRegularMicro"
            >
              {Intl.NumberFormat('en-US', {
                notation: 'compact',
                maximumFractionDigits: 1
              }).format(totalFrequency)}
            </Text>
            {isManager ? (
              <span className="topic-options">
                <OptionsMenu
                  buttonStyle={{ fontSize: '$xxs' }}
                  onOpenChange={setOpenOptions}
                  open={openOptions}
                  options={options}
                  size="small"
                  stopPropagation
                />
              </span>
            ) : null}
          </TopicInfoRow>
        </>
      )}
    </TopicAccordionItem>
  )
}

export default TopicGroupItem
