import { getAuth0Client } from '@/plugins/auth0'
import UserService from '../UserService'
import { updateClient } from '../Request'

let birdieToken_isRefreshing = false

const refreshToken = async () => {
  if (birdieToken_isRefreshing) {
    return
  }

  birdieToken_isRefreshing = true
  const auth0Client = getAuth0Client()

  const auth0Token = await auth0Client.getTokenSilently({
    detailedResponse: true
  })
  const tokenResponse = await UserService.token({
    ...auth0Token,
    token_type: 'Bearer'
  })
  localStorage.setItem('access_token', tokenResponse.token)
  localStorage.setItem('expires_in', tokenResponse.expires.toString())
  updateClient()

  birdieToken_isRefreshing = false
}

export default refreshToken
