import useEnumFiltersQuery from '@/hooks/filters/useEnumFiltersQuery'
import { FilterComponentProps } from './types'
import { useMemo } from 'react'
import EnumFilterRow from './EnumFilterRow'
import useAllMetrics from '@/hooks/metrics/useAllMetricItems'

const SourceFilterRow = (props: FilterComponentProps) => {
  const { getMetricDisplayName } = useAllMetrics()
  const { data, isLoading } = useEnumFiltersQuery({
    name: 'source_alias',
    enabled: true
  })

  const options = useMemo(() => {
    return data?.values?.map(value => ({ label: getMetricDisplayName(value), value })) ?? []
  }, [data, getMetricDisplayName])

  return <EnumFilterRow {...props} isLoading={isLoading} options={options} />
}

export default SourceFilterRow
