import { DialogTitle } from '@/components/atoms/dialog'
import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'

export const ModalContainer = styled(FlexContainer, {
  padding: '18px 16px 24px 20px',

  '& .modal-description': {
    opacity: 0.5
  }
})

export const ModalTitle = styled(DialogTitle, {
  margin: 0
})
