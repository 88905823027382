import { useUIStore } from '@/store'
import EChartsReact, { EChartsReactProps } from 'echarts-for-react'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { shallow } from 'zustand/shallow'
import { SelectionAll, SelectionInverse } from '@phosphor-icons/react'
import { ContextMenu } from '../context-menu'

interface EChartProps extends EChartsReactProps {
  height?: string
  width?: string
  style?: React.CSSProperties
  minWidth?: string
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  onEvents?: Record<string, (e: any) => void>
  withContextMenu?: boolean
}

function EChart({
  option,
  height,
  style,
  width,
  onEvents = {},
  withContextMenu = true,
  ...props
}: EChartProps) {
  const isExpanded = useUIStore(state => state.isFiltersOpen, shallow)
  const isAdvancedFiltersOpen = useUIStore(state => state.isAdvancedFiltersOpen, shallow)
  const isFiltersOpen = useUIStore(state => state.isFeedFiltersOpen, shallow)
  const chartRef = useRef<EChartsReact>(null)

  const resize = useCallback(() => {
    setTimeout(() => {
      const instance = chartRef.current?.getEchartsInstance()
      if (instance) {
        instance.resize()
      }
    }, 320)
  }, [])

  const resizeObserver = useMemo(() => new window.ResizeObserver(resize), [resize])

  const refresh = useCallback(() => {
    const element = document.getElementById('root')
    if (element) {
      resizeObserver.observe(element)
    }
  }, [resizeObserver])

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen once
  useEffect(() => {
    refresh()
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: it should resize when height, option, isExpanded, filters open changed
  useEffect(() => {
    refresh()
    resize()
  }, [option, height, isExpanded, isAdvancedFiltersOpen, isFiltersOpen, refresh, resize])

  const selectAll = () => {
    const instance = chartRef.current?.getEchartsInstance()
    if (instance) {
      instance.dispatchAction({
        type: 'legendAllSelect'
      })
    }
  }

  const unselectAll = () => {
    const instance = chartRef.current?.getEchartsInstance()
    if (instance) {
      instance.dispatchAction({
        type: 'legendAllSelect'
      })
      instance.dispatchAction({
        type: 'legendInverseSelect'
      })
    }
  }

  const inverseSelect = () => {
    const instance = chartRef.current?.getEchartsInstance()
    if (instance) {
      instance.dispatchAction({
        type: 'legendInverseSelect'
      })
    }
  }

  const renderChart = () => {
    return (
      <EChartsReact
        onChartReady={refresh}
        option={option}
        ref={chartRef}
        {...props}
        onEvents={onEvents}
        style={{ height: withContextMenu ? `calc(${height} - 26px)` : height, width, ...style }}
      />
    )
  }

  if (!withContextMenu) {
    return renderChart()
  }

  return (
    <ContextMenu.Root>
      <ContextMenu.Trigger>{renderChart()}</ContextMenu.Trigger>
      <ContextMenu.Content>
        <ContextMenu.Item onClick={selectAll}>
          <SelectionAll size={16} weight="fill" />
          <span>Select all</span>
        </ContextMenu.Item>
        <ContextMenu.Item onClick={unselectAll}>
          <SelectionAll size={16} />
          <span>Unselect all</span>
        </ContextMenu.Item>
        <ContextMenu.Item onClick={inverseSelect}>
          <SelectionInverse size={16} weight="fill" />
          <span>Selection inverse</span>
        </ContextMenu.Item>
      </ContextMenu.Content>
    </ContextMenu.Root>
  )
}

export default EChart
