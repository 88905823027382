import { ReactNode } from 'react'
import {
  CalendarBlank,
  ClipboardText,
  HandPalm,
  IconContext,
  Layout,
  MagnifyingGlass,
  SmileyWink,
  Stack,
  Star,
  Tag,
  UserList,
  X
} from '@phosphor-icons/react'

import Text from '@/components/atoms/text'
import { Container, OptionsMenuButton } from './FilterItem.styles'
import IconButton from '@/components/atoms/icon-button'
import { colors } from '@/theme'
import Tooltip from '@/components/atoms/tooltip'

const iconMap: Record<string, JSX.Element> = {
  'Feedback intention': <HandPalm />,
  Sentiment: <SmileyWink />,
  Rating: <Star />,
  'Product areas': <Layout />,
  Other: <Tag />,
  'Feedback type': <Stack />,
  'Feedback details': <ClipboardText />,
  'Custom fields': <UserList />,
  Date: <CalendarBlank />,
  Search: <MagnifyingGlass />
}

interface FilterItemProps {
  editButton: ReactNode
  name: string
  customIcon?: JSX.Element
  disabled?: boolean
  disabledTooltipText?: ReactNode
  onDelete(): void
}

function FilterItem(props: FilterItemProps) {
  const content = (
    <Container disabled={props.disabled}>
      {iconMap[props.name] || props.customIcon ? (
        <IconContext.Provider
          value={{
            size: 16,
            color: props.disabled ? colors.neutralLowLight : colors.neutralLowPure
          }}
        >
          {iconMap[props.name] || props.customIcon}
        </IconContext.Provider>
      ) : null}

      <Text
        color={props.disabled ? 'neutralLowLight' : 'neutralLowPure'}
        css={{ fontSize: 14, fontWeight: 400, lineHeight: 1.15, marginRight: 'auto' }}
      >
        {props.name}
      </Text>

      {props.editButton}
      {!props.disabled && (
        <IconButton css={OptionsMenuButton} title="Remove this filter">
          <X onClick={props.onDelete} size={16} />
        </IconButton>
      )}
    </Container>
  )

  if (props.disabled && props.disabledTooltipText) {
    return (
      <Tooltip side="right" text={props.disabledTooltipText} variant="small">
        {content}
      </Tooltip>
    )
  }
  return content
}

export default FilterItem
