import { Plus } from '@phosphor-icons/react'
import NameInput from '../input/NameInput'
import Button from '@/components/atoms/button'
import useUser from '@/hooks/useUser'
import useClassification, { CLASSIFICATION_LABELS } from '@/hooks/useClassification'

export interface AddTopicProps {
  addingTopicStatus?: 'top' | 'bottom' | null
  onAddCancel?: () => void
  onAddConfirm?: (value: string) => void
  onBottomAddTopic?: () => void
}

const AddTopic = ({
  addingTopicStatus,
  onAddCancel,
  onAddConfirm,
  onBottomAddTopic
}: AddTopicProps) => {
  const { userPermissions } = useUser()
  const isManager = userPermissions.topic.includes('manager')
  const { classificationLabelsMode } = useClassification()

  const labels: Record<CLASSIFICATION_LABELS, { placeholder: string; button: string }> = {
    'topic-group': {
      placeholder: 'Type topic name',
      button: 'Add topic'
    },
    'default-topic': {
      placeholder: 'Type subtopic name',
      button: 'Add subtopic'
    }
  }

  return (
    <>
      {addingTopicStatus === 'bottom' ? (
        <NameInput
          css={{ pl: '$sm', pr: '$xs' }}
          onCancel={() => onAddCancel?.()}
          onConfirm={value => onAddConfirm?.(value)}
          placeholder={labels[classificationLabelsMode].placeholder}
        />
      ) : isManager ? (
        <Button micro onClick={onBottomAddTopic} text>
          <Plus />
          <span>{labels[classificationLabelsMode].button}</span>
        </Button>
      ) : null}
    </>
  )
}

export default AddTopic
