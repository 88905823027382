import { CSS, styled } from '@/theme'
import ReactMarkdown from 'react-markdown'
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown'
import remarkGfm from 'remark-gfm'

type MarkdownTextProps = {
  css?: CSS
} & ReactMarkdownOptions

const Article = styled('article', {
  fontSize: '$xxxs',
  fontWeight: '$light',
  color: '$neutralLowPure',
  px: '$nano',
  lineHeight: 1.7,

  strong: {
    fontWeight: '$semibold'
  },

  h1: {
    fontSize: '$xxs'
  },
  h3: {
    fontSize: '$xxxs',
    pt: '$xxxs',
    pb: '$micro',

    '&:first-child': {
      pt: 0
    }
  },

  p: {
    pt: '$xxs',

    '&:first-child': {
      pt: 0
    }
  },

  ul: {
    ml: '$xs'
  },

  hr: {
    display: 'none'
  }
})

const MarkdownText = ({
  css,
  children,
  remarkPlugins = [remarkGfm],
  ...props
}: MarkdownTextProps) => {
  return (
    <Article css={css}>
      <ReactMarkdown remarkPlugins={remarkPlugins} {...props}>
        {children}
      </ReactMarkdown>
    </Article>
  )
}

export default MarkdownText
