import Checkbox, { CheckboxState } from '@/components/atoms/checkbox'
import FlexContainer from '@/components/atoms/flex-container'
import { Fragment } from 'react'
import { TopicGroupResultItem } from './AllTopicSearchResults.types'
import { ThemeItemData } from '@/types/classification/Theme'
import parseTopicResultToTheme from './parseTopicResultToTheme'
import { CheckedState } from '@radix-ui/react-checkbox'

interface CheckboxHandlers {
  getThemeCheckedState: (theme: ThemeItemData) => CheckboxState
  isTopicChecked: (topicId: string) => CheckboxState
  onThemeCheck: (theme: ThemeItemData) => (checked: CheckboxState) => void
  onTopicCheck: (topicId: string) => (checked: CheckboxState) => void
}
interface Props {
  items: TopicGroupResultItem[]
  title: string
  onCheckCategoryChange: (checked: CheckedState) => void
  isCategoryChecked: CheckedState
  handlers: CheckboxHandlers
}

const TopicSearchResultList = ({
  items,
  title,
  handlers,
  isCategoryChecked,
  onCheckCategoryChange
}: Props) => {
  const { getThemeCheckedState, isTopicChecked, onThemeCheck, onTopicCheck } = handlers
  return (
    <>
      {items.length > 0 && (
        <>
          <Checkbox
            checked={isCategoryChecked}
            onCheckedChange={onCheckCategoryChange}
            onClick={e => {
              e.stopPropagation()
            }}
            text={title}
            value={'filter-' + title}
          />
          <FlexContainer css={{ ml: '$xs' }} direction="column" gap={'xxxs'}>
            {items.map(group => (
              <Fragment key={group.id}>
                <Checkbox
                  checked={getThemeCheckedState(parseTopicResultToTheme(group))}
                  key={group.id}
                  onCheckedChange={onThemeCheck(parseTopicResultToTheme(group))}
                  text={group.name}
                  value={'filter-' + group.id}
                />
                <FlexContainer css={{ ml: '$xs' }} direction="column" gap={'xxxs'}>
                  {group.childTopics.map(topic => (
                    <Checkbox
                      checked={isTopicChecked(topic.topicId)}
                      key={topic.topicId}
                      onCheckedChange={onTopicCheck(topic.topicId)}
                      text={topic.topicName}
                      value={'filter-' + topic.topicId}
                    />
                  ))}
                </FlexContainer>
              </Fragment>
            ))}
          </FlexContainer>
        </>
      )}
    </>
  )
}

export default TopicSearchResultList
