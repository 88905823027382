import Button, { ButtonProps } from '@/components/atoms/button'
import { colors, styled } from '@/theme'
import { CaretLeft, CaretRight } from '@phosphor-icons/react'

const StyledButton = styled(Button, {
  color: '$neutralLowLight',
  padding: '$nano',
  boxSizing: 'border-box',
  maxSize: 24
})

const Previous = (props: ButtonProps) => {
  return (
    <StyledButton {...props} size="square" variant="flat-bordered">
      <CaretLeft color={colors.neutralLowLight} size={16} />
    </StyledButton>
  )
}

const Next = (props: ButtonProps) => {
  return (
    <StyledButton {...props} size="square" variant="flat-bordered">
      <CaretRight color={colors.neutralLowLight} size={16} />
    </StyledButton>
  )
}

const CarouselButton = {
  Previous,
  Next
}

export default CarouselButton
