import { useCallback } from 'react'
import { useFiltersStore } from '@/store'
import { shallow } from 'zustand/shallow'
import {
  FilterBoolean,
  FilterDatetime,
  FilterNumeric,
  FilterString,
  FilterText,
  FilterTopicOption,
  NumericRangeOption,
  SavedFilterContent
} from '@/types/filters/Filters'
import useClassification from './useClassification'
import { TopicCategory } from '@/types/classification'
import { Period } from '@/types/periods'
import { DateValue, parseDate, parseDateTime } from '@internationalized/date'
import usePeriods from './usePeriods'
import { ThemeItemData } from '@/types/classification/Theme'
import { useFeedFiltersStore } from '@/store/useFiltersStore'

interface Params {
  newFeed?: boolean
}

const useSavedFiltersParsers = ({ newFeed = false }: Params = { newFeed: false }) => {
  const useStore = newFeed ? useFeedFiltersStore : useFiltersStore

  const {
    dateRange,
    period,
    selectedProductAreaThemes,
    selectedProductAreaTopics,
    selectedOtherThemes,
    selectedOtherTopics,
    selectedUnclassifiedTopics,
    selectedUngroupedTopics,
    stringFilters,
    numericFilters,
    datetimeFilters,
    booleanFilters,
    textFilters,
    searchText,
    accounts,
    selectedAccountsNumericFilters,
    selectedAccountsBooleanFilters,
    selectedAccountsDateFilters,
    users,
    selectedUsersNumericFilters,
    selectedUsersBooleanFilters,
    selectedUsersDateFilters
  } = useStore(
    state => ({
      period: state.datePeriod,
      dateRange: state.dateRange,
      selectedProductAreaThemes: state.productAreaThemes.selected,
      selectedOtherThemes: state.otherThemes.selected,
      selectedProductAreaTopics: state.productAreaTopics.selected,
      selectedOtherTopics: state.otherTopics.selected,
      selectedUnclassifiedTopics: state.unclassifiedTopics.selected,
      selectedUngroupedTopics: state.ungroupedTopics.selected,
      stringFilters: state.stringFilters,
      numericFilters: state.numericFilters,
      datetimeFilters: state.datetimeFilters,
      booleanFilters: state.booleanFilters,
      textFilters: state.textFilters,
      searchText: state.search,
      accounts: state.accountsStringFilters,
      selectedAccountsNumericFilters: state.accountNumericFilters,
      selectedAccountsBooleanFilters: state.accountBooleanFilters,
      selectedAccountsDateFilters: state.accountsDateFilters,
      users: state.usersStringFilters,
      selectedUsersNumericFilters: state.usersNumericFilters,
      selectedUsersBooleanFilters: state.usersBooleanFilters,
      selectedUsersDateFilters: state.usersDateFilters
    }),
    shallow
  )

  //
  // Parse selected filters to content
  //
  const parseProductAreaThemesToContent = useCallback(
    (): SavedFilterContent => ({
      key: 'PRODUCT_AREA_THEME',
      name: 'Product area',
      type: 'theme',
      category: 'PRODUCT_AREA',
      values: selectedProductAreaThemes
    }),
    [selectedProductAreaThemes]
  )

  const parseOtherThemesToContent = useCallback(
    (): SavedFilterContent => ({
      key: 'OTHER_THEME',
      name: 'Other topics',
      type: 'theme',
      category: 'OTHER',
      values: selectedOtherThemes
    }),
    [selectedOtherThemes]
  )

  const parseProductAreaTopicsToContent = useCallback(
    (): SavedFilterContent => ({
      key: 'PRODUCT_AREA_TOPIC',
      name: 'Product area',
      type: 'topic',
      category: 'PRODUCT_AREA',
      values: selectedProductAreaTopics
    }),
    [selectedProductAreaTopics]
  )

  const parseOtherTopicsToContent = useCallback(
    (): SavedFilterContent => ({
      key: 'OTHER_TOPIC',
      name: 'Other topics',
      type: 'topic',
      category: 'OTHER',
      values: selectedOtherTopics
    }),
    [selectedOtherTopics]
  )

  const parseUngroupedTopicsToContent = useCallback(
    (): SavedFilterContent[] =>
      selectedUngroupedTopics.map(filter => ({
        key: filter.id,
        name: filter.text,
        type: 'ungrouped-topic',
        values: [filter.id]
      })),
    [selectedUngroupedTopics]
  )

  const parseAccountsToContent = useCallback((): SavedFilterContent[] => {
    const filteredAccounts = Object.entries(accounts).filter(
      ([, value]) => value.selected.length > 0
    )

    return filteredAccounts.map(([key, value]) => ({
      key,
      name: key,
      type: 'customer-records-accounts',
      values: value.selected
    }))
  }, [accounts])

  const parseAccountNumericFiltersToContent = useCallback(
    () =>
      selectedAccountsNumericFilters
        .filter(filter => filter.option !== 'all')
        .map(
          (filter): SavedFilterContent => ({
            key: filter.key,
            type: 'accounts-numeric',
            name: filter.name,
            category: filter.option,
            values: filter.value
              ? [filter.value?.[0].toString(), filter.value?.[1].toString()]
              : ['', '']
          })
        ),
    [selectedAccountsNumericFilters]
  )

  const parseAccountBooleanFiltersToContent = useCallback(
    () =>
      selectedAccountsBooleanFilters
        .filter(filter => filter.value !== null)
        .map(
          (filter): SavedFilterContent => ({
            key: filter.key,
            type: 'accounts-boolean',
            name: filter.name,
            values: [filter.value ? 'true' : 'false']
          })
        ),
    [selectedAccountsBooleanFilters]
  )

  const parseAccountDateFiltersToContent = useCallback(
    () =>
      selectedAccountsDateFilters
        .filter(filter => filter.selected !== null)
        .map((filter): SavedFilterContent => {
          const ISOStart = filter.selected
            ? new Date(filter.selected?.start.toString()).toISOString()
            : ''
          const ISOEnd = filter.selected
            ? new Date(filter.selected?.end.toString()).toISOString()
            : ''

          const start = newFeed ? ISOStart : ISOStart.slice(0, ISOStart.indexOf('T'))
          const end = newFeed ? ISOEnd : ISOEnd.slice(0, ISOEnd.indexOf('T'))

          return {
            key: filter.key,
            type: 'account-date',
            name: filter.key,
            values: [start, end]
          }
        }),
    [selectedAccountsDateFilters, newFeed]
  )

  const parseUsersToContent = useCallback((): SavedFilterContent[] => {
    const filteredUsers = Object.entries(users).filter(([, value]) => value.selected.length > 0)

    return filteredUsers.map(([key, value]) => ({
      key,
      name: key,
      type: 'customer-records-users',
      values: value.selected
    }))
  }, [users])

  const parseUsersNumericFiltersToContent = useCallback(
    () =>
      selectedUsersNumericFilters
        .filter(filter => filter.option !== 'all')
        .map(
          (filter): SavedFilterContent => ({
            key: filter.key,
            type: 'users-numeric',
            name: filter.name,
            category: filter.option,
            values: filter.value
              ? [filter.value?.[0].toString(), filter.value?.[1].toString()]
              : ['', '']
          })
        ),
    [selectedUsersNumericFilters]
  )

  const parseUsersBooleanFiltersToContent = useCallback(
    () =>
      selectedUsersBooleanFilters
        .filter(filter => filter.value !== null)
        .map(
          (filter): SavedFilterContent => ({
            key: filter.key,
            type: 'users-boolean',
            name: filter.name,
            values: [filter.value ? 'true' : 'false']
          })
        ),
    [selectedUsersBooleanFilters]
  )

  const parseUsersDateFiltersToContent = useCallback(
    () =>
      selectedUsersDateFilters
        .filter(filter => filter.selected !== null)
        .map((filter): SavedFilterContent => {
          const ISOStart = filter.selected
            ? new Date(filter.selected?.start.toString()).toISOString()
            : ''
          const ISOEnd = filter.selected
            ? new Date(filter.selected?.end.toString()).toISOString()
            : ''

          const start = newFeed ? ISOStart : ISOStart.slice(0, ISOStart.indexOf('T'))
          const end = newFeed ? ISOEnd : ISOEnd.slice(0, ISOEnd.indexOf('T'))

          return {
            key: filter.key,
            type: 'users-date',
            name: filter.key,
            values: [start, end]
          }
        }),
    [selectedUsersDateFilters, newFeed]
  )

  const parseUnclassifiedTopicsToContent = useCallback(
    (): SavedFilterContent => ({
      key: 'unclassified_topic',
      name: 'Unclassified',
      type: 'unclassified_topic',
      values: selectedUnclassifiedTopics
    }),
    [selectedUnclassifiedTopics]
  )

  const parseStringFiltersToContent = useCallback(
    () =>
      stringFilters.map(
        (filter): SavedFilterContent => ({
          key: filter.key,
          type: filter.type,
          name: filter.name,
          values: filter.selected
        })
      ),
    [stringFilters]
  )

  const parseNumericFiltersToContent = useCallback(
    () =>
      numericFilters
        .filter(filter => filter.option !== 'all')
        .map(
          (filter): SavedFilterContent => ({
            key: filter.key,
            type: filter.type,
            name: filter.name,
            category: filter.option,
            values: filter.value
              ? [filter.value?.[0].toString(), filter.value?.[1].toString()]
              : ['', '']
          })
        ),
    [numericFilters]
  )

  const parseDatetimeFiltersToContent = useCallback(
    () =>
      datetimeFilters
        .filter(filter => filter.value !== null)
        .map((filter): SavedFilterContent => {
          const ISOStart = filter.value
            ? new Date(filter.value?.start.toString()).toISOString()
            : ''
          const ISOEnd = filter.value ? new Date(filter.value?.end.toString()).toISOString() : ''

          const start = newFeed ? ISOStart : ISOStart.slice(0, ISOStart.indexOf('T'))
          const end = newFeed ? ISOEnd : ISOEnd.slice(0, ISOEnd.indexOf('T'))

          return {
            key: filter.key,
            type: filter.type,
            name: filter.name,
            category: filter.period,
            values: [start, end]
          }
        }),
    [datetimeFilters, newFeed]
  )

  const parseBooleanFiltersToContent = useCallback(
    () =>
      booleanFilters
        .filter(filter => filter.value !== null)
        .map(
          (filter): SavedFilterContent => ({
            key: filter.key,
            type: filter.type,
            name: filter.name,
            values: filter.value ? ['true'] : ['false']
          })
        ),
    [booleanFilters]
  )

  const parseTextFiltersToContent = useCallback(
    () =>
      textFilters
        .filter(filter => filter.value !== '')
        .map(
          (filter): SavedFilterContent => ({
            key: filter.key,
            type: 'text',
            name: filter.name,
            values: [filter.value]
          })
        ),
    [textFilters]
  )

  const parseDateFilterToContent = useCallback((): SavedFilterContent | undefined => {
    // does not save default period
    if (period === '3months') return

    return {
      key: 'date_range',
      name: 'Date range',
      type: 'date_range',
      values: dateRange ? [dateRange.start.toString(), dateRange.end.toString()] : ['', ''],
      category: period
    }
  }, [dateRange, period])

  const parseSearchTextToContent = useCallback((): SavedFilterContent | undefined => {
    const text = searchText.trim()
    if (!text.length) return

    return {
      key: newFeed ? 'search_feedback_text' : 'search_text',
      type: newFeed ? 'search_feedback_text' : 'search_text',
      name: 'Search text',
      values: [text]
    }
  }, [searchText, newFeed])

  const parseAllToContent = () => {
    const productAreaThemesContent = parseProductAreaThemesToContent()
    const otherThemesContent = parseOtherThemesToContent()
    const productAreaTopicsContent = parseProductAreaTopicsToContent()
    const otherTopicsContent = parseOtherTopicsToContent()
    const unclassifiedTopicsContent = parseUnclassifiedTopicsToContent()
    const ungroupedTopicsContent = parseUngroupedTopicsToContent()
    const topicsContent = [
      productAreaThemesContent,
      otherThemesContent,
      productAreaTopicsContent,
      otherTopicsContent,
      unclassifiedTopicsContent,
      ...ungroupedTopicsContent
    ].filter(content => content.values.length > 0)

    const stringFiltersContent = parseStringFiltersToContent()
    const numericFiltersContent = parseNumericFiltersToContent()
    const datetimeFiltersContent = parseDatetimeFiltersToContent()
    const booleanFiltersContent = parseBooleanFiltersToContent()
    const textFiltersContent = parseTextFiltersToContent()

    const dateContent = parseDateFilterToContent()

    const searchTextContent = parseSearchTextToContent()

    const accountsContent = parseAccountsToContent()
    const accountsNumericFilters = parseAccountNumericFiltersToContent()
    const accountsBooleanFilters = parseAccountBooleanFiltersToContent()
    const accountDateFilters = parseAccountDateFiltersToContent()
    const usersContent = parseUsersToContent()
    const usersNumericFilters = parseUsersNumericFiltersToContent()
    const usersBooleanFilters = parseUsersBooleanFiltersToContent()
    const usersDateFilters = parseUsersDateFiltersToContent()

    const content: SavedFilterContent[] = [
      ...topicsContent,
      ...stringFiltersContent,
      ...numericFiltersContent,
      ...datetimeFiltersContent,
      ...booleanFiltersContent,
      ...textFiltersContent,
      ...accountsContent,
      ...accountsNumericFilters,
      ...accountsBooleanFilters,
      ...accountDateFilters,
      ...usersContent,
      ...usersNumericFilters,
      ...usersBooleanFilters,
      ...usersDateFilters
    ]

    if (dateContent) {
      content.push(dateContent)
    }

    if (searchTextContent) {
      content.push(searchTextContent)
    }

    return content
  }

  const { themes: storedThemes, topics: storedTopics } = useClassification()

  const getThemesFromContent = (content: SavedFilterContent[], allThemes?: ThemeItemData[]) => {
    let hasMissingThemes = false

    const themes = allThemes ?? storedThemes

    const mapTheme = (item: SavedFilterContent) => {
      const themeIds = themes
        .filter(theme => !!theme.themeId && item.values.includes(theme.themeId))
        .map(theme => theme.themeId)
        .filter(Boolean) as string[]

      hasMissingThemes = themeIds.length < item.values.length

      return themeIds
    }

    const otherThemesIds = content
      .filter(
        content => Boolean(content.type) && content.type === 'theme' && content.category === 'OTHER'
      )
      .flatMap(mapTheme)

    const productAreaThemesIds = content
      .filter(
        content =>
          Boolean(content.type) && content.type === 'theme' && content.category === 'PRODUCT_AREA'
      )
      .flatMap(mapTheme)

    return {
      otherThemesIds,
      productAreaThemesIds,
      hasMissingThemes
    }
  }

  const getTopicsFromContent = (content: SavedFilterContent[], allThemes?: ThemeItemData[]) => {
    let hasMissingTopics = false

    const topics = allThemes ? allThemes.flatMap(theme => theme.topics) : storedTopics

    const mapTopic = (item: SavedFilterContent) => {
      const topicIds = topics
        .filter(topic => !!topic.topicId && item.values.includes(topic.topicId))
        .map(topic => topic.topicId)
        .filter(Boolean) as string[]

      hasMissingTopics = topicIds.length < item.values.length

      return topicIds
    }

    const otherTopicIds = content
      .filter(c => Boolean(c.type) && c.type === 'topic' && c.category === 'OTHER')
      .flatMap(mapTopic)

    const productAreaTopicIds = content
      .filter(c => Boolean(c.type) && c.type === 'topic' && c.category === 'PRODUCT_AREA')
      .flatMap(mapTopic)

    return {
      otherTopicIds,
      productAreaTopicIds,
      hasMissingTopics
    }
  }

  const getUnclassifiedTopicsFromContent = (content: SavedFilterContent[]) => {
    return (content.find(c => Boolean(c.type) && c.type === 'unclassified_topic')?.values ??
      []) as TopicCategory[]
  }

  const getUngroupedTopicsFromContent = (content: SavedFilterContent[]) => {
    const ungroupedTopics = content.filter(c => Boolean(c.type) && c.type === 'ungrouped-topic')

    return ungroupedTopics.map(ungrouped => ({
      id: ungrouped.key,
      text: ungrouped.name
    })) as FilterTopicOption[]
  }

  const { getPeriod } = usePeriods()

  const getDateRangeFromContent = (content: SavedFilterContent[]) => {
    const dateRangeFilter = content.find(c => Boolean(c.type) && c.type === 'date_range')

    if (!dateRangeFilter) {
      return
    }

    const period = (dateRangeFilter.category ?? 'allTime') as Period
    let range: RangeValue<DateValue> | null = null

    if (period === 'custom') {
      range =
        dateRangeFilter?.values && dateRangeFilter.values?.[0].length
          ? {
              start: parseDate(dateRangeFilter?.values[0]),
              end: parseDate(dateRangeFilter?.values[1])
            }
          : null
    } else {
      const preDefinedPeriod = getPeriod(period)
      range = preDefinedPeriod?.range ?? null
    }

    return { period, range }
  }

  const getSearchFilterFromContent = (content: SavedFilterContent[]) => {
    const searchFilter = content.find(
      c => Boolean(c.type) && (c.type === 'search_text' || c.type === 'search_feedback_text')
    )
    return searchFilter && searchFilter.values.length ? searchFilter.values[0] : ''
  }

  const getStringFiltersFromContent = (content: SavedFilterContent[]) => {
    return content
      .filter(
        c =>
          Boolean(c.type) &&
          (c.type.includes('string') || c.type.includes('enum') || c.type.includes('unique'))
      )
      .map(
        (c): FilterString => ({
          key: c.key,
          name: c.name,
          type: c.type,
          selected: c.values,
          draftSelected: c.values
        })
      )
  }

  const getNumericFiltersFromContent = (content: SavedFilterContent[]) => {
    return content
      .filter(c => Boolean(c.type) && (c.type.includes('number') || c.type.includes('integer')))
      .map((c): FilterNumeric => {
        const option = (c.category ?? 'all') as NumericRangeOption
        const value =
          c.category === 'null'
            ? null
            : ([Number(c.values[0]), Number(c.values[1])] as [number, number])

        return {
          key: c.key,
          name: c.name,
          type: c.type,
          option,
          draftOption: option,
          value,
          draftValue: value
        }
      })
  }

  const getDatetimeFiltersFromContent = (content: SavedFilterContent[]) => {
    return content
      .filter(c => Boolean(c.type) && c.type.includes('datetime'))
      .map((c): FilterDatetime => {
        const period = (c.category ?? 'allTime') as Period
        const value = c.values[0]
          ? { start: parseDate(c.values[0].slice(0, 10)), end: parseDate(c.values[1].slice(0, 10)) }
          : null

        return {
          key: c.key,
          name: c.name,
          type: c.type,
          period,
          draftPeriod: period,
          value,
          draftValue: value
        }
      })
  }

  const getBooleanFiltersFromContent = (content: SavedFilterContent[]) => {
    return content
      .filter(c => Boolean(c.type) && c.type.includes('boolean'))
      .map((c): FilterBoolean => {
        const value = c.values[0] === 'true'

        return {
          key: c.key,
          name: c.name,
          type: c.type,
          value,
          draftValue: value
        }
      })
  }

  const getTextFiltersFromContent = (content: SavedFilterContent[]) => {
    return content
      .filter(c => Boolean(c.type) && c.type === 'text')
      .map(
        (c): FilterText => ({
          key: c.key,
          name: c.name,
          value: c.values[0] ?? ''
        })
      )
  }

  const getAccountsFromContent = (content: SavedFilterContent[]) => {
    return content.filter(c => Boolean(c.type) && c.type.includes('customer-records-accounts'))
  }

  const getAccountNumericFiltersFromContent = (content: SavedFilterContent[]) => {
    return content
      .filter(c => Boolean(c.type) && c.type === 'accounts-numeric')
      .map(c => ({
        key: c.key,
        name: c.name,
        type: c.type,
        option: (c.category ?? 'all') as NumericRangeOption,
        value:
          c.category === 'null'
            ? null
            : ([Number(c.values[0]), Number(c.values[1])] as [number, number])
      }))
  }

  const getAccountBooleanFiltersFromContent = (content: SavedFilterContent[]) => {
    return content
      .filter(c => Boolean(c.type) && c.type === 'accounts-boolean')
      .map(c => ({
        key: c.key,
        name: c.name,
        type: c.type,
        value: c.values[0] === 'true'
      }))
  }

  const getAccountDateFiltersFromContent = (content: SavedFilterContent[]) => {
    return content
      .filter(c => Boolean(c.type) && c.type === 'account-date')
      .map(c => ({
        key: c.key,
        name: c.name,
        type: c.type,
        value: c.values[0]
          ? {
              start: parseDateTime(c.values[0].slice(0, 10)),
              end: parseDateTime(c.values[1].slice(0, 10))
            }
          : null
      }))
  }

  const getUsersFromContent = (content: SavedFilterContent[]) => {
    return content.filter(c => Boolean(c.type) && c.type.includes('customer-records-users'))
  }

  const getUsersNumericFiltersFromContent = (content: SavedFilterContent[]) => {
    return content
      .filter(c => Boolean(c.type) && c.type === 'users-numeric')
      .map(c => ({
        key: c.key,
        name: c.name,
        type: c.type,
        option: (c.category ?? 'all') as NumericRangeOption,
        value:
          c.category === 'null'
            ? null
            : ([Number(c.values[0]), Number(c.values[1])] as [number, number])
      }))
  }

  const getUsersBooleanFiltersFromContent = (content: SavedFilterContent[]) => {
    return content
      .filter(c => Boolean(c.type) && c.type === 'users-boolean')
      .map(c => ({
        key: c.key,
        name: c.name,
        type: c.type,
        value: c.values[0] === 'true'
      }))
  }

  const getUsersDateFiltersFromContent = (content: SavedFilterContent[]) => {
    return content
      .filter(c => Boolean(c.type) && c.type === 'users-date')
      .map(c => ({
        key: c.key,
        name: c.name,
        type: c.type,
        value: c.values[0]
          ? {
              start: parseDateTime(c.values[0].slice(0, 10)),
              end: parseDateTime(c.values[1].slice(0, 10))
            }
          : null
      }))
  }

  return {
    parseAllToContent,
    getThemesFromContent,
    getTopicsFromContent,
    getUnclassifiedTopicsFromContent,
    getDateRangeFromContent,
    getSearchFilterFromContent,
    getStringFiltersFromContent,
    getNumericFiltersFromContent,
    getDatetimeFiltersFromContent,
    getBooleanFiltersFromContent,
    getTextFiltersFromContent,
    getAccountsFromContent,
    getAccountNumericFiltersFromContent,
    getAccountBooleanFiltersFromContent,
    getAccountDateFiltersFromContent,
    getUsersFromContent,
    getUsersNumericFiltersFromContent,
    getUsersBooleanFiltersFromContent,
    getUsersDateFiltersFromContent,
    getUngroupedTopicsFromContent
  }
}

export default useSavedFiltersParsers
