import { CSS } from '@/theme'

export const navigationItemDisbledStyle: CSS = {
  pointerEvents: 'none',
  color: '$grey',
  opacity: 0.3,
  cursor: 'default'
}

export const navigationItemStyle: CSS = {
  all: 'unset',
  cursor: 'pointer',
  transition: '.2s',
  color: '$white',
  opacity: 0.5,

  '&.active': {
    opacity: 1
  },

  '&:hover': {
    opacity: 0.8
  },

  '&:disabled': navigationItemDisbledStyle
}

export const opportunityNavigationItemStyle: CSS = {
  bc: '$neutralHighLight',
  color: '$brandPrimaryPure !important',
  padding: '$xxxs',
  br: '60px',
  height: 40,
  width: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  transition: 'none',
  bAll: 'transparent',
  opacity: 1,
  '& svg': {
    minWidth: 16
  },

  '&.active': {
    color: '$neutralHighLight !important',
    bc: '$brandPrimaryPure'
  },

  '&:hover:not(.active)': {
    opacity: 1,

    bc: '$brandPrimaryHover',
    bAll: '$brandPrimaryTransparent07',
    color: '$brandPrimaryPure'
  }
}
