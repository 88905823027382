import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Input, { InputErrorMessage } from '@/components/atoms/input'
import PasswordInput from '@/components/atoms/input/PasswordInput'
import Text from '@/components/atoms/text'
import { styled, typefaceVariants } from '@/theme'
import { InvitedAccountData } from '@/types/auth'
import { ChangeEventHandler, FormEvent, useMemo, useState } from 'react'
import PasswordValidation from './PasswordValidation'
import { useTranslation } from 'react-i18next'

const FormItem = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  width: '100%',

  label: typefaceVariants.subtitleRegularXXXS
})

const initialAccountData: InvitedAccountData = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirmation: ''
}

function validatePassword(password: string) {
  const requirements = {
    length: false,
    lowercase: false,
    uppercase: false,
    numeric: false
  }

  if (password.length >= 8) {
    requirements.length = true
  }
  if (/[a-z]/.test(password)) {
    requirements.lowercase = true
  }
  if (/[A-Z]/.test(password)) {
    requirements.uppercase = true
  }
  if (/[0-9]/.test(password)) {
    requirements.numeric = true
  }

  // Check if the password is valid for all requirements
  if (Object.values(requirements).some(requirement => !requirement)) {
    return { valid: false, requirements }
  }

  return { valid: true, requirements }
}
interface Props {
  inviteeEmail: string
  isSending: boolean
  onCreate: (formData: InvitedAccountData) => Promise<'invalidPassword' | undefined>
}
function InviteForm({ inviteeEmail, isSending, onCreate }: Props) {
  const [accountData, setAccountData] = useState<InvitedAccountData>({
    ...initialAccountData,
    email: inviteeEmail
  })
  const [isValidationVisible, setIsValidationVisible] = useState(false)

  const { t } = useTranslation()
  const setFirstName = (firstName: string) =>
    setAccountData(previous => ({ ...previous, firstName }))
  const setLastName = (lastName: string) => setAccountData(previous => ({ ...previous, lastName }))
  const setPassword = (password: string) => setAccountData(previous => ({ ...previous, password }))
  const setPasswordConfirmation = (passwordConfirmation: string) =>
    setAccountData(previous => ({ ...previous, passwordConfirmation }))

  const onPasswordInput: ChangeEventHandler<HTMLInputElement> = e => {
    const value = e.currentTarget.value
    setPassword(value)
    setIsValidationVisible(value.length > 0)
  }

  const onPasswordBlur = () => {
    setIsValidationVisible(false)
  }

  const passwordDontMatch =
    accountData.passwordConfirmation.length > 0 &&
    accountData.password !== accountData.passwordConfirmation

  const passwordValidation = useMemo(
    () => validatePassword(accountData.password),
    [accountData.password]
  )

  const isDisabled =
    !accountData.firstName.length ||
    !accountData.lastName.length ||
    !accountData.email.length ||
    !accountData.password.length ||
    !accountData.passwordConfirmation.length ||
    !passwordValidation.valid ||
    passwordDontMatch

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (isDisabled) return

    onCreate(accountData)
  }

  return (
    <form onSubmit={onSubmit}>
      <FlexContainer css={{ gap: 20, width: '100%' }} direction="column">
        <FlexContainer css={{ gap: 20 }}>
          <FormItem>
            <label htmlFor="first-name">{t('firstName')}</label>
            <Input
              id="first-name"
              name="first-name"
              onChange={e => setFirstName(e.currentTarget.value)}
              required
              type="text"
              value={accountData.firstName}
            />
          </FormItem>
          <FormItem>
            <label htmlFor="last-name">{t('lastName')}</label>
            <Input
              id="last-name"
              name="last-name"
              onChange={e => setLastName(e.currentTarget.value)}
              required
              type="text"
              value={accountData.lastName}
            />
          </FormItem>
        </FlexContainer>
        <FormItem>
          <label htmlFor="email">Email</label>
          <Input disabled id="email" type="email" value={accountData.email} />
        </FormItem>
        <FormItem>
          <FlexContainer>
            <label htmlFor="create-password">{t('createPassword')}</label>
            {accountData.password.length > 0 && (
              <PasswordValidation
                strength={passwordValidation.valid ? 'strong' : 'weak'}
                visible={isValidationVisible}
                {...passwordValidation.requirements}
              />
            )}
          </FlexContainer>
          <PasswordInput
            id="create-password"
            onBlur={onPasswordBlur}
            onChange={onPasswordInput}
            required
            type="password"
            value={accountData.password}
          />
        </FormItem>
        <FormItem>
          <label htmlFor="confirm-password">{t('confirmPassword')}</label>
          <PasswordInput
            error={passwordDontMatch}
            id="confirm-password"
            onChange={e => setPasswordConfirmation(e.currentTarget.value)}
            required
            type="password"
            value={accountData.passwordConfirmation}
          />
          {passwordDontMatch && (
            <InputErrorMessage css={{ position: 'absolute', bottom: -18 }}>
              {t('passwordsDoNotMatch')}
            </InputErrorMessage>
          )}
        </FormItem>
        <Text as="p" color="neutralLowLight" typeface="paragraphRegularMicro">
          {t('byCreatingAnAccountYouAgreeToOur')}
          <a href="https://birdie.ai/terms-of-use/" rel="noreferrer" tabIndex={9} target="_blank">
            {t('termsAndConditions')}
          </a>{' '}
          {t('and')}{' '}
          <a
            href="https://birdie.ai/privacy-policy/"
            rel="noreferrer"
            tabIndex={10}
            target="_blank"
          >
            {t('privacyPolicy')}
          </a>
          .
        </Text>
        <Button disabled={isDisabled || isSending} fullWidth type="submit" variant="exception">
          {isSending ? t('creatingAccount') : t('createAccount')}
        </Button>
      </FlexContainer>
    </form>
  )
}

export default InviteForm
