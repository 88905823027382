import { OptionsMenuItem } from '@/components/atoms/options-menu'
import useAllMetrics from '@/hooks/metrics/useAllMetricItems'
import useMetricListPayload from '@/hooks/metrics/useMetricListPayload'
import useSourcesQuery from '@/hooks/useSourcesQuery'
import { AllMetricsKey, MetricKey, OverallMetricKeys } from '@/types/metrics'
import { BreakdownOption } from '@/types/time-series/TimeSeries'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const specificBreakdowns: BreakdownOption[] = [
  'opportunity',
  'satisfaction',
  'sentiment',
  'intention',
  'source_alias',
  'customField',
  'feedbackDetail',
  'account',
  'user'
]

interface Props {
  breakdown?: BreakdownOption
  enableShareOverFilters?: boolean
  trackMetric: (value: AllMetricsKey) => void
}

const useTimeSeriesMetricOptions = ({ breakdown, enableShareOverFilters, trackMetric }: Props) => {
  const { values: sourceAliasValues, isLoading } = useSourcesQuery({
    enabled: true,
    sourceMode: 'sourceAlias'
  })

  const [selectedMetric, setSelectedMetric] = useState<AllMetricsKey>('count')

  const { t } = useTranslation()

  const { getMetricKeys } = useMetricListPayload()
  const { allMetricItems, getMetricDisplayName } = useAllMetrics()

  const metricOptionsDropdown = useMemo(() => {
    const countMetric = allMetricItems.count
    const Icon = countMetric.icon

    const getMetricKeysOptions = {
      useTimeseriesMetrics: true,
      enableShareOverFilters,
      breakdown,
      specificBreakdowns
    }
    const overallSubMetrics = getMetricKeys('count', getMetricKeysOptions)

    const options: OptionsMenuItem[] = [
      {
        text: t('overall'),
        icon: <Icon />,
        bold: overallSubMetrics?.includes(selectedMetric as OverallMetricKeys),
        options: overallSubMetrics?.map(metricKey => ({
          text: metricKey === 'feedback_share' ? '% over org' : allMetricItems[metricKey].label,
          onClick: () => {
            setSelectedMetric(metricKey)
            trackMetric(metricKey)
          },
          bold: selectedMetric === metricKey
        }))
      }
    ]

    sourceAliasValues.forEach(source => {
      const metricOption = source as MetricKey
      const timeseriesMetric = allMetricItems[metricOption]

      if (!timeseriesMetric) return
      const Icon = timeseriesMetric.icon

      const metricKeys = getMetricKeys(metricOption, getMetricKeysOptions)
      if (!metricKeys) return

      options.push({
        text: getMetricDisplayName(metricOption),
        icon: <Icon />,
        bold: metricKeys?.includes(selectedMetric as AllMetricsKey),
        options: metricKeys?.map(metricKey => ({
          text: allMetricItems[metricKey].label,
          onClick: () => {
            setSelectedMetric(metricKey)
            trackMetric(metricKey)
          },
          bold: selectedMetric === metricKey
        }))
      })
    })

    return options
  }, [
    sourceAliasValues,
    selectedMetric,
    trackMetric,
    t,
    getMetricKeys,
    breakdown,
    enableShareOverFilters,
    allMetricItems,
    getMetricDisplayName
  ])

  const onMetricChange = (value: string) => {
    setSelectedMetric(value as AllMetricsKey)
  }

  return {
    selectedMetric,
    metricOptionsDropdown,
    onMetricChange,
    setSelectedMetric,
    isDisabled: isLoading
  }
}

export default useTimeSeriesMetricOptions
