import { styled } from '@/theme'
import { slideAndFadeOpen } from '@/theme/animations'
import * as ContextMenuPrimitive from '@radix-ui/react-context-menu'

const Root = ContextMenuPrimitive.Root
const Trigger = ContextMenuPrimitive.Trigger
const Portal = ContextMenuPrimitive.Portal

const Content = styled(ContextMenuPrimitive.Content, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$nano',
  padding: '$micro',
  color: '$neutralLowPure',
  borderRadius: 8,
  bAll: '$neutralHighPure',
  background: '$neutralHighLight',
  overflow: 'hidden',
  boxShadow: '$softShadow',

  ...slideAndFadeOpen
})

const Item = styled(ContextMenuPrimitive.Item, {
  display: 'flex',
  alignItems: 'center',
  gap: '$nano',
  padding: '$nano $micro',
  color: '$neutralLowPure',
  borderRadius: 8,
  background: '$neutralHighLight',
  overflow: 'hidden',
  cursor: 'pointer',
  fontSize: '$xxxs',

  '&:focus-visible': {
    backgroundColor: '$brandPrimaryLight',
    outline: '$brandPrimaryMedium'
  },

  '&:hover': {
    backgroundColor: '$brandPrimaryLight',
    boxShadow: 'none',
    outline: 'none'
  }
})

const ContextMenu = { Root, Trigger, Portal, Content, Item }
export default ContextMenu
