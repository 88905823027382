import { TopicData } from '@/types/classification'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface TopicSettingsState {
  isLoadingMergedTopic: boolean
  setIsLoadingMergedTopic: (isLoadingMergedTopic: boolean) => void

  topicNameInput: string
  setTopicNameInput: (topicNameInput: string) => void

  topicSearchInput: string
  setTopicSearchInput: (topicSearchInput: string) => void

  topicsToMerge: TopicData[]
  pushTopicToMerge: (topicToMerge: TopicData) => void
  removeTopicToMerge: (topicToMerge: TopicData) => void
  setTopicsToMerge: (topicsToMerge: TopicData[]) => void
  clearTopicsToMerge: () => void

  topicsToUnmerge: string[]
  pushTopicToUnmerge: (topicToUnmerge: string) => void
  removeTopicToUnmerge: (topicToUnmerge: string) => void
  clearTopicsToUnmerge: () => void
}

const useTopicSettingsStore = createWithEqualityFn<TopicSettingsState>()(
  set => ({
    isLoadingMergedTopic: false,
    setIsLoadingMergedTopic: isLoadingMergedTopic => set({ isLoadingMergedTopic }),

    topicNameInput: '',
    setTopicNameInput: topicNameInput => set({ topicNameInput }),

    topicSearchInput: '',
    setTopicSearchInput: topicSearchInput => set({ topicSearchInput }),

    topicsToMerge: [],
    pushTopicToMerge: topicToMerge =>
      set(state => ({ topicsToMerge: [...state.topicsToMerge, topicToMerge] })),
    removeTopicToMerge: topicToMerge =>
      set(state => ({
        topicsToMerge: state.topicsToMerge.filter(topic => topic.topicId !== topicToMerge.topicId)
      })),
    setTopicsToMerge: topicsToMerge => set({ topicsToMerge }),
    clearTopicsToMerge: () => set({ topicsToMerge: [] }),

    topicsToUnmerge: [],
    pushTopicToUnmerge: topicToUnmerge =>
      set(state => ({ topicsToUnmerge: [...state.topicsToUnmerge, topicToUnmerge] })),
    removeTopicToUnmerge: topicToUnmerge =>
      set(state => ({
        topicsToUnmerge: state.topicsToUnmerge.filter(topic => topic !== topicToUnmerge)
      })),
    clearTopicsToUnmerge: () => set({ topicsToUnmerge: [] })
  }),
  shallow
)

export default useTopicSettingsStore
