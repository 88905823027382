import { CheckboxState } from '@/components/atoms/checkbox'
import useFiltersStore, { useFeedFiltersStore } from '@/store/useFiltersStore'
import { useCallback, useMemo } from 'react'
import { shallow } from 'zustand/shallow'

interface Props {
  name: string
  filterKey: string
  type: string
  newFeed?: boolean
}

const useStringFilterSelection = ({ name, type, filterKey, newFeed = false }: Props) => {
  const defaultStringFilters = useFiltersStore(state => state.stringFilters, shallow)
  const defaultSelectStringFilter = useFiltersStore(state => state.selectStringFilter)

  const newFeedStringFilters = useFeedFiltersStore(state => state.stringFilters, shallow)
  const newFeedSelectStringFilter = useFeedFiltersStore(state => state.selectStringFilter)

  const stringFilters = newFeed ? newFeedStringFilters : defaultStringFilters
  const selectStringFilter = newFeed ? newFeedSelectStringFilter : defaultSelectStringFilter

  const storedFilter = useMemo(
    () => stringFilters.find(f => f.key === filterKey && f.type === type),
    [stringFilters, filterKey, type]
  )

  const selectedOptions: string[] = useMemo(
    () => storedFilter?.draftSelected ?? [],
    [storedFilter?.draftSelected]
  )
  const appliedOptions: string[] = useMemo(
    () => storedFilter?.selected ?? [],
    [storedFilter?.selected]
  )

  const hasChanges = useMemo(
    () => JSON.stringify(selectedOptions) !== JSON.stringify(appliedOptions),
    [selectedOptions, appliedOptions]
  )

  const isChecked = (option: string) => selectedOptions.includes(option)

  const selectOption = useCallback(
    (checked: CheckboxState, option: string) => {
      const newCheckedItems = checked
        ? [...selectedOptions, option]
        : selectedOptions.filter(item => item !== option)

      selectStringFilter({
        key: filterKey,
        name,
        type,
        selected: newCheckedItems
      })
    },
    [filterKey, name, type, selectedOptions, selectStringFilter]
  )

  const apply = useCallback(() => {
    selectStringFilter(
      {
        key: filterKey,
        name,
        type,
        selected: selectedOptions
      },
      true
    )
  }, [filterKey, name, type, selectedOptions, selectStringFilter])

  return {
    isChecked,
    selectOption,
    hasChanges,
    selectedOptions,
    appliedOptions,
    apply
  }
}

export default useStringFilterSelection
