import { create } from 'zustand'
import { IntegrationConnector, IntegrationSource } from '@/types/integrations'
import IntegrationService from '@/services/IntegrationServices'
import useUserStore from './useUserStore'

interface ConnectorState {
  data: IntegrationConnector[]
  isLoading: boolean
  page: number
  hasMore: boolean
  searchQuery?: string
  fields: Record<string, IntegrationConnector['arguments']>
}

interface IntegrationState {
  breadcrumbs: Array<{ to: string; name: string }>
  connectors: ConnectorState
  hasMore: boolean
  integrations: IntegrationSource[]
  isLoading: boolean
  page: number

  getConnectorById: (id: string) => IntegrationConnector | undefined
  getIntegrationById: (id: string) => IntegrationSource | undefined
  loadConnectorArgument: (id: string) => Promise<void>
  loadConnectors: () => Promise<void>
  loadIntegrations: (options?: { concat?: boolean }) => Promise<void>
  reloadIntegration: (integrationId: string) => Promise<void>
  removeExtraction: (fieldId: string) => Promise<void>
  addBreadcrumbs: (crumbs: Array<{ to: string; name: string }>) => void
  removeBreadcrumbs: (count?: number) => void
  setConnectors: (connectorState: Partial<ConnectorState>) => void
  setIntegrations: (integrations: IntegrationSource[], concat?: boolean) => void
  setPage: (newPage: number) => void
  setPageConnector: (newPage: number) => void
}

const useIntegrationsStore = create<IntegrationState>((set, get) => ({
  integrations: [],
  isLoading: false,
  page: 0,
  hasMore: true,
  connectors: {
    data: [],
    isLoading: false,
    page: 0,
    hasMore: true,
    fields: {}
  },
  breadcrumbs: [{ to: '/settings/integrations', name: 'Integrations' }],

  setIntegrations: (integrations, concat) =>
    set(state => ({
      integrations: concat ? [...state.integrations, ...integrations] : integrations
    })),

  setPage: newPage => set(() => ({ page: newPage })),

  getIntegrationById: id => {
    const { integrations } = get()
    return integrations.find(integration => integration.id === id)
  },

  loadIntegrations: async (options = {}) => {
    try {
      set(state => ({ ...state, isLoading: true }))

      const organizationId = useUserStore.getState().currentUser?.organization_id ?? ''
      const data = await IntegrationService.listIntegrations(organizationId, { limit: '1000' })

      set(state => ({
        integrations: options.concat ? [...state.integrations, ...data] : data,
        isLoading: false
      }))
    } catch (error) {
      set(state => ({ ...state, isLoading: false }))
      throw error
    }
  },

  reloadIntegration: async (integrationId: string) => {
    try {
      set({ isLoading: true })

      const data = await IntegrationService.getIntegration(integrationId)

      set(state => ({
        integrations: state.integrations.map(integration =>
          integration.id === integrationId ? data : integration
        ),
        isLoading: false
      }))
    } catch {
      set({ isLoading: false })
    }
  },

  loadConnectors: async () => {
    const { setConnectors } = get()
    setConnectors({ isLoading: true })
    try {
      const data = await IntegrationService.listConnectors()

      setConnectors({ data, isLoading: false })
    } catch (error) {
      setConnectors({ isLoading: false })
      throw error
    }
  },
  setConnectors: connectorState =>
    set(state => ({
      connectors: { ...state.connectors, ...connectorState }
    })),
  setPageConnector: newPage =>
    set(state => ({
      connectors: { ...state.connectors, page: newPage }
    })),
  getConnectorById: id => {
    const {
      connectors: { data }
    } = get()
    return data.find(connector => connector.id === id)
  },

  loadConnectorArgument: async id => {
    const { setConnectors, connectors } = get()
    const { fields } = connectors
    try {
      if (!fields[id]) {
        const connectorData = await IntegrationService.getConnector(id)
        const newArguments = {
          ...fields,
          [id]: connectorData.arguments
        }

        setConnectors({ fields: newArguments || {} })
      }
    } catch (error) {
      console.error(error)
    }
  },

  removeExtraction: async extractionId => {
    await IntegrationService.deleteExtraction(extractionId)
    set(state => ({
      integrations: state.integrations.filter(extraction => extraction.id !== extractionId)
    }))
  },

  addBreadcrumbs: crumbs => {
    set(state => ({ breadcrumbs: [...state.breadcrumbs, ...crumbs] }))
  },

  removeBreadcrumbs: count => {
    // Prevent removal of first element
    const getEnd = (length: number) => (count ? -Math.min(count, length - 1) : 1)

    set(state => ({
      breadcrumbs: state.breadcrumbs.slice(0, getEnd(state.breadcrumbs.length))
    }))
  }
}))

export default useIntegrationsStore
