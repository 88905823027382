import TrendingTopicsCard from './TrendingTopicsCard'

const TrendingTopicItemEmpty = () => (
  <TrendingTopicsCard.Item empty to="">
    <TrendingTopicsCard.ItemTitle> ---- </TrendingTopicsCard.ItemTitle>
    <TrendingTopicsCard.Number css={{ ml: 'auto' }}> ---- </TrendingTopicsCard.Number>
  </TrendingTopicsCard.Item>
)

export default TrendingTopicItemEmpty
