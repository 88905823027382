import FeedbackService from '@/services/FeedbackService'
import useDebounce from './useDebounce'
import { useQuery } from '@tanstack/react-query'

const DEBOUNCE_INTERVAL = 1000 // 1s

type OnValidate = (isValid: boolean) => void

const useQueryValidation = (onValidate: OnValidate, queryString: string, enabled = true) => {
  const debouncedValue = useDebounce(queryString, DEBOUNCE_INTERVAL)

  const queryFn = async () => {
    if (debouncedValue === '') {
      return true
    }

    const [error] = await FeedbackService.validateQuery(debouncedValue)

    onValidate(error === undefined)
    return !error
  }

  useQuery({
    queryKey: ['query-validation', debouncedValue],
    queryFn,
    enabled
  })
}

export default useQueryValidation
