import { CheckboxState } from '@/components/atoms/checkbox'
import KeywordService from '@/services/KeywordService'
import ThemeService from '@/services/ThemeService'
import TopicService, { topicErrors } from '@/services/TopicService'
import { useFeedbackStore, useKeywordsStore } from '@/store'
import useClassificationStore, {
  usePersistedClassificationStore
} from '@/store/useClassificationStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import { TopicCategory } from '@/types/classification'
import { TopicItemData } from '@/types/classification/Theme'
import {
  Keyword,
  KeywordSuggestion,
  KeywordWithTopicIds,
  TopicRelationEvent
} from '@/types/keywords'
import { useCallback, useMemo } from 'react'

import { shallow } from 'zustand/shallow'
import useEvents from './useEvents'
import useSegment from './useSegment'
import useUser from './useUser'
import { useArchivedFeedbackStore, useChatFeedbackStore } from '@/store/useFeedbackStore'
import useFiltersStore from '@/store/useFiltersStore'
import useTopicsStore from '@/store/useTopicsStore'
import { useLocation } from 'react-router-dom'
import useLogging from './useLogging'

/** Max delay (in ms) to call refresh themes API */
export const REFRESH_THEMES_MAX_DELAY = 2000
/** Min delay (in ms) to call refresh themes API */
export const REFRESH_THEMES_MIN_DELAY = 850

export type CLASSIFICATION_LABELS = 'topic-group' | 'default-topic'
function useClassification() {
  const { track } = useSegment()
  const { logException } = useLogging({ context: 'classification' })
  const { currentUser, userPermissions } = useUser()

  const addToast = useToastMessageStore(state => state.addToast)
  const removeToast = useToastMessageStore(state => state.removeToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const setThemes = useClassificationStore(state => state.setThemes)
  const setThemesByCategory = useClassificationStore(state => state.setThemesByCategory)
  const replaceThemes = useClassificationStore(state => state.replaceThemes)
  const pushTheme = useClassificationStore(state => state.pushTheme)
  const pushTopic = useClassificationStore(state => state.pushTopic)
  const updateTheme = useClassificationStore(state => state.updateTheme)
  const updateTopic = useClassificationStore(state => state.updateTopic)
  const storeRemoveTheme = useClassificationStore(state => state.removeTheme)
  const storeRemoveTopic = useClassificationStore(state => state.removeTopic)
  const setIsLoading = useClassificationStore(state => state.setIsLoading)
  const expandTheme = useClassificationStore(state => state.setExpandedTheme)
  const setSuggestionsForTopic = useClassificationStore(state => state.setSuggestionsForTopic)
  const removeSuggestionFromTopic = useClassificationStore(state => state.removeSuggestionFromTopic)
  const setIsTopicsVerified = useClassificationStore(state => state.setIsTopicsVerified)

  const {
    unclassifiedOtherThemes,
    unclassifiedProductArea,
    productAreaThemes,
    otherThemes,
    isLoading,
    expandedTheme,
    keywordSuggestions,
    isTopicsVerified
  } = useClassificationStore(
    state => ({
      unclassifiedProductArea: state.unclassifiedProductArea,
      unclassifiedOtherThemes: state.unclassifiedOtherThemes,
      productAreaThemes: state.productAreaThemes,
      otherThemes: state.otherThemes,
      isLoading: state.isLoading,
      expandedTheme: state.expandedTheme,
      keywordSuggestions: state.keywordsSuggestions,
      isTopicsVerified: state.isTopicsVerified
    }),
    shallow
  )

  const setSelectedCategory = usePersistedClassificationStore(state => state.setSelectedCategory)
  const selectedCategory = usePersistedClassificationStore(state => state.selectedCategory, shallow)

  const themes = selectedCategory === 'PRODUCT_AREA' ? productAreaThemes : otherThemes
  const allThemes = [...productAreaThemes, ...otherThemes]
  const unclassifiedTopics =
    selectedCategory === 'PRODUCT_AREA' ? unclassifiedProductArea : unclassifiedOtherThemes

  const storeSelectTheme = useFiltersStore(state => state.selectTheme)
  const storeUnselectTheme = useFiltersStore(state => state.unselectTheme)
  const storeSelectTopics = useFiltersStore(state => state.selectTopics)
  const storeUnselectTopics = useFiltersStore(state => state.unselectTopics)
  const toggleUnclassifiedTopics = useFiltersStore(state => state.toggleUnclassifiedTopics)
  const removeKeywordTopicFromFeedback = useFeedbackStore(
    state => state.removeKeywordTopicFromFeedback
  )

  const { selectedThemes, selectedTopics, unclassifiedTopicType, volumeBy } = useFiltersStore(
    state => ({
      selectedThemes: [
        ...state.productAreaThemes.draftSelected,
        ...state.otherThemes.draftSelected
      ],
      selectedTopics: [
        ...state.productAreaTopics.draftSelected,
        ...state.otherTopics.draftSelected
      ],
      unclassifiedTopicType: state.unclassifiedTopics.draftSelected,
      volumeBy: state.volumeBy
    }),
    shallow
  )

  const resetFeedbacks = useFeedbackStore(state => state.reset)
  const resetArchivedFeedbacks = useArchivedFeedbackStore(state => state.reset)
  const resetChatFeedbacks = useChatFeedbackStore(state => state.reset)
  const resetKeywords = useKeywordsStore(state => state.reset)
  const resetTopics = useTopicsStore(state => state.reset)

  const removeRelationsFromKeywords = useKeywordsStore(state => state.removeRelationsFromKeywords)

  const isUnclassifiedSelected = unclassifiedTopicType.includes(selectedCategory)

  const { dispatch } = useEvents<TopicRelationEvent>()

  const { pathname } = useLocation()
  const showNewTopics = userPermissions['new-topic'].length > 0
  const classificationLabelsMode: CLASSIFICATION_LABELS = useMemo(() => {
    if (pathname.includes('keyword')) return 'topic-group'
    if (!showNewTopics) return 'default-topic'

    const ENABLED_PAGES = ['keyword', 'topic-management', 'discovery', 'feedback']
    if (ENABLED_PAGES.some(url => pathname.includes(url))) return 'topic-group'

    return 'default-topic'
  }, [pathname, showNewTopics])

  function refresh() {
    resetFeedbacks()
    resetChatFeedbacks()
    resetArchivedFeedbacks()
    resetKeywords()
    resetTopics()
  }

  function selectTheme(themeId: string) {
    if (themeId === 'unclassified') {
      toggleUnclassifiedTopics(selectedCategory)
    } else {
      storeSelectTheme({ themeId, topicCategory: selectedCategory })
    }
  }

  function unselectTheme(themeId: string) {
    if (themeId === 'unclassified') {
      toggleUnclassifiedTopics(selectedCategory)
    } else {
      const topicIds =
        themes.find(theme => theme.themeId === themeId)?.topics.map(topic => topic.topicId) ?? []
      storeUnselectTheme({ themeId, topicCategory: selectedCategory })
      storeUnselectTopics({ topicIds, topicCategory: selectedCategory })
    }
  }

  function getThemeSelectedState(themeId?: string): CheckboxState {
    if (!themeId) return false

    if (themeId === 'unclassified') {
      return isUnclassifiedSelected
    }

    // const isIndeterminated = themes
    //   .find(theme => theme.themeId === themeId)
    //   ?.topics.some(topic => selectedTopics.includes(topic.topicId))

    // if (isIndeterminated) {
    //   return 'indeterminate'
    // }

    return selectedThemes.includes(themeId)
  }

  const getSelectedTopicCountByTheme = useCallback(
    (themeId: string | null) => {
      if (!themeId) {
        return 0
      }

      const foundTheme = themes.find(theme => theme.themeId === themeId)
      if (!foundTheme) {
        return 0
      }

      return selectedTopics.reduce(
        (acc, cur) => acc + (foundTheme.topics.map(topic => topic.topicId).includes(cur) ? 1 : 0),
        0
      )
    },
    [selectedTopics, themes]
  )

  const selectedTopicCount = useMemo(() => {
    if (!expandedTheme) {
      return 0
    }

    return selectedTopics.reduce(
      (acc, cur) => acc + (expandedTheme.topics.map(topic => topic.topicId).includes(cur) ? 1 : 0),
      0
    )
  }, [selectedTopics, expandedTheme])

  function selectTopic(topicId: string) {
    if (!expandedTheme) {
      return
    }
    if (selectedTopicCount === expandedTheme?.topics.length - 1) {
      selectTheme(expandedTheme.themeId ?? '')
      storeUnselectTopics({
        topicIds: expandedTheme.topics.map(topic => topic.topicId),
        topicCategory: selectedCategory
      })
    } else {
      storeSelectTopics({ topicIds: [topicId], topicCategory: selectedCategory })
    }
  }

  function unselectTopic(topicId: string) {
    const themeId = expandedTheme?.themeId ?? ''
    if (selectedThemes.includes(themeId)) {
      unselectTheme(themeId)
      storeSelectTopics({
        topicIds: expandedTheme?.topics.map(topic => topic.topicId) ?? [],
        topicCategory: selectedCategory
      })
    }
    storeUnselectTopics({ topicIds: [topicId], topicCategory: selectedCategory })
  }

  function isTopicSelected(topicId: string) {
    return selectedTopics.includes(topicId)
  }

  async function loadThemes(category?: TopicCategory) {
    setIsLoading(true)
    const themes = await ThemeService.listThemes({
      feedback_search_schema: {
        filter: {
          feedback_group_enable: volumeBy === 'ticket'
        },
        filter_keyword: {
          topic_groups: [],
          theme_type: category ?? selectedCategory
        }
      }
    })

    setThemesByCategory({ themes, topicCategory: category ?? selectedCategory })
  }

  async function loadAllThemes() {
    setIsLoading(true)
    const productAreaPromise = ThemeService.listThemes({
      feedback_search_schema: {
        filter: {
          feedback_group_enable: volumeBy === 'ticket'
        },
        filter_keyword: {
          topic_groups: [],
          theme_type: 'PRODUCT_AREA'
        }
      }
    })
    const otherThemesPromise = ThemeService.listThemes({
      feedback_search_schema: {
        filter: {
          feedback_group_enable: volumeBy === 'ticket'
        },
        filter_keyword: {
          topic_groups: [],
          theme_type: 'OTHER'
        }
      }
    })

    const [productAreaThemesData, otherThemesData] = await Promise.all([
      productAreaPromise,
      otherThemesPromise
    ])

    setThemes({ productAreaThemes: productAreaThemesData, otherThemes: otherThemesData })
    return [...productAreaThemesData, ...otherThemesData]
  }

  async function refreshThemes(all?: boolean) {
    if (all) {
      const productAreaPromise = ThemeService.listThemes({
        feedback_search_schema: {
          filter: {
            feedback_group_enable: volumeBy === 'ticket'
          },
          filter_keyword: {
            topic_groups: [],
            theme_type: 'PRODUCT_AREA'
          }
        }
      })
      const otherThemesPromise = ThemeService.listThemes({
        feedback_search_schema: {
          filter: {
            feedback_group_enable: volumeBy === 'ticket'
          },
          filter_keyword: {
            topic_groups: [],
            theme_type: 'OTHER'
          }
        }
      })

      const [productAreaThemes, otherThemes] = await Promise.all([
        productAreaPromise,
        otherThemesPromise
      ])

      setThemes({ productAreaThemes, otherThemes })
      return
    }

    const themes = await ThemeService.listThemes({
      feedback_search_schema: {
        filter: {
          feedback_group_enable: volumeBy === 'ticket'
        },
        filter_keyword: {
          topic_groups: [],
          theme_type: selectedCategory
        }
      }
    })

    const currentTheme = themes.find(theme => theme.themeId === expandedTheme?.themeId)
    if (currentTheme) expandTheme(currentTheme)

    replaceThemes(themes)
  }

  async function addTheme(themeName: string, category?: TopicCategory) {
    if (themeName.trim().length === 0) {
      return
    }

    const removeLoadingToast = addLoadingToast({ text: 'Creating new topic...' })

    const [error, data] = await ThemeService.create({
      name: themeName,
      description: '',
      category: category ?? selectedCategory
    })

    if (error) {
      removeLoadingToast()
      logException(error, { message: 'Failed to create theme.' })

      addErrorToast({
        text: error.message,
        duration: 3000
      })

      return
    }

    track('explore_user_theme_creation')
    removeLoadingToast()

    addSuccessToast({
      text: 'Topic created successfully'
    })

    pushTheme(
      {
        themeId: data.theme_id,
        themeName,
        topics: [],
        frequency: 0,
        sentimentMetrics: {
          negativeCount: 0,
          negativePercentage: 0,
          netSentiment: 0,
          neutralCount: 0,
          neutralPercentage: 0,
          positiveCount: 0,
          positivePercentage: 0
        }
      },
      category ?? selectedCategory
    )
  }

  async function addTopic(topicName: string, themeId?: string, category?: TopicCategory) {
    if (topicName.trim().length === 0) {
      return
    }

    if (!themeId) {
      return
    }

    const removeLoadingToast = addLoadingToast({ text: 'Creating new topic...' })

    const [error, data] = await TopicService.create({
      name: topicName,
      description: ''
    })
    if (error) {
      removeLoadingToast()

      logException(error, { message: 'Failed to create topic.' })

      addErrorToast({
        text: error.message,
        duration: 3000
      })

      return
    }
    await TopicService.addThemeRelation(data.topic_id, themeId)

    track('explore_user_topic_creation')
    removeLoadingToast()

    addSuccessToast({
      text: 'Created successfully'
    })

    pushTopic({
      topic: {
        topicName,
        topicId: data.topic_id,
        frequency: 0,
        sentimentMetrics: {
          negativeCount: 0,
          negativePercentage: 0,
          netSentiment: 0,
          neutralCount: 0,
          neutralPercentage: 0,
          positiveCount: 0,
          positivePercentage: 0
        }
      },
      themeId,
      category
    })
  }

  async function renameTheme({
    themeId,
    themeName
  }: {
    themeId: string | null
    themeName: string
  }) {
    if (!themeId) {
      return
    }

    const removeLoadingToast = addLoadingToast({ text: 'Updating subtopic...' })

    const [error] = await ThemeService.update({
      themeId,
      name: themeName,
      category: selectedCategory,
      description: ''
    })

    if (error) {
      removeLoadingToast()

      logException(error, { message: 'Failed to rename theme.' })

      addErrorToast({
        text: error.message,
        duration: 3000
      })

      return
    }
    removeLoadingToast()
    updateTheme({ themeId, partialTheme: { themeName } })
    addSuccessToast({
      text: 'Renamed successfully'
    })
  }

  async function removeTheme(themeId: string) {
    const toastId = `remove-theme-${themeId}`
    addToast({ id: toastId, open: true, status: 'loading', text: 'Removing topic...' })
    try {
      await ThemeService.remove(themeId)
      removeToast(toastId)
      storeRemoveTheme(themeId)
      addToast({
        id: 'remove-theme-success',
        open: true,
        status: 'success',
        text: 'Topic removed successfully'
      })
    } catch (error) {
      const message = ' Failed to remove topic'
      logException(error, { message })
      addToast({
        id: 'remove-theme-failed',
        open: true,
        status: 'error',
        text: message
      })

      removeToast(toastId)
    }
  }

  async function moveTheme({
    themeId,
    category
  }: {
    themeId?: string | null
    category: TopicCategory
  }) {
    if (!themeId) return
    const theme = themes.find(theme => theme.themeId === themeId)

    if (!theme) return

    const removeLoadingToast = addLoadingToast({ text: 'Moving topic...' })

    const [error] = await ThemeService.update({
      themeId,
      name: theme.themeName,
      category,
      description: ''
    })

    if (error) {
      removeLoadingToast()

      logException(error, { message: 'Failed to move theme.' })

      addErrorToast({
        text: error.message,
        duration: 3000
      })

      return
    }
    removeLoadingToast()
    storeRemoveTheme(themeId)
    addSuccessToast({
      text: 'Topic moved successfully'
    })
  }

  async function renameTopic({ topicId, topicName }: { topicId: string; topicName: string }) {
    const themeId = expandedTheme?.themeId
    if (!themeId) {
      return
    }

    const removeLoadingToast = addLoadingToast({ text: 'Updating subtopic...' })

    const [error] = await TopicService.update(topicId, {
      name: topicName,
      description: '',
      updated_by: currentUser?.email ?? ''
    })
    removeLoadingToast()

    if (error) {
      const message = 'Failed to rename topic.'
      logException(error, { message })
      addErrorToast({
        text: topicErrors[error.key] || error.message || message
      })
      return
    }

    updateTopic({ themeId, topicId, partialTopic: { topicName } })
    addToast({
      id: 'edit-topic-success',
      open: true,
      status: 'success',
      text: 'Subtopic renamed successfully'
    })
  }

  async function removeTopic(topicId: string) {
    const themeId = expandedTheme?.themeId
    if (!themeId) {
      return
    }

    const toastId = `remove-topic-${topicId}`
    addToast({ id: toastId, open: true, status: 'loading', text: 'Removing subtopic...' })
    try {
      await TopicService.delete(topicId, currentUser?.organization_id ?? '')
      removeToast(toastId)
      storeRemoveTopic({ themeId, topicId })
      addToast({
        id: 'remove-topic-success',
        open: true,
        status: 'success',
        text: 'Subtopic removed successfully'
      })

      refreshThemes()
    } catch (error) {
      const message = 'Failed to remove subtopic'
      logException(error, { message })
      addToast({
        id: 'remove-topic-failed',
        open: true,
        status: 'error',
        text: message
      })

      removeToast(toastId)
    }
  }

  async function moveTopic({
    topic,
    themeIdDestination
  }: {
    topic: TopicItemData
    themeIdDestination: string
  }) {
    const currentThemeId = expandedTheme?.themeId
    if (!currentThemeId) {
      return
    }

    const removeLoadingToast = addLoadingToast({
      text: 'Moving subtopic...'
    })

    try {
      await TopicService.addThemeRelation(topic.topicId, themeIdDestination)
      await TopicService.removeThemeRelation(topic.topicId, currentThemeId)

      removeLoadingToast()

      storeRemoveTopic({ themeId: currentThemeId, topicId: topic.topicId })
      pushTopic({ topic, themeId: themeIdDestination })

      addSuccessToast({
        text: 'Subtopic moved successfully'
      })

      setTimeout(() => {
        refreshThemes()
      }, REFRESH_THEMES_MIN_DELAY)
    } catch (error) {
      removeLoadingToast()
      const message = 'Failed to move subtopic'
      logException(error, { message })
      addErrorToast({
        text: message
      })
    }
  }

  async function loadSuggestionsForTopic(topicId: string) {
    const response = await KeywordService.getSuggestionsByTopicId({
      topicId,
      offset: 0,
      size: 50
    })

    const suggestions: KeywordSuggestion[] = response.data.map(keywordSuggestion => ({
      frequency: keywordSuggestion.frequency,
      keywordHash: keywordSuggestion.keyword_hash,
      text: keywordSuggestion.text
    }))

    setSuggestionsForTopic({ topicId, suggestions })
  }

  async function fetchKeywordsForTopic(topicId: string): Promise<KeywordWithTopicIds[]> {
    const response = await KeywordService.search({
      filter: { topic_groups: [[topicId]], feedback_filter: { topic_groups: [[topicId]] } }
    })

    return response.data.keyword_list.map(keyword => ({
      keywordHash: keyword.keyword_hash,
      text: keyword.text,
      topicIds: keyword.topic_ids,
      topics: keyword.topics.map(topic => ({ topicId: topic.topic_id, name: topic.name }))
    }))
  }

  async function addTopicKeywordRelation(params: {
    topicId: string
    keyword: KeywordSuggestion | Keyword
  }) {
    const { topicId, keyword } = params
    await TopicService.addKeywordRelation(topicId, keyword.keywordHash)
    removeSuggestionFromTopic({ topicId, keywordHash: keyword.keywordHash })
    refreshThemes()
  }

  async function removeTopicKeywordSuggestion(params: { topicId: string; keywordHash: string }) {
    const toastId = 'remove-suggestion'
    addToast({
      id: toastId,
      text: 'Removing keyword suggestion...',
      open: true,
      status: 'loading'
    })
    try {
      await KeywordService.removeKeywordSuggestion(params)
      removeSuggestionFromTopic(params)
      removeToast(toastId)
      addToast({
        id: 'remove-suggestion-success',
        text: 'Keyword suggestion removed',
        open: true,
        status: 'success'
      })
    } catch (e) {
      console.error(e)
      removeToast(toastId)
      const message = 'Failed ot remove keyword suggestion'
      logException(e, { message })
      addToast({
        id: 'remove-suggestion-error',
        text: message,
        open: true,
        status: 'error'
      })
    }
  }

  const moveKeywordToTopic = async (params: {
    keywordHash: string
    fromTopicId: string
    toTopicId: string
  }) => {
    const toastId = 'move-keyword'
    addToast({
      id: toastId,
      text: 'Moving keyword...',
      open: true,
      status: 'loading'
    })

    const { keywordHash, fromTopicId, toTopicId } = params

    try {
      await TopicService.removeKeywordRelation(fromTopicId, keywordHash)

      await TopicService.addKeywordRelation(toTopicId, keywordHash)

      removeSuggestionFromTopic({ topicId: toTopicId, keywordHash })
      removeRelationsFromKeywords({ ids: keywordHash, topicId: fromTopicId })
      removeKeywordTopicFromFeedback(keywordHash, fromTopicId)

      dispatch('update-keywords', {
        topicId: toTopicId,
        keywordHash,
        text: '',
        topicIds: [toTopicId],
        topics: [{ topicId: toTopicId, name: '' }]
      })

      removeToast(toastId)
      addToast({
        id: `${toastId}-success`,
        text: 'Keyword moved successfully.',
        open: true,
        status: 'success'
      })
    } catch (error) {
      removeToast(toastId)
      const message = 'Failed to move keyword.'
      logException(error, { message })
      addToast({
        id: `${toastId}-failed`,
        text: message,
        open: true,
        status: 'error'
      })
    }
  }

  const topics = allThemes.length ? allThemes.flatMap(theme => theme.topics) : []

  function getTopicParent(topicId: string) {
    return allThemes.find(theme => theme.topics.some(topic => topic.topicId === topicId))
  }

  function getTopicGroups(topicId: string) {
    return allThemes.filter(theme => theme.topics.some(topic => topic.topicId === topicId))
  }

  const getTopicById = useCallback(
    function (id: string) {
      return topics.find(topic => topic.topicId === id)
    },
    [topics]
  )

  function selectCategory(category: TopicCategory) {
    setSelectedCategory(category)
    loadThemes(category)
  }

  async function verifyAllTopicAndThemes() {
    let topicsAndThemes = [...productAreaThemes, ...otherThemes]
    if (isTopicsVerified) {
      return topicsAndThemes
    }

    if (allThemes.length === 0) {
      topicsAndThemes = await loadAllThemes()
    }

    setIsTopicsVerified(true)
    return topicsAndThemes
  }

  return {
    isLoading,

    unclassifiedTopics,
    themes: allThemes,
    currentThemes: themes,
    otherThemes,
    productAreaThemes,

    selectedCategory,
    selectCategory,

    loadThemes,
    loadAllThemes,
    refreshThemes,
    refreshAll: refresh,

    addTheme,
    renameTheme,
    removeTheme,
    moveTheme,

    selectTheme,
    unselectTheme,
    getThemeSelectedState,
    expandTheme,
    expandedTheme,

    isUnclassifiedSelected,

    keywordSuggestions,
    loadSuggestionsForTopic,
    addTopicKeywordRelation,
    moveKeywordToTopic,
    removeTopicKeywordSuggestion,
    fetchKeywordsForTopic,

    addTopic,
    renameTopic,
    removeTopic,
    moveTopic,

    topics,
    selectTopic,
    unselectTopic,
    isTopicSelected,
    selectedTopicCount,
    getSelectedTopicCountByTheme,
    getTopicById,
    getTopicParent,
    getTopicGroups,
    verifyAllTopicAndThemes,
    isTopicsVerified,

    classificationLabelsMode
  }
}

export default useClassification
