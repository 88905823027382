import { colors, styled } from '@/theme'
import React, { ReactNode } from 'react'
import {
  ScrollAreaRoot,
  ScrollAreaViewport,
  ScrollAreaScrollbar,
  ScrollAreaThumb
} from '@/components/molecules/scroll-area'
import { CheckedState } from '@radix-ui/react-checkbox'
import { useFiltersStore, useUIStore } from '@/store'
import { KeywordSortType } from '@/types/keywords'
import { shallow } from 'zustand/shallow'
import { CaretDown, CaretUp } from '@phosphor-icons/react'
import { FIRST_CELL_PADDING, GRID_TEMPLATE } from './KeywordsCollapsibleRow.styles'
import Checkbox from '@/components/atoms/checkbox'
import useClassification, { CLASSIFICATION_LABELS } from '@/hooks/useClassification'

const StyledScrollArea = styled(ScrollAreaRoot, {
  overflow: 'hidden',
  pr: '$micro',

  variants: {
    expanded: {
      true: {
        // width: `calc(100% - 180px)`
      },
      false: {
        // width: `calc(100% - 550px)`
      }
    }
  }
})

const TableContainer = styled('div', {
  boxSizing: 'border-box',
  width: '100%',
  maxWidth: '100%',
  overflow: 'hidden',
  mb: 96
})

const TableHeader = styled('header', {
  display: 'grid',
  gridTemplateColumns: GRID_TEMPLATE,
  fontSize: 12,
  fontWeight: 600,
  py: '$micro',
  pr: '$micro',
  borderRadius: '$micro',
  background: '$neutralHighMedium'
})

const TableHeaderCell = styled('div', {
  position: 'relative',
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  padding: '0 $nano',
  gap: '$nano',

  '&:first-of-type': {
    paddingLeft: FIRST_CELL_PADDING
  },

  variants: {
    clickable: {
      true: {
        cursor: 'pointer'
      }
    },

    justifyEnd: {
      true: {
        justifyContent: 'flex-end'
      }
    },
    justifyCenter: {
      true: {
        justifyContent: 'center',
        textAlign: 'center'
      }
    }
  }
})

const NET_SENTIMENT_TYPES = [KeywordSortType.NET_SENTIMENT_DESC, KeywordSortType.NET_SENTIMENT_ASC]

interface KeywordsTableProps {
  onCheckedAllChange: (checked: CheckedState) => void
  checkedAll: CheckedState
  children: ReactNode
}

const KeywordsTable = (props: KeywordsTableProps) => {
  const keywordSortType = useFiltersStore(state => state.keywordSortType, shallow)
  const setKeywordSortType = useFiltersStore(state => state.setKeywordSortType)
  const isExpanded = useUIStore(state => state.classificationCollapsed, shallow)

  const { classificationLabelsMode } = useClassification()

  const labels: Record<CLASSIFICATION_LABELS, { topicGroupsColumn: string; topicsColumn: string }> =
    {
      'topic-group': {
        topicGroupsColumn: 'Topic',
        topicsColumn: 'Keywords'
      },
      'default-topic': {
        topicGroupsColumn: 'Subtopic',
        topicsColumn: 'Topics'
      }
    }

  const onClickCell = (type: 'net-sentiment' | 'frequency') => () => {
    if (type === 'net-sentiment') {
      setKeywordSortType(
        keywordSortType === KeywordSortType.NET_SENTIMENT_DESC
          ? KeywordSortType.NET_SENTIMENT_ASC
          : KeywordSortType.NET_SENTIMENT_DESC
      )
    }

    if (type === 'frequency') {
      setKeywordSortType(KeywordSortType.FREQUENCY)
    }
  }

  return (
    <React.Fragment>
      <TableHeader>
        <TableHeaderCell css={{ ml: 'calc($xs + 5.5px)' }}>
          <Checkbox
            checked={props.checkedAll}
            defaultChecked={false}
            onCheckedChange={props.onCheckedAllChange}
            title="Select all"
            value="keywords-all"
          />
          <span>{labels[classificationLabelsMode].topicsColumn}</span>
        </TableHeaderCell>
        <TableHeaderCell>{labels[classificationLabelsMode].topicGroupsColumn}</TableHeaderCell>
        <TableHeaderCell clickable justifyCenter onClick={onClickCell('net-sentiment')}>
          Net sentiment
          {keywordSortType === KeywordSortType.NET_SENTIMENT_DESC ? (
            <CaretUp
              color={
                NET_SENTIMENT_TYPES.includes(keywordSortType) ? colors.neutralLowLight : colors.grey
              }
              size={14}
              weight={NET_SENTIMENT_TYPES.includes(keywordSortType) ? 'bold' : 'regular'}
            />
          ) : (
            <CaretDown
              color={
                NET_SENTIMENT_TYPES.includes(keywordSortType) ? colors.neutralLowLight : colors.grey
              }
              size={14}
              weight={NET_SENTIMENT_TYPES.includes(keywordSortType) ? 'bold' : 'regular'}
            />
          )}
        </TableHeaderCell>
        <TableHeaderCell clickable onClick={onClickCell('frequency')}>
          Volume
          {keywordSortType === KeywordSortType.FREQUENCY ? (
            <CaretUp color={colors.neutralLowLight} size={14} weight={'bold'} />
          ) : (
            <CaretDown color={colors.grey} size={14} weight={'regular'} />
          )}
        </TableHeaderCell>
      </TableHeader>
      <StyledScrollArea expanded={isExpanded} type="auto">
        <ScrollAreaViewport id="list-root-keyword">
          <TableContainer>{props.children}</TableContainer>
        </ScrollAreaViewport>
        <ScrollAreaScrollbar orientation="vertical">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </StyledScrollArea>
    </React.Fragment>
  )
}

export default KeywordsTable
