import Button from '@/components/atoms/button'
import Text from '@/components/atoms/text'
import { colors } from '@/theme'
import { CaretLeft } from '@phosphor-icons/react'
import { memo, useCallback, useMemo, useState, MouseEvent } from 'react'
import * as S from './MoveTopic.styles'
import useClassification from '@/hooks/useClassification'
import { TopicCategory } from '@/types/classification'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import SelectCategory from './SelectCategory'
import CategoriesSection from './CategoriesSection'
import SelectTheme from './SelectTheme'
import TopicSearch from './TopicSearch'
import useTopicsSearch from '@/hooks/useTopics/useTopicsSearch'
import { ThemeItemData } from '@/types/classification/Theme'
import FlexContainer from '@/components/atoms/flex-container'
import { sortByKeyString } from '@/utils/array'

interface Props {
  mode: 'topic' | 'subtopic'
  defaultSelected: string
  onApply: (selected: string) => Promise<void> | void
  onCancel?: () => void
}

const MoveTopicMenu = ({ mode, defaultSelected, onApply, onCancel }: Props) => {
  const [selected, setSelected] = useState<string>(defaultSelected)
  const [searchQuery, setSearch] = useState('')

  const [currentList, setCurrentList] = useState<'categories' | 'themes' | 'searching'>(
    'categories'
  )
  const [currentCategory, setCurrentCategory] = useState<TopicCategory>('PRODUCT_AREA')

  const categories: { category: TopicCategory; name: string }[] = [
    { category: 'PRODUCT_AREA', name: 'Product areas' },
    { category: 'OTHER', name: 'Other topics' }
  ]
  const currentCategoryName = currentCategory === 'OTHER' ? 'Other topics' : 'Product areas'

  const { productAreaThemes, otherThemes } = useClassification()

  const themes = useMemo(
    () =>
      (currentCategory === 'OTHER' ? otherThemes : productAreaThemes).sort(
        sortByKeyString('themeName')
      ),
    [currentCategory, productAreaThemes, otherThemes]
  )

  const searchResults = useTopicsSearch(searchQuery, themes)
  const filteredSearchResults = useMemo(() => {
    const themeResults = searchResults
      .filter(result => result.type === 'theme' && result.theme)
      .map(result => result.theme) as ThemeItemData[]

    return themeResults.sort(sortByKeyString('themeName'))
  }, [searchResults])

  const handleOnApply = useCallback(() => {
    onApply(selected)
  }, [selected, onApply])

  const onSearch = (value: string) => {
    setSearch(value)
    setCurrentList(value.length ? 'searching' : 'categories')
  }

  const onClickCategory = (category: TopicCategory) => (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setCurrentList('themes')
    setCurrentCategory(category)
    setSearch('')
  }

  const returnToList = () => {
    if (mode === 'topic') return
    if (currentList === 'themes') {
      setCurrentList('categories')
    }
  }

  const handleOnCancel = () => {
    onCancel?.()
  }

  const currentHeading = (() => {
    if (mode === 'topic' || currentList === 'categories') return undefined
    if (currentList === 'searching') return 'Results: '

    return (
      <>
        <CaretLeft color={colors.grey} size={14} weight="bold" />
        <Text color="grey" title={currentCategoryName} truncate typeface="textRegularSmall">
          {currentCategoryName}
        </Text>
      </>
    )
  })()

  return (
    <S.DropdownMenuSubContent sideOffset={10}>
      <S.Header>{mode === 'subtopic' ? 'Move subtopic to' : 'Move topic to'}</S.Header>
      <S.Container>
        {mode === 'subtopic' && <TopicSearch onSearch={onSearch} searchQuery={searchQuery} />}

        <S.Content fixedHeight={searchQuery.length > 0}>
          {currentHeading && (
            <S.SectionTitleHeading>
              <Text
                as="button"
                color="grey"
                css={{
                  cursor: currentList !== 'categories' ? 'pointer' : undefined
                }}
                onClick={returnToList}
                typeface="textRegularSmall"
              >
                {currentHeading}
              </Text>
            </S.SectionTitleHeading>
          )}
          {mode === 'topic' && (
            <SelectCategory
              categories={categories}
              onChangeCategory={setSelected}
              selectedCategory={selected as TopicCategory}
            />
          )}

          {mode === 'subtopic' && (
            <>
              {currentList === 'categories' && (
                <CategoriesSection categories={categories} onClickCategory={onClickCategory} />
              )}

              {currentList === 'themes' && (
                <SelectTheme
                  onChangeTheme={setSelected}
                  selectedTheme={selected}
                  themes={themes}
                  topicCategory={currentCategory}
                />
              )}
            </>
          )}

          {currentList === 'searching' && (
            <>
              {!filteredSearchResults.length && (
                <FlexContainer alignItems="center" justifyContent="center">
                  <Text color="grey" typeface="textRegularXSmall">
                    No results
                  </Text>
                </FlexContainer>
              )}
              {filteredSearchResults.length > 0 && (
                <SelectTheme
                  onChangeTheme={setSelected}
                  selectedTheme={selected}
                  themes={filteredSearchResults}
                  topicCategory="OTHER"
                />
              )}
            </>
          )}
        </S.Content>

        <S.Footer>
          <DropdownMenuPrimitive.Item asChild>
            <Button disabled={selected === defaultSelected} onClick={handleOnApply} small>
              Apply
            </Button>
          </DropdownMenuPrimitive.Item>
          <DropdownMenuPrimitive.Item asChild>
            <Button onClick={handleOnCancel} small text>
              Cancel
            </Button>
          </DropdownMenuPrimitive.Item>
        </S.Footer>
      </S.Container>
    </S.DropdownMenuSubContent>
  )
}

export default memo(MoveTopicMenu)
