import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'

import { useFiltersStore } from '@/store'
import { FilterNumeric } from '@/types/filters/Filters'
import { RATING_KEY } from '@/utils/filters'
import FilterItem, { FilterItemDropdownNumeric } from '../FilterItem'

function AppliedRatingFilters() {
  const apply = useFiltersStore(state => state.apply)
  const numericFilters = useFiltersStore(state => state.numericFilters, shallow)
  const removeNumericFilter = useFiltersStore(state => state.removeNumericFilter)

  const appliedFilters = useMemo(() => {
    return numericFilters.filter(filter => filter.key === RATING_KEY && filter.option !== 'all')
  }, [numericFilters])

  function removeFilter(filter: FilterNumeric) {
    removeNumericFilter(filter.key)
    apply()
  }

  return appliedFilters.map(filter => (
    <FilterItem
      editButton={<FilterItemDropdownNumeric filter={filter} />}
      key={filter.key}
      name="Rating"
      onDelete={() => removeFilter(filter)}
    />
  ))
}

export default AppliedRatingFilters
