import { InterestAreaFilterMode } from '@/hooks/filters/useInterestAreaFilterSpliter'
import i18n from '../../../../plugins/i18n/i18n'

export interface AppliedFiltersDefaultProps {
  splitMode: InterestAreaFilterMode
}

export const defaultAppliedFilterProps: AppliedFiltersDefaultProps = {
  splitMode: i18n.t('all')
}

export const disabledAreaFilterTooltipText = i18n.t(
  'youCantModifyAnAppliedAreaOfInterestFilterButYouCanAddNewFiltersToExploreItFurther'
)
