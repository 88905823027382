import Dialog, { DeleteDialog } from '@/components/atoms/dialog'
import { Intention, Reason } from '@/types/reasons'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import IntentionChip from '@/components/atoms/chip/IntentionChip'
import ReasonsService from '@/services/ReasonsService'
import useToastMessageStore from '@/store/useToastMessageStore'
import { X } from '@phosphor-icons/react'
import { styled } from '@/theme'
import { useState } from 'react'
import Loader from '@/components/atoms/loader/Loader'
import useLogging from '@/hooks/useLogging'
import { useTranslation } from 'react-i18next'

const ReasonTextContainer = styled(FlexContainer, {
  bBottom: '$neutralHighPure',
  '& .delete-text-icon': {
    visibility: 'hidden',
    cursor: 'pointer'
  },

  '&:hover': {
    '& .delete-text-icon': {
      visibility: 'visible'
    }
  }
})

interface ReasonsTextsDialogProps {
  selectedReason: Reason | undefined
  refetchByIntention: (intention: Intention) => Promise<void>
  onClose?: () => void
}

const ReasonsTextsDialog = ({
  selectedReason,
  onClose,
  refetchByIntention
}: ReasonsTextsDialogProps) => {
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { t } = useTranslation()
  const { logException } = useLogging({ context: 'reasons-texts-dialog' })

  const [reasonIndex, setReasonIndex] = useState<number>()
  const [isDeleting, setIsDeleting] = useState(false)

  const onOpenChange = (open: boolean) => {
    if (open) return
    onClose?.()
  }

  const onDeleteReasonText = async () => {
    if (reasonIndex === undefined) return
    if (!selectedReason) return

    const reasonId = selectedReason.reasons.at(reasonIndex)
    if (!reasonId) return

    setIsDeleting(true)
    const removeToast = addLoadingToast({ text: t('deletingReasonFromCluster') })

    const [error] = await ReasonsService.deleteReasonFromCluster({
      cluster_id: selectedReason.clusterId,
      cluster_type: 'reason',
      keyword_hash: reasonId
    })

    if (error) {
      removeToast()
      setIsDeleting(false)
      const message = t('failedToDeleteReason')
      logException(error, { message })
      addErrorToast({ text: `${message} Message: ${error.key}` })
      return
    }

    setTimeout(() => {
      removeToast()
      setIsDeleting(false)
      addSuccessToast({ text: t('reasonDeletedRefetching') })
      refetchByIntention(selectedReason.intention)
      onClose?.()
    })
  }

  if (!selectedReason) return <></>

  return (
    <>
      <Dialog
        align="right"
        closeIcon
        modal
        onOpenChange={onOpenChange}
        open={!!selectedReason}
        useFullHeight
        width={'medium'}
      >
        <FlexContainer
          css={{ padding: '$xxxs', overflow: 'auto' }}
          direction="column"
          fullHeight
          gap="xxxs"
        >
          <Text as="p" fontSize="xxs" fontWeight="semibold">
            {t('findingName')} {selectedReason.name}
          </Text>
          <IntentionChip intention={selectedReason.intention} />
          {isDeleting && (
            <FlexContainer alignItems="center" justifyContent="center">
              <Loader />
            </FlexContainer>
          )}

          {!isDeleting && (
            <Text as="p" fontSize="xxxs" fontWeight="semibold">
              {t('texts')} {`(${selectedReason.reasonsTexts.length || 0})`}:
            </Text>
          )}

          {!isDeleting &&
            selectedReason.reasonsTexts.map((text, index) => (
              <ReasonTextContainer
                alignItems="center"
                gap="micro"
                justifyContent="spaceBetween"
                key={text}
              >
                <Text as="p" fontSize="xxxs" fontWeight="regular" truncate>
                  {text}
                </Text>
                <X className="delete-text-icon" onClick={() => setReasonIndex(index)} size={16} />
              </ReasonTextContainer>
            ))}
        </FlexContainer>
      </Dialog>

      <DeleteDialog
        description={t('thisActionCannotBeUndone')}
        isDeleting={false}
        onConfirmDelete={onDeleteReasonText}
        onOpenChange={open => setReasonIndex(prevIndex => (open ? prevIndex : undefined))}
        open={reasonIndex !== undefined}
      />
    </>
  )
}

export default ReasonsTextsDialog
