import { ExportRequests } from '@/types/export'
import RequestHandler from './RequestHandlers/NewRequest'

export default class ExportService {
  static PREFIX = '/export'

  static async export(payload: ExportRequests.ExportPayload, options?: { signal?: AbortSignal }) {
    return await RequestHandler.post(this.PREFIX, payload, options)
  }

  static async exportWithArea(
    payload: ExportRequests.ExportWithAreaPayload,
    options?: { signal?: AbortSignal }
  ) {
    return await RequestHandler.post(this.PREFIX + '/areas', payload, options)
  }
}
