import { CSS } from '@/theme'
import { InputHTMLAttributes } from 'react'
import { InputContainer, StyledInput } from './styles'

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  css?: CSS
}

const SearchInput = ({ css, ...props }: SearchInputProps) => {
  return (
    <InputContainer css={css}>
      <StyledInput autoFocus placeholder="Search topic here..." {...props} />
    </InputContainer>
  )
}

export default SearchInput
