import { ReactNode } from 'react'
import FlexContainer, { FlexVariants } from '../flex-container'
import Text, { TextProps } from '../text'
import { StyledIndicator, StyledRadio } from './RadioGroup.styles'
import { CSS } from '@/theme'

export interface Props extends FlexVariants {
  text: ReactNode
  value: string
  textProps?: TextProps
  css?: CSS
}
/**
 *  It should be used within a ``RadioGroupPrimitive.Root`` from radix.
 *  It is used to render a radio button.
 *
 *   @see https://www.radix-ui.com/docs/primitives/components/radio-group
 */
const RadioItem = ({ text, value, gap, alignItems, textProps, css, ...props }: Props) => {
  const id = 'r' + value

  return (
    <FlexContainer {...props} alignItems={alignItems || 'baseline'} css={css} gap={gap || 'micro'}>
      <StyledRadio id={id} value={value}>
        <StyledIndicator forceMount />
      </StyledRadio>
      <Text as="label" fontSize="xxxs" {...textProps} htmlFor={id}>
        {text}
      </Text>
    </FlexContainer>
  )
}

export default RadioItem
