import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Input from '@/components/atoms/input'
import { SelecteableCardItem, IconSelectableCard } from '@/components/atoms/selectable-card'
import Text from '@/components/atoms/text'
import { APP_ENVIRONEMNT } from '@/config'
import useSegment from '@/hooks/useSegment'
import { updateClient } from '@/services/Request'
import UserService from '@/services/UserService'
import { useUIStore, useUserStore } from '@/store'
import useSignupStore from '@/store/useSignupStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import { colors } from '@/theme'
import { useAuth0 } from '@auth0/auth0-react'
import {
  ChatCircleDots,
  ChatCircleText,
  ListChecks,
  MegaphoneSimple,
  Newspaper,
  ThumbsUp,
  UserList,
  UsersFour
} from '@phosphor-icons/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { shallow } from 'zustand/shallow'
import { FeedbackTypeGrid } from './Signup.styles'
import { offers } from '@/utils/offers'
import useLogging from '@/hooks/useLogging'
import { useTranslation } from 'react-i18next'

interface Auth0User {
  email: string
}

const OnboardingFeedbackType = () => {
  const { user: auth0User, getAccessTokenSilently } = useAuth0<Auth0User>()
  const { setCurrentUser } = useUserStore()
  const { identify, track } = useSegment()
  const navigate = useNavigate()
  const { logException } = useLogging({ context: 'onboarding-signup' })

  const { t } = useTranslation()
  const setShowOnboardingSuccess = useUIStore(state => state.setShowOnboardingSuccess)
  const setShowFullScreenLoader = useUIStore(state => state.setShowFullScreenLoader)

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { feedbackType, firstName, lastName, useCase, companyName, language, role, offer } =
    useSignupStore(
      state => ({
        feedbackType: state.feedbackType,
        firstName: state.firstName,
        lastName: state.lastName,
        useCase: state.useCase,
        companyName: state.companyName,
        language: state.language,
        role: state.role,
        offer: state.offer
      }),
      shallow
    )

  const toggleFeedbackType = useSignupStore(state => state.toggleFeedbackType)

  const feedbackTypes: SelecteableCardItem[] = [
    {
      icon: <ListChecks />,
      iconColor: colors.feedbackPositivePure,
      text: t('survey')
    },
    {
      icon: <MegaphoneSimple />,
      iconColor: colors.feedbackInformativePure,
      text: t('support')
    },
    {
      icon: <UserList />,
      iconColor: colors.feedbackNegativePure,
      text: t('interviews')
    },
    {
      icon: <ChatCircleText />,
      iconColor: colors.feedbackWarningPure,
      text: 'NPS'
    },
    {
      icon: <Newspaper />,
      iconColor: colors.neutralLowPure,
      text: t('reviews')
    },
    {
      icon: <UsersFour />,
      iconColor: colors.brandPrimaryPure,
      text: 'CRM'
    },
    {
      icon: <ThumbsUp />,
      iconColor: colors.feedbackInformativePure,
      text: t('social')
    },
    {
      icon: <ChatCircleDots />,
      iconColor: colors.feedbackNegativePure,
      text: t('others')
    }
  ]

  const isInputActive = feedbackType.includes('Others')
  const [feedbackTypeInput, setFeedbackTypeInput] = useState('')

  const isDoneDisabled = feedbackType.length === 0

  async function onDoneClick() {
    if (!auth0User) {
      return
    }

    const hasCustomFeedbackType = isInputActive && feedbackTypeInput.length > 0
    const actualFeedbackTypes = hasCustomFeedbackType
      ? [...feedbackType.filter(type => type !== 'Others'), `Others: ${feedbackTypeInput}`]
      : feedbackType

    try {
      setShowFullScreenLoader(true)
      const accessToken = await getAccessTokenSilently({ detailedResponse: true })
      const user = await UserService.userOnboarding(
        {
          username: `${firstName} ${lastName}`,
          email: auth0User.email,
          organization_name: companyName,
          organization_target_language: language,
          use_case: useCase?.toLowerCase(),
          function: role?.toLowerCase(),
          feedback_types: actualFeedbackTypes.map(type => type.toLowerCase()),
          access_token: accessToken.access_token,
          plan_id: offer ?? offers.core
        },
        accessToken.access_token
      )
      const userAccess = await UserService.token(accessToken)

      localStorage.setItem('access_token', userAccess.token)
      setCurrentUser(userAccess)
      updateClient()

      if (APP_ENVIRONEMNT === 'production') {
        window.Intercom('boot', {
          api_base: 'https://api-iam.intercom.io',
          app_id: 'itcxgqp7',
          user_id: user.user_id,
          name: user.name,
          email: user.email
        })
      }

      identify(userAccess)

      track('onboarding_user_signup', {
        signup_date: new Date(Date.now()).toISOString(),
        user_id: user.user_id,
        email: user.email,
        name: user.name,
        user_role: user.function,
        user_case: user.use_case,
        feedback_type: user.feedback_types,
        organization_id: user.organization_id,
        company_name: companyName
      })

      navigate('/settings/integrations')
      setShowFullScreenLoader(false)
      setShowOnboardingSuccess(true)
    } catch (e) {
      console.error(e)
      const message = t('failedToFinishPleaseRefreshThePageAndTryAgain')
      logException(e, { message })
      addErrorToast({ text: message })
    }
  }

  return (
    <FlexContainer css={{ width: '100%', paddingRight: 100 }} direction="column" gap="xs">
      <div>
        <Text as="h1" typeface="titleRegularSmall">
          {t('andWhatFeedbackTypesDoYouWantToAnalyzeFirst')}
        </Text>
        <Text as="p" typeface="paragraphRegularMicro">
          {t('youCanChooseMoreThanOneChoicesHereWontLimitYourUsage')}
        </Text>
      </div>
      <FeedbackTypeGrid>
        {feedbackTypes.map((item, index) => (
          <IconSelectableCard
            {...item}
            format="square"
            key={index}
            onClick={() => toggleFeedbackType(item.text)}
            selected={feedbackType.includes(item.text)}
          />
        ))}
      </FeedbackTypeGrid>

      {isInputActive && (
        <Input
          css={{ width: 472 }}
          onChange={e => setFeedbackTypeInput(e.currentTarget.value)}
          placeholder={t('whatIsTheOtherFeedbackTypeOptional')}
          small
          value={feedbackTypeInput}
        />
      )}

      <Button disabled={isDoneDisabled} onClick={onDoneClick} variant="exception">
        {t('done')}
      </Button>
    </FlexContainer>
  )
}

export default OnboardingFeedbackType
