import { styled } from '@/theme'
import { ScrollAreaRoot } from '../scroll-area'

export const FeedbackDetailItem = styled('div', {
  '& > p': {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word'
  }
})

export const FeedbackDetailTitle = styled('p', {
  fontSize: '$xxxs',
  color: '$grey',
  fontWeight: '$regular'
})

export const DetailsScrollArea = styled(ScrollAreaRoot, {
  overflow: 'hidden',
  height: 'fit-content',
  px: '$xs',
  py: '$xxxs'
})

export const FeedbackDetailsHeader = styled('header', {
  px: '$xs',
  py: '$xxxs',
  backgroundColor: '$neutralHighMedium',
  display: 'flex',
  alignItems: 'center',
  gap: '$micro',
  br: 6,
  '& svg': {
    cursor: 'pointer'
  }
})

export const FeedbackDetailInnerTitle = styled('p', {
  fontSize: '$xxxs',
  color: '$neutralLowPure',
  fontWeight: '$regular',
  lineHeight: '$md'
})
