import { ToastStatus } from '@/types/toast'
import { create } from 'zustand'

type ToastParams = {
  id?: string
  duration?: number
  actionText?: string
  onClose?: () => void
  onClickAction?: () => void
  text?: string
  children?: React.ReactNode
} & ({ text: string } | { children: React.ReactNode })

export type ToastMessage = {
  status?: ToastStatus
  open?: boolean
} & ToastParams

interface ToastsState {
  toasts: ToastMessage[]

  addToast: (toast: ToastMessage) => void
  addLoadingToast: (params: ToastParams) => () => void
  addSuccessToast: (params: ToastParams) => void
  addErrorToast: (params: ToastParams) => void
  addWarningToast: (params: ToastParams) => void
  removeToast: (id: string) => void
  onOpenChange: (open: boolean, id: string) => void
  getToastById: (id: string) => ToastMessage | undefined

  safeAreaOn: boolean
  setSafeAreaOn: (safeSpaceOn: boolean) => void
}

const useToastMessageStore = create<ToastsState>((set, get) => ({
  toasts: [],

  addToast: toast =>
    set(state => {
      let duration = toast.duration
      if (toast.status === 'loading') {
        duration = Number.POSITIVE_INFINITY
      }

      const newToast = {
        ...toast,
        id: toast.id || `id-${state.toasts.length + 1}-${toast.status}-${toast.text}`,
        open: toast.open || true,
        duration
      }

      return {
        toasts: [...state.toasts, newToast]
      }
    }),

  addLoadingToast: params => {
    const { toasts, removeToast, addToast } = get()
    const toast = {
      ...params,
      status: 'loading',
      id: params?.id || `loading-id-${toasts.length + 1}-${params.text}`
    } as ToastMessage

    addToast(toast)

    return () => removeToast(toast.id as string)
  },
  addSuccessToast: (params: ToastParams) => {
    const { addToast } = get()
    const toast = {
      ...params,
      status: 'success'
    } as ToastMessage

    addToast(toast)
  },

  addErrorToast: (params: ToastParams) => {
    const { addToast } = get()
    const toast = {
      ...params,
      status: 'error'
    } as ToastMessage

    addToast(toast)
  },

  addWarningToast: (params: ToastParams) => {
    const { addToast } = get()
    const toast = {
      ...params,
      status: 'warning'
    } as ToastMessage

    addToast(toast)
  },

  removeToast: id => {
    const { onOpenChange } = get()
    onOpenChange(false, id)
    set(state => ({
      toasts: state.toasts.filter(toast => toast.id !== id)
    }))
  },
  onOpenChange: (open, id) => {
    const { toasts } = get()
    const newToasts = toasts.map(toast => (toast.id === id ? { ...toast, open } : toast))

    set(() => ({ toasts: newToasts }))
  },
  getToastById: id => {
    const { toasts } = get()
    return toasts.find(toast => toast.id === id)
  },

  safeAreaOn: false,
  setSafeAreaOn: safeAreaOn => set(() => ({ safeAreaOn }))
}))

export default useToastMessageStore
