import FlexContainer from '@/components/atoms/flex-container'
import { GoBack } from './ManageCustomers.styles'
import { CaretLeft } from '@phosphor-icons/react'
import { ReactNode } from 'react'
import { CSS } from '@/theme'

interface Props {
  title: string
  rightContent?: ReactNode
  className?: string
  css?: CSS
}

const ManageCustomersHeader = ({ rightContent, title, ...props }: Props) => {
  return (
    <FlexContainer
      alignSelf={!rightContent ? 'start' : 'stretch'}
      justifyContent="spaceBetween"
      {...props}
    >
      <FlexContainer>
        <GoBack to="..">
          <CaretLeft size={16} weight="bold" />
          {title}
        </GoBack>
      </FlexContainer>
      {rightContent}
    </FlexContainer>
  )
}

export default ManageCustomersHeader
