import DateFilter from '@/components/molecules/filters/DateFilter'
import useLetterCase from '@/hooks/useLetterCase'
import usePeriods from '@/hooks/usePeriods'
import { useFiltersStore } from '@/store'
import { FilterDatetime, FilterDatetimeValue } from '@/types/filters/Filters'
import { Period } from '@/types/periods'
import { getFormattedDateRange } from '@/utils/date'
import FilterItemDropdown from './FilterItemDropdown'

interface FilterItemDropdownDatetimeProps {
  filter: FilterDatetime
}

function FilterItemDropdownDatetime(props: FilterItemDropdownDatetimeProps) {
  const apply = useFiltersStore(state => state.apply)
  const setDatetimeFilter = useFiltersStore(state => state.setDatetimeFilter)

  const { snakeToTitle } = useLetterCase()
  const { getPeriod } = usePeriods()

  function setDateRange(range: FilterDatetimeValue, period?: Period) {
    setDatetimeFilter({
      key: props.filter.key,
      name: props.filter.name,
      type: props.filter.type,
      value: range,
      period: period ?? 'custom'
    })
  }

  function getTooltipText(filter: FilterDatetime) {
    const formattedDateRange = filter.value ? getFormattedDateRange(filter.value) : 'All time'
    const text = filter.period === 'custom' ? formattedDateRange : getPeriod(filter.period).name

    return `Is <b>${snakeToTitle(filter.name)}</b> > <b>${text}</b>`
  }

  const hasChanges = JSON.stringify(props.filter.value) !== JSON.stringify(props.filter.draftValue)

  return (
    <FilterItemDropdown
      content={1}
      contentType="count"
      disabled={!hasChanges}
      dropdown={
        <DateFilter
          appliedPeriod={props.filter.draftPeriod}
          appliedRange={props.filter.draftValue}
          setDateRange={setDateRange}
        />
      }
      onApply={apply}
      title={snakeToTitle(props.filter.name)}
      tooltip={getTooltipText(props.filter)}
    />
  )
}

export default FilterItemDropdownDatetime
