import axios, { AxiosError } from 'axios'

export function isApiError(response: unknown): response is AxiosError<RequestError> {
  return !!(response as AxiosError<RequestError>).response?.data?.key
}

export function isApiGatewayError(
  response: unknown
): response is AxiosError<GatewayAPIRequestError> {
  return !!(response as AxiosError<GatewayAPIRequestError>).response?.data?.error?.message
}

class DefaultErrorHandler {
  isApiRequestError = false
  isApiResponseError = false
  isCanceledError = false
  isError = false
  code: string | undefined
  statusCode: number | undefined
  key = ''
  message = 'Unknown error'
  cause: unknown
  errorInstance: unknown

  constructor(error: unknown) {
    this.errorInstance = error

    if (error instanceof Error) {
      this.isError = true
      this.message = error.message
      this.cause = error.cause
    }

    if (axios.isAxiosError(error)) {
      this.isError = true
      this.isApiRequestError = !!error.request
      this.isApiResponseError = !!error.response
      this.code = error.code
      this.message =
        typeof error.response?.data?.message === 'string'
          ? error.response?.data?.message
          : 'Unknown server message'

      // Canceled
      if (error.code === 'ERR_CANCELED') {
        this.isCanceledError = true
        return
      }

      if (isApiError(error)) {
        const { response } = error as AxiosError<RequestError>
        if (response) {
          this.statusCode = response.status

          if (response.data.message) {
            this.message = response.data.message
            this.key = response.data?.key
          } else if (response.data.details) {
            this.message = response.data.details.message
            this.key = response.data.details.code
          }
        }
      } else if (isApiGatewayError(error)) {
        const { response } = error as AxiosError<GatewayAPIRequestError>
        if (response) {
          this.message = response.data?.error?.message || 'Unknown server message'
          this.key = `${response.status}`
          this.statusCode = response.status
        }
      } else if (error.response) {
        this.key = error.code || ''
        this.statusCode = error.response.status
      }
    }

    !this.isCanceledError && console.error(error)
  }
}

export default DefaultErrorHandler
