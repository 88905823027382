import { SelectButton, SelectOption } from '@/components/atoms/select'
import { useFeedbacks } from '@/hooks/feedback/useFeedbacks'
import useSegment from '@/hooks/useSegment'
import { useFiltersStore, useUIStore } from '@/store'
import useToastMessageStore from '@/store/useToastMessageStore'
import {
  CalendarCheck,
  ChartLineUp,
  Export,
  SortAscending,
  SortDescending
} from '@phosphor-icons/react'
import { useMemo, useState } from 'react'
import FlexContainer from '@/components/atoms/flex-container'
import Button from '@/components/atoms/button'
import ResultsCounter from '../ResultsCounter'
import Tooltip from '@/components/atoms/tooltip'
import useLogging from '@/hooks/useLogging'

const mapSortText: Record<SortDirection, string> = {
  asc: 'Sort to ascending order',
  desc: 'Sort to descending order'
}

interface Props {
  withResultCounter?: boolean
  showSortBy?: boolean
}

function FeedbackOptions({ withResultCounter = true, showSortBy = true }: Props) {
  const { track } = useSegment()
  const { logException } = useLogging({ context: 'feedback-options' })

  const [isExportLoading, setIsExportLoading] = useState(false)

  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addWarningToast = useToastMessageStore(state => state.addWarningToast)

  // const feedbackViewMode = usePersistedFeedbackStore(state => state.feedbackViewMode)
  // const toggleFeedbackViewMode = usePersistedFeedbackStore(state => state.toggleFeedbackViewMode)
  const { exportFeedbacks } = useFeedbacks()

  const isAssistantOpen = useUIStore(state => state.isAssistantOpen)

  const onExport = async () => {
    if (isExportLoading) {
      return
    }
    setIsExportLoading(true)

    const removeToast = addLoadingToast({ text: 'Export request in progress...' })

    const [error] = await exportFeedbacks()
    if (error) {
      removeToast()
      const message = 'Failed to request export.'
      logException(error, { message })
      addErrorToast({ text: `${message} Message: ${error.key}` })
      setIsExportLoading(false)

      return
    }

    removeToast()
    setIsExportLoading(false)
    track('explore_user_feedback_tab-export_data')
    addWarningToast({
      text: 'The export is in progress. The file will be emailed to you in a few minutes.',
      duration: 5000
    })
  }

  const isExportDisabled = isExportLoading

  const { setSortDirection, sortDirection, orderBy, setOrderBy, hasSearch } = useFiltersStore(
    state => ({
      setSortDirection: state.setSortDirection,
      sortDirection: state.sortDirection,
      orderBy: state.orderBy,
      setOrderBy: state.setOrderBy,
      hasSearch: state.search.length > 0
    })
  )
  const { reset: resetFeedbacks } = useFeedbacks()
  const { reset: resetArchivedFeedbacks } = useFeedbacks({ archived: true })

  const handleSortChange = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    resetFeedbacks()
    resetArchivedFeedbacks()
  }

  const handleFeedbackOrderByChange = (value: string) => {
    setOrderBy(value as OrderBy)
    resetFeedbacks()
    resetArchivedFeedbacks()
  }

  const feedbackSortOptions: SelectOption[] = useMemo(
    () => [
      {
        text: 'Sort by date',
        value: 'posted_at',
        icon: <CalendarCheck size={24} />
      },
      {
        text: 'Sort by relevance',
        value: 'relevance',
        icon: <ChartLineUp size={24} />
      }
    ],
    []
  )

  return (
    <>
      {isAssistantOpen ? <div /> : withResultCounter && <ResultsCounter />}

      <FlexContainer alignItems="center" gap={'xxxs'}>
        {hasSearch && showSortBy && (
          <SelectButton
            big
            label="Select order by"
            onValueChange={handleFeedbackOrderByChange}
            options={feedbackSortOptions}
            title={hasSearch ? undefined : 'Use search to enable order by'}
            value={orderBy}
          />
        )}
        {/* <IconButton
        data-mode={feedbackViewMode}
        onClick={toggleFeedbackViewMode}
        title="Toggle topic view"
      >
        <SmileyWink weight="regular" />
      </IconButton> */}
        <Tooltip side="bottom" text="Export" variant="small">
          <Button
            disabled={isExportDisabled}
            onClick={onExport}
            size="square"
            variant="flat-bordered"
          >
            <Export size={24} weight="regular" />
          </Button>
        </Tooltip>
        <Tooltip side="bottom" text={mapSortText[sortDirection]} variant="small">
          <Button onClick={handleSortChange} size="square" variant="flat-bordered">
            {sortDirection === 'asc' ? (
              <SortAscending size={24} weight="regular" />
            ) : (
              <SortDescending size={24} weight="regular" />
            )}
          </Button>
        </Tooltip>
      </FlexContainer>
    </>
  )
}

export default FeedbackOptions
