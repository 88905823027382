import { useEffect } from 'react'

interface Params {
  preventPageUnload: boolean
}

const useUnsavedChangesWarning = ({ preventPageUnload }: Params) => {
  useEffect(() => {
    // Detecting browser closing
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (preventPageUnload) {
        e.preventDefault()
        e.returnValue = '' // required for chrome
        return true // Return a truthy value
      }
      return null // Allow navigation if no conditions met
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [preventPageUnload])
}

export default useUnsavedChangesWarning
