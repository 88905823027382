import FlexContainer from '@/components/atoms/flex-container'
import { savedViewBarHeight, styled, topBarHeight } from '@/theme'

export const DiscoveryGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(420px, 1fr))',
  minHeight: `calc(100vh - ${savedViewBarHeight + topBarHeight}px)`
})

export const DiscoveryContainer = styled(FlexContainer, {
  padding: '$sm',
  alignItems: 'center',
  justifyContent: 'center',

  bTop: '$neutralHighPure',
  mt: '-1px',

  bRight: '$neutralHighPure',
  mr: '-1px'
})

export const Content = styled(FlexContainer, {
  br: 8,
  height: '100%',
  width: '100%'
})

export const List = styled(FlexContainer, {
  py: '$xxs',
  px: '$xxs',
  br: 8,

  variants: {
    isMarkdown: {
      true: {
        '& ol, & ul': {
          ml: '$xs'
        },
        '& li': {
          lineHeight: '$xl',
          fontSize: '$xxxs'
        },
        '& li::marker': {
          fontSize: '$xxxs',
          fontWeight: '$regular',
          fontFamily: '$default'
        },

        '& p': {
          lineHeight: '$lg',
          fontSize: '$xxxs'
        },

        '& table': {
          bRight: '$neutralLowLight',
          bLeft: '$neutralLowLight',
          br: '$micro',
          borderCollapse: 'collapse'
        },

        '& th, & td': {
          textAlign: 'left',
          py: '$nano',
          px: '$micro',
          bAll: '$neutralLowLight',
          lineHeight: '$lg',
          fontSize: '$xxxs'
        },

        '& th': {
          textAlign: 'center'
        }
      }
    }
  }
})

export const ListSeparetor = styled('div', {
  height: 1,
  backgroundColor: '$neutralHighPure',

  '&:last-of-type': {
    mb: '$micro'
  }
})
