import { memo, useCallback } from 'react'
import TopicChip from '@/components/atoms/topic-chip/TopicChip'
import { Keyword, KeywordTopic, KeywordWithTopicIds } from '@/types/keywords'
import { KeywordTopicsContainer, TopicsCounter } from './KeywordsCollapsibleRow.styles'
import { Plus } from '@phosphor-icons/react'
import { colors } from '@/theme'
import KeywordEditorPopover from '../../keyword-editor/KeywordEditorPopover'
import useKeywordsRelations from '@/hooks/useKeywords/useKeywordsRelations'
import useToastMessageStore from '@/store/useToastMessageStore'
import useClassification, { REFRESH_THEMES_MAX_DELAY } from '@/hooks/useClassification'
import { OnApplyCallback } from '../../keyword-editor/KeywordEditor'
import useUser from '@/hooks/useUser'
import useLogging from '@/hooks/useLogging'

interface KeywordTopicsProps {
  name: string
  id: string
  topics: KeywordTopic[]
  isCluster?: boolean
  clusterKeywords?: Keyword[]
  onDeleteRelation?: (topicId: string, keywordId: string) => void
}

const KeywordTopics = ({
  topics,
  isCluster,
  name,
  id,
  clusterKeywords,
  onDeleteRelation
}: KeywordTopicsProps) => {
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const { logException } = useLogging({ context: 'keywords-topics' })

  const { userPermissions } = useUser()
  const isManager = userPermissions.topic.includes('manager')

  const { applyTopicsToKeywords } = useKeywordsRelations()

  const { refreshThemes } = useClassification()

  const onApplyAddToTopic = useCallback<OnApplyCallback>(
    async (selectedTopics, removedTopics) => {
      const removeToast = addLoadingToast({
        text: 'Applying changes to keywords relations...'
      })
      try {
        const keywords: KeywordWithTopicIds[] =
          isCluster && clusterKeywords
            ? clusterKeywords.map(clusterKeyword => ({
                keywordHash: clusterKeyword.keywordHash,
                text: clusterKeyword.text,
                topicIds: clusterKeyword.topicIds,
                topics: clusterKeyword.topics
              }))
            : [
                {
                  keywordHash: id,
                  text: name,
                  topicIds: topics.map(topic => topic.topicId),
                  topics
                }
              ]

        await applyTopicsToKeywords({ keywords, selectedTopics, removedTopics })

        removeToast()

        addSuccessToast({
          text: 'All changes applied.'
        })

        setTimeout(() => {
          refreshThemes()
        }, REFRESH_THEMES_MAX_DELAY)

        return 'success'
      } catch (error) {
        console.error(error)
        removeToast()
        const message = 'Failed to apply all changes.'
        logException(error, { message })
        addErrorToast({ text: message })

        return 'error'
      }
    },
    [
      id,
      isCluster,
      clusterKeywords,
      applyTopicsToKeywords,
      refreshThemes,
      addErrorToast,
      addSuccessToast,
      addLoadingToast,
      logException,
      name,
      topics
    ]
  )

  return (
    <KeywordTopicsContainer wrap="wrap">
      {topics?.map(topic => (
        <KeywordEditorPopover
          appliedTopics={topics || []}
          defaultOpen={false}
          key={topic.topicId}
          onApply={onApplyAddToTopic}
          singleKeyword={!isCluster}
        >
          <TopicChip
            dismissable={!isCluster && isManager}
            ghost
            label={topic.name}
            onClick={() => onDeleteRelation?.(topic.topicId, id)}
          />
        </KeywordEditorPopover>
      ))}
      <KeywordEditorPopover
        appliedTopics={topics || []}
        defaultOpen={false}
        onApply={onApplyAddToTopic}
        singleKeyword={!isCluster}
      >
        <TopicsCounter className="plus-container">
          <Plus color={colors.pajarito} size={12} weight="bold" />
        </TopicsCounter>
      </KeywordEditorPopover>
    </KeywordTopicsContainer>
  )
}

export default memo(KeywordTopics)
