import Select, { SelectOption } from '@/components/atoms/select'
import { FilterComponentProps } from './types'
import { ChipMultiSelect, ChipMultiSelectItem } from '@/components/molecules/chip-multi-select'
import Bone from '@/components/atoms/bone'
import { EnumFilterOption } from '@/types/filters/AdvancedFilters'
import i18n from '../../../../../plugins/i18n/i18n'

const selectOptions: SelectOption<EnumFilterOption>[] = [
  {
    value: 'matches',
    text: i18n.t('matches')
  },
  {
    value: 'does_not_match',
    text: i18n.t('doesNotMatch')
  }
]

interface Props extends FilterComponentProps {
  options: ChipMultiSelectItem[]
  isLoading?: boolean
}

const EnumFilterRow = ({ filter, onFilterChange, options, isLoading }: Props) => {
  const onOptionChange = (v: EnumFilterOption) => {
    onFilterChange({ ...filter, option: v })
  }
  const selectedOption = filter.option

  const onValueChange = (v: string[]) => {
    onFilterChange({ ...filter, value: v })
  }

  const value = typeof filter.value === 'string' ? [filter.value] : (filter.value as string[])

  return (
    <>
      <Select
        onValueChange={onOptionChange}
        options={selectOptions}
        small
        value={selectedOption}
        width="100%"
      />
      {isLoading ? (
        <Bone css={{ width: '100%', height: 32 }} />
      ) : (
        <ChipMultiSelect checked={value} items={options} onChange={onValueChange} />
      )}
    </>
  )
}

export default EnumFilterRow
