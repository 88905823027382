import useEvents from '@/hooks/useEvents'
import usePeriods from '@/hooks/usePeriods'
import { useFiltersStore } from '@/store'
import { getFormattedDateRange } from '@/utils/date'
import FilterItem, { FilterItemButton } from '../FilterItem'
import { useTranslation } from 'react-i18next'

function AppliedDateFilters() {
  const datePeriod = useFiltersStore(state => state.datePeriod)
  const dateRange = useFiltersStore(state => state.dateRange)
  const setDateRange = useFiltersStore(state => state.setDateRange)

  const { dispatch } = useEvents()
  const { getPeriod } = usePeriods()
  const { t } = useTranslation()

  const formattedDateRange = dateRange ? getFormattedDateRange(dateRange) : 'All time'
  const text = datePeriod === 'custom' ? formattedDateRange : getPeriod(datePeriod).name

  function removeFilter() {
    setDateRange(null, 'allTime')
  }

  function onClick() {
    dispatch('open-date-filter', {})
  }

  return [
    dateRange ? (
      <FilterItem
        editButton={
          <FilterItemButton
            content={text}
            contentType="label"
            onClick={onClick}
            tooltip={formattedDateRange}
          />
        }
        name={t('date')}
        onDelete={removeFilter}
        key="date"
      />
    ) : null
  ].filter(Boolean) as JSX.Element[]
}

export default AppliedDateFilters
