import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface UIState {
  pageTitle: string
  setPageTitle: (title: string) => void

  classificationCollapsed: boolean
  toggleClassificationCollapsed: () => void
  openClassification: () => void
  closeClassification: () => void

  showFullScreenLoader: boolean
  fullScreenMessage: string | undefined
  setShowFullScreenLoader: (show: boolean, message?: string) => void

  showOnboardingSuccess: boolean
  setShowOnboardingSuccess: (show: boolean) => void

  isFeedFiltersOpen: boolean
  setIsFeedFiltersOpen: (show: boolean) => void

  isFiltersOpen: boolean
  toggleFiltersOpen: () => void
  openFilters: () => void
  closeFilters: () => void

  isAdvancedFiltersOpen: boolean
  toggleAdvancedFiltersOpen: () => void
  openAdvancedFilters: () => void
  closeAdvancedFilters: () => void

  isAssistantOpen: boolean
  toggleAssistantOpen: () => void
  openAssistant: () => void
  closeAssistant: () => void
  setAssistantOpen: (open: boolean) => void

  descriptionEditOpen: boolean
  setDescriptionEditOpen: (open: boolean) => void

  isCreateModalOpen: boolean
  setIsCreateModalOpen: (isCreateModalOpen: boolean) => void
}

const useUIStore = createWithEqualityFn<UIState>(
  set => ({
    pageTitle: '',
    setPageTitle: title => set(() => ({ pageTitle: title })),

    classificationCollapsed: false,
    toggleClassificationCollapsed: () =>
      set(state => ({ classificationCollapsed: !state.classificationCollapsed })),
    openClassification: () => set(() => ({ classificationCollapsed: false })),
    closeClassification: () => set(() => ({ classificationCollapsed: true })),

    showFullScreenLoader: false,
    fullScreenMessage: undefined,
    setShowFullScreenLoader: (show, message) =>
      set(() => ({
        showFullScreenLoader: show,
        fullScreenMessage: show ? message : undefined
      })),

    showOnboardingSuccess: false,
    setShowOnboardingSuccess: show => set(() => ({ showOnboardingSuccess: show })),

    isFeedFiltersOpen: false,
    setIsFeedFiltersOpen: isFeedFiltersOpen => set(() => ({ isFeedFiltersOpen })),

    isFiltersOpen: false,
    toggleFiltersOpen: () => set(state => ({ isFiltersOpen: !state.isFiltersOpen })),
    openFilters: () => set(() => ({ isFiltersOpen: true })),
    closeFilters: () => set(() => ({ isFiltersOpen: false })),

    isAdvancedFiltersOpen: false,
    toggleAdvancedFiltersOpen: () =>
      set(state => ({ isAdvancedFiltersOpen: !state.isAdvancedFiltersOpen })),
    openAdvancedFilters: () => set(() => ({ isAdvancedFiltersOpen: true })),
    closeAdvancedFilters: () => set(() => ({ isAdvancedFiltersOpen: false })),

    isAssistantOpen: false,
    toggleAssistantOpen: () => set(state => ({ isAssistantOpen: !state.isAssistantOpen })),
    openAssistant: () => set(() => ({ isAssistantOpen: true })),
    closeAssistant: () => set(() => ({ isAssistantOpen: false })),
    setAssistantOpen: open => set(() => ({ isAssistantOpen: open })),

    descriptionEditOpen: false,
    setDescriptionEditOpen: open => set(() => ({ descriptionEditOpen: open })),

    isCreateModalOpen: false,
    setIsCreateModalOpen: isCreateModalOpen => set({ isCreateModalOpen })
  }),
  shallow
)

export default useUIStore
