import { RequestReturnType } from '@/services/RequestHandlers/NewRequest'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { MetricsRequests, RawMetric } from '@/types/metrics'
import { SegmentationItemWithMetrics } from '@/types/segmentation/Segmentation'
import { fetchMetricsListInChunks } from '../metrics/metricsQueryUtils'
import { FilterNode } from '@/types/filters/AdvancedFilters'
import getSegmentationFiltersCombinedFn from './getSegmentationFiltersCombinedFn'

interface Params {
  segmentations: SegmentationItemWithMetrics[]
  metricList: MetricsRequests.MetricListPayloadItem[]
  startDate?: string
  endDate?: string
  currentFilters?: FilterNode
  opportunityId?: string
  onProgress?: (chunkLength: number, completed: number) => void
}

const getMetricsForSegmentationsFn = async ({
  segmentations,
  metricList,
  startDate,
  endDate,
  currentFilters,
  opportunityId,
  onProgress
}: Params): Promise<RequestReturnType<SegmentationItemWithMetrics[]>> => {
  let filterList: FeedbackListQueryParams[]

  if (currentFilters) {
    const contextsData = await getSegmentationFiltersCombinedFn({
      segmentations,
      currentFilters
    })

    filterList = contextsData.map(item => ({
      context: item.context,
      opportunity_id: opportunityId
    }))
  } else {
    filterList = segmentations.map(seg => ({
      context: seg.context,
      opportunity_id: opportunityId
    }))
  }

  const responses = await fetchMetricsListInChunks({
    filterList,
    metricList,
    startDate,
    endDate,
    onProgress
  })
  const someResultWithError = responses.find(response => response[0])
  if (someResultWithError && someResultWithError[0]) {
    return [someResultWithError[0], undefined]
  }

  const segmentationsMetrics = responses.flatMap(response => response[1]) as RawMetric[][]

  const newSegmentations = segmentations.map((item, index) => {
    return {
      ...item,
      metrics: segmentationsMetrics[index]
    }
  }) as SegmentationItemWithMetrics[]

  return [undefined, newSegmentations]
}

export default getMetricsForSegmentationsFn
