import { ReactNode, useMemo } from 'react'
import { TrendingTopicItemData } from '@/types/analytics/Analytics'
import { formatPercentageWithSignal } from '@/utils/numbers'
import { TrendingTopicType } from '@/types/analytics/TrendingTopic'
import useComparisonText from '@/hooks/useComparisonText'
import { useFiltersStore } from '@/store'
import { shallow } from 'zustand/shallow'
import GenericTooltip from '../indicators/GenericTooltiop'

interface Props {
  topic: TrendingTopicItemData
  children: ReactNode
  open?: boolean
  onOpenChange?: (open: boolean) => void
  type?: TrendingTopicType
}

const TrendingTopicTooltip = ({ topic, children, open, onOpenChange, type }: Props) => {
  const dateRange = useFiltersStore(state => state.dateRange, shallow)

  const formattedValue = formatPercentageWithSignal(topic.variation)

  const intention = useMemo(() => {
    if (type === 'topIssues') return 'issues'
    if (type === 'topRequests') return 'requests'
    return 'feedback'
  }, [type])

  const { text: comparisonText } = useComparisonText()

  const firstLine = `${topic.current} (${topic.currentPercentageIntention}% of all ${intention})`
  const secondLine = `${formattedValue} ${comparisonText} - ${topic.previous} (
    ${topic.previousPercentageIntention}%)`

  const bulletPoints = [firstLine]
  if (dateRange) bulletPoints.push(secondLine)

  return (
    <GenericTooltip
      bulletPoints={bulletPoints}
      onOpenChange={onOpenChange}
      open={open}
      title={topic.name}
    >
      {children}
    </GenericTooltip>
  )
}

export default TrendingTopicTooltip
