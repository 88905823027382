import { useEffect } from 'react'
import useSourcesQuery from '../useSourcesQuery'
import useAllowedMetrics from './useAllowedMetrics'

const useConfigureAllowedMetrics = () => {
  const { values: sourceAliasValues, isFetched } = useSourcesQuery({
    enabled: true,
    sourceMode: 'sourceAlias'
  })

  const { configureAllowedMetricsForUnknownSources } = useAllowedMetrics()

  useEffect(() => {
    if (sourceAliasValues.length && isFetched) {
      configureAllowedMetricsForUnknownSources(sourceAliasValues)
    }
  }, [sourceAliasValues, isFetched, configureAllowedMetricsForUnknownSources])
}

export default useConfigureAllowedMetrics
