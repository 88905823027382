import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { useTranslation } from 'react-i18next'
import { AssociatedOpportunitiesGrid } from './styles'
import AssociatedOpportunity from './AssociatedOpportunity'
import { useMemo, useState } from 'react'
import useInitiativeStore from '@/store/initiatives/useInitiativeStore'
import AssociateOppToInitiativeDialog from './modals/AssociateOppToInitiativeDialog'
import { useInitiatives } from '@/hooks/initiatives/useInitiatives'
import useBasicOpportunitiesQuery from '@/hooks/opportunity/useBasicOpportunitiesQuery'
import useSegment from '@/hooks/useSegment'
import AssociatedOpportunityEmpty from './AssociatedOpportunityEmpty'

const AssociatedOpportunityModule = () => {
  const { t } = useTranslation()
  const { track } = useSegment()

  const currentInitiative = useInitiativeStore(state => state.currentInitiative)

  const [isAddOpportunityModalOpen, setIsAddOpportunityModalOpen] = useState(false)
  const onAddOpportunityClick = () => {
    setIsAddOpportunityModalOpen(true)
    track('initiative_add_opportunity_button')
  }
  const onAddFirstOpportunityClick = () => {
    setIsAddOpportunityModalOpen(true)
  }

  const { associateOpportunities } = useInitiatives({ enabled: false })

  const onSaveInitiative = (opportunityIds: string[]) => {
    if (!currentInitiative) return
    associateOpportunities({ initiativeId: currentInitiative?.id, opportunityIds })
    const opportunitiesAddedNames = initiativeOpportunities.map(opportunity => opportunity.name)
    track('initiative_add_opportunity_dialog', { param: opportunitiesAddedNames })
  }

  const onClose = () => {
    track('initiative_add_opportunity_close_dialog')
  }

  const { opportunities: allOpportunities } = useBasicOpportunitiesQuery()

  const initiativeOpportunities = useMemo(() => {
    if (!allOpportunities) return []
    return allOpportunities.filter(opportunity =>
      currentInitiative?.opportunities.includes(opportunity.id)
    )
  }, [currentInitiative, allOpportunities])

  if (!currentInitiative) return null

  return (
    <>
      <FlexContainer direction="column" gap="sm">
        <FlexContainer justifyContent="spaceBetween" alignItems="center">
          <Text
            as="h2"
            color="brandPrimaryPure"
            css={{ fontSize: '$xs', lineHeight: 1.25, fontWeight: '$light' }}
          >
            {t('associatedOpportunity')}
          </Text>
          {initiativeOpportunities.length > 0 && (
            <Button
              text
              onClick={onAddOpportunityClick}
              size="small"
              variant="flat-bordered"
              css={{ border: 'none', fontWeight: '$regular' }}
            >
              {t('addOpportunity')}
            </Button>
          )}
        </FlexContainer>

        {initiativeOpportunities.length > 0 ? (
          <AssociatedOpportunitiesGrid>
            {initiativeOpportunities.map(opportunity => (
              <AssociatedOpportunity {...opportunity} key={opportunity.id} />
            ))}
          </AssociatedOpportunitiesGrid>
        ) : (
          <AssociatedOpportunityEmpty onAddFirstOpportunityClick={onAddFirstOpportunityClick} />
        )}
      </FlexContainer>

      {isAddOpportunityModalOpen && (
        <AssociateOppToInitiativeDialog
          initiative={currentInitiative}
          open={isAddOpportunityModalOpen}
          onOpenChange={setIsAddOpportunityModalOpen}
          onClose={onClose}
          onSave={onSaveInitiative}
        />
      )}
    </>
  )
}

export default AssociatedOpportunityModule
