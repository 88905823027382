import { Suspense, useState, lazy } from 'react'

import { Feedback } from '@/types/feedbacks'
import ScrollLoader from '../feed/ScrollLoader'
import NoResults from '../feed/NoResults'
import FeedError from '../feed/FeedError'
import useSegment from '@/hooks/useSegment'
import FeedbackDetails from '../feedback-details'
import useChatFeedback from '@/hooks/feedback/useChatFeedback'
import ChatFeedbacksList from './ChatFeedbackVirtualized/ChatFeedbacksList'

const FeedbackThread = lazy(() => import('../feed/feedbacks/FeedbackThread'))

interface Props {
  isVisible?: boolean
  disableAutomaticLoad?: boolean
}

function ChatFeedbacks({ disableAutomaticLoad = false, isVisible }: Props) {
  const { track } = useSegment()

  const { feedbacks, error, isLoading, getChatFeedbacks } = useChatFeedback({
    disableAutomaticLoad
  })

  const [feedbackThreadOpen, setFeedbackThreadOpen] = useState<Feedback | null>(null)
  function openThreadModal(feedback: Feedback) {
    track('feedback_user_view_thread')
    setFeedbackThreadOpen(feedback)
  }

  if (error && feedbacks.length === 0) {
    return <FeedError onReload={getChatFeedbacks} />
  }

  if (isLoading) {
    return <ScrollLoader />
  }

  if (feedbacks.length === 0) {
    return <NoResults archived={false} />
  }

  return (
    <>
      <ChatFeedbacksList
        disableAutomaticLoad={true}
        isVisible={isVisible}
        openThreadModal={openThreadModal}
      />
      <FeedbackDetails store={'chat'} />

      <Suspense>
        {feedbackThreadOpen && (
          <FeedbackThread
            feedback={feedbackThreadOpen}
            onOpenChange={() => setFeedbackThreadOpen(null)}
            ticketId={feedbackThreadOpen?.ticket?.id ?? ''}
          />
        )}
      </Suspense>
    </>
  )
}

export default ChatFeedbacks
