import ErrorBoundary from '@/components/atoms/error-boundary'
import FlexContainer from '@/components/atoms/flex-container'

import Feedbacks from '@/components/molecules/feed/feedbacks'
import FiltersHeader from '@/components/molecules/filters/FiltersHeader'
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import useClassification from '@/hooks/useClassification'
import { styled } from '@/theme'
import { useEffect } from 'react'

const ArchivedFeedbacksContainer = styled('div', {
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  gap: '$xxs',
  flexGrow: 1,
  height: '100%',
  pt: '$xxxs',

  variants: {
    center: {
      true: {
        width: '100%'
      }
    }
  }
})

const FeedScrollArea = styled(ScrollAreaRoot, {
  overflow: 'hidden'
})

const ArchivedFilterContainer = styled(FlexContainer, {
  maxWidth: 900,
  minWidth: 900,
  alignSelf: 'center'
})

const ArchivedContent = () => {
  const { loadAllThemes } = useClassification()

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    loadAllThemes()
  }, [])

  return (
    <ErrorBoundary>
      <ArchivedFilterContainer>
        <FiltersHeader archived />
      </ArchivedFilterContainer>
      <ArchivedFeedbacksContainer>
        <FeedScrollArea>
          <ScrollAreaViewport id="scroll-feedback">
            <Feedbacks archived />
          </ScrollAreaViewport>
          <ScrollAreaScrollbar orientation="vertical">
            <ScrollAreaThumb />
          </ScrollAreaScrollbar>
        </FeedScrollArea>
      </ArchivedFeedbacksContainer>
    </ErrorBoundary>
  )
}

export default ArchivedContent
