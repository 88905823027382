import { styled } from '@/theme'

export const DistributionBarContainer = styled('div', {
  display: 'flex',
  borderRadius: 20,
  width: '100%',
  alignItems: 'center',
  padding: '4px 0',

  variants: {
    outlined: {
      true: {
        bAll: '$neutralHighPure'
      }
    }
  }
})

export const SentimentBar = styled('div', {
  '&:first-child': {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20
  },

  '&:last-child': {
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20
  },

  variants: {
    sentiment: {
      negative: {
        background: '$feedbackNegativePure'
      },
      neutral: {
        background: '$neutralLowLight'
      },
      positive: {
        background: '$feedbackPositivePure'
      }
    },
    heightSize: {
      small: {
        height: 4
      },
      normal: {
        height: 16
      }
    }
  }
})
