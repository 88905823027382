import IndicatorCard from '../indicators/IndicatorCard'
import { IndicatorCardProps } from './TopicDetails.types'
import { useFiltersStore } from '@/store'
import shallow from 'zustand/shallow'
import useTopicIntentionIndicators from '@/hooks/analytics/useTopicIntentionIndicators'
import TopicIndicatorCardSkeleton from './TopicIndicatorCardSkeleton'
import { IntentionType } from '../trending-topics/TrendingTopics.types'
import { intentionMap } from '../indicatorsUtilts'

const TopicIntentionIndicatorBase = ({
  topicId,
  intention
}: IndicatorCardProps & { intention: IntentionType }) => {
  const dateRange = useFiltersStore(state => state.dateRange, shallow)

  const { text, count, comparisonPercentage, intentionPercentage, isLoading } =
    useTopicIntentionIndicators({
      intention,
      topicId
    })

  const color = intentionMap[intention].color
  const Icon = intentionMap[intention].Icon

  return (
    <IndicatorCard.Root alignItems="end" color={color}>
      <IndicatorCard.Column>
        <IndicatorCard.Header>
          <IndicatorCard.IconBox>
            <Icon />
          </IndicatorCard.IconBox>
          <IndicatorCard.Title>{intention}</IndicatorCard.Title>
        </IndicatorCard.Header>

        {isLoading ? (
          <TopicIndicatorCardSkeleton.Number />
        ) : (
          <IndicatorCard.NumberContainer>
            <IndicatorCard.BigNumber>{intentionPercentage}%</IndicatorCard.BigNumber>
            <IndicatorCard.Paragraph>({count})</IndicatorCard.Paragraph>
          </IndicatorCard.NumberContainer>
        )}
      </IndicatorCard.Column>
      <IndicatorCard.Column>
        {!dateRange ? (
          <IndicatorCard.Fallback />
        ) : isLoading ? (
          <TopicIndicatorCardSkeleton.Text />
        ) : (
          <IndicatorCard.Paragraph>
            <em>{comparisonPercentage}</em> <span>{text}</span>
          </IndicatorCard.Paragraph>
        )}
      </IndicatorCard.Column>
    </IndicatorCard.Root>
  )
}

const Issue = ({ topicId }: IndicatorCardProps) => (
  <TopicIntentionIndicatorBase intention="issue" topicId={topicId} />
)
const Request = ({ topicId }: IndicatorCardProps) => (
  <TopicIntentionIndicatorBase intention="request" topicId={topicId} />
)

const TopicIntentionIndicator = {
  Issue,
  Request
}

export default TopicIntentionIndicator
