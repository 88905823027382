import { ChipContainer } from './TopicChip.styles'
import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons'
import { CSS } from '@/theme'

export interface TopicKeywordChipProps {
  label: string
  dismissable?: boolean
  dismissed?: boolean

  css?: CSS
  onClick?: React.MouseEventHandler<HTMLSpanElement>
}

const TopicKeywordChip = ({
  label,
  css,
  dismissable = true,
  dismissed,
  onClick
}: TopicKeywordChipProps) => {
  return (
    <ChipContainer css={css} topicKeyword>
      <span>{label}</span>

      {dismissable && (
        <>
          <span className={`icon check${dismissed ? ' dismissed' : ''}`}>
            <CheckIcon />
          </span>

          <span className={`icon delete${dismissed ? ' dismissed' : ''}`} onClick={onClick}>
            <Cross2Icon />
          </span>
        </>
      )}
    </ChipContainer>
  )
}

export default TopicKeywordChip
