import { queryClient } from '@/plugins/reactQueryClient'
import CollectionService from '@/services/CollectionService'
import type { Collection, CollectionRequests } from '@/types/collection'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import useLogging from '../useLogging'
import useToastMessageStore from '@/store/useToastMessageStore'
import useCollectionStore, { usePersistedCollectionStore } from '@/store/useCollectionStore'
import AccessService from '@/services/AccessServices'
import useAnalysisHubState from '@/store/useAnalysisHubStore'
import useSegment from '../useSegment'
import { useLocation } from 'react-router-dom'
import { SegmentEvent } from '@/types/segment'
import { OPPORTUNITIES_KEY_PREFIX } from '../opportunity/useAllOpportunitiesWithMetricsQuery'
import { AREAS_KEY_PREFIX } from '../areaOfInterest/useAllAreasQuery'
import { SavedFilterContentAdvanced } from '@/types/filters/Filters'

interface Params {
  search?: string
  enabled?: boolean
  recent?: boolean
}

const defaultParams: Params = { search: '', enabled: true, recent: false }

type CollectionResponseItem = CollectionRequests.SearchResponse[number]

const mapCollection = (collection: CollectionResponseItem): Collection => ({
  collectionId: collection.collection_id,
  name: collection.name,
  description: collection.description,
  visibility: collection.visibility,
  type: collection.type,
  favorite: collection.favorite,
  createdBy: collection.created_by,
  createdAt: collection.created_at,
  updatedBy: collection.updated_by,
  updatedAt: collection.updated_at,
  deletedBy: collection.deleted_by,
  deletedAt: collection.deleted_at,
  filterIds: collection.filters ?? []
})

interface InsertFiltersToCollectionParams {
  collectionId: string
  filterIds: string[]
  onSuccess?: () => void
}

interface CreateCollectionParams extends CollectionRequests.CreatePayload {
  filterIds: string[]
}

interface EditCollectionParams {
  collection: Collection
  name: string
  filterIds: string[]
}

interface ToggleCollectionVisibilityParams {
  collection: Collection
  action: 'publish' | 'unpublish'
}

const getReplaceCollectionsOnEdit =
  ({ collection, name }: Omit<EditCollectionParams, 'filterIds'>) =>
  (old: CollectionResponseItem[] | undefined): CollectionResponseItem[] | undefined => {
    if (!old) return

    return old.map(c => ({
      ...c,
      name: c.collection_id === collection.collectionId ? name : c.name
    }))
  }

const getReplaceCollectionsOnFavorite =
  (collectionId: string, favorite: boolean) =>
  (old: CollectionResponseItem[] | undefined): CollectionResponseItem[] | undefined => {
    if (!old) return

    return old.map(collection => ({
      ...collection,
      favorite: collection.collection_id === collectionId ? favorite : collection.favorite
    }))
  }

const getReplaceCollectionsOnDelete =
  (collectionId: string) =>
  (old: CollectionResponseItem[] | undefined): CollectionResponseItem[] | undefined => {
    if (!old) return
    return old.filter(collection => collection.collection_id !== collectionId)
  }

const getReplaceCollectionsOnToggleVisibility =
  ({ collection, action }: ToggleCollectionVisibilityParams) =>
  (old: CollectionResponseItem[] | undefined): CollectionResponseItem[] | undefined => {
    if (!old) return

    const visibility = action === 'publish' ? 'public' : 'private'
    return old.map(c => ({
      ...c,
      visibility: c.collection_id === collection.collectionId ? visibility : c.visibility
    }))
  }

const useCollections = ({
  search = '',
  enabled = true,
  recent = false
}: Params = defaultParams) => {
  const { track } = useSegment()

  const location = useLocation()

  const from = useMemo(() => {
    if (location.pathname.includes('home')) return 'home'
    if (location.pathname.includes('areas-and-opportunities')) return 'areas-and-opps'
    return location.pathname
  }, [location.pathname])

  const { logException } = useLogging({ context: 'useCollections' })
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const { currentCollection, setCurrentCollection } = useCollectionStore()
  const currentCollectionId = currentCollection?.collectionId

  const { setHasCollections } = usePersistedCollectionStore()

  const currentTab = useAnalysisHubState(state => state.currentTab)
  const setCurrentTab = useAnalysisHubState(state => state.setCurrentTab)

  const changeCollection = async (collection: Collection | null) => {
    setCurrentCollection(collection)

    if (!collection) return

    if (collection.type === 'opportunity') {
      setCurrentTab('opportunities')
    }

    if (currentTab === 'segments' && collection.type === 'area_interest') {
      setCurrentTab('areas')
    }

    const [error] = await AccessService.logAccess({
      resource: 'collection',
      resource_id: collection.collectionId
    })

    if (error) {
      console.error(error)
      logException(error)
    }
  }

  const queryFn = async () => {
    const [error, response] = await CollectionService.search({
      name: search
    })

    if (error) throw error

    if (search === '') {
      setHasCollections(response.length > 0)
    }
    return response
  }

  const collectionsKey = ['collections', search]
  const { data, ...query } = useQuery({
    queryKey: collectionsKey,
    queryFn,
    enabled
  })

  const collections = useMemo(() => {
    if (!data) return []
    return data.map(mapCollection)
  }, [data])

  const recentQueryFn = async () => {
    const [error, response] = await CollectionService.search({
      name: '',
      recently: true,
      limit: 30
    })

    if (error) throw error
    return response
  }

  const recentCollectionsKey = ['recent-collections']
  const { data: recentData, ...recentQuery } = useQuery({
    queryKey: recentCollectionsKey,
    queryFn: recentQueryFn,
    enabled: enabled && recent
  })

  const recentCollections = useMemo(() => {
    if (!recentData) return []
    return recentData.map(mapCollection)
  }, [recentData])

  const contentQueryFn = async () => {
    if (!currentCollectionId) {
      throw new Error('Invalid collection id.')
    }

    const [error, response] = await CollectionService.getContent(currentCollectionId)

    if (error) throw error

    const result: CollectionRequests.GetContentResult = {
      ...response,
      content: [
        {
          key: 'advanced',
          name: 'advanced',
          type: 'advanced',
          values: {
            filter: response.content?.filter ?? []
          }
        }
      ] as [SavedFilterContentAdvanced]
    }
    return result
  }

  const { data: contentData, ...contentQuery } = useQuery({
    queryKey: ['collection-content', currentCollectionId],
    queryFn: contentQueryFn,
    enabled: enabled && !!currentCollectionId
  })

  const { mutate: insertFiltersToCollection, isLoading: isInsertFiltersLoading } = useMutation({
    mutationKey: ['insert-filters-to-collection'],
    mutationFn: async ({ collectionId, filterIds }: InsertFiltersToCollectionParams) => {
      const [error] = await CollectionService.upinsertFilters(collectionId, {
        filter_ids: filterIds,
        sync: false
      })
      if (error) throw error
    },
    onSuccess: (_, { onSuccess }) => {
      onSuccess?.()
    },
    onError: error => {
      const message = 'Failed to insert filters to collection.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  })

  const { mutate: createCollection, isLoading: isCreateCollectionLoading } = useMutation({
    mutationKey: ['create-collection'],
    mutationFn: async ({ filterIds, ...payload }: CreateCollectionParams) => {
      const [error, response] = await CollectionService.create(payload)
      if (error) throw error

      setHasCollections(true)
      return { newCollectionData: response, filterIds }
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['collections'] })
      await queryClient.cancelQueries({ queryKey: recentCollectionsKey })
    },
    onSuccess: async ({ newCollectionData, filterIds }) => {
      track('collection_save_new', {
        entity: newCollectionData.type
      })

      queryClient.setQueryData<CollectionResponseItem[]>(collectionsKey, old => [
        ...(old ?? []),
        newCollectionData
      ])

      insertFiltersToCollection({
        collectionId: newCollectionData.collection_id,
        filterIds,
        onSuccess: () => {
          changeCollection(mapCollection(newCollectionData))
        }
      })
    },
    onError: error => {
      const message = 'Failed to create collection.'
      logException(error, { message })
      addErrorToast({ text: message })
    },
    onSettled: () => {
      query.refetch()
      recentQuery.refetch()
    }
  })

  const { mutate: editCollection, isLoading: isEditCollectionLoading } = useMutation({
    mutationKey: ['edit-collection'],
    mutationFn: async ({ collection, name, filterIds }: EditCollectionParams) => {
      if (collection.name !== name) {
        const [updateError] = await CollectionService.update(collection.collectionId, {
          name,
          description: collection.description,
          visibility: collection.visibility,
          type: collection.type
        })

        if (updateError) throw updateError
      }

      const [insertError] = await CollectionService.upinsertFilters(collection.collectionId, {
        filter_ids: filterIds,
        sync: true
      })

      if (insertError) throw insertError
    },
    onMutate: async ({ collection, name }) => {
      await queryClient.cancelQueries({ queryKey: ['collections'] })
      await queryClient.cancelQueries({ queryKey: recentCollectionsKey })

      const previousCollections = queryClient.getQueryData<CollectionResponseItem[]>(collectionsKey)
      const previousRecentCollections =
        queryClient.getQueryData<CollectionResponseItem[]>(recentCollectionsKey)

      const replaceFn = getReplaceCollectionsOnEdit({ collection, name })

      queryClient.setQueryData<CollectionResponseItem[]>(collectionsKey, replaceFn)
      queryClient.setQueryData<CollectionResponseItem[]>(recentCollectionsKey, replaceFn)
      setCurrentCollection({ ...collection, name })

      return {
        previousCollections,
        previousRecentCollections,
        queryKey: collectionsKey,
        recentQueryKey: recentCollectionsKey
      }
    },
    onSuccess: (_, { collection }) => {
      track('collection_edited', {
        entity: collection.type,
        from
      })

      const message = 'Successfully updated collection.'
      addSuccessToast({ text: message })
      if (collection.type === 'area_interest') {
        queryClient.invalidateQueries({ queryKey: ['interest-areas', collection.collectionId] })
      } else {
        queryClient.invalidateQueries({ queryKey: ['opportunities', collection.collectionId] })
      }

      queryClient.invalidateQueries({ queryKey: [AREAS_KEY_PREFIX], exact: false })
      queryClient.invalidateQueries({ queryKey: [OPPORTUNITIES_KEY_PREFIX], exact: false })
    },
    onError: (error, { collection }, context) => {
      const message = 'Failed to update collection.'
      if (context) {
        queryClient.setQueryData<CollectionResponseItem[]>(
          context.queryKey,
          context.previousCollections
        )

        queryClient.setQueryData<CollectionResponseItem[]>(
          context.recentQueryKey,
          context.previousRecentCollections
        )
      }
      setCurrentCollection(collection)

      logException(error, { message })
      addErrorToast({ text: message })
    },
    onSettled: () => {
      query.refetch()
      recentQuery.refetch()
    }
  })

  const { mutate: favoriteCollection } = useMutation({
    mutationKey: ['favorite-collection'],
    mutationFn: async (collectionId: string) => {
      const [error] = await CollectionService.favorite(collectionId)
      if (error) throw error
    },
    onMutate: async collectionId => {
      await queryClient.cancelQueries({ queryKey: ['collections'] })
      await queryClient.cancelQueries({ queryKey: recentCollectionsKey })

      const previousCollections = queryClient.getQueryData<CollectionResponseItem[]>(collectionsKey)
      const previousRecentCollections =
        queryClient.getQueryData<CollectionResponseItem[]>(recentCollectionsKey)

      const replaceFn = getReplaceCollectionsOnFavorite(collectionId, true)

      queryClient.setQueryData<CollectionResponseItem[]>(collectionsKey, replaceFn)
      queryClient.setQueryData<CollectionResponseItem[]>(recentCollectionsKey, replaceFn)

      return {
        previousCollections,
        previousRecentCollections,
        queryKey: collectionsKey,
        recentQueryKey: recentCollectionsKey
      }
    },
    onSuccess: () => {
      query.refetch()
      recentQuery.refetch()
    },
    onError: (error, _, context) => {
      const message = 'Failed to favorite collection.'

      if (context) {
        queryClient.setQueryData<CollectionResponseItem[]>(
          context.queryKey,
          context.previousCollections
        )
        queryClient.setQueryData<CollectionResponseItem[]>(
          context.recentQueryKey,
          context.previousRecentCollections
        )
      }
      logException(error, { message })
      addErrorToast({ text: message })
    }
  })

  const { mutate: unfavoriteCollection } = useMutation({
    mutationKey: ['unfavorite-collection'],
    mutationFn: async (collectionId: string) => {
      const [error] = await CollectionService.unfavorite(collectionId)
      if (error) throw error
    },
    onMutate: async collectionId => {
      await queryClient.cancelQueries({ queryKey: ['collections'] })
      await queryClient.cancelQueries({ queryKey: recentCollectionsKey })

      const previousCollections = queryClient.getQueryData<CollectionResponseItem[]>(collectionsKey)
      const previousRecentCollections =
        queryClient.getQueryData<CollectionResponseItem[]>(recentCollectionsKey)

      const replaceFn = getReplaceCollectionsOnFavorite(collectionId, false)

      queryClient.setQueryData<CollectionResponseItem[]>(collectionsKey, replaceFn)
      queryClient.setQueryData<CollectionResponseItem[]>(recentCollectionsKey, replaceFn)

      return {
        previousCollections,
        previousRecentCollections,
        queryKey: collectionsKey,
        recentQueryKey: recentCollectionsKey
      }
    },
    onError: (error, _, context) => {
      const message = 'Failed to unfavorite collection.'
      if (context) {
        queryClient.setQueryData<CollectionResponseItem[]>(
          context?.queryKey,
          context?.previousCollections
        )
        queryClient.setQueryData<CollectionResponseItem[]>(
          context?.recentQueryKey,
          context?.previousRecentCollections
        )
      }
      logException(error, { message })
      addErrorToast({ text: message })
    },
    onSettled: () => {
      query.refetch()
      recentQuery.refetch()
    }
  })

  const { mutate: removeCollection } = useMutation({
    mutationKey: ['remove-collection'],
    mutationFn: async (collectionId: string) => {
      const [error] = await CollectionService.remove(collectionId)
      if (error) throw error
    },
    onMutate: async collectionId => {
      if (currentCollectionId === collectionId) {
        changeCollection(null)
      }

      await queryClient.cancelQueries({ queryKey: ['collections'] })
      await queryClient.cancelQueries({ queryKey: recentCollectionsKey })

      const previousCollections = queryClient.getQueryData<CollectionResponseItem[]>(collectionsKey)
      const previousRecentCollections =
        queryClient.getQueryData<CollectionResponseItem[]>(recentCollectionsKey)

      const replaceFn = getReplaceCollectionsOnDelete(collectionId)

      queryClient.setQueryData<CollectionResponseItem[]>(collectionsKey, replaceFn)
      queryClient.setQueryData<CollectionResponseItem[]>(recentCollectionsKey, replaceFn)

      return {
        previousCollections,
        previousRecentCollections,
        queryKey: collectionsKey,
        recentQueryKey: recentCollectionsKey
      }
    },
    onSuccess: () => {
      track('collection_deleted', { from })
      addSuccessToast({ text: 'Collection successfully deleted.' })
    },
    onError: (error, _, context) => {
      const message = 'Failed to remove collection.'
      if (context) {
        queryClient.setQueryData<CollectionResponseItem[]>(
          context?.queryKey,
          context?.previousCollections
        )
        queryClient.setQueryData<CollectionResponseItem[]>(
          context?.recentQueryKey,
          context?.previousRecentCollections
        )
      }
      logException(error, { message })
      addErrorToast({ text: message })
    },
    onSettled: () => {
      query.refetch()
      recentQuery.refetch()
    }
  })

  const { mutate: toggleCollectionVisibility } = useMutation({
    mutationKey: ['toggle-collection-visibility'],
    mutationFn: async ({ collection, action }: ToggleCollectionVisibilityParams) => {
      const visibility = action === 'publish' ? 'public' : 'private'
      const [error] = await CollectionService.update(collection.collectionId, {
        name: collection.name,
        description: collection.description,
        visibility,
        type: collection.type
      })

      if (error) throw error
    },
    onMutate: async ({ collection, action }) => {
      await queryClient.cancelQueries({ queryKey: ['collections'] })
      await queryClient.cancelQueries({ queryKey: recentCollectionsKey })

      const previousCollections = queryClient.getQueryData<CollectionResponseItem[]>(collectionsKey)
      const previousRecentCollections =
        queryClient.getQueryData<CollectionResponseItem[]>(recentCollectionsKey)

      const replaceFn = getReplaceCollectionsOnToggleVisibility({ collection, action })

      queryClient.setQueryData<CollectionResponseItem[]>(collectionsKey, replaceFn)
      queryClient.setQueryData<CollectionResponseItem[]>(recentCollectionsKey, replaceFn)
      setCurrentCollection({
        ...collection,
        visibility: action === 'publish' ? 'public' : 'private'
      })

      return {
        previousCollections,
        previousRecentCollections,
        queryKey: collectionsKey,
        recentQueryKey: recentCollectionsKey
      }
    },
    onSuccess: (_, { action, collection }) => {
      const event: SegmentEvent =
        action === 'publish' ? 'collection_published' : 'collection_unpublished'
      track(event, {
        entity: collection.type,
        from
      })
      const message =
        action === 'publish'
          ? 'Collection successfully published.'
          : 'Collection successfully unpublished.'
      addSuccessToast({ text: message })
    },
    onError: (error, { collection, action }, context) => {
      const message =
        action === 'publish' ? 'Failed to publish collection.' : 'Failed to unpublish collection.'
      if (context) {
        queryClient.setQueryData<CollectionResponseItem[]>(
          context?.queryKey,
          context?.previousCollections
        )
        queryClient.setQueryData<CollectionResponseItem[]>(
          context?.recentQueryKey,
          context?.previousRecentCollections
        )
      }
      setCurrentCollection(collection)
      logException(error, { message })
      addErrorToast({ text: message })
    },
    onSettled: () => {
      query.refetch()
      recentQuery.refetch()
    }
  })

  const collectionFilterIds = useMemo(() => {
    if (!currentCollectionId) return undefined
    const filterIds = collections.find(
      collection => collection.collectionId === currentCollectionId
    )?.filterIds

    return filterIds
  }, [collections, currentCollectionId])

  return {
    collections,
    ...query,
    recentCollections,
    recentQuery,
    createCollection,
    isCreateCollectionLoading,
    insertFiltersToCollection,
    isInsertFiltersLoading,
    editCollection,
    isEditCollectionLoading,
    favoriteCollection,
    unfavoriteCollection,
    removeCollection,
    toggleCollectionVisibility,
    currentCollection,
    changeCollection,
    contentData,
    contentQuery,
    collectionFilterIds
  }
}

export default useCollections
