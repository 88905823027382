import { useMutation, useQuery } from '@tanstack/react-query'
import useOpportunityCalibrationStore from '@/store/useOpportunityCalibrationStore'
import OpportunityService from '@/services/OpportunityService'
import { OpportunityRequests } from '@/types/opportunity'
import { OpportunityItem } from '@/types/opportunity/Opportunity'
import moment from 'moment'
import useLogging from '../useLogging'
import useToastMessageStore from '@/store/useToastMessageStore'

export const SIZE_SAMPLE = 10

const useOpportunityCalibration = (opportunity?: OpportunityItem) => {
  const currentUserCalibration = useOpportunityCalibrationStore(
    state => state.currentUserCalibration
  )
  const currentRound = useOpportunityCalibrationStore(state => state.currentRound)
  const evaluatedRound = useOpportunityCalibrationStore(state => state.evaluatedRound)
  const setEvaluatedRound = useOpportunityCalibrationStore(state => state.setEvaluatedRound)
  const setIterationsData = useOpportunityCalibrationStore(state => state.setIterationsData)
  const iterationsData = useOpportunityCalibrationStore(state => state.iterationsData)
  const suggestedDescription = useOpportunityCalibrationStore(state => state.suggestedDescription)
  const setSuggestedDescription = useOpportunityCalibrationStore(
    state => state.setSuggestedDescription
  )
  const { logException } = useLogging({ context: 'calibrate-opportunity' })
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const queryFn = async () => {
    const description =
      currentRound > 0 ? suggestedDescription ?? '' : opportunity?.description ?? ''

    const payload: OpportunityRequests.CalibrateOpportunityPayload = {
      opportunity: {
        name: opportunity?.name ?? '',
        description
      },
      expert_model: 'chatgpt:gpt-4o',
      automated_model: 'vertexai:gemini-1.5-flash',
      size_sample: SIZE_SAMPLE,
      first_interation_search: {
        size_search: 1000,
        size_classify: 100,
        random_frac: 0.2
      },
      ...(currentRound > 0 && { iterations_data: iterationsData })
    }

    const [error, response] = await OpportunityService.calibrateOpportunityFeedback(payload)

    if (error) throw error
    return response
  }

  const { data, ...query } = useQuery({
    queryKey: ['calibrationSample', opportunity, evaluatedRound],
    queryFn,
    keepPreviousData: true,
    enabled: !!opportunity
  })

  const feedbackSample = data

  const { mutate: finishCalibrationRound, isLoading: isFinishingCalibrationRound } = useMutation({
    mutationFn: async () => {
      const feedbacks =
        feedbackSample?.feedbacks.map(feedback => ({
          id: feedback.id,
          text: feedback.text,
          most_similar_sentence: {
            sentence: feedback.most_similar_sentence?.sentence,
            similarity: feedback.most_similar_sentence?.similarity,
            similar_to: feedback.most_similar_sentence?.similar_to,
            method: 'Most similar' as const
          },
          classifications: {
            automated: feedback.classifications?.automated,
            expert: feedback.classifications?.expert
          }
        })) ?? []

      const payload: OpportunityRequests.FinishCalibrationRoundPayload = {
        opportunity: {
          name: opportunity?.name ?? '',
          description: opportunity?.description ?? ''
        },
        iterations_data: {
          user_classifications: currentUserCalibration ?? [],
          feedbacks: feedbacks
        }
      }

      setIterationsData({
        user_classifications: currentUserCalibration ?? [],
        feedbacks: feedbacks
      })

      const [error, response] = await OpportunityService.finishCalibrationRound(payload)

      if (error) throw error
      return response
    },

    onSuccess: data => {
      setSuggestedDescription(data.improvement_suggestions[0])
      setEvaluatedRound()
    },

    onError: error => {
      setIterationsData(undefined)
      const message = 'Failed to calibrate the opportunity'
      logException(error, { message })
      addErrorToast({
        text: message,
        duration: 4000
      })
      throw error
    }
  })

  const {
    mutate: updateOpportunityPosCalibration,
    isLoading: isUpdatingOpportunityPosCalibration
  } = useMutation({
    mutationKey: ['update-opportunity-pos-calibration'],
    mutationFn: async () => {
      const description =
        currentRound > 0 ? suggestedDescription ?? '' : opportunity?.description ?? ''
      if (!iterationsData) return
      const now = moment()

      const payload: OpportunityRequests.UpdateOpportunityPosCalibrationPayload = {
        iterations_data: iterationsData,
        quantification_info: {
          period: {
            start: now.clone().subtract(6, 'months').format('YYYY-MM-DDTHH:mm:ss') + '.000000',
            end: now.format('YYYY-MM-DDTHH:mm:ss') + '.000000'
          },
          sample_size: 10
        },
        opportunity_instruction: description,
        automated_model: 'vertexai:gemini-1.5-flash'
      }

      if (!opportunity) return
      const [error] = await OpportunityService.updateOpportunityPosCalibration(
        opportunity.id,
        payload
      )

      if (error) throw error
    },

    onSuccess: () => {
      addSuccessToast({
        text: 'Opportunity calibrated successfully. Updates may take a few minutes.'
      })
    },

    onError: error => {
      setIterationsData(undefined)
      const message = 'Failed to calibrate the opportunity'
      logException(error, { message })
      addErrorToast({
        text: message,
        duration: 4000
      })
      throw error
    }
  })

  return {
    feedbackSample,
    ...query,
    finishCalibrationRound,
    isFinishingCalibrationRound,
    updateOpportunityPosCalibration,
    isUpdatingOpportunityPosCalibration
  }
}

export default useOpportunityCalibration
