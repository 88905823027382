import React, { useMemo, useState } from 'react'

import { TopicChip, TopicChipsContainer } from './FeedbackItem.styles'
import { FeedbackTopicChipType, FeedbackTopicSentiment } from './FeedbackItem.types'
import { usePersistedFeedbackStore } from '@/store/useFeedbackStore'
import { shallow } from 'zustand/shallow'
import { IconContext } from '@phosphor-icons/react'
import KeywordTopicPopover from './KeywordTopicPopover'
import { intentionBgColor, intentionIcons, intentionTextColor } from '@/utils/intention'
import { colors } from '@/theme'
import { Feedback } from '@/types/feedbacks'

const FeedbackTopicChip: React.FC<Feedback> = ({ ...props }) => {
  const [activeTopicChip] = useState<FeedbackTopicChipType | undefined>(undefined)

  const feedbackViewMode = usePersistedFeedbackStore(state => state.feedbackViewMode, shallow)

  const filteredKeywordTopicList = useMemo(
    () => props.keywordTopicList.filter(keywordTopic => keywordTopic.topics?.length > 0),
    [props.keywordTopicList]
  )

  const topicChips = useMemo(() => {
    const topicChipsScoped: FeedbackTopicChipType[] = []

    for (const { topics, sentiment, intention, text, keywordHash } of filteredKeywordTopicList) {
      for (const topic of topics) {
        const topicAlreadyExists = topicChipsScoped.some(topicChip =>
          areTopicsEqual(topicChip, { id: topic.topicId, sentiment })
        )

        if (topicAlreadyExists) continue

        topicChipsScoped.push({
          color: intention?.[0] ? intentionTextColor[intention?.[0]] : colors.neutralLowLight,
          bgColor: intention?.[0] ? intentionBgColor[intention?.[0]] : colors.neutralHighMedium,
          id: topic.topicId,
          name: topic.name || '',
          sentiment,
          intentions: intention,
          keywordText: text,
          keywordHash
        })
      }
    }

    return topicChipsScoped
  }, [filteredKeywordTopicList])

  function areTopicsEqual(
    topicToBeCompared: FeedbackTopicSentiment,
    topicToCompareWith: FeedbackTopicSentiment | undefined = activeTopicChip
  ) {
    if (!topicToCompareWith) return true

    if (
      topicToBeCompared.id === topicToCompareWith.id &&
      topicToBeCompared.sentiment === topicToCompareWith.sentiment
    ) {
      return true
    }

    return false
  }

  if (!topicChips.length) return <></>

  return (
    <TopicChipsContainer>
      {topicChips.map(topic => {
        const topicIntention = topic.intentions.length ? topic.intentions[0] : null
        return (
          <KeywordTopicPopover
            customButton
            feedbackId={props.feedbackId}
            feedbackSourceId={props.feedbackSourceId}
            key={`${topic.id} ${topic.sentiment} ${topicIntention}`}
            topic={topic}
          >
            <TopicChip
              css={{
                color: topic.color,
                backgroundColor: topic.bgColor,
                '&[data-mode="compact"]': {
                  backgroundColor: topic.color
                }
              }}
              data-active={areTopicsEqual(topic)}
              data-mode={feedbackViewMode}
              key={`${topic.id} ${topic.sentiment} ${topicIntention}`}
              // onMouseEnter={() => handleTopicChipMouseEnter(topic)}
              // onMouseLeave={handleTopicChipMouseLeave}
              tabIndex={feedbackViewMode === 'expanded' ? -1 : 0}
            >
              <IconContext.Provider value={{ size: 16, weight: 'fill' }}>
                {topicIntention && intentionIcons[topicIntention]}
              </IconContext.Provider>
              <span title={topic.name}>{topic.name}</span>
            </TopicChip>
          </KeywordTopicPopover>
        )
      })}
    </TopicChipsContainer>
  )
}

export default FeedbackTopicChip
