import { AnalyticsRequests } from '@/types/analytics'
import Request from './Request'
import { BaseRequestReturnType } from './RequestHandlers/BaseRequestHandler'
import {
  IndicatorsData,
  TimeSeriesData,
  TopicDetails,
  TrendingTopicItemData
} from '@/types/analytics/Analytics'
import RequestHandler from './RequestHandlers/NewRequest'
import { TopicMetric } from '@/types/analytics/AnalyticsRequests'

const parseTrendingTopicItem = (topic: TopicMetric): TrendingTopicItemData => ({
  topicId: topic.topic_id,
  name: topic.name,
  currentPercentageIntention: topic.percentage_intention_current,
  previousPercentageIntention: topic.percentage_intention_previous,
  current: topic.current,
  previous: topic.previous,
  variation: topic.variation,
  currentFeedback: topic.feedback_current,
  previousFeedback: topic.feedback_previous
})

const PREFIX = '/analytics'
export default class AnalyticsService {
  /** Deprecated */
  static async findTimeSeries(payload: AnalyticsRequests.TimeSeriesPayload) {
    return Request.post<AnalyticsRequests.TimeSeriesResponse>(PREFIX + '/find_time_series', payload)
  }

  static async timeSeries(
    payload: AnalyticsRequests.TimeSeriesPayload,
    signal?: AbortSignal
  ): Promise<BaseRequestReturnType<TimeSeriesData[]>> {
    const [error, data] = await RequestHandler.post<AnalyticsRequests.TimeSeriesResponse>(
      PREFIX + '/find_time_series',
      payload,
      { signal }
    )

    if (error) return [error, undefined]

    const timeSeriesData: TimeSeriesData[] = data.time_series.map(item => ({
      key: item.field + payload.interval,
      values: item.values.map(value => ({
        fieldValue: value.field_value,
        data: value.data.map(data => ({
          postedAt: data.posted_at,
          countFeedback: data.count_feedback,
          countFeedbackWithoutFilter: data.count_feedback_without_filter ?? 0,
          percentageFeedback: data.percentage_feedback ?? 0
        }))
      }))
    }))

    return [undefined, timeSeriesData]
  }

  static async multipleTimeSeries(
    payload: AnalyticsRequests.TimeSeriesPayload,
    signal?: AbortSignal
  ): Promise<BaseRequestReturnType<TimeSeriesData[][]>> {
    const [error, data] = await RequestHandler.post<AnalyticsRequests.TimeSeriesResponse>(
      PREFIX + '/find_time_series',
      payload,
      { signal }
    )

    if (error) return [error, undefined]

    const multipleTimeSeriesData: TimeSeriesData[][] = data.time_series_list.map(timeSeries =>
      timeSeries.map(
        (item): TimeSeriesData => ({
          key: item.field + payload.interval,
          values: item.values.map(value => ({
            fieldValue: value.field_value,
            data: value.data.map(data => ({
              postedAt: data.posted_at,
              countFeedback: data.count_feedback,
              countFeedbackWithoutFilter: data.count_feedback_without_filter ?? 0,
              percentageFeedback: data.percentage_feedback ?? 0
            }))
          }))
        })
      )
    )

    return [undefined, multipleTimeSeriesData]
  }

  static async indicators(
    payload: AnalyticsRequests.IndicatorsPayload,
    signal?: AbortSignal
  ): Promise<BaseRequestReturnType<IndicatorsData>> {
    const [error, data] = await RequestHandler.post<AnalyticsRequests.IndicatorsResponse>(
      PREFIX + '/metric/indicators',
      payload,
      { signal }
    )

    if (error) return [error, undefined]

    const indicatorsData: IndicatorsData = {
      feedbackMetric: {
        currentFeedbackCount: data.metric_feedback.count_feedback_current,
        previousFeedbackCount: data.metric_feedback.count_feedback_previous,
        feedbackPercentage: data.metric_feedback.percentage_feedback
      },
      metricCustomer: {
        totalCustomer: data.metric_customer.total_customer,
        percentageCustomer: data.metric_customer.percentage_customer
      },
      metricsByIntention: data.metric_intentions.map(metrics => ({
        intention: metrics.intention,
        currentFeedbackCount: metrics.count_feedback_current,
        previousFeedbackCount: metrics.count_feedback_previous,
        feedbackPercentage: metrics.percentage_feedback,
        intentionPercentage: metrics.percentage_intention,
        detailsPercentage: metrics.percentage_details
      }))
    }
    return [undefined, indicatorsData]
  }

  static async emergingTopics(
    payload: AnalyticsRequests.TrendingTopicsPayload,
    signal?: AbortSignal
  ): Promise<BaseRequestReturnType<TrendingTopicItemData[]>> {
    const actualPayload: AnalyticsRequests.TrendingTopicsPayload = {
      ...payload,
      metric_emerging: true,
      metric_intentions: []
    }

    const [error, data] = await RequestHandler.post<AnalyticsRequests.TrendingTopicsResponse>(
      PREFIX + '/metric/topics',
      actualPayload,
      { signal }
    )
    if (error) return [error, undefined]

    const topicsData: TrendingTopicItemData[] =
      data.metric_emerging_topics?.metric?.map(parseTrendingTopicItem) ?? []

    return [undefined, topicsData]
  }

  static async topIssues(
    payload: AnalyticsRequests.TrendingTopicsPayload,
    signal?: AbortSignal
  ): Promise<BaseRequestReturnType<TrendingTopicItemData[]>> {
    const actualPayload: AnalyticsRequests.TrendingTopicsPayload = {
      ...payload,
      metric_emerging: false,
      metric_intentions: ['Issue']
    }

    const [error, data] = await RequestHandler.post<AnalyticsRequests.TrendingTopicsResponse>(
      PREFIX + '/metric/topics',
      actualPayload,
      { signal }
    )

    if (error) return [error, undefined]

    const topicsData: TrendingTopicItemData[] =
      data.metric_intention_topics
        ?.find(intention => intention.name === 'Issue')
        ?.metric?.map(parseTrendingTopicItem) ?? []

    return [undefined, topicsData]
  }

  static async topRequests(
    payload: AnalyticsRequests.TrendingTopicsPayload,
    signal?: AbortSignal
  ): Promise<BaseRequestReturnType<TrendingTopicItemData[]>> {
    const actualPayload: AnalyticsRequests.TrendingTopicsPayload = {
      ...payload,
      metric_emerging: false,
      metric_intentions: ['Request']
    }

    const [error, data] = await RequestHandler.post<AnalyticsRequests.TrendingTopicsResponse>(
      PREFIX + '/metric/topics',
      actualPayload,
      { signal }
    )

    if (error) return [error, undefined]

    const topicsData: TrendingTopicItemData[] =
      data.metric_intention_topics
        ?.find(intention => intention.name === 'Request')
        ?.metric?.map(parseTrendingTopicItem) ?? []

    return [undefined, topicsData]
  }

  static async topicDetails(
    payload: AnalyticsRequests.TopicDetailsPayload,
    signal?: AbortSignal
  ): Promise<BaseRequestReturnType<TopicDetails>> {
    const fullPayload = { ...payload }

    fullPayload.root_causes_enable = false

    const [error, data] = await RequestHandler.post<AnalyticsRequests.TopicDetailsResponse>(
      PREFIX + '/metric/topics/details',
      fullPayload,
      { signal }
    )

    if (error) return [error, undefined]

    const topicDetails: TopicDetails = {
      summary: data.summary_short,
      rootCauses: data.root_causes.map(rootCause => ({
        count: rootCause.count,
        relevance: rootCause.relevance,
        text: rootCause.theme,
        topSample: rootCause.top_sample,
        reasonsHash: rootCause.reasons_hash
      }))
    }

    return [undefined, topicDetails]
  }
}
