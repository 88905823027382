import { styled } from '@/theme'
import TrendingTopicsCard from './TrendingTopicsCard'
import Bone from '@/components/atoms/bone'
import { EmergingTopicsHeader } from './EmergingTopics'
import { TopIssuesHeader } from './TopIssues'
import { TopRequestsHeader } from './TopRequests'

const SkeletonCard = styled(TrendingTopicsCard.Root, {
  height: 522
})

const ListSkeleton = () => (
  <TrendingTopicsCard.ItemList>
    <Bone css={{ height: 64 }} />
    <Bone css={{ height: 64 }} />
    <Bone css={{ height: 64 }} />
    <Bone css={{ height: 64 }} />
    <Bone css={{ height: 64 }} />
  </TrendingTopicsCard.ItemList>
)

export const EmergingTopicsSkeleton = () => (
  <SkeletonCard type="emerging">
    <EmergingTopicsHeader />
    <ListSkeleton />
  </SkeletonCard>
)

export const TopIssuesSkeleton = () => (
  <SkeletonCard type="issues">
    <TopIssuesHeader />
    <ListSkeleton />
  </SkeletonCard>
)

export const TopRequestsSkeleton = () => (
  <SkeletonCard type="requests">
    <TopRequestsHeader />
    <ListSkeleton />
  </SkeletonCard>
)
