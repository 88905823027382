import AreaService from '@/services/AreaService'
import OpportunityService from '@/services/OpportunityService'
import { RequestReturnType } from '@/services/RequestHandlers/NewRequest'
import SegmentationService from '@/services/SegmentationService'
import { SegmentationRequests } from '@/types/segmentation'
import { SegmentationItem, SegmentationItemWithMetrics } from '@/types/segmentation/Segmentation'
import { mapSegmentation } from '@/utils/segmentation'

interface Params {
  searchText: string
  limit?: number
  areaId?: string
  opportunityId?: string
  segmentations?: SegmentationItem[]
}

const getSegmentationsQueryFn = async ({
  searchText,
  limit = 1000,
  areaId,
  opportunityId
}: Params): Promise<RequestReturnType<SegmentationItemWithMetrics[]>> => {
  if (areaId) {
    const [error, response] = await AreaService.getAreaSegmentations(areaId)
    if (error) {
      return [error, undefined]
    }

    const data = response.map(
      (item): SegmentationItemWithMetrics => ({
        ...mapSegmentation(item),
        metrics: []
      })
    )
    return [undefined, data]
  }

  if (opportunityId) {
    const [error, response] = await OpportunityService.getOpportunitySegmentations(opportunityId)
    if (error) {
      return [error, undefined]
    }

    const data = response.map(
      (item): SegmentationItemWithMetrics => ({
        ...mapSegmentation(item),
        metrics: []
      })
    )
    return [undefined, data]
  }

  const searchParams: SegmentationRequests.GetSegmentationsParams = {
    limit,
    name: searchText
  }

  const [error, response] = await SegmentationService.getAll(searchParams)

  if (error) {
    return [error, undefined]
  }

  const data = response.segmentations.map(
    (item): SegmentationItemWithMetrics => ({
      ...mapSegmentation(item),
      metrics: []
    })
  )

  return [undefined, data]
}

export default getSegmentationsQueryFn
