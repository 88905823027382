// reference: https://codesandbox.io/s/objective-shape-8r4utm?file=/src/CalendarGrid.js

import { useCalendarGrid } from '@react-aria/calendar'
import { getWeeksInMonth, endOfMonth } from '@internationalized/date'
import { useLocale } from '@react-aria/i18n'

import type { RangeCalendarState } from '@react-stately/calendar'
import type { DateDuration } from '@internationalized/date'

import { CalendarCell } from './CalendarCell'
import { styled } from '@/theme'

const StyledTable = styled('table', {
  margin: 0
})

const Week = styled('th', {
  fontSize: 10,
  fontWeight: 'bold',
  color: '$neutralLowPure',
  paddingBottom: '8px'
})

export interface CalendarGridProps {
  state: RangeCalendarState
  offset?: DateDuration
}

export function CalendarGrid({ state, offset = {} }: CalendarGridProps) {
  const { locale } = useLocale()
  const startDate = state.visibleRange.start.add(offset)
  const endDate = endOfMonth(startDate)
  const { gridProps, headerProps, weekDays } = useCalendarGrid({ startDate, endDate }, state)

  const weeksInMonth = getWeeksInMonth(startDate, locale)
  const weeks = [...new Array(weeksInMonth).keys()]

  return (
    <StyledTable {...gridProps} cellPadding="0" cellSpacing={0}>
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            <Week key={index}>{day}</Week>
          ))}
        </tr>
      </thead>

      <tbody>
        {weeks.map(weekIndex => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex, startDate)
              .map((date, i) =>
                date ? (
                  <CalendarCell currentMonth={startDate} date={date} key={i} state={state} />
                ) : (
                  <td key={i} />
                )
              )}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}
