export const lineHeights = {
  default: 1,
  xs: 1.15,
  sm: 1.2,
  md: 1.33,
  lg: 1.5,
  xl: 1.6,
  xxl: 2
}

type LineHeightKeys = keyof typeof lineHeights
type LineHeightVariant = {
  [x in LineHeightKeys]: { lineHeight: string }
}
export const lineHeightVariant = Object.keys(lineHeights).reduce((acc, lineHeight) => {
  return { ...acc, [lineHeight]: { lineHeight: `$${lineHeight}` } }
}, {} as LineHeightVariant)
