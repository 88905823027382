import { StateCreator } from 'zustand'

const initialState = {
  data: [],
  total: 0,
  page: 0,
  error: null,
  isLoading: false,
  hasMore: true
}

export interface InifiniteScrollSlice<T> {
  data: T[]
  total: number
  page: number
  error: Error | null
  isLoading: boolean
  hasMore: boolean
  dataHasBeenLoaded: boolean

  setData: (data: T[]) => void
  setTotal: (total: number) => void
  push: (data: T[]) => void
  setError: (error: Error | null) => void
  setLoading: (value: boolean) => void
  setHasMore: (value: boolean) => void
  reset: () => void
}

const createInfiniteScrollSlice =
  <T>(): StateCreator<InifiniteScrollSlice<T>> =>
  set => ({
    ...initialState,

    dataHasBeenLoaded: false,

    setData: data =>
      set(state => ({
        ...state,
        data
      })),

    setTotal: total =>
      set(state => ({
        ...state,
        total
      })),

    push: data =>
      set(state => ({
        ...state,
        page: state.page + 1,
        data: [...state.data, ...data],
        dataHasBeenLoaded: true
      })),

    setError: error =>
      set(state => ({
        ...state,
        error
      })),

    setLoading: value =>
      set(state => ({
        ...state,
        isLoading: value
      })),

    setHasMore: value =>
      set(state => ({
        ...state,
        hasMore: value
      })),

    reset: () =>
      set(() => ({
        ...initialState,
        dataHasBeenLoaded: true
      }))
  })

export default createInfiniteScrollSlice
