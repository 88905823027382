import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { useTranslation } from 'react-i18next'
import { PencilSimple } from '@phosphor-icons/react'
import CollapsableEditableText from '../tiptap/CollapsableEditableText'
import { useUIStore } from '@/store'
import { shallow } from 'zustand/shallow'
import { useCallback, useMemo } from 'react'
import OverviewDetailField from './OverviewDetailField'
import useInitiativeStore from '@/store/initiatives/useInitiativeStore'
import { useInitiatives } from '@/hooks/initiatives/useInitiatives'
import useSegment from '@/hooks/useSegment'

const InitiativeOverview = () => {
  const { t } = useTranslation()
  const { track } = useSegment()

  const currentInitiative = useInitiativeStore(state => state.currentInitiative)

  const { updateInitiative } = useInitiatives({
    enabled: false
  })

  const saveDescription = useCallback(
    (description: string) => {
      if (currentInitiative?.id) {
        updateInitiative({
          description,
          initiativeId: currentInitiative.id
        })
      }
    },
    [currentInitiative, updateInitiative]
  )

  const descriptionEditOpen = useUIStore(state => state.descriptionEditOpen, shallow)
  const setDescriptionEditOpen = useUIStore(state => state.setDescriptionEditOpen)

  const onEditDescriptionClick = () => {
    track('initiative_edit_description')
    setDescriptionEditOpen(true)
  }

  const editDescriptionLabel = useMemo(() => {
    if (!currentInitiative) return
    if (!currentInitiative.description || !currentInitiative.description.length)
      return t('addDescription')
    if (descriptionEditOpen) return t('editing')

    return t('editDescription')
  }, [descriptionEditOpen, currentInitiative, t])

  return (
    <>
      <FlexContainer direction="column" gap="sm">
        <Text
          as="h2"
          color="brandPrimaryPure"
          css={{ fontSize: '$xs', lineHeight: 1.25, fontWeight: '$light' }}
        >
          {t('overview')}
        </Text>

        <FlexContainer justifyContent="start" fullWidth gap="micro">
          <FlexContainer direction="column" gap="xxs" css={{ width: '50%' }}>
            <OverviewDetailField />
          </FlexContainer>

          <FlexContainer direction="column" gap="xxs" css={{ width: '50%' }}>
            <FlexContainer alignItems="start" justifyContent="spaceBetween" gap="xxs" fullWidth>
              <Text color="brandPrimaryMedium" css={{ fontSize: '$micro', fontWeight: '$regular' }}>
                {t('description')}
              </Text>
              <Button
                data-state={descriptionEditOpen ? 'open' : 'closed'}
                onClick={onEditDescriptionClick}
                size="small"
                variant="white-bordered"
              >
                <PencilSimple size={16} />
                <Text
                  as="span"
                  fontSize="xxxs"
                  fontWeight="regular"
                  color="neutralLowPure"
                  css={{ whiteSpace: 'nowrap' }}
                >
                  {editDescriptionLabel}
                </Text>
              </Button>
            </FlexContainer>
            <CollapsableEditableText
              content={currentInitiative?.description ?? ''}
              editable={descriptionEditOpen}
              onEditableChange={setDescriptionEditOpen}
              onSave={saveDescription}
            />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </>
  )
}

export default InitiativeOverview
