import { useCallback, useEffect, useState } from 'react'
import { cloneObject } from '@/utils/object'
import TopicService from '@/services/TopicService'
import { TopicStatsPayload } from '@/types/classification/TopicRequests'
import DefaultErrorHandler from '@/services/DefaultError'
import { TopicData } from '@/types/classification'
import useDebounce from '../useDebounce'

interface Params {
  searchQuery: string
  enableSearchOnType?: boolean
  delay?: number
  defaultIsSearching?: boolean
}

const useUngroupedTopicsSearch = ({
  searchQuery,
  enableSearchOnType = true,
  delay = 300,
  defaultIsSearching = false
}: Params) => {
  const [results, setResults] = useState<TopicData[]>([])
  const [isSearching, setIsSearching] = useState(defaultIsSearching)
  const [error, setError] = useState<DefaultErrorHandler | null>(null)

  const deboucedSearch = useDebounce(searchQuery, delay)

  const search = useCallback(async (query: string) => {
    setIsSearching(true)
    const payload: TopicStatsPayload = cloneObject(TopicService.DEFAULT_PAYLOAD)
    payload.search_text = query
    payload.return_all = false
    const [error, data] = await TopicService.ungroupedTopics(payload)

    setIsSearching(false)
    if (error) {
      setResults([])
      setError(error)
      return []
    }

    setResults(data.items)
    setError(null)
    return data.items
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (deboucedSearch.length && enableSearchOnType) {
      search(deboucedSearch)
    }
  }, [deboucedSearch, enableSearchOnType])

  return { search, results, isSearching, error }
}

export default useUngroupedTopicsSearch
