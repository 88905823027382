import { keyframes, styled } from '@/theme'
import * as ToastPrimitive from '@radix-ui/react-toast'

const VIEWPORT_PADDING = 24
const VIEWPORT_SAFE_PADDING = 112

export const hide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 }
})

export const slideIn = keyframes({
  from: { transform: `translateY(calc(100% + ${VIEWPORT_PADDING}px))` },
  to: { transform: 'translateY(0)' }
})

export const swipeOut = keyframes({
  from: { transform: 'translateY(var(--radix-toast-swipe-end-y))' },
  to: { transform: `translateY(calc(100% + ${VIEWPORT_PADDING}px))` }
})

export const ToastViewport = styled(ToastPrimitive.Viewport, {
  position: 'fixed',
  bottom: 0,
  right: '50%',
  left: '50%',
  transform: 'translate(-50%, 0)',
  display: 'flex',
  flexDirection: 'column',
  padding: VIEWPORT_PADDING,
  gap: '$micro',
  alignItems: 'center',
  width: 'max-content',
  minWidth: 278,
  maxWidth: 720,
  margin: 0,
  listStyle: 'none',
  zIndex: 11235813,
  outline: 'none',

  variants: {
    safeArea: {
      true: {
        pb: VIEWPORT_SAFE_PADDING
      }
    }
  }
})

export const StyledToast = styled(ToastPrimitive.Toast, {
  height: 'auto',
  backgroundColor: '$dark',
  color: '$white',
  fontSize: '$xxxs',
  boxShadow: '$componentShadowLarge',
  display: 'flex',
  alignItems: 'center',
  padding: '$micro $xs',
  borderRadius: '$sm',
  gap: '$xxs',
  width: 'fit-content',

  a: {
    all: 'inherit',
    color: '$white',
    cursor: 'pointer',
    textDecoration: 'underline',
    width: 'fit-content'
  },

  p: {
    display: 'inline'
  },

  '@media (prefers-reduced-motion: no-preference)': {
    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
    },
    '&[data-state="closed"]': {
      animation: `${hide} 100ms ease-in`
    },
    '&[data-swipe="move"]': {
      transform: 'translateY(var(--radix-toast-swipe-move-y))'
    },
    '&[data-swipe="cancel"]': {
      transform: 'translateY(0)',
      transition: 'transform 200ms ease-out'
    },
    '&[data-swipe="end"]': {
      animation: `${swipeOut} 100ms ease-out`
    }
  }
})
