import { CSS, styled, typefaceVariants } from '@/theme'

const hideCSS: CSS = {
  transform: 'translateX(-100%)',
  opacity: 0,
  height: 0,
  my: -40,
  pointerEvents: 'none'
}

export const FeedbackContainer = styled('article', {
  fontFamily: '$default',
  display: 'flex',
  flexDirection: 'column',
  gap: '$xxs',
  padding: '$sm',
  background: '#FFFFFF',
  color: '$neutralLowPure',
  bBottom: '$stroke',
  bAll: '$neutralHighPure',
  br: '$micro',

  margin: 2,

  transitionDelay: '0s, .2s, .2s',
  transitionDuration: '.2s, .3s, .2s',
  transitionProperty: 'all, height, margin',

  a: {
    color: '$brandPrimaryPure',
    '&:visited': {
      color: '$feedbackInformativePure'
    }
  },

  '&:has(div[role="menu"])': {
    background: 'transparent'
  },

  p: {
    fontSize: '$xxxs',
    fontWeight: '$light',
    lineHeight: 1.7,
    // break large links
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word'
  },

  h4: {
    fontSize: '$xxxs',
    fontWeight: '$semibold'
  },

  em: {
    fontStyle: 'normal',
    fontWeight: 600,
    background: '$feedbackWarningMedium'
  },

  '&:first-of-type': {
    mt: '$xs'
  },

  '& .muted-feedback-text': {
    color: '$neutralLowLight'
  },

  variants: {
    isSelected: {
      true: {
        color: '$brandPrimaryPure',
        background: '$greyLight',
        bAll: '$brandPrimaryMedium'
      },
      false: {}
    },
    hasReplies: {
      true: {
        zIndex: 1,
        position: 'relative',
        mr: 9,
        background: '$white'
      }
    },
    isArchived: {
      true: {
        color: '$brandPrimaryMedium',
        border: '1px dashed $brandPrimaryMedium',

        '&::after': {
          content: 'This feedback was archived',
          ml: 'auto',
          mt: '-$xs',
          fontSize: 12,
          color: '$neutralLowMedium'
        }
      }
    },
    selected: {
      true: {
        outline: '2px solid $brandPrimaryPure'
      },
      false: {}
    },
    canSelect: {
      true: {
        '&:hover': {
          cursor: 'pointer',
          outline: '2px solid $brandPrimaryPure',
          boxShadow: '$softShadow'
        }
      },
      false: {}
    },
    dismissed: {
      true: {
        ...hideCSS
      },
      false: {}
    }
  }
})

export const FeedbackHeader = styled('header', {
  display: 'flex',
  alignItems: 'top',
  justifyContent: 'flex-end',
  gap: '$micro',
  userSelect: 'none',
  maxHeight: 24,

  variants: {
    isThreadItem: {
      true: {
        // mr: '$md'
      }
    }
  },

  ...typefaceVariants.subtitleSemiBoldMicro,
  fontSize: '$xxxs'
})

export const Group = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$micro',
  color: '$neutralLowLight',

  '&:first-child': {
    mr: 'auto'
  },

  'strong, b': {
    fontWeight: '$semibold'
  },

  '.replies': {
    color: '$dark',
    fontWeight: '$bold',
    cursor: 'pointer'
  }
})

export const FeedbackType = styled('b', {
  color: '$neutralLowPure'
})

export const FeedbackRating = styled('b', {
  alignItems: 'flex-start',
  color: '$neutralLowPure',
  display: 'flex',
  gap: '$nano'
})

export const FeedbackDate = styled('time', {
  fontWeight: '$regular'
})

export const TopicChipsContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$space$micro',
  minHeight: 24,
  overflow: 'hidden'
})

const compactModeCss: CSS = {
  color: 'transparent',
  padding: 0,
  height: 4,
  maxWidth: 24,
  transition: '.3s cubic-bezier(.53,.22,.59,.82)'
}

const feedbackChipCss: CSS = {
  backgroundColor: 'unset',
  border: 0,
  borderRadius: 4,
  color: '$neutralHighLight',
  fontFamily: '$default',
  fontSize: '$micro',
  fontWeight: '$semibold',
  padding: '$nano $xxxs',
  height: 24,
  maxWidth: 300,
  cursor: 'pointer',
  transition: '.3s cubic-bezier(.53,.22,.59,.82)',
  whiteSpace: 'nowrap',
  wordWrap: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'flex',
  gap: '$micro',

  '&[data-active=false]': {
    backgroundColor: '$neutralHighPure'
  },

  '& > span': {
    whiteSpace: 'nowrap',
    wordWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}

export const TopicChip = styled('button', {
  ...feedbackChipCss,

  '&[data-mode="compact"]': {
    ...compactModeCss
  },

  '& > svg': {
    minWidth: 16
  }
})

export const OptionsMenuButton: CSS = {
  all: 'unset',
  borderRadius: '50%',
  color: '$neutralLowPure',
  cursor: 'pointer',
  size: 16,
  minWidth: 16,
  transition: 'background-color .3s',
  zIndex: 1,

  '&:hover': {
    backgroundColor: '$brandPrimaryLight',
    color: '$brandPrimaryPure'
  },

  '&[data-state=open]': {
    backgroundColor: '$brandPrimaryPure',
    color: '$neutralHighLight'
  },

  '& svg': {
    size: '100%'
  }
}

export const FeedbackThreadHeader = styled('header', {
  fontSize: '$micro',
  color: '$grey',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '$micro',
  strong: {
    fontWeight: '$semibold',
    color: '$dark'
  }
})

export const FeedbackItemSeparator = styled('div', {
  width: '100%',
  height: 1,
  bBottom: '$neutralHighPure'
})

export const FeedbackAccountField = styled('b', {
  color: '$neutralLowPure'
})

export const FeedbackAccountFieldContainer = styled('div', {
  whiteSpace: 'nowrap',
  wordWrap: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  variants: {
    size: {
      small: {
        maxWidth: 138
      },
      regular: {
        maxWidth: 180
      }
    }
  }
})

export const FeedbackSearchCountBanner = styled('div', {
  borderRadius: 8,
  padding: '$xxs',
  background: '$brandPrimaryLight',
  fontSize: 14,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
})
