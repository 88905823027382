import Text from '@/components/atoms/text'
import { colors } from '@/theme'
import { ThemeItemData } from '@/types/classification/Theme'
import { CaretLeft } from '@phosphor-icons/react'
import { memo, MouseEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import * as S from './TopicGroupEditor.styles'
import SearchResultSection from './SearchResultSection'
import ThemesSection from './ThemesSection'
import TopicSearch from './TopicSearch'
import { TopicCategory, TopicDataWithCategory, TopicThemeItem } from '@/types/classification'
import CategoriesSection from './CategoriesSection'
import useSegment from '@/hooks/useSegment'
import KeywordLimit from '../keyword-creator/KeywordLimit'
import useUser from '@/hooks/useUser'
import useTopicsStore from '@/store/useTopicsStore'
import { useTranslation } from 'react-i18next'

/**
 * Called with the ids of topics to add
 *
 * If is single keyword, the already applied topics are excluded by default
 *
 * Second parameter is an array with the ids of topics removed by the user, if single keyword
 * multi keyword is empty array
 *
 */
type OnApplyStatus = 'error' | 'expansion_limit' | 'success'
export type OnApplyCallback = (
  selectedTopics: string[],
  removedTopics: string[]
) => Promise<OnApplyStatus>

export interface TopicGroupEditorProps {
  /** Topics already applied for the keyword, single keyword only */
  appliedGroups: TopicThemeItem[]
  renderApplyButton: (onApply: () => void, isDisabled: boolean) => ReactNode
  renderCancelButton: (onCancel: () => void) => ReactNode
  /** If true the user can add AND remove topics from a keyword, if false can only add topics */
  singleKeyword?: boolean
  /** Called when user clicks on Apply with the selected topics and the removed */
  onApply: OnApplyCallback
  onCancel?: () => void
  isOverflowing?: boolean
}

const TopicGroupEditor = ({
  appliedGroups,
  singleKeyword,
  onApply,
  onCancel,
  renderApplyButton,
  renderCancelButton,
  isOverflowing
}: TopicGroupEditorProps) => {
  const { track } = useSegment()

  const { userPermissions } = useUser()
  const isManager = userPermissions.topic.includes('manager')

  const [removedTopics, setRemovedTopics] = useState<TopicThemeItem[]>([])
  const { t } = useTranslation()

  const [searchQuery, setSearch] = useState('')

  const [currentList, setCurrentList] = useState<'categories' | 'themes' | 'topics' | 'searching'>(
    'categories'
  )
  const [currentCategory, setCurrentCategory] = useState<TopicCategory>('PRODUCT_AREA')
  const [currentTheme, setTheme] = useState<ThemeItemData | undefined>()
  const [selectedGroups, setSelectedGroups] = useState<TopicThemeItem[]>(
    singleKeyword ? appliedGroups : []
  )

  const categories: { category: TopicCategory; name: string }[] = [
    { category: 'PRODUCT_AREA', name: 'Product areas' },
    { category: 'OTHER', name: 'Other topics' }
  ]
  const currentCategoryName = currentCategory === 'OTHER' ? 'Other topics' : 'Product areas'

  const storedTopicGroups = useTopicsStore(state => state.topicGroups)

  const topicGroups = useMemo(() => {
    return storedTopicGroups.map(group => ({
      ...group,
      category: group.category || 'OTHER'
    })) as TopicDataWithCategory[]
  }, [storedTopicGroups])

  const themes = useMemo(
    () => topicGroups.filter(group => group.category === currentCategory),
    [currentCategory, topicGroups]
  )

  useEffect(() => {
    setSelectedGroups(singleKeyword ? appliedGroups : [])
  }, [appliedGroups, singleKeyword])

  const groupsToAdd = useMemo(
    () =>
      singleKeyword
        ? selectedGroups.filter(
            group => !appliedGroups.find(appliedGroup => appliedGroup.themeId === group.themeId)
          )
        : selectedGroups,
    [selectedGroups, appliedGroups, singleKeyword]
  )

  const [showLimitError, setShowLimitError] = useState(false)
  const [isApplyLoading, setIsApplyLoading] = useState(false)

  const onOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        setCurrentList('categories')
        setTheme(undefined)
        setSelectedGroups(singleKeyword ? appliedGroups : [])
        setRemovedTopics([])
        onCancel?.()
      }
    },
    [appliedGroups, singleKeyword, onCancel]
  )

  const handleOnApply = useCallback(async () => {
    track('add_topic_to_folder-apply')
    setIsApplyLoading(true)

    const status = await onApply(
      groupsToAdd.filter(group => group.themeId !== null).map(group => group.themeId) as string[],
      removedTopics.map(group => group.themeId)
    )

    if (status === 'expansion_limit') {
      setShowLimitError(true)
    } else {
      setIsApplyLoading(false)
    }

    onOpenChange(false)
  }, [groupsToAdd, removedTopics, onApply, onOpenChange, track])

  const isApplyDisabled = isApplyLoading || groupsToAdd.length === 0

  const onSearch = (value: string) => {
    if (searchQuery.length === 0 && value.length > 0) {
      track('add_topic_to_folder-search')
    }
    setSearch(value)
    setCurrentList(value.length ? 'searching' : 'categories')
  }

  const onClickCategory = (category: TopicCategory) => (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setCurrentList('themes')
    setCurrentCategory(category)
    setSearch('')
  }

  const onClickTheme = (theme: ThemeItemData) => (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setCurrentList('topics')
    setTheme(theme)
    setSearch('')
  }

  const returnToList = () => {
    if (currentList === 'themes') {
      setCurrentList('categories')
      return
    }

    if (currentList === 'topics') {
      setCurrentList('categories')
      setTheme(undefined)
    }
  }

  const handleOnCancel = () => {
    onOpenChange(false)
    setShowLimitError(false)
    setIsApplyLoading(false)
    onCancel?.()
  }

  const onCheckGroup = (topicGroup: TopicThemeItem) => (checked: boolean) => {
    setSelectedGroups(prevGroups =>
      checked
        ? [...prevGroups, topicGroup]
        : prevGroups.filter(prevGroup => prevGroup.themeId !== topicGroup.themeId)
    )
    if (!checked && appliedGroups.find(group => group.themeId === topicGroup.themeId)) {
      setRemovedTopics(prev => [...prev, topicGroup])
    }
  }

  const isGroupChecked = (groupId: string) =>
    !!selectedGroups.find(group => group.themeId === groupId)

  const onAddTopicGroup = (topicGroup: TopicThemeItem) => {
    setSelectedGroups(prevGroups => [...prevGroups, topicGroup])
  }

  const currentHeading = (() => {
    if (currentList === 'searching') return 'Results: '

    const name = currentTheme ? currentTheme.themeName : currentCategoryName

    return (
      <>
        <CaretLeft color={colors.grey} size={14} weight="bold" />
        <Text color="grey" title={name} truncate typeface="textRegularSmall">
          {name}
        </Text>
      </>
    )
  })()

  const title = singleKeyword ? t('addToTopicGroup') : t('addAllSelectedToTopicGroup')

  return (
    <S.Container>
      {isManager ? (
        <>
          <S.Header>{title}</S.Header>

          {showLimitError ? (
            <KeywordLimit />
          ) : (
            <>
              <TopicSearch onSearch={onSearch} searchQuery={searchQuery} />

              {currentList !== 'categories' && (
                <S.SectionTitleHeading>
                  <Text
                    as="button"
                    color="grey"
                    css={{
                      cursor: 'pointer'
                    }}
                    onClick={returnToList}
                    typeface="textRegularSmall"
                  >
                    {currentHeading}
                  </Text>
                </S.SectionTitleHeading>
              )}

              {currentList === 'categories' && (
                <CategoriesSection
                  categories={categories}
                  isOverflowing={isOverflowing}
                  onClickCategory={onClickCategory}
                />
              )}

              {currentList === 'themes' && (
                <ThemesSection
                  isOverflowing={isOverflowing}
                  isTopicGroupChecked={isGroupChecked}
                  onAddTopicGroup={onAddTopicGroup}
                  onCheckTopicGroup={onCheckGroup}
                  onClickTheme={onClickTheme}
                  topicCategory={currentCategory}
                  topicGroups={themes}
                />
              )}

              {currentList === 'searching' && (
                <SearchResultSection
                  isOverflowing={isOverflowing}
                  isTopicGroupChecked={isGroupChecked}
                  onCheckTopicGroup={onCheckGroup}
                  searchQuery={searchQuery}
                  topicGroups={topicGroups}
                />
              )}
            </>
          )}
        </>
      ) : null}

      {isManager ? (
        <S.Footer>
          {renderApplyButton(handleOnApply, isApplyDisabled)}
          {renderCancelButton(handleOnCancel)}
        </S.Footer>
      ) : null}
    </S.Container>
  )
}

export default memo(TopicGroupEditor)
