import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface ManageNotificationState {
  updatingNotifications: string[]
  setUpdatingNotifications: (updatingNotifications: string[]) => void
  pushUpdatingNotification: (value: string) => void
  removeUpdatingNotification: (value: string) => void
  resetUpdatingNotifications: () => void

  disableAll: boolean
  setDisableAll: (disableAll: boolean) => void
}

const useManageNotificationsStore = createWithEqualityFn<ManageNotificationState>(
  set => ({
    updatingNotifications: [],
    setUpdatingNotifications: updatingNotifications => set(() => ({ updatingNotifications })),
    pushUpdatingNotification: value =>
      set(state => ({ updatingNotifications: [...state.updatingNotifications, value] })),
    removeUpdatingNotification: value =>
      set(state => ({
        updatingNotifications: state.updatingNotifications.filter(v => v !== value)
      })),
    resetUpdatingNotifications: () => set(() => ({ updatingNotifications: [] })),

    disableAll: false,
    setDisableAll: disableAll => set(() => ({ disableAll }))
  }),
  shallow
)

export default useManageNotificationsStore
