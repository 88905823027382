import Dialog from '@/components/atoms/dialog'

import Text from '@/components/atoms/text'
import FlexContainer from '@/components/atoms/flex-container'
import { Fragment, ReactNode, useEffect, useState } from 'react'
import { CaretLeft } from '@phosphor-icons/react'
import useLetterCase from '@/hooks/useLetterCase'
import useAccountsStore from '@/store/useAccountsStore'
import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import {
  DetailOptionType,
  DetailItem,
  DetailTitle,
  DetailsHeader,
  DetailsScrollArea,
  DetailInnerTitle
} from '../Details'
import CollapsibleDetail from '../Details/CollapsibleDetail'
import { formatDateTime } from '@/utils/date'
import { useTranslation } from 'react-i18next'

function AccountDetails() {
  const { capitalizeFirst, snakeToTitle } = useLetterCase()

  const [currentOpen, setCurrentOpen] = useState<DetailOptionType | ''>('properties')
  const { t } = useTranslation()

  const detailedAccount = useAccountsStore(state => state.detailedAccount)
  const setDetailedAccount = useAccountsStore(state => state.setDetailedAccount)

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    return () => {
      setDetailedAccount(undefined)
    }
  }, [])

  const isOpen = (option: string) => currentOpen === option

  const onCollapseChange = (option: string) => (open: boolean) => {
    setCurrentOpen(open ? (option as DetailOptionType) : '')
  }

  const onOpenChange = (open: boolean) => {
    setDetailedAccount(open ? detailedAccount : undefined)

    /*
     * Fix for Dropdown Menu + Dialog bug
     * pointer-events was not being removed from body when FeedbackDetails was closed, leaving the page noninteractive
     */
    if (!open) {
      const observer = new MutationObserver((_, observer) => {
        document.body.removeAttribute('style')
        observer.disconnect()
      })

      observer.observe(document.body, { attributeFilter: ['style'] })
    }
  }

  const renderInnerFields = (field: Record<string, unknown>): ReactNode[] => {
    const innerNodes = Object.entries(field).map(([key, value]) => {
      const name = capitalizeFirst(snakeToTitle(key))
      if (['string', 'number'].includes(typeof value)) {
        return (
          <Fragment key={key}>
            <DetailInnerTitle>
              {name}: {value as string | number}
            </DetailInnerTitle>
          </Fragment>
        )
      }

      if (typeof value === 'object' && !Array.isArray(value)) {
        return renderInnerFields(value as Record<string, unknown>)
      }

      return (
        <Fragment key={key}>
          <DetailInnerTitle>{name}</DetailInnerTitle>
          <p>{t('unkwownValue')}</p>
        </Fragment>
      )
    })

    return innerNodes
  }

  const renderOtherFields = () => {
    if (!detailedAccount?.otherFields) return undefined

    const { otherFields } = detailedAccount

    const nodes = Object.entries(otherFields).map(([key, value]) => {
      const name = capitalizeFirst(snakeToTitle(key))

      if (!value) return <></>

      if (['string', 'number'].includes(typeof value)) {
        return (
          <DetailItem key={key}>
            <DetailTitle>{name}</DetailTitle>
            <p>{value as string | number}</p>
          </DetailItem>
        )
      }

      if (typeof value === 'object') {
        return (
          <DetailItem key={key}>
            <DetailTitle>{name}</DetailTitle>
            <p>{renderInnerFields(value as Record<string, unknown>)}</p>
          </DetailItem>
        )
      }

      if (typeof value === 'boolean') {
        const displayValue = value ? 'True' : 'False'
        return (
          <DetailItem key={key}>
            <DetailTitle>{name}</DetailTitle>
            <p>{displayValue}</p>
          </DetailItem>
        )
      }

      return (
        <DetailItem key={key}>
          <DetailTitle>{name}</DetailTitle>
          <p>{t('unkwownValue')}</p>
        </DetailItem>
      )
    })

    return nodes
  }

  const renderCustomFields = (() => {
    if (!detailedAccount?.customFields) return undefined

    const { customFields } = detailedAccount

    return Object.entries(customFields).map(([key, data]) => {
      const name = capitalizeFirst(snakeToTitle(key))
      let value = data.value
      if (typeof data.value === 'boolean') {
        value = data.value ? 'True' : 'False'
      }
      return (
        <DetailItem key={key}>
          <DetailTitle>{name}</DetailTitle>
          <p>{value}</p>
        </DetailItem>
      )
    })
  })()

  if (!detailedAccount) return <></>

  return (
    <Dialog
      align="right"
      closeIcon
      modal
      onOpenChange={onOpenChange}
      open={!!detailedAccount}
      useFullHeight
    >
      <FlexContainer
        css={{ height: '100%', boxSizing: 'border-box', overflow: 'hidden' }}
        direction="column"
      >
        <DetailsHeader>
          <CaretLeft onClick={() => onOpenChange(false)} size={16} weight="bold" />
          <Text as="h4" fontSize="xxxs" fontWeight="semibold" lineHeight="md">
            {t('accountDetails')}
          </Text>
        </DetailsHeader>
        <DetailsScrollArea type="auto">
          <ScrollAreaViewport>
            <CollapsibleDetail isOpen={isOpen} onOpenChange={onCollapseChange} option="properties">
              <FlexContainer direction="column" gap="micro">
                <DetailItem>
                  <DetailTitle>{t('ID')}</DetailTitle>
                  <p>{detailedAccount.id}</p>
                </DetailItem>

                <DetailItem>
                  <DetailTitle>{t('ingestedID')}</DetailTitle>
                  <p>{detailedAccount.ingestedId}</p>
                </DetailItem>

                <DetailItem>
                  <DetailTitle>{t('importedDate')}</DetailTitle>
                  <p>{formatDateTime(detailedAccount.importedAt.replace('Z', ''))}</p>
                </DetailItem>

                <DetailItem>
                  <DetailTitle>{t('customerType')}</DetailTitle>
                  <p>{detailedAccount.customerType}</p>
                </DetailItem>

                {renderOtherFields()}
              </FlexContainer>
            </CollapsibleDetail>

            {renderCustomFields && renderCustomFields.length > 0 && (
              <CollapsibleDetail
                isOpen={isOpen}
                onOpenChange={onCollapseChange}
                option="custom_fields"
              >
                <FlexContainer direction="column" gap="micro">
                  {renderCustomFields}
                </FlexContainer>
              </CollapsibleDetail>
            )}

            <ScrollAreaScrollbar orientation="vertical">
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
          </ScrollAreaViewport>
        </DetailsScrollArea>
      </FlexContainer>
    </Dialog>
  )
}

export default AccountDetails
