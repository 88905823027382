import AnalyticsService from '@/services/AnalyticsService'
import { useFiltersStore } from '@/store'
import { useQuery } from '@tanstack/react-query'
import useSelectedFilters from '../filters/useSelectedFilters'
import { shallow } from 'zustand/shallow'

const useAnalyticsIndicators = () => {
  const toFeedbackFilterSchema = useFiltersStore(state => state.toAnalyticsFeedbackFilterSchema)
  const { allSelectedFilters } = useSelectedFilters()

  const { volumeBy } = useFiltersStore(state => ({ volumeBy: state.volumeBy }), shallow)

  const queryFn = async () => {
    const feedbackFilter = toFeedbackFilterSchema()
    const [error, data] = await AnalyticsService.indicators({
      feedback_search: feedbackFilter,
      labeling_exact_count: true,
      group_by_exact_count: false,
      posted_at_exact_count: true,
      topic_search_text: feedbackFilter.filter?.search_text ?? '',
      metric_feedback: true,
      metric_customer: false,
      metric_intentions: ['Issue', 'Request']
    })

    if (error) {
      throw error
    }

    return data
  }

  const { data, isLoading, isError, isRefetching } = useQuery({
    queryKey: ['analytics', 'indicators', volumeBy, allSelectedFilters],
    queryFn
  })

  return {
    data,
    isLoading,
    isError,
    isRefetching
  }
}

export default useAnalyticsIndicators
