import * as SelectPrimitive from '@radix-ui/react-select'
import { styled, CSS, typefaceVariants } from '@/theme'
import { slideAndFadeOpen } from '@/theme/animations'

export const StyledTrigger = styled(SelectPrimitive.SelectTrigger, {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  bAll: '$neutralHighPure',
  background: '$neutralHighLight',
  br: '$micro',
  boxSizing: 'border-box',
  cursor: 'pointer',
  height: 40,
  transition: '.2s',

  ...typefaceVariants.subtitleRegularXXXS,
  whiteSpace: 'nowrap',
  wordWrap: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: '$dark',
  pAll: '$xxxs $xxs',
  gap: '$micro',

  position: 'relative',

  '.caret': {
    transition: '.2s',
    transform: 'rotate(0)',
    position: 'absolute',
    top: 9,
    right: 16
  },

  '&:hover': {
    backgroundColor: '$brandPrimaryLight',
    color: '$brandPrimaryPure'
  },

  '&[data-placeholder]': {
    color: '$neutralLowLight'
  },

  '&[data-disabled]': {
    cursor: 'not-allowed',
    color: '$neutralLowLight',
    backgroundColor: '$neutralHighMedium'

    // '&:hover': {
    //   backgroundColor: 'transparent',
    //   bAll: '$stroke'
    // }
  },

  '&:active': {
    backgroundColor: '$brandPrimaryLight',
    color: '$brandPrimaryPure'
  },

  '&:focus-visible': {
    bAll: '$brandPrimaryPure',
    outline: '1px solid $brandPrimaryPure'
  },

  '&[data-state="open"]': {
    '.caret': {
      transform: 'rotate(180deg)'
    }
  },

  // '&[data-state="open"]': {
  //   background: '$brandPrimaryPure',
  //   color: '$neutralHighLight'
  // },

  variants: {
    core: {
      true: {
        border: 'none',
        background: 'transparent',
        borderRadius: 'none',
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }
    },
    small: {
      true: {
        height: 32,
        pAll: '0 $xxxs'
      }
    },
    noBorder: {
      true: {
        border: 'none',
        '&:hover': {
          border: 'none'
        }
      }
    }
  }
})

export const StyledContent = styled(SelectPrimitive.Content, {
  display: 'flex',
  flexDirection: 'column',
  minWidth: 'var(--radix-select-trigger-width)',
  maxHeight: 'var(--radix-select-content-available-height)',

  color: '$dark',

  borderRadius: 16,
  bAll: '$neutralHighPure',
  background: '$white',
  padding: 4,
  overflow: 'hidden',
  zIndex: 99999,
  // position: 'relative',
  boxSizing: 'border-box',
  boxShadow: '$soft',
  ...slideAndFadeOpen
})

export const StyledSelectItem = styled(SelectPrimitive.Item, {
  all: 'unset',
  padding: '8px 12px',
  borderRadius: 12,
  fontSize: 14,
  bAll: 'transparent',
  cursor: 'pointer',
  // width: 'var(--radix-select-trigger-width)',

  '&:focus-visible': {
    backgroundColor: '$greenLight',
    bAll: '$stroke'
  },

  '&:hover': {
    backgroundColor: '$greenLight',
    bAll: 'transparent',
    boxShadow: 'none'
  },

  '&[data-state="checked"]': {
    fontWeight: '$semibold',
    color: '$neutralLowPure'
  },

  '&[data-disabled]': {
    color: '$neutralLowLight',
    cursor: 'not-allowed',

    '&:hover': {
      backgroundColor: 'transparent'
    }
  },

  variants: {
    hasIcon: {
      true: {
        display: 'flex',
        alignItems: 'center',
        gap: '$micro'
      }
    },
    small: {
      true: {
        padding: '6px 10px'
      }
    }
  }
})

export const StyledViewport = styled(SelectPrimitive.Viewport, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$nano'
})

export const scrollButtonStyles: CSS = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

export const StyledScrollUpButton = styled(SelectPrimitive.ScrollUpButton, scrollButtonStyles)
export const StyledScrollDownButton = styled(SelectPrimitive.ScrollDownButton, scrollButtonStyles)

export const StyledItemIndicator = styled(SelectPrimitive.SelectIcon, {
  color: '$pajarito',
  height: 16
})

export const StyledSelectValue = styled(SelectPrimitive.SelectValue, {})
