import { APP_ENVIRONEMNT } from '@/config'
import { TableauItem } from '@/types/tableau/Tableau'
import { TableauGraphicsResponse } from '@/types/tableau/TableauRequests'
import axios from 'axios'
import Request from './Request'

export default class TableauService {
  static async ticket(): Promise<string> {
    return (await (
      await axios.get('https://tableau-ticket.birdie.ai/ticket_insights')
    ).data) as string
  }

  static async sync(oranizationId: string) {
    return await Request.post(
      `/tableau?organization_id=${oranizationId}&environment=${APP_ENVIRONEMNT}`
    )
  }

  static async graphics(): Promise<TableauItem[]> {
    const data = await Request.get<TableauGraphicsResponse>('/tableau/graphics')
    return data.map(item => ({
      tableauId: item.tableau_id,
      description: item.description,
      height: item.height ?? undefined,
      name: item.name,
      path: item.path
    }))
  }
}
