import { colors, keyframes, styled } from '@/theme'

const spin = keyframes({
  '0%': { transform: 'rotate(0)' },
  '100%': { transform: 'rotate(360deg)' }
})

const spinnerWidth = 4

const Loader = styled('div', {
  borderWidth: spinnerWidth,
  borderStyle: 'solid',
  borderRadius: '50%',
  animation: `${spin} 1s linear infinite`,
  position: 'relative',
  margin: 0,

  variants: {
    size: {
      xs: {
        size: 10,
        borderWidth: 2
      },
      small: {
        size: 14,
        borderWidth: 2
      },
      regular: {
        size: 24
      },
      large: {
        size: 32
      }
    },
    color: {
      pajarito: {
        borderColor: `${colors.pajarito} ${colors.pajarito} ${colors.pajarito} transparent`
      },
      white: {
        borderColor: `${colors.white} ${colors.white} ${colors.white} transparent`
      }
    }
  },

  defaultVariants: {
    size: 'regular',
    color: 'pajarito'
  }
})

export default Loader
