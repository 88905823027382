import FlexContainer from '@/components/atoms/flex-container'
import Illustration from '@/assets/drawings/bird_empty_settings.png'
import { useTranslation } from 'react-i18next'
import Text from '@/components/atoms/text'
import Button from '@/components/atoms/button'

type Props = {
  onAddFirstOpportunityClick: () => void
}

const AssociatedOpportunityEmpty = ({ onAddFirstOpportunityClick }: Props) => {
  const { t } = useTranslation()
  return (
    <FlexContainer alignItems="center" css={{ mt: '$sm', mb: '$xs' }} direction="column" gap="sm">
      <img
        alt="A black and white image of a bird and gears."
        height={251}
        width={399}
        src={Illustration}
      />
      <FlexContainer alignItems="center" direction="column" gap="micro">
        <Text typeface="titleBoldXS">{t('emptyAssociatedOpportunityTitle')}</Text>
        <Text>{t('emptyAssociatedOpportunityDescription')}</Text>
        <Button onClick={onAddFirstOpportunityClick} css={{ m: '16px' }}>
          {t('addOpportunity')}
        </Button>
      </FlexContainer>
    </FlexContainer>
  )
}

export default AssociatedOpportunityEmpty
