import IndicatorCardCarousel from '@/components/molecules/analytics/topic-details/IndicatorCardCarousel'
import { IndicatorCardProps } from '@/components/molecules/analytics/topic-details/TopicDetails.types'
import TopicIntentionIndicator from '@/components/molecules/analytics/topic-details/TopicIntentionIndicator'
import { TrendingTopicType } from '@/types/analytics/TrendingTopic'
import { Bug, ChartLineUp, Icon, Megaphone } from '@phosphor-icons/react'

interface TrendingTopicTypeMapItem {
  title: string
  Icon: Icon
  IndicatorComponent: (props: IndicatorCardProps) => JSX.Element
  intentions: string[]
}

export const trendingTopicTypeMap: Record<TrendingTopicType, TrendingTopicTypeMapItem> = {
  emerging: {
    title: 'Emerging topics',
    Icon: ChartLineUp,
    IndicatorComponent: IndicatorCardCarousel,
    intentions: ['Issue', 'Request', 'Question', 'Compliment']
  },
  topIssues: {
    title: 'Top issues',
    Icon: Bug,
    IndicatorComponent: TopicIntentionIndicator.Issue,
    intentions: ['Issue']
  },
  topRequests: {
    title: 'Top requests',
    Icon: Megaphone,
    IndicatorComponent: TopicIntentionIndicator.Request,
    intentions: ['Request']
  }
}

export const allTrendingTopicType: TrendingTopicType[] = ['emerging', 'topIssues', 'topRequests']
export const isTrendingTopicType = (value: string) =>
  allTrendingTopicType.includes(value as TrendingTopicType)
