import { useEffect, useMemo } from 'react'
import { Plus, Question } from '@phosphor-icons/react'

import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import { SelectOption } from '@/components/atoms/select'
import { Table, TableCell, TableHeader } from '@/components/atoms/table'
import Text from '@/components/atoms/text'
import Tooltip from '@/components/atoms/tooltip'
import { getPathsFromObject, getValueFromPath } from '@/components/molecules/integrations/utils'
import useCustomFieldsStore from '@/store/useCustomFieldsStore'
import { FormStepSection } from '../../IntegrationForm.styles'
import { CustomFieldMapPair } from '../ReviewStep'
import CustomFieldsRow from './CustomFieldsRow'
import { useTranslation } from 'react-i18next'

interface CustomFieldsSectionProps {
  createCustomFieldMapPair(): void
  customFieldsMap: CustomFieldMapPair[]
  integrationName: string
  integrationSample: Record<string, unknown>
  removeCustomFieldMapPair(position: number): () => void
  updateCustomFieldMapPair(
    position: number
  ): (field: keyof CustomFieldMapPair, value: string) => void
}

export type GroupedSelectOptions = Record<string, (SelectOption & { tooltip: string })[]>

function CustomFieldsSection(props: CustomFieldsSectionProps) {
  const customFields = useCustomFieldsStore(state => state.customFields)
  const loadCustomFields = useCustomFieldsStore(state => state.loadCustomFields)

  const { t } = useTranslation()
  useEffect(() => {
    if (customFields.length === 0) {
      loadCustomFields()
    }
  }, [customFields.length, loadCustomFields])

  const integrationFieldOptions: GroupedSelectOptions = useMemo(() => {
    const pathStrings = getPathsFromObject(props.integrationSample)
    const groupedOptions = pathStrings.reduce((group, pathString) => {
      const path = pathString.split('|')
      const collection = path[0]

      const value = getValueFromPath(props.integrationSample, path)

      const displayText = `${path.at(-1)}: ${value}`
      const tooltipText = `${path.join(' | ')}: ${value}`

      group[collection] = group[collection] ?? []
      group[collection].push({
        text: displayText,
        tooltip: tooltipText,
        value: path.join('|')
      })

      return group
    }, {} as GroupedSelectOptions)

    return groupedOptions
  }, [props.integrationSample])

  const customFieldOptions: SelectOption[] = customFields
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(customField => ({
      text: customField.name,
      value: customField.id
    }))

  const disabledAddFieldButton =
    props.customFieldsMap.length >= customFields.length ||
    props.customFieldsMap.some(pair => pair.field_id === undefined || pair.key === undefined)

  return (
    <FormStepSection>
      <FlexContainer alignItems="center" css={{ gap: 4 }}>
        <Text as="h2" css={{ lineHeight: 1.25 }} typeface="titleBoldXXS">
          {t('customFields')}
        </Text>
        <Tooltip
          side="right"
          text={t('customFieldsAllowsYouToAddPersonalizedAdditionalData')}
          variant="small"
        >
          <Question size={16} weight="fill" />
        </Tooltip>
      </FlexContainer>

      <Table>
        <thead>
          <tr>
            <TableHeader css={{ width: 324 }}>
              {props.integrationName} {t('fields')}
            </TableHeader>
            <TableHeader css={{ width: 56 }} />
            <TableHeader css={{ width: 324 }}>{t('birdieFields')}</TableHeader>
            <TableHeader aria-hidden />
          </tr>
        </thead>
        <tbody>
          {props.customFieldsMap.map((mapPair, index) => (
            <CustomFieldsRow
              key={`${index}_${mapPair.field_id}`}
              onChange={props.updateCustomFieldMapPair(index)}
              onRemove={props.removeCustomFieldMapPair(index)}
              options={[integrationFieldOptions, customFieldOptions]}
              selectedValues={props.customFieldsMap}
              value={mapPair}
            />
          ))}
          <tr>
            <TableCell colSpan={4}>
              <FlexContainer justifyContent="center">
                <Button
                  disabled={disabledAddFieldButton}
                  onClick={props.createCustomFieldMapPair}
                  type="button"
                  variant="flat"
                >
                  <Plus size={12} /> {t('addField')}
                </Button>
              </FlexContainer>
            </TableCell>
          </tr>
        </tbody>
      </Table>
    </FormStepSection>
  )
}

export default CustomFieldsSection
