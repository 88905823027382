import FlexContainer from '@/components/atoms/flex-container'
import { savedViewBarHeight, styled, topBarHeight } from '@/theme'

const width = 400

export const FiltersAreaContainer = styled('div', {
  maxWidth: width,
  minWidth: width,
  display: 'flex',
  flexDirection: 'column',
  transition: '.2s',
  bRight: '$neutralHighPure',
  opacity: 1,
  background: 'white',
  zIndex: 1,
  overflow: 'visible',
  position: 'sticky',
  top: 0,
  height: '100%',

  variants: {
    collapsed: {
      true: {
        maxWidth: 0,
        minWidth: 0,
        overflow: 'hidden'
      }
    },
    opportunityPlan: {
      true: {
        bRight: '$neutralHighPure'
      }
    }
  },

  compoundVariants: [
    {
      collapsed: true,
      opportunityPlan: true,
      css: {
        bRight: '$brandPrimaryLight'
      }
    }
  ]
})

export const FiltersAreaInnerContent = styled(FlexContainer, {
  maxWidth: width,
  minWidth: width,
  maxHeight: `calc(100vh - ${savedViewBarHeight + topBarHeight}px)`,
  px: '$xs',
  pt: '$xs',
  overflow: 'hidden'
})
