import useLetterCase from '@/hooks/useLetterCase'
import ReasonsService from '@/services/ReasonsService'
import { useFiltersStore, useReasonsFeedbackStore } from '@/store'
import { Intention, Reason } from '@/types/reasons'
import { useCallback, useMemo, useState } from 'react'
import { shallow } from 'zustand/shallow'
import { DiscoveryGrid } from './Discovery.styles'
import ReasonDialog from './ReasonDialog'
import useReasons from '@/hooks/useReasons'
import SeeMoreDialog from './SeeMoreDialog'
import useSelectedFilters from '@/hooks/filters/useSelectedFilters'
import useToastMessageStore from '@/store/useToastMessageStore'
import useSegment from '@/hooks/useSegment'
import { useFeedbacks } from '@/hooks/feedback/useFeedbacks'
import { QueryFunctionContext, UseQueryResult, useQuery } from '@tanstack/react-query'
import DiscoveryItem from './DiscoveryItem'
import { MergeFindingProps } from './Discovery.types'
import ReasonsTextsDialog from './ReasonsTextsDialog'
import useLogging from '@/hooks/useLogging'

type QueryKeyParams = [string, { intention: Intention; [x: string]: unknown }]

const getFindingTypePropery = (intention: Intention) =>
  intention === 'issue' ? 'issues' : intention + 's'

const intentionList: Intention[] = ['issue', 'request', 'question', 'compliment']

const Discovery = ({ showMergeFindingsCheckbox }: MergeFindingProps) => {
  const { track } = useSegment()
  const { logException } = useLogging({ context: 'discovery' })

  const { reset } = useFeedbacks({ archived: false, store: 'reasons' })
  const setSelectedFeedback = useReasonsFeedbackStore(state => state.setSelectedFeedback)

  const toRequestPayload = useFiltersStore(state => state.toReasonsRequestInputSchema)
  const [selectedIntention, setSelectedIntention] = useState<Intention | null>(null)
  const [reasonTexts, setReasonsTexts] = useState<Reason | undefined>()

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const {
    reasons,
    selectedReason,
    selectReason,
    isLoading,
    isLoadingChart,
    trendSeries,
    fetchReasonsByIntention
  } = useReasons()

  const { capitalizeFirst } = useLetterCase()

  const searchFilters = useFiltersStore(
    state => ({
      dateRange: state.dateRange,
      orderBy: state.orderBy,
      sortDirection: state.sortDirection,
      volumeBy: state.volumeBy,
      search: state.search,
      filtersByURL: state.filtersByURL,
      accounts: Object.values(state.accountsStringFilters).flatMap(value => value.selected),
      accountsDateFilters: state.accountsDateFilters.filter(filter => filter.selected !== null),
      accountsNumericFilters: state.accountNumericFilters
        .filter(filter => filter.option !== 'all')
        .map(filter => ({ key: filter.key, value: filter.value, option: filter.option })),
      accountsBooleanFilters: state.accountBooleanFilters.filter(filter => filter.value !== null),
      users: Object.values(state.usersStringFilters).flatMap(value => value.selected),
      usersDateFilters: state.usersDateFilters.filter(filter => filter.selected !== null),
      usersNumericFilters: state.usersNumericFilters
        .filter(filter => filter.option !== 'all')
        .map(filter => ({ key: filter.key, value: filter.value, option: filter.option })),
      usersBooleanFilters: state.usersBooleanFilters.filter(filter => filter.value !== null)
    }),
    shallow
  )

  const {
    selectedNumericFilters,
    selectedDatetimeFilters,
    selectedStringFilters,
    selectedTopicFilters
  } = useSelectedFilters()

  const loadReasonsByIntention = useCallback(
    async ({ queryKey, signal }: QueryFunctionContext<QueryKeyParams>) => {
      const { intention } = queryKey[1]

      const inputSchema = toRequestPayload()

      inputSchema.filter.feedback_filter.feedback_keyword_classes = [capitalizeFirst(intention)]

      const [error, data] = await ReasonsService.getClusterReason(
        {
          search_input_schema: inputSchema,
          search_text: inputSchema.filter.search_text,
          keywords_to_cluster: [],
          approximate_count: true,
          date_range: 90,
          offset: 0,
          size: showMergeFindingsCheckbox ? 100 : 6,
          sort_type: 'F'
        },
        signal
      )

      if (error) {
        if (error.isCanceledError) throw error
        const message = `Error while fetching Findings with intention "${capitalizeFirst(
          intention
        )}".`
        logException(error, { message })

        addErrorToast({
          text: `${message} Message: ${error.key}`
        })
        throw error
      }

      return data?.map(ReasonsService.searchResultToReason) || []
    },
    [toRequestPayload, showMergeFindingsCheckbox, addErrorToast, capitalizeFirst, logException]
  )

  const filters = useMemo(
    () => ({
      selectedNumericFilters,
      selectedDatetimeFilters,
      selectedStringFilters,
      selectedTopicFilters,
      showMergeFindingsCheckbox,
      ...searchFilters
    }),
    [
      selectedNumericFilters,
      selectedDatetimeFilters,
      selectedStringFilters,
      selectedTopicFilters,
      searchFilters,
      showMergeFindingsCheckbox
    ]
  )

  const complimentsQuery = useQuery({
    queryKey: ['findings', { intention: 'compliment', ...filters }],
    queryFn: loadReasonsByIntention,
    retry: false
  })

  const issuesQuery = useQuery({
    queryKey: ['findings', { intention: 'issue', ...filters }],
    queryFn: loadReasonsByIntention,
    retry: false
  })

  const requestsQuery = useQuery({
    queryKey: ['findings', { intention: 'request', ...filters }],
    queryFn: loadReasonsByIntention,
    retry: false
  })

  const questionsQuery = useQuery({
    queryKey: ['findings', { intention: 'question', ...filters }],
    queryFn: loadReasonsByIntention,
    retry: false
  })

  const informationQuery = useQuery({
    queryKey: ['findings', { intention: 'information', ...filters }],
    queryFn: loadReasonsByIntention,
    retry: false,
    enabled: false
  })

  const solutionQuery = useQuery({
    queryKey: ['findings', { intention: 'solution', ...filters }],
    queryFn: loadReasonsByIntention,
    retry: false,
    enabled: false
  })

  const mapQueries: Record<Intention, UseQueryResult<Reason[], unknown>> = useMemo(
    () => ({
      compliment: complimentsQuery,
      issue: issuesQuery,
      request: requestsQuery,
      question: questionsQuery,
      information: informationQuery,
      solution: solutionQuery
    }),
    [complimentsQuery, issuesQuery, requestsQuery, questionsQuery, informationQuery, solutionQuery]
  )

  const onClickSeeMore = (intention: Intention) => () => {
    const findingType = getFindingTypePropery(intention)
    track('findings_view_list', { source_page: 'discovery', finding_type: findingType })

    setSelectedIntention(intention)
  }

  const onClickReason = (value: Reason | null) => {
    selectReason(value)
    if (value) {
      const findingType = getFindingTypePropery(value.intention)
      track('findings_view_detail', { source_page: 'discovery', finding_type: findingType })
      if (value.id !== selectedReason?.id) {
        reset()
        setSelectedFeedback(null)
      }
    }
  }

  const onViewTexts = useCallback((reason: Reason) => {
    setReasonsTexts(reason)
  }, [])

  const onCloseViewText = useCallback(() => {
    setReasonsTexts(undefined)
  }, [])

  const refetchByIntention = useCallback(
    async (intention: Intention) => {
      const { refetch } = mapQueries[intention]

      refetch()
    },
    [mapQueries]
  )

  return (
    <DiscoveryGrid id="discovery-grid">
      {intentionList.map(intention => {
        const queryResult = mapQueries[intention]
        const { data, isLoading, isRefetching, refetch } = queryResult

        return (
          <DiscoveryItem
            data={data || []}
            intention={intention}
            isLoading={isLoading}
            isRefetching={isRefetching}
            key={intention}
            onClickReason={onClickReason}
            onClickSeeMore={onClickSeeMore(intention)}
            onViewTexts={onViewTexts}
            refetch={refetch}
            showMergeFindingsCheckbox={showMergeFindingsCheckbox}
          />
        )
      })}

      <ReasonDialog
        isLoadingChart={isLoadingChart}
        selectReason={selectReason}
        selectedIntention={selectedIntention}
        selectedReason={selectedReason}
        trendSeries={trendSeries}
      />

      {selectedIntention && (
        <SeeMoreDialog
          fetchReasonsByIntention={fetchReasonsByIntention}
          isLoading={isLoading}
          isLoadingChart={isLoadingChart}
          onClose={() => setSelectedIntention(null)}
          reasons={reasons}
          selectReason={selectReason}
          selectedIntention={selectedIntention}
          selectedReason={selectedReason}
          trendSeries={trendSeries}
        />
      )}

      <ReasonsTextsDialog
        onClose={onCloseViewText}
        refetchByIntention={refetchByIntention}
        selectedReason={reasonTexts}
      />
    </DiscoveryGrid>
  )
}

export default Discovery
