import { CSS } from '@/theme'
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'
import { StyledItem, StyledToggleGroup } from './ToggleGroup.styles'
import { ReactNode } from 'react'
import { IconContext } from '@phosphor-icons/react'

export interface ToggleGroupOption {
  text: string
  icon?: ReactNode
  value: string
}

export interface ToggleGroupProps
  extends Omit<ToggleGroupPrimitive.ToggleGroupSingleProps, 'type'> {
  options: ToggleGroupOption[]
  css?: CSS
}

const ToggleGroup = ({ options, ...props }: ToggleGroupProps) => {
  return (
    <StyledToggleGroup type="single" {...props}>
      {options.map(option => (
        <StyledItem key={option.value} value={option.value}>
          <IconContext.Provider value={{ size: 16 }}>{option.icon}</IconContext.Provider>
          <span>{option.text}</span>
        </StyledItem>
      ))}
    </StyledToggleGroup>
  )
}

export default ToggleGroup
