import { FeedbackFilter } from '../filters/FilterRequests'

export enum TimeSeriesField {
  All = 'feedback',
  FeedbackKeywordClasses = 'feedback_keyword_classes',
  DataType = 'data_type',
  Sentiment = 'sentiment',
  Topics = 'topics',
  Themes = 'themes'
}

export interface TimeSeriesPayload {
  feedback_search?: FeedbackFilter
  feedback_search_list?: FeedbackFilter[]
  fields: (TimeSeriesField | string)[]
  exact_count?: boolean
  interval?: string
  calculate_percentage?: boolean
  topics_size?: number
}

interface TimeSeriesItem {
  field: string
  values: {
    field_value: string
    data: {
      posted_at: string
      count_feedback: number
      count_feedback_without_filter: number
      percentage_feedback: number
    }[]
  }[]
}

export interface TimeSeriesResponse {
  took: number
  time_series: TimeSeriesItem[]
  time_series_list: TimeSeriesItem[][]
}

export interface IndicatorsPayload {
  feedback_search?: FeedbackFilter
  labeling_exact_count?: boolean
  group_by_exact_count?: boolean
  posted_at_exact_count?: boolean
  topic_search_text: string
  metric_feedback: boolean
  metric_customer: boolean
  metric_intentions: string[]
}

export interface IndicatorsResponse {
  took: number
  metric_feedback: {
    count_feedback_previous: number
    count_feedback_current: number
    percentage_feedback: number
  }
  metric_customer: {
    total_customer: number
    percentage_customer: number
  }
  metric_intentions: {
    intention: string
    count_feedback_previous: number
    count_feedback_current: number
    percentage_feedback: number
    percentage_intention: number
    percentage_details: number
  }[]
  posted_at_current: {
    gte: string
    lte: string
  }
  posted_at_previous: {
    gte: string
    lte: string
  }
}

export interface TrendingTopicsPayload {
  feedback_search_schema?: FeedbackFilter
  count_by: 'ingested_feedback_id'
  search_text: string
  return_all: boolean
  exact_count: boolean
  sentiment_count: boolean
  sentiment_exact_count: boolean
  // offset: number
  // size: number
  metric_emerging?: boolean
  metric_intentions?: string[]
  root_causes_enable?: boolean
}

export interface TopicMetric {
  topic_id: string
  name: string
  percentage_intention_current: number
  percentage_intention_previous: number
  current: number
  previous: number
  variation: number
  feedback_current: number
  feedback_previous: number
}

interface TopicIntentionMetric {
  name: string
  metric: TopicMetric[] | null
  size: number
}

export interface TrendingTopicsResponse {
  took: number
  metric_emerging_topics: {
    metric: TopicMetric[] | null
    size: number
  }
  metric_intention_topics: TopicIntentionMetric[] | null
  posted_at_current: string | null
  posted_at_previous: string | null
}

export type TopicDetailsPayload = TrendingTopicsPayload

export interface TopicDetailsResponse {
  summary_long: string
  summary_short: string
  root_causes_origin: string[]
  root_causes: {
    theme: string
    count: number
    top_sample: string[]
    normalized_count: 1
    relevance: number
    reasons_hash: string[]
  }[]
}
