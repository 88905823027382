import ErrorBoundary from '@/components/atoms/error-boundary'
import TableauVizByUrl from '@/components/atoms/tableau/TableauVizByUrl'
import Tabs, { TabItem } from '@/components/atoms/tabs'
import NoPermission from '@/components/molecules/no-permission/NoPermission'
import useUser from '@/hooks/useUser'
import TableauService from '@/services/TableauService'
import { useUIStore } from '@/store'
import { TableauItem } from '@/types/tableau/Tableau'
import { useQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'

const DashboardsPage = () => {
  const { setShowFullScreenLoader } = useUIStore()
  const { userPermissions, currentUser, currentUserPlan } = useUser()
  const canViewTableau = userPermissions.tableau.length > 0
  const canViewTracking = userPermissions.tracking.length > 0 && currentUserPlan === 'topic'

  const queryFn = async () => {
    const result = await TableauService.graphics()
    if (!canViewTracking && selectedGraphic === null && result.length > 0) {
      setSelectedGraphic(result[0])
    }
    setShowFullScreenLoader(false)
    return result
  }

  const { data: graphics, isLoading } = useQuery({
    queryKey: ['tableau-charts'],
    queryFn,
    enabled: canViewTableau
  })

  const [selectedGraphic, setSelectedGraphic] = useState<TableauItem | null>(graphics?.[0] ?? null)
  const tabValue = selectedGraphic?.tableauId ?? ''

  const onValueChange = (value: string) => {
    setSelectedGraphic(graphics?.find(graphic => graphic.tableauId === value) ?? null)
  }

  const tabs: TabItem[] = useMemo(
    () =>
      graphics?.map(
        (graphic): TabItem => ({
          text: graphic.name,
          value: graphic.tableauId,
          disabled: isLoading
        })
      ) ?? [],
    [graphics, isLoading]
  )

  if (!canViewTableau && !canViewTracking && currentUser) {
    return <NoPermission />
  }

  return (
    <>
      <Tabs
        css={{ overflowY: 'visible', ml: '$navigationBarWidth', px: '$xxs' }}
        onValueChange={onValueChange}
        stretch={false}
        tabs={tabs}
        value={tabValue}
      >
        <ErrorBoundary>
          {selectedGraphic && (
            <TableauVizByUrl
              css={{ padding: '$xs', boxSizing: 'border-box' }}
              height={selectedGraphic.height}
              hideMenu
              url={selectedGraphic.path}
            />
          )}
        </ErrorBoundary>
      </Tabs>
    </>
  )
}

export default DashboardsPage
