import useSegment from '@/hooks/useSegment'
import MetricsTrendlineCard from './MetricsTrendlineCard'
import { AllMetricsKey } from '@/types/metrics'
import { BreakdownOption } from '@/types/time-series/TimeSeries'
import { useMemo } from 'react'
import useLocalStorage from '@/hooks/useLocalStorage'
import { OPPORTUNITY_PLAN_KEY } from '@/types/params/OpportunityPlanParams'
import { defaultOpportunityPlanFormData } from '@/pages/params'

const OverallMetricsTrendline = () => {
  const [opportunityPlanParams] = useLocalStorage(
    OPPORTUNITY_PLAN_KEY,
    defaultOpportunityPlanFormData
  )

  const breakdownList: BreakdownOption[] = useMemo(() => {
    const list: BreakdownOption[] = [
      'org',
      'areas',
      'opportunity',
      'segment',
      'sentiment',
      'intention',
      'source_alias',
      'satisfaction',
      'feedbackDetail',
      'customField',
      'account',
      'user',
      'date'
    ]

    if (!opportunityPlanParams.enableSegments) {
      list.splice(3, 1)
    }

    return list
  }, [opportunityPlanParams])

  const { track } = useSegment()
  const trackMetric = (metric: AllMetricsKey) => {
    track('areas_and_opps_trendline_metrics', {
      selected_metric: metric
    })
  }

  const trackCreateAnnotation = () => {
    track('areas_and_opps_trendline_add_annotation')
  }

  const trackCopyTable = () => {
    track('areas_and_opps_trendline_copy_data_table')
  }

  return (
    <MetricsTrendlineCard
      annotationType="organization"
      breakdownList={breakdownList}
      defaultOption="areas"
      onCopyTable={trackCopyTable}
      trackCreateAnnotation={trackCreateAnnotation}
      trackMetric={trackMetric}
    />
  )
}
export default OverallMetricsTrendline
