import Text from '@/components/atoms/text'
import RadioGroup from '@/components/atoms/radio-group'
import { ThemeItemData } from '@/types/classification/Theme'
import { TopicCategory } from '@/types/classification'
import FlexContainer from '@/components/atoms/flex-container'
import AddTopicGroup from '../topic-groups/AddTopicGroup'

interface Props {
  selectedTheme: string
  themes: ThemeItemData[]
  topicCategory: TopicCategory
  onChangeTheme: (category: string) => void
}

const SelectTheme = ({ selectedTheme, themes, topicCategory, onChangeTheme }: Props) => {
  return (
    <FlexContainer
      as="form"
      direction="column"
      gap={'xxs'}
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}
    >
      <RadioGroup
        gap="xxs"
        itemDirection="rowReverse"
        justifyContent="spaceBetween"
        onValueChange={onChangeTheme}
        options={themes.map(theme => ({
          value: theme.themeId || '',
          text: (
            <Text color="brandPrimaryPure" typeface="subtitleSemiBoldXXXS">
              {theme.themeName}
            </Text>
          )
        }))}
        value={selectedTheme}
      />

      <AddTopicGroup topicCategory={topicCategory} withoutButtons />
    </FlexContainer>
  )
}

export default SelectTheme
