import { Intention } from '@/types/reasons'
import FlexContainer, { FlexContainerProps } from '../flex-container'
import Text, { TextProps } from '../text'
import { intentionIcons } from '@/utils/intention'

interface Props extends FlexContainerProps {
  intention: Intention
  textProps?: TextProps
}

export const IntentionWithText = ({ intention, textProps, gap = 'micro', ...props }: Props) => {
  return (
    <FlexContainer {...props} gap={gap}>
      {intentionIcons[intention]}
      <Text css={{ ...textProps?.css, textTransform: 'capitalize' }} {...textProps}>
        {intention}
      </Text>
    </FlexContainer>
  )
}
