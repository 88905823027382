import Checkbox from '@/components/atoms/checkbox'

interface Item {
  id: string
  name: string
}

interface Props {
  item: Item
  isChecked: (id: string) => boolean
  onCheckedChange: (item: Item, checked: boolean) => void
}

const MoveFeedbackCheckbox = ({ item, isChecked, onCheckedChange }: Props) => {
  return (
    <Checkbox
      key={item.id}
      value={item.id}
      checked={isChecked(item.id)}
      onCheckedChange={checked => onCheckedChange(item, checked as boolean)}
      text={item.name}
    />
  )
}

export default MoveFeedbackCheckbox
