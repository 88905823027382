import { useFiltersStore } from '@/store'
import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'

const useSelectedFilters = () => {
  const {
    dateRange,
    search,
    numericFilters,
    datetimeFilters,
    stringFilters,
    selectedProductAreaTopics,
    selectedOtherTopics,
    selectedProductAreaThemes,
    selectedOtherThemes,
    selectedUnclassifiedTopics,
    selectedUngroupedTopics,
    accounts,
    accountDateFilters,
    accountNumericFilters,
    accountBooleanFilters,
    users,
    usersDateFilters,
    usersNumericFilters,
    usersBooleanFilters
  } = useFiltersStore(
    state => ({
      dateRange: state.dateRange,
      search: state.search,
      numericFilters: state.numericFilters,
      datetimeFilters: state.datetimeFilters,
      stringFilters: state.stringFilters,
      selectedProductAreaTopics: state.productAreaTopics.selected,
      selectedOtherTopics: state.otherTopics.selected,
      selectedProductAreaThemes: state.productAreaThemes.selected,
      selectedOtherThemes: state.otherThemes.selected,
      selectedUnclassifiedTopics: state.unclassifiedTopics.selected,
      selectedUngroupedTopics: state.ungroupedTopics.selected,
      accounts: Object.values(state.accountsStringFilters).flatMap(value => value.selected),
      accountDateFilters: state.accountsDateFilters,
      accountNumericFilters: state.accountNumericFilters,
      accountBooleanFilters: state.accountBooleanFilters,
      users: Object.values(state.usersStringFilters).flatMap(value => value.selected),
      usersDateFilters: state.usersDateFilters,
      usersNumericFilters: state.usersNumericFilters,
      usersBooleanFilters: state.usersBooleanFilters
    }),
    shallow
  )

  const selectedNumericFilters = useMemo(
    () =>
      numericFilters
        .filter(filter => filter.option !== 'all')
        .map(filter => ({
          value: filter.value,
          option: filter.option,
          key: filter.key,
          name: filter.name
        })),
    [numericFilters]
  )

  const selectedDatetimeFilters = useMemo(
    () =>
      datetimeFilters
        .filter(filter => filter.value !== null)
        .map(filter => ({
          value: filter.value,
          key: filter.key,
          name: filter.name
        })),
    [datetimeFilters]
  )

  const selectedStringFilters = useMemo(
    () =>
      stringFilters.flatMap(filter =>
        filter.selected.map(selectedItem => ({
          key: filter.key,
          name: filter.name,
          value: selectedItem
        }))
      ),
    [stringFilters]
  )

  const selectedTopicFilters = useMemo(
    () => [
      ...selectedProductAreaTopics,
      ...selectedOtherTopics,
      ...selectedProductAreaThemes,
      ...selectedOtherThemes,
      ...selectedUnclassifiedTopics,
      ...selectedUngroupedTopics
    ],
    [
      selectedProductAreaTopics,
      selectedOtherTopics,
      selectedProductAreaThemes,
      selectedOtherThemes,
      selectedUnclassifiedTopics,
      selectedUngroupedTopics
    ]
  )

  const accountNumericFiltersCount = accountNumericFilters.filter(filter => filter.option !== 'all')
  const accountBooleanFiltersCount = accountBooleanFilters.filter(filter => filter.value !== null)
  const accountDateFiltersCount = accountDateFilters.filter(filter => filter.selected !== null)

  const usersNumericFiltersCount = usersNumericFilters.filter(filter => filter.option !== 'all')
  const usersBooleanFiltersCount = usersBooleanFilters.filter(filter => filter.value !== null)
  const usersDateFiltersCount = usersDateFilters.filter(filter => filter.selected !== null)

  const selectedCustomerRecords = useMemo(() => {
    return [
      ...accounts,
      ...accountNumericFiltersCount,
      ...accountDateFiltersCount,
      ...accountBooleanFiltersCount,
      ...users,
      ...usersNumericFiltersCount,
      ...usersDateFiltersCount,
      ...usersBooleanFiltersCount
    ]
  }, [
    accounts,
    users,
    accountNumericFiltersCount,
    accountDateFiltersCount,
    usersNumericFiltersCount,
    usersDateFiltersCount,
    accountBooleanFiltersCount,
    usersBooleanFiltersCount
  ])

  const allSelectedFilters = [
    dateRange,
    search,
    ...selectedDatetimeFilters,
    ...selectedNumericFilters,
    ...selectedStringFilters,
    ...selectedTopicFilters,
    ...selectedCustomerRecords
  ]

  return {
    dateRange,
    search,
    selectedNumericFilters,
    selectedDatetimeFilters,
    selectedStringFilters,
    selectedProductAreaTopics,
    selectedOtherTopics,
    selectedProductAreaThemes,
    selectedOtherThemes,
    selectedUnclassifiedTopics,
    selectedTopicFilters,
    selectedCustomerRecords,
    allSelectedFilters
  }
}

export default useSelectedFilters
