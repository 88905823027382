import { styled } from '@/theme'
import { slideAndFadeOpen } from '@/theme/animations'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'

type RootProps = TooltipPrimitive.TooltipProps & { skipDelayDuration?: number }
const Root = ({ children, ...props }: RootProps) => {
  return (
    <TooltipPrimitive.Provider
      delayDuration={props.delayDuration}
      disableHoverableContent={props.disableHoverableContent}
      skipDelayDuration={props.skipDelayDuration}
    >
      <TooltipPrimitive.Root {...props}>{children}</TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}

type TriggerProps = TooltipPrimitive.TooltipTriggerProps
const Trigger = ({ children, ...props }: TriggerProps) => {
  return <TooltipPrimitive.Trigger {...props}>{children}</TooltipPrimitive.Trigger>
}

const Content = styled(TooltipPrimitive.Content, {
  borderRadius: 8,
  background: '$neutralHighLight',
  userSelect: 'none',
  zIndex: 9999,
  padding: '$micro',
  bAll: '$neutralHighPure',
  boxShadow: '$soft',

  ...slideAndFadeOpen
})

const Container = styled('div', {
  px: '$micro',
  borderLeft: '2px solid CurrentColor'
})

const TextContent = styled('div', {
  fontSize: '$micro',
  color: '$neutralLowPure'
})

const Text = styled('p', {})

const List = styled('ul', {
  padding: '$micro',
  px: '$xxs'
})

const ListItem = styled('li', {})

const TooltipCard = {
  Root,
  Trigger,
  Content,
  Container,
  TextContent,
  Text,
  List,
  ListItem
}

export default TooltipCard
