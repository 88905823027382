import { ThemeItemData } from '@/types/classification/Theme'
import Text from '@/components/atoms/text'
import { MouseEvent } from 'react'
import { TopicCategory, TopicDataWithCategory, TopicThemeItem } from '@/types/classification'
import Switch from '@/components/atoms/switch'
import AddTopicGroup from '../classification/topic-groups/AddTopicGroup'
import * as S from './TopicGroupEditor.styles'

interface Props {
  topicGroups: TopicDataWithCategory[]
  topicCategory: TopicCategory
  onClickTheme: (theme: ThemeItemData) => (e: MouseEvent<HTMLButtonElement>) => void
  isTopicGroupChecked: (topicId: string) => boolean
  onCheckTopicGroup: (theme: TopicThemeItem) => (checked: boolean) => void
  onAddTopicGroup?: (theme: TopicThemeItem) => void
  isOverflowing?: boolean
}

const ThemesSection = ({
  topicGroups,
  topicCategory,
  isTopicGroupChecked,
  onCheckTopicGroup,
  onAddTopicGroup,
  isOverflowing
}: Props) => {
  return (
    <>
      <S.Content fixedHeight isOverflowing={isOverflowing}>
        {topicGroups.map(group => (
          <S.Item key={group.topicId}>
            <Text
              color="pajarito"
              maxWidth={200}
              title={group.topicName}
              truncate
              typeface="textSemiboldSmall"
            >
              {group.topicName}
            </Text>
            <Switch
              checked={isTopicGroupChecked(group.topicId || '')}
              onCheckedChange={onCheckTopicGroup({
                themeCategory: group.category,
                themeId: group.topicId,
                themeName: group.topicName
              })}
              value={group.topicId || ''}
            />
          </S.Item>
        ))}
      </S.Content>
      <AddTopicGroup onAdd={onAddTopicGroup} topicCategory={topicCategory} withoutButtons />
    </>
  )
}

export default ThemesSection
