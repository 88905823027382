import Dialog from '@/components/atoms/dialog'
import FlexContainer from '@/components/atoms/flex-container'
import Search from '@/components/molecules/search'
import { NewFeedback } from '@/types/feedbacks/Feedback'
import useMoveFeedbackQuery from './useMoveFeedbackQuery'
import useBasicAreaOfInterestQuery from '@/hooks/areaOfInterest/useBasicAreaOfInterestQuery'
import useBasicOpportunitiesQuery from '@/hooks/opportunity/useBasicOpportunitiesQuery'
import { FormEvent, useCallback, useMemo, useState } from 'react'
import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import { RelatedFeedbackLabelBaseItem } from '@/types/feed/FeedRequests'
import Text from '@/components/atoms/text'
import { CaretLeft, CaretRight } from '@phosphor-icons/react'
import useDebounce from '@/hooks/useDebounce'
import { OpportunityItem } from '@/types/opportunity/Opportunity'
import Divider from '@/components/atoms/divider'
import { useTranslation } from 'react-i18next'
import Button from '@/components/atoms/button'
import MoveFeedbackLoading from './MoveFeedbackLoading'
import MoveFeedbackNoResults from './MoveFeedbackNoResults'
import MoveFeedbackCheckbox from './MoveFeedbackCheckbox'
import useSegment from '@/hooks/useSegment'

interface Props {
  isOpen: boolean
  feedbackToMove: NewFeedback
  /** if is moving the feedback to other opportunities, show the list of opportunities grouped by areas,
   *  if is moving to area, show the list of areas
   */
  moveTo: 'area' | 'opportunity'
  onOpenChange: (value: boolean) => void
}

const MoveFeedbackDialog = ({ feedbackToMove, moveTo, isOpen, onOpenChange }: Props) => {
  const [searchText, setSearchText] = useState('')
  const [currentArea, setCurrentArea] = useState<BaseInterestArea | null>(null)
  const [newLabels, setNewLabels] = useState<(RelatedFeedbackLabelBaseItem & { name: string })[]>(
    []
  )

  const debouncedSearch = useDebounce(searchText, 500)

  const isUsingSearch = searchText.length > 0
  const isUsingDebouncedSearch = debouncedSearch.length > 0

  const { t } = useTranslation()
  const { track } = useSegment()

  const { isLoading, moveFeedbackMutation } = useMoveFeedbackQuery({
    feedbackIdToMove: feedbackToMove.id,
    moveTo,
    enabled: isOpen
  })

  const { areas, isLoading: isLoadingAreas } = useBasicAreaOfInterestQuery({ enabled: true })

  const { opportunities, isLoading: isLoadingOpportunites } = useBasicOpportunitiesQuery({
    enabled: moveTo === 'opportunity' && (!!currentArea || isUsingDebouncedSearch),
    searchText: debouncedSearch,
    areaId: isUsingDebouncedSearch ? undefined : currentArea?.id
  })

  const onChangeSearch = (e: FormEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value)

    if (currentArea) {
      setCurrentArea(null)
    }
  }

  const newLabelsToAdd = useMemo(() => {
    return newLabels.filter(item => item.has_label)
  }, [newLabels])

  const oppsSearchResults = useMemo(() => {
    const areaResults: Record<string, OpportunityItem[]> = {}
    opportunities.forEach(opportunity => {
      const firstAreaId = opportunity.relations.at(0)

      if (!firstAreaId) return

      const areaResultList = areaResults[firstAreaId]

      if (areaResultList && !areaResultList.find(areaOpp => areaOpp.id === opportunity.id)) {
        areaResults[firstAreaId] = [...areaResultList, opportunity]
      } else {
        areaResults[firstAreaId] = [opportunity]
      }
    })

    const _results = Object.entries(areaResults).map(([areaId, areaOpportunities]) => {
      const resultArea = areas.find(area => area.id === areaId)
      return { areaId, areaName: resultArea?.name ?? '', opportunities: areaOpportunities }
    })

    return _results
  }, [opportunities, areas])

  const filteredAreas = useMemo(() => {
    if (!searchText.length) return areas

    return areas.filter(area => area.name.toLowerCase().includes(searchText.toLowerCase()))
  }, [areas, searchText])

  const isChecked = useCallback(
    (id: string) => {
      return !!newLabels.find(opportunity => opportunity.id === id && opportunity.has_label)
    },
    [newLabels]
  )

  const onCheckedChange = useCallback((item: { id: string; name: string }, checked: boolean) => {
    setNewLabels(prevLabels => {
      if (prevLabels.find(prevLabel => prevLabel.id === item.id)) {
        return prevLabels.map(prevLabel =>
          prevLabel.id === item.id ? { ...prevLabel, has_label: checked } : prevLabel
        )
      } else if (checked) {
        return [...prevLabels, { ...item, has_label: checked }]
      }

      return prevLabels
    })
  }, [])

  const onApply = useCallback(() => {
    moveFeedbackMutation.mutate(
      newLabelsToAdd.map(label => ({ id: label.id, has_label: label.has_label })),
      {
        onSuccess: () => {
          track(
            moveTo === 'area' ? 'area_move_feedback_dialog' : 'opportunity_move_feedback_dialog',
            { to: newLabelsToAdd.map(item => item.name) }
          )
          onOpenChange(false)
        }
      }
    )
  }, [newLabelsToAdd, moveTo, moveFeedbackMutation.mutate, onOpenChange, track])

  const onOpenChangeWithTrack = (open: boolean) => {
    if (!open) {
      track(
        moveTo === 'area'
          ? 'area_move_feedback_dialog_giveup'
          : 'opportunity_move_feedback_dialog_giveup'
      )
    }
    onOpenChange(open)
  }

  return (
    <Dialog
      align="center"
      closeIcon
      modal={true}
      onOpenChange={onOpenChangeWithTrack}
      open={isOpen}
      title={t(moveTo === 'area' ? 'moveFeedbackToArea' : 'moveFeedbackToOpportunity')}
      width={'medium'}
      contentProps={{ css: { padding: '$xs $sm' } }}
    >
      {isLoading && <MoveFeedbackLoading.MoveFeedbackCircleLoader />}

      {!isLoading && (
        <FlexContainer direction="column" gap="xs" css={{ mt: '$xs' }}>
          <Search value={searchText} onChange={onChangeSearch} />

          <FlexContainer
            gap="xxs"
            direction="column"
            css={{ maxHeight: newLabels.length > 0 ? 275 : 340, overflow: 'hidden auto' }}
          >
            {moveTo === 'opportunity' ? (
              <>
                {isLoadingAreas && <MoveFeedbackLoading.MoveFeedbackSkeleton />}

                {isUsingSearch && !isLoadingAreas && (
                  <>
                    {isLoadingOpportunites && <MoveFeedbackLoading.MoveFeedbackSkeleton />}

                    {!isLoadingOpportunites && oppsSearchResults.length === 0 && (
                      <MoveFeedbackNoResults />
                    )}

                    {!isLoadingOpportunites &&
                      oppsSearchResults.map(result => (
                        <FlexContainer key={`${result.areaId}`} gap="xxs" direction="column">
                          <FlexContainer
                            justifyContent="start"
                            alignItems="center"
                            gap="micro"
                            css={{ mb: 'xs' }}
                          >
                            <Text
                              fontSize="xxxs"
                              color="neutralLowPure"
                              truncate
                              title={result.areaName}
                            >
                              {result.areaName}
                            </Text>
                          </FlexContainer>

                          {result.opportunities.map(opportunity => (
                            <MoveFeedbackCheckbox
                              key={opportunity.id}
                              item={opportunity}
                              isChecked={isChecked}
                              onCheckedChange={onCheckedChange}
                            />
                          ))}
                        </FlexContainer>
                      ))}
                  </>
                )}

                {!currentArea && !isUsingSearch && (
                  <>
                    {!isLoadingAreas && areas.length === 0 && <MoveFeedbackNoResults />}

                    {!isLoadingAreas &&
                      areas.map(area => (
                        <FlexContainer
                          key={area.id}
                          justifyContent="spaceBetween"
                          alignItems="center"
                          gap="micro"
                          css={{ cursor: 'pointer' }}
                          onClick={() => setCurrentArea(area)}
                        >
                          <Text fontSize="xxxs" color="neutralLowPure" truncate title={area.name}>
                            {area.name}
                          </Text>

                          <CaretRight size={16} />
                        </FlexContainer>
                      ))}
                  </>
                )}

                {currentArea && !isUsingSearch && (
                  <>
                    <FlexContainer
                      justifyContent="start"
                      alignItems="center"
                      gap="micro"
                      css={{ cursor: 'pointer', mb: 'xs' }}
                      onClick={() => setCurrentArea(null)}
                    >
                      <CaretLeft size={16} />

                      <Text
                        fontSize="xxxs"
                        color="neutralLowPure"
                        truncate
                        title={currentArea.name}
                      >
                        {currentArea.name}
                      </Text>
                    </FlexContainer>

                    {isLoadingOpportunites && <MoveFeedbackLoading.MoveFeedbackSkeleton />}

                    {!isLoadingOpportunites && opportunities.length === 0 && (
                      <MoveFeedbackNoResults />
                    )}

                    {!isLoadingOpportunites &&
                      opportunities.map(opportunity => (
                        <MoveFeedbackCheckbox
                          key={opportunity.id}
                          item={opportunity}
                          isChecked={isChecked}
                          onCheckedChange={onCheckedChange}
                        />
                      ))}
                  </>
                )}
              </>
            ) : (
              filteredAreas.map(area => (
                <MoveFeedbackCheckbox
                  key={area.id}
                  item={area}
                  isChecked={isChecked}
                  onCheckedChange={onCheckedChange}
                />
              ))
            )}
          </FlexContainer>
        </FlexContainer>
      )}

      {newLabelsToAdd.length > 0 && (
        <>
          <Divider
            orientation="horizontal"
            line="solid"
            color="$neutralLowPure"
            css={{ my: '$xs' }}
          />
          <FlexContainer
            gap="xxs"
            direction="column"
            css={{ maxHeight: 225, overflow: 'hidden auto' }}
          >
            {newLabelsToAdd.map(item => (
              <MoveFeedbackCheckbox
                key={item.id}
                item={item}
                isChecked={isChecked}
                onCheckedChange={onCheckedChange}
              />
            ))}
          </FlexContainer>
        </>
      )}

      <FlexContainer css={{ mt: '$xs' }}>
        {!isLoading && (
          <Button fullWidth disabled={newLabelsToAdd.length === 0} onClick={onApply}>
            {t('apply')}
          </Button>
        )}
      </FlexContainer>
    </Dialog>
  )
}

export default MoveFeedbackDialog
