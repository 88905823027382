import { Item } from './MoveTopic.styles'
import Text from '@/components/atoms/text'
import { MouseEvent } from 'react'
import { CaretRight } from '@phosphor-icons/react'
import { colors } from '@/theme'
import { TopicCategory } from '@/types/classification'

interface Props {
  categories: { category: TopicCategory; name: string }[]
  onClickCategory: (category: TopicCategory) => (e: MouseEvent<HTMLButtonElement>) => void
}

const CategoriesSection = ({ categories, onClickCategory }: Props) => {
  return (
    <>
      {categories.map(({ category, name }) => (
        <Item key={category} onClick={onClickCategory(category)}>
          <Text color="pajarito" maxWidth={200} truncate typeface="textSemiboldSmall">
            {name}
          </Text>
          <CaretRight color={colors.grey} size={14} weight="bold" />
        </Item>
      ))}
    </>
  )
}

export default CategoriesSection
