import Bone from '@/components/atoms/bone'
import { styled } from '@/theme'
import { Container } from './ConnectorCard.styles'

function ConnectorCardSkeleton() {
  const DescriptionContainer = styled('div', {
    width: '100%'
  })

  const DescriptionLineHeight = styled('div', {
    height: 23.8,
    display: 'flex',
    alignItems: 'center'
  })

  return (
    <Container data-testid="connector-card-skeleton">
      <Bone css={{ height: 40, width: 40 }} />
      <Bone css={{ height: 16, width: 80 }} />
      <DescriptionContainer>
        <DescriptionLineHeight>
          <Bone css={{ height: 14, width: '100%' }} />
        </DescriptionLineHeight>
        <DescriptionLineHeight>
          <Bone css={{ height: 14, width: '100%' }} />
        </DescriptionLineHeight>
        <DescriptionLineHeight>
          <Bone css={{ height: 14, width: '50%' }} />
        </DescriptionLineHeight>
      </DescriptionContainer>
      <Bone css={{ height: 32, width: '100%' }} />
    </Container>
  )
}

export default ConnectorCardSkeleton
