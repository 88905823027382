import { Newspaper } from '@phosphor-icons/react'
import { integerFormatter, percentageFormatter, percentageScoreTransformer } from './formatters'
import { MetricItem } from '@/types/metrics'
import i18n from '../../plugins/i18n/i18n'

export type TranscriptMetricKey = 'transcript' | 'transcript_share'

// export const TRANSCRIPT_METRICS_KEYS: TranscriptMetricKey[] = ['transcript', 'transcript_share']

export const transcriptTimeseries: Record<TranscriptMetricKey, MetricItem> = {
  transcript: {
    label: i18n.t('count'),
    selectedLabel: i18n.t('transcriptsCount'),
    icon: Newspaper,
    mainMetricType: 'count',
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: `transcript_count`,
      filter: { source_alias: 'transcript' }
    }
  },
  transcript_share: {
    label: i18n.t('percentCount'),
    selectedLabel: i18n.t('transcriptsPercentCount'),
    icon: Newspaper,
    mainMetricType: 'share',
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: `transcript_share`,
      filter: { source_alias: 'transcript' }
    }
  }
}

export const TRANSCRIPT_METRICS_KEYS = Object.keys(transcriptTimeseries) as TranscriptMetricKey[]

export const TRANSCRIPT_DEFAULT_METRICS = ['transcript']
