import FlexContainer from '@/components/atoms/flex-container'
import Loader from '@/components/atoms/loader'
import Bone from '@/components/atoms/bone'

const MoveFeedbackCircleLoader = () => {
  return (
    <FlexContainer justifyContent="center" gap="xs" css={{ mt: '$xs' }}>
      <Loader />
    </FlexContainer>
  )
}

const MoveFeedbackSkeleton = () => {
  return (
    <>
      <Bone css={{ height: '$xs' }} />
      <Bone css={{ height: '$xs' }} />
      <Bone css={{ height: '$xs' }} />
      <Bone css={{ height: '$xs' }} />
    </>
  )
}

const MoveFeedbackLoading = {
  MoveFeedbackSkeleton,
  MoveFeedbackCircleLoader
}

export default MoveFeedbackLoading
