import { ReactNode } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

import Button from '@/components/atoms/button'
import { DropdownMenuContent } from '@/components/atoms/options-menu'
import Text from '@/components/atoms/text'
import FilterItemButton, { FilterItemButtonProps } from './FilterItemButton'
import useFilters from '@/hooks/filters/useFilters'
import { CSS } from '@/theme'
import { useTranslation } from 'react-i18next'

interface FilterItemDropdownProps extends FilterItemButtonProps {
  disabled: boolean
  buttonDisabled?: boolean
  dropdown: ReactNode
  onApply(): void
  onOpenChange?(open: boolean): void
  open?: boolean
  title: string
  contentCss?: CSS
}

function FilterItemDropdown(props: FilterItemDropdownProps) {
  const { applyAndReload } = useFilters()
  const { t } = useTranslation()

  const onClick = () => {
    applyAndReload(props.onApply)
  }

  const isApplyDisabled = props.disabled

  const open = props.open

  return (
    <DropdownMenu.Root onOpenChange={props.onOpenChange} open={open}>
      <DropdownMenu.Trigger asChild disabled={props.buttonDisabled}>
        <FilterItemButton
          content={props.content}
          contentType={props.contentType}
          disabled={props.buttonDisabled}
          tooltip={props.tooltip}
        />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenuContent
          align="start"
          css={{ gap: '$xxs', padding: '$xxs', width: 320, ...props.contentCss }}
          sideOffset={4}
        >
          {props.title ? <Text typeface="subtitleSemiBoldMicro">{props.title}</Text> : null}
          <div>{props.dropdown}</div>
          <DropdownMenu.DropdownMenuItem>
            <Button disabled={isApplyDisabled} fullWidth onClick={onClick} size="micro">
              {t('applyFilter')}
            </Button>
          </DropdownMenu.DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default FilterItemDropdown
