import FlexContainer from '@/components/atoms/flex-container'
import Select from '@/components/atoms/select'
import { StatusFilter, statusFilterOptions } from '@/utils/opportunityUtils'
import HiddenMetricsModal from '@/components/molecules/home/HiddenMetricsModal'

interface Props {
  selectedStatus: StatusFilter
  setSelectedStatus: (value: StatusFilter) => void
}
function OpportunitiesListFilters({ selectedStatus, setSelectedStatus }: Props) {
  return (
    <FlexContainer gap="xxs">
      <Select
        onValueChange={setSelectedStatus}
        options={statusFilterOptions}
        small
        value={selectedStatus}
        width={120}
      />

      <HiddenMetricsModal borderedAndSmall />
    </FlexContainer>
  )
}

export default OpportunitiesListFilters
