import OptionsMenu from '@/components/atoms/options-menu'
import { ReactNode } from 'react'
import Button from '@/components/atoms/button'
import { shallow } from 'zustand/shallow'
import { VolumeByType } from '@/types/filters/Filters'
import { useFiltersStore } from '@/store'
import { ChatText, Chats } from '@phosphor-icons/react'

const volumeByIcons: Record<VolumeByType, ReactNode> = {
  'feedback-record': <ChatText size={24} weight="regular" />,
  ticket: <Chats size={24} weight="regular" />
}

function DiscoveryOptions() {
  const { volumeBy } = useFiltersStore(state => ({ volumeBy: state.volumeBy }), shallow)
  const setVolumeBy = useFiltersStore(state => state.setVolumeBy, shallow)

  const onClick = (option: VolumeByType) => () => {
    setVolumeBy(option)
  }

  return (
    <>
      <OptionsMenu
        customTrigger={
          <Button size="square" variant="flat-bordered">
            {volumeByIcons[volumeBy]}
          </Button>
        }
        options={[
          {
            text: 'Volume by feedback record',
            icon: volumeByIcons['feedback-record'],
            bold: volumeBy === 'feedback-record',
            onClick: onClick('feedback-record')
          },
          {
            text: 'Volume by ticket',
            icon: volumeByIcons.ticket,
            bold: volumeBy === 'ticket',
            onClick: onClick('ticket')
          }
        ]}
      />
    </>
  )
}

export default DiscoveryOptions
