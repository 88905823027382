import MetricIndicator from './MetricIndicator'
import { colors, styled } from '@/theme'
import Bone from '@/components/atoms/bone'
import useFeedQueryParams from '@/hooks/feedback/new/useFeedQueryParams'
import FlexContainer from '@/components/atoms/flex-container'
import useExplorationMetrics from '@/hooks/metrics/useExplorationMetrics'
import { MetricKey } from '@/types/metrics'
import { useCallback, useMemo } from 'react'
import i18n from '../../../../plugins/i18n/i18n'
import useOpportunityStore from '@/store/useOpportunityStore'
import useCarouselArrows from '@/hooks/useCarouselArrows'
import ExplorationIndicatorArrow from './ExplorationIndicatorArrow'
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'
import { useUIStore } from '@/store'
import { shallow } from 'zustand/shallow'

const mapMetricToMetricName: Partial<Record<MetricKey, string>> = {
  support_ticket: i18n.t('shareOfTotal'),
  transcript: i18n.t('shareOfTotal'),
  review: i18n.t('rating'),
  issue: i18n.t('shareOfTotal'),
  social_media_post: i18n.t('shareOfTotal')
}

const ExplorationOuterContainer = styled(FlexContainer, {
  position: 'relative',
  scrollBehavior: 'smooth',
  zIndex: 1
})

const ExplorationInnerContainer = styled(FlexContainer, {
  minHeight: 160,
  width: '100%',
  overflow: 'auto hidden',
  scrollBehavior: 'smooth'
})

interface Props {
  onTrackScroll: (scroll: string) => void
}

const ExplorationIndicators = ({ onTrackScroll }: Props) => {
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)
  const isFiltersOpen = useUIStore(state => state.isAdvancedFiltersOpen, shallow)

  const { queryParams } = useFeedQueryParams()

  const {
    hasScroll,
    onScroll: onCarouselScroll,
    showLeftArrow,
    showRightArrow,
    viewportRef,
    updateHasScroll
  } = useCarouselArrows({
    rightOffset: 8,
    leftOffset: 8
  })

  const filters = useMemo(() => {
    return {
      ...queryParams,
      opportunity_id: currentOpportunity?.id
    }
  }, [queryParams, currentOpportunity])

  const { metrics, isLoading, satisfactionCountItems } = useExplorationMetrics({
    filters: filters ? [{ ...filters }] : [],
    useShare: true
  })

  useDidUpdateEffect(() => {
    updateHasScroll()
  }, [metrics, isFiltersOpen])

  const getSatisfactionMetricValue = useCallback(
    (metricKey: MetricKey) => {
      const metricItem = satisfactionCountItems.find(metric => metric.metricKey === metricKey)
      if (!metricItem) return undefined

      return metricItem.currentValue
    },
    [satisfactionCountItems]
  )

  const scrollTo = (direction: 'right' | 'left') => {
    if (!viewportRef.current) return

    if (direction === 'right' && showRightArrow) {
      viewportRef.current.scrollLeft += 232
      onTrackScroll('right')
    }

    if (direction === 'left' && showLeftArrow) {
      viewportRef.current.scrollLeft -= 232
      onTrackScroll('left')
    }
  }

  const onScroll = () => {
    onCarouselScroll()
  }

  if (isLoading) {
    return (
      <ExplorationInnerContainer alignItems="stretch" gap="xs">
        <Bone css={{ height: 164 }} />
        <Bone css={{ height: 164 }} />
        <Bone css={{ height: 164 }} />
      </ExplorationInnerContainer>
    )
  }

  return (
    <ExplorationOuterContainer>
      <ExplorationIndicatorArrow
        visible={hasScroll && showLeftArrow}
        direction="left"
        onClick={() => scrollTo('left')}
      />

      <ExplorationInnerContainer
        gap={'xs'}
        ref={viewportRef}
        onScroll={onScroll}
        className="scroll-on-hover-container"
      >
        {metrics.map(metric => (
          <MetricIndicator
            expanded={metrics.length < 3}
            key={metric.metricKey}
            countMetricValue={getSatisfactionMetricValue(metric.metricKey)}
            {...metric}
            color={colors.brandPrimaryPure}
            metricName={mapMetricToMetricName[metric.metricKey] ?? metric.label}
          />
        ))}
      </ExplorationInnerContainer>

      <ExplorationIndicatorArrow
        visible={hasScroll && showRightArrow}
        direction="right"
        onClick={() => scrollTo('right')}
      />
    </ExplorationOuterContainer>
  )
}

export default ExplorationIndicators
