import { styled } from '@/theme'

export const StepIndicator = styled('div', {
  width: 32,
  height: 4,
  borderRadius: 50,

  variants: {
    filled: {
      true: {
        background: '$brandPrimaryPure'
      },
      false: {
        background: '$neutralHighPure'
      }
    }
  }
})

export const StepGroup = styled('div', {
  position: 'relative'
})

export const StepContentTransiton = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  pb: '$sm',
  transition: '.5s',
  opacity: 1,

  variants: {
    transitionStatus: {
      preEnter: {
        opacity: 0
      },
      exiting: {
        opacity: 0
      },
      preExited: {
        opacity: 0
      },
      exited: {
        display: 'none'
      },
      unmounted: {
        display: 'none'
      },
      entering: {},
      entered: {},
      preExit: {
        opacity: 0
      }
    }
  }
})
