import Button from '@/components/atoms/button'
import Dialog from '@/components/atoms/dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import Input from '@/components/atoms/input'
import Text from '@/components/atoms/text'
import useSegment from '@/hooks/useSegment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  previousName: string
  onRename: (name: string) => void
}

const RenameAreaModal = ({ open, onOpenChange, previousName, onRename }: Props) => {
  const { track } = useSegment()

  const [name, setName] = useState(previousName)

  const { t } = useTranslation()

  const confirmRename = () => {
    track('areas_and_opps_rename_area')
    onRename(name)
  }

  const onKeyUp: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      confirmRename()
    }
  }

  const onClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    confirmRename()
  }

  return (
    <Dialog align="center" closeIcon onOpenChange={onOpenChange} open={open}>
      <FlexContainer direction="column" gap="xs">
        <Text as="h1" typeface="titleBoldXXS">
          {t('renameArea')}
        </Text>
        <FlexContainer direction="column" gap="micro">
          <Text as="label" typeface="subtitleRegularXXXS">
            {t('nameYourAreaOfInterest')}
          </Text>
          <Input
            autoFocus
            onChange={e => setName(e.target.value)}
            onKeyUp={onKeyUp}
            placeholder={t('nameYourAreaOfInterest')}
            type="text"
            value={name}
          />
        </FlexContainer>

        <Divider line="solid" />
        <Button fullWidth onClick={onClick} size="small">
          {t('rename')}
        </Button>
      </FlexContainer>
    </Dialog>
  )
}

export default RenameAreaModal
