import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'

export const PageOptionsContainer = styled(FlexContainer, {
  alignItems: 'center',
  justifyContent: 'space-between',
  flexGrow: 1,
  gap: '$xxxs',

  variants: {
    noGrow: {
      true: {
        flexGrow: 'inherit'
      }
    }
  }
})
