import NoResultsIllustration from '@/assets/drawings/no_results.png'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { useTranslation } from 'react-i18next'

const NoResults = () => {
  const { t } = useTranslation()

  return (
    <FlexContainer alignItems="center" css={{ marginTop: '$sm' }} direction="column" gap="sm">
      <Text typeface="titleBoldXS">{t('noResultsFound')}</Text>
      <img
        alt="A black and white image of a bird looking at empty boxes."
        height={221}
        src={NoResultsIllustration}
        width={352}
      />
      <FlexContainer alignItems="center" direction="column" gap="micro">
        <Text css={{ fontSize: '$xxs', lineHeight: '$lg', maxWidth: 495, textAlign: 'center' }}>
          {t('makeSureYouveWrittenYourSearchCorrectly')}
        </Text>
      </FlexContainer>
    </FlexContainer>
  )
}

export default NoResults
