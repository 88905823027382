import { AUTH_CLIENT, AUTH_DOMAIN } from '@/config'
import { Auth0Client } from '@auth0/auth0-spa-js'

export const getAuth0ClientData = () => ({
  domain: AUTH_DOMAIN,
  clientId: AUTH_CLIENT,
  redirectUri: `${window.location.origin}/redirect`,
  audience: `https://${AUTH_DOMAIN}/api/v2/`,
  scope: 'read:current_user update:current_user_metadata'
})

const auth0ClientData = getAuth0ClientData()

// export const auth0Client = new Auth0Client({
//   domain: auth0ClientData.domain,
//   clientId: auth0ClientData.clientId,
//   authorizationParams: {
//     redirect_uri: auth0ClientData.redirectUri
//   }
// })

export const getAuth0Client = () => {
  return new Auth0Client({
    domain: auth0ClientData.domain,
    clientId: auth0ClientData.clientId,
    authorizationParams: {
      redirect_uri: auth0ClientData.redirectUri
    }
  })
}
