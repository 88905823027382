import { TrendingTopicType } from '@/types/analytics/TrendingTopic'
import { trendingTopicTypeMap } from '@/utils/trendingTopics'
import TrendingTopicsCard from './TrendingTopicsCard'

interface Props {
  type: TrendingTopicType
}

const TrendingTopicsHeader = ({ type }: Props) => {
  const Icon = trendingTopicTypeMap[type as TrendingTopicType].Icon
  const title = trendingTopicTypeMap[type as TrendingTopicType].title

  return (
    <TrendingTopicsCard.Header>
      <TrendingTopicsCard.IconBox>
        <Icon />
      </TrendingTopicsCard.IconBox>
      <TrendingTopicsCard.Title>{title}</TrendingTopicsCard.Title>
    </TrendingTopicsCard.Header>
  )
}

export default TrendingTopicsHeader
