import { styled } from '@/theme'

export const Container = styled('div', {
  display: 'grid',
  gridAutoRows: '1fr',
  gridTemplateColumns: 'repeat(auto-fill, minmax(312px, 1fr))',
  gap: '$xs'
})

export const Card = styled('article', {
  bAll: '$neutralHighPure',
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  gap: '$xxs',
  padding: '$xs'
})

export const Image = styled('img', {
  variants: {
    round: {
      true: { borderRadius: '50%' }
    }
  }
})

export const ConnectedTag = styled('span', {
  alignItems: 'center',
  color: '$feedbackPositivePure',
  display: 'flex',
  fontSize: '$micro',
  fontWeight: '$bold',
  gap: 8,
  lineHeight: '$xxl'
})
