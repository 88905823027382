import { useTranslation } from 'react-i18next'
import OrganizationStyles from './OrganizationSettings.styles'
import FlexContainer from '@/components/atoms/flex-container'
import Button from '@/components/atoms/button'
import { getLocalTimeZone, parseDate, today } from '@internationalized/date'
import type { DateValue } from '@react-types/calendar'
import { ActiveUsersByDateConfigItem } from '@/types/organization/Organization'
import useToastMessageStore from '@/store/useToastMessageStore'
import { useMemo, useState } from 'react'
import { makeUniqueArray } from '@/utils/array'
import ActiveUsersHistoryModal from './ActiveUsersHistoryModal'
import ActiveUserRow from './ActiveUserRow'

const defaultItem: ActiveUsersByDateConfigItem = { key: null, date: null, value: '' }

interface Props {
  allActiveUsersByDateList: ActiveUsersByDateConfigItem[]
  activeUsersToAdd: ActiveUsersByDateConfigItem[]
  onChangeAllActiveUsers: (value: ActiveUsersByDateConfigItem[]) => void
  onChangeUsersToAdd: (value: ActiveUsersByDateConfigItem[]) => void
}

const ContactRate = ({
  allActiveUsersByDateList,
  activeUsersToAdd,
  onChangeUsersToAdd,
  onChangeAllActiveUsers
}: Props) => {
  const { t } = useTranslation()
  const addWarningToast = useToastMessageStore(state => state.addWarningToast)

  const [historyOpen, setHistoryOpen] = useState(false)

  const sortedByDate = useMemo(() => {
    return [...allActiveUsersByDateList].sort((a, b) => {
      const todayAsDate = today(getLocalTimeZone())
      const dateA = a.date ? a.date : todayAsDate
      const dateB = b.date ? b.date : todayAsDate
      return dateB.compare(dateA)
    })
  }, [allActiveUsersByDateList])

  const isDateUnavailable = (date: DateValue) => {
    const allDates = makeUniqueArray('key', [...allActiveUsersByDateList, ...activeUsersToAdd]).map(
      item => item.key
    )
    return allDates.includes(date.toString())
  }

  const firstEntry = useMemo(() => {
    return activeUsersToAdd.at(0) as ActiveUsersByDateConfigItem
  }, [activeUsersToAdd])

  const otherUsersToAdd = useMemo(() => {
    return activeUsersToAdd.slice(1)
  }, [activeUsersToAdd])

  const onAddNewEntry = () => {
    if (firstEntry.key === null || firstEntry.value.length === 0) return

    onChangeUsersToAdd([defaultItem, ...activeUsersToAdd])
  }

  const onChangeItemValue =
    (from: 'add-list' | 'history') => (key: string | null, newValue: string) => {
      const newActiveUsersByDateList = (
        from === 'add-list' ? activeUsersToAdd : allActiveUsersByDateList
      ).map(item => {
        if (item.key === key) return { ...item, value: newValue }
        return item
      })
      from === 'add-list'
        ? onChangeUsersToAdd(newActiveUsersByDateList)
        : onChangeAllActiveUsers(newActiveUsersByDateList)
    }

  const onChangeItemDate =
    (from: 'add-list' | 'history') => (currentKey: string | null, newDate: DateValue) => {
      const newActiveUsersByDateList = (
        from === 'add-list' ? activeUsersToAdd : allActiveUsersByDateList
      ).map(item => {
        if (item.key === currentKey)
          return { ...item, key: newDate.toString(), date: parseDate(newDate.toString()) }
        return item
      })

      const hasRepeatedDate =
        activeUsersToAdd.filter(item => item.key === newDate.toString()).length > 0 ||
        allActiveUsersByDateList.filter(item => item.key === newDate.toString()).length > 0 ||
        newActiveUsersByDateList.filter(item => item.key === newDate.toString()).length > 1

      if (hasRepeatedDate) {
        addWarningToast({
          text: t('itemWithSameDateAlreadyExists'),
          duration: 4500
        })
        return
      }

      from === 'add-list'
        ? onChangeUsersToAdd(newActiveUsersByDateList)
        : onChangeAllActiveUsers(newActiveUsersByDateList)
    }

  const onDeleteEntry = (from: 'add-list' | 'history') => (key: string | null) => {
    const newActiveUsersByDateList = makeUniqueArray(
      'key',
      from === 'add-list' ? activeUsersToAdd : allActiveUsersByDateList
    ).filter(item => item.key !== key)

    from === 'add-list'
      ? onChangeUsersToAdd(newActiveUsersByDateList)
      : onChangeAllActiveUsers(newActiveUsersByDateList)
  }

  return (
    <OrganizationStyles.Card>
      <FlexContainer justifyContent="spaceBetween">
        <OrganizationStyles.CardHeader as="h3">
          {t('contactRateMetricPremise')}
        </OrganizationStyles.CardHeader>

        {allActiveUsersByDateList.length > 0 && (
          <Button
            small
            size={'small'}
            variant="link"
            css={{ pt: 0 }}
            onClick={() => setHistoryOpen(true)}
          >
            {t('seeFullHistory')}
          </Button>
        )}
      </FlexContainer>

      <FlexContainer gap="xxs" direction="column">
        <OrganizationStyles.Row alignItems="start">
          <FlexContainer direction={'column'}>
            <OrganizationStyles.ColText>{t('activeUsers')}</OrganizationStyles.ColText>
            <OrganizationStyles.ColText description>
              {t('fillingThisDataEnablesContactRate')}
            </OrganizationStyles.ColText>
          </FlexContainer>
          <FlexContainer
            direction="column"
            wrap="wrap"
            gap="xxs"
            css={{ maxHeight: 300, overflow: 'auto' }}
            fullWidth
          >
            <ActiveUserRow
              item={firstEntry}
              isDateUnavailable={isDateUnavailable}
              canAddNewEntry={firstEntry.key !== null && firstEntry.value.length > 0}
              showDeleteButton={false}
              onChangeItemDate={onChangeItemDate('add-list')}
              onChangeItemValue={onChangeItemValue('add-list')}
              onDeleteEntry={onDeleteEntry('add-list')}
              onAddEntry={onAddNewEntry}
            />

            {otherUsersToAdd.map(item => (
              <ActiveUserRow
                item={item}
                key={item.key}
                isDateUnavailable={isDateUnavailable}
                canAddNewEntry={false}
                showDeleteButton={item.key !== null}
                onChangeItemDate={onChangeItemDate('add-list')}
                onChangeItemValue={onChangeItemValue('add-list')}
                onDeleteEntry={onDeleteEntry('add-list')}
              />
            ))}
          </FlexContainer>
        </OrganizationStyles.Row>
      </FlexContainer>

      <ActiveUsersHistoryModal
        allActiveUsersByDateList={allActiveUsersByDateList}
        sortedByDate={sortedByDate}
        isDateUnavailable={isDateUnavailable}
        onChangeItemDate={onChangeItemDate('history')}
        onChangeItemValue={onChangeItemValue('history')}
        onDeleteEntry={onDeleteEntry('history')}
        open={historyOpen}
        setOpen={setHistoryOpen}
      />
    </OrganizationStyles.Card>
  )
}

export default ContactRate
