import { useCallback } from 'react'

const useHideIntercom = () => {
  const hideIntercom = useCallback((hide: boolean | undefined) => {
    const el =
      document.getElementById('intercom-container') ??
      document.getElementsByClassName('intercom-lightweight-app-launcher')[0]

    if (!el) {
      return
    }

    if (hide) {
      el.setAttribute('hidden', '')
    } else {
      el.removeAttribute('hidden')
    }
  }, [])

  return hideIntercom
}

export default useHideIntercom
