import { useFiltersStore } from '@/store'
import { PresetPeriod } from '@/types/periods'
import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'
import usePeriods from './usePeriods'
import { dateShortMonthFormat, getDifferenceInDays } from '@/utils/date'
import { getLocalTimeZone } from '@internationalized/date'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'

const periodToText: Record<PresetPeriod, string> = {
  '1day': 'day',
  '7days': '7 days',
  '30days': '30 days',
  '3months': '3 months',
  '6months': '6 months'
}

interface Params {
  newFeed?: boolean
}

const useComparisonText = ({ newFeed = false }: Params = { newFeed: false }) => {
  const topicPlanDateFilter = useFiltersStore(
    state => ({ dateRange: state.dateRange, datePeriod: state.datePeriod }),
    shallow
  )
  const dateFilter = useDateFilterStore(
    state => ({ dateRange: state.dateRange, datePeriod: state.datePeriod }),
    shallow
  )

  const { dateRange, datePeriod } = newFeed ? dateFilter : topicPlanDateFilter

  const { getPeriod } = usePeriods()

  const text = useMemo(() => {
    if (datePeriod === 'allTime' || datePeriod === 'custom') {
      return 'when compared to the previous period'
    }

    return 'when compared to the previous ' + periodToText[datePeriod]
  }, [datePeriod])

  const label = useMemo(() => {
    if (datePeriod === 'allTime' || datePeriod === 'custom') {
      return {
        current: 'Current period',
        previous: 'Previous period'
      }
    }

    return {
      current: 'Current ' + periodToText[datePeriod],
      previous: 'Previous ' + periodToText[datePeriod]
    }
  }, [datePeriod])

  const rangeText: { previous: string; current: string } = useMemo(() => {
    const range = dateRange ?? getPeriod(datePeriod).range

    if (!range) return { previous: '', current: '' }

    const differenceInDays = getDifferenceInDays(range)

    const previousStart = range.start.subtract({ days: differenceInDays })
    const previousEnd = range.start.subtract({ days: 1 })

    const previousStartText = dateShortMonthFormat(previousStart.toDate(getLocalTimeZone()))
    const previousEndText = dateShortMonthFormat(previousEnd.toDate(getLocalTimeZone()))
    const currentStartText = dateShortMonthFormat(range.start.toDate(getLocalTimeZone()))
    const currentEndText = dateShortMonthFormat(range.end.toDate(getLocalTimeZone()))

    return {
      previous: `${previousStartText} to ${previousEndText}`,
      current: `${currentStartText} to ${currentEndText}`
    }
  }, [dateRange, datePeriod, getPeriod])

  return {
    text,
    rangeText,
    label
  }
}

export default useComparisonText
