import FlexContainer from '@/components/atoms/flex-container'
import { DropdownMenuContent, DropdownMenuItem } from '@/components/atoms/options-menu'
import { styled } from '@/theme'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'

export const UsersContainer = styled(FlexContainer, {
  boxSizing: 'border-box',
  gap: '$xxs',
  maxHeight: 340,
  overflow: 'hidden auto'
})

export const UserOptionsTrigger = styled(DropdownMenuPrimitive.Trigger, {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: '$dark',
  boxSizing: 'border-box',
  borderRadius: 6,
  border: 'none',
  backgroundColor: '$greenLight',
  paddingRight: 10,
  justifyContent: 'space-between',
  minWidth: 104,

  '&:hover': {
    backgroundColor: '$greenBackground'
  },

  '.caret': {
    transition: '.2s'
  },

  '&[data-state="open"]': {
    '.caret': {
      transform: 'rotate(-90deg)'
    }
  },

  '&:disabled': {
    cursor: 'default',
    background: 'transparent'
  }
})

export const TriggerText = styled('span', {
  fontSize: 12,
  fontWeight: 400,
  padding: '6px 10px'
})

const DROPDOWN_CONTENT_PADDING = 0

export const UserOptionsMenuContent = styled(DropdownMenuContent, {
  padding: DROPDOWN_CONTENT_PADDING,
  gap: 6
})

export const UserOptionSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,
  background: '$stroke',
  width: `calc(100% + ${DROPDOWN_CONTENT_PADDING * 2}px)`,
  position: 'relative',
  left: `-${DROPDOWN_CONTENT_PADDING}px`
})

export const UserOption = styled(DropdownMenuItem, {
  padding: 8,
  margin: '0px 10px',

  '&:is(:first-child)': {
    marginTop: 6
  },

  '&:is(:last-child)': {
    marginBottom: 6
  },

  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',
        color: '$grey'
      }
    }
  }
})
