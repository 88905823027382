import { memo, useCallback, useMemo } from 'react'
import TopicChip from '@/components/atoms/topic-chip/TopicChip'
import { TopicGroupsContainer, TopicsCounter } from './TopicRow.styles'
import { Plus } from '@phosphor-icons/react'
import { colors } from '@/theme'
import TopicGroupEditorPopover from '../../topic-group-editor/TopicGroupEditorPopover'
import useToastMessageStore from '@/store/useToastMessageStore'
import { REFRESH_THEMES_MAX_DELAY, REFRESH_THEMES_MIN_DELAY } from '@/hooks/useClassification'
import { OnApplyCallback } from '../../topic-group-editor/TopicGroupEditor'
import useUser from '@/hooks/useUser'
import TopicService from '@/services/TopicService'
import { TopicGroupChipsProps } from './TopicRow.types'
import { useTopics } from '@/hooks/useTopics/useTopics'
import useTopicsStore from '@/store/useTopicsStore'
import useLogging from '@/hooks/useLogging'

const TopicGroupChips = ({ id, themeData }: TopicGroupChipsProps) => {
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const { logException } = useLogging({ context: 'topic-group-chips' })

  const addWarningToast = useToastMessageStore(state => state.addWarningToast)

  const { loadTopicGroups } = useTopics()

  const setTopicGroupInTopic = useTopicsStore(state => state.setTopicGroupInTopic)
  const removeTopicGroupFromTopic = useTopicsStore(state => state.removeTopicGroupFromTopic)

  const { userPermissions } = useUser()
  const isManager = userPermissions.topic.includes('manager')

  const topics = themeData ?? []

  const firstTheme = useMemo(() => {
    if (!themeData?.length) return
    const [_first] = themeData.slice(0)

    return _first
  }, [themeData])

  const removeFromGroup = useCallback(async () => {
    if (!firstTheme) return

    const removeToast = addLoadingToast({
      text: 'Removing from group...'
    })
    try {
      await TopicService.removeThemeRelation(id, firstTheme.themeId)
      removeTopicGroupFromTopic(id, firstTheme.themeId)

      setTimeout(() => {
        loadTopicGroups(false)
        removeToast()
        addSuccessToast({
          text: 'Removed successfully.'
        })
      }, REFRESH_THEMES_MIN_DELAY)
    } catch (error) {
      removeToast()
      const message = 'Failed to remove.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  }, [
    id,
    addErrorToast,
    addLoadingToast,
    addSuccessToast,
    firstTheme,
    loadTopicGroups,
    logException,
    removeTopicGroupFromTopic
  ])

  const onApplyAddToTopic = useCallback<OnApplyCallback>(
    async (selectedGroups, removedGroups) => {
      if (!selectedGroups.length && !removedGroups.length) {
        addWarningToast({ text: 'You need to add or remove a group.' })
        return 'error'
      }
      const removeToast = addLoadingToast({
        text: 'Applying changes to topic...'
      })

      try {
        await Promise.all(
          selectedGroups.map(async group => {
            await TopicService.addThemeRelation(id, group)
            setTopicGroupInTopic(id, group)
          })
        )

        await Promise.all(
          removedGroups.map(async group => {
            await TopicService.removeThemeRelation(id, group)
            removeTopicGroupFromTopic(id, group)
          })
        )

        setTimeout(() => {
          loadTopicGroups(false)
          removeToast()
          addSuccessToast({
            text: 'All changes applied.'
          })
        }, REFRESH_THEMES_MAX_DELAY)

        return 'success'
      } catch (error) {
        console.error(error)
        removeToast()
        const message = 'Failed to apply all changes.'
        logException(error, { message })
        addErrorToast({ text: message })

        return 'error'
      }
    },
    [
      id,
      addErrorToast,
      addSuccessToast,
      addLoadingToast,
      addWarningToast,
      loadTopicGroups,
      logException,
      setTopicGroupInTopic,
      removeTopicGroupFromTopic
    ]
  )

  return (
    <TopicGroupsContainer>
      {topics?.map(topic => (
        <TopicGroupEditorPopover
          appliedGroups={themeData || []}
          defaultOpen={false}
          key={topic.themeId}
          onApply={onApplyAddToTopic}
          singleKeyword
        >
          <TopicChip
            dismissable={isManager}
            ghost
            label={topic.themeName}
            onClick={e => {
              e.stopPropagation()
              removeFromGroup()
            }}
          />
        </TopicGroupEditorPopover>
      ))}
      <TopicGroupEditorPopover
        appliedGroups={themeData || []}
        defaultOpen={false}
        onApply={onApplyAddToTopic}
        singleKeyword
      >
        <TopicsCounter className="plus-container">
          <Plus color={colors.pajarito} size={12} weight="bold" />
        </TopicsCounter>
      </TopicGroupEditorPopover>
    </TopicGroupsContainer>
  )
}

export default memo(TopicGroupChips)
