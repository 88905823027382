import Select, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  MultiValue,
  MultiValueRemoveProps,
  Options,
  OptionsOrGroups,
  SingleValue
} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { getMultiSelectStyles } from '@/theme/select'
import { CaretDown, X } from '@phosphor-icons/react'
import { SelectComponents } from 'react-select/dist/declarations/src/components'

type Option = { label: string; value: string }

interface MultiSelectProps {
  options: string[]
  values: string[]
  disabled?: boolean
  onChange: (value: string[]) => void
  placeholder: string
  creatable?: boolean
  allowEmpty?: boolean
  onCreate?: (inputValue: string) => void
  isNewOptionValid?: (
    inputValue: string,
    value: Options<Option>,
    options: OptionsOrGroups<Option, GroupBase<Option>>
  ) => boolean
  customCreateLabel?: (inputValue: string) => string
}

const styles = getMultiSelectStyles<Option>()

const DropdownIndicator = (props: DropdownIndicatorProps<Option>) => (
  <components.DropdownIndicator {...props}>
    <CaretDown color="#8E8E8E" size={16} weight="bold" />
  </components.DropdownIndicator>
)

const MultiValueRemove = (props: MultiValueRemoveProps<Option>) => (
  <components.MultiValueRemove {...props}>
    <X color="#8E8E8E" size={12} weight="bold" />
  </components.MultiValueRemove>
)

const valuesToOptions = (values: string[]): Option[] =>
  values.map(value => ({ label: value, value }))

function MultiSelect({
  values,
  options,
  onChange,
  disabled = false,
  placeholder,
  creatable = false,
  allowEmpty = false,
  isNewOptionValid,
  customCreateLabel,
  onCreate: onCreateProp
}: MultiSelectProps) {
  function onSelect(selectedOptions: MultiValue<Option> | SingleValue<Option>) {
    const _selectedOptions = selectedOptions as MultiValue<Option>
    const newValues = _selectedOptions.map(option => option.value)
    if (!allowEmpty && newValues.length === 0) {
      return
    }
    onChange(newValues)
  }

  function onCreate(inputValue: string) {
    if (onCreateProp) {
      onCreateProp(inputValue)
      return
    }
    onChange([...values, inputValue])
  }

  const selectOptions = valuesToOptions(options)
  const selectionValues = valuesToOptions(values)

  const components: Partial<SelectComponents<Option, boolean, GroupBase<Option>>> = {
    DropdownIndicator,
    IndicatorSeparator: null,
    MultiValueRemove: () => <></>
  }

  if ((values.length > 1 && !disabled) || allowEmpty) {
    components.MultiValueRemove = MultiValueRemove
  }

  if (creatable) {
    return (
      <CreatableSelect
        className="basic-multi-select"
        classNamePrefix="select"
        components={components}
        formatCreateLabel={inputValue =>
          customCreateLabel ? customCreateLabel(inputValue) : `Add ${inputValue}`
        }
        isClearable={false}
        isDisabled={disabled}
        isMulti
        isValidNewOption={isNewOptionValid}
        menuPortalTarget={document.body}
        onChange={onSelect}
        onCreateOption={onCreate}
        options={selectOptions}
        placeholder={placeholder}
        styles={styles}
        value={selectionValues}
      />
    )
  }

  return (
    <Select
      className="basic-multi-select"
      classNamePrefix="select"
      components={components}
      isClearable={false}
      isDisabled={disabled}
      isMulti
      menuPortalTarget={document.body}
      onChange={onSelect}
      options={selectOptions}
      placeholder={placeholder}
      styles={styles}
      value={selectionValues}
    />
  )
}

export default MultiSelect
