import Dialog from '@/components/atoms/dialog'
import { lazy, Suspense, useState } from 'react'
import { shallow } from 'zustand/shallow'
// import ReasonDetails from './ReasonDetails'
import { useReasonsFeedbackStore } from '@/store/useFeedbackStore'
import { Feedback } from '@/types/feedbacks'
import ReasonDetails from '../reasons/ReasonDetails'
import { Intention, Reason, ReasonsTrend } from '@/types/reasons'
import { useFeedbacks } from '@/hooks/feedback/useFeedbacks'
import FeedbackDetails from '../feedback-details'

const FeedbackThread = lazy(() => import('../feed/feedbacks/FeedbackThread'))

interface ReasonDialogProps {
  selectedIntention: Intention | null
  selectedReason: Reason | null
  selectReason: (value: Reason | null) => void
  isLoadingChart: boolean
  trendSeries: ReasonsTrend
  onClose?: () => void
}

const ReasonDialog = ({
  selectedIntention,
  selectedReason,
  isLoadingChart,
  trendSeries,
  selectReason,
  onClose
}: ReasonDialogProps) => {
  // const { track } = useSegment()

  const { reset } = useFeedbacks({
    archived: false,
    store: 'reasons'
  })

  // const clusters = useKeywordStore(state => state.data, shallow)
  const { selectedFeedback } = useReasonsFeedbackStore(
    state => ({
      selectedFeedback: state.selectedFeedback
    }),
    shallow
  )
  const setSelectedFeedback = useReasonsFeedbackStore(state => state.setSelectedFeedback)

  const [feedbackThreadOpen, setFeedbackThreadOpen] = useState<Feedback | null>(null)

  const onOpenChange = (open: boolean) => {
    if (open) return

    onClose?.()
    selectReason(null)
    setSelectedFeedback(null)
    reset()
  }

  const hasFeedback = !!selectedFeedback || !!feedbackThreadOpen

  return (
    <Dialog
      align="right"
      closeIcon={!hasFeedback}
      contentProps={{
        css: { right: hasFeedback ? 'calc(min(calc(30% - 32px), 486px) + 2 * $xxs)' : '$xxs' }
      }}
      enableInteractionsOutside={false}
      modal={false}
      noBackdrop={false}
      onOpenChange={onOpenChange}
      open={!!selectedReason && !selectedIntention}
      useFullHeight
      width={'medium'}
    >
      {selectedReason && (
        <ReasonDetails
          {...selectedReason}
          isLoadingChart={isLoadingChart}
          keywordHashes={[]}
          onOpenThread={setFeedbackThreadOpen}
          trendSeries={trendSeries}
        />
      )}
      <FeedbackDetails interactOutside store="reasons" />

      <Suspense>
        {feedbackThreadOpen && (
          <FeedbackThread
            feedback={feedbackThreadOpen}
            onOpenChange={() => setFeedbackThreadOpen(null)}
            ticketId={feedbackThreadOpen?.ticket?.id ?? ''}
          />
        )}
      </Suspense>
    </Dialog>
  )
}

export default ReasonDialog
