import useToastMessageStore from '@/store/useToastMessageStore'
import useLogging from '../../useLogging'
import useCollectionStore from '@/store/useCollectionStore'
import { useMemo, useRef } from 'react'
import useAnalysisHubState from '@/store/useAnalysisHubStore'
import { useQuery } from '@tanstack/react-query'
import useBasicAreaOfInterestQuery from '../../areaOfInterest/useBasicAreaOfInterestQuery'
import getOpportunitiesQueryFn from './getOpportunitiesQueryFn'
import { RawAreaError } from '@/types/area/AreaRequests'
import { useTranslation } from 'react-i18next'
import useOpportunitiesMetricsQuery from './useOpportunitiesMetricsQuery'

const PROGRESS_STEP_SIZE = 100 / 3

export const OPPORTUNITIES_KEY_PREFIX = 'all-opportunities'

interface Params {
  enabled?: boolean
  sortColumn?: string
  sortDirection?: 'asc' | 'desc'
}

const defaultParams = {
  enabled: true,
  sortColumn: 'count',
  sortDirection: 'desc'
} satisfies Params

const useAllOpportunitiesWithMetricsQuery = ({
  enabled = defaultParams.enabled,
  sortColumn = defaultParams.sortColumn,
  sortDirection = defaultParams.sortDirection
}: Params = defaultParams) => {
  const { t } = useTranslation()

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { logException } = useLogging({ context: 'all-opportunities-query' })

  const searchText = useAnalysisHubState(state => state.searchText)

  const progress = useAnalysisHubState(state => state.opportunitiesProgress)
  const setProgress = useAnalysisHubState(state => state.setOpportunitiesProgress)
  const loadStep = useAnalysisHubState(state => state.opportunitiesLoadStep)
  const setLoadStep = useAnalysisHubState(state => state.setOpportunitiesLoadStep)

  const currentCollection = useCollectionStore(state => state.currentCollection)
  const currentCollectionId = currentCollection?.collectionId

  const { areas, isLoading: isLoadingCollectionAreas } = useBasicAreaOfInterestQuery({
    collectionId: currentCollectionId,
    enabled: true
  })

  const { areas: allAreas, isLoading: isLoadingAllAreas } = useBasicAreaOfInterestQuery({
    enabled: currentCollection?.type === 'opportunity'
  })

  const isLoadingAreas = isLoadingCollectionAreas || isLoadingAllAreas

  const mapMergedAreasErrorById = useRef<Record<string, RawAreaError>>({})

  /**
   * Used only to list opportunities query, reacts only to relevant changes for listing
   */
  const allOppsQueryKey = useMemo(
    () => [OPPORTUNITIES_KEY_PREFIX, { searchText, currentCollectionId }],
    [searchText, currentCollectionId]
  )

  const actualAreas = useMemo(() => {
    return currentCollection?.type === 'opportunity' ? allAreas : areas
  }, [currentCollection?.type, allAreas, areas])

  const isEnabled = enabled && actualAreas.length > 0

  const { data: allOpportunitiesData, isLoading: isLoadingAllOpportunities } = useQuery({
    queryKey: allOppsQueryKey,
    queryFn: async () => {
      setLoadStep('loading')
      setProgress(2)

      const [error, data] = await getOpportunitiesQueryFn({
        currentAreas: actualAreas,
        searchText,
        currentCollectionId,
        onError: logException,
        errorMap: mapMergedAreasErrorById.current
      })

      if (error) {
        logException(error, {
          message: 'Failed to fetch opportunity list',
          tags: { searchText, currentCollectionId: currentCollectionId ?? '' }
        })
        addErrorToast({ text: t('fetchOpportunityListErrorMessage') })
        setLoadStep('done')
        setProgress(0)
        throw error
      }

      if (data.length === 0) {
        setProgress(100)
        setLoadStep('done')
        return data
      }

      setProgress(PROGRESS_STEP_SIZE)

      return data
    },
    enabled: isEnabled
  })

  const allOpportunities = useMemo(() => {
    return allOpportunitiesData ?? []
  }, [allOpportunitiesData])

  const {
    opportunitiesWithMetrics: opportunities,
    isLoadingOpportunitiesWithSortMetric,
    isLoadingOpportunitiesWithAllMetrics,
    loadNextPage,
    hasMore,
    opportunitiesSorted,
    resetPage
  } = useOpportunitiesMetricsQuery({
    opportunities: allOpportunities,
    enabled: isEnabled,
    sortColumn,
    sortDirection
  })

  return {
    opportunities,
    isLoadingAllOpportunities: isEnabled
      ? isLoadingAllOpportunities || isLoadingAreas
      : isLoadingAreas,
    isLoadingAllOpportunitiesWithSortMetric: isLoadingOpportunitiesWithSortMetric,
    isLoadingOpportunitiesWithAllMetrics,
    isLoading:
      isLoadingAllOpportunities ||
      isLoadingOpportunitiesWithSortMetric ||
      isLoadingOpportunitiesWithAllMetrics,
    loadNextPage,
    hasMore,
    loadStep,
    progress,
    queryKey: allOppsQueryKey,
    allOpportunities: allOpportunities ?? [],
    allOpportunitiesSorted: opportunitiesSorted ?? [],
    resetPage
  }
}

export default useAllOpportunitiesWithMetricsQuery
