import TrendingTopicsCard from './TrendingTopicsCard'
import TrendingTopicItem from './TrendingTopicItem'
import Button from '@/components/atoms/button'
import { useFiltersStore } from '@/store'
import { shallow } from 'zustand/shallow'
import Text from '@/components/atoms/text'
import TrendingTopicItemEmpty from './TrendingTopicItemEmpty'
import TrendingTopicsEmptyList from './TrendingTopicsEmptyList'
import { getEmptyCardsArray, scrollOnList } from './trendingTopicsUtils'
import useTrendingTopics from '@/hooks/analytics/useTrendingTopics'
import { EmergingTopicsSkeleton } from './TrendingTopicsSkeletons'
import TrendingTopicsHeader from './TrendingTopicsHeader'
import { useTranslation } from 'react-i18next'

export const EmergingTopicsHeader = () => <TrendingTopicsHeader type="emerging" />

const EmergingTopics = () => {
  const { topics, nextPage, hasNextPage, max, isLoading } = useTrendingTopics('emerging')

  const dateRange = useFiltersStore(state => state.dateRange, shallow)

  const { t } = useTranslation()
  const onLoadMoreClick = () => {
    nextPage()
    scrollOnList('emerging-scroll-area')
  }

  const emptyCards = getEmptyCardsArray(topics)

  if (!dateRange) {
    return (
      <TrendingTopicsCard.Root type="emerging">
        <EmergingTopicsHeader />
        <TrendingTopicsCard.CenteredContainer>
          <TrendingTopicsCard.EmptyImage src={TrendingTopicsCard.EmptyImageSource} />

          <Text align="center">
            {t('noPeriodToCompareThisDataTo')}
            <br />
            {t('selectADifferentPeriodToViewTrends')}
          </Text>
        </TrendingTopicsCard.CenteredContainer>
      </TrendingTopicsCard.Root>
    )
  }

  if (isLoading) {
    return <EmergingTopicsSkeleton />
  }

  if (topics.length === 0) {
    return (
      <TrendingTopicsCard.Root type="emerging">
        <EmergingTopicsHeader />
        <TrendingTopicsEmptyList />
      </TrendingTopicsCard.Root>
    )
  }

  return (
    <TrendingTopicsCard.Root type="emerging">
      <EmergingTopicsHeader />

      <TrendingTopicsCard.ScrollArea id="emerging-scroll-area">
        <TrendingTopicsCard.ItemList>
          {topics.map(topic => (
            <TrendingTopicItem
              hasDetails
              key={topic.name}
              max={max}
              topic={topic}
              type="emerging"
            />
          ))}
          {emptyCards.map((_, index) => (
            <TrendingTopicItemEmpty key={index} />
          ))}
        </TrendingTopicsCard.ItemList>
      </TrendingTopicsCard.ScrollArea>

      <TrendingTopicsCard.Row justifyContent="flexEnd">
        {hasNextPage && (
          <Button onClick={onLoadMoreClick} size="small" variant="flat">
            {t('loadMore')}
          </Button>
        )}
      </TrendingTopicsCard.Row>
    </TrendingTopicsCard.Root>
  )
}

export default EmergingTopics
