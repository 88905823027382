import { useFiltersStore } from '@/store'
import useSelectedFilters from '../filters/useSelectedFilters'
import { cloneObject } from '@/utils/object'
import { FeedbackFilter } from '@/types/filters/FilterRequests'
import AnalyticsService from '@/services/AnalyticsService'
import { useQuery } from '@tanstack/react-query'
import { Intention } from '@/types/reasons'
import { capitalizeFirst } from '@/utils/letterCase'
import useComparisonText from '../useComparisonText'
import { formatPercentage, formatPercentageWithSignal, roundAndSuffix } from '@/utils/numbers'

interface Params {
  intention: Intention
  topicId: string
  asExploreFeedbackItem?: boolean
}

const useTopicIntentionIndicators = ({ intention, topicId, asExploreFeedbackItem }: Params) => {
  const toFeedbackFilterSchema = useFiltersStore(state => state.toAnalyticsFeedbackFilterSchema)
  const { allSelectedFilters } = useSelectedFilters()

  const queryFn = async () => {
    const feedbackFilter = toFeedbackFilterSchema()

    const newFilters: FeedbackFilter = cloneObject(feedbackFilter)
    if (newFilters.filter && !asExploreFeedbackItem) {
      newFilters.filter.topic_groups = [[topicId]]
    }

    const [error, data] = await AnalyticsService.indicators({
      feedback_search: newFilters,
      labeling_exact_count: true,
      group_by_exact_count: false,
      posted_at_exact_count: true,
      topic_search_text: feedbackFilter.filter?.search_text ?? '',
      metric_feedback: false,
      metric_customer: false,
      metric_intentions: [capitalizeFirst(intention)]
    })

    if (error) {
      throw error
    }

    return data
  }

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      'analytics',
      'topic',
      'indicators',
      topicId,
      intention,
      allSelectedFilters,
      asExploreFeedbackItem
    ],
    queryFn
  })

  const intentionMetrics = data?.metricsByIntention[0]

  const { text } = useComparisonText()

  const count = roundAndSuffix(intentionMetrics?.currentFeedbackCount ?? 0)
  const comparisonPercentage = formatPercentageWithSignal(intentionMetrics?.detailsPercentage ?? 0)
  const intentionPercentage = formatPercentage(intentionMetrics?.feedbackPercentage ?? 0)

  return {
    text,
    count,
    comparisonPercentage,
    intentionPercentage,
    isLoading,
    isError
  }
}

export default useTopicIntentionIndicators
