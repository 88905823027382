import FlexContainer from '@/components/atoms/flex-container'
import { PopoverHeader } from '@/components/atoms/popover'
import { styled, typefaceVariants } from '@/theme'

export const Header = styled(PopoverHeader, {
  ...typefaceVariants.subtitleSemiBoldMicro,
  background: '$neutralHighMedium',
  py: '$xxxs',
  px: '$xxs',

  maxWidth: 300,
  display: 'block',
  whiteSpace: 'nowrap',
  wordWrap: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
})

export const Container = styled(FlexContainer, {
  flexDirection: 'column',
  gap: '$xxs',
  pt: '$micro',
  pb: '$xxxs',
  maxWidth: 300,

  variants: {
    changeSentiment: {
      true: {
        padding: '0 !important'
      }
    }
  }
})

export const ChangePopoverItem = styled(FlexContainer, {
  mx: '$xxs',

  variants: {
    cliclable: {
      true: {
        border: 'none',
        outline: 'none',
        background: 'transparent',
        fontFamily: '$default',
        cursor: 'pointer',

        mx: '$micro',
        padding: '$micro',
        br: '$nano',

        '&:hover': {
          backgroundColor: '$greenLight',
          boxShadow: 'none'
        }
      }
    }
  }
})

export const SentimentChip = styled(FlexContainer, {
  py: '$nano',
  px: '$xxxs',
  br: '$nano',
  cursor: 'pointer',

  fontWeight: '$semibold',
  fontSize: '$micro',
  lineHeight: '$lg',
  textTransform: 'capitalize',

  variants: {
    sentiment: {
      positive: {
        color: '$feedbackPositivePure',
        backgroundColor: '$feedbackPositiveLight'
      },
      negative: {
        color: '$feedbackNegativePure',
        backgroundColor: '$feedbackNegativeLight'
      },
      neutral: {
        color: '$neutralLowLight',
        backgroundColor: '$neutralHighMedium'
      }
    },
    disabled: {
      true: { cursor: 'default' },
      false: {}
    }
  },
  defaultVariants: {
    sentiment: 'neutral',
    disabled: false
  }
})

export const SentimentDot = styled('div', {
  size: '$xxxs',
  br: '$full',

  variants: {
    sentiment: {
      positive: {
        backgroundColor: '$feedbackPositivePure'
      },
      negative: {
        backgroundColor: '$feedbackNegativePure'
      },
      neutral: {
        backgroundColor: '$neutralLowLight'
      }
    }
  },
  defaultVariants: {
    sentiment: 'neutral'
  }
})

export const changeItemCss = {
  padding: '$xxxs',
  gap: '$micro',
  cursor: 'pointer',
  alignItems: 'center',

  '&:hover': {
    backgroundColor: '$greenLight',
    boxShadow: 'none'
  }
}

export const SentimentItem = styled(FlexContainer, {
  ...changeItemCss,
  gap: '$micro'
})

export const SentimentItemSeparator = styled('div', {
  height: 1,
  width: '100%',

  bBottom: '$greyBorder'
})
