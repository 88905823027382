import { AxiosInstance, AxiosRequestConfig } from 'axios'
import DefaultErrorHandler from '../DefaultError'
import client from '../clients'

export type BaseRequestReturnType<T = unknown> = [DefaultErrorHandler, undefined] | [undefined, T]

export default class BaseRequestHandler {
  apiClient: AxiosInstance
  constructor(clientToUse: AxiosInstance = client) {
    this.apiClient = clientToUse
  }

  async get<T = unknown>(
    path: string,
    params?: unknown,
    config?: AxiosRequestConfig<unknown>
  ): Promise<BaseRequestReturnType<T>> {
    try {
      // Remove every param that is undefined
      const correctedParams = Object.fromEntries(
        Object.entries(params ?? {}).filter(([, value]) => value !== undefined)
      )

      const response = await this.apiClient.get<T>(path, {
        params: correctedParams,
        ...config
      })

      return [undefined, response.data]
    } catch (e) {
      return [new DefaultErrorHandler(e), undefined]
    }
  }

  async post<T = unknown>(
    path: string,
    payload?: unknown,
    config?: AxiosRequestConfig<unknown>
  ): Promise<BaseRequestReturnType<T>> {
    try {
      const response = await this.apiClient.post<T>(path, payload, config)

      return [undefined, response.data]
    } catch (e) {
      return [new DefaultErrorHandler(e), undefined]
    }
  }

  async put<T = unknown>(
    path: string,
    payload?: unknown,
    config?: AxiosRequestConfig<unknown>
  ): Promise<BaseRequestReturnType<T>> {
    try {
      const response = await this.apiClient.put<T>(path, payload, config)

      return [undefined, response.data]
    } catch (e) {
      return [new DefaultErrorHandler(e), undefined]
    }
  }

  async del<T = unknown>(
    path: string,
    params?: unknown,
    data?: unknown,
    config?: AxiosRequestConfig<unknown>
  ): Promise<BaseRequestReturnType<T>> {
    try {
      const response = await this.apiClient.delete<T>(path, { params, data, ...config })

      return [undefined, response.data]
    } catch (e) {
      return [new DefaultErrorHandler(e), undefined]
    }
  }

  async patch<T = unknown>(
    path: string,
    payload?: unknown,
    config?: AxiosRequestConfig<unknown>
  ): Promise<BaseRequestReturnType<T>> {
    try {
      const response = await this.apiClient.patch<T>(path, payload, config)

      return [undefined, response.data]
    } catch (e) {
      return [new DefaultErrorHandler(e), undefined]
    }
  }
}
