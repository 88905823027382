import { styled } from '@/theme'

const GoBackButton = styled('button', {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 8,
  background: '$neutralHighLight',
  bAll: '$neutralHighPure',
  transition: '.2s',
  cursor: 'pointer',
  size: 24,

  variants: {
    activeDropzone: {
      true: { background: '$pajaritoTransparent' }
    },
    active: {
      true: {
        backgroundColor: '$brandPrimaryLight',
        color: '$brandPrimaryPure',

        '&:hover': {
          background: '$brandPrimaryMedium'
        }
      }
    },
    color: {
      default: {
        '&:hover': {
          backgroundColor: '$brandPrimaryLight',
          color: '$brandPrimaryPure'
        }
      }
    },
    size: {
      regular: {
        minWidth: '40px',
        minHeight: '40px',
        padding: 4,
        size: '1.4em',
        boxSizing: 'border-box'
      }
    }
  },

  defaultVariants: {
    size: 'regular',
    color: 'default'
  }
})

export default GoBackButton
