import Bone from '@/components/atoms/bone'
import { styled } from '@/theme'

const SkeletonContainer = styled('div', {
  display: 'flex',
  gap: '$xs',
  width: 270,
  ml: '$xxs'
})

interface FeedbackAccountSkeletonProps {
  size?: number
}

function FeedbackAccountSkeleton({ size = 3 }: FeedbackAccountSkeletonProps) {
  return (
    <SkeletonContainer>
      {Array(size)
        .fill(null)
        .map((_, index) => (
          <Bone css={{ height: 24, width: '100%', borderRadius: 24 }} key={index} />
        ))}
    </SkeletonContainer>
  )
}

export default FeedbackAccountSkeleton
