import Text from '@/components/atoms/text'
import { KeywordTopic } from '@/types/keywords'
import Switch from '@/components/atoms/switch'
import useClassification, { CLASSIFICATION_LABELS } from '@/hooks/useClassification'
import Warning from '@/components/atoms/warning'
import { TopicCategory } from '@/types/classification'
import { useState, MouseEvent } from 'react'
import { CaretRight, Plus } from '@phosphor-icons/react'
import Button from '@/components/atoms/button'
import NameInput from '@/components/atoms/input/NameInput'
import { TopicItemData } from '@/types/classification/Theme'
import { colors } from '@/theme'
import * as S from './KeywordEditor.styles'
import FlexContainer from '@/components/atoms/flex-container'

interface Props {
  topics: TopicItemData[]
  isChecked: (topicId: string) => boolean
  onCheckTopic: (topic: KeywordTopic) => (checked: boolean) => void
  onClickMergedTopic: (topic: TopicItemData) => (e: MouseEvent<HTMLButtonElement>) => void
  topicCategory: TopicCategory
  themeId?: string
  hideAddTopic?: boolean
  isOverflowing?: boolean
}

const TopicsSection = ({
  topics,
  onCheckTopic,
  isChecked,
  onClickMergedTopic,
  topicCategory,
  themeId,
  hideAddTopic,
  isOverflowing
}: Props) => {
  const { getTopicById, addTopic, classificationLabelsMode } = useClassification()
  const [isAddingTopic, setIsAddingTopic] = useState(false)

  function onAddClick() {
    setIsAddingTopic(true)
  }

  function onAddCancel() {
    setIsAddingTopic(false)
  }

  function onAddConfirm(value: string) {
    addTopic(value, themeId, topicCategory)
    setIsAddingTopic(false)
  }

  const labels: Record<
    CLASSIFICATION_LABELS,
    { placeholder: string; button: string; warning: string }
  > = {
    'topic-group': {
      placeholder: 'Type topic name',
      button: 'Add topic',
      warning: 'topic'
    },
    'default-topic': {
      placeholder: 'Type subtopic name',
      button: 'Add subtopic',
      warning: 'subtopic'
    }
  }

  return (
    <>
      <S.Content fixedHeight isOverflowing={isOverflowing}>
        {topics.length === 0 ? (
          <Warning
            css={{ height: 200, padding: '$xxs' }}
            description={`You have to create a ${labels[classificationLabelsMode].warning} to add a keyword`}
            title="You can't apply."
          />
        ) : (
          topics.map(topic => {
            const storedTopic = getTopicById(topic.topicId)
            const topicId = topic.topicId
            const topicName = storedTopic?.topicName ?? topic.topicName

            return (
              <S.Item
                disablePointer={!topic.isMerged}
                key={topicId}
                onClick={topic.isMerged ? onClickMergedTopic(topic) : undefined}
              >
                <Text
                  color="pajarito"
                  maxWidth={180}
                  title={topicName}
                  truncate
                  typeface="textSemiboldSmall"
                >
                  {topicName}
                </Text>

                {topic.isMerged ? (
                  <CaretRight color={colors.grey} size={14} weight="bold" />
                ) : (
                  <Switch
                    checked={isChecked(topicId)}
                    onCheckedChange={onCheckTopic({ name: topic.topicName, topicId })}
                    value={topicId}
                  />
                )}
              </S.Item>
            )
          })
        )}
      </S.Content>
      {!hideAddTopic && (
        <FlexContainer css={{ padding: '$micro' }}>
          {isAddingTopic ? (
            <NameInput
              onCancel={onAddCancel}
              onConfirm={onAddConfirm}
              placeholder={labels[classificationLabelsMode].button}
              withoutButtons
            />
          ) : (
            <Button micro onClick={onAddClick} text>
              <Plus />
              <span>{labels[classificationLabelsMode].button}</span>
            </Button>
          )}
        </FlexContainer>
      )}
    </>
  )
}

export default TopicsSection
