import FlexContainer from '@/components/atoms/flex-container'
import { ArrowCounterClockwise, Gear } from '@phosphor-icons/react'
import AddAdvancedFilterMenu from './AddAdvancedFilterMenu'
import OptionsMenu, { OptionsMenuItem } from '@/components/atoms/options-menu'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import { useMemo } from 'react'
import { FilterNode, GenericFilter } from '@/types/filters/AdvancedFilters'
import { useTranslation } from 'react-i18next'
import useFilterEntityStore from '@/store/useFiltersStore/useFilterEntityStore'

interface Props {
  onResetClick: React.MouseEventHandler<HTMLDivElement>
  onAdvancedClick: React.MouseEventHandler<HTMLDivElement>
}

const AdvancedFiltersAreaHeader = ({ onResetClick, onAdvancedClick }: Props) => {
  const { t } = useTranslation()

  const options: OptionsMenuItem[] = [
    {
      text: t('resetFilters'),
      icon: <ArrowCounterClockwise />,
      onClick: onResetClick
    }
  ]

  const editMode = useFilterEntityStore(state => state.editMode)

  const { filters } = useAdvancedFilters()
  const hasAreaAdvancedFilters = useMemo(
    () =>
      (filters.value as (GenericFilter | FilterNode)[]).some(
        node => Object.keys(node).includes('operator') && node.isFromEntity
      ),
    [filters]
  )

  options.push({
    text: t('advanced'),
    icon: <Gear />,
    onClick: onAdvancedClick,
    disabled: !editMode && hasAreaAdvancedFilters
  })

  return (
    <FlexContainer css={{ mb: '$nano' }} direction="column">
      <FlexContainer alignItems="center" gap="micro" justifyContent="end">
        <AddAdvancedFilterMenu />
        <OptionsMenu options={options} />
      </FlexContainer>
    </FlexContainer>
  )
}

export default AdvancedFiltersAreaHeader
