import { ThemeItemData } from '@/types/classification/Theme'
import { KeywordTopic } from '@/types/keywords'
import { makeUniqueArray, sortByKeyString } from '@/utils/array'
import { useMemo } from 'react'

export interface TopicSearchResult {
  name: string
  id: string
  type: 'theme' | 'topic'
  topic?: KeywordTopic & {
    topicName?: string
    isMerged?: boolean
    mergedTopicList?: { id: string; name: string }[]
  }
  theme?: ThemeItemData
}

const useTopicsSearch = (searchQuery: string, themes: ThemeItemData[]) => {
  const results = useMemo(() => {
    if (!searchQuery.length) return []

    const themesResult: TopicSearchResult[] = themes
      .filter(theme => theme.themeName.toLowerCase().includes(searchQuery.toLowerCase()))
      .map(theme => ({ name: theme.themeName, id: theme.themeId ?? '', type: 'theme', theme }))

    const topicsResult: TopicSearchResult[] = themes
      .flatMap(theme => theme.topics)
      .filter(topic => topic.topicName.toLowerCase().includes(searchQuery.toLowerCase()))
      .map(topic => ({
        name: topic.topicName,
        id: topic.topicId,
        type: 'topic',
        topic: {
          topicName: topic.topicName,
          name: topic.topicName,
          topicId: topic.topicId,
          isMerged: topic.isMerged,
          mergedTopicList: topic.mergedTopicList
        }
      }))

    const _unique = makeUniqueArray('id', [...themesResult, ...topicsResult])
    return _unique.sort(sortByKeyString('name')) as TopicSearchResult[]
  }, [searchQuery, themes])

  return results
}

export default useTopicsSearch
