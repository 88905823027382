import { Suspense, useCallback, useEffect, useState, lazy } from 'react'
import { shallow } from 'zustand/shallow'
import InfiniteScroll from 'react-infinite-scroll-component'

import { useFeedbacks } from '@/hooks/feedback/useFeedbacks'

import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'
import { useFeedbackStore, useFiltersStore, useKeywordsStore } from '@/store'
import { Feedback } from '@/types/feedbacks'
import ScrollLoader from '../ScrollLoader'
import NoResults from '../NoResults'
import FlexContainer from '@/components/atoms/flex-container'
import FeedbackItem from '../../feedback-item'
import { useArchivedFeedbackStore } from '@/store/useFeedbackStore'
import FeedError from '../FeedError'
import useSelectedFilters from '@/hooks/filters/useSelectedFilters'
import useSegment from '@/hooks/useSegment'
import FeedbackDetails from '../../feedback-details'
import { FeedbackListInnerContainer, feedbackItemCSS } from './Feedbacks.styles'
import { useStoreWithEqualityFn } from 'zustand/traditional'

const FeedbackConversation = lazy(() => import('./FeedbackConversation'))

interface FeedbacksProps {
  archived?: boolean
}

function Feedbacks({ archived = false }: FeedbacksProps) {
  const { track } = useSegment()

  const useStore = archived ? useArchivedFeedbackStore : useFeedbackStore

  const { feedbacks, load, isLoading, hasMore, archive, unarchive, error } = useFeedbacks({
    archived,
    store: archived ? 'archived' : 'explore'
  })

  const resetKeywords = useKeywordsStore(state => state.reset)

  // const { selectedFeedback } = useStore(
  //   state => ({
  //     selectedFeedback: state.selectedFeedback
  //   }),
  //   shallow
  // )

  const selectedFeedback = useStoreWithEqualityFn(
    useStore,
    state => state.selectedFeedback,
    shallow
  )
  const setSelectedFeedback = useStore(state => state.setSelectedFeedback)

  const filters = useFiltersStore(
    state => ({
      dateRange: state.dateRange,
      orderBy: state.orderBy,
      search: state.search,
      filtersByURL: state.filtersByURL,
      accounts: Object.values(state.accountsStringFilters).flatMap(value => value.selected),
      accountsDateFilters: state.accountsDateFilters.filter(filter => filter.selected !== null),
      accountsNumericFilters: state.accountNumericFilters
        .filter(filter => filter.option !== 'all')
        .map(filter => ({ key: filter.key, value: filter.value, option: filter.option })),
      accountsBooleanFilters: state.accountBooleanFilters.filter(filter => filter.value !== null),
      users: Object.values(state.usersStringFilters).flatMap(value => value.selected),
      usersDateFilters: state.usersDateFilters.filter(filter => filter.selected !== null),
      usersNumericFilters: state.usersNumericFilters
        .filter(filter => filter.option !== 'all')
        .map(filter => ({ key: filter.key, value: filter.value, option: filter.option })),
      usersBooleanFilters: state.usersBooleanFilters.filter(filter => filter.value !== null)
    }),
    shallow
  )

  const {
    selectedNumericFilters,
    selectedDatetimeFilters,
    selectedStringFilters,
    selectedTopicFilters
  } = useSelectedFilters()

  // biome-ignore lint/correctness/useExhaustiveDependencies: it should happens once
  useEffect(() => {
    resetKeywords()
    if (feedbacks.length === 0) {
      load()
    }
  }, [])

  useDidUpdateEffect(
    () => {
      load()
    },
    [
      filters,
      selectedDatetimeFilters,
      selectedStringFilters,
      selectedTopicFilters,
      selectedNumericFilters
    ],
    true
  )

  const [feedbackThreadOpen, setFeedbackThreadOpen] = useState<Feedback | null>(null)
  const [conversationSearch, setConversationSearch] = useState('')

  const loadMore = () => {
    feedbacks.length && load()
  }

  const getFeedbackItemKey = useCallback(
    (feedback: Feedback) => feedback.feedbackId + feedback.keywordTopicList.length,
    []
  )

  function openThreadModal(feedback: Feedback) {
    track('feedback_user_view_thread')
    setFeedbackThreadOpen(feedback)
    setConversationSearch('')
  }

  function openThreadModalWithSearch(feedback: Feedback) {
    track('feedback_user_view_thread')
    setFeedbackThreadOpen(feedback)
    setConversationSearch(filters.search)
  }

  function onThreadModalClose() {
    setFeedbackThreadOpen(null)
    setConversationSearch('')
  }

  if (error && feedbacks.length === 0) {
    return <FeedError onReload={load} />
  }

  if (isLoading && feedbacks.length === 0) {
    return <ScrollLoader />
  }

  if (feedbacks.length === 0) {
    return <NoResults archived={archived} />
  }

  return (
    <>
      <InfiniteScroll
        dataLength={feedbacks.length}
        endMessage={<FlexContainer css={{ mb: 96 }} />}
        hasMore={hasMore}
        loader={isLoading ? <ScrollLoader /> : ''}
        next={loadMore}
        scrollThreshold="250px"
        scrollableTarget="scroll-feedback"
      >
        <FeedbackListInnerContainer>
          {feedbacks.map(item => (
            <FeedbackItem
              archived={archived}
              css={feedbackItemCSS}
              key={getFeedbackItemKey(item)}
              onArchive={archive}
              onConversationSearchClick={() => openThreadModalWithSearch(item)}
              onRepliesClick={() => openThreadModal(item)}
              onSelect={setSelectedFeedback}
              onUnarchive={unarchive}
              selectedId={selectedFeedback?.feedbackId}
              showAccountsData
              showConversationSearch
              {...item}
            />
          ))}
        </FeedbackListInnerContainer>
      </InfiniteScroll>
      <FeedbackDetails store={archived ? 'archived' : 'explore'} />

      <Suspense>
        {feedbackThreadOpen && (
          <FeedbackConversation
            feedback={feedbackThreadOpen}
            highlightSearch={conversationSearch}
            onOpenChange={onThreadModalClose}
            ticketId={feedbackThreadOpen?.ticket?.id ?? ''}
          />
        )}
      </Suspense>
    </>
  )
}

export default Feedbacks
