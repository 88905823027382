import { MouseEventHandler, ReactNode } from 'react'
import { ChipContainer, ChipIconButton } from './Chip.styles'
import { Cross1Icon } from '@radix-ui/react-icons'
import { CSS } from '@/theme'

export interface ChipProps {
  label: string
  color?: string
  negativeText?: boolean
  deleteIcon?: ReactNode
  icon?: ReactNode
  disabled?: boolean
  hoverText?: string
  wrapText?: boolean
  dashed?: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
  onDelete?: MouseEventHandler<HTMLButtonElement>
  css?: CSS
  showDeleteIcon?: boolean
}

const Chip: React.FC<ChipProps> = ({ showDeleteIcon = true, ...props }: ChipProps) => {
  const css: CSS = {
    backgroundColor: props.color ?? '$neutralHighMedium',
    ...props.css
  }

  const onDelete: typeof props.onDelete = e => {
    e.stopPropagation()
    props.onDelete?.(e)
  }

  return (
    <ChipContainer
      css={css}
      dashed={props.dashed}
      disabled={props.disabled}
      negative={props.negativeText}
      onClick={props.onClick}
      wrapText={props.wrapText}
    >
      {props.icon && props.icon}
      <span className="label" title={props.hoverText || props.label}>
        {props.label}
      </span>

      {showDeleteIcon && (
        <ChipIconButton disabled={props.disabled} negative={props.negativeText} onClick={onDelete}>
          {props.deleteIcon ?? <Cross1Icon />}
        </ChipIconButton>
      )}
    </ChipContainer>
  )
}

Chip.defaultProps = {
  negativeText: false,
  disabled: false
}

export default Chip
