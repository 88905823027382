import { useState } from 'react'

import useLetterCase from '@/hooks/useLetterCase'
import { FilterNumeric } from '@/types/filters/Filters'
import { RATING_KEY } from '@/utils/filters'
import { NumericFilterContent } from '../../FiltersSidebar/NumericGenericFilter'
import FilterItemDropdown from './FilterItemDropdown'
import useCustomerRecordsNumericFilter from '../../FiltersSidebar/CustomerRecordsFilter/Accounts/useCustomerRecordsNumericGenericFilter'
import { CustomerRecordType } from '@/types/manage-customers/CustomerRecordsFilters'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

interface Props {
  filter: FilterNumeric
  recordType: CustomerRecordType
}

function FilterItemDropdownCustomerRecordsNumeric(props: Props) {
  const { snakeToTitle } = useLetterCase()
  const { t } = useTranslation()

  const [enabled, setEnabled] = useState(false)

  const {
    exactValue,
    exactValueText,
    isDisabled,
    isExactValueOpen,
    isRangeOpen,
    max,
    min,
    onApply,
    onOptionChange,
    onRangeChange,
    onValueChange,
    rangeText,
    rangeValue,
    selectedOption
  } = useCustomerRecordsNumericFilter({
    filterKey: props.filter.key,
    type: props.filter.type,
    name: props.filter.name,
    recordType: props.recordType,
    enabled
  })

  function getTooltipText(filter: FilterNumeric) {
    let option = ''

    if (filter.option === 'is') {
      const exact = filter.value?.[0] ?? min
      option = `${exact}`
    }

    if (filter.option === 'between') {
      const start = filter.value?.[0] ?? min
      const end = filter.value?.[1] ?? max
      option = i18n.t('betweenRange', { start, end })
    }

    if (filter.option === 'null') {
      option = `${i18n.t('empty')}`
    }

    if (filter.key === RATING_KEY) {
      return i18n.t('isOption', { option })
    }
    return i18n.t('isFilterOption', { filterName: snakeToTitle(filter.name), option })
  }

  const label =
    props.filter.key === RATING_KEY
      ? {
          all: t('allRatings'),
          between: t('range'),
          is: t('exactlyRating'),
          null: t('emptyRating')
        }
      : { all: t('allValues'), between: t('range'), is: t('exactlyValue'), null: t('emptyValues') }

  return (
    <FilterItemDropdown
      content={1}
      contentType="count"
      disabled={isDisabled}
      dropdown={
        <NumericFilterContent
          exactValue={exactValue}
          exactValueText={exactValueText}
          isExactValueOpen={isExactValueOpen}
          isRangeOpen={isRangeOpen}
          labels={label}
          max={max}
          min={min}
          onOptionChange={onOptionChange}
          onRangeChange={onRangeChange}
          onValueChange={onValueChange}
          rangeText={rangeText}
          rangeValue={rangeValue}
          selectedOption={selectedOption}
        />
      }
      onApply={onApply}
      onOpenChange={setEnabled}
      open={enabled}
      title={snakeToTitle(props.filter.name)}
      tooltip={getTooltipText(props.filter)}
    />
  )
}

export default FilterItemDropdownCustomerRecordsNumeric
