import KeywordService from '@/services/KeywordService'
import { useFiltersStore, useKeywordsStore } from '@/store'
import { Keyword, KeywordCluster, KeywordSortType } from '@/types/keywords'
import { useCallback, useMemo, useState } from 'react'
import { shallow } from 'zustand/shallow'
import usePromiseSync from '../usePromiseSync'

export function useKeywords() {
  const { sync } = usePromiseSync()
  const pageSize = 100

  const { page, error, isLoading, hasMore, total, dataHasBeenLoaded, data } = useKeywordsStore(
    state => ({
      page: state.page,
      error: state.error,
      isLoading: state.isLoading,
      hasMore: state.hasMore,
      total: state.data.length,
      dataHasBeenLoaded: state.dataHasBeenLoaded,
      data: state.data
    }),
    shallow
  )

  const push = useKeywordsStore(state => state.push)
  const setError = useKeywordsStore(state => state.setError)
  const setLoading = useKeywordsStore(state => state.setLoading)
  const setHasMore = useKeywordsStore(state => state.setHasMore)
  const reset = useKeywordsStore(state => state.reset)
  const getFirstFrequency = useKeywordsStore(state => state.getFirstFrequency)

  const toRequestPayload = useFiltersStore(state => state.toKeywordRequestPayload)

  const [maxFrequency, setMaxFrequency] = useState(getFirstFrequency() ?? 1)

  const load = useCallback(
    async function (forceReset = false) {
      if (isLoading) {
        return
      }

      setLoading(true)
      setError(null)

      if (!hasMore && !forceReset) {
        setLoading(false)
        return
      }

      const payload = toRequestPayload(forceReset ? 0 : page, pageSize)

      try {
        await sync('keywords', KeywordService.clusterSearch(payload), response => {
          const keywordData = response
          if (keywordData.length === 0) {
            setHasMore(false)
            setLoading(false)
            return
          }

          const newKeywords = keywordData.map(
            (cluster): KeywordCluster => ({
              frequency: cluster.total_frequency,
              cluster: cluster.cluster,
              sentiment: {
                negativeCount: cluster.negative_count,
                neutralCount: cluster.neutral_count,
                positiveCount: cluster.positive_count,
                negativePercentage: cluster.negative_percent,
                neutralPercentage: cluster.neutral_percent,
                positivePercentage: cluster.positive_percent,
                netSentiment: cluster.net_sentiment
              },
              totalHits: cluster.total_hits,
              totalReasons: cluster.total_reasons || 0,
              keywordList: cluster.keyword_list.map(
                (keyword): Keyword => ({
                  keywordHash: keyword.keyword_hash,
                  text: keyword.text,
                  frequency: keyword.frequency,
                  sentiment: {
                    negativeCount: keyword.negative_count,
                    neutralCount: keyword.neutral_count,
                    positiveCount: keyword.positive_count,
                    negativePercentage: keyword.negative_percent,
                    neutralPercentage: keyword.neutral_percent,
                    positivePercentage: keyword.positive_percent,
                    netSentiment: keyword.net_sentiment
                  },
                  totalHits: keyword.total_hits,
                  topics: keyword.topics
                    ? keyword.topics.map(topic => ({ topicId: topic.topic_id, name: topic.name }))
                    : [],
                  topicIds: keyword.topics ? keyword.topics.map(topic => topic.topic_id) : []
                })
              )
            })
          )

          push(newKeywords)
          setMaxFrequency(getFirstFrequency() ?? newKeywords[0].frequency)
          setLoading(false)
        })
      } catch (error) {
        setError(error as Error)
        setLoading(false)
      }
    },
    [
      isLoading,
      hasMore,
      page,
      setLoading,
      setError,
      toRequestPayload,
      push,
      getFirstFrequency,
      setHasMore,
      sync
    ]
  )

  const keywordSortType = useFiltersStore(state => state.keywordSortType)
  const clusters = useMemo(() => {
    if (keywordSortType === KeywordSortType.NET_SENTIMENT_ASC) {
      return [...data].sort((a, b) => a.sentiment.netSentiment - b.sentiment.netSentiment)
    }

    if (keywordSortType === KeywordSortType.NET_SENTIMENT_DESC) {
      return [...data].sort((a, b) => b.sentiment.netSentiment - a.sentiment.netSentiment)
    }

    return data
  }, [data, keywordSortType])

  return {
    isLoading,
    error,
    hasMore,
    load,
    reset,
    maxFrequency,
    total,
    dataHasBeenLoaded,
    clusters
  }
}
