import Button from '@/components/atoms/button'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { memo } from 'react'
import KeywordEditor, { KeywordEditorProps } from './KeywordEditor'
import { DropdownMenuSubContent } from './KeywordEditor.styles'

const KeywordEditorSubmenu = ({
  onCancel,
  ...props
}: Omit<KeywordEditorProps, 'renderApplyButton' | 'renderCancelButton'>) => {
  return (
    <DropdownMenuSubContent sideOffset={10}>
      <KeywordEditor
        {...props}
        onCancel={onCancel}
        renderApplyButton={(handleOnApply, isDisabled) => (
          <DropdownMenuPrimitive.Item asChild>
            <Button disabled={isDisabled} onClick={handleOnApply} small>
              {'Apply'}
            </Button>
          </DropdownMenuPrimitive.Item>
        )}
        renderCancelButton={handleOnCancel => (
          <DropdownMenuPrimitive.Item asChild>
            <Button onClick={handleOnCancel} small text>
              Cancel
            </Button>
          </DropdownMenuPrimitive.Item>
        )}
      />
    </DropdownMenuSubContent>
  )
}

export default memo(KeywordEditorSubmenu)
