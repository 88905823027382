import FlexContainer from '@/components/atoms/flex-container'
import { CSS, styled } from '@/theme'
import { Sparkle, X } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../plugins/i18n/i18n'

const StyledContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  padding: '2px 4px',
  gap: 2,
  borderRadius: 4,
  background: '$feedbackWarningPure',
  color: '$brandPrimaryPure',
  fontSize: '$nano',
  fontWeight: '$semibold',
  width: 'fit-content'
})

const Badge = ({ count, css }: { count?: number; css?: CSS }) => (
  <StyledContainer css={css}>
    <Sparkle size={10} weight="fill" />
    <span>
      {i18n.t('new')} {count ? `(${count})` : ''}
    </span>
  </StyledContainer>
)

const FilterContainer = styled('button', {
  all: 'unset',
  background: '$feedbackWarningPure',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 4,
  padding: '2px 4px',
  gap: '$micro',
  cursor: 'pointer',
  boxSizing: 'border-box',
  fontSize: '$nano',
  fontWeight: '$semibold',
  color: '$brandPrimaryPure',
  bAll: '$feedbackWarningPure',

  '&:hover': {
    background: '$feedbackWarningMedium'
  },

  '.dismiss': {
    display: 'none',
    position: 'relative',
    bottom: -1
  },

  variants: {
    checked: {
      true: {
        bAll: '$brandPrimaryPure',
        '.dismiss': {
          display: 'block'
        }
      },
      false: {}
    }
  },

  defaultVariants: {
    checked: false
  }
})

interface FilterProps {
  count: number
  checked: boolean
  onCheckedChange: (checked: boolean) => void
}
const Filter = ({ count, checked, onCheckedChange }: FilterProps) => {
  const { t } = useTranslation()

  if (count === 0) return <></>

  return (
    <FilterContainer checked={checked} onClick={() => onCheckedChange(!checked)}>
      <FlexContainer alignItems="center" gap="nano">
        <Sparkle size={12} weight="fill" />
        <span>
          {t('new')} ({count})
        </span>
      </FlexContainer>
      <X className="dismiss" size={12} />
    </FilterContainer>
  )
}

const NewOpportunity = {
  Badge,
  Filter
}

export default NewOpportunity
