export const mapPropertyToLabel: { [x: string]: string } = {
  product_reference: 'Custom Tag',
  feedback_keyword_classes: 'Feedback Intention',
  feedback_source_ids: 'Feedback Type',
  data_type: 'Feedback Type',
  'Connector name': 'Source name',
  overall_quality: 'Feedback Quality'
}

export const REMOVED_FILTERS = [
  'product_reference',
  'keyword_quality',
  'feedback_source_id',
  'data_type',
  'feedback_keyword_classes' // This is actually feedback intention
]
