import useUser from '@/hooks/useUser'
import { useUIStore } from '@/store'
import { useEffect } from 'react'
import FlexContainer from '@/components/atoms/flex-container'
import { Outlet } from 'react-router-dom'
import NoPermission from '@/components/molecules/no-permission/NoPermission'
import DefaultContent from '@/components/molecules/layout/DefaultContent'
import {
  AnalyticsFlexContainer,
  AnalyticsScrollArea
} from '@/components/molecules/analytics/Analytics.styles'
import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import AskBirdieFloatingButton from '@/components/molecules/assistant/AskBirdieFloatingButton'

const AnalyticsPage = () => {
  const { setPageTitle } = useUIStore()
  const setIsAssistantOpen = useUIStore(state => state.setAssistantOpen)

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen once
  useEffect(() => {
    setPageTitle('Analytics')
    setIsAssistantOpen(false)
  }, [])

  const { userPermissions, currentUser } = useUser()
  if (!userPermissions.analytics.includes('basic') && currentUser) {
    return <NoPermission />
  }

  return (
    <DefaultContent css={{ width: '100%' }}>
      <AnalyticsScrollArea>
        <ScrollAreaViewport
          asChild
          css={{ display: 'flex !important', justifyContent: 'center' }}
          id="scroll-analytics"
        >
          <AnalyticsFlexContainer css={{ pt: '$xs' }} direction="column">
            <Outlet />
            <FlexContainer css={{ minHeight: '$micro' }} />
            <AskBirdieFloatingButton />
          </AnalyticsFlexContainer>
        </ScrollAreaViewport>
        <ScrollAreaScrollbar orientation="vertical">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </AnalyticsScrollArea>
    </DefaultContent>
  )
}

export default AnalyticsPage
