import { FilterItem } from '@/types/filters/Filters'
import { Stack } from '@phosphor-icons/react'
import FilterSubMenu from '../AddFilterMenu/FilterSubMenu'
import { useStringGenericFilter } from '../StringGenericFilter'
import { useState } from 'react'
import FeedbackDataTypeContent from './FeedbackDataTypeContent'
import { useTranslation } from 'react-i18next'

export interface FeedbackTypeSectionProps {
  feedbackDataType: FilterItem
}

const FeedbackDataTypeSubmenu = ({ feedbackDataType }: FeedbackTypeSectionProps) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const {
    isLoading: isLoadingValues,
    hasChanges,
    apply
  } = useStringGenericFilter({
    type: feedbackDataType.type,
    name: feedbackDataType.name,
    filterKey: feedbackDataType.key,
    enabled: open
  })

  return (
    <FilterSubMenu
      icon={<Stack />}
      isDisabled={!hasChanges}
      isLoading={isLoadingValues}
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      title={t('feedbackType')}
    >
      <FeedbackDataTypeContent enabled={open} feedbackDataType={feedbackDataType} />
    </FilterSubMenu>
  )
}

export default FeedbackDataTypeSubmenu
