import { formatPercentageNoDecimalWithSymbol, roundAndSuffix } from '../numbers'

export const percentageScoreTransformer = (value: number) => Number((value * 100).toFixed(2))

export const percentageFormatter = (value: number) => formatPercentageNoDecimalWithSymbol(value)

export const fixedValueFormatter = (value: number, precision: number) => {
  if (Number.isNaN(value)) {
    console.warn('invalid number')
    return ''
  }
  if (value === 0) return '0'
  if (value >= 100) return '100'

  if (Number.isInteger(value)) return `${value}`
  const fixedValue = value.toFixed(precision)

  if (fixedValue.endsWith('0')) {
    return `${fixedValue.slice(0, fixedValue.length - 1)}`
  }

  return `${fixedValue}`
}

export const scoreFormatter = (value: number) => fixedValueFormatter(value, 1)
// formatPercentageNoDecimalWithSymbol(value).replace('%', '')

export const integerFormatter = (value: number) => roundAndSuffix(value)

export const npsPercentageFormater = (value: number) => {
  const fixedValue = fixedValueFormatter(value, 2)
  return `${fixedValue}%`
}

export const currencyFormater = (value: number, currency?: string) => {
  return `${currency ?? ''} ${roundAndSuffix(value)}`
}
