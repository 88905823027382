import Button from '@/components/atoms/button'
import Popover from '@/components/atoms/popover'
import { PopoverContent, SectionTitle } from '../saved-filters/SavedFiltersPopover.styles'
import useSavedFiltersStore from '@/store/useSavedFiltersStore'
import { CheckboxGroup, CheckboxGroupOption } from '@/components/atoms/checkbox'
import FlexContainer from '@/components/atoms/flex-container'
import { PopoverClose } from '@radix-ui/react-popover'
import Divider from '@/components/atoms/divider'
import Search from '../search'
import { styled } from '@/theme'
import { useEffect, useMemo, useState } from 'react'
import { CaretDown, FolderStar } from '@phosphor-icons/react'
import Text from '@/components/atoms/text'
import { Badge } from '@/components/atoms/badge'
import useSegment from '@/hooks/useSegment'
import { SavedFilterContent } from '@/types/filters/Filters'
import { useTranslation } from 'react-i18next'
import { sortByKeyString } from '@/utils/array'

const SelectionScrollArea = styled('div', {
  height: 240,
  overflow: 'hidden',
  bottom: 0,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '$xs'
})

const SelectButton = styled('button', {
  borderRadius: 8,
  fontFamily: 'inherit',
  cursor: 'pointer',
  fontSize: '$xxxs',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: '.2s',
  backgroundColor: '$neutralHighLight',
  bAll: '$neutralHighPure',
  padding: '0 $xxs',
  fontWeight: '$regular',

  '.caret': {
    transform: 'rotate(0deg)',
    transition: '.2s',
    color: '$neutralLowPure'
  },

  '&:hover': {
    backgroundColor: '$brandPrimaryLight',
    color: '$brandPrimaryPure',
    bAll: '$brandPrimaryPure'
  },

  '&[data-state="open"]': {
    color: '$brandPrimaryPure',
    backgroundColor: '$brandPrimaryLight',
    bAll: '$brandPrimaryPure',

    '.caret': {
      transform: 'rotate(180deg)'
    }
  },

  variants: {
    empty: {
      true: {
        color: '$neutralLowLight'
      },
      false: {
        color: '$neutralLowPure'
      }
    },
    size: {
      small: {
        height: 32
      },
      regular: {
        height: 40
      }
    }
  },

  defaultVariants: {
    empty: 'false',
    size: 'regular'
  }
})

interface Props {
  onClose?: () => void
  onChange: (value: string[]) => void
  value: string[]
  showCaret?: boolean
}

const SavedFilterSelection = ({ onClose, onChange, value, showCaret = false }: Props) => {
  const { track } = useSegment()

  const [selectedValues, setSelectedValues] = useState<string[]>(value)
  useEffect(() => {
    setSelectedValues(value)
  }, [value])

  const { t } = useTranslation()

  const savedFilters = useSavedFiltersStore(state => state.savedFilters)
  const sortedFilters = savedFilters.sort(sortByKeyString('name'))

  const [searchInput, setSearchInput] = useState('')
  const filteredSavedFiltersOptions: CheckboxGroupOption[] = useMemo(
    () =>
      sortedFilters
        .filter(
          savedFilter =>
            savedFilter.name.toLowerCase().includes(searchInput.toLowerCase()) &&
            (savedFilter.content as SavedFilterContent[]).every(f => Boolean(f.type))
        )
        .map(savedFilter => ({
          text: savedFilter.name,
          value: savedFilter.filterId ?? savedFilter.name
        })),
    [sortedFilters, searchInput]
  )

  const searchResultsText = `${filteredSavedFiltersOptions.length} filter${
    filteredSavedFiltersOptions.length === 1 ? '' : 's'
  } found for your search`

  // biome-ignore lint/correctness/useExhaustiveDependencies: it should track when the user starts typing
  useEffect(() => {
    track('dashboard_user_saved_view_search')
  }, [searchInput.length > 0])

  const savedFiltersOptions: CheckboxGroupOption[] = sortedFilters
    .filter(savedFilter =>
      (savedFilter.content as SavedFilterContent[]).every(f => Boolean(f.type))
    )
    .map(savedFilter => ({
      text: savedFilter.name,
      value: savedFilter.filterId
    }))

  const onAddClick = () => {
    onChange(selectedValues)
    track('dashbaord_user_saved_view_add')
  }

  const onCloseClick = () => {
    onClose?.()
    setIsOpen(false)
    setSelectedValues(value)
  }

  const [isOpen, setIsOpen] = useState(false)

  const onOpenChange = (open: boolean) => {
    setIsOpen(open)
    if (!open) {
      onCloseClick()
    }
  }

  const count = value.length

  const renderButton = () => {
    if (count > 0) {
      return (
        <SelectButton size={showCaret ? 'regular' : 'small'}>
          <FlexContainer alignItems="center" gap="micro">
            <FolderStar size={16} />
            <span>Saved filters</span>
            <Badge>
              <Text
                color="neutralHighLight"
                fontSize="micro"
                fontWeight="bold"
                lineHeight="default"
              >
                {count}
              </Text>
            </Badge>
          </FlexContainer>
          {showCaret && <CaretDown className="caret" size={16} weight="bold" />}
        </SelectButton>
      )
    }

    return (
      <SelectButton empty size={showCaret ? 'regular' : 'small'}>
        <FlexContainer alignItems="center" gap="micro">
          <FolderStar size={16} />
          <span>{t('selectSavedFilters')}</span>
        </FlexContainer>
        {showCaret && <CaretDown className="caret" size={16} weight="bold" />}
      </SelectButton>
    )
  }

  return (
    <Popover
      buttonChildren={renderButton()}
      contentProps={{ align: 'start' }}
      customButton
      modal={false}
      onOpenChange={onOpenChange}
      open={isOpen}
    >
      <PopoverContent>
        <Search
          css={{ mb: '$micro' }}
          onChange={e => setSearchInput(e.currentTarget.value)}
          placeholder={t('selectSavedFilters')}
          size="small"
          small
          value={searchInput}
        />

        <Divider line="solid" />

        <SelectionScrollArea>
          {searchInput.trim().length > 0 ? (
            <>
              <SectionTitle>{searchResultsText}</SectionTitle>
              <CheckboxGroup
                onChange={setSelectedValues}
                options={filteredSavedFiltersOptions}
                values={selectedValues}
              />
            </>
          ) : (
            <>
              {Boolean(savedFilters.length) && (
                <>
                  <SectionTitle>{t('savedFilters')}</SectionTitle>
                  <CheckboxGroup
                    onChange={setSelectedValues}
                    options={savedFiltersOptions}
                    values={selectedValues}
                  />
                  <Divider line="solid" />
                </>
              )}
            </>
          )}
        </SelectionScrollArea>

        <Divider line="solid" />

        <FlexContainer gap="micro">
          <PopoverClose asChild>
            <Button
              disabled={selectedValues.length === 0}
              fullWidth
              onClick={onAddClick}
              size="small"
            >
              {t('applySavedFilter')}
            </Button>
          </PopoverClose>
        </FlexContainer>
      </PopoverContent>
    </Popover>
  )
}

export default SavedFilterSelection
