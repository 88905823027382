import MetricsTrendlineCard from './MetricsTrendlineCard'
import useSegment from '@/hooks/useSegment'
import { AllMetricsKey } from '@/types/metrics'
import { BreakdownOption } from '@/types/time-series/TimeSeries'
import { AnnotationType } from '@/types/annotations/Annotation'

const baseList: BreakdownOption[] = [
  'sentiment',
  'intention',
  // 'kind',
  'source_alias',
  'feedbackDetail',
  'customField',
  'account',
  'user',
  'satisfaction',
  'date'
]

const breakdownList: BreakdownOption[] = ['all', ...baseList]
const defaultOption = 'all'

const SegmentMetricsTrendline = () => {
  const { track } = useSegment()
  const trackMetric = (metric: AllMetricsKey) => {
    track('segment_trendline_metrics', {
      selected_metric: metric
    })
  }

  const annotationType: AnnotationType = 'segment'

  const trackCreateAnnotation = () => {
    track('segment_trendline_add_annotation')
  }

  const trackCopyTable = () => {
    track('segment_trendline_copy_data_table')
  }

  return (
    <MetricsTrendlineCard
      annotationType={annotationType}
      breakdownList={breakdownList}
      defaultOption={defaultOption}
      onCopyTable={trackCopyTable}
      trackCreateAnnotation={trackCreateAnnotation}
      trackMetric={trackMetric}
      useAppliedFilters
      isAreasLoading={false}
    />
  )
}
export default SegmentMetricsTrendline
