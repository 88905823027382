import Bone from '@/components/atoms/bone'
import { AnalyticsFlexContainer } from '../Analytics.styles'
import IndicatorCard from './IndicatorCard'
import { styled } from '@/theme'
import { ReactNode } from 'react'
import { ColorKeys } from '@/theme/colors'
import { Bug, ChatText, Megaphone } from '@phosphor-icons/react'

const SkeletonCard = styled(IndicatorCard.Root, {
  height: 122
})

interface Props {
  children: ReactNode
  color: ColorKeys
}
const IndicatorCardSkeleton = ({ children, color }: Props) => (
  <SkeletonCard color={color}>
    <IndicatorCard.Column>
      {children}
      <Bone css={{ height: 40, width: 80 }} />
    </IndicatorCard.Column>
    <IndicatorCard.Column css={{ gap: '$nano' }}>
      <Bone css={{ height: 16, width: 180 }} />
      <Bone css={{ height: 16, width: 128 }} />
    </IndicatorCard.Column>
  </SkeletonCard>
)

const TicketsSkeleton = () => (
  <IndicatorCardSkeleton color="brandPrimaryPure">
    <IndicatorCard.Header>
      <IndicatorCard.IconBox>
        <ChatText />
      </IndicatorCard.IconBox>
      <IndicatorCard.Title>Feedback</IndicatorCard.Title>
    </IndicatorCard.Header>
  </IndicatorCardSkeleton>
)
const IssuesSkeleton = () => (
  <IndicatorCardSkeleton color="feedbackNegativePure">
    <IndicatorCard.Header>
      <IndicatorCard.IconBox>
        <Bug />
      </IndicatorCard.IconBox>
      <IndicatorCard.Title>Issue</IndicatorCard.Title>
    </IndicatorCard.Header>
  </IndicatorCardSkeleton>
)
const RequestsSkeleton = () => (
  <IndicatorCardSkeleton color="feedbackInformativePure">
    <IndicatorCard.Header>
      <IndicatorCard.IconBox>
        <Megaphone />
      </IndicatorCard.IconBox>
      <IndicatorCard.Title>Request</IndicatorCard.Title>
    </IndicatorCard.Header>
  </IndicatorCardSkeleton>
)

const IndicatorsSkeletons = () => (
  <AnalyticsFlexContainer justifyContent="stretch">
    <TicketsSkeleton />
    <IssuesSkeleton />
    <RequestsSkeleton />
  </AnalyticsFlexContainer>
)

export default IndicatorsSkeletons
