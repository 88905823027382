import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'

import { useFiltersStore } from '@/store'
import { FilterString } from '@/types/filters/Filters'
import { SENTIMENT_KEY } from '@/utils/filters'
import FilterItem from '../FilterItem'
import AppliedSentimentFiltersEdit from './AppliedSentimentFiltersEdit'

function AppliedSentimentFilters() {
  const apply = useFiltersStore(state => state.apply)
  const removeStringFilter = useFiltersStore(state => state.removeStringFilter)
  const stringFilters = useFiltersStore(state => state.stringFilters, shallow)

  const appliedFilters = useMemo(() => {
    return stringFilters.filter(
      filter => filter.key === SENTIMENT_KEY && filter.selected.length > 0
    )
  }, [stringFilters])

  function removeFilter(filter: FilterString) {
    filter.selected.forEach(option => removeStringFilter(filter.key, option))
    apply()
  }

  return appliedFilters.map(filter => (
    <FilterItem
      editButton={<AppliedSentimentFiltersEdit filter={filter} />}
      key={filter.key}
      name="Sentiment"
      onDelete={() => removeFilter(filter)}
    />
  ))
}

export default AppliedSentimentFilters
