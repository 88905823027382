import Button from '@/components/atoms/button'
import Dialog from '@/components/atoms/dialog/Dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import OptionsMenu, { OptionsMenuItem } from '@/components/atoms/options-menu'
import Text from '@/components/atoms/text'
import { FilterNode, GenericFilter } from '@/types/filters/AdvancedFilters'
import { Plus, SelectionForeground } from '@phosphor-icons/react'
import { useMemo, useState } from 'react'
import AdvancedFilterGroup from './AdvancedFilterGroup'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

const checkGenericFilterDisabled = (genericFilter: GenericFilter) => {
  if (genericFilter.status === 'validating' || genericFilter.status === 'invalid') {
    return true
  }

  if (genericFilter.type === 'text' && genericFilter.value === '') {
    return true
  }

  return false
}

interface Params {
  open: boolean
  onOpenChange: (open: boolean) => void
}

const CreateAdvancedFilterModal = ({ open, onOpenChange }: Params) => {
  const { applyFilterSet, filters } = useAdvancedFilters()
  const storedCurrentFilter = useMemo(
    () =>
      (filters.value as (GenericFilter | FilterNode)[]).find(
        f => Object.keys(f).includes('operator') && (f as FilterNode).operator !== '$not'
      ) as FilterNode | undefined,
    [filters]
  )
  const { t } = useTranslation()

  const defaultFilterNode: FilterNode = {
    operator: '$and',
    value: []
  }

  const [currentFilter, setCurrentFilter] = useState<FilterNode>(
    storedCurrentFilter ?? defaultFilterNode
  )

  const isEmpty = Array.isArray(currentFilter.value) && currentFilter.value.length === 0

  const onAddFilter = () => {
    setCurrentFilter({
      operator: currentFilter.operator,
      value: [
        ...(currentFilter.value as (GenericFilter | FilterNode)[]),

        {
          name: 'text.feedback',
          status: 'valid',
          type: 'text',
          value: '',
          path: 'text'
        }
      ]
    })
  }

  const onAddFilterGroup = () => {
    setCurrentFilter({
      operator: currentFilter.operator,
      value: [
        ...(currentFilter.value as (GenericFilter | FilterNode)[]),

        {
          operator: '$and',
          value: [
            {
              name: 'text.feedback',
              status: 'valid',
              type: 'text',
              value: '',
              path: 'text'
            }
          ]
        }
      ]
    })
  }

  const menuOptions: OptionsMenuItem[] = [
    {
      text: i18n.t('addFilter'),
      icon: <Plus />,
      onClick: onAddFilter
    },
    {
      text: i18n.t('addFilterGroup'),
      icon: <SelectionForeground />,
      onClick: onAddFilterGroup
    }
  ]

  const isApplyDisabled = useMemo(() => {
    let isDisabled = false
    for (const node of currentFilter.value as (GenericFilter | FilterNode)[]) {
      if (Object.keys(node).includes('operator') && (node as FilterNode).operator !== '$not') {
        const children = (node as FilterNode).value as GenericFilter[]
        for (const child of children) {
          if (checkGenericFilterDisabled(child)) {
            isDisabled = true
            break
          }
        }
      }

      if (checkGenericFilterDisabled(node as GenericFilter)) {
        isDisabled = true
        break
      }
    }

    return isDisabled
  }, [currentFilter])

  const onApplyClick = () => {
    applyFilterSet(currentFilter)
    onOpenChange(false)
  }

  return (
    <Dialog align="center" closeIcon onOpenChange={onOpenChange} open={open} width="large">
      <FlexContainer direction="column" gap="xxxs">
        <Text as="h2" color="neutralLowPure" fontSize="xxs" fontWeight="bold">
          {t('advancedFilters')}
        </Text>
        {isEmpty ? (
          <Text color="neutralLowLight" fontSize="xxxs">
            {t('useAdvancedFiltersToRefineSearchParametersOrSimplySpecifyTheContentYouWantToSee')}
          </Text>
        ) : (
          <AdvancedFilterGroup level={0} node={currentFilter} onNodeChange={setCurrentFilter} />
        )}
        <Divider line="solid" orientation="horizontal" />
        <OptionsMenu
          customTrigger={
            <Button css={{ padding: 0 }} onClick={onAddFilter} size="small" variant="link">
              <Plus />
              <span>{t('addFilter')}</span>
            </Button>
          }
          options={menuOptions}
        />

        {!isEmpty && (
          <>
            <Divider line="solid" orientation="horizontal" />
            <FlexContainer fullWidth justifyContent="flexEnd">
              <Button disabled={isApplyDisabled} onClick={onApplyClick} size="small">
                {t('apply')}
              </Button>
            </FlexContainer>
          </>
        )}
      </FlexContainer>
    </Dialog>
  )
}

export default CreateAdvancedFilterModal
