import { ParamsBase } from '@/types'
import {
  CancelTaskResponse,
  CreateExtractionPayload,
  CreateExtractionResponse,
  DeleteIntegrationResponse,
  GetConnectorResponse,
  GetConnectorSchemaResponse,
  GetIntegrationResponse,
  ListApiKeyResponse,
  ListConnectorParams,
  ListConnectorsResponse,
  ListIntegrationsResponse,
  ListTasksResponse,
  RefreshIntegrationResponse,
  TestIntegrationPayload,
  TestIntegrationResponse,
  UpdateExtractionPayload,
  UpdateIntegrationResponse
} from '@/types/integrations/IntegrationRequest'
import { feedbackRiverClient, searchClient } from './clients'

export default class IntegrationService {
  static PREFIX = '/api'

  static async listApiKey() {
    const { data } = await searchClient.get<ListApiKeyResponse[]>('/users/api_key_list')

    if (Array.isArray(data) && data.length) {
      return data
    }
    return undefined
  }

  static async listConnectors(options: ListConnectorParams = {}) {
    const params = new URLSearchParams(options as Record<string, string>)
    const queryString = params.toString()

    const { data } = await feedbackRiverClient.get<ListConnectorsResponse>(
      `${this.PREFIX}/connector/list${queryString ? `?${queryString}` : ''}`
    )
    return data
  }

  static async getConnector(connectorId: string) {
    const { data } = await feedbackRiverClient.get<GetConnectorResponse>(
      `${this.PREFIX}/connector/${connectorId}`
    )

    return data
  }

  static async getConnectorSchema(connectorId: string) {
    const { data } = await feedbackRiverClient.get<GetConnectorSchemaResponse>(
      `${this.PREFIX}/connector/data_schema/${connectorId}`
    )

    return data
  }

  static async createExtraction(payload: CreateExtractionPayload) {
    const { data } = await feedbackRiverClient.post<CreateExtractionResponse>(
      `${this.PREFIX}/extraction/`,
      payload
    )

    return data
  }

  static async updateExtraction(payload: UpdateExtractionPayload) {
    const { data } = await feedbackRiverClient.put<UpdateIntegrationResponse>(
      `${this.PREFIX}/extraction/`,
      payload
    )

    return data
  }

  static async listIntegrations(organizationId: string, options?: ParamsBase) {
    const params = new URLSearchParams()
    if (options) {
      Object.keys(options).forEach(key => {
        if (options[key]) {
          params.append(key, `${options[key]}`)
        }
      })
    }

    const { data } = await feedbackRiverClient.get<ListIntegrationsResponse>(
      `${this.PREFIX}/extraction/list/${organizationId}?${params.toString()}`
    )

    return data
  }

  static async getIntegration(integrationId: string) {
    const { data } = await feedbackRiverClient.get<GetIntegrationResponse>(
      `${this.PREFIX}/extraction/${integrationId}`
    )

    return data
  }

  static async deleteExtraction(integrationId: string) {
    const { data } = await feedbackRiverClient.delete<DeleteIntegrationResponse>(
      `${this.PREFIX}/extraction/${integrationId}`
    )

    return data
  }

  static async testIntegration(payload: TestIntegrationPayload) {
    const { data } = await feedbackRiverClient.post<TestIntegrationResponse>(
      `${this.PREFIX}/extraction/test_integration/`,
      payload
    )

    return data
  }

  static async refreshIntegration(integrationId: string) {
    const params = new URLSearchParams({
      extraction_id: integrationId,
      refresh_type: 'manual'
    })

    const { data } = await feedbackRiverClient.get<RefreshIntegrationResponse>(
      `${this.PREFIX}/extraction/refresh?${params.toString()}`
    )

    return data
  }

  static async listTasks(integrationId: string) {
    const { data } = await feedbackRiverClient.get<ListTasksResponse>(
      `${this.PREFIX}/task/list/${integrationId}`
    )

    return data
  }

  static async cancelTask(integrationId: string) {
    const { data } = await feedbackRiverClient.get<CancelTaskResponse>(
      `${this.PREFIX}/task/cancel/${integrationId}`
    )

    return data
  }
}
