import { Collection } from '@/types/collection'
import { persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface CollectionState {
  currentCollection: Collection | null
  setCurrentCollection: (collection: Collection | null) => void
}

const useCollectionStore = createWithEqualityFn<CollectionState>(
  set => ({
    currentCollection: null,
    setCurrentCollection: collection => set({ currentCollection: collection })
  }),
  shallow
)

export default useCollectionStore

interface PersistedCollectionState {
  hasCollections: boolean | null
  setHasCollections: (hasCollections: boolean) => void
}

const usePersistedCollectionStore = createWithEqualityFn<PersistedCollectionState>()(
  persist(
    set => ({
      hasCollections: null,
      setHasCollections: hasCollections => set({ hasCollections })
    }),
    { name: 'persisted-collection-store', version: 1 }
  ),
  shallow
)

export { usePersistedCollectionStore }
