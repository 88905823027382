import { useFiltersStore } from '@/store'
import useSelectedFilters from '../filters/useSelectedFilters'
import AnalyticsService from '@/services/AnalyticsService'
import { TimeSeriesField } from '@/types/analytics/AnalyticsRequests'
import { useEffect, useMemo, useState } from 'react'
import { IntervalOption, MetricOption, TimeSeriesData } from '@/types/analytics/Analytics'
import { useQuery } from '@tanstack/react-query'
import { SelectOption } from '@/components/atoms/select'
import {
  ChartBar,
  ChartLine,
  ChatText,
  HandPalm,
  ListNumbers,
  Percent,
  Stack,
  Swatches
} from '@phosphor-icons/react'
import useUser from '../useUser'
import useSegment from '../useSegment'
import { shallow } from 'zustand/shallow'
import { ChartType } from '@/types/dashboard'
import { FilterDatetimeValue } from '@/types/filters/Filters'
import { dateRangeIsEqual, getDifferenceInDays, getIntervalByDateRange } from '@/utils/date'
import usePeriods from '../usePeriods'
import FlexContainer from '@/components/atoms/flex-container'
import { cloneObject } from '@/utils/object'
import { FeedbackFilter } from '@/types/filters/FilterRequests'
import { Period } from '@/types/periods'

type SmallPeriodType = Exclude<Period, '3months' | '6months' | 'allTime'>

interface Params {
  topicId?: string
  asExploreFeedbackItem?: boolean
}

const useTrendLine = (params?: Params) => {
  const { track } = useSegment()
  const { userPermissions } = useUser()

  const topicId = params?.topicId
  const asExploreFeedbackItem = params?.asExploreFeedbackItem || false

  const { volumeBy, dateRange, datePeriod } = useFiltersStore(
    state => ({
      volumeBy: state.volumeBy,
      dateRange: state.dateRange,
      datePeriod: state.datePeriod,
      setDateRange: state.setDateRange
    }),
    shallow
  )

  const toFeedbackFilterSchema = useFiltersStore(state => state.toAnalyticsFeedbackFilterSchema)
  const { allSelectedFilters } = useSelectedFilters()

  const [field, setField] = useState<TimeSeriesField>(TimeSeriesField.Topics)
  const fieldOptions = useMemo(() => {
    const optionList: SelectOption<TimeSeriesField>[] = []

    if (userPermissions.analytics.includes('over-time_subtopic') && !topicId) {
      optionList.push({ text: 'Topic', value: TimeSeriesField.Topics, icon: <Swatches /> })
    }

    if (userPermissions.analytics.includes('over-time_topic') && !topicId) {
      optionList.push({
        text: 'Topic group',
        value: TimeSeriesField.Themes,
        icon: (
          <FlexContainer
            alignItems="center"
            css={{ bAll: 'CurrentColor', borderRadius: 4 }}
            justifyContent="center"
          >
            <Swatches />
          </FlexContainer>
        )
      })
    }

    if (userPermissions.analytics.includes('over-time_f-type')) {
      optionList.push({ text: 'Feedback type', value: TimeSeriesField.DataType, icon: <Stack /> })
    }

    if (userPermissions.analytics.includes('over-time_f-intention')) {
      optionList.push({
        text: 'Feedback intention',
        value: TimeSeriesField.FeedbackKeywordClasses,
        icon: <HandPalm />
      })
    }

    optionList.push({
      text: 'All feedback',
      value: TimeSeriesField.All,
      icon: <ChatText />
    })

    return optionList
  }, [userPermissions, topicId])

  const onFieldChange = (value: TimeSeriesField) => {
    track('analytics_user_over_time-timeline_change')
    setField(value)
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen once
  useEffect(() => {
    if (topicId) {
      setField(TimeSeriesField.FeedbackKeywordClasses)
      return
    }

    if (!userPermissions.analytics.includes('over-time_subtopic')) {
      setField(TimeSeriesField.All)
    }
  }, [])

  const [interval, setInterval] = useState<IntervalOption>(IntervalOption.Day)

  const onIntervalChange = (value: string) => {
    setInterval(value as IntervalOption)
  }

  const [metric, setMetric] = useState<MetricOption>('count')
  const metricOptions: SelectOption<MetricOption>[] = [
    { text: 'Absolute value', value: 'count', icon: <ListNumbers /> },
    { text: 'Percentage', value: 'percentage', icon: <Percent /> }
  ]
  const onMetricChange = (value: string) => {
    setMetric(value as MetricOption)
  }

  const [chartType, setChartType] = useState<ChartType>('line')
  const chartTypeOptions: SelectOption<ChartType>[] = [
    { text: 'Line', value: 'line', icon: <ChartLine /> },
    { text: 'Bar', value: 'bar', icon: <ChartBar /> }
  ]
  const onChartTypeChange = (value: string) => {
    setChartType(value as ChartType)
  }

  const [dateRangeToCompare, setDateRangeToCompare] = useState<FilterDatetimeValue>(null)
  const [disableSmallIntervals, setDisableSmallIntervals] = useState(false)
  const [disableLargeIntervals, setDisableLargeIntervals] = useState(false)

  useEffect(() => {
    if (!dateRangeIsEqual(dateRange, dateRangeToCompare)) {
      setInterval(getIntervalByDateRange(dateRange))
      setDateRangeToCompare(dateRange)
    }

    const differenceInDays = dateRange ? getDifferenceInDays(dateRange) : 0
    const isLargeInterval = !dateRange || differenceInDays > 93
    const isSmallInterval = dateRange ? differenceInDays <= 32 : false

    isLargeInterval && setInterval(IntervalOption.Month)
    setDisableSmallIntervals(isLargeInterval)

    setDisableLargeIntervals(isSmallInterval)
    if (isSmallInterval) {
      const periodToInverval: Record<SmallPeriodType, IntervalOption> = {
        '1day': IntervalOption.Day,
        '7days': IntervalOption.Week,
        '30days': IntervalOption.Month,
        custom: IntervalOption.Day
      }
      setInterval(periodToInverval[datePeriod as SmallPeriodType])
    }
  }, [dateRange, datePeriod, dateRangeToCompare])

  const dependencies = useMemo(() => {
    return [volumeBy, field, interval, allSelectedFilters, dateRange, asExploreFeedbackItem]
  }, [field, interval, allSelectedFilters, dateRange, asExploreFeedbackItem, volumeBy])

  const { getPeriod } = usePeriods()

  const getComparisonDateRange = (): PostedAt => {
    // newFilters.filter.posted_at
    const range = dateRange ?? getPeriod(datePeriod).range

    if (!range) {
      return {}
    }

    const differenceInDays = getDifferenceInDays(range)

    const rangeStart = range.start.subtract({ days: differenceInDays })
    const rangeEnd = range.start

    return {
      gte: rangeStart.toString(),
      lte: rangeEnd.toString()
    }
  }

  const queryFn = async (): Promise<TimeSeriesData> => {
    const feedbackFilter = toFeedbackFilterSchema()
    const currentPeriodFilters = cloneObject(feedbackFilter) as FeedbackFilter
    const comparisonPeriodFilters = cloneObject(feedbackFilter) as FeedbackFilter
    if (topicId && currentPeriodFilters.filter && !asExploreFeedbackItem) {
      currentPeriodFilters.filter.topic_groups = [[topicId]]
    }

    const hasComparison = field === TimeSeriesField.All
    if (hasComparison && comparisonPeriodFilters.filter) {
      comparisonPeriodFilters.filter.posted_at = getComparisonDateRange()

      if (topicId && !asExploreFeedbackItem) {
        comparisonPeriodFilters.filter.topic_groups = [[topicId]]
      }
    }

    const request = hasComparison
      ? AnalyticsService.multipleTimeSeries
      : AnalyticsService.timeSeries

    const [error, data] = await request({
      feedback_search: hasComparison ? {} : currentPeriodFilters,
      feedback_search_list: hasComparison
        ? [currentPeriodFilters, comparisonPeriodFilters]
        : undefined,
      exact_count: false,
      interval,
      calculate_percentage: true,
      topics_size: 10,
      fields: [field]
    })

    if (error) {
      throw error
    }

    if (hasComparison) {
      return {
        key: 'All ' + interval,
        values: (data as TimeSeriesData[][]).map((item: TimeSeriesData[]) => item[0].values[0])
      }
    }

    return data[0] as TimeSeriesData
  }

  const { data, isLoading, isError, isRefetching } = useQuery({
    queryKey: ['analytics', 'timeSeries', ...dependencies],
    queryFn
  })

  const isComparisionInvervalDisabled = field === TimeSeriesField.All && disableLargeIntervals
  const isWeekDisabled =
    disableSmallIntervals || (isComparisionInvervalDisabled && datePeriod === '1day')
  const isMonthDisabled = isComparisionInvervalDisabled && ['1day', '7days'].includes(datePeriod)

  const intervalOptions: SelectOption<IntervalOption>[] = [
    { text: 'Day', value: IntervalOption.Day, disabled: disableSmallIntervals },
    { text: 'Week', value: IntervalOption.Week, disabled: isWeekDisabled },
    { text: 'Month', value: IntervalOption.Month, disabled: isMonthDisabled },
    { text: 'Quarter', value: IntervalOption.Quarter, disabled: isComparisionInvervalDisabled },
    { text: 'Year', value: IntervalOption.Year, disabled: isComparisionInvervalDisabled }
  ]

  return {
    field,
    fieldOptions,
    onFieldChange,

    interval,
    intervalOptions,
    onIntervalChange,

    metric,
    metricOptions,
    onMetricChange,

    chartType,
    chartTypeOptions,
    onChartTypeChange,

    volumeBy,

    data,
    isLoading: isLoading || isRefetching,
    isError,
    isRefetching
  }
}

export default useTrendLine
