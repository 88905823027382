import Select, { SelectOption } from '@/components/atoms/select'
import { FilterLogicOperator, FilterNode, GenericFilter } from '@/types/filters/AdvancedFilters'
import { logicOperatorToText } from '@/utils/advancedFilter'
import AdvancedFilterRow from './AdvancedFilterRow'
import Button from '@/components/atoms/button'
import { CopySimple, Plus, TrashSimple } from '@phosphor-icons/react'
import OptionsMenu, { OptionsMenuItem } from '@/components/atoms/options-menu'
import { FilterGroupContainer, FilterGroupRowCell, FilterGroupRowContainer } from './styles'
import { styled } from '@/theme'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

const OptionsTriggerWrapper = styled('div', {
  variants: {
    group: {
      true: {
        mt: 9
      },
      false: {}
    }
  }
})

interface Props {
  level: number
  node: FilterNode
  onNodeChange: React.Dispatch<React.SetStateAction<FilterNode>>
  parentIndex?: number
}

const conditionOptions: SelectOption<FilterLogicOperator>[] = [
  { value: '$and', text: i18n.t('AND') },
  { value: '$or', text: i18n.t('OR') }
]

const AdvancedFilterGroup = ({ level, node, onNodeChange, parentIndex }: Props) => {
  const { t } = useTranslation()

  const currentNode = node
  const setNode =
    parentIndex !== undefined
      ? (filterNode: FilterNode) => {
          onNodeChange(prevNode => {
            const newNode = {
              ...prevNode,
              value: (prevNode.value as FilterNode[]).map((item, i) =>
                i === parentIndex ? filterNode : item
              ) as (FilterNode | GenericFilter)[]
            }
            return newNode
          })
        }
      : onNodeChange

  const onOperatorChange = (value: string) => {
    const newNode = { ...currentNode, operator: value as FilterLogicOperator }
    setNode(newNode)
  }

  const onFilterChange = (index: number, newFilter: GenericFilter) => {
    if (parentIndex === undefined) {
      onNodeChange(prevNode => {
        const newNode = {
          ...prevNode,
          value: (prevNode.value as GenericFilter[]).map((filter, i) =>
            i === index ? newFilter : filter
          )
        }
        return newNode
      })

      return
    }

    onNodeChange(prevNode => {
      const newNode = {
        ...prevNode,
        value: (prevNode.value as (GenericFilter | FilterNode)[]).map((item, i) =>
          i === parentIndex
            ? {
                ...item,
                value: (item.value as GenericFilter[]).map((filter, i) =>
                  i === index ? newFilter : filter
                ) as GenericFilter[]
              }
            : item
        ) as (GenericFilter | FilterNode)[]
      }

      return newNode
    })
  }

  const isFilterGroup = (filter: FilterNode | GenericFilter): filter is FilterNode => {
    return Object.keys(filter).includes('operator')
  }

  const onAddFilter = () => {
    setNode({
      operator: currentNode.operator,
      value: [
        ...(currentNode.value as (GenericFilter | FilterNode)[]),
        {
          name: 'text.feedback',
          type: 'text',
          status: 'valid',
          value: '',
          path: 'text'
        }
      ]
    })
  }

  const removeFilter = (index: number) => {
    setNode({
      operator: currentNode.operator,
      value: (currentNode.value as (GenericFilter | FilterNode)[]).filter((_, i) => i !== index)
    })
  }

  const duplicateFilter = (index: number) => {
    setNode({
      operator: currentNode.operator,
      value: [
        ...(currentNode.value as (GenericFilter | FilterNode)[]).slice(0, index + 1),
        (currentNode.value as (GenericFilter | FilterNode)[])[index],
        ...(currentNode.value as (GenericFilter | FilterNode)[]).slice(index + 1)
      ]
    })
  }

  const generateFilterOptions = (index: number) => {
    const options: OptionsMenuItem[] = [
      { text: t('remove'), icon: <TrashSimple />, onClick: () => removeFilter(index) },
      { text: t('duplicate'), icon: <CopySimple />, onClick: () => duplicateFilter(index) }
    ]

    return options
  }

  const children = currentNode.value
  if (!Array.isArray(children)) return null

  return (
    <FilterGroupContainer root={level === 0}>
      {children.map((child, index) => (
        <FilterGroupRowContainer
          key={`(${level},${index},${parentIndex},${(child as GenericFilter).name ?? 'filter'})`}
        >
          <FilterGroupRowCell>
            {index === 0 && <span>Where</span>}
            {index === 1 && (
              <Select
                onValueChange={onOperatorChange}
                options={conditionOptions}
                small
                value={node.operator}
              />
            )}
            {index > 1 && <span>{logicOperatorToText(node.operator)}</span>}
          </FilterGroupRowCell>

          {isFilterGroup(child) ? (
            <AdvancedFilterGroup
              level={level + 1}
              node={child}
              onNodeChange={onNodeChange}
              parentIndex={index}
            />
          ) : (
            <AdvancedFilterRow
              filter={child}
              onFilterChange={filter => onFilterChange(index, filter)}
            />
          )}
          <OptionsTriggerWrapper group={isFilterGroup(child)}>
            <OptionsMenu options={generateFilterOptions(index)} />
          </OptionsTriggerWrapper>
        </FilterGroupRowContainer>
      ))}

      {level > 0 && (
        <Button
          css={{ padding: 0, height: 'fit-content', mt: '$micro' }}
          onClick={onAddFilter}
          size="small"
          variant="link"
        >
          <Plus />
          <span>{t('addFilter')}</span>
        </Button>
      )}
    </FilterGroupContainer>
  )
}

export default AdvancedFilterGroup
