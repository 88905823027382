import { FilterItem } from '@/types/filters/Filters'
import { StringFilterContent, useStringGenericFilter } from '../StringGenericFilter'

interface Props {
  feedbackDataType: FilterItem
  enabled: boolean
}

const FeedbackDataTypeContent = ({ feedbackDataType, enabled }: Props) => {
  const {
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isChecked,
    selectOption,
    options,
    isShowMoreDisabled
  } = useStringGenericFilter({
    type: feedbackDataType.type,
    name: feedbackDataType.name,
    filterKey: feedbackDataType.key,
    enabled
  })

  return (
    <StringFilterContent
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isChecked={isChecked}
      isFetchingNextPage={isFetchingNextPage}
      isShowMoreDisabled={isShowMoreDisabled}
      options={options}
      selectOption={selectOption}
    />
  )
}

export default FeedbackDataTypeContent
