import { navigationBarWidth, styled, topBarHeight, topBarHomeHeight } from '@/theme'

export const StyledHeader = styled('header', {
  display: 'flex',
  height: topBarHeight,
  alignItems: 'center',
  justifyContent: 'space-between',
  pl: 28 + navigationBarWidth,
  pr: 28,

  position: 'fixed',
  top: 0,
  boxSizing: 'border-box',
  width: '100vw',
  zIndex: 1,
  bc: '$neutralHighMedium',
  color: '$neutralLowPure',

  variants: {
    opportunityPlan: {
      true: {
        height: topBarHomeHeight,
        color: '$neutralHighLight',
        padding: '$micro $xs $micro $xxs',
        bc: '$topbar',
        zIndex: 2
      },
      false: {}
    }
  },

  defaultVariants: {
    opportunityPlan: false
  }
})

export const AreasButton = styled('button', {
  background: '$neutralHighLight',
  size: '$sm',
  bAll: '$neutralHighPure',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 8,
  boxSizing: 'border-box',
  cursor: 'pointer',

  '&:hover': {
    '& > svg': {
      color: '$brandPrimaryPure'
    }
  }
})

export const BirdieLogoIcon = styled('img', {
  width: 40,
  height: 40,
  padding: '$xxs'
})

export const BirdieLogoOpporortunityIcon = styled('img', {
  width: 40,
  height: 40,
  boxSizing: 'border-box',
  padding: 'calc($nano + 2px)'
})
