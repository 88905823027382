import useDashboardStore from '@/store/useDashboardStore'
import useSavedFiltersStore from '@/store/useSavedFiltersStore'
import { useMemo } from 'react'
import useSavedFiltersParsers from '../useSavedFiltersParsers'
import {
  getFromDatetimeFilters,
  getFromNumericFilters,
  getFromStringFilters,
  getTopicGroups
} from '@/utils/filters'
import {
  FilterDatetime,
  FilterDatetimeValue,
  FilterNumeric,
  FilterString,
  SavedFilterContent
} from '@/types/filters/Filters'
import AnalyticsService from '@/services/AnalyticsService'
import { TimeSeriesField } from '@/types/analytics/AnalyticsRequests'
import { useQuery } from '@tanstack/react-query'
import { ChartType, DashboardChart } from '@/types/dashboard'
import { IntervalOption, MetricOption, TimeSeriesValue } from '@/types/analytics/Analytics'
import { SelectOption } from '@/components/atoms/select'
import { Period } from '@/types/periods'
import usePeriods from '../usePeriods'
import { ChartBar, ChartLine, ListNumbers, Percent, Table } from '@phosphor-icons/react'
import useDashboardState from './useDashboardState'
import { FilterRequests } from '@/types/filters'

const intervalOptions: SelectOption<IntervalOption>[] = [
  { text: 'Day', value: IntervalOption.Day },
  { text: 'Week', value: IntervalOption.Week },
  { text: 'Month', value: IntervalOption.Month },
  { text: 'Quarter', value: IntervalOption.Quarter },
  { text: 'Year', value: IntervalOption.Year }
]

const metricOptions: SelectOption<MetricOption>[] = [
  { text: 'Absolute value', value: 'count', icon: <ListNumbers /> },
  { text: 'Percentage', value: 'percentage', icon: <Percent /> }
]

const chartTypeOptions: SelectOption<ChartType>[] = [
  { text: 'Line', value: 'line', icon: <ChartLine /> },
  { text: 'Bar', value: 'bar', icon: <ChartBar /> },
  { text: 'Table', value: 'table', icon: <Table /> }
]

const useDashboardChart = (chart: DashboardChart) => {
  const id = chart.id

  const savedFilters = useSavedFiltersStore(state => state.savedFilters)

  const selectedSavedFiltersData = useMemo(
    () => savedFilters.filter(filter => chart.savedFilters.includes(filter.filterId)),
    [savedFilters, chart.savedFilters]
  )

  const { getPeriod } = usePeriods()

  const {
    getSearchFilterFromContent,
    getStringFiltersFromContent,
    getNumericFiltersFromContent,
    getDatetimeFiltersFromContent,
    getThemesFromContent,
    getTopicsFromContent,
    getUnclassifiedTopicsFromContent
  } = useSavedFiltersParsers()

  const getFiltersPayload = (): FilterRequests.FeedbackFilter[] =>
    selectedSavedFiltersData.length > 0
      ? selectedSavedFiltersData.map(savedFilter => {
          const savedFilterContent = savedFilter.content as SavedFilterContent[]
          const search = getSearchFilterFromContent(savedFilterContent)

          const stringFilters = getStringFiltersFromContent(
            savedFilter.content as SavedFilterContent[]
          )
          const {
            regularStringFilters,
            schemaFieldsStringFilters,
            customFieldsStringFilters,
            sentiment,
            feedbackSourceIds
          } = getFromStringFilters(
            stringFilters.map(
              (filter): FilterString => ({
                key: filter.key,
                name: filter.name,
                type: filter.type,
                draftSelected: filter.selected,
                selected: filter.selected
              })
            )
          )

          const numericFilters = getNumericFiltersFromContent(savedFilterContent)
          const {
            regularNumericFilters,
            schemaFieldsNumberFilters,
            schemaFieldsIntegerFilters,
            customFieldsNumberFilters,
            customFieldsIntegerFilters
          } = getFromNumericFilters(
            numericFilters.map(
              (filter): FilterNumeric => ({
                key: filter.key,
                name: filter.name,
                type: filter.type,
                draftOption: filter.option,
                option: filter.option,
                draftValue: filter.value,
                value: filter.value
              })
            )
          )

          const datetimeFilters = getDatetimeFiltersFromContent(savedFilterContent)
          const { schemaFieldsDatetimeFilters, customFieldsDatetimeFilters } =
            getFromDatetimeFilters(
              datetimeFilters.map(
                (filter): FilterDatetime => ({
                  key: filter.key,
                  name: filter.name,
                  type: filter.type,
                  draftPeriod: filter.period,
                  period: filter.period,
                  draftValue: filter.value,
                  value: filter.value
                })
              )
            )

          const { productAreaThemesIds, otherThemesIds } = getThemesFromContent(savedFilterContent)
          const { productAreaTopicIds, otherTopicIds } = getTopicsFromContent(savedFilterContent)
          const unclassifiedTopics = getUnclassifiedTopicsFromContent(savedFilterContent)
          const topicGroups = getTopicGroups({
            unclassifiedTopics,
            selectedProductAreaThemes: productAreaThemesIds,
            selectedProductAreaTopics: productAreaTopicIds,
            selectedOtherTopicsThemes: otherThemesIds,
            selectedOtherTopics: otherTopicIds
          })

          let dateRange = chart.dateRange
          if (chart.datePeriod !== 'custom' && chart.datePeriod !== 'allTime') {
            dateRange = getPeriod(chart.datePeriod)?.range ?? null
          }

          return {
            filter: {
              search_text: search,
              posted_at: dateRange
                ? {
                    gte: dateRange.start.toString(),
                    lte: dateRange.end.toString()
                  }
                : {},
              sentiment,
              feedback_source_ids: feedbackSourceIds,
              feedback_group_enable: true,
              schema_fields_datetime: schemaFieldsDatetimeFilters,
              schema_fields_integer: schemaFieldsIntegerFilters,
              schema_fields_number: schemaFieldsNumberFilters,
              schema_fields_string: schemaFieldsStringFilters,
              custom_fields_datetime: customFieldsDatetimeFilters,
              custom_fields_integer: customFieldsIntegerFilters,
              custom_fields_number: customFieldsNumberFilters,
              custom_fields_string: customFieldsStringFilters,
              topic_groups: topicGroups,
              ...regularStringFilters,
              ...regularNumericFilters
            }
          } as FilterRequests.FeedbackFilter
        })
      : []

  const getFieldValue = (index: number) => {
    if (chart.savedFilters.length === 0) return 'Default'

    return selectedSavedFiltersData[index].name ?? `Saved filter ${index}`
  }

  const queryFunction = async () => {
    const payload = getFiltersPayload()

    const response = await AnalyticsService.findTimeSeries({
      feedback_search: {},
      feedback_search_list: payload,
      exact_count: false,
      interval: chart.interval,
      calculate_percentage: true,
      fields: [TimeSeriesField.All]
    })

    const timeSeries: TimeSeriesValue[] = response.time_series_list.map((item, index) => ({
      fieldValue: getFieldValue(index),
      data: item[0].values[0].data.map(data => ({
        postedAt: data.posted_at,
        countFeedback: data.count_feedback,
        countFeedbackWithoutFilter: data.count_feedback_without_filter ?? 0,
        percentageFeedback: data.percentage_feedback ?? 0
      }))
    }))

    return timeSeries
  }

  const { data, isLoading, isError, isRefetching, refetch, ...otherQueryProps } = useQuery({
    queryKey: [
      'dashboardChart',
      id,
      chart.savedFilters,
      chart.interval,
      chart.dateRange,
      chart.datePeriod
    ],
    enabled: savedFilters.length > 0,
    queryFn: queryFunction
  })

  const setSavedFiltersStore = useDashboardStore(state => state.setSavedFilters)
  const setSavedFilters = (value: string[]) => setSavedFiltersStore(id, value)

  const setTypeStore = useDashboardStore(state => state.setType)
  const setType = (value: ChartType) => setTypeStore(id, value)

  const setIntervalStore = useDashboardStore(state => state.setInterval)
  const setInterval = (value: IntervalOption) => setIntervalStore(id, value)

  const setMetricStore = useDashboardStore(state => state.setMetric)
  const setMetric = (value: MetricOption) => setMetricStore(id, value)

  const setDateStore = useDashboardStore(state => state.setDate)
  const setDate = (range: FilterDatetimeValue, period?: Period) => setDateStore(id, range, period)

  const setNameStore = useDashboardStore(state => state.setName)
  const setName = (value: string) => setNameStore(id, value)

  const duplicateChartStore = useDashboardStore(state => state.duplicateChart)
  const duplicateChart = () => duplicateChartStore(id)

  const { saveChart, removeChart } = useDashboardState()
  const save = () => saveChart(id)
  const remove = () => removeChart(id)

  return {
    data,
    isLoading,
    isError,
    isRefetching,
    refetch,
    otherQueryProps,
    setSavedFilters,
    setType,
    setInterval,
    setMetric,
    setDate,
    setName,
    intervalOptions,
    metricOptions,
    chartTypeOptions,
    duplicateChart,
    remove,
    save
  }
}

export default useDashboardChart
