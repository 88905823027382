import Checkbox from '@/components/atoms/checkbox'
import { INTENTION_LIST, intentionIcons } from '@/utils/intention'
import { CheckedState } from '@radix-ui/react-checkbox'

export interface FeedbackIntentionContentProps {
  isChecked: (value: string) => boolean
  selectOption: (checked: CheckedState, option: string) => void
}

const FeedbackIntentionContent = ({ isChecked, selectOption }: FeedbackIntentionContentProps) => {
  return (
    <>
      {INTENTION_LIST.map(intention => (
        <Checkbox
          checked={isChecked(intention)}
          css={{ textTransform: 'capitalize' }}
          icon={intentionIcons[intention]}
          key={intention}
          onCheckedChange={checked => selectOption(checked, intention)}
          text={intention}
          value={intention}
        />
      ))}
    </>
  )
}

export default FeedbackIntentionContent
