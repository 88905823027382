import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'

const Root = styled(FlexContainer, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$micro',
  color: '$neutralLowPure',
  fontSize: '$micro'
})

const Header = styled(FlexContainer, {
  display: 'flex',
  alignItems: 'center',
  fontSize: '$nano',
  color: '$neutralLowLight',
  gap: '$micro',

  '& > div': {
    color: 'inherit'
  }
})

const Section = styled(FlexContainer, {
  display: 'flex',
  flexDirection: 'column',
  gap: 2
})

const Row = styled(FlexContainer, {
  display: 'flex',
  alignItems: 'center',
  gap: '$micro'
})

const Cell = styled(FlexContainer, {
  display: 'flex',
  alignItems: 'center',
  gap: '$nano',

  variants: {
    size: {
      regular: { width: 84 },
      long: { width: 128 }
    },
    meaning: {
      good: {
        color: '$feedbackPositivePure'
      },
      neutral: {
        color: '$neutralLowPure'
      },
      bad: {
        color: '$feedbackNegativePure'
      }
    }
  },

  defaultVariants: {
    size: 'regular',
    meaning: 'neutral'
  }
})

const Sub = styled('p', {
  fontSize: '$nano',
  color: '$neutralLowLight',
  lineHeight: '16px'
})

const Divider = styled('div', {
  width: 1,
  height: 16,
  background: '$neutralHighMedium'
})

const MetricsTooltip = {
  Root,
  Header,
  Section,
  Row,
  Cell,
  Sub,
  Divider
}

export default MetricsTooltip
