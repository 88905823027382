import { Feedback, KeywordTopic } from '@/types/feedbacks'
import { Keyword } from '@/types/keywords'
import createInfiniteScrollSlice, { InifiniteScrollSlice } from '../createInfiniteScrollSlice'
import { StateCreator } from 'zustand'
import { Intention } from '@/types/reasons'

interface FeedbackState {
  selectedFeedback: Feedback | null
  selectedThreadFeedback: Feedback | null

  setSelectedFeedback: (feedback: Feedback | null) => void
  setSelectedThreadFeedback: (feedback: Feedback | null) => void
  deleteFeedback: (feedbackId: string) => void
  markFeedbackAsArchived: (feedbackId: string) => void
  unmarkFeedbackAsArchived: (feedbackId: string) => void
  addKeywordTopicToFeedback: (keywordTopic: KeywordTopic) => void
  updateTopicOnFeedback: (topicName: string, topicId: string) => void
  removeKeywordTopicFromFeedback: (keywordHash: string, topicId: string) => void
  removeTopicFromFeedback: (topicId: string) => void
  updateSentimentOfKeywordOnFeedback: (
    feedbackId: Feedback['feedbackId'],
    keywordHash: Keyword['keywordHash'],
    newSentiment: number
  ) => void
  updateIntentionOfKeywordOnFeedback: (
    feedbackId: Feedback['feedbackId'],
    keywordHash: Keyword['keywordHash'],
    newIntention: Intention
  ) => void
  removeFeedbackKeyword: (feedbackId: string, keywordHash: string) => void
}

const feedbackInfiniteScrollSlice = createInfiniteScrollSlice<Feedback>()
export type FeedbacksStoreState = InifiniteScrollSlice<Feedback> & FeedbackState

const createFeedbackStore: StateCreator<FeedbacksStoreState> = (set, ...a) => ({
  ...feedbackInfiniteScrollSlice(set, ...a),

  selectedFeedback: null,
  selectedThreadFeedback: null,

  setSelectedFeedback: selectedFeedback => set(() => ({ selectedFeedback })),
  setSelectedThreadFeedback: selectedThreadFeedback => set(() => ({ selectedThreadFeedback })),
  deleteFeedback: feedbackId =>
    set(state => ({
      ...state,
      data: state.data.filter(feedback => feedback.feedbackId !== feedbackId)
    })),

  markFeedbackAsArchived: feedbackId =>
    set(state => ({
      ...state,
      data: state.data.map(
        (feedback): Feedback =>
          feedback.feedbackId === feedbackId
            ? { ...feedback, archivedAt: new Date(Date.now()) }
            : feedback
      )
    })),
  unmarkFeedbackAsArchived: feedbackId =>
    set(state => ({
      ...state,
      data: state.data.map(
        (feedback): Feedback =>
          feedback.feedbackId === feedbackId
            ? { ...feedback, archivedAt: undefined, archivedBy: undefined }
            : feedback
      )
    })),

  addKeywordTopicToFeedback: keywordTopic =>
    set(state => ({
      ...state,
      data: state.data.map(feedback => {
        const text = feedback.text.replaceAll('<em>', '').replaceAll('</em>', '').toLowerCase()
        const title = feedback.title?.replaceAll('<em>', '').replaceAll('</em>', '').toLowerCase()
        if (
          text.includes(keywordTopic.text.toLowerCase()) ||
          title?.includes(keywordTopic.text.toLowerCase())
        ) {
          return {
            ...feedback,
            keywordTopicList: [...feedback.keywordTopicList, keywordTopic]
          }
        }
        return feedback
      })
    })),

  updateTopicOnFeedback: (topicName, topicId) =>
    set(state => ({
      ...state,
      data: state.data.map(feedback => ({
        ...feedback,
        keywordTopicList: feedback.keywordTopicList.map(
          (keywordTopic): KeywordTopic => ({
            ...keywordTopic,
            topics: keywordTopic.topics.map(topic =>
              topic.topicId === topicId ? { name: topicName, topicId } : topic
            )
          })
        )
      }))
    })),

  removeKeywordTopicFromFeedback: (keywordHash, topicId) =>
    set(state => ({
      ...state,
      data: state.data.map(feedback => ({
        ...feedback,
        keywordTopicList: feedback.keywordTopicList.map(
          (keywordTopic): KeywordTopic => ({
            ...keywordTopic,
            topics: keywordTopic.topics.filter(
              topic => !(keywordTopic.keywordHash === keywordHash && topic.topicId === topicId)
            )
          })
        )
      }))
    })),

  removeTopicFromFeedback: topicId =>
    set(state => ({
      ...state,
      data: state.data.map(feedback => ({
        ...feedback,
        keywordTopicList: feedback.keywordTopicList.map(
          (keywordTopic): KeywordTopic => ({
            ...keywordTopic,
            topics: keywordTopic.topics.filter(topic => topic.topicId !== topicId)
          })
        )
      }))
    })),

  updateSentimentOfKeywordOnFeedback: (feedbackId, keywordHash, newSentiment) => {
    set(state => ({
      ...state,
      data: state.data.map(feedback =>
        feedback.feedbackId === feedbackId
          ? {
              ...feedback,
              keywordTopicList: feedback.keywordTopicList.map(keywordTopic =>
                keywordTopic.keywordHash === keywordHash
                  ? {
                      ...keywordTopic,
                      sentiment: newSentiment
                    }
                  : keywordTopic
              )
            }
          : feedback
      )
    }))
  },
  updateIntentionOfKeywordOnFeedback: (feedbackId, keywordHash, newIntention) => {
    set(state => ({
      ...state,
      data: state.data.map(feedback =>
        feedback.feedbackId === feedbackId
          ? {
              ...feedback,
              keywordTopicList: feedback.keywordTopicList.map(keywordTopic =>
                keywordTopic.keywordHash === keywordHash
                  ? {
                      ...keywordTopic,
                      intention: [newIntention]
                    }
                  : keywordTopic
              )
            }
          : feedback
      )
    }))
  },

  removeFeedbackKeyword: (feedbackId, keywordHash) => {
    set(state => ({
      ...state,
      data: state.data.map(feedback =>
        feedback.feedbackId === feedbackId
          ? {
              ...feedback,
              keywordTopicList: feedback.keywordTopicList.filter(
                keywordTopic => keywordTopic.keywordHash !== keywordHash
              )
            }
          : feedback
      )
    }))
  }
})

export default createFeedbackStore
