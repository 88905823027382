import { FeedbackKindName } from '@/types/feedbacks/FeedbackKindFields'
import { snakeToTitle } from './letterCase'
import i18n from '../plugins/i18n/i18n'
import { FeedbackResponse } from '@/types/feedbacks/FeedbackRequests'
import { NewFeedback } from '@/types/feedbacks/Feedback'
import { stringToDate } from './date'

const feedbackKindNameMap: Record<FeedbackKindName, string> = {
  support_ticket: i18n.t('tickets'),
  transcript: i18n.t('transcripts'),
  issue: i18n.t('issue'),
  social_media_post: i18n.t('socialMediaPost'),
  review: i18n.t('review'),
  nps: 'NPS',
  csat: 'CSAT',
  discussion: i18n.t('discussion'),
  tcsat: 'Tickets CSAT',
  tnps: 'Tickets NPS'
}

export const getFeedbackKindPrettyName = (name: FeedbackKindName | string) => {
  if (!name) return ''
  const prettyKindName = feedbackKindNameMap[name as FeedbackKindName]
  if (prettyKindName) return prettyKindName

  return name.includes('_') ? snakeToTitle(name) : name
}

export const parseRawFeedback = (rawFeedback: FeedbackResponse): NewFeedback => ({
  source: rawFeedback.source,
  kind: {
    ...rawFeedback.kind,
    prettyName: getFeedbackKindPrettyName(rawFeedback.kind.name)
  },
  id: rawFeedback.id,
  language: rawFeedback.language,
  text: rawFeedback.text ?? '',
  customFields: rawFeedback.custom_fields,
  updatedAt: stringToDate(rawFeedback.updated_at),
  postedAt: stringToDate(rawFeedback.posted_at),
  messages: rawFeedback.messages
    ? {
        total: rawFeedback.messages.total,
        firstPostedAt: stringToDate(rawFeedback.messages.first_posted_at),
        lastPostedAt: stringToDate(rawFeedback.messages.last_posted_at)
      }
    : undefined,
  accounts: rawFeedback.accounts,
  users: rawFeedback.users,
  sourceAlias: {
    sourceAliasName: rawFeedback.source_alias,
    sourceAliasPrettyName: getFeedbackKindPrettyName(rawFeedback.source_alias),
    kind: { ...rawFeedback.kind, prettyName: getFeedbackKindPrettyName(rawFeedback.kind.name) }
  }
})
