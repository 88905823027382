import IconButton from '@/components/atoms/icon-button'
import { CSS, styled, typefaceVariants } from '@/theme'
import React, { HTMLAttributes, ReactNode, useRef, useState } from 'react'
import { MagnifyingGlass, X } from '@phosphor-icons/react'
import i18n from '../../../plugins/i18n/i18n'

export interface SearchProps extends HTMLAttributes<HTMLInputElement> {
  round?: boolean
  select?: boolean
  value?: string
  children?: ReactNode
  width?: string
  clearable?: boolean
  green?: boolean
  onClear?: React.MouseEventHandler<HTMLButtonElement>
  css?: CSS
  trailing?: ReactNode
  small?: boolean
  size?: 'xxxs' | 'small'
  inputRef?: React.RefObject<HTMLInputElement> | null
  placeholder?: string
  /**
   * the search will appear initially collapsed till the search icon, and expand animated on click
   * focusing the input immediately, and remaind expanded if has value, or collapse when lose focus and has no value
   *
   * the width prop will be ignored
   */
  expandOnFocus?: boolean
  disabled?: boolean
  error?: boolean
}

const SearchBox = styled('div', {
  display: 'flex',
  color: '$neutralLowLight',
  transition: '200ms',
  height: 40,
  boxSizing: 'border-box',
  pAll: '$xxxs',

  variants: {
    round: {
      true: {
        borderRadius: '40px'
      },
      false: {
        borderRadius: '8px'
      }
    },

    green: {
      false: {
        background: '$white',
        bAll: '$neutralHighPure'
      },
      true: {
        background: '$greenLight',
        bAll: '$greenLight'
      }
    },

    size: {
      small: {
        height: 'auto'
      }
    },
    expandOnFocus: {
      true: {
        py: '$micro',
        width: 'auto !important',
        transition: 'width 400ms'
      }
    },
    isExpanded: {
      true: {},
      false: {}
    },

    disabled: {
      true: {}
    },

    error: {
      true: {
        bAll: '$feedbackNegativePure !important',
        outline: '1px solid $feedbackNegativePure',
        // marginBottom: 8,

        '&:focus-visible, &:focus': {
          outline: '1px solid $feedbackNegativePure'
        }
      }
    }
  },

  '&:focus-within': {
    color: '$neutralLowLight',
    background: '$white',
    // bAll: '$brandPrimaryPure',
    border: '1px solid $brandPrimaryPure',
    // margin: '0 -1px',
    // outline: '1px solid $brandPrimaryPure',
    transition: '200ms'
  },

  compoundVariants: [
    {
      expandOnFocus: true,
      isExpanded: false,
      disabled: false,
      css: {
        cursor: 'pointer',
        '& svg': {
          fill: '$neutralLowPure'
        },
        '&:hover': {
          background: '$brandPrimaryLight',
          '& svg': {
            fill: '$brandPrimaryPure'
          }
        }
      }
    },
    {
      expandOnFocus: true,
      isExpanded: true,
      css: {
        border: '2px solid $brandPrimaryPure !important',
        '& svg': {
          fill: '$brandPrimaryPure'
        }
      }
    }
  ]
})

const SelectBox = styled('div', {
  bRight: '$neutralsStroke',
  '&:hover': {
    backgroundColor: '$greenLight'
  },

  variants: {
    round: {
      true: {
        borderTopLeftRadius: '40px',
        borderBottomLeftRadius: '40px'
      },
      false: {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px'
      }
    }
  }
})

const SearchFlex = styled('div', {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: '$nano',

  '.trailing': {
    marginLeft: 'auto'
  },

  '& svg:first-child': {
    minWidth: 16
  },

  variants: {
    size: {
      small: {}
    },
    removeGap: {
      true: {
        gap: 0
      }
    },
    expandOnFocus: {
      true: {}
    }
  },

  compoundVariants: [
    {
      expandOnFocus: true,
      size: 'small',
      css: {
        padding: 0
      }
    }
  ]
})

const SearchInput = styled('input', {
  all: 'unset',
  width: '100%',
  marginLeft: '$nano',
  ...typefaceVariants.subtitleRegularXXS,

  color: '$neutralLowMedium',

  '&::placeholder': {
    color: '$neutralLowLight'
  },

  variants: {
    small: {
      true: {
        ...typefaceVariants.paragraphRegularMicro
      }
    },
    size: {
      xxxs: typefaceVariants.subtitleRegularXXXS,
      small: {
        ...typefaceVariants.paragraphRegularMicro,
        lineHeight: '$sm',
        marginLeft: 'unset'
      }
    },
    expandOnFocus: {
      true: {
        transition: 'all 400ms',
        '&:not(:focus)': {
          width: '0px',
          ml: 0
        },
        '&:focus, &:focus-within': {
          width: '200px'
        }
      }
    },
    isExpanded: {
      true: {
        '&:not(:focus)': {
          width: '200px',
          ml: 0
        }
      },
      false: {}
    }
  }
})

const Search: React.FC<SearchProps> = ({
  round = false,
  select,
  children,
  value,
  width = '100%',
  clearable,
  onClear,
  onFocus,
  onBlur,
  green = false,
  css,
  trailing,
  small,
  size,
  inputRef,
  expandOnFocus,
  disabled,
  error,
  ...props
}) => {
  const defaultInputRef = useRef<HTMLInputElement>(null)
  const [isExpanded, setIsExpanded] = useState(value ? value.length > 0 : false)

  const iconSize = (() => {
    // if (size === 'small') return 16
    // if (small || size === 'xxxs') return 20
    return 16

    // return 24
  })()

  const onClick = (event: React.MouseEvent<HTMLDivElement | SVGSVGElement>) => {
    const ref = inputRef || defaultInputRef
    if (expandOnFocus && ref.current && !disabled) {
      event.stopPropagation()
      ref.current?.focus()
      setIsExpanded(true)
    }
  }

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = event => {
    expandOnFocus && !disabled && setIsExpanded(true)
    onFocus?.(event)
  }

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = event => {
    if (expandOnFocus && !value?.length) {
      setIsExpanded(false)
    }
    onBlur?.(event)
  }

  const handleClear: React.MouseEventHandler<HTMLButtonElement> = event => {
    onClear?.(event)
    if (expandOnFocus) setIsExpanded(false)
  }

  return (
    <SearchBox
      css={{ width, ...css }}
      disabled={disabled}
      error={error}
      expandOnFocus={expandOnFocus}
      green={green}
      isExpanded={isExpanded}
      onBlur={handleBlur}
      onClick={onClick}
      onFocus={handleFocus}
      round={round}
      size={small ? 'small' : undefined}
    >
      {select && <SelectBox round={round}>{children}</SelectBox>}

      <SearchFlex
        expandOnFocus={expandOnFocus}
        removeGap={expandOnFocus && !isExpanded}
        size={small ? 'small' : undefined}
      >
        <MagnifyingGlass onClick={onClick} size={iconSize} weight="regular" />
        <SearchInput
          ref={inputRef || defaultInputRef}
          size={size}
          value={value}
          {...props}
          disabled={disabled}
          expandOnFocus={expandOnFocus}
          isExpanded={isExpanded}
        />

        {clearable && Boolean(value?.length) && (
          <IconButton
            id="birdie-clear-search"
            onClick={handleClear}
            size={small ? 'small' : 'regular'}
          >
            <X size={iconSize} weight="regular" />
          </IconButton>
        )}

        <div className="trailing">{trailing}</div>
      </SearchFlex>
    </SearchBox>
  )
}

Search.defaultProps = {
  placeholder: i18n.t('searchToDiscover'),
  round: false,
  select: false
}

export default Search
