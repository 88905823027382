import Indicators from '@/components/molecules/analytics/indicators/Indicators'
import AnalyticsTrendLine from '@/components/molecules/analytics/trend-line/AnalyticsTrendLine'
import TrendingTopics from '@/components/molecules/analytics/trending-topics/TrendingTopics'
import useClassification from '@/hooks/useClassification'
import useSegment from '@/hooks/useSegment'
import { useEffect } from 'react'

const AnalyticsPage = () => {
  const { track } = useSegment()

  const { verifyAllTopicAndThemes } = useClassification()

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen once
  useEffect(() => {
    track('explore_user_analysis_tab')
    verifyAllTopicAndThemes()
  }, [])

  return (
    <>
      <Indicators />
      <TrendingTopics />
      <AnalyticsTrendLine />
    </>
  )
}

export default AnalyticsPage
