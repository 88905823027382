import Bone from '@/components/atoms/bone'

const NumberBone = () => <Bone css={{ height: 40, width: 80 }} />

const Text = () => (
  <>
    <Bone css={{ height: 16, width: '80%' }} />
    <Bone css={{ height: 16, width: '50%' }} />
  </>
)

const TopicIndicatorCardSkeleton = {
  Number: NumberBone,
  Text
}

export default TopicIndicatorCardSkeleton
