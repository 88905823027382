import ReasonsService from '@/services/ReasonsService'
import { useFiltersStore } from '@/store'
import useToastMessageStore from '@/store/useToastMessageStore'
import { Intention, Reason, ReasonsRequests, ReasonsTrend } from '@/types/reasons'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useLetterCase from './useLetterCase'
import usePromiseSync from './usePromiseSync'
import useTopicsStore from '@/store/useTopicsStore'

function useReasonsTopics() {
  const { sync } = usePromiseSync()

  const topicsToReasons = useTopicsStore(state => state.topicsToReasons)

  const [reasons, setReasons] = useState<Reason[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedReason, setSelectedReason] = useState<Reason | null>(null)
  const [isLoadingChart, setIsLoadingChart] = useState(false)
  const [trendSeries, setTrendSeries] = useState<ReasonsTrend>([])

  const toInputSchema = useFiltersStore(state => state.toReasonsRequestInputSchema)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { capitalizeFirst } = useLetterCase()

  const controllerRef = useRef<AbortController>()

  const fetchReasons = useCallback(
    async function () {
      setIsLoading(true)
      const inputSchema = toInputSchema()
      inputSchema.filter.search_text = ''
      inputSchema.filter.feedback_filter.search_text = ''
      inputSchema.filter.topic_groups = [topicsToReasons.map(topic => topic.topicId)]

      const payload: ReasonsRequests.GetClusterReasonPayload = {
        search_input_schema: inputSchema,
        keywords_to_cluster: [],
        approximate_count: true,
        date_range: 90,
        offset: 0,
        size: 100,
        sort_type: 'F'
      }

      const controller = new AbortController()
      controllerRef.current = controller

      await sync(
        'reasons',
        ReasonsService.getClusterReason(payload, controllerRef.current.signal),
        result => {
          setIsLoading(false)
          const [error, response] = result
          if (error) {
            setReasons([])
            if (error.isCanceledError) {
              return
            }

            addErrorToast({ text: `Failed to load findings. Message: ${error.message}` })
            return
          }
          const reasons: Reason[] = response.map(ReasonsService.searchResultToReason)

          setReasons(reasons)
        }
      )
    },
    [topicsToReasons, toInputSchema, sync, addErrorToast]
  )

  function cancelRequest() {
    controllerRef.current?.abort()
  }

  async function fetchTrendSeries(reasons: string[]) {
    setIsLoadingChart(true)
    const inputSchema = toInputSchema()
    const [error, response] = await ReasonsService.trend({
      search_input_schema: inputSchema,
      reasons_list: reasons,
      approximate_count: false
    })
    if (error) {
      addErrorToast({ text: `Failed to load trending chart. Message: ${error.key}` })
      setIsLoadingChart(false)
      return
    }
    const trend: ReasonsTrend = response.map(trend => ({
      frequency: trend.frequency,
      postedAt: trend.posted_at
    }))

    setTrendSeries(trend)
    setIsLoadingChart(false)
  }

  function selectReason(value: Reason | null) {
    setSelectedReason(value)

    if (!value) {
      setTrendSeries([])
      return
    }

    fetchTrendSeries(value.reasons)
  }

  useEffect(() => {
    if (!topicsToReasons.length) {
      setReasons([])
      return
    }

    fetchReasons()
  }, [topicsToReasons, fetchReasons])

  async function fetchReasonsByIntention(intention: Intention, signal?: AbortSignal) {
    setIsLoading(true)
    const inputSchema = toInputSchema()

    const inputSchemaWithIntention = { ...inputSchema }
    inputSchemaWithIntention.filter.feedback_filter.feedback_keyword_classes = [
      capitalizeFirst(intention)
    ]

    const [error, response] = await ReasonsService.getClusterReason(
      {
        search_input_schema: inputSchemaWithIntention,
        search_text: inputSchemaWithIntention.filter.search_text,
        keywords_to_cluster: [],
        approximate_count: true,
        date_range: 90,
        offset: 0,
        size: 100,
        sort_type: 'F'
      },
      signal
    )

    if (error) {
      setReasons([])
      setIsLoading(false)

      if (error.isCanceledError) return
      addErrorToast({
        text: `Failed to load findings related to ${capitalizeFirst(intention)}. Message: ${
          error.key
        }`
      })
      return
    }

    const fetchedReasons: Reason[] = response.map(ReasonsService.searchResultToReason)

    const filteredReasons = fetchedReasons.filter(reason => reason.frequency >= 1)
    setReasons(filteredReasons)

    setIsLoading(false)
  }

  const filteredReasons = useMemo(() => {
    const frequencyFilteredReasons = reasons.filter(reason => reason.frequency >= 5)
    return frequencyFilteredReasons.length ? frequencyFilteredReasons : reasons.slice(0, 3)
  }, [reasons])

  const totalFrequency = useMemo(
    () => reasons.reduce((acc, value) => acc + value.frequency, 0),
    [reasons]
  )
  const filteredFrequency = useMemo(
    () => filteredReasons.reduce((acc, value) => acc + value.frequency, 0),
    [filteredReasons]
  )

  return {
    reasons,
    filteredReasons,
    totalFrequency,
    filteredFrequency,
    isLoading,
    selectReason,
    selectedReason,
    isLoadingChart,
    trendSeries,
    cancelRequest,
    fetchReasonsByIntention
  }
}

export default useReasonsTopics
