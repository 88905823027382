import useTopicDetails from '@/hooks/analytics/useTopicDetails'
import { TrendingTopicType } from '@/types/analytics/TrendingTopic'
import TopicSummary, { SummarySkeleton } from '../../summary/Summary'
import RootCauses, { RootCausesSkeleton } from './RootCauses'
import useUser from '@/hooks/useUser'

interface Props {
  topicId: string
  type: TrendingTopicType
  asExploreFeedbackItem?: boolean
}

const TopicSummaryAndCauses = ({ topicId, type, asExploreFeedbackItem = false }: Props) => {
  const { summary, isLoading, rootCauses } = useTopicDetails({
    topicId,
    type,
    asExploreFeedbackItem
  })

  const { userPermissions } = useUser()

  const hasSummaryPermission = userPermissions.summary.includes('view')
  const hasRootCausePermission = userPermissions.root_causes.includes('view')

  const isRootCausesEnabled = false
  // const isRootCausesEnabled = topicDetailsParams.enableRootCauses && hasRootCausePermission

  if (!hasSummaryPermission && !hasRootCausePermission) return <></>

  if (isLoading) {
    return (
      <>
        {hasSummaryPermission && <SummarySkeleton title={"What's happending?"} />}
        {isRootCausesEnabled && <RootCausesSkeleton />}
      </>
    )
  }

  return (
    <>
      {hasSummaryPermission && <TopicSummary title={"What's happending?"} text={summary} />}
      {isRootCausesEnabled && <RootCauses rootCauses={rootCauses} />}
    </>
  )
}

export default TopicSummaryAndCauses
