import { ActionBar } from '@/components/atoms/action-bar'
import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'

export const ActionsBar = ActionBar

export const CheckedTopicsTrigger = styled(FlexContainer, {
  height: 32,
  minWidth: 140,
  width: '100%',
  maxWidth: 260,
  ml: '$xxxs',
  padding: '$xxxs $xxs',
  bAll: '$neutralHighPure',
  br: '$micro',
  backgroundColor: '$brandPrimaryPure',
  cursor: 'pointer'
})
