import { CheckboxState } from '@/components/atoms/checkbox'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { ThemeItemData } from '@/types/classification/Theme'
import { useCallback, useMemo } from 'react'
import TopicsSearchResultItem from './TopicsSearchResultItem'
import { useTranslation } from 'react-i18next'

export interface TopicsSearchResultProps {
  searchQuery: string
  themes: ThemeItemData[]
  isThemeChecked: (theme: ThemeItemData) => CheckboxState
  isTopicChecked: (topicId: string) => CheckboxState
  onThemeCheck: (theme: ThemeItemData) => (checked: CheckboxState) => void
  onTopicCheck: (topicId: string) => (checked: CheckboxState) => void
  isDisabled: boolean
}

const TopicsSearchResult = ({
  searchQuery,
  themes,
  isThemeChecked,
  isTopicChecked,
  onThemeCheck,
  onTopicCheck,
  isDisabled
}: TopicsSearchResultProps) => {
  const match = useCallback(
    (value: string) => value.toLowerCase().includes(searchQuery.toLowerCase()),
    [searchQuery]
  )
  const { t } = useTranslation()

  const results = useMemo(
    () =>
      themes
        .filter(
          theme => match(theme.themeName) || theme.topics.some(topic => match(topic.topicName))
        )
        .map(theme => {
          const filteredTopics = theme.topics.filter(topic => match(topic.topicName))
          const remainingTopicsCount = theme.topics.length - filteredTopics.length
          return { ...theme, filteredTopics, remainingTopicsCount }
        }),
    [themes, match]
  )

  return (
    <FlexContainer css={{ mx: '$xxxs' }} direction="column">
      <Text as="p" fontSize="micro">
        {t('showingResultsFor', { searchQuery })}
      </Text>
      {results.map(theme => (
        <TopicsSearchResultItem
          isDisabled={isDisabled}
          isThemeChecked={isThemeChecked}
          isTopicChecked={isTopicChecked}
          key={theme.themeId}
          onThemeCheck={onThemeCheck}
          onTopicCheck={onTopicCheck}
          result={theme}
        />
      ))}
    </FlexContainer>
  )
}

export default TopicsSearchResult
