import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import useUser from '@/hooks/useUser'
import { CheckCircle } from '@phosphor-icons/react'
import { MouseEventHandler } from 'react'
import { useNavigate } from 'react-router-dom'
import { StyledSeparator } from './UserIndicator.styles'

interface PlanProps {
  name: string
  description: string
  isSelected: boolean
  onClick: MouseEventHandler<HTMLDivElement>
}
const Plan = ({ name, description, isSelected, onClick }: PlanProps) => {
  return (
    <FlexContainer css={{ cursor: 'pointer' }} direction="column" onClick={onClick}>
      <FlexContainer alignItems="center" fullWidth justifyContent="spaceBetween">
        <Text fontSize="xxxs">{name}</Text>
        {isSelected && <CheckCircle size={16} />}
      </FlexContainer>
      <Text color="neutralLowLight" fontSize="micro">
        {description}
      </Text>
    </FlexContainer>
  )
}

const UserPlans = () => {
  const { userPlan, currentUserPlan, setCurrentUserPlan } = useUser()

  const navigate = useNavigate()

  const onTopicPlanClick = () => {
    setCurrentUserPlan('topic')
    navigate('/analytics')
  }

  const onOpportunityPlanClick = () => {
    setCurrentUserPlan('opportunity')
    navigate('/home')
  }

  if (userPlan !== 'all') return null

  return (
    <>
      <FlexContainer css={{ px: '$xxs' }} direction="column" gap="xxs">
        <Text color="neutralLowLight" fontSize="micro">
          Birdie spaces
        </Text>
        <Plan
          description="Explore feedback using topics"
          isSelected={currentUserPlan === 'topic'}
          name="Topic plan"
          onClick={onTopicPlanClick}
        />
        <Plan
          description="Find, quantify and track opportunities"
          isSelected={currentUserPlan === 'opportunity'}
          name="Opportunity plan"
          onClick={onOpportunityPlanClick}
        />
      </FlexContainer>
      <StyledSeparator />
    </>
  )
}

export default UserPlans
