import { css, VariantProps } from '@stitches/react'
import { CSS } from '.'

const oldTypeface = {
  textH1MediumHeading: {
    fontSize: '36px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal'
  },
  textH2MediumHeading: {
    fontSize: 24,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '36px',
    letterSpacing: 0
  },
  textH3SmallHeading: {
    fontSize: 18,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '27px',
    letterSpacing: 0
  },
  textBodyRegularStrong: {
    fontSize: 16,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: 0
  },
  textBodyRegularMedium: {
    fontSize: 16,
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: 0
  },
  textBodyRegularRegular: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: 0
  },
  textBodySmallStrong: {
    fontSize: 14,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '21px',
    letterSpacing: 0
  },
  textBodySmallMedium: {
    fontSize: 14,
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '21px',
    letterSpacing: 0
  },
  textBodySmallRegular: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: '21px',
    letterSpacing: 0
  },
  uiComponentsSmallStrong: {
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '12px',
    letterSpacing: 0
  },
  uiComponentsSmallRegular: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: '12px',
    letterSpacing: 0
  },
  preTitleSemibold: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '150%'
  },
  body1Medium: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '150%'
  }
}

const typeface = {
  ...oldTypeface,
  textSemiboldXSmall: {
    fontFamily: '$default',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '15px'
  },
  textRegularXSmall: {
    fontFamily: '$default',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '15px'
  },
  textSemiboldSmall: {
    fontFamily: '$default',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '15px'
  },
  textRegularSmall: {
    fontFamily: '$default',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '15px'
  },
  pageHeading: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '30px',
    fontStyle: 'normal'
  },
  bigNumber: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 40
  },

  titleRegularLarge: {
    fontFamily: '$default',
    fontSize: '$lg',
    fontStyle: 'normal',
    fontWeight: '$regular',
    lineHeight: '$sm'
  },
  titleRegularSmall: {
    fontFamily: '$default',
    fontSize: '$sm',
    fontStyle: 'normal',
    fontWeight: '$regular',
    lineHeight: '$md'
  },
  titleBoldSmall: {
    fontFamily: '$default',
    fontSize: '$sm',
    fontStyle: 'normal',
    fontWeight: '$bold',
    lineHeight: '$md'
  },
  titleBoldXS: {
    fontFamily: '$default',
    fontSize: '$xs',
    fontStyle: 'normal',
    fontWeight: '$bold',
    lineHeight: '$sm'
  },
  titleBoldXXS: {
    fontFamily: '$default',
    fontSize: '$xxs',
    fontStyle: 'normal',
    fontWeight: '$bold',
    lineHeight: '$default'
  },
  titleBoldXXXS: {
    fontFamily: '$default',
    fontSize: '$xxxs',
    fontStyle: 'normal',
    fontWeight: '$bold',
    lineHeight: '$default'
  },
  titleBoldNano: {
    fontFamily: '$default',
    fontSize: '$nano',
    fontStyle: 'normal',
    fontWeight: '$bold',
    lineHeight: '$xl'
  },
  subtitleSemiBoldXXXS: {
    fontFamily: '$default',
    fontSize: '$xxxs',
    fontStyle: 'normal',
    fontWeight: '$semibold',
    lineHeight: '$xs'
  },
  subtitleRegularXXXS: {
    fontFamily: '$default',
    fontSize: '$xxxs',
    fontStyle: 'normal',
    fontWeight: '$regular',
    lineHeight: '$xs'
  },
  subtitleRegularXXS: {
    fontFamily: '$default',
    fontSize: '$xxs',
    fontStyle: 'normal',
    fontWeight: '$regular',
    lineHeight: '$default'
  },
  subtitleSemiBoldMicro: {
    fontFamily: '$default',
    fontSize: '$micro',
    fontStyle: 'normal',
    fontWeight: '$semibold',
    lineHeight: '$md'
  },
  subtitleRegularMicro: {
    fontFamily: '$default',
    fontSize: '$micro',
    fontStyle: 'normal',
    fontWeight: '$regular',
    lineHeight: '$md'
  },
  subtitleBoldMicro: {
    fontFamily: '$default',
    fontSize: '$micro',
    fontStyle: 'normal',
    fontWeight: '$bold',
    lineHeight: '$md'
  },
  subtitleRegularNano: {
    fontFamily: '$default',
    fontSize: '$nano',
    fontStyle: 'normal',
    fontWeight: '$regular',
    lineHeight: '$xl'
  },
  paragraphRegularMicro: {
    fontFamily: '$default',
    fontSize: '$micro',
    fontStyle: 'normal',
    fontWeight: '$regular',
    lineHeight: '$xxl'
  }
}

export const text = css({
  variants: {
    typeface
  }
})

export const typefaceVariants = typeface as { [x in keyof typeof typeface]: CSS }

type TextTypeFace = VariantProps<typeof text>
export type TypeFaceOptions = TextTypeFace['typeface']
