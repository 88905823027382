import moment from 'moment'
import { AnnotationType } from '../annotations/Annotation'

export type TimeSeriesGroupQueryOption = 'intention' | 'kind' | string
export type TimeSeriesGroupOption = TimeSeriesGroupQueryOption | 'all'

export interface TimeSeriesGroup {
  name: string
  values: number[]
}

interface TimeSeriesOpportunityGroup extends TimeSeriesGroup {
  id: string
  groups: Partial<Record<TimeSeriesGroupOption, TimeSeriesGroup[]>>
}

export type TimeSeriesGroupsRecord = {
  [key in TimeSeriesGroupOption | 'opportunity']: key extends 'opportunity'
    ? TimeSeriesOpportunityGroup[]
    : TimeSeriesGroup[]
}

export interface TimeSeriesAnnotation {
  annotationId: string
  organizationId: string
  name: string
  description: string
  timestamp: moment.Moment
  annotationDate: moment.Moment
  parentId: string
  type: AnnotationType
  createdAt: moment.Moment
}

export interface TimeSeries {
  timestamps: moment.Moment[]
  values: number[]
  groups?: Partial<TimeSeriesGroupsRecord>
  annotations?: TimeSeriesAnnotation[]
}

export enum TimeSeriesIntervalOption {
  Day = 'daily',
  Week = 'weekly',
  Month = 'monthly',
  Quarter = 'quarterly',
  Year = 'yearly'
}

export type BreakdownOption =
  | 'org'
  | 'all'
  | 'areas'
  | 'opportunity'
  | 'segment'
  | 'satisfaction'
  | 'satisfaction_by_nps'
  | 'satisfaction_by_csat'
  | 'satisfaction_by_review'
  | 'sentiment'
  | 'intention'
  | 'source_alias'
  | 'customField'
  | 'feedbackDetail'
  | 'account'
  | 'user'
  | 'date'

export const satisfactionByKindList: BreakdownOption[] = [
  'satisfaction',
  'satisfaction_by_csat',
  'satisfaction_by_nps',
  'satisfaction_by_review'
]

export const satisfactionByKindMap: Record<
  string,
  'satisfaction_by_nps' | 'satisfaction_by_csat' | 'satisfaction_by_review'
> = {
  nps: 'satisfaction_by_nps',
  csat: 'satisfaction_by_csat',
  review: 'satisfaction_by_review',
  reviews: 'satisfaction_by_review'
}

export const mapSatisfactionToKind: Record<
  'satisfaction_by_nps' | 'satisfaction_by_csat' | 'satisfaction_by_review',
  string
> = {
  satisfaction_by_nps: 'nps',
  satisfaction_by_csat: 'csat',
  satisfaction_by_review: 'review'
}
