export const fontSizes = {
  nano: '10px',
  micro: '12px',
  xxxs: '14px',
  xxs: '16px',
  xs: '20px',
  sm: '24px',
  md: '32px',
  lg: '40px',
  xl: '48px'
}

type FontSizeKeys = keyof typeof fontSizes
type FontSizeVariant = {
  [x in FontSizeKeys]: { fontSize: string }
}
export const fontSizeVariant = Object.keys(fontSizes).reduce((acc, fontSize) => {
  return { ...acc, [fontSize]: { fontSize: `$${fontSize}` } }
}, {} as FontSizeVariant)
