import { Auth0Provider } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType
} from 'react-router-dom'

import {
  API_URL,
  APP_ENVIRONEMNT,
  BIRDE_API_URL,
  DASHBOARD_STATE_API,
  SEARCH_URL,
  SENTRY_DSN
} from '@/config'
import routes from '@/pages/routes'
import { QueryClientProvider } from '@tanstack/react-query'
import { hotjarWorkaround } from './plugins/hotjar-workaround'
import { queryClient } from './plugins/reactQueryClient'
import { globalStyles } from './theme'
import { TextReplacementProvider } from './plugins/TextReplacementProvider'

import './plugins/i18n/i18n'
import { getAuth0ClientData } from './plugins/auth0'

if (APP_ENVIRONEMNT === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        networkDetailAllowUrls: [
          API_URL,
          BIRDE_API_URL,
          SEARCH_URL,
          DASHBOARD_STATE_API,
          'https://api.segment.io/v1/t'
        ]
      }),
      Sentry.extraErrorDataIntegration({ depth: 6 })
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter(routes)
const AppRouter = () => <RouterProvider router={router} />

const SentryRoutes = Sentry.withProfiler(AppRouter)

// START workaround from https://github.com/facebook/react/issues/11538#issuecomment-417504600

/**
 * Strongly typed polyfill to prevent insertion and removal of nodes from different parents.
 * @param {Node} child - The child node to be removed.
 * @returns {Node} - The child node that was attempted to be removed.
 */
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  ;(Node.prototype as any).removeChild = function (child: Node): Node {
    if (child.parentNode !== this) {
      if (console) {
        console.error('Cannot remove a child from a different parent', child, this)
      }
      return child
    }
    // biome-ignore lint/style/noArguments: <explanation>
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    return originalRemoveChild.apply(this, arguments as any)
  }

  /**
   * Strongly typed polyfill to prevent insertion before a reference node from a different parent.
   * @param {Node} newNode - The node to be inserted.
   * @param {Node | null} referenceNode - The node before which the new node is to be inserted.
   * @returns {Node} - The new node that was attempted to be inserted.
   */
  const originalInsertBefore = Node.prototype.insertBefore
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  ;(Node.prototype as any).insertBefore = function (
    newNode: Node,
    referenceNode: Node | null
  ): Node {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error(
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this
        )
      }
      return newNode
    }
    // biome-ignore lint/style/noArguments: <explanation>
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    return originalInsertBefore.apply(this, arguments as any)
  }
}

// END workaround

const auth0ClientData = getAuth0ClientData()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Auth0Provider
    {...auth0ClientData}
    useRefreshTokens
    // audience={`https://${AUTH_DOMAIN}/api/v2/`}
    // clientId={AUTH_CLIENT}
    // domain={AUTH_DOMAIN}
    // redirectUri={window.location.origin + '/redirect'}
    // scope="read:current_user update:current_user_metadata"
  >
    <QueryClientProvider client={queryClient}>
      <TextReplacementProvider>
        <SentryRoutes />
      </TextReplacementProvider>
    </QueryClientProvider>
  </Auth0Provider>
)

globalStyles()
hotjarWorkaround()
