import Text from '@/components/atoms/text'
import { AppliedBadge, FilterItemContainer } from './SavedFiltersPopover.styles'
import OptionsMenu, { OptionsMenuItem } from '@/components/atoms/options-menu'
import { BellSimple, CheckCircle, IconContext, NoteBlank, TrashSimple } from '@phosphor-icons/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SavedFilter, SavedFilterWithDisabled } from '@/types/filters/Filters'
import FlexContainer from '@/components/atoms/flex-container'
import { useTranslation } from 'react-i18next'

interface Props {
  applied?: boolean
  filter: SavedFilterWithDisabled
  onClickSavedFilter: (filter: SavedFilter) => void
  onClickSaveAsNew: (filter: SavedFilter) => void
  onClickDelete: (filter: SavedFilter) => void
}

function SavedFilterItem({
  filter,
  onClickSavedFilter,
  onClickSaveAsNew,
  onClickDelete,
  applied = false
}: Props) {
  const navigate = useNavigate()
  const [optionsOpen, setOptionsOpen] = useState(false)
  const { t } = useTranslation()

  const options: OptionsMenuItem[] = [
    {
      text: t('manageNotifications'),
      icon: <BellSimple />,
      onClick: e => {
        e.stopPropagation()
        navigate('/settings/topic_notifications')
      }
    },
    {
      text: t('saveAsNew'),
      icon: <NoteBlank />,
      onClick: e => {
        e.stopPropagation()
        onClickSaveAsNew(filter)
      }
    },
    {
      text: t('delete'),
      icon: <TrashSimple />,
      onClick: e => {
        e.stopPropagation()
        e.preventDefault()
        onClickDelete(filter)
      }
    }
  ]

  return (
    <FilterItemContainer as="button" onClick={() => onClickSavedFilter(filter)}>
      <Text as="p" fontSize="xxxs" lineHeight="xxl" title={filter.name} truncate>
        {filter.name}
      </Text>

      <FlexContainer alignItems="center" gap="nano">
        {applied && (
          <AppliedBadge>
            <CheckCircle size={16} />
            <span>{t('applied')}</span>
          </AppliedBadge>
        )}
        <span className="options">
          <IconContext.Provider value={{ size: 16, weight: 'regular' }}>
            <OptionsMenu
              onOpenChange={setOptionsOpen}
              open={optionsOpen}
              options={options}
              stopPropagation
            />
          </IconContext.Provider>
        </span>
      </FlexContainer>
    </FilterItemContainer>
  )
}

export default SavedFilterItem
