import { CSS, styled } from '@/theme'

const Badge = styled('span', {
  borderRadius: 8,
  padding: '$nano $micro',
  color: '$neutralHighLight',
  fontSize: '$micro',
  fontWeight: '$semibold',
  textTransform: 'capitalize',
  width: 'fit-content',

  variants: {
    visibility: {
      public: {
        backgroundColor: '$brandPrimaryPure'
      },
      private: {
        backgroundColor: '$brandPrimaryMedium'
      }
    }
  }
})

interface Props {
  visibility: 'public' | 'private'
  css?: CSS
}

const CollectionBadge = ({ visibility, css }: Props) => (
  <Badge css={css} visibility={visibility}>
    {visibility}
  </Badge>
)

export default CollectionBadge
