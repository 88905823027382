import Button from '@/components/atoms/button'
import Dialog from '@/components/atoms/dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { useTranslation } from 'react-i18next'
import useSegment from '@/hooks/useSegment'
import { useLocation, useNavigate } from 'react-router-dom'
import { WarningCircle } from '@phosphor-icons/react'
import useOpportunityMutations from '@/hooks/opportunity/useOpportunityMutations'
import { OpportunityItem } from '@/types/opportunity/Opportunity'

interface Props {
  open: boolean
  userEmail?: string
  userCompanyName?: string
  oppName?: string
  oppDescription?: string
  opportunity: OpportunityItem
}

const CalibrationReviewDialog = ({ open, userEmail, opportunity, userCompanyName }: Props) => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const navigate = useNavigate()
  const location = useLocation()
  const basePath = location.pathname.split('/calibration')[0]
  const { updateOpportunity } = useOpportunityMutations()

  const onCloseClick = () => {
    navigate(basePath)
    updateOpportunity({ id: opportunity.id, internal_review: true })
    track('calibrate_opp_dialog_send_to_specialists', {
      email: userEmail,
      opportunity_name: opportunity.name,
      organization_name: userCompanyName,
      opportunity_description: opportunity.description,
      date: new Date(Date.now()).toISOString()
    })
  }

  return (
    <Dialog align="center" closeIcon={false} modal onClose={onCloseClick} open={open}>
      <FlexContainer direction="column" fullHeight gap="xs">
        <FlexContainer gap="micro">
          <WarningCircle size={16} />

          <Text as="h1" typeface="titleBoldXXS">
            {t('calibrateOpportunityDialogReviewTitle')}
          </Text>
        </FlexContainer>

        <FlexContainer direction="column" gap="xxs">
          <Text as="p" fontSize="xxxs">
            {t('calibrateOpportunityDialogReviewBody1')}
          </Text>
          <Text as="p" fontSize="xxxs">
            {t('calibrateOpportunityDialogReviewBody2')}
          </Text>
        </FlexContainer>

        <Divider line="solid" orientation="horizontal" />
        <FlexContainer direction="column" gap="xxs">
          <Button onClick={onCloseClick} size="small">
            {t('calibrateOpportunityDialogReviewConfirm')}
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Dialog>
  )
}

export default CalibrationReviewDialog
