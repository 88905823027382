import i18n from '@/plugins/i18n/i18n'
import CalibrationBetweenQuestionCard from './CalibrationBetweenQuestionCard'
import { RoundNumber } from '@/store/useOpportunityCalibrationStore'

const nextRoundOption = {
  title: i18n.t('calibrateOpportunityNextRoundTitle'),
  icon: '\u{1F389}',
  text: i18n.t('calibrateOpportunityNextRoundBody1'),
  textTwo: i18n.t('calibrateOpportunityNextRoundBody2'),
  action: i18n.t('calibrateOpportunityNextRoundConfirm')
}

const lastStepOption = {
  title: i18n.t('calibrateOpportunityLastStepTitle'),
  icon: '\u{1F3C1}',
  text: i18n.t('calibrateOpportunityLastStepBody1'),
  textTwo: i18n.t('calibrateOpportunityLastStepBody2'),
  action: i18n.t('calibrateOpportunityLastStepConfirm')
}

const lessRoundsOption = {
  title: i18n.t('calibrateOpportunityLastStepTitle'),
  icon: '\u{1F929}',
  text: i18n.t('calibrateOpportunityLessRoundsBody1'),
  textTwo: i18n.t('calibrateOpportunityLessRoundsBody2'),
  action: i18n.t('calibrateOpportunityLastStepConfirm')
}

const doneOption = {
  title: i18n.t('calibrateOpportunityDoneTitle'),
  icon: '\u{1F973}',
  text: i18n.t('calibrateOpportunityDoneBody1'),
  textTwo: i18n.t('calibrateOpportunityDoneBody2'),
  action: i18n.t('calibrateOpportunityDoneConfirm')
}

type Props = {
  currentRound: RoundNumber
  feedbackSampleStatus: string | undefined
  isCalibrationFinished: boolean
  onNextRoundClick: () => void
  onRecordCalibrationClick: () => void
  onOpenDetailsClick: () => void
}

const CalibrationBetweenQuestionItem = ({
  currentRound,
  feedbackSampleStatus,
  isCalibrationFinished,
  onNextRoundClick,
  onRecordCalibrationClick,
  onOpenDetailsClick
}: Props) => {
  if (currentRound >= 3 || isCalibrationFinished) {
    return (
      <CalibrationBetweenQuestionCard
        finalRound={true}
        onClick={onOpenDetailsClick}
        {...doneOption}
      />
    )
  }

  if (feedbackSampleStatus === 'finished') {
    return (
      <CalibrationBetweenQuestionCard onClick={onRecordCalibrationClick} {...lessRoundsOption} />
    )
  }

  if (currentRound === 2) {
    return <CalibrationBetweenQuestionCard onClick={onRecordCalibrationClick} {...lastStepOption} />
  }

  if (currentRound < 2) {
    return <CalibrationBetweenQuestionCard onClick={onNextRoundClick} {...nextRoundOption} />
  }
}

export default CalibrationBetweenQuestionItem
