import { Suspense, lazy, memo, useMemo, useState } from 'react'

import Checkbox from '@/components/atoms/checkbox'
import KeywordChip from '@/components/atoms/keyword-chip'
import VolumeBar from '@/components/atoms/volume-bar'
import Text from '@/components/atoms/text'
import { TableCell, TableRow } from './TopicRow.styles'
import TopicGroupChips from './TopicGroupChips'
import SentimentsTooltip from '../../classification/tooltip/SentimentsTooltip'
import useUser from '@/hooks/useUser'
import { TopicRowProps } from './TopicRow.types'
import Tooltip from '@/components/atoms/tooltip'
import IconButton from '@/components/atoms/icon-button'
import { GitMerge, Question } from '@phosphor-icons/react'
import useTopicsStore from '@/store/useTopicsStore'
import { useFiltersStore } from '@/store'
import { shallow } from 'zustand/shallow'
import { colors } from '@/theme'
import useTopicSettingsStore from '@/store/useTopicSettingsStore'
import { DeleteDialog } from '@/components/atoms/dialog'

const TopicModal = lazy(() => import('./topic-modal/TopicModal'))

const TopicRow = ({ topic, ...props }: TopicRowProps) => {
  const { userPermissions } = useUser()
  const isManager = userPermissions.topic.includes('manager')

  const isMerged = Boolean(topic.mergedTopics?.length)

  const { topicNameInput, topicsToMerge, topicsToUnmerge } = useTopicSettingsStore(
    state => ({
      topicNameInput: state.topicNameInput,
      topicsToMerge: state.topicsToMerge,
      topicsToUnmerge: state.topicsToUnmerge
    }),
    shallow
  )
  const setTopicNameInput = useTopicSettingsStore(state => state.setTopicNameInput)
  const setSearchTopicInput = useTopicSettingsStore(state => state.setTopicSearchInput)
  const clearTopicsToMerge = useTopicSettingsStore(state => state.clearTopicsToMerge)
  const clearTopicsToUnmerge = useTopicSettingsStore(state => state.clearTopicsToUnmerge)

  const setTopicSortType = useTopicsStore(state => state.setTopicSortType)
  const onMergedIndicatorClick = () => {
    setTopicSortType('merged')
  }

  const netSentimentRange = useMemo(() => {
    const { netSentiment } = topic.sentiment
    if (netSentiment < 0) return 'red'

    if (netSentiment > -1 && netSentiment <= 20) return 'neutralMedium'

    return 'green'
  }, [topic.sentiment])

  const showCheckbox = isManager

  const search = useFiltersStore(state => state.search, shallow)
  const showTooltip = search && !topic.topicName.includes(search) && isMerged
  const tooltipText = `We found the topic "${search}" inside "${topic.topicName}"`

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCloseDialogOpen, setIsCloseDialogOpen] = useState(false)

  const onTopicClick: React.MouseEventHandler<HTMLSpanElement> = e => {
    e.preventDefault()
    if (isMerged) {
      setSearchTopicInput(topic.topicName)
    }
    setTopicNameInput(topic.topicName)
    setIsModalOpen(true)
  }

  const onCloseModal = () => {
    if (
      topicNameInput.trim() !== topic.topicName ||
      !!topicsToMerge.length ||
      !!topicsToUnmerge.length
    ) {
      setIsCloseDialogOpen(true)
      return
    }

    setIsModalOpen(false)
  }

  const onConfirmClose = () => {
    setIsCloseDialogOpen(false)
    setIsModalOpen(false)
    clearTopicsToMerge()
    clearTopicsToUnmerge()
  }

  return (
    <>
      <TableRow key={topic.topicId}>
        <TableCell>
          {showTooltip && (
            <span className="tooltip">
              <Tooltip side="bottom" text={tooltipText} variant="small">
                <Question color={colors.neutralLowPure} size={14} weight="fill" />
              </Tooltip>
            </span>
          )}

          {showCheckbox ? (
            <Checkbox
              checked={props.checked}
              onCheckedChange={props.onCheckedChange}
              value={topic.topicId}
            />
          ) : null}
          <KeywordChip
            id={topic.topicId}
            isCluster
            label={topic.topicName}
            onClick={onTopicClick}
          />
        </TableCell>
        <TableCell>
          {isMerged && (
            <Tooltip side="bottom" text="Merged topic" variant="small">
              <IconButton
                css={{
                  backgroundColor: '$brandPrimaryLight',
                  color: '$brandPrimaryPure',
                  ml: '$xxxs'
                }}
                onClick={onMergedIndicatorClick}
              >
                <GitMerge weight="fill" />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell wrap>
          <TopicGroupChips id={topic.topicId} themeData={topic.themeList} />
        </TableCell>
        <TableCell justifyCenter netSentiment>
          <SentimentsTooltip
            {...topic.sentiment}
            align="center"
            closeDelay={400}
            feedbackCount={0}
            openDelay={250}
            showHeader={false}
            title=""
            totalCount={0}
          >
            <div>
              <Text color={netSentimentRange} typeface="textRegularXSmall">
                {Math.trunc(topic.sentiment.netSentiment)}
              </Text>
            </div>
          </SentimentsTooltip>
        </TableCell>
        <TableCell>
          <VolumeBar
            css={{ background: '$purple' }}
            fullRounded
            height="small"
            max={props.max}
            showNumber={false}
            showNumberOutside
            value={topic.totalFrequency}
            valueLabel={Intl.NumberFormat('en-US', {
              notation: 'compact',
              maximumFractionDigits: 1
            }).format(topic.totalFrequency)}
          />
        </TableCell>
      </TableRow>

      <Suspense>{isModalOpen && <TopicModal onClose={onCloseModal} topic={topic} />}</Suspense>

      <DeleteDialog
        cancelText="Go back"
        confirmText="Discard changes"
        description="You have unsaved changes on your topic. Your changes will be lost if you discard changes."
        isDeleting={false}
        modal
        onConfirmDelete={onConfirmClose}
        onOpenChange={setIsCloseDialogOpen}
        open={isCloseDialogOpen}
        title="Unsaved changes"
      />
    </>
  )
}

export default memo(TopicRow)
