import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'

export const GRID_TEMPLATE =
  'minmax(215px, 2.5fr) minmax(100px, 0.4fr) minmax(170px, 1.6fr) minmax(120px, 0.6fr) 1.5fr 100px'
export const FIRST_CELL_PADDING = '$xs'

export const TableRow = styled('div', {
  display: 'grid',
  gridTemplateColumns: GRID_TEMPLATE,
  bBottom: '$stroke',
  background: '$white',
  py: '$xxxs',
  pr: '$xs',

  '.topic-item-options': { opacity: 0 },

  '&:hover': {
    background: '$greyLight',
    '.topic-item-options': {
      opacity: 1
    }
  },

  '.expand-icon': {
    padding: 0
  },

  variants: {
    isClicked: {
      true: {
        background: '$greyLight',
        boxShadow: '$leftBlueBorder',
        'span.options': { opacity: 1 }
      },
      false: {}
    },

    isExpanded: {
      true: {
        '.expand-icon': {
          transform: 'rotate(0deg)',
          transition: '.2s'
        }
      },
      false: {
        '.expand-icon': {
          transform: 'rotate(-90deg)'
        }
      }
    }
  }
})

export const TableCell = styled('div', {
  display: 'flex',
  alignItems: 'center',
  fontSize: '$micro',
  color: '$grey',

  '&:last-of-type': {
    borderRight: 'none'
  },

  '&:first-of-type': {
    paddingLeft: FIRST_CELL_PADDING
  },

  '.tooltip': {
    position: 'relative',
    width: 0,
    top: 1,
    left: -20,
    boxSizing: 'border-box'
  },

  variants: {
    wrap: {
      true: {
        flexWrap: 'wrap'
      }
    },

    justifyEnd: {
      true: {
        justifyContent: 'flex-end'
      }
    },
    justifyCenter: {
      true: {
        justifyContent: 'center'
      }
    },
    netSentiment: {
      true: {
        div: {
          cursor: 'pointer'
        }
      }
    }
  }
})

export const TopicsCounter = styled('div', {
  background: 'transparent',
  bAll: '$stroke',
  color: '$pajarito',
  padding: '4px 8px',
  borderRadius: 8,
  height: 28,
  boxSizing: 'border-box',
  cursor: 'pointer',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  variants: {
    disabled: {
      true: {
        backgroundColor: '$stroke',
        border: 'none',
        color: '$grey',
        pointerEvents: 'none'
      }
    }
  }
})

export const TopicGroupsContainer = styled(FlexContainer, {
  gap: '$micro',
  alignItems: 'center',
  minHeight: '100%',
  minWidth: '100%',
  width: '100%',

  display: 'flex',
  flexWrap: 'wrap',

  '& .plus-container': {
    visibility: 'hidden'
  },

  '& .plus-container.open': {
    visibility: 'visible',
    width: 'auto'
  },

  '&:hover': {
    '& .plus-container': {
      visibility: 'visible',
      width: 'auto'
    }
  },

  '& .plus-container:hover': {
    visibility: 'visible',
    width: 'auto'
  }
})
