import { RadioItem } from '@/components/atoms/radio-group'
import { RadioBooleanOption } from '@/types/filters/Filters'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { useTranslation } from 'react-i18next'

export interface BooleanFilterContentProps {
  selectedOption: RadioBooleanOption
  onOptionChange: (option: string) => void
}

const BooleanFilterContent = ({ selectedOption, onOptionChange }: BooleanFilterContentProps) => {
  const { t } = useTranslation()
  return (
    <RadioGroupPrimitive.Root onValueChange={onOptionChange} value={selectedOption}>
      <RadioItem text={t('all')} value="all" />
      <RadioItem text={t('checked')} value="true" />
      <RadioItem text={t('unchecked')} value="false" />
    </RadioGroupPrimitive.Root>
  )
}

export default BooleanFilterContent
