import { CSS, styled } from '@/theme'

const cellStyle: CSS = {
  paddingBlock: 8,
  paddingInline: 12,
  border: '1ps solid $neutralLowHigh',
  verticalAlign: 'top',

  '&:firstChild': {
    marginBlockStart: 0
  },

  '&:lastChild': {
    marginBlockStart: 0
  }
}

export const Table = styled('table', {
  position: 'relative',
  borderCollapse: 'collapse',
  marginBlockEnd: '1.4em',
  tableLayout: 'fixed',
  width: '100%',
  maxWidth: 1400,
  fontSize: 12,
  fontWeight: 400,
  ml: 'auto',
  mr: 'auto',
  pb: '$md'
})

export const Col = styled('col', {})
export const ColGroup = styled('colgroup', {})
export const Tr = styled('tr', {
  variants: {
    header: {
      top: {
        position: 'sticky',
        top: 0
      },
      bottom: {
        position: 'sticky',
        top: 30
      }
    }
  }
})

export const Td = styled('td', {
  ...cellStyle,
  textAlign: 'right',
  bBottom: '$neutralHighMedium',

  variants: {
    last: {
      true: { bRight: '$neutralHighMedium' }
    }
  }
})

export const Th = styled('th', {
  ...cellStyle,

  fontWeight: 500,
  backgroundColor: '$neutralHighMedium',
  wordBreak: 'break-word',

  variants: {
    date: {
      true: { width: 100 }
    },
    minWidth: {
      true: { minWidth: 200, width: 200 }
    }
  }
})
