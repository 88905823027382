import Dialog from '@/components/atoms/dialog/Dialog'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import CollapsibleDetail from '@/components/molecules/feedback-details/CollapsibleDetail'
import {
  DetailsScrollArea,
  FeedbackDetailItem,
  FeedbackDetailTitle,
  FeedbackDetailsHeader
} from '@/components/molecules/feedback-details/FeedbackDetails.styles'
import { DetailOptionType } from '@/components/molecules/feedback-details/FeedbackDetails.types'
import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import { NewFeedback } from '@/types/feedbacks/Feedback'
import { snakeToTitle } from '@/utils/letterCase'
import { CaretLeft } from '@phosphor-icons/react'
import { useState } from 'react'
import useFeedbackDetails from './useFeedbackDetails'
import Bone from '@/components/atoms/bone'
import { dateShortMonthFormat } from '@/utils/date'
import { useTranslation } from 'react-i18next'

const formatDate = dateShortMonthFormat

interface Props {
  feedback: NewFeedback | null
  onClose: () => void
}

const FeedbackDetails = ({ feedback, onClose }: Props) => {
  const { data, isLoading } = useFeedbackDetails(feedback?.id)

  const [currentOpen, setCurrentOpen] = useState<DetailOptionType | ''>('properties')
  const isOpen = (option: string) => currentOpen === option
  const onCollapseChange = (option: string) => (open: boolean) => {
    setCurrentOpen(open ? (option as DetailOptionType) : '')
  }

  const { t } = useTranslation()

  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      onClose()
    }
  }

  const customFields = data?.customFields ? Object.entries(data.customFields) : []

  if (!feedback) {
    return
  }

  return (
    <Dialog
      align="right"
      closeIcon
      enableInteractionsOutside={false}
      modal
      onOpenChange={onOpenChange}
      open={!!feedback}
      useFullHeight
    >
      <FlexContainer
        css={{ boxSizing: 'border-box', overflow: 'hidden' }}
        direction="column"
        fullHeight
      >
        <FeedbackDetailsHeader>
          <CaretLeft onClick={() => onOpenChange(false)} size={16} weight="bold" />
          <Text as="h4" fontSize="xxxs" fontWeight="semibold" lineHeight="md">
            {t('feedbackDetails')}
          </Text>
        </FeedbackDetailsHeader>
        <DetailsScrollArea type="auto">
          <ScrollAreaViewport>
            <CollapsibleDetail
              isOpen={isOpen}
              onOpenChange={onCollapseChange}
              option={t('properties')}
            >
              <FlexContainer direction="column" gap="micro">
                <FeedbackDetailItem>
                  <FeedbackDetailTitle>{t('feedbackId')}</FeedbackDetailTitle>
                  <p>{feedback.id}</p>
                </FeedbackDetailItem>

                {!!feedback.postedAt && (
                  <FeedbackDetailItem>
                    <FeedbackDetailTitle>{t('postedAt')}</FeedbackDetailTitle>
                    <p>{formatDate(feedback.postedAt)}</p>
                  </FeedbackDetailItem>
                )}

                {!!feedback.updatedAt && (
                  <FeedbackDetailItem>
                    <FeedbackDetailTitle>{t('updatedAt')}</FeedbackDetailTitle>
                    <p>{formatDate(feedback.updatedAt)}</p>
                  </FeedbackDetailItem>
                )}

                <FeedbackDetailItem>
                  <FeedbackDetailTitle>{t('language')}</FeedbackDetailTitle>
                  <p>{feedback.language}</p>
                </FeedbackDetailItem>

                <FeedbackDetailItem>
                  <FeedbackDetailTitle>{t('source')}</FeedbackDetailTitle>
                  <p>{feedback.source}</p>
                </FeedbackDetailItem>

                <FeedbackDetailItem>
                  <FeedbackDetailTitle>{t('feedbackType')}</FeedbackDetailTitle>
                  <p>{feedback.kind.prettyName}</p>
                </FeedbackDetailItem>

                {Object.entries(feedback.kind.fields).map(([field, value]) => (
                  <FeedbackDetailItem key={field}>
                    <FeedbackDetailTitle>{snakeToTitle(field)}</FeedbackDetailTitle>
                    <p>{value}</p>
                  </FeedbackDetailItem>
                ))}
              </FlexContainer>
            </CollapsibleDetail>

            {isLoading && <Bone css={{ mt: '$micro' }} />}

            {customFields.length > 0 && !isLoading && (
              <CollapsibleDetail
                isOpen={isOpen}
                onOpenChange={onCollapseChange}
                option={t('customFields')}
              >
                <FlexContainer direction="column" gap="micro">
                  {customFields.map(([field, value]) => (
                    <FeedbackDetailItem key={field}>
                      <FeedbackDetailTitle>
                        {value.description ?? snakeToTitle(field)}
                      </FeedbackDetailTitle>
                      <p>{value.value}</p>
                    </FeedbackDetailItem>
                  ))}
                </FlexContainer>
              </CollapsibleDetail>
            )}
          </ScrollAreaViewport>
          <ScrollAreaScrollbar orientation="vertical">
            <ScrollAreaThumb />
          </ScrollAreaScrollbar>
        </DetailsScrollArea>
      </FlexContainer>
    </Dialog>
  )
}

export default FeedbackDetails
