import IntentionChip from '@/components/atoms/chip/IntentionChip'
import SelectableCard from '@/components/atoms/selectable-card/SelectableCard'
import { CSS, styled } from '@/theme'
import { Reason } from '@/types/reasons'
import { ArrowDown, ArrowUp, CaretRight, ChatCenteredText } from '@phosphor-icons/react'

const FlexReasonCard = styled(SelectableCard, {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100% - 64px)'
})

const ReasonCardFooter = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '$xxxs',
  marginTop: 'auto'
})

const InfoGroup = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$nano'
})

const CaretIcon = styled('span', {
  position: 'absolute',
  right: '$xxxs',
  top: 'calc(50% - $micro)'
})

interface Props extends Reason {
  onClick?: () => void
  isSelected?: boolean
  css?: CSS
}

const ReasonCard = ({ name, intention, frequency, trend, onClick, isSelected, css }: Props) => {
  const trendIcon = trend < 0 ? <ArrowDown /> : <ArrowUp />

  return (
    <FlexReasonCard css={css} onClick={onClick} selected={isSelected}>
      <p>{name}</p>
      <ReasonCardFooter>
        <IntentionChip css={{ marginRight: 'auto', cursor: 'pointer' }} intention={intention} />
        <InfoGroup>
          <ChatCenteredText />
          <span>{frequency}</span>
        </InfoGroup>
        <InfoGroup>
          {trend !== 0 && trendIcon}
          <span>{Math.abs(Math.round(trend))}%</span>
        </InfoGroup>
      </ReasonCardFooter>
      <CaretIcon>
        <CaretRight size={16} />
      </CaretIcon>
    </FlexReasonCard>
  )
}

export default ReasonCard
