import useDatetimeFiltersQuery from '@/hooks/filters/useDatetimeFiltersQuery'
import { FilterComponentProps } from './types'
import { CalendarDate, DateValue, getLocalTimeZone, now, parseDate } from '@internationalized/date'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DateFilterValue, DatetimeFilterOption } from '@/types/filters/AdvancedFilters'
import {
  dateShortMonthFormat,
  endDateParam,
  getFormattedDateRange,
  startDateParam
} from '@/utils/date'
import Select, { SelectOption } from '@/components/atoms/select'
import Bone from '@/components/atoms/bone'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import Button from '@/components/atoms/button'
import Text from '@/components/atoms/text'
import { CalendarBlank, CaretDown } from '@phosphor-icons/react'
import { DropdownMenuContent } from '@/components/atoms/options-menu'
import FlexContainer from '@/components/atoms/flex-container'
import { PopoverFooter, PopoverHeader } from '@/components/atoms/popover'
import RangeCalendar from '@/components/atoms/range-calendar'
import DatePickerCalendar from '@/components/atoms/date-picker/DatePickerCalendar'
import { DropdownButton } from './styles'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

const selectOptions: SelectOption<DatetimeFilterOption>[] = [
  {
    value: 'matches',
    text: i18n.t('between')
  },
  {
    value: 'does_not_match',
    text: i18n.t('isNotBetween')
  },
  {
    value: 'before',
    text: i18n.t('before')
  },
  {
    value: 'after',
    text: i18n.t('after')
  },
  {
    value: 'empty',
    text: i18n.t('isEmpty')
  },
  {
    value: 'not_empty',
    text: i18n.t('isNotEmpty')
  }
]

const DatetimeFilterRow = ({ filter, onFilterChange }: FilterComponentProps) => {
  const isCustomerRecord = filter.type !== 'datetime'
  const { data, isLoading } = useDatetimeFiltersQuery({
    name: filter.name,
    enabled: !isCustomerRecord
  })

  const minDate: DateValue = useMemo(
    () => parseDate(data?.startDate.split('T')[0] ?? '2000-01-01'),
    [data]
  )
  const maxDate: DateValue = useMemo(
    () => (data ? parseDate(data.endDate.split('T')[0]) : now(getLocalTimeZone())),
    [data]
  )
  const { t } = useTranslation()

  const value = filter.value as DateFilterValue | null
  const initialFilter = useMemo(() => filter, [filter])

  const initialValue = useMemo(() => {
    const initialFilterValue = initialFilter.value as DateFilterValue | 'new' | null
    const isNew = initialFilterValue === 'new'
    if (isNew) {
      return {
        start: minDate,
        end: maxDate
      }
    }
    return {
      start: initialFilterValue?.$gte ? parseDate(initialFilterValue.$gte?.slice(0, 10)) : minDate,
      end: initialFilterValue?.$lt ? parseDate(initialFilterValue.$lt?.slice(0, 10)) : maxDate
    }
  }, [minDate, maxDate, initialFilter.value])

  const selectedOption = (filter.option ?? 'empty') as DatetimeFilterOption

  const [dateRange, setDateRange] = useState(initialValue)
  const singleValue = selectedOption === 'before' ? dateRange.end : dateRange.start

  const appliedDateRange = useMemo(() => {
    const parsedGte = value?.$gte ? parseDate(value.$gte?.slice(0, 10)) : null
    const parsedLt = value?.$lt ? parseDate(value.$lt?.slice(0, 10)) : null
    return {
      start: parsedGte ?? parsedLt ?? initialValue.start,
      end: parsedLt ?? parsedGte ?? initialValue.end
    }
  }, [value, initialValue])

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens only when data change
  useEffect(() => {
    if (initialFilter.value === 'new') {
      onFilterChange({
        ...filter,
        value: {
          $gte: startDateParam(initialValue.start),
          $lt: endDateParam(initialValue.end)
        },
        option: 'matches'
      })
      setDateRange(initialValue)
    }
  }, [data])

  const onOptionChange = (v: DatetimeFilterOption) => {
    if (v === 'matches' || v === 'does_not_match') {
      onFilterChange({
        ...filter,
        value: {
          $gte: startDateParam(dateRange.start),
          $lt: endDateParam(dateRange.end)
        },
        option: v
      })
      return
    }

    if (v === 'after') {
      onFilterChange({
        ...filter,
        value: {
          $gte: startDateParam(dateRange.start)
        },
        option: v
      })
      return
    }

    if (v === 'before') {
      onFilterChange({
        ...filter,
        value: {
          $lt: endDateParam(dateRange.end)
        },
        option: v
      })
      return
    }

    onFilterChange({
      ...filter,
      value: null,
      option: v
    })
  }

  const onRangeChange = (range: RangeValue<DateValue>) => {
    setDateRange({ start: range.start as CalendarDate, end: range.end as CalendarDate })
  }
  const onDateChange = (date: DateValue) => {
    if (selectedOption === 'before') {
      setDateRange(prev => ({ start: prev.start, end: date as CalendarDate }))
      return
    }

    setDateRange(prev => ({ start: date as CalendarDate, end: prev.end }))
  }

  const [dateMenuOpen, setDateMenuOpen] = useState(false)
  const onCalendarOpenChange = (isOpen: boolean) => {
    setDateMenuOpen(isOpen)
    if (!isOpen) {
      setDateRange(appliedDateRange)
    }
  }

  const onCalendarClose = () => {
    setDateRange(appliedDateRange)
    setDateMenuOpen(false)
  }

  const onCalendarApply = () => {
    onOptionChange(selectedOption)
    setDateMenuOpen(false)
  }

  const getRangeText = useCallback(
    (v: typeof initialValue) => {
      if (selectedOption === 'matches' || selectedOption === 'does_not_match') {
        return getFormattedDateRange(v)
      }

      if (selectedOption === 'after') {
        return dateShortMonthFormat(v.start.toDate(getLocalTimeZone()))
      }

      if (selectedOption === 'before') {
        return dateShortMonthFormat(v.end.toDate(getLocalTimeZone()))
      }

      return ''
    },
    [selectedOption]
  )

  const rangeText = useMemo(() => getRangeText(dateRange), [dateRange, getRangeText])
  const appliedRangeText = useMemo(
    () => getRangeText(appliedDateRange),
    [appliedDateRange, getRangeText]
  )

  if (isLoading && !isCustomerRecord) {
    return <Bone css={{ width: '100%', height: 32 }} />
  }

  return (
    <>
      <Select
        onValueChange={onOptionChange}
        options={selectOptions}
        small
        value={selectedOption}
        width="100%"
      />
      {selectedOption !== 'empty' && selectedOption !== 'not_empty' && (
        <DropdownMenuPrimitive.Root onOpenChange={onCalendarOpenChange} open={dateMenuOpen}>
          <DropdownMenuPrimitive.Trigger asChild>
            <DropdownButton size="small" variant="white-bordered">
              <CalendarBlank size={16} />
              <Text fontSize="xxxs" fontWeight="regular" truncate>
                {appliedRangeText}
              </Text>
              <CaretDown className="caret" size={12} />
            </DropdownButton>
          </DropdownMenuPrimitive.Trigger>
          <DropdownMenuPrimitive.Portal>
            <DropdownMenuContent css={{ width: 'auto' }}>
              <FlexContainer direction="column" fullWidth>
                <PopoverHeader>
                  <Text color="neutralLowPure" typeface="subtitleRegularXXXS">
                    {rangeText}
                  </Text>
                </PopoverHeader>
                <FlexContainer css={{ padding: '$xxxs' }} justifyContent="center">
                  {selectedOption === 'matches' || selectedOption === 'does_not_match' ? (
                    <RangeCalendar onChange={onRangeChange} value={dateRange} />
                  ) : (
                    <DatePickerCalendar onChange={onDateChange} value={singleValue} />
                  )}
                </FlexContainer>
              </FlexContainer>
              <PopoverFooter>
                <Button onClick={onCalendarClose} text>
                  {t('cancel')}
                </Button>
                <Button onClick={onCalendarApply}>{t('apply')}</Button>
              </PopoverFooter>
            </DropdownMenuContent>
          </DropdownMenuPrimitive.Portal>
        </DropdownMenuPrimitive.Root>
      )}
    </>
  )
}

export default DatetimeFilterRow
