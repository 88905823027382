import Bone from '@/components/atoms/bone'

const FiltersSkeleton = () => (
  <>
    <Bone css={{ width: '100%', height: 16, borderRadius: 4, mb: 8 }} />
    <Bone css={{ width: '100%', height: 16, borderRadius: 4, mb: 8 }} />
    <Bone css={{ width: '100%', height: 16, borderRadius: 4, mb: 8 }} />
    <Bone css={{ width: '100%', height: 16, borderRadius: 4, mb: 8 }} />
    <Bone css={{ width: '100%', height: 16, borderRadius: 4 }} />
  </>
)

export default FiltersSkeleton
