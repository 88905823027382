import Text from '@/components/atoms/text'
import { KeywordTopic } from '@/types/keywords'
import Switch from '@/components/atoms/switch'
import { ThemeItemData, TopicItemData } from '@/types/classification/Theme'
import { MouseEvent, useMemo } from 'react'
import FlexContainer from '@/components/atoms/flex-container'
import { colors } from '@/theme'
import { CaretRight } from '@phosphor-icons/react'
import useClassification from '@/hooks/useClassification'
import useTopicsSearch, { TopicSearchResult } from '@/hooks/useTopics/useTopicsSearch'
import useUngroupedTopicsSearch from '@/hooks/useTopics/useUngroupedTopicsSearch'
import Loader from '@/components/atoms/loader'
import * as S from './KeywordEditor.styles'
import { makeUniqueArray, sortByKeyString } from '@/utils/array'

interface Props {
  searchQuery: string
  themes: ThemeItemData[]
  isChecked: (topicId: string) => boolean
  onCheckTopic: (topic: KeywordTopic) => (checked: boolean) => void
  onClickTheme: (theme: ThemeItemData) => (e: MouseEvent<HTMLButtonElement>) => void
  onClickMergedTopic: (topic: Partial<TopicItemData>) => (e: MouseEvent<HTMLButtonElement>) => void
  isOverflowing?: boolean
}

const SearchResultSection = ({
  searchQuery,
  themes,
  onClickTheme,
  onCheckTopic,
  isChecked,
  onClickMergedTopic,
  isOverflowing
}: Props) => {
  const results = useTopicsSearch(searchQuery, themes)
  const { isSearching, results: ungroupedResults } = useUngroupedTopicsSearch({
    searchQuery,
    defaultIsSearching: true
  })

  const { getTopicById } = useClassification()

  const normalizeResults = useMemo(() => {
    const _normalized: TopicSearchResult[] = []
    results.forEach(result => {
      if (result.type === 'theme' && result.theme) {
        _normalized.push(result)
      }

      if (result.type === 'topic' && result.topic) {
        _normalized.push({
          ...result,
          name: getTopicById(result.id)?.topicName || result.topic.name
        })
      }
    })

    ungroupedResults.forEach(ungrouped => {
      _normalized.push({
        id: ungrouped.topicId,
        name: ungrouped.topicName,
        type: 'topic',
        topic: {
          name: ungrouped.topicName,
          topicName: ungrouped.topicName,
          topicId: ungrouped.topicId,
          isMerged: ungrouped.isMergeParent,
          mergedTopicList: ungrouped.mergedTopics
        }
      })
    })

    return makeUniqueArray('id', _normalized).sort(sortByKeyString('name'))
  }, [results, ungroupedResults, getTopicById])

  if (isSearching) {
    return (
      <FlexContainer alignItems="center" css={{ mb: '$nano' }} justifyContent="center">
        <Loader />
      </FlexContainer>
    )
  }

  if (!normalizeResults.length) {
    return (
      <FlexContainer alignItems="center" css={{ mb: '$nano' }} justifyContent="center">
        <Text color="grey" typeface="textRegularXSmall">
          No results
        </Text>
      </FlexContainer>
    )
  }

  return (
    <S.Content fixedHeight isOverflowing={isOverflowing}>
      {normalizeResults.map(result => {
        if (result.type === 'topic' && result.topic) {
          const topicId = result.id
          const topicName = result.topic.name
          return (
            <S.Item
              disablePointer={!result.topic.isMerged}
              key={topicId}
              onClick={result.topic.isMerged ? onClickMergedTopic(result.topic) : undefined}
            >
              <Text color="pajarito" title={topicName} truncate typeface="textSemiboldSmall">
                {topicName}
              </Text>
              {result.topic.isMerged ? (
                <CaretRight color={colors.grey} size={14} weight="bold" />
              ) : (
                <Switch
                  checked={isChecked(topicId)}
                  onCheckedChange={onCheckTopic({ name: topicName, topicId })}
                  value={topicId}
                />
              )}
            </S.Item>
          )
        }

        return (
          <S.Item key={result.id} onClick={onClickTheme(result.theme as ThemeItemData)}>
            <Text color="pajarito" title={result.name} truncate typeface="textSemiboldSmall">
              {result.name}
            </Text>
            <CaretRight color={colors.grey} size={14} weight="bold" />
          </S.Item>
        )
      })}
    </S.Content>
  )
}

export default SearchResultSection
