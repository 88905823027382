import useEvents from '@/hooks/useEvents'
import usePeriods from '@/hooks/usePeriods'
import { getDateRangeFromNow, getFormattedDateRange } from '@/utils/date'
import FilterItem, { FilterItemButton } from '../../AppliedFilters/FilterItem'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { useTranslation } from 'react-i18next'

function AppliedDateFilters() {
  const datePeriod = useDateFilterStore(state => state.datePeriod)
  const dateRange = useDateFilterStore(state => state.dateRange)
  const setDateRange = useDateFilterStore(state => state.setDateFilter)

  const { dispatch } = useEvents()
  const { getPeriod } = usePeriods()

  const { t } = useTranslation()
  const formattedDateRange = dateRange ? getFormattedDateRange(dateRange) : 'All time'
  const text = datePeriod === 'custom' ? formattedDateRange : getPeriod(datePeriod).name

  function removeFilter() {
    setDateRange(getDateRangeFromNow('30days'), '30days')
  }

  function onClick() {
    dispatch('open-date-filter', {})
  }

  if (datePeriod === '30days') return []

  return (
    <FilterItem
      editButton={
        <FilterItemButton
          content={text}
          contentType="label"
          onClick={onClick}
          tooltip={formattedDateRange}
        />
      }
      name={t('date')}
      onDelete={removeFilter}
    />
  )
}

export default AppliedDateFilters
