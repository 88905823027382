import useUser from '@/hooks/useUser'
import useSummaryQuery from './useSummaryQuery'
import Summary, { SummarySkeleton } from '../../summary/Summary'
import useOpportunityStore from '@/store/useOpportunityStore'
import { OpportunityStatus } from '@/types/opportunity/Opportunity'
import i18n from '@/plugins/i18n/i18n'

const FeedSummary = () => {
  const { summary, isLoading } = useSummaryQuery()
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)

  const { userPermissions } = useUser()
  const hasSummaryPermission = userPermissions.summary.includes('view')

  const isDraft = currentOpportunity?.status === OpportunityStatus.Draft

  if (!hasSummaryPermission || isDraft) return <></>

  if (isLoading) return <SummarySkeleton title={i18n.t('whatsHappening')} />

  return <Summary text={summary} title={i18n.t('whatsHappening')} />
}

export default FeedSummary
