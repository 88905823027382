import { create } from 'zustand'

type StepNumber = 0 | 1 | 2

interface SignupState {
  firstName: string
  lastName: string
  role?: string
  offer: string | null
  language: string
  companyName: string
  useCase?: string
  feedbackType: string[]
  onboardingStep: StepNumber

  setFirstName: (firstName: string) => void
  setLastName: (lastName: string) => void
  setRole: (role: string) => void
  setOffer: (offer: string | null) => void
  setLanguage: (language: string) => void
  setCompanyName: (companyName: string) => void
  setUseCase: (useCase: string) => void
  toggleFeedbackType: (feedbackType: string) => void
  setOnboardingStep: (step: StepNumber) => void
}

const useSignupStore = create<SignupState>(set => ({
  firstName: '',
  lastName: '',
  role: undefined,
  offer: null,
  language: 'en',
  companyName: '',
  useCase: undefined,
  feedbackType: [],
  onboardingStep: 0,

  setFirstName: firstName => set(state => ({ ...state, firstName })),
  setLastName: lastName => set(state => ({ ...state, lastName })),
  setRole: role => set(state => ({ ...state, role })),
  setOffer: offer => set(state => ({ ...state, offer })),
  setLanguage: language => set(state => ({ ...state, language })),
  setCompanyName: companyName => set(state => ({ ...state, companyName })),
  setUseCase: useCase => set(state => ({ ...state, useCase })),
  toggleFeedbackType: feedbackType =>
    set(state => ({
      ...state,
      feedbackType: state.feedbackType.includes(feedbackType)
        ? state.feedbackType.filter(type => type !== feedbackType)
        : [...state.feedbackType, feedbackType]
    })),
  setOnboardingStep: step => set(state => ({ ...state, onboardingStep: step }))
}))

export default useSignupStore
