import Button from '@/components/atoms/button'
import { DeleteDialog } from '@/components/atoms/dialog'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { REFRESH_THEMES_MAX_DELAY } from '@/hooks/useClassification'
import useHideIntercom from '@/hooks/useHideIntercom'
import useSegment from '@/hooks/useSegment'
import useUser from '@/hooks/useUser'
import { useKeywordsStore, useUIStore } from '@/store'
import useToastMessageStore from '@/store/useToastMessageStore'
import useTopicsStore from '@/store/useTopicsStore'
import { CaretDown, GitMerge, Lightning, Plus, TrashSimple, X } from '@phosphor-icons/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { shallow } from 'zustand/shallow'
import { OnApplyCallback } from '../../topic-group-editor/TopicGroupEditor'
import MergeTopicsModal from './merge/MergeTopicsModal'
import TopicService from '@/services/TopicService'
import TopicGroupEditorPopover from '../../topic-group-editor/TopicGroupEditorPopover'
import { useTopics } from '@/hooks/useTopics/useTopics'
import { TopicData, TopicThemeItem } from '@/types/classification'
import Popover from '@/components/atoms/popover'
import Checkbox from '@/components/atoms/checkbox'
import { ActionsBar, CheckedTopicsTrigger } from './TopicsActionBar.styles'
import useLogging from '@/hooks/useLogging'
import { makeUniqueArray } from '@/utils/array'

const TopicsActionBar = () => {
  const { track } = useSegment()

  const { classificationCollapsed } = useUIStore()
  const { userPermissions } = useUser()
  const isManager = userPermissions.topic.includes('manager')
  const canViewFindings = userPermissions.topic.includes('findings')

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showMergeTopicsModal, setShowMergeTopicsModal] = useState(false)
  const [showCheckedTopics, setShowChecktedTopics] = useState(false)

  const reasonsKeywordHashes = useKeywordsStore(state => state.reasonsKeywordHashes)

  const { checkedTopics } = useTopicsStore(
    state => ({
      checkedTopics: state.checkedTopics
    }),
    shallow
  )

  const { loadTopicGroups, removeTopic } = useTopics()

  const setCheckedTopics = useTopicsStore(state => state.setCheckedTopics)
  const setTopicGroupInTopic = useTopicsStore(state => state.setTopicGroupInTopic)
  const setTopicsToReasons = useTopicsStore(state => state.setTopicsToReasons)
  const removeTopicGroupFromTopic = useTopicsStore(state => state.removeTopicGroupFromTopic)

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addWarningToast = useToastMessageStore(state => state.addWarningToast)
  const setSafeAreaOn = useToastMessageStore(state => state.setSafeAreaOn)

  const { logException } = useLogging({ context: 'topics-action-bar' })

  const onClickClose = () => {
    setCheckedTopics([])
  }

  const onConfirmDelete = async () => {
    try {
      setShowDeleteDialog(false)
      removeTopic(checkedTopics)

      setTimeout(() => {
        setCheckedTopics([])
        loadTopicGroups(false)
      }, REFRESH_THEMES_MAX_DELAY)
    } catch (error) {
      console.error(error)
      const message = 'Failed to delete topics.'
      logException(error, { message })
      addErrorToast({ text: message })

      setShowDeleteDialog(false)
    }
  }

  const appliedGroups = useMemo(() => {
    return makeUniqueArray(
      'themeId',
      checkedTopics
        .filter(topic => !!topic.themeList)
        .flatMap(topic => topic.themeList) as TopicThemeItem[]
    )
  }, [checkedTopics])

  const onApplyAddToTopic = useCallback<OnApplyCallback>(
    async (selectedGroups, removedGroups) => {
      if (!selectedGroups.length && !removedGroups.length) {
        addWarningToast({ text: 'You need to add or remove a group.' })
        return 'error'
      }
      const removeToast = addLoadingToast({
        text: 'Applying changes to topic...'
      })

      try {
        await Promise.all(
          checkedTopics.map(async topic => {
            await Promise.all(
              selectedGroups.map(async group => {
                await TopicService.addThemeRelation(topic.topicId, group)
                setTopicGroupInTopic(topic.topicId, group)
              })
            )
          })
        )

        await Promise.all(
          checkedTopics.map(async topic => {
            await Promise.all(
              removedGroups.map(async group => {
                await TopicService.removeThemeRelation(topic.topicId, group)
                removeTopicGroupFromTopic(topic.topicId, group)
              })
            )
          })
        )

        setTimeout(() => {
          loadTopicGroups(false)
          removeToast()
          addSuccessToast({
            text: 'All changes applied.'
          })
          setCheckedTopics([])
        }, REFRESH_THEMES_MAX_DELAY)

        return 'success'
      } catch (error) {
        console.error(error)
        removeToast()
        const message = 'Failed to apply all changes.'
        logException(error, { message })
        addErrorToast({ text: message })

        return 'error'
      }
    },
    [
      checkedTopics,
      addErrorToast,
      addLoadingToast,
      addSuccessToast,
      addWarningToast,
      loadTopicGroups,
      logException,
      removeTopicGroupFromTopic,
      setCheckedTopics,
      setTopicGroupInTopic
    ]
  )

  const onViewReasonsClick = () => {
    setTopicsToReasons(checkedTopics)
    track('explore_reason_view_list')
  }

  const hideIntercom = useHideIntercom()

  useEffect(() => {
    if (checkedTopics.length) {
      hideIntercom(true)
    } else {
      hideIntercom(false)
    }

    return () => hideIntercom(false)
  }, [checkedTopics.length, hideIntercom])

  const isOpen = Boolean(checkedTopics.length && !reasonsKeywordHashes.length)

  useEffect(() => {
    setSafeAreaOn(isOpen)
  }, [isOpen, setSafeAreaOn])

  const onCheckedChange = (topic: TopicData) => (checked: boolean) => {
    if (!checked)
      setCheckedTopics(checkedTopics.filter(checkedTopic => checkedTopic.topicId !== topic.topicId))
  }

  if (!isOpen) return <></>

  return (
    <>
      <ActionsBar expanded={classificationCollapsed}>
        <FlexContainer alignItems="center" gap="xxs">
          {isManager ? (
            <>
              <TopicGroupEditorPopover appliedGroups={appliedGroups} onApply={onApplyAddToTopic}>
                <Text as="p" color="white" typeface="textSemiboldSmall">
                  <Plus size={16} weight="bold" />
                  Add to topic group
                </Text>
              </TopicGroupEditorPopover>
              <Text
                as="p"
                color="white"
                onClick={() => setShowDeleteDialog(true)}
                typeface="textSemiboldSmall"
              >
                <TrashSimple size={16} weight="bold" />
                Delete
              </Text>
            </>
          ) : null}

          {canViewFindings && (
            <Text as="p" color="white" onClick={onViewReasonsClick} typeface="textSemiboldSmall">
              <Lightning weight="fill" />
              <span>View findings</span>
            </Text>
          )}

          {checkedTopics.length > 1 && (
            <Button onClick={() => setShowMergeTopicsModal(true)} size="small" variant="exception">
              <GitMerge weight="fill" />
              <span>Merge</span>
            </Button>
          )}
        </FlexContainer>
        <FlexContainer alignItems="center" css={{ flex: 1 }} gap="xxs" justifyContent="flexEnd">
          <Popover
            buttonChildren={
              <CheckedTopicsTrigger alignItems="center" justifyContent="spaceBetween">
                <Text as="p" color="white" fontSize="micro" fontWeight="semibold" lineHeight="md">
                  {checkedTopics.length} selected
                </Text>
                <CaretDown size={16} weight="bold" />
              </CheckedTopicsTrigger>
            }
            customButton
            onOpenChange={setShowChecktedTopics}
            open={showCheckedTopics}
          >
            <FlexContainer
              css={{
                padding: '$xxs',
                width: 'var(--radix-popover-trigger-width)'
              }}
              direction="column"
              gap="xxs"
            >
              <Text
                as="p"
                color="neutralLowPure"
                fontSize="micro"
                fontWeight="semibold"
                lineHeight="md"
                typeface="textSemiboldSmall"
              >
                All selected topics
              </Text>
              <FlexContainer
                css={{ overflow: 'auto', maxHeight: 200 }}
                direction="column"
                gap="xxs"
              >
                {checkedTopics.map(topic => (
                  <Checkbox
                    checked
                    key={topic.topicId}
                    onCheckedChange={onCheckedChange(topic)}
                    text={topic.topicName}
                    value={topic.topicId}
                  />
                ))}
              </FlexContainer>
              <Button
                css={{ alignSelf: 'flex-end', color: '$neutralLowPure' }}
                onClick={onClickClose}
                size="small"
                variant="flat"
              >
                Clear all
              </Button>
            </FlexContainer>
          </Popover>
          <Text
            as="p"
            color="white"
            css={{ cursor: 'pointer' }}
            onClick={onClickClose}
            typeface="textSemiboldSmall"
          >
            <X size={16} weight="bold" />
          </Text>
        </FlexContainer>
      </ActionsBar>

      {showMergeTopicsModal && (
        <MergeTopicsModal
          onOpenChange={setShowMergeTopicsModal}
          onSuccesMerge={onClickClose}
          open={showMergeTopicsModal}
        />
      )}

      <DeleteDialog
        confirmText="Delete"
        description="This action is definitive. Deleting will permanently dissociate the topic with their feedback. Please proceed with caution."
        isDeleting={false}
        onConfirmDelete={onConfirmDelete}
        onOpenChange={setShowDeleteDialog}
        open={showDeleteDialog}
        title="Delete topic?"
      />
    </>
  )
}

export default TopicsActionBar
