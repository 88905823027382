import { CSS } from '@/theme'
import type * as Stitches from '@stitches/react'
import { useMemo } from 'react'
import { VolumeBarContainer, Bar } from './VolumeBar.styles'

type BarVariants = Stitches.VariantProps<typeof Bar>

export interface VolumeBarProps extends BarVariants {
  value: number
  max: number
  showNumber?: boolean
  css?: CSS
  containerCss?: CSS
  onClick?: React.MouseEventHandler<HTMLDivElement>
  clickable?: boolean
  showNumberOutside?: boolean
  valueLabel?: string | number
}

const VolumeBar: React.FC<VolumeBarProps> = (props: VolumeBarProps) => {
  const width = useMemo(() => (props.value / props.max) * 100 + '%', [props.value, props.max])

  return (
    <VolumeBarContainer
      className="bar-container"
      clickable={props.clickable}
      css={{ ...props.containerCss }}
      isOutside={props.showNumberOutside}
      onClick={props.onClick}
    >
      <Bar
        className="bar"
        css={{ width, ...props.css }}
        fullRounded={props.fullRounded}
        height={props.height}
      >
        {props.showNumber && <span>{props.valueLabel ?? props.value}</span>}
      </Bar>
      {props.showNumberOutside && <span>{props.valueLabel ?? props.value}</span>}
    </VolumeBarContainer>
  )
}

VolumeBar.defaultProps = {
  showNumber: true
}

export default VolumeBar
