import * as HoverCard from '@radix-ui/react-hover-card'
import { styled } from '@/theme'
import { slideAndFadeOpen } from '@/theme/animations'

export const TooltipHoverCard = styled(HoverCard.Content, {
  borderRadius: 8,
  bAll: '$stroke',
  boxShadow: '$softShadow',
  width: 280,
  fontSize: 12,
  fontWeight: 400,
  ...slideAndFadeOpen
})

export const TooltipCardHeader = styled('header', {
  background: '$neutralHighMedium',
  color: '$neutralLowMedium',
  borderTopLeftRadius: 7,
  borderTopRightRadius: 7,

  display: 'grid',
  gridTemplateColumns: '4fr 1fr 1fr',
  alignItems: 'center',

  padding: '8px 12px',

  h4: {
    fontSize: 12,
    fontWeight: 700
  }
})

export const TooltipCardItem = styled('div', {
  bBottom: '$stroke',
  background: '$white',
  fontSize: 12,
  display: 'grid',
  gridTemplateColumns: '2fr 2fr 1fr 1fr',
  alignItems: 'center',
  padding: '8px 12px',
  gap: 8,

  '&:first-child': {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7
  },

  '&:last-child': {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
    borderBottom: 'none'
  }
})

export const MetricItem = styled('span', {
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  width: '100%',

  variants: {
    align: {
      right: {
        justifyContent: 'end'
      },
      left: {
        justifyContent: 'start'
      }
    },

    type: {
      number: {
        color: '$neutralLowPure'
      },
      percentage: {
        color: '$neutralLowLight'
      },
      positive: {
        color: '$green'
      },
      neutral: {
        color: '$neutralLowLight'
      },
      negative: {
        color: '$red'
      }
    }
  },

  defaultVariants: {
    align: 'right',
    type: 'neutral'
  }
})
