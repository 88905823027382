import useUniqueAdvancedFilter from '@/hooks/advancedFilters/useUniqueAdvancedFilter'
import useDebounce from '@/hooks/useDebounce'
import { capitalizeFirst } from '@/utils/letterCase'
import { useState } from 'react'
import { FilterItemDropdown } from '../../AppliedFilters/FilterItem'
import SearchInputForFilters from '../../SearchInputFilter'
import { StringFilterContent } from '../../FiltersSidebar/StringGenericFilter'
import FiltersSkeleton from '../../FiltersSidebar/AddFilterMenu/FiltersSkeleton'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

interface Props {
  title: string
  name: string
  path: string
  value: string[]
  index: number
  disabled?: boolean
}

const UniqueFilterApplied = ({ title, name, path, value, index, disabled = false }: Props) => {
  const [open, setOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const { t } = useTranslation()

  const debouncedSearch = useDebounce(searchText, 350)

  const {
    update,
    fetchNextPage,
    hasNextPage,
    isChecked,
    isFetchingNextPage,
    isLoading,
    isShowMoreDisabled,
    options,
    selectOption
  } = useUniqueAdvancedFilter({
    name,
    path,
    enableQuery: open,
    searchText: debouncedSearch,
    initialValue: value,
    index
  })

  const onChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setSearchText(event.currentTarget.value)
  }

  const onClear = () => {
    setSearchText('')
  }

  const count = value.length
  const valueTextArray = value.map(v => `<b>${capitalizeFirst(v)}</b>`).join(t('or'))
  // const text = `Is ${valueTextArray}`
  const text = i18n.t('isValueTextArray', { valueTextArray })

  return (
    <FilterItemDropdown
      buttonDisabled={disabled}
      content={count}
      contentType="count"
      disabled={isLoading || disabled}
      dropdown={
        <>
          <SearchInputForFilters
            css={{ my: '$micro' }}
            onChange={onChange}
            onClear={onClear}
            placeholder={t('search')}
            size="small"
            small
            value={searchText}
          />
          <StringFilterContent
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isChecked={isChecked}
            isFetchingNextPage={isFetchingNextPage}
            isShowMoreDisabled={isShowMoreDisabled}
            options={options}
            selectOption={selectOption}
          />
          {isLoading && <FiltersSkeleton />}
        </>
      }
      onApply={update}
      onOpenChange={setOpen}
      open={open}
      title={title}
      tooltip={text}
    />
  )
}

export default UniqueFilterApplied
