import { IterationsData, UserCalibration } from '@/types/opportunity/OpportunityRequests'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

export type RoundNumber = 0 | 1 | 2 | 3

interface OpportunityCalibrationState {
  currentUserCalibration: UserCalibration | undefined
  setCurrentUserCalibration: (data: UserCalibration) => void

  currentRound: RoundNumber
  setCurrentRound: () => void

  evaluatedRound: number
  setEvaluatedRound: () => void

  suggestedDescription: string | undefined
  setSuggestedDescription: (data: string) => void

  iterationsData: IterationsData | undefined
  setIterationsData: (data: IterationsData | undefined) => void

  resetStore: () => void
}

const useOpportunityCalibrationStore = createWithEqualityFn<OpportunityCalibrationState>(
  set => ({
    currentUserCalibration: undefined,
    setCurrentUserCalibration: data =>
      set(state => ({
        currentUserCalibration: state.currentUserCalibration
          ? [...state.currentUserCalibration, ...data]
          : data
      })),

    currentRound: 0,
    setCurrentRound: () =>
      set(state => ({
        currentRound: state.currentRound < 3 ? ((state.currentRound + 1) as RoundNumber) : 0
      })),

    evaluatedRound: 0,
    setEvaluatedRound: () =>
      set(state => ({
        evaluatedRound: state.evaluatedRound + 1
      })),

    suggestedDescription: undefined,
    setSuggestedDescription: data => set({ suggestedDescription: data }),

    iterationsData: undefined,
    setIterationsData: data => set({ iterationsData: data }),

    resetStore: () =>
      set({
        currentUserCalibration: undefined,
        currentRound: 0,
        suggestedDescription: undefined,
        iterationsData: undefined,
        evaluatedRound: 0
      })
  }),
  shallow
)

export default useOpportunityCalibrationStore
