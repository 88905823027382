import FlexContainer from '@/components/atoms/flex-container'
import ToastMessagesProvider from '@/components/molecules/ToastMessagesProvider'
import Discovery from '@/components/molecules/discovery'
import FiltersHeader from '@/components/molecules/filters/FiltersHeader'
import NoPermission from '@/components/molecules/no-permission/NoPermission'
import useClassification from '@/hooks/useClassification'
import useUser from '@/hooks/useUser'
// import useSegment from '@/hooks/useSegment'
import { useUIStore } from '@/store'
import { styled } from '@/theme'
import { useEffect } from 'react'

const DiscoveryContainer = styled(FlexContainer, {
  background: 'transparent',
  height: '100%'
})

function MergeFindingsPage() {
  // const { track } = useSegment()
  const { verifyAllTopicAndThemes } = useClassification()
  const { setPageTitle } = useUIStore()

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    // track('explore_user_feedback_tab')
    setPageTitle('Merge Findings (be careful)')
    verifyAllTopicAndThemes()
  }, [])

  const { userPermissions } = useUser()
  if (!userPermissions.topic.includes('findings')) {
    return <NoPermission />
  }

  return (
    <FlexContainer direction="column" fullHeight>
      <FiltersHeader searchPlaceholder="Search to discover" />
      <DiscoveryContainer direction="column" fullHeight>
        <Discovery showMergeFindingsCheckbox />
        <ToastMessagesProvider />
      </DiscoveryContainer>
    </FlexContainer>
  )
}

export default MergeFindingsPage
