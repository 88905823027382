import { CSSProperties, memo, useEffect, useState } from 'react'
import FeedbackItem, { FeedbackItemProps } from '../../feedback-item'

interface Props extends FeedbackItemProps {
  style: CSSProperties
  index: number
  setRowHeight: (index: number, height: number) => void
}

const ChatFeedbackRow = ({ style, index, setRowHeight, ...props }: Props) => {
  const [rowRef, setRowRef] = useState<HTMLElement | null>(null)

  // biome-ignore lint/correctness/useExhaustiveDependencies: only when rowRef is set
  useEffect(() => {
    if (rowRef) {
      setRowHeight(index, rowRef.clientHeight)
    }
  }, [rowRef])

  return (
    <div className="scroll-on-hover-child" style={style}>
      <FeedbackItem
        {...props}
        css={{ ...props.css, minHeight: 120 }}
        index={index}
        ref={setRowRef}
      />
    </div>
  )
}

export default memo(ChatFeedbackRow)
