import { ThemeRequests, Topic } from '@/types/classification'
import { ExportThemeResult, ThemeItemData, TopicTheme } from '@/types/classification/Theme'
import { RawSentimentMetrics } from '@/types/classification/ThemeRequests'
import Request from './Request'
import NewRequest from './RequestHandlers/NewRequest'

const sortByName = (a: { name: string }, b: { name: string }) =>
  a.name.toLowerCase().localeCompare(b.name.toLowerCase())

const parseSentimentMetrics = (metrics: RawSentimentMetrics): SentimentMetrics => ({
  positiveCount: metrics.positive_count,
  neutralCount: metrics.neutral_count,
  negativeCount: metrics.negative_count,
  positivePercentage: metrics.positive_percent,
  neutralPercentage: metrics.neutral_percent,
  negativePercentage: metrics.negative_percent,
  netSentiment: metrics.net_sentiment
})

export default class ThemeService {
  static PREFIX = '/themes'

  static async list() {
    const themes = (await Request.get<ThemeRequests.ListResponse[]>(this.PREFIX))
      .map(
        (theme): TopicTheme => ({
          name: theme.name,
          description: theme.description,
          themeId: theme.theme_id,
          frequency: theme.feedback_count,
          topics: theme.topics.sort(sortByName).map(
            (topic): Topic => ({
              topicId: topic.topic_id,
              name: topic.name,
              description: topic.description,
              frequency: topic.feedback_count
            })
          )
        })
      )
      .sort(sortByName)

    return themes
  }

  static async listThemes(payload: ThemeRequests.ThemesPayload) {
    const themes = (
      await Request.post<ThemeRequests.ThemesResponse>(this.PREFIX + '/filters', payload)
    )
      .map(
        (theme): ThemeItemData => ({
          themeId: theme.theme_id || null,
          themeName: theme.theme_name,
          frequency: theme.frequency,
          sentimentMetrics: parseSentimentMetrics(theme.sentiment_metrics),
          topics:
            theme?.topics
              ?.map(topic => ({
                topicId: topic.topic_id,
                topicName: topic.topic_name,
                frequency: topic.frequency,
                sentimentMetrics: parseSentimentMetrics(topic.sentiment_metrics),
                isMerged: topic.is_merged,
                mergedTopicList: topic.merged_topic_list || []
              }))
              .sort((a, b) => a.topicName.toLowerCase().localeCompare(b.topicName.toLowerCase())) ??
            []
        })
      )
      .sort((a, b) => a.themeName.toLowerCase().localeCompare(b.themeName.toLowerCase()))

    return themes
  }

  static async create(payload: ThemeRequests.CreatePayload) {
    return await NewRequest.post<ThemeRequests.CreateResponse>(this.PREFIX, payload)
  }

  static async getOne(themeId: string) {
    return await Request.get<ThemeRequests.GetOneResponse>(`${this.PREFIX}/${themeId}`)
  }

  static async update({ themeId, ...payload }: ThemeRequests.UpdatePayload) {
    return await NewRequest.put<ThemeRequests.UpdatePayload>(`${this.PREFIX}/${themeId}`, payload)
  }

  static async remove(themeId: string) {
    return await Request.del(`${this.PREFIX}/${themeId}`)
  }

  static async export(): Promise<ExportThemeResult[]> {
    return (await Request.get<ExportThemeResult[]>(`${this.PREFIX}/export`)).map(item => ({
      theme: item.theme,
      topic: item.topic,
      keyword: item.keyword
    }))
  }
}
