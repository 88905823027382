import Button from '@/components/atoms/button'
import Dialog, { DialogProps } from '@/components/atoms/dialog'
import { ReactNode } from 'react'
import { DialogButtons, DialogDescription } from './Dialog.styles'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { WarningCircle } from '@phosphor-icons/react'
import Divider from '../divider'
import FlexContainer from '../flex-container'

interface Props {
  open?: boolean
  onConfirmDelete: () => void
  isDeleting: boolean
  onOpenChange?: (open: boolean) => void
  title?: ReactNode
  description?: ReactNode
  confirmText?: string
  cancelText?: string
  modal?: boolean
  width?: DialogProps['width']
  onClose?: () => void
  onCancelClick?: () => void
}

const DeleteDialog = ({
  open,
  onConfirmDelete,
  isDeleting,
  onOpenChange,
  title = 'Are you sure?',
  description,
  confirmText = 'Yes',
  cancelText = 'Cancel',
  modal = true,
  width,
  onCancelClick,
  onClose
}: Props) => {
  const titleWithIcon = (
    <FlexContainer alignItems="center" gap="nano">
      <WarningCircle size={24} /> {title}
    </FlexContainer>
  )

  return (
    <Dialog
      align="center"
      closeIcon
      modal={modal}
      onOpenChange={onOpenChange}
      open={open}
      title={titleWithIcon}
      width={width}
      onClose={onClose}
    >
      <FlexContainer direction="column" gap="xxs">
        <DialogDescription deleteDialog>{description}</DialogDescription>
        <Divider line="solid" orientation="horizontal" />
        <DialogButtons>
          <DialogPrimitive.Close asChild>
            <Button disabled={isDeleting} onClick={onConfirmDelete} size="small" variant="danger">
              {isDeleting ? 'Removing...' : confirmText}
            </Button>
          </DialogPrimitive.Close>
          <DialogPrimitive.Close asChild>
            <Button
              css={{ color: '$neutralLowPure' }}
              onClick={onCancelClick}
              size="small"
              variant="flat"
            >
              {cancelText}
            </Button>
          </DialogPrimitive.Close>
        </DialogButtons>
      </FlexContainer>
    </Dialog>
  )
}

export default DeleteDialog
