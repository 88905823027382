import { useCallback, useEffect, useRef, useState } from 'react'

import { Feedback } from '@/types/feedbacks'

import { feedbackItemCSS } from '../../feed/feedbacks/Feedbacks.styles'
import useChatFeedback from '@/hooks/feedback/useChatFeedback'
import { VariableSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import ChatFeedbackRow from './ChatFeedbackRow'
import { useLocation } from 'react-router-dom'

interface Props {
  isVisible?: boolean
  disableAutomaticLoad?: boolean
  openThreadModal: (feedback: Feedback) => void
}

function ChatFeedbacksList({ disableAutomaticLoad = false, isVisible, openThreadModal }: Props) {
  const {
    feedbacks,
    selectedFeedback,
    isHighlighted,
    getFeedbackItemKey,
    archive,
    unarchive,
    setSelectedFeedback
  } = useChatFeedback({ disableAutomaticLoad })

  const { hash } = useLocation()

  const [listRef, setListRef] = useState<List<Feedback[]> | null>(null)
  const rowHeights = useRef<Record<number, number>>({})

  const highlightFeedbackLazy = useCallback(() => {
    if (hash.length) {
      const highlightedIndex = Number(hash.replace('#', ''))
      // the chat uses index + 1 already
      listRef?.scrollToItem(highlightedIndex - 1, 'start')

      // the browser would reach to the feedback before this useEffect runs, but sometimes the feedback isnt in the DOM yet
      // because of react window
      // wait a little and scroll to the feedback again
      const timeoutId = setTimeout(() => {
        const feedbackElement = document.getElementById(`${highlightedIndex}`)
        if (feedbackElement) {
          feedbackElement.scrollIntoView({ behavior: 'smooth' })
        }
      }, 100)

      return () => {
        if (timeoutId) clearTimeout(timeoutId)
      }
    }
  }, [hash, listRef])

  useEffect(() => {
    return highlightFeedbackLazy()
  }, [highlightFeedbackLazy])

  useEffect(() => {
    if (isVisible) {
      const timeoutId = setTimeout(highlightFeedbackLazy, 100)

      return () => {
        if (timeoutId) clearTimeout(timeoutId)
      }
    }
  }, [isVisible, highlightFeedbackLazy])

  function getRowHeight(index: number) {
    return rowHeights.current[index] + 24 || 120
  }

  function setRowHeight(index: number, size: number) {
    listRef?.resetAfterIndex(0)
    rowHeights.current = { ...rowHeights.current, [index]: size + 2 }
  }

  return (
    <AutoSizer disableWidth>
      {({ height }) => (
        <List
          className="scroll-on-hover-container"
          height={height}
          itemCount={feedbacks.length}
          itemData={feedbacks}
          itemKey={(index, data) => data[index].feedbackId}
          itemSize={getRowHeight}
          overscanCount={4}
          ref={setListRef}
          width="100%"
        >
          {({ data, index, style }) => (
            <ChatFeedbackRow
              archived={false}
              css={feedbackItemCSS}
              enableHighlightOnFocus
              index={index}
              isHighlighted={isHighlighted(index + 1)}
              key={getFeedbackItemKey(data[index])}
              onArchive={archive}
              onRepliesClick={() => openThreadModal(data[index])}
              onSelect={setSelectedFeedback}
              onUnarchive={unarchive}
              selectedId={selectedFeedback?.feedbackId}
              setRowHeight={setRowHeight}
              showAccountsData
              style={style}
              {...data[index]}
            />
          )}
        </List>
      )}
    </AutoSizer>
  )
}

export default ChatFeedbacksList
