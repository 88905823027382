import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import { useMemo, useState } from 'react'
import { FilterItemDropdown } from '../../AppliedFilters/FilterItem'
import { TextFilterContent } from '../../FiltersSidebar/TextGenericFilter'
import { FilterValueStatus } from '@/types/filters/AdvancedFilters'
import useQueryValidation from '@/hooks/useQueryValidation'

interface Props {
  title: string
  name: string
  path: string
  value: string
  index: number
  disabled?: boolean
}

const TextFilterApplied = ({ title, name, path, value = '', index, disabled = false }: Props) => {
  const { updateFilter } = useAdvancedFilters()
  const [textInput, setTextInput] = useState(value)

  const [status, setStatus] = useState<FilterValueStatus>('valid')
  const onValueChange = (value: string) => {
    setTextInput(value)
    setStatus('validating')
  }

  useQueryValidation(isValid => {
    setStatus(isValid ? 'valid' : 'invalid')
  }, textInput)

  const error = useMemo(() => (status === 'invalid' ? 'Invalid text.' : undefined), [status])

  const update = () => {
    updateFilter({
      index,
      filter: { type: 'text', name, value: textInput, path, status: 'valid' }
    })
  }

  const onClear = () => {
    setTextInput('')
  }

  const text = value || 'empty'

  return (
    <FilterItemDropdown
      buttonDisabled={disabled}
      content={text}
      contentType="label"
      disabled={disabled || status !== 'valid'}
      dropdown={
        <TextFilterContent
          error={error}
          onClearClick={onClear}
          onValueChange={onValueChange}
          value={textInput}
        />
      }
      onApply={update}
      title={title}
      tooltip={text}
    />
  )
}

export default TextFilterApplied
