import * as PopoverPrimitive from '@radix-ui/react-popover'
import { styled } from '@/theme'
import { slideAndFadeOpen } from '@/theme/animations'

export const PopoverIconButton = styled('button', {
  all: 'unset',
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  position: 'relative',
  // pAll: '$xxxs',
  height: 40,
  minWidth: 40,
  gap: '$nano',
  boxSizing: 'border-box',
  transition: '.2s',

  '&:disabled': {
    pointerEvents: 'none'
  },

  '&:hover': {
    color: '$brandPrimaryPure',
    background: '$brandPrimaryLight'
  },

  variants: {
    open: {
      true: {
        color: '$brandPrimaryPure',
        background: '$brandPrimaryLight'
      }
    },

    hasDetails: {
      true: {
        pAll: '$micro'
      }
    },

    active: {
      true: {
        background: '$pajaritoLight',
        color: '$pajarito'
      }
    },

    bordered: {
      true: {
        bAll: '$neutralHighPure'
      }
    },

    text: {
      true: {
        background: 'transparent',
        width: 'fit-content',

        '&:hover': {
          background: 'transparent'
        }
      }
    }
  },

  compoundVariants: [
    {
      open: true,
      bordered: false,
      css: {
        background: '$white',
        bAll: '$neutralHighPure',
        '&:hover': {
          background: '$greenLight'
        }
      }
    },
    {
      open: true,
      active: true,
      css: {
        background: '$pajarito',
        color: '$white',
        '&:hover': {
          color: '$white',
          background: '$pajaritoBrighter'
        }
      }
    },
    {
      open: true,
      text: true,
      css: {
        background: 'transparent',
        width: 'fit-content',
        '&:hover': {
          background: 'transparent'
        }
      }
    }
  ]
})

export const PopoverContent = styled(PopoverPrimitive.Content, {
  background: '$neutralHighLight',
  bAll: '$neutralHighPure',
  borderRadius: 16,
  position: 'relative',
  zIndex: 9999,
  boxShadow: '$softShadow',
  ...slideAndFadeOpen
})
