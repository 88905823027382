import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import MetricsTrendlineCard from './MetricsTrendlineCard'
import useSegment from '@/hooks/useSegment'
import { AllMetricsKey } from '@/types/metrics'
import { BreakdownOption } from '@/types/time-series/TimeSeries'
import { AnnotationType } from '@/types/annotations/Annotation'
import { useMemo } from 'react'
import useBasicAreaOfInterestQuery from '@/hooks/areaOfInterest/useBasicAreaOfInterestQuery'

const baseList: BreakdownOption[] = [
  'sentiment',
  'intention',
  'source_alias',
  'feedbackDetail',
  'customField',
  'account',
  'user',
  'satisfaction',
  'date'
]

const AreaMetricsTrendline = () => {
  const currentAreaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)

  const { areas, isLoading } = useBasicAreaOfInterestQuery()

  const breakdownList = useMemo(() => {
    let list: BreakdownOption[] = ['all', 'opportunity', ...baseList]
    if (areas.length === 0) {
      list = ['org', ...baseList]
    } else if (!currentAreaOfInterest) {
      list = ['org', 'areas', ...baseList]
    }

    return list
  }, [areas, currentAreaOfInterest])

  const defaultOption = useMemo(() => {
    let option: BreakdownOption = 'all'
    if (areas.length === 0) {
      option = 'org'
    } else if (!currentAreaOfInterest) {
      option = 'areas'
    }
    return option
  }, [areas, currentAreaOfInterest])

  const { track } = useSegment()
  const trackMetric = (metric: AllMetricsKey) => {
    track(currentAreaOfInterest ? 'area_trendline_metrics' : 'exploration_trendline_metrics', {
      selected_metric: metric
    })
  }

  const annotationType: AnnotationType = currentAreaOfInterest ? 'area' : 'organization'

  const trackCreateAnnotation = () => {
    track(
      currentAreaOfInterest
        ? 'area_trendline_add_annotation'
        : 'exploration_trendline_add_annotation'
    )
  }

  const trackCopyTable = () => {
    track(
      currentAreaOfInterest
        ? 'area_trendline_copy_data_table'
        : 'exploration_trendline_copy_data_table'
    )
  }

  return (
    <MetricsTrendlineCard
      annotationType={annotationType}
      breakdownList={breakdownList}
      defaultOption={defaultOption}
      onCopyTable={trackCopyTable}
      trackCreateAnnotation={trackCreateAnnotation}
      trackMetric={trackMetric}
      useAppliedFilters
      isAreasLoading={isLoading}
    />
  )
}
export default AreaMetricsTrendline
