import { Notepad } from '@phosphor-icons/react'
import Card from '../card/Card'
import { ReactNode } from 'react'
import Bone from '@/components/atoms/bone'
import { MarkdownText } from '@/components/atoms/markdown-text'

interface CardProps {
  children: ReactNode
  title: string
}
const SummaryCard = ({ children, title }: CardProps) => (
  <Card.Root alignItems="start" color="brandPrimaryPure" direction="column" gap="xxs">
    <Card.Header>
      <Card.IconBox>
        <Notepad />
      </Card.IconBox>
      <Card.Title>{title}</Card.Title>
    </Card.Header>
    {children}
  </Card.Root>
)

interface SummarySkeletonProps {
  title: string
}

export const SummarySkeleton = ({ title }: SummarySkeletonProps) => (
  <SummaryCard title={title}>
    <Bone css={{ height: 24, width: '100%' }} />
    <Bone css={{ height: 24, width: '100%' }} />
    <Bone css={{ height: 24, width: '70%' }} />
  </SummaryCard>
)

interface Props {
  text: string
  title: string
}
const TopicSummary = ({ text, title }: Props) => {
  return (
    <SummaryCard title={title}>
      <MarkdownText>{text}</MarkdownText>
    </SummaryCard>
  )
}

export default TopicSummary
