import { InputHTMLAttributes, MouseEventHandler, useState } from 'react'
import { VariantProps } from '@stitches/react'
import Input from './Input'
import { styled } from '@/theme'
import { Eye, EyeSlash } from '@phosphor-icons/react'
import FlexContainer from '../flex-container'

const TogglePassword = styled('button', {
  all: 'unset',
  position: 'absolute',
  top: 40,
  mr: '$micro',
  pr: '$nano',
  pl: '$nano',
  cursor: 'pointer',
  color: '$neutralLowLight',

  '&:focus-visible': {
    outline: '2px solid $brandPrimaryPure',
    borderRadius: 4
  }
})

type PasswordInputProps = VariantProps<typeof Input> &
  InputHTMLAttributes<HTMLInputElement> & {
    toggleTabIndex?: number
  }

const PasswordInput = (props: PasswordInputProps) => {
  const [isVisible, setIsVisible] = useState(false)

  const togglePassword: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault()
    setIsVisible(value => !value)
  }

  const icon = isVisible ? <Eye /> : <EyeSlash />
  const type = isVisible ? 'text' : 'password'

  return (
    <FlexContainer alignItems="center" justifyContent="flexEnd">
      <Input {...props} css={{ pr: '$sm', position: 'relative' }} type={type} />
      <TogglePassword onClick={togglePassword} tabIndex={props.toggleTabIndex}>
        {icon}
      </TogglePassword>
    </FlexContainer>
  )
}

export default PasswordInput
