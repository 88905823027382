import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'

const DropdownTriggerButton = styled('button', {
  all: 'unset',
  boxSizing: 'border-box',
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '$xxs',
  padding: '$xxxs $xxs',
  cursor: 'pointer',
  br: '$micro',

  color: '$neutralLowPure',

  '&:hover, &[data-state="open"]': {
    color: '$brandPrimaryPure'
  },

  variants: {
    variant: {
      'white-bordered': {
        bc: '$neutralHighLight',
        color: '$neutralLowPure',
        bAll: '$neutralHighPure',
        '&:hover': {
          color: '$brandPrimaryPure',
          backgroundColor: '$brandPrimaryHover'
        },
        '&:disabled': {
          color: '$neutralLowLight'
        },

        '&[data-state="open"]': {
          color: '$brandPrimaryPure',
          backgroundColor: '$brandPrimaryHover'
        }
      },
      'white-bordered-active': {
        bAll: '$neutralHighPure',
        color: '$brandPrimaryPure',
        bc: '$brandPrimaryHover',
        '&:disabled': {
          color: '$neutralLowLight'
        },

        '&[data-state="open"]': {
          color: '$brandPrimaryPure',
          backgroundColor: '$brandPrimaryHover'
        }
      }
    }
  }
})

const Container = styled(FlexContainer, {
  flexDirection: 'column',
  width: 480,
  br: '$micro',
  height: '100%',
  gap: '$xs',
  padding: '$xxs'
})

const ScrollContainer = styled(FlexContainer, {
  flex: 1,
  overflowY: 'auto',
  flexDirection: 'column',
  minHeight: 320,
  maxHeight: '62vh',
  width: '100%',
  height: '100%',
  gap: '$xs'
})

export default {
  ScrollContainer,
  Container,
  DropdownTriggerButton
}
