import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import type { TableV2Props } from '@/components/atoms/table-v2'
import { styled } from '@/theme'
import { ReactNode } from 'react'

const Cell = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '$xxs',
  pt: 'calc($xxs + 3px)'
})

const NameCell = styled(Cell, {
  justifyContent: 'flex-start !important',
  span: {
    textAlign: 'start'
  }
})

const TableButton = ({
  onClick,
  text,
  icon
}: { onClick: () => void; text: string; icon: ReactNode }) => (
  <FlexContainer alignItems="center" justifyContent="flexEnd">
    <Button
      css={{ fontWeight: '$regular' }}
      onClick={onClick}
      size={'small'}
      variant="flat-bordered"
    >
      {icon}
      {text}
    </Button>
  </FlexContainer>
)

const thContainerProps: TableV2Props['thContainerProps'] = header => {
  if (header.id === 'name') {
    return {
      justifyContent: 'flexStart',
      css: { bc: '$neutralHighLight !important' }
    }
  }
  return {
    css: { bc: '$brandPrimaryLight !important' }
  }
}

const thCss: TableV2Props['thCss'] = header => {
  if (header.id === 'name') {
    return {
      justifyContent: 'flex-start !important',
      bc: '$neutralHighLight !important',
      padding: '$xxs',
      pt: 'calc($xxs + 3px)',
      pl: '0px !important'
    }
  }
  return {
    bc: '$brandPrimaryLight !important',
    padding: '$xxs',
    pt: 'calc($xxs + 3px)'
  }
}

const tdCss: TableV2Props['tdCss'] = cell => {
  if (cell.column.id === 'name') {
    return {
      bc: '$brandPrimaryLight !important',
      color: '$brandPrimaryPure'
    }
  }
  return {}
}

const TimeseriesTableStyles = {
  tdCss,
  thCss,
  thContainerProps,
  Cell,
  TableButton,
  NameCell
}

export default TimeseriesTableStyles
