import { forwardRef } from 'react'
import { CSS, styled } from '@/theme'
import { VariantProps } from '@stitches/react'
import { IconContext } from '@phosphor-icons/react'

const StyledButton = styled('button', {
  $$border: '1px',
  alignItems: 'center',
  border: '$$border solid transparent',
  borderRadius: 8,
  cursor: 'pointer',
  display: 'flex',
  fontFamily: 'inherit',
  fontSize: '$xxxs',
  fontWeight: '$semibold',
  gap: '$micro',
  height: 'fit-content',
  justifyContent: 'center',
  lineHeight: '$xs',
  transition: '.2s',
  width: 'max-content',
  outline: 'none',

  '&:disabled': {
    pointerEvents: 'none'
  },

  variants: {
    variant: {
      primary: {
        backgroundColor: '$brandPrimaryPure',
        color: '$neutralHighLight',

        '&:hover': {
          backgroundColor: '$brandPrimaryMedium'
        },
        '&:disabled': {
          backgroundColor: '$neutralHighMedium',
          color: '$neutralLowLight'
        }
      },
      secondary: {
        backgroundColor: '$neutralHighLight',
        borderColor: '$brandPrimaryPure',
        color: '$brandPrimaryPure',

        '&:hover': {
          backgroundColor: '$brandPrimaryLight'
        },
        '&:disabled': {
          backgroundColor: '$neutralHighMedium',
          borderColor: '$neutralHighPure',
          color: '$neutralLowLight'
        }
      },
      flat: {
        backgroundColor: 'transparent',
        color: '$brandPrimaryPure',

        '&:hover': {
          backgroundColor: '$brandPrimaryLight'
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          color: '$neutralLowLight'
        }
      },
      exception: {
        backgroundColor: '$feedbackWarningPure',
        borderColor: '$brandPrimaryPure',
        color: '$brandPrimaryPure',

        '&:hover': {
          backgroundColor: '$feedbackWarningMedium'
        },
        '&:disabled': {
          backgroundColor: '$neutralHighMedium',
          borderColor: '$neutralLowLight',
          color: '$neutralLowLight'
        }
      },
      danger: {
        backgroundColor: '$feedbackNegativePure',
        color: '$neutralHighLight',

        '&:hover': {
          backgroundColor: '$feedbackNegativeMedium'
        },
        '&:disabled': {
          backgroundColor: '$neutralHighMedium',
          color: '$neutralLowLight'
        }
      },
      positive: {
        backgroundColor: '$feedbackPositivePure',
        color: '$neutralHighLight',

        '&:hover': {
          backgroundColor: '$feedbackPositiveMedium'
        },
        '&:disabled': {
          backgroundColor: '$neutralHighMedium',
          color: '$neutralLowLight'
        }
      },
      'flat-bordered': {
        backgroundColor: 'transparent',
        color: '$neutralLowPure',
        bAll: '$neutralHighPure',

        '&:hover': {
          color: '$brandPrimaryPure',
          backgroundColor: '$brandPrimaryLight'
        },
        '&:disabled': {
          color: '$neutralLowLight'
        },

        '&[data-state="open"]': {
          color: '$brandPrimaryPure',
          backgroundColor: '$brandPrimaryLight'
        }
      },
      'white-bordered': {
        bc: '$neutralHighLight',
        color: '$neutralLowPure',
        bAll: '$neutralHighPure',
        '&:hover': {
          color: '$brandPrimaryPure',
          backgroundColor: '$brandPrimaryHover'
        },
        '&:disabled': {
          color: '$neutralLowLight'
        },

        '&[data-state="open"]': {
          color: '$brandPrimaryPure',
          backgroundColor: '$brandPrimaryHover'
        }
      },
      'white-bordered-active': {
        bAll: '$neutralHighPure',
        color: '$brandPrimaryPure',
        bc: '$brandPrimaryHover',
        '&:disabled': {
          color: '$neutralLowLight'
        },

        '&[data-state="open"]': {
          color: '$brandPrimaryPure',
          backgroundColor: '$brandPrimaryHover'
        }
      },
      'tinted-bordered': {
        backgroundColor: 'transparent',
        color: '$brandPrimaryPure',
        bAll: '$brandPrimaryPure',

        '&:hover': {
          color: '$brandPrimaryPure',
          backgroundColor: '$brandPrimaryLight'
        },
        '&:disabled': {
          color: '$neutralLowLight'
        },

        '&[data-state="open"]': {
          color: '$brandPrimaryPure',
          backgroundColor: '$brandPrimaryLight'
        }
      },
      link: {
        backgroundColor: 'transparent',
        color: '$neutralLowPure',
        padding: '0px',
        height: 'fit-content',

        '&:hover': {
          color: '$brandPrimaryPure',
          background: 'transparent'
        },
        '&:disabled': {
          color: '$neutralHighPure'
        }
      }
    },

    size: {
      large: {
        padding: '$xxxs $xs'
      },
      small: {
        height: 32,
        padding: 'calc($micro - $$border) calc($xxs - $$border)'
      },
      micro: {
        fontSize: 12,
        padding: '8px 0',
        width: 'fit-content'
      },
      default: {
        height: 40,
        padding: 'calc($micro - $$border) calc($xxs - $$border)'
      },
      square: {
        size: 40
      },
      mediumSquare: {
        size: 32
      }
    },

    fontWeight: {
      regular: {
        fontWeight: '$regular'
      },
      semibold: {
        fontWeight: '$semibold'
      },
      bold: {
        fontWeight: '$bold'
      },
      extrabold: {
        fontWeight: '$extrabold'
      }
    },

    fullWidth: {
      true: {
        width: '100% !important'
      }
    },

    // older variants
    text: {
      true: {
        color: '$brandPrimaryPure',
        background: 'transparent',
        fontWeight: '$semibold',

        '&:hover': {
          background: '$brandPrimaryLight'
        },

        '&:disabled': {
          pointerEvents: 'none',
          color: '$pajaritoMuted',
          background: 'transparent'
        }
      }
    },

    small: {
      true: {
        width: 'fit-content',
        height: 32,
        padding: '$micro $xxs'
      }
    },

    micro: {
      true: {
        color: '$neutralLowPure',
        fontSize: 12,
        padding: 0,
        width: 'fit-content',

        '&:hover': {
          color: '$brandPrimaryPure',
          background: 'transparent'
        }
      }
    },

    deleteButton: {
      true: {
        '&:hover': {
          color: '$feedbackNegativePure !important',
          bc: '$feedbackNegativeLight !important',
          borderRadius: 4
        }
      }
    }
  },

  defaultVariants: {
    variant: 'primary',
    size: 'large',
    fullWidth: false,
    text: false
  }
})

export type ButtonProps = VariantProps<typeof StyledButton> &
  React.ButtonHTMLAttributes<HTMLButtonElement> & { css?: CSS; as?: string; href?: string }

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }: ButtonProps, ref) => (
    <IconContext.Provider value={{ weight: 'regular', size: 16 }}>
      <StyledButton {...props} ref={ref}>
        {children}
      </StyledButton>
    </IconContext.Provider>
  )
)

Button.displayName = 'Button'

Button.defaultProps = {
  disabled: false,
  text: false
}

export default Button
