import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'

const GRID_TEMPLATE = 'minmax(120px, 2.5fr) minmax(120px, 2.5fr) minmax(120px, 2fr) 40px'

export const TableContainer = styled(FlexContainer, {
  padding: '$xs',
  flexDirection: 'column',
  br: '$micro',
  bAll: '$neutralHighPure',
  overflow: 'hidden',
  position: 'relative'
})

export const TableBody = styled('div', {
  overflow: 'hidden auto'
})

export const HeaderRow = styled('div', {
  display: 'grid',
  gridTemplateColumns: GRID_TEMPLATE,

  px: '$xs',
  pb: '$micro',
  bBottom: '$neutralHighPure',
  gap: '$xxxs',
  mr: '$micro'
})

export const HeaderCell = styled('p', {
  fontSize: '$nano',
  fontWeight: '$bold',
  lineHeight: '$xl'
})

export const TableRow = styled('div', {
  display: 'grid',
  gridTemplateColumns: GRID_TEMPLATE,

  px: '$xs',
  py: '$xxxs',
  gap: '$xxxs',
  bBottom: '$neutralHighPure',
  mr: '$micro',

  '& .options': {
    opacity: 0
  },

  '&:hover': {
    '& .options': {
      opacity: 1
    }
  }
})

export const TableCell = styled('p', {
  fontSize: '$micro',
  fontWeight: '$regular',
  lineHeight: '$md',

  display: 'block',
  whiteSpace: 'nowrap',
  wordWrap: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
})
