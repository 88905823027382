import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Input, { InputErrorMessage } from '@/components/atoms/input'
import Select, { SelectOption } from '@/components/atoms/select'
import Text from '@/components/atoms/text'
import useSignupStore from '@/store/useSignupStore'
import { shallow } from 'zustand/shallow'
import { FormGroup } from './Signup.styles'
import Tooltip from '@/components/atoms/tooltip'
import { Info } from '@phosphor-icons/react'
import { OfferKey, offers, offersCode } from '@/utils/offers'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const OnboardingUser = () => {
  const { firstName, lastName, role, language, offer, companyName } = useSignupStore(
    state => ({
      firstName: state.firstName,
      lastName: state.lastName,
      role: state.role,
      offer: state.offer,
      language: state.language,
      companyName: state.companyName
    }),
    shallow
  )
  const { t } = useTranslation()

  const setFirstName = useSignupStore(state => state.setFirstName)
  const setLastName = useSignupStore(state => state.setLastName)
  const setRole = useSignupStore(state => state.setRole)
  const setOffer = useSignupStore(state => state.setOffer)
  const setLanguage = useSignupStore(state => state.setLanguage)
  const setCompanyName = useSignupStore(state => state.setCompanyName)

  const setOnboardingStep = useSignupStore(state => state.setOnboardingStep)

  const roleOptions: SelectOption<string>[] = [
    'Product Management',
    'Product Marketing',
    'UX',
    'Design',
    'Customer Support',
    'Customer Success',
    'Insights',
    'Engineering',
    'Executive',
    'Sales',
    'Operations',
    'Marketing',
    'Other'
  ].map(role => ({ value: role, text: role }))

  const languageOptions: SelectOption[] = [
    { text: t('english'), value: 'en' },
    { text: t('portugueseBrazil'), value: 'pt-br' },
    { text: t('spanishSpain'), value: 'es-es' }
  ]

  const isContinueDisabled = !(
    firstName.length > 0 &&
    lastName.length > 0 &&
    role &&
    companyName.length > 0 &&
    offer !== null
  )

  const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()
    setOnboardingStep(1)
  }

  const [isCodeInputWithError, setIsCodeInputWithError] = useState(false)

  const checkAndSetOffer = (value: string) => {
    let offerValue: string | null = null
    Object.entries(offersCode).forEach(([key, code]) => {
      if (code === value) {
        offerValue = offers[key as OfferKey]
        setIsCodeInputWithError(false)
      }
    })
    setOffer(offerValue)
  }

  const validateCode = () => {
    setIsCodeInputWithError(offer === null)
  }

  const tooltipText = (
    <p>
      {t(
        'inOrderToCreateANewBirdieAccountYouNeedAnInvitationCodeIfYouDontHaveOneTalkWithOurSalesTeamHere'
      )}
      <a href="https://birdie.ai/get-started-for-free/" rel="noreferrer" target="_blank">
        {t('here')}
      </a>
      .
    </p>
  )

  return (
    <FlexContainer as="form" css={{ width: 464 }} direction="column" gap="xs" onSubmit={onSubmit}>
      <Text as="h1" typeface="titleRegularSmall">
        {t('welcomeLetsSetupYourWorkspace')}
      </Text>

      <FlexContainer boxSizing="contentBox" css={{ marginTop: '$xxs', width: '100%' }} gap="xs">
        <FormGroup>
          <label htmlFor="onboardingFirstName">{t('firstName')}</label>
          <Input
            css={{ width: 'auto' }}
            id="onboardingFirstName"
            name="firstName"
            onChange={e => setFirstName(e.currentTarget.value)}
            small
            type="text"
            value={firstName}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="onboardingLastName">{t('lastName')}</label>
          <Input
            css={{ width: 'auto' }}
            id="onboardingLastName"
            name="lastName"
            onChange={e => setLastName(e.currentTarget.value)}
            small
            type="text"
            value={lastName}
          />
        </FormGroup>
      </FlexContainer>
      <FormGroup>
        <label>{t('yourRole')}</label>
        <Select
          onValueChange={setRole}
          options={roleOptions}
          placeholder="Select your role"
          position="item-aligned"
          value={role}
        />
      </FormGroup>
      <FormGroup>
        <FlexContainer alignItems="center" css={{ gap: 4 }}>
          <label htmlFor="inviteCode">{t('inviteCode')}</label>
          <Tooltip css={{ maxWidth: 350 }} side="right" text={tooltipText}>
            <Info size={16} weight="fill" />
          </Tooltip>
        </FlexContainer>

        <Input
          css={{ width: 'auto' }}
          error={isCodeInputWithError}
          id="inviteCode"
          name="inviteCode"
          onBlur={validateCode}
          onChange={e => checkAndSetOffer(e.currentTarget.value)}
          small
          type="text"
        />
        {isCodeInputWithError && (
          <InputErrorMessage css={{ position: 'relative', bottom: 4, height: 0, marginBottom: -8 }}>
            {t('invalidInviteCode')}
          </InputErrorMessage>
        )}
      </FormGroup>
      <FormGroup>
        <FlexContainer alignItems="center" css={{ gap: 4 }}>
          <label>Language</label>
          <Tooltip
            css={{ maxWidth: 232 }}
            side="right"
            text={t('chooseHowBirdieMustGenerateAutomaticContentSuchAsTopicsAndInsights')}
          >
            <Info size={16} weight="fill" />
          </Tooltip>
        </FlexContainer>
        <Select
          label="Language"
          onValueChange={setLanguage}
          options={languageOptions}
          position="item-aligned"
          value={language}
        />
      </FormGroup>
      <FormGroup>
        <label htmlFor="onboardingCompanyName">{t('yourCompanyName')}</label>
        <Input
          id="onboardingCompanyName"
          name="companyName"
          onChange={e => setCompanyName(e.currentTarget.value)}
          small
          type="text"
          value={companyName}
        />
      </FormGroup>

      <Button disabled={isContinueDisabled} type="submit" variant="exception">
        {t('continue')}
      </Button>
    </FlexContainer>
  )
}

export default OnboardingUser
