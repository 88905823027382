import { AllMetricsKey, GetColumnValueFn, MetricExtraContent, RawMetric } from '@/types/metrics'
import { baseAllMetricItems, baseMetricKeyToAllMetricsKeys } from './allMetrics'

export const parseBaseRawMetric: GetColumnValueFn = ({
  rawMetrics,
  groupKey,
  columKey,
  allMetricItems,
  metricKeyToAllMetricsKeys
}) => {
  const allowedKeys = (metricKeyToAllMetricsKeys ?? baseMetricKeyToAllMetricsKeys)[groupKey]
  if (!allowedKeys) return { currentValue: undefined, metric: undefined }
  if (!allowedKeys.includes(columKey)) return { currentValue: undefined, metric: undefined }
  if (rawMetrics?.length === 0) return { currentValue: null, metric: null }
  const rawMetric = rawMetrics?.find(
    metric => metric.label === (allMetricItems ?? baseAllMetricItems)[columKey]?.metric.label
  )

  if (!rawMetric) return { currentValue: undefined, metric: null }

  const currentValue = rawMetric.current_value
  const previousValue = rawMetric.previous_value
  const absVariation = rawMetric.absolute_variation
  const percentageVariation = rawMetric.percentage_variation

  return {
    currentValue,
    previousValue,
    absVariation,
    percentageVariation,
    metric: rawMetric,
    error: rawMetric.error
  }
}

export const getRawMetricExtra = ({
  rawMetric
}: {
  rawMetric: RawMetric
  metricKey: AllMetricsKey
}) => {
  if (!rawMetric.extra) return

  const extras: MetricExtraContent[] = []

  return extras
}
