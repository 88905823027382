import ComparisonTable from './ComparisonTable'
import { TimeSeriesChartProps } from '@/types/analytics/Compare'
import { useMemo } from 'react'
import getChartOptions from './chartOptions'
import { EChartOption } from 'echarts'
import EChart from '@/components/atoms/echart'
import { ChartType } from '@/types/dashboard'

const Chart = ({
  type,
  compare,
  intervalOption,
  metricOption,
  timeSeries,
  volumeBy
}: TimeSeriesChartProps & { type: ChartType }) => {
  const props = useMemo(
    () => ({ compare, intervalOption, metricOption, timeSeries, volumeBy }),
    [compare, intervalOption, metricOption, timeSeries, volumeBy]
  )

  const barChartOptions: EChartOption = useMemo(
    () =>
      getChartOptions({
        timeSeriesProps: props,
        xAxis: { boundaryGap: ['20%', '20%'] },
        series: props.timeSeries.length
          ? props.timeSeries.map(
              (serie): EChartOption.Series => ({
                data: serie.data.map(metrics =>
                  props.metricOption === 'count'
                    ? metrics.countFeedback
                    : metrics.percentageFeedback
                ),

                type: 'bar',
                barGap: '5%',
                barCategoryGap: '10%',
                barMaxWidth: 40,
                name: serie.fieldValue
              })
            )
          : [{ data: [], type: 'bar' }]
      }),
    [props]
  )

  const lineChartOptions: EChartOption = useMemo(
    () =>
      getChartOptions({
        timeSeriesProps: props,
        series: props.timeSeries.length
          ? props.timeSeries.map(
              (serie): EChartOption.Series => ({
                data: serie.data.map(metrics =>
                  props.metricOption === 'count'
                    ? metrics.countFeedback
                    : metrics.percentageFeedback
                ),

                type: 'line',
                name: serie.fieldValue,
                showSymbol: false
              })
            )
          : [{ data: [], type: 'line' }]
      }),
    [props]
  )

  const chartOptions = type === 'bar' ? barChartOptions : lineChartOptions

  if (type === 'table') {
    return <ComparisonTable {...props} />
  }

  return <EChart height="400px" key="timeSeriesComparison" option={chartOptions} />
}

export default Chart
