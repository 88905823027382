import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import { FilterItem } from '@/types/filters/Filters'
import { ReactNode } from 'react'
import { FilterSubMenuScrollArea } from './AddFilterMenu.styles'
import DatetimeFilterSection from './DatetimeFilterSection'
import FilterSubMenu from './FilterSubMenu'
import StringFilterSubMenu from '../StringGenericFilter/StringFilterSubMenu'
import NumericFilterSubMenu from '../NumericGenericFilter/NumericFilterSubMenu'

export interface GenericFiltersProps {
  filters: FilterItem[]
  isLoading?: boolean
  icon: ReactNode
  title: string
}

const FilterItemComponent = (props: FilterItem & { title: string; filterKey: string }) => {
  if (props.type.includes('datetime')) {
    return (
      <DatetimeFilterSection
        filterKey={props.filterKey}
        name={props.name}
        title={props.displayName}
        type={props.type}
      />
    )
  }

  if (props.type.includes('number') || props.type.includes('integer')) {
    return (
      <NumericFilterSubMenu
        filterKey={props.filterKey}
        name={props.name}
        title={props.displayName}
        type={props.type}
      />
    )
  }

  return (
    <StringFilterSubMenu
      filterKey={props.filterKey}
      name={props.name}
      previousTitle={props.title}
      title={props.displayName}
      type={props.type}
    />
  )
}

const GenericFiltersSection = ({
  filters,
  isLoading = false,
  icon,
  title
}: GenericFiltersProps) => {
  return (
    <FilterSubMenu compact hideButton icon={icon} isLoading={isLoading} title={title}>
      <FilterSubMenuScrollArea>
        <ScrollAreaViewport css={{ maxHeight: 320 }}>
          {filters.map(filter => (
            <FilterItemComponent
              {...filter}
              filterKey={filter.key}
              key={filter.key}
              title={title}
            />
          ))}
        </ScrollAreaViewport>
        <ScrollAreaScrollbar orientation="vertical">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </FilterSubMenuScrollArea>
    </FilterSubMenu>
  )
}

export default GenericFiltersSection
