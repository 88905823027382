import { TimeSeriesChartProps } from '@/types/analytics/Compare'
import React from 'react'
import { defaultMetrics, getXAxisData } from './chartOptions'
import { formatPercentage } from '@/utils/numbers'
import * as S from './ComparisonTable.styles'

const ComparisonTable = ({ intervalOption, timeSeries }: TimeSeriesChartProps) => {
  const xAxis = getXAxisData(timeSeries, intervalOption)

  return (
    <S.Table>
      <S.Col />
      <S.ColGroup span={2} />
      {timeSeries.map(v => (
        <S.ColGroup key={v.fieldValue} span={2} />
      ))}
      <S.Tr header="top">
        <S.Td rowSpan={2} />
        <S.Th colSpan={1} scope="colgroup" />
        {timeSeries.map(v => (
          <S.Th colSpan={2} key={v.fieldValue} scope="colgroup">
            {v.fieldValue}
          </S.Th>
        ))}
      </S.Tr>
      <S.Tr header="bottom">
        <S.Th scope="col">Total</S.Th>
        {timeSeries.map(v => (
          <React.Fragment key={v.fieldValue}>
            <S.Th scope="col">Volume</S.Th>
            <S.Th scope="col">%</S.Th>
          </React.Fragment>
        ))}
      </S.Tr>
      {xAxis.map((x, i) => (
        <S.Tr key={x}>
          <S.Th date scope="row">
            {x}
          </S.Th>
          <S.Td last>{timeSeries[0].data[i].countFeedbackWithoutFilter}</S.Td>
          {timeSeries.map(v => {
            const metrics = v.data[i] ?? defaultMetrics

            return (
              <React.Fragment key={v.fieldValue}>
                <S.Td>{metrics.countFeedback}</S.Td>
                <S.Td last>{formatPercentage(metrics.percentageFeedback)}%</S.Td>
              </React.Fragment>
            )
          })}
        </S.Tr>
      ))}
    </S.Table>
  )
}

export default ComparisonTable
