import TopicClassificationArea from '@/components/molecules/classification/ClassificationArea'
import { useUIStore } from '@/store'
import { shallow } from 'zustand/shallow'
import { CaretLeft, X } from '@phosphor-icons/react'
import {
  ClassificationAreaContainer,
  ExploreFloatingButton,
  SideExplorerInnerContent
} from './SideExplore.styles'
import FiltersArea from '../filters/FiltersSidebar/FiltersArea'
import ClassificationArea from '../classification-keywords/ClassificationArea'

interface Props {
  useTopicClassification?: boolean
}
function SideExplore({ useTopicClassification = false }: Props) {
  const { classificationCollapsed, isFiltersOpen } = useUIStore(
    state => ({
      classificationCollapsed: state.classificationCollapsed,
      isFiltersOpen: state.isFiltersOpen
    }),
    shallow
  )
  const toggleCollapse = useUIStore(state => state.toggleClassificationCollapsed)
  const closeFilters = useUIStore(state => state.closeFilters)

  return (
    <ClassificationAreaContainer collapsed={classificationCollapsed}>
      <ExploreFloatingButton onClick={toggleCollapse}>
        <CaretLeft className="collapse-icon" size={12} weight="bold" />
      </ExploreFloatingButton>
      {isFiltersOpen && (
        <ExploreFloatingButton colorScheme="tinted" onClick={closeFilters}>
          <X size={12} weight="regular" />
        </ExploreFloatingButton>
      )}
      <FiltersArea css={{ position: 'absolute', top: 0, left: 0 }} />
      <SideExplorerInnerContent direction="column">
        {useTopicClassification ? <TopicClassificationArea /> : <ClassificationArea />}
      </SideExplorerInnerContent>
    </ClassificationAreaContainer>
  )
}

export default SideExplore
