import { Suspense, lazy, useCallback, useEffect, useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import { useKeywords } from '@/hooks/useKeywords/useKeywords'

import KeywordsTable from './KeywordsTable'
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'
import { useFeedbackStore, useFiltersStore, useKeywordsStore } from '@/store'
import { Keyword, KeywordCluster, KeywordWithTopicIds } from '@/types/keywords'
import { CheckedState } from '@radix-ui/react-checkbox'
import { shallow } from 'zustand/shallow'
import ErrorBoundary from '@/components/atoms/error-boundary'
import KeywordsCollapsibleRow from './KeywordsCollapsibleRow'
import Keywords from './Keywords'
import ScrollLoader from '../../feed/ScrollLoader'
import NoResults from '../../feed/NoResults'
import ReasonsArea from '../../reasons/ReasonsArea'
import useSegment from '@/hooks/useSegment'
import FeedError from '../FeedError'
import useSelectedFilters from '@/hooks/filters/useSelectedFilters'
import { makeUniqueArray } from '@/utils/array'

const KeywordFeedbacks = lazy(() => import('./KeywordFeedbacks'))

// const MAX_CHECKED_KEYWORDS = 2000
interface Props {
  useKeywordTitle?: boolean
}

function KeywordClusters({ useKeywordTitle }: Props) {
  const { track } = useSegment()

  const resetFeedbacks = useFeedbackStore(state => state.reset)
  const { load, isLoading, maxFrequency, hasMore, error, clusters } = useKeywords()
  const filters = useFiltersStore(
    state => ({
      dateRange: state.dateRange,
      orderBy: state.orderBy,
      sortDirection: state.sortDirection,
      volumeBy: state.volumeBy,
      search: state.search,
      filtersByURL: state.filtersByURL,
      accounts: Object.values(state.accountsStringFilters).flatMap(value => value.selected),
      accountsDateFilters: state.accountsDateFilters.filter(filter => filter.selected !== null),
      accountsNumericFilters: state.accountNumericFilters
        .filter(filter => filter.option !== 'all')
        .map(filter => ({ key: filter.key, value: filter.value, option: filter.option })),
      accountsBooleanFilters: state.accountBooleanFilters.filter(filter => filter.value !== null),
      users: Object.values(state.usersStringFilters).flatMap(value => value.selected),
      usersDateFilters: state.usersDateFilters.filter(filter => filter.selected !== null),
      usersNumericFilters: state.usersNumericFilters
        .filter(filter => filter.option !== 'all')
        .map(filter => ({ key: filter.key, value: filter.value, option: filter.option })),
      usersBooleanFilters: state.usersBooleanFilters.filter(filter => filter.value !== null)
    }),
    shallow
  )

  const {
    selectedNumericFilters,
    selectedDatetimeFilters,
    selectedStringFilters,
    selectedTopicFilters
  } = useSelectedFilters()

  const { checkedKeywords, hasClickedKeywords } = useKeywordsStore(
    state => ({
      checkedKeywords: state.checkedKeywords,
      hasClickedKeywords: !!state.clickedKeywords
    }),
    shallow
  )
  const setCheckedKeywords = useKeywordsStore(state => state.setCheckedKeywords)
  const setClickedKeyword = useKeywordsStore(state => state.setClickedKeyword)
  const setReasonsKeywordHashes = useKeywordsStore(state => state.setReasonsKeywordHashes)

  const keywords = useMemo(() => clusters.flatMap(cluster => cluster.keywordList), [clusters])

  // const maxCheckedKeywords = useMemo(
  //   () => (keywords.length < MAX_CHECKED_KEYWORDS ? keywords.length : MAX_CHECKED_KEYWORDS),
  //   [keywords]
  // )

  const checkedAll = useMemo((): CheckedState => {
    if (isLoading || !keywords.length) {
      return false
    }

    if (checkedKeywords.length === keywords.length) {
      return true
    } else if (checkedKeywords.length > 0) {
      return 'indeterminate'
    } else {
      return false
    }
  }, [keywords, checkedKeywords, isLoading])

  const keywordsWithTopics = useMemo(
    () =>
      keywords.map(keyword => ({
        keywordHash: keyword.keywordHash,
        text: keyword.text,
        topicIds: keyword.topicIds,
        topics: keyword.topics
      })) as KeywordWithTopicIds[],
    [keywords]
  )

  const handleCheckAll = (checked: CheckedState) => {
    if (checked) {
      // setCheckedKeywords(keywordsWithTopics.slice(0, maxCheckedKeywords))
      setCheckedKeywords(keywordsWithTopics)
    } else {
      setCheckedKeywords([])
    }
  }

  const handleCheckCluster = useCallback(
    (cluster: KeywordCluster, checked: CheckedState) => {
      const clusterKeywords = cluster.keywordList.map(keyword => ({
        keywordHash: keyword.keywordHash,
        text: keyword.text,
        topicIds: keyword.topicIds,
        topics: keyword.topics
      })) as KeywordWithTopicIds[]

      if (checked) {
        setCheckedKeywords([...checkedKeywords, ...clusterKeywords])
      } else {
        setCheckedKeywords(
          checkedKeywords.filter(
            keyword =>
              !clusterKeywords.find(
                clusterKeyword => clusterKeyword.keywordHash === keyword.keywordHash
              )
          )
        )
      }
    },
    [setCheckedKeywords, checkedKeywords]
  )

  const isClusterChecked = useCallback(
    (cluster: KeywordCluster): CheckedState => {
      const isAllChecked = cluster.keywordList.every(({ keywordHash }) =>
        checkedKeywords.find(checkedKeyword => checkedKeyword.keywordHash === keywordHash)
      )
      if (isAllChecked) {
        return true
      }

      const isHalfChecked = cluster.keywordList.some(({ keywordHash }) =>
        checkedKeywords.find(checkedKeyword => checkedKeyword.keywordHash === keywordHash)
      )
      if (isHalfChecked) {
        return 'indeterminate'
      }

      return false
    },
    [checkedKeywords]
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    load()

    resetFeedbacks()
  }, [])

  useDidUpdateEffect(
    () => {
      load()
      setCheckedKeywords([])
    },
    [
      filters,
      selectedNumericFilters,
      selectedDatetimeFilters,
      selectedStringFilters,
      selectedTopicFilters
    ],
    true
  )

  const loadMore = () => {
    keywords.length && load()
  }

  const getClusterTopics = (keywords: Keyword[]) => {
    return makeUniqueArray(
      'topicId',
      keywords.flatMap(keyword => keyword.topics)
    )
  }

  const onReasonsClick = (cluster: KeywordCluster) => {
    setReasonsKeywordHashes(cluster.keywordList.map(keyword => keyword.keywordHash))
    track('explore_reason_view_list')
  }

  const onClickCluster = (cluster: KeywordCluster) => () => {
    setClickedKeyword(cluster.keywordList)
  }

  const onReload = () => {
    load()
  }

  if (error && clusters.length === 0) {
    return <FeedError onReload={onReload} />
  }

  if (!isLoading && clusters.length === 0) {
    return <NoResults />
  }

  return (
    <ErrorBoundary>
      <KeywordsTable checkedAll={checkedAll} onCheckedAllChange={handleCheckAll}>
        <InfiniteScroll
          dataLength={clusters.length}
          endMessage={<div />}
          hasMore={hasMore}
          loader={''}
          next={loadMore}
          scrollThreshold="250px"
          scrollableTarget="list-root-keyword"
        >
          {clusters.map((cluster: KeywordCluster, index) => (
            <KeywordsCollapsibleRow
              // checkboxDisabled={isCheckboxClusterDisabled(cluster)}
              checkboxDisabled={false}
              checked={isClusterChecked(cluster)}
              clusterKeywords={cluster.keywordList}
              frequency={cluster.frequency}
              id={cluster.cluster}
              isClicked={false}
              isCluster
              key={cluster.cluster + index}
              keywordsCount={cluster.keywordList.length}
              max={maxFrequency}
              name={cluster.cluster}
              onCheckedChange={checked => handleCheckCluster(cluster, checked)}
              onClickRow={onClickCluster(cluster)}
              onReasonsClick={() => onReasonsClick(cluster)}
              sentiment={cluster.sentiment}
              topics={getClusterTopics(cluster.keywordList)}
              totalReasons={cluster.totalReasons}
            >
              <Keywords keywordList={cluster.keywordList} useKeywordTitle={useKeywordTitle} />
            </KeywordsCollapsibleRow>
          ))}
          {isLoading && <ScrollLoader />}
        </InfiniteScroll>
      </KeywordsTable>

      <Suspense>{hasClickedKeywords && <KeywordFeedbacks />}</Suspense>

      <ReasonsArea onClose={() => setReasonsKeywordHashes([])} />
    </ErrorBoundary>
  )
}

export default KeywordClusters
