import FlexContainer from '@/components/atoms/flex-container'
import { PopoverHeader, PopoverFooter } from '@/components/atoms/popover'
import { styled, typefaceVariants } from '@/theme'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { slideAndFadeOpen } from '@/theme/animations'

export const DropdownMenuSubContent = styled(DropdownMenuPrimitive.SubContent, {
  display: 'flex',
  flexDirection: 'column',

  color: '$dark',

  borderRadius: 8,
  bAll: '$neutralHighPure',
  background: '$white',
  position: 'relative',
  zIndex: 9999,
  boxShadow: '$softShadow',

  ...slideAndFadeOpen
})

export const Header = styled(FlexContainer, {
  ...typefaceVariants.subtitleSemiBoldMicro,
  background: '$neutralHighMedium',
  bBottom: '$neutralHighPure',
  py: '$xxxs',
  px: '$xs'
})

export const Content = styled(FlexContainer, {
  width: '100%',
  height: '100%',
  maxHeight: 152,
  overflow: 'auto',
  flexDirection: 'column',
  boxSizing: 'border-box',
  px: '$xs',
  py: '$xxs',

  variants: {
    fixedHeight: {
      true: {
        minHeight: 120
      }
    }
  }
})

export const Container = styled(FlexContainer, {
  flexDirection: 'column',
  width: 240
})

export const SearchHeader = styled(PopoverHeader, {
  px: '$xs',
  py: '$xxs'
})

export const Item = styled('button', {
  all: 'unset',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: 'auto',
  cursor: 'pointer',
  padding: '10px 6px',
  borderRadius: 6,

  '&:hover': {
    background: '$greenLight'
  },

  variants: {
    disablePointer: {
      true: {
        cursor: 'default'
      }
    }
  }
})

export const Footer = styled(PopoverFooter, {
  display: 'flex',
  gap: 14,
  padding: '12px 20px',
  justifyContent: 'flex-start'
})

export const SectionTitleHeading = styled(FlexContainer, {
  mb: '$micro',
  alignItems: 'center',

  '& > button, & > p': {
    background: 'transparent',
    border: 'none',
    outline: 'none',
    display: 'inline-flex',
    gap: '$nano'
  },

  '& > button': {
    width: '100%'
  },
  '& > button > svg': {
    minWidth: 14
  },

  variants: {
    separator: {
      true: {
        color: '$neutralLowPure',
        background: '$neutralHighMedium',
        bBottom: '$neutralHighPure',
        padding: '$xxxs'
      }
    }
  }
})
