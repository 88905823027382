import IndicatorCard from './IndicatorCard'
import { IntentionIndicatorsProps } from './Indicators.types'
import { formatPercentage, formatPercentageWithSignal, roundAndSuffix } from '@/utils/numbers'
import useComparisonText from '@/hooks/useComparisonText'
import { useFiltersStore } from '@/store'
import { shallow } from 'zustand/shallow'
import { intentionMap } from '../indicatorsUtilts'

const IntentionIndicator = ({
  count,
  intentionPercentage,
  comparisonPercentage,
  intention
}: IntentionIndicatorsProps) => {
  const dateRange = useFiltersStore(state => state.dateRange, shallow)

  const { text } = useComparisonText()

  const formattedCount = roundAndSuffix(count)
  const formattedComparisonPercentage = formatPercentageWithSignal(comparisonPercentage)
  const formattedIntentionPercentage = formatPercentage(intentionPercentage)

  const color = intentionMap[intention].color
  const Icon = intentionMap[intention].Icon

  return (
    <IndicatorCard.Root alignItems="end" color={color}>
      <IndicatorCard.Column>
        <IndicatorCard.Header>
          <IndicatorCard.IconBox>
            <Icon />
          </IndicatorCard.IconBox>
          <IndicatorCard.Title>{intention}</IndicatorCard.Title>
        </IndicatorCard.Header>
        <IndicatorCard.NumberContainer>
          <IndicatorCard.BigNumber>{formattedIntentionPercentage}%</IndicatorCard.BigNumber>
          <IndicatorCard.Paragraph>({formattedCount})</IndicatorCard.Paragraph>
        </IndicatorCard.NumberContainer>
      </IndicatorCard.Column>
      {dateRange ? (
        <IndicatorCard.Paragraph>
          <em>{formattedComparisonPercentage}</em> <span>{text}</span>
        </IndicatorCard.Paragraph>
      ) : (
        <IndicatorCard.Fallback />
      )}
    </IndicatorCard.Root>
  )
}

export default IntentionIndicator
