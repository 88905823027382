import * as ToastPrimitive from '@radix-ui/react-toast'
import { StyledToast } from './Toast.styles'
import { ReactComponent as SuccessIcon } from '@/assets/icons/check_circle.svg'
import { ReactComponent as ErrorIcon } from '@/assets/icons/cross_circle.svg'
import { colors } from '@/theme'
import { ToastStatus } from '@/types/toast'
import Loader from '../loader'
import { WarningCircle } from '@phosphor-icons/react'

export interface ToastProps extends ToastPrimitive.ToastProps {
  status?: ToastStatus
}

const Toast = ({ status, children, ...props }: ToastProps) => {
  const Icon = {
    error: ErrorIcon,
    success: SuccessIcon,
    warning: () => <WarningCircle color={colors.feedbackWarningMedium} size="24" weight="fill" />,
    loading: () => <Loader color="white" size="small" />,
    default: () => <></>
  }[status ?? 'default']

  return (
    <StyledToast {...props}>
      <Icon />
      <span>{children}</span>
    </StyledToast>
  )
}

export default Toast
