import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import KeywordCreator from '@/components/molecules/keyword-creator'
import useLetterCase from '@/hooks/useLetterCase'
import useParse from '@/hooks/useParse'
import useKeywordStore from '@/store/useKeywordsStore'
import { KeywordTopic } from '@/types/feedbacks'
import useToastMessageStore from '@/store/useToastMessageStore'
import useUser from './useUser'

const useTextSelection = <T extends HTMLElement>(
  text: React.ReactNode[],
  keywords: KeywordTopic[]
): [React.ReactNode[], React.RefObject<T>, () => void] => {
  const { parseAndSanitizeArray, parseAndSanitize } = useParse()

  const addToast = useToastMessageStore(state => state.addToast)

  const parsedAndSanitizedText = useMemo(() => {
    return parseAndSanitizeArray(text)
  }, [parseAndSanitizeArray, text])

  const [textWithSelection, setTextWithSelection] = useState<ReactNode[]>(parsedAndSanitizedText)
  const [selectedText, setSelectedText] = useState('')
  const [currentSelection, setCurrentSelection] = useState<Selection | null>(null)

  const { cleanTextForRegex } = useLetterCase()

  const setNewKeyword = useKeywordStore(state => state.setNewKeyword)

  const { userPermissions } = useUser()
  const canAdd = userPermissions.feedback.includes('add-to')

  const containerRef = useRef<T>(null)

  const reset = useCallback(() => {
    setTextWithSelection(parsedAndSanitizedText)
    setSelectedText('')
    setCurrentSelection(null)
    setNewKeyword(null)
  }, [parsedAndSanitizedText, setNewKeyword])

  const onSelect = useCallback(() => {
    if (!canAdd) return
    const selection = window.getSelection()

    if (selection) {
      const selectionString = selection.toString().trim()

      const plainText = containerRef.current?.innerText
      if (!plainText?.includes(selectionString)) return

      if (keywords.some(keyword => keyword.text === selectionString)) {
        addToast({
          text: 'This keyword already exists',
          status: 'default',
          id: 'keyword-exists',
          open: true
        })

        return
      }

      if (selectionString.length > 1) {
        setSelectedText(selectionString)
        setCurrentSelection(selection)
        setNewKeyword(selectionString)
      }
    }
  }, [setNewKeyword, addToast, canAdd, keywords])

  const applySelectionHighlight = useCallback(() => {
    if (currentSelection && selectedText) {
      const plainText = containerRef.current?.innerHTML
        ?.replaceAll('<em>', '')
        ?.replaceAll('</em>', '')
      if (!plainText) {
        setTextWithSelection(parsedAndSanitizedText)
        return
      }

      const [before, , ...after] = plainText.split(
        new RegExp(`(${cleanTextForRegex(selectedText)})`, 'im')
      )

      const parsedBefore = parseAndSanitize(before)
      const parsedAfter = parseAndSanitize(after.join(''))

      const keywordCreatorComponent = (
        <KeywordCreator key={selectedText} onCancel={reset}>
          {selectedText}
        </KeywordCreator>
      )

      setTextWithSelection([parsedBefore, keywordCreatorComponent, parsedAfter])
    }
  }, [
    cleanTextForRegex,
    currentSelection,
    parsedAndSanitizedText,
    reset,
    selectedText,
    parseAndSanitize
  ])

  useEffect(() => {
    setTextWithSelection(parsedAndSanitizedText)
  }, [parsedAndSanitizedText])

  useEffect(() => {
    applySelectionHighlight()
  }, [applySelectionHighlight])

  return [textWithSelection, containerRef, onSelect]
}

export default useTextSelection
