import useToastMessageStore from '@/store/useToastMessageStore'
import { useTranslation } from 'react-i18next'
import useLogging from '../useLogging'
import { shallow } from 'zustand/shallow'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { useMemo, useRef } from 'react'
import { RawAreaError } from '@/types/area/AreaRequests'
import useBasicOpportunitiesQuery from './useBasicOpportunitiesQuery'
import useSingleSegmentationQuery from '../segmentation/useSingleSegmentationQuery'
import {
  OpportunityItemWithMergedContext,
  OpportunityItemWithMetrics
} from '@/types/opportunity/Opportunity'
import { getMergedAreasContexts } from '@/utils/opportunityQueryUtils'
import { useQuery } from '@tanstack/react-query'
import useOpportunitiesMetricsQuery from './useAllOpportunitiesWithMetricsQuery/useOpportunitiesMetricsQuery'
import useBasicAreaOfInterestQuery from '../areaOfInterest/useBasicAreaOfInterestQuery'

interface Params {
  segmentationId?: string
  enabled?: boolean
  sortColumn?: string
  sortDirection?: 'asc' | 'desc'
}

const useOpportunitiesOfSegment = ({
  segmentationId,
  enabled = true,
  sortColumn = 'count',
  sortDirection = 'desc'
}: Params) => {
  const { t } = useTranslation()

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const { logException } = useLogging({ context: 'opportunities-of-segment' })

  const currentArea = useCurrentInterestAreaStore(state => state.currentInterestArea, shallow)

  const { opportunities: allOpportunities, isLoading: isLoadingOpportunities } =
    useBasicOpportunitiesQuery({
      enabled,
      areaId: currentArea?.id
    })

  const { areas: allAreas, isLoading: isLoadingAllAreas } = useBasicAreaOfInterestQuery({
    enabled
  })

  const mapMergedAreasErrorById = useRef<Record<string, RawAreaError>>({})

  const {
    data: segmentationData,
    isLoading: isLoadingSegmentation,
    queryKey: segmentationQueryKey
  } = useSingleSegmentationQuery({
    segmentationId: segmentationId ?? '',
    enabled: enabled && !!segmentationId && !!allOpportunities && !!allAreas
  })

  const segmentationOpportunities = useMemo(() => {
    if (!segmentationData) return []
    const segOppsIds = segmentationData?.opportunities.map(opp => opp.id) ?? []
    const uniqueSegOppsIds = [...new Set(segOppsIds)]

    const mappedOpps: OpportunityItemWithMetrics[] = allOpportunities
      ?.filter(opp => uniqueSegOppsIds.includes(opp.id))
      .map(opp => {
        return {
          ...opp,
          area:
            opp.relations.length === 1
              ? allAreas?.find(area => area.id === opp.relations[0])
              : undefined,
          metrics: [],
          opportunityCount: 0
        }
      })

    return mappedOpps
  }, [segmentationData, allOpportunities, allAreas])

  const mergedOpportunitiesQueryFn = async () => {
    const errorMap = mapMergedAreasErrorById.current
    const [contextError, contextsData] = await getMergedAreasContexts(
      segmentationOpportunities,
      errorMap
    )

    if (contextError) {
      logException(contextError, {
        message: 'Failed to get merged areas contexts.'
      })
      addErrorToast({ text: t('getMergedAreasContextsErrorMessage') })
      throw contextError
    }

    const withMergedContext = segmentationOpportunities.map((opp, index) => {
      return { ...opp, mergedContext: contextsData[index] } as OpportunityItemWithMergedContext
    })

    return withMergedContext
  }

  const { data: mergedOpportunitiesData, isLoading: isLoadingMergedOpportunities } = useQuery({
    queryKey: ['merged-opportunities', segmentationOpportunities],
    queryFn: mergedOpportunitiesQueryFn,
    enabled: enabled && !!segmentationData
  })

  const mergedOpportunities = useMemo(
    () => mergedOpportunitiesData ?? [],
    [mergedOpportunitiesData]
  )

  const {
    opportunitiesWithMetrics: opportunities,
    isLoadingOpportunitiesWithSortMetric,
    isLoadingOpportunitiesWithAllMetrics,
    loadNextPage,
    hasMore,
    opportunitiesSorted,
    resetPage
  } = useOpportunitiesMetricsQuery({
    opportunities: mergedOpportunities,
    enabled: enabled && !!mergedOpportunitiesData,
    sortColumn,
    sortDirection
  })

  return {
    opportunities,
    isLoadingSegmentation,
    isLoadingOpportunities,
    isLoadingAllAreas,
    isLoadingMergedOpportunities,
    isLoadingOpportunitiesWithSortMetric,
    isLoadingOpportunitiesWithAllMetrics,
    loadNextPage,
    hasMore,
    queryKey: segmentationQueryKey,
    opportunitiesSorted,
    resetPage
  }
}

export default useOpportunitiesOfSegment
