import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ColumnDef,
  ColumnFiltersState,
  ColumnSort,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import TableV2 from '@/components/atoms/table-v2/TableV2'
import useMetricsTableColumns from '@/hooks/metrics/useMetricsTableColumns'
import { ArrowUp } from '@phosphor-icons/react'
import { colors } from '@/theme'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import OpportunityNameCell from './OpportunityNameCell'
import OpportunityOptions from './OpportunityOptions'
import { ONGOING_STATUS, OPPORTUNITY_STATUS_OPTIONS, StatusFilter } from '@/utils/opportunityUtils'
import Select from '@/components/atoms/select'
import FlexContainer from '@/components/atoms/flex-container'
import TableSkeleton from '@/components/molecules/skeleton/TableSkeleton'
import NoResults from '@/components/molecules/feed/NoResults'
import { getHeaderCss } from '@/components/molecules/opportunities/opportunityTableUtils'
import useUser from '@/hooks/useUser'
import useFeedQueryParams from '@/hooks/feedback/new/useFeedQueryParams'
import { useTranslation } from 'react-i18next'
import { OpportunityItemWithMetrics, OpportunityStatus } from '@/types/opportunity/Opportunity'

interface Props {
  data: OpportunityItemWithMetrics[]
  isLoading: boolean
  selectedStatusFilter: StatusFilter
  filterNew: boolean
  isUpdating: boolean
  onStatusChange: (
    opportunity: OpportunityItemWithMetrics
  ) => (newStatus: OpportunityStatus) => void
  onConfirmMoveTo: (opportunity: OpportunityItemWithMetrics) => (newArea: BaseInterestArea) => void
  onRename: (opportunity: OpportunityItemWithMetrics) => void
}

// const placeholderOpps: OpportunityItemWithMetrics[] = []

const OpportunityTable = ({
  filterNew,
  selectedStatusFilter,
  data,
  isLoading,
  isUpdating,
  onConfirmMoveTo,
  onStatusChange,
  onRename
}: Props) => {
  const [sorting, setSorting] = useState<ColumnSort[]>([{ id: 'count', desc: true }])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    { id: 'status', value: selectedStatusFilter },
    { id: 'name', value: true }
  ])

  const [isEmpty, setIsEmpty] = useState(false)

  const { t } = useTranslation()

  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)

  // const { advancedAreas } = useAdvancedAreasOnly({ enabled: true })

  const { queryParams } = useFeedQueryParams()

  // const currentAdvancedArea = useMemo(() => {
  //   if (currentInterestArea?.isUnmappedArea) return currentInterestArea
  //   return advancedAreas.find(area => area.id === currentInterestArea?.id)
  // }, [currentInterestArea, advancedAreas])

  const { metricColumns, columnVisibility, setColumnVisibility } =
    useMetricsTableColumns<OpportunityItemWithMetrics>({
      filterFn: item => ({
        // context: currentAdvancedArea?.context,
        ...queryParams,
        opportunity_id: item.id
      }),
      metricFor: 'opportunity'
    })

  const { isManager } = useUser()

  const opportuntyColumns: ColumnDef<OpportunityItemWithMetrics>[] = useMemo(() => {
    const columns: ColumnDef<OpportunityItemWithMetrics>[] = [
      {
        accessorKey: 'name',
        id: 'name',
        header: t('name'),
        enableHiding: false,
        minSize: 350,
        maxSize: 400,
        sortDescFirst: true,
        filterFn: (row, _, filterValue) => {
          const onlyNew = filterValue as boolean

          if (onlyNew) return row.original.new

          return true
        },
        cell: data => <OpportunityNameCell data={data.row.original} />,
        footer: data => data.column.id
      },
      ...metricColumns.filter(column => column.id !== 'opportunityCount'),
      {
        id: 'status',
        header: t('status'),
        accessorKey: 'status',
        minSize: 145,
        maxSize: 145,
        enableSorting: true,
        filterFn: (row, _, filterValue) => {
          const status = filterValue as StatusFilter

          if (status === 'ongoing') {
            return ONGOING_STATUS.includes(row.original.status)
          }

          if (status === 'dismissed') {
            return row.original.status === OpportunityStatus.Dismissed
          }

          return true
        },
        cell: ({ row }) => {
          const opportunity = row.original
          const isDraft = opportunity.status === OpportunityStatus.Draft

          return (
            <FlexContainer css={{ padding: '$xxs' }}>
              <Select
                defaultValue={opportunity.status}
                disabled={isDraft || isUpdating || !isManager}
                onValueChange={onStatusChange(opportunity)}
                options={OPPORTUNITY_STATUS_OPTIONS}
                small
                value={opportunity.status}
                width={110}
              />
            </FlexContainer>
          )
        },
        footer: data => data.column.id
      }
    ]

    if (isManager) {
      columns.push({
        id: 'options',
        minSize: 40,
        maxSize: 40,
        enableSorting: false,
        cell: data => {
          return (
            <OpportunityOptions
              data={data.row.original}
              onConfirmMoveTo={onConfirmMoveTo(data.row.original)}
              onRename={() => onRename(data.row.original)}
            />
          )
        },
        footer: data => data.column.id
      })
    }

    return columns
  }, [metricColumns, isUpdating, onConfirmMoveTo, onStatusChange, onRename, isManager, t])

  const headerCss = useCallback(getHeaderCss, [])

  const getRowId = useCallback((row: OpportunityItemWithMetrics) => {
    return row.id
  }, [])

  const table = useReactTable({
    data,
    columns: opportuntyColumns,
    manualFiltering: false,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,

    enableColumnResizing: false,
    enableHiding: true,
    state: {
      columnVisibility,
      columnFilters,
      sorting,
      columnPinning: {
        left: ['name'],
        right: ['status', 'options']
      }
    }
  })

  useEffect(() => {
    setColumnFilters(prevFilters => {
      return prevFilters.map(prevFilter => {
        if (prevFilter.id === 'status') {
          return { ...prevFilter, value: selectedStatusFilter }
        }

        if (prevFilter.id === 'name') {
          return { ...prevFilter, value: filterNew }
        }

        return prevFilter
      })
    })
  }, [selectedStatusFilter, filterNew])

  useEffect(() => {
    setIsEmpty(table.getRowCount() === 0)
  })

  if (!currentInterestArea) return <></>

  if (isLoading) {
    return <TableSkeleton />
  }

  if (isEmpty) {
    return (
      <NoResults
        css={{ color: '$neutralLowPure' }}
        descriptionText={t('reviewYourSelectedFilters')}
      />
    )
  }

  return (
    <>
      <TableV2
        enableSort
        sortIndicator={<ArrowUp color={colors.brandPrimaryPure} size={16} />}
        table={table}
        thContainerProps={headerCss}
      />
    </>
  )
}

export default OpportunityTable
