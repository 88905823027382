import type { Collection } from '@/types/collection'
import { CollectionItemContainer } from './styles'
import FlexContainer from '@/components/atoms/flex-container'
import { CheckCircle, FolderSimple, Heart, IconContext } from '@phosphor-icons/react'
import IconButton from '@/components/atoms/icon-button'
import OptionsMenu from '@/components/atoms/options-menu'
import useCollectionOptions from '../useCollectionOptions'
import useCollections from '@/hooks/collections/useCollections'
import { colors } from '@/theme'
import DeleteCollectionAlert from '../alerts/DeleteCollectionAlert'
import PublishCollectionAlert from '../alerts/PublishCollectionAlert'
import UnpublishCollectionAlert from '../alerts/UnpublishCollectionAlert'
import EditCollectionModal from '../EditCollectionModal'

interface Props {
  collection: Collection
  onClick: (collection: Collection) => void
}

const CollectionItem = ({ collection, onClick }: Props) => {
  const { currentCollection } = useCollections({ enabled: false })

  const onCollectionClick = () => {
    onClick(collection)
  }

  const {
    isMenuOpen,
    setIsMenuOpen,
    menuOptions,
    toggleFavorite,
    isEditModalOpen,
    setIsEditModalOpen,
    confirmEdit,
    isDeleteAlertOpen,
    setIsDeleteAlertOpen,
    confirmDelete,
    isPublishAlertOpen,
    setIsPublishAlertOpen,
    confirmPublish,
    isUnpublishAlertOpen,
    setIsUnpublishAlertOpen,
    confirmUnpublish
  } = useCollectionOptions(collection)

  const onFavoriteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    toggleFavorite()
  }

  const checkCircleClassName =
    currentCollection?.collectionId === collection.collectionId ? 'check visible' : 'check'

  return (
    <CollectionItemContainer onClick={onCollectionClick} open={isMenuOpen}>
      <IconContext.Provider value={{ size: 16 }}>
        <FlexContainer alignItems="center" gap="nano">
          <FolderSimple />
          <span>{collection.name}</span>
        </FlexContainer>
        <FlexContainer alignItems="center" gap="nano">
          <IconButton className={collection.favorite ? '' : 'hidden'} onClick={onFavoriteClick}>
            <Heart weight={collection.favorite ? 'fill' : 'regular'} />
          </IconButton>
          <OptionsMenu
            className="hidden options"
            onOpenChange={setIsMenuOpen}
            open={isMenuOpen}
            options={menuOptions}
            stopPropagation
          />
          <CheckCircle className={checkCircleClassName} color={colors.feedbackPositivePure} />
        </FlexContainer>
      </IconContext.Provider>
      <EditCollectionModal
        collection={collection}
        onOpenChange={setIsEditModalOpen}
        onSaveCollection={confirmEdit}
        open={isEditModalOpen}
      />
      <DeleteCollectionAlert
        onConfirmDelete={confirmDelete}
        onOpenChange={setIsDeleteAlertOpen}
        open={isDeleteAlertOpen}
      />
      <PublishCollectionAlert
        onConfirmPublish={confirmPublish}
        onOpenChange={setIsPublishAlertOpen}
        open={isPublishAlertOpen}
      />
      <UnpublishCollectionAlert
        onConfirmUnpublish={confirmUnpublish}
        onOpenChange={setIsUnpublishAlertOpen}
        open={isUnpublishAlertOpen}
      />
    </CollectionItemContainer>
  )
}

export default CollectionItem
