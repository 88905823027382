import FeedbackService from '@/services/FeedbackService'
import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'

interface Props {
  name: string
  enabled?: boolean
}

const useNumberFiltersQuery = ({ name, enabled = true }: Props) => {
  const queryFunction = useCallback(async () => {
    const [error, response] = await FeedbackService.getFeedbackFieldValues({
      type: 'number',
      fieldName: name
    })

    if (error) throw error

    return response
  }, [name])

  const query = useQuery({
    queryKey: ['feed-filters', 'number', name],
    queryFn: queryFunction,
    enabled
  })

  return query
}

export default useNumberFiltersQuery
