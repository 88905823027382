import { styled } from '@/theme'
import * as RadixSwitch from '@radix-ui/react-switch'

export const SwitchRoot = styled(RadixSwitch.Root, {
  all: 'unset',
  width: 34,
  height: 16,
  backgroundColor: '$neutralLowLight',
  borderRadius: '9999px',
  position: 'relative',
  boxShadow: `0 2px 10px $dark`,
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  cursor: 'pointer',
  '&[data-state="checked"]': { backgroundColor: '$feedbackPositivePure' },

  '&[data-disabled]': {
    background: '$neutralLowLight',
    opacity: 0.5,
    cursor: 'not-allowed'
  }
})

export const SwitchThumb = styled(RadixSwitch.Thumb, {
  display: 'block',
  width: 12,
  height: 12,
  backgroundColor: 'white',
  borderRadius: '9999px',
  boxShadow: `0 2px 2px $dark`,
  transition: 'transform 100ms',
  transform: 'translateX(2px)',
  willChange: 'transform',
  '&[data-state="checked"]': { transform: 'translateX(19px)' }
})
