import { useEffect, useState } from 'react'
import { GenericFeatureToggle } from './types'
import { ParamsContainer, ParamsInputContainer } from './ParamsForms.styles'
import Text from '@/components/atoms/text'
import Switch from '@/components/atoms/switch'
import ParamsButtons from './ParamsButtons'

export interface ParamsFormProps<T> {
  data: T
  onSave: (formData: T) => void
  onReset: () => void
}

interface Props<T> extends ParamsFormProps<T> {
  title: string
  featureToggleList: GenericFeatureToggle<keyof T>[]
}

const GenericFeatureToggleForm = <T,>({
  title,
  featureToggleList,
  data,
  onSave,
  onReset
}: Props<T>) => {
  const [formData, setFormData] = useState(data)

  useEffect(() => setFormData(data), [data])

  const handleChange = (key: keyof T) => (value: boolean) => {
    setFormData(prev => ({ ...prev, [key]: value }))
  }

  return (
    <ParamsContainer>
      <Text as="p" typeface="textBodyRegularMedium">
        {title}
      </Text>

      {featureToggleList.map(({ label, name }) => (
        <ParamsInputContainer direction="row" key={name as string}>
          <Text as="label" htmlFor={name as string} typeface="textBodySmallRegular">
            {label}
          </Text>
          <Switch checked={formData[name] as boolean} onCheckedChange={handleChange(name)} />
        </ParamsInputContainer>
      ))}

      <ParamsButtons onReset={onReset} onSave={() => onSave(formData)} />
    </ParamsContainer>
  )
}

export default GenericFeatureToggleForm
