import { NumericRangeOption } from '@/types/filters/Filters'
import { RATING_KEY } from '@/utils/filters'
import { Star } from '@phosphor-icons/react'
import { NumericFilterSubMenu } from '../NumericGenericFilter'

const filterKey = RATING_KEY

const RatingSection = () => {
  const labels: Record<NumericRangeOption, string> = {
    all: 'All ratings',
    between: 'Range',
    is: 'Exactly rating',
    null: 'Empty rating'
  }

  return (
    <NumericFilterSubMenu
      filterKey={filterKey}
      icon={<Star />}
      labels={labels}
      name="rating"
      title="Rating"
      type="number"
    />
  )
}

export default RatingSection
