import DefaultErrorHandler from '@/services/DefaultError'
import MetricsService from '@/services/MetricsService'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { MetricItem, MetricsRequests } from '@/types/metrics'
import { MetricListPayloadItem } from '@/types/metrics/MetricsRequests'
import { delay } from '@/utils/delay'

interface GetMetricsListParams {
  filterList: FeedbackListQueryParams[]
  onError?: (error: DefaultErrorHandler) => void
  sortingMetric?: MetricItem['metric']
  metricList?: MetricListPayloadItem[]
  onProgress?: (chunkLength: number, completed: number) => void
  startDate?: string
  endDate?: string
}

export const getMetricsToUse = (
  sortingMetric?: MetricItem['metric'],
  metricList?: MetricListPayloadItem[]
) => {
  if (metricList && metricList.length > 0) return metricList

  if (sortingMetric)
    return [
      {
        ...sortingMetric,
        include_previous_value: false
      }
    ] as MetricListPayloadItem[]

  return undefined
}

export const buildMetricsChunks = ({
  sortingMetric,
  metricList,
  filterList,
  chunkSize
}: GetMetricsListParams & { chunkSize: number }) => {
  const metricsToUse = getMetricsToUse(sortingMetric, metricList)

  const chunks = []

  for (let i = 0; i < filterList.length; i += chunkSize) {
    chunks.push({
      filters: filterList.slice(i, i + chunkSize)
    })
  }
  return { chunks, metricsToUse }
}

export const fetchMetricsListInChunks = async ({
  onProgress,
  startDate,
  endDate,
  ...params
}: GetMetricsListParams) => {
  const { metricsToUse, chunks } = buildMetricsChunks({ ...params, chunkSize: 10 })

  if (!metricsToUse) {
    const emptyMetricsErrors = new DefaultErrorHandler(Error('No metrics to use'))
    throw emptyMetricsErrors
  }

  let completed = 0
  const promises = chunks.map(async (chunk, index) => {
    const metricsPayload: MetricsRequests.MetricsPayload = {
      filter_list: chunk.filters,
      metric_list: metricsToUse,
      posted_at_gte: startDate,
      posted_at_lt: endDate
    }

    await delay(250 * index)
    return MetricsService.metrics(metricsPayload).then(result => {
      onProgress?.(chunks.length, completed)
      completed++

      return result
    })
  })

  return Promise.all(promises)
}
