import { UploadFormData } from '@/types/params/UploadParams'
import { useEffect, useState } from 'react'
import { ParamsContainer, ParamsInputContainer } from './ParamsForms.styles'
import Text from '@/components/atoms/text'
import Switch from '@/components/atoms/switch'
import ParamsButtons from './ParamsButtons'
import { useTranslation } from 'react-i18next'

interface Props {
  data: UploadFormData
  onSave: (formData: UploadFormData) => void
  onReset: () => void
}

const UploadParamsForm = ({ data, onSave, onReset }: Props) => {
  const [formData, setFormData] = useState(data)
  const { t } = useTranslation()

  useEffect(() => setFormData(data), [data])

  const handleEnableImportDataChange = (value: boolean) => {
    setFormData(prev => ({ ...prev, enableImportData: value }))
  }

  const handleEnableImportCustomersChange = (value: boolean) => {
    setFormData(prev => ({ ...prev, enableImportCustomers: value }))
  }

  return (
    <ParamsContainer>
      <Text as="p" typeface="textBodyRegularMedium">
        {t('uploadParams')}
      </Text>

      <ParamsInputContainer direction="row">
        <Text as="label" htmlFor="enableImportData" typeface="textBodySmallRegular">
          {t('enableImportData')}
        </Text>
        <Switch
          checked={formData.enableImportData}
          onCheckedChange={handleEnableImportDataChange}
        />
      </ParamsInputContainer>

      <ParamsInputContainer direction="row">
        <Text as="label" htmlFor="enableImportCustomers" typeface="textBodySmallRegular">
          {t('enableImportCustomers')}
        </Text>
        <Switch
          checked={formData.enableImportCustomers}
          onCheckedChange={handleEnableImportCustomersChange}
        />
      </ParamsInputContainer>

      <ParamsButtons onReset={onReset} onSave={() => onSave(formData)} />
    </ParamsContainer>
  )
}

export default UploadParamsForm
