import SegmentationService from '@/services/SegmentationService'
import { SegmentationRequests } from '@/types/segmentation'
import { SegmentationItem } from '@/types/segmentation/Segmentation'
import { mapSegmentation } from '@/utils/segmentation'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

interface Prams {
  enabled: boolean
}

export const basicSegmentationsQueryKey = ['segmentations']

const useBasicSegmentationsQuery = ({ enabled = true }: Prams = { enabled: true }) => {
  const queryFn = async () => {
    const params: SegmentationRequests.GetSegmentationsParams = {
      limit: 1000,
      page: 1
    }

    const [error, response] = await SegmentationService.getAll(params)
    if (error) throw error
    return response.segmentations ?? []
  }

  const { data, isLoading, ...query } = useQuery({
    queryKey: basicSegmentationsQueryKey,
    queryFn,
    enabled,
    refetchOnMount: false
  })

  const segmentations: SegmentationItem[] = useMemo(() => data?.map(mapSegmentation) ?? [], [data])

  return {
    segmentations,
    isLoading: enabled ? isLoading : false,
    queryKey: basicSegmentationsQueryKey,
    ...query
  }
}

export default useBasicSegmentationsQuery
