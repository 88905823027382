import { GroupBase, StylesConfig } from 'react-select'

export function getMultiSelectStyles<Option = { label: string; value: string }>(): StylesConfig<
  Option,
  boolean,
  GroupBase<Option>
> {
  return {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.selectProps.menuIsOpen ? '#05495F' : '#E4E4E4',
      borderRadius: 8,
      boxShadow: state.selectProps.menuIsOpen ? '0 0 0 1px #05495F' : 'none',

      ':hover': {
        borderColor: state.selectProps.menuIsOpen ? '#05495F' : '#E4E4E4'
      }
    }),
    dropdownIndicator: (baseStyles, state) => ({
      ...baseStyles,
      padding: '12px 16px',
      transform: state.selectProps.menuIsOpen ? 'scaleY(-1)' : undefined,
      transition: 'transform .1s'
    }),
    input: baseStyles => ({
      ...baseStyles,
      padding: 0
    }),
    menu: baseStyles => ({
      ...baseStyles,
      zIndex: 9999,
      border: 0,
      borderRadius: 8,
      boxShadow: '0px 4px 16px rgba(30, 30, 30, 0.16)'
    }),
    menuList: baseStyles => ({
      ...baseStyles,
      zIndex: 9999,
      padding: 0
    }),
    multiValue: baseStyles => ({
      ...baseStyles,
      alignItems: 'center',
      backgroundColor: '#F3F3F3',
      borderRadius: 4,
      display: 'flex',
      gap: 8,
      height: 24,
      margin: 0,
      padding: '4px 12px'
    }),
    multiValueLabel: () => ({
      color: '#565656',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.33,
      overflow: 'hidden',
      padding: 0,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }),
    multiValueRemove: () => ({
      cursor: 'pointer'
    }),
    option: (_, state) => ({
      backgroundColor: state.isFocused ? '#F1F5F6' : 'transparent',
      borderRadius: 8,
      cursor: 'pointer',
      fontSize: 14,
      lineHeight: 'calc(24 / 14)',
      margin: 4,
      padding: '4px 12px'
    }),
    placeholder: () => ({
      color: '#8E8E8E',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.15
    }),
    valueContainer: baseStyles => ({
      ...baseStyles,
      display: 'flex',
      gap: 8,
      padding: '8px 8px'
    }),
    menuPortal: baseStyles => ({
      ...baseStyles,
      zIndex: 9999,
      pointerEvents: 'auto'
    })
  }
}
