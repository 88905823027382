import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'
import { Link } from 'react-router-dom'
import { ScrollAreaRoot } from '../scroll-area'

export const HOME_CARD_WIDTH = 264
export const HomeCard = styled(FlexContainer, {
  padding: '$xs',
  gap: '$xxs',
  flexDirection: 'column',
  br: '$micro',
  bAll: '$neutralHighPure',
  backgroundColor: '$neutralHighLight',
  width: `calc(${HOME_CARD_WIDTH}px + $lg)`, // card width + left and right padding

  '.description': {
    opacity: 0.5
  },

  '.template-link': {
    cursor: 'pointer',
    textDecoration: 'none'
  }
})

export const GoBack = styled(Link, {
  display: 'flex',
  alignItems: 'center',
  gap: '$nano',

  color: 'inherit',
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline'
  }
})

export const Fade = styled('div', {
  position: 'absolute',
  bottom: -2,
  zIndex: 2,
  height: 44,
  width: '100%',
  background: 'linear-gradient(rgba(255, 255, 255, 0) 1%, rgb(255, 255, 255) 64.92%)',

  variants: {
    hide: {
      true: {
        display: 'none'
      },
      false: {}
    }
  },

  defaultVariants: {
    hide: false
  }
})

export const CustomerRecordsScrollArea = styled(ScrollAreaRoot, {
  overflow: 'hidden'
})
