import useBooleanAdvancedFilter from '@/hooks/advancedFilters/useBooleanAdvancedFilter'
import { snakeToTitle } from '@/utils/letterCase'
import { FilterItemDropdown } from '../../AppliedFilters/FilterItem'
import { BooleanFilterContent } from '../../FiltersSidebar/BooleanGenericFilter'
import { BooleanFilterType } from '@/types/filters/AdvancedFilters'
import { useTranslation } from 'react-i18next'

interface Props {
  title: string
  name: string
  path: string
  value: boolean
  index: number
  type: BooleanFilterType
  disabled?: boolean
}

const BooleanFilterApplied = ({
  title,
  name,
  path,
  value,
  index,
  type = 'boolean',
  disabled = false
}: Props) => {
  const initialValue = value ? 'true' : 'false'
  const { t } = useTranslation()

  const { update, selectedOption, onOptionChange } = useBooleanAdvancedFilter({
    name,
    path,
    initialValue,
    index,
    type
  })

  const shortText = value ? t('yes') : t('no')
  const longText = `<b>${shortText}</b>`

  const tooltipText = `${snakeToTitle(name)} ${longText}`

  const hasChanges = selectedOption !== initialValue

  return (
    <FilterItemDropdown
      buttonDisabled={disabled}
      content={shortText}
      contentType="label"
      disabled={!hasChanges || disabled}
      dropdown={
        <BooleanFilterContent onOptionChange={onOptionChange} selectedOption={selectedOption} />
      }
      onApply={update}
      title={title}
      tooltip={tooltipText}
    />
  )
}

export default BooleanFilterApplied
