import Button from '@/components/atoms/button'
import { DropdownMenuContent } from '@/components/atoms/options-menu'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { ClipboardText, IconContext, Plus, UserList } from '@phosphor-icons/react'
import { SentimentSubMenu } from '../SentimentFilter'
import { FeedbackIntentionSubMenu } from '../FeedbackIntention'
import RatingSection from './RatingSection'
import { useFiltersStore } from '@/store'

import useFiltersQuery from '@/hooks/filters/useFiltersQuery'
import GenericFiltersSection from './GenericFiltersSection'
// import { TopicsFilterSubMenu } from '../TopicsFilter'
import useUser from '@/hooks/useUser'
import useAccountsStore from '@/store/useAccountsStore'
import useCustomerUsersStore from '@/store/useCustomerUsersStore'
import { useQuery } from '@tanstack/react-query'
import AccountsFiltersSection from '../CustomerRecordsFilter/Accounts/AccountsFiltersSection'
import FeedbackDataTypeSubmenu from '../FeedbackType/FeedbackDataTypeSubmenu'
import TopicGroupsFilterSection from '../TopicGroupsFilter/TopicGroupsFiltersSection'
import CustomerUsersFiltersSection from '../CustomerRecordsFilter/Users/CustomerUsersFiltersSection'

const AddFilterMenu = () => {
  const { feedbackDetailsFilters, customFieldsFilters, isLoading, feedbackDataType } =
    useFiltersQuery()

  const resetDraft = useFiltersStore(state => state.resetDraft)

  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      resetDraft()
    }
  }

  const { currentUser } = useUser()

  const hasAccounts = useAccountsStore(state => state.hasAccounts)
  const checkHasAccounts = useAccountsStore(state => state.checkHasAccounts)

  const hasCustomerUsers = useCustomerUsersStore(state => state.hasCustomerUsers)
  const checkHasCustomerUsers = useCustomerUsersStore(state => state.checkHasCustomerUsers)

  useQuery({
    queryKey: ['customer-records-check'],
    queryFn: async () => {
      return Promise.all([
        checkHasAccounts(currentUser?.organization_id),
        checkHasCustomerUsers(currentUser?.organization_id)
      ])
    },
    retry: false,
    refetchOnMount: true
  })

  return (
    <DropdownMenuPrimitive.Root onOpenChange={onOpenChange}>
      <DropdownMenuPrimitive.Trigger asChild>
        <Button css={{ mr: 'auto', pAll: 0 }} variant="link">
          <Plus weight="bold" />
          <span>Add filter</span>
        </Button>
      </DropdownMenuPrimitive.Trigger>

      <DropdownMenuPrimitive.Portal>
        <DropdownMenuContent
          align="start"
          css={{ width: 320, padding: '$nano', gap: 0 }}
          hideWhenDetached
          onClick={e => {
            e.stopPropagation()
          }}
          sideOffset={8}
        >
          <IconContext.Provider value={{ size: 16 }}>
            <FeedbackIntentionSubMenu />
            <SentimentSubMenu />
            <RatingSection />

            <TopicGroupsFilterSection />

            {!!feedbackDataType && <FeedbackDataTypeSubmenu feedbackDataType={feedbackDataType} />}

            <GenericFiltersSection
              filters={feedbackDetailsFilters}
              icon={<ClipboardText />}
              isLoading={isLoading}
              title="Feedback details"
            />
            <GenericFiltersSection
              filters={customFieldsFilters}
              icon={<UserList />}
              isLoading={isLoading}
              title="Custom fields"
            />

            <AccountsFiltersSection enabled={hasAccounts} />
            <CustomerUsersFiltersSection enabled={hasCustomerUsers} />
          </IconContext.Provider>
        </DropdownMenuContent>
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Root>
  )
}

export default AddFilterMenu
