import useLetterCase from '@/hooks/useLetterCase'
import { FilterText } from '@/types/filters/Filters'
import { FilterItemDropdown } from '../../AppliedFilters/FilterItem'
import { useState } from 'react'
import { TextFilterContent } from '../../FiltersSidebar/TextGenericFilter'
import { useFeedFiltersStore } from '@/store/useFiltersStore'

interface Props {
  filter: FilterText
  buttonDisabled?: boolean
}

function FilterItemDropdownText({ filter, buttonDisabled }: Props) {
  const [open, setOpen] = useState(false)

  const setTextFilter = useFeedFiltersStore(state => state.setTextFilter)
  const removeTextFilter = useFeedFiltersStore(state => state.removeTextFilter)

  const { snakeToTitle } = useLetterCase()

  function getTooltipText(textFilter: FilterText) {
    return `Is <b>${snakeToTitle(textFilter.name)}</b> > <b>${filter.value}</b>`
  }

  const [textInput, setTextInput] = useState('')

  const isDisabled = textInput === filter.value || textInput.length === 0

  const onApply = () => {
    setTextFilter({
      key: filter.key,
      name: filter.name,
      value: textInput
    })
  }

  const onClear = () => {
    setTextInput('')
    removeTextFilter(filter.key)
  }

  return (
    <FilterItemDropdown
      buttonDisabled={buttonDisabled}
      content={filter.value}
      contentType="label"
      disabled={isDisabled}
      dropdown={
        <TextFilterContent onClearClick={onClear} onValueChange={setTextInput} value={textInput} />
      }
      onApply={onApply}
      onOpenChange={setOpen}
      open={open}
      title={snakeToTitle(filter.name)}
      tooltip={getTooltipText(filter)}
    />
  )
}

export default FilterItemDropdownText
