import React, { useEffect, useRef } from 'react'

function useDidUpdateEffect(
  effect: React.EffectCallback,
  deps: React.DependencyList,
  stringfy = false
) {
  const didMountRef = useRef(false)

  // biome-ignore lint/correctness/useExhaustiveDependencies: custom useEffect
  useEffect(
    () => {
      if (didMountRef.current) {
        return effect()
      }
      didMountRef.current = true
    },
    stringfy ? [JSON.stringify(deps)] : deps
  )
}

export default useDidUpdateEffect
