import { CSS, styled } from '@/theme'
import { AccordionItem } from '@/components/molecules/accordion'

const topicRowStyles: CSS = {
  bBottom: '$stroke',
  padding: '0 32px',
  alignItems: 'center'
}

export const TopicRow = styled('div', {
  ...topicRowStyles,
  display: 'flex',
  gap: 0,
  height: 40,
  paddingLeft: 58,
  backgroundColor: '$neutralHighMedium',

  '&:first-child': {
    bTop: '$stroke'
  }
})

export const TopicInfoRow = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  marginLeft: 'auto',
  width: 80,
  paddingRight: 32
})

export const TopicAccordionItem = styled(AccordionItem, {
  ...topicRowStyles,
  paddingRight: 0
})
