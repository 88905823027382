import { styled } from '@/theme'

export const Container = styled('div', {
  display: 'grid',
  gridAutoRows: '1fr',
  gridTemplateColumns: 'repeat(auto-fill, minmax(312px, 1fr))',
  gap: '$xs'
})

export const LinkButton = styled('button', {
  backgroundColor: 'transparent',
  border: 0,
  color: '$brandPrimaryPure',
  cursor: 'pointer',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  outlineOffset: 1,

  '&:hover': {
    textDecoration: 'underline'
  }
})
