import NoResultsIllustration from '@/assets/drawings/no_results.png'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { CSS } from '@/theme'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  title?: ReactNode
  description?: ReactNode
  hideDescription?: boolean
  css?: CSS
}

const NoResults = ({ hideDescription = false, css, title, description }: Props) => {
  const { t } = useTranslation()
  return (
    <FlexContainer alignItems="center" css={{ mt: '$sm', ...css }} direction="column" gap="sm">
      {title ?? (
        <Text fontSize={'xxs'} fontWeight="semibold">
          {t('noResultsFound')}
        </Text>
      )}
      <img
        alt="A black and white image of a bird looking at empty boxes."
        height={221}
        src={NoResultsIllustration}
        width={352}
      />
      {!hideDescription && (
        <FlexContainer alignItems="center" direction="column" gap="micro">
          {description ?? (
            <Text css={{ fontSize: '$xxs', lineHeight: '$lg', maxWidth: 495, textAlign: 'center' }}>
              {t('makeSureYouveWrittenYourSearchCorrectly')}
            </Text>
          )}
        </FlexContainer>
      )}
    </FlexContainer>
  )
}

export default NoResults
