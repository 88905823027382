import { AreaOfInterestData } from '@/types/area/AreaOfInterest'
import { useMemo } from 'react'
import { NormalizedAreaTableData, NormalizedAreaTypes } from './AreaOfInterestTable.types'
import {
  INVALID_METRIC_TABLE_COLUMNS,
  ONGOING_STATUS,
  sortByOpportunityStatus
} from '@/utils/opportunityUtils'
import { OpportunityStatus } from '@/types/opportunity/Opportunity'
import useAllMetrics from '@/hooks/metrics/useAllMetricItems'

const useNormalizedAreas = (
  areas: AreaOfInterestData[],
  unmappedAreas?: AreaOfInterestData,
  sort?: { sortColumn?: string; sortDirection: 'desc' | 'asc' }
) => {
  const { allMetricItems, getMetricByColumn } = useAllMetrics()

  const areasArray = useMemo(
    () => (unmappedAreas ? [unmappedAreas, ...areas] : areas),
    [areas, unmappedAreas]
  )

  const normalizedAreas = useMemo(() => {
    const _normalizedAreas: NormalizedAreaTableData[] = areasArray.map(area => {
      const normalizedArea = {
        type: 'area-interest' as NormalizedAreaTypes,
        originalArea: area,
        id: area.id,
        name: area.name,
        opportunityCount: area.opportunityCount,
        metrics: area.metrics,
        opportunities: (area.opportunities ?? [])
          .filter(opportunity => ONGOING_STATUS.includes(opportunity.status))
          .map(opportunity => ({
            type: 'opportunity' as NormalizedAreaTypes,
            originalOpportunity: opportunity,
            originalArea: area,
            id: opportunity.id,
            favorite: false,
            name: opportunity.name,
            new: opportunity.new,
            opportunityCount: 0,
            metrics: opportunity.metrics,
            status: opportunity.status,
            createdAt: opportunity.createdAt
          })) as NormalizedAreaTableData[]
      }

      if (sort) {
        const { sortColumn, sortDirection } = sort
        if (INVALID_METRIC_TABLE_COLUMNS.includes(sortColumn ?? '')) {
          if (sortColumn === 'name') {
            normalizedArea.opportunities.sort((a, b) =>
              sortDirection === 'desc' ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name)
            )
          }
          if (sortColumn === 'status') {
            normalizedArea.opportunities.sort((a, b) =>
              sortDirection === 'desc'
                ? sortByOpportunityStatus(b.status as OpportunityStatus) -
                  sortByOpportunityStatus(a.status as OpportunityStatus)
                : sortByOpportunityStatus(a.status as OpportunityStatus) -
                  sortByOpportunityStatus(b.status as OpportunityStatus)
            )
          }
        } else {
          normalizedArea.opportunities.sort((a, b) => a.name.localeCompare(b.name))

          const countMetric = allMetricItems.count.metric
          const sortingMetric = sortColumn
            ? getMetricByColumn(sortColumn)?.metric ?? countMetric
            : countMetric

          normalizedArea.opportunities.sort((a, b) => {
            const metricAValue =
              a.metrics.find(metric => metric.label === sortingMetric.label)?.current_value ?? 0
            const metricBValue =
              b.metrics.find(metric => metric.label === sortingMetric.label)?.current_value ?? 0

            return sortDirection === 'desc'
              ? metricBValue - metricAValue
              : metricAValue - metricBValue
          })
        }
      }

      return normalizedArea
    })

    return _normalizedAreas
  }, [areasArray, allMetricItems, sort, getMetricByColumn])

  return normalizedAreas
}

export default useNormalizedAreas
