import { checkHasScroll, checkScrollToBottom } from '@/utils/scroll'
import { useRef, useState } from 'react'

const useScrollFade = () => {
  const viewportRef = useRef<HTMLDivElement>(null)
  const [fade, setFade] = useState(false)

  if (checkHasScroll(viewportRef.current)) {
    const shouldFade = !checkScrollToBottom(viewportRef.current)
    if (fade !== shouldFade) setFade(shouldFade)
  } else if (fade) {
    setFade(false)
  }

  function onScroll() {
    const scrollToBottom = checkScrollToBottom(viewportRef.current)
    setFade(!scrollToBottom)
  }

  return {
    viewportRef,
    fade,
    onScroll
  }
}

export default useScrollFade
