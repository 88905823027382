import { TopicCategory } from '@/types/classification'
import {
  FilterBoolean,
  FilterDatetime,
  FilterNumeric,
  FilterString,
  FilterText,
  MultiSelect
} from '@/types/filters/Filters'
import { KeywordSortType } from '@/types/keywords'
import { INSENSITIVE_CASE_FILTERS } from '@/utils/filters'
import {
  CustomerRecordsDateFilter,
  FiltersStateProperties,
  UseFiltersState
} from './useFiltersStore.types'
import payloadParsersProperties from './payloadParsersProperties'
import { getDateRangeFromNow } from '@/utils/date'
import { StateCreator } from 'zustand'
import { isAreaOfInterestFilter } from '@/utils/areaOfInterest'

export const initialState: FiltersStateProperties = {
  search: '',
  areaSearch: '',

  dateRange: getDateRangeFromNow('30days'),
  datePeriod: '30days',

  numericFilters: [],
  datetimeFilters: [],
  stringFilters: [],
  booleanFilters: [],
  textFilters: [],

  productAreaTopics: { draftSelected: [], selected: [] },
  otherTopics: { draftSelected: [], selected: [] },
  productAreaThemes: { draftSelected: [], selected: [] },
  otherThemes: { draftSelected: [], selected: [] },
  unclassifiedTopics: { draftSelected: [], selected: [] },
  ungroupedTopics: { draftSelected: [], selected: [] },

  sortDirection: 'desc',
  orderBy: 'posted_at',
  keywordSortType: KeywordSortType.FREQUENCY,

  filtersLoaded: false,
  volumeBy: 'ticket',

  filtersByURL: null,

  accountsStringFilters: {},
  accountsDateFilters: [],
  accountNumericFilters: [],
  accountBooleanFilters: [],

  usersStringFilters: {},
  usersDateFilters: [],
  usersNumericFilters: [],
  usersBooleanFilters: []
}

const createFiltersStore: StateCreator<UseFiltersState> = (set, get) => ({
  ...initialState,

  setSearch: search => set(() => ({ search })),
  setAreaSearch: areaSearch => set(() => ({ areaSearch })),

  setDateRange: (range, datePeriod) =>
    set(state => ({
      dateRange: range,
      datePeriod: datePeriod || state.datePeriod
    })),

  setNumericFilter: ({ key, type, name, value }, apply = false) =>
    set(state => ({
      ...state,
      numericFilters: state.numericFilters.find(filter => filter.key === key)
        ? state.numericFilters.map(
            (filter): FilterNumeric =>
              filter.key === key
                ? { ...filter, draftValue: value, value: apply ? value : filter.value }
                : filter
          )
        : [
            ...state.numericFilters,
            {
              key,
              type,
              name,
              draftOption: 'all',
              option: 'all',
              draftValue: value,
              value: apply ? value : null
            }
          ]
    })),
  setNumericOption: ({ key, type, name, option }, apply = false) =>
    set(state => ({
      ...state,
      numericFilters: state.numericFilters.find(filter => filter.key === key)
        ? state.numericFilters.map(
            (filter): FilterNumeric =>
              filter.key === key
                ? { ...filter, draftOption: option, option: apply ? option : filter.option }
                : filter
          )
        : [
            ...state.numericFilters,
            {
              key,
              type,
              name,
              draftOption: option,
              option: apply ? option : 'all',
              draftValue: null,
              value: null
            }
          ]
    })),
  setDatetimeFilter: ({ key, type, name, value, period }, apply = false) =>
    set(state => ({
      ...state,
      datetimeFilters: state.datetimeFilters.find(filter => filter.key === key)
        ? state.datetimeFilters.map(
            (filter): FilterDatetime =>
              filter.key === key
                ? {
                    ...filter,
                    draftValue: value,
                    value: apply ? value : filter.value,
                    draftPeriod: period,
                    period: apply ? period : filter.period
                  }
                : filter
          )
        : [
            ...state.datetimeFilters,
            {
              key,
              type,
              name,
              draftValue: value,
              value: apply ? value : null,
              draftPeriod: period,
              period: apply ? period : 'allTime'
            }
          ]
    })),
  selectStringFilter: ({ key, type, name, selected }, apply = false) =>
    set(state => {
      const selectedValues = INSENSITIVE_CASE_FILTERS.includes(key)
        ? selected.map(value => value.toLowerCase())
        : selected

      return {
        ...state,
        stringFilters: state.stringFilters.find(filter => filter.key === key)
          ? state.stringFilters.map(
              (filter): FilterString =>
                filter.key === key
                  ? {
                      ...filter,
                      draftSelected: selectedValues,
                      selected: apply ? selectedValues : filter.selected
                    }
                  : filter
            )
          : [
              ...state.stringFilters,
              {
                key,
                type,
                name,
                selected: apply ? selectedValues : [],
                draftSelected: selectedValues
              }
            ]
      }
    }),
  setBooleanFilter: ({ key, type, name, value }, apply = false) =>
    set(state => ({
      ...state,
      booleanFilters: state.booleanFilters.find(filter => filter.key === key)
        ? state.booleanFilters.map(
            (filter): FilterBoolean =>
              filter.key === key
                ? {
                    ...filter,
                    draftValue: value,
                    value: apply ? value : filter.value
                  }
                : filter
          )
        : [
            ...state.booleanFilters,
            {
              key,
              type,
              name,
              value: apply ? value : null,
              draftValue: value
            }
          ]
    })),

  setTextFilter: ({ key, name, value }) =>
    set(state => ({
      ...state,
      textFilters: state.textFilters.find(filter => filter.key === key)
        ? state.textFilters.map(
            (filter): FilterText =>
              filter.key === key
                ? {
                    ...filter,
                    value
                  }
                : filter
          )
        : [
            ...state.textFilters,
            {
              key,
              name,
              value
            }
          ]
    })),

  selectTheme: ({ themeId, topicCategory }) =>
    set(state => {
      const newThemes =
        topicCategory === 'PRODUCT_AREA'
          ? [...state.productAreaThemes.draftSelected, themeId]
          : [...state.otherThemes.draftSelected, themeId]
      return topicCategory === 'PRODUCT_AREA'
        ? {
            productAreaThemes: {
              selected: state.productAreaThemes.selected,
              draftSelected: newThemes
            }
          }
        : { otherThemes: { selected: state.otherThemes.selected, draftSelected: newThemes } }
    }),
  unselectTheme: ({ themeId, topicCategory }) =>
    set(state => {
      const newThemes =
        topicCategory === 'PRODUCT_AREA'
          ? state.productAreaThemes.draftSelected.filter(id => id !== themeId)
          : state.otherThemes.draftSelected.filter(id => id !== themeId)
      return topicCategory === 'PRODUCT_AREA'
        ? {
            productAreaThemes: {
              selected: state.productAreaThemes.selected,
              draftSelected: newThemes
            }
          }
        : { otherThemes: { selected: state.otherThemes.selected, draftSelected: newThemes } }
    }),
  unselectThemes: ({ themeIds, topicCategory }) =>
    set(state => {
      const newThemes =
        topicCategory === 'PRODUCT_AREA'
          ? state.productAreaThemes.draftSelected.filter(id => !themeIds.includes(id))
          : state.otherThemes.draftSelected.filter(id => !themeIds.includes(id))
      return topicCategory === 'PRODUCT_AREA'
        ? {
            productAreaThemes: {
              selected: state.productAreaThemes.selected,
              draftSelected: newThemes
            }
          }
        : { otherThemes: { selected: state.otherThemes.selected, draftSelected: newThemes } }
    }),
  unselectAllThemes: () =>
    set(state => ({
      productAreaThemes: { selected: state.productAreaThemes.selected, draftSelected: [] },
      otherThemes: { selected: state.otherThemes.selected, draftSelected: [] }
    })),
  selectThemes: ({ themeIds, topicCategory }) =>
    set(state => {
      const _newThemes =
        topicCategory === 'PRODUCT_AREA'
          ? [...state.productAreaThemes.draftSelected, ...themeIds]
          : [...state.otherThemes.draftSelected, ...themeIds]
      const newThemes = [...new Set(_newThemes)]
      return topicCategory === 'PRODUCT_AREA'
        ? {
            productAreaThemes: {
              selected: state.productAreaThemes.selected,
              draftSelected: newThemes
            }
          }
        : { otherThemes: { selected: state.otherThemes.selected, draftSelected: newThemes } }
    }),
  selectTopics: ({ topicIds, topicCategory }) =>
    set(state => {
      const newTopics =
        topicCategory === 'PRODUCT_AREA'
          ? [...state.productAreaTopics.draftSelected, ...topicIds]
          : [...state.otherTopics.draftSelected, ...topicIds]
      return topicCategory === 'PRODUCT_AREA'
        ? {
            productAreaTopics: {
              selected: state.productAreaTopics.selected,
              draftSelected: newTopics
            }
          }
        : { otherTopics: { selected: state.otherTopics.selected, draftSelected: newTopics } }
    }),
  unselectTopics: ({ topicIds, topicCategory }) =>
    set(state => {
      const newTopics: string[] =
        topicCategory === 'PRODUCT_AREA'
          ? state.productAreaTopics.draftSelected.filter(topicId => !topicIds.includes(topicId))
          : state.otherTopics.draftSelected.filter(topicId => !topicIds.includes(topicId))

      return topicCategory === 'PRODUCT_AREA'
        ? {
            productAreaTopics: {
              selected: state.productAreaTopics.selected,
              draftSelected: newTopics
            }
          }
        : { otherTopics: { selected: state.otherTopics.selected, draftSelected: newTopics } }
    }),
  setDraftTopicsAndThemes: () =>
    set(state => ({
      productAreaTopics: {
        selected: state.productAreaTopics.selected,
        draftSelected: state.productAreaTopics.selected
      },
      productAreaThemes: {
        selected: state.productAreaThemes.selected,
        draftSelected: state.productAreaThemes.selected
      },
      otherTopics: {
        selected: state.otherTopics.selected,
        draftSelected: state.otherTopics.selected
      },
      otherThemes: {
        selected: state.otherThemes.selected,
        draftSelected: state.otherThemes.selected
      }
    })),
  setDraftProductArea: (id, data) => {
    if (id === 'theme')
      set(state => ({
        productAreaThemes: { selected: state.productAreaThemes.selected, draftSelected: data }
      }))
    else if (id === 'topic')
      set(state => ({
        productAreaTopics: { selected: state.productAreaTopics.selected, draftSelected: data }
      }))
  },
  setDraftOtherTopics: (id, data) => {
    if (id === 'theme')
      set(state => ({
        otherThemes: { selected: state.otherThemes.selected, draftSelected: data }
      }))
    else if (id === 'topic')
      set(state => ({
        otherTopics: { selected: state.otherTopics.selected, draftSelected: data }
      }))
  },

  applyProductArea: () =>
    set(state => ({
      productAreaThemes: {
        draftSelected: state.productAreaThemes.draftSelected,
        selected: state.productAreaThemes.draftSelected
      },
      productAreaTopics: {
        draftSelected: state.productAreaTopics.draftSelected,
        selected: state.productAreaTopics.draftSelected
      },
      unclassifiedTopics: {
        draftSelected: state.unclassifiedTopics.draftSelected,
        selected: state.unclassifiedTopics.draftSelected.includes('PRODUCT_AREA')
          ? ([
              ...new Set([...state.unclassifiedTopics.selected, 'PRODUCT_AREA'])
            ] as TopicCategory[])
          : state.unclassifiedTopics.selected.filter(value => value !== 'PRODUCT_AREA')
      }
    })),
  applyOtherTopics: () =>
    set(state => ({
      otherThemes: {
        draftSelected: state.otherThemes.draftSelected,
        selected: state.otherThemes.draftSelected
      },
      otherTopics: {
        draftSelected: state.otherTopics.draftSelected,
        selected: state.otherTopics.draftSelected
      },
      unclassifiedTopics: {
        draftSelected: state.unclassifiedTopics.draftSelected,
        selected: state.unclassifiedTopics.draftSelected.includes('OTHER')
          ? ([...new Set([...state.unclassifiedTopics.selected, 'OTHER'])] as TopicCategory[])
          : state.unclassifiedTopics.selected.filter(value => value !== 'OTHER')
      }
    })),
  toggleUnclassifiedTopics: topicCategory =>
    set(state => {
      const draftSelected = state.unclassifiedTopics.draftSelected
      const newUnclassifiedTopics = draftSelected.includes(topicCategory)
        ? draftSelected.filter(tc => tc !== topicCategory)
        : [...draftSelected, topicCategory]

      return {
        ...state,
        unclassifiedTopics: {
          selected: state.unclassifiedTopics.selected,
          draftSelected: newUnclassifiedTopics
        }
      }
    }),
  setUnclassifiedTopics: topicCategories =>
    set(state => ({
      ...state,
      unclassifiedTopics: {
        selected: state.unclassifiedTopics.selected,
        draftSelected: topicCategories
      }
    })),

  setSortDirection: sortDirection => set(() => ({ sortDirection })),
  setOrderBy: orderBy => set(() => ({ orderBy })),
  setKeywordSortType: keywordSortType => set(() => ({ keywordSortType })),

  clearDraft: () =>
    set(state => ({
      ...state,
      numericFilters: state.numericFilters.map(filter => ({
        ...filter,
        draftOption: 'all',
        draftValue: null
      })),
      datetimeFilters: state.datetimeFilters.map(filter => ({
        ...filter,
        draftValue: null
      })),
      stringFilters: state.stringFilters.map(filter => ({
        ...filter,
        draftSelected: []
      })),
      booleanFilters: state.booleanFilters.map(filter => ({
        ...filter,
        draftValue: null
      })),
      productAreaTopics: { selected: state.productAreaTopics.selected, draftSelected: [] },
      otherTopics: { selected: state.otherTopics.selected, draftSelected: [] },
      productAreaThemes: { selected: state.productAreaThemes.selected, draftSelected: [] },
      otherThemes: { selected: state.otherThemes.selected, draftSelected: [] },
      unclassifiedTopics: { selected: state.unclassifiedTopics.selected, draftSelected: [] },
      accounts: {},
      users: {},
      accountPlanDate: {}
    })),
  resetDraft: () => {
    const { accountsStringFilters, usersStringFilters } = get()
    const resetedAccountsFilter = Object.entries(accountsStringFilters).reduce(
      (acc, currentAccountFilter) => {
        const [currentKey, currentValue] = currentAccountFilter
        acc[currentKey] = {
          draftSelected: currentValue.selected,
          selected: currentValue.selected
        }
        return acc
      },
      {} as Record<string, MultiSelect<string>>
    )

    const resetedUsersFilter = Object.entries(usersStringFilters).reduce(
      (acc, currentUserFilter) => {
        const [currentKey, currentValue] = currentUserFilter
        acc[currentKey] = {
          draftSelected: currentValue.selected,
          selected: currentValue.selected
        }
        return acc
      },
      {} as Record<string, MultiSelect<string>>
    )

    set(state => {
      return {
        ...state,
        numericFilters: state.numericFilters.map(filter => ({
          ...filter,
          draftOption: filter.option,
          draftValue: filter.value
        })),
        datetimeFilters: state.datetimeFilters.map(filter => ({
          ...filter,
          draftValue: filter.value
        })),
        stringFilters: state.stringFilters.map(filter => ({
          ...filter,
          draftSelected: filter.selected
        })),
        booleanFilters: state.booleanFilters.map(filter => ({
          ...filter,
          draftValue: filter.value
        })),
        productAreaTopics: {
          selected: state.productAreaTopics.selected,
          draftSelected: state.productAreaTopics.selected
        },
        otherTopics: {
          selected: state.otherTopics.selected,
          draftSelected: state.otherTopics.selected
        },
        ungroupedTopics: {
          selected: state.ungroupedTopics.selected,
          draftSelected: state.ungroupedTopics.selected
        },
        productAreaThemes: {
          selected: state.productAreaThemes.selected,
          draftSelected: state.productAreaThemes.selected
        },
        otherThemes: {
          selected: state.otherThemes.selected,
          draftSelected: state.otherThemes.selected
        },
        unclassifiedTopics: {
          selected: state.unclassifiedTopics.selected,
          draftSelected: state.unclassifiedTopics.selected
        },
        accounts: resetedAccountsFilter,
        users: resetedUsersFilter,
        accountsDateFilters: state.accountsDateFilters.map(filter => ({
          ...filter,
          draftSelected: filter.selected
        })),
        accountNumericFilters: state.accountNumericFilters.map(filter => ({
          ...filter,
          draftOption: filter.option,
          draftValue: filter.value
        }))
      }
    })
  },
  apply: () => {
    const {
      numericFilters,
      datetimeFilters,
      stringFilters,
      booleanFilters,
      productAreaTopics,
      otherTopics,
      productAreaThemes,
      otherThemes,
      unclassifiedTopics,
      accountsStringFilters,
      accountsDateFilters,
      accountNumericFilters,
      usersStringFilters,
      usersDateFilters,
      usersNumericFilters,
      ungroupedTopics
    } = get()

    const hasAccountsChanges = Object.values(accountsStringFilters).some(
      value => JSON.stringify(value.draftSelected) !== JSON.stringify(value.selected)
    )

    const hasAccountDateFiltersChange = !accountsDateFilters.every(
      filter => JSON.stringify(filter.draftSelected) === JSON.stringify(filter.selected)
    )

    const hasAccountNumericChanges = !accountNumericFilters.every(
      filter =>
        JSON.stringify(filter.draftValue) === JSON.stringify(filter.value) &&
        filter.draftOption === filter.option
    )

    const hasUsersChanges = Object.values(usersStringFilters).some(
      value => JSON.stringify(value.draftSelected) !== JSON.stringify(value.selected)
    )

    const hasUsersDateFiltersChange = !usersDateFilters.every(
      filter => JSON.stringify(filter.draftSelected) === JSON.stringify(filter.selected)
    )

    const hasUsersNumericChanges = !usersNumericFilters.every(
      filter =>
        JSON.stringify(filter.draftValue) === JSON.stringify(filter.value) &&
        filter.draftOption === filter.option
    )

    const hasChanged: boolean =
      !numericFilters.every(
        filter =>
          JSON.stringify(filter.draftValue) === JSON.stringify(filter.value) &&
          filter.draftOption === filter.option
      ) ||
      !datetimeFilters.every(
        filter => JSON.stringify(filter.draftValue) === JSON.stringify(filter.value)
      ) ||
      !stringFilters.every(
        filter => JSON.stringify(filter.draftSelected) === JSON.stringify(filter.selected)
      ) ||
      !booleanFilters.every(
        filter => JSON.stringify(filter.draftValue) === JSON.stringify(filter.value)
      ) ||
      JSON.stringify(productAreaTopics.draftSelected) !==
        JSON.stringify(productAreaTopics.selected) ||
      JSON.stringify(otherTopics.draftSelected) !== JSON.stringify(otherTopics.selected) ||
      JSON.stringify(productAreaThemes.draftSelected) !==
        JSON.stringify(productAreaThemes.selected) ||
      JSON.stringify(otherThemes.draftSelected) !== JSON.stringify(otherThemes.selected) ||
      JSON.stringify(unclassifiedTopics.draftSelected) !==
        JSON.stringify(unclassifiedTopics.selected) ||
      JSON.stringify(ungroupedTopics.draftSelected) !== JSON.stringify(ungroupedTopics.selected) ||
      hasAccountsChanges ||
      hasAccountDateFiltersChange ||
      hasAccountNumericChanges ||
      hasUsersChanges ||
      hasUsersDateFiltersChange ||
      hasUsersNumericChanges

    const getNewAccounts = () => {
      if (!hasAccountsChanges) return accountsStringFilters

      const newAccounts: Record<string, MultiSelect> = {}
      Object.entries(accountsStringFilters).forEach(([key, value]) => {
        newAccounts[key] = {
          draftSelected: value.draftSelected,
          selected: value.draftSelected
        }
      })

      return newAccounts
    }

    const getNewUsers = () => {
      if (!hasUsersChanges) return usersStringFilters

      const newUsers: Record<string, MultiSelect> = {}
      Object.entries(usersStringFilters).forEach(([key, value]) => {
        newUsers[key] = {
          draftSelected: value.draftSelected,
          selected: value.draftSelected
        }
      })

      return newUsers
    }

    if (hasChanged) {
      set(state => ({
        ...state,
        numericFilters: numericFilters.map(filter => ({
          ...filter,
          value: filter.draftValue,
          option: filter.draftOption
        })),
        datetimeFilters: datetimeFilters.map(filter => ({
          ...filter,
          value: filter.draftValue,
          period: filter.draftPeriod
        })),
        stringFilters: stringFilters.map(filter => ({
          ...filter,
          selected: filter.draftSelected
        })),
        booleanFilters: booleanFilters.map(filter => ({ ...filter, value: filter.draftValue })),
        productAreaTopics: {
          ...state.productAreaTopics,
          selected: state.productAreaTopics.draftSelected
        },
        otherTopics: { ...state.otherTopics, selected: state.otherTopics.draftSelected },
        ungroupedTopics: {
          ...state.ungroupedTopics,
          selected: state.ungroupedTopics.draftSelected
        },
        productAreaThemes: {
          ...state.productAreaThemes,
          selected: state.productAreaThemes.draftSelected
        },
        otherThemes: { ...state.otherThemes, selected: state.otherThemes.draftSelected },
        unclassifiedTopics: {
          ...state.unclassifiedTopics,
          selected: state.unclassifiedTopics.draftSelected
        },
        accountsStringFilters: getNewAccounts(),
        accountsDateFilters: state.accountsDateFilters.map(filter => ({
          ...filter,
          selected: filter.draftSelected
        })),
        accountNumericFilters: accountNumericFilters.map(filter => ({
          ...filter,
          value: filter.draftValue,
          option: filter.draftOption
        })),
        usersStringFilters: getNewUsers(),
        usersDateFilter: state.usersDateFilters.map(filter => ({
          ...filter,
          selected: filter.draftSelected
        })),
        usersNumericFilters: state.usersNumericFilters.map(filter => ({
          ...filter,
          value: filter.draftValue,
          option: filter.draftOption
        }))
      }))
    }

    return hasChanged
  },
  applyThemesAndTopics: () => {
    const {
      productAreaTopics,
      otherTopics,
      productAreaThemes,
      otherThemes,
      unclassifiedTopics,
      ungroupedTopics
    } = get()
    const hasChanged: boolean =
      JSON.stringify(productAreaTopics.draftSelected) !==
        JSON.stringify(productAreaTopics.selected) ||
      JSON.stringify(otherTopics.draftSelected) !== JSON.stringify(otherTopics.selected) ||
      JSON.stringify(productAreaThemes.draftSelected) !==
        JSON.stringify(productAreaThemes.selected) ||
      JSON.stringify(otherThemes.draftSelected) !== JSON.stringify(otherThemes.selected) ||
      JSON.stringify(unclassifiedTopics.draftSelected) !==
        JSON.stringify(unclassifiedTopics.selected) ||
      JSON.stringify(ungroupedTopics.draftSelected) !== JSON.stringify(ungroupedTopics.selected)

    if (hasChanged) {
      set(state => ({
        ...state,
        productAreaTopics: {
          ...state.productAreaTopics,
          selected: state.productAreaTopics.draftSelected
        },
        otherTopics: { ...state.otherTopics, selected: state.otherTopics.draftSelected },
        productAreaThemes: {
          ...state.productAreaThemes,
          selected: state.productAreaThemes.draftSelected
        },
        otherThemes: { ...state.otherThemes, selected: state.otherThemes.draftSelected },
        unclassifiedTopics: {
          ...state.unclassifiedTopics,
          selected: state.unclassifiedTopics.draftSelected
        },
        ungroupedTopics: {
          ...state.ungroupedTopics,
          selected: state.ungroupedTopics.draftSelected
        }
      }))
    }

    return hasChanged
  },
  clearAndApply: alsoTopics =>
    set(state => {
      const topicFilters = alsoTopics
        ? {}
        : {
            productAreaThemes: state.productAreaThemes,
            productAreaTopics: state.productAreaTopics,
            otherThemes: state.otherThemes,
            otherTopics: state.otherTopics,
            ungroupedTopics: state.ungroupedTopics
          }

      return {
        ...initialState,
        ...topicFilters,
        search: state.search,
        areaSearch: state.areaSearch,
        sortDirection: state.sortDirection,
        orderBy: state.orderBy,
        keywordSortType: state.keywordSortType,
        filtersLoaded: state.filtersLoaded
      }
    }),

  clearButKeepAreaOfInterest: areaOfInterest =>
    set(state => {
      const { numericFilters, datetimeFilters, stringFilters, textFilters, booleanFilters } = state

      const newNumericFilters = numericFilters.filter(filter =>
        isAreaOfInterestFilter({
          key: filter.key,
          values: filter.value,
          areaOfInterest
        })
      )

      const newDatetimeFilters = datetimeFilters.filter(filter => {
        const values = [filter.value?.start.toString() || '', filter.value?.end.toString() || '']
        return isAreaOfInterestFilter({
          key: filter.key,
          values,
          areaOfInterest
        })
      })

      const newStringFilters = stringFilters.filter(filter =>
        isAreaOfInterestFilter({
          key: filter.key,
          values: filter.selected,
          areaOfInterest
        })
      )

      const newTextFilters = textFilters.filter(filter =>
        isAreaOfInterestFilter({
          key: filter.key,
          values: filter.value,
          areaOfInterest
        })
      )

      const newBooleanFilters = booleanFilters.filter(filter =>
        isAreaOfInterestFilter({
          key: filter.key,
          values: filter.value,
          areaOfInterest
        })
      )

      return {
        ...state,
        dateRange: initialState.dateRange,
        datePeriod: initialState.datePeriod,
        areaSearch: '',
        numericFilters: newNumericFilters,
        datetimeFilters: newDatetimeFilters,
        stringFilters: newStringFilters,
        booleanFilters: newBooleanFilters,
        textFilters: newTextFilters
      }
    }),
  resetAll: ({ keepDate } = { keepDate: false }) =>
    set(state => {
      const newState = { ...initialState }
      if (keepDate) {
        newState.dateRange = state.dateRange
        newState.datePeriod = state.datePeriod
      }
      return newState
    }),

  removeNumericFilter: key =>
    set(state => ({
      ...state,
      numericFilters: state.numericFilters.map(
        (filter): FilterNumeric =>
          filter.key === key ? { ...filter, draftOption: 'all', draftValue: null } : filter
      )
    })),
  removeDatetimeFilter: key =>
    set(state => ({
      ...state,
      datetimeFilters: state.datetimeFilters.map(
        (filter): FilterDatetime => (filter.key === key ? { ...filter, draftValue: null } : filter)
      )
    })),
  removeStringFilter: (key, value) => {
    set(state => ({
      ...state,
      stringFilters: state.stringFilters.map(
        (filter): FilterString =>
          filter.key === key
            ? {
                ...filter,
                draftSelected: filter.draftSelected.filter(draftValue => draftValue !== value)
              }
            : filter
      )
    }))
  },
  removeBooleanFilter: key => {
    set(state => ({
      ...state,
      booleanFilters: state.booleanFilters.filter(filter => filter.key !== key)
    }))
  },
  removeTextFilter: key => {
    set(state => ({
      ...state,
      textFilters: state.textFilters.filter(filter => filter.key !== key)
    }))
  },
  removeTopicFilter: ({ type, value }) => {
    const { productAreaTopics, otherTopics, productAreaThemes, otherThemes, unclassifiedTopics } =
      get()

    if (type === 'topic') {
      const newProductAreaTopics = productAreaTopics.selected.filter(topic => topic !== value)
      const newOtherTopics = otherTopics.selected.filter(topic => topic !== value)
      set(state => ({
        ...state,
        productAreaTopics: {
          selected: productAreaTopics.selected,
          draftSelected: newProductAreaTopics
        },
        otherTopics: { selected: otherTopics.selected, draftSelected: newOtherTopics }
      }))

      return
    }

    if (type === 'theme') {
      const newProductAreaThemes = productAreaThemes.selected.filter(theme => theme !== value)
      const newOtherThemes = otherThemes.selected.filter(theme => theme !== value)
      set(state => ({
        ...state,
        productAreaThemes: {
          selected: productAreaThemes.selected,
          draftSelected: newProductAreaThemes
        },
        otherThemes: { selected: otherThemes.selected, draftSelected: newOtherThemes }
      }))

      return
    }

    if (type === 'unclassified_topic') {
      set(state => ({
        ...state,
        unclassifiedTopics: { selected: unclassifiedTopics.selected, draftSelected: [] }
      }))
    }
  },

  setFiltersLoaded: () => ({}),
  setVolumeBy: type => set(() => ({ volumeBy: type })),

  setFiltersByURL: filtersByURL => set(() => ({ filtersByURL })),

  checkAccount: props => {
    const { checked, filterName, value, apply } = props
    const { accountsStringFilters } = get()
    const selectedValues = Array.isArray(value) ? value : [value]

    let newValues: string[] = []

    if (accountsStringFilters[filterName]) {
      const filterValues = Array.from(accountsStringFilters[filterName].draftSelected)
      newValues = checked
        ? [...filterValues, ...selectedValues]
        : filterValues.filter(filterValue => !selectedValues.includes(filterValue))
    } else if (checked) {
      newValues = selectedValues
    }

    set(state => ({
      accountsStringFilters: {
        ...state.accountsStringFilters,
        [filterName]: {
          draftSelected: newValues,
          selected: apply ? newValues : state.accountsStringFilters[filterName]?.selected ?? []
        }
      }
    }))
  },

  removeAccountFilter: props => {
    const { filterName, apply } = props

    set(state => ({
      accountsStringFilters: {
        ...state.accountsStringFilters,
        [filterName]: {
          draftSelected: [],
          selected: apply ? [] : state.accountsStringFilters[filterName]?.selected ?? []
        }
      }
    }))
  },

  applyAccounts: () => {
    const {
      accountsStringFilters,
      accountsDateFilters,
      accountNumericFilters,
      accountBooleanFilters
    } = get()
    const newAccounts: Record<string, MultiSelect> = {}

    Object.entries(accountsStringFilters).forEach(([key, value]) => {
      newAccounts[key] = { draftSelected: value.draftSelected, selected: value.draftSelected }
    })

    const newAccoutsDateFilters = accountsDateFilters.map(
      filter => ({ ...filter, selected: filter.draftSelected }) as CustomerRecordsDateFilter
    )

    const newAccountNumericFilters = accountNumericFilters.map(filter => ({
      ...filter,
      value: filter.draftValue,
      option: filter.draftOption
    }))

    const newAccountBooleanFilters = accountBooleanFilters.map(filter => ({
      ...filter,
      value: filter.draftValue
    }))

    set(() => ({
      accountsStringFilters: newAccounts,
      accountsDateFilters: newAccoutsDateFilters,
      accountNumericFilters: newAccountNumericFilters,
      accountBooleanFilters: newAccountBooleanFilters
    }))
  },

  checkUser: props => {
    const { checked, filterName, value, apply } = props
    const { usersStringFilters } = get()

    const selectedValues = Array.isArray(value) ? value : [value]

    let newValues: string[] = []

    if (usersStringFilters[filterName]) {
      const filterValues = Array.from(usersStringFilters[filterName].draftSelected)
      newValues = checked
        ? [...filterValues, ...selectedValues]
        : filterValues.filter(filterValue => !selectedValues.includes(filterValue))
    } else if (checked) {
      newValues = selectedValues
    }

    set(state => ({
      usersStringFilters: {
        ...state.usersStringFilters,
        [filterName]: {
          draftSelected: newValues,
          selected: apply ? newValues : state.usersStringFilters[filterName]?.selected ?? []
        }
      }
    }))
  },

  applyUsers: () => {
    const { usersStringFilters, usersDateFilters, usersNumericFilters, usersBooleanFilters } = get()
    const newUsers: Record<string, MultiSelect> = {}

    Object.entries(usersStringFilters).forEach(([key, value]) => {
      newUsers[key] = { draftSelected: value.draftSelected, selected: value.draftSelected }
    })

    const newUsersDateFilters = usersDateFilters.map(
      filter => ({ ...filter, selected: filter.draftSelected }) as CustomerRecordsDateFilter
    )

    const newUsersNumericFilters = usersNumericFilters.map(filter => ({
      ...filter,
      value: filter.draftValue,
      option: filter.draftOption
    }))

    const newUsersBooleanFilters = usersBooleanFilters.map(filter => ({
      ...filter,
      value: filter.draftValue
    }))

    set(() => ({
      usersStringFilters: newUsers,
      usersDateFilters: newUsersDateFilters,
      usersNumericFilters: newUsersNumericFilters,
      usersBooleanFilters: newUsersBooleanFilters
    }))
  },

  removeUsersFilter: props => {
    const { filterName, apply } = props

    set(state => ({
      usersStringFilters: {
        ...state.usersStringFilters,
        [filterName]: {
          draftSelected: [],
          selected: apply ? [] : state.usersStringFilters[filterName]?.selected ?? []
        }
      }
    }))
  },
  changeAccountDateFilter: ({ key, value, apply }) => {
    const { accountsDateFilters } = get()
    const dateFilter = accountsDateFilters.find(filter => filter.key === key)
    let newAccountDateFilters = accountsDateFilters
    if (!dateFilter) {
      newAccountDateFilters = [
        ...accountsDateFilters,
        { key, draftSelected: value, selected: apply ? value : null } as CustomerRecordsDateFilter
      ]
    } else {
      newAccountDateFilters = accountsDateFilters.map(accountFilter => {
        if (accountFilter.key === key) {
          return {
            ...accountFilter,
            draftSelected: value,
            selected: apply ? value : accountFilter.selected
          }
        }

        return accountFilter
      })
    }
    set(() => ({
      accountsDateFilters: newAccountDateFilters
    }))
  },

  removeAccountDateFilter: ({ apply, key }) => {
    const { accountsDateFilters } = get()

    const newAccountDateFilters = accountsDateFilters.map(accountFilter => {
      if (accountFilter.key === key) {
        return {
          ...accountFilter,
          draftSelected: null,
          selected: apply ? null : accountFilter.selected
        }
      }

      return accountFilter
    })

    set(() => ({
      accountsDateFilters: newAccountDateFilters
    }))
  },

  setAccountNumericFilter: ({ key, type, name, value }, apply = false) =>
    set(state => ({
      ...state,
      accountNumericFilters: state.accountNumericFilters.find(filter => filter.key === key)
        ? state.accountNumericFilters.map(
            (filter): FilterNumeric =>
              filter.key === key
                ? { ...filter, draftValue: value, value: apply ? value : filter.value }
                : filter
          )
        : [
            ...state.accountNumericFilters,
            {
              key,
              type,
              name,
              draftOption: 'all',
              option: 'all',
              draftValue: value,
              value: apply ? value : null
            }
          ]
    })),
  setAccountNumericOption: ({ key, type, name, option }, apply = false) =>
    set(state => ({
      ...state,
      accountNumericFilters: state.accountNumericFilters.find(filter => filter.key === key)
        ? state.accountNumericFilters.map(
            (filter): FilterNumeric =>
              filter.key === key
                ? { ...filter, draftOption: option, option: apply ? option : filter.option }
                : filter
          )
        : [
            ...state.accountNumericFilters,
            {
              key,
              type,
              name,
              draftOption: option,
              option: apply ? option : 'all',
              draftValue: null,
              value: null
            }
          ]
    })),
  setAccountBooleanFilter: ({ key, type, name, value }, apply = false) =>
    set(state => ({
      ...state,
      accountBooleanFilters: state.accountBooleanFilters.find(filter => filter.key === key)
        ? state.accountBooleanFilters.map(
            (filter): FilterBoolean =>
              filter.key === key
                ? {
                    ...filter,
                    draftValue: value,
                    value: apply ? value : filter.value
                  }
                : filter
          )
        : [
            ...state.accountBooleanFilters,
            {
              key,
              type,
              name,
              value: apply ? value : null,
              draftValue: value
            }
          ]
    })),
  removeAccountNumericFilter: key =>
    set(state => ({
      accountNumericFilters: state.accountNumericFilters.map(
        (filter): FilterNumeric =>
          filter.key === key ? { ...filter, draftOption: 'all', draftValue: null } : filter
      )
    })),
  removeAccountBooleanFilter: key =>
    set(state => ({
      accountBooleanFilters: state.accountBooleanFilters.map(
        (filter): FilterBoolean => (filter.key === key ? { ...filter, draftValue: null } : filter)
      )
    })),

  changeUsersDateFilter: ({ key, value, apply }) => {
    const { usersDateFilters } = get()
    const dateFilter = usersDateFilters.find(filter => filter.key === key)
    let newUsersDateFilters = usersDateFilters
    if (!dateFilter) {
      newUsersDateFilters = [
        ...usersDateFilters,
        { key, draftSelected: value, selected: apply ? value : null } as CustomerRecordsDateFilter
      ]
    } else {
      newUsersDateFilters = usersDateFilters.map(userFilter => {
        if (userFilter.key === key) {
          return {
            ...userFilter,
            draftSelected: value,
            selected: apply ? value : userFilter.selected
          }
        }

        return userFilter
      })
    }
    set(() => ({
      usersDateFilters: newUsersDateFilters
    }))
  },

  removeUsersDateFilter: ({ apply, key }) => {
    const { usersDateFilters } = get()

    const newUsersDateFilters = usersDateFilters.map(userFilter => {
      if (userFilter.key === key) {
        return {
          ...userFilter,
          draftSelected: null,
          selected: apply ? null : userFilter.selected
        }
      }

      return userFilter
    })

    set(() => ({
      usersDateFilters: newUsersDateFilters
    }))
  },

  setUsersNumericFilter: ({ key, type, name, value }, apply = false) =>
    set(state => ({
      ...state,
      usersNumericFilters: state.usersNumericFilters.find(filter => filter.key === key)
        ? state.usersNumericFilters.map(
            (filter): FilterNumeric =>
              filter.key === key
                ? { ...filter, draftValue: value, value: apply ? value : filter.value }
                : filter
          )
        : [
            ...state.usersNumericFilters,
            {
              key,
              type,
              name,
              draftOption: 'all',
              option: 'all',
              draftValue: value,
              value: apply ? value : null
            }
          ]
    })),
  setUsersNumericOption: ({ key, type, name, option }, apply = false) =>
    set(state => ({
      ...state,
      usersNumericFilters: state.usersNumericFilters.find(filter => filter.key === key)
        ? state.usersNumericFilters.map(
            (filter): FilterNumeric =>
              filter.key === key
                ? { ...filter, draftOption: option, option: apply ? option : filter.option }
                : filter
          )
        : [
            ...state.usersNumericFilters,
            {
              key,
              type,
              name,
              draftOption: option,
              option: apply ? option : 'all',
              draftValue: null,
              value: null
            }
          ]
    })),
  setUsersBooleanFilter: ({ key, type, name, value }, apply = false) =>
    set(state => ({
      ...state,
      usersBooleanFilters: state.usersBooleanFilters.find(filter => filter.key === key)
        ? state.usersBooleanFilters.map(
            (filter): FilterBoolean =>
              filter.key === key
                ? {
                    ...filter,
                    draftValue: value,
                    value: apply ? value : filter.value
                  }
                : filter
          )
        : [
            ...state.usersBooleanFilters,
            {
              key,
              type,
              name,
              value: apply ? value : null,
              draftValue: value
            }
          ]
    })),
  removeUsersNumericFilter: key =>
    set(state => ({
      usersNumericFilters: state.usersNumericFilters.map(
        (filter): FilterNumeric =>
          filter.key === key ? { ...filter, draftOption: 'all', draftValue: null } : filter
      )
    })),
  removeUsersBooleanFilter: key =>
    set(state => ({
      usersBooleanFilters: state.usersBooleanFilters.map(
        (filter): FilterBoolean => (filter.key === key ? { ...filter, draftValue: null } : filter)
      )
    })),

  selectUngroupedTopics: ({ topics }) =>
    set(state => ({
      ungroupedTopics: {
        ...state.ungroupedTopics,
        draftSelected: [...state.ungroupedTopics.draftSelected, ...topics]
      }
    })),
  unselectUngroupedTopics: ({ topics }) =>
    set(state => ({
      ungroupedTopics: {
        ...state.ungroupedTopics,
        draftSelected: state.ungroupedTopics.draftSelected.filter(
          draftTopic => !topics.find(topic => topic.id === draftTopic.id)
        )
      }
    })),

  applyUngroupedTopics: () => {
    set(state => ({
      ungroupedTopics: {
        ...state.ungroupedTopics,
        selected: state.ungroupedTopics.draftSelected
      }
    }))
  },

  resetUngroupedTopics: () => {
    set(state => ({
      ungroupedTopics: {
        ...state.ungroupedTopics,
        draftSelected: state.ungroupedTopics.selected
      }
    }))
  },

  removeUngroupedTopics: () => {
    set(() => ({
      ungroupedTopics: {
        selected: [],
        draftSelected: []
      }
    }))
  },

  ...payloadParsersProperties(get)
})

export default createFiltersStore
