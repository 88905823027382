import React, { useCallback, useMemo, useState } from 'react'

import OptionsMenu, { OptionsMenuItem, OptionsMenuProps } from '@/components/atoms/options-menu'

import { OptionsMenuButton } from './FeedbackItem.styles'
import { FeedbackItemProps } from './FeedbackItem.types'
import useSegment from '@/hooks/useSegment'
import useUser from '@/hooks/useUser'
import { ArchiveBox, ListDashes } from '@phosphor-icons/react'
import { colors } from '@/theme'

const FeedbackItemOptions: React.FC<FeedbackItemProps> = props => {
  const { track } = useSegment()

  const [open, setOpen] = useState(false)
  const onOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      track('feedback_user_options')
    }
    setOpen(isOpen)
  }

  const { userPermissions } = useUser()
  const isManager = userPermissions.feedback.includes('manager')

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const onSelectFeedback = useCallback(
    function () {
      track('feedback_user_options-feedback_detail')
      props.onSelect?.(props?.selectedId === props.feedbackId ? null : props)
    },
    [props.selectedId, props.feedbackId, props.onSelect]
  )

  const optionsMenuProps = useMemo(() => {
    const onClick = props.archived ? props.onUnarchive : props.onArchive

    const archiveOption: OptionsMenuItem = {
      text: props.archived ? 'Unarchive' : 'Archive',
      icon: !props.archived ? <ArchiveBox color={colors.neutralLowPure} size={16} /> : undefined,
      onClick: () => {
        track('feedback_user_options-archive')
        onClick?.(props.feedbackId)
      }
    }
    const detailsOption: OptionsMenuItem = {
      text: 'Details',
      icon: <ListDashes color={colors.neutralLowPure} size={16} />,
      onClick: onSelectFeedback
    }

    const options = [detailsOption, ...(isManager ? [archiveOption] : [])]

    const buttonStyle = {
      ...OptionsMenuButton,
      top: props.isThreadItem ? -4 : 20
    }

    return { buttonStyle, options } as OptionsMenuProps
  }, [
    props.feedbackId,
    props.archived,
    props.isThreadItem,
    props.onArchive,
    props.onUnarchive,
    onSelectFeedback,
    isManager,
    track
  ])

  return <OptionsMenu onOpenChange={onOpenChange} open={open} {...optionsMenuProps} />
}

export default FeedbackItemOptions
