import FlexContainer from '@/components/atoms/flex-container'
import IconButton from '@/components/atoms/icon-button'
import { APP_ENVIRONEMNT } from '@/config'
import useSearchInputStore from '@/store/useSearchInputStore'
import { Info, WarningCircle } from '@phosphor-icons/react'
import { useEffect, useRef } from 'react'
import Search from '../search'
import useEvents from '@/hooks/useEvents'
import { InputErrorMessage } from '@/components/atoms/input'
import { useTranslation } from 'react-i18next'

interface SearchFilterProps {
  onSearch: () => void
  onClear?: () => void
  searchPlaceholder: string
  storedValue: string
  error?: string
}

const ADVANCED_SEARCH_ARTICLE_ID = 6624248

const notBlurElements = [
  'birdie-clear-search',
  'birdie-search-button',
  'birdie-search-tip',
  'birdie-filters-popover'
]

function SearchFilter({
  onSearch,
  onClear,
  searchPlaceholder,
  storedValue,
  error
}: SearchFilterProps) {
  const { textInput, setTextInput, isFocused, setIsFocused } = useSearchInputStore()

  const lastSearchRef = useRef(textInput)
  const inputRef = useRef<HTMLInputElement>(null)

  const { t } = useTranslation()

  const { on, removeListener } = useEvents()

  const isProduction = APP_ENVIRONEMNT === 'production'

  // biome-ignore lint/correctness/useExhaustiveDependencies: should only react to storedValue
  useEffect(() => {
    if (storedValue !== textInput) {
      setTextInput(storedValue)
    }
  }, [storedValue])

  const onBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (notBlurElements.includes(e.relatedTarget?.id ?? '')) {
      return
    }

    setIsFocused(false)

    if (!textInput.length && lastSearchRef.current.length) {
      onClear?.()
      lastSearchRef.current = ''
    }
  }

  const onFocus = () => {
    setIsFocused(true)
  }

  const handleEnter: React.KeyboardEventHandler<HTMLInputElement> = event => {
    const isEnter = event.key === 'Enter'
    if (isEnter) {
      lastSearchRef.current = textInput
      onSearch()
    }
  }

  function showAdvancedSearchArticle() {
    if (isProduction) {
      window.Intercom('showArticle', ADVANCED_SEARCH_ARTICLE_ID)
      setIsFocused(false)
    }
  }

  const handleClear = () => {
    setTextInput('')
    onClear?.()
    lastSearchRef.current = ''
  }

  useEffect(() => {
    on('focus-search-filter', () => {
      const input = inputRef.current
      if (!input) return
      input.focus()
      input.setSelectionRange(input.value.length, input.value.length)
    })

    return function () {
      removeListener('focus-search-filter')
    }
  }, [on, removeListener])

  return (
    <FlexContainer css={{ flexGrow: 1, minWidth: 230 }} direction="column" gap="nano">
      <Search
        clearable={isFocused}
        error={Boolean(error)}
        inputRef={inputRef}
        onBlur={onBlur}
        onChange={e => {
          setTextInput(e.currentTarget.value)
        }}
        onClear={handleClear}
        onFocus={onFocus}
        onKeyUp={handleEnter}
        placeholder={searchPlaceholder}
        size="xxxs"
        trailing={
          isFocused &&
          isProduction && (
            <IconButton
              id="birdie-search-tip"
              onClick={showAdvancedSearchArticle}
              title={t('howBirdieSearchWorks')}
            >
              <Info />
            </IconButton>
          )
        }
        value={textInput}
      />
      {Boolean(error) && (
        <InputErrorMessage>
          <FlexContainer alignItems="center" gap="nano">
            <WarningCircle size={14} />
            <span>{t('invalidText')}</span>
          </FlexContainer>
        </InputErrorMessage>
      )}
    </FlexContainer>
  )
}

export default SearchFilter
