export const roundAndSuffix = (value: number) => {
  const absNum = Math.abs(value)
  const negative = value < 0 ? '-' : ''

  if (absNum < 1e4) {
    return negative + absNum.toString()
  }
  if (absNum < 1e6) {
    const toPrecision = (absNum / 1e3).toPrecision(3).replace('.00', '')
    const toNextSuffix = toPrecision.includes('e')
    return negative + (toNextSuffix ? `${Number.parseInt(toPrecision)}M` : `${toPrecision}K`)
  }
  if (absNum < 1e9) {
    const toPrecision = (absNum / 1e6).toPrecision(3).replace('.00', '')
    const toNextSuffix = toPrecision.includes('e')
    return negative + (toNextSuffix ? `${Number.parseInt(toPrecision)}B` : `${toPrecision}M`)
  }
  if (absNum < 1e12) {
    const toPrecision = (absNum / 1e9).toPrecision(3).replace('.00', '')
    const toNextSuffix = toPrecision.includes('e')
    return negative + (toNextSuffix ? `${Number.parseInt(toPrecision)}T` : `${toPrecision}B`)
  }

  return `${negative}${(absNum / 1e12).toPrecision(3)}T`
}

export const formatPercentage = (value: number) => {
  if (value === 0) {
    return '0'
  }

  if (value >= 100) {
    return Math.round(value).toString()
  }

  let precisionValue = ''

  if (Math.abs(value) > 0 && Math.abs(value) < 1) {
    precisionValue = value.toPrecision(1)
  } else if (Math.abs(value) > 0 && Math.abs(value) < 10) {
    precisionValue = value.toPrecision(2)
  } else {
    precisionValue = value.toPrecision(3)
  }

  if (precisionValue.endsWith('.0')) {
    return precisionValue.replace('.0', '')
  }

  return precisionValue
}

export const formatPercentageVariationWithSymbol = (value: number) => {
  if (value === 0) return '0%'

  // Round the value to 1 decimal place
  const roundedValue = Math.round(value * 10) / 10

  if (Math.abs(roundedValue) < 1) {
    return `${formatPercentage(roundedValue).replace('-', '')}%`
  }

  return `${roundedValue}%`.replace('-', '')
}

export const formatPercentageNoDecimalWithSymbol = (value: number): string => {
  if (value === 0) return '0%'
  if (Math.abs(value) < 1) return '<1%'
  return `${Math.round(value)}%`
}

export const formatPercentageWithSignal = (value: number): string => {
  if (value > 0) {
    return `+${formatPercentage(value)}%`
  }

  return `${formatPercentage(value)}%`
}

export const isNumberNull = (x?: number) => typeof x === 'undefined' || x === null

export const formatCurrency = (
  value: number | string,
  currency: string,
  options?: Intl.NumberFormatOptions
): string | null => {
  const parsedValue = typeof value === 'number' ? value : Number.parseFloat(value)
  if (Number.isNaN(parsedValue)) return null

  const defaultOptions: Intl.NumberFormatOptions = { currency, style: 'currency' }

  const formated = Intl.NumberFormat(
    'default',
    options ? { ...defaultOptions, ...options } : defaultOptions
  ).format(parsedValue)

  return formated
}
