import { CSS } from '@/theme'
import { Intention } from '@/types/reasons'
import { intentionBgColor, intentionIcons, intentionTextColor } from '@/utils/intention'
import { IconContext } from '@phosphor-icons/react'
import Chip from './Chip'

interface IntentionChipProps {
  intention: Intention
  css?: CSS
  disabled?: boolean
}

const IntentionChip = ({ intention, css, disabled }: IntentionChipProps) => {
  const intentionCss: CSS = {
    color: intentionTextColor[intention],
    backgroundColor: intentionBgColor[intention],
    borderRadius: '$nano',
    textTransform: 'capitalize',
    ...css
  }

  return (
    <IconContext.Provider value={{ size: 16, weight: 'fill' }}>
      <Chip
        css={intentionCss}
        disabled={disabled}
        icon={intentionIcons[intention]}
        label={intention}
        showDeleteIcon={false}
      />
    </IconContext.Provider>
  )
}

export default IntentionChip
