import Checkbox from '@/components/atoms/checkbox/Checkbox'
import FlexContainer from '@/components/atoms/flex-container'
import OptionsMenu, { OptionsMenuItem } from '@/components/atoms/options-menu'
import { DropdownMenuSubContent } from '@/components/atoms/options-menu/OptionsMenu.styles'
import { Gear, PencilSimple, TrashSimple } from '@phosphor-icons/react'
import { AreaOfInterestOptionsHandlers } from '../../types'
import { useState } from 'react'
import {
  NormalizedAreaTableData,
  isNormalizedArea,
  isNormalizedOpportunity
} from './AreaOfInterestTable.types'
import useSegment from '@/hooks/useSegment'
import { useTranslation } from 'react-i18next'
import { AreaOfInterestData } from '@/types/area/AreaOfInterest'

interface Props extends AreaOfInterestOptionsHandlers {
  data: NormalizedAreaTableData | AreaOfInterestData
}
const AreaOfInterestOptions = ({
  data,
  onClickUpdateUseInUnmapped,
  onDeleteAreaClick,
  onRenameAreaClick
}: Props) => {
  const { t } = useTranslation()
  const { track } = useSegment()

  const [isOptionsOpen, setIsOptionsOpen] = useState(false)

  const area: AreaOfInterestData | undefined = (data as AreaOfInterestData).content
    ? (data as AreaOfInterestData)
    : isNormalizedArea(data)
      ? data.originalArea
      : undefined
  const opportunity = isNormalizedOpportunity(data) ? data.originalOpportunity : undefined

  const onCheckedChange = () => {
    if (area) {
      onClickUpdateUseInUnmapped(area)
      track('areas_and_opps_keep_unmapped', { keep_unmapped: !area.useInUnmappedArea })
    }
    setIsOptionsOpen(false)
  }

  const menuOptions: OptionsMenuItem[] = [
    {
      text: t('rename'),
      icon: <PencilSimple />,
      onClick: () => area && onRenameAreaClick(area)
    },
    {
      text: t('delete'),
      icon: <TrashSimple />,
      onClick: () => area && onDeleteAreaClick(area)
    },
    {
      text: t('advanced'),
      icon: <Gear />,
      customSubOption: (
        <DropdownMenuSubContent>
          <FlexContainer
            alignItems="center"
            css={{ padding: '$nano $xxxs' }}
            justifyContent="center"
          >
            <Checkbox
              checked={!area?.useInUnmappedArea}
              onCheckedChange={onCheckedChange}
              text={t('keepAsUnmapped')}
              value="use_in_unmapped"
            />
          </FlexContainer>
        </DropdownMenuSubContent>
      )
    }
  ]

  if (area?.isUnmappedArea) return <></>

  if (opportunity) return <></>

  return (
    <OptionsMenu
      buttonStyle={{ mx: 'auto' }}
      onOpenChange={setIsOptionsOpen}
      open={isOptionsOpen}
      options={menuOptions}
    />
  )
}

export default AreaOfInterestOptions
