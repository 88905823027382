import { FeedScrollArea } from '@/components/molecules/feed/feedbacks/Feedbacks.styles'
import FeedbackList from '@/components/molecules/feed/new/feedback/FeedbackList'
import FeedSearchContent from '@/components/molecules/layout/FeedSearchContent'
import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import { useUIStore } from '@/store'
import { useEffect } from 'react'

function FeedbackFeed() {
  const { setPageTitle } = useUIStore()

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen once
  useEffect(() => {
    setPageTitle('Feedback')
  }, [])

  return (
    <FeedSearchContent>
      <FeedScrollArea>
        <ScrollAreaViewport id="scroll-feedback">
          <FeedbackList />
        </ScrollAreaViewport>
        <ScrollAreaScrollbar>
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </FeedScrollArea>
    </FeedSearchContent>
  )
}

export default FeedbackFeed
