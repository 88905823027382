import { useCallback, useState } from 'react'
import axios from 'axios'

function useErrorScreens() {
  const [serverError, setServerError] = useState(false)

  const checkServerError = useCallback((error: unknown) => {
    if (axios.isAxiosError(error) && Number(error.response?.status) >= 500) {
      setServerError(true)
    } else {
      setServerError(false)
    }
  }, [])

  return { checkServerError, hasServerError: serverError }
}

export default useErrorScreens
