// reference: https://codesandbox.io/s/objective-shape-8r4utm?file=/src/CalendarCell.js

import { useRef } from 'react'
import { useCalendarCell } from '@react-aria/calendar'
import { useFocusRing } from '@react-aria/focus'
import { mergeProps } from '@react-aria/utils'
import { isSameDay, isSameMonth } from '@internationalized/date'

import type { RangeCalendarState } from '@react-stately/calendar'
import type { CalendarDate } from '@internationalized/date'
import { styled } from '@/theme'

const defaultRadius = '20px'

const StyledTd = styled('td', {
  textAlign: 'center'
})

const SelectionCell = styled('div', {
  marginBottom: 4,

  '&:focus': {
    outline: 'none'
  },

  '&[aria-disabled="true"]': {
    color: '$neutralLowLight',
    pointerEvents: 'none'
  },

  variants: {
    selected: {
      true: {
        background: '$brandPrimaryTransparentWeak'
      }
    },
    muted: {
      true: {
        color: '$neutralLowLight',
        pointerEvents: 'none'
      },
      false: {
        color: '$neutralLowPure'
      }
    },
    roundedLeft: {
      true: {
        borderTopLeftRadius: defaultRadius,
        borderBottomLeftRadius: defaultRadius
      }
    },
    roundedRight: {
      true: {
        borderTopRightRadius: defaultRadius,
        borderBottomRightRadius: defaultRadius
      }
    }
  },

  compoundVariants: [
    {
      selected: true,
      muted: true,
      css: {
        background: 'transparent'
      }
    }
  ]
})

const DateCell = styled('div', {
  padding: '7px 8px',
  // margin: '0px 8px',
  borderRadius: defaultRadius,
  bAll: 'transparent',

  '&:hover': { background: '$brandPrimaryTransparentWeak' },

  '&:[aria-disabled="true"]': {
    color: '$neutralLowLight',
    pointerEvents: 'none'
  },

  variants: {
    selected: {
      true: {
        background: '$brandPrimaryPure',
        color: '$white',

        '&:hover': {
          background: '$brandPrimaryMedium'
        }
      }
    },
    muted: {
      true: {}
    }
  },

  compoundVariants: [
    {
      selected: true,
      muted: true,
      css: {
        background: 'unset',
        color: 'unset'
      }
    }
  ]
})

export interface CalendarCellProps {
  state: RangeCalendarState
  date: CalendarDate
  currentMonth: CalendarDate
}

export function CalendarCell({ state, date, currentMonth }: CalendarCellProps) {
  const ref = useRef(null)
  const { cellProps, buttonProps, isSelected, formattedDate } = useCalendarCell(
    { date },
    state,
    ref
  )

  const isOutsideMonth = !isSameMonth(currentMonth, date)

  // The start and end date of the selected range will have
  // an emphasized appearance.
  const isSelectionStart = state.highlightedRange
    ? isSameDay(date, state.highlightedRange.start)
    : isSelected
  const isSelectionEnd = state.highlightedRange
    ? isSameDay(date, state.highlightedRange.end)
    : isSelected

  const isRoundedLeft = isSelected && isSelectionStart
  const isRoundedRight = isSelected && isSelectionEnd

  const { focusProps } = useFocusRing()

  return (
    <StyledTd {...cellProps}>
      <SelectionCell
        {...mergeProps(buttonProps, focusProps)}
        muted={isOutsideMonth}
        ref={ref}
        roundedLeft={isRoundedLeft}
        roundedRight={isRoundedRight}
        selected={isSelected}
      >
        <DateCell muted={isOutsideMonth} selected={isSelectionStart || isSelectionEnd}>
          {formattedDate}
        </DateCell>
      </SelectionCell>
    </StyledTd>
  )
}
