import { styled } from '@/theme'

import { ScrollAreaRoot } from '@/components/molecules/scroll-area'
import { FIRST_CELL_PADDING, GRID_TEMPLATE } from './TopicRow.styles'

export const TopicListScrollArea = styled(ScrollAreaRoot, {
  overflow: 'hidden',
  pr: '$micro',

  variants: {
    expanded: {
      true: {
        // width: `calc(100% - 180px)`
      },
      false: {
        // width: `calc(100% - 550px)`
      }
    }
  }
})

export const TopicListContainer = styled('div', {
  boxSizing: 'border-box',
  width: '100%',
  maxWidth: '100%',
  overflow: 'hidden',
  mb: 96
})

export const TopicListHeader = styled('header', {
  display: 'grid',
  gridTemplateColumns: GRID_TEMPLATE,
  fontSize: '$nano',
  fontWeight: '$bold',
  py: '$xxxs',
  pr: '$xs',
  borderRadius: '$micro',
  background: '$neutralHighMedium'
})

export const TopicListHeaderCell = styled('div', {
  position: 'relative',
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  gap: '$nano',

  '&:first-of-type': {
    pl: FIRST_CELL_PADDING
  },

  variants: {
    clickable: {
      true: {
        cursor: 'pointer'
      }
    },

    justifyEnd: {
      true: {
        justifyContent: 'flex-end'
      }
    },
    justifyCenter: {
      true: {
        justifyContent: 'center',
        textAlign: 'center'
      }
    }
  }
})
