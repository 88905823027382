import { useState } from 'react'
import FilterSubMenu from '../../AddFilterMenu/FilterSubMenu'

import useAccountStringFilter from './useAccountStringFilter'
import { FilterItem } from '@/types/filters/Filters'
import CustomerRecordsStringFilterContent from '../common/CustomerRecordsStringFilterContent'
import useAccountStringFilterSelection from './useAccountStringFilterSelection'

export interface Props {
  filter: FilterItem
  newFeed?: boolean
}

const AccountStringFilterSubmenu = ({ filter, newFeed }: Props) => {
  const [open, setOpen] = useState(false)

  const { options, isLoading } = useAccountStringFilter({
    filterKey: filter.key,
    enabled: open
  })

  const { apply, hasChanges, isChecked, selectOption } = useAccountStringFilterSelection({
    filterKey: filter.key,
    newFeed
  })

  return (
    <FilterSubMenu
      isDisabled={!hasChanges}
      isLoading={isLoading}
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      previousTitle="Accounts"
      title={filter.displayName}
    >
      <CustomerRecordsStringFilterContent
        isChecked={isChecked}
        options={options}
        selectOption={selectOption}
      />
    </FilterSubMenu>
  )
}

export default AccountStringFilterSubmenu
