import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import { TextArea } from '@/components/atoms/input'
import Popover from '@/components/atoms/popover'
import Text from '@/components/atoms/text'
import { ChatPrompt } from '@/types/feedbacks/Feedback'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  isBusy: boolean
  questionText: string
  commands: ChatPrompt[]
  hasFeedbacks: boolean
  setQuestionText: (value: string) => void
  onClickSend: () => void
  onCancelRequest: () => void
}

function InputWithCommands({
  isBusy,
  questionText,
  commands,
  hasFeedbacks,
  onCancelRequest,
  onClickSend,
  setQuestionText
}: Props) {
  const inputRef = useRef<HTMLTextAreaElement>(null)

  const [showCommandsPopover, setShowCommandsPopover] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (!showCommandsPopover) {
      resizeInput(inputRef.current)
    }
  }, [showCommandsPopover])

  useEffect(() => {
    if (!questionText.length) {
      resizeInput(inputRef.current)
    }
  }, [questionText])

  const onFocus: React.FocusEventHandler<HTMLTextAreaElement> = event => {
    resizeInput(event.target)
  }

  const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = e => {
    const value = e.currentTarget.value
    setQuestionText(value)
    setShowCommandsPopover(value.startsWith('/'))
    resizeInput(e.target)
  }

  const onOpenCommands = (open: boolean) => {
    if (!questionText.startsWith('/')) {
      setShowCommandsPopover(false)
      return
    }
    setShowCommandsPopover(open)

    if (!open) {
      inputRef.current?.focus()
    }
  }

  function resizeInput(element: HTMLTextAreaElement | null) {
    if (element === null) return

    const style = window.getComputedStyle(element)
    const verticalSpace = ['border-top', 'border-bottom', 'margin-top', 'margin-bottom']
      .map(key => parseInt(style.getPropertyValue(key), 10))
      .reduce((previous, current) => previous + current)

    element.style.height = '0px'
    const newHeight = element.scrollHeight + verticalSpace
    element.style.height = newHeight + 'px'
  }

  const commandList = useMemo(() => {
    if (!questionText || questionText === '/') return commands
    const commandText = questionText.trim().substring(1).toLocaleLowerCase()

    return commands.filter(command => command.name.toLocaleLowerCase().indexOf(commandText) > -1)
  }, [questionText, commands])

  const onClickCommand = (command: ChatPrompt) => {
    setQuestionText(command.text)
    setShowCommandsPopover(false)
    inputRef.current?.focus()
  }

  const onKeyUp: React.KeyboardEventHandler<HTMLTextAreaElement> = event => {
    const isEnter = event.key === 'Enter'
    if (isEnter && event.shiftKey) {
      return
    }

    if (isEnter) {
      onClickSend()
    }
  }

  const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = event => {
    const isEnter = event.key === 'Enter'
    if (isEnter && !event.shiftKey) {
      event.preventDefault()
    }
  }

  const placeholder = hasFeedbacks
    ? t('askAQuestionOrTypeToSeeCommands')
    : t('noFeedbackFoundRefineYourFilters')

  return (
    <FlexContainer alignItems="center">
      <Popover
        buttonChildren={
          <FlexContainer
            alignItems="center"
            css={{
              width: '100%'
            }}
            gap="micro"
          >
            <TextArea
              css={{ overflow: 'hidden auto', maxHeight: 170, wordWrap: 'break-all' }}
              disabled={isBusy || !hasFeedbacks}
              onChange={onChange}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              placeholder={placeholder}
              ref={inputRef}
              value={questionText}
            />
            <Button
              css={{ alignSelf: 'flex-start' }}
              disabled={!hasFeedbacks}
              onClick={isBusy ? onCancelRequest : onClickSend}
              size="large"
            >
              {isBusy ? t('cancel') : t('send')}
            </Button>
          </FlexContainer>
        }
        contentProps={{
          onInteractOutside: e => e.preventDefault(),
          onOpenAutoFocus: e => e.preventDefault()
        }}
        customButton
        modal={false}
        onOpenChange={onOpenCommands}
        open={showCommandsPopover}
      >
        <FlexContainer
          css={{
            padding: '$xxs',
            width: 'var(--radix-popover-trigger-width)',
            maxHeight: 'var(--radix-popover-content-available-height)'
          }}
          direction="column"
          gap="xxxs"
        >
          {!commandList.length && (
            <FlexContainer justifyContent="center">
              <Text color="neutralLowPure" fontSize="xxxs">
                {t('noCommandFound')}
              </Text>
            </FlexContainer>
          )}
          {commandList.length > 0 &&
            commandList.map(command => (
              <Button
                css={{ width: '100%', justifyContent: 'flex-start' }}
                key={command.name}
                onClick={() => onClickCommand(command)}
                size="small"
                variant="flat"
              >
                <Text color="neutralLowPure" fontSize="xxxs" title={command.text}>
                  {command.name}
                </Text>
              </Button>
            ))}
        </FlexContainer>
      </Popover>
    </FlexContainer>
  )
}

export default InputWithCommands
