import { TreeStructure } from '@phosphor-icons/react'
import Card from '../../../card/Card'
import FlexContainer from '@/components/atoms/flex-container'
import RootCauseItem from './RootCauseItem'
import { ReactNode, useState } from 'react'
import Bone from '@/components/atoms/bone'
import { TopicRootCause } from '@/types/analytics/Analytics'
import { styled } from '@/theme'
import RootCauseFeedbackModal from './RootCauseFeedbackModal'

interface CardProps {
  children: ReactNode
}
const RootCausesCard = ({ children }: CardProps) => (
  <Card.Root color="brandPrimaryPure" direction="column" gap="xs">
    <Card.Header>
      <Card.IconBox>
        <TreeStructure />
      </Card.IconBox>
      <Card.Title>Root causes</Card.Title>
    </Card.Header>
    {children}
  </Card.Root>
)

const RootCausesList = styled(FlexContainer, {
  overflowY: 'auto',
  maxHeight: 400,
  pr: '$nano'
})

export const RootCausesSkeleton = () => (
  <RootCausesCard>
    <Bone css={{ height: 80, width: '100%' }} />
    <Bone css={{ height: 80, width: '100%' }} />
    <Bone css={{ height: 80, width: '100%' }} />
    <Bone css={{ height: 80, width: '100%' }} />
    <Bone css={{ height: 80, width: '100%' }} />
  </RootCausesCard>
)

interface Props {
  rootCauses: TopicRootCause[]
}

const RootCauses = ({ rootCauses }: Props) => {
  const [rootCauseFeedback, setRootCauseFeedback] = useState<TopicRootCause | undefined>(undefined)

  const maxRelavance = Math.max(...rootCauses.map(rootCause => rootCause.relevance))

  const onCloseRootCauseFeedbackModal = () => {
    setRootCauseFeedback(undefined)
  }
  return (
    <>
      <RootCausesCard>
        <RootCausesList className="scroll-on-hover-container" direction="column" gap="xs">
          {rootCauses.map(rootCause => (
            <RootCauseItem
              key={rootCause.text}
              maxRelevance={maxRelavance}
              onClickShowFeedback={() => setRootCauseFeedback(rootCause)}
              {...rootCause}
            />
          ))}
        </RootCausesList>
      </RootCausesCard>

      <RootCauseFeedbackModal
        onClose={onCloseRootCauseFeedbackModal}
        rootCause={rootCauseFeedback}
      />
    </>
  )
}

export default RootCauses
