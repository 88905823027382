import { DEFAULT_ALLOWED_NPS_METRICS, NPS_METRICS_KEYS, npsTimeSeries } from './npsMetrics'
import { REVIEW_DEFAULT_METRICS, REVIEW_METRICS_KEYS, reviewTimeseries } from './reviewMetrics'
import {
  SUPPORT_TICKET_METRICS_KEYS,
  supportTicketTimeseries,
  TICKETS_DEFAULT_METRICS
} from './supportTicketMetrics'
import { CSAT_DEFAULT_METRICS, CSAT_METRICS_KEYS, csatTimeSeries } from './csatMetrics'
import { ISSUE_DEFAULT_METRICS, ISSUE_METRICS_KEYS, issueTimeSeries } from './issueMetrics'
import {
  DEFAULT_ALLOWED_OVERALL_METRICS,
  OVERALL_METRIC_KEYS,
  overallTimeseries
} from './overallMetrics'
import {
  SOCIAL_MEDIA_DEFAULT_METRICS,
  SOCIAL_MEDIA_METRICS_KEYS,
  socialMediaTimeSeries
} from './socialMediaMetrics'
import {
  TRANSCRIPT_DEFAULT_METRICS,
  TRANSCRIPT_METRICS_KEYS,
  transcriptTimeseries
} from './transcriptMetrics'
import type { AllMetricsKey, MetricItem, MetricKey } from '@/types/metrics'
import {
  TICKET_SATISFACTION_METRICS_KEYS,
  ticketsSatisfactionMetricItems
} from './ticketsSatisfactionMetrics'

//allMetricItems
export const baseAllMetricItems: Record<AllMetricsKey, MetricItem> = {
  ...supportTicketTimeseries,
  ...npsTimeSeries,
  ...overallTimeseries,
  ...socialMediaTimeSeries,
  ...issueTimeSeries,
  ...csatTimeSeries,
  ...reviewTimeseries,
  ...transcriptTimeseries,
  ...ticketsSatisfactionMetricItems
}

//metricKeyToAllMetricsKeys
export const baseMetricKeyToAllMetricsKeys: Record<MetricKey, AllMetricsKey[]> = {
  count: OVERALL_METRIC_KEYS,
  feedback_share: OVERALL_METRIC_KEYS,
  nps: NPS_METRICS_KEYS,
  support_ticket: SUPPORT_TICKET_METRICS_KEYS,
  csat: CSAT_METRICS_KEYS,
  issue: ISSUE_METRICS_KEYS,
  review: REVIEW_METRICS_KEYS,
  social_media_post: SOCIAL_MEDIA_METRICS_KEYS,
  transcript: TRANSCRIPT_METRICS_KEYS,
  tcsat: TICKET_SATISFACTION_METRICS_KEYS,
  tnps: TICKET_SATISFACTION_METRICS_KEYS
}

//allMetricItemsList
export const baseAllMetricItemsList = Object.entries(baseAllMetricItems).map(([key, value]) => ({
  key,
  ...value
})) as (MetricItem & { key: AllMetricsKey })[]

export const baseAllMetricsGroupedByMetricKey: Record<
  MetricKey,
  Partial<Record<AllMetricsKey, MetricItem>>
> = {
  count: overallTimeseries,
  feedback_share: overallTimeseries,
  nps: npsTimeSeries,
  support_ticket: supportTicketTimeseries,
  csat: csatTimeSeries,
  issue: issueTimeSeries,
  review: reviewTimeseries,
  social_media_post: socialMediaTimeSeries,
  transcript: transcriptTimeseries,
  tcsat: ticketsSatisfactionMetricItems,
  tnps: ticketsSatisfactionMetricItems
}

export const COLLECTION_METRICS: AllMetricsKey[] = [
  'ticket_share_over_collection',
  'nps_impact_over_collection',
  'feedback_share_over_collection'
]

export const AREA_METRICS: AllMetricsKey[] = [
  'ticket_share_over_area',
  'share_over_area',
  'nps_detractors_over_area_responses',
  'nps_detractors_over_area_responses_with_comments',
  'review_share_over_area',
  'review_share_unsatisfied_over_area_reviews_with_comments'
]

export const getBaseMetricByColumn = (column: string) => {
  const [metricType, metricKey] = column.split(':') as [MetricKey, AllMetricsKey]

  const metric = baseAllMetricsGroupedByMetricKey[metricType][metricKey]
  return metric
}

export const DEFAULT_ALLOWED_METRICS_PER_SOURCE: Record<MetricKey, AllMetricsKey[]> = {
  feedback_share: [],
  count: DEFAULT_ALLOWED_OVERALL_METRICS,
  nps: DEFAULT_ALLOWED_NPS_METRICS,
  csat: CSAT_DEFAULT_METRICS,
  review: REVIEW_DEFAULT_METRICS,
  issue: ISSUE_DEFAULT_METRICS,
  social_media_post: SOCIAL_MEDIA_DEFAULT_METRICS,
  support_ticket: TICKETS_DEFAULT_METRICS,
  transcript: TRANSCRIPT_DEFAULT_METRICS,
  tcsat: ['tcsat'],
  tnps: ['tnps']
}

export const buildMetricKey = (options: {
  source: MetricKey
  kind?: MetricKey
  key: AllMetricsKey
}) => {
  const { source, kind, key } = options

  const adjustedKey = kind ? key.replace(`${kind}_`, '') : key
  const metricKey = kind === key ? source : `${source}_${adjustedKey}`

  return metricKey as AllMetricsKey
}
