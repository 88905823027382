import * as SliderPrimitive from '@radix-ui/react-slider'
import { styled } from '@/theme'

export const ValueBox = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 12,
  minWidth: 40,
  height: 32,
  bAll: '$stroke',
  borderRadius: 8,
  background: '$white'
})

export const StyledTrack = styled(SliderPrimitive.Track, {
  backgroundColor: '$neutralHighMedium',
  position: 'relative',
  flexGrow: 1,
  borderRadius: '20px',

  '&[data-orientation="horizontal"]': {
    height: 4
  },
  '&[data-orientation="vertical"]': { width: 4 }
})

export const StyledRange = styled(SliderPrimitive.Range, {
  position: 'absolute',
  backgroundColor: '$brandPrimaryPure',
  borderRadius: '20px',
  height: '100%'
})

export const StyledThumb = styled(SliderPrimitive.Thumb, {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  size: 12,
  backgroundColor: '$white',
  bAll: '$brandPrimaryPure',
  borderRadius: '100%',
  cursor: 'pointer',
  transition: '.2s',

  '&:hover': { backgroundColor: '$brandPrimaryPure' },

  '&::after': {
    content: '""',
    display: 'block',
    size: 8,
    borderRadius: '50%',
    backgroundColor: '$brandPrimaryPure'
  }
})

export const StyledSlider = styled(SliderPrimitive.Root, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  width: '100%',

  '&[data-orientation="horizontal"]': {
    height: 20
  },

  '&[data-orientation="vertical"]': {
    flexDirection: 'column',
    width: 20,
    height: '100%'
  }
})
