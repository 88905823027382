import { AreaOfInterestData, BaseInterestArea } from '@/types/area/AreaOfInterest'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface CurrentInterestAreaState {
  currentInterestArea: BaseInterestArea | undefined
  lastOrganizationId: string | undefined

  setCurrentInterestArea: (data: BaseInterestArea | AreaOfInterestData | undefined) => void
  setLastOrganizationId: (id: string | undefined) => void
}

export const useCurrentInterestAreaStore = createWithEqualityFn<CurrentInterestAreaState>(
  set => ({
    currentInterestArea: undefined,
    lastOrganizationId: undefined,

    setCurrentInterestArea: data => set({ currentInterestArea: data }),
    setLastOrganizationId: id => set({ lastOrganizationId: id })
  }),
  shallow
)
