import { styled, CSS } from '@/theme'
import i18n from '../../../../../plugins/i18n/i18n'

const GRID_LAYOUT = 'minmax(280px, 6fr) 100px 100px 120px 120px 120px'

const hideCSS: CSS = {
  transform: 'translateX(-100%)',
  opacity: 0,
  my: -40,
  pointerEvents: 'none'
}

export const OpportunityItemRow = styled('div', {
  display: 'grid',
  gridTemplateColumns: GRID_LAYOUT,
  alignItems: 'center',
  padding: '$xxxs $xs',
  pr: 'calc($xs + $nano)',
  gap: '$xs',
  bBottom: '$neutralHighPure',
  position: 'relative',

  cursor: 'pointer',
  '&:hover': {
    background: '$neutralHighMedium50'
  },

  '.dismiss-button': {
    position: 'absolute',
    right: -2,
    top: -6,
    zIndex: 2,
    opacity: 0
  },

  '&:first-of-type': {
    pt: 'calc($xxxs + $nano)',
    '.dismiss-button': {
      top: 0
    }
  },

  '.item-options': { opacity: 0 },

  variants: {
    variant: {
      header: {
        mr: '$nano',
        cursor: 'default',
        '&:hover': {
          background: 'inherit',
          '.item-options, .dismiss-button': {
            opacity: 0
          }
        }
      },
      opportunity: {
        '&:hover': {
          '.item-options, .dismiss-button': {
            opacity: 1
          }
        }
      },
      button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '$neutralLowPure',
        gap: '$micro',
        bBottom: 'transparent'
      }
    },

    new: {
      true: {},
      false: {}
    },

    flexible: {
      true: {
        display: 'flex',
        gap: '0px !important',
        alignItems: 'center',
        justifyContent: 'space-between',

        transitionDelay: '.1s, .3s',
        transitionDuration: '.3s, .4s',
        transitionProperty: 'all, margin',

        '& .large-column': {
          minWidth: 205,
          maxWidth: 205,
          flex: 1
        },
        '& .medium-column': {
          minWidth: 125,
          maxWidth: 125,
          flex: 1
        },
        '& .small-column': {
          minWidth: 122,
          maxWidth: 122,
          flex: 1
        },
        '& .options-column': {
          minWidth: 40,
          maxWidth: 40
        }
      }
    },

    moved: {
      true: {
        ...hideCSS
      },
      false: {}
    }
  },

  compoundVariants: [
    {
      variant: 'opportunity',
      new: true,
      css: {
        background: '$feedbackWarningLight',
        '&:hover': {
          background: '$feedbackWarningMedium'
        }
      }
    }
  ],

  defaultVariants: {
    variant: 'opportunity',
    new: false
  }
})

const SuccessLink = styled('button', {
  all: 'unset',
  textDecoration: 'underline',
  cursor: 'pointer'
})

export const MoveOppToSuccessMessage = (props: { areaName: string; onClick: () => void }) => (
  <span>
    {i18n.t('opportunityMovedTo')}
    <q>
      <SuccessLink onClick={props.onClick}>{props.areaName}</SuccessLink>
    </q>
  </span>
)
