import useUser from '@/hooks/useUser'
import LayoutTopicPlan from './LayoutTopicPlan'
import Layout from './Layout'
import { TopBarProps } from './top-bar/TopBar'

const LayoutCurrentPlan = (topBarProps?: TopBarProps) => {
  const { currentUserPlan } = useUser()

  return currentUserPlan === 'topic' ? <LayoutTopicPlan /> : <Layout {...topBarProps} />
}

export default LayoutCurrentPlan
