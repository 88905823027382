import FeedbackService from '@/services/FeedbackService'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

const SOURCE_ALIAS_NAME = 'source_alias'
const KIND_NAME = 'kind'

type SourceMode = 'kind' | 'sourceAlias'

const mapMode: Record<SourceMode, string> = {
  kind: KIND_NAME,
  sourceAlias: SOURCE_ALIAS_NAME
}

interface Params {
  enabled?: boolean
  sourceMode?: SourceMode
}

const useSourcesQuery = (
  { enabled = true, sourceMode = 'kind' }: Params = { enabled: true, sourceMode: 'kind' }
) => {
  const sourcesQueryFn = async () => {
    const [error, response] = await FeedbackService.getFeedbackFieldValues({
      type: 'enum',
      fieldName: mapMode[sourceMode]
    })

    if (error) throw error
    return response
  }

  const { data, ...query } = useQuery({
    queryKey: ['sources', { sourceMode }],
    queryFn: sourcesQueryFn,
    enabled,
    retry: false,
    retryOnMount: false
  })

  const values = useMemo(() => {
    const items = data?.values ?? []
    return items.filter(value => value && value.length > 0)
  }, [data])

  return {
    data,
    values,
    ...query
  }
}

export default useSourcesQuery
