import { NavLink } from 'react-router-dom'

import { styled, typefaceVariants } from '@/theme'

export const PageLink = styled(NavLink, {
  ...typefaceVariants.subtitleRegularXXXS,
  borderRadius: 8,
  boxSizing: 'border-box',
  color: '$brandPrimaryPure',
  padding: '8px 16px',
  textDecoration: 'none',
  width: 200,

  '&.active': {
    ...typefaceVariants.subtitleSemiBoldXXXS,
    backgroundColor: '$brandPrimaryLight'
  }
})
