import { FilterTopicOption } from '@/types/filters/Filters'
import { FilterItemDropdown } from '.'
import { useState } from 'react'
import FiltersSkeleton from '../../FiltersSidebar/AddFilterMenu/FiltersSkeleton'
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'
import useDebounce from '@/hooks/useDebounce'
import SearchInputForFilters from '../../SearchInputFilter'
import useCustomerUsersFilters from '@/hooks/filters/useCustomerUsersFilters'
import useCustomerUsersStringFilterSelection from '../../FiltersSidebar/CustomerRecordsFilter/Users/useCustomerUsersStringFilterSelection'
import useCustomerUsersStringFilter from '../../FiltersSidebar/CustomerRecordsFilter/Users/useCustomerUsersStringFilter'
import CustomerRecordsIdFilterContent from '../../FiltersSidebar/CustomerRecordsFilter/common/CustomerRecordsFilterContent'
import CustomerRecordsStringFilterContent from '../../FiltersSidebar/CustomerRecordsFilter/common/CustomerRecordsStringFilterContent'
import useCustomerRecordIdFilters from '../../FiltersSidebar/CustomerRecordsFilter/Accounts/useCustomerRecordIdFilters'

interface FilterItemDropdownUsersProps {
  filter: FilterTopicOption
  selectedValues: string[]
  newFeed?: boolean
}

function FilterItemDropdownUsers({
  filter,
  selectedValues,
  newFeed = false
}: FilterItemDropdownUsersProps) {
  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const debouncedSearch = useDebounce(searchValue, 350)

  const { fields } = useCustomerUsersFilters({ refetch: false })
  const { apply, hasChanges, isChecked, selectOption } = useCustomerUsersStringFilterSelection({
    filterKey: filter.id,
    newFeed
  })

  const { options: stringOptions } = useCustomerUsersStringFilter({
    filterKey: filter.id,
    enabled: true
  })

  const fieldFilter = fields.find(userField => userField.key === filter.id)
  const isIdFilter = fieldFilter?.type === 'id'

  const {
    options: idOptions,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isShowMoreDisabled
  } = useCustomerRecordIdFilters({
    customerRecordType: 'user',
    enabled: open && isIdFilter,
    filter: fieldFilter ?? {
      displayName: filter.text,
      name: filter.text,
      key: filter.id,
      type: 'id'
    },
    searchQuery: debouncedSearch,
    newFeed
  })

  function getTooltipText() {
    const values = isIdFilter
      ? idOptions.filter(option => selectedValues.includes(option.id)).map(option => option.text)
      : selectedValues

    const selectedOptions = values.map(value => `<b>${value}</b>`).join(' or ')

    return `Is ${selectedOptions}`
  }

  useDidUpdateEffect(() => {
    if (!open) {
      setSearchValue('')
    }

    return () => {
      setSearchValue('')
    }
  }, [open])

  const onChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value)
  }

  const onKeyUp: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  const onClear = () => {
    setSearchValue('')
  }

  const content = isIdFilter ? (
    <CustomerRecordsIdFilterContent
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isChecked={isChecked}
      isFetchingNextPage={isFetchingNextPage}
      isShowMoreDisabled={isShowMoreDisabled}
      key={filter.id}
      options={idOptions}
      selectOption={selectOption}
    />
  ) : (
    <CustomerRecordsStringFilterContent
      isChecked={isChecked}
      options={stringOptions}
      selectOption={selectOption}
    />
  )

  const dropdown = isIdFilter ? (
    <>
      <SearchInputForFilters
        clearable
        css={{ mt: '$micro', mb: '$micro' }}
        onChange={onChange}
        onClear={onClear}
        onKeyUp={onKeyUp}
        placeholder="Search"
        size="small"
        small
        value={searchValue}
      />
      {content}
      {isLoading && !isFetchingNextPage && <FiltersSkeleton />}
    </>
  ) : (
    <CustomerRecordsStringFilterContent
      isChecked={isChecked}
      options={stringOptions}
      selectOption={selectOption}
    />
  )

  return (
    <FilterItemDropdown
      content={selectedValues.length}
      contentType="count"
      disabled={!hasChanges}
      dropdown={dropdown}
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      title={filter.text}
      tooltip={getTooltipText()}
    />
  )
}

export default FilterItemDropdownUsers
