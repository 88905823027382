// reference: https://codesandbox.io/s/objective-shape-8r4utm?file=/src/CalendarCell.js

import { useRef } from 'react'
import { useCalendarCell } from '@react-aria/calendar'
import { useFocusRing } from '@react-aria/focus'
import { mergeProps } from '@react-aria/utils'

import type { CalendarState } from '@react-stately/calendar'
import type { CalendarDate } from '@internationalized/date'
import { styled } from '@/theme'

const defaultRadius = '20px'

const StyledTd = styled('td', {
  textAlign: 'center'
})

const SelectionCell = styled('div', {
  marginBottom: 4,

  '&:focus': {
    outline: 'none'
  },

  variants: {
    selected: {
      true: {
        background: '$greenBackground'
      }
    },
    muted: {
      true: {
        color: '$grey',
        pointerEvents: 'none'
      },
      false: {
        color: '$dark'
      }
    },
    roundedLeft: {
      true: {
        borderTopLeftRadius: defaultRadius,
        borderBottomLeftRadius: defaultRadius
      }
    },
    roundedRight: {
      true: {
        borderTopRightRadius: defaultRadius,
        borderBottomRightRadius: defaultRadius
      }
    }
  },

  compoundVariants: [
    {
      selected: true,
      muted: true,
      css: {
        background: 'transparent'
      }
    }
  ]
})

const DateCell = styled('div', {
  padding: '8px',
  // margin: '0px 8px',
  borderRadius: defaultRadius,
  bAll: 'transparent',

  '&:hover': { background: '$pajaritoTransparent' },

  variants: {
    selected: {
      true: {
        background: '$pajarito',
        color: '$white',

        '&:hover': {
          background: '$pajaritoBrighter'
        }
      }
    },
    muted: {
      true: {}
    }
  },

  compoundVariants: [
    {
      selected: true,
      muted: true,
      css: {
        background: 'unset',
        color: 'unset'
      }
    }
  ]
})

export interface CalendarCellProps {
  state: CalendarState
  date: CalendarDate
}

export function CalendarCell({ state, date }: CalendarCellProps) {
  const ref = useRef(null)
  const { cellProps, buttonProps, isSelected, formattedDate, isDisabled, isUnavailable } =
    useCalendarCell({ date }, state, ref)

  // const isOutsideMonth = !isSameMonth(currentMonth, date)

  const isRoundedLeft = isSelected
  const isRoundedRight = isSelected

  const { focusProps } = useFocusRing()

  return (
    <StyledTd {...cellProps}>
      <SelectionCell
        {...mergeProps(buttonProps, focusProps)}
        muted={isDisabled || isUnavailable}
        ref={ref}
        roundedLeft={isRoundedLeft}
        roundedRight={isRoundedRight}
        selected={isSelected}
      >
        <DateCell muted={isDisabled} selected={isSelected}>
          {formattedDate}
        </DateCell>
      </SelectionCell>
    </StyledTd>
  )
}
