import { CSS, styled } from '@/theme'
import { WarningCircle } from '@phosphor-icons/react'
import { ReactNode } from 'react'

const WarningContainer = styled('div', {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 8,
  color: '$neutralColorLowMedium',

  fontSize: 10,

  strong: {
    fontSize: 12,
    fontWeight: '$semiBold'
  },

  span: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },

  p: {
    padding: 0
  }
})

export interface WarningProps {
  title: string | ReactNode
  description: string | ReactNode
  css?: CSS
}

const Warning = ({ title, description, css }: WarningProps) => {
  return (
    <WarningContainer css={css}>
      <WarningCircle size={24} />
      <div>
        <strong>{title}</strong>
        <p>{description}</p>
      </div>
    </WarningContainer>
  )
}

export default Warning
