import { AxiosError } from 'axios'
import { EChartOption } from 'echarts'

export interface ResponseBase<T = unknown> {
  message: string
  data: T
}

export interface RequestError {
  details?: string
  key: string
  message?: string
}

export interface ParamsBase {
  limit?: string
  offset?: string
  [x: string]: string | undefined
}

export function isAxiosError(response: unknown): response is AxiosError<RequestError> {
  return (response as AxiosError).isAxiosError
}

export type EchartCustomSeries = EChartOption.Series & {
  labelLayout?: {
    hideOverlap?: boolean
  }
}
