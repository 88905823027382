import {
  MergedTopicInfo,
  SuggestedTopic,
  Topic,
  TopicCategory,
  TopicCreate,
  TopicData,
  TopicRequests,
  TopicUpdate,
  TopicWithFeedbackCount
} from '@/types/classification'
import {
  KeywordPayload,
  MergeTopicResponse,
  TopicCountingResult
} from '@/types/classification/TopicRequests'
import { SearchPayload } from '@/types/feedbacks/FeedbackRequests'
import Request from './Request'
import NewRequest, { RequestReturnType } from './RequestHandlers/NewRequest'

export const topicErrors: Record<string, string> = {
  topic_already_exists: 'A topic with same name already exists in your workspace',
  update_resource_error: 'A topic with same name already exists in your workspace'
}

export default class TopicService {
  static PREFIX = '/topics'
  static DEFAULT_PAYLOAD = {
    feedback_search_schema: {
      filter: {
        search_text: '',
        posted_at: {},
        archived: false,
        sentiment: [],
        feedback_group_enable: true,
        feedback_source_ids: [],
        schema_fields_datetime: [],
        schema_fields_integer: [],
        schema_fields_number: [],
        schema_fields_string: [],
        custom_fields_datetime: [],
        custom_fields_integer: [],
        custom_fields_number: [],
        custom_fields_string: []
      },
      filter_keyword: {
        theme_ids: [],
        topic_ids: []
      },
      topic_groups: [],
      size: 200,
      offset: 0,
      order_by: 'posted_at',
      order_direction: 'desc'
    },
    topic_structure: [],
    theme_id_list: [],
    order_by: 'volume',
    order_direction: 'desc',
    count_by: 'ingested_feedback_id',
    exact_count: false,
    sentiment_count: true,
    sentiment_exact_count: false,
    size: 200,
    offset: 0
  } as TopicRequests.TopicStatsPayload

  static async list(payload?: TopicRequests.ListPayload) {
    const { skip, limit } = payload || {}

    const params = new URLSearchParams()

    if (skip) params.append('skip', `${skip > 0 ? skip * 30 : 0}`)

    params.append('limit', limit ? `${limit}` : '30')

    const data = await Request.get<TopicWithFeedbackCount[]>(`${this.PREFIX}?${params.toString()}`)
    return data
  }

  static async search(payload: TopicRequests.SearchPayload | undefined) {
    const { name, description, skip, limit } = payload || {}

    const params = new URLSearchParams()
    if (name?.length) params.append('name', name)

    if (description?.length) params.append('description', description)

    if (skip) params.append('skip', `${skip > 0 ? skip * 30 : 0}`)

    params.append('limit', limit ? `${limit}` : '30')

    const data = await Request.get<TopicWithFeedbackCount[]>(`${this.PREFIX}?${params.toString()}`)
    return data
  }

  static async getById(topicId: string) {
    return await Request.get<TopicRequests.GetOneResponse>(`${this.PREFIX}/${topicId}`)
  }

  static async create(payload: TopicCreate) {
    return await NewRequest.post<TopicRequests.CreateResponse>(`${this.PREFIX}`, payload)
  }

  static async update(topicId: string, payload: TopicUpdate) {
    return await NewRequest.put<Topic | Topic[]>(`${this.PREFIX}/${topicId}`, payload)
  }

  static async delete(topicId: string, organizationId: string) {
    return await Request.del<void>(`${this.PREFIX}/${topicId}?organization_id=${organizationId}`)
  }

  static async addKeywordRelation(topicId: string, keywordId: string) {
    return await Request.post<void>(`${this.PREFIX}/${topicId}/keyword/${keywordId}`)
  }

  static async addKeywordRelationBatch(
    topicId: string,
    keywords: (KeywordPayload & { organization_id: string })[]
  ) {
    return await Request.post(`${this.PREFIX}/${topicId}/keyword_batch`, keywords)
  }

  static async removeKeywordRelation(topicId: string, keywordId: string) {
    return await Request.del<void>(`${this.PREFIX}/${topicId}/keyword/${keywordId}`)
  }

  static async removeKeywordRelationBatch(
    payload: TopicRequests.RemoveKeywordRelationBatchPayload[]
  ) {
    return await Request.del<void>(`${this.PREFIX}/topic-keyword`, undefined, payload)
  }

  static async addThemeRelation(topicId: string, themeId: string) {
    return await Request.post(`${this.PREFIX}/${topicId}/themes/${themeId}`)
  }

  static async removeThemeRelation(topicId: string, themeId: string) {
    return await Request.del(`${this.PREFIX}/${topicId}/themes/${themeId}`)
  }

  static async getSuggestedTopics(payload?: SearchPayload, category?: TopicCategory) {
    const requestPayload = {
      feedback_search_schema: payload || {
        filter: {
          search_text: '',
          posted_at: {},
          feedback_source_ids: [],
          product_reference: [],
          rating: {},
          archived: false,
          sentiment: [],
          feedback_keyword_classes: [],
          schema_fields_string: [],
          schema_fields_integer: [],
          schema_fields_number: [],
          schema_fields_datetime: [],
          custom_fields_string: [],
          custom_fields_integer: [],
          custom_fields_number: [],
          custom_fields_datetime: []
        },
        filter_keyword: {
          topic_ids: [],
          theme_ids: [],
          topic_groups: []
        },
        size: 100,
        offset: 0,
        order_by: 'posted_at',
        order_direction: 'desc'
      },
      category: category || 'OTHER'
    }
    const data = await Request.post<{ suggested_topic_list: TopicRequests.SuggestedTopicData[] }>(
      `${this.PREFIX}/suggested_topic/search`,
      requestPayload
    )

    return (data.suggested_topic_list || []).map(
      topic =>
        ({
          id: topic.topic_id,
          name: topic.topic_name,
          themeId: topic.theme_id || null,
          themeName: topic.theme_name,
          frequency: topic.frequency,
          sentimentMetrics: {
            positiveCount: topic.sentiment_metrics.positive_count,
            negativeCount: topic.sentiment_metrics.negative_count,
            neutralCount: topic.sentiment_metrics.neutral_count,
            positivePercentage: topic.sentiment_metrics.positive_percent,
            negativePercentage: topic.sentiment_metrics.negative_percent,
            neutralPercentage: topic.sentiment_metrics.neutral_percent,
            netSentiment: topic.sentiment_metrics.net_sentiment
          }
        }) as SuggestedTopic
    )
  }

  static async acceptSuggestion(payload: TopicRequests.AcceptSuggestion) {
    const requestPayload: TopicRequests.AcceptSuggestionPayload = {
      topic_id: payload.topicId,
      topic_name: payload.topicName,
      theme_id: payload.themeId,
      description: payload.description || ''
    }

    return await Request.post<{ suggested_topic_list: TopicRequests.SuggestedTopicData[] }>(
      `${this.PREFIX}/suggested_topic/accept`,
      requestPayload
    )
  }

  static async rejectSuggestion(topicId: string) {
    return await Request.post(`${this.PREFIX}/${topicId}/blocklist`)
  }

  static parseTopicStatsToData(item: TopicRequests.TopicStatsItem): TopicData {
    return {
      totalFrequency: item.frequency,
      topicId: item.id,
      topicName: item.name,
      category: item.category,
      isMergeParent: item.is_merged,
      mergedTopics: item.merged_topic_list || [],
      themeList: item.theme_list
        ? item.theme_list.map(theme => ({
            themeId: theme.theme_id,
            themeName: theme.theme_name,
            themeCategory: theme.theme_category as TopicCategory
          }))
        : [],
      sentiment: {
        positiveCount: item.sentiment_metrics?.positive_count || 0,
        negativeCount: item.sentiment_metrics?.negative_count || 0,
        neutralCount: item.sentiment_metrics?.neutral_count || 0,
        positivePercentage: item.sentiment_metrics?.positive_percent || 0,
        negativePercentage: item.sentiment_metrics?.negative_percent || 0,
        neutralPercentage: item.sentiment_metrics?.neutral_percent || 0,
        netSentiment: item.sentiment_metrics?.net_sentiment || 0
      }
    }
  }

  /**
   * Fetch for Topic Groups (a.k.a themes)
   */
  static async getGroupedTopics(
    payload: TopicRequests.TopicStatsPayload
  ): Promise<RequestReturnType<TopicData[]>> {
    const [error, data] = await NewRequest.post<TopicRequests.StatsTopicsResponse>(
      `${this.PREFIX}/grouped/stats`,
      payload
    )

    if (error) return [error, undefined]

    const items = data.topic_structure.map(this.parseTopicStatsToData)
    return [undefined, items]
  }

  static async ungroupedTopics(
    payload: TopicRequests.TopicStatsPayload
  ): Promise<RequestReturnType<{ items: TopicData[]; total: number }>> {
    const [error, data] = await NewRequest.post<TopicRequests.StatsTopicsResponse>(
      `${this.PREFIX}/ungrouped/stats`,
      payload
    )

    if (error) return [error, undefined]

    const items = data.topic_structure.map(this.parseTopicStatsToData)

    return [undefined, { items, total: data.total_hits }]
  }

  static async specificTopics(
    payload: TopicRequests.TopicStatsPayload
  ): Promise<RequestReturnType<{ items: TopicData[]; total: number }>> {
    const [error, data] = await NewRequest.post<TopicRequests.StatsTopicsResponse>(
      `${this.PREFIX}/specific/stats`,
      payload
    )

    if (error) return [error, undefined]

    const items = data.topic_structure.map(this.parseTopicStatsToData)
    return [undefined, { items, total: data.total_hits }]
  }

  static async getMergedTopicInfo(
    topicId: string,
    customPayload?: TopicRequests.MergedTopicInfoPayload
  ): Promise<RequestReturnType<MergedTopicInfo>> {
    let payload: TopicRequests.MergedTopicInfoPayload
    if (customPayload) {
      payload = customPayload
    } else {
      payload = {
        feedback_search_schema: this.DEFAULT_PAYLOAD.feedback_search_schema,
        count_by: this.DEFAULT_PAYLOAD.count_by,
        exact_count: this.DEFAULT_PAYLOAD.exact_count,
        sentiment_count: this.DEFAULT_PAYLOAD.sentiment_count,
        sentiment_exact_count: this.DEFAULT_PAYLOAD.sentiment_exact_count,
        topic_id: topicId
      }
    }

    const [error, data] = await NewRequest.post<TopicRequests.MergedTopicInfoResponse>(
      `${this.PREFIX}/get_merged_topic_info`,
      payload
    )

    if (error) return [error, undefined]

    const result: MergedTopicInfo = {
      topicParent: this.parseTopicStatsToData(data.topic_info),
      topicChildren: data.merged_topics_info.map(this.parseTopicStatsToData)
    }

    return [undefined, result]
  }

  static async mergeTopic(
    topicName: string,
    topicList: string[]
  ): Promise<RequestReturnType<MergeTopicResponse>> {
    const payload: TopicRequests.MergeTopicPayload = {
      topic_list: topicList,
      topic_name: topicName
    }

    return NewRequest.post<TopicRequests.MergeTopicResponse>(`${this.PREFIX}/merge`, payload)
  }

  static async unmergeTopics(
    topicList: { topicParentId: string; children: string[] }[]
  ): Promise<RequestReturnType<void>> {
    const payload: TopicRequests.UnmergeTopicsPayload = {
      topic_list: topicList.map(item => ({ topic_id: item.topicParentId, children: item.children }))
    }

    return NewRequest.post<void>(`${this.PREFIX}/unmerge`, payload)
  }

  static async getTopicsCount(
    topicList: string[],
    fetchSentiment = false,
    customPayload?: TopicRequests.TopicCountingPayload,
    signal?: AbortSignal
  ): Promise<RequestReturnType<TopicCountingResult>> {
    let payload: TopicRequests.TopicCountingPayload
    if (customPayload) {
      payload = customPayload
    } else {
      payload = {
        feedback_search_schema: this.DEFAULT_PAYLOAD.feedback_search_schema,
        count_by: this.DEFAULT_PAYLOAD.count_by,
        exact_count: this.DEFAULT_PAYLOAD.exact_count,
        sentiment_count: fetchSentiment || this.DEFAULT_PAYLOAD.sentiment_count,
        sentiment_exact_count: this.DEFAULT_PAYLOAD.sentiment_exact_count,
        topic_list: topicList
      }
    }

    const [error, data] = await NewRequest.post<TopicRequests.TopicCountingResponse>(
      `${this.PREFIX}/counting`,
      payload,
      { signal }
    )

    if (error) return [error, undefined]

    const result: TopicCountingResult = {
      took: data.took,
      frequency: data.frequency,
      sentimentMetrics: data.sentiment_metrics
        ? {
            positiveCount: data.sentiment_metrics.positive_count,
            negativeCount: data.sentiment_metrics.negative_count,
            neutralCount: data.sentiment_metrics.neutral_count,
            positivePercentage: data.sentiment_metrics.positive_percent,
            negativePercentage: data.sentiment_metrics.negative_percent,
            neutralPercentage: data.sentiment_metrics.neutral_percent,
            netSentiment: data.sentiment_metrics.net_sentiment
          }
        : undefined
    }

    return [undefined, result]
  }
}
