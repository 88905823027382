import useAdvancedFiltersStore from '@/store/useFiltersStore/useAdvancedFiltersStore'
import {
  AdvancedFilterContent,
  FilterMap,
  FilterMapItem,
  FilterNode,
  FilterType,
  GenericFilter
} from '@/types/filters/AdvancedFilters'
import {
  advancedFilterToContent,
  contentToAdvancedFilter,
  mergeAdvancedFilters,
  mergeAreasToAdvancedFilterContent
} from '@/utils/advancedFilter'
import { shallow } from 'zustand/shallow'
import { useCallback, useMemo } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import FiltersService from '@/services/FiltersService'
import useLogging from '../useLogging'
import useToastMessageStore from '@/store/useToastMessageStore'
import { AreaOfInterestData, BaseInterestArea } from '@/types/area/AreaOfInterest'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useOpportunityStore from '@/store/useOpportunityStore'
import AreaService from '@/services/AreaService'
import { OpportunityItem } from '@/types/opportunity/Opportunity'
import useBasicAreaOfInterestQuery from '../areaOfInterest/useBasicAreaOfInterestQuery'
import { mapArea } from '@/utils/areaOfInterest'
import FieldsService from '@/services/FieldsService'
import { FieldsRequests } from '@/types/fields'
import { useTranslation } from 'react-i18next'
import { SavedFilterContentAdvanced } from '@/types/filters/Filters'
import { SegmentationItem } from '@/types/segmentation/Segmentation'
import { mapAccountsTypes } from '../filters/useAccountsFilters'
import { CustomerRecordsFieldTypes } from '@/types/manage-customers/CustomerRecordsFilters'
import { mapCustomerUsersTypes } from '../filters/useCustomerUsersFilters'

interface ApplyFilterFromContentParams {
  content: AdvancedFilterContent
  context?: string
  applyOnStore?: boolean
}

interface ApplyOpportunityFiltersParams {
  opportunity: OpportunityItem
  areaIds?: string[]
  clearAllFilters?: boolean
}

export const getFilterMap = (data?: FieldsRequests.FieldsResponse): FilterMap => {
  const feedbackMap: FilterMapItem = new Map()
  feedbackMap.set('text.feedback', { type: 'text', path: 'text.feedback' })
  feedbackMap.set('text', { type: 'text', path: 'text' })
  feedbackMap.set('posted_at.gte', { type: 'datetime', path: 'posted_at' })
  feedbackMap.set('posted_at.lt', { type: 'datetime', path: 'posted_at' })
  feedbackMap.set('label', { type: 'text', path: 'label' })

  if (!data)
    return {
      feedback: feedbackMap,
      account: new Map(),
      user: new Map()
    }

  const feedbackFields = data.filter(
    field => !field.type.startsWith('user') && !field.type.startsWith('account')
  )
  const accountFields = data.filter(field => field.type.startsWith('account'))
  const userFields = data.filter(field => field.type.startsWith('user'))

  const accountMap: FilterMapItem = new Map()
  const userMap: FilterMapItem = new Map()

  feedbackFields.forEach(field => {
    feedbackMap.set(field.name, { type: field.type as FilterType, path: field.path })
  })

  accountFields.forEach(field => {
    accountMap.set('account.' + field.name, { type: field.type as FilterType, path: field.path })
  })

  userFields.forEach(field => {
    userMap.set('user.' + field.name, { type: field.type as FilterType, path: field.path })
  })

  return {
    feedback: feedbackMap,
    account: accountMap,
    user: userMap
  }
}

const useAdvancedFilters = () => {
  const { t } = useTranslation()
  const { logException } = useLogging({ context: 'advanced-filters' })
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const currentAreaOfInterest = useCurrentInterestAreaStore(
    state => state.currentInterestArea,
    shallow
  )

  const {
    filters,
    setFilters,
    addSimpleFilter,
    removeSimpleFilter,
    updateSimpleFilter,
    upinsertSimpleFilter,
    applyAdvancedFiltersSet,
    context,
    setContext,
    isFetchingContext,
    setIsFetchingContext
  } = useAdvancedFiltersStore(state => state, shallow)

  const clearFilters = () => {
    setFilters({ operator: '$and', value: [] })
    setContext(null)
  }

  const filterTypeMapQueryFn = async () => {
    const [fieldsError, fieldsResponse] = await FieldsService.fields('feedback_fields')
    if (fieldsError) throw fieldsError

    const [accountFieldsError, accountFieldsResponse] =
      await FieldsService.fields('audience_account')
    if (accountFieldsError) throw accountFieldsError
    const mappedAccountFields = accountFieldsResponse.map(field => ({
      ...field,
      type: mapAccountsTypes[field.type as CustomerRecordsFieldTypes]
    }))

    const [userFieldsError, userFieldsResponse] = await FieldsService.fields('audience_user')
    if (userFieldsError) throw userFieldsError
    const mappedUserFields = userFieldsResponse.map(field => ({
      ...field,
      type: mapCustomerUsersTypes[field.type as CustomerRecordsFieldTypes]
    }))

    return [...fieldsResponse, ...mappedAccountFields, ...mappedUserFields]
  }

  const { data } = useQuery({
    queryKey: ['advanced-filter-fields'],
    queryFn: filterTypeMapQueryFn
  })
  const filterTypeMap = useMemo(() => getFilterMap(data), [data])

  const getFilterContext = async (content: AdvancedFilterContent) => {
    setIsFetchingContext(true)
    const [contextError, contextData] = await FiltersService.advancedFilterContext({
      filter: content
    })

    setIsFetchingContext(false)
    if (contextError) throw contextError
    return contextData.context
  }

  const setCurrentOpportunity = useOpportunityStore(state => state.setCurrentOpportunity)
  const setOpportunityAreas = useOpportunityStore(state => state.setSelectedAreas)

  const { mutate: applyFilterFromContent, isLoading: applyFilterFromContentIsLoading } =
    useMutation({
      mutationKey: ['advanced-filter', 'apply-from-content'],
      mutationFn: async ({
        content,
        context,
        applyOnStore = true
      }: ApplyFilterFromContentParams) => {
        let filterMap = filterTypeMap
        if (!data) {
          const fieldsData = await filterTypeMapQueryFn()

          filterMap = getFilterMap(fieldsData)
          if (filterMap.feedback.size === 0) throw new Error('No filter types found')
        }

        const advancedFiltersNode = contentToAdvancedFilter({ content, filterMap })

        if (context) {
          setContext(context)
        } else {
          const filterContext = await getFilterContext(content)
          setContext(filterContext)
        }

        if (applyOnStore) {
          let filterNode = advancedFiltersNode as FilterNode
          if ((advancedFiltersNode as FilterNode).operator !== '$and') {
            filterNode = { operator: '$and', value: [advancedFiltersNode] }
          }

          setFilters(filterNode)
        }
        return advancedFiltersNode
      },
      onError: error => {
        const message = 'Failed to apply filter.'
        logException(error, { message })
        addErrorToast({ text: t('failedToApplyFilter') ?? message })
      }
    })

  const applyFilterFromArea = (area: BaseInterestArea | AreaOfInterestData) => {
    if (area.error && area.error.message) {
      addErrorToast({ text: `Area error: ${area.error.message}` })
      return
    }
    const content = area.content[0]?.values.filter as AdvancedFilterContent
    if (content) {
      applyFilterFromContent({
        content,
        context: area.context
      })
    }
  }

  const applyFilterFromSegmentation = (segmentation: SegmentationItem) => {
    const content = segmentation.content[0]?.values.filter as AdvancedFilterContent
    if (content) {
      applyFilterFromContent({
        content,
        context: segmentation.context
      })
    }
  }

  const { areas: advancedAreas } = useBasicAreaOfInterestQuery()

  const applyOpportunityFilters = async ({
    opportunity,
    areaIds = [],
    clearAllFilters = false
  }: ApplyOpportunityFiltersParams) => {
    setCurrentOpportunity(opportunity)
    let areas = advancedAreas
    if (areas.length === 0) {
      const [error, response] = await AreaService.searchAreas({
        limit: 300,
        page: 1,
        transform: true
      })

      if (error) {
        logException(error, { message: 'Failed to fetch areas' })
        return
      }

      areas = response.areas.map(mapArea)
    }

    if (areaIds.length > 0) {
      areas = areas.filter(area => areaIds.includes(area.id))
    } else {
      areas = areas.filter(area => opportunity.relations?.includes(area.id))
    }
    setOpportunityAreas(areas)
    if (clearAllFilters) {
      setFilters({ operator: '$and', value: [] })
    }
    const currentFilters: FilterNode = clearAllFilters ? { operator: '$and', value: [] } : filters
    const areasContent = mergeAreasToAdvancedFilterContent(areas)
    const appliedContent = advancedFilterToContent(currentFilters)
    const mergedContent = mergeAdvancedFilters([areasContent, appliedContent], '$and')
    applyFilterFromContent({ content: mergedContent, applyOnStore: false })
  }

  const { mutate: addFilter, isLoading: isAddFilterLoading } = useMutation({
    mutationKey: ['advanced-filter', 'add-filter'],
    mutationFn: async (filter: GenericFilter) => {
      const newFiltersNode = addSimpleFilter({ ...filter, isFromEntity: false })
      const advancedFiltersContent = advancedFilterToContent(newFiltersNode)
      const context = await getFilterContext(advancedFiltersContent)
      setOpportunityAreas([])
      setContext(context)
    },
    onError: error => {
      const message = 'Failed to add filter.'
      logException(error, { message })
      addErrorToast({ text: t('failedToAddFilter') ?? message })
    }
  })

  const { mutate: removeFilter, isLoading: isRemoveFilterLoading } = useMutation({
    mutationKey: ['advanced-filter', 'remove-filter'],
    mutationFn: async ({ name, index }: { name?: string; index?: number }) => {
      if (!name && index === undefined) throw new Error('No filter name or index provided')

      const newFiltersNode = removeSimpleFilter({ name, index })
      const advancedFiltersContent = advancedFilterToContent(newFiltersNode)
      const context = await getFilterContext(advancedFiltersContent)
      setOpportunityAreas([])
      setContext(context)
    },
    onError: error => {
      const message = 'Failed to remove filter.'
      logException(error, { message })
      addErrorToast({ text: t('failedToRemoveFilter') ?? message })
    }
  })

  const { mutate: updateFilter, isLoading: isUpdateFilterLoading } = useMutation({
    mutationKey: ['advanced-filter', 'update-filter'],
    mutationFn: async ({ filter, index }: { filter: GenericFilter; index: number }) => {
      const newFiltersNode = updateSimpleFilter({
        filter: { ...filter, isFromEntity: false },
        index
      })
      const advancedFiltersContent = advancedFilterToContent(newFiltersNode)
      const context = await getFilterContext(advancedFiltersContent)
      setOpportunityAreas([])
      setContext(context)
    },
    onError: error => {
      const message = 'Failed to update filter.'
      logException(error, { message })
      addErrorToast({ text: t('failedToUpdateFilter') ?? message })
    }
  })

  const { mutate: upinsertFilter, isLoading: isUpinsertFilterLoading } = useMutation({
    mutationKey: ['advanced-filter', 'upinsert-filter'],
    mutationFn: async (filter: GenericFilter) => {
      const newFiltersNode = upinsertSimpleFilter(filter)
      const advancedFiltersContent = advancedFilterToContent(newFiltersNode)
      const context = await getFilterContext(advancedFiltersContent)
      setOpportunityAreas([])
      setContext(context)
    },
    onError: error => {
      const message = 'Failed to upinsert filter.'
      logException(error, { message })
      addErrorToast({ text: t('failedToUpinsertFilter') ?? message })
    }
  })

  const { mutate: applyFilterSet, isLoading: isApplyFilterSetLoading } = useMutation({
    mutationKey: ['advanced-filter', 'apply-filter-set'],
    mutationFn: async (filters: FilterNode) => {
      const newFiltersNode = applyAdvancedFiltersSet(filters)
      const advancedFiltersContent = advancedFilterToContent(newFiltersNode)
      const context = await getFilterContext(advancedFiltersContent)
      setOpportunityAreas([])
      setContext(context)
    },
    onError: error => {
      const message = 'Failed to apply advanced filter set.'
      logException(error, { message })
      addErrorToast({ text: t('failedToApplyFilterSet') ?? message })
    }
  })

  const isMutationLoading =
    applyFilterFromContentIsLoading ||
    isAddFilterLoading ||
    isRemoveFilterLoading ||
    isUpdateFilterLoading ||
    isUpinsertFilterLoading ||
    isApplyFilterSetLoading

  const simpleFiltersCount = useMemo(() => {
    if (filters.operator !== '$and') return 0
    if (!Array.isArray(filters.value)) return 0
    const filtersArray = filters.value as GenericFilter[]
    return filtersArray.length
  }, [filters])

  const hasChanges = useMemo(() => {
    if (currentAreaOfInterest) {
      return (
        JSON.stringify(currentAreaOfInterest.content) !==
        JSON.stringify([
          {
            key: 'advanced',
            name: 'advanced',
            type: 'advanced',
            values: { filter: advancedFilterToContent(filters) }
          }
        ])
      )
    }

    return Boolean(simpleFiltersCount > 0)
  }, [filters, currentAreaOfInterest, simpleFiltersCount])

  const buildFilterContent = useCallback(
    (content?: AdvancedFilterContent): SavedFilterContentAdvanced => ({
      key: 'advanced',
      name: 'advanced',
      type: 'advanced',
      values: {
        filter: content ?? advancedFilterToContent(filters)
      }
    }),
    [filters]
  )

  return {
    filters,
    applyFilterFromContent,
    applyFilterFromContentIsLoading,
    applyFilterFromArea,
    addFilter,
    isAddFilterLoading,
    removeFilter,
    isRemoveFilterLoading,
    updateFilter,
    isUpdateFilterLoading,
    upinsertFilter,
    isUpinsertFilterLoading,
    applyFilterSet,
    isApplyFilterSetLoading,
    isMutationLoading,
    clearFilters,
    context,
    isFetchingContext,
    simpleFiltersCount,
    hasChanges,
    getFilterContext,
    applyOpportunityFilters,
    buildFilterContent,
    applyFilterFromSegmentation
  }
}

export default useAdvancedFilters
