import { API_URL } from '@/config'
import RequestHandler, { RequestReturnType } from './RequestHandlers/NewRequest'
import { ChatData } from '@/types/assistant/Assistant'
import { getFeedbackKindPrettyName } from '@/utils/feedback'
import { FeedRequests } from '@/types/feed'
import { buildSafeSearchParams } from '@/utils/searchParams'
import { stringToDate } from '@/utils/date'
import { TimeSeries } from '@/types/time-series/TimeSeries'
import moment from 'moment'
import { SUMMARY_PARAMS_KEY, SummaryParamsData } from '@/types/params/SummaryParams'

const chatSortingParams = { sort: 'random', random_seed: 0 }

export default class FeedService {
  static async chatStream(
    params: FeedRequests.ChatParams,
    options?: {
      signal?: AbortSignal
    }
  ) {
    const searchParams = buildSafeSearchParams({ ...params, ...chatSortingParams })
    return fetch(`${API_URL}feed/chat?${searchParams.toString()}`, {
      method: 'GET',
      signal: options?.signal,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    })
  }

  static async chatData(
    params: FeedRequests.ChatParams,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<ChatData>> {
    const [error, data] = await RequestHandler.get<FeedRequests.ChatResponse>(
      '/feed/chat',
      { ...params, ...chatSortingParams },
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )

    if (error) return [error, undefined]

    return [
      undefined,
      {
        feedbackList: data.data.map(feedback => ({
          source: feedback.source,
          kind: {
            ...feedback.kind,
            prettyName: getFeedbackKindPrettyName(feedback.kind.name)
          },
          sourceAlias: {
            sourceAliasName: feedback.source_alias,
            sourceAliasPrettyName: getFeedbackKindPrettyName(feedback.source_alias),
            kind: { ...feedback.kind, prettyName: getFeedbackKindPrettyName(feedback.kind.name) }
          },
          id: feedback.id,
          language: feedback.language,
          text: feedback.text,
          customFields: feedback.custom_fields,
          updatedAt: stringToDate(feedback.updated_at),
          postedAt: stringToDate(feedback.posted_at),
          messages: feedback.messages
            ? {
                total: feedback.messages.total,
                firstPostedAt: stringToDate(feedback.messages.first_posted_at),
                lastPostedAt: stringToDate(feedback.messages.last_posted_at)
              }
            : undefined,
          accounts: feedback.accounts,
          users: feedback.users
        })),
        feedbackTakenIntoAccount: data.feedback_taken_into_account,
        feedbackTotalHits: data.feedback_total_hits,
        tokenCountPrediction: {
          feedbackList: data.token_count_prediction.feedback_list,
          history: data.token_count_prediction.history,
          total: data.token_count_prediction.total
        },
        answer: data.answer,
        question: data.question
      }
    ]
  }

  static async summary(
    params: FeedRequests.SummaryParams,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<FeedRequests.SummaryResponse>> {
    const actualParams = { ...params }

    const storedItemParams = localStorage.getItem(SUMMARY_PARAMS_KEY)
    if (storedItemParams && storedItemParams !== 'undefined') {
      const storedParams: SummaryParamsData = JSON.parse(storedItemParams)

      if (storedParams.enabled) {
        actualParams.focus = storedParams.focus ? [storedParams.focus] : params.focus
        actualParams.model = storedParams.model ? storedParams.model : params.model
        actualParams.prompt = storedParams.prompt ? storedParams.prompt : params.prompt

        actualParams.max_words = storedParams.max_words
          ? Number(storedParams.max_words)
          : params.max_words

        actualParams.sample_per_kind = storedParams.sample_per_kind
          ? Number(storedParams.sample_per_kind)
          : params.sample_per_kind
      }
    }

    return await RequestHandler.get<FeedRequests.SummaryResponse>('/feed/summary', actualParams, {
      signal: options?.signal,
      paramsSerializer: {
        indexes: null
      }
    })
  }

  static async timeseries(
    params: FeedRequests.TimeSeriesQueryParams,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<TimeSeries>> {
    const [error, data] = await RequestHandler.get<FeedRequests.TimeSeriesResponse>(
      '/feed/timeseries',
      params,
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )

    if (error) return [error, undefined]

    return [
      undefined,
      {
        timestamps: data.timestamps.map(timestamp => moment.unix(timestamp).utc()),
        values: data.values,
        groups: data.groups
      }
    ]
  }

  /** Get all the areas that can be related to the feedback_id */
  static async getAreasByFeedbackId(feedbackId: string) {
    return await RequestHandler.get<FeedRequests.RelatedFeedbackLabelResponseItem[]>(
      `/feed/${feedbackId}/areas`
    )
  }

  /** Adds or removes various area labels from the feedback by comparing previous_areas and current_areas */
  static async updateFeedbackIdAreas(
    feedbackId: string,
    params: {
      previousAreas: FeedRequests.RelatedFeedbackLabelBaseItem[]
      currentAreas: FeedRequests.RelatedFeedbackLabelBaseItem[]
    }
  ) {
    return await RequestHandler.post<void>(`/feed/${feedbackId}/areas`, {
      previous_areas: params.previousAreas,
      current_areas: params.currentAreas
    })
  }

  /** Remove area labels that are related to feedback_id*/
  static async deleteFeedbackFromArea(feedbackId: string, areaId: string) {
    return await RequestHandler.del<void>(`/feed/${feedbackId}/area/${areaId}`)
  }

  /** Get all the opportunities that can be related to the feedback_id */
  static async getOpportunitiesByFeedbackId(feedbackId: string) {
    return await RequestHandler.get<FeedRequests.RelatedFeedbackLabelResponseItem[]>(
      `/feed/${feedbackId}/opportunities`
    )
  }

  /** Adds or removes various opportunities labels from the feedback by comparing previous_opportunities and current_opportunities */
  static async updateFeedbackIdOpportunities(
    feedbackId: string,
    params: {
      previousOpportunities: FeedRequests.RelatedFeedbackLabelBaseItem[]
      currentOpportunities: FeedRequests.RelatedFeedbackLabelBaseItem[]
    }
  ) {
    return await RequestHandler.post<void>(`/feed/${feedbackId}/opportunities`, {
      previous_opportunities: params.previousOpportunities,
      current_opportunities: params.currentOpportunities
    })
  }

  /** Remove opportunity labels that are related to feedback_id*/
  static async deleteOpportunityRelatedToFeedbackId(feedbackId: string, opportunityId: string) {
    return await RequestHandler.del<void>(`/feed/${feedbackId}/opportunity/${opportunityId}`)
  }
}
