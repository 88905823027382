import useDatetimeAdvancedFilter from '@/hooks/advancedFilters/useDatetimeAdvancedFilter'
import { FilterDatetimeValue } from '@/types/filters/Filters'
import { dateShortMonthFormat, stringToDate } from '@/utils/date'
import { parseDate } from '@internationalized/date'
import { FilterItemDropdown } from '../../AppliedFilters/FilterItem'
import RangeCalendar from '@/components/atoms/range-calendar'
import { DateFilterType } from '@/types/filters/AdvancedFilters'

interface Props {
  title: string
  name: string
  path: string
  value: { $gte: string; $lt: string }
  index: number
  type: DateFilterType
  disabled?: boolean
}

const DatetimeFilterApplied = ({
  title,
  name,
  path,
  value,
  index,
  type,
  disabled = false
}: Props) => {
  const parsedStart = parseDate(value.$gte.slice(0, 10))
  const parsedEnd = parseDate(value.$lt.slice(0, 10))

  const initialValue: FilterDatetimeValue = {
    start: parsedStart,
    end: parsedEnd
  }

  const { update, dateRange, isLoading, maxDate, minDate, setDateRange } =
    useDatetimeAdvancedFilter({
      name,
      path,
      enableQuery: true,
      initialValue,
      index,
      type
    })

  const startDate = stringToDate(value.$gte)
  const endDate = stringToDate(value.$lt)
  if (!startDate || !endDate) return null

  const shortText = `${dateShortMonthFormat(startDate)} - ${dateShortMonthFormat(endDate)}`
  const longText = `<b>${dateShortMonthFormat(startDate)}</b> - <b>${dateShortMonthFormat(
    endDate
  )}</b>`

  return (
    <FilterItemDropdown
      buttonDisabled={disabled}
      content={shortText}
      contentType="label"
      disabled={isLoading || disabled}
      dropdown={
        <RangeCalendar
          maxValue={maxDate}
          minValue={minDate}
          onChange={setDateRange}
          value={dateRange}
        />
      }
      onApply={update}
      title={title}
      tooltip={longText}
    />
  )
}

export default DatetimeFilterApplied
