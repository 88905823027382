import { CSS, styled } from '@/theme'
import { entityMap } from '@/utils/filterEntityUtils'
import { IconContext } from '@phosphor-icons/react'
import { ReactNode } from 'react'

const Badge = styled('div', {
  borderRadius: 8,
  height: 20,
  padding: '$nano $micro',
  color: '$neutralHighLight',
  fontSize: '$micro',
  fontWeight: '$semibold',
  textTransform: 'capitalize',
  width: 'fit-content',
  backgroundColor: '$brandPrimaryPure',
  display: 'flex',
  alignItems: 'center',
  gap: '$nano',
  boxSizing: 'border-box'
})

interface Props {
  icon?: ReactNode
  name: string
  css?: CSS
}

const Generic = ({ icon, name, css }: Props) => (
  <Badge css={css}>
    <IconContext.Provider value={{ size: 16 }}>{icon}</IconContext.Provider>
    <span>{name}</span>
  </Badge>
)

const Area = ({ css }: { css?: CSS }) => (
  <Generic name={entityMap.area.name} icon={entityMap.area.icon} css={css} />
)

const Segment = ({ css }: { css?: CSS }) => (
  <Generic name={entityMap.segment.name} icon={entityMap.segment.icon} css={css} />
)

const Opportunity = ({ css }: { css?: CSS }) => (
  <Generic name={entityMap.opportunity.name} icon={entityMap.opportunity.icon} css={css} />
)

const EntityBadge = {
  Generic,
  Area,
  Segment,
  Opportunity
}

export default EntityBadge
