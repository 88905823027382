import type { ParamsBase } from '..'
import type { ExtractionTask, IntegrationConnector, IntegrationSource } from './Integrations'

export interface ListApiKeyResponse {
  api_key: string
  created_at: string
  description: string
  expiry: number
  id: string
  organization: { id: string }
  service_name: string
  updated_at: string
}

export interface ListConnectorParams extends ParamsBase {
  name?: string
  locale?: string
  connector_type?: string
}

export type ListConnectorsResponse = IntegrationConnector[]

export type GetConnectorResponse = IntegrationConnector

export type GetConnectorSchemaResponse = Record<string, string>

export interface CreateExtractionPayload {
  organization_id: string
  extractions: Array<{
    name: string
    connector_id: string
    schedule: string
    arguments: Record<string, string | string[]>
    fields_config?: string[]
    core_fields?: Record<string, string | null>
  }>
}

interface TaskId {
  extraction_id: string
  task_id: string
}

export interface CreateExtractionResponse {
  task_ids: TaskId[]
  extractions_created: Array<{
    arguments: { [x: string]: string }
    connector: { id: string }
    custom_schema: unknown | null
    data_type: unknown | null
    deleted_at: unknown | null
    enabled: boolean
    feedback_source_id: string
    id: string
    name: string
    organization: { id: string }
    prepconfigs: unknown[]
    schedule: { id: string }
    schema_type: unknown | null
    tasks: unknown[]
  }>
  extractions_not_created: unknown[]
}

export interface UpdateExtractionPayload {
  id: string
  name: string
  arguments: Record<string, string | string[]>
  enabled?: boolean
  schedule: string
  fields_config: string[]
  core_fields?: Record<string, string | null>
}

export type UpdateIntegrationResponse = IntegrationSource

export type ListIntegrationsResponse = IntegrationSource[]

export type GetIntegrationResponse = IntegrationSource

export interface DeleteIntegrationResponse {
  message: string
}

export interface TestIntegrationPayload {
  organization_id: string
  connector_id: string
  arguments: Record<string, string | string[]>
  extraction_id?: string
}

export type TestConnectionSuccessResponse = Record<string, unknown>

interface TestConnectionErrorResponse {
  error_message: string
}

export type TestIntegrationResponse = TestConnectionSuccessResponse | TestConnectionErrorResponse

export function isTestConnectionError(response: unknown): response is TestConnectionErrorResponse {
  return (response as TestConnectionErrorResponse).error_message !== undefined
}

export interface RefreshIntegrationResponse {
  task_ids: TaskId[]
}

export type ListTasksResponse = ExtractionTask[]

export type CancelTaskResponse = ExtractionTask
