import { useState } from 'react'
import { CheckCircle } from '@phosphor-icons/react'
import { Navigate, useParams } from 'react-router-dom'

import zapierLogo from '@/assets/logos/zapier-logo.png'
import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import useSegment from '@/hooks/useSegment'
import useIntegrationsStore from '@/store/useIntegrationsStore'
import { formatConnectorName, resizeLogoUrl } from '../utils'
import {
  ConnectorContainer,
  Container,
  Description,
  InterestMessage,
  Title
} from './IntegrationPlaceholder.styles'
import { useTranslation } from 'react-i18next'

import useUser from '@/hooks/useUser'

function IntegrationPlaceholder() {
  const params = useParams()

  const { t } = useTranslation()
  const [interested, setInterested] = useState(false)

  const connectors = useIntegrationsStore(state => state.connectors.data)

  const { track } = useSegment()

  const connector = connectors.find(
    connector => connector.id === params.connectorId && connector.metadata.placeholder === true
  )

  function trackInterest() {
    track('sources_user_connector_notify', {
      connector_id: connector?.id,
      connector_name: connector?.name,
      connector_type: connector?.metadata.data_type
    })
    setInterested(true)
  }

  const { userPermissions } = useUser()
  const isManager = userPermissions.source.includes('manager')

  if (!connector) return <Navigate replace to="/settings/integrations" />

  return (
    <Container>
      <ConnectorContainer>
        <img alt="" height={40} src={resizeLogoUrl(connector.logo_url, 40)} />
        <Title>{formatConnectorName(connector.name)}</Title>
        {connector.description ? (
          <Description data-testid="integration-placeholder-description">
            {connector.description}
          </Description>
        ) : null}

        <Text as="b" typeface="paragraphRegularMicro">
          {t('ThisIntegrationIsStillUnavailableAtBirdie')}
          <br />
          {!interested ? t('WantToBeNotifiedWhenItsReadyToUse') : <br />}
        </Text>
        {!interested ? (
          <Button onClick={trackInterest} type="button">
            {t('NotifyMe')}
          </Button>
        ) : (
          <InterestMessage>
            <CheckCircle size={20} weight="fill" />
            {t('YouWillBeNotifiedWhenItsReady')}
          </InterestMessage>
        )}
      </ConnectorContainer>
      {isManager && (
        <FlexContainer css={{ gap: 8 }} direction="column">
          <FlexContainer alignItems="center" justifyContent="spaceBetween">
            <div>
              <Text as="p" typeface="titleBoldXXS">
                {t('OrQuickImportWithZapier')}
              </Text>
              <Text as="p" typeface="paragraphRegularMicro">
                {t('inTheMeanwhileYouCanTryConnectingWithThisTemplate')}
              </Text>
            </div>
            <img alt="" height={22} src={zapierLogo} width={80} />
          </FlexContainer>

          <zapier-zap-templates
            apps={`birdie,${connector.name}`}
            create-without-template="hide"
            limit={1}
            theme="light"
            use-this-zap="show"
          />
        </FlexContainer>
      )}
    </Container>
  )
}

export default IntegrationPlaceholder
