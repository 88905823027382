import { NumberBadge } from '@/components/atoms/badge'
import Bone from '@/components/atoms/bone'
import FlexContainer from '@/components/atoms/flex-container'
import { CSS, styled } from '@/theme'
import { slideDownWithOpacity, slideUpWithOpacity } from '@/theme/animations'
import { ArrowDown, ArrowUp, CaretDown } from '@phosphor-icons/react'
import * as Accordion from '@radix-ui/react-accordion'
import ExplorationNumberVariation from '../../exploration/ExplorationNumberVariation'

const Root = styled(FlexContainer, {
  flexDirection: 'column',
  color: '$neutralLowPure',
  mt: '$xxs',
  background: '$neutralHighLight',
  borderRadius: 16,
  border: '1px solid $brandPrimaryPure'
})

const rowCSS: CSS = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '$xxxs $xs',
  bBottom: '$neutralHighPure',
  fontSize: '$micro',

  '&:last-of-type': {
    bBottom: 'transparent'
  },

  '&:hover': {
    background: '$neutralHighMedium50'
  }
}

const Row = styled('div', {
  ...rowCSS,
  variants: {
    open: {
      true: {
        bc: '$neutralHighPure',
        '&:hover': {
          bc: '$neutralHighPure'
        }
      },
      false: {}
    },

    nested: {
      true: {
        '&:hover': {
          bc: '$neutralHighMedium'
        }
      },
      false: {}
    },

    hasNewOpportunities: {
      true: {
        bc: '$feedbackWarningLight',
        '&:hover': {
          bc: '$feedbackWarningMedium'
        }
      }
    }
  },

  compoundVariants: [
    {
      open: true,
      hasNewOpportunities: true,
      css: {
        bc: '$neutralHighPure',
        '&:hover': {
          bc: '$neutralHighPure'
        }
      }
    }
  ],

  defaultVariants: {
    open: false,
    nested: false
  }
})

const Header = styled(Row, {
  fontWeight: '$bold',
  lineHeight: '$xl',
  fontSize: '$nano',
  color: '$neutralLowLight'
})

const CellContainer = styled(FlexContainer, {
  // gap: '$nano',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 122,
  maxWidth: 122,

  variants: {
    sortEnabled: {
      true: {
        cursor: 'pointer',
        '& .area-sort-indicator': {
          minWidth: 18
        },
        '& .area-sort-indicator svg': {
          opacity: 0
        },

        '&:hover': {
          color: '$brandPrimaryPure',
          '& .area-sort-indicator': {
            backgroundColor: '$brandPrimaryLight',
            br: 4,
            '& svg': {
              opacity: 1
            }
          }
        }
      }
    },
    active: {
      true: {
        '& .area-sort-indicator svg': {
          opacity: 1
        }
      },
      false: {
        '& .area-sort-indicator': {
          minWidth: 0,
          maxWidth: 0
        },
        '& .area-sort-indicator svg': {
          opacity: 0
        }
      }
    }
  }
})

const MediumCellContainer = styled(CellContainer, {
  minWidth: 138,
  maxWidth: 138
})

const LongCellContainer = styled(CellContainer, {
  justifyContent: 'flex-start',
  minWidth: 264,
  maxWidth: 264
})

const ShortCellContainer = styled(CellContainer, {
  minWidth: 40,
  maxWidth: 40
})

const NameCell = styled('span', {
  fontWeight: '$regular',
  fontSize: '$micro',
  textAlign: 'left'
})

const NestedNameCell = styled(NameCell, {
  pl: '$xs',

  variants: {
    isNew: {
      true: {
        pl: '$nano'
      }
    }
  }
})

const Cell = styled('span', {
  textAlign: 'center'
})

const Button = styled('button', {
  all: 'unset',
  display: 'flex',
  cursor: 'pointer'
})

const FlexCell = styled(FlexContainer, {
  width: '100%',
  gap: '$nano',
  justifyContent: 'center',
  alignItems: 'center'
})

const NumberHighlight = styled('span', {
  fontSize: '$micro',
  color: '$neutralLowMedium'
})

const NumberMuted = styled('span', {
  fontSize: '$micro',
  color: '$neutralLowLight'
})

const Badge = styled(NumberBadge, {
  '& p': {
    width: 'max-content'
  },

  '.highlight': {
    fontSize: '$xxxs',
    color: '$neutralLowMedium'
  },

  '.absolute-value': {
    color: '$neutralLowLight'
  }
})

const AccordionTrigger = styled(Accordion.Trigger, {
  all: 'unset',
  cursor: 'pointer'
})

const AccordionCaret = styled(CaretDown, {
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  '[data-state=open] &': { transform: 'rotate(180deg)' },

  variants: {
    open: {
      true: {
        transform: 'rotate(180deg)'
      }
    }
  }
})

const AccordionContent = styled(Accordion.Content, {
  background: '$neutralHighMedium50',
  a: {
    color: '$neutralLowPure',
    textDecoration: 'none'
  },
  '&[data-state="open"]': {
    animation: `${slideDownWithOpacity} 300ms cubic-bezier(0.87, 0, 0.13, 1)`
  },
  '&[data-state="closed"]': {
    animation: `${slideUpWithOpacity} 300ms cubic-bezier(0.87, 0, 0.13, 1)`
  }
})

const StyledBone = ({ width, css }: { width: number; css?: CSS }) => (
  <Bone css={{ height: 24, width, ...css }} />
)

const SortIndicator = ({ enabled, order }: { enabled: boolean; order: 'desc' | 'asc' }) => {
  if (!enabled) return <></>

  return (
    <FlexContainer alignItems="center" className="area-sort-indicator" justifyContent="center">
      {order === 'desc' ? <ArrowUp size={16} /> : <ArrowDown size={16} />}
    </FlexContainer>
  )
}

const AreaOfInterestList = {
  Root,
  Row,
  Header,
  CellContainer,
  LongCellContainer,
  ShortCellContainer,
  MediumCellContainer,
  NameCell,
  NestedNameCell,
  Cell,
  FlexCell,
  NumberHighlight,
  NumberMuted,
  Badge,
  Variation: ExplorationNumberVariation,
  Button,
  AccordionTrigger,
  AccordionCaret,
  AccordionContent,
  Bone: StyledBone,
  SortIndicator
}

export default AreaOfInterestList
