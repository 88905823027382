import { create } from 'zustand'

interface ReasonSummaryState {
  summaries: Record<string, string | undefined>
  setSummary: (key: string, summary: string | undefined) => void
}

const useReasonSummaryState = create<ReasonSummaryState>(set => ({
  summaries: {},
  setSummary: (key, summary) =>
    set(state => ({ summaries: { ...state.summaries, [key]: summary } }))
}))

export default useReasonSummaryState
