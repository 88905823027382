import { API_URL } from '@/config'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import * as Sentry from '@sentry/react'

export enum CustomHeader {
  BirdieToken = 'x-birdie-token',
  BirdieHash = 'x-birdie-hash'
}

let client = axios.create({
  baseURL: API_URL
})

function updateClient() {
  client = axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`
    }
  })
}

export default class Request {
  static async get<T = unknown>(
    path: string,
    params?: unknown,
    config?: AxiosRequestConfig<unknown>
  ) {
    try {
      const response = await client.get<T>(path, {
        params,
        ...config
      })

      return response.data
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)

      const key = (e as AxiosError<{ key: string }>).response?.data?.key
      const code = (e as AxiosError<unknown>).code

      if (key) {
        throw new Error(key)
      }
      if (code === 'ERR_CANCELED') {
        throw new Error('Request was canceled')
      }
      throw new Error('Unable to get')
    }
  }

  static async post<T = unknown>(
    path: string,
    payload?: unknown,
    config?: AxiosRequestConfig<unknown>
  ) {
    try {
      const response = await client.post<T>(path, payload, config)

      return response.data
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)

      const key = (e as AxiosError<{ key: string }>).response?.data?.key
      const code = (e as AxiosError<unknown>).code

      if (key) {
        throw new Error(key)
      }
      if (code === 'ERR_CANCELED') {
        throw new Error('Request was canceled')
      }
      throw new Error('Unable to post')
    }
  }

  static async put<T = unknown>(path: string, payload?: unknown) {
    try {
      const response = await client.put<T>(path, payload)

      return response.data
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      throw e
    }
  }

  static async del<T = unknown>(path: string, params?: unknown, data?: unknown) {
    try {
      const response = await client.delete<T>(path, { params, data })

      return response.data
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      throw e
    }
  }

  static async patch<T = unknown>(
    path: string,
    payload?: unknown,
    config?: AxiosRequestConfig<unknown>
  ) {
    try {
      const response = await client.patch<T>(path, payload, config)

      return response.data
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      throw e
    }
  }
}

export { updateClient }
