import { AnnotationRequests } from '@/types/annotations'
import RequestHandler, { RequestReturnType } from './RequestHandlers/NewRequest'
import { TimeSeriesAnnotation } from '@/types/time-series/TimeSeries'
import moment from 'moment'
import { AnnotationType } from '@/types/annotations/Annotation'

export default class AnnotationsService {
  static PREFIX = '/annotations'

  static async search(
    params: AnnotationRequests.SearchAnnotationsParams,
    options?: { signal?: AbortSignal }
  ): Promise<RequestReturnType<TimeSeriesAnnotation[]>> {
    const [error, data] = await RequestHandler.get<AnnotationRequests.SearchAnnotationsResponse>(
      this.PREFIX + '/v2',
      params,
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )

    if (error) return [error, undefined]
    return [
      undefined,
      data.map(
        (annotation): TimeSeriesAnnotation => ({
          annotationId: annotation.annotation_id,
          organizationId: annotation.organization_id,
          name: annotation.name,
          description: annotation.description,
          timestamp: moment.unix(annotation.timestamp_normalize).utc(),
          annotationDate: moment(annotation.annotation_date).utc(),
          parentId: annotation.parent_id,
          type: annotation.type,
          createdAt: moment(annotation.created_at).utc()
        })
      )
    ]
  }

  static async create(
    payload: AnnotationRequests.CreateAnnotationPayload,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<string>> {
    const [error, data] = await RequestHandler.post<AnnotationRequests.CreateAnnotationResponse>(
      this.PREFIX,
      payload,
      {
        signal: options?.signal
      }
    )

    if (error) return [error, undefined]
    return [undefined, data.annotation_id]
  }

  static async update(
    newAnnotation: TimeSeriesAnnotation,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<AnnotationRequests.UpdateAnnotationResponse>> {
    const payload: AnnotationRequests.UpdateAnnotationPayload = {
      name: newAnnotation.name,
      description: newAnnotation.description,
      annotation_date: newAnnotation.annotationDate.toISOString().slice(0, 10),
      parent_id: newAnnotation.parentId,
      type: newAnnotation.type as AnnotationType
    }
    return await RequestHandler.put<AnnotationRequests.UpdateAnnotationResponse>(
      `${this.PREFIX}/${newAnnotation.annotationId}`,
      payload,
      {
        signal: options?.signal
      }
    )
  }

  static async delete(
    annotationId: string,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<void>> {
    const [error] = await RequestHandler.del<void>(`${this.PREFIX}/${annotationId}`, {
      signal: options?.signal
    })
    if (error) return [error, undefined]
    return [undefined, undefined]
  }
}
