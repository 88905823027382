import { useTranslation } from 'react-i18next'
import FlexContainer from '@/components/atoms/flex-container'
import Input from '@/components/atoms/input'
import Button from '@/components/atoms/button'
import { getLocalTimeZone, today } from '@internationalized/date'
import DatePicker from '@/components/atoms/date-picker'
import type { DateValue } from '@react-types/calendar'
import { Plus, TrashSimple } from '@phosphor-icons/react'
import { ActiveUsersByDateConfigItem } from '@/types/organization/Organization'

interface Props {
  item: ActiveUsersByDateConfigItem
  showDeleteButton: boolean
  canAddNewEntry: boolean
  isDateUnavailable: (date: DateValue) => boolean
  onAddEntry?: () => void
  onChangeItemDate: (currentKey: string | null, newDate: DateValue) => void
  onChangeItemValue: (key: string | null, value: string) => void
  onDeleteEntry: (key: string | null) => void
}

const ActiveUserRow = ({
  item,
  showDeleteButton,
  canAddNewEntry,
  isDateUnavailable,
  onAddEntry,
  onChangeItemDate,
  onChangeItemValue,
  onDeleteEntry
}: Props) => {
  const { t } = useTranslation()
  return (
    <FlexContainer gap="xxs" fullWidth>
      <DatePicker
        onChange={value => onChangeItemDate(item.key, value)}
        value={item.date}
        maxValue={today(getLocalTimeZone())}
        triggerCss={{ width: '100%' }}
        isDateUnavailable={isDateUnavailable}
      />

      <FlexContainer gap="xxs" fullWidth alignItems="center" css={{ py: 1, pr: 1 }}>
        <Input
          value={item.value}
          min={1}
          onChange={e => onChangeItemValue(item.key, e.currentTarget.value)}
          type="number"
          required
          error={item.key !== null && !item.value}
          placeholder={t('value')}
        />

        {!showDeleteButton && !!onAddEntry && (
          <Button
            small
            css={{
              padding: '$nano',
              '&:not(:hover,:disabled)': {
                color: '$neutralLowPure'
              },
              width: 32
            }}
            size={'small'}
            variant="flat"
            disabled={!canAddNewEntry}
            onClick={() => onAddEntry()}
          >
            <Plus size={16} />
          </Button>
        )}

        {showDeleteButton && (
          <Button
            small
            css={{ padding: '$nano', width: 32 }}
            size={'small'}
            variant="link"
            deleteButton
            onClick={() => onDeleteEntry(item.key)}
          >
            <TrashSimple size={16} />
          </Button>
        )}
      </FlexContainer>
    </FlexContainer>
  )
}

export default ActiveUserRow
