import useSentimentFilter from '@/hooks/advancedFilters/useSentimentFilter'
import FilterSubMenu from '../../FiltersSidebar/AddFilterMenu/FilterSubMenu'
import { SmileyWink } from '@phosphor-icons/react/dist/ssr'
import { useTranslation } from 'react-i18next'
import SentimentFilterSelection from './SentimentFilterSelection'

const SentimentSubMenu = () => {
  const { t } = useTranslation()
  const { apply, isDisabled, selectedOption, setSelectedOption } = useSentimentFilter()
  return (
    <FilterSubMenu compact hideButton icon={<SmileyWink />} title={t('sentiment')}>
      <SentimentFilterSelection
        onApply={apply}
        isDisabled={isDisabled}
        value={selectedOption}
        onValueChange={setSelectedOption}
      />
    </FilterSubMenu>
  )
}

export default SentimentSubMenu
