import Popover from '@/components/atoms/popover'
import { memo, ReactNode, useState } from 'react'
import * as S from './KeywordTopicPopover.styles'
import { FeedbackTopicChipType } from './FeedbackItem.types'
import FlexContainer from '@/components/atoms/flex-container'
import IntentionChip from '@/components/atoms/chip/IntentionChip'
import Text from '@/components/atoms/text'
import ChangeSentimentMenu from './ChangeSentimentMenu'
import ChangeIntention from './ChangeIntention'
import { X } from '@phosphor-icons/react'
import useToastMessageStore from '@/store/useToastMessageStore'
import KeywordService from '@/services/KeywordService'
import { useFeedbacks } from '@/hooks/feedback/useFeedbacks'
import useSegment from '@/hooks/useSegment'
import useUser from '@/hooks/useUser'
import useLogging from '@/hooks/useLogging'
import { getSentimentName } from '@/utils/sentiments'

interface Props {
  children: ReactNode
  topic: FeedbackTopicChipType
  feedbackId: string
  feedbackSourceId: string
  customButton?: boolean
  defaultOpen?: boolean
}

const KeywordTopicPopover = ({ children, topic, defaultOpen, ...props }: Props) => {
  const { track } = useSegment()

  const { userPermissions } = useUser()
  const isManager = userPermissions.topic.includes('manager')

  const [open, setOpen] = useState(defaultOpen)
  const onOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      track('feedback_user_topic_detail')
    }
    setOpen(isOpen)
  }

  const { removeFeedbackKeywordAllFeedbackStores } = useFeedbacks()

  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const { logException } = useLogging({ context: 'keyword-topic-popover' })

  const onRemoveKeywordFeedback = async () => {
    const removeToast = addLoadingToast({
      text: 'Removing...'
    })

    try {
      const { keywordHash } = topic
      await KeywordService.removeFeedbackKeyword({
        relations: [{ ingested_feedback_id: props.feedbackId, keyword_hash: keywordHash }]
      })

      removeFeedbackKeywordAllFeedbackStores(props.feedbackId, keywordHash)

      removeToast()
      addSuccessToast({
        text: 'Keyword removed from feedback successfully.'
      })
    } catch (error) {
      removeToast()
      const message = 'Failed to remove keyword.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  }

  return (
    <Popover
      active={false}
      buttonChildren={children}
      defaultOpen={defaultOpen}
      iconButtonCss={{ padding: 0, height: 'auto' }}
      modal
      onOpenChange={onOpenChange}
      open={open}
      text
      {...props}
    >
      <S.Header title={topic.name}>{topic.name}</S.Header>
      <S.Container>
        <S.ChangePopoverItem gap="xxs">
          {topic.intentions.length > 0 && (
            <FlexContainer direction="column" gap="nano">
              <Text color="neutralLowPure" fontSize="micro" fontWeight="regular" lineHeight="xxl">
                Intention:
              </Text>
              <ChangeIntention disabled={!isManager} topic={topic} {...props}>
                <div>
                  <IntentionChip
                    css={{
                      fontWeight: '$semibold',
                      cursor: 'pointer'
                    }}
                    disabled={!isManager}
                    intention={topic.intentions[0]}
                  />
                </div>
              </ChangeIntention>
            </FlexContainer>
          )}

          <FlexContainer direction="column" gap="nano">
            <Text color="neutralLowPure" fontSize="micro" fontWeight="regular" lineHeight="xxl">
              Sentiment:
            </Text>
            <ChangeSentimentMenu disabled={!isManager} topic={topic} {...props}>
              <S.SentimentChip disabled={!isManager} sentiment={getSentimentName(topic.sentiment)}>
                {getSentimentName(topic.sentiment)}
              </S.SentimentChip>
            </ChangeSentimentMenu>
          </FlexContainer>
        </S.ChangePopoverItem>

        <S.ChangePopoverItem direction="column">
          <Text color="neutralLowLight" fontSize="micro" fontWeight="regular" lineHeight="xl">
            Keyword:
          </Text>

          <Text
            color="neutralLowPure"
            fontSize="xxxs"
            fontWeight="regular"
            lineHeight="xl"
            truncate
          >
            {topic.keywordText}
          </Text>
        </S.ChangePopoverItem>

        {isManager ? (
          <>
            <S.SentimentItemSeparator />
            <S.ChangePopoverItem
              alignItems="center"
              as="button"
              cliclable
              gap="micro"
              onClick={onRemoveKeywordFeedback}
            >
              <X size={20} />
              <Text color="neutralLowPure" fontSize="micro" fontWeight="regular" lineHeight="xl">
                Remove from this feedback
              </Text>
            </S.ChangePopoverItem>
          </>
        ) : null}
      </S.Container>
    </Popover>
  )
}

export default memo(KeywordTopicPopover)
