import { ReactComponent as BirdieLogo } from '@/assets/birdie_logo.svg'
import BirdsSignupDrawing from '@/assets/drawings/birds_signup.png'
import ToastMessagesProvider from '@/components/molecules/ToastMessagesProvider'
import Redirect from '@/components/molecules/redirect'
import useUser from '@/hooks/useUser'
import useSignupStore from '@/store/useSignupStore'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { shallow } from 'zustand/shallow'
import { DrawingImage, SignupContainer } from './Signup.styles'
import OnboardingFeedbackType from './OnboardingFeedbackType'
import OnboardingUseCase from './OnboardingUseCase'
import OnboardingUser from './OnboardingUser'
import { FullScreenLoader } from '@/components/atoms/loader'
import { StepsIndicator, StepContent, StepGroup } from '@/components/atoms/step'
import useTransition from 'react-transition-state'

const transitionConfig = {
  timeout: 500,
  mountOnEnter: true,
  unmountOnExit: true,
  preEnter: true
}

const SignupPage = () => {
  const [isChecking, setIsChecking] = useState(true)
  const navigate = useNavigate()
  const { checkFirstAccess } = useUser()

  const onboardingStep = useSignupStore(state => state.onboardingStep, shallow)
  const [userState, userToggle] = useTransition(transitionConfig)
  const [caseState, caseToggle] = useTransition(transitionConfig)
  const [feedbackTypeState, feedbackTypeToggle] = useTransition(transitionConfig)

  // biome-ignore lint/correctness/useExhaustiveDependencies: shoudl happen only on onboardingStep change
  useEffect(() => {
    if (onboardingStep === 0) {
      userToggle(true)
      caseToggle(false)
      feedbackTypeToggle(false)
    } else if (onboardingStep === 1) {
      userToggle(false)
      caseToggle(true)
      feedbackTypeToggle(false)
    } else {
      userToggle(false)
      caseToggle(false)
      feedbackTypeToggle(true)
    }
  }, [onboardingStep])

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen once
  useEffect(() => {
    const checkForLoggedUser = async () => {
      setIsChecking(true)
      const firstAccess = await checkFirstAccess()

      setIsChecking(false)
      if (!firstAccess) {
        navigate('/')
      }
    }

    checkForLoggedUser()
  }, [])

  if (isChecking) return <Redirect />

  return (
    <SignupContainer>
      <BirdieLogo className="logo" />
      <DrawingImage
        alt="A drawing of a red bird in front of a form on a clipboard"
        src={BirdsSignupDrawing}
      />
      <StepsIndicator
        css={{ marginTop: 76, marginBottom: '$xs' }}
        maxSteps={3}
        step={onboardingStep}
      />
      <StepGroup>
        <StepContent isMounted={userState.isMounted} status={userState.status}>
          <OnboardingUser />
        </StepContent>
        <StepContent isMounted={caseState.isMounted} status={caseState.status}>
          <OnboardingUseCase />
        </StepContent>
        <StepContent isMounted={feedbackTypeState.isMounted} status={feedbackTypeState.status}>
          <OnboardingFeedbackType />
        </StepContent>
      </StepGroup>
      <ToastMessagesProvider />
      <FullScreenLoader />
    </SignupContainer>
  )
}

export default SignupPage
