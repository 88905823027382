import { useMemo, useState } from 'react'
import { shallow } from 'zustand/shallow'
import FilterSubMenu from '../AddFilterMenu/FilterSubMenu'
import TextFilterContent from './TextFilterContent'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { isAreaOfInterestFilter } from '@/utils/areaOfInterest'

interface Props {
  previousTitle: string
  title: string
  name: string
  filterKey: string
}

const TextFilterSubMenuNew = ({ previousTitle, title, name, filterKey }: Props) => {
  const [open, setOpen] = useState(false)

  const [textInput, setTextInput] = useState('')

  const textFilters = useFeedFiltersStore(state => state.textFilters, shallow)
  const setTextFilter = useFeedFiltersStore(state => state.setTextFilter)
  const removeTextFilter = useFeedFiltersStore(state => state.removeTextFilter)

  const areaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)

  const storedFilter = useMemo(
    () => textFilters.find(filter => filter.key === filterKey),
    [textFilters, filterKey]
  )

  const isDisabled = textInput === storedFilter?.value || textInput.length === 0

  const onApply = () => {
    setTextFilter({
      key: filterKey,
      name,
      value: textInput
    })
  }

  const onClear = () => {
    setTextInput('')
    removeTextFilter(filterKey)
  }

  if (
    storedFilter &&
    isAreaOfInterestFilter({
      key: storedFilter.key,
      values: storedFilter.value,
      areaOfInterest
    })
  )
    return <></>

  return (
    <FilterSubMenu
      isDisabled={isDisabled}
      isLoading={false}
      onApply={onApply}
      onOpenChange={setOpen}
      open={open}
      previousTitle={previousTitle}
      title={title}
    >
      <TextFilterContent onClearClick={onClear} onValueChange={setTextInput} value={textInput} />
    </FilterSubMenu>
  )
}

export default TextFilterSubMenuNew
