import OptionsMenu, { OptionsMenuItem } from '@/components/atoms/options-menu'
import { SegmentationItemWithMetrics } from '@/types/segmentation/Segmentation'
import { PencilSimple, TrashSimple } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'

interface Props {
  data: SegmentationItemWithMetrics
  onDeleteClick: (segmentation: SegmentationItemWithMetrics) => void
  onRenameClick: (segmentation: SegmentationItemWithMetrics) => void
}

const SegmentationOptions = ({ data, onDeleteClick, onRenameClick }: Props) => {
  const { t } = useTranslation()

  const menuOptions: OptionsMenuItem[] = [
    {
      text: t('rename'),
      icon: <PencilSimple />,
      onClick: () => onRenameClick(data)
    },
    {
      text: t('delete'),
      icon: <TrashSimple />,
      onClick: () => onDeleteClick(data)
    }
  ]

  return <OptionsMenu options={menuOptions} buttonStyle={{ mx: 'auto' }} />
}

export default SegmentationOptions
