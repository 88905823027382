import { styled, typefaceVariants } from '@/theme'

export const ChipContainer = styled('div', {
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  ...typefaceVariants.paragraphRegularMicro,
  lineHeight: '$xs',
  gap: '$micro',
  px: '$xxxs',
  py: '$nano',
  // background: '$neutralHighMedium',
  br: '$md',

  cursor: 'default',
  userSelect: 'none',
  border: '1px solid $stroke',

  '& > span.label': {
    lineHeight: '$xs'
  },

  variants: {
    negative: {
      true: {
        color: '$white'
      },
      false: {
        color: '$neutralLowMedium'
      }
    },

    dashed: {
      true: {
        border: 'dashed',
        borderColor: '$brandPrimaryPure',
        color: '$brandPrimaryPure',
        py: '$nano',
        boxSizing: 'border-box',
        borderWidth: 1,
        background: 'transparent !important',
        '&:hover': {
          background: '$brandPrimaryLight !important'
        }
      },
      false: {
        border: 'none'
      }
    },

    border: {
      dashed: {
        border: 'dashed',
        borderColor: '$grey',
        borderWidth: 1
      },
      solid: {
        border: '1px solid $stroke'
      },
      none: {
        border: 'none'
      }
    },

    disabled: {
      true: {
        pointerEvents: 'none'
      }
    },
    wrapText: {
      true: {
        maxWidth: 280,
        '& > span.label': {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }
      }
    }
  }
})

export const ChipIconButton = styled('button', {
  all: 'unset',
  size: 12,
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$primaryColorHonoluluBlue',
  cursor: 'pointer',

  '&:hover': { backgroundColor: '$transparentHover' },
  '&:disabled': {
    color: '$semanticColorsNeutral',
    pointerEvents: 'none'
  },

  variants: {
    negative: {
      true: {
        color: '$neutralsNegativeText',
        '&:hover': { backgroundColor: '#FFFFFF30' }
      },
      false: {
        color: '$primaryColorHonoluluBlue'
      }
    }
  }
})
