import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'

const Card = styled(FlexContainer, {
  bc: '$neutralHighLight',
  bAll: '$neutralHighPure',
  br: '$micro',
  padding: '$xs $sm',

  flexDirection: 'column'
})

const CardHeader = styled('p', {
  fontWeight: '$bold',
  fontSize: '$xxs',
  mb: '$md'
})

const Row = styled(FlexContainer, {
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '$xl',
  width: '100%'
})

const ColText = styled('p', {
  fontWeight: '$regular',
  fontSize: '$xxs',
  lineHeight: '24px',
  color: '$neutralLowPure',
  minWidth: 400,

  variants: {
    description: {
      true: {
        color: '$neutralLowLight',
        fontSize: '$xxxs'
      }
    }
  }
})

const OrgIdContainer = styled(FlexContainer, {
  alignItems: 'center',
  bAll: '$neutralHighPure',
  br: '$micro',
  height: 40,
  justifyContent: 'space-between',
  padding: '$xxxs $xxs',
  width: '100%'
})

const OrganizationStyles = {
  Card,
  CardHeader,
  Row,
  ColText,
  OrgIdContainer
}

export default OrganizationStyles
