import * as Tooltip from '@radix-ui/react-tooltip'
import { styled } from '@/theme'
import { slideAndFadeOpen } from '@/theme/animations'

export const StyledTooltipContent = styled(Tooltip.Content, {
  borderRadius: 8,
  color: '$neutralHighLight',
  background: '$neutralLowPure',
  userSelect: 'none',
  zIndex: 9999,
  ...slideAndFadeOpen,

  a: {
    color: '$neutralHighLight',
    '&:visited': {
      color: '$neutralHighLight'
    },
    '&:hover': {
      color: '$neutralHighLight'
    }
  },

  variants: {
    variant: {
      default: {
        fontSize: '$xxxs',
        lineHeight: '$xl',
        padding: '$xxxs $xxs'
      },
      small: {
        fontSize: '$micro',
        lineHeight: '$xl',
        maxWidth: 256,
        padding: '$micro $xxs'
      }
    }
  },

  defaultVariants: {
    variant: 'default'
  }
})

export const StyledTooltipArrow = styled(Tooltip.Arrow, { fill: '$dark' })
