import { ReactNode, useEffect, useMemo } from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import { CSS } from '@/theme'
import { PopoverIconButton, PopoverContent } from './Popover.styles'
import { IconContext } from '@phosphor-icons/react'
import { BadgeDot } from '../badge'
import useHideIntercom from '@/hooks/useHideIntercom'
import { space } from '@/theme/space'
import Text from '../text'

export interface PopoverProps extends PopoverPrimitive.PopoverProps {
  buttonChildren?: ReactNode
  customButton?: boolean
  title?: string
  buttonId?: string
  disabled?: boolean
  showBadge?: boolean
  active?: boolean
  bordered?: boolean
  text?: boolean
  iconButtonCss?: CSS
  extendedContent?: ReactNode
  contentProps?: PopoverPrimitive.PopoverContentProps & {
    css?: CSS
  }
  badgeColor?: string
}

const Popover = ({
  open,
  children,
  buttonChildren,
  customButton = false,
  title,
  buttonId,
  disabled = false,
  active,
  bordered,
  text,
  iconButtonCss,
  showBadge,
  extendedContent,
  contentProps,
  badgeColor,
  ...props
}: PopoverProps) => {
  const handleIntercom = useHideIntercom()

  useEffect(() => {
    handleIntercom(open)

    return () => handleIntercom(false)
  }, [open, handleIntercom])

  const details = useMemo(
    () =>
      typeof extendedContent === 'string' ? (
        <Text css={{ whiteSpace: 'nowrap' }} typeface="subtitleBoldMicro">
          {extendedContent}
        </Text>
      ) : (
        extendedContent
      ),
    [extendedContent]
  )

  return (
    <IconContext.Provider value={{ size: space.xs }}>
      <PopoverPrimitive.Root
        defaultOpen={props.defaultOpen}
        modal={props.modal}
        onOpenChange={props.onOpenChange}
        open={open}
      >
        <PopoverPrimitive.Trigger asChild>
          {customButton ? (
            buttonChildren
          ) : (
            <PopoverIconButton
              bordered={bordered}
              css={iconButtonCss}
              disabled={disabled}
              hasDetails={active && !!details}
              id={buttonId}
              open={open}
              text={text}
              title={title}
            >
              {buttonChildren}

              {active && details}

              {showBadge && (
                <BadgeDot
                  align="right-bottom"
                  css={{ background: badgeColor }}
                  outside
                  show
                  size="default"
                />
              )}
            </PopoverIconButton>
          )}
        </PopoverPrimitive.Trigger>
        <PopoverPrimitive.Portal>
          <PopoverContent collisionPadding={12} hideWhenDetached sideOffset={5} {...contentProps}>
            {children}
          </PopoverContent>
        </PopoverPrimitive.Portal>
      </PopoverPrimitive.Root>
    </IconContext.Provider>
  )
}

Popover.defaultProps = {
  icon: '',
  buttonChildren: null,
  extended: false
}

export default Popover
