import { FilterTopicOption } from '@/types/filters/Filters'
import { FilterItemDropdown } from '.'
import UngroupedTopicFilterContent from '../../FiltersSidebar/TopicGroupsFilter/UngroupedTopicFilterContent'
import useUngroupedTopicsFilters from '../../FiltersSidebar/TopicGroupsFilter/hooks/useUngroupedTopicsFilters'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

interface FilterItemDropdownUngroupedProps {
  filter: FilterTopicOption[]
}

function FilterItemDropdownUngrouped(props: FilterItemDropdownUngroupedProps) {
  const { hasChanges, apply } = useUngroupedTopicsFilters({
    searchQuery: '',
    enabled: false
  })
  const { t } = useTranslation()

  function getTooltipText(filter: FilterTopicOption[]) {
    const selectedOptions = filter.map(filter => `<b>${filter.text}</b>`).join(t('or'))

    return i18n.t('isSelectedOptions', { selectedOptions })
  }

  return (
    <FilterItemDropdown
      content={props.filter.length}
      contentType="count"
      disabled={!hasChanges}
      dropdown={<UngroupedTopicFilterContent />}
      onApply={apply}
      title={t('topics')}
      tooltip={getTooltipText(props.filter)}
    />
  )
}

export default FilterItemDropdownUngrouped
