import { DateValue, getLocalTimeZone, toCalendarDateTime, today } from '@internationalized/date'

import {
  CreateExtractionPayload,
  IntegrationFormData,
  TestIntegrationPayload,
  UpdateExtractionPayload
} from '@/types/integrations'

/** Connector utils */
export function formatConnectorName(name: string) {
  const connectorNameMap: Record<string, string> = {
    'hubspot - surveys': 'HubSpot - Surveys',
    'hubspot - tickets': 'HubSpot - Tickets',
    'jira-software': 'Jira Software',
    bigquery: 'BigQuery',
    track: 'Track.co'
  }

  function format(value: string) {
    return value
      .split(/[_ ]/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  return connectorNameMap[name] ?? format(name)
}

export function resizeLogoUrl(logoUrl: string | null, height: number) {
  return logoUrl?.replace('upload', `upload/h_${height}/dpr_2.0`)
}

/** Integration Sample utils */
function isObjectOrArray(value: unknown) {
  return Boolean(value) && typeof value === 'object'
}

export function getPathsFromObject(obj: Record<string, unknown>, head = ''): string[] {
  return Object.entries(obj).reduce((paths, [key, value]) => {
    const fullPath = head !== '' ? `${head}|${key}` : key

    if (!isObjectOrArray(value)) return [...paths, fullPath]

    return [...paths, ...getPathsFromObject(value as Record<string, unknown>, fullPath)]
  }, [] as string[])
}

export function getValueFromPath(object: Record<string, unknown>, path: string[]): string {
  const [key, ...keys] = path
  const value = object[key]

  if (keys.length > 0 && isObjectOrArray(value)) {
    return getValueFromPath(value as Record<string, unknown>, keys)
  }

  if (value === null || value === undefined) return '[empty]'

  return JSON.stringify(value)
}

/** Integration form date utils */
export function getAbsoluteDateTimeString(value: DateValue) {
  return toCalendarDateTime(value).toString() + 'Z'
}

const TODAY = today(getLocalTimeZone())
export const DEFAULT_START_DATE = getAbsoluteDateTimeString(TODAY.subtract({ months: 1 }))
export const DEFAULT_END_DATE = getAbsoluteDateTimeString(TODAY)

/** Extraction payload formatting utils */
function mapArgumentsData(formData: IntegrationFormData) {
  const argumentsData: Record<string, string | string[]> = {}
  Object.keys(formData.fields).forEach(fieldKey => {
    const field = formData.fields[fieldKey]
    if (field && field.value) {
      argumentsData[fieldKey] = Array.isArray(field.value) ? field.value : field.value.trim()
    }
  })

  return argumentsData
}

export function toExtractionPayload(
  formData: IntegrationFormData,
  fieldsConfigIds: string[],
  organizationId: string,
  coreFields: Record<string, string | null>
) {
  const payload: CreateExtractionPayload = {
    organization_id: organizationId,
    extractions: [
      {
        connector_id: formData.connectorId || '',
        name: formData.integrationName,
        schedule: formData.schedule,
        arguments: mapArgumentsData(formData),
        fields_config: fieldsConfigIds,
        core_fields: coreFields
      }
    ]
  }

  return payload
}

export function toUpdateExtractionPayload(
  formData: IntegrationFormData,
  fieldsConfigIds: string[],
  integrationId: string,
  coreFields: Record<string, string | null>
) {
  const payload: UpdateExtractionPayload = {
    id: integrationId,
    name: formData.integrationName,
    schedule: formData.schedule,
    arguments: mapArgumentsData(formData),
    fields_config: fieldsConfigIds,
    core_fields: coreFields
  }

  return payload
}

export function toTestIntegrationPayload(
  formData: IntegrationFormData,
  organizationId: string,
  integrationId?: string
): TestIntegrationPayload {
  const payload = {
    organization_id: organizationId,
    connector_id: formData.connectorId as string,
    arguments: Object.fromEntries(
      Object.entries(formData.fields)
        .map(([key, field]) => {
          if (field.type === 'date' && key !== 'end_date') {
            return [key, DEFAULT_START_DATE]
          }

          return [key, field.value]
        })
        .filter(([, value]) => Boolean(value))
    )
  }

  if (integrationId) {
    return { ...payload, extraction_id: integrationId }
  }

  return payload
}
