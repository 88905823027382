export const fontWeights = {
  extrabold: 800,
  bold: 700,
  semibold: 600,
  regular: 400,
  light: 300
}

type FontWeightsKeys = keyof typeof fontWeights
type FontWeightsVariant = {
  [x in FontWeightsKeys]: { fontWeight: string }
}
export const fontWeightsVariant = Object.keys(fontWeights).reduce((acc, fontWeight) => {
  return { ...acc, [fontWeight]: { fontWeight: `$${fontWeight}` } }
}, {} as FontWeightsVariant)
