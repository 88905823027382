import { InitiativeItem } from '@/types/initiatives/Initiatives'
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { useCallback, useMemo, useState } from 'react'
import InitiativeNameCell from './InitiativeNameCell'
import UserCell from '../../user-cell/UserCell'
import InitiativeStatusChip from '../InitiativeStatusChip'
import TableV2, { TableV2Props } from '@/components/atoms/table-v2'
import { ArrowUp, TrashSimple } from '@phosphor-icons/react'
import { colors, styled } from '@/theme'
import Card from '../../card'
import NoResults from '@/components/atoms/no-results'
import { useInitiatives } from '@/hooks/initiatives/useInitiatives'
import useUser from '@/hooks/useUser'
import IconButton from '@/components/atoms/icon-button'
import TableSkeleton from '../../skeleton/TableSkeleton'
import { DeleteDialog } from '@/components/atoms/dialog'
import { useTranslation } from 'react-i18next'
import { DueDateChip } from '../DueDateChip'
import useInitiativeStore from '@/store/initiatives/useInitiativeStore'
import EmptyInitiatives from './EmptyInitiatives'
import useSegment from '@/hooks/useSegment'
import AssociateOppToInitiativeDialog from '../modals/AssociateOppToInitiativeDialog'
// import FlexContainer from '@/components/atoms/flex-container'

const TableCard = styled(Card.Root, {
  color: '$neutralLowPure',
  padding: '$micro',

  alignItems: 'center',
  justifyContent: 'center'
})

const CountCell = styled('span', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '$micro'
})

const AddOppLink = styled('span', {
  cursor: 'pointer',
  fontSize: '$micro',
  color: '$brandPrimaryPure',
  whiteSpace: 'nowrap',
  '&:hover': {
    color: '$brandPrimaryMedium',
    textDecoration: 'underline'
  }
})

const InitiativesTable = () => {
  const { t } = useTranslation()
  const { track } = useSegment()

  const { initiatives, isLoading, deleteInitiative, isDeleteInitiativeLoading, noResult, isEmpty } =
    useInitiatives()
  const setCurrentInitiative = useInitiativeStore(state => state.setCurrentInitiative)

  const onInitiativeClick = useCallback(
    (initiative: InitiativeItem) => {
      setCurrentInitiative(initiative)
    },
    [setCurrentInitiative]
  )

  const [initiativeToDelete, setInitiativeToDelete] = useState<string | null>(null)

  const onInitiativeDeleteClick = useCallback(
    (initiative: InitiativeItem) => {
      setInitiativeToDelete(initiative.id)
      track('initiative_delete_button')
    },
    [track]
  )

  const onInitiativeDeleteConfirm = useCallback(() => {
    if (!initiativeToDelete) return
    deleteInitiative(initiativeToDelete)
    track('initiative_delete_dialog')
  }, [track, initiativeToDelete, deleteInitiative])

  const onOpenDeleteChange = useCallback((open: boolean) => {
    setInitiativeToDelete(prev => (open ? prev : null))
  }, [])

  const onCancelClick = () => {
    track('initiative_delete_cancel_dialog')
  }

  const onClose = () => {
    track('initiative_delete_close_dialog')
  }

  const { isManager } = useUser()

  const [isAddOpportunityModalOpen, setIsAddOpportunityModalOpen] = useState(false)
  const [chosenInitiative, setChosenInitiative] = useState<InitiativeItem | null>(null)
  const { associateOpportunities } = useInitiatives({ enabled: false })

  const onAddOpportunityClick = useCallback((initiative: InitiativeItem) => {
    setIsAddOpportunityModalOpen(true)
    setChosenInitiative(initiative)
  }, [])

  const onAssociateOpp = (opportunityIds: string[]) => {
    if (!chosenInitiative) return
    associateOpportunities({ initiativeId: chosenInitiative?.id, opportunityIds })
  }

  const columns = useMemo((): ColumnDef<InitiativeItem>[] => {
    const _columns: ColumnDef<InitiativeItem>[] = [
      {
        accessorKey: 'name',
        id: 'name',
        header: 'Initiative name',
        minSize: 400,
        maxSize: 420,
        sortDescFirst: true,
        cell: props => <InitiativeNameCell data={props.row.original} onClick={onInitiativeClick} />,
        footer: props => props.column.id
      },
      {
        accessorKey: 'owner',
        id: 'owner',
        header: 'Owner',
        maxSize: 250,
        cell: ({ row }) => <UserCell userId={row.original.owner} />,
        footer: props => props.column.id
      },
      {
        accessorKey: 'assignee',
        id: 'assignee',
        header: 'Assignee',
        maxSize: 250,
        cell: ({ row }) => <UserCell userId={row.original.assignees[0]} />,
        footer: props => props.column.id
      },
      {
        accessorKey: 'status',
        id: 'status',
        header: 'Status',
        maxSize: 200,
        cell: props => <InitiativeStatusChip status={props.row.original.status} />
      },
      {
        accessorKey: 'dueDate',
        id: 'dueDate',
        header: 'Due date',
        maxSize: 200,
        cell: props => (
          <span>
            {props.row.original.dueDate ? <DueDateChip date={props.row.original.dueDate} /> : ''}
          </span>
        )
      },
      {
        accessorKey: 'opportunityCount',
        id: 'opportunityCount',
        header: 'Opportunity',
        maxSize: 88,

        cell: props =>
          props.row.original.opportunities.length === 0 ? (
            <AddOppLink onClick={() => onAddOpportunityClick(props.row.original)}>
              {t('addOpportunity')}
            </AddOppLink>
          ) : (
            <CountCell>{props.row.original.opportunities.length}</CountCell>
          )
      }
    ]

    if (isManager) {
      _columns.push({
        id: 'delete',
        minSize: 25,
        maxSize: 25,
        enableSorting: false,
        cell: data => {
          return (
            <IconButton
              color="danger"
              onClick={() => onInitiativeDeleteClick(data.row.original)}
              className="option"
            >
              <TrashSimple />
            </IconButton>
          )
        }
      })
    }

    return _columns
  }, [onInitiativeClick, onInitiativeDeleteClick, isManager, t, onAddOpportunityClick])

  const getRowId = useCallback((row: InitiativeItem) => row.id, [])

  const table = useReactTable({
    data: initiatives,
    columns,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // onColumnFiltersChange
    enableColumnResizing: false,
    enableHiding: false,
    enableColumnFilters: true,
    enableFilters: true,
    enableSorting: true
  })

  const trCss: TableV2Props['trCss'] = useCallback(
    () => ({
      '.option': {
        opacity: 0
      },

      '&:hover': {
        '.option': {
          opacity: 1
        }
      }
    }),
    []
  )

  const tdCss: TableV2Props['tdCss'] = useCallback(
    () => ({
      textAlign: 'left',
      py: '$xxs',
      px: '$xs',
      borderRightStyle: 'none'
    }),
    []
  )

  const thContainerProps: TableV2Props['thContainerProps'] = useCallback(
    () => ({
      css: {
        justifyContent: 'flex-start',
        color: '$neutralLowLight',
        bc: '$neutralHighLight',
        border: 'none',
        height: 51,
        py: '$xxs',
        px: '$xs'
      }
    }),
    []
  )

  const thCss: TableV2Props['thCss'] = useCallback(
    () => ({ padding: 0, borderRightStyle: 'none' }),
    []
  )

  if (isLoading) {
    return (
      <TableCard>
        <TableSkeleton />
      </TableCard>
    )
  }

  if (isEmpty) {
    return (
      <TableCard color="neutralLowPure">
        <EmptyInitiatives />
      </TableCard>
    )
  }

  if (noResult) {
    return (
      <TableCard color="neutralLowPure">
        <NoResults css={{ mb: '$xs' }} />
      </TableCard>
    )
  }

  return (
    <TableCard>
      <TableV2
        enableSort
        sortIndicator={<ArrowUp color={colors.neutralLowPure} size={16} />}
        table={table}
        trCss={trCss}
        tdCss={tdCss}
        thCss={thCss}
        thContainerProps={thContainerProps}
      />

      {isAddOpportunityModalOpen &&
        chosenInitiative && ( // check if the chosen Init exists
          <AssociateOppToInitiativeDialog
            initiative={chosenInitiative}
            open={isAddOpportunityModalOpen}
            onOpenChange={setIsAddOpportunityModalOpen}
            onClose={onClose}
            onSave={onAssociateOpp}
          />
        )}

      {initiativeToDelete && (
        <DeleteDialog
          cancelText={t('cancel')}
          confirmText={t('deleteInitiative')}
          description={t('deleteInitiativeDescription')}
          isDeleting={isDeleteInitiativeLoading}
          onConfirmDelete={onInitiativeDeleteConfirm}
          onOpenChange={onOpenDeleteChange}
          open={!!initiativeToDelete}
          title={t('deleteInitiativeTitle')}
          onClose={onClose}
          onCancelClick={onCancelClick}
        />
      )}
    </TableCard>
  )
}

export default InitiativesTable
