import { FilterItem } from '@/types/filters/Filters'
import { ReactNode } from 'react'
import DatetimeFilterSubMenu from './datetime/DatetimeFilterSubMenu'
import NumberFilterSubMenu from './number/NumberFilterSubMenu'
import TextFilterSubMenu from './text/TextFilterSubMenu'
import UniqueFilterSubMenu from './unique/UniqueFilterSubMenu'
import BooleanFilterSubMenu from './boolean/BooleanFilterSubMenu'
import EnumFilterSubMenu from './enum/EnumFilterSubMenu'
import FilterSubMenu from '../FiltersSidebar/AddFilterMenu/FilterSubMenu'
import { FilterSubMenuScrollArea } from '../FiltersSidebar/AddFilterMenu/AddFilterMenu.styles'
import { ScrollAreaScrollbar, ScrollAreaThumb, ScrollAreaViewport } from '../../scroll-area'
import {
  BooleanFilterType,
  DateFilterType,
  EnumFilterType,
  NumberFilterType
} from '@/types/filters/AdvancedFilters'
import AccountIdFilterSubmenu from '../FiltersSidebar/CustomerRecordsFilter/Accounts/AccountIdFilterSubmenu'
import CustomerUsersIdFilterSubmenu from '../FiltersSidebar/CustomerRecordsFilter/Users/CustomerUsersIdFilterSubmenu'

interface Props {
  filters: FilterItem[]
  isLoading?: boolean
  icon: ReactNode
  title: string
}

const FilterItemComponent = (props: FilterItem & { title: string; filterKey: string }) => {
  if (props.type.includes('datetime') || props.type.includes('date')) {
    return (
      <DatetimeFilterSubMenu
        name={props.name}
        path={props.path ?? props.key}
        previousTitle={props.title}
        title={props.displayName}
        type={props.type as DateFilterType}
      />
    )
  }

  if (props.type.includes('number') || props.type.includes('integer')) {
    return (
      <NumberFilterSubMenu
        name={props.name}
        path={props.path ?? props.key}
        previousTitle={props.title}
        title={props.displayName}
        type={props.type as NumberFilterType}
      />
    )
  }

  if (props.type.includes('text')) {
    return (
      <TextFilterSubMenu
        name={props.name}
        path={props.path ?? props.key}
        previousTitle={props.title}
        title={props.displayName}
      />
    )
  }

  if (props.type.includes('unique')) {
    return (
      <UniqueFilterSubMenu
        name={props.name}
        path={props.path ?? props.key}
        previousTitle={props.title}
        title={props.displayName}
      />
    )
  }

  // TODO: create proper components for those types

  if (props.type === 'accounts-id') {
    return <AccountIdFilterSubmenu filter={{ ...props, key: props.filterKey }} newFeed />
  }

  if (props.type === 'users-id') {
    return <CustomerUsersIdFilterSubmenu filter={{ ...props, key: props.filterKey }} newFeed />
  }

  if (props.type.includes('boolean')) {
    return (
      <BooleanFilterSubMenu
        name={props.name}
        path={props.path ?? props.key}
        previousTitle={props.title}
        title={props.displayName}
        type={props.type as BooleanFilterType}
      />
    )
  }

  return (
    <EnumFilterSubMenu
      name={props.name}
      path={props.path ?? props.key}
      previousTitle={props.title}
      title={props.displayName}
      type={props.type as EnumFilterType}
    />
  )
}

const GenericAdvancedFilterSection = ({ filters, isLoading = false, icon, title }: Props) => {
  return (
    <FilterSubMenu compact hideButton icon={icon} isLoading={isLoading} title={title}>
      <FilterSubMenuScrollArea>
        <ScrollAreaViewport css={{ maxHeight: 320 }}>
          {filters.map((filter, index) => (
            <FilterItemComponent
              {...filter}
              filterKey={filter.key}
              key={filter.key + filter.name + index}
              title={title}
            />
          ))}
        </ScrollAreaViewport>
        <ScrollAreaScrollbar orientation="vertical">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </FilterSubMenuScrollArea>
    </FilterSubMenu>
  )
}

export default GenericAdvancedFilterSection
