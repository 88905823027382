import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { useState } from 'react'
import Search from '../../search'
import { useTranslation } from 'react-i18next'

interface Props {
  count: number
  total: number
  currentSearch: string
  saveSearch: (value: string) => void
}

const UserSearch = ({ count, total, currentSearch, saveSearch }: Props) => {
  const [searchQuery, setSearchQuery] = useState(currentSearch)
  const { t } = useTranslation()
  const handleEnter: React.KeyboardEventHandler<HTMLInputElement> = event => {
    const isEnter = event.key === 'Enter'
    if (isEnter) {
      saveSearch(searchQuery)
    }
  }

  const onClear = () => {
    setSearchQuery('')
    saveSearch('')
  }

  return (
    <FlexContainer alignItems="end" css={{ mr: '$xs' }} gap="micro" justifyContent="spaceBetween">
      <Search
        clearable
        css={{
          '&:focus-within': {
            border: '2px solid $brandPrimaryPure'
          }
        }}
        onChange={event => setSearchQuery(event.currentTarget.value)}
        onClear={onClear}
        onKeyUp={handleEnter}
        placeholder={t('searchUser')}
        value={searchQuery}
        width="320px"
      />

      {count > 0 && (
        <Text color="neutralLowPure" fontSize="xxxs" fontWeight="regular" lineHeight="xl">
          {currentSearch.length
            ? `Showing search results for "${currentSearch}": ${count} ${
                count > 1 ? 'users' : 'user'
              }`
            : `Total: ${total} ${total > 1 ? 'users' : 'user'}`}
        </Text>
      )}
    </FlexContainer>
  )
}

export default UserSearch
