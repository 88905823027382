import FlexContainer from '@/components/atoms/flex-container'
import { PopoverContent } from '@/components/atoms/popover/Popover.styles'
import { CSS, styled } from '@/theme'

export const CollectionSelectPopoverContent = styled(PopoverContent, {
  width: 320,
  padding: '$xxs $micro',
  pb: '$micro',
  maxHeight: '72vh',
  overflowY: 'auto'
})

export const CollectionSelectSectionTitle = styled('h2', {
  px: '$micro',
  fontSize: '$xxxs',
  fontWeight: 'bold',
  color: '$neutralLowPure'
})

const collectionItemHoverStyle: CSS = {
  color: '$brandPrimaryPure',

  '.check': {
    opacity: 0,
    display: 'none'
  },

  '.fixed': {
    opacity: 1,
    display: 'inline-block'
  },

  '.hidden': {
    opacity: 1,
    display: 'inline-block'
  }
}

export const CollectionItemContainer = styled(FlexContainer, {
  padding: '$micro',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 8,
  color: '$neutralLowPure',
  fontSize: '$xxxs',
  transition: '.2s',
  cursor: 'pointer',
  height: 32,

  '.check': {
    opacity: 0,
    display: 'inline-block',
    px: 4.5
  },

  '.visible': {
    opacity: 1
  },

  '.hidden': {
    display: 'none',
    opacity: 0,
    transition: '.2s'
  },

  '&:hover, &:active': collectionItemHoverStyle,

  variants: {
    open: {
      true: collectionItemHoverStyle
    }
  }
})
