import Button from '@/components/atoms/button'
import Dialog from '@/components/atoms/dialog'
import { DialogTitleDefault } from '@/components/atoms/dialog/Dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import Input from '@/components/atoms/input'
import Text from '@/components/atoms/text'
import useSegment from '@/hooks/useSegment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  onCreate: (name: string) => void
}

const CreateInitiativeDialog = ({ open, onOpenChange, onCreate }: Props) => {
  const { t } = useTranslation()
  const { track } = useSegment()

  const [name, setName] = useState('')

  const onCreateClick = () => {
    onCreate(name)
    onOpenChange(false)
    setName('')
    track('initiative_create_dialog')
  }

  const onClose = () => {
    track('initiative_close_dialog')
  }

  return (
    <Dialog
      align="center"
      closeIcon
      modal
      onOpenChange={onOpenChange}
      open={open}
      onClose={onClose}
    >
      <FlexContainer direction="column" gap="xs">
        <DialogTitleDefault>{t('addOpportunityToInitiative')}</DialogTitleDefault>
        <FlexContainer direction="column" gap="xxs">
          <Text as="p" fontSize="xxxs">
            {t('initiativeName')}
          </Text>
          <Input onChange={e => setName(e.currentTarget.value)} small value={name} />
        </FlexContainer>
        <Divider line="solid" orientation="horizontal" />
        <Button fullWidth onClick={onCreateClick} size="small">
          {t('createInitiative')}
        </Button>
      </FlexContainer>
    </Dialog>
  )
}

export default CreateInitiativeDialog
