import useClassification from '@/hooks/useClassification'
import ThemeItem from './ThemeItem'
import { styled } from '@/theme'
import ClassificationSkeleton from '../ClassificationSkeleton'
import { useState } from 'react'
import { DeleteDialog } from '@/components/atoms/dialog'
import Divider from '@/components/atoms/divider'

const ThemeListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$micro',
  paddingLeft: '$xxxs',
  overflow: 'hidden'
})

const ThemeList = () => {
  const { isLoading, unclassifiedTopics, currentThemes: themes, removeTheme } = useClassification()
  const [themeToDelete, setThemeToDelete] = useState<string | undefined>()

  const onOpenDeleteChange = (open: boolean) => {
    setThemeToDelete(prevTopic => (open ? prevTopic : undefined))
  }

  const onConfirmDelete = () => {
    setThemeToDelete(undefined)
    themeToDelete && removeTheme(themeToDelete)
  }

  return (
    <ThemeListContainer>
      {isLoading ? (
        <ClassificationSkeleton />
      ) : (
        <>
          <ThemeItem
            {...unclassifiedTopics}
            onClickRemove={setThemeToDelete}
            themeName="Unclassified"
          />
          <Divider css={{ marginRight: 54, marginLeft: 0 }} orientation="horizontal" />
          {themes.map(theme => (
            <ThemeItem key={theme.themeId} onClickRemove={setThemeToDelete} {...theme} />
          ))}
        </>
      )}

      {!!themeToDelete && (
        <DeleteDialog
          description="Removing this Topic will also remove all of its associated subtopics."
          isDeleting={false}
          onConfirmDelete={onConfirmDelete}
          onOpenChange={onOpenDeleteChange}
          open={Boolean(themeToDelete)}
        />
      )}
    </ThemeListContainer>
  )
}

export default ThemeList
