import FeedbackService from '@/services/FeedbackService'
import { useFeedbackStore, useFiltersStore } from '@/store'
import useToastMessageStore from '@/store/useToastMessageStore'
import usePromiseSync from '../usePromiseSync'
import {
  FEEDBACK_STORE_LIST,
  FeedbackStoreNames,
  mapFeedbackStore,
  useArchivedFeedbackStore
} from '@/store/useFeedbackStore'
import { SearchPayload } from '@/types/feedbacks/FeedbackRequests'
import { Feedback, KeywordTopic } from '@/types/feedbacks'
import { Intention } from '@/types/reasons'
import useSegment from '../useSegment'
import useLetterCase from '../useLetterCase'
import DefaultErrorHandler from '@/services/DefaultError'
import { useMemo, useRef } from 'react'
import useClassification from '../useClassification'
import useAccountsToFeedback from '../useAccountsToFeedback'
import useCustomerUsersToFeedbacks from '../useCustomerUsersToFeedback'

interface UseFeedbacksParams {
  archived: boolean
  store: FeedbackStoreNames
  pageSize: number
}
const defaultParams: UseFeedbacksParams = {
  archived: false,
  pageSize: 10,
  store: 'explore'
}

export function useFeedbacks(params?: Partial<UseFeedbacksParams>) {
  const { track } = useSegment()
  const { sync } = usePromiseSync()
  const { capitalizeFirst } = useLetterCase()

  const { archived, pageSize, store } = {
    ...defaultParams,
    ...params
  } as UseFeedbacksParams

  const useStore = archived ? mapFeedbackStore.archived : mapFeedbackStore[store]

  const {
    data,
    page,
    error,
    isLoading,
    hasMore,
    total,
    dataHasBeenLoaded,

    push,
    setTotal,
    setError,
    setLoading,
    setHasMore,
    reset,
    deleteFeedback,
    markFeedbackAsArchived,
    unmarkFeedbackAsArchived
  } = useStore()

  const toRequestPayload = useFiltersStore(state => state.toFeedbackRequestPayload)
  const toExportRequestPayload = useFiltersStore(state => state.toFeedbackExportRequestPayload)

  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)

  const resetFeedbacks = useFeedbackStore(state => state.reset)
  const resetArchivedFeedbacks = useArchivedFeedbackStore(state => state.reset)

  const { getTopicById } = useClassification()

  const { checkAccountsFromFeedbacks } = useAccountsToFeedback()
  const { checkUsersFromFeedbacks } = useCustomerUsersToFeedbacks()

  const controllerRef = useRef<AbortController>()

  function cancel() {
    controllerRef.current?.abort()
  }

  async function load(customPayload?: SearchPayload, forceReset = false) {
    if (isLoading) {
      return
    }
    setLoading(true)
    setError(null)

    if (!hasMore && !forceReset) {
      setLoading(false)
      return
    }

    const controller = new AbortController()
    controllerRef.current = controller

    const payload = customPayload
      ? { ...customPayload, size: pageSize, offset: forceReset ? 0 : page * pageSize }
      : toRequestPayload(forceReset ? 0 : page, pageSize, archived)

    payload.filter.feedback_keyword_classes =
      payload.filter.feedback_keyword_classes?.map(capitalizeFirst)

    let error: DefaultErrorHandler | undefined
    await sync(
      'feedbacks' + store,
      FeedbackService.search(payload, controllerRef.current?.signal),
      response => {
        const [feedbackError, feedbackData] = response
        error = feedbackError

        if (!feedbackData) {
          return
        }

        if (feedbackData.data.length === 0) {
          setHasMore(false)
          return
        }

        setTotal(feedbackData.totalHits)
        push(feedbackData.data)
        checkAccountsFromFeedbacks(feedbackData.data)
        checkUsersFromFeedbacks(feedbackData.data)
      }
    )

    setLoading(false)

    if (error) {
      console.error(error)
    }

    return error
  }

  async function exportFeedbacks() {
    const payload = toExportRequestPayload()
    const result = await FeedbackService.exportAsync(payload)

    track('feedback_user_export')

    return result
  }

  async function archive(feedbackId: string) {
    const removeToast = addLoadingToast({ text: 'Archiving feedback...' })
    try {
      await FeedbackService.patch(feedbackId, { archived: true })
      markFeedbackAsArchived(feedbackId)
      if (store !== 'thread') {
        !archived && deleteFeedback(feedbackId)
      }
      resetArchivedFeedbacks()
      removeToast()
    } catch (e) {
      removeToast()
      console.error(e)
    }
  }

  async function unarchive(feedbackId: string) {
    const removeToast = addLoadingToast({ text: 'Unarchiving feedback...' })
    try {
      await FeedbackService.patch(feedbackId, { archived: false })
      unmarkFeedbackAsArchived(feedbackId)
      if (store === 'archived') {
        archived && deleteFeedback(feedbackId)
        resetFeedbacks()
      } else {
        resetArchivedFeedbacks()
      }
      removeToast()
    } catch (e) {
      removeToast()
      console.error(e)
    }
  }

  const addKeywordTopicToAllFeedbackStores = (keywordTopic: KeywordTopic) => {
    FEEDBACK_STORE_LIST.forEach(storeName => {
      const store = mapFeedbackStore[storeName]
      const { addKeywordTopicToFeedback } = store.getState()
      addKeywordTopicToFeedback(keywordTopic)
    })
  }

  const updateSentimentToAllFeedbackStores = (
    feedbackId: string,
    keywordHash: string,
    newSentiment: number
  ) => {
    FEEDBACK_STORE_LIST.forEach(storeName => {
      const store = mapFeedbackStore[storeName]
      const { updateSentimentOfKeywordOnFeedback } = store.getState()
      updateSentimentOfKeywordOnFeedback(feedbackId, keywordHash, newSentiment)
    })
  }

  const updateIntentionAllFeedbackStores = (
    feedbackId: string,
    keywordHash: string,
    newIntention: Intention
  ) => {
    FEEDBACK_STORE_LIST.forEach(storeName => {
      const store = mapFeedbackStore[storeName]
      const { updateIntentionOfKeywordOnFeedback } = store.getState()
      updateIntentionOfKeywordOnFeedback(feedbackId, keywordHash, newIntention)
    })
  }

  const removeFeedbackKeywordAllFeedbackStores = (feedbackId: string, keywordHash: string) => {
    FEEDBACK_STORE_LIST.forEach(storeName => {
      const store = mapFeedbackStore[storeName]
      const { removeFeedbackKeyword } = store.getState()
      removeFeedbackKeyword(feedbackId, keywordHash)
    })
  }

  const resetFeedbackForAllStores = () => {
    FEEDBACK_STORE_LIST.forEach(storeName => {
      const store = mapFeedbackStore[storeName]
      const { reset } = store.getState()
      reset()
    })
  }

  const filteredFeedbacksTopics = useMemo(() => {
    return data.map(feedback => ({
      ...feedback,
      keywordTopicList: feedback.keywordTopicList.map(keyword => ({
        ...keyword,
        topics: keyword.topics.filter(topic => !!getTopicById(topic.topicId))
      }))
    })) as Feedback[]
  }, [getTopicById, data])

  return {
    feedbacks: data,
    filteredFeedbacksTopics,
    isLoading,
    error,
    hasMore,
    load,
    cancel,
    reset,
    exportFeedbacks,
    archive,
    unarchive,
    total,
    deleteFeedback,
    dataHasBeenLoaded,

    addKeywordTopicToAllFeedbackStores,
    updateSentimentToAllFeedbackStores,
    updateIntentionAllFeedbackStores,
    removeFeedbackKeywordAllFeedbackStores,
    resetFeedbackForAllStores
  }
}
