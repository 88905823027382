import { CheckCircle } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'

import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import useSegment from '@/hooks/useSegment'
import useIntegrationsStore from '@/store/useIntegrationsStore'
import { IntegrationConnector } from '@/types/integrations'
import { formatConnectorName, resizeLogoUrl } from '../utils'
import { ConnectedTag, Container, Description } from './ConnectorCard.styles'
import useUser from '@/hooks/useUser'
import { useTranslation } from 'react-i18next'

export interface ConnectorCardProps {
  description: IntegrationConnector['description']
  id: IntegrationConnector['id']
  logoUrl: IntegrationConnector['logo_url']
  multipleIntegrations: IntegrationConnector['metadata']['multiple_extractions']
  name: IntegrationConnector['name']
  placeholder: IntegrationConnector['metadata']['placeholder']
  type: IntegrationConnector['metadata']['data_type']
}

function ConnectorCard(props: ConnectorCardProps) {
  const navigate = useNavigate()

  const integrations = useIntegrationsStore(state => state.integrations)
  const { t } = useTranslation()

  const { track } = useSegment()

  const activeIntegrations = integrations.filter(
    integration => integration.connector.id === props.id
  )
  const isActiveConnector = activeIntegrations.length > 0

  function handleOnClick() {
    if (isActiveConnector) {
      track('sources_user_connector_view-details', {
        connector_id: props.id,
        connector_name: props.name,
        connector_type: props.type
      })

      if (props.multipleIntegrations) {
        navigate(`/settings/integrations/${props.id}`)
      } else {
        navigate(`/settings/integrations/${props.id}/${activeIntegrations[0].id}`)
      }
    } else {
      track('sources_user_connector_connect', {
        connector_id: props.id,
        connector_name: props.name,
        connector_type: props.type
      })

      navigate(`/settings/integrations/${props.id}/${props.placeholder ? 'placeholder' : 'new'}`)
    }
  }

  const { userPermissions } = useUser()
  const isManager = userPermissions.source.includes('manager')
  const isButtonDisabled = !isManager && !isActiveConnector

  return (
    <Container>
      <FlexContainer css={{ width: '100%' }} justifyContent="spaceBetween">
        <img alt="" height={40} src={resizeLogoUrl(props.logoUrl, 40)} />
        {!isActiveConnector ? null : (
          <ConnectedTag>
            <CheckCircle size={16} weight="bold" />
            {activeIntegrations.length > 1
              ? `${activeIntegrations.length} Connections`
              : t('connected')}
          </ConnectedTag>
        )}
      </FlexContainer>
      <Text as="h3" typeface="titleBoldXXS">
        {formatConnectorName(props.name)}
      </Text>
      {props.description ? (
        <Description data-testid="connector-card-description">{props.description}</Description>
      ) : null}
      <Button
        css={{ marginTop: 'auto' }}
        disabled={isButtonDisabled}
        fullWidth
        onClick={handleOnClick}
        size="small"
        type="button"
        variant={!isActiveConnector ? 'primary' : 'secondary'}
      >
        {!isActiveConnector ? t('connect') : t('viewDetails')}
      </Button>
    </Container>
  )
}

export default ConnectorCard
