import { useEffect, useMemo, useState } from 'react'

import FlexContainer from '@/components/atoms/flex-container'
import Select, { SelectOption } from '@/components/atoms/select'
import ConnectorSection from '@/components/molecules/integrations/ConnectorSection'
import FeedbackService from '@/services/FeedbackService'
import useIntegrationsStore from '@/store/useIntegrationsStore'
import { SearchIcon, SearchInput, Separator } from './ConnectorLibrary.styles'
import { useTranslation } from 'react-i18next'

function ConnectorLibrary() {
  const connectors = useIntegrationsStore(state => state.connectors.data)
  const isLoadingConnectors = useIntegrationsStore(state => state.connectors.isLoading)
  const isLoadingIntegrations = useIntegrationsStore(state => state.isLoading)
  const { t } = useTranslation()

  const defaultFilterOptions = useMemo(() => [{ text: t('all'), value: 'All' }], [t])
  const [filterOptions, setFilterOptions] = useState<SelectOption[]>(defaultFilterOptions)
  const [filterCategory, setFilterCategory] = useState('All')
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    FeedbackService.getFeedbackSources().then(sources => {
      const sortedSources = sources.sort((a, b) => a.name.localeCompare(b.name))
      const options = sortedSources.map(source => ({ text: source.name, value: source.name }))
      setFilterOptions([...defaultFilterOptions, ...options])
    })
  }, [defaultFilterOptions])

  const isLoading = isLoadingConnectors || isLoadingIntegrations

  const sortedConnectors = connectors
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter(connector => !connector.metadata.hidden)

  const filteredConnectors = sortedConnectors.filter(
    connector =>
      connector.name.includes(searchTerm.trim().toLowerCase().replaceAll(' ', '_')) &&
      (filterCategory === 'All' || connector.metadata.data_type === filterCategory)
  )
  const recommendedConnectors = sortedConnectors.filter(connector => connector.metadata.recommended)
  const otherConnectors = sortedConnectors.filter(
    connector => !recommendedConnectors.includes(connector)
  )

  return (
    <FlexContainer css={{ gap: 36 }} direction="column">
      <FlexContainer css={{ gap: 16, height: 40 }} justifyContent="spaceBetween">
        <Select
          label="Connector category"
          onValueChange={value => setFilterCategory(value)}
          options={filterOptions}
          value={filterCategory}
          width={320}
        />
        <FlexContainer css={{ position: 'relative' }}>
          <SearchIcon size={24} weight="bold" />
          <SearchInput
            aria-label="Search integrations"
            onChange={e => setSearchTerm(e.target.value)}
            placeholder={t('searchIntegrations')}
            value={searchTerm}
          />
        </FlexContainer>
      </FlexContainer>
      {searchTerm || filterCategory !== 'All' ? (
        <ConnectorSection
          connectors={filteredConnectors}
          isLoading={isLoading}
          title={`Showing results for: ${searchTerm || filterCategory}`}
        />
      ) : (
        <FlexContainer css={{ gap: 40 }} direction="column">
          <ConnectorSection
            connectors={recommendedConnectors}
            isLoading={isLoading}
            title={t('recommended')}
          />
          <Separator />
          <ConnectorSection connectors={otherConnectors} isLoading={isLoading} />
        </FlexContainer>
      )}
    </FlexContainer>
  )
}

export default ConnectorLibrary
