import * as ScrollArea from '@radix-ui/react-scroll-area'

import { styled } from '@/theme'

export const ScrollAreaRoot = styled(ScrollArea.Root, {
  overflow: 'hidden',
  position: 'relative',

  '&::before': {
    background: 'linear-gradient(transparent, white)',
    bottom: 0,
    content: '',
    height: 32,
    position: 'absolute',
    width: '100%'
  },

  variants: {
    fade: {
      true: {
        '&::before': { display: 'block' }
      },
      false: {
        '&::before': { display: 'none' }
      }
    }
  }
})
