import { useUIStore } from '@/store'
import { CSS } from '@/theme'
import { shallow } from 'zustand/shallow'
import { FiltersAreaContainer, FiltersAreaInnerContent } from '../FiltersSidebar/FiltersArea.styles'
import AdvancedFiltersAreaHeader from './AdvancedFiltersAreaHeader'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import { ExploreFloatingButton } from '../../side-explore/SideExplore.styles'
import { X } from '@phosphor-icons/react'
import AppliedAdvancedFilters from './AppliedAdvancedFilters'
import SaveFiltersButton from '../../saved-filters/SaveFiltersButton'
import CreateAdvancedFilterModal from './create/CreateAdvancedFilterModal'
import useUser from '@/hooks/useUser'
import AppliedFilterEntity from './AppliedFilterEntity'
import useAreaOfInterest from '@/hooks/areaOfInterest/useAreaOfInterest'
import useFilterEntityStore from '@/store/useFiltersStore/useFilterEntityStore'
import useSegmentationStore from '@/store/useSegmentationStore'
import useSegmentation from '@/hooks/segmentation/useSegmentation'
import useOpportunityStore from '@/store/useOpportunityStore'
import { useMemo } from 'react'

interface Props {
  css?: CSS
}

const AdvancedFiltersArea = ({ css }: Props) => {
  const collapsed = useUIStore(state => !state.isAdvancedFiltersOpen, shallow)
  const close = useUIStore(state => state.closeAdvancedFilters)

  const isEditing = useFilterEntityStore(state => state.editMode)
  const setIsEditing = useFilterEntityStore(state => state.setEditMode)

  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)

  const {
    applyFilterFromArea,
    applyFilterFromSegmentation,
    clearFilters,
    hasChanges,
    simpleFiltersCount
  } = useAdvancedFilters()
  const isEmpty = hasChanges && simpleFiltersCount === 0

  // Area related

  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const { updateArea, isUpdatingArea } = useAreaOfInterest()
  const updateAreaDisabled = !hasChanges || isEmpty || isUpdatingArea

  const onAreaCancelClick = () => {
    setIsEditing(false)

    if (!currentInterestArea) return
    applyFilterFromArea(currentInterestArea)
  }

  const onUpdateAreaClick = (params: { name: string; description: string }) => {
    if (!currentInterestArea) return

    updateArea({
      area: currentInterestArea,
      name: params.name,
      description: params.description
    })
  }

  // Segment related

  const currentSegmentation = useSegmentationStore(state => state.currentSegmentation)
  const { updateSegmentation, isUpdatingSegmentation } = useSegmentation()
  const updateSegmentationDisabled = !hasChanges || isEmpty || isUpdatingSegmentation

  const onSegmentCancelClick = () => {
    setIsEditing(false)

    if (!currentSegmentation) return
    applyFilterFromSegmentation(currentSegmentation)
  }

  const onUpdateSegmentClick = (params: { name: string; description: string }) => {
    if (!currentSegmentation) return

    updateSegmentation({
      segmentation: currentSegmentation,
      name: params.name,
      description: params.description,
      refreshCurrent: true
    })
  }

  // General 🫡

  const onResetClick = () => {
    if (currentInterestArea) {
      applyFilterFromArea(currentInterestArea)
    } else if (currentSegmentation) {
      applyFilterFromSegmentation(currentSegmentation)
    } else {
      clearFilters()
    }
  }

  const isAdvancedFiltersModalOpen = useUIStore(state => state.isCreateModalOpen, shallow)
  const setIsAdvancedFiltersModalOpen = useUIStore(state => state.setIsCreateModalOpen)
  const onAdvancedClick = () => {
    setIsAdvancedFiltersModalOpen(true)
  }

  const { userPermissions } = useUser()
  const isManager = userPermissions.areas.includes('manager')

  const showSaveFiltersButton = useMemo(
    () => !currentInterestArea && !currentSegmentation && !currentOpportunity && hasChanges,
    [currentInterestArea, currentSegmentation, currentOpportunity, hasChanges]
  )

  return (
    <FiltersAreaContainer collapsed={collapsed} css={css} opportunityPlan>
      <FiltersAreaInnerContent direction="column" gap="micro">
        {/* {!editMode && <AreaOfInterestAdvancedFilters />} */}
        {currentInterestArea && (
          <AppliedFilterEntity
            entity="area"
            name={currentInterestArea?.name}
            description={currentInterestArea?.description ?? ''}
            onUpdate={onUpdateAreaClick}
            onOpenChange={setIsEditing}
            open={isEditing}
            updateDisabled={updateAreaDisabled}
            onCancelClick={onAreaCancelClick}
          />
        )}

        {currentSegmentation && (
          <AppliedFilterEntity
            entity="segment"
            name={currentSegmentation?.name}
            description={currentSegmentation?.description ?? ''}
            onUpdate={onUpdateSegmentClick}
            onOpenChange={setIsEditing}
            open={isEditing}
            updateDisabled={updateSegmentationDisabled}
            onCancelClick={onSegmentCancelClick}
          />
        )}

        {!isEditing && (
          <AdvancedFiltersAreaHeader
            onAdvancedClick={onAdvancedClick}
            onResetClick={onResetClick}
          />
        )}
        <AppliedAdvancedFilters />
        {showSaveFiltersButton && isManager && (
          <SaveFiltersButton css={{ alignSelf: 'flex-end', my: '$xs' }} newFeed />
        )}
      </FiltersAreaInnerContent>
      {!collapsed && (
        <ExploreFloatingButton
          colorScheme="tinted"
          css={{ padding: '$nano', top: '30px', right: '3px' }}
          onClick={close}
        >
          <X size={16} weight="regular" />
        </ExploreFloatingButton>
      )}

      {isAdvancedFiltersModalOpen && (
        <CreateAdvancedFilterModal
          onOpenChange={setIsAdvancedFiltersModalOpen}
          open={isAdvancedFiltersModalOpen}
        />
      )}
    </FiltersAreaContainer>
  )
}

export default AdvancedFiltersArea
