import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'

import { useFiltersStore } from '@/store'
import { FilterTopicOption } from '@/types/filters/Filters'
import FilterItem from '../FilterItem'
import FilterItemDropdownAccounts from '../FilterItem/FilterItemDropdownAccounts'
import { snakeToTitle } from '@/utils/letterCase'
import { Buildings } from '@phosphor-icons/react'
import { useFeedFiltersStore } from '@/store/useFiltersStore'

interface Props {
  newFeed?: boolean
}

function AppliedAccountsStringFilters({ newFeed = false }: Props = { newFeed: false }) {
  const useStore = newFeed ? useFeedFiltersStore : useFiltersStore

  const removeAccountFilter = useStore(state => state.removeAccountFilter)
  const accounts = useStore(state => state.accountsStringFilters, shallow)

  const accountsApplied = useMemo(() => {
    return Object.entries(accounts)
      .map(([key, value]) => {
        if (!value.selected.length) return null

        const text = key === 'ingestedId' ? 'Id' : snakeToTitle(key)
        return { id: key, text, values: value.selected }
      })
      .filter(Boolean) as (FilterTopicOption & { values: string[] })[]
  }, [accounts])

  const onRemoveAccountField = (fieldId: string) => () => {
    removeAccountFilter({ filterName: fieldId, apply: true })
  }

  return [
    ...accountsApplied.map(filter => (
      <FilterItem
        customIcon={<Buildings />}
        editButton={
          <FilterItemDropdownAccounts
            filter={{ id: filter.id, text: filter.text }}
            key={filter.id}
            newFeed={newFeed}
            selectedValues={filter.values}
          />
        }
        key={filter.id}
        name={'Accounts/' + filter.text}
        onDelete={onRemoveAccountField(filter.id)}
      />
    ))
  ].filter(Boolean) as JSX.Element[]
}

export default AppliedAccountsStringFilters
