import { FormEvent } from 'react'
import Search from '../search'
import * as S from './KeywordEditor.styles'

interface Props {
  searchQuery: string
  onSearch: (value: string) => void
}

const TopicSearch = ({ searchQuery, onSearch }: Props) => {
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    onSearch(e.currentTarget.value)
  }

  return (
    <S.SearchHeader>
      <Search
        green={false}
        onChange={handleChange}
        placeholder="Search topic"
        size="small"
        small
        value={searchQuery}
      />
    </S.SearchHeader>
  )
}

export default TopicSearch
