import { TopicData, TopicSortType } from '@/types/classification/Topic'
import createInfiniteScrollStore, { InifiniteScrollSlice } from './createInfiniteScrollSlice'
import { createWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'

interface TopicState {
  checkedTopics: TopicData[]
  topicsToReasons: TopicData[]
  topicGroups: TopicData[]

  isLoadingTopicGroups: boolean
  setIsLoadingTopicGroups: (isLoadingTopicGroups: boolean) => void
  setCheckedTopics: (topics: TopicData[]) => void
  checkTopic: (topic: TopicData) => void
  uncheckTopic: (topicId: string) => void

  getFirstFrequency: () => number
  getMaxFrequency: () => number
  setTopicGroups: (topicGroups: TopicData[]) => void
  replaceTopic: (newTopic: TopicData, originalTopicId?: string) => void
  setTopicGroupInTopic: (topicId: string, topicGroupId: string) => void
  removeTopicGroupFromTopic: (topicId: string, topicGroupId: string) => void
  addTopicGroup: (topicGroup: TopicData) => void
  replaceTopicGroup: (topicGroup: TopicData) => void
  removeTopicGroup: (topicGroupId: string) => void
  mergeTopics: (data: {
    newTopicId: string
    newTopicName: string
    mergedTopics: string[]
    newFrequency: number
  }) => void
  setTopicsToReasons: (topicsToReasons: TopicData[]) => void
  removeTopic: (topicId: string) => void
  addTopicsAt: (index: number, topics: TopicData[]) => void

  selectedTopicGroups: string[]
  selectTopicGroup: (value: string | string[]) => void
  unselectTopicGroup: (value: string | string[]) => void
  setSelectedTopicGroups: (value: string[]) => void

  topicSortType: TopicSortType
  setTopicSortType: (topicSortType: TopicSortType) => void
  topicSortDirection: SortDirection
  setTopicSortDirection: (topicSortDirection: SortDirection) => void

  isUngrouped: boolean
  setIsUngrouped: (isUngrouped: boolean) => void

  isDebounceLoading: boolean
  setDebounceLoading: (isLoading: boolean) => void
}

const topicsInfiniteScrollSlice = createInfiniteScrollStore<TopicData>()

const useTopicsStore = createWithEqualityFn<InifiniteScrollSlice<TopicData> & TopicState>()(
  (set, ...args) => ({
    ...topicsInfiniteScrollSlice(set, ...args),
    topicsToReasons: [],
    topicGroups: [],

    checkedTopics: [],

    isLoadingTopicGroups: false,

    setIsLoadingTopicGroups: isLoadingTopicGroups => {
      set(() => ({
        isLoadingTopicGroups
      }))
    },

    setTopicsToReasons: topicsToReasons => {
      set(() => ({
        topicsToReasons
      }))
    },

    setCheckedTopics: topics => {
      set(() => ({
        checkedTopics: topics
      }))
    },

    checkTopic: topic => {
      set(state => ({
        checkedTopics: [...state.checkedTopics, topic]
      }))
    },

    uncheckTopic: topicId => {
      set(state => ({
        checkedTopics: state.checkedTopics.filter(topic => topic.topicId !== topicId)
      }))
    },

    getFirstFrequency: () => {
      const [get] = args
      const { data } = get()
      return data[0]?.totalFrequency
    },

    getMaxFrequency: () => {
      const [get] = args
      const { data } = get()
      return Math.max(...data.map(topic => topic.totalFrequency))
    },

    setTopicGroups: topicGroups => set(() => ({ topicGroups })),

    replaceTopic: (newTopic, originalTopicId) => {
      set(state => ({
        data: state.data.map(topic =>
          topic.topicId === (originalTopicId ?? newTopic.topicId) ? newTopic : topic
        )
      }))
    },

    removeTopic: topicId => {
      set(state => ({
        data: state.data.filter(topic => topic.topicId !== topicId)
      }))
    },

    setTopicGroupInTopic: (topicId, topicGroupId) => {
      const [get] = args
      const { topicGroups, data } = get()
      const topic = data.find(item => item.topicId === topicId)
      const topicGroup = !topicGroupId
        ? undefined
        : topicGroups.find(item => item.topicId === topicGroupId)

      if (!topic) return
      if (!topicGroup) return

      const newTheme = {
        themeName: topicGroup.topicName,
        themeId: topicGroup.topicId,
        themeCategory: topicGroup.category || 'OTHER'
      }

      const newTopic = {
        ...topic,
        themeList: !topic.themeList ? [newTheme] : [...topic.themeList, newTheme]
      } as TopicData

      set(state => ({
        data: state.data.map(item => (item.topicId === newTopic.topicId ? newTopic : item))
      }))
    },

    removeTopicGroupFromTopic: (topicId, topicGroupId) => {
      const [get] = args
      const { data } = get()
      const topic = data.find(item => item.topicId === topicId)

      if (!topic || !topic.themeList) return

      const newTopic = {
        ...topic,
        themeList: topic.themeList?.filter(theme => theme.themeId !== topicGroupId)
      } as TopicData

      set(state => ({
        data: state.data.map(item => (item.topicId === newTopic.topicId ? newTopic : item))
      }))
    },

    addTopicGroup: topicGroup => {
      set(state => ({
        topicGroups: [...state.topicGroups, topicGroup]
      }))
    },

    replaceTopicGroup: topicGroup => {
      set(state => ({
        topicGroups: state.topicGroups.map(group =>
          group.topicId === topicGroup.topicId ? topicGroup : group
        )
      }))
    },

    removeTopicGroup: topicGroupId => {
      set(state => ({
        topicGroups: state.topicGroups.filter(group => group.topicId !== topicGroupId)
      }))
    },

    mergeTopics: props => {
      const [get] = args
      const { data } = get()

      const [firstTopicId, ...otherTopicsIds] = props.mergedTopics.slice(0)
      if (!firstTopicId) return

      const firstTopicIndex = data.findIndex(topic => topic.topicId === firstTopicId)
      if (firstTopicIndex === -1) return

      const newMergedTopics = data.filter(oldMerged =>
        props.mergedTopics.includes(oldMerged.topicId)
      )

      const mergedThemeList = newMergedTopics.flatMap(topic => topic.themeList || [])

      const newData = data
        .map((topic, index) =>
          index === firstTopicIndex
            ? {
                ...topic,
                topicId: props.newTopicId,
                topicName: props.newTopicName,
                totalFrequency: props.newFrequency,
                themeList: [
                  ...new Map(
                    [...(topic.themeList || []), ...mergedThemeList].map(item => [
                      item.themeId,
                      item
                    ])
                  ).values()
                ],
                isMergeParent: true,
                mergedTopics: newMergedTopics.map(oldMerged => ({
                  id: oldMerged.topicId,
                  name: oldMerged.topicName
                }))
              }
            : topic
        )
        .filter(topic => !otherTopicsIds.includes(topic.topicId))

      set(() => ({ data: newData }))
    },

    addTopicsAt: (index, topics) => {
      const [get] = args
      const { data } = get()

      const newData = Array.from(data)
      newData.splice(index, 0, ...topics)

      set(() => ({
        data: newData
      }))
    },

    selectedTopicGroups: [],
    selectTopicGroup: value =>
      set(state => ({
        selectedTopicGroups: [
          ...new Set([
            ...state.selectedTopicGroups,
            ...(typeof value === 'string' ? [value] : value)
          ])
        ]
      })),
    unselectTopicGroup: value =>
      set(state => ({
        selectedTopicGroups: state.selectedTopicGroups.filter(id =>
          typeof value === 'string' ? id !== value : !value.includes(id)
        )
      })),
    setSelectedTopicGroups: topicGroupIds =>
      set(() => ({
        selectedTopicGroups: topicGroupIds
      })),

    topicSortType: 'volume',
    setTopicSortType: topicSortType => set(() => ({ topicSortType })),
    topicSortDirection: 'desc',
    setTopicSortDirection: topicSortDirection => set(() => ({ topicSortDirection })),

    isUngrouped: false,
    setIsUngrouped: isUngrouped => set(() => ({ isUngrouped })),

    isDebounceLoading: false,
    setDebounceLoading: isDebounceLoading => set(() => ({ isDebounceLoading }))
  }),
  shallow
)

export default useTopicsStore
