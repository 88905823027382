import Input from '@/components/atoms/input'
import Select, { SelectOption } from '@/components/atoms/select'
import { FilterComponentProps } from './types'
import { UniqueFilterOption, UniqueFilterValue } from '@/types/filters/AdvancedFilters'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

const selectOptions: SelectOption<UniqueFilterOption>[] = [
  {
    value: 'matches',
    text: i18n.t('exactMatches')
  },
  {
    value: 'does_not_match',
    text: i18n.t('doesNotMatch')
  },
  {
    value: 'starts_with',
    text: i18n.t('startsWith')
  }
]

const UniqueFilterRow = ({ filter, onFilterChange }: FilterComponentProps) => {
  const filterValue = filter.value as UniqueFilterValue
  const value = (filter.option === 'starts_with' ? filterValue.$startsWith : filterValue.$eq) ?? ''
  const { t } = useTranslation()

  const option = filter.option

  const onValueChange = (v: string) => {
    const newValue = option === 'starts_with' ? { $startsWith: v } : { $eq: v }
    const newFilter = { ...filter, value: newValue }
    onFilterChange(newFilter)
  }

  const onOptionChange = (v: UniqueFilterOption) => {
    const newValue = v === 'starts_with' ? { $startsWith: value } : { $eq: value }
    const newFilter = { ...filter, option: v, value: newValue }
    onFilterChange(newFilter)
  }

  return (
    <>
      <Select
        onValueChange={onOptionChange}
        options={selectOptions}
        small
        value={option}
        width="100%"
      />
      <Input
        css={{ height: 32 }}
        onChange={e => onValueChange(e.currentTarget.value)}
        placeholder={t('value')}
        value={value}
      />
    </>
  )
}

export default UniqueFilterRow
