import { Chats } from '@phosphor-icons/react'
import IndicatorCard from '../indicators/IndicatorCard'
import { IndicatorCardProps } from './TopicDetails.types'
import useTopicFeedbackIndicators from '@/hooks/analytics/useTopicFeedbackIndicator'
import { useFiltersStore } from '@/store'
import TopicIndicatorCardSkeleton from './TopicIndicatorCardSkeleton'
import shallow from 'zustand/shallow'

const TopicFeedbackIndicator = ({ topicId, asExploreFeedbackItem = false }: IndicatorCardProps) => {
  const dateRange = useFiltersStore(state => state.dateRange, shallow)

  const { text, count, percentage, isLoading } = useTopicFeedbackIndicators({
    topicId,
    asExploreFeedbackItem
  })

  return (
    <IndicatorCard.Root alignItems="end" color="brandPrimaryPure">
      <IndicatorCard.Column>
        <IndicatorCard.Header>
          <IndicatorCard.IconBox>
            <Chats />
          </IndicatorCard.IconBox>
          <IndicatorCard.Title>Feedback</IndicatorCard.Title>
        </IndicatorCard.Header>

        {isLoading ? (
          <TopicIndicatorCardSkeleton.Number />
        ) : (
          <IndicatorCard.BigNumber>{count}</IndicatorCard.BigNumber>
        )}
      </IndicatorCard.Column>
      <IndicatorCard.Column>
        {!dateRange ? (
          <IndicatorCard.Fallback />
        ) : isLoading ? (
          <TopicIndicatorCardSkeleton.Text />
        ) : (
          <IndicatorCard.Paragraph css={{ mt: '$xxs' }}>
            <em>{percentage}</em> <span>{text}</span>
          </IndicatorCard.Paragraph>
        )}
      </IndicatorCard.Column>
    </IndicatorCard.Root>
  )
}

export default TopicFeedbackIndicator
