import { styled } from '@/theme'
import FlexContainer from '../flex-container'

export const ActionBar = styled(FlexContainer, {
  width: '100%',
  minWidth: 400,
  pAll: '$xxs $xs',
  boxSizing: 'border-box',

  position: 'fixed',
  bottom: 20,

  zIndex: 3,

  background: '$pajarito',
  color: '$white',
  borderRadius: 12,
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: '$componentShadowLarge',

  transition: '.2s',

  '& p': {
    display: 'flex',
    alignItems: 'center',
    gap: '$nano',
    cursor: 'pointer',
    borderRadius: 6,

    '&:hover': {
      color: '$neutralHighPure'
    }
  },

  variants: {
    expanded: {
      true: {
        maxWidth: 'calc(100vw - 172px)'
      },
      false: {
        maxWidth: 'calc(100vw - 540px)'
      }
    }
  }
})
