import { navigationBarWidth, styled } from '@/theme'

export const Bar = styled('aside', {
  position: 'fixed',
  left: 0,
  zIndex: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px 12px',
  boxSizing: 'border-box',
  bc: '$pajarito',

  width: navigationBarWidth,
  height: '100%',
  gap: 28,

  variants: {
    opportunityPlan: {
      true: {
        zIndex: 1,
        padding: '24px 16px 24px 16px',
        bc: '$brandPrimaryLight',
        bRight: '$brandPrimaryTransparentWeak'
      },
      false: {}
    }
  },

  defaultVariants: {
    opportunityPlan: false
  }
})

export const NavigationItems = styled('nav', {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  left: -1,
  gap: 28,
  variants: {
    opportunityPlan: {
      true: {
        justifyContent: 'space-between',
        height: '100%'
      }
    }
  }
})

export const NavigationLogoIcon = styled('img', {
  width: 37,
  height: 37
})
