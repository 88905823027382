import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { Intention, Reason } from '@/types/reasons'
import { intentionIcons, intentionTextColor } from '@/utils/intention'
import { IconContext } from '@phosphor-icons/react'
import { Fragment, useState } from 'react'
import ReasonItem from './ReasonItem'
import { ItemContainer } from './DiscoveryItem.styles'
import { Content, DiscoveryContainer, List, ListSeparetor } from './Discovery.styles'
import EmptyReasons from './EmptyReasons'
import ListSkeleton from '../analytics/skeletons/ListSkeleton'
import { MergeFindingProps } from './Discovery.types'
import Button from '@/components/atoms/button'
import KeywordService from '@/services/KeywordService'
import useToastMessageStore from '@/store/useToastMessageStore'
import useLogging from '@/hooks/useLogging'
import { useTranslation } from 'react-i18next'

const titleMap: Partial<Record<Intention, string>> = {
  compliment: 'Most relevant compliments',
  issue: 'Most relevant issues',
  request: 'Most relevant requests',
  question: 'Most relevant questions'
}

interface Props extends MergeFindingProps {
  data: Reason[]
  isLoading: boolean
  isRefetching?: boolean
  intention: Intention
  onClickSeeMore: () => void
  onClickReason: (value: Reason | null) => void
  refetch?: () => void
}

const DiscoveryItem = ({
  data,
  intention,
  isLoading,
  onClickSeeMore,
  onClickReason,
  showMergeFindingsCheckbox,
  isRefetching,
  refetch,
  onViewTexts
}: Props) => {
  const intentionColor = intentionTextColor[intention]
  const showSeeMore = data.length > 5 || false
  const reasons = showMergeFindingsCheckbox ? data : data.slice(0, 5)
  const { t } = useTranslation()

  const [checkedClusterIds, setCheckedClusterIds] = useState<string[]>([])

  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const { logException } = useLogging({ context: 'discovery-item' })

  const isChecked = (clusterId: string) => checkedClusterIds.includes(clusterId)

  const onCheckedChange = (clusterId: string) => (checked: boolean) => {
    setCheckedClusterIds(prevIds =>
      checked ? [...prevIds, clusterId] : prevIds.filter(id => id !== clusterId)
    )
  }

  const enableMergeButton = checkedClusterIds.length > 1

  const onClickMerge = async () => {
    const removeToast = addLoadingToast({
      text: `Merging findings from intention ${intention}. Please wait.`
    })
    try {
      await KeywordService.mergeCluster({
        cluster_type: 'reason',
        clusters_list: checkedClusterIds
      })
      setCheckedClusterIds([])

      setTimeout(() => {
        refetch?.()
        removeToast()
        addSuccessToast({ text: t('mergeFindingsCompletedRefetching') })
      }, 1000)
    } catch (error) {
      console.error(error)

      removeToast()
      const message = t('mergeFailed')
      logException(error, { message })
      addErrorToast({ text: message })
    }
  }

  return (
    <DiscoveryContainer key={intention}>
      <Content direction="column" gap="xs">
        {(isLoading || isRefetching) && (
          <FlexContainer
            alignItems="center"
            direction="column"
            fullHeight
            justifyContent="spaceAround"
          >
            <ListSkeleton count={1} gap={16} itemHeight={16} />
            <ListSkeleton count={5} gap={16} itemHeight={16} />
          </FlexContainer>
        )}
        {!isLoading && !isRefetching && !data.length && <EmptyReasons intention={intention} />}

        {!isLoading && !isRefetching && reasons.length > 0 && (
          <>
            <FlexContainer alignItems="center" gap="micro">
              <IconContext.Provider value={{ size: 20, weight: 'fill', color: intentionColor }}>
                {intentionIcons[intention]}
              </IconContext.Provider>
              <Text as="p" color="neutralLowPure" fontSize="xxs" fontWeight="bold">
                {titleMap[intention]}
              </Text>
              {showMergeFindingsCheckbox && enableMergeButton && (
                <Button css={{ ml: 'auto' }} onClick={onClickMerge} size="small">
                  {t('merge')}
                </Button>
              )}
            </FlexContainer>
            <List css={{ bAll: intentionColor }} direction="column" fullHeight>
              <FlexContainer
                css={
                  showMergeFindingsCheckbox ? { maxHeight: '30vh', overflow: 'auto' } : undefined
                }
                direction="column"
                fullHeight
              >
                {reasons.map(reason => (
                  <Fragment key={reason.id}>
                    <ReasonItem
                      isChecked={isChecked}
                      onCheckedChange={onCheckedChange(reason.clusterId)}
                      onClickReason={onClickReason}
                      onViewTexts={onViewTexts}
                      reason={reason}
                      showMergeFindingsCheckbox={showMergeFindingsCheckbox}
                    />
                    <ListSeparetor />
                  </Fragment>
                ))}
              </FlexContainer>
              {showSeeMore && (
                <ItemContainer onClick={onClickSeeMore} seeMore>
                  <Text
                    color="neutralLowPure"
                    css={{ width: '100%', flex: 1 }}
                    fontSize="xxxs"
                    fontWeight="semibold"
                    lineHeight="xs"
                    truncate
                  >
                    {t('seeMore')} {'>>'}
                  </Text>
                </ItemContainer>
              )}
            </List>
          </>
        )}
      </Content>
    </DiscoveryContainer>
  )
}

export default DiscoveryItem
