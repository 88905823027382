import FlexContainer from '@/components/atoms/flex-container'
import { Dispatch, SetStateAction, useMemo } from 'react'
import Text from '@/components/atoms/text'
import Input from '@/components/atoms/input'
import ToggleGroup from '@/components/atoms/toggle-group'
import * as NotificationSelect from '../../notification-select'
import SlackBannerV2 from '../SlackBannerV2'
import { useUserStore } from '@/store'
import { shallow } from 'zustand/shallow'
import Checkbox from '@/components/atoms/checkbox'
import { SlackChannel } from '@/types/notifications/Notifications'
import { NotificationSchedule } from '@/types/alerts/Alerts'
import { useTranslation } from 'react-i18next'
import useSlackIntegration from '@/hooks/useSlackIntegration'

interface Props {
  name: string
  setName: Dispatch<SetStateAction<string>>
  schedule: NotificationSchedule
  setSchedule: Dispatch<SetStateAction<NotificationSchedule>>
  emails: string[]
  setEmails: Dispatch<SetStateAction<string[]>>
  sendToEmail: boolean
  onChangeSendToEmail: (value: boolean) => void
  channels: string[]
  setChannels: Dispatch<SetStateAction<string[]>>
  sendToSlack: boolean
  onChangeSendToSlack: (value: boolean) => void
  isManager: boolean
  isOwner: boolean
  isSlackConnected?: boolean
  slackChannels?: SlackChannel[]
}

const NotificationForm = ({
  channels,
  emails,
  isManager,
  isOwner,
  name,
  onChangeSendToEmail,
  onChangeSendToSlack,
  schedule,
  sendToEmail,
  sendToSlack,
  setChannels,
  setEmails,
  setName,
  setSchedule,
  isSlackConnected,
  slackChannels
}: Props) => {
  const users = useUserStore(state => state.users, shallow)
  const { t } = useTranslation()
  const usersEmails = useMemo(() => {
    const filteredUsers = users.filter(user => !user.first_access && !user.is_blocked)

    return filteredUsers.map(user => user.email)
  }, [users])

  const onChangeEmail = (value: string[]) => {
    setEmails(
      value.filter(email => !!email.trim().match(/^[A-Za-z0-9_.\-\\+]+@[A-Za-z0-9._-]+$/gm))
    )
  }

  const { connectToSlack } = useSlackIntegration()

  return (
    <>
      <FlexContainer direction="column" gap="xxs">
        <Text fontSize="xxxs" fontWeight="regular">
          {t('name')}
        </Text>
        <Input
          disabled={!isManager || !isOwner}
          onChange={e => setName(e.currentTarget.value)}
          required
          value={name}
        />
      </FlexContainer>

      <FlexContainer direction="column" gap="xxs">
        <Text fontSize="xxxs" fontWeight="regular">
          {t('schedule')}
        </Text>
        <ToggleGroup
          disabled={!isManager || !isOwner}
          onValueChange={value => setSchedule(value as NotificationSchedule)}
          options={[
            { text: t('daily'), value: 'daily' },
            { text: t('weekly'), value: 'weekly' },
            { text: t('monthly'), value: 'monthly' }
          ]}
          value={schedule}
        />
      </FlexContainer>

      <NotificationSelect.Root gap="xxs">
        <NotificationSelect.Label>
          {isSlackConnected && isOwner ? (
            <Checkbox
              checked={sendToEmail}
              onCheckedChange={onChangeSendToEmail}
              text="E-mail:"
              value="send-to-email"
            />
          ) : (
            'E-mail:'
          )}
        </NotificationSelect.Label>
        <NotificationSelect.MultiSelect
          allowEmpty={isSlackConnected}
          creatable
          customCreateLabel={inputValue => {
            const isValid = !!inputValue.trim().match(/^[A-Za-z0-9_.\-\\+]+@[A-Za-z0-9._-]+$/gm)

            return isValid ? `Add ${inputValue}` : `"${inputValue}" is not a valid e-mail address.`
          }}
          disabled={!sendToEmail || !isManager || !isOwner}
          onChange={onChangeEmail}
          options={usersEmails}
          placeholder={t('selectEmails')}
          values={emails}
        />
      </NotificationSelect.Root>

      {isManager && !isSlackConnected && (
        <FlexContainer direction="column" gap="xxs">
          <Text fontSize="xxxs" fontWeight="regular">
            Slack
          </Text>
          <SlackBannerV2 />
        </FlexContainer>
      )}

      {isManager && isSlackConnected && (
        <FlexContainer direction="column" gap="xxs">
          <NotificationSelect.Root gap="xxs">
            <NotificationSelect.Label>
              {isOwner ? (
                <Checkbox
                  checked={sendToSlack}
                  onCheckedChange={onChangeSendToSlack}
                  text={t('emailSendAlertsToTheSelectedSlackChannels')}
                  value="send-to-slack"
                />
              ) : (
                t('emailSendAlertsToTheSelectedSlackChannels')
              )}
            </NotificationSelect.Label>
            <NotificationSelect.MultiSelect
              allowEmpty
              creatable
              customCreateLabel={() => t('addNewSlackChannel')}
              onCreate={connectToSlack}
              disabled={!sendToSlack || !isManager || !isOwner}
              onChange={setChannels}
              options={(slackChannels ?? []).map(channel => channel.name)}
              placeholder={t('selectChannels')}
              values={channels}
            />
          </NotificationSelect.Root>
        </FlexContainer>
      )}
    </>
  )
}

export default NotificationForm
