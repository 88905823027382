import { useNavigate } from 'react-router-dom'
import { shallow } from 'zustand/shallow'

import { CSS, styled } from '@/theme'
import archviedEmpty from '@/assets/drawings/archived-empty.png'
import NoResultsIllustration from '@/assets/drawings/no_results.png'
import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { useFiltersStore } from '@/store'
import { useTranslation } from 'react-i18next'

const NoResultsContainer = styled('div', {
  display: 'flex',
  gap: 12,
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 600,
  fontSize: 16,

  variants: {
    archived: {
      true: {
        flexDirection: 'column',
        flex: 1,
        boxSizing: 'border-box',
        overflow: 'auto',
        alignItems: 'center',
        gap: 0,
        pAll: '$xs $nano $xxs $xs'
      }
    }
  }
})

interface Props {
  archived?: boolean
  css?: CSS
  descriptionText?: string
}

const NoResults = ({ archived, css, descriptionText }: Props) => {
  const navigate = useNavigate()
  const search = useFiltersStore(state => state.search, shallow)
  const { t } = useTranslation()

  if (archived) {
    return (
      <NoResultsContainer archived css={css}>
        <img alt="" height={338} src={archviedEmpty} width={611} />

        {search.length > 0 ? null : (
          <Text as="h2" css={{ fontSize: '$md', lineHeight: '$md', fontWeight: '$bold' }}>
            Archived
          </Text>
        )}

        <Text css={{ fontSize: '$xxs', lineHeight: '$xxl', textAlign: 'center' }}>
          {search.length > 0
            ? 'No feedback found.'
            : "You don't have any archived feedback yet. When you archive any feedback, it will show up here."}
        </Text>

        {search.length > 0 ? null : (
          <Button
            css={{ marginTop: '$xs' }}
            onClick={() => navigate('/feedback')}
            type="button"
            variant="exception"
          >
            Go to feedback page
          </Button>
        )}
      </NoResultsContainer>
    )
  }

  return (
    <FlexContainer
      alignItems="center"
      css={{ marginTop: '$sm', ...css }}
      direction="column"
      gap="sm"
    >
      <img
        alt="A black and white image of a bird looking at empty boxes."
        height={221}
        src={NoResultsIllustration}
        width={352}
      />
      <FlexContainer alignItems="center" direction="column" gap="micro">
        <Text typeface="titleBoldXS">{t('noResultsFound')}</Text>
        <Text css={{ fontSize: '$xxs', lineHeight: '$lg', maxWidth: 495, textAlign: 'center' }}>
          {descriptionText ||
            `Make sure you've written your search correctly or review your filters and date selected.`}
        </Text>
      </FlexContainer>
    </FlexContainer>
  )
}

export default NoResults
