import useToastMessageStore from '@/store/useToastMessageStore'
import { useQuery } from '@tanstack/react-query'
import useLogging from '../useLogging'
import useAnalysisHubState from '@/store/useAnalysisHubStore'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import { useCallback, useMemo, useState } from 'react'
import useMetricListPayload from '../metrics/useMetricListPayload'
import getSegmentationsQueryFn from './getSegmentationsQueryFn'
import { useTranslation } from 'react-i18next'
import useSortingMetric from '../metrics/useSortingMetric'
import { SegmentationItemWithMetrics } from '@/types/segmentation/Segmentation'
import getMetricsForSegmentationsFn from './getMetricsForSegmentationsFn'
import { queryClient } from '@/plugins/reactQueryClient'
import useDidUpdateEffect from '../useDidUpdateEffect'
import useAdvancedFilters from '../advancedFilters/useAdvancedFilters'
import useAppliedDate from '../useAppliedDate'

const PAGE_SIZE = 10
const PROGRESS_STEP_SIZE = 100 / 3

const SEGMENTATIONS_KEY_PREFIX = 'segmentations'
const SEGMENTATIONS_SORTING_METRIC_KEY_PREFIX = 'sort-segmentations'
const SEGMENTATIONS_ALL_METRICS_KEY_PREFIX = 'get-all-metrics-for-segmentation'

const INVALID_METRIC_TABLE_COLUMNS = ['', 'name']

interface Params {
  enabled?: boolean
  sortColumn?: string
  sortDirection?: 'asc' | 'desc'
  areaId?: string
  opportunityId?: string
}

const defaultParams = {
  enabled: true,
  sortColumn: 'count',
  sortDirection: 'desc'
} satisfies Params

const useSegmentationsWithMetricsQuery = ({
  enabled = defaultParams.enabled,
  sortColumn = defaultParams.sortColumn,
  sortDirection = defaultParams.sortDirection,
  areaId,
  opportunityId
}: Params = defaultParams) => {
  const { t } = useTranslation()

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { logException } = useLogging({ context: 'segmentations-query' })

  const searchText = useAnalysisHubState(state => state.searchText)

  const progress = useAnalysisHubState(state => state.opportunitiesProgress)
  const setProgress = useAnalysisHubState(state => state.setOpportunitiesProgress)
  const loadStep = useAnalysisHubState(state => state.opportunitiesLoadStep)
  const setLoadStep = useAnalysisHubState(state => state.setOpportunitiesLoadStep)

  const allowedMetricsBySource = useHiddenMetricsStore(state => state.allowedMetricsBySource)

  const { filters: currentFilters } = useAdvancedFilters()
  const shouldMergeFilters = useMemo(
    () => Boolean(areaId || opportunityId),
    [areaId, opportunityId]
  )

  const { dateRange, datePeriod, startDate, endDate } = useAppliedDate()

  const { addShareFiltersToMetrics, getMetricList } = useMetricListPayload()

  const metricList = useMemo(() => {
    return addShareFiltersToMetrics(
      getMetricList({ includePreviousValue: false, metricFor: 'opportunity' })
    )
  }, [getMetricList, addShareFiltersToMetrics])

  const [currentPage, setCurrentPage] = useState(0)

  /**
   * Used only to list segmentations, reacts only to relevant changes for listing
   */
  const segmentationsQueryKey = useMemo(
    () => [SEGMENTATIONS_KEY_PREFIX, { searchText, areaId, opportunityId, currentFilters }],
    [searchText, areaId, opportunityId, currentFilters]
  )

  const { data: allSegmentationsData, isLoading: isLoadingAllSegmentations } = useQuery({
    queryKey: segmentationsQueryKey,
    queryFn: async () => {
      setLoadStep('loading')
      setProgress(2)

      const [error, data] = await getSegmentationsQueryFn({
        searchText,
        areaId,
        opportunityId
      })

      if (error) {
        logException(error, {
          message: 'Failed to fetch segmentation list',
          tags: { searchText }
        })
        addErrorToast({ text: t('fetchSegmentationListErrorMessage') })
        setLoadStep('done')
        setProgress(0)
        throw error
      }

      setProgress(PROGRESS_STEP_SIZE)

      return data
    },
    enabled
  })

  const allSegmentations = useMemo(() => {
    return allSegmentationsData ?? []
  }, [allSegmentationsData])

  const sortingMetric = useSortingMetric(sortColumn)

  /**
   * Used only to get the sorting metric data and create a new segmentation list with the sorting metric (not sorted yet)
   */
  const sortingSegmentationMetricQueryKey = useMemo(
    () => [
      SEGMENTATIONS_SORTING_METRIC_KEY_PREFIX,
      { allSegmentations, datePeriod, dateRange, sortingMetric }
    ],
    [allSegmentations, datePeriod, dateRange, sortingMetric]
  )

  const {
    data: allSegmentationsWithSortMetric,
    isLoading: isLoadingAllSegmentationsWithSortMetric
  } = useQuery({
    queryKey: sortingSegmentationMetricQueryKey,
    queryFn: async () => {
      if (!allSegmentations) return []

      setLoadStep('ordering')
      setCurrentPage(0)
      setProgress(PROGRESS_STEP_SIZE)

      const [error, data] = await getMetricsForSegmentationsFn({
        segmentations: allSegmentations,
        metricList: [sortingMetric],
        startDate,
        endDate,
        currentFilters: shouldMergeFilters ? currentFilters : undefined,
        opportunityId
      })

      if (error) {
        logException(error, {
          message: `Failed to fetch metric "${sortingMetric.name}/${sortingMetric.label}" to sort segmentations`,
          tags: {
            sortingMetricName: sortingMetric.name,
            sortingMetricLabel: sortingMetric.label,
            startDate: startDate ?? '',
            endDate: endDate ?? ''
          }
        })
        addErrorToast({ text: t('fetchSegmentationSortingMetricErrorMessage') })
        setLoadStep('done')
        setProgress(0)
        throw error
      }

      setProgress(PROGRESS_STEP_SIZE * 2)

      return data
    },
    enabled:
      enabled &&
      allSegmentations &&
      allSegmentations.length > 0 &&
      !INVALID_METRIC_TABLE_COLUMNS.includes(sortColumn ?? 'count')
  })

  const allSegmentationsSorted = useMemo(() => {
    let newSegmentations: SegmentationItemWithMetrics[] = []

    if (INVALID_METRIC_TABLE_COLUMNS.includes(sortColumn ?? 'count')) {
      if (!allSegmentationsWithSortMetric) return newSegmentations
      newSegmentations = [...allSegmentations]

      if (sortColumn === 'name') {
        newSegmentations.sort((a, b) =>
          sortDirection === 'desc' ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name)
        )
      }
    } else {
      if (!allSegmentationsWithSortMetric) return newSegmentations
      newSegmentations = [...allSegmentationsWithSortMetric]

      newSegmentations.sort((a, b) => {
        const valueA =
          a.metrics.find(metric => metric.label === sortingMetric.label)?.current_value ?? 0
        const valueB =
          b.metrics.find(metric => metric.label === sortingMetric.label)?.current_value ?? 0

        return sortDirection === 'desc' ? valueB - valueA : valueA - valueB
      })
    }

    return newSegmentations
  }, [sortDirection, sortColumn, sortingMetric, allSegmentationsWithSortMetric, allSegmentations])

  /*
   * Use the already sorted segmentation list to fetch its metric list, all the metrics, by currentPage (slices)
   *  Here we care only for the order of the segmentation list, so use only the array of segmentation ids to listen to changes
   */
  const segmentationsWithAllMetricsQueryKey = useMemo(() => {
    const key = [
      SEGMENTATIONS_ALL_METRICS_KEY_PREFIX,
      {
        currentPage,
        sortedSegmentationIds: allSegmentationsSorted.map(seg => seg.segmentationId),
        metricList,
        startDate,
        endDate
      }
    ]
    return key
  }, [currentPage, allSegmentationsSorted, metricList, startDate, endDate])

  const { data: segmentationsWithAllMetrics, isLoading: isLoadingSegmentationsWithAllMetrics } =
    useQuery({
      queryKey: segmentationsWithAllMetricsQueryKey,
      queryFn: async () => {
        if (!allSegmentationsSorted) return []
        if (allSegmentationsSorted.length === 0) return []

        setLoadStep('calculating')
        if (progress === 100) {
          setProgress(PROGRESS_STEP_SIZE)
        }

        const startPage = currentPage * PAGE_SIZE
        const endPage = (currentPage + 1) * PAGE_SIZE
        const currentSlice = allSegmentationsSorted.slice(startPage, endPage)

        const [error, fetchedSlice] = await getMetricsForSegmentationsFn({
          segmentations: currentSlice,
          metricList,
          onProgress: (chunkLength, completed) => {
            setProgress?.(PROGRESS_STEP_SIZE + (PROGRESS_STEP_SIZE / (chunkLength * 2)) * completed)
          },
          startDate,
          endDate,
          currentFilters: shouldMergeFilters ? currentFilters : undefined,
          opportunityId
        })

        if (error) {
          logException(error, {
            message: `Failed to fetch metrics for segmentations`,
            tags: {
              metricList: JSON.stringify(metricList.map(metric => metric.label)),
              startDate: startDate ?? '',
              endDate: endDate ?? ''
            }
          })
          addErrorToast({ text: t('fetchMetricsErrorMessage') })
          setLoadStep('done')
          setProgress(0)
          throw error
        }

        setProgress(100)
        setLoadStep('done')

        const newAllSegmentations = [...allSegmentationsSorted]
        // Substitute the current slice with the new, with all the page metrics
        newAllSegmentations.splice(startPage, PAGE_SIZE, ...fetchedSlice)

        // update the list with sorted metric, prevents the other metrics from blinking
        queryClient.setQueryData(sortingSegmentationMetricQueryKey, newAllSegmentations)

        return newAllSegmentations
      },
      enabled: enabled && allSegmentationsSorted?.length > 0
    })

  const loadNextPage = useCallback(() => {
    setCurrentPage(prevPage => prevPage + 1)
  }, [])

  const segmentations = useMemo(() => {
    let _segmentations: SegmentationItemWithMetrics[] = []

    if (allSegmentations && allSegmentations.length) {
      _segmentations = [...allSegmentations]
    }

    if (allSegmentationsSorted && allSegmentationsSorted.length) {
      _segmentations = [...allSegmentationsSorted]
    }

    if (segmentationsWithAllMetrics && segmentationsWithAllMetrics.length) {
      _segmentations = [...segmentationsWithAllMetrics]
    }

    return _segmentations.slice(0, (currentPage + 1) * PAGE_SIZE)
  }, [segmentationsWithAllMetrics, allSegmentations, allSegmentationsSorted, currentPage])

  const hasMore =
    segmentationsWithAllMetrics &&
    segmentationsWithAllMetrics.length > (currentPage + 1) * PAGE_SIZE

  const resetPage = useCallback(() => {
    setCurrentPage(0)
  }, [])

  useDidUpdateEffect(() => {
    resetPage()
  }, [allowedMetricsBySource])

  return {
    segmentations,
    isLoadingAllSegmentations,
    isLoadingAllSegmentationsWithSortMetric,
    isLoadingSegmentationsWithAllMetrics,
    isLoading:
      isLoadingAllSegmentations ||
      isLoadingAllSegmentationsWithSortMetric ||
      isLoadingSegmentationsWithAllMetrics,
    loadNextPage,
    hasMore,
    loadStep,
    progress,
    queryKey: segmentationsQueryKey,
    allSegmentations,
    allSegmentationsSorted,
    resetPage
  }
}

export default useSegmentationsWithMetricsQuery
