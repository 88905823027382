import Dialog from '@/components/atoms/dialog'
import { DialogTitleDefault } from '@/components/atoms/dialog/Dialog'
import FlexContainer from '@/components/atoms/flex-container'
import { InitiativeItem } from '@/types/initiatives/Initiatives'
import { useTranslation } from 'react-i18next'
import Illustration from '@/assets/drawings/bird.png'
import Text from '@/components/atoms/text'
import DatePicker from '@/components/atoms/date-picker'
import { useEffect, useState } from 'react'
import { DateValue } from '@internationalized/date'
import Button from '@/components/atoms/button'
import Divider from '@/components/atoms/divider'
import { useInitiatives } from '@/hooks/initiatives/useInitiatives'
import useOpportunityMutations from '@/hooks/opportunity/useOpportunityMutations'
import { OpportunityStatus } from '@/types/opportunity/Opportunity'
import useSegment from '@/hooks/useSegment'

interface Props {
  initiative: InitiativeItem
  open: boolean
  onOpenChange: (open: boolean) => void
}

const InitiativeDoneDialog = ({ initiative, open, onOpenChange }: Props) => {
  const { t } = useTranslation()
  const { track } = useSegment()

  const [date, setDate] = useState<DateValue | undefined>(undefined)

  useEffect(() => {
    if (!open) {
      setDate(undefined)
    }
  }, [open])

  const close = () => {
    onOpenChange(false)
    setDate(undefined)
  }

  const { updateInitiative } = useInitiatives({ enabled: false })
  const { updateOpportunity } = useOpportunityMutations()

  const onTrackClick = () => {
    if (!date) return

    const isoString = date.toString().slice(0, 10)
    // const parsedDate = new Date(isoString)
    updateInitiative({
      initiativeId: initiative.id,
      releaseDate: isoString,
      status: 'done'
    })

    initiative.opportunities.forEach(opp => {
      updateOpportunity({ id: opp, status: OpportunityStatus.Tracking })
    })
    track('initiative_done_release_date')

    close()
  }

  const isDisabled = !date

  return (
    <Dialog align="center" closeIcon onOpenChange={onOpenChange} open={open}>
      <FlexContainer direction="column" gap="xs">
        <DialogTitleDefault>{t('congratulations')}</DialogTitleDefault>

        <FlexContainer justifyContent="center" fullWidth>
          <img src={Illustration} alt="Bird singing" width={159} height={155} />
        </FlexContainer>
        <Text as="p" fontSize="xxxs">
          {t('initiativeReleaseEventAlert')}
          <br />
          {t('releaseDateRequest')}
        </Text>

        <DatePicker onChange={setDate} value={date} placeholder={t('releaseDate')} />

        <Text as="p" fontSize="xxxs">
          {t('trackInitiativeOpportunityStatusChangeReminder')}
        </Text>

        <Divider line="solid" />

        <Button disabled={isDisabled} fullWidth onClick={onTrackClick} size="small">
          {t('trackInitiativeButtonLabel')}
        </Button>
      </FlexContainer>
    </Dialog>
  )
}

export default InitiativeDoneDialog
