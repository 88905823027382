import { ThemeItemData, TopicItemData } from '@/types/classification/Theme'
import { TopicGroupResultItem } from './AllTopicSearchResults.types'

const defaultSentimentMetrics = {
  positiveCount: 0,
  negativeCount: 0,
  neutralCount: 0,
  positivePercentage: 0,
  negativePercentage: 0,
  neutralPercentage: 0,
  netSentiment: 0
}

const parseTopicResultToTheme = (result: TopicGroupResultItem) => {
  return {
    frequency: 0,
    sentimentMetrics: defaultSentimentMetrics,
    themeId: result.id,
    themeName: result.name,
    topics: result.childTopics.map(topic => ({
      frequency: topic.totalFrequency,
      sentimentMetrics: topic.sentiment || defaultSentimentMetrics,
      topicId: topic.topicId,
      topicName: topic.topicName,
      isMerged: topic.isMergeParent,
      mergedTopicList: topic.mergedTopics
    })) as TopicItemData[]
  } as ThemeItemData
}

export default parseTopicResultToTheme
