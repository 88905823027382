import useSegment from '@/hooks/useSegment'
import FlexContainer from '@/components/atoms/flex-container'
import DateFilter from '../../filters/DateFilter'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { shallow } from 'zustand/shallow'
import { camelToSnakeCase } from '@/utils/letterCase'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import { Period } from '@/types/periods'
import { CSS } from '@/theme'

const DateSelect = () => {
  const { track } = useSegment()

  const triggerCss: CSS = {
    color: '$neutralHighLight',
    backgroundColor: '$neutralLight',
    border: 'none',
    '&:hover': {
      color: '$neutralHighLight',
      backgroundColor: '$primaryLight'
    },
    padding: 0,
    marginTop: '2px'
  }

  const dateFilter = useDateFilterStore(state => state, shallow)
  const appliedDateRange = dateFilter.dateRange
  const appliedDatePeriod = dateFilter.datePeriod
  const applyDateFilter = dateFilter.setDateFilter

  function onOpen() {
    track('explore_user_date_range_filter-open')
  }

  function onApply() {
    track('explore_user_date_range_filter_apply')
  }

  function onChangePeriod(period: Period) {
    track('explore_user_date_range_filter-options', { data_type: camelToSnakeCase(period) })
  }

  function onSelectCustom() {
    track('explore_user_date_range_filter-options', { data_type: 'custom' })
  }

  function onSelectAllTime() {
    track('explore_user_date_range_filter-clear')
  }

  function onCalendarClose() {
    track('explore_user_date_range_filter-cancel')
  }

  const { dateRange } = useFeedFiltersStore(
    state => ({
      dateRange: state.dateRange
    }),
    shallow
  )

  return (
    <FlexContainer gap="micro">
      <DateFilter
        appliedPeriod={appliedDatePeriod}
        appliedRange={appliedDateRange}
        key={JSON.stringify(dateRange)}
        onApply={onApply}
        onCalendarClose={onCalendarClose}
        onChangePeriod={onChangePeriod}
        onOpen={onOpen}
        onSelectAllTime={onSelectAllTime}
        onSelectCustom={onSelectCustom}
        setDateRange={applyDateFilter}
        triggerCss={triggerCss}
      />
    </FlexContainer>
  )
}

export default DateSelect
