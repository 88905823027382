import { CheckboxState } from '@/components/atoms/checkbox'
import useFiltersStore, { useFeedFiltersStore } from '@/store/useFiltersStore'
import { useCallback, useMemo } from 'react'
import { shallow } from 'zustand/shallow'

interface Props {
  filterKey: string
  newFeed?: boolean
  initialValues?: string[]
}

const useAccountStringFilterSelection = ({ filterKey, newFeed = false }: Props) => {
  const useStore = newFeed ? useFeedFiltersStore : useFiltersStore

  const accountsStringFilters = useStore(state => state.accountsStringFilters, shallow)
  const checkAccount = useStore(state => state.checkAccount)
  const applyAccounts = useStore(state => state.applyAccounts)

  const storedFilter = useMemo(
    () => accountsStringFilters[filterKey],
    [accountsStringFilters, filterKey]
  )

  const selectedOptions: string[] = useMemo(
    () => storedFilter?.draftSelected ?? [],
    [storedFilter?.draftSelected]
  )
  const appliedOptions: string[] = useMemo(
    () => storedFilter?.selected ?? [],
    [storedFilter?.selected]
  )

  const hasChanges = useMemo(
    () => JSON.stringify(selectedOptions) !== JSON.stringify(appliedOptions),
    [selectedOptions, appliedOptions]
  )

  const isChecked = (option: string) => selectedOptions.includes(option)

  const selectOption = useCallback(
    (checked: CheckboxState, option: string) => {
      checkAccount({ checked: checked as boolean, filterName: filterKey, value: option })
    },
    [filterKey, checkAccount]
  )

  const apply = applyAccounts

  return {
    isChecked,
    selectOption,
    hasChanges,
    apply
  }
}

export default useAccountStringFilterSelection
