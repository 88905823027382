import { useState, useCallback, memo, useMemo, useEffect } from 'react'
import * as Tabs from '@radix-ui/react-tabs'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { shallow } from 'zustand/shallow'

import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import useHideIntercom from '@/hooks/useHideIntercom'
import useIntegrationsStore from '@/store/useIntegrationsStore'
import { ConnectorArgumentWithValue, IntegrationFormData } from '@/types/integrations'
import IntegrationDetails from '../IntegrationDetails'
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '../utils'
import { FormStepList, FormStepTrigger } from './IntegrationForm.styles'
import NotificationBanner from '@/components/atoms/notification-banner'
import ConfigureStep from './ConfigureStep'
import countryCodesMap from './country_codes.json'
import ReviewStep from './ReviewStep'
import useUser from '@/hooks/useUser'
import NoPermission from '../../no-permission/NoPermission'
import { useTranslation } from 'react-i18next'

const FORM_STEPS = ['configure', 'review', 'done'] as const
export type FormStep = (typeof FORM_STEPS)[number]

const IntegrationForm = () => {
  const { key, pathname } = useLocation()
  const params = useParams()
  const { t } = useTranslation()

  const connectorArguments = useIntegrationsStore(state => state.connectors.fields, shallow)
  const connectors = useIntegrationsStore(state => state.connectors.data)
  const integrations = useIntegrationsStore(state => state.integrations)

  const handleIntercom = useHideIntercom()

  const [integrationId, setIntegrationId] = useState<string | undefined>(params.integrationId)

  const connector = connectors.find(connector => connector.id === params.connectorId)
  const integration = integrations.find(integration => integration.id === integrationId)

  const defaultFormData: IntegrationFormData = {
    connectorId: connector?.id,
    integrationName: '',
    schedule: '0 5 * * *',
    fields: {}
  }

  const [currentStep, setCurrentStep] = useState<FormStep>(FORM_STEPS[0])
  const [formData, setFormData] = useState(defaultFormData)
  const [formError, setFormError] = useState<string>()
  const [integrationSample, setIntegrationSample] = useState<Record<string, unknown>>({})

  const isEdit = pathname.includes('/edit')

  const connectorFields = useMemo(() => {
    if (!connector?.id) return {}
    return connectorArguments[connector.id] ?? {}
  }, [connector?.id, connectorArguments])

  const fieldsConfigureStep = useMemo(() => {
    return Object.fromEntries(
      Object.entries(connectorFields).filter(([, value]) => !['date'].includes(value.type))
    )
  }, [connectorFields])

  const fieldsReviewStep = useMemo(() => {
    return Object.fromEntries(
      Object.entries(connectorFields).filter(([, value]) => ['date'].includes(value.type))
    )
  }, [connectorFields])

  useEffect(() => {
    handleIntercom(Boolean(connector?.id))
    return () => handleIntercom(false)
  }, [connector?.id, handleIntercom])

  // biome-ignore lint/correctness/useExhaustiveDependencies: it should react to key changes
  useEffect(() => {
    setCurrentStep('configure')
  }, [key])

  // biome-ignore lint/correctness/useExhaustiveDependencies: it should react to connectorArguments too
  useEffect(() => {
    if (isEdit && integration) {
      const fields: Record<string, ConnectorArgumentWithValue> = {}
      for (const key in integration.arguments) {
        if (connectorFields && connectorFields[key]) {
          fields[key] = { ...connectorFields[key], value: integration.arguments[key] }
        }
      }

      setFormData({
        integrationName: integration.name || '',
        schedule: integration.schedule?.cron || '',
        connectorId: integration.connector.id,
        fields
      })
    } else {
      // Set default value for empty date fields
      const initialFields = Object.fromEntries(
        Object.entries(connectorFields).map(([key, field]) => {
          if (field.type === 'array' && key === 'countries')
            return [key, { ...field, value: ['United States'] }]

          if (field.type === 'date' && key === 'end_date')
            return [key, { ...field, value: DEFAULT_END_DATE }]

          if (field.type === 'date') return [key, { ...field, value: DEFAULT_START_DATE }]

          return [key, field]
        })
      )

      setFormData(prev => ({ ...prev, fields: initialFields }))
    }
  }, [connectorFields, connectorArguments, integration, isEdit])

  useEffect(() => {
    if (!['app_url', 'countries'].every(key => Object.keys(formData.fields).includes(key))) return

    const appUrl = formData.fields.app_url?.value
    if (!appUrl) return

    function getCountryFromAppUrl(url: string) {
      const getAppStoreCountryCode = (url: string) =>
        /apps.apple.com\/(\w{2,})\/app\//.exec(url)?.[1]
      const getPlayStoreCountryCode = (url: string) =>
        /play.google.com\/store\/apps\/details.*gl=(\w{2,})/.exec(url)?.[1]

      const countryCode = getAppStoreCountryCode(url) || getPlayStoreCountryCode(url)
      if (!countryCode) return undefined
      return countryCodesMap[countryCode.toLowerCase() as keyof typeof countryCodesMap]
    }

    const country = getCountryFromAppUrl(appUrl as string)
    if (!country) return

    handleFieldsChange('countries', { ...formData.fields.countries, value: [country] })
  }, [formData.fields])

  const handleNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({ ...prev, integrationName: event.target.value }))
  }, [])

  const handleScheduleChange = useCallback((value: string) => {
    setFormData(prev => ({ ...prev, schedule: value }))
  }, [])

  const handleFieldsChange = useCallback(
    (key: string, field: ConnectorArgumentWithValue) => {
      if (formError) setFormError(undefined)
      setFormData(prev => ({ ...prev, fields: { ...prev.fields, [key]: field } }))
    },
    [formError]
  )

  const disableSubmit = useMemo(() => {
    if (formError) return true
    if (!formData.integrationName || !formData.schedule) return true

    const hasEmptyRequiredFields = Object.keys(formData.fields).some(fieldKey => {
      const field = formData.fields[fieldKey]
      const isEmpty = Array.isArray(field.value) ? field.value.length === 0 : !field.value

      if (isEdit && field.type === 'secret' && isEmpty) return false
      return field.required && isEmpty
    })

    return hasEmptyRequiredFields
  }, [formData, formError, isEdit])

  function onChangeTab(value: string) {
    setCurrentStep(value as FormStep)
  }

  function getStepStatus(step: FormStep) {
    if (currentStep === 'done') return 'done'
    if (FORM_STEPS.indexOf(currentStep) > FORM_STEPS.indexOf(step)) return 'done'
    if (currentStep === step) return 'current'
    return 'next'
  }

  const { userPermissions, currentUser } = useUser()
  const isManager = userPermissions.source.includes('manager')

  if (!isManager && currentUser) {
    return <NoPermission />
  }

  if (!connector) return <Navigate replace to="/settings/integrations" />

  return (
    <Tabs.Root onValueChange={onChangeTab} value={currentStep}>
      <FormStepList>
        {FORM_STEPS.map(step => (
          <FormStepTrigger disabled key={step} status={getStepStatus(step)} value={step} />
        ))}
      </FormStepList>

      <Tabs.Content value="configure">
        <ConfigureStep
          connector={connector}
          connectorFields={fieldsConfigureStep}
          disableSubmit={disableSubmit}
          formData={formData}
          formError={formError}
          handleFieldsChange={handleFieldsChange}
          handleNameChange={handleNameChange}
          setCurrentStep={setCurrentStep}
          setFormError={setFormError}
          setIntegrationSample={setIntegrationSample}
        />
      </Tabs.Content>

      <Tabs.Content value="review">
        <ReviewStep
          connector={connector}
          connectorFields={fieldsReviewStep}
          disableSubmit={disableSubmit}
          formData={formData}
          handleFieldsChange={handleFieldsChange}
          handleScheduleChange={handleScheduleChange}
          integration={integration}
          integrationSample={integrationSample}
          isEdit={isEdit}
          setCurrentStep={setCurrentStep}
          setIntegrationId={setIntegrationId}
        />
      </Tabs.Content>

      <Tabs.Content asChild value="done">
        <FlexContainer css={{ gap: 24, maxWidth: 800 }} direction="column">
          <Text as="h1" className="form-title" typeface="titleBoldXS">
            {t('3integrationDone')}
          </Text>
          {isEdit ? (
            <NotificationBanner
              description={t('butYouCanManuallyRunTheIntegrationNowClickingOnThe3DotsMenu')}
              status="warning"
              title={t('forNowChangesDontAffectCurrentData')}
            />
          ) : null}
          <IntegrationDetails integrationId={integrationId} />
        </FlexContainer>
      </Tabs.Content>
    </Tabs.Root>
  )
}

export default memo(IntegrationForm)
