import FeedbackService from '@/services/FeedbackService'
import { useQuery } from '@tanstack/react-query'

interface Props {
  name: string
  enabled?: boolean
}

const useDatetimeFiltersQuery = ({ name, enabled = true }: Props) => {
  const queryFunction = async () => {
    const [error, response] = await FeedbackService.getFeedbackFieldValues({
      type: 'datetime',
      fieldName: name
    })

    if (error) throw error

    return response
  }

  const query = useQuery({
    queryKey: ['feed-filters', 'datetime', name],
    queryFn: queryFunction,
    enabled
  })

  return query
}

export default useDatetimeFiltersQuery
