import { PageLink } from '@/pages/sources/styles'
import { CSS, styled, typefaceVariants } from '@/theme'

const buttonStyle: CSS = {
  all: 'unset',
  ...typefaceVariants.subtitleRegularXXXS,
  borderRadius: 8,
  boxSizing: 'border-box',
  color: '$brandPrimaryPure',
  padding: '8px 16px',
  textDecoration: 'none',
  width: 200,
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',

  '&:hover': {
    backgroundColor: '$brandPrimaryLight'
  }
}

const activeStyle: CSS = {
  ...typefaceVariants.subtitleSemiBoldXXXS,
  backgroundColor: '$brandPrimaryLight'
}

export const NavigationButton = styled('button', {
  ...buttonStyle,

  variants: {
    active: {
      true: activeStyle
    }
  }
})

export const NavigationButtonLink = styled(PageLink, {
  ...buttonStyle,
  '&.active': activeStyle,

  variants: {
    opportunityPlan: {
      true: {
        color: '$brandPrimaryPure !important',

        '&.active, &:hover': {
          br: '$micro',
          bc: '$brandPrimaryHover'
        }
      }
    }
  }
})
