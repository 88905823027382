import { ChipContainer } from './KeywordChip.styles'
import { CSS } from '@/theme'

export interface KeywordChipProps {
  label: string
  id: string
  disabled?: boolean
  css?: CSS
  alwaysShowHandle?: boolean
  isCluster?: boolean
  isClusterOpen?: boolean
  onClick?: React.MouseEventHandler<HTMLSpanElement>
}

const KeywordChip: React.FC<KeywordChipProps> = (props: KeywordChipProps) => {
  return (
    <ChipContainer
      alwaysShowHandle={props.alwaysShowHandle}
      css={{ ...props.css }}
      isCluster={props.isCluster}
      isClusterClickable={!!props.onClick && props.isCluster}
      onClick={props.onClick}
      title={props.label}
    >
      {props.label}
    </ChipContainer>
  )
}

KeywordChip.defaultProps = {
  disabled: false
}

export default KeywordChip
