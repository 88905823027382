import useStringFilterSelection from '@/hooks/filters/useStringFilterSelection'
import { SENTIMENT_KEY } from '@/utils/filters'

const filterKey = SENTIMENT_KEY

interface Params {
  newFeed?: boolean
}

const defaultParams: Params = {
  newFeed: false
}

const useSentimentSelection = ({ newFeed = false }: Params = defaultParams) => {
  const { isChecked, selectOption, hasChanges, apply, appliedOptions } = useStringFilterSelection({
    filterKey,
    name: 'sentiment',
    type: 'string',
    newFeed
  })

  return {
    isChecked,
    selectOption,
    hasChanges,
    appliedOptions,
    apply
  }
}

export default useSentimentSelection
