import useStringFilterSelection from '@/hooks/filters/useStringFilterSelection'
import { FEEDBACK_INTENTION_KEY } from '@/utils/filters'

const useFeedbackIntentionSelection = () => {
  const { selectOption, isChecked, hasChanges, apply, appliedOptions } = useStringFilterSelection({
    name: 'feedback_keyword_classes',
    filterKey: FEEDBACK_INTENTION_KEY,
    type: 'string'
  })

  return {
    selectOption,
    isChecked,
    hasChanges,
    appliedOptions,
    apply
  }
}

export default useFeedbackIntentionSelection
