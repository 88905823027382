import Button from '@/components/atoms/button'
import Checkbox from '@/components/atoms/checkbox'
import FlexContainer from '@/components/atoms/flex-container'
import { AllTopicSearchResult, TopicGroupResultItem } from './AllTopicSearchResults.types'
import FiltersSkeleton from '../../AddFilterMenu/FiltersSkeleton'
import TopicSearchResultList from './TopicSearchResultList'
import useUngroupedTopicsFilters from '../hooks/useUngroupedTopicsFilters'
import { useFiltersStore } from '@/store'
import { TopicCategory } from '@/types/classification'
import { CheckedState } from '@radix-ui/react-checkbox'
import { useTranslation } from 'react-i18next'

export interface FullTopicSearchResultItemProps {
  result: AllTopicSearchResult
  hasNextPage: boolean | undefined
  isFetchingNextPage: boolean
  fetchNextPage: () => void
  isLoading: boolean
  isShowMoreDisabled: boolean
}

const FullTopicSearchResultItem = ({
  result,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  isShowMoreDisabled,
  fetchNextPage
}: FullTopicSearchResultItemProps) => {
  const { tempTopics } = useFiltersStore(state => ({
    tempTopics: [...state.productAreaTopics.draftSelected, ...state.otherTopics.draftSelected]
  }))

  const selectTopics = useFiltersStore(state => state.selectTopics)
  const unselectTopics = useFiltersStore(state => state.unselectTopics)

  const { t } = useTranslation()

  const productAreaGroups = result.topicGroups.filter(group => group.category === 'PRODUCT_AREA')
  const otherTopicGroups = result.topicGroups.filter(group => group.category === 'OTHER')

  const {
    isTopicChecked: isUngroupedTopicChecked,
    onCheckUngroupedTopic,
    onCheckUngroupedTopics
  } = useUngroupedTopicsFilters({
    searchQuery: '',
    enabled: false
  })

  const isTopicChecked = (topicId: string) => tempTopics.includes(topicId)

  const isCategoryChecked = (result: TopicGroupResultItem[]): CheckedState => {
    const allCategoryTopics = result.flatMap(item => item.childTopics)
    const checkedTopics = allCategoryTopics.filter(topic => isTopicChecked(topic.topicId))

    if (checkedTopics.length > 0 && checkedTopics.length < allCategoryTopics.length) {
      return 'indeterminate'
    }
    return checkedTopics.length === allCategoryTopics.length
  }

  const onCheckCategoryChange =
    (result: TopicGroupResultItem[], category: TopicCategory) => (checked: CheckedState) => {
      const topicIds = result.flatMap(group => group.childTopics.map(topic => topic.topicId))

      if (checked === 'indeterminate' || checked === false) {
        unselectTopics({
          topicIds: [...new Set(topicIds)],
          topicCategory: category
        })
        return
      }

      selectTopics({
        topicIds: [...new Set(topicIds)],
        topicCategory: category
      })
    }

  const onCheckGroupChange =
    (groupId: string, category: TopicCategory) => (checked: CheckedState) => {
      const group =
        category === 'PRODUCT_AREA'
          ? productAreaGroups.find(group => group.id === groupId)
          : otherTopicGroups.find(group => group.id === groupId)

      if (!group) return false

      const topicIds = group.childTopics.map(topic => topic.topicId)
      if (checked === 'indeterminate' || checked === false) {
        unselectTopics({
          topicIds,
          topicCategory: category
        })
        return
      }

      selectTopics({
        topicIds,
        topicCategory: category
      })
    }

  const isGroupChecked = (groupId: string, category: TopicCategory): CheckedState => {
    const group =
      category === 'PRODUCT_AREA'
        ? productAreaGroups.find(group => group.id === groupId)
        : otherTopicGroups.find(group => group.id === groupId)

    if (!group) return false

    const checkedTopics = group.childTopics.filter(topic => isTopicChecked(topic.topicId))

    if (checkedTopics.length > 0 && checkedTopics.length < group.childTopics.length) {
      return 'indeterminate'
    }
    return checkedTopics.length === group.childTopics.length
  }

  const onTopicCheck = (checked: CheckedState, topicId: string, category: TopicCategory) => {
    if (checked) {
      selectTopics({ topicIds: [topicId], topicCategory: category })
    } else {
      unselectTopics({ topicIds: [topicId], topicCategory: category })
    }
  }

  const isUngroupedResultChecked = (): CheckedState => {
    const checkedTopics = result.ungroupedTopics.filter(topic =>
      isUngroupedTopicChecked(topic.topicId)
    )

    if (checkedTopics.length > 0 && checkedTopics.length < result.ungroupedTopics.length) {
      return 'indeterminate'
    }
    return checkedTopics.length === result.ungroupedTopics.length
  }

  const onCheckUngrouped = (checked: CheckedState) => {
    const topics = result.ungroupedTopics.map(topic => ({
      id: topic.topicId,
      text: topic.topicName
    }))
    if (checked === 'indeterminate' || checked === false) {
      onCheckUngroupedTopics(false, topics)
      return
    }
    onCheckUngroupedTopics(true, topics)
  }

  return (
    <FlexContainer css={{ maxHeight: 320, overflowY: 'auto' }} direction="column" gap={'xxxs'}>
      {isLoading && <FiltersSkeleton />}

      <TopicSearchResultList
        handlers={{
          isTopicChecked,
          getThemeCheckedState: theme => isGroupChecked(theme.themeId || '', 'PRODUCT_AREA'),
          onThemeCheck: theme => onCheckGroupChange(theme.themeId || '', 'PRODUCT_AREA'),
          onTopicCheck: topicId => checkedState =>
            onTopicCheck(checkedState, topicId, 'PRODUCT_AREA')
        }}
        isCategoryChecked={isCategoryChecked(productAreaGroups)}
        items={productAreaGroups}
        onCheckCategoryChange={onCheckCategoryChange(productAreaGroups, 'PRODUCT_AREA')}
        title={t('productAreas')}
      />

      <TopicSearchResultList
        handlers={{
          isTopicChecked,
          getThemeCheckedState: theme => isGroupChecked(theme.themeId || '', 'OTHER'),
          onThemeCheck: theme => onCheckGroupChange(theme.themeId || '', 'OTHER'),
          onTopicCheck: topicId => checkedState => onTopicCheck(checkedState, topicId, 'OTHER')
        }}
        isCategoryChecked={isCategoryChecked(otherTopicGroups)}
        items={otherTopicGroups}
        onCheckCategoryChange={onCheckCategoryChange(otherTopicGroups, 'OTHER')}
        title={t('otherTopics')}
      />

      {result.ungroupedTopics.length > 0 && (
        <>
          <Checkbox
            checked={isUngroupedResultChecked()}
            onCheckedChange={onCheckUngrouped}
            onClick={e => {
              e.stopPropagation()
            }}
            text={t('ungroupedTopics')}
            value={'filter-' + 'ungrouped'}
          />
          <FlexContainer css={{ ml: '$xs' }} direction="column" gap={'xxxs'}>
            {result.ungroupedTopics.map(topic => (
              <Checkbox
                checked={isUngroupedTopicChecked(topic.topicId)}
                disabled={false}
                key={topic.topicId}
                onCheckedChange={checkedState =>
                  onCheckUngroupedTopic(checkedState as boolean, topic)
                }
                text={topic.topicName}
                value={'filter-' + topic.topicId}
              />
            ))}
          </FlexContainer>
        </>
      )}

      {isFetchingNextPage && <FiltersSkeleton />}
      {hasNextPage && (
        <Button
          disabled={isShowMoreDisabled}
          onClick={() => fetchNextPage()}
          size="micro"
          variant="link"
        >
          {t('showMore')}
        </Button>
      )}
    </FlexContainer>
  )
}

export default FullTopicSearchResultItem
