import FlexContainer from '@/components/atoms/flex-container'
import VolumeBar from '@/components/atoms/volume-bar'
import { styled, CSS } from '@/theme'

const Root = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '$xs $xxs',
  borderRadius: 8,
  color: '$neutralLowPure',
  bAll: '$neutralHighPure'
})

interface ProgressBarProps {
  value: number
  maxValue: number
  css?: CSS
}
const ProgressBar = ({ value, maxValue, css }: ProgressBarProps) => {
  return (
    <VolumeBar
      clickable={false}
      containerCss={{ background: '$neutralHighPure', borderRadius: 8 }}
      css={{ background: '$neutralLowMedium', mr: '$micro', ...css }}
      fullRounded
      height="small"
      max={maxValue}
      showNumber={false}
      value={value}
    />
  )
}

const Chip = styled('span', {
  borderRadius: 8,
  padding: '$nano $micro',
  display: 'flex',
  alignItems: 'center',
  gap: '$nano',
  fontSize: '$xxxs',

  variants: {
    tinted: {
      true: {
        background: '$brandPrimaryLight',
        color: '$brandPrimaryPure'
      },
      false: {
        background: '$neutralHighMedium',
        color: '$neutralLowMedium'
      }
    },
    clickable: {
      true: {
        cursor: 'pointer'
      }
    }
  },

  defaultVariants: {
    tinted: false
  }
})

const RelevanceContainer = styled(FlexContainer, {
  width: 180,
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$micro',

  '& svg': {
    minWidth: 16,
    minHeight: 16
  }
})

const RootCauseItemComponents = {
  Root,
  ProgressBar,
  Chip,
  RelevanceContainer
}

export default RootCauseItemComponents
