import Button from '@/components/atoms/button'
import { Plus } from '@phosphor-icons/react'
import { useState } from 'react'
import FlexContainer from '@/components/atoms/flex-container'
import { CSS, styled } from '@/theme'
import { TopicCategory, TopicThemeItem } from '@/types/classification'
import { useTopics } from '@/hooks/useTopics/useTopics'
import useTopicsStore from '@/store/useTopicsStore'
import NameInput from '@/components/atoms/input/NameInput'
import useUser from '@/hooks/useUser'

const AddThemeContainer = styled(FlexContainer, {
  padding: '$micro',
  position: 'relative',
  '& > button': {
    color: '$neutralLowPure'
  }
})

interface Props {
  onAddStatusChange?: (isAdding: boolean) => void
  onAdd?: (value: TopicThemeItem) => void
  withoutButtons?: boolean
  topicCategory?: TopicCategory
  css?: CSS
  inputCSS?: CSS
  buttonCSS?: CSS
}

const AddTopicGroup = ({
  withoutButtons = false,
  onAddStatusChange,
  onAdd,
  topicCategory,
  css,
  inputCSS,
  buttonCSS
}: Props) => {
  const { userPermissions } = useUser()
  const { addTopicGroup } = useTopics()
  const isLoadingTopicGroups = useTopicsStore(state => state.isLoadingTopicGroups)
  const [isAddingTheme, setIsAddingTheme] = useState(false)

  function onAddClick() {
    setIsAddingTheme(true)
    onAddStatusChange?.(true)
  }

  function onAddCancel() {
    setIsAddingTheme(false)
    onAddStatusChange?.(false)
  }

  async function onAddConfirm(value: string) {
    setIsAddingTheme(false)
    onAddStatusChange?.(false)

    const topicGroup = await addTopicGroup(value, topicCategory)
    if (topicGroup) {
      onAdd?.(topicGroup)
    }
  }

  const hasPermission = userPermissions.topic.includes('manager')
  if (isLoadingTopicGroups || !hasPermission) return <></>

  return (
    <AddThemeContainer css={css}>
      {isAddingTheme ? (
        <NameInput
          css={{ mr: '$xxs', ...inputCSS }}
          onCancel={onAddCancel}
          onConfirm={onAddConfirm}
          placeholder="Type topic group name"
          withoutButtons={withoutButtons}
        />
      ) : (
        <Button css={buttonCSS} micro onClick={onAddClick} text>
          <Plus />
          <span>Add topic group</span>
        </Button>
      )}
    </AddThemeContainer>
  )
}

export default AddTopicGroup
