import { useCallback, useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import TopicListWrapper from './TopicListWrapper'
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'
import { useFeedbackStore, useFiltersStore } from '@/store'
import { CheckedState } from '@radix-ui/react-checkbox'
import { shallow } from 'zustand/shallow'
import ErrorBoundary from '@/components/atoms/error-boundary'
import TopicRow from './TopicRow'
import ScrollLoader from '../ScrollLoader'
import NoResults from '../NoResults'
import ReasonsArea from '../../reasons-topics/ReasonsArea'
import FeedError from '../FeedError'
import useSelectedFilters from '@/hooks/filters/useSelectedFilters'
import { useTopics } from '@/hooks/useTopics/useTopics'
import useTopicsStore from '@/store/useTopicsStore'
import { TopicData } from '@/types/classification/Topic'
import { DeleteDialog } from '@/components/atoms/dialog'
import useDebounce from '@/hooks/useDebounce'

function TopicList() {
  const resetFeedbacks = useFeedbackStore(state => state.reset)
  const { load, topics, isLoading, maxFrequency, hasMore, error, removeTopic } = useTopics()

  const filters = useFiltersStore(
    state => ({
      dateRange: state.dateRange,
      orderBy: state.orderBy,
      sortDirection: state.sortDirection,
      volumeBy: state.volumeBy,
      search: state.search,
      filtersByURL: state.filtersByURL,
      accounts: Object.values(state.accountsStringFilters).flatMap(value => value.selected),
      accountsDateFilters: state.accountsDateFilters.filter(filter => filter.selected !== null),
      accountsNumericFilters: state.accountNumericFilters
        .filter(filter => filter.option !== 'all')
        .map(filter => ({ key: filter.key, value: filter.value, option: filter.option })),
      accountsBooleanFilters: state.accountBooleanFilters.filter(filter => filter.value !== null),
      users: Object.values(state.usersStringFilters).flatMap(value => value.selected),
      usersDateFilters: state.usersDateFilters.filter(filter => filter.selected !== null),
      usersNumericFilters: state.usersNumericFilters
        .filter(filter => filter.option !== 'all')
        .map(filter => ({ key: filter.key, value: filter.value, option: filter.option })),
      usersBooleanFilters: state.usersBooleanFilters.filter(filter => filter.value !== null)
    }),
    shallow
  )

  const { selectedNumericFilters, selectedDatetimeFilters, selectedStringFilters } =
    useSelectedFilters()

  const {
    checkedTopics,
    selectedTopicGroups,
    isDebounceLoading,
    isUngrouped,
    topicSortType,
    topicSortDirection
  } = useTopicsStore(
    state => ({
      checkedTopics: state.checkedTopics,
      selectedTopicGroups: state.selectedTopicGroups,
      isDebounceLoading: state.isDebounceLoading,
      isUngrouped: state.isUngrouped,
      topicSortDirection: state.topicSortDirection,
      topicSortType: state.topicSortType
    }),
    shallow
  )

  const debouncedSelectedTopicGroup = useDebounce(selectedTopicGroups, 600)

  const setCheckedTopics = useTopicsStore(state => state.setCheckedTopics)
  const setTopicsToReasons = useTopicsStore(state => state.setTopicsToReasons)

  const [currentView, setCurrentView] = useState<{
    topic: TopicData | null
    modal:
      | 'related-keyword'
      | 'feedbacks'
      | 'none'
      | 'reasons'
      | 'edit-topic'
      | 'delete-topic'
      | 'topic'
  }>({ topic: null, modal: 'none' })

  const checkedAll = useMemo((): CheckedState => {
    if (isLoading || !topics.length) {
      return false
    }

    if (checkedTopics.length === topics.length) {
      return true
    } else if (checkedTopics.length > 0) {
      return 'indeterminate'
    } else {
      return false
    }
  }, [checkedTopics, topics, isLoading])

  const handleCheckAll = (checked: CheckedState) => {
    if (checked) {
      setCheckedTopics(topics)
    } else {
      setCheckedTopics([])
    }
  }

  const handleCheckCluster = useCallback(
    (topic: TopicData, checked: CheckedState) => {
      if (checked) {
        setCheckedTopics([...checkedTopics, topic])
      } else {
        setCheckedTopics(
          checkedTopics.filter(checkedTopic => checkedTopic.topicId !== topic.topicId)
        )
      }
    },
    [setCheckedTopics, checkedTopics]
  )

  const isTopicChecked = useCallback(
    (topic: TopicData): CheckedState => {
      return !!checkedTopics.find(checkedTopic => checkedTopic.topicId === topic.topicId)
    },
    [checkedTopics]
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    load()

    resetFeedbacks()
  }, [])

  const reload = () => {
    load()
    setTopicsToReasons([])
  }

  useDidUpdateEffect(
    reload,
    [
      filters,
      selectedNumericFilters,
      selectedDatetimeFilters,
      selectedStringFilters,
      debouncedSelectedTopicGroup,
      isUngrouped,
      topicSortDirection,
      topicSortType
    ],
    true
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (currentView.modal === 'topic') {
      const updatedTopic = topics.find(topic => topic.topicId === currentView.topic?.topicId)
      if (updatedTopic) {
        setCurrentView({ topic: updatedTopic, modal: 'topic' })
      }
    }
  }, [topics])

  const loadMore = () => {
    topics.length && load()
  }

  const onReload = () => {
    load()
  }

  const loading = isLoading || isDebounceLoading

  if (error && topics.length === 0) {
    return <FeedError onReload={onReload} />
  }

  if (!loading && topics.length === 0) {
    return <NoResults />
  }

  return (
    <ErrorBoundary>
      <TopicListWrapper checkedAll={checkedAll} onCheckedAllChange={handleCheckAll}>
        <InfiniteScroll
          dataLength={topics.length}
          endMessage={<div />}
          hasMore={hasMore}
          loader={''}
          next={loadMore}
          scrollThreshold="250px"
          scrollableTarget="list-root-keyword"
        >
          {topics.map(topic => (
            <TopicRow
              checked={isTopicChecked(topic)}
              key={topic.topicId}
              max={maxFrequency}
              onCheckedChange={checked => handleCheckCluster(topic, checked)}
              topic={topic}
            />
          ))}
          {loading && <ScrollLoader />}
        </InfiniteScroll>
      </TopicListWrapper>

      <ReasonsArea onClose={() => setTopicsToReasons([])} />

      {currentView.topic !== null && currentView.modal === 'delete-topic' && (
        <DeleteDialog
          confirmText="Delete"
          description="This action is definitive. Deleting will permanently dissociate the topic with their feedback. Please proceed with caution."
          isDeleting={false}
          modal
          onConfirmDelete={() => removeTopic(currentView.topic as TopicData)}
          onOpenChange={open => setCurrentView(open ? currentView : { topic: null, modal: 'none' })}
          open={!!currentView.topic}
          title="Delete topic?"
        />
      )}
    </ErrorBoundary>
  )
}

export default TopicList
