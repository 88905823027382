import Card from '../card'
import { Users } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'
import SegmentationsTable from './SegmentationsTable'
import useOpportunityStore from '@/store/useOpportunityStore'
import FlexContainer from '@/components/atoms/flex-container'
import HiddenMetricsModal from '../home/HiddenMetricsModal'

const OpportunityRelatedSegments = () => {
  const { t } = useTranslation()

  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)
  if (!currentOpportunity) return null

  return (
    <Card.Root color="brandPrimaryPure" direction="column" gap="xs">
      <Card.Header css={{ justifyContent: 'space-between' }}>
        <FlexContainer alignItems="center" gap="nano">
          <Card.IconBox>
            <Users />
          </Card.IconBox>
          <Card.Title>{t('opportunityRelatedSegmentsTitle')}</Card.Title>
        </FlexContainer>
        <HiddenMetricsModal borderedAndSmall />
      </Card.Header>
      <SegmentationsTable opportunityId={currentOpportunity.id} />
    </Card.Root>
  )
}

export default OpportunityRelatedSegments
