import { CSS } from '@/theme'
import { VariantProps } from '@stitches/react'
import { IconContext } from '@phosphor-icons/react'
import { HTMLAttributes, ReactNode } from 'react'
import { IconCard } from './SelectableCard.styles'

export interface IconSelectableCardProps
  extends VariantProps<typeof IconCard>,
    HTMLAttributes<HTMLDivElement> {
  icon?: ReactNode
  iconColor?: string
  text: string
  css?: CSS
}

const IconSelectableCard = ({ icon, iconColor, text, css, ...props }: IconSelectableCardProps) => {
  return (
    <IconCard {...props} css={css}>
      <IconContext.Provider value={{ size: 32, color: iconColor, weight: 'fill' }}>
        {icon}
      </IconContext.Provider>
      <p data-text={text}>{text}</p>
    </IconCard>
  )
}

export default IconSelectableCard
