import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import useAdvancedFilters from './advancedFilters/useAdvancedFilters'
import { useQuery } from '@tanstack/react-query'
import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import { stringToDate } from '@/utils/date'

import shortUUID from 'short-uuid'
import useOpportunityStore from '@/store/useOpportunityStore'
import useNavitagateTo from './useNavigateTo'
import AreaService from '@/services/AreaService'
import OpportunityService from '@/services/OpportunityService'
import { OpportunityItem } from '@/types/opportunity/Opportunity'
import useInitiativeStore from '@/store/initiatives/useInitiativeStore'
import InitiativesService from '@/services/InitiativesService'
import { mapInitiative } from './initiatives/useInitiatives'
import { InitiativeItem } from '@/types/initiatives/Initiatives'
import AlertsService from '@/services/AlertsService'
import useSegmentationStore from '@/store/useSegmentationStore'
import SegmentationService from '@/services/SegmentationService'
import { mapSegmentation } from '@/utils/segmentation'
import { SegmentationItem } from '@/types/segmentation/Segmentation'

const usePageLink = () => {
  const translator = useMemo(() => shortUUID(), [])

  const { shortAreaId, shortOpportunityId, shortInitiativeId, shortSegmentationId } = useParams()
  const { search } = useLocation()

  const [areaId, setAreaId] = useState(() =>
    shortAreaId ? translator.toUUID(shortAreaId).toString() : undefined
  )
  const [opportunityId, setOpportunityId] = useState(() =>
    shortOpportunityId ? translator.toUUID(shortOpportunityId).toString() : undefined
  )
  const [initiativeId, setInitiativeId] = useState(() =>
    shortInitiativeId ? translator.toUUID(shortInitiativeId).toString() : undefined
  )
  const [segmentationId, setSegmentationId] = useState(() =>
    shortSegmentationId ? translator.toUUID(shortSegmentationId).toString() : undefined
  )

  useEffect(() => {
    if (search.includes('notificationId') || search.includes('id')) return

    setAreaId(shortAreaId ? translator.toUUID(shortAreaId) : undefined)
    setOpportunityId(shortOpportunityId ? translator.toUUID(shortOpportunityId) : undefined)
    setInitiativeId(shortInitiativeId ? translator.toUUID(shortInitiativeId) : undefined)
    setSegmentationId(shortSegmentationId ? translator.toUUID(shortSegmentationId) : undefined)
  }, [shortAreaId, shortOpportunityId, shortInitiativeId, shortSegmentationId, search, translator])

  const { navigateTo: navigate } = useNavitagateTo()
  const params = new URLSearchParams(search)
  const notificationId = params.get('id') ?? params.get('notificationId')

  const currentAreaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const setCurrentAreaOfInterest = useCurrentInterestAreaStore(
    state => state.setCurrentInterestArea
  )

  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)
  const setCurrentOpportunity = useOpportunityStore(state => state.setCurrentOpportunity)

  const { applyFilterFromArea, applyOpportunityFilters, applyFilterFromSegmentation } =
    useAdvancedFilters()

  const currentInitiative = useInitiativeStore(state => state.currentInitiative)
  const setCurrentInitiative = useInitiativeStore(state => state.setCurrentInitiative)

  const currentSegmentation = useSegmentationStore(state => state.currentSegmentation)
  const setCurrentSegmentation = useSegmentationStore(state => state.setCurrentSegmentation)

  const isAreaQueryEnabled = (!!areaId && currentAreaOfInterest?.id !== areaId) || !!notificationId

  const areaQueryFn = async () => {
    let id: string | undefined
    if (notificationId) {
      const [alertError, alertResponse] = await AlertsService.searchAlerts({
        id: notificationId,
        per_page: 1,
        version: 'v2'
      })

      if (alertError) throw alertError
      const alert = alertResponse?.alerts[0]
      id = alert?.area_id
    } else {
      id = areaId
    }

    if (!id) {
      throw new Error(`No area id provided`)
    }

    const [error, response] = await AreaService.getArea(id)

    if (error) throw error

    const areaFound = response
    if (!areaFound) throw new Error(`Area ${areaId} not found`)

    const parsedArea: BaseInterestArea = {
      id: areaFound.area_id,
      filterId: areaFound.filter_id,
      name: areaFound.name,
      content: areaFound.content ?? [],
      context: areaFound.context,
      createdBy: areaFound.created_by ?? '',
      opportunityCount: areaFound.opportunities?.length ?? 0,
      useInUnmappedArea: areaFound.is_mapped ?? false,
      advanced: areaFound.advanced ?? false,
      error: areaFound.error || null
    }

    if (notificationId) {
      setAreaId(areaFound.filter_id)

      params.delete('notificationId')
      params.delete('version')

      const areaUUID = translator.fromUUID(areaFound.filter_id)
      navigate(
        {
          pathname: `/area/${areaUUID}`,
          search: params.toString()
        },
        { replace: true }
      )
    }

    return parsedArea
  }

  const { data: area, isLoading: isAreaLoading } = useQuery({
    queryKey: ['area-of-interest', areaId, notificationId],
    queryFn: areaQueryFn,
    enabled: isAreaQueryEnabled,
    retry: false
  })

  const isOpportunityQueryEnabled =
    (!!opportunityId && currentOpportunity?.id !== opportunityId) || !!notificationId

  const opportunityQueryFn = async () => {
    let id: string | undefined
    if (notificationId) {
      const [alertError, alertResponse] = await AlertsService.searchAlerts({
        id: notificationId,
        per_page: 1,
        version: 'v2'
      })

      if (alertError) throw alertError
      const alert = alertResponse?.alerts[0]
      id = alert?.opportunity_id
    } else {
      id = opportunityId
    }

    if (!id) {
      throw new Error(`No opportunity id provided`)
    }

    const [error, response] = await OpportunityService.getOpportunity(id)

    if (error) throw error

    const opportunityFound = response
    if (!opportunityFound) throw new Error(`Opportunity ${opportunityId} not found`)

    const parsedOpportunity: OpportunityItem = {
      name: opportunityFound.name,
      id: opportunityFound.opportunity_id,
      filterId: opportunityFound.filter_id,
      status: opportunityFound.opportunity_status_id,
      description: opportunityFound.description,
      createdAt: stringToDate(opportunityFound.created_at),
      new: opportunityFound.new ?? false,
      createdBy: opportunityFound.created_by,
      relations: opportunityFound.areas,
      initiatives: opportunityFound.initiatives,
      pinnedFeedback: opportunityFound.pinned_feedback ?? [],
      internal_review: opportunityFound.internal_review
    }

    if (notificationId) {
      setOpportunityId(opportunityFound.filter_id)
    }

    return parsedOpportunity
  }

  const { data: opportunity, isLoading: isOpportunityLoading } = useQuery({
    queryKey: ['opportunity', opportunityId, notificationId],
    queryFn: opportunityQueryFn,
    enabled: isOpportunityQueryEnabled,
    retry: false
  })

  const isInitiativeQueryEnabled = !!initiativeId && currentInitiative?.id !== initiativeId

  const initiativeQueryFn = async () => {
    const id = initiativeId
    if (!id) {
      throw new Error(`No initiative id provided`)
    }

    // TODO: use get by id
    const [error, response] = await InitiativesService.search({
      limit: 100,
      page: 1
    })

    if (error) throw error

    const initiativeFound = response.initiatives.find(i => i.initiative_id === id)
    if (!initiativeFound) throw new Error(`Initiative ${initiativeId} not found`)

    const parsedInitiative: InitiativeItem = mapInitiative(initiativeFound)

    return parsedInitiative
  }

  const { data: initiative, isLoading: isInitiativeLoading } = useQuery({
    queryKey: ['initiative', initiativeId],
    queryFn: initiativeQueryFn,
    enabled: isInitiativeQueryEnabled,
    retry: false
  })

  const isSegmentQueryEnabled =
    !!segmentationId && currentSegmentation?.segmentationId !== segmentationId

  const segmentationQueryFn = async () => {
    const id = segmentationId
    if (!id) {
      throw new Error(`No segmentation id provided`)
    }

    const [error, response] = await SegmentationService.getOne({ segmentation_id: id })

    if (error) throw error

    const parsedSegmentation: SegmentationItem = mapSegmentation(response)
    return parsedSegmentation
  }

  const { data: segmentation, isLoading: isSegmentationLoading } = useQuery({
    queryKey: ['segmentation', segmentationId],
    queryFn: segmentationQueryFn,
    enabled: isSegmentQueryEnabled,
    retry: false
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies: should only react to area and opportunity changes
  useEffect(() => {
    if (areaId && currentAreaOfInterest?.id !== areaId && area) {
      setCurrentAreaOfInterest(area)
      applyFilterFromArea(area)
    }

    if (opportunityId && currentOpportunity?.id !== opportunityId && opportunity) {
      setCurrentOpportunity(opportunity)
      applyOpportunityFilters({ opportunity })
    }

    if (initiativeId && currentInitiative?.id !== initiativeId && initiative) {
      setCurrentInitiative(initiative)
    }

    if (segmentationId && currentSegmentation?.segmentationId !== segmentationId && segmentation) {
      setCurrentSegmentation(segmentation)
      applyFilterFromSegmentation(segmentation)
    }
  }, [area, opportunity, initiative, segmentation])

  return {
    area,
    opportunity,
    initiative,
    segmentation,
    isAreaLoading,
    isOpportunityLoading,
    isInitiativeLoading,
    isSegmentationLoading
  }
}

export default usePageLink
