import useAccountsStore from '@/store/useAccountsStore'
import useUser from './useUser'
import { useCallback } from 'react'
import AudienceService from '@/services/AudienceService'
import useToastMessageStore from '@/store/useToastMessageStore'
import { InfiniteData, QueryFunctionContext, useQueryClient } from '@tanstack/react-query'
import { AccountResult } from '@/types/manage-customers'
import useLogging from './useLogging'

const useAccounts = () => {
  const { currentUser } = useUser()
  const queryClient = useQueryClient()

  const push = useAccountsStore(state => state.push)
  const setTotal = useAccountsStore(state => state.setTotal)
  const setLoading = useAccountsStore(state => state.setLoading)
  const setError = useAccountsStore(state => state.setError)
  const removeAccount = useAccountsStore(state => state.removeAccount)

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)

  const { logException } = useLogging({ context: 'use-accounts' })

  const loadAccounts = useCallback(
    async (params: QueryFunctionContext<[string, { q: string; perPage?: number }]>) => {
      const { pageParam, signal, queryKey } = params
      const { q, perPage } = queryKey[1]

      setLoading(true)

      const [error, data] = await AudienceService.getAccounts(
        {
          orgId: currentUser?.organization_id || '',
          per_page: perPage ?? 50,
          cursor: pageParam,
          q
        },
        signal
      )

      if (error) {
        setLoading(false)
        if (error.isCanceledError) return undefined
        setTotal(0)
        setError(Error(error.key))

        const message = `Failed to fetch for accounts.`
        logException(error, { message })

        addErrorToast({
          text: message,
          duration: 4500
        })
        throw error
      }

      setLoading(false)
      setError(null)
      setTotal(data.total)
      push(data.accounts)

      return { ...data, nextPage: pageParam && !data.accounts.length ? undefined : data.nextPage }
    },
    [currentUser, addErrorToast, logException, push, setError, setLoading, setTotal]
  )

  const deleteAccount = async (accountId: string) => {
    const removeToast = addLoadingToast({ text: 'Deleting account...' })
    const [error] = await AudienceService.deleteAccount(accountId)
    if (error) {
      removeToast()
      const message = 'Failed to delete account.'
      logException(error, { message })
      addErrorToast({
        text: message,
        duration: 4500
      })
      return
    }

    queryClient.setQueriesData(['customer-accounts'], (oldData?: InfiniteData<AccountResult>) => {
      if (!oldData || !oldData.pages.length) return oldData

      const pages = oldData.pages.map(page => ({
        ...page,
        accounts: page.accounts.filter(account => account.id !== accountId)
      }))
      pages[0].total = pages[0].total - 1

      return { ...oldData, pages }
    })

    removeToast()
    removeAccount(accountId)
    addSuccessToast({ text: 'Account deleted.' })
  }

  return { loadAccounts, deleteAccount }
}

export default useAccounts
