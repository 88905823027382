import { Link } from 'react-router-dom'

import BirdieIcon from '@/assets/birdie_icon.svg'
import { NavigationItemButton, NavigationItemLink } from '@/components/atoms/navigation-item'
import {
  Atom,
  ChartLine,
  ChatText,
  Compass,
  Gear,
  House,
  PresentationChart,
  Question,
  Swatches
} from '@phosphor-icons/react'
import { APP_ENVIRONEMNT } from '@/config'
import useSegment from '@/hooks/useSegment'
import useUser from '@/hooks/useUser'
import { Bar, NavigationItems, NavigationLogoIcon } from './NavigationBar.styles'

function NavigationBarTopicPlan() {
  const { track } = useSegment()

  const { userPermissions, currentUserPlan } = useUser()

  const showHome = currentUserPlan === 'opportunity' && userPermissions.areas.length > 0

  const showDiscovery = currentUserPlan === 'topic' && userPermissions.discovery.length > 0
  const showTopics =
    currentUserPlan === 'topic' &&
    (userPermissions.topic.length > 0 || userPermissions['new-topic'].length > 0)
  const showAnalytics = currentUserPlan === 'topic' && userPermissions.analytics.includes('basic')
  const showFeedbacks = currentUserPlan === 'topic' && userPermissions.feedback.length > 0
  const showDashboards = userPermissions.tableau.length > 0 || userPermissions.tracking.length > 0
  const showNewTopics = currentUserPlan === 'topic' && userPermissions['new-topic'].length > 0

  const showExploration = currentUserPlan === 'opportunity' && userPermissions.areas.length > 0
  const showSettings = userPermissions.notifications.length > 0 || userPermissions.source.length > 0

  const isProduction = APP_ENVIRONEMNT === 'production'

  function showHelpSpace() {
    if (isProduction) {
      window.Intercom('show')
    }
  }

  const topicMenu = (() => {
    if (!showTopics) return undefined
    if (showNewTopics) return { title: 'Topic Management', to: '/topic-management' }

    return { title: 'Topics', to: '/topics' }
  })()

  return (
    <Bar>
      <Link onClick={() => track('menu_user_logo')} to="/">
        <picture>
          <NavigationLogoIcon src={BirdieIcon} />
        </picture>
      </Link>

      <NavigationItems>
        {showHome && (
          <NavigationItemLink title="Home" to="/home">
            <House />
          </NavigationItemLink>
        )}

        {showAnalytics && (
          <NavigationItemLink
            onClick={() => track('menu_user_analytics')}
            title="Analytics"
            to="/analytics"
          >
            <ChartLine />
          </NavigationItemLink>
        )}

        {showExploration && (
          <NavigationItemLink title="Exploration" to="/exploration">
            <Compass />
          </NavigationItemLink>
        )}

        {showDiscovery && (
          <NavigationItemLink
            onClick={() => track('menu_user_discovery')}
            title="Discovery"
            to="/discovery"
          >
            <Atom />
          </NavigationItemLink>
        )}

        {topicMenu && (
          <NavigationItemLink onClick={() => track('menu_user_topics')} {...topicMenu}>
            <Swatches />
          </NavigationItemLink>
        )}

        {showFeedbacks && (
          <NavigationItemLink
            onClick={() => track('menu_user_feedback')}
            title="Feedback"
            to="/feedback"
          >
            <ChatText />
          </NavigationItemLink>
        )}

        {showDashboards && (
          <NavigationItemLink
            onClick={() => track('menu_user_dashboards')}
            title="Dashboards"
            to="/tracking"
          >
            <PresentationChart />
          </NavigationItemLink>
        )}
      </NavigationItems>
      <NavigationItems css={{ mt: 'auto' }}>
        {showSettings && (
          <NavigationItemLink title="Settings" to="/settings">
            <Gear />
          </NavigationItemLink>
        )}
        {isProduction && (
          <NavigationItemButton onClick={showHelpSpace} title="Help">
            <Question />
          </NavigationItemButton>
        )}
      </NavigationItems>
    </Bar>
  )
}

export default NavigationBarTopicPlan
