const OPERATORS = [' and ', ' or ', ' not ', '*', '"']

const enableAutoCompleteSearch = (searchText: string) => {
  const hasOperators = OPERATORS.some(operator => searchText.toLowerCase().includes(operator))
  if (hasOperators) return searchText

  return `${searchText}*`
}

export default enableAutoCompleteSearch
