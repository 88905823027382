import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'

const defaultExtensions = [
  StarterKit,
  Underline,
  Link.configure({
    autolink: true,
    openOnClick: 'whenNotEditable',
    HTMLAttributes: { class: 'editor-link' }
  })
]

export default defaultExtensions
