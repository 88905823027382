import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import customFieldsEmpty from '@/assets/drawings/custom-fields-empty.png'
import Button from '@/components/atoms/button'
import ErrorBoundary from '@/components/atoms/error-boundary'
import FlexContainer from '@/components/atoms/flex-container'
import { Table, TableContainer, TableHeader } from '@/components/atoms/table'
import Text from '@/components/atoms/text'
import CustomFieldsTableRow from '@/components/molecules/custom-fields/CustomFieldsTableRow'
import CustomFieldsTableRowSkeleton from '@/components/molecules/custom-fields/CustomFieldsTableRowSkeleton'
import ServerError from '@/components/molecules/server-error'
import useErrorScreens from '@/hooks/useErrorScreens'
import useCustomFieldsStore from '@/store/useCustomFieldsStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import useUser from '@/hooks/useUser'
import useLogging from '@/hooks/useLogging'
import { useTranslation } from 'react-i18next'

function CustomFields() {
  const navigate = useNavigate()
  const { logException } = useLogging({ context: 'custom-fields' })

  const customFields = useCustomFieldsStore(state => state.customFields)
  const isLoading = useCustomFieldsStore(state => state.isLoading)
  const loadCustomFields = useCustomFieldsStore(state => state.loadCustomFields)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { t } = useTranslation()

  const { checkServerError, hasServerError } = useErrorScreens()

  useEffect(() => {
    if (customFields.length > 0) return

    loadCustomFields().catch(error => {
      const message = t('failedToLoadCustomFields')
      logException(error, { message })
      addErrorToast({ text: message })
      checkServerError(error)
    })
  }, [addErrorToast, checkServerError, customFields.length, loadCustomFields, logException, t])

  function navigateToForm() {
    navigate('/settings/custom_fields_deprecated/new')
  }

  const { userPermissions } = useUser()
  const isManager = userPermissions.source.includes('manager')

  if (hasServerError) return <ServerError />

  const title = t('customFields')
  const subtitle = t(`customFieldsAreUsedToBringAdditionalMetadataAboutYourUsersFeedback`)

  return (
    <ErrorBoundary>
      {isLoading || customFields.length > 0 ? (
        <FlexContainer css={{ gap: 24, maxWidth: 800 }} direction="column">
          <FlexContainer justifyContent="spaceBetween">
            <FlexContainer css={{ gap: 16, maxWidth: 560 }} direction="column">
              <Text as="h2" typeface="titleBoldXXS">
                {title}
              </Text>
              <Text as="p" typeface="paragraphRegularMicro">
                {subtitle}
              </Text>
            </FlexContainer>

            <Button disabled={!isManager} onClick={navigateToForm} type="button">
              {t('newCustomField')}
            </Button>
          </FlexContainer>

          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <TableHeader css={{ width: 240 }}>{t('fieldName')}</TableHeader>
                  <TableHeader>{t('fieldType')}</TableHeader>
                  <TableHeader>{t('useAsFilter')}</TableHeader>
                  <TableHeader>{t('createdAt')}</TableHeader>
                  <TableHeader css={{ width: 24 }} />
                </tr>
              </thead>
              <tbody>
                {!isLoading
                  ? customFields.map(field => (
                      <CustomFieldsTableRow
                        createdAt={field.created_at}
                        filter={field.filter}
                        id={field.id}
                        isManager={isManager}
                        key={field.id}
                        name={field.name}
                        type={field.type}
                      />
                    ))
                  : Array.from({ length: 4 }, (_, index) => (
                      <CustomFieldsTableRowSkeleton key={index} />
                    ))}
              </tbody>
            </Table>
          </TableContainer>
        </FlexContainer>
      ) : (
        <FlexContainer alignItems="center" css={{ maxWidth: 800 }} direction="column">
          <img alt="" height={298} src={customFieldsEmpty} width={478} />
          <Text as="h2" css={{ fontSize: '$md', lineHeight: '$md', fontWeight: '$bold' }}>
            {title}
          </Text>
          <Text css={{ fontSize: '$xxs', lineHeight: '$xxl', maxWidth: 720, textAlign: 'center' }}>
            {subtitle}
          </Text>
          <Button
            css={{ marginTop: '$xs' }}
            disabled={!isManager}
            onClick={navigateToForm}
            type="button"
            variant="exception"
          >
            {t('addCustomField')}
          </Button>
        </FlexContainer>
      )}

      <Outlet />
    </ErrorBoundary>
  )
}

export default CustomFields
