import { styled, typefaceVariants } from '@/theme'
import { CheckCircle } from '@phosphor-icons/react'

const PasswordValidationWrapper = styled('div', {
  position: 'relative',
  display: 'inline-block',
  cursor: 'default !important',
  padding: 0,
  margin: 0,
  ml: 'auto',
  mb: -4,
  width: 60,
  right: -16
})
const PasswordStrength = styled('span', {
  textTransform: 'capitalize',
  ...typefaceVariants.subtitleSemiBoldMicro,

  variants: {
    strength: {
      strong: {
        color: '$feedbackPositivePure'
      },
      weak: {
        color: '$feedbackInformativePure'
      }
    }
  }
})
const TooltipBox = styled('div', {
  color: '$neutralHighLight',
  pointerEvents: 'none',
  width: 282,
  background: '$neutralLowPure',
  borderRadius: '$micro',
  padding: '16px 20px',
  position: 'absolute',
  zIndex: 1,
  transition: '.2s',
  transform: 'translateX(60px) translateY(-36px)',
  fontSize: 14,
  lineHeight: 1.61,

  '&:hover': {
    opacity: 1
  },

  '&::after': {
    content: ' ',
    position: 'absolute',
    top: 24,
    right: 'calc(100% + 8px)',
    width: 8,
    height: 8,
    borderRadius: '$micro',
    background: '$neutralLowPure'
  },

  '&::before': {
    content: ' ',
    position: 'absolute',
    top: 27,
    right: '100%',
    width: 8,
    height: 1,
    background: '$neutralLowPure'
  },

  variants: {
    visible: {
      true: {
        opacity: 1
      },
      false: {
        opacity: 0
      }
    }
  }
})

const ValidationList = styled('ul', {
  mt: '$micro',
  ml: 0,
  pl: '$xxs'
})

const ValidationItem = styled('li', {
  ml: 0,
  display: 'flex',
  alignItems: 'center',
  gap: 8,

  variants: {
    validated: {
      true: {
        color: '$feedbackPositivePure',
        left: -4,
        gap: '$nano'
      },
      false: {
        '&::before': {
          content: '•',
          whiteSpace: 'pre',
          width: 10
        }
      }
    }
  }
})

interface PasswordValidationProps {
  strength: 'strong' | 'weak'
  visible: boolean
  length: boolean
  lowercase: boolean
  uppercase: boolean
  numeric: boolean
}

const PasswordValidation = ({
  strength,
  visible,
  length,
  lowercase,
  uppercase,
  numeric
}: PasswordValidationProps) => {
  const validationList: { validated: boolean; text: string }[] = [
    {
      validated: length,
      text: 'At least 8 characters in length'
    },
    {
      validated: lowercase,
      text: 'Lower case letters (a-z)'
    },
    {
      validated: uppercase,
      text: 'Upper case letters (A-Z)'
    },
    {
      validated: numeric,
      text: 'Numbers (0-9)'
    }
  ]

  return (
    <PasswordValidationWrapper>
      <PasswordStrength>{strength}</PasswordStrength>
      <TooltipBox visible={visible}>
        You need a strong password with:
        <ValidationList>
          {validationList.map(item => (
            <ValidationItem key={item.text} validated={item.validated}>
              {item.validated && <CheckCircle style={{ margin: 0, padding: 0 }} weight="fill" />}
              <span>{item.text}</span>
            </ValidationItem>
          ))}
        </ValidationList>
      </TooltipBox>
    </PasswordValidationWrapper>
  )
}

export default PasswordValidation
