import Bone from '@/components/atoms/bone'
import { styled } from '@/theme'

const DashboardBone = styled(Bone, {
  height: 32,
  width: 200
})

const DashboardTabsSkeleton = () => (
  <>
    <DashboardBone />
    <DashboardBone />
    <DashboardBone />
    <DashboardBone />
    <DashboardBone />
    <DashboardBone />
  </>
)

export default DashboardTabsSkeleton
