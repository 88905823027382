import { queryClient } from '@/plugins/reactQueryClient'
import SegmentationService from '@/services/SegmentationService'
import { SegmentationRequests } from '@/types/segmentation'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import useLogging from '../useLogging'
import useToastMessageStore from '@/store/useToastMessageStore'
import { AdvancedFilterContent } from '@/types/filters/AdvancedFilters'
import useAdvancedFilters from '../advancedFilters/useAdvancedFilters'
import useSegmentationsWithMetricsQuery from './useSegmentationsWithMetricsQuery'
import { SegmentationItem, SegmentationItemWithMetrics } from '@/types/segmentation/Segmentation'
import useSegmentationStore from '@/store/useSegmentationStore'
import useAdvancedFiltersStore from '@/store/useFiltersStore/useAdvancedFiltersStore'
import useSavedFilters from '../useSavedFilters'
import shortUUID from 'short-uuid'
import { useNavigate } from 'react-router-dom'
import useOpportunityStore from '@/store/useOpportunityStore'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { stringToDate } from '@/utils/date'
import useSegment from '../useSegment'
import { basicSegmentationsQueryKey } from './useBasicSegmentationsQuery'

interface CreateSegmentationParams {
  content?: AdvancedFilterContent
  name: string
  description?: string
}

interface UpdateSegmentationParams {
  segmentation: SegmentationItem
  content?: AdvancedFilterContent
  name?: string
  description?: string
  refreshCurrent?: boolean
}

const useSegmentation = () => {
  const { t } = useTranslation()
  const { logException } = useLogging({ context: 'segmentation-mutations' })

  const { track } = useSegment()

  const navigate = useNavigate()

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const removeToast = useToastMessageStore(state => state.removeToast)

  const { buildFilterContent } = useAdvancedFilters()

  const { queryKey: segmentationsQueryKey } = useSegmentationsWithMetricsQuery({ enabled: false })

  const setCurrentSegmentation = useSegmentationStore(state => state.setCurrentSegmentation)
  const setCurrentOpportunity = useOpportunityStore(state => state.setCurrentOpportunity)
  const setCurrentAreaOfInterest = useCurrentInterestAreaStore(
    state => state.setCurrentInterestArea
  )

  const transformIntoEntityFilters = useAdvancedFiltersStore(
    state => state.transformIntoEntityFilters
  )

  const { applyFilterContent } = useSavedFilters({ newFeed: true })
  const { applyFilterFromSegmentation } = useAdvancedFilters()

  const setSegmentation = (segmentation: SegmentationItem, applyContent?: boolean) => {
    setCurrentSegmentation(segmentation)
    setCurrentOpportunity(undefined)
    setCurrentAreaOfInterest(undefined)

    if (applyContent) {
      applyFilterFromSegmentation(segmentation)
      applyFilterContent([], true)
    }

    const translator = shortUUID()
    const shortSegmentationId = translator.fromUUID(segmentation.segmentationId)

    navigate(`/segment/${shortSegmentationId}`)
  }

  const { mutate: createSegmentation, isLoading: isCreatingSegmentation } = useMutation({
    mutationKey: ['create-segmentation'],
    mutationFn: async (params: CreateSegmentationParams) => {
      const payload: SegmentationRequests.AddSegmentationPayload = {
        name: params.name,
        description: params.description,
        content: [buildFilterContent(params.content)]
      }

      const [error, response] = await SegmentationService.addSegmentation(payload)
      if (error) throw error
      return response
    },
    onMutate: () => {
      queryClient.cancelQueries({ queryKey: segmentationsQueryKey })
      addLoadingToast({ text: t('createSegmentationLoadingMessage'), id: 'create-segmentation' })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: segmentationsQueryKey })
    },
    onError: (error, params) => {
      removeToast('create-segmentation')
      addErrorToast({ text: t('createSegmentationErrorMessage') })

      const content = buildFilterContent(params.content)

      const message = 'Failed to create segmentation.'
      logException(error, {
        message,
        tags: { content: JSON.stringify(content), name: params.name }
      })
    },
    onSuccess: (data, params) => {
      removeToast('create-segmentation')
      addSuccessToast({ text: t('createSegmentationSuccessMessage') })

      const content = buildFilterContent(params.content)

      track('exploration_segment_created', {
        filters: JSON.stringify(content),
        segment_name: params.name,
        segment_id: data.segmentation_id
      })

      const newSegmentation: SegmentationItem = {
        segmentationId: data.segmentation_id,
        filterId: data.filter_id,
        name: params.name,
        description: params.description,
        content: [content],
        createdAt: stringToDate(data.created_at),
        createdBy: data.created_by,
        updatedAt: stringToDate(data.updated_at),
        updatedBy: data.updated_by
      }

      setSegmentation(newSegmentation)
      transformIntoEntityFilters()

      return newSegmentation
    }
  })

  const { mutate: updateSegmentation, isLoading: isUpdatingSegmentation } = useMutation({
    mutationKey: ['update-segmentation'],
    mutationFn: async (params: UpdateSegmentationParams) => {
      const content = buildFilterContent(params.content)
      const newSegmentation: SegmentationItem = {
        ...params.segmentation,
        content: content ? [content] : params.segmentation.content,
        name: params.name ?? params.segmentation.name,
        description: params.description ?? params.segmentation.description
      }

      const [error] = await SegmentationService.updateSegmentation(newSegmentation.segmentationId, {
        filter_id: newSegmentation.filterId,
        name: newSegmentation.name,
        description: newSegmentation.description,
        content: newSegmentation.content
      })

      if (error) throw error
      return newSegmentation
    },
    onMutate: () => {
      queryClient.cancelQueries({ queryKey: segmentationsQueryKey })
      addLoadingToast({ text: t('updateSegmentationLoadingMessage'), id: 'edit-segmentation' })
    },
    onError: (error, params) => {
      removeToast('edit-segmentation')
      console.error(error)
      logException(error, {
        message: 'Failed to update segmentation.',
        tags: { segmentation: JSON.stringify(params) }
      })

      addErrorToast({ text: t('updateSegmentationErrorMessage') })
    },
    onSuccess: async (newSegmentation, { refreshCurrent }) => {
      removeToast('edit-segmentation')
      addSuccessToast({ text: t('updateSegmentationSuccessMessage') })

      track('segment_update', {
        filters: JSON.stringify(newSegmentation.content),
        segment_name: newSegmentation.name,
        segment_id: newSegmentation.segmentationId
      })

      if (refreshCurrent) {
        setCurrentSegmentation({
          ...newSegmentation
        })
        transformIntoEntityFilters()
      }

      queryClient.invalidateQueries({ queryKey: segmentationsQueryKey })
    }
  })

  const { mutate: deleteSegmentation, isLoading: isDeletingSegmentation } = useMutation({
    mutationKey: ['delete-segmentation'],
    mutationFn: async (segmentationId: string) => {
      const [error] = await SegmentationService.deleteSegmentation(segmentationId)
      if (error) throw error
      return segmentationId
    },
    onMutate: async (segmentationId: string) => {
      await queryClient.cancelQueries({ queryKey: segmentationsQueryKey })
      await queryClient.cancelQueries({ queryKey: basicSegmentationsQueryKey })

      const previousSegmentations =
        queryClient.getQueryData<SegmentationItemWithMetrics[]>(segmentationsQueryKey)
      queryClient.setQueryData<SegmentationItemWithMetrics[]>(segmentationsQueryKey, old => {
        if (!old) return
        return old.filter(item => item.segmentationId !== segmentationId)
      })

      const previousBasicSegmentations = queryClient.getQueryData<SegmentationItem[]>(
        basicSegmentationsQueryKey
      )
      queryClient.setQueryData<SegmentationItem[]>(basicSegmentationsQueryKey, old => {
        if (!old) return
        return old.filter(item => item.segmentationId !== segmentationId)
      })

      return { previousSegmentations, previousBasicSegmentations }
    },
    onError: (error, segmentationId, context) => {
      logException(error, { message: 'Failed to delete segmentation.', tags: { segmentationId } })
      addErrorToast({ text: t('deleteSegmentationErrorMessage') })
      queryClient.setQueryData<SegmentationItemWithMetrics[]>(
        segmentationsQueryKey,
        context?.previousSegmentations
      )
      queryClient.setQueryData<SegmentationItem[]>(
        basicSegmentationsQueryKey,
        context?.previousBasicSegmentations
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: segmentationsQueryKey })
      queryClient.invalidateQueries({ queryKey: basicSegmentationsQueryKey })
    }
  })

  return {
    createSegmentation,
    isCreatingSegmentation,
    setSegmentation,
    updateSegmentation,
    isUpdatingSegmentation,
    deleteSegmentation,
    isDeletingSegmentation
  }
}

export default useSegmentation
