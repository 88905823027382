import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DeleteDialog } from '@/components/atoms/dialog'
import FlexContainer from '@/components/atoms/flex-container'
import OptionsMenu, { OptionsMenuProps } from '@/components/atoms/options-menu'
import Switch from '@/components/atoms/switch'
import { TableCell } from '@/components/atoms/table'
import useLetterCase from '@/hooks/useLetterCase'
import useCustomFieldsStore from '@/store/useCustomFieldsStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import { formatDateTime } from '@/utils/date'
import { OptionsMenuButton } from './CustomFieldsTableRow.styles'

interface CustomFieldsTableRowProps {
  createdAt: string
  filter: boolean
  id: string
  name: string
  type: string
  isManager: boolean
}

function CustomFieldsTableRow(props: CustomFieldsTableRowProps) {
  const navigate = useNavigate()

  const removeCustomField = useCustomFieldsStore(state => state.removeCustomField)
  const updateCustomField = useCustomFieldsStore(state => state.updateCustomField)
  const addToast = useToastMessageStore(state => state.addToast)

  const { capitalizeFirst } = useLetterCase()

  const [openOptionsMenu, setOpenOptionsMenu] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [isDeletingCustomField, setIsDeletingCustomField] = useState(false)

  function updateUseAsFilter(value: boolean) {
    updateCustomField(props.id, { filter: value })
  }

  async function deleteCustomField() {
    try {
      setIsDeletingCustomField(true)
      await removeCustomField(props.id)

      addToast({
        text: 'Custom field was deleted successfully',
        open: true,
        status: 'success'
      })
    } catch {
      addToast({
        text: 'Failed to delete custom field',
        open: true,
        status: 'error'
      })
    } finally {
      setIsDeletingCustomField(false)
      setOpenDeleteDialog(false)
    }
  }

  function handleEditOptionClick() {
    // https://github.com/radix-ui/primitives/issues/1088
    setOpenOptionsMenu(false)
    navigate(`/settings/custom_fields_deprecated/${props.id}/edit`)
  }

  function handleDeleteOptionClick() {
    // https://github.com/radix-ui/primitives/issues/1088
    setOpenOptionsMenu(false)
    setOpenDeleteDialog(true)
  }

  const optionsMenuOptions: OptionsMenuProps['options'] = [
    { text: 'Edit', onClick: handleEditOptionClick },
    { text: 'Delete', onClick: handleDeleteOptionClick }
  ]

  function renderDeleteDialog() {
    return (
      <DeleteDialog
        confirmText="Delete"
        description="Do you really want to delete this custom field? You're only deleting this custom field, your data is safe."
        isDeleting={isDeletingCustomField}
        onConfirmDelete={deleteCustomField}
        onOpenChange={setOpenDeleteDialog}
        open={openDeleteDialog}
        title="Delete custom field?"
      />
    )
  }

  return (
    <tr>
      <TableCell>{props.name}</TableCell>
      <TableCell>{capitalizeFirst(props.type)}</TableCell>
      <TableCell>
        <FlexContainer>
          <Switch
            checked={Boolean(props.filter)}
            disabled={!props.isManager}
            onCheckedChange={updateUseAsFilter}
          />
        </FlexContainer>
      </TableCell>
      <TableCell>{formatDateTime(props.createdAt)}</TableCell>
      <TableCell>
        <FlexContainer justifyContent="center">
          {props.isManager && (
            <OptionsMenu
              buttonStyle={OptionsMenuButton}
              onOpenChange={setOpenOptionsMenu}
              open={openOptionsMenu}
              options={optionsMenuOptions}
            />
          )}
        </FlexContainer>
      </TableCell>

      {renderDeleteDialog()}
    </tr>
  )
}

export default CustomFieldsTableRow
