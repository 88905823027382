import { useMemo } from 'react'
import useSourcesQuery from './useSourcesQuery'
import useEnumFiltersQuery from './filters/useEnumFiltersQuery'
import useFieldsQuery from './useFieldsQuery'

const SURVEY_TYPE_KEY = 'support_ticket.survey_type'
const SURVEY_TYPE_VALUES = ['csat', 'nps']

interface Params {
  enabled?: boolean
}

const useTicketSurveyType = ({ enabled = true }: Params = { enabled: true }) => {
  const { values } = useSourcesQuery({
    enabled: true
  })

  const isEnabled = useMemo(() => values.includes('support_ticket') && enabled, [values, enabled])

  const { data: surveyTypeField, isLoading } = useEnumFiltersQuery({
    name: SURVEY_TYPE_KEY,
    enabled: isEnabled
  })
  const { data: fieldsData, isLoading: isLoadingFieldsData } = useFieldsQuery()

  const hasTicketSurveyType = useMemo(() => {
    if (!values.includes('support_ticket')) return false
    if (!surveyTypeField?.values) return false
    if (!fieldsData) return false

    if (!fieldsData.find(field => field.name === SURVEY_TYPE_KEY)) return false

    return surveyTypeField.values.filter(value => SURVEY_TYPE_VALUES.includes(value)).length > 0
  }, [surveyTypeField, fieldsData, values])

  const surveyTypeIsNPS = useMemo(() => {
    if (!hasTicketSurveyType) return false

    return !!surveyTypeField?.values?.find(value => value === 'nps')
  }, [hasTicketSurveyType, surveyTypeField])

  const surveyTypeIsCSAT = useMemo(() => {
    if (!hasTicketSurveyType) return false

    return !!surveyTypeField?.values?.find(value => value === 'csat')
  }, [hasTicketSurveyType, surveyTypeField])

  return {
    surveyTypeField,
    hasTicketSurveyType,
    surveyTypeIsNPS,
    surveyTypeIsCSAT,
    isLoading: isEnabled ? isLoading || isLoadingFieldsData : false
  }
}

export default useTicketSurveyType
