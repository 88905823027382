import { styled } from '@/theme'
import * as Collapsible from '@radix-ui/react-collapsible'

export const CollapsibleButton = styled('button', {
  all: 'unset',
  alignItems: 'center',
  alignSelf: 'flex-start',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  display: 'flex',
  gap: '$micro',
  py: '$xs',
  width: '100%',
  outline: 'none',
  bBottom: '$neutralHighPure',

  '& > svg': {
    transition: 'transform .1s',
    color: '$neutralLowLight'
  },

  '&[data-state=open]': {
    borderBottom: 'none',

    '& svg': {
      transform: 'rotate(90deg)'
    }
  },

  variants: {
    subLevel: {
      true: {
        borderBottom: 'none',
        py: '$xxxs',

        '&[data-state=open]': {
          cursor: 'pointer'
        }
      }
    }
  }
})

export const CollapisibleDetailRoot = styled(Collapsible.Root, {
  '&[data-state=open]': {
    bBottom: '$neutralHighPure',
    pb: '$xs'
  },

  variants: {
    subLevel: {
      true: {
        borderBottom: 'none',
        '&[data-state=open]': {
          borderBottom: 'none'
        }
      }
    }
  }
})
