import FlexContainer from '@/components/atoms/flex-container'
import useClassification from '@/hooks/useClassification'
import { useUIStore } from '@/store'
import { styled } from '@/theme'
import { useMemo, useState } from 'react'
import AddTheme from './theme/AddTheme'
import { shallow } from 'zustand/shallow'
import Button from '@/components/atoms/button'
import AddTopic, { AddTopicProps } from './topic/AddTopic'
import useFiltersStore from '@/store/useFiltersStore'
import useUser from '@/hooks/useUser'

const Container = styled(FlexContainer, {
  mt: '$xxs',
  ml: '$xxxs',
  mr: 48,
  justifyContent: 'space-between',
  alignItems: 'center',

  variants: {
    isAdmin: {
      true: {
        justifyContent: 'space-between'
      },
      false: {
        justifyContent: 'flex-end'
      }
    },
    isTopicModal: {
      true: {
        ml: '$sm',
        mr: 36
      }
    },
    isAdding: {
      true: {}
    }
  },

  compoundVariants: [
    {
      isTopicModal: true,
      isAdding: true,
      css: {
        margin: '0'
      }
    }
  ]
})

interface Props extends AddTopicProps {
  mode: 'theme' | 'topic'
}

const ClassificationFooter = ({ mode, addingTopicStatus, ...props }: Props) => {
  const { refreshAll } = useClassification()

  const { userPermissions } = useUser()
  const isManager = userPermissions.topic.includes('manager')

  const applyThemesAndTopics = useFiltersStore(state => state.applyThemesAndTopics)

  const [isAddingTheme, setIsAddingTheme] = useState(false)

  const isFeedFiltersOpen = useUIStore(state => state.isFeedFiltersOpen, shallow)

  const {
    draftThemes,
    draftTopics,
    selectedThemes,
    selectedTopics,
    draftUnclassifiedTopicType,
    unclassifiedTopicType
  } = useFiltersStore(
    state => ({
      draftThemes: [...state.productAreaThemes.draftSelected, ...state.otherThemes.draftSelected],
      draftTopics: [...state.productAreaTopics.draftSelected, ...state.otherTopics.draftSelected],
      selectedThemes: [...state.productAreaThemes.selected, ...state.otherThemes.selected],
      selectedTopics: [...state.productAreaTopics.selected, ...state.otherTopics.selected],
      draftUnclassifiedTopicType: state.unclassifiedTopics.draftSelected,
      unclassifiedTopicType: state.unclassifiedTopics.selected
    }),
    shallow
  )

  const hasChanges = useMemo(() => {
    const hasChanged: boolean =
      JSON.stringify(draftThemes.sort((a, b) => a.localeCompare(b))) !==
        JSON.stringify(selectedThemes.sort((a, b) => a.localeCompare(b))) ||
      JSON.stringify(draftTopics.sort((a, b) => a.localeCompare(b))) !==
        JSON.stringify(selectedTopics.sort((a, b) => a.localeCompare(b))) ||
      JSON.stringify(draftUnclassifiedTopicType.sort((a, b) => a.localeCompare(b))) !==
        JSON.stringify(unclassifiedTopicType.sort((a, b) => a.localeCompare(b)))

    return hasChanged
  }, [
    draftThemes,
    draftTopics,
    selectedThemes,
    selectedTopics,
    draftUnclassifiedTopicType,
    unclassifiedTopicType
  ])

  const onClickApply = () => {
    const hasChanged = applyThemesAndTopics()
    if (hasChanged) refreshAll()
  }

  const isAdding = useMemo(() => {
    return addingTopicStatus === 'bottom' || isAddingTheme
  }, [isAddingTheme, addingTopicStatus])

  const showApply = (() => {
    if (isFeedFiltersOpen) return false

    return hasChanges && !isAdding
  })()

  return (
    <Container isAdding={isAdding} isAdmin={isManager} isTopicModal={mode === 'topic'}>
      {mode === 'theme' && isManager ? <AddTheme onAddStatusChange={setIsAddingTheme} /> : null}
      {mode === 'topic' && <AddTopic {...props} addingTopicStatus={addingTopicStatus} />}

      {showApply && (
        <Button onClick={onClickApply} small variant="primary">
          Apply filters
        </Button>
      )}
    </Container>
  )
}

export default ClassificationFooter
