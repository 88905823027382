import { SegmentationItem } from '@/types/segmentation/Segmentation'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface SegmentationState {
  currentSegmentation: SegmentationItem | undefined
  setCurrentSegmentation: (data: SegmentationItem | undefined) => void
}

const useSegmentationStore = createWithEqualityFn<SegmentationState>(
  set => ({
    currentSegmentation: undefined,
    setCurrentSegmentation: data => set({ currentSegmentation: data })
  }),
  shallow
)

export default useSegmentationStore
