export const APP_ENVIRONEMNT = import.meta.env.VITE_APP_ENVIRONMENT
export const API_URL = import.meta.env.VITE_API_URL
export const SEARCH_URL = import.meta.env.VITE_SEARCH_URL
export const AUTH_CLIENT = import.meta.env.VITE_AUTH_CLIENT
export const AUTH_DOMAIN = import.meta.env.VITE_AUTH_DOMAIN
export const FEEDBACK_RIVER_API_URL = import.meta.env.VITE_FEEDBACK_RIVER_URL
export const TABLEAU_SERVER_URL = import.meta.env.VITE_TABLEAU_SERVER_URL
export const TABLEAU_VIEW = import.meta.env.VITE_TABLEAU_VIEW

export const UNCLASSIFED_TOPICS_THEME_ID = '00000000-0000-0000-0000-000000000000'

export const CSVB0X_UPLOAD_KEY = import.meta.env.VITE_CSVBOX_SHEET_KEY
export const CSVBOX_IMPORT_THEMES_KEY = import.meta.env.VITE_CSVBOX_IMPORT_THEMES_SHEET_KEY
export const CSVBOX_ACCOUNTS_SHEET_KEY = import.meta.env.VITE_CSVBOX_ACCOUNTS_SHEET_KEY
export const CSVBOX_USERS_SHEET_KEY = import.meta.env.VITE_CSVBOX_USERS_SHEET_KEY
export const CSVBOX_NPS_SHEET_KEY = import.meta.env.VITE_CSVBOX_NPS_SHEET_KEY
export const CSVBOX_CONVERSATION_SHEET_KEY = import.meta.env.VITE_CSVBOX_CONVERSATION_SHEET_KEY

export const INTERCOM_ID = import.meta.env.VITE_INTERCOM_ID
export const HOTJAR_HJID = import.meta.env.VITE_HOTJAR_HJID
export const HOTJAR_HJSV = import.meta.env.VITE_HOTJAR_HJSV
export const PENDO_KEY = import.meta.env.VITE_PENDO_KEY
export const SEGMENT_KEY = import.meta.env.VITE_SEGMENT_KEY
export const GTM_ID = import.meta.env.VITE_GTM_ID

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN

export const BIRDE_API_URL = import.meta.env.VITE_BIRDIE_API_URL

export const DASHBOARD_STATE_API = import.meta.env.VITE_DASHBOARD_STATE_API
export const DASHBOARD_STATE_KEY = import.meta.env.VITE_DASHBOARD_STATE_KEY
