import { useMemo } from 'react'

import { FilterString } from '@/types/filters/Filters'
import { NEW_FEEDBACK_TYPE_KEY } from '@/utils/filters'
import FilterItem from '../../AppliedFilters/FilterItem'
import AppliedFeedbackTypeFiltersEdit from './AppliedFeedbackTypeFiltersEditNew'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import useInterestAreaFilterSpliter from '@/hooks/filters/useInterestAreaFilterSpliter'
import {
  AppliedFiltersDefaultProps,
  defaultAppliedFilterProps,
  disabledAreaFilterTooltipText
} from '../AppliedFilters.types'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'

function AppliedFeedbackTypeFilters(props: AppliedFiltersDefaultProps = defaultAppliedFilterProps) {
  const { splitMode } = props
  const apply = useFeedFiltersStore(state => state.apply)
  const removeStringFilter = useFeedFiltersStore(state => state.removeStringFilter)
  // const stringFilters = useFeedFiltersStore(state => state.stringFilters, shallow)
  const { stringFilters } = useInterestAreaFilterSpliter({ mode: splitMode })
  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const disabled = currentInterestArea && splitMode === 'onlyAreaOfInterestFilters'

  const appliedFilters = useMemo(() => {
    return stringFilters.filter(
      filter => filter.key === NEW_FEEDBACK_TYPE_KEY && filter.selected.length > 0
    )
  }, [stringFilters])

  function removeFilter(filter: FilterString) {
    filter.selected.forEach(option => removeStringFilter(filter.key, option))
    apply()
  }

  return appliedFilters.map(filter => (
    <FilterItem
      disabled={disabled}
      disabledTooltipText={disabledAreaFilterTooltipText}
      editButton={<AppliedFeedbackTypeFiltersEdit buttonDisabled={disabled} filter={filter} />}
      key={filter.key}
      name="Source"
      onDelete={() => removeFilter(filter)}
    />
  ))
}

export default AppliedFeedbackTypeFilters
