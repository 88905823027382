import { styled } from '@/theme'

import FlexContainer from '@/components/atoms/flex-container'

export const TopicGroupListContainer = styled('div', {
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  gap: '$xxs',
  overflow: 'hidden'
})

export const TopicGroupHeader = styled(FlexContainer, {
  '.area-options': {
    opacity: 0
  },

  '&:hover': {
    '.area-options': {
      opacity: 1
    }
  }
})

export const TopicGroupRow = styled(FlexContainer, {
  mb: '$xxxs',
  '.expand-icon': {
    cursor: 'pointer'
  },

  variants: {
    isExpanded: {
      true: {
        '.expand-icon': {
          transform: 'rotate(0deg)',
          transition: '.2s'
        }
      },
      false: {
        '.expand-icon': {
          transform: 'rotate(-90deg)'
        }
      }
    }
  }
})
