import useErrorScreens from '@/hooks/useErrorScreens'
import useLogging from '@/hooks/useLogging'
import useUser from '@/hooks/useUser'
import CustomFieldsService from '@/services/CustomFieldsServices'
import useToastMessageStore from '@/store/useToastMessageStore'
import { CustomFieldRecordType } from '@/types/integrations'
import { QueryFunctionContext, useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

export const GET_CUSTOM_FIELDS_V2_QUERY_KEY = 'get-custom-fields-v2'

interface Options {
  enabled?: boolean
}

const useCustomFieldsV2Query = ({ enabled = true }: Options) => {
  const { currentUser } = useUser()
  const { logException } = useLogging({ context: 'custom-fields-query' })

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { checkServerError, hasServerError } = useErrorScreens()

  const queryFn = async (
    params: QueryFunctionContext<
      [string, { source?: string; recordType?: CustomFieldRecordType; perPage?: number }]
    >
  ) => {
    const { pageParam, signal, queryKey } = params
    const { perPage, recordType, source } = queryKey[1]

    const [error, data] = await CustomFieldsService.getCustomFieldsMappings(
      currentUser?.organization_id || '',
      { cursor: pageParam, perPage, recordType, source },
      { signal }
    )

    if (error) {
      const message = `Failed to load custom fields.`
      logException(error, { message })
      addErrorToast({ text: message })
      checkServerError(error.errorInstance)
      throw error
    }

    return data
  }

  const queryKey = [GET_CUSTOM_FIELDS_V2_QUERY_KEY, { perPage: 15 }]

  const query = useInfiniteQuery({
    queryKey: [GET_CUSTOM_FIELDS_V2_QUERY_KEY, { perPage: 15 }],
    queryFn,
    getNextPageParam: lastPage => lastPage?.next_page,
    enabled,
    retry: false,
    refetchOnMount: false
  })

  const customFields = useMemo(() => {
    if (!query.data) return []
    const { pages } = query.data

    return pages.flatMap(page => page.mappings || [])
  }, [query.data])

  return { queryKey, customFields, hasServerError, ...query }
}

export default useCustomFieldsV2Query
