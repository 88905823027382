import { styled, CSS } from '@/theme'
import { ScrollAreaRoot, ScrollAreaViewport } from '../../scroll-area'
import { FloatingButton } from '@/components/atoms/button/FloatingButton'
import FlexContainer from '@/components/atoms/flex-container'

const FEEDBACK_MAX_WIDTH = 900

export const ExpandButton = styled(FloatingButton, {
  left: -12,
  top: 4,
  transition: 'cubic-bezier(.34,.19,.44,.85) .2s',

  variants: {
    visible: {
      true: {
        size: 24,
        opacity: 1
      },
      false: {
        size: 0,
        opacity: 0
      }
    }
  }
})

export const FeedbackListContainer = styled('div', {
  position: 'relative',
  width: '100%',
  paddingLeft: '$sm',
  paddingRight: 0,
  transition: '.2s',

  variants: {
    expanded: {
      true: {
        background: '$neutralHighLight'
      },
      false: {
        background: '$brandPrimaryLight'
      }
    }
  }
})

export const FeedScrollArea = styled(ScrollAreaRoot, {
  overflow: 'hidden',
  bottom: 0,
  height: '100%',
  width: '100%',
  pr: '$sm',
  alignSelf: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column'
})

export const FeedbackChatInfo = styled(FlexContainer, {
  alignItems: 'center',
  padding: '$xxs $sm',
  br: '$micro',
  bAll: '$neutralHighPure',
  background: '$neutralHighLight',
  gap: '$nano',
  mt: '$xs',
  mb: '$xxs',
  visibility: 'hidden',
  color: '$neutralLowPure',
  fontSize: '$xxxs',
  fontWeight: '$regular',
  lineHeight: '$default',
  maxWidth: FEEDBACK_MAX_WIDTH,
  mx: 'auto',

  '& svg': {
    minWidth: '$xxs'
  },

  '& a': {
    textDecoration: 'underline',
    color: '$neutralLowPure'
  },

  variants: {
    visible: {
      true: {
        visibility: 'visible',
        width: '100%'
      },
      false: {
        visibility: 'hidden',
        height: 0,
        width: 0,
        margin: 0,
        padding: 0,
        '& p, & svg': { visibility: 'hidden' },
        '& svg': {
          minWidth: 0
        }
      }
    },

    withTransition: {
      true: {
        transition: '.2s'
      }
    }
  }
})

export const FeedbackChatViewport = styled(ScrollAreaViewport, {
  // transition: '.2s',
  '& > div:first-child': {
    height: '100%'
  },
  variants: {
    showInfo: {
      true: {},
      false: {
        pt: '$xs'
      }
    },

    withTransition: {
      true: {
        transition: '.2s'
      }
    }
  }
})

export const feedbackItemCSS: CSS = {
  maxWidth: FEEDBACK_MAX_WIDTH,
  width: '100%',
  boxSizing: 'border-box',
  '&:first-of-type': {
    mt: '0px'
  }
}

export const FeedbackListInnerContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$xxs',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'stretch',
  scrollBehavior: 'smooth'
})
