import { Outlet } from 'react-router-dom'

import ErrorBoundary from '@/components/atoms/error-boundary'
import useUser from '@/hooks/useUser'
import NoPermission from '../no-permission/NoPermission'
import useLocalStorage from '@/hooks/useLocalStorage'
import { UPLOAD_PARAMS_KEY, UploadFormData } from '@/types/params/UploadParams'

const ManageCustomers = () => {
  const { userPermissions, currentUser } = useUser()
  const canViewImportCustomers = userPermissions.source.includes('customer_records')

  const [uploadParams] = useLocalStorage<UploadFormData>(UPLOAD_PARAMS_KEY, {
    enableImportCustomers: false,
    enableImportData: false
  })

  if ((!canViewImportCustomers && currentUser) || !uploadParams.enableImportCustomers) {
    return <NoPermission />
  }

  return (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  )
}

export default ManageCustomers
