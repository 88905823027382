import useDebounce from '@/hooks/useDebounce'
import { useState } from 'react'
import { FilterItem } from '@/types/filters/Filters'
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'
import { FilterItemDropdown } from '../../AppliedFilters/FilterItem'
import { capitalizeFirst } from '@/utils/letterCase'
import SearchInputForFilters from '../../SearchInputFilter'
import CustomerRecordsIdFilterContent from '../../FiltersSidebar/CustomerRecordsFilter/common/CustomerRecordsFilterContent'
import { IDFilterType } from '@/types/filters/AdvancedFilters'
import useCustomerRecordIdFilters from '../../FiltersSidebar/CustomerRecordsFilter/Accounts/useCustomerRecordIdFilters'

interface Props {
  title: string
  disabled?: boolean
  name: string
  path: string
  value: string[]
  index: number
  type: IDFilterType
}

const CustomerRecordIdFilterApplied = ({
  title,
  name,
  path,
  value = [],
  disabled = false,
  index,
  type
}: Props) => {
  const [searchValue, setSearchValue] = useState('')
  const [open, setOpen] = useState(false)

  const debouncedSearch = useDebounce(searchValue, 350)

  const filter: FilterItem = {
    type,
    name,
    path,
    displayName: title,
    key: name
  }

  const {
    apply,
    fetchNextPage,
    hasChanges,
    hasNextPage,
    isChecked,
    isFetchingNextPage,
    isLoading,
    isShowMoreDisabled,
    options,
    selectOption
  } = useCustomerRecordIdFilters({
    customerRecordType: type === 'accounts-id' ? 'account' : 'user',
    enabled: open,
    filter,
    searchQuery: debouncedSearch,
    newFeed: true,
    index,
    initialValues: value
  })

  useDidUpdateEffect(() => {
    if (!open) {
      setSearchValue('')
    }

    return () => {
      setSearchValue('')
    }
  }, [open])

  const onChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value)
  }

  const onKeyUp: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  const onClear = () => {
    setSearchValue('')
  }

  const count = value.length
  const formatter = capitalizeFirst
  const valueTextArray = value.map(v => `<b>${formatter(v)}</b>`).join(' or ')
  const text = `Is ${valueTextArray}`

  return (
    <FilterItemDropdown
      buttonDisabled={disabled}
      content={count}
      contentType="count"
      disabled={isLoading || disabled || !hasChanges}
      dropdown={
        <>
          <SearchInputForFilters
            clearable
            css={{ mb: '$micro' }}
            onChange={onChange}
            onClear={onClear}
            onKeyUp={onKeyUp}
            placeholder="Search"
            size="small"
            small
            value={searchValue}
          />
          <CustomerRecordsIdFilterContent
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isChecked={isChecked}
            isFetchingNextPage={isFetchingNextPage}
            isShowMoreDisabled={isShowMoreDisabled}
            key={filter.key}
            options={options}
            selectOption={selectOption}
          />
        </>
      }
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      title={title}
      tooltip={text}
    />
  )
}

export default CustomerRecordIdFilterApplied
