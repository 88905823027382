// https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen

interface OpenPopupParams {
  url: string
  width: number
  height: number
}

/** Open a popup window on the center */
function openPopup({ url, width: w, height: h }: OpenPopupParams) {
  const dualScreenLeft = window.screenLeft ?? window.screenX
  const dualScreenTop = window.screenTop ?? window.screenY

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height

  const systemZoom = width / window.screen.availWidth
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop

  const strWindowFeatures = `toolbar=no, menubar=no, width=${w}, height=${h}, top=${top}, left=${left}`
  return window.open(url, 'popup', strWindowFeatures)
}

export default openPopup
