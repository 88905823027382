import FlexContainer from '@/components/atoms/flex-container'
import BirdieLogo from '@/assets/birdie_logo.svg'
import { styled } from '@/theme'
import Button from '@/components/atoms/button'
import { useTranslation } from 'react-i18next'

const BirdieLogoImg = styled('img', {
  height: 40
})

const ParagraphText = styled('p', {
  fontSize: '$xxxs',
  fontWeight: '$light',
  textAlign: 'center',
  lineHeight: '24px',
  color: '$neutralLowLight',

  strong: {
    fontWeight: 'inherit',
    color: '$neutralLowPure'
  }
})

const SlackSuccessPage = () => {
  const onCloseWindowClick = () => {
    window.close()
  }
  const { t } = useTranslation()

  return (
    <FlexContainer
      alignItems="center"
      css={{ mt: '-$sm', width: '100vw' }}
      direction="column"
      fullHeight
      fullWidth
      gap="xxs"
      justifyContent="center"
    >
      <BirdieLogoImg alt="Birdie logo" src={BirdieLogo} />
      <ParagraphText>
        <strong>{t('allSetYouSuccessfullyConnectedToSlack')}</strong>
        <br />
        {t('youMayNowCloseThisWindow')}
      </ParagraphText>
      <Button css={{ mt: '$xxs' }} onClick={onCloseWindowClick}>
        {t('closeWindow')}
      </Button>
    </FlexContainer>
  )
}

export default SlackSuccessPage
