import TextComponent from '@/components/atoms/text'
import Tooltip from '@/components/atoms/tooltip'
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'
import useUser from '@/hooks/useUser'
import { useUserStore } from '@/store'
import useToastMessageStore from '@/store/useToastMessageStore'
import { UserPlus } from '@phosphor-icons/react'
import { useMemo, useState, Suspense, lazy, useEffect, useCallback, useRef } from 'react'
import { shallow } from 'zustand/shallow'
import { MultipleUsersTopbar, UsersTopbarContent, UserInitial } from './TopbarContent.styles'
import { useTranslation } from 'react-i18next'

const ManageUsersModal = lazy(() => import('./ManageUsersModal'))

interface Props {
  home?: boolean
}

function ManageUsersTopbar({ home = false }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { t } = useTranslation()
  const users = useUserStore(state => state.users, shallow)
  const currentUser = useUserStore(state => state.currentUser, shallow)
  const loadUsers = useUserStore(state => state.loadUsers)
  const setIsLoadingInviter = useUserStore(state => state.setIsLoadingInviter)

  const { userPermissions } = useUser()
  const isManager = userPermissions.user.includes('manager')

  const addToast = useToastMessageStore(state => state.addToast)
  const alreadyOpenRef = useRef(false)

  const getInvitedUsers = useCallback(async () => {
    setIsLoadingInviter(true)
    try {
      await loadUsers()
    } catch (error) {
      console.error(error)
      if (isModalOpen) {
        addToast({
          id: 'load-inviter-error',
          text: t('failedToLoadInvites'),
          open: true,
          status: 'error'
        })
      }
    } finally {
      setIsLoadingInviter(false)
    }
  }, [setIsLoadingInviter, loadUsers, addToast, isModalOpen, t])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    getInvitedUsers()
  }, [])

  useDidUpdateEffect(() => {
    if (isModalOpen) {
      if (!alreadyOpenRef.current) {
        alreadyOpenRef.current = true
        return
      }

      getInvitedUsers()
    }
  }, [isModalOpen])

  const firstUsers = useMemo(() => {
    return users
      .filter(
        invitedUser => !invitedUser.first_access && invitedUser.user_id !== currentUser?.user_id
      )
      .slice(0, 2)
  }, [users, currentUser])

  const extraUsersCount = useMemo(() => {
    return users
      .filter(
        invitedUser => !invitedUser.first_access && invitedUser.user_id !== currentUser?.user_id
      )
      .slice(2).length
  }, [users, currentUser])

  const onClickAddUser = () => {
    if (!isManager) return
    setIsModalOpen(true)
  }

  return (
    <>
      <UsersTopbarContent disabled={!isManager} onClick={onClickAddUser}>
        {firstUsers.length > 0 && (
          <MultipleUsersTopbar home={home}>
            {isManager ? (
              <Tooltip side="bottom" text={t('addUser')}>
                <UserPlus className="add-user-icon" size={20} weight="bold" />
              </Tooltip>
            ) : null}
            {firstUsers.map((user, index) => (
              <Tooltip key={user.user_id} side="bottom" text={user.name}>
                <UserInitial
                  className="user-inital-circle"
                  css={{ zIndex: 5 - index }}
                  key={user.user_id}
                >
                  {user.name.trim().at(0)}
                </UserInitial>
              </Tooltip>
            ))}

            {extraUsersCount > 0 && (
              <UserInitial className="extra-users-count user-inital-circle">
                +{extraUsersCount}
              </UserInitial>
            )}
          </MultipleUsersTopbar>
        )}

        {!firstUsers.length && isManager && (
          <>
            <UserPlus className="add-user-icon" size={16} weight="bold" />
            <TextComponent fontSize="xxxs" fontWeight="regular" lineHeight="xs">
              {t('inviteUsers')}
            </TextComponent>
          </>
        )}
      </UsersTopbarContent>

      <Suspense fallback={<></>}>
        {isModalOpen && <ManageUsersModal onOpenChange={setIsModalOpen} open={isModalOpen} />}
      </Suspense>
    </>
  )
}

export default ManageUsersTopbar
