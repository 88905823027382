import {
  capitalizeFirst as capitalizeFirstFn,
  snakeToTitle as snakeToTitleFn,
  camelToSnakeCase
} from '@/utils/letterCase'
import { useCallback } from 'react'

function useLetterCase() {
  const snakeToTitle = useCallback(snakeToTitleFn, [])

  const capitalizeFirst = useCallback(capitalizeFirstFn, [])

  const cleanTextForRegex = useCallback((value: string) => {
    if (!value) return ''
    return value
      .replaceAll('\\', '')
      .replace(/(\()/gim, '\\(')
      .replace(/(\))/gim, '\\)')
      .replace(/(\?)/gim, '\\?')
      .replace(/(\\!)/gim, '\\!')
      .replace(/(\/)/gim, '\\/')
      .replace(/(\+)/gim, '\\+')
      .replace(/(\\-)/gim, '\\-')
      .replace(/(\.)/gim, '\\.')
      .replace(/(\[)/gim, '\\[')
      .replace(/(\])/gim, '\\]')
      .replace(/(\$)/gim, '\\$')
      .replace(/(\*)/gim, '')
  }, [])

  const shortUrls = useCallback((value: string) => {
    if (!value) return ''
    const urlRegex = /(?<alt>!\[[^\]]*\])\((?<filename>.*?)(?="|\))\)/gim // it only for images

    return value.replace(urlRegex, '')
  }, [])

  return {
    snakeToTitle,
    camelToSnakeCase,
    capitalizeFirst,
    cleanTextForRegex,
    shortUrls
  }
}

export default useLetterCase
