import { FilterNode, GenericFilter } from '@/types/filters/AdvancedFilters'
import { FilterItemButton } from '../AppliedFilters/FilterItem'
import { shallow } from 'zustand/shallow'
import { useUIStore } from '@/store'
import { useMemo } from 'react'

interface Props {
  filterNode: FilterNode
  disabled?: boolean
}

const AdvancedFilterSetApplied = ({ filterNode, disabled }: Props) => {
  const setIsCreateModalOpen = useUIStore(state => state.setIsCreateModalOpen, shallow)

  const length = (filterNode.value as (FilterNode | GenericFilter)[]).length

  const text = useMemo(() => {
    if (filterNode.operator === '$not') {
      return 'unmapped feedback'
    }

    return length + ' ' + (length > 1 ? 'rules' : 'rule')
  }, [length, filterNode])

  const onClick = () => {
    setIsCreateModalOpen(true)
  }

  return (
    <FilterItemButton
      content={text}
      contentType="count"
      disabled={disabled}
      onClick={onClick}
      tooltip={text}
    />
  )
}

export default AdvancedFilterSetApplied
