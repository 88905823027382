import useUser from '@/hooks/useUser'
import { Suspense, lazy, useState } from 'react'
import Drawing from '@/assets/drawings/assistant_bird.png'
import BalloonSvg from '@/assets/drawings/ask_birdie.svg'
import {
  AssistantBallonImage,
  AssistantImageCircle,
  AssistantOptionButton,
  AssistantOptionsContainer
} from './Assistant.styles'
import { useUIStore } from '@/store'
import useSegment from '@/hooks/useSegment'
import { useNavigate } from 'react-router-dom'

interface Props {
  onOpenFeedback?: () => void
  onOpenAssistant?: () => void
}

const RelatedFeedbackModal = lazy(() => import('./RelatedFeedbackModal'))
const AssistantModal = lazy(() => import('./AssistantModal'))

const AskBirdieFloatingButton = (props: Props) => {
  const { track } = useSegment()

  const { userPermissions, currentUser } = useUser()
  const [open, setOpen] = useState(false)

  const onImageClick = () => {
    setOpen(!open)
  }

  const [feedbackOpen, setFeedbackOpen] = useState(false)

  const onFeedbackClick = () => {
    track('explore_user_copilot_feedback')
    setOpen(false)
    setFeedbackOpen(true)
    props.onOpenFeedback?.()
  }

  const setIsAssistantOpen = useUIStore(state => state.setAssistantOpen)
  const isAssistantOpen = useUIStore(state => state.isAssistantOpen)

  const onAssistantClick = () => {
    track('explore_user_copilot_ask')
    setOpen(false)
    setIsAssistantOpen(true)
    props.onOpenAssistant?.()
  }

  const navigate = useNavigate()

  const onClickExploreFeedback = () => {
    track('explore_user_copilot_drilldown')
    navigate('/analytics/explore-feedback')
  }

  if (!userPermissions.feedback.includes('chat') && currentUser) {
    return <></>
  }

  return (
    <>
      <AssistantBallonImage
        alt={'A speech balloon written: "Ask Birdie"'}
        src={BalloonSvg}
        visible={!open}
      />
      <AssistantImageCircle
        alt="A drawing of a blue bird"
        onClick={onImageClick}
        role="button"
        src={Drawing}
      />
      <AssistantOptionsContainer visible={open}>
        <AssistantOptionButton onClick={onFeedbackClick}>
          See related feedback
        </AssistantOptionButton>

        <AssistantOptionButton onClick={onAssistantClick}>Ask a question</AssistantOptionButton>

        <AssistantOptionButton onClick={onClickExploreFeedback}>
          Explore feedback
        </AssistantOptionButton>
      </AssistantOptionsContainer>

      <Suspense>
        {feedbackOpen && (
          <RelatedFeedbackModal onOpenChange={setFeedbackOpen} open={feedbackOpen} />
        )}
      </Suspense>

      <Suspense>
        {isAssistantOpen && (
          <AssistantModal onOpenChange={setIsAssistantOpen} open={isAssistantOpen} />
        )}
      </Suspense>
    </>
  )
}

export default AskBirdieFloatingButton
