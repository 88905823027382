import ToastMessagesProvider from '@/components/molecules/ToastMessagesProvider'
import { useUIStore } from '@/store'
import { useEffect } from 'react'
import SideExplore from '@/components/molecules/side-explore/SideExplore'
import FiltersHeader from '@/components/molecules/filters/FiltersHeader'
import useUser from '@/hooks/useUser'
import NoPermission from '@/components/molecules/no-permission/NoPermission'
import { ExploreContainer, FeedContainer, PageContainer } from './styles'
import FeedTabsContent from '@/components/molecules/feed/FeedTabsContent'
import { TopicList } from '@/components/molecules/feed/topics'
import TopicsActionBar from '@/components/molecules/feed/topics/TopicsActionBar'
import useSegment from '@/hooks/useSegment'

function TopicsPage() {
  const { setPageTitle } = useUIStore()
  const { track } = useSegment()

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen once
  useEffect(() => {
    setPageTitle('Topic Management')
    track('explore_user_keywords_tab')
  }, [])

  const { userPermissions, currentUser } = useUser()
  if (userPermissions.topic.length === 0 && currentUser) {
    return <NoPermission />
  }

  return (
    <PageContainer>
      <FiltersHeader searchPlaceholder="Search for topics" />
      <ExploreContainer>
        <SideExplore useTopicClassification />
        <FeedContainer>
          <FeedTabsContent id="topics" noDefaultScroll>
            <TopicList />
            <TopicsActionBar />
          </FeedTabsContent>
        </FeedContainer>
        <ToastMessagesProvider />
      </ExploreContainer>
    </PageContainer>
  )
}

export default TopicsPage
