import useLetterCase from '@/hooks/useLetterCase'
import { FilterBoolean } from '@/types/filters/Filters'
import { CustomerRecordType } from '@/types/manage-customers/CustomerRecordsFilters'
import { useMemo, useState } from 'react'
import useCustomerRecordsBooleanGenericFilter from '../../FiltersSidebar/CustomerRecordsFilter/common/useCustomerRecordsBooleanFilter'
import FilterItemDropdown from './FilterItemDropdown'
import { BooleanFilterContent } from '../../FiltersSidebar/BooleanGenericFilter'

interface Props {
  filter: FilterBoolean
  recordType: CustomerRecordType
}

function FilterItemDropdownCustomerRecordsBoolean(props: Props) {
  const { snakeToTitle } = useLetterCase()

  const [enabled, setEnabled] = useState(false)

  const { selectedOption, onOptionChange, onApply, isDisabled } =
    useCustomerRecordsBooleanGenericFilter({
      filterKey: props.filter.key,
      type: props.filter.type,
      name: props.filter.name,
      recordType: props.recordType
    })

  const value = useMemo(() => (props.filter.value ? 'Checked' : 'Unchecked'), [props.filter])
  const tooltipText = useMemo(
    () => `Is ${snakeToTitle(props.filter.name)} > ${value}`,
    [props.filter, value, snakeToTitle]
  )

  return (
    <FilterItemDropdown
      content={value}
      contentType="label"
      disabled={isDisabled}
      dropdown={
        <BooleanFilterContent onOptionChange={onOptionChange} selectedOption={selectedOption} />
      }
      onApply={onApply}
      onOpenChange={setEnabled}
      open={enabled}
      title={snakeToTitle(props.filter.name)}
      tooltip={tooltipText}
    />
  )
}

export default FilterItemDropdownCustomerRecordsBoolean
