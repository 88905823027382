import { colors, savedViewBarHeight, topBarHeight } from '@/theme'
import ClassificationSkeleton from '../ClassificationSkeleton'
import { useMemo, useState } from 'react'
import { DeleteDialog } from '@/components/atoms/dialog'
import {
  CollapsibleRoot,
  CollapsibleTrigger,
  CollapsibleContentAnimated
} from '@/components/atoms/collapsible'
import { TopicCategory, TopicDataWithCategory } from '@/types/classification'
import { CaretDown } from '@phosphor-icons/react'
import Checkbox, { CheckboxState } from '@/components/atoms/checkbox'
import { shallow } from 'zustand/shallow'
import {
  TopicGroupHeader,
  TopicGroupListContainer,
  TopicGroupRow
} from './TopicGroupCollapse.styles'
import Text from '@/components/atoms/text'
import ClassificationOptions from '../ClassificationOptions'
import TopicGroupItem from './TopicGroupItem'
import AddTopicGroup from './AddTopicGroup'
import useTopicsStore from '@/store/useTopicsStore'
import { useTopics } from '@/hooks/useTopics/useTopics'
import FlexContainer from '@/components/atoms/flex-container'
import { useTranslation } from 'react-i18next'

const CATEGORIES: TopicCategory[] = ['PRODUCT_AREA', 'OTHER']

const getCategoryTitle = (category: TopicCategory) => {
  if (category === 'PRODUCT_AREA') return 'Product Areas'
  return 'Other Topics'
}

const TopicGroupCollapse = () => {
  const [topicToDelete, setTopicToDelete] = useState<string | undefined>()
  const [categoriesOpen, setCategoriesOpen] = useState<TopicCategory[]>([])

  const selectTopicGroups = useTopicsStore(state => state.selectTopicGroup)
  const unselectTopicGroups = useTopicsStore(state => state.unselectTopicGroup)
  const setDebounceLoading = useTopicsStore(state => state.setDebounceLoading)
  const { t } = useTranslation()

  const { removeTopicGroup, reset } = useTopics()

  const { selectedTopicGroups } = useTopicsStore(
    state => ({
      selectedTopicGroups: state.selectedTopicGroups
    }),
    shallow
  )
  const isLoadingTopicGroups = useTopicsStore(state => state.isLoadingTopicGroups)

  const storedTopicGroups = useTopicsStore(state => state.topicGroups)
  const topicGroups = useMemo(() => {
    return storedTopicGroups.map(group => ({
      ...group,
      category: group.category || 'OTHER'
    })) as TopicDataWithCategory[]
  }, [storedTopicGroups])

  const isCategoryOpen = (category: TopicCategory) => categoriesOpen.includes(category)
  const onOpenCategory = (category: TopicCategory) => (open: boolean) => {
    if (open) {
      setCategoriesOpen(prev => [...prev, category])
    } else {
      setCategoriesOpen(prev => prev.filter(cat => cat !== category))
    }
  }

  const onOpenDeleteChange = (open: boolean) => {
    setTopicToDelete(prevTopic => (open ? prevTopic : undefined))
  }

  const onConfirmDelete = () => {
    setTopicToDelete(undefined)
    topicToDelete && removeTopicGroup(topicToDelete)
  }

  const mapCategoriesToTopics: Record<TopicCategory, TopicDataWithCategory[]> = useMemo(() => {
    const _map: Record<TopicCategory, TopicDataWithCategory[]> = { PRODUCT_AREA: [], OTHER: [] }
    _map.PRODUCT_AREA = topicGroups.filter(group => group.category === 'PRODUCT_AREA')
    _map.OTHER = topicGroups.filter(group => group.category === 'OTHER')

    return _map
  }, [topicGroups])

  const onSelectCategory = (category: TopicCategory) => (checked: boolean) => {
    const categoryTopicGroups = mapCategoriesToTopics[category].map(
      group => group.topicId
    ) as string[]

    if (checked) {
      selectTopicGroups(categoryTopicGroups)
    } else {
      unselectTopicGroups(categoryTopicGroups)
    }

    reset()
    setDebounceLoading(true)
  }

  const getCategoryStatus = (category: TopicCategory): CheckboxState => {
    const categoryTopics = mapCategoriesToTopics[category]
    if (!categoryTopics.length) return false

    const isIndeterminated = categoryTopics.some(topic =>
      selectedTopicGroups.includes(topic.topicId || '')
    )
    const isSelected = categoryTopics.every(topic =>
      selectedTopicGroups.includes(topic.topicId || '')
    )

    if (!isSelected && isIndeterminated) return 'indeterminate'

    return isSelected
  }

  return (
    <TopicGroupListContainer>
      <TopicGroupHeader alignItems="center" gap="micro">
        <Text as="p" color="neutralLowPure" fontSize="xxxs" fontWeight="bold">
          {t('topicGroups')}
        </Text>
        <span className="area-options">
          <ClassificationOptions />
        </span>
      </TopicGroupHeader>
      {isLoadingTopicGroups ? (
        <ClassificationSkeleton />
      ) : (
        <>
          {CATEGORIES.map(category => {
            const categoryState = getCategoryStatus(category)
            const isCategorySelected = categoryState !== 'indeterminate' && !!categoryState

            return (
              <CollapsibleRoot
                key={category}
                onOpenChange={onOpenCategory(category)}
                open={isCategoryOpen(category)}
              >
                <TopicGroupRow
                  alignItems="center"
                  gap={'micro'}
                  isExpanded={isCategoryOpen(category)}
                >
                  <CollapsibleTrigger asChild>
                    <CaretDown
                      className="expand-icon"
                      color={colors.neutralLowPure}
                      size={16}
                      weight="bold"
                    />
                  </CollapsibleTrigger>

                  <Checkbox
                    checked={getCategoryStatus(category)}
                    css={{ margin: 0 }}
                    disabled={mapCategoriesToTopics[category].length === 0}
                    labelClickDisabled
                    onCheckedChange={onSelectCategory(category)}
                    text={getCategoryTitle(category)}
                    textProps={{ css: { fontWeight: '$semibold' } }}
                    value={category}
                  />
                </TopicGroupRow>

                <CollapsibleContentAnimated>
                  <FlexContainer
                    css={{
                      overflowY: 'auto',
                      flex: 1,
                      maxHeight:
                        categoriesOpen.length > 1
                          ? `calc((100vh - ${savedViewBarHeight + topBarHeight + 380}px)/2)`
                          : `calc(100vh - ${savedViewBarHeight + topBarHeight + 380}px)`
                    }}
                    direction="column"
                    gap="xxxs"
                  >
                    {mapCategoriesToTopics[category].map(group => (
                      <TopicGroupItem
                        key={group.topicId}
                        {...group}
                        isThemeSelected={isCategorySelected}
                        onClickRemove={setTopicToDelete}
                      />
                    ))}
                  </FlexContainer>

                  <AddTopicGroup
                    buttonCSS={{ mt: '$nano', mb: '$xxxs' }}
                    css={{ ml: '$xxxs' }}
                    topicCategory={category}
                  />
                </CollapsibleContentAnimated>
              </CollapsibleRoot>
            )
          })}
        </>
      )}

      {!!topicToDelete && (
        <DeleteDialog
          confirmText={t('delete')}
          description={t(
            'theTopicsAssociatedWithThisGroupWillBeRemovedFromHereButTheyWontBeAffected'
          )}
          isDeleting={false}
          onConfirmDelete={onConfirmDelete}
          onOpenChange={onOpenDeleteChange}
          open={Boolean(topicToDelete)}
          title={t('deleteTopicGroupQuestion')}
        />
      )}
    </TopicGroupListContainer>
  )
}

export default TopicGroupCollapse
