import { useEffect, useMemo } from 'react'

import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import FeedSummary from '@/components/molecules/exploration/summary'
import FeedbackListExploration from '@/components/molecules/exploration/feedback/FeedbackListExploration'
import DraftAlert from '@/components/molecules/opportunity-details/DraftAlert'
import ExplorationIndicators from '@/components/molecules/exploration/indicators/ExplorationIndicators'
import OpportunityMetricsTrendline from '@/components/molecules/metrics-trendline/OpportunityMetricsTrendline'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import CollapsableEditableText from '@/components/molecules/tiptap/CollapsableEditableText'
import useOpportunitiesQuery from '@/hooks/opportunity/useOpportunitiesQuery'
import { useUIStore } from '@/store'
import { shallow } from 'zustand/shallow'
import useSegment from '@/hooks/useSegment'
import usePageLink from '@/hooks/usePageLink'
import AffectedAreas from '@/components/molecules/exploration/AffectedAreas'
import useOpportunityStore from '@/store/useOpportunityStore'
import OpportunityRelatedSegments from '@/components/molecules/segmentations/OpportunityRelatedSegments'
import useLocalStorage from '@/hooks/useLocalStorage'
import { OPPORTUNITY_PLAN_KEY } from '@/types/params/OpportunityPlanParams'
import { defaultOpportunityPlanFormData } from '../params'
import FlexContainer from '@/components/atoms/flex-container'
import { useTranslation } from 'react-i18next'
import Text from '@/components/atoms/text'
import Button from '@/components/atoms/button'
import { WarningCircle } from '@phosphor-icons/react'
import { OpportunityStatus } from '@/types/opportunity/Opportunity'
import shortUUID from 'short-uuid'
import { useNavigate } from 'react-router-dom'

const OpportunityPage = () => {
  const { applyFilterFromArea, context } = useAdvancedFilters()
  const currentAreaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const translator = useMemo(() => shortUUID(), [])

  const opportunity = useOpportunityStore(state => state.currentOpportunity)
  const setCurrentOpportunity = useOpportunityStore(state => state.setCurrentOpportunity)

  const descriptionEditOpen = useUIStore(state => state.descriptionEditOpen, shallow)
  const setDescriptionEditOpen = useUIStore(state => state.setDescriptionEditOpen)

  const isCalibrated = useMemo(() => {
    return (
      opportunity?.status !== OpportunityStatus.Processing &&
      opportunity?.status !== OpportunityStatus.Draft &&
      !opportunity?.internal_review
    )
  }, [opportunity?.status, opportunity?.internal_review])

  usePageLink()

  const { track } = useSegment()

  const { updateOpportunity } = useOpportunitiesQuery({
    area: currentAreaOfInterest,
    enabled: false
  })

  const saveDescription = (description: string) => {
    if (!opportunity) return
    updateOpportunity(
      { ...opportunity, description },
      {
        onSuccess: () => {
          track('opportunity_edit_description_save', { name: opportunity.name, id: opportunity.id })
        }
      }
    )
  }

  const onExitOpportunity = () => {
    setCurrentOpportunity(undefined)
  }

  const onCompleteCalibration = () => {
    const shortOpportunityId = translator.fromUUID(opportunity?.id ?? '')
    navigate(`/opportunity/${shortOpportunityId}/calibration`)
    track('calibrate_opp_complete_calibration')
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    if (currentAreaOfInterest && !context) {
      applyFilterFromArea(currentAreaOfInterest)
    }

    return onExitOpportunity
  }, [])

  const trackSourceChange = (sourceKey: string | undefined) => {
    track('opportunity_source_filter_on_feedback', { source_key: sourceKey })
  }

  const trackExportFeedback = () => {
    if (!opportunity) return
    track('opportunity_export_feedback')
  }

  const trackMetricScroll = (scroll: string) => {
    track('opportunity_metrics_scroll', { scroll })
  }

  const [opportunityPlanParams] = useLocalStorage(
    OPPORTUNITY_PLAN_KEY,
    defaultOpportunityPlanFormData
  )

  if (!opportunity) {
    return <></>
  }

  return (
    <>
      <CollapsableEditableText
        content={opportunity.description ?? ''}
        editable={descriptionEditOpen}
        onEditableChange={setDescriptionEditOpen}
        onSave={saveDescription}
      />
      {opportunity.relations.length > 0 && <AffectedAreas />}

      {isCalibrated ? (
        <>
          <ExplorationIndicators onTrackScroll={trackMetricScroll} />
          <FeedSummary />
          {opportunityPlanParams.enableSegments && <OpportunityRelatedSegments />}
          <OpportunityMetricsTrendline />
          <DraftAlert />
          <FeedbackListExploration
            trackExportFeedback={trackExportFeedback}
            trackSourceChange={trackSourceChange}
          />
        </>
      ) : (
        <FlexContainer justifyContent="spaceBetween" alignItems="center" fullWidth>
          <FlexContainer gap="micro" alignItems="center">
            <WarningCircle size={16} color="#D46E68" />
            <Text fontWeight="bold" color="feedbackNegativePure">
              {opportunity.internal_review
                ? t('calibrateOpportunityReviewAlertBody')
                : t('calibrateOpportunityAlertBody')}
            </Text>
          </FlexContainer>
          {!opportunity.internal_review && (
            <Button
              text
              onClick={onCompleteCalibration}
              css={{ border: 'none', fontWeight: '$semibold' }}
            >
              {t('calibrateOpportunityAlertAction')}
            </Button>
          )}
        </FlexContainer>
      )}
    </>
  )
}

export default OpportunityPage
