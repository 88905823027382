import Select from '@/components/atoms/select'
import UploadFile from '@/components/atoms/upload-file'
import { useEffect, useState } from 'react'
import { CSVBoxButton } from '@csvbox/react'
import { FeedbackSource } from '@/types/feedbacks'
import { CSVBoxData, FormUploadValues } from '@/types/upload-csv/upload-form'
import Text from '@/components/atoms/text'
import Loader from '@/components/atoms/loader'
import FlexContainer from '@/components/atoms/flex-container'
import sampleFile from '@/assets/samples/Birdie_sample_file.csv'
import useSegment from '@/hooks/useSegment'
import { HelperText, QuestionItem } from './FormUpload.styles'
import useToastMessageStore from '@/store/useToastMessageStore'
import useCSVUpload from './useCSVUpload'
import { useTranslation } from 'react-i18next'
import i18n from '../../../plugins/i18n/i18n'

const defaultFormValues: FormUploadValues = {
  feedbackSource: undefined,
  csvData: undefined
}

const newFeedbackSources: FeedbackSource[] = [
  {
    id: 'nps',
    name: 'NPS'
  },
  {
    id: 'csat',
    name: 'CSAT'
  },
  {
    id: 'review',
    name: 'Reviews'
  },
  {
    id: 'support_ticket',
    name: i18n.t('supportTickets')
  },
  {
    id: 'issue',
    name: 'Issues'
  },
  {
    id: 'social_media_post',
    name: i18n.t('socialMediaPosts')
  },
  {
    id: 'transcript',
    name: i18n.t('meetingTranscripts')
  }
]

interface FormUploadProps {
  feedbackSources?: FeedbackSource[]
  onSubmit?: (data: FormUploadValues) => void
  resetAfterSubmit?: boolean
  showSampleFile?: boolean
}

const FormUpload = ({ onSubmit, feedbackSources = [], resetAfterSubmit }: FormUploadProps) => {
  const { track } = useSegment()
  const { t } = useTranslation()

  const [values, setValues] = useState(defaultFormValues)
  const [showImport, setShowImport] = useState(false)
  const [uploadReady, setUploadReady] = useState(false)

  const addToast = useToastMessageStore(state => state.addToast)

  const feedbackSourceList = [...newFeedbackSources, ...feedbackSources]

  useEffect(() => {
    // call onSubmit whenever the user complete both form fields
    if (values.feedbackSource && values.csvData) {
      onSubmit && onSubmit(values)

      if (resetAfterSubmit) {
        setValues(defaultFormValues)
        setUploadReady(false)
      }
    }
  }, [values, resetAfterSubmit, onSubmit])

  useEffect(() => {
    if (values.feedbackSource && !showImport) {
      setShowImport(true)
      setUploadReady(false)
    }
  }, [showImport, values.feedbackSource])

  const getFeedbackSourceById = (id: string) => feedbackSourceList.find(fs => fs.id === id)

  const handleSelectChange = (value: string) => {
    setShowImport(!values.feedbackSource)
    setValues(prev => ({ ...prev, feedbackSource: getFeedbackSourceById(value) }))
  }

  const onImportData = (result: boolean, csvData?: CSVBoxData) => {
    if (!result) {
      addToast({ id: 'upload-failed', text: t('failedToImport'), status: 'error', open: true })
      return
    }

    addToast({
      id: 'upload-success',
      text: t('importFinishedSuccessfully'),
      status: 'success',
      open: true
    })
    track('sources_user_upload_data-upload_your_file')
    setValues(prev => ({ ...prev, csvData }))
  }

  const onTemplateFileClick = () => {
    track('sources_user_upload_data-template_file_download')
  }

  const { licenceKey, customData } = useCSVUpload(values.feedbackSource)

  return (
    <form>
      <QuestionItem direction="column">
        <Select
          contentAlign="start"
          onValueChange={handleSelectChange}
          options={feedbackSourceList.map(source => ({
            text: source.name,
            value: source.id
          }))}
          placeholder={t('chooseHere')}
          value={values.feedbackSource?.id}
          width="100%"
        />
      </QuestionItem>
      {values.feedbackSource && showImport && (
        <QuestionItem>
          <CSVBoxButton
            licenseKey={licenceKey}
            onImport={onImportData}
            onReady={() => setUploadReady(true)}
            render={(launch: () => void) => {
              return !uploadReady ? (
                <FlexContainer
                  alignItems="center"
                  css={{ gap: 8, padding: 48, bAll: 'transparent' }}
                  direction="column"
                  justifyContent="center"
                >
                  <Loader />
                  <Text typeface="subtitleRegularXXXS">{t('loadingImporter')}</Text>
                </FlexContainer>
              ) : (
                <UploadFile onClickButton={launch} overrideDefaultController />
              )
            }}
            user={customData}
          />
        </QuestionItem>
      )}
      <QuestionItem>
        <HelperText>
          {t('dontKnowHowYourFileShouldBeQuestion')}
          <a
            download
            href={sampleFile}
            onClick={onTemplateFileClick}
            rel="noreferrer"
            target="_blank"
          >
            {t('downloadOurTemplate')}
          </a>{' '}
          {t('orSendYourDoubtsToUsOnTheChat')}
        </HelperText>
      </QuestionItem>
    </form>
  )
}

export default FormUpload
