import { FilterComponentProps } from './types'
import i18n from '../../../../../plugins/i18n/i18n'
import { filterToSentiment, Sentiment, sentimentToFilter } from '@/utils/sentiments'
import Select, { SelectOption } from '@/components/atoms/select'
import { SentimentFilterOption } from '@/types/filters/AdvancedFilters'

const sentimentOptions: SelectOption<SentimentFilterOption>[] = [
  {
    value: 'positive',
    text: i18n.t('positive')
  },
  {
    value: 'neutral',
    text: i18n.t('neutral')
  },
  {
    value: 'negative',
    text: i18n.t('negative')
  }
]

const positiveOptions: SelectOption<Sentiment>[] = [
  {
    value: Sentiment.OnlyPositive,
    text: i18n.t('onlyPositive')
  },
  {
    value: Sentiment.MostlyPositive,
    text: i18n.t('mostlyPositive')
  },
  {
    value: Sentiment.AnyPositive,
    text: i18n.t('anyPositive')
  }
]

const neutralOptions: SelectOption<Sentiment>[] = [
  {
    value: Sentiment.OnlyNeutral,
    text: i18n.t('onlyNeutral')
  },
  {
    value: Sentiment.MostlyNeutral,
    text: i18n.t('mostlyNeutral')
  },
  {
    value: Sentiment.AnyNeutral,
    text: i18n.t('anyNeutral')
  }
]

const negativeOptions: SelectOption<Sentiment>[] = [
  {
    value: Sentiment.OnlyNegative,
    text: i18n.t('onlyNegative')
  },
  {
    value: Sentiment.MostlyNegative,
    text: i18n.t('mostlyNegative')
  },
  {
    value: Sentiment.AnyNegative,
    text: i18n.t('anyNegative')
  }
]

const options: Record<SentimentFilterOption, SelectOption<Sentiment>[]> = {
  positive: positiveOptions,
  neutral: neutralOptions,
  negative: negativeOptions
}

const SentimentFilterRow = ({ filter, onFilterChange }: FilterComponentProps) => {
  const sentimentValue = filterToSentiment(filter)

  const option = filter.option as SentimentFilterOption | undefined
  const filterOptions = option ? options[option] : []

  const onOptionChange = (v: SentimentFilterOption) => {
    onFilterChange(sentimentToFilter(options[v][0].value))
  }

  const onValueChange = (value: Sentiment) => {
    onFilterChange(sentimentToFilter(value))
  }

  return (
    <>
      <Select
        onValueChange={onOptionChange}
        options={sentimentOptions}
        small
        value={option}
        width="100%"
      />
      <Select
        onValueChange={onValueChange}
        options={filterOptions}
        small
        value={sentimentValue}
        width="100%"
      />
    </>
  )
}

export default SentimentFilterRow
