import Text from '@/components/atoms/text'
import i18n from '../../../plugins/i18n/i18n'

const AndSeparator = () => (
  <Text color="neutralLowLight" css={{ fontSize: '$micro', lineHeight: '$xs' }}>
    {i18n.t('AND')}
  </Text>
)

export default AndSeparator
