import { AlertsRequests } from '@/types/alerts'
import RequestHandler, { RequestReturnType } from './RequestHandlers/NewRequest'
import { FeedbackFilter } from '@/types/filters/FilterRequests'
import { SavedFilterContentAdvanced } from '@/types/filters/Filters'
import {
  CONDITION_QUANTITY,
  NOTIFICATION_TYPE,
  NotificationConfigModel,
  NotificationSchedule
} from '@/types/alerts/Alerts'

type AlertPayload = {
  name: string
  areaId?: string
  opportunityId?: string
  filter: FeedbackFilter | SavedFilterContentAdvanced
  notificationSchedule: NotificationSchedule
  notificationConfig: NotificationConfigModel[]
  enabled?: boolean
}

const getFullPayload = (payload: AlertPayload): AlertsRequests.AlertBase => ({
  area_id: payload.areaId,
  opportunity_id: payload.opportunityId,
  filter: payload.filter,
  notification_schedule: payload.notificationSchedule,
  version: 'v2',
  enabled: payload.enabled ?? true,
  description: payload.name,
  condition: { quantity: CONDITION_QUANTITY },
  notification_type: NOTIFICATION_TYPE,
  notification_config: payload.notificationConfig
})

export default class AlertsService {
  private static PREFIX = '/alerts'

  static async searchAlerts(
    params: AlertsRequests.SearchAlertsParams,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<AlertsRequests.SearchAlertsResponse>> {
    return await RequestHandler.get<AlertsRequests.SearchAlertsResponse>(this.PREFIX, params, {
      signal: options?.signal
    })
  }

  static async createAlert(
    payload: AlertPayload,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<AlertsRequests.CreateAlertResponse>> {
    const requestPayload = getFullPayload(payload)

    return await RequestHandler.post<AlertsRequests.CreateAlertResponse>(
      this.PREFIX,
      requestPayload,
      {
        signal: options?.signal
      }
    )
  }

  static async updateAlert(
    alertId: string,
    payload: AlertPayload,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<AlertsRequests.UpdateAlertResponse>> {
    const requestPayload = getFullPayload(payload)

    return await RequestHandler.put<AlertsRequests.UpdateAlertResponse>(
      `${this.PREFIX}/${alertId}`,
      requestPayload,
      {
        signal: options?.signal
      }
    )
  }

  static async removeAlert(
    alertId: string,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<void>> {
    return await RequestHandler.del<void>(`${this.PREFIX}/${alertId}`, {
      signal: options?.signal
    })
  }
}
