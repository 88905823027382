import React, { useState } from 'react'
import * as Select from '@radix-ui/react-select'
import { ArrowFatLinesRight, CaretDown, Plus, TrashSimple } from '@phosphor-icons/react'

import FlexContainer from '@/components/atoms/flex-container'
import { SelectOption } from '@/components/atoms/select'
import { TableCell } from '@/components/atoms/table'
import Text from '@/components/atoms/text'
import Tooltip from '@/components/atoms/tooltip'
import CustomFieldForm from '@/components/molecules/custom-fields/CustomFieldForm'
import { colors } from '@/theme'
import { LinkButton } from '../../IntegrationForm.styles'
import { CustomFieldMapPair } from '../ReviewStep'
import { GroupedSelectOptions } from './CustomFieldsSection'
import {
  RemoveRowButton,
  SearchIcon,
  SearchInput,
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  TextOverflowContainer
} from './CustomFieldsRow.styles'
import { useTranslation } from 'react-i18next'

interface CustomFieldsRowProps {
  onChange(field: keyof CustomFieldMapPair, value: string): void
  onRemove(): void
  options: [integrationFieldOptions: GroupedSelectOptions, customFieldOptions: SelectOption[]]
  selectedValues: CustomFieldMapPair[]
  value: CustomFieldMapPair
}

function CustomFieldsRow(props: CustomFieldsRowProps) {
  const [openCustomFieldForm, setOpenCustomFieldForm] = useState(false)
  const [openCustomFieldSelect, setOpenCustomFieldSelect] = useState(false)
  const [searchTermCustomField, setSearchTermCustomField] = useState('')
  const [searchTermIntegrationField, setSearchTermIntegrationField] = useState('')
  const { t } = useTranslation()

  function handleCreateFieldClick() {
    setOpenCustomFieldSelect(false)
    setOpenCustomFieldForm(true)
  }

  function onSelectCustomField(value: string) {
    props.onChange('field_id', value)
    setSearchTermCustomField('')
  }

  function onSelectIntegrationField(value: string) {
    props.onChange('key', value)
    setSearchTermIntegrationField('')
  }

  const [integrationFieldOptions, customFieldOptions] = props.options
  const { key: integrationFieldValue, field_id: customFieldValue } = props.value

  const disabledIntegrationFieldSelect = !customFieldValue

  const filteredCustomFieldOptions = customFieldOptions.filter(option => {
    const isSelf = option.value === customFieldValue
    const isSelected = props.selectedValues.some(
      selectedValue => selectedValue.field_id === option.value
    )
    const isSearchResult = new RegExp(searchTermCustomField, 'i').test(option.text as string)

    return isSelf || (!isSelected && isSearchResult)
  })

  const filteredIntegrationFieldOptions: GroupedSelectOptions = Object.fromEntries(
    Object.entries(integrationFieldOptions)
      .map(([collection, options]) => {
        const filteredOptions = options.filter(option => {
          return option.value.includes(searchTermIntegrationField)
        })
        return [collection, filteredOptions]
      })
      .filter(([, items]) => items.length)
  )

  return (
    <tr>
      <TableCell>
        <Select.Root
          disabled={disabledIntegrationFieldSelect}
          onValueChange={onSelectIntegrationField}
          value={disabledIntegrationFieldSelect ? undefined : integrationFieldValue}
        >
          <SelectTrigger aria-label={t('integrationField')}>
            <Select.Value
              placeholder={disabledIntegrationFieldSelect ? '' : t('selectAnIntegrationField')}
            />

            <Select.Icon asChild>
              <CaretDown color={colors.neutralLowLight} size={16} weight="bold" />
            </Select.Icon>
          </SelectTrigger>

          <Select.Portal>
            <SelectContent position="popper" sideOffset={8}>
              {integrationFieldValue === undefined ? (
                <>
                  <FlexContainer css={{ position: 'relative', margin: 16 }}>
                    <SearchIcon size={16} weight="bold" />
                    <SearchInput
                      aria-autocomplete="none"
                      aria-label={t('searchAvailableFields')}
                      autoComplete="off"
                      onChange={e => setSearchTermIntegrationField(e.target.value)}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => e.stopPropagation()}
                      placeholder={t('searchAvailableFields')}
                      small
                      value={searchTermIntegrationField}
                    />
                  </FlexContainer>
                  {Object.keys(filteredIntegrationFieldOptions).length > 0 ? (
                    <SelectSeparator />
                  ) : null}
                </>
              ) : null}

              <Select.Viewport>
                {Object.entries(filteredIntegrationFieldOptions).map(
                  ([collection, items], index) => (
                    <React.Fragment key={collection}>
                      {index > 0 ? <SelectSeparator /> : null}
                      <Select.Group>
                        <SelectLabel>{collection}</SelectLabel>
                        {items.map((item, index) => (
                          <SelectItem key={`${index}_${item.value}`} value={item.value}>
                            <Select.ItemText>
                              <TextOverflowContainer>
                                <Tooltip side="right" text={item.tooltip} variant="small">
                                  <span aria-hidden>... | </span>
                                </Tooltip>
                                {item.text}
                              </TextOverflowContainer>
                            </Select.ItemText>
                          </SelectItem>
                        ))}
                      </Select.Group>
                    </React.Fragment>
                  )
                )}
              </Select.Viewport>
            </SelectContent>
          </Select.Portal>
        </Select.Root>
      </TableCell>

      <TableCell>
        <FlexContainer justifyContent="center">
          <ArrowFatLinesRight size={24} weight="fill" />
        </FlexContainer>
      </TableCell>

      <TableCell>
        <Select.Root
          onOpenChange={setOpenCustomFieldSelect}
          onValueChange={onSelectCustomField}
          open={openCustomFieldSelect}
          value={customFieldValue}
        >
          <SelectTrigger aria-label="Custom field" onClick={() => setOpenCustomFieldSelect(true)}>
            <Select.Value placeholder={t('addACustomFieldHere')} />

            <Select.Icon asChild>
              <CaretDown color={colors.neutralLowLight} size={16} weight="bold" />
            </Select.Icon>
          </SelectTrigger>

          <Select.Portal>
            <SelectContent position="popper" sideOffset={8}>
              {customFieldValue === undefined ? (
                <>
                  <FlexContainer css={{ position: 'relative', margin: 16 }}>
                    <SearchIcon size={16} weight="bold" />
                    <SearchInput
                      aria-autocomplete="none"
                      aria-label="Search available fields"
                      autoComplete="off"
                      onChange={e => setSearchTermCustomField(e.target.value)}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => e.stopPropagation()}
                      placeholder={t('searchAvailableFields')}
                      small
                      value={searchTermCustomField}
                    />
                  </FlexContainer>
                  {filteredCustomFieldOptions.length > 0 ? <SelectSeparator /> : null}
                </>
              ) : null}

              <Select.Viewport>
                {filteredCustomFieldOptions.map((item, index) => (
                  <SelectItem key={`${index}_${item.value}`} value={item.value}>
                    <Select.ItemText>
                      <TextOverflowContainer>{item.text}</TextOverflowContainer>
                    </Select.ItemText>
                  </SelectItem>
                ))}
              </Select.Viewport>

              <SelectSeparator css={{ margin: 0 }} />
              <FlexContainer css={{ padding: 16 }} direction="column">
                <LinkButton onClick={handleCreateFieldClick}>
                  <Plus size={12} weight="bold" />
                  <Text typeface="subtitleSemiBoldMicro">{t('createNew')}</Text>
                </LinkButton>
              </FlexContainer>
            </SelectContent>
          </Select.Portal>
        </Select.Root>
      </TableCell>

      <TableCell>
        <FlexContainer justifyContent="center">
          <RemoveRowButton onClick={props.onRemove} title="Remove row" type="button">
            <TrashSimple size={16} weight="bold" />
          </RemoveRowButton>
        </FlexContainer>
      </TableCell>

      <CustomFieldForm onOpenChange={setOpenCustomFieldForm} open={openCustomFieldForm} />
    </tr>
  )
}

export default CustomFieldsRow
