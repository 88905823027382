import Card from '@/components/molecules/card'
import { ProgressBar } from '@/components/atoms/progress-bar'
import { Trans } from 'react-i18next'
import { useEffect, useState } from 'react'
import { RoundNumber } from '@/store/useOpportunityCalibrationStore'

const ProgressText = ({
  step,
  isCalibrationFinished
}: { step: RoundNumber; isCalibrationFinished: boolean }) => {
  if (step === 3 || isCalibrationFinished)
    return (
      <ProgressBar.TextParagraph>
        <Trans i18nKey="calibrateOpportunityRecordLoading">
          We are finishing registering your calibration, this should take <b>up to 1 minute</b>.
        </Trans>
      </ProgressBar.TextParagraph>
    )

  if (step === 0)
    return (
      <ProgressBar.TextParagraph>
        <Trans i18nKey="calibrateOpportunityRoundLoading">
          Loading rounds and questions this may take <b>up to 2 minutes</b>.
        </Trans>
      </ProgressBar.TextParagraph>
    )

  if (step === 1)
    return (
      <ProgressBar.TextParagraph>
        <Trans i18nKey="calibrateOpportunityRoundTwoLoading">
          Loading next round this may take <b>up to 2 minutes</b>.
        </Trans>
      </ProgressBar.TextParagraph>
    )

  if (step === 2)
    return (
      <ProgressBar.TextParagraph>
        <Trans i18nKey="calibrateOpportunityRoundTwoLoading">
          Loading next round this may take <b>up to 2 minutes</b>.
        </Trans>
      </ProgressBar.TextParagraph>
    )

  return <></>
}

type Props = {
  duration: number
  loadStep: RoundNumber
  isCalibrationFinished: boolean
}

export const LoadingCalibrationQuestionsCard = ({
  duration,
  loadStep,
  isCalibrationFinished
}: Props) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const intervalDuration = duration * 15

    const interval = setInterval(() => {
      setProgress(prev => {
        const nextProgress = prev + 1
        if (nextProgress >= 100) {
          clearInterval(interval)
        }
        return nextProgress
      })
    }, intervalDuration)

    return () => {
      clearInterval(interval)
    }
  }, [duration])

  return (
    <Card.Root
      justifyContent="center"
      direction="column"
      gap="xxs"
      style={{ height: '264px', padding: '0 48px' }}
    >
      <ProgressBar.Container direction="column" fullWidth gap="micro">
        <ProgressText isCalibrationFinished={isCalibrationFinished} step={loadStep} />
        <ProgressBar.Bar max={100} value={progress} />
      </ProgressBar.Container>
    </Card.Root>
  )
}
