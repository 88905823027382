import { SegmentationItem } from '@/types/segmentation/Segmentation'
import useAdvancedFilters from '../advancedFilters/useAdvancedFilters'
import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import getSegmentationFiltersCombinedFn from './getSegmentationFiltersCombinedFn'

interface Params {
  segmentations: SegmentationItem[]
  enabled?: boolean
}

const useSegmentationFiltersCombined = ({ segmentations, enabled = true }: Params) => {
  const { filters: advancedFilters } = useAdvancedFilters()

  const queryKey = useMemo(
    () => ['get-segmentations-contexts', { segmentations, advancedFilters }],
    [segmentations, advancedFilters]
  )

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      return getSegmentationFiltersCombinedFn({ segmentations, currentFilters: advancedFilters })
    },
    enabled: enabled && segmentations.length > 0
  })

  return { isLoading: enabled ? isLoading : false, data, queryKey }
}

export default useSegmentationFiltersCombined
