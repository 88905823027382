import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { ArrowDownLeft, ArrowUpRight } from '@phosphor-icons/react'
import { ItemContainer, ItemRightDetails, TrendContainer } from './DiscoveryItem.styles'
import { Reason } from '@/types/reasons'
import { ReactNode, useMemo, useState } from 'react'
import Checkbox from '@/components/atoms/checkbox'
import { MergeFindingCheckboxProps } from './Discovery.types'
import OptionsMenu, { OptionsMenuItem } from '@/components/atoms/options-menu'

type TrendStatus = 'increased' | 'neutral' | 'decreased'
const trendStatusIcon: Record<TrendStatus, ReactNode> = {
  increased: <ArrowUpRight size={12} weight="bold" />,
  decreased: <ArrowDownLeft size={12} weight="bold" />,
  neutral: <></>
}

interface Props extends MergeFindingCheckboxProps {
  reason: Reason
  onClickReason: (value: Reason | null) => void
}

const ReasonItem = ({
  reason,
  showMergeFindingsCheckbox,
  onClickReason,
  onCheckedChange,
  isChecked,
  onViewTexts
}: Props) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const onClick = () => {
    onClickReason(reason)
  }

  const trendStatus: TrendStatus = (() => {
    const { trend } = reason
    if (trend === 0) return 'neutral'

    return trend > 0 ? 'increased' : 'decreased'
  })()

  const trend = `${trendStatus === 'increased' ? '+' : ''}${reason.trend}%`

  const options: OptionsMenuItem[] = useMemo(
    () => [
      {
        text: 'View texts',
        onClick: () => {
          setMenuOpen(false)
          onViewTexts?.(reason)
        }
      }
    ],
    [onViewTexts, reason]
  )

  return (
    <ItemContainer onClick={onClick}>
      <Text
        color="neutralLowPure"
        css={{
          width: '100%',
          flex: 1,
          display: showMergeFindingsCheckbox ? 'flex' : 'block',
          alignItems: 'center'
        }}
        fontSize="xxxs"
        fontWeight="regular"
        lineHeight="xs"
        title={reason.name}
        truncate
      >
        {showMergeFindingsCheckbox && (
          <>
            <OptionsMenu
              onOpenChange={setMenuOpen}
              open={menuOpen}
              options={options}
              stopPropagation
            />
            <Checkbox
              checked={isChecked?.(reason.clusterId)}
              onCheckedChange={onCheckedChange}
              onClick={e => e.stopPropagation()}
              value={reason.clusterId}
            />
          </>
        )}
        {reason.name}
      </Text>
      <ItemRightDetails gap="nano">
        <FlexContainer alignItems="center" gap="nano" justifyContent="spaceBetween">
          <Text
            fontSize="xxxs"
            fontWeight="regular"
            lineHeight="xs"
            title={`${reason.frequency}`}
            truncate
          >
            {reason.frequency}
          </Text>
        </FlexContainer>
        <TrendContainer alignItems="center" gap="nano" justifyContent="flexEnd">
          <Text fontSize="xxxs" fontWeight="regular" lineHeight="xs" title={trend} truncate>
            {trend}
          </Text>
          {trendStatusIcon[trendStatus]}
        </TrendContainer>
      </ItemRightDetails>
    </ItemContainer>
  )
}

export default ReasonItem
