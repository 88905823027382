import { ThemeItemData } from '@/types/classification/Theme'
import { Item } from './KeywordEditor.styles'
import Text from '@/components/atoms/text'
import { MouseEvent } from 'react'
import { CaretRight } from '@phosphor-icons/react'
import { colors } from '@/theme'
import AddTheme from '../classification-keywords/theme/AddTheme'
import { TopicCategory } from '@/types/classification'
import * as S from './KeywordEditor.styles'

interface Props {
  themes: ThemeItemData[]
  onClickTheme: (theme: ThemeItemData) => (e: MouseEvent<HTMLButtonElement>) => void
  topicCategory: TopicCategory
  isOverflowing?: boolean
}

const ThemesSection = ({ themes, onClickTheme, topicCategory, isOverflowing }: Props) => {
  return (
    <>
      <S.Content fixedHeight isOverflowing={isOverflowing}>
        {themes.map(theme => (
          <Item key={theme.themeId} onClick={onClickTheme(theme)}>
            <Text
              color="pajarito"
              maxWidth={200}
              title={theme.themeName}
              truncate
              typeface="textSemiboldSmall"
            >
              {theme.themeName}
            </Text>
            <CaretRight color={colors.grey} size={14} weight="bold" />
          </Item>
        ))}
      </S.Content>
      <AddTheme topicCategory={topicCategory} withoutButtons />
    </>
  )
}

export default ThemesSection
