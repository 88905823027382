import TrendingTopicsCard from './TrendingTopicsCard'
import TrendingTopicItem from './TrendingTopicItem'
import Button from '@/components/atoms/button'
import TrendingTopicItemEmpty from './TrendingTopicItemEmpty'
import TrendingTopicsEmptyList from './TrendingTopicsEmptyList'
import { getEmptyCardsArray, scrollOnList } from './trendingTopicsUtils'
import useTrendingTopics from '@/hooks/analytics/useTrendingTopics'
import { TopIssuesSkeleton } from './TrendingTopicsSkeletons'
import TrendingTopicsHeader from './TrendingTopicsHeader'

export const TopIssuesHeader = () => <TrendingTopicsHeader type="topIssues" />

const TopIssues = () => {
  const { topics, nextPage, hasNextPage, max, isLoading } = useTrendingTopics('topIssues')

  const onLoadMoreClick = () => {
    nextPage()
    scrollOnList('top-issues-scroll-area')
  }

  const emptyCards = getEmptyCardsArray(topics)

  if (isLoading) {
    return <TopIssuesSkeleton />
  }

  if (topics.length === 0) {
    return (
      <TrendingTopicsCard.Root type="issues">
        <TopIssuesHeader />
        <TrendingTopicsEmptyList />
      </TrendingTopicsCard.Root>
    )
  }

  return (
    <TrendingTopicsCard.Root type="issues">
      <TrendingTopicsCard.Row>
        <TopIssuesHeader />
      </TrendingTopicsCard.Row>

      <TrendingTopicsCard.ScrollArea id="top-issues-scroll-area">
        <TrendingTopicsCard.ItemList>
          {topics.map(topic => (
            <TrendingTopicItem
              hasDetails
              key={topic.name}
              max={max}
              topic={topic}
              type="topIssues"
            />
          ))}
          {emptyCards.map((_, index) => (
            <TrendingTopicItemEmpty key={index} />
          ))}
        </TrendingTopicsCard.ItemList>
      </TrendingTopicsCard.ScrollArea>

      <TrendingTopicsCard.Row justifyContent="flexEnd">
        {hasNextPage && (
          <Button onClick={onLoadMoreClick} size="small" variant="flat">
            Load more
          </Button>
        )}
      </TrendingTopicsCard.Row>
    </TrendingTopicsCard.Root>
  )
}

export default TopIssues
