import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'

export const ItemContainer = styled('button', {
  all: 'unset',
  display: 'flex',
  gap: '$sm',
  justifyContent: 'space-between',
  alignItems: 'center',
  my: '$micro',
  py: '$micro',
  px: '$micro',
  // bBottom: '$neutralHighPure',
  cursor: 'pointer',
  '&:first-child': {
    mt: 0
  },
  '&:hover': {
    backgroundColor: '$neutralHighMedium',
    br: '$micro'
  },

  variants: {
    seeMore: {
      true: {
        mt: 'auto',
        mb: 0
      }
    }
  }
})

export const ItemRightDetails = styled(FlexContainer, {
  color: '$neutralLowLight',
  '& svg': { minWidth: 16 },
  '& span': {
    width: 48,
    textAlign: 'center'
  }
})

export const TrendContainer = styled(FlexContainer, {
  br: 2,
  backgroundColor: '$neutralHighMedium',
  padding: 2
})
