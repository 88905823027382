import { ChatText } from '@phosphor-icons/react'
import IndicatorCard from './IndicatorCard'
import { IndicatorsProps } from './Indicators.types'
import { formatPercentageWithSignal, roundAndSuffix } from '@/utils/numbers'
import useComparisonText from '@/hooks/useComparisonText'
import { useFiltersStore } from '@/store'
import { shallow } from 'zustand/shallow'

const FeedbackIndicator = ({ count, comparisonPercentage }: IndicatorsProps) => {
  const dateRange = useFiltersStore(state => state.dateRange, shallow)

  const { text } = useComparisonText()

  const formattedCount = roundAndSuffix(count)
  const formattedPercentage = formatPercentageWithSignal(comparisonPercentage)

  return (
    <IndicatorCard.Root alignItems="end" color="brandPrimaryPure">
      <IndicatorCard.Column>
        <IndicatorCard.Header>
          <IndicatorCard.IconBox>
            <ChatText />
          </IndicatorCard.IconBox>
          <IndicatorCard.Title>Feedback</IndicatorCard.Title>
        </IndicatorCard.Header>
        <IndicatorCard.BigNumber>{formattedCount}</IndicatorCard.BigNumber>
      </IndicatorCard.Column>
      {dateRange ? (
        <IndicatorCard.Paragraph>
          <em>{formattedPercentage}</em> <span>{text}</span>
        </IndicatorCard.Paragraph>
      ) : (
        <IndicatorCard.Fallback />
      )}
    </IndicatorCard.Root>
  )
}

export default FeedbackIndicator
