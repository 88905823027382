import { IntervalOption, MetricOption } from '@/types/analytics/Analytics'
import { ChartType, DashboardChart } from '@/types/dashboard'
import { persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'
import { v4 as uuid } from 'uuid'
import { FilterDatetimeValue } from '@/types/filters/Filters'
import { Period } from '@/types/periods'
import { copyName } from '@/utils/string'

interface DashboardState {
  organizationId: string | null
  setOrganizationId: (id: string) => void

  lastTimeSaved: Date | null
  setLastTimeSaved: (date: Date) => void

  charts: DashboardChart[]
  setCharts: (charts: DashboardChart[]) => void

  savedCharts: DashboardChart[]
  setSavedCharts: (charts: DashboardChart[]) => void

  duplicateChart: (id: string) => string
  addNewChart: (name: string, savedFilters: string[]) => string

  setSavedFilters: (id: string, savedFilters: string[]) => void
  setType: (id: string, type: ChartType) => void
  setInterval: (id: string, interval: IntervalOption) => void
  setMetric: (id: string, metric: MetricOption) => void
  setDate: (id: string, range: FilterDatetimeValue, period?: Period) => void
  setName: (id: string, name: string) => void
}

const useDashboardStore = createWithEqualityFn<DashboardState>()(
  persist(
    set => ({
      organizationId: null,
      setOrganizationId: id => set(() => ({ organizationId: id })),

      lastTimeSaved: null,
      setLastTimeSaved: date => set(() => ({ lastTimeSaved: date })),

      charts: [],
      setCharts: charts => set(() => ({ charts })),

      savedCharts: [],
      setSavedCharts: charts => set(() => ({ savedCharts: charts })),

      duplicateChart: id => {
        const newId = uuid()

        set(state => {
          const chartIndex = state.charts.findIndex(chart => chart.id === id)
          if (chartIndex === -1) return {}

          const chart = state.charts[chartIndex]

          const newChart: DashboardChart = {
            ...chart,
            saved: false,
            id: newId,
            name: copyName(chart.name)
          }

          if (chartIndex === 0) {
            return { ...state, charts: [state.charts[0], newChart, ...state.charts.slice(1)] }
          }

          if (chartIndex === state.charts.length - 1) {
            return {
              ...state,
              charts: [...state.charts, newChart]
            }
          }

          return {
            ...state,
            charts: [
              ...state.charts.slice(0, chartIndex),
              newChart,
              ...state.charts.slice(chartIndex + 1)
            ]
          }
        })

        return newId
      },

      addNewChart: (name, savedFilters) => {
        const newId = uuid()
        set(state => ({
          charts: [
            ...state.charts,
            {
              id: uuid(),
              name,
              interval: IntervalOption.Month,
              metric: 'count',
              savedFilters,
              type: 'line',
              dateRange: null,
              datePeriod: 'allTime',
              saved: false
            }
          ]
        }))
        return newId
      },
      setSavedFilters: (id, savedFilters) =>
        set(state => ({
          charts: state.charts.map(chart =>
            chart.id === id
              ? {
                  ...chart,
                  saved: false,
                  savedFilters
                }
              : chart
          )
        })),

      setType: (id, type) =>
        set(state => ({
          charts: state.charts.map(chart =>
            chart.id === id ? { ...chart, saved: false, type } : chart
          )
        })),
      setInterval: (id, interval) =>
        set(state => ({
          charts: state.charts.map(chart =>
            chart.id === id ? { ...chart, saved: false, interval } : chart
          )
        })),
      setMetric: (id, metric) =>
        set(state => ({
          charts: state.charts.map(chart =>
            chart.id === id ? { ...chart, saved: false, metric } : chart
          )
        })),
      setDate: (id, range, period) =>
        set(state => ({
          charts: state.charts.map(chart =>
            chart.id === id
              ? {
                  ...chart,
                  saved: false,
                  dateRange: period === 'custom' ? range : null,
                  datePeriod: period || chart.datePeriod
                }
              : chart
          )
        })),
      setName: (id, name) =>
        set(state => ({
          charts: state.charts.map(chart =>
            chart.id === id ? { ...chart, saved: false, name } : chart
          )
        }))
    }),
    { name: 'dashboard-store', version: 2 }
  ),
  shallow
)

export default useDashboardStore
