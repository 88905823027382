import Bone from '@/components/atoms/bone'
import {
  SectionContainer,
  SectionLeftContainer,
  SectionRightContainer
} from './IntegrationDetails.styles'

function IntegrationDetailsSkeleton() {
  return (
    <SectionContainer data-testid="integration-details-skeleton">
      <SectionLeftContainer>
        <Bone css={{ height: 64, width: 64 }} />
        <Bone css={{ height: 16, width: 64 }} />
      </SectionLeftContainer>
      <SectionRightContainer>
        <Bone css={{ height: 16, width: 128 }} />
      </SectionRightContainer>
      <SectionRightContainer>
        <Bone css={{ height: 16, width: 128 }} />
      </SectionRightContainer>
      <SectionRightContainer>
        <Bone css={{ height: 16, width: 128 }} />
      </SectionRightContainer>
    </SectionContainer>
  )
}

export default IntegrationDetailsSkeleton
