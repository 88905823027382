import { useMemo } from 'react'
import useAllMetrics from './useAllMetricItems'
import useMetricListPayload from './useMetricListPayload'
import { baseAllMetricItems } from '@/utils/metrics'
import { INVALID_METRIC_TABLE_COLUMNS } from '@/utils/opportunityUtils'
import { MetricCustomizationValue } from '@/types/metrics/MetricsRequests'

const useSortingMetric = (sortColumn?: string) => {
  const { getMetricByColumn } = useAllMetrics()
  const { handleCustomMetricVariables } = useMetricListPayload()

  const sortingMetric = useMemo(() => {
    const countMetric = baseAllMetricItems.count.metric
    if (!sortColumn) return countMetric

    const actualMetric = INVALID_METRIC_TABLE_COLUMNS.includes(sortColumn)
      ? countMetric
      : getMetricByColumn(sortColumn)?.metric ?? countMetric

    if (
      actualMetric.name === 'custom' &&
      actualMetric.customization &&
      actualMetric.customization.variables
    ) {
      const newVariables: MetricCustomizationValue['variables'] = handleCustomMetricVariables(
        actualMetric.customization.variables,
        'opportunity'
      )

      return {
        ...actualMetric,
        customization: {
          ...actualMetric.customization,
          variables: newVariables
        }
      }
    }

    return actualMetric
  }, [sortColumn, getMetricByColumn, handleCustomMetricVariables])

  return sortingMetric
}

export default useSortingMetric
