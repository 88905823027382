import * as Select from '@radix-ui/react-select'
import { MagnifyingGlass } from '@phosphor-icons/react'

import Input from '@/components/atoms/input'
import { StyledTrigger } from '@/components/atoms/select/Select.styles'
import { styled, typefaceVariants } from '@/theme'

export const RemoveRowButton = styled('button', {
  backgroundColor: 'transparent',
  border: 0,
  color: 'transparent',
  cursor: 'pointer',
  lineHeight: 0,
  size: 24,
  transition: 'color .3s',

  '&:hover, &:focus-within': {
    color: '$feedbackNegativePure'
  }
})

export const SearchIcon = styled(MagnifyingGlass, {
  color: '$neutralLowLight',
  height: '100%',
  left: '$micro',
  position: 'absolute'
})

export const SearchInput = styled(Input, {
  ...typefaceVariants.paragraphRegularMicro,
  boxSizing: 'border-box',
  paddingLeft: '$sm !important'
})

export const TextOverflowContainer = styled('span', {
  display: 'block',
  overflow: 'hidden',
  textAlign: 'start',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
})

export const SelectTrigger = styled(StyledTrigger, {
  width: 324,

  '&[data-disabled]': {
    backgroundColor: '$neutralHighMedium',
    bAll: '$neutralHighPure',
    color: '$neutralLowLight',

    '&:hover': {
      backgroundColor: '$neutralHighMedium',
      bAll: '$neutralHighPure'
    }
  },

  '& :first-child': {
    overflow: 'hidden'
  },

  '& > svg': {
    flexShrink: 0,
    transition: 'transform .1s'
  },

  '&[data-state=open] > svg': {
    transform: 'scaleY(-1)'
  }
})

export const SelectContent = styled(Select.Content, {
  ...typefaceVariants.paragraphRegularMicro,
  backgroundColor: '$neutralHighLight',
  bAll: '$stroke',
  borderRadius: 8,
  boxShadow: '$soft',
  color: '$neutralLowPure',
  maxHeight: 'var(--radix-select-content-available-height)',
  width: 'var(--radix-select-trigger-width)',
  zIndex: 1
})

export const SelectLabel = styled(Select.Label, {
  color: '$neutralLowLight',
  padding: '$nano $xxxs',
  margin: 4
})

export const SelectItem = styled(Select.Item, {
  borderRadius: 8,
  padding: '$nano $xxxs',
  cursor: 'pointer',
  margin: 4,

  '&[data-highlighted]': {
    backgroundColor: '$brandPrimaryLight'
  },

  '&[data-state="checked"]': {
    color: '$brandPrimaryPure',
    fontWeight: '$semibold'
  }
})

export const SelectSeparator = styled(Select.Separator, {
  backgroundColor: '$neutralHighPure',
  height: 1,
  margin: '0 16px'
})
