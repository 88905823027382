import { CSS, styled } from '@/theme'

export const SectionContainer = styled('section', {
  bAll: '$neutralHighPure',
  borderRadius: 8,
  display: 'grid',
  gridTemplateAreas: '"left right""left right""left right"',
  gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 3fr)',
  gridTemplateRows: 'minmax(1fr, max-content) 1fr 1fr',
  maxWidth: 800
})

export const SectionLeftContainer = styled('div', {
  alignItems: 'center',
  bRight: '$neutralHighPure',
  display: 'flex',
  flexDirection: 'column',
  gap: '$micro',
  gridArea: 'left',
  justifyContent: 'center',
  padding: '20px 32px',
  textAlign: 'center'
})

export const Image = styled('img', {
  variants: {
    round: {
      true: { borderRadius: '50%' }
    }
  }
})

export const SectionRightContainer = styled('span', {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '20px 32px',
  position: 'relative',

  '&:not(:last-of-type)': {
    bBottom: '$neutralHighPure'
  }
})

export const IconButtonCss: CSS = {
  all: 'unset',
  borderRadius: '50%',
  color: '$neutralLowPure',
  cursor: 'pointer',
  padding: 4,
  position: 'absolute',
  right: 32 - 4,
  size: 24,
  top: 16 - 4,
  transition: 'background-color .3s',
  zIndex: 1,

  '&:hover': {
    backgroundColor: '$brandPrimaryLight',
    color: '$brandPrimaryPure'
  },

  '&[data-state=open]': {
    backgroundColor: '$brandPrimaryPure',
    color: '$neutralHighLight'
  },

  '& svg': {
    size: '100%'
  }
}
