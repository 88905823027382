export interface KeywordTopic {
  topicId: string
  name: string
}

export interface KeywordBase {
  keywordHash: string
  text: string
}

export interface KeywordWithTopicIds extends KeywordBase {
  topicIds: string[]
  topics: KeywordTopic[]
}

export interface KeywordSentiment {
  positiveCount: number
  neutralCount: number
  negativeCount: number
  positivePercentage: number
  neutralPercentage: number
  negativePercentage: number
  netSentiment: number
}
export interface Keyword extends KeywordWithTopicIds {
  frequency: number
  topics: KeywordTopic[]
  totalHits: number
  sentiment: KeywordSentiment
}

export interface KeywordCluster {
  frequency: number
  cluster: string
  keywordList: Keyword[]
  totalHits: number
  sentiment: KeywordSentiment
  totalReasons: number
}

export interface KeywordSuggestion {
  keywordHash: string
  text: string
  frequency: number
}

export interface TopicRelationEvent extends KeywordWithTopicIds {
  topicId: string
}

export interface KeywordSuggestionMap {
  [topic_id: string]: KeywordSuggestion[]
}

export enum KeywordSortType {
  FREQUENCY = 'F',
  NET_SENTIMENT_DESC = 'NET',
  NET_SENTIMENT_ASC = 'NNET',
  POSITIVE_SENTIMENT = 'P',
  NEGATIVE_SENTIMENT = 'N'
}
