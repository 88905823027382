import FlexContainer from '@/components/atoms/flex-container'
import { PopoverHeader, PopoverFooter } from '@/components/atoms/popover'
import { styled, typefaceVariants } from '@/theme'

export const Header = styled(PopoverHeader, {
  ...typefaceVariants.subtitleSemiBoldMicro,
  background: '$neutralHighMedium',
  padding: '$xxxs'
})

export const Content = styled(FlexContainer, {
  width: '100%',
  height: '100%',
  maxHeight: 152,
  overflow: 'auto',
  flexDirection: 'column',
  padding: '0 $micro',
  boxSizing: 'border-box',
  gap: '$micro',
  margin: '$micro 0',

  variants: {
    fixedHeight: {
      true: {
        minHeight: 120
      }
    },
    isOverflowing: {
      true: {
        maxHeight: 320
      }
    }
  },

  compoundVariants: [
    {
      fixedHeight: true,
      isOverflowing: true,
      css: {
        minHeight: 78,
        maxHeight: 300
      }
    }
  ]
})

export const Container = styled(FlexContainer, {
  flexDirection: 'column',
  width: 251
})

export const SearchHeader = styled(PopoverHeader, {
  padding: '8px 14px'
})

export const Item = styled('button', {
  all: 'unset',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: 'auto',
  cursor: 'pointer',
  padding: '10px 6px',
  borderRadius: 6,

  '&:hover': {
    background: '$greenLight'
  },

  variants: {
    disablePointer: {
      true: {
        cursor: 'default'
      }
    }
  }
})

export const Footer = styled(PopoverFooter, {
  display: 'flex',
  gap: 14,
  padding: '12px 20px',
  justifyContent: 'flex-start'
})

export const SectionTitleHeading = styled(FlexContainer, {
  mt: '$xxxs',
  padding: '0 14px',
  display: 'flex',
  alignItems: 'center',

  '& > button, & > p': {
    background: 'transparent',
    border: 'none',
    outline: 'none',
    display: 'inline-flex',
    gap: '$nano'
  },

  '& > button': {
    width: '100%'
  },
  '& > button > svg': {
    minWidth: 14
  },

  variants: {
    separator: {
      true: {
        color: '$neutralLowPure',
        background: '$neutralHighMedium',
        bBottom: '$neutralHighPure',
        padding: 12
      }
    }
  }
})
