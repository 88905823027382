import i18n from '@/plugins/i18n/i18n'
import Summary, { SummarySkeleton } from '@/components/molecules/summary/Summary'

interface CalibrateContextProps {
  context: string
}

const CalibrateContext = ({ context }: CalibrateContextProps) => {
  const isLoading = false
  // probably not needed
  if (isLoading) return <SummarySkeleton title={i18n.t('calibrateOpportunityContext')} />

  return <Summary text={context} title={i18n.t('calibrateOpportunityContext')} />
}

export default CalibrateContext
