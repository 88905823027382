import Tooltip from '@/components/atoms/tooltip'
import { colors } from '@/theme'
import { FeedbackChatMessageWithId } from '@/types/feedbacks/Feedback'
import { CheckCircle, X, NotePencil, CopySimple } from '@phosphor-icons/react'
import ChatBalloon from '@/components/atoms/chat-balloon'

interface Props {
  message: FeedbackChatMessageWithId
  isEditing: boolean
  onFinishEdit: (id: string, newContent: string) => void
  onClickDelete: (id: string) => void
  onClickEdit: () => void
  onSaveEdit: () => void
  onCancelEdit: () => void
  onCopyMessage: () => Promise<void>
}

function MessageOptions({
  message,
  isEditing,
  onCancelEdit,
  onCopyMessage,
  onClickEdit,
  onSaveEdit
}: Props) {
  return (
    <ChatBalloon.OptionsContainer className="options" gap="nano">
      {isEditing ? (
        <>
          <Tooltip side="bottom" text="Cancel changes" variant={'small'}>
            <X color={colors.brandPrimaryPure} onClick={onCancelEdit} size={16} />
          </Tooltip>
          <Tooltip side="bottom" text="Save changes" variant={'small'}>
            <CheckCircle color={colors.brandPrimaryPure} onClick={onSaveEdit} size={16} />
          </Tooltip>
        </>
      ) : (
        <>
          {message.role === 'assistant' ? (
            <Tooltip side="bottom" text="Click to copy this message" variant={'small'}>
              <CopySimple color={colors.brandPrimaryPure} onClick={onCopyMessage} size={16} />
            </Tooltip>
          ) : (
            <>
              <Tooltip side="bottom" text="Click to copy this message" variant={'small'}>
                <CopySimple color={colors.brandPrimaryPure} onClick={onCopyMessage} size={16} />
              </Tooltip>

              <Tooltip side="bottom" text="Click to edit this message" variant={'small'}>
                <NotePencil color={colors.brandPrimaryPure} onClick={onClickEdit} size={16} />
              </Tooltip>
            </>
          )}
        </>
      )}
    </ChatBalloon.OptionsContainer>
  )
}

export default MessageOptions
