import Dialog from '@/components/atoms/dialog'
import { shallow } from 'zustand/shallow'

import {
  DetailsScrollArea,
  FeedbackDetailItem,
  FeedbackDetailTitle,
  FeedbackDetailsHeader
} from './FeedbackDetails.styles'

import Text from '@/components/atoms/text'
import FlexContainer from '@/components/atoms/flex-container'
import { useEffect, useMemo, useState } from 'react'
import { FeedbackStoreNames, mapFeedbackStore } from '@/store/useFeedbackStore'
import useParse from '@/hooks/useParse'
import { ScrollAreaScrollbar, ScrollAreaThumb, ScrollAreaViewport } from '../scroll-area'
import { CaretLeft } from '@phosphor-icons/react'
import FeedbackTopicChip from '../feedback-item/FeedbackTopicChip'
import { DetailOptionType } from './FeedbackDetails.types'
import CollapsibleDetail from './CollapsibleDetail'
import useLetterCase from '@/hooks/useLetterCase'
import { isNumberNull } from '@/utils/numbers'
import useAccountsStore from '@/store/useAccountsStore'
import FeedbackAccountDetails from './AccountDetails'
import useCustomerUsersStore from '@/store/useCustomerUsersStore'
import FeedbackUserDetails from './UsersDetails'
import { useStoreWithEqualityFn } from 'zustand/traditional'
import { dateShortMonthFormat } from '@/utils/date'
import { getSentimentName } from '@/utils/sentiments'

const formatDate = dateShortMonthFormat

interface Props {
  interactOutside?: boolean
  store: FeedbackStoreNames
}

function FeedbackDetails({ interactOutside = false, store }: Props) {
  const useStore = mapFeedbackStore[store]
  const { selectedFeedback } = useStoreWithEqualityFn(
    useStore,
    state => ({ selectedFeedback: state.selectedFeedback }),
    shallow
  )
  const setSelectedFeedback = useStore(state => state.setSelectedFeedback)

  const { parseAndSanitize, parseLinks } = useParse()
  const { capitalizeFirst } = useLetterCase()

  const [currentOpen, setCurrentOpen] = useState<DetailOptionType | ''>('properties')

  const accountResult = useAccountsStore(state =>
    state.accountsFeedbacksResults.find(account => account.id === selectedFeedback?.accountId)
  )

  const usersResult = useCustomerUsersStore(state =>
    state.usersFeedbacksResults.find(user => user.id === selectedFeedback?.authorId)
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    return () => {
      setSelectedFeedback(null)
    }
  }, [])

  const isOpen = (option: string) => currentOpen === option

  const onCollapseChange = (option: string) => (open: boolean) => {
    setCurrentOpen(open ? (option as DetailOptionType) : '')
  }

  const onOpenChange = (open: boolean) => {
    setSelectedFeedback(open ? selectedFeedback : null)

    /*
     * Fix for Dropdown Menu + Dialog bug
     * pointer-events was not being removed from body when FeedbackDetails was closed, leaving the page noninteractive
     */
    if (!open) {
      const observer = new MutationObserver((_, observer) => {
        document.body.removeAttribute('style')
        observer.disconnect()
      })

      observer.observe(document.body, { attributeFilter: ['style'] })
    }
  }

  const schemaFields = useMemo(() => {
    if (!selectedFeedback) {
      return []
    }

    const { schemaFieldsDatetime, schemaFieldsInteger, schemaFieldsNumber, schemaFieldsString } =
      selectedFeedback

    const dateTimeFields = schemaFieldsDatetime.map(field => ({
      ...field,
      value: formatDate(new Date(field.value as number | string))
    }))

    const otherFields = [...schemaFieldsInteger, ...schemaFieldsNumber, ...schemaFieldsString].map(
      field => ({
        ...field,
        value: field.value
      })
    )

    return [...dateTimeFields, ...otherFields]
  }, [selectedFeedback])

  const customFields = useMemo(() => {
    if (!selectedFeedback) {
      return []
    }

    const { customFieldsDatetime, customFieldsInteger, customFieldsNumber, customFieldsString } =
      selectedFeedback
    const dateTimeFields = customFieldsDatetime.map(field => ({
      ...field,
      value: formatDate(new Date(field.value as number | string))
    }))

    const otherFields = [...customFieldsInteger, ...customFieldsNumber, ...customFieldsString].map(
      field => ({
        ...field,
        value: field.value
      })
    )

    return [...dateTimeFields, ...otherFields]
  }, [selectedFeedback])

  const hasTopics = useMemo(() => {
    if (!selectedFeedback) return false
    const { keywordTopicList } = selectedFeedback
    const filtered = keywordTopicList.filter(keywordTopic => keywordTopic.topics?.length > 0)

    return filtered.length > 0
  }, [selectedFeedback])

  if (!selectedFeedback) return <></>

  return (
    <Dialog
      align="right"
      closeIcon
      enableInteractionsOutside={interactOutside}
      modal={!interactOutside}
      noBackdrop={interactOutside}
      onOpenChange={onOpenChange}
      open={!!selectedFeedback}
      useFullHeight
    >
      <FlexContainer
        css={{ height: '100%', boxSizing: 'border-box', overflow: 'hidden' }}
        direction="column"
      >
        <FeedbackDetailsHeader>
          <CaretLeft onClick={() => onOpenChange(false)} size={16} weight="bold" />
          <Text as="h4" fontSize="xxxs" fontWeight="semibold" lineHeight="md">
            Feedback details
          </Text>
        </FeedbackDetailsHeader>
        <DetailsScrollArea type="auto">
          <ScrollAreaViewport>
            <CollapsibleDetail isOpen={isOpen} onOpenChange={onCollapseChange} option="properties">
              <FlexContainer direction="column" gap="micro">
                <FeedbackDetailItem>
                  <FeedbackDetailTitle>Feedback Id</FeedbackDetailTitle>
                  <p>{selectedFeedback.feedbackId}</p>
                </FeedbackDetailItem>

                {selectedFeedback.dataType && (
                  <FeedbackDetailItem>
                    <FeedbackDetailTitle>Feedback Type</FeedbackDetailTitle>
                    <p>{selectedFeedback.dataType}</p>
                  </FeedbackDetailItem>
                )}

                {!isNumberNull(selectedFeedback.rating) && (
                  <FeedbackDetailItem>
                    <FeedbackDetailTitle>Feedback Rating</FeedbackDetailTitle>
                    <p>{selectedFeedback.rating}</p>
                  </FeedbackDetailItem>
                )}

                {selectedFeedback.productReference && (
                  <FeedbackDetailItem>
                    <FeedbackDetailTitle>Custom Tag</FeedbackDetailTitle>
                    <p>{selectedFeedback.productReference}</p>
                  </FeedbackDetailItem>
                )}

                {selectedFeedback.threadId && (
                  <FeedbackDetailItem>
                    <FeedbackDetailTitle>Thread Id</FeedbackDetailTitle>
                    <p>{selectedFeedback.threadId}</p>
                  </FeedbackDetailItem>
                )}

                {selectedFeedback.authorId && (
                  <FeedbackDetailItem>
                    <FeedbackDetailTitle>Author ID</FeedbackDetailTitle>
                    <p>{selectedFeedback.authorId}</p>
                  </FeedbackDetailItem>
                )}

                {selectedFeedback.accountId && (
                  <FeedbackDetailItem>
                    <FeedbackDetailTitle>Account ID</FeedbackDetailTitle>
                    <p>{selectedFeedback.accountId}</p>
                  </FeedbackDetailItem>
                )}

                {selectedFeedback.postedAt && (
                  <FeedbackDetailItem>
                    <FeedbackDetailTitle>Posted At</FeedbackDetailTitle>
                    <p>{formatDate(selectedFeedback.postedAt)}</p>
                  </FeedbackDetailItem>
                )}
              </FlexContainer>
            </CollapsibleDetail>

            {schemaFields.length > 0 && (
              <CollapsibleDetail
                isOpen={isOpen}
                onOpenChange={onCollapseChange}
                option="schema_fields"
              >
                <FlexContainer direction="column" gap="micro">
                  {schemaFields.map(field => (
                    <FeedbackDetailItem key={field.key || field.name}>
                      <FeedbackDetailTitle>{field.name}</FeedbackDetailTitle>
                      <p>
                        {Array.isArray(field.value)
                          ? field.value.join(', ')
                          : parseAndSanitize(parseLinks(`${field.value}`))}
                      </p>
                    </FeedbackDetailItem>
                  ))}
                </FlexContainer>
              </CollapsibleDetail>
            )}

            {customFields.length > 0 && (
              <CollapsibleDetail
                isOpen={isOpen}
                onOpenChange={onCollapseChange}
                option="custom_fields"
              >
                <FlexContainer direction="column" gap="micro">
                  {customFields.map(field => (
                    <FeedbackDetailItem key={field.key || field.name}>
                      <FeedbackDetailTitle>{field.name}</FeedbackDetailTitle>
                      <p>{parseAndSanitize(parseLinks(`${field.value}`))}</p>
                    </FeedbackDetailItem>
                  ))}
                </FlexContainer>
              </CollapsibleDetail>
            )}

            <CollapsibleDetail isOpen={isOpen} onOpenChange={onCollapseChange} option="topics">
              {hasTopics ? (
                <FeedbackTopicChip {...selectedFeedback} />
              ) : (
                <Text fontSize="xxxs" fontWeight="regular" lineHeight="md">
                  No topics associated.
                </Text>
              )}
            </CollapsibleDetail>

            {selectedFeedback.keywordTopicList.length > 0 && (
              <CollapsibleDetail isOpen={isOpen} onOpenChange={onCollapseChange} option="keywords">
                {selectedFeedback.keywordTopicList.map(keyword => (
                  <CollapsibleDetail
                    isOpen={isOpen}
                    key={keyword.keywordHash}
                    onOpenChange={onCollapseChange}
                    option={keyword.text}
                    subLevel
                  >
                    <FlexContainer css={{ ml: '$micro' }} direction="column" gap="micro">
                      <FeedbackDetailItem>
                        <FeedbackDetailTitle>Hash</FeedbackDetailTitle>
                        <p>{keyword.keywordHash}</p>
                      </FeedbackDetailItem>
                      <FeedbackDetailItem>
                        <FeedbackDetailTitle>Sentiment</FeedbackDetailTitle>
                        <p>{capitalizeFirst(getSentimentName(keyword.sentiment))}</p>
                      </FeedbackDetailItem>
                      {keyword.intention[0] ? (
                        <FeedbackDetailItem>
                          <FeedbackDetailTitle>Intention</FeedbackDetailTitle>
                          <p>{capitalizeFirst(keyword.intention[0])}</p>
                        </FeedbackDetailItem>
                      ) : null}
                      {keyword.topics.length > 0 && (
                        <FeedbackDetailItem>
                          <FeedbackDetailTitle>Topics</FeedbackDetailTitle>
                          <p>{keyword.topics.map(topic => topic.name).join(',')}</p>
                        </FeedbackDetailItem>
                      )}

                      {keyword.keywordQuality && (
                        <FeedbackDetailItem>
                          <FeedbackDetailTitle>Keyword Quality</FeedbackDetailTitle>
                          <p> {keyword.keywordQuality}</p>
                        </FeedbackDetailItem>
                      )}

                      {keyword.overallQuality && (
                        <FeedbackDetailItem>
                          <FeedbackDetailTitle>Overall Quality</FeedbackDetailTitle>
                          <p> {keyword.overallQuality}</p>
                        </FeedbackDetailItem>
                      )}
                    </FlexContainer>
                  </CollapsibleDetail>
                ))}
              </CollapsibleDetail>
            )}

            {!!accountResult && !!accountResult.account && (
              <FeedbackAccountDetails
                account={accountResult.account}
                isOpen={isOpen}
                onCollapseChange={onCollapseChange}
              />
            )}

            {!!usersResult && !!usersResult.user && (
              <FeedbackUserDetails
                detailedUser={usersResult.user}
                isOpen={isOpen}
                onCollapseChange={onCollapseChange}
              />
            )}

            <ScrollAreaScrollbar orientation="vertical">
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
          </ScrollAreaViewport>
        </DetailsScrollArea>
      </FlexContainer>
    </Dialog>
  )
}

export default FeedbackDetails
