import useClassification from '@/hooks/useClassification'
import { useFiltersStore } from '@/store'
import { TopicCategory } from '@/types/classification'
import { ThemeItemData } from '@/types/classification/Theme'
import { CheckedState } from '@radix-ui/react-checkbox'
import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'

interface Params {
  category: TopicCategory
}

const useTopicsFilter = ({ category }: Params) => {
  const { productAreaThemes, otherThemes, getTopicParent } = useClassification()
  const themes = category === 'PRODUCT_AREA' ? productAreaThemes : otherThemes

  const {
    productAreaTopicsFilters,
    otherTopicsFilters,
    productAreaThemesFilters,
    otherThemesFilters,
    unclassifiedTopicsFilters
  } = useFiltersStore(
    state => ({
      productAreaTopicsFilters: state.productAreaTopics,
      otherTopicsFilters: state.otherTopics,
      productAreaThemesFilters: state.productAreaThemes,
      otherThemesFilters: state.otherThemes,
      unclassifiedTopicsFilters: state.unclassifiedTopics
    }),
    shallow
  )

  const storeSelectTheme = useFiltersStore(state => state.selectTheme)
  const storeUnselectTheme = useFiltersStore(state => state.unselectTheme)
  const storeSelectTopics = useFiltersStore(state => state.selectTopics)
  const storeUnselectTopics = useFiltersStore(state => state.unselectTopics)
  const toggleUnclassifiedTopics = useFiltersStore(state => state.toggleUnclassifiedTopics)
  const applyProductArea = useFiltersStore(state => state.applyProductArea)
  const applyOtherTopics = useFiltersStore(state => state.applyOtherTopics)

  const tempThemes =
    category === 'PRODUCT_AREA'
      ? productAreaThemesFilters.draftSelected
      : otherThemesFilters.draftSelected
  const tempTopics =
    category === 'PRODUCT_AREA'
      ? productAreaTopicsFilters.draftSelected
      : otherTopicsFilters.draftSelected

  const appliedThemes =
    category === 'PRODUCT_AREA' ? productAreaThemesFilters.selected : otherThemesFilters.selected
  const appliedTopics =
    category === 'PRODUCT_AREA' ? productAreaTopicsFilters.selected : otherTopicsFilters.selected

  const isUnclassifiedChecked = unclassifiedTopicsFilters.draftSelected.includes(category)
  const isAppliedUnclassifiedChecked = unclassifiedTopicsFilters.selected.includes(category)

  const hasChanges = useMemo(
    () =>
      JSON.stringify(appliedThemes) !== JSON.stringify(tempThemes) ||
      JSON.stringify(appliedTopics) !== JSON.stringify(tempTopics) ||
      isUnclassifiedChecked !== isAppliedUnclassifiedChecked,
    [
      tempThemes,
      tempTopics,
      isUnclassifiedChecked,
      isAppliedUnclassifiedChecked,
      appliedThemes,
      appliedTopics
    ]
  )

  function onUnclassifiedCheck() {
    toggleUnclassifiedTopics(category)
  }

  const getThemeCheckedState = (theme: ThemeItemData): CheckedState => {
    if (theme.topics.some(topic => tempTopics.includes(topic.topicId))) {
      return 'indeterminate'
    }

    return tempThemes.includes(theme.themeId ?? '')
  }
  const isTopicChecked = (topicId: string, themeId?: string) => {
    const actualThemeId = themeId ?? getTopicParent(topicId)?.themeId ?? ''
    return tempThemes.includes(actualThemeId) || tempTopics.includes(topicId)
  }

  function onThemeCheck(checkedState: CheckedState, theme: ThemeItemData) {
    if (getThemeCheckedState(theme) === 'indeterminate' || checkedState === false) {
      storeUnselectTheme({ themeId: theme.themeId ?? '', topicCategory: category })
      storeUnselectTopics({
        topicIds: theme.topics.map(topic => topic.topicId),
        topicCategory: category
      })

      return
    }

    storeSelectTheme({ themeId: theme.themeId ?? '', topicCategory: category })
  }

  function onTopicCheck(checkedState: CheckedState, topicId: string, theme?: ThemeItemData) {
    const actualTheme: ThemeItemData | undefined = theme ?? getTopicParent(topicId)

    if (!actualTheme) {
      console.info('actual theme not found')
      return
    }

    if (checkedState) {
      const selectedTopicCount = tempTopics.reduce(
        (acc, cur) => acc + (actualTheme.topics.map(topic => topic.topicId).includes(cur) ? 1 : 0),
        0
      )

      if (selectedTopicCount === actualTheme.topics.length - 1) {
        storeSelectTheme({ themeId: actualTheme.themeId ?? '', topicCategory: category })
        storeUnselectTopics({
          topicIds: actualTheme.topics.map(topic => topic.topicId),
          topicCategory: category
        })

        return
      }

      storeSelectTopics({ topicIds: [topicId], topicCategory: category })
      return
    }

    if (tempThemes.includes(actualTheme.themeId ?? 'unclassified')) {
      storeUnselectTheme({ themeId: actualTheme.themeId ?? '', topicCategory: category })
      storeSelectTopics({
        topicIds: actualTheme.topics.map(topic => topic.topicId),
        topicCategory: category
      })
    }
    storeUnselectTopics({ topicIds: [topicId], topicCategory: category })
  }

  const onApply = category === 'PRODUCT_AREA' ? applyProductArea : applyOtherTopics

  const filteredThemes = useMemo(() => themes.filter(theme => theme.topics.length > 0), [themes])

  return {
    hasChanges,
    onApply,
    isUnclassifiedChecked,
    getThemeCheckedState,
    isTopicChecked,
    onThemeCheck,
    onTopicCheck,
    filteredThemes,
    onUnclassifiedCheck
  }
}

export default useTopicsFilter
