import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'

const Header = styled('p', {
  borderRadius: 8,
  bAll: '$brandPrimaryPure',
  borderLeft: '4px solid $brandPrimaryPure',
  outline: '10px solid transparent !important',
  padding: '$xs $xxs',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: '$nano',
  textDecoration: 'none',
  boxSizing: 'border-box',
  mb: '$xs',

  '& svg': {
    minWidth: 16,
    minHeight: 16
  }
})

const IconBox = styled(FlexContainer, {
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$micro',
  padding: '$nano',
  boxSizing: 'border-box',
  background: '$brandPrimaryPure'
})

const Container = styled(FlexContainer, {
  padding: '$xs',
  overflow: 'hidden'
})

const FeedbackListContainer = styled(FlexContainer, {
  overflow: 'auto',
  pr: '$nano',

  '& .feedback-evidence-item:first-of-type': {
    mt: 0
  }
})

const RootCauseFeedbackModalStyles = {
  Header,
  IconBox,
  Container,
  FeedbackListContainer
}

export default RootCauseFeedbackModalStyles
