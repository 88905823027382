import {
  Keyword,
  KeywordCluster,
  KeywordSuggestionMap,
  KeywordWithTopicIds
} from '@/types/keywords'
import createInfiniteScrollStore, { InifiniteScrollSlice } from './createInfiniteScrollSlice'
import { createWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'

interface KeywordsTopicIdsParams {
  ids: string | string[]
  topicId: string
  topicName?: string
}
interface KeywordsState {
  checkedKeywords: KeywordWithTopicIds[]
  newKeyword: string | null
  keywordsSuggestionsByTopicId: KeywordSuggestionMap
  selectedText: string
  clickedKeywords?: Keyword | Keyword[]
  reasonsKeywordHashes: string[]

  setCheckedKeywords: (keywords: KeywordWithTopicIds[]) => void
  checkKeyword: (keyword: KeywordWithTopicIds) => void
  uncheckKeyword: (keyword: string) => void

  setKeywordsSuggestions: (suggestion: KeywordSuggestionMap) => void
  setNewKeyword: (keywordText: string | null) => void
  setSelectedText: (text: string) => void

  updateKeywordsTopicIds: (params: KeywordsTopicIdsParams) => void
  removeRelationsFromKeywords: (params: KeywordsTopicIdsParams) => void
  removeKeyword: (keywordHash: string) => void
  removeKeywordBatch: (keywordsBatch: string[]) => void

  setClickedKeyword: (keywordId: Keyword | Keyword[] | undefined) => void

  getFirstFrequency: () => number
  getClusterById: (clusterId: string) => KeywordCluster | undefined
  getKeywordById: (keywordId: string) => Keyword | undefined

  setReasonsKeywordHashes: (keywordHashes: string[]) => void

  getHasClusterWithoutReasons: () => boolean
}

const keywordsInfiniteScrollSlice = createInfiniteScrollStore<KeywordCluster>()

const useKeywordStore = createWithEqualityFn<
  InifiniteScrollSlice<KeywordCluster> & KeywordsState
>()(
  (set, ...args) => ({
    ...keywordsInfiniteScrollSlice(set, ...args),

    checkedKeywords: [],
    newKeyword: null,
    keywordsSuggestionsByTopicId: {},
    selectedText: '',
    clickedKeyword: undefined,
    reasonsKeywordHashes: [],

    setCheckedKeywords: keywords =>
      set(state => ({
        ...state,
        checkedKeywords: keywords
      })),

    checkKeyword: keyword =>
      set(state => ({
        ...state,
        checkedKeywords: [...state.checkedKeywords, keyword]
      })),

    uncheckKeyword: keywordHash =>
      set(state => ({
        ...state,
        checkedKeywords: state.checkedKeywords.filter(
          keyword => keyword.keywordHash !== keywordHash
        )
      })),

    setNewKeyword: (keywordText: string | null) =>
      set(state => ({
        ...state,
        newKeyword: keywordText
      })),

    setKeywordsSuggestions: (suggestion: KeywordSuggestionMap) =>
      set(state => ({
        ...state,
        keywordsSuggestionsByTopicId: {
          ...state.keywordsSuggestionsByTopicId,
          ...suggestion
        }
      })),

    setSelectedText: (text: string) =>
      set(state => ({
        ...state,
        selectedText: text
      })),

    updateKeywordsTopicIds: ({ ids, topicId, topicName }: KeywordsTopicIdsParams) =>
      set(state => {
        const keywordsIds = Array.isArray(ids) ? ids : [ids]
        return {
          ...state,
          data: state.data.map(cluster => ({
            ...cluster,
            keywordList: cluster.keywordList.map(keyword =>
              keywordsIds.includes(keyword.keywordHash)
                ? {
                    ...keyword,
                    topics: [...keyword.topics, { topicId, name: topicName ?? '' }]
                  }
                : keyword
            )
          }))
        }
      }),

    removeRelationsFromKeywords: ({ ids, topicId }: KeywordsTopicIdsParams) =>
      set(state => {
        const keywordsIds = Array.isArray(ids) ? ids : [ids]
        return {
          ...state,
          data: state.data.map(cluster => ({
            ...cluster,
            keywordList: cluster.keywordList.map(keyword =>
              keywordsIds.includes(keyword.keywordHash)
                ? {
                    ...keyword,
                    topics: keyword.topics.filter(topic => topic.topicId !== topicId)
                  }
                : keyword
            )
          }))
        }
      }),

    removeKeyword: (keywordHash: string) =>
      set(state => ({
        ...state,
        data: state.data.map(cluster => ({
          ...cluster,
          keywordList: cluster.keywordList.filter(keyword => keyword.keywordHash !== keywordHash)
        }))
      })),

    removeKeywordBatch: keywordsBatch =>
      set(state => ({
        ...state,
        data: state.data
          .map(cluster => ({
            ...cluster,
            keywordList: cluster.keywordList.filter(
              keyword => !keywordsBatch.includes(keyword.keywordHash)
            )
          }))
          .filter(cluster => cluster.keywordList.length > 0)
      })),

    setClickedKeyword: clickedKeywords => set(() => ({ clickedKeywords })),
    getFirstFrequency: () => {
      const [get] = args
      const { data } = get()
      return data[0]?.frequency
    },

    getClusterById: clusterId => {
      const [get] = args
      const { data } = get()
      return data.find(cluster => cluster.cluster === clusterId)
    },

    getKeywordById: keywordId => {
      const [get] = args
      const { data } = get()
      const keywords = data.flatMap(cluster => cluster.keywordList)

      return keywords.find(keyword => keyword.keywordHash === keywordId)
    },

    setReasonsKeywordHashes: keywordHashes => set(() => ({ reasonsKeywordHashes: keywordHashes })),

    getHasClusterWithoutReasons: () => {
      const [get] = args
      const { data, checkedKeywords } = get()

      const clustersWithoutReasons = data.filter(cluster => !cluster.totalReasons)
      const keywordHashes = clustersWithoutReasons.flatMap(cluster =>
        cluster.keywordList.map(keyword => keyword.keywordHash)
      )

      return checkedKeywords.some(checkedKeyword =>
        keywordHashes.includes(checkedKeyword.keywordHash)
      )
    }
  }),
  shallow
)

export default useKeywordStore
