import FlexContainer from '@/components/atoms/flex-container'
import { ProgressBar } from '@/components/atoms/progress-bar'
import TableV2 from '@/components/atoms/table-v2/TableV2'
import useMetricsTableColumns from '@/hooks/metrics/useMetricsTableColumns'
import useSegmentationsWithMetricsQuery from '@/hooks/segmentation/useSegmentationsWithMetricsQuery'
import useSegment from '@/hooks/useSegment'
import useUser from '@/hooks/useUser'
import { LoadStep } from '@/store/useAnalysisHubStore'
import { SegmentationItemWithMetrics } from '@/types/segmentation/Segmentation'
import { getParamsFromFilterContent } from '@/utils/filters'
import {
  ColumnDef,
  ColumnFiltersState,
  ColumnSort,
  getCoreRowModel,
  OnChangeFn,
  SortingState,
  useReactTable
} from '@tanstack/react-table'
import { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { getHeaderCss } from '../opportunities/opportunityTableUtils'
import Button from '@/components/atoms/button'
import { ArrowDown, ArrowUp } from '@phosphor-icons/react'
import TableSkeleton from '../skeleton/TableSkeleton'
import { colors } from '@/theme'
import SegmentationNameCell from './SegmentationNameCell'
import useSegmentation from '@/hooks/segmentation/useSegmentation'
// import shortUUID from 'short-uuid'
import SegmentationOptions from './SegmentationOptions'
import useSegmentationStore from '@/store/useSegmentationStore'
import { DeleteDialog } from '@/components/atoms/dialog'
import RenameSegmentationModal from './RenameSegmentationModal'
import NoResults from '../area-of-interest/AreaOfInterestList/NoResults'
// import useNavitagateTo from '@/hooks/useNavigateTo'

const ProgressText = ({ step }: { step: LoadStep }) => {
  if (step === 'loading')
    return (
      <ProgressBar.TextParagraph>
        <Trans i18nKey="loadingSegmentationsList">
          <em>Loading</em> your segmentation list...
        </Trans>
      </ProgressBar.TextParagraph>
    )

  if (step === 'ordering')
    return (
      <ProgressBar.TextParagraph>
        <Trans i18nKey="orderingAllSegmentations">
          <em>Ordering</em> all segmentations...
        </Trans>
      </ProgressBar.TextParagraph>
    )

  if (step === 'calculating')
    return (
      <ProgressBar.TextParagraph>
        <Trans i18nKey="calculatingAllMetrics">
          <em>Calculating</em> all metrics...
        </Trans>
      </ProgressBar.TextParagraph>
    )

  return <></>
}

export interface SegmentationsTableProps {
  areaId?: string
  opportunityId?: string
}

const SegmentationsTable = ({ areaId, opportunityId }: SegmentationsTableProps) => {
  const { track } = useSegment()
  const { t } = useTranslation()

  // const { navigateTo: navigate } = useNavitagateTo()

  const [sorting, setSorting] = useState<ColumnSort[]>([{ id: 'count', desc: true }])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    { id: 'name', value: true }
  ])

  const currentSegmentation = useSegmentationStore(state => state.currentSegmentation)
  const setCurrentSegmentation = useSegmentationStore(state => state.setCurrentSegmentation)

  const { setSegmentation, deleteSegmentation, updateSegmentation, isDeletingSegmentation } =
    useSegmentation()

  // const translator = useMemo(() => shortUUID(), [])

  const onSegmentationClick = useCallback(
    (segmentation: SegmentationItemWithMetrics) => {
      let from = 'home_all_segmentations'
      if (areaId) {
        from = 'area'
        setSegmentation(segmentation, false)
      } else if (opportunityId) {
        from = 'opportunity'
        setSegmentation(segmentation, false)
      } else {
        setSegmentation(segmentation)
      }

      track('segment_access', {
        segment_name: segmentation.name,
        from
      })

      // const shortSegmentationId = translator.fromUUID(segmentation.segmentationId)
      // navigate(`/segment/${shortSegmentationId}`)
    },
    [track, setSegmentation, areaId, opportunityId]
  )

  const { metricColumns, columnVisibility, setColumnVisibility } =
    useMetricsTableColumns<SegmentationItemWithMetrics>({
      filterFn: item => ({
        ...getParamsFromFilterContent(item.content ?? [])
        // context: item.context,
      }),
      metricFor: 'segmentation'
    })

  const [segmentationToDelete, setSegmentationToDelete] = useState<string | null>(null)
  const onDeleteClick = useCallback((segmentation: SegmentationItemWithMetrics) => {
    setSegmentationToDelete(segmentation.segmentationId)
  }, [])

  const onConfirmDelete = () => {
    if (!segmentationToDelete) return
    if (currentSegmentation?.segmentationId === segmentationToDelete) {
      setCurrentSegmentation(undefined)
    }

    deleteSegmentation(segmentationToDelete)
    track('segment_deleted', {
      segment_name: segmentationToDelete,
      segment_id: segmentationToDelete
    })
  }

  const onOpenDeleteChange = (open: boolean) => {
    setSegmentationToDelete(prevSegmentation => (open ? prevSegmentation : null))
  }

  const [segmentationToRename, setSegmentationToRename] =
    useState<SegmentationItemWithMetrics | null>(null)
  const onRenameClick = useCallback((segmentation: SegmentationItemWithMetrics) => {
    setSegmentationToRename(segmentation)
  }, [])

  const onConfirmRename = (newName: string) => {
    if (!segmentationToRename) return
    updateSegmentation({
      segmentation: segmentationToRename,
      name: newName
    })

    if (currentSegmentation?.segmentationId === segmentationToRename.segmentationId) {
      setCurrentSegmentation({ ...currentSegmentation, name: newName })
    }
  }

  const onOpenRenameChange = (open: boolean) => {
    setSegmentationToRename(prevSegmentation => (open ? prevSegmentation : null))
  }

  const { userPermissions } = useUser()
  const isManager = userPermissions.opportunities.includes('manager')

  const isNavigationDisabled = Boolean(areaId || opportunityId)

  const segmentationColumns: ColumnDef<SegmentationItemWithMetrics>[] = useMemo(() => {
    const columns: ColumnDef<SegmentationItemWithMetrics>[] = [
      {
        accessorKey: 'name',
        id: 'name',
        header: () => (
          <FlexContainer alignItems="center" css={{ ml: 21 }} gap="nano">
            <span>{t('segments')}</span>
          </FlexContainer>
        ),
        enableHiding: false,
        minSize: 400,
        sortDescFirst: true,
        cell: ({ row }) => (
          <SegmentationNameCell
            disabled={isNavigationDisabled}
            data={row.original}
            onSegmentationClick={onSegmentationClick}
            showCheckbox={false}
          />
        ), // TODO: add SegmentationNameCell
        footer: ({ column }) => column.id
      },
      ...metricColumns
    ]

    if (isManager) {
      columns.push({
        id: 'options',
        minSize: 40,
        maxSize: 40,
        enableSorting: false,
        cell: props => (
          <SegmentationOptions
            data={props.row.original}
            onDeleteClick={onDeleteClick}
            onRenameClick={onRenameClick}
          />
        )
      })
    }

    return columns
  }, [
    metricColumns,
    t,
    isManager,
    onSegmentationClick,
    onDeleteClick,
    onRenameClick,
    isNavigationDisabled
  ])

  const getRowId = useCallback(
    (row: SegmentationItemWithMetrics) => row.segmentationId + '&&' + row.filterId,
    []
  )

  const {
    segmentations,
    allSegmentations,
    isLoadingAllSegmentations,
    isLoadingAllSegmentationsWithSortMetric,
    isLoadingSegmentationsWithAllMetrics,
    hasMore,
    loadNextPage,
    loadStep,
    progress,
    resetPage
  } = useSegmentationsWithMetricsQuery({
    enabled: true,
    sortColumn: sorting[0]?.id ?? 'count',
    sortDirection: sorting[0] ? (sorting[0].desc ? 'desc' : 'asc') : 'desc',
    areaId,
    opportunityId
  })

  const onSortingChange: OnChangeFn<SortingState> = useCallback(
    updater => {
      setSorting(updater)
      resetPage()
    },
    [resetPage]
  )

  const isLoadMoreDisabled = isLoadingAllSegmentations || isLoadingAllSegmentationsWithSortMetric
  const isSomethingLoading =
    isLoadingAllSegmentations ||
    isLoadingAllSegmentationsWithSortMetric ||
    isLoadingSegmentationsWithAllMetrics

  const isProgressVisible = !areaId && !opportunityId && isSomethingLoading

  const table = useReactTable<SegmentationItemWithMetrics>({
    data: segmentations,
    columns: segmentationColumns,
    manualFiltering: true,
    manualSorting: true,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange,
    enableColumnResizing: false,
    enableHiding: true,
    enableRowSelection: false,
    enableSorting: !isSomethingLoading,
    state: {
      columnVisibility,
      columnFilters,
      sorting,
      columnPinning: {
        left: ['name'],
        right: ['options']
      }
    }
  })

  const headerCss = useCallback(getHeaderCss, [])

  const renderFooter = () => {
    if (hasMore) {
      return (
        <FlexContainer alignItems="center" css={{ mb: 0 }} fullWidth justifyContent="center">
          <Button disabled={isLoadMoreDisabled} onClick={loadNextPage} variant="link">
            <ArrowDown />
            <span>{t('loadMore')}</span>
          </Button>
        </FlexContainer>
      )
    }
  }

  if (allSegmentations.length === 0 && !isSomethingLoading) {
    return (
      <FlexContainer
        css={{ maxHeight: 'max(63.5vh, 500px)', pb: '$xxs' }}
        direction="column"
        gap="xxxs"
      >
        <NoResults />
      </FlexContainer>
    )
  }

  return (
    <FlexContainer
      css={{ maxHeight: 'max(63.5vh, 500px)', pb: '$xxs' }}
      direction="column"
      gap="xxxs"
    >
      <ProgressBar.Container direction="column" fullWidth gap="micro" visible={isProgressVisible}>
        <ProgressText step={loadStep} />
        <ProgressBar.Bar max={100} value={progress} />
      </ProgressBar.Container>

      {isLoadingAllSegmentations ? (
        <TableSkeleton />
      ) : (
        <TableV2
          enableSort
          footer={renderFooter()}
          sortIndicator={<ArrowUp color={colors.brandPrimaryPure} size={16} />}
          table={table}
          thContainerProps={headerCss}
          dimmed={isNavigationDisabled}
        />
      )}

      {segmentationToDelete && (
        <DeleteDialog
          cancelText={t('cancel')}
          confirmText={t('delete')}
          description={t('deleteSegmentDialogDescription')}
          isDeleting={isDeletingSegmentation}
          onConfirmDelete={onConfirmDelete}
          onOpenChange={onOpenDeleteChange}
          open={!!segmentationToDelete}
          title={t('deleteSegmentDialogTitle')}
        />
      )}
      {segmentationToRename && (
        <RenameSegmentationModal
          onOpenChange={onOpenRenameChange}
          onRename={onConfirmRename}
          open={!!segmentationToRename}
          previousName={segmentationToRename.name ?? ''}
        />
      )}
    </FlexContainer>
  )
}

export default SegmentationsTable
