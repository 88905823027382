import DatetimeFilterRow from './DatetimeFilterRow'
import { FilterComponentProps } from './types'
import useEnumFiltersQuery from '@/hooks/filters/useEnumFiltersQuery'
import { useMemo } from 'react'
import EnumFilterRow from './EnumFilterRow'
import TextFilterRow from './TextFilterRow'
import BooleanFilterRow from './BooleanFilterRow'
import UniqueFilterRow from './UniqueFilterRow'
import NumberFilterRow from './NumberFilterRow'
import useAccountStringFilter from '../../FiltersSidebar/CustomerRecordsFilter/Accounts/useAccountStringFilter'
import useCustomerUsersStringFilter from '../../FiltersSidebar/CustomerRecordsFilter/Users/useCustomerUsersStringFilter'

const GenericFilterRow = ({ filter, onFilterChange }: FilterComponentProps) => {
  const { data: enumData, isLoading: regularEnumIsLoading } = useEnumFiltersQuery({
    name: filter.name,
    enabled: filter.type === 'enum'
  })
  const { options: accountOptions, isLoading: accountIsLoading } = useAccountStringFilter({
    filterKey: filter.name.replace('account.', ''),
    enabled: filter.type === 'accounts-enum'
  })
  const { options: userOptions, isLoading: userIsLoading } = useCustomerUsersStringFilter({
    filterKey: filter.name.replace('user.', ''),
    enabled: filter.type === 'users-enum'
  })

  const enumIsLoading = useMemo(() => {
    if (filter.type === 'enum') return regularEnumIsLoading
    else if (filter.type === 'accounts-enum') return accountIsLoading
    else if (filter.type === 'users-enum') return userIsLoading
    else return false
  }, [filter.type, regularEnumIsLoading, accountIsLoading, userIsLoading])

  const enumOptions = useMemo(() => {
    if (filter.type === 'accounts-enum') return accountOptions.map(value => ({ value }))
    if (filter.type === 'users-enum') return userOptions.map(value => ({ value }))
    return enumData?.values?.map(value => ({ value })) ?? []
  }, [enumData, filter.type, accountOptions, userOptions])

  if (filter.type.includes('datetime') || filter.type.includes('date')) {
    return <DatetimeFilterRow filter={filter} onFilterChange={onFilterChange} />
  }

  if (filter.type.includes('text')) {
    return <TextFilterRow filter={filter} onFilterChange={onFilterChange} />
  }

  if (filter.type.includes('boolean')) {
    return <BooleanFilterRow filter={filter} onFilterChange={onFilterChange} />
  }

  if (filter.type.includes('enum')) {
    return (
      <EnumFilterRow
        filter={filter}
        isLoading={enumIsLoading}
        onFilterChange={onFilterChange}
        options={enumOptions}
      />
    )
  }

  if (filter.type.includes('number') || filter.type.includes('money')) {
    return (
      <NumberFilterRow
        filter={filter}
        isNew={false}
        money={filter.type.includes('money')}
        onFilterChange={onFilterChange}
      />
    )
  }

  // TODO: change to a proper component
  if (filter.type.includes('id')) {
    return <UniqueFilterRow filter={filter} onFilterChange={onFilterChange} />
  }

  if (filter.type.includes('unique')) {
    return <UniqueFilterRow filter={filter} onFilterChange={onFilterChange} />
  }

  return <></>
}

export default GenericFilterRow
