import { TopicCategory } from '@/types/classification'
import { FilterTopicOption } from '@/types/filters/Filters'
import { TopicsFilterContent, useTopicsFilter } from '../../FiltersSidebar/TopicsFilter'
import { FilterItemDropdown } from '../FilterItem'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

interface FilterItemDropdownTopicProps {
  filter: FilterTopicOption[]
  category: TopicCategory
}

function FilterItemDropdownTopic(props: FilterItemDropdownTopicProps) {
  const {
    filteredThemes,
    getThemeCheckedState,
    hasChanges,
    isTopicChecked,
    isUnclassifiedChecked,
    onApply,
    onThemeCheck,
    onTopicCheck,
    onUnclassifiedCheck
  } = useTopicsFilter({ category: props.category })
  const { t } = useTranslation()

  const itemTitle = {
    PRODUCT_AREA: t('productAreas'),
    OTHER: t('other')
  }

  function getTooltipText(filter: FilterTopicOption[]) {
    const selectedOptions = filter.map(filter => `${filter.text}`).join(t('or'))

    return i18n.t('isSelectedOptions', { selectedOptions })
  }

  return (
    <FilterItemDropdown
      content={props.filter.length}
      contentType="count"
      disabled={!hasChanges}
      dropdown={
        <TopicsFilterContent
          filteredThemes={filteredThemes}
          getThemeCheckedState={getThemeCheckedState}
          hasChanges={hasChanges}
          isTopicChecked={isTopicChecked}
          isUnclassifiedChecked={isUnclassifiedChecked}
          itemTitle={itemTitle[props.category]}
          onApply={onApply}
          onThemeCheck={onThemeCheck}
          onTopicCheck={onTopicCheck}
          onUnclassifiedCheck={onUnclassifiedCheck}
        />
      }
      onApply={onApply}
      title={itemTitle[props.category]}
      tooltip={getTooltipText(props.filter)}
    />
  )
}

export default FilterItemDropdownTopic
