import { CSS, styled } from '@/theme'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { ReactNode } from 'react'

export interface TabButtonItem {
  children: ReactNode
  value: string
  disabled?: boolean
}

export interface TabsButtonProps extends TabsPrimitive.TabsProps {
  children: ReactNode
  tabs: TabButtonItem[]
  onTabClick?: React.MouseEventHandler<HTMLButtonElement>
  css?: CSS
}

export const TabsButtonList = styled(TabsPrimitive.List, {
  display: 'flex',
  flexShrink: 0
})

export const TabsButtonTrigger = styled(TabsPrimitive.Trigger, {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 8,
  color: '$dark',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '115%',
  background: '$white',
  height: 40,
  padding: '0 24px',
  cursor: 'pointer',

  bAll: '$stroke',
  borderLeft: 'none',

  '&:first-child': {
    bLeft: '$stroke',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8
  },

  '&:last-child': {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8
  },

  '&:hover': {
    background: '$pajaritoLight'
  },

  '&[data-state="active"]': {
    fontWeight: 600,
    background: '$pajaritoLight'
  }
})

export const TabsButton = styled(TabsPrimitive.Root, {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'clip'
})
