import { memo } from 'react'
import * as Tabs from '@radix-ui/react-tabs'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { styled } from '@/theme'

import FlexContainer from '@/components/atoms/flex-container'
import { useTranslation } from 'react-i18next'
import Text from '@/components/atoms/text'

const FormStepList = styled(TabsPrimitive.List, {
  display: 'flex',
  gap: 8,
  margin: 0
})

const FormStepTrigger = styled(TabsPrimitive.Trigger, {
  border: 0,
  borderRadius: 8,
  height: 4,
  width: 32,

  variants: {
    status: {
      current: { backgroundColor: '$brandPrimaryPure' },
      done: { backgroundColor: '$brandPrimaryPure' },
      next: { backgroundColor: '#B9CCD2' }
    }
  }
})

const DISPLAY_STEPS = ['round 0', 'round 1', 'done'] as const
export type DisplayStep = (typeof DISPLAY_STEPS)[number]

const CalibrateSteps = ({ currentRound }: { currentRound: number }) => {
  const { t } = useTranslation()

  const getCurrentStep = (round: number): DisplayStep => {
    if (round === 0) return 'round 0'
    if (round === 1) return 'round 1'
    return 'done'
  }

  const currentStep = getCurrentStep(currentRound)

  const getStepStatus = (step: DisplayStep) => {
    if (
      currentStep === 'done' ||
      DISPLAY_STEPS.indexOf(currentStep) > DISPLAY_STEPS.indexOf(step)
    ) {
      return 'done'
    }
    return currentStep === step ? 'current' : 'next'
  }

  return (
    <FlexContainer css={{ marginTop: '$xxs' }} direction="column" gap="xxs">
      <FlexContainer alignItems="center" gap="xxs">
        <Text fontSize="xxxs" fontWeight="semibold">
          {t('calibrateOpportunityRounds')}
        </Text>
        <Tabs.Root value={currentStep}>
          <FormStepList>
            {DISPLAY_STEPS.map(step => (
              <FormStepTrigger disabled key={step} status={getStepStatus(step)} value={step} />
            ))}
          </FormStepList>
        </Tabs.Root>
      </FlexContainer>
      <Text fontSize="xxxs" fontWeight="light">
        {t('calibrateOpportunityRoundsDesc')}
      </Text>
    </FlexContainer>
  )
}

export default memo(CalibrateSteps)
