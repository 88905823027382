import { ColorKeys } from '@/theme/colors'
import { IntentionType } from './trending-topics/TrendingTopics.types'
import { Bug, Icon, Megaphone } from '@phosphor-icons/react'

export const intentionMap: Record<IntentionType, { color: ColorKeys; Icon: Icon }> = {
  issue: {
    color: 'feedbackNegativePure',
    Icon: Bug
  },
  request: {
    color: 'feedbackInformativePure',
    Icon: Megaphone
  }
}
