import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import CollectionsTabs from '@/components/molecules/collections/collection-grid/CollectionsTabs'
import PageContentScroll from '@/components/molecules/layout/PageContentScroll'
import useConfigureAllowedMetrics from '@/hooks/metrics/useConfigureAllowedMetrics'
import useUser from '@/hooks/useUser'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useOpportunityStore from '@/store/useOpportunityStore'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const HomePage = () => {
  const { currentUser } = useUser()

  const { t } = useTranslation()

  const setCurrentInterestArea = useCurrentInterestAreaStore(state => state.setCurrentInterestArea)
  const setCurrentOpportunity = useOpportunityStore(state => state.setCurrentOpportunity)

  const name = currentUser?.name
  const greetings = name ? `Hello, ${name}!` : 'Hello!'

  // biome-ignore lint/correctness/useExhaustiveDependencies: remove current area when entering this page
  useEffect(() => {
    setCurrentInterestArea(undefined)
    setCurrentOpportunity(undefined)
  }, [])

  useConfigureAllowedMetrics()

  return (
    <PageContentScroll>
      <FlexContainer direction="column" gap="sm">
        <Text as="h1" fontSize="xs" fontWeight="bold">
          {greetings}
        </Text>
        <Text as="h2" fontSize="xxs" fontWeight="bold">
          {t('collections')}
        </Text>
        <CollectionsTabs />
      </FlexContainer>
    </PageContentScroll>
  )
}

export default HomePage
