import { styled } from '@/theme'
import IndicatorCard from '../indicators/IndicatorCard'
import { useState } from 'react'
import CarouselIndicator from '../indicators/CarouselIndicator'
import FlexContainer from '@/components/atoms/flex-container'
import { intentionIcons } from '@/utils/intention'
import CarouselButton from '../indicators/CarouselButton'
import { IndicatorCardProps } from './TopicDetails.types'
import useTopicIntentionIndicators from '@/hooks/analytics/useTopicIntentionIndicators'
import TopicIndicatorCardSkeleton from './TopicIndicatorCardSkeleton'
import { useFiltersStore } from '@/store'
import { shallow } from 'zustand/shallow'

const CarouselIndicatorCardRoot = styled(IndicatorCard.Root, {
  flexDirection: 'column',
  alignItems: 'flex-end',
  py: '$micro',
  gap: '$xxxs',
  h2: {
    textTransform: 'capitalize'
  },

  variants: {
    intention: {
      issue: {
        color: '$feedbackNegativePure'
      },
      request: {
        color: '$feedbackInformativePure'
      },
      question: {
        color: '$neutralLowPure',
        svg: {
          color: '$neutralLowPure'
        },

        '.icon-box': {
          backgroundColor: '$feedbackWarningPure'
        }
      },
      compliment: {
        color: '$feedbackPositivePure'
      }
    }
  }
})

const intentions = ['issue', 'request', 'question', 'compliment'] as const
type IndicatorIntention = (typeof intentions)[number]

const IndicatorCardCarousel = ({ topicId, asExploreFeedbackItem = false }: IndicatorCardProps) => {
  const [intentionIndex, setIntentionIndex] = useState(0)
  const currentIntention: IndicatorIntention = intentions[intentionIndex]

  const dateRange = useFiltersStore(state => state.dateRange, shallow)

  const { text, count, comparisonPercentage, intentionPercentage, isLoading } =
    useTopicIntentionIndicators({
      intention: currentIntention,
      topicId,
      asExploreFeedbackItem
    })

  const nextIntention = () => {
    if (intentionIndex === intentions.length - 1) {
      setIntentionIndex(0)
      return
    }

    setIntentionIndex(currentIndex => currentIndex + 1)
  }

  const previousIntention = () => {
    if (intentionIndex === 0) {
      setIntentionIndex(intentions.length - 1)
      return
    }

    setIntentionIndex(currentIndex => currentIndex - 1)
  }

  return (
    <CarouselIndicatorCardRoot intention={currentIntention}>
      <CarouselIndicator.Root>
        {intentions.map(intention => (
          <CarouselIndicator.Indicator
            active={intention === currentIntention}
            intention={intention}
            key={intention}
          />
        ))}
      </CarouselIndicator.Root>
      <FlexContainer fullWidth justifyContent="stretch">
        <IndicatorCard.Column alignItems="start" fullWidth>
          <IndicatorCard.Header>
            <IndicatorCard.IconBox className="icon-box">
              {intentionIcons[currentIntention]}
            </IndicatorCard.IconBox>
            <IndicatorCard.Title>{currentIntention}</IndicatorCard.Title>
          </IndicatorCard.Header>
          <IndicatorCard.NumberContainer>
            {isLoading ? (
              <TopicIndicatorCardSkeleton.Number />
            ) : (
              <>
                <IndicatorCard.BigNumber>{intentionPercentage}%</IndicatorCard.BigNumber>
                <IndicatorCard.Paragraph>({count})</IndicatorCard.Paragraph>
              </>
            )}
          </IndicatorCard.NumberContainer>
        </IndicatorCard.Column>
        <IndicatorCard.Column fullWidth>
          <FlexContainer justifyContent="spaceBetween">
            <CarouselButton.Previous onClick={previousIntention} />
            <CarouselButton.Next onClick={nextIntention} />
          </FlexContainer>

          {!dateRange ? (
            <IndicatorCard.Fallback />
          ) : isLoading ? (
            <TopicIndicatorCardSkeleton.Text />
          ) : (
            <IndicatorCard.Paragraph>
              <em>{comparisonPercentage}</em> <span>{text}</span>
            </IndicatorCard.Paragraph>
          )}
        </IndicatorCard.Column>
      </FlexContainer>
    </CarouselIndicatorCardRoot>
  )
}

export default IndicatorCardCarousel
