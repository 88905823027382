import Checkbox from '@/components/atoms/checkbox'
import { FormEvent, useState } from 'react'
import { FilterSubMenuScrollArea, SelectionContainer } from '../AddFilterMenu/AddFilterMenu.styles'
import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import useDebounce from '@/hooks/useDebounce'
import FiltersSkeleton from '../AddFilterMenu/FiltersSkeleton'
import Button from '@/components/atoms/button'
import useUngroupedTopicsFilters from './hooks/useUngroupedTopicsFilters'
import SearchInputForFilters from '../../SearchInputFilter'
import { useTranslation } from 'react-i18next'

const UngroupedTopicFilterContent = () => {
  const [searchInput, setSearchInput] = useState('')

  const debouncedSearch = useDebounce(searchInput, 500)

  const {
    topics,
    isLoading,
    isLoadingSearch,
    isFetchingNextPage,
    isShowMoreDisabled,
    fetchNextPage,
    hasNextPage,
    isTopicChecked,
    onCheckUngroupedTopic
  } = useUngroupedTopicsFilters({
    searchQuery: debouncedSearch
  })

  const onChangeSearch = (e: FormEvent<HTMLInputElement>) => {
    setSearchInput(e.currentTarget.value)
  }

  const showLoading = isLoadingSearch || (isLoading && !isFetchingNextPage)

  const { t } = useTranslation()

  return (
    <>
      <SearchInputForFilters
        css={{ mt: '$micro', mb: '$micro' }}
        onChange={onChangeSearch}
        placeholder={t('search')}
        size="small"
        small
        value={searchInput}
      />
      <FilterSubMenuScrollArea>
        <ScrollAreaViewport css={{ maxHeight: 320 }}>
          {showLoading ? (
            <FiltersSkeleton />
          ) : (
            <>
              <SelectionContainer>
                {topics.map(topic => (
                  <Checkbox
                    checked={isTopicChecked(topic.topicId)}
                    key={topic.topicId}
                    onCheckedChange={checkedState =>
                      onCheckUngroupedTopic(checkedState as boolean, topic)
                    }
                    text={topic.topicName}
                    value={'filter-' + topic.topicId}
                  />
                ))}
              </SelectionContainer>
              {isFetchingNextPage && <FiltersSkeleton />}
              {hasNextPage && (
                <Button
                  disabled={isShowMoreDisabled}
                  onClick={() => fetchNextPage()}
                  size="micro"
                  variant="link"
                >
                  {t('showMore')}
                </Button>
              )}
            </>
          )}
        </ScrollAreaViewport>

        <ScrollAreaScrollbar orientation="vertical">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </FilterSubMenuScrollArea>
    </>
  )
}

export default UngroupedTopicFilterContent
