import { keyframes, styled } from '@/theme'

const boneAnimation = keyframes({
  '0%': { opacity: 0.05 },
  '30%': { opacity: 0.1 },
  '50%': { opacity: 0.12 },
  '70%': { opacity: 0.1 },
  '100%': { opacity: 0.05 }
})

const Bone = styled('div', {
  borderRadius: 8,
  background: '$pajarito',
  width: '100%',
  height: 40,

  animation: `${boneAnimation} 1.5s linear infinite`
})

export default Bone
