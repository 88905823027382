import Bone from '@/components/atoms/bone'
import { styled } from '@/theme'

const SkeletonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  width: 300,
  marginLeft: 44
})

interface KeywordsSkeletonProps {
  size?: number
}

function KeywordsSkeleton({ size = 6 }: KeywordsSkeletonProps) {
  return (
    <SkeletonContainer>
      {Array(size)
        .fill(null)
        .map((_, index) => (
          <Bone css={{ height: 24, width: '100%', borderRadius: 24 }} key={index} />
        ))}
    </SkeletonContainer>
  )
}

export default KeywordsSkeleton
