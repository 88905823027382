import VolumeBar from '@/components/atoms/volume-bar'
import { CSS, styled } from '@/theme'
import Card from '../../card/Card'
import { ReactNode } from 'react'
import FlexContainer from '@/components/atoms/flex-container'
import {
  ScrollAreaRoot as ScrollAreaRootPrimitive,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '../../scroll-area'

import EmptyImageSource from '@/assets/drawings/bird-empty-card.png'
import { Link } from 'react-router-dom'

const Root = styled(Card.Root, {
  flexDirection: 'column',
  gap: '$xs',
  height: 522,
  boxSizing: 'border-box',

  variants: {
    type: {
      emerging: {
        color: '$brandPrimaryPure',
        '.bar': { color: '$brandPrimaryPure' },
        '.bar-container': { backgroundColor: '$brandPrimaryLight' },
        '.number': { backgroundColor: '$brandPrimaryLight' }
      },
      issues: {
        color: '$feedbackNegativePure',
        '.bar': { color: '$feedbackNegativePure' },
        '.bar-container': { backgroundColor: '$feedbackNegativeLight' },
        '.number': { backgroundColor: '$feedbackNegativeLight' }
      },
      requests: {
        color: '$feedbackInformativePure',
        '.bar': { color: '$feedbackInformativePure' },
        '.bar-container': { backgroundColor: '$feedbackInformativeLight' },
        '.number': { backgroundColor: '$feedbackInformativeLight' }
      }
    }
  }
})

const Row = styled(FlexContainer, {
  display: 'flex',
  justifyContent: 'space-between'
})

const Header = Card.Header
const IconBox = Card.IconBox
const Title = Card.Title

const ItemList = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'visible',
  gap: '$xxxs',
  position: 'relative',
  zIndex: 2
})

const Item = styled(Link, {
  borderRadius: 8,
  borderLeft: '4px solid currentColor',
  outline: '10px solid transparent !important',
  padding: '$xxs',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '$nano',
  transition: '.2s',
  color: 'inherit',
  textDecoration: 'none',
  height: 64,
  boxSizing: 'border-box',

  variants: {
    clickable: {
      true: {
        cursor: 'pointer',
        '&:hover': {
          boxShadow: '$soft'
        }
      },
      false: {
        cursor: 'default'
      }
    },
    empty: {
      true: {
        color: '$neutralLowLight',
        '.bar': { color: '$neutralLowLight' },
        h3: { color: '$neutralLowLight' },
        '.number': { backgroundColor: '$neutralHighMedium' }
      }
    }
  }
})

const ItemTitle = styled('h3', {
  minWidth: 224,
  maxWidth: 224,
  maxHeight: 36,
  color: '$neutralLowPure',
  fontSize: '$xxxs',
  fontWeight: '$regular',
  display: 'block',
  alignItems: 'center',
  overflow: 'clip',
  textOverflow: 'ellipsis'
})

interface ProgressBarProps {
  value: number
  max: number
  css?: CSS
}
const ProgressBar = ({ value, max, css }: ProgressBarProps) => (
  <VolumeBar
    clickable={false}
    css={{ background: 'CurrentColor', mr: '$micro', ...css }}
    fullRounded
    height="small"
    max={max}
    showNumber={false}
    value={value}
  />
)

const NummberWrapper = styled('span', {
  px: '$micro',
  py: '$nano',
  borderRadius: 8,
  fontSize: '$xxxs',
  lineHeight: '115%'
})

interface NumberProps {
  children: ReactNode
  css?: CSS
}
const NumberSpan = ({ children, css }: NumberProps) => (
  <NummberWrapper className="number" css={css}>
    {children}
  </NummberWrapper>
)

const ScrollAreaRoot = styled(ScrollAreaRootPrimitive, {
  overflow: 'hidden'
})

interface ScrollAreaProps {
  children: ReactNode
  id?: string
}
const ScrollArea = ({ children, id }: ScrollAreaProps) => (
  <ScrollAreaRoot>
    <ScrollAreaViewport css={{ maxHeight: 368 }} id={id}>
      {children}
    </ScrollAreaViewport>
    <ScrollAreaScrollbar orientation="vertical">
      <ScrollAreaThumb />
    </ScrollAreaScrollbar>
  </ScrollAreaRoot>
)

const CenteredContainer = styled(FlexContainer, {
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  gap: '$xxs',
  justifyContent: 'center',
  color: '$neutralLowPure'
})

const EmptyImage = styled('img', {
  width: 192
})

const TrendingTopicsCard = {
  Root,
  Row,
  Header,
  IconBox,
  Title,
  ItemList,
  Item,
  ItemTitle,
  ProgressBar,
  Number: NumberSpan,
  ScrollArea,
  CenteredContainer,
  EmptyImageSource,
  EmptyImage
}

export default TrendingTopicsCard
