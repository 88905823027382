import { FilterString } from '@/types/filters/Filters'
import { FilterItemDropdown } from '../../AppliedFilters/FilterItem'
import { useState } from 'react'
import { NEW_FEEDBACK_TYPE_KEY } from '@/utils/filters'
import useStringGenericFilter from '../../FiltersSidebar/StringGenericFilter/useStringGenericFilterNew'
import StringFilterContent from '../../FiltersSidebar/StringGenericFilter/StringFilterContentNew'
import { getFeedbackKindPrettyName } from '@/utils/feedback'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

interface AppliedFeedbackTypeFiltersEditProps {
  filter: FilterString
  buttonDisabled?: boolean
}

function AppliedFeedbackTypeFiltersEdit(props: AppliedFeedbackTypeFiltersEditProps) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const textFormatter = (text: string) => getFeedbackKindPrettyName(text)

  function getTooltipText(filter: FilterString) {
    const selectedOptions = filter.selected
      .map(option => `<b>${textFormatter(option)}</b>`)
      .join(t('or'))

    return i18n.t('isSelectedOptions', { selectedOptions })
  }

  const { hasChanges, apply, isChecked, selectOption, options } = useStringGenericFilter({
    filterKey: NEW_FEEDBACK_TYPE_KEY,
    enabled: open,
    name: 'kind'
  })

  return (
    <FilterItemDropdown
      buttonDisabled={props.buttonDisabled}
      content={props.filter.selected.length}
      contentType="count"
      disabled={!hasChanges}
      dropdown={
        <StringFilterContent
          isChecked={isChecked}
          options={options}
          selectOption={selectOption}
          textFormatter={textFormatter}
        />
      }
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      title={t('feedbackType')}
      tooltip={getTooltipText(props.filter)}
    />
  )
}

export default AppliedFeedbackTypeFiltersEdit
