import { useFiltersStore } from '@/store'
import FilterItem from '../FilterItem'
import FilterItemDropdownUngrouped from '../FilterItem/FilterItemDropdownUngroupedTopic'

function AppliedUngroupedTopics() {
  const ungrounpedTopics = useFiltersStore(state => state.ungroupedTopics.selected)
  const removeUngroupedTopics = useFiltersStore(state => state.removeUngroupedTopics)

  return [
    ungrounpedTopics.length > 0 ? (
      <FilterItem
        editButton={<FilterItemDropdownUngrouped filter={ungrounpedTopics} />}
        name="Topics"
        onDelete={removeUngroupedTopics}
        key="ungrouped-topics"
      />
    ) : null
  ].filter(Boolean) as JSX.Element[]
}

export default AppliedUngroupedTopics
