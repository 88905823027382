import MetricsService from '@/services/MetricsService'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { MetricsRequests } from '@/types/metrics'
import { endDateParam, startDateParam } from '@/utils/date'
import { useQuery } from '@tanstack/react-query'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import useAdvancedFiltersStore from '@/store/useFiltersStore/useAdvancedFiltersStore'

interface Params {
  filters?: FeedbackListQueryParams[]
  enabled?: boolean
  keepPreviousData?: boolean
  sources: string[]
  metricList: MetricsRequests.MetricsPayload['metric_list']
}

const useMetricsQuery = ({
  filters = [],
  enabled = true,
  keepPreviousData,
  sources,
  metricList
}: Params) => {
  const isFetchingContext = useAdvancedFiltersStore(state => state.isFetchingContext)

  const { dateRange, datePeriod } = useDateFilterStore(state => ({
    dateRange: state.dateRange,
    datePeriod: state.datePeriod
  }))

  const isEnabled = enabled && metricList.length > 0

  const queryFn = async () => {
    let startDate: string | undefined
    let endDate: string | undefined
    if (datePeriod !== 'allTime' && dateRange) {
      startDate = startDateParam(dateRange.start)
      endDate = endDateParam(dateRange.end)
    }
    const payload: MetricsRequests.MetricsPayload = {
      filter_list: filters,
      metric_list: metricList,
      posted_at_gte: startDate,
      posted_at_lt: endDate
    }

    const [error, data] = await MetricsService.metrics(payload)
    if (error) throw error
    return data
  }

  const queryKey = ['metrics', metricList, filters, { dateRange, datePeriod }, sources]
  const query = useQuery({
    queryKey,
    queryFn,
    enabled: isEnabled,
    keepPreviousData
  })

  return {
    ...query,
    isLoading: query.isLoading || isFetchingContext
  }
}

export default useMetricsQuery
