import Discovery from '@/components/molecules/discovery'
import useClassification from '@/hooks/useClassification'
// import useSegment from '@/hooks/useSegment'
import { useUIStore } from '@/store'
import { styled } from '@/theme'
import { useEffect } from 'react'
import DefaultContent from '@/components/molecules/layout/DefaultContent'
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import useUser from '@/hooks/useUser'
import NoPermission from '@/components/molecules/no-permission/NoPermission'

const DiscoveryScrollArea = styled(ScrollAreaRoot, {
  overflow: 'hidden',
  background: 'transparent',
  bottom: 0,
  height: '100%',

  boxSizing: 'border-box'
})

function DiscoveryPage() {
  const { verifyAllTopicAndThemes } = useClassification()
  const { setPageTitle } = useUIStore()

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen once
  useEffect(() => {
    setPageTitle('Discovery')
    verifyAllTopicAndThemes()
  }, [])

  const { userPermissions, currentUser } = useUser()
  if (userPermissions.discovery.length === 0 && currentUser) {
    return <NoPermission />
  }

  return (
    <DefaultContent searchPlaceholder="Search to discover">
      <DiscoveryScrollArea>
        <ScrollAreaViewport id="discovery-viewport">
          <Discovery />
        </ScrollAreaViewport>
        <ScrollAreaScrollbar orientation="vertical">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </DiscoveryScrollArea>
    </DefaultContent>
  )
}

export default DiscoveryPage
