export const TEXT_REPLACEMENT_PARAMS_KEY = 'text_replacement_params'

export interface DynamicTextItem {
  original: string
  replacement: string
}

export interface TextReplacementFormData {
  enable: boolean
  companyName: string | null
  userName: string | null
  email: string | null
  dynamicText: DynamicTextItem[]
}
