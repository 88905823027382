import TopicService from '@/services/TopicService'
import { TopicStatsPayload } from '@/types/classification/TopicRequests'
import { cloneObject } from '@/utils/object'
import { QueryFunctionContext, useInfiniteQuery } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import enableAutoCompleteSearch from './enableAutoCompleteSearch'

interface Params {
  searchQuery?: string
}

const useAllTopicsSearch = ({ searchQuery = '' }: Params) => {
  const searchForAllTopics = useCallback(async function (
    params: QueryFunctionContext<[string, { searchText: string }], number>
  ) {
    const { queryKey, pageParam } = params
    const { searchText } = queryKey[1]

    const pageSize = 25
    const currentPage = pageParam || 0
    const offset = pageSize * currentPage

    const payload: TopicStatsPayload = cloneObject(TopicService.DEFAULT_PAYLOAD)
    payload.sentiment_count = false
    payload.search_text = enableAutoCompleteSearch(searchText)
    payload.theme_id_list = []
    payload.return_all = true
    payload.size = pageSize
    payload.offset = offset
    payload.feedback_search_schema.size = pageSize
    payload.feedback_search_schema.offset = offset

    const [error, responseData] = await TopicService.ungroupedTopics(payload)

    if (error) {
      // addErrorToast({ text: `Failed to load topic groups. Error key: ${error.key}` })
      throw error
    }

    const { items } = responseData
    const loadedAll = !items.length || items.length < pageSize

    const newPage = loadedAll ? undefined : currentPage + 1

    return {
      topics: items,
      page: newPage
    }
  }, [])

  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ['topics-filters', { searchText: searchQuery }],
    queryFn: searchForAllTopics,
    getNextPageParam: lastPage => lastPage?.page,
    retry: false,
    refetchOnMount: true
  })

  const topics = useMemo(() => {
    if (!data) return []

    return data.pages.flatMap(page => page.topics)
  }, [data])

  const isUsingSearch = searchQuery.length > 0
  const isLoadingSearch = isUsingSearch && isLoading

  const isShowMoreDisabled = isLoading || isFetchingNextPage

  return {
    topics,
    isLoading,
    isLoadingSearch,
    isUsingSearch,
    hasNextPage,
    isFetchingNextPage,
    isShowMoreDisabled,
    fetchNextPage
  }
}

export default useAllTopicsSearch
