import FlexContainer from '@/components/atoms/flex-container'
import Loader from '@/components/atoms/loader/Loader'
import { useInfiniteQuery } from '@tanstack/react-query'
import UserList from './UsersList'
import useToastMessageStore from '@/store/useToastMessageStore'
import { useMemo, useState } from 'react'
import useCustomerUsers from '@/hooks/useCustomerUsers'
import EmptyUsers from './EmptyUsers'
import Text from '@/components/atoms/text'
import ManageCustomersHeader from '../ManageCustomersHeader'
import ImportButton from '../ImportButton'
import UserSearch from './UsersSearch'
import { CustomerRecordsUserData } from '@/types/manage-customers'
import { DeleteDialog } from '@/components/atoms/dialog'
import useLogging from '@/hooks/useLogging'
import { useTranslation } from 'react-i18next'

const Users = () => {
  const { loadCustomerUsers, deleteUser } = useCustomerUsers()
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const { t } = useTranslation()

  const { logException } = useLogging({ context: 'users' })

  const [isWaiting, setIsWaiting] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [userToDelete, setUserToDelete] = useState<CustomerRecordsUserData | undefined>()

  const {
    data,
    isLoading,
    isRefetching,
    isError,
    isSuccess,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  } = useInfiniteQuery({
    queryKey: ['customer-users', { q: searchQuery }],
    queryFn: loadCustomerUsers,
    getNextPageParam: lastPage => lastPage?.nextPage,
    retry: false
  })

  const users = useMemo(() => {
    if (!data) return []
    return data.pages.flatMap(page => page?.users || [])
  }, [data])

  const isSearching = searchQuery.length > 0

  const showLoader = (isLoading || isRefetching || isWaiting) && !isSearching
  const showEmptyUsers = !showLoader && (isError || (isSuccess && !users.length && !isSearching))
  const isLoadingSearch = isLoading && isSearching

  const showAccounts = !showLoader && !isError && (isSearching || (isSuccess && users.length > 0))

  const loadMore = () => {
    !isFetching && !isFetchingNextPage && fetchNextPage()
  }

  const onImport = (result: boolean) => {
    if (result) {
      setIsWaiting(true)
      addSuccessToast({ text: t('importCompletedRefetching') })

      setTimeout(() => {
        setIsWaiting(false)
        refetch()
      }, 5000)
    } else {
      const message = t('failedToImport')
      logException(new Error(message), { message })
      addErrorToast({ text: message })
    }
  }

  const onDeleteUser = async () => {
    if (!userToDelete) return

    await deleteUser(userToDelete.id)
    setUserToDelete(undefined)
  }

  const onOpenDeleteChange = (open: boolean) => {
    setUserToDelete(prevUser => (open ? prevUser : undefined))
  }

  return (
    <FlexContainer css={{ overflow: 'hidden', flex: 1, maxWidth: 800 }} direction="column">
      <ManageCustomersHeader
        css={{ mb: '$xs' }}
        rightContent={
          !showEmptyUsers && !showLoader ? (
            <ImportButton importSheet="users" onImport={onImport} />
          ) : undefined
        }
        title={t('users')}
      />

      {showLoader && (
        <FlexContainer alignItems="center" justifyContent="center">
          <Loader />
        </FlexContainer>
      )}

      {showEmptyUsers && <EmptyUsers onImport={onImport} />}

      {showAccounts && (
        <FlexContainer css={{ overflow: 'hidden' }} direction="column" gap="xxs">
          <Text
            as="p"
            color="neutralLowPure"
            css={{ mb: '$xs' }}
            fontSize="micro"
            fontWeight="regular"
            lineHeight="xxl"
          >
            {t(
              'thisIsYourCurrentListOfUsersEveryIncomingFeedbackThatMatchesAUserCanNowBeFilteredBasedOnUsersOrUserGroupsMakingItEasierForYouToGetAdditionalContextAndCloseYourFeedbackLoop'
            )}
          </Text>

          <UserSearch
            count={users.length}
            currentSearch={searchQuery}
            saveSearch={setSearchQuery}
            total={data?.pages[0]?.total || 0}
          />

          <UserList
            hasMore={hasNextPage}
            isFetchingPage={isFetching || isFetchingNextPage}
            isLoadingSearch={isLoadingSearch}
            isSearching={isSearching}
            loadMore={loadMore}
            onClickDelete={setUserToDelete}
            userList={users}
          />
        </FlexContainer>
      )}

      {userToDelete && (
        <DeleteDialog
          isDeleting={false}
          onConfirmDelete={onDeleteUser}
          onOpenChange={onOpenDeleteChange}
          open={!!userToDelete}
        />
      )}
    </FlexContainer>
  )
}

export default Users
