import Breadcrumb from '@/components/atoms/breadcrumb'
import FlexContainer from '@/components/atoms/flex-container'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useCollectionStore from '@/store/useCollectionStore'
import useOpportunityStore from '@/store/useOpportunityStore'
import { useMemo } from 'react'
import shortUUID from 'short-uuid'
import NewOpportunity from './Opportunities/NewOpportunity'
import { useTranslation } from 'react-i18next'
import useSegmentationStore from '@/store/useSegmentationStore'

const ExplorationBreadcrumb = () => {
  const { t } = useTranslation()
  const opportunity = useOpportunityStore(state => state.currentOpportunity)

  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const currentCollection = useCollectionStore(state => state.currentCollection)

  const currentSegmentation = useSegmentationStore(state => state.currentSegmentation)

  const translator = useMemo(() => shortUUID(), [])
  const shortAreaId = translator.fromUUID(currentInterestArea?.id ?? '')

  return (
    <FlexContainer alignItems="center" gap="micro">
      <Breadcrumb.Root>
        {(!!opportunity || !!currentInterestArea || !!currentSegmentation) && (
          <>
            <Breadcrumb.Item to="/areas-and-opportunities">
              <span>{currentCollection?.name ?? t('allOrganizationAreas')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Divider />

            {currentInterestArea && (
              <>
                <Breadcrumb.Item to={`/area/${shortAreaId}`} active={!opportunity}>
                  <span>{currentInterestArea?.name}</span>
                </Breadcrumb.Item>
                {opportunity && <Breadcrumb.Divider />}
              </>
            )}

            {currentSegmentation && (
              <Breadcrumb.Item active>{currentSegmentation?.name}</Breadcrumb.Item>
            )}
            {opportunity && <Breadcrumb.Item active>{opportunity?.name}</Breadcrumb.Item>}
          </>
        )}
      </Breadcrumb.Root>

      {opportunity?.new && <NewOpportunity.Badge css={{ maxHeight: 16 }} />}
    </FlexContainer>
  )
}

export default ExplorationBreadcrumb
