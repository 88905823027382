import useDashboardChart from '@/hooks/useDashboard/useDashboardChart'
import { ChartType, DashboardChart } from '@/types/dashboard'
import {
  DashboardChartCardContainer,
  DashboardChartCardContent,
  DashboardChartCardHeader,
  DashboardChartCardOptions,
  DashboardChartSavedMessage
} from './DashboardChartCard.styles'
import FlexContainer from '@/components/atoms/flex-container'
import SavedFilterSelection from './SavedFilterSelection'
import Select from '@/components/atoms/select'
import { IntervalOption, MetricOption } from '@/types/analytics/Analytics'
import ErrorHandler from './ErrorHandler'
import DateFilter from '../filters/DateFilter'
import { DeleteDialog } from '@/components/atoms/dialog'
import { useEffect, useRef, useState } from 'react'
import Button from '@/components/atoms/button'
import {
  CheckCircle,
  Clock,
  CloudArrowUp,
  IconContext,
  SelectionForeground,
  TrashSimple
} from '@phosphor-icons/react'
import NameInput from '@/components/atoms/input/NameInput'
import useDashboardState from '@/hooks/useDashboard/useDashboardState'
import Tooltip from '@/components/atoms/tooltip'
import LoadingChart from './LoadingChart'
import Chart from './Chart'
import useUser from '@/hooks/useUser'
import useSegment from '@/hooks/useSegment'
import { camelToSnakeCase } from '@/utils/letterCase'
import { Period } from '@/types/periods'
import { shortIntervalToLong } from '@/utils/date'

const DashboardChartCard = (chart: DashboardChart) => {
  const { track } = useSegment()

  const {
    data,
    isLoading,
    isRefetching,
    isError,
    refetch,
    setSavedFilters,
    setType,
    setInterval,
    setMetric,
    setDate,
    setName,
    metricOptions,
    chartTypeOptions,
    intervalOptions,
    duplicateChart,
    remove,
    save
  } = useDashboardChart(chart)

  const { limitReached } = useDashboardState()

  const chartRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    chartRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [])

  const [isEditingName, setIsEditingName] = useState(false)
  const onNameClick = () => {
    setIsEditingName(true)
  }
  const onConfirmName = (name: string) => {
    setIsEditingName(false)
    setName(name)
  }
  const onCancelName = () => {
    setIsEditingName(false)
  }

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const onConfirmDelete = () => {
    setIsRemoving(true)
  }

  useEffect(() => {
    if (isRemoving === true) {
      remove().then(() => {
        setIsRemoving(false)
        setDeleteDialogOpen(false)
      })
    }
  }, [isRemoving, remove])

  const onSaveClick = () => {
    save()
    track('dashboard_user_save_chart')
  }

  const onDeleteButtonClick = () => {
    setDeleteDialogOpen(true)
  }

  const onDuplicateButtonClick = () => {
    duplicateChart()
  }

  const onChangePeriod = (period: Period) => {
    track('dashboard_user_date_range_filter-options', { data_type: camelToSnakeCase(period) })
  }

  const onSelectCustom = () => {
    track('dashboard_user_date_range_filter-options', { data_type: 'custom' })
  }

  const onMetricChange = (value: string) => {
    const metric = value as MetricOption
    setMetric(metric)
    track('dashboard_user_count_by', { count_type: metric })
  }

  const onIntervalChange = (value: string) => {
    const interval = value as IntervalOption
    setInterval(interval)
    track('dashboard_user_period_by', { period_type: shortIntervalToLong(interval) })
  }

  const onChartTypeChange = (value: string) => {
    const chartType = value as ChartType
    setType(chartType)
    track('dashboard_user_chart_type', { chart_type: chartType })
  }

  const duplicateTooltipText = limitReached
    ? "Can't duplicate chart. Limit is 10 charts."
    : 'Duplicate'
  const deleteTooltipText = 'Delete'

  const isChartLoading = isLoading || isRefetching || (!data && !isError)

  const renderChart = () => {
    if (isChartLoading) {
      return <LoadingChart />
    }

    if (isError) {
      return <ErrorHandler onRetry={refetch} />
    }

    if (data) {
      return (
        <Chart
          compare={false}
          intervalOption={chart.interval}
          metricOption={chart.metric}
          timeSeries={data}
          type={chart.type}
          volumeBy="ticket"
        />
      )
    }
  }

  const id = 'chart-' + chart.id

  const { userPermissions } = useUser()
  const isManager = userPermissions.tracking.includes('manager')

  return (
    <DashboardChartCardContainer id={id} ref={chartRef}>
      <DashboardChartCardHeader>
        {isEditingName ? (
          <NameInput
            confirmLabel="Ok"
            css={{ maxWidth: 400, padding: 0, mt: -8, mb: -8 }}
            initialValue={chart.name}
            onCancel={onCancelName}
            onConfirm={onConfirmName}
          />
        ) : (
          <h2 onClick={onNameClick}>{chart.name}</h2>
        )}
        <DashboardChartCardOptions>
          <IconContext.Provider value={{ size: 16, weight: 'bold' }}>
            {isManager &&
              (chart.saved ? (
                <DashboardChartSavedMessage>
                  <CheckCircle />
                  <span>Chart view saved</span>
                </DashboardChartSavedMessage>
              ) : (
                <Button onClick={onSaveClick} size="small" variant="exception">
                  <CloudArrowUp />
                  <span>Save chart changes</span>
                </Button>
              ))}
            <Tooltip side="bottom" text={duplicateTooltipText}>
              <Button
                className="icon-button"
                disabled={limitReached}
                onClick={onDuplicateButtonClick}
                size="mediumSquare"
                variant="flat-bordered"
              >
                <SelectionForeground />
              </Button>
            </Tooltip>
            {isManager && (
              <Tooltip side="bottom" text={deleteTooltipText}>
                <Button
                  className="icon-button"
                  onClick={onDeleteButtonClick}
                  size="mediumSquare"
                  variant="flat-bordered"
                >
                  <TrashSimple />
                </Button>
              </Tooltip>
            )}
          </IconContext.Provider>
        </DashboardChartCardOptions>
      </DashboardChartCardHeader>
      <DashboardChartCardContent>
        <FlexContainer css={{ mb: 20 }} justifyContent="spaceBetween">
          <FlexContainer gap="xxs">
            <SavedFilterSelection onChange={setSavedFilters} value={chart.savedFilters} />
            <DateFilter
              appliedPeriod={chart.datePeriod}
              appliedRange={chart.dateRange}
              onChangePeriod={onChangePeriod}
              onSelectCustom={onSelectCustom}
              setDateRange={setDate}
              size="small"
            />
          </FlexContainer>
          <FlexContainer gap="xxs">
            <Select
              hideCaret
              onValueChange={onMetricChange}
              options={metricOptions}
              showSelectedIcon
              small
              value={chart.metric}
            />
            <Select
              hideCaret
              leftIcon={<Clock size={16} />}
              onValueChange={onIntervalChange}
              options={intervalOptions}
              small
              value={chart.interval}
            />
            <Select
              hideCaret
              onValueChange={onChartTypeChange}
              options={chartTypeOptions}
              showSelectedIcon
              small
              value={chart.type}
            />
          </FlexContainer>
        </FlexContainer>

        {renderChart()}
      </DashboardChartCardContent>
      <DeleteDialog
        confirmText="Delete"
        description="Deleting this chart will not affect the associated data. However, once deleted, the chart cannot be recovered."
        isDeleting={isRemoving}
        modal={false}
        onConfirmDelete={onConfirmDelete}
        onOpenChange={setDeleteDialogOpen}
        open={deleteDialogOpen}
        title="Delete chart?"
      />
    </DashboardChartCardContainer>
  )
}

export default DashboardChartCard
