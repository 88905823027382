import { Banner } from '@/components/atoms/banner'
import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import { dateShortMonthFormat } from '@/utils/date'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface NotificationFilterBannerProps {
  dateRange: RangeValue<Date> | null
  savedFilterName: string
  onDismiss: React.MouseEventHandler<HTMLButtonElement>
}

const NotificationFilterBanner = ({
  dateRange,
  savedFilterName,
  onDismiss
}: NotificationFilterBannerProps) => {
  const { t } = useTranslation()
  const formattedDateRange = useMemo(() => {
    if (!dateRange) {
      return ''
    }
    const startDate = dateShortMonthFormat(dateRange.start)
    const endDate = dateShortMonthFormat(dateRange.end)
    return ` in ${startDate} to ${endDate}`
  }, [dateRange])

  const title = t('customViewTitle', { savedFilterName })
  const description = t('customViewDescription', { savedFilterName, formattedDateRange })

  return (
    <Banner.Container fullWidth>
      <Banner.WarningIcon />
      <FlexContainer direction="column">
        <Banner.Title>{title}</Banner.Title>
        <Banner.Description>{description}</Banner.Description>
      </FlexContainer>
      <Button css={{ ml: 'auto' }} onClick={onDismiss}>
        {t('dismiss')}
      </Button>
    </Banner.Container>
  )
}

export default NotificationFilterBanner
