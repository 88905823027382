import { chartColors, colors } from '@/theme/colors'
import { IntervalOption } from '@/types/analytics/Analytics'
import { TimeSeriesGroup } from '@/types/time-series/TimeSeries'
import { intervalFormat } from '@/utils/date'
import { EChartOption } from 'echarts'
import moment from 'moment'

interface TrendLineEChartOptionsParams {
  timestamps: moment.Moment[]
  timeSeries: TimeSeriesGroup[]
  interval: IntervalOption
  xAxis?: EChartOption.XAxis
  grid?: EChartOption.Grid
  legend?: EChartOption.Legend
  enableDataZoom?: boolean
  series: EChartOption.Series[]
  dataZoomRange?: [number, number]
  disableSortSeries?: boolean
  color?: string[]
  tooltipFormatter?: (params: EChartOption.Tooltip.Format[]) => string
}

export const getXAxisData = (timestamps: moment.Moment[], interval: IntervalOption) =>
  timestamps.map(timestamp => intervalFormat(timestamp, interval))

const getTrendLineEChartOptions = ({
  timestamps,
  // timeSeries,
  interval,
  xAxis,
  series,
  grid,
  legend,
  enableDataZoom = true,
  dataZoomRange = [0, 100],
  disableSortSeries = false,
  color = chartColors,
  tooltipFormatter
}: TrendLineEChartOptionsParams): EChartOption => {
  // const sortedTimeSeries = disableSortSeries
  //   ? [...timeSeries]
  //   : [...timeSeries].sort((a, b) => a.name.localeCompare(b.name))

  const xAxisData = getXAxisData(timestamps, interval)

  return {
    color,
    legend: {
      show: true,
      icon: 'roundRect',
      itemWidth: 12,
      itemHeight: 12,
      textStyle: { fontFamily: 'Lexend' },
      width: 1000,
      height: 60,
      top: 0,
      padding: 0,
      ...legend
    },
    grid: {
      show: false,
      top: '16%',
      left: '5%',
      right: '5%',
      bottom: '1%',
      containLabel: true,
      ...grid
    },
    xAxis: {
      show: true,
      type: 'category',
      splitNumber: 4,
      offset: 0,
      boundaryGap: false,
      axisLabel: {
        color: colors.neutralLowMedium,
        fontFamily: 'Lexend',
        align: 'center',
        showMinLabel: true,
        showMaxLabel: true
      },
      data: xAxisData,
      ...xAxis
    },
    yAxis: {
      type: 'value'
    },
    dataZoom: [
      {
        show: enableDataZoom,
        type: 'slider',
        start: dataZoomRange[0],
        end: dataZoomRange[1],
        fillerColor: colors.brandPrimaryTransparentWeak,
        borderColor: colors.brandPrimaryTransparentWeak,
        backgroundColor: colors.brandPrimaryLight,
        textStyle: {
          fontFamily: 'Lexend'
        },
        dataBackground: {
          lineStyle: {
            color: colors.neutralLowLight
          }
        },
        selectedDataBackground: {
          lineStyle: {
            color: colors.neutralLowLight
          }
        },
        handleStyle: {
          borderColor: colors.brandPrimaryTransparentWeak
        },
        moveHandleStyle: {
          color: colors.brandPrimaryTransparentWeak,
          backgroundColor: colors.brandPrimaryLight,
          borderColor: colors.brandPrimaryTransparentWeak
        },
        emphasis: {
          handleStyle: {
            borderColor: colors.brandPrimaryTransparentModerate
          },
          moveHandleStyle: {
            color: colors.brandPrimaryTransparentModerate,
            backgroundColor: colors.brandPrimaryMedium,
            borderColor: colors.brandPrimaryTransparentModerate
          }
        },
        realtime: true
      } as EChartOption.DataZoom
    ],
    axisPointer: {
      z: -2
    },
    // TODO: Add custom tooltip
    tooltip: {
      show: true,
      trigger: 'axis',
      enterable: true,
      textStyle: {
        fontFamily: 'Lexend'
      },
      formatter: (tooltipFormatter as EChartOption.Tooltip.Formatter) ?? undefined
    },

    series: disableSortSeries
      ? [...series]
      : [...series].sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
  }
}

export default getTrendLineEChartOptions
