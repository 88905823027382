import { styled } from '@/theme'

export const Container = styled('div', {
  borderRadius: 8,
  display: 'flex',
  gap: 16,
  padding: 16,

  variants: {
    multiline: {
      false: {
        alignItems: 'center'
      }
    },
    status: {
      informative: {
        backgroundColor: '$feedbackInformativeLight'
      },
      negative: {
        backgroundColor: '$feedbackNegativeLight'
      },
      positive: {
        backgroundColor: '$feedbackPositiveLight'
      },
      warning: {
        backgroundColor: '$feedbackWarningLight'
      }
    }
  }
})

export const CloseButton = styled('button', {
  backgroundColor: 'transparent',
  border: 0,
  cursor: 'pointer',
  marginLeft: 'auto',
  size: 24
})
