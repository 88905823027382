import { create } from 'zustand'

interface SearchInputState {
  isFocused: boolean
  textInput: string

  setIsFocused: (isFocused: boolean) => void
  setTextInput: (textInput: string) => void
}

const useSearchInputStore = create<SearchInputState>(set => ({
  isFocused: false,
  textInput: '',

  setIsFocused: isFocused => set(state => ({ ...state, isFocused })),
  setTextInput: textInput => set(state => ({ ...state, textInput }))
}))

export default useSearchInputStore
