import { styled } from '@/theme'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'

export const StyledRadio = styled(RadioGroupPrimitive.Item, {
  all: 'unset',
  backgroundColor: '$white',
  absoluteSize: 12,
  borderRadius: '100%',
  bAll: '$neutralLowLight',
  cursor: 'pointer',

  label: {
    fontSize: 10
  },

  '&:hover': { backgroundColor: '$brandPrimaryLight' },

  '&[data-state="checked"]': {
    bAll: '$brandPrimaryPure'
  },

  '&[data-disabled]': {
    backgroundColor: '$neutralHighMedium',
    bAll: '$neutralLowLight',
    cursor: 'not-allowed'
  }
})

export const StyledIndicator = styled(RadioGroupPrimitive.Indicator, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'relative',
  '&::after': {
    content: '""',
    display: 'block',
    size: 8,
    borderRadius: '50%',
    backgroundColor: 'transparent',
    bAll: '$neutralLowLight'
  },

  '&[data-state="checked"]': {
    '&::after': {
      backgroundColor: '$brandPrimaryPure',
      border: 'none'
    }
  }
})
