import FlexContainer from '@/components/atoms/flex-container'
import { StyledLink } from '@/components/atoms/table-v2'
import Text from '@/components/atoms/text'
import { styled } from '@/theme'
import { AreaOfInterestData } from '@/types/area/AreaOfInterest'
import { useMemo } from 'react'
import shortUUID from 'short-uuid'

const NameContainer = styled(FlexContainer, {
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: '$xxs',
  pt: 'calc($xxs + 3px)',
  width: '100%',
  position: 'relative',
  color: '$neutralLowPure'
})

interface Props {
  data: AreaOfInterestData
  onAreaClick: (area: AreaOfInterestData) => void
  disabled?: boolean
}

const SimpleAreaNameCell = ({ data, onAreaClick, disabled = false }: Props) => {
  const handleClick = () => {
    if (disabled) return
    onAreaClick(data)
  }

  const translator = useMemo(() => shortUUID(), [])
  const shortAreaId = useMemo(() => translator.fromUUID(data.id), [data.id, translator])
  const to = useMemo(() => (disabled ? '' : `/area/${shortAreaId}`), [shortAreaId, disabled])

  return (
    <NameContainer>
      <StyledLink aria-disabled={disabled} onClick={handleClick} to={to}>
        <Text typeface="subtitleRegularMicro">{data.name}</Text>
      </StyledLink>
    </NameContainer>
  )
}

export default SimpleAreaNameCell
