import SlackLogo from '@/assets/logos/slack.png'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { styled } from '@/theme'
import { useTranslation } from 'react-i18next'

const Banner = styled('div', {
  background: '$feedbackWarningMedium',
  br: 8,
  display: 'flex',
  gap: '$xxs',
  padding: '$xxs'
})

const SlackBannerV2 = () => {
  const { t } = useTranslation()

  return (
    <Banner>
      <img alt="Slack logo" height={32} src={SlackLogo} width={32} />
      <FlexContainer direction="column" gap="micro" justifyContent="center">
        <Text as="h3" typeface="titleBoldXXS">
          {t('receiveNotificationsViaSlack')}
        </Text>
        <Text as="p" color="neutralLowMedium" typeface="paragraphRegularMicro">
          {t(
            'inTheSidebarGoToSettingsManageNotificationsAndConnectToSlackToReceiveNotificationsThroughSlackChannels'
          )}
        </Text>
      </FlexContainer>
    </Banner>
  )
}

export default SlackBannerV2
