import FlexContainer from '@/components/atoms/flex-container'

import { useEffect, useRef, useState } from 'react'
import { CustomerRecordsUserData } from '@/types/manage-customers'
import {
  HeaderCell,
  HeaderRow,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from './UserList.styles'
import NoResults from '../NoResults'
import { formatDateTime } from '@/utils/date'
import { CustomerRecordsScrollArea, Fade } from '../ManageCustomers.styles'
import useCustomerUsersStore from '@/store/useCustomerUsersStore'
import UserDetails from './UserDetails'
import OptionsMenu from '@/components/atoms/options-menu'
import { ListDashes, TrashSimple } from '@phosphor-icons/react'
import InfiniteScroll from 'react-infinite-scroll-component'
import ScrollLoader from '../../feed/ScrollLoader'
import { ScrollAreaScrollbar, ScrollAreaThumb, ScrollAreaViewport } from '../../scroll-area'
import Loader from '@/components/atoms/loader'

function checkHasScroll(element: HTMLElement | null) {
  if (!element) return false
  return element.scrollHeight > element.clientHeight
}

interface Props {
  hasMore?: boolean
  userList: CustomerRecordsUserData[]
  isFetchingPage?: boolean
  isLoadingSearch: boolean
  isSearching: boolean
  loadMore: () => void
  onClickDelete: (user: CustomerRecordsUserData) => void
}

const UserList = ({
  userList,
  hasMore,
  isSearching,
  isFetchingPage,
  isLoadingSearch,
  onClickDelete,
  loadMore
}: Props) => {
  const [isScrollAtBottom, setIsScrollAtBottom] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const detailedUser = useCustomerUsersStore(state => state.detailedUser)
  const setDetailedUser = useCustomerUsersStore(state => state.setDetailedUser)

  useEffect(() => {
    const onScroll = () => {
      if (containerRef.current && userList.length > 0) {
        if (!checkHasScroll(containerRef.current)) {
          setIsScrollAtBottom(true)
          return
        }

        const { scrollTop, scrollHeight, clientHeight } = containerRef.current
        const isNearBottom = scrollTop + clientHeight >= scrollHeight
        setIsScrollAtBottom(isNearBottom)
      }
    }

    if (containerRef.current && userList.length > 0) {
      containerRef.current.addEventListener('scroll', onScroll)

      return () => {
        containerRef.current && containerRef.current.removeEventListener('scroll', onScroll)
      }
    }
  }, [userList.length])

  useEffect(() => {
    if (userList.length && !checkHasScroll(containerRef.current)) {
      setIsScrollAtBottom(true)
    } else {
      setIsScrollAtBottom(isFetchingPage || false)
    }
  }, [userList, isFetchingPage])

  if (isLoadingSearch)
    return (
      <FlexContainer alignItems="center" justifyContent="center">
        <Loader />
      </FlexContainer>
    )

  return (
    <>
      {!userList.length && isSearching ? (
        <NoResults />
      ) : (
        <TableContainer>
          <HeaderRow>
            <HeaderCell>User ID</HeaderCell>
            <HeaderCell>User full name</HeaderCell>
            <HeaderCell>e-mail</HeaderCell>
            <HeaderCell>Import date</HeaderCell>
            <HeaderCell />
          </HeaderRow>

          <CustomerRecordsScrollArea>
            <ScrollAreaViewport id="users-viewport" ref={containerRef}>
              <TableBody>
                <InfiniteScroll
                  dataLength={userList.length}
                  endMessage={<div />}
                  hasMore={hasMore || false}
                  loader={isFetchingPage ? <ScrollLoader /> : ''}
                  next={loadMore}
                  scrollThreshold="250px"
                  scrollableTarget="users-viewport"
                >
                  {userList.map(user => {
                    const formatedDate = formatDateTime(user.importedAt.replace('Z', ''))
                    return (
                      <TableRow key={user.id}>
                        <TableCell title={user.ingestedId}>{user.ingestedId}</TableCell>
                        <TableCell title={user.name}>{user.name}</TableCell>
                        <TableCell title={user.email}>{user.email}</TableCell>
                        <TableCell title={formatedDate}>{formatedDate}</TableCell>
                        <TableCell className="options">
                          <OptionsMenu
                            buttonStyle={{ color: '$neutralLowPure' }}
                            options={[
                              {
                                text: 'Details',
                                icon: <ListDashes size={16} />,
                                onClick: () => setDetailedUser(user)
                              },
                              {
                                text: 'Delete',
                                icon: <TrashSimple size={16} />,
                                onClick: () => onClickDelete(user)
                              }
                            ]}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </InfiniteScroll>
              </TableBody>
              <Fade hide={isScrollAtBottom} />
            </ScrollAreaViewport>
            <ScrollAreaScrollbar orientation="vertical">
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
          </CustomerRecordsScrollArea>
        </TableContainer>
      )}

      {detailedUser && <UserDetails />}
    </>
  )
}

export default UserList
