import BirdieRequest from './RequestHandlers/BirdieRequest'
import { BaseRequestReturnType } from './RequestHandlers/BaseRequestHandler'
import {
  AlertModel,
  AllAlertsResponse,
  NotificationsMethodsPayload,
  NotificationsMethodsResponse,
  RegisterAlertPayload,
  RegisterAlertResponse,
  UpdateAlertPayload,
  UpdateAlertResponse
} from '@/types/notifications/NotificationsRequests'
import { NotificationData, SlackChannel } from '@/types/notifications/Notifications'
import DefaultErrorHandler from './DefaultError'
import { CONDITION_QUANTITY, NOTIFICATION_TYPE } from '@/types/alerts/Alerts'

const NOTIFICATIONS_PAGE_SIZE = 100

export default class NotificationsService {
  private static PREFIX = '/alerts'
  private static NOTIFICATIONS_METHODS_PREFIX = 'notification-methods'

  static parseModel = (model: AlertModel): NotificationData => ({
    id: model.id,
    description: model.description,
    filter: model.filter,
    notificationConfig: model.notification_config,
    notificationSchedule: model.notification_schedule,
    enabled: model.enabled,
    toggleDisabled: false,
    version: model.version,
    createdBy: model.created_by
  })

  static async getNotifications(
    signal?: AbortSignal
  ): Promise<BaseRequestReturnType<NotificationData[]>> {
    const [error, data] = await BirdieRequest.get<AllAlertsResponse>(
      this.PREFIX,
      { per_page: NOTIFICATIONS_PAGE_SIZE },
      { signal }
    )

    if (error) return [error, undefined]
    return [undefined, data.alerts.map(this.parseModel)]
  }

  static async getNotificationsV2(
    signal?: AbortSignal
  ): Promise<BaseRequestReturnType<NotificationData[]>> {
    const [error, data] = await BirdieRequest.get<AllAlertsResponse>(
      this.PREFIX,
      { per_page: NOTIFICATIONS_PAGE_SIZE + 100, version: 'v2' },
      { signal }
    )

    if (error) return [error, undefined]
    return [undefined, data.alerts.map(this.parseModel)]
  }

  static async getNotificationById(
    id: string,
    signal?: AbortSignal
  ): Promise<BaseRequestReturnType<NotificationData>> {
    const [error, data] = await BirdieRequest.get<AlertModel>(`${this.PREFIX}/${id}`, { signal })

    if (error) return [error, undefined]
    return [undefined, this.parseModel(data)]
  }

  static async registerNotification(
    notification: NotificationData,
    signal?: AbortSignal
  ): Promise<BaseRequestReturnType<NotificationData>> {
    const payload: RegisterAlertPayload = {
      description: notification.description,
      filter: notification.filter,
      condition: { quantity: CONDITION_QUANTITY },
      notification_schedule: notification.notificationSchedule,
      notification_config: notification.notificationConfig,
      notification_type: NOTIFICATION_TYPE,
      version: notification.version
    }
    const [error, data] = await BirdieRequest.post<RegisterAlertResponse>(this.PREFIX, payload, {
      signal
    })

    if (error) return [error, undefined]
    return [undefined, this.parseModel(data)]
  }

  static async updateNotification(
    notification: NotificationData,
    signal?: AbortSignal
  ): Promise<BaseRequestReturnType<NotificationData>> {
    if (!notification.id) {
      return [new DefaultErrorHandler({ message: 'No id provided' }), undefined]
    }

    const id = notification.id
    const payload: UpdateAlertPayload = {
      description: notification.description,
      filter: notification.filter,
      condition: { quantity: CONDITION_QUANTITY },
      notification_schedule: notification.notificationSchedule,
      notification_config: notification.notificationConfig,
      notification_type: NOTIFICATION_TYPE,
      enabled: notification.enabled,
      version: notification.version
    }

    const [error, data] = await BirdieRequest.put<UpdateAlertResponse>(
      `${this.PREFIX}/${id}`,
      payload,
      { signal }
    )

    if (error) return [error, undefined]
    return [undefined, this.parseModel(data)]
  }

  static async deleteNotification(
    notificationId: string,
    signal?: AbortSignal
  ): Promise<BaseRequestReturnType<void>> {
    const [error] = await BirdieRequest.del(`${this.PREFIX}/${notificationId}`, null, null, {
      signal
    })
    if (error) return [error, undefined]
    return [undefined, undefined]
  }

  static async getSlackChannels(
    perPage = NOTIFICATIONS_PAGE_SIZE
  ): Promise<BaseRequestReturnType<SlackChannel[]>> {
    const params: NotificationsMethodsPayload = {
      per_page: perPage,
      type: 'slack'
    }
    const [error, data] = await BirdieRequest.get<NotificationsMethodsResponse>(
      this.NOTIFICATIONS_METHODS_PREFIX,
      params
    )

    if (error) return [error, undefined]

    const slackChannels: SlackChannel[] = data.notifications.map(n => ({
      id: n.id,
      name: n.slack.channel
    }))
    return [undefined, slackChannels]
  }

  static async removeChannel(id: string): Promise<BaseRequestReturnType<true>> {
    const [error] = await BirdieRequest.del(`${this.NOTIFICATIONS_METHODS_PREFIX}/${id}`)

    if (error) return [error, undefined]
    return [undefined, true]
  }

  static async getSlackConnectUrl(): Promise<BaseRequestReturnType<string>> {
    const [error, data] = await BirdieRequest.get<string>(
      `${this.NOTIFICATIONS_METHODS_PREFIX}/slack`
    )

    if (error) return [error, undefined]
    return [undefined, data]
  }
}
