import { styled } from '@/theme'
import { slideAndFadeOpen } from '@/theme/animations'
import * as PopoverPrimitive from '@radix-ui/react-popover'

const transition = 'cubic-bezier(.34,.19,.44,.85) .2s'

export const AssistantImageCircle = styled('img', {
  position: 'absolute',
  background: '$neutralHighLight',
  borderRadius: '100%',
  size: 87,
  border: '4px solid $brandPrimaryPure',
  left: 32,
  bottom: '$sm',
  zIndex: 3,
  transition,

  variants: {
    noHover: {
      true: {},
      false: {
        '&:hover': {
          cursor: 'pointer',
          transform: 'translateY(-4px)',
          boxShadow: '$medium'
        }
      }
    },

    opportunityPlan: {
      true: {
        position: 'static',
        bc: '$brandPrimaryPure',
        br: '50px',
        border: 'none',

        boxSizing: 'border-box',
        height: 40,
        width: 40
      }
    }
  },

  defaultVariants: {
    noHover: false
  }
})

export const AssistantBallonImage = styled('img', {
  position: 'absolute',
  left: 64,
  bottom: 104,
  zIndex: 4,
  pointerEvents: 'none',
  transition,

  variants: {
    visible: {
      true: {
        opacity: 1
      },
      false: {
        opacity: 0
      }
    }
  }
})

export const AssistantOptionsContainer = styled(PopoverPrimitive.Content, {
  position: 'relative',
  left: -12,
  padding: '$micro $xxs',
  pl: '$xs',
  zIndex: 2,
  // transition,
  background: '$brandPrimaryPure',
  borderRadius: 8,
  boxShadow: '$medium',
  width: 180,

  display: 'flex',
  flexDirection: 'column',
  gap: '$xxs',
  ...slideAndFadeOpen,
  variants: {
    opportunityPlan: {
      true: {
        zIndex: 4,
        left: 20
      }
    }
  }
})

export const AssistantOptionButton = styled('button', {
  all: 'unset',
  fontSize: '$micro',
  color: '$neutralHighLight',
  cursor: 'pointer',
  transition: '.2s',

  '&:hover': {
    color: '$brandPrimaryLight'
  }
})
