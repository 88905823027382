import FlexContainer from '@/components/atoms/flex-container'
import PlantEmptyImage from '@/assets/drawings/plant_empty.png'
import { styled, typefaceVariants } from '@/theme'
import { useTranslation } from 'react-i18next'

const EmptyMessage = styled('p', {
  ...typefaceVariants.subtitleRegularXXXS,
  maxWidth: 244,
  color: '$neutralLowMedium',
  textAlign: 'center',

  '& > .icon': {
    position: 'relative',
    top: 2,
    mx: '$nano'
  }
})

const EmptyFilters = () => {
  const { t } = useTranslation()
  return (
    <FlexContainer
      alignItems="center"
      css={{ width: '100%', mt: '$xs' }}
      direction="column"
      gap="xs"
    >
      <img
        alt="A drawing of small plant in black and white"
        height={132}
        src={PlantEmptyImage}
        width={128}
      />
      <EmptyMessage>{t('oopsNoFiltersHereYet')}</EmptyMessage>
    </FlexContainer>
  )
}

export default EmptyFilters
