import FlexContainer from '@/components/atoms/flex-container'
import Input from '@/components/atoms/input'
import useSegment from '@/hooks/useSegment'
import useToastMessageStore, { ToastMessage } from '@/store/useToastMessageStore'
import { EnvelopeSimple } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { InputIconContainer, InviteButton, InviteByEmailContainer } from './InviteOptions.styles'
import { useTranslation } from 'react-i18next'
import i18n from '../../../plugins/i18n/i18n'

const mapMessages: { [x: string]: ToastMessage } = {
  'invalid-email': {
    open: true,
    text: i18n.t('invalidEmailCheckYourEmailList'),
    id: 'invalid-email',
    status: 'error'
  },
  'send-email-failed': {
    open: true,
    text: i18n.t('failedToSendInvites'),
    id: 'send-email-failed',
    status: 'error'
  }
}

interface Props {
  isSending: boolean
  onCancel: () => void
  onFinish: (emails: string[]) => Promise<void>
}

const InviteByEmail = ({ onFinish, onCancel, isSending }: Props) => {
  const { track } = useSegment()

  const [hasInvalidEmail, setHasInvalidEmail] = useState(false)
  const [emails, setEmails] = useState('')
  const [message, setMessage] = useState<'invalid-email' | 'send-email-failed' | undefined>()

  const { t } = useTranslation()
  const addToast = useToastMessageStore(state => state.addToast)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (message) {
      addToast(mapMessages[message])
      setMessage(undefined)
    }
  }, [message])

  const handleEmailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmails(e.target.value)

    if (hasInvalidEmail) setHasInvalidEmail(false)
  }

  const onClickSend = async () => {
    const splitedEmails = emails.trim().split(',')
    if (
      splitedEmails.some(email => !email.trim().match(/^[A-Za-z0-9_.\-\\+]+@[A-Za-z0-9._-]+$/gm))
    ) {
      setHasInvalidEmail(true)
      setMessage('invalid-email')
      return
    }

    try {
      await onFinish(splitedEmails)
      setEmails('')
      track('explore_user_send_invitation')
    } catch (error) {
      console.error(error)
      setMessage('send-email-failed')
    }
  }

  const onClickCancel = () => {
    setEmails('')
    onCancel()
  }

  return (
    <InviteByEmailContainer direction="column">
      <InputIconContainer>
        <EnvelopeSimple size={20} weight="bold" />
        <Input
          autoFocus
          borderBottom
          css={{ borderBottom: hasInvalidEmail ? '2px solid $red !important' : undefined }}
          onChange={handleEmailsChange}
          placeholder={t('enterEmailsToInvite')}
          value={emails}
        />
      </InputIconContainer>

      <FlexContainer css={{ gap: 10 }}>
        <InviteButton
          disabled={!emails.length || isSending || hasInvalidEmail}
          onClick={onClickSend}
          size="small"
        >
          {isSending ? t('sending') : t('sendInvitations')}
        </InviteButton>
        <InviteButton disabled={isSending} onClick={onClickCancel} size="small" variant="flat">
          {t('cancel')}
        </InviteButton>
      </FlexContainer>
    </InviteByEmailContainer>
  )
}

export default InviteByEmail
