import DefaultErrorHandler from '@/services/DefaultError'
import { RequestReturnType } from '@/services/RequestHandlers/NewRequest'
import { RawAreaError } from '@/types/area/AreaRequests'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { MetricsRequests, RawMetric } from '@/types/metrics'
import {
  OpportunityItemWithMergedContext,
  OpportunityItemWithMetrics
} from '@/types/opportunity/Opportunity'
import {
  buildFilterListWithMergedContextOpp,
  getOppsMetrics,
  getOptimizedOppsMetrics,
  getRawMetricListWithAreaError
} from '@/utils/opportunityQueryUtils'
import { OPPORTUNITY_METRICS_SUPPORTED_NAMES } from '@/utils/opportunityUtils'

interface Params {
  opps: OpportunityItemWithMetrics[]
  metricList: MetricsRequests.MetricListPayloadItem[]
  currentFilterContext?: string
  errorMap: Record<string, RawAreaError>
  startDate?: string
  endDate?: string
  onProgress?: (chunkLength: number, completed: number) => void
  onError: (error: DefaultErrorHandler, customMessage?: string) => void
}

const getAllMetricsForOpportunitiesFn = async ({
  opps,
  metricList,
  currentFilterContext,
  errorMap,
  startDate,
  endDate,
  onProgress
}: Params): Promise<RequestReturnType<OpportunityItemWithMergedContext[]>> => {
  let oppsMetrics: RawMetric[][]

  const filterList: FeedbackListQueryParams[] = buildFilterListWithMergedContextOpp(
    opps,
    {
      currentFilterContext
    },
    errorMap
  )

  const isOpportunityMetricsEndpointEnabled = metricList.every(metric =>
    OPPORTUNITY_METRICS_SUPPORTED_NAMES.includes(metric.name)
  )

  if (isOpportunityMetricsEndpointEnabled) {
    const responses = await getOptimizedOppsMetrics({
      filterList,
      metricList,
      startDate,
      endDate,
      onProgress
    })
    const someResultWithError = responses.find(response => response[0])

    if (someResultWithError && someResultWithError[0]) {
      return [someResultWithError[0], undefined]
    }

    const data = responses.flatMap(response => response[1]) as RawMetric[][]
    oppsMetrics = data
  } else {
    const responses = await getOppsMetrics({
      filterList,
      metricList,
      startDate,
      endDate,
      onProgress
    })

    const someResultWithError = responses.find(response => response[0])
    if (someResultWithError && someResultWithError[0]) {
      return [someResultWithError[0], undefined]
    }
    const data = responses.flatMap(response => response[1]) as RawMetric[][]
    oppsMetrics = data
  }

  const oppsMetricsWithHandledErrors = opps.map((item, index) => {
    return {
      ...item,
      metrics: getRawMetricListWithAreaError(item.id, oppsMetrics[index], errorMap)
    }
  }) as OpportunityItemWithMetrics[]

  return [undefined, oppsMetricsWithHandledErrors]
}

export default getAllMetricsForOpportunitiesFn
