import { useEffect, useState } from 'react'
import SavedFilterPopover from './SavedFiltersPopover'
import useSavedFilters, { FILTER_HASH_KEY } from '@/hooks/useSavedFilters'
import { useSearchParams } from 'react-router-dom'
import { useFiltersStore, useUIStore } from '@/store'
import useToastMessageStore from '@/store/useToastMessageStore'
import useSavedFiltersStore from '@/store/useSavedFiltersStore'
import { shallow } from 'zustand/shallow'
import useUser from '@/hooks/useUser'
import { SavedFilter } from '@/types/filters/Filters'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import useLogging from '@/hooks/useLogging'

interface Props {
  newFeed?: boolean
}

function SavedFiltersArea({ newFeed = false }: Props) {
  const { applySavedFilter, removeHashFromUrl, fetchAll, fetchFilterByHash, hasChanges } =
    useSavedFilters({ newFeed })

  const currentSavedFilter = useSavedFiltersStore(state => state.currentSavedFilter, shallow)
  const setCurrentSavedFilter = useSavedFiltersStore(state => state.setCurrentSavedFilter)
  const setFilterName = useSavedFiltersStore(state => state.setFilterName)

  const { userPermissions } = useUser()
  const isManager = userPermissions.filters.includes('manager')

  const setShowFullScreenLoader = useUIStore(state => state.setShowFullScreenLoader)

  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { logException } = useLogging({ context: 'saved-filters-area' })

  const useStore = newFeed ? useFeedFiltersStore : useFiltersStore
  const filtersLoaded = useStore(state => state.filtersLoaded, shallow)

  const [searchParams] = useSearchParams()

  const [viewToApply, setViewToApply] = useState<SavedFilter | undefined>()

  useEffect(() => {
    if (hasChanges && !isManager) {
      removeHashFromUrl()
      setCurrentSavedFilter(undefined)
      setFilterName('')
    }
  }, [hasChanges, isManager, removeHashFromUrl, setCurrentSavedFilter, setFilterName])

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen once
  useEffect(() => {
    fetchAll()

    const filterHash = searchParams.get(FILTER_HASH_KEY)
    if (filterHash) {
      fetchFilterByHash(filterHash)
      if (currentSavedFilter?.hash === filterHash) return

      setShowFullScreenLoader(true, 'Loading your saved view...')
    }
  }, [])

  const onApplySavedFilter = async (filter: SavedFilter) => {
    try {
      setFilterName(filter.name)
      applySavedFilter(filter)
      setCurrentSavedFilter(filter)
      addSuccessToast({ text: 'View applied' })
    } catch (error) {
      console.error(error, 'Error parsing filter content')
      const message = 'Failed to apply this saved filter.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: only react to viewToApply
  useEffect(() => {
    const onViewApplied = async () => {
      if (viewToApply) {
        onApplySavedFilter(viewToApply)
        setViewToApply(undefined)
      }
    }

    onViewApplied()
  }, [viewToApply])

  const onApplyView = () => async (filter: SavedFilter) => {
    if (!filtersLoaded) {
      const removeToast = addLoadingToast({ text: 'Applying saved view...' })
      removeToast()
    }

    setViewToApply(filter)
  }

  return <SavedFilterPopover onApplySavedFilter={onApplyView()} />
}

export default SavedFiltersArea
