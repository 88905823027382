import Button from '@/components/atoms/button'
import Checkbox, { CheckboxState } from '@/components/atoms/checkbox'
import FlexContainer from '@/components/atoms/flex-container'
import { ThemeItemData, TopicItemData } from '@/types/classification/Theme'
import { useState } from 'react'

export interface TopicsSearchResultItemProps {
  result: ThemeItemData & { filteredTopics: TopicItemData[]; remainingTopicsCount: number }
  isThemeChecked: (theme: ThemeItemData) => CheckboxState
  isTopicChecked: (topicId: string) => CheckboxState
  onThemeCheck: (theme: ThemeItemData) => (checked: CheckboxState) => void
  onTopicCheck: (topicId: string) => (checked: CheckboxState) => void
  isDisabled: boolean
}

const TopicsSearchResultItem = ({
  result,
  isThemeChecked,
  isTopicChecked,
  onThemeCheck,
  onTopicCheck,
  isDisabled
}: TopicsSearchResultItemProps) => {
  const [isShowAll, setIsShowAll] = useState(false)

  const buttonMessage = isShowAll ? 'Show less' : `Show ${result.remainingTopicsCount} more`
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    setIsShowAll(value => !value)
  }

  const topicList = isShowAll ? result.topics : result.filteredTopics
  const showButton = result.remainingTopicsCount > 0

  return (
    <>
      <Checkbox
        checked={isThemeChecked(result)}
        disabled={isDisabled}
        onCheckedChange={onThemeCheck(result)}
        onClick={e => {
          e.stopPropagation()
        }}
        text={result.themeName}
        value={'filter-' + result.themeId}
      />
      <FlexContainer css={{ ml: '$xxxs' }} direction="column">
        {topicList.map(topic => (
          <Checkbox
            checked={isTopicChecked(topic.topicId)}
            disabled={isDisabled}
            key={topic.topicId}
            onCheckedChange={onTopicCheck(topic.topicId)}
            text={topic.topicName}
            value={'filter-' + topic.topicId}
          />
        ))}
        {showButton && (
          <Button css={{ px: 0 }} onClick={handleClick} size="small" variant="link">
            {buttonMessage}
          </Button>
        )}
      </FlexContainer>
    </>
  )
}

export default TopicsSearchResultItem
