import { AreaOfInterestTabs } from '@/types/area/AreaOfInterest'
import { ExpandedState } from '@tanstack/react-table'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

export type LoadStep = 'loading' | 'ordering' | 'calculating' | 'done'

interface AnalysisHubState {
  currentTab: AreaOfInterestTabs
  searchText: string
  expandedAreas: ExpandedState

  setCurrentTab: (currentTab: AreaOfInterestTabs) => void
  setSearchText: (searchText: string) => void
  setExpandedAreas: (expandedAreas: ExpandedState) => void

  isAreaOpen: (areaId: string) => boolean

  opportunitiesProgress: number
  setOpportunitiesProgress: (progress: number) => void
  opportunitiesLoadStep: LoadStep
  setOpportunitiesLoadStep: (loadStep: LoadStep) => void

  areasProgress: number
  setAreasProgress: (progress: number) => void
  areasLoadStep: LoadStep
  setAreasLoadStep: (loadStep: LoadStep) => void

  segmentationsProgress: number
  setSegmentationsProgress: (progress: number) => void
  segmentationsLoadStep: LoadStep
  setSegmentationsLoadStep: (loadStep: LoadStep) => void
}

const useAnalysisHubState = createWithEqualityFn<AnalysisHubState>()(
  (set, get) => ({
    currentTab: 'areas',
    searchText: '',
    expandedAreas: {},

    setCurrentTab: currentTab => set({ currentTab }),
    setSearchText: searchText => set({ searchText }),
    setExpandedAreas: expandedAreas => set({ expandedAreas }),

    isAreaOpen: areaId => {
      const { expandedAreas } = get()
      return !!Object.entries(expandedAreas).find(([key, value]) => key === areaId && value)
    },

    opportunitiesProgress: 2,
    setOpportunitiesProgress: progress => set({ opportunitiesProgress: progress }),
    opportunitiesLoadStep: 'loading',
    setOpportunitiesLoadStep: loadStep => set({ opportunitiesLoadStep: loadStep }),

    areasProgress: 2,
    setAreasProgress: progress => set({ areasProgress: progress }),
    areasLoadStep: 'loading',
    setAreasLoadStep: loadStep => set({ areasLoadStep: loadStep }),

    segmentationsProgress: 2,
    setSegmentationsProgress: progress => set({ segmentationsProgress: progress }),
    segmentationsLoadStep: 'loading',
    setSegmentationsLoadStep: loadStep => set({ segmentationsLoadStep: loadStep })
  }),

  shallow
)

export default useAnalysisHubState
