import * as SelectPrimitive from '@radix-ui/react-select'
import { CaretDown, IconContext, Layout, Tag } from '@phosphor-icons/react'
import { SelectOption } from '@/components/atoms/select'
import useClassification from '@/hooks/useClassification'
import { TopicCategory } from '@/types/classification'
import {
  CategorySelectItem,
  CategorySelectContent,
  CategorySelectTrigger,
  CategorySelectViewport,
  CategorySelectIcon
} from './CategorySelect.styles'
import { colors } from '@/theme'
import Text from '@/components/atoms/text'
import { useTranslation } from 'react-i18next'

const CategorySelect = () => {
  const { selectCategory, selectedCategory } = useClassification()
  const { t } = useTranslation()

  const items: SelectOption<TopicCategory>[] = [
    {
      value: 'PRODUCT_AREA',
      text: t('productAreas'),
      icon: <Layout />
    },
    {
      value: 'OTHER',
      text: t('otherTopics'),
      icon: <Tag />
    }
  ]

  const icon = items.find(item => item.value === selectedCategory)?.icon ?? <></>

  return (
    <IconContext.Provider value={{ size: 20, weight: 'duotone', color: colors.neutralLowPure }}>
      <SelectPrimitive.Root
        onValueChange={value => selectCategory(value as TopicCategory)}
        value={selectedCategory}
      >
        <CategorySelectTrigger>
          {icon}
          <Text typeface="titleBoldXXS">
            <SelectPrimitive.Value />
          </Text>
          <CategorySelectIcon>
            <CaretDown color={colors.neutralLowLight} size={12} weight="bold" />
          </CategorySelectIcon>
        </CategorySelectTrigger>

        <SelectPrimitive.Portal>
          <CategorySelectContent align="center" sideOffset={4}>
            <CategorySelectViewport>
              {items.map(item => (
                <CategorySelectItem key={item.value} value={item.value}>
                  {item.icon}
                  <SelectPrimitive.ItemText>
                    <Text typeface="titleBoldXXS">{item.text}</Text>
                  </SelectPrimitive.ItemText>
                </CategorySelectItem>
              ))}
            </CategorySelectViewport>
          </CategorySelectContent>
        </SelectPrimitive.Portal>
      </SelectPrimitive.Root>
    </IconContext.Provider>
  )
}

export default CategorySelect
