import FlexContainer from '@/components/atoms/flex-container'
import { Fragment, ReactNode } from 'react'
import useLetterCase from '@/hooks/useLetterCase'
import CollapsibleDetail from './CollapsibleDetail'
import { formatDateTime } from '@/utils/date'
import {
  FeedbackDetailItem,
  FeedbackDetailTitle,
  FeedbackDetailInnerTitle
} from './FeedbackDetails.styles'
import { AccountData } from '@/types/manage-customers'

interface Props {
  account?: AccountData
  isOpen: (option: string) => boolean
  onCollapseChange: (option: string) => (open: boolean) => void
}

function FeedbackAccountDetails({ account, isOpen, onCollapseChange }: Props) {
  const { capitalizeFirst, snakeToTitle } = useLetterCase()

  const renderInnerFields = (field: Record<string, unknown>): ReactNode[] => {
    const innerNodes = Object.entries(field).map(([key, value]) => {
      const name = capitalizeFirst(snakeToTitle(key))
      if (['string', 'number'].includes(typeof value)) {
        return (
          <Fragment key={key}>
            <FeedbackDetailInnerTitle>
              {name}: {value as string | number}
            </FeedbackDetailInnerTitle>
          </Fragment>
        )
      }

      if (typeof value === 'object' && !Array.isArray(value)) {
        return renderInnerFields(value as Record<string, unknown>)
      }

      return (
        <Fragment key={key}>
          <FeedbackDetailInnerTitle>{name}</FeedbackDetailInnerTitle>
          <p>Unkwown value</p>
        </Fragment>
      )
    })

    return innerNodes
  }

  const renderOtherFields = () => {
    if (!account?.otherFields) return undefined

    const { otherFields } = account

    const nodes = Object.entries(otherFields).map(([key, value]) => {
      const name = capitalizeFirst(snakeToTitle(key))

      if (!value) return <></>

      if (['string', 'number'].includes(typeof value)) {
        return (
          <FeedbackDetailItem key={key}>
            <FeedbackDetailTitle>{name}</FeedbackDetailTitle>
            <p>{value as string | number}</p>
          </FeedbackDetailItem>
        )
      }

      if (typeof value === 'object') {
        return (
          <FeedbackDetailItem key={key}>
            <FeedbackDetailTitle>{name}</FeedbackDetailTitle>
            <p>{renderInnerFields(value as Record<string, unknown>)}</p>
          </FeedbackDetailItem>
        )
      }

      return (
        <FeedbackDetailItem key={key}>
          <FeedbackDetailTitle>{name}</FeedbackDetailTitle>
          <p>Unkwown value</p>
        </FeedbackDetailItem>
      )
    })

    return nodes
  }

  const renderCustomFields = (() => {
    if (!account?.customFields) return undefined

    const { customFields } = account

    return Object.entries(customFields).map(([key, data]) => {
      const name = capitalizeFirst(snakeToTitle(key))
      let formattedValue = data.value
      if (typeof data.value === 'boolean') {
        formattedValue = data.value ? 'Yes' : 'No'
      }
      return (
        <FeedbackDetailItem key={key}>
          <FeedbackDetailTitle>{name}</FeedbackDetailTitle>
          <p>{formattedValue}</p>
        </FeedbackDetailItem>
      )
    })
  })()

  if (!account) return <></>

  return (
    <CollapsibleDetail isOpen={isOpen} onOpenChange={onCollapseChange} option="account">
      <FlexContainer direction="column" gap="micro">
        <FeedbackDetailItem>
          <FeedbackDetailTitle>Company Name</FeedbackDetailTitle>
          <p>{account.name}</p>
        </FeedbackDetailItem>

        <FeedbackDetailItem>
          <FeedbackDetailTitle>ID</FeedbackDetailTitle>
          <p>{account.id}</p>
        </FeedbackDetailItem>

        <FeedbackDetailItem>
          <FeedbackDetailTitle>Ingested ID</FeedbackDetailTitle>
          <p>{account.ingestedId}</p>
        </FeedbackDetailItem>

        <FeedbackDetailItem>
          <FeedbackDetailTitle>Imported date</FeedbackDetailTitle>
          <p>{formatDateTime(account.importedAt.replace('Z', ''))}</p>
        </FeedbackDetailItem>

        <FeedbackDetailItem>
          <FeedbackDetailTitle>Customer type</FeedbackDetailTitle>
          <p>{account.customerType}</p>
        </FeedbackDetailItem>

        {renderOtherFields()}

        {renderCustomFields && renderCustomFields.length > 0 && (
          <FlexContainer direction="column" gap="micro">
            {renderCustomFields}
          </FlexContainer>
        )}
      </FlexContainer>
    </CollapsibleDetail>
  )
}

export default FeedbackAccountDetails
