import { useState } from 'react'
import useAdvancedFilters from './useAdvancedFilters'
import { RadioBooleanOption } from '@/types/filters/Filters'
import { BooleanFilterType } from '@/types/filters/AdvancedFilters'
import { prefixFilterName } from '@/utils/filters'

interface Params {
  name: string
  path: string
  initialValue?: RadioBooleanOption
  index?: number
  type: BooleanFilterType
}

const useBooleanAdvancedFilter = ({
  name,
  path,
  initialValue = 'all',
  index,
  type = 'boolean'
}: Params) => {
  const { addFilter, updateFilter, removeFilter } = useAdvancedFilters()
  const [selectedOption, setSelectedOption] = useState<RadioBooleanOption>(initialValue)

  const onOptionChange = (value: string) => {
    setSelectedOption(value as RadioBooleanOption)
  }

  let prefix = ''
  if (type === 'accounts-boolean') {
    prefix = 'account.'
  } else if (type === 'users-boolean') {
    prefix = 'user.'
  }

  const apply = () => {
    if (selectedOption === 'all') return
    addFilter({
      type,
      name: prefixFilterName(prefix, name),
      value: selectedOption === 'true',
      path,
      status: 'valid'
    })
  }

  const update = () => {
    if (selectedOption === 'all') {
      removeFilter({ index })
      return
    }

    if (index === undefined) return

    updateFilter({
      index,
      filter: {
        type,
        name: prefixFilterName(prefix, name),
        path,
        value: selectedOption === 'true',
        status: 'valid'
      }
    })
  }

  return {
    selectedOption,
    onOptionChange,
    apply,
    update
  }
}

export default useBooleanAdvancedFilter
