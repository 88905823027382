import useBooleanAdvancedFilter from '@/hooks/advancedFilters/useBooleanAdvancedFilter'
import FilterSubMenu from '../../FiltersSidebar/AddFilterMenu/FilterSubMenu'
import { BooleanFilterContent } from '../../FiltersSidebar/BooleanGenericFilter'
import { BooleanFilterType } from '@/types/filters/AdvancedFilters'

interface Props {
  title: string
  previousTitle?: string
  name: string
  path: string
  type?: BooleanFilterType
}

const BooleanFilterSubMenu = ({ title, previousTitle, name, path, type = 'boolean' }: Props) => {
  const { apply, selectedOption, onOptionChange } = useBooleanAdvancedFilter({
    name,
    path,
    type
  })

  return (
    <FilterSubMenu onApply={apply} previousTitle={previousTitle} title={title}>
      <BooleanFilterContent onOptionChange={onOptionChange} selectedOption={selectedOption} />
    </FilterSubMenu>
  )
}

export default BooleanFilterSubMenu
