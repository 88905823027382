import { AxiosRequestConfig } from 'axios'
import BaseRequestHandler, { BaseRequestReturnType } from './BaseRequestHandler'
import { dashboardStateClient } from '../clients'

export default class DashboardStateRequest {
  static requestHandler: BaseRequestHandler = new BaseRequestHandler(dashboardStateClient)

  static async get<T = unknown>(
    path: string,
    params?: unknown,
    config?: AxiosRequestConfig<unknown>
  ): Promise<BaseRequestReturnType<T>> {
    return this.requestHandler.get<T>(path, params, config)
  }

  static async post<T = unknown>(
    path: string,
    payload?: unknown,
    config?: AxiosRequestConfig<unknown>
  ): Promise<BaseRequestReturnType<T>> {
    return this.requestHandler.post<T>(path, payload, config)
  }
}
