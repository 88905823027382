import { CSS } from '@/theme'
import { DistributionBarContainer, SentimentBar } from './DistributionBar.styles'

export interface DistributionBarProps {
  negativePercentage: string
  positivePercentage: string
  neutralPercentage: string
  outlined?: boolean
  negativeColor?: string
  positiveColor?: string
  neutralColor?: string
  height?: 'small' | 'normal'
  css?: CSS
}

const DistributionBar = ({
  outlined = true,
  negativePercentage,
  neutralPercentage,
  positivePercentage,
  height = 'normal',
  neutralColor,
  negativeColor,
  positiveColor,
  css
}: DistributionBarProps) => {
  return (
    <DistributionBarContainer css={css} outlined={outlined}>
      {positivePercentage !== '0%' && (
        <SentimentBar
          css={{ width: positivePercentage, background: positiveColor }}
          heightSize={height}
          sentiment="positive"
        />
      )}
      {negativePercentage !== '0%' && (
        <SentimentBar
          css={{ width: negativePercentage, background: negativeColor }}
          heightSize={height}
          sentiment="negative"
        />
      )}
      {neutralPercentage !== '0%' && (
        <SentimentBar
          css={{ width: neutralPercentage, background: neutralColor }}
          heightSize={height}
          sentiment="neutral"
        />
      )}
    </DistributionBarContainer>
  )
}

export default DistributionBar
