import TrendingTopicsCard from './TrendingTopicsCard'
import TrendingTopicItem from './TrendingTopicItem'
import Button from '@/components/atoms/button'
import TrendingTopicItemEmpty from './TrendingTopicItemEmpty'
import TrendingTopicsEmptyList from './TrendingTopicsEmptyList'
import { getEmptyCardsArray, scrollOnList } from './trendingTopicsUtils'
import useTrendingTopics from '@/hooks/analytics/useTrendingTopics'
import { TopRequestsSkeleton } from './TrendingTopicsSkeletons'
import TrendingTopicsHeader from './TrendingTopicsHeader'

export const TopRequestsHeader = () => <TrendingTopicsHeader type="topRequests" />

const TopRequests = () => {
  const { topics, nextPage, hasNextPage, max, isLoading } = useTrendingTopics('topRequests')

  const onLoadMoreClick = () => {
    nextPage()
    scrollOnList('top-requests-scroll-area')
  }

  const emptyCards = getEmptyCardsArray(topics)

  if (isLoading) {
    return <TopRequestsSkeleton />
  }

  if (topics.length === 0) {
    return (
      <TrendingTopicsCard.Root type="requests">
        <TopRequestsHeader />
        <TrendingTopicsEmptyList />
      </TrendingTopicsCard.Root>
    )
  }

  return (
    <TrendingTopicsCard.Root type="requests">
      <TrendingTopicsCard.Row>
        <TopRequestsHeader />
      </TrendingTopicsCard.Row>

      <TrendingTopicsCard.ScrollArea id="top-requests-scroll-area">
        <TrendingTopicsCard.ItemList>
          {topics.map(topic => (
            <TrendingTopicItem
              hasDetails
              key={topic.name}
              max={max}
              topic={topic}
              type="topRequests"
            />
          ))}
          {emptyCards.map((_, index) => (
            <TrendingTopicItemEmpty key={index} />
          ))}
        </TrendingTopicsCard.ItemList>
      </TrendingTopicsCard.ScrollArea>

      <TrendingTopicsCard.Row justifyContent="flexEnd">
        {hasNextPage && (
          <Button onClick={onLoadMoreClick} size="small" variant="flat">
            Load more
          </Button>
        )}
      </TrendingTopicsCard.Row>
    </TrendingTopicsCard.Root>
  )
}

export default TopRequests
