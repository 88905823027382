import Select, { SelectOption } from '@/components/atoms/select'
import { FilterComponentProps } from './types'
import i18n from '../../../../../plugins/i18n/i18n'

const selectOptions: SelectOption<'true' | 'false'>[] = [
  {
    value: 'true',
    text: i18n.t('yes')
  },
  {
    value: 'false',
    text: i18n.t('no')
  }
]

const BooleanFilterRow = ({ filter, onFilterChange }: FilterComponentProps) => {
  const onOptionChange = (value: string) => {
    onFilterChange({ ...filter, value: value === 'true' })
  }
  const selectedOption = filter.value ? 'true' : 'false'

  return (
    <Select
      onValueChange={onOptionChange}
      options={selectOptions}
      small
      value={selectedOption}
      width="100%"
    />
  )
}

export default BooleanFilterRow
