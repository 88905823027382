import { Parser } from '@json2csv/plainjs'

function useCsv(fileName?: string) {
  const download = (data: string | undefined) => {
    if (!data) {
      return
    }

    const blob = new Blob([data], {
      type: 'text/csv'
    })
    const url = window.URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.setAttribute('href', url)

    const date = new Date().toISOString().split('T')[0]
    a.setAttribute('download', `${fileName || 'feedback'}_${date}.csv`)
    a.click()
  }

  const csvmaker = (data: Record<never, never>[], formatter: Record<string, string> = {}) => {
    const fields = Object.keys(data[0]).map(field => ({
      label: formatter[field] || field,
      value: field,
      default: 'NULL'
    }))

    try {
      const parser = new Parser({
        header: true,
        fields
      })

      const csv = parser.parse(data)

      return csv
    } catch (err) {
      console.error(err)
    }
  }

  return {
    download,
    csvmaker
  }
}

export default useCsv
