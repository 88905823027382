import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import { APP_ENVIRONEMNT } from '@/config'
import { styled } from '@/theme'
import { useTranslation } from 'react-i18next'

import BirdiesFlying from '@/assets/drawings/birdies-flying.png'

const Title = styled('h1', {
  fontSize: '$lg',
  fontWeight: '$bold',
  lineHeight: '50px',
  color: '$brandPrimaryPure',
  textAlign: 'center'
})

const Paragraph = styled('p', {
  fontSize: '$xxs',
  maxWidth: 580,
  textAlign: 'center'
})

const Drawing = styled('img', {
  width: 819
})

const NoPermission = () => {
  const onButtonClick = () => {
    if (APP_ENVIRONEMNT === 'production') {
      window.Intercom('showSpace', 'messages')
    }
  }
  const { t } = useTranslation()

  return (
    <FlexContainer alignItems="center" css={{ pt: 72 }} direction="column" gap="xxxs">
      <Title>{t('theBirdiesAreLeaving')}</Title>
      <Paragraph>
        {t(
          'yourCurrentPlanDoesntGiveAccessToThisModuleGetInTouchWithOurSalesTeamAndGetReadyToFlyHigher'
        )}
      </Paragraph>
      <Button css={{ mt: '$xs' }} onClick={onButtonClick} variant="exception">
        {t('talkToSales')}
      </Button>
      <Drawing alt="A drawing of birdies flying" src={BirdiesFlying} />
    </FlexContainer>
  )
}

export default NoPermission
