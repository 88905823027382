import { useEditor } from '@tiptap/react'
import EditorToolbar from './EditorToolbar'
import { OptionsContainer, TextEditorContainer, TextEditorContent } from './TextEditor.styles'
import FlexContainer from '@/components/atoms/flex-container'
import Button from '@/components/atoms/button'
import defaultExtensions from './extensions'
import { useTranslation } from 'react-i18next'

interface TextEditorProps {
  initialContent?: string
  onSave?: (content: string) => void
  onCancel?: () => void
}

const TextEditor = ({ initialContent = '', onSave, onCancel }: TextEditorProps) => {
  const editor = useEditor({ extensions: defaultExtensions, content: initialContent })

  const { t } = useTranslation()

  const onSaveClick = () => {
    if (!editor) {
      onSave?.(initialContent)
      return
    }
    const content = editor.getHTML()
    onSave?.(content)
  }

  const onCancelClick = () => {
    if (!editor) return
    editor.commands.setContent(initialContent)
    onCancel?.()
  }

  return (
    <FlexContainer direction="column" fullWidth gap="xs">
      <TextEditorContainer collapsed={false} direction="column" editable>
        <EditorToolbar editor={editor} />
        <TextEditorContent editable editor={editor} />
      </TextEditorContainer>
      <OptionsContainer alignItems="center" css={{}} gap="xxs">
        <Button onClick={onSaveClick} size="small">
          {t('save')}
        </Button>
        <Button onClick={onCancelClick} size="small" variant="flat">
          {t('cancel')}
        </Button>
      </OptionsContainer>
    </FlexContainer>
  )
}

export default TextEditor
