import useClassification, { CLASSIFICATION_LABELS } from '@/hooks/useClassification'
import Button from '@/components/atoms/button'
import { Plus } from '@phosphor-icons/react'
import { useState } from 'react'
import NameInput from '../input/NameInput'
import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'
import { TopicCategory } from '@/types/classification'

const AddThemeContainer = styled(FlexContainer, {
  padding: '$micro',
  position: 'relative',
  '& > button': {
    color: '$neutralLowPure'
  }
})

interface Props {
  onAddStatusChange?: (isAdding: boolean) => void
  withoutButtons?: boolean
  topicCategory?: TopicCategory
}

const AddTheme = ({ withoutButtons = false, onAddStatusChange, topicCategory }: Props) => {
  const { addTheme, isLoading, classificationLabelsMode } = useClassification()
  const [isAddingTheme, setIsAddingTheme] = useState(false)

  function onAddClick() {
    setIsAddingTheme(true)
    onAddStatusChange?.(true)
  }

  function onAddCancel() {
    setIsAddingTheme(false)
    onAddStatusChange?.(false)
  }

  function onAddConfirm(value: string) {
    addTheme(value, topicCategory)
    setIsAddingTheme(false)
    onAddStatusChange?.(false)
  }

  const labels: Record<CLASSIFICATION_LABELS, { placeholder: string; button: string }> = {
    'topic-group': {
      placeholder: 'Type topic group name',
      button: 'Add topic group'
    },
    'default-topic': {
      placeholder: 'Type topic name',
      button: 'Add topic'
    }
  }

  if (isLoading) return <></>

  return (
    <AddThemeContainer>
      {isAddingTheme ? (
        <NameInput
          css={{ mr: '$xxs' }}
          onCancel={onAddCancel}
          onConfirm={onAddConfirm}
          placeholder={labels[classificationLabelsMode].placeholder}
          withoutButtons={withoutButtons}
        />
      ) : (
        <Button micro onClick={onAddClick} text>
          <Plus />
          <span>{labels[classificationLabelsMode].button}</span>
        </Button>
      )}
    </AddThemeContainer>
  )
}

export default AddTheme
