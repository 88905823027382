import useLetterCase from '@/hooks/useLetterCase'
import { FilterString } from '@/types/filters/Filters'
import {
  FeedbackIntentionContent,
  useFeedbackIntentionSelection
} from '../../FiltersSidebar/FeedbackIntention'
import { FilterItemDropdown } from '../FilterItem'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

interface AppliedFeedbackIntentionFiltersEditProps {
  filter: FilterString
}

function AppliedFeedbackIntentionFiltersEdit(props: AppliedFeedbackIntentionFiltersEditProps) {
  const { capitalizeFirst } = useLetterCase()
  const { t } = useTranslation()

  const { selectOption, isChecked, hasChanges, apply: onApply } = useFeedbackIntentionSelection()

  function getTooltipText(filter: FilterString) {
    const selectedOptions = filter.selected
      .map(option => `${capitalizeFirst(option)}`)
      .join(t('or'))

    return i18n.t('isSelectedOptions', { selectedOptions })
  }

  return (
    <FilterItemDropdown
      content={props.filter.selected.length}
      contentType="count"
      disabled={!hasChanges}
      dropdown={<FeedbackIntentionContent isChecked={isChecked} selectOption={selectOption} />}
      onApply={onApply}
      title={t('feedbackIntention')}
      tooltip={getTooltipText(props.filter)}
    />
  )
}

export default AppliedFeedbackIntentionFiltersEdit
