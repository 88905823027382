import { TimeSeriesField } from './AnalyticsRequests'

export interface AnalyticsMetrics extends SentimentMetrics {
  totalCount: number
  feedbackCount: number
}

export interface PostedAtMetrics {
  postedAt: string
  feedbackCount: number
  averageRating: number | null
  netSentiment: number | null
}

export interface ThemeChartItem {
  themeId: string
  themeName: string
  topicIds: string[]
  sentimentMetrics: AnalyticsMetrics
  postedAtMetrics: PostedAtMetrics[]
}

export interface ThemeTopicItem {
  themeId: string
  themeName: string
  topicId: string
  topicName: string
  sentimentMetrics: AnalyticsMetrics
  postedAtMetrics: PostedAtMetrics[]
}

export interface AnalyzedFeedbacksMetric {
  labeledCount: number
  totalCount: number
  labeledPercentage: number
  unlabeledPercentage: number
}

export interface KeywordMetrics {
  frequency: number
  clusterName: string
  sentimentMetrics: AnalyticsMetrics
  postedAtMetrics: PostedAtMetrics[]
}

export interface FeedbackClassMetrics {
  feedbackClass: string
  feedbackCount: number
  percentage: number
  postedAtMetrics: PostedAtMetrics[]
}

export interface FeedbackTypeMetrics {
  feedbackType: string
  feedbackCount: number
  percentage: number
  postedAtMetrics: PostedAtMetrics[]
  connectors: {
    name: string
    sentimentMetrics: AnalyticsMetrics
  }[]
}

export interface FeedbackLabeledMetrics {
  countTotal: number
  countLabeled: number
  countUnlabeled: number
  percentageLabeled: number
  percentageUnlabeled: number
}

export interface TimeSeriesValue {
  fieldValue: string
  data: {
    postedAt: string
    countFeedback: number
    countFeedbackWithoutFilter: number
    percentageFeedback: number
  }[]
}

export interface TimeSeriesData {
  key: string
  values: TimeSeriesValue[]
}

export type AnalyticsSortType = 'name' | 'frequency' | 'positive' | 'negative'

export type ChartId =
  | 'themes'
  | 'productAreas'
  | 'keywords'
  | 'report'
  | 'timeSeries'
  | 'timeSeriesAll'
  | 'timeSeriesFeedbackType'
  | 'timeSeriesFeedbackClass'
  | 'timeSeriesTopic'
  | 'timeSeriesSubTopic'

export type BreakdownOption = TimeSeriesField

export enum IntervalOption {
  Day = '1d',
  Week = '1w',
  Month = '1M',
  Quarter = '1q',
  Year = '1y'
}

export type MetricOption = 'count' | 'percentage'

export interface IndicatorsData {
  feedbackMetric: {
    previousFeedbackCount: number
    currentFeedbackCount: number
    feedbackPercentage: number
  }
  metricCustomer: {
    totalCustomer: number
    percentageCustomer: number
  }
  metricsByIntention: {
    intention: string
    previousFeedbackCount: number
    currentFeedbackCount: number
    feedbackPercentage: number
    intentionPercentage: number
    detailsPercentage: number
  }[]
}

export interface TrendingTopicItemData {
  topicId: string
  name: string
  currentPercentageIntention: number
  previousPercentageIntention: number
  current: number
  previous: number
  variation: number
  currentFeedback: number
  previousFeedback: number
}

export interface TrendingTopicsData {
  emergingTopics: TrendingTopicItemData[]
  topIssues: TrendingTopicItemData[]
  topRequests: TrendingTopicItemData[]
}

export interface TopicRootCause {
  text: string
  count: number
  topSample: string[]
  relevance: number
  reasonsHash: string[]
}

export interface TopicDetails {
  summary: string
  rootCauses: TopicRootCause[]
}
