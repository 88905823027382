import TrendingTopicsCard from './TrendingTopicsCard'
import Text from '@/components/atoms/text'
import i18n from '../../../../plugins/i18n/i18n'

const TrendingTopicsEmptyList = () => (
  <TrendingTopicsCard.CenteredContainer>
    <TrendingTopicsCard.EmptyImage src={TrendingTopicsCard.EmptyImageSource} />
    <Text align="center">
      {i18n.t('noTopicsFound')}
      <br />
      {i18n.t('youMayReviewYourFiltersAndDateSelected')}
    </Text>
  </TrendingTopicsCard.CenteredContainer>
)

export default TrendingTopicsEmptyList
