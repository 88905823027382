import { memo, ReactNode, useMemo, useState } from 'react'

import {
  CollapsibleRoot,
  CollapsibleTrigger,
  CollapsibleContentAnimated
} from '@/components/atoms/collapsible'
import Checkbox, { CheckboxState } from '@/components/atoms/checkbox'
import IconButton from '@/components/atoms/icon-button'
import KeywordChip from '@/components/atoms/keyword-chip'
import VolumeBar from '@/components/atoms/volume-bar'
import { Keyword, KeywordSentiment, KeywordTopic } from '@/types/keywords'
import Text from '@/components/atoms/text'
import { TableCell, TableCellGap, TableRow } from './KeywordsCollapsibleRow.styles'
import KeywordTopics from './KeywordTopics'
import SentimentsTooltip from '../../classification/tooltip/SentimentsTooltip'
import { colors } from '@/theme'
import { CaretDown, Lightning } from '@phosphor-icons/react'
import Button from '@/components/atoms/button'
import useUser from '@/hooks/useUser'

interface KeywordsTableRowProps {
  name: string
  id: string
  topics: KeywordTopic[]
  frequency: number
  keywordsCount?: number
  max: number
  onCheckedChange: (checked: CheckboxState) => void
  checked: CheckboxState
  checkboxDisabled: boolean
  onDeleteRelation?: (topicId: string, keywordId: string) => void
  onDeleteKeyword?: (keywordHash: string) => void
  onClickRow?: () => void
  isClicked: boolean
  sentiment: KeywordSentiment
  isCluster?: boolean
  children?: ReactNode
  onReasonsClick?: () => void
  clusterKeywords?: Keyword[]
  totalReasons?: number
}

const KeywordsCollapsibleRow = (props: KeywordsTableRowProps) => {
  const { userPermissions } = useUser()
  const isManager = userPermissions.topic.includes('manager')
  const canViewFindings = userPermissions.topic.includes('findings')
  const isFindingsVisible = canViewFindings && props.isCluster && !!props.totalReasons

  const [open, setOpen] = useState(false)

  const netSentimentRange = useMemo(() => {
    const { netSentiment } = props.sentiment
    if (netSentiment < 0) return 'red'

    if (netSentiment > -1 && netSentiment <= 20) return 'neutralMedium'

    return 'green'
  }, [props.sentiment])

  const showCheckbox = Boolean(props.totalReasons) || isManager

  return (
    <CollapsibleRoot onOpenChange={setOpen} open={open}>
      <TableRow isClicked={props.isClicked} isExpanded={open} key={props.id}>
        <TableCell>
          {props.isCluster ? (
            <CollapsibleTrigger css={{ marginRight: 8 }}>
              <IconButton
                className="expand-icon"
                css={{
                  svg: {
                    polyline: { 'stroke-width': 32 },
                    line: { 'stroke-width': 32 }
                  }
                }}
              >
                <CaretDown color={colors.neutralLowLight} weight="bold" />
              </IconButton>
            </CollapsibleTrigger>
          ) : (
            <TableCellGap />
          )}
          {showCheckbox ? (
            <Checkbox
              checked={props.checked}
              disabled={props.checkboxDisabled}
              onCheckedChange={props.onCheckedChange}
              value={props.id}
            />
          ) : null}
          <KeywordChip
            id={props.id}
            isCluster={props.isCluster}
            isClusterOpen={open}
            label={props.isCluster ? `${props.name} (${props.keywordsCount})` : props.name}
            onClick={e => {
              e.preventDefault()
              props.onClickRow?.()
            }}
          />
        </TableCell>
        <TableCell wrap>
          <KeywordTopics {...props} />
        </TableCell>
        <TableCell justifyCenter netSentiment>
          <SentimentsTooltip
            {...props.sentiment}
            align="center"
            closeDelay={400}
            feedbackCount={0}
            openDelay={250}
            showHeader={false}
            title=""
            totalCount={0}
          >
            <div>
              <Text color={netSentimentRange} typeface="textRegularXSmall">
                {Math.trunc(props.sentiment.netSentiment)}
              </Text>
            </div>
          </SentimentsTooltip>
        </TableCell>
        <TableCell>
          <VolumeBar
            css={{ background: '$purple' }}
            fullRounded
            height="small"
            max={props.max}
            showNumber={false}
            showNumberOutside
            value={props.frequency}
          />
        </TableCell>
        <TableCell>
          {isFindingsVisible && (
            <Button css={{ fontSize: '$micro' }} onClick={props.onReasonsClick} variant="flat">
              <Lightning weight="fill" />
              <span>View findings</span>
            </Button>
          )}
        </TableCell>
      </TableRow>

      <CollapsibleContentAnimated>{props.children}</CollapsibleContentAnimated>
    </CollapsibleRoot>
  )
}

export default memo(KeywordsCollapsibleRow)
