import { formatPercentageWithSignal } from '@/utils/numbers'
import TrendingTopicsCard from './TrendingTopicsCard'
import TrendingTopicTooltip from './TrendingTopicTooltip'
import { TrendingTopicItemData } from '@/types/analytics/Analytics'
import { useFiltersStore } from '@/store'
import { shallow } from 'zustand/shallow'
import { TrendingTopicType } from '@/types/analytics/TrendingTopic'
import useUser from '@/hooks/useUser'

interface Props {
  topic: TrendingTopicItemData
  max: number
  hasDetails?: boolean
  type?: TrendingTopicType
}
const TrendingTopicItem = ({ topic, max, hasDetails = false, type }: Props) => {
  const formattedValue = formatPercentageWithSignal(topic.variation)

  const { dateRange } = useFiltersStore(
    state => ({
      dateRange: state.dateRange
    }),
    shallow
  )

  const { userPermissions } = useUser()

  const displayNumber = !dateRange ? topic.current : formattedValue

  const clickable = userPermissions.drilldown.includes('view') && hasDetails

  const link = clickable
    ? `/analytics/${type}/${encodeURIComponent(topic.name)}@@${topic.topicId}`
    : ''

  return (
    <>
      <TrendingTopicTooltip topic={topic} type={type}>
        <TrendingTopicsCard.Item clickable={clickable} to={link}>
          <TrendingTopicsCard.Row alignItems="center">
            <TrendingTopicsCard.ItemTitle>{topic.name}</TrendingTopicsCard.ItemTitle>
            <TrendingTopicsCard.Number>{displayNumber}</TrendingTopicsCard.Number>
          </TrendingTopicsCard.Row>
          <TrendingTopicsCard.ProgressBar max={max} value={topic.current} />
        </TrendingTopicsCard.Item>
      </TrendingTopicTooltip>
    </>
  )
}

export default TrendingTopicItem
