import { useTranslation } from 'react-i18next'
import OrganizationStyles from './OrganizationSettings.styles'
import FlexContainer from '@/components/atoms/flex-container'
import Input from '@/components/atoms/input'
import Select from '@/components/atoms/select'

const mapCurrency: Record<string, string> = {
  BRL: 'R$',
  R$: 'R$',
  USD: 'US$',
  US$: 'US$'
}

interface Props {
  currency: string
  setCurrency: (value: string) => void
  ticketCost: string
  setTicketCost: (value: string) => void
}

const PotentialSavings = ({ currency, setCurrency, ticketCost, setTicketCost }: Props) => {
  const { t } = useTranslation()

  return (
    <OrganizationStyles.Card>
      <OrganizationStyles.CardHeader as="h3">
        {t('potentialSavingsMetricPremise')}
      </OrganizationStyles.CardHeader>

      <FlexContainer gap="xxs" direction="column">
        <OrganizationStyles.Row>
          <FlexContainer direction={'column'}>
            <OrganizationStyles.ColText>
              {t('costCurrency')} / {t('unitCostToServe')}
            </OrganizationStyles.ColText>
            <OrganizationStyles.ColText description>
              {t('directAndIndirectUnitCostsPerTicket')}
            </OrganizationStyles.ColText>
          </FlexContainer>
          <FlexContainer gap="xxs" fullWidth>
            <Select
              options={[
                { text: t('americanDollarCurrency'), value: mapCurrency.US$ },
                { text: t('brazilianRealCurrency'), value: mapCurrency.R$ }
              ]}
              width="100%"
              onValueChange={setCurrency}
              value={mapCurrency[currency] ?? 'US$'}
            />
            <Input
              value={ticketCost}
              min={0}
              onChange={e => setTicketCost(e.currentTarget.value)}
              type="number"
            />
          </FlexContainer>
        </OrganizationStyles.Row>
      </FlexContainer>
    </OrganizationStyles.Card>
  )
}

export default PotentialSavings
