import { FilterItem } from '@/types/filters/Filters'
import { Stack } from '@phosphor-icons/react'
import FilterSubMenu from '../AddFilterMenu/FilterSubMenu'
import { useState } from 'react'
import useStringGenericFilter from '../StringGenericFilter/useStringGenericFilterNew'
import StringFilterContent from '../StringGenericFilter/StringFilterContentNew'
import { getFeedbackKindPrettyName } from '@/utils/feedback'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { isAreaOfInterestFilter } from '@/utils/areaOfInterest'
import { useTranslation } from 'react-i18next'

export interface FeedbackTypeSectionProps {
  feedbackTypeFilter: FilterItem
}

const FeedbackTypeSubMenu = ({ feedbackTypeFilter }: FeedbackTypeSectionProps) => {
  const [open, setOpen] = useState(false)
  const { isLoading, hasChanges, apply, options, isChecked, selectOption, selectedOptions } =
    useStringGenericFilter({
      filterKey: feedbackTypeFilter.key,
      enabled: open,
      name: feedbackTypeFilter.name
    })

  const textFormatter = (text: string) => getFeedbackKindPrettyName(text)
  const areaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const { t } = useTranslation()

  if (
    isAreaOfInterestFilter({
      key: feedbackTypeFilter.key,
      values: selectedOptions,
      areaOfInterest
    })
  )
    return <></>

  return (
    <FilterSubMenu
      icon={<Stack />}
      isDisabled={!hasChanges}
      isLoading={isLoading}
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      title={t('source')}
    >
      <StringFilterContent
        isChecked={isChecked}
        options={options}
        selectOption={selectOption}
        textFormatter={textFormatter}
      />
    </FilterSubMenu>
  )
}

export default FeedbackTypeSubMenu
