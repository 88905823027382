import { styled } from '@/theme'

export const InputContainer = styled('div', {
  display: 'flex',
  bBottom: '$brandPrimaryPure',
  alignItems: 'center',
  width: '100%',

  boxSizing: 'border-box',
  gap: 8,
  height: 48,
  paddingRight: 8
})

export const StyledInput = styled('input', {
  all: 'unset',
  fontSize: 14,
  width: '100%'
})
