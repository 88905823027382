import { styled } from '@/theme'

const IconButton = styled('button', {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'none',
  borderRadius: 4,
  background: 'transparent',
  bAll: 'transparent',
  transition: '.2s',
  cursor: 'pointer',
  size: 24,

  '&:disabled': {
    opacity: 0.5,
    pointerEvents: 'none'
  },

  variants: {
    activeDropzone: {
      true: { background: '$pajaritoTransparent' }
    },
    active: {
      true: {
        backgroundColor: '$brandPrimaryLight',
        color: '$brandPrimaryPure',

        '&:hover': {
          background: '$brandPrimaryMedium'
        }
      }
    },
    color: {
      default: {
        '&:hover': {
          backgroundColor: '$brandPrimaryLight',
          color: '$brandPrimaryPure'
        }
      },
      danger: {
        '&:hover': {
          backgroundColor: '$feedbackNegativeLight',
          color: '$feedbackNegativePure'
        }
      }
    },
    size: {
      small: {
        size: '1em',
        minWidth: '1em',
        minHeight: '1em',
        padding: 2,
        boxSizing: 'content-box',
        margin: -3
      },
      regular: {
        minWidth: '1.8em',
        minHeight: '1.8em',
        padding: 4,
        size: '1.4em',
        boxSizing: 'border-box'
      }
    }
  },

  defaultVariants: {
    size: 'regular',
    color: 'default'
  }
})

export default IconButton
