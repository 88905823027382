import useLetterCase from '@/hooks/useLetterCase'
import usePeriods from '@/hooks/usePeriods'
import { FilterDatetime, FilterDatetimeValue } from '@/types/filters/Filters'
import { getFormattedDateRange } from '@/utils/date'
import { FilterItemDropdown } from '../../AppliedFilters/FilterItem'
import RangeCalendar from '@/components/atoms/range-calendar'
import { useFeedFiltersStore } from '@/store/useFiltersStore'

interface Props {
  filter: FilterDatetime
  buttonDisabled?: boolean
}

function FilterItemDropdownDatetime({ filter, buttonDisabled }: Props) {
  const apply = useFeedFiltersStore(state => state.apply)
  const setDatetimeFilter = useFeedFiltersStore(state => state.setDatetimeFilter)

  const { snakeToTitle } = useLetterCase()
  const { getPeriod } = usePeriods()

  function setDateRange(range: FilterDatetimeValue) {
    setDatetimeFilter({
      key: filter.key,
      name: filter.name,
      type: filter.type,
      value: range,
      period: 'custom'
    })
  }

  function getTooltipText(filter: FilterDatetime) {
    const formattedDateRange = filter.value ? getFormattedDateRange(filter.value) : 'All time'
    const text = filter.period === 'custom' ? formattedDateRange : getPeriod(filter.period).name

    return `Is <b>${snakeToTitle(filter.name)}</b> > <b>${text}</b>`
  }

  const hasChanges = JSON.stringify(filter.value) !== JSON.stringify(filter.draftValue)

  return (
    <FilterItemDropdown
      buttonDisabled={buttonDisabled}
      content={1}
      contentCss={{ width: 'auto' }}
      contentType="count"
      disabled={!hasChanges}
      dropdown={<RangeCalendar onChange={setDateRange} value={filter.draftValue} />}
      onApply={apply}
      title={snakeToTitle(filter.name)}
      tooltip={getTooltipText(filter)}
    />
  )
}

export default FilterItemDropdownDatetime
