import { AccordionCardItem, AccordionCaret, AccordionContent, AccordionTrigger } from '../accordion'
import Text from '@/components/atoms/text'
import FlexContainer from '@/components/atoms/flex-container'
import Switch from '@/components/atoms/switch'
import ToggleGroup, { ToggleGroupOption } from '@/components/atoms/toggle-group'
import { useMemo, useState } from 'react'
import useUser from '@/hooks/useUser'
import { useUserStore } from '@/store'
import { shallow } from 'zustand/shallow'
import { OnChangeData } from '@/types/notifications/Notifications'
import Divider from '@/components/atoms/divider'
import * as NotificationSelect from './notification-select'
import Button from '@/components/atoms/button'
import { NotificationCardContent } from './Notifications.styles'
import useSegment from '@/hooks/useSegment'
import { NotificationSchedule } from '@/types/alerts/Alerts'
import { useTranslation } from 'react-i18next'

interface Props {
  notificationId?: string
  name: string
  enabled: boolean
  toggleDisabled: boolean
  emailList: string[]
  slackList: string[]
  slackOptions: string[]
  schedule: NotificationSchedule
  onChange: (data: OnChangeData) => void
  onSlackSettingsClick: React.MouseEventHandler<HTMLButtonElement>
}

const ManageNotificationCard = ({
  name,
  enabled,
  toggleDisabled,
  emailList,
  slackList,
  schedule,
  slackOptions,
  onChange,
  onSlackSettingsClick
}: Props) => {
  const { track } = useSegment()

  const { t } = useTranslation()

  const users = useUserStore(state => state.users, shallow)
  const { currentUser } = useUser()
  const userEmail = currentUser?.email ?? ''

  const emailOptions = useMemo(
    () =>
      [userEmail, ...users.map(u => u.email).filter(email => email !== userEmail)].filter(
        email => email !== ''
      ),
    [users, userEmail]
  )

  const toggleGroupOptions: ToggleGroupOption[] = [
    { text: t('daily'), value: 'daily' },
    { text: t('weekly'), value: 'weekly' },
    { text: t('monthly'), value: 'monthly' }
  ]

  const [scheduleInput, setScheduleInput] = useState<NotificationSchedule>(schedule)

  const onChangeEnabled = (value: boolean) => {
    if (value) {
      track('explore_user_enable_summary_notification')
    } else {
      track('explore_user_disable_summary_notification')
    }
    onChange({
      emails: emailList,
      slackChannels: slackList,
      enabled: value,
      schedule: scheduleInput
    })
  }

  const onChangeEmail = (value: string[]) => {
    track('explore_user_change_summary_notification_email-recipients')
    onChange({
      emails: value,
      slackChannels: slackList,
      enabled,
      schedule: scheduleInput
    })
  }

  const onChangeSlackChannels = (value: string[]) => {
    track('explore_user_change_summary_notification_slack_channel')
    onChange({
      emails: emailList,
      slackChannels: value,
      enabled,
      schedule: scheduleInput
    })
  }

  const onChangeSchedule = (value: NotificationSchedule) => {
    track('explore_user_change_summary_notification_frequency')
    setScheduleInput(value)
    onChange({
      emails: emailList,
      slackChannels: slackList,
      enabled,
      schedule: value
    })
  }

  const isSlackConnected = slackOptions.length > 0

  return (
    <AccordionCardItem value={name}>
      <FlexContainer
        alignItems="center"
        css={{ height: 'fit-content' }}
        justifyContent="spaceBetween"
      >
        <AccordionTrigger css={{ py: 0 }}>
          <AccordionCaret size={16} />
          <Text as="h2" fontSize="xxs" fontWeight="semibold">
            {name}
          </Text>
        </AccordionTrigger>
        <Switch
          checked={enabled}
          css={{ padding: 0, margin: 0 }}
          disabled={toggleDisabled}
          onCheckedChange={onChangeEnabled}
        />
      </FlexContainer>
      <AccordionContent>
        <NotificationCardContent>
          <Divider line="solid" />
          <FlexContainer direction="column" gap={isSlackConnected ? 'xs' : 'micro'}>
            <Text as="h3" fontSize="micro" fontWeight="semibold">
              {isSlackConnected ? t('sendNotificationVia') : t('sendTo')}
            </Text>
            <NotificationSelect.Root>
              <NotificationSelect.Label>E-mail</NotificationSelect.Label>
              <NotificationSelect.MultiSelect
                creatable
                onChange={onChangeEmail}
                options={emailOptions}
                placeholder={t('selectEmails')}
                values={emailList}
              />
            </NotificationSelect.Root>

            {isSlackConnected && (
              <NotificationSelect.Root>
                <FlexContainer alignItems="center" justifyContent="spaceBetween">
                  <NotificationSelect.Label>
                    {t('slackSendAlertsToTheSelectedSlackChannels')}
                  </NotificationSelect.Label>
                  <Button
                    css={{ padding: 0, height: 'fit-content' }}
                    onClick={onSlackSettingsClick}
                    size="small"
                    variant="link"
                  >
                    {t('slackSettings')}
                  </Button>
                </FlexContainer>
                <NotificationSelect.MultiSelect
                  allowEmpty
                  onChange={onChangeSlackChannels}
                  options={slackOptions}
                  placeholder={t('selectSlackChannels')}
                  values={slackList}
                />
              </NotificationSelect.Root>
            )}
          </FlexContainer>
          <Divider line="solid" />

          <FlexContainer alignItems="center" justifyContent="spaceBetween">
            <Text as="h3" fontSize="micro" fontWeight="semibold">
              {t('notificationFrequency')}
            </Text>
            <ToggleGroup
              onValueChange={value => onChangeSchedule(value as NotificationSchedule)}
              options={toggleGroupOptions}
              value={scheduleInput}
            />
          </FlexContainer>
        </NotificationCardContent>
      </AccordionContent>
    </AccordionCardItem>
  )
}

export default ManageNotificationCard
