import useStringFilterSelection from '@/hooks/filters/useStringFilterSelection'
import useUniqueFiltersQuery from '@/hooks/filters/useUniqueFiltersQuery'
import { useMemo } from 'react'

interface Params {
  filterKey: string
  path: string
  name: string
  enabled: boolean
  searchText?: string
}

const useUniqueGenericFilter = ({ filterKey, path, name, enabled, searchText = '' }: Params) => {
  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useUniqueFiltersQuery(
    {
      name,
      path,
      searchText,
      enabled
    }
  )

  const { isChecked, selectOption, hasChanges, selectedOptions, apply } = useStringFilterSelection({
    name,
    filterKey,
    type: 'unique',
    newFeed: true
  })

  const options = useMemo(() => {
    if (!data) return []
    const dataOptions = data?.pages.flatMap(page => page.values)
    const notFoundOptions = selectedOptions.filter(option => !dataOptions.includes(option))

    return [...new Set([...notFoundOptions, ...dataOptions])]
  }, [data, selectedOptions])

  const isShowMoreDisabled = isLoading || isFetchingNextPage

  return {
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isChecked,
    selectOption,
    hasChanges,
    apply,
    options,
    selectedOptions,
    isShowMoreDisabled
  }
}

export default useUniqueGenericFilter
