import useLetterCase from '@/hooks/useLetterCase'
import { useFiltersStore } from '@/store'
import { Intention } from '@/types/reasons'
import { useCallback, useEffect, useMemo } from 'react'
import { shallow } from 'zustand/shallow'
import { DiscoveryGrid } from './Discovery.styles'
import useSelectedFilters from '@/hooks/filters/useSelectedFilters'
import { QueryFunctionContext, UseQueryResult, useQuery } from '@tanstack/react-query'
import { MergeFindingProps } from './Discovery.types'
import useChatStore from '@/store/useChatStore'
import { cloneObject } from '@/utils/object'
import { SearchPayload, SearchWithChatPayload } from '@/types/feedbacks/FeedbackRequests'
import FeedbackService from '@/services/FeedbackService'
import DiscoveryItemMarkdown from './DiscoveryItemMarkdown'
import useChat from '@/hooks/useChat'
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'

type QueryKeyParams = {
  intention: Intention
  enabled: boolean
  [x: string]: unknown
}

const intentionList: Intention[] = ['issue', 'request', 'question', 'compliment']

const DiscoveryWithAssistant = ({ showMergeFindingsCheckbox }: MergeFindingProps) => {
  const { capitalizeFirst } = useLetterCase()

  const toPayload = useFiltersStore(state => state.toFeedbackRequestPayload)

  const { chatData, isLoadingChatData } = useChatStore(
    state => ({
      chatData: state.chatData,
      isLoadingChatData: state.isLoadingChatData,
      randomFeedbacks: state.randomFeedbacks
    }),
    shallow
  )

  const { getChatFeedbacks } = useChat()

  const { filtersByURL, ...searchFilters } = useFiltersStore(
    state => ({
      search: state.search,
      dateRange: state.dateRange,
      orderBy: state.orderBy,
      sortDirection: state.sortDirection,
      volumeBy: state.volumeBy,
      filtersByURL: state.filtersByURL
    }),
    shallow
  )

  const {
    selectedNumericFilters,
    selectedDatetimeFilters,
    selectedStringFilters,
    selectedTopicFilters
  } = useSelectedFilters()

  const filters = useMemo(
    () => ({
      selectedNumericFilters,
      selectedDatetimeFilters,
      selectedStringFilters,
      selectedTopicFilters,
      enabled: (chatData?.feedbackTakenIntoAccount || 0) > 0,
      ...searchFilters
    }),
    [
      selectedNumericFilters,
      selectedDatetimeFilters,
      selectedStringFilters,
      selectedTopicFilters,
      // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
      searchFilters,
      chatData?.feedbackTakenIntoAccount
    ]
  )

  useEffect(() => {
    getChatFeedbacks('openai')
  }, [getChatFeedbacks])

  useDidUpdateEffect(
    () => {
      getChatFeedbacks('openai')
    },
    [
      selectedNumericFilters,
      selectedDatetimeFilters,
      selectedStringFilters,
      selectedTopicFilters,
      searchFilters,
      filtersByURL
    ],
    true
  )

  const loadReasonsByIntention = useCallback(
    async ({ queryKey, signal }: QueryFunctionContext) => {
      const { intention, enabled } = queryKey[1] as QueryKeyParams

      if (!enabled) return ''

      const searchPayload: SearchPayload = cloneObject(
        toPayload(0, chatData?.feedbackTakenIntoAccount || 200, false)
      )
      searchPayload.filter.feedback_keyword_classes = [capitalizeFirst(intention)]

      const payload: SearchWithChatPayload = {
        feedback_search: searchPayload,
        question: `Give me only the title of 10 most relevant ${intention}s as Markdown Numbered list. You must not return any quote or reference and any counts. Just the markdown list.`,
        stream: true,
        feedback_id_list: [],
        history: [],
        feedback_input_fields: []
      }

      try {
        const response = await FeedbackService.searchWithChatStream(payload, signal)

        if (!response.ok) throw new Error('Request failed')
        if (!response.body) throw new Error('Request failed')

        const reader = response.body.getReader()
        const decoder = new TextDecoder()

        let done = false
        let data = ''

        while (!done) {
          try {
            const result = await reader.read()
            done = result.done

            const value = decoder.decode(result.value)
            data += value
          } catch (error) {
            done = true
            data += '[UNEXPECTED STREAM CLOSED]'
            console.error(error)
          }
        }

        return data
      } catch (error) {
        console.error(error)
        if (error instanceof DOMException && error.message.includes('abort')) return ''

        throw error
      }
    },
    [capitalizeFirst, chatData?.feedbackTakenIntoAccount, toPayload]
  )

  const enabled = chatData && chatData.feedbackTakenIntoAccount > 0
  const complimentsQuery = useQuery({
    queryKey: ['findings', { intention: 'compliment', ...filters }],
    queryFn: loadReasonsByIntention,
    retry: false,
    enabled
  })

  const issuesQuery = useQuery({
    queryKey: ['findings', { intention: 'issue', ...filters }],
    queryFn: loadReasonsByIntention,
    retry: false,
    enabled
  })

  const requestsQuery = useQuery({
    queryKey: ['findings', { intention: 'request', ...filters }],
    queryFn: loadReasonsByIntention,
    retry: false,
    enabled
  })

  const questionsQuery = useQuery({
    queryKey: ['findings', { intention: 'question', ...filters }],
    queryFn: loadReasonsByIntention,
    retry: false,
    enabled
  })

  const informationQuery = useQuery({
    queryKey: ['findings', { intention: 'information', ...filters }],
    queryFn: loadReasonsByIntention,
    retry: false,
    enabled: false
  })

  const solutionQuery = useQuery({
    queryKey: ['findings', { intention: 'solution', ...filters }],
    queryFn: loadReasonsByIntention,
    retry: false,
    enabled: false
  })

  const mapQueries: Record<Intention, UseQueryResult<string, unknown>> = useMemo(
    () => ({
      compliment: complimentsQuery,
      issue: issuesQuery,
      request: requestsQuery,
      question: questionsQuery,
      information: informationQuery,
      solution: solutionQuery
    }),
    [complimentsQuery, issuesQuery, requestsQuery, questionsQuery, informationQuery, solutionQuery]
  )

  return (
    <DiscoveryGrid id="discovery-grid">
      {intentionList.map(intention => {
        const queryResult = mapQueries[intention]
        const { data, isLoading, isRefetching, refetch } = queryResult

        return (
          <DiscoveryItemMarkdown
            data={data || ''}
            intention={intention}
            isLoading={isLoading || isLoadingChatData}
            isRefetching={isRefetching}
            key={intention}
            refetch={refetch}
            showMergeFindingsCheckbox={showMergeFindingsCheckbox}
          />
        )
      })}
    </DiscoveryGrid>
  )
}

export default DiscoveryWithAssistant
