import FlexContainer from '@/components/atoms/flex-container'
import { CollectionItemContainer } from './styles'
import { CheckCircle, FolderSimple } from '@phosphor-icons/react'
import { colors } from '@/theme'
import useCollections from '@/hooks/collections/useCollections'
import { useTranslation } from 'react-i18next'

interface Props {
  onClick: () => void
}

const AllAreasCollectionItem = ({ onClick }: Props) => {
  const { currentCollection } = useCollections({ enabled: false })

  const { t } = useTranslation()
  return (
    <CollectionItemContainer onClick={onClick}>
      <FlexContainer alignItems="center" gap="nano">
        <FolderSimple size={16} />
        <span>{t('allOrganizationAreas')}</span>
      </FlexContainer>
      {currentCollection === null && (
        <CheckCircle
          className="check fixed visible"
          color={colors.feedbackPositivePure}
          size={16}
        />
      )}
    </CollectionItemContainer>
  )
}

export default AllAreasCollectionItem
