import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import { MarkdownText } from '@/components/atoms/markdown-text'
import Card from '@/components/molecules/card'
import { styled } from '@stitches/react'

interface BetweenCardProps {
  title: string
  icon: string
  text: string
  textTwo: string
  action: string
  finalRound?: boolean
  onClick: () => void
}
const CardIcon = styled(FlexContainer, {
  width: '100%',
  fontSize: '80px',
  justifyContent: 'center',
  marginBottom: '24px'
})

const CalibrationBetweenQuestionCard = ({
  title,
  icon,
  text,
  textTwo,
  action,
  finalRound = false,
  onClick
}: BetweenCardProps) => (
  <Card.Root
    css={{ minHeight: '300px' }}
    alignItems="center"
    justifyContent="spaceBetween"
    color="brandPrimaryPure"
    direction="column"
    gap="xxs"
  >
    <Card.Header css={{ justifyContent: 'space-between', width: '100%', marginLeft: '4px' }}>
      <Card.Title>{title}</Card.Title>
    </Card.Header>
    <FlexContainer direction="column" alignItems="center" fullWidth>
      <CardIcon>{icon}</CardIcon>
      <MarkdownText>{text}</MarkdownText>
      <MarkdownText>{textTwo}</MarkdownText>
    </FlexContainer>
    {finalRound ? (
      <Button
        css={{ border: 'none', fontWeight: '$semibold', color: '$brandPrimaryPure' }}
        variant="flat-bordered"
        onClick={onClick}
        small
      >
        {action}
      </Button>
    ) : (
      <Button onClick={onClick} small>
        {action}
      </Button>
    )}
  </Card.Root>
)

export default CalibrationBetweenQuestionCard
