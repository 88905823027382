import { useState } from 'react'
import { CheckedState } from '@radix-ui/react-checkbox'
import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import { ThemeItemData } from '@/types/classification/Theme'
import Checkbox from '@/components/atoms/checkbox'
import SubTopicList from './SubTopicList'
import TopicsSearchResult from './TopicsSearchResult'
import { FilterSubMenuScrollArea } from '../AddFilterMenu/AddFilterMenu.styles'
import FilterSubMenu from '../AddFilterMenu/FilterSubMenu'
import FlexContainer from '@/components/atoms/flex-container'
import useUser from '@/hooks/useUser'
import SearchInputForFilters from '../../SearchInputFilter'
import { useTranslation } from 'react-i18next'

export interface TopicsFilterContentProps {
  itemTitle: string
  hasChanges: boolean
  isUnclassifiedChecked: boolean
  getThemeCheckedState: (theme: ThemeItemData) => CheckedState
  isTopicChecked: (topicId: string, themeId?: string) => boolean
  onThemeCheck: (checkedState: CheckedState, theme: ThemeItemData) => void
  onTopicCheck: (checkedState: CheckedState, topicId: string, theme?: ThemeItemData) => void
  onUnclassifiedCheck: () => void
  onApply: () => void
  filteredThemes: ThemeItemData[]
}

const TopicsFilterContent = ({
  filteredThemes,
  getThemeCheckedState,
  isTopicChecked,
  isUnclassifiedChecked,
  onApply,
  onThemeCheck,
  onTopicCheck,
  onUnclassifiedCheck,
  hasChanges,
  itemTitle
}: TopicsFilterContentProps) => {
  const [searchInput, setSearchInput] = useState('')
  const isSearching = searchInput.length > 0
  const { t } = useTranslation()

  const { userPermissions } = useUser()
  const hideUnclassified = userPermissions['new-topic'].length > 0

  return (
    <>
      <SearchInputForFilters
        clearable
        css={{ mt: '$micro', mb: '$micro' }}
        onChange={e => setSearchInput(e.currentTarget.value)}
        onClear={() => setSearchInput('')}
        placeholder={t('search')}
        size="small"
        small
        value={searchInput}
      />
      <FilterSubMenuScrollArea css={{ mx: '-$micro' }}>
        <ScrollAreaViewport css={{ maxHeight: 320 }}>
          {isSearching ? (
            <TopicsSearchResult
              isDisabled={isUnclassifiedChecked}
              isThemeChecked={getThemeCheckedState}
              isTopicChecked={isTopicChecked}
              onThemeCheck={theme => checkedState => onThemeCheck(checkedState, theme)}
              onTopicCheck={topicId => checkedState => onTopicCheck(checkedState, topicId)}
              searchQuery={searchInput}
              themes={filteredThemes}
            />
          ) : (
            <FlexContainer direction="column">
              {!hideUnclassified && (
                <Checkbox
                  checked={isUnclassifiedChecked}
                  css={{ ml: 8, mb: '$nano' }}
                  onCheckedChange={onUnclassifiedCheck}
                  text={t('unclassified')}
                  value="filter-unclassified"
                />
              )}
              {filteredThemes.map(theme => (
                <FilterSubMenu
                  css={{ py: '$nano', pointerEvents: isUnclassifiedChecked ? 'none' : 'unset' }}
                  customTrigger={
                    <Checkbox
                      checked={getThemeCheckedState(theme)}
                      disabled={isUnclassifiedChecked}
                      onCheckedChange={checkedState => onThemeCheck(checkedState, theme)}
                      onClick={e => {
                        e.stopPropagation()
                      }}
                      text={theme.themeName}
                      value={'filter-' + theme.themeId ?? ''}
                    />
                  }
                  isDisabled={!hasChanges}
                  key={theme.themeId}
                  onApply={onApply}
                  previousTitle={itemTitle}
                  title={theme.themeName}
                >
                  <SubTopicList
                    isTopicChecked={isTopicChecked}
                    onTopicCheck={onTopicCheck}
                    theme={theme}
                  />
                </FilterSubMenu>
              ))}
            </FlexContainer>
          )}
        </ScrollAreaViewport>
        <ScrollAreaScrollbar orientation="vertical">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </FilterSubMenuScrollArea>
    </>
  )
}

export default TopicsFilterContent
