import { useMemo } from 'react'

import { FilterString } from '@/types/filters/Filters'
import { NEW_FEEDBACK_INTENTION_KEY } from '@/utils/filters'
import FilterItem from '../../AppliedFilters/FilterItem'
import AppliedFeedbackIntentionFiltersEdit from './AppliedFeedbackIntentionFiltersEditNew'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import {
  AppliedFiltersDefaultProps,
  defaultAppliedFilterProps,
  disabledAreaFilterTooltipText
} from '../AppliedFilters.types'
import useInterestAreaFilterSpliter from '@/hooks/filters/useInterestAreaFilterSpliter'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'

function AppliedFeedbackIntentionFilters(
  props: AppliedFiltersDefaultProps = defaultAppliedFilterProps
) {
  const { splitMode } = props
  const apply = useFeedFiltersStore(state => state.apply)
  const removeStringFilter = useFeedFiltersStore(state => state.removeStringFilter)
  const { stringFilters } = useInterestAreaFilterSpliter({ mode: splitMode })
  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const disabled = currentInterestArea && splitMode === 'onlyAreaOfInterestFilters'

  const appliedFilters = useMemo(() => {
    return stringFilters.filter(
      filter => filter.key === NEW_FEEDBACK_INTENTION_KEY && filter.selected.length > 0
    )
  }, [stringFilters])

  function removeFilter(filter: FilterString) {
    filter.selected.forEach(option => removeStringFilter(filter.key, option))
    apply()
  }

  return appliedFilters.map(filter => (
    <FilterItem
      disabled={disabled}
      disabledTooltipText={disabledAreaFilterTooltipText}
      editButton={<AppliedFeedbackIntentionFiltersEdit buttonDisabled={disabled} filter={filter} />}
      key={filter.key}
      name="Feedback intention"
      onDelete={() => removeFilter(filter)}
    />
  ))
}

export default AppliedFeedbackIntentionFilters
