import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { endDateParam, startDateParam } from '@/utils/date'
import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'

const useAppliedDate = () => {
  const { dateRange, datePeriod } = useDateFilterStore(
    state => ({
      dateRange: state.dateRange
        ? { start: startDateParam(state.dateRange.start), end: endDateParam(state.dateRange.end) }
        : null,
      datePeriod: state.datePeriod
    }),
    shallow
  )

  const startDate = useMemo(() => {
    if (datePeriod !== 'allTime' && dateRange) return dateRange.start
    return undefined
  }, [datePeriod, dateRange])

  const endDate = useMemo(() => {
    if (datePeriod !== 'allTime' && dateRange) return dateRange.end
    return undefined
  }, [datePeriod, dateRange])

  return {
    dateRange,
    datePeriod,
    startDate,
    endDate
  }
}

export default useAppliedDate
