import Warning from '@/components/atoms/warning'

interface ErrorHandlerProps {
  onRetry: () => void
}

function ErrorHandler({ onRetry }: ErrorHandlerProps) {
  return (
    <Warning
      description={
        <>
          Click{' '}
          <span onClick={onRetry} role="button">
            here
          </span>{' '}
          to retry.
        </>
      }
      title="Failed to load this data"
    />
  )
}

export default ErrorHandler
