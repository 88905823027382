import TopicService from '@/services/TopicService'
import { useFiltersStore } from '@/store'
import { TopicData } from '@/types/classification'
import { TopicStatsPayload } from '@/types/classification/TopicRequests'
import { FilterTopicOption } from '@/types/filters/Filters'
import { cloneObject } from '@/utils/object'
import { QueryFunctionContext, useInfiniteQuery } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import enableAutoCompleteSearch from './enableAutoCompleteSearch'

interface Params {
  searchQuery?: string
  enabled?: boolean
}

const useUngroupedTopicsFilters = ({ searchQuery = '', enabled = true }: Params) => {
  const { tempUngroupedTopics, appliedUngroupedTopics } = useFiltersStore(state => ({
    tempUngroupedTopics: state.ungroupedTopics.draftSelected,
    appliedUngroupedTopics: state.ungroupedTopics.selected
  }))

  const selectUngroupedTopics = useFiltersStore(state => state.selectUngroupedTopics)
  const unselectUngroupedTopics = useFiltersStore(state => state.unselectUngroupedTopics)
  const applyUngroupedTopics = useFiltersStore(state => state.applyUngroupedTopics)

  const loadTopicsFilters = useCallback(async function (
    params: QueryFunctionContext<[string, { searchText: string }], number>
  ) {
    const { queryKey, pageParam } = params
    const { searchText } = queryKey[1]

    const pageSize = 25
    const currentPage = pageParam || 0
    const offset = pageSize * currentPage

    const payload: TopicStatsPayload = cloneObject(TopicService.DEFAULT_PAYLOAD)
    payload.sentiment_count = false
    payload.search_text = enableAutoCompleteSearch(searchText)
    payload.return_all = false
    payload.size = pageSize
    payload.offset = offset
    payload.feedback_search_schema.size = pageSize
    payload.feedback_search_schema.offset = offset

    const [error, responseData] = await TopicService.ungroupedTopics(payload)

    if (error) {
      // addErrorToast({ text: `Failed to load topic groups. Error key: ${error.key}` })
      throw error
    }

    const { items } = responseData
    const loadedAll = !items.length || items.length < pageSize

    const newPage = loadedAll ? undefined : currentPage + 1

    return {
      topics: items,
      page: newPage
    }
  }, [])

  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ['topics-filters', { searchText: searchQuery }],
    queryFn: loadTopicsFilters,
    getNextPageParam: lastPage => lastPage?.page,
    retry: false,
    refetchOnMount: true,
    enabled
  })

  const topics = useMemo(() => {
    if (!data) return []
    const _topics = data.pages.flatMap(page => page.topics)
    const notFoundTopics = tempUngroupedTopics
      .filter(tempTopic => !_topics.find(topic => topic.topicId === tempTopic.id))
      .map(
        tempTopic =>
          ({
            topicId: tempTopic.id,
            topicName: tempTopic.text,
            totalFrequency: 0,
            sentiment: {
              positiveCount: 0,
              negativeCount: 0,
              neutralCount: 0,
              positivePercentage: 0,
              negativePercentage: 0,
              neutralPercentage: 0,
              netSentiment: 0
            }
          }) as TopicData
      )

    return [...notFoundTopics, ..._topics]
  }, [data, tempUngroupedTopics])

  const isUsingSearch = searchQuery.length > 0
  const isLoadingSearch = isUsingSearch && isLoading

  const isShowMoreDisabled = isLoading || isFetchingNextPage

  const hasChanges = useMemo(
    () => JSON.stringify(appliedUngroupedTopics) !== JSON.stringify(tempUngroupedTopics),
    [appliedUngroupedTopics, tempUngroupedTopics]
  )

  const isTopicChecked = (topicId: string) => {
    return !!tempUngroupedTopics.find(topic => topic.id === topicId)
  }

  const onCheckUngroupedTopic = (checked: boolean, topic: TopicData) => {
    checked
      ? selectUngroupedTopics({ topics: [{ id: topic.topicId, text: topic.topicName }] })
      : unselectUngroupedTopics({ topics: [{ id: topic.topicId, text: topic.topicName }] })
  }

  const onCheckUngroupedTopics = (checked: boolean, topics: FilterTopicOption[]) => {
    checked ? selectUngroupedTopics({ topics }) : unselectUngroupedTopics({ topics })
  }

  return {
    topics,
    isLoading,
    isLoadingSearch,
    isUsingSearch,
    hasNextPage,
    isFetchingNextPage,
    isShowMoreDisabled,
    hasChanges,
    apply: applyUngroupedTopics,
    fetchNextPage,
    isTopicChecked,
    onCheckUngroupedTopic,
    onCheckUngroupedTopics
  }
}

export default useUngroupedTopicsFilters
