import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'

import useClassification from '@/hooks/useClassification'
import { useFiltersStore } from '@/store'
import { FilterTopicOption } from '@/types/filters/Filters'
import FilterItem, { FilterItemDropdownTopic } from '../FilterItem'

function AppliedOtherTopicFilters() {
  const apply = useFiltersStore(state => state.apply)
  const otherThemes = useFiltersStore(state => state.otherThemes.selected, shallow)
  const otherTopics = useFiltersStore(state => state.otherTopics.selected, shallow)
  const setDraftOtherTopics = useFiltersStore(state => state.setDraftOtherTopics)
  const toggleUnclassifiedTopics = useFiltersStore(state => state.toggleUnclassifiedTopics)
  const unclassifiedTopics = useFiltersStore(state => state.unclassifiedTopics.selected, shallow)

  const { themes, getTopicById } = useClassification()

  const appliedFilters = useMemo(() => {
    if (unclassifiedTopics.includes('OTHER')) {
      return [{ id: 'OTHER', text: 'Unclassified' }] as FilterTopicOption[]
    }

    const _topics = otherTopics
      .map(topicId => {
        const topic = getTopicById(topicId)
        if (topic) {
          return { id: topic.topicId, text: topic.topicName } as FilterTopicOption
        }
        return null
      })
      .filter(Boolean) as FilterTopicOption[]

    const _themes = otherThemes
      .map(themeId => {
        const theme = themes.find(theme => theme.themeId === themeId)
        if (theme) {
          return { id: theme.themeId, text: theme.themeName } as FilterTopicOption
        }
        return null
      })
      .filter(Boolean) as FilterTopicOption[]

    return [..._themes, ..._topics]
  }, [otherTopics, otherThemes, unclassifiedTopics, themes, getTopicById])

  function onRemoveOtherTopics() {
    const hasUnclassified = appliedFilters.some(filter => filter.id === 'OTHER')

    if (hasUnclassified) {
      toggleUnclassifiedTopics('OTHER')
    } else {
      setDraftOtherTopics('theme', [])
      setDraftOtherTopics('topic', [])
    }

    apply()
  }

  return [
    appliedFilters.length > 0 ? (
      <FilterItem
        editButton={<FilterItemDropdownTopic category="OTHER" filter={appliedFilters} />}
        name="Other"
        onDelete={onRemoveOtherTopics}
        key="other-topics"
      />
    ) : null
  ].filter(Boolean) as JSX.Element[]
}

export default AppliedOtherTopicFilters
