import parse from 'html-react-parser'
import DOMPurify from 'dompurify'
import { useCallback } from 'react'

function useParse() {
  const parseAndSanitize = useCallback((dirty: string) => {
    return parse(DOMPurify.sanitize(dirty, { ADD_ATTR: ['target'] }))
  }, [])

  const parseAndSanitizeArray = useCallback(
    (array: React.ReactNodeArray): React.ReactNode[] => {
      return array.flatMap(element => {
        if (typeof element !== 'string') return element
        return parseAndSanitize(element)
      })
    },
    [parseAndSanitize]
  )

  const parseLinks = useCallback((value: string, contentTemplate = '$1') => {
    const urlRegex = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim

    return value.replace(
      urlRegex,
      `<a target="_blank" href="$1" rel="noreferrer">${contentTemplate}</a>`
    )
  }, [])

  return { parseAndSanitize, parseAndSanitizeArray, parseLinks }
}

export default useParse
