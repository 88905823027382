import { FilterComponentProps } from './types'
import { ChipMultiSelectItem } from '@/components/molecules/chip-multi-select'
import { INTENTION_LIST, intentionFeedQueryParam, intentionIcons } from '@/utils/intention'
import { capitalizeFirst } from '@/utils/letterCase'
import EnumFilterRow from './EnumFilterRow'

const options: ChipMultiSelectItem[] = INTENTION_LIST.map(intention => ({
  label: capitalizeFirst(intention),
  value: intentionFeedQueryParam[intention],
  icon: intentionIcons[intention]
}))

const IntentionFilterRow = (props: FilterComponentProps) => (
  <EnumFilterRow {...props} options={options} />
)

export default IntentionFilterRow
