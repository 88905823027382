import { useTranslation } from 'react-i18next'
import OrganizationStyles from './OrganizationSettings.styles'
import Text from '@/components/atoms/text'
import useUser from '@/hooks/useUser'
import FlexContainer from '@/components/atoms/flex-container'
import Input from '@/components/atoms/input'
import IconButton from '@/components/atoms/icon-button'
import { CopySimple } from '@phosphor-icons/react'
import useCopyToClipboard from '@/hooks/useCopyToClipboard'
import { useEffect } from 'react'
import useToastMessageStore from '@/store/useToastMessageStore'
import i18n from '@/plugins/i18n/i18n'

const mapLanguage: Record<string, string> = {
  'pt-br': i18n.t('portugueseBrazil'),
  en: i18n.t('english'),
  es: i18n.t('spanishSpain')
}

const OrganizationDetails = () => {
  const { t } = useTranslation()
  const { organization } = useUser()

  const [copyToClipboard, status, reset] = useCopyToClipboard()
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  useEffect(() => {
    if (status === 'copied') {
      addSuccessToast({
        text: t('copiedToClipboard'),
        onClose: reset
      })
    } else if (status === 'error') {
      addErrorToast({
        text: t('failedToCopyToClipboard'),
        onClose: reset
      })
    }
  }, [addSuccessToast, addErrorToast, reset, t, status])

  if (!organization) return <></>

  return (
    <OrganizationStyles.Card>
      <OrganizationStyles.CardHeader as="h3">
        {t('organizationDetailsLabel')}
      </OrganizationStyles.CardHeader>

      <FlexContainer gap="xxs" direction="column">
        <OrganizationStyles.Row>
          <OrganizationStyles.ColText> {t('organizationNameLabel')}</OrganizationStyles.ColText>
          <Input disabled value={organization.name} />
        </OrganizationStyles.Row>

        <OrganizationStyles.Row>
          <FlexContainer direction={'column'}>
            <OrganizationStyles.ColText>{t('targetLanguage')}</OrganizationStyles.ColText>
            <OrganizationStyles.ColText description>
              {t('appliedToAllInfoInPlatform')}
            </OrganizationStyles.ColText>
          </FlexContainer>
          <Input
            disabled
            value={mapLanguage[organization.targetLanguage?.toLowerCase() ?? ''] ?? ''}
          />
        </OrganizationStyles.Row>
        <OrganizationStyles.Row>
          <FlexContainer direction={'column'}>
            <OrganizationStyles.ColText>{t('organizationIDLabel')}</OrganizationStyles.ColText>
            <OrganizationStyles.ColText description>
              {t('uniqueOrganizationID')}
            </OrganizationStyles.ColText>
          </FlexContainer>
          <OrganizationStyles.OrgIdContainer>
            <Text as="p" typeface="subtitleRegularXXXS">
              {organization.organizationId}
            </Text>

            <IconButton onClick={() => copyToClipboard(organization.organizationId)}>
              <CopySimple size={16} />
            </IconButton>
          </OrganizationStyles.OrgIdContainer>
        </OrganizationStyles.Row>
      </FlexContainer>
    </OrganizationStyles.Card>
  )
}

export default OrganizationDetails
