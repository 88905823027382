import FilterSubMenu from '../AddFilterMenu/FilterSubMenu'

import { ReactNode, useState } from 'react'
import { NumericRangeOption } from '@/types/filters/Filters'
import useNumericGenericFilter from './useNumericGenericFilter'
import NumericFilterContent from './NumericFilterContent'

const defaultLabels: Record<NumericRangeOption, string> = {
  all: 'All values',
  between: 'Range',
  is: 'Exactly value',
  null: 'Empty values'
}

export interface NumericFilterSectionProps {
  title: string
  type: string
  name: string
  filterKey: string
  labels?: Record<NumericRangeOption, string>
  icon?: ReactNode
}

const NumericFilterSubMenu = ({
  title,
  type,
  name,
  filterKey,
  icon,
  labels = defaultLabels
}: NumericFilterSectionProps) => {
  const [open, setOpen] = useState(false)

  const {
    exactValue,
    exactValueText,
    isDisabled,
    isExactValueOpen,
    isLoading,
    isRangeOpen,
    max,
    min,
    onApply,
    onOptionChange,
    onRangeChange,
    onValueChange,
    rangeText,
    rangeValue,
    selectedOption
  } = useNumericGenericFilter({ filterKey, type, name, enabled: open })

  return (
    <FilterSubMenu
      icon={icon}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onApply={onApply}
      onOpenChange={setOpen}
      open={open}
      title={title}
    >
      <NumericFilterContent
        exactValue={exactValue}
        exactValueText={exactValueText}
        isExactValueOpen={isExactValueOpen}
        isRangeOpen={isRangeOpen}
        labels={labels}
        max={max}
        min={min}
        onOptionChange={onOptionChange}
        onRangeChange={onRangeChange}
        onValueChange={onValueChange}
        rangeText={rangeText}
        rangeValue={rangeValue}
        selectedOption={selectedOption}
      />
    </FilterSubMenu>
  )
}

export default NumericFilterSubMenu
