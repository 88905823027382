import SlackLogo from '@/assets/logos/slack.png'
import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { colors, styled } from '@/theme'
import { CheckCircle } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'

const Banner = styled('div', {
  background: '$feedbackWarningMedium',
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  gap: '$xxs',
  padding: '$xxs',

  variants: {
    card: {
      true: {
        bAll: '$neutralHighPure',
        background: '$neutralHighLight',
        '&:hover': {
          bAll: '$brandPrimaryPure'
        }
      }
    },
    isConnected: {
      true: {
        cursor: 'pointer'
      }
    }
  }
})

const ConnectButton = styled(Button, {
  background: 'transparent !important',
  ml: 'auto',

  '&:hover': {
    background: '$feedbackWarningMedium !important'
  },

  variants: {
    card: {
      true: {
        '&:hover': {
          background: '$transparent !important'
        }
      }
    }
  }
})

interface Props {
  asCard?: boolean
  isConnected?: boolean
  onClickCard?: () => void
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const SlackBanner = ({ asCard, isConnected, onClickCard, onClick }: Props) => {
  const { t } = useTranslation()
  return (
    <Banner card={asCard} isConnected={isConnected} onClick={onClickCard}>
      <img alt="Slack logo" height={32} src={SlackLogo} width={32} />
      <FlexContainer css={{ mt: '$nano' }} direction="column" justifyContent="center">
        <Text as="h3" typeface="titleBoldXXS">
          {t('receiveNotificationsViaSlack')}
        </Text>
        <Text as="p" color="neutralLowMedium" typeface="paragraphRegularMicro">
          {t('stayInformedAboutYourSavedFiltersDirectlyOnYourSlackChannels')}
        </Text>
      </FlexContainer>
      {isConnected ? (
        <>
          <FlexContainer
            alignItems="center"
            css={{ width: 182, py: '$xxxs', ml: 'auto' }}
            gap="micro"
          >
            <CheckCircle color={colors.brandPrimaryPure} size={16} />
            <Text color="brandPrimaryPure" fontSize="xxxs" fontWeight="semibold">
              {t('connected')}
            </Text>
          </FlexContainer>
        </>
      ) : (
        <ConnectButton card={asCard} onClick={onClick} variant="secondary">
          {t('connectToSlack')}
        </ConnectButton>
      )}
    </Banner>
  )
}

export default SlackBanner
