import { CSS } from '@/theme'

export const OptionsMenuButton: CSS = {
  all: 'unset',
  borderRadius: '50%',
  color: '$neutralLowPure',
  cursor: 'pointer',
  size: 24,
  transition: 'background-color .3s',

  '&:hover': {
    backgroundColor: '$brandPrimaryLight',
    color: '$brandPrimaryPure'
  },

  '&[data-state=open]': {
    backgroundColor: '$brandPrimaryPure',
    color: '$neutralHighLight'
  },

  '& svg': {
    size: '100%'
  }
}
