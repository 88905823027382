import { styled } from '@/theme'

export const RedirectContainer = styled('main', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw'
})

export const RedirectText = styled('p', {
  marginTop: '20px',

  fontSize: '24px',
  fontWeight: '500'
})
