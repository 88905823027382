import Button from '@/components/atoms/button'
import Tooltip from '@/components/atoms/tooltip'
import { CSS } from '@/theme'
import { useState } from 'react'
import { InputContainer, StyledInput } from './styles'

interface NameInputProps {
  onCancel: () => void
  onConfirm: (value: string) => void
  css?: CSS
  placeholder?: string
  initialValue?: string
  confirmLabel?: string
  withoutButtons?: boolean
}

const NameInput = ({
  onCancel,
  onConfirm,
  css,
  initialValue = '',
  confirmLabel = 'Add',
  placeholder = 'Type the name',
  withoutButtons = false
}: NameInputProps) => {
  const [input, setInput] = useState(initialValue)

  const onKeyUp: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      onConfirm(input)
    }

    if (e.key === 'Escape') {
      onCancel()
    }
  }

  const tooltipOpen = input.length > 0 && withoutButtons

  const onCancelClick = () => {
    onCancel()
  }

  const onAddClick = () => {
    onConfirm(input)
  }

  return (
    <Tooltip open={tooltipOpen} side="bottom" text="Press enter to confirm or esc to cancel">
      <InputContainer css={css}>
        <StyledInput
          autoFocus
          onChange={e => setInput(e.currentTarget.value)}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          value={input}
        />
        {!withoutButtons && (
          <>
            <Button onClick={onCancelClick} small text>
              Cancel
            </Button>
            <Button onClick={onAddClick} small>
              {confirmLabel}
            </Button>
          </>
        )}
      </InputContainer>
    </Tooltip>
  )
}

export default NameInput
