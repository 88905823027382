import useAreaOfInterest from '@/hooks/areaOfInterest/useAreaOfInterest'
import useSavedFilters from '@/hooks/useSavedFilters'
import useSegment from '@/hooks/useSegment'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import { AreaOfInterestData } from '@/types/area/AreaOfInterest'
import { useCallback, useState } from 'react'

const useAreaOfInterestOptions = () => {
  const { track } = useSegment()

  const setCurrentArea = useCurrentInterestAreaStore(state => state.setCurrentInterestArea)
  const currentArea = useCurrentInterestAreaStore(state => state.currentInterestArea)

  const resetAllFilters = useFeedFiltersStore(state => state.resetAll)

  const { onStartBlankFilter } = useSavedFilters({ newFeed: true })

  const { updateUseInUnmapped, deleteArea, isDeleting, renameArea } = useAreaOfInterest()

  const onClickUpdateUseInUnmapped = useCallback(
    (area: AreaOfInterestData) => {
      updateUseInUnmapped({ area, newUseInUnmapped: !area.useInUnmappedArea })
    },
    [updateUseInUnmapped]
  )

  const [areaToDelete, setAreaToDelete] = useState<string | null>(null)

  const onDeleteAreaClick = useCallback((area: AreaOfInterestData) => {
    setAreaToDelete(area.id)
  }, [])

  const onConfirmDelete = () => {
    if (areaToDelete) {
      if (currentArea?.id === areaToDelete) {
        setCurrentArea(undefined)
        onStartBlankFilter(true)
        resetAllFilters({ keepDate: true })
      }

      deleteArea(areaToDelete, {
        onSuccess: () => {
          track('area_deleted')
        }
      })
    }
  }

  const onOpenDeleteChange = (open: boolean) => {
    setAreaToDelete(prevArea => (open ? prevArea : null))
  }

  const isDeleteModalOpen = !!areaToDelete

  const [areaToRename, setAreaToRename] = useState<AreaOfInterestData | null>(null)

  const onRenameAreaClick = useCallback((area: AreaOfInterestData) => {
    setAreaToRename(area)
  }, [])

  const onConfirmRename = (newName: string) => {
    if (areaToRename) {
      renameArea({ area: areaToRename, newName })
      setAreaToRename(null)
      if (currentArea?.id === areaToRename.id) {
        setCurrentArea({ ...currentArea, name: newName })
      }
    }
  }

  const onOpenRenameChange = (open: boolean) => {
    setAreaToRename(prevArea => (open ? prevArea : null))
  }

  const isRenameModalOpen = !!areaToRename

  return {
    onClickUpdateUseInUnmapped,
    isDeleting,
    onConfirmDelete,
    onOpenDeleteChange,
    isDeleteModalOpen,
    onDeleteAreaClick,
    onRenameAreaClick,
    onConfirmRename,
    onOpenRenameChange,
    isRenameModalOpen,
    areaToRename
  }
}

export default useAreaOfInterestOptions
