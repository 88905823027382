import useAllMetrics from '@/hooks/metrics/useAllMetricItems'
import useMetricListPayload from '@/hooks/metrics/useMetricListPayload'
import useSortingMetric from '@/hooks/metrics/useSortingMetric'
import useAppliedDate from '@/hooks/useAppliedDate'
import useAnalysisHubState from '@/store/useAnalysisHubStore'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import { RawAreaError } from '@/types/area/AreaRequests'
import {
  OpportunityItemWithMergedContext,
  OpportunityItemWithMetrics
} from '@/types/opportunity/Opportunity'
import { INVALID_METRIC_TABLE_COLUMNS, sortByOpportunityStatus } from '@/utils/opportunityUtils'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useMemo, useRef, useState } from 'react'
import getMetricToSortOpportunitesFn from './getMetricToSortOpportunitesFn'
import { useTranslation } from 'react-i18next'
import useToastMessageStore from '@/store/useToastMessageStore'
import useLogging from '@/hooks/useLogging'
import getAllMetricsForOpportunitiesFn from './getAllMetricsForOpportunitiesFn'
import { queryClient } from '@/plugins/reactQueryClient'
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'

const PAGE_SIZE = 10
const PROGRESS_STEP_SIZE = 100 / 3

export const OPPORTUNITIES_SORTING_METRIC_KEY_PREFIX = 'sort-all-opportunities'
export const OPPORTUNITIES_ALL_METRICS_KEY_PREFIX = 'get-all-metrics-for-opportunity'

interface Params {
  opportunities: (OpportunityItemWithMetrics | OpportunityItemWithMergedContext)[]
  enabled?: boolean
  sortColumn?: string
  sortDirection?: 'asc' | 'desc'
}

const useOpportunitiesMetricsQuery = ({
  opportunities,
  enabled = true,
  sortColumn = 'count',
  sortDirection = 'desc'
}: Params) => {
  const { t } = useTranslation()

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { logException } = useLogging({ context: 'opportunities-metrics-query' })

  const progress = useAnalysisHubState(state => state.opportunitiesProgress)
  const setProgress = useAnalysisHubState(state => state.setOpportunitiesProgress)
  const loadStep = useAnalysisHubState(state => state.opportunitiesLoadStep)
  const setLoadStep = useAnalysisHubState(state => state.setOpportunitiesLoadStep)

  const allowedMetricsBySource = useHiddenMetricsStore(state => state.allowedMetricsBySource)

  const { allMetricItems } = useAllMetrics()

  const { dateRange, datePeriod, startDate, endDate } = useAppliedDate()

  const { addShareFiltersToMetrics, getMetricList } = useMetricListPayload()

  const metricList = useMemo(() => {
    return addShareFiltersToMetrics(
      getMetricList({ includePreviousValue: false, metricFor: 'opportunity' })
    )
  }, [getMetricList, addShareFiltersToMetrics])

  const [currentPage, setCurrentPage] = useState(0)

  const mapMergedAreasErrorById = useRef<Record<string, RawAreaError>>({})

  const sortingMetric = useSortingMetric(sortColumn)

  /**
   * Used only to get the sorting metric data and create a new opps list with the sorting metric (not sorted yet)
   */
  const sortingMetricQueryKey = useMemo(
    () => [
      OPPORTUNITIES_SORTING_METRIC_KEY_PREFIX,
      { opportunities, datePeriod, dateRange, sortingMetric }
    ],
    [opportunities, datePeriod, dateRange, sortingMetric]
  )

  const isSortingMetricQueryEnabled = useMemo(() => {
    return (
      enabled &&
      opportunities &&
      opportunities.length > 0 &&
      !INVALID_METRIC_TABLE_COLUMNS.includes(sortColumn ?? 'count')
    )
  }, [enabled, opportunities, sortColumn])

  const {
    data: allOpportunitiesWithSortMetric,
    isLoading: isLoadingAllOpportunitiesWithSortMetric
  } = useQuery({
    queryKey: sortingMetricQueryKey,
    queryFn: async () => {
      if (!opportunities) return []

      setLoadStep('ordering')
      setCurrentPage(0)
      setProgress(PROGRESS_STEP_SIZE)

      const [error, data] = await getMetricToSortOpportunitesFn({
        opps: opportunities,
        sortingMetric,
        errorMap: mapMergedAreasErrorById.current,
        startDate,
        endDate,
        allMetricItems
      })

      if (error) {
        logException(error, {
          message: `Failed to fetch metric "${sortingMetric.name}/${sortingMetric.label}" to sort opportunities`,
          tags: {
            sortingMetricName: sortingMetric.name,
            sortingMetricLabel: sortingMetric.label,
            startDate: startDate ?? '',
            endDate: endDate ?? ''
          }
        })
        addErrorToast({ text: t('fetchOpportunitiesSortingMetricErrorMessage') })
        setLoadStep('done')
        setProgress(0)
        throw error
      }

      setProgress(PROGRESS_STEP_SIZE * 2)

      return data
    },
    enabled: isSortingMetricQueryEnabled
  })

  /**
   * Since we have the all necessary data to sorting here (using a sorting metric or opp column)
   * sorting is made only here using the front, no need to refetch sorting metric or list on changes
   */
  const opportunitiesSorted = useMemo(() => {
    let newOpps: OpportunityItemWithMetrics[] = []

    if (INVALID_METRIC_TABLE_COLUMNS.includes(sortColumn ?? 'count')) {
      if (!opportunities.length) return newOpps
      newOpps = [...opportunities]

      if (sortColumn === 'name') {
        newOpps.sort((a, b) =>
          sortDirection === 'desc' ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name)
        )
      } else if (sortColumn === 'status') {
        newOpps.sort((a, b) =>
          sortDirection === 'desc'
            ? sortByOpportunityStatus(b.status) - sortByOpportunityStatus(a.status)
            : sortByOpportunityStatus(a.status) - sortByOpportunityStatus(b.status)
        )
      }
    } else {
      if (!allOpportunitiesWithSortMetric?.length) return newOpps

      newOpps = [...allOpportunitiesWithSortMetric]

      newOpps.sort((a, b) => {
        const valueA =
          a.metrics.find(metric => metric.label === sortingMetric.label)?.current_value ?? 0
        const valueB =
          b.metrics.find(metric => metric.label === sortingMetric.label)?.current_value ?? 0

        return sortDirection === 'desc' ? valueB - valueA : valueA - valueB
      })
    }

    return newOpps
  }, [sortDirection, sortColumn, sortingMetric, allOpportunitiesWithSortMetric, opportunities])

  /*
   * Use the already sorted opp list to fetch its metric list, all the metrics, by currentPage (slices)
   *  Here we care only for the order of the opp list, so use only the array of opp ids to listen to changes
   */
  const opportunitesWithAllMetricsQueryKey = useMemo(
    () => [
      OPPORTUNITIES_ALL_METRICS_KEY_PREFIX,
      { currentPage, sortedOppsIds: opportunitiesSorted.map(opp => opp.id), metricList }
    ],
    [currentPage, opportunitiesSorted, metricList]
  )

  const isOpportunitiesWithAllMetricsQueryEnabled = useMemo(
    () => opportunitiesSorted.length > 0,
    [opportunitiesSorted]
  )

  const { data: opportunitiesWithAllMetrics, isLoading: isLoadingOpportunitiesWithAllMetrics } =
    useQuery({
      queryKey: opportunitesWithAllMetricsQueryKey,
      queryFn: async () => {
        if (!opportunitiesSorted) return []

        setLoadStep('calculating')
        if (progress === 100) {
          setProgress(PROGRESS_STEP_SIZE)
        }

        const startPage = currentPage * PAGE_SIZE
        const endPage = (currentPage + 1) * PAGE_SIZE
        const currentSlice = opportunitiesSorted.slice(startPage, endPage)

        // send the currentSlice/page to get metrics
        const [error, fetchedSlice] = await getAllMetricsForOpportunitiesFn({
          opps: currentSlice,
          errorMap: mapMergedAreasErrorById.current,
          metricList,
          onProgress: (chunkLength, completed) =>
            setProgress?.(
              PROGRESS_STEP_SIZE + (PROGRESS_STEP_SIZE / (chunkLength * 2)) * completed
            ),
          startDate,
          endDate,
          onError: (error, message) => logException(error, { message })
        })

        if (error) {
          logException(error, {
            message: `Failed to fetch metrics for opportunities`,
            tags: {
              sortingMetricName: sortingMetric.name,
              sortingMetricLabel: sortingMetric.label,
              startDate: startDate ?? '',
              endDate: endDate ?? ''
            }
          })
          addErrorToast({ text: t('fetchMetricsErrorMessage') })
          setLoadStep('done')
          setProgress(0)
          throw error
        }

        setProgress(100)
        setLoadStep('done')

        const newAllOpps = [...opportunitiesSorted]
        // Substitute the current slice with the new, with all the page metrics
        newAllOpps.splice(startPage, PAGE_SIZE, ...fetchedSlice)

        // update the opp list with sorted metric, prevents the other metrics from blinking
        queryClient.setQueryData(sortingMetricQueryKey, newAllOpps)

        return newAllOpps
      },
      enabled: isOpportunitiesWithAllMetricsQueryEnabled
    })

  const loadNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1)
  }

  // the current displayed list
  const opportunitiesWithMetrics = useMemo(() => {
    let _opps: OpportunityItemWithMetrics[] = []

    if (opportunities && opportunities.length) {
      _opps = [...opportunities]
    }

    if (opportunitiesSorted && opportunitiesSorted.length) {
      _opps = [...opportunitiesSorted]
    }

    if (opportunitiesWithAllMetrics && opportunitiesWithAllMetrics.length) {
      _opps = [...opportunitiesWithAllMetrics]
    }

    return _opps.slice(0, (currentPage + 1) * PAGE_SIZE)
  }, [opportunitiesWithAllMetrics, opportunities, opportunitiesSorted, currentPage])

  const hasMore =
    opportunitiesWithAllMetrics &&
    opportunitiesWithAllMetrics.length > (currentPage + 1) * PAGE_SIZE

  const resetPage = useCallback(() => {
    setCurrentPage(0)
  }, [])

  useDidUpdateEffect(() => {
    resetPage()
  }, [allowedMetricsBySource])

  return {
    opportunitiesWithMetrics,
    isLoadingOpportunitiesWithSortMetric: isSortingMetricQueryEnabled
      ? isLoadingAllOpportunitiesWithSortMetric
      : false,
    isLoadingOpportunitiesWithAllMetrics: isOpportunitiesWithAllMetricsQueryEnabled
      ? isLoadingOpportunitiesWithAllMetrics
      : false,
    loadNextPage,
    hasMore,
    loadStep,
    progress,
    opportunitiesSorted,
    resetPage
  }
}

export default useOpportunitiesMetricsQuery
