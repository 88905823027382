import { styled } from '@/theme'

export const NotificationsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 800,
  gap: '$xxs',

  variants: {
    v2: {
      true: {
        maxWidth: 'unset',
        gap: '$sm'
      }
    }
  }
})

export const NotificationCardContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$xs',
  fontSize: '$xxxs',
  pl: 26,
  pt: '$xs',

  '.emails': {
    color: '$neutralLowLight'
  }
})
