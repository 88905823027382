import FlexContainer from '@/components/atoms/flex-container'
import BirdGrayImage from '@/assets/drawings/bird_gray.png'
import Text from '@/components/atoms/text'
import { styled } from '@/theme'
import { useTranslation, Trans } from 'react-i18next'

const ReloadButton = styled('button', {
  all: 'unset',
  color: '$brandPrimaryPure',
  cursor: 'pointer'
})

interface FeedErrorProps {
  onReload?: () => void
}

const FeedError = ({ onReload }: FeedErrorProps) => {
  const reload = onReload ?? window.location.reload
  const { t } = useTranslation()

  return (
    <FlexContainer alignItems="center" css={{ mt: 100 }} direction="column" gap="xxs">
      <img alt="A bird drawing in black and white" src={BirdGrayImage} />
      <Text typeface="subtitleSemiBoldXXXS">{t('somethingHappensWhileProcessingYourSearch')}</Text>
      <Text color="neutralLowLight" typeface="paragraphRegularMicro">
        <Trans components={{ 1: <ReloadButton onClick={reload} /> }} i18nKey="feedErrorMessage" />
      </Text>
    </FlexContainer>
  )
}

export default FeedError
