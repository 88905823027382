import NoResultsIllustration from '@/assets/drawings/no_results.png'
import Bone from '@/components/atoms/bone'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { APP_ENVIRONEMNT } from '@/config'
import { IntegrationConnector } from '@/types/integrations'
import ConnectorCard, { ConnectorCardSkeleton } from '../ConnectorCard'
import { Container, LinkButton } from './ConnectorSection.styles'
import { useTranslation } from 'react-i18next'

export interface ConnectorSectionProps {
  connectors: IntegrationConnector[]
  isLoading: boolean
  title?: string
}

function ConnectorSection(props: ConnectorSectionProps) {
  function sendMessage() {
    if (APP_ENVIRONEMNT !== 'production') return
    window.Intercom('showNewMessage')
  }
  const { t } = useTranslation()

  const hasConnectors = props.connectors.length > 0

  if (props.isLoading) {
    return (
      <FlexContainer css={{ gap: 20 }} direction="column">
        {props.title ? (
          <Bone css={{ height: 24, width: 206 }} data-testid="connector-section-skeleton-title" />
        ) : null}
        <Container>
          <ConnectorCardSkeleton />
          <ConnectorCardSkeleton />
        </Container>
      </FlexContainer>
    )
  }

  return (
    <FlexContainer css={{ gap: hasConnectors ? 20 : 32 }} direction="column">
      {props.title ? (
        <Text as="h2" typeface="titleBoldXS">
          {props.title}
        </Text>
      ) : null}
      {hasConnectors ? (
        <Container>
          {props.connectors.map(connector => (
            <ConnectorCard
              description={connector.description}
              id={connector.id}
              key={connector.id}
              logoUrl={connector.logo_url}
              multipleIntegrations={connector.metadata.multiple_extractions}
              name={connector.name}
              placeholder={connector.metadata.placeholder}
              type={connector.metadata.data_type}
            />
          ))}
        </Container>
      ) : (
        <FlexContainer alignItems="center" direction="column" gap="sm">
          <img
            alt="A black and white image of a bird looking at empty boxes."
            height={221}
            src={NoResultsIllustration}
            width={352}
          />
          <Text css={{ fontSize: '$xxs', lineHeight: '$lg', maxWidth: 540, textAlign: 'center' }}>
            {t('itLooksLikeWeDontHaveThisIntegrationYetReachOutToYourCSMOr')}
            <LinkButton onClick={sendMessage} type="button">
              {t('sendUsAMessage')}
            </LinkButton>
            {t('forItAndStayTunedForUpdates')}
          </Text>
        </FlexContainer>
      )}
    </FlexContainer>
  )
}

export default ConnectorSection
