import { styled, theme } from '@/theme'
import { VariantProps } from '@stitches/react'

type SpaceKeys = keyof typeof theme.space
type GapVariant = {
  [x in SpaceKeys]: { gap: string }
}
const gapVariant: GapVariant = Object.keys(theme.space).reduce((acc, space) => {
  return { ...acc, [space]: { gap: `$${space}` } }
}, {} as GapVariant)

const FlexContainer = styled('div', {
  display: 'flex',
  boxSizing: 'border-box',

  a: {
    textDecoration: 'none',
    color: 'inherit'
  },

  variants: {
    direction: {
      row: { flexDirection: 'row' },
      rowReverse: { flexDirection: 'row-reverse' },
      column: { flexDirection: 'column' },
      columnReverse: { flexDirection: 'column-reverse' }
    },
    alignItems: {
      stretch: { alignItems: 'stretch' },
      start: { alignItems: 'flex-start' },
      end: { alignItems: 'flex-end' },
      center: { alignItems: 'center' },
      baseline: { alignItems: 'baseline' }
    },
    justifyContent: {
      center: { justifyContent: 'center' },
      start: { justifyContent: 'flex-start' },
      end: { justifyContent: 'flex-end' },
      spaceAround: { justifyContent: 'space-around' },
      spaceBetween: { justifyContent: 'space-between' },
      spaceEvenly: { justifyContent: 'space-evenly' },
      stretch: { justifyContent: 'stretch' },
      flexStart: { justifyContent: 'flex-start' },
      flexEnd: { justifyContent: 'flex-end' }
    },
    alignSelf: {
      center: { alignSelf: 'center' },
      end: { alignSelf: 'flex-end' },
      start: { alignSelf: 'flex-start' },
      stretch: { alignSelf: 'stretch' },
      baseline: { alignSelf: 'baseline' },
      auto: { alignSelf: 'auto' }
    },
    wrap: {
      nowrap: { flexWrap: 'nowrap' },
      wrap: { flexWrap: 'wrap' },
      wrapReverse: { flexWrap: 'wrap-reverse' }
    },
    flow: {
      wrap: { flexFlow: 'wrap' },
      nowrap: { flexFlow: 'nowrap' },
      column: { flexFlow: 'column' },
      columnReverse: { flexFlow: 'column-reverse' },
      row: { flexFlow: 'row' },
      rowReverse: { flexFlow: 'row-reverse' }
    },
    shrink: {
      0: { flexShrink: 0 }
    },
    boxSizing: {
      borderBox: { boxSizing: 'border-box' },
      contentBox: { boxSizing: 'content-box' }
    },
    fullHeight: {
      true: { height: '100%' },
      false: { height: 'auto' }
    },
    fullWidth: {
      true: { width: '100%' },
      false: { height: 'auto' }
    },
    gap: gapVariant
  }
})

export type FlexContainerProps = React.ComponentProps<typeof FlexContainer> & {
  as?: React.ElementType
}

export type FlexVariants = VariantProps<typeof FlexContainer>

export default FlexContainer
