import {
  AccountData,
  AccountRequestItem,
  AccountResult,
  AccountsResponse,
  CustomerTypesResponse,
  GetAudiencePayload,
  IndustriesResponse,
  PlansResponse,
  SalesPipelineStagesResponse
} from '@/types/manage-customers'

import BirdieRequest from './RequestHandlers/BirdieRequest'
import { BaseRequestReturnType } from './RequestHandlers/BaseRequestHandler'
import { UsersRequestItem, UsersResponse } from '@/types/manage-customers/UsersRequests'
import { CustomerRecordsUserData, UsersResponseData } from '@/types/manage-customers/Users'
import {
  CustomerRecordsFieldsResponse,
  CustomerRecordsFieldsValueResponse
} from '@/types/manage-customers/CustomerRecordsFilters'

const PREFIX = '/audience'

/**
 * Manage customers/Customer records > accounts and users
 */
export default class AudienceService {
  static parseAccountItemToData = (account: AccountRequestItem): AccountData => {
    const {
      id,
      name,
      ingested_id: ingestedId,
      updated_at: updatedAt,
      customer_type: customerType,
      custom_fields: customFields,
      ...otherFields
    } = account
    return {
      id,
      name,
      ingestedId,
      ingestedAt: account.ingested_at,
      importedAt: account.ingested_at || updatedAt,
      updatedAt,
      customerType: customerType || 'other',
      customFields: customFields || {},
      otherFields
    }
  }

  static parseUserItemToData = (user: UsersRequestItem): CustomerRecordsUserData => {
    const {
      id,
      name,
      updated_at: updatedAt,
      ingested_id: ingestedId,
      custom_fields: customFields,
      ...otherFields
    } = user
    return {
      id,
      name,
      email: user.email,
      ingestedId,
      accountId: user.account_id,
      updatedAt,
      importedAt: user.ingested_at || updatedAt,
      ingestedAt: user.ingested_at,
      customFields,
      otherFields
    }
  }

  static async getAccounts(
    params: GetAudiencePayload & { raw?: false },
    signal?: AbortSignal
  ): Promise<BaseRequestReturnType<AccountResult>>

  static async getAccounts(
    params: GetAudiencePayload & { raw: true },
    signal?: AbortSignal
  ): Promise<
    BaseRequestReturnType<{
      total: number
      accounts: AccountRequestItem[]
      nextPage?: string
      prevPage?: string
    }>
  >

  static async getAccounts(
    params: GetAudiencePayload & { raw?: boolean },
    signal?: AbortSignal
  ): Promise<
    BaseRequestReturnType<{
      total: number
      accounts: AccountData[] | AccountRequestItem[]
      nextPage?: string
      prevPage?: string
    }>
  > {
    const { orgId, ...rest } = params
    const [error, data] = await BirdieRequest.get<AccountsResponse>(`${PREFIX}/accounts`, rest, {
      signal,
      headers: {
        'Birdie-Organization-Id': orgId
      },
      paramsSerializer: {
        indexes: null
      }
    })

    if (error) return [error, undefined]

    const accounts: AccountData[] | AccountRequestItem[] = params.raw
      ? data.accounts
      : data.accounts.map(this.parseAccountItemToData)

    return [
      undefined,
      {
        total: data.total,
        accounts,
        nextPage: data.next_page,
        prevPage: data.prev_page
      }
    ]
  }

  static async getAccountsFiltersFields(params: {
    signal?: AbortSignal
  }): Promise<BaseRequestReturnType<CustomerRecordsFieldsResponse>> {
    const { signal } = params
    return BirdieRequest.get<CustomerRecordsFieldsResponse>(
      `${PREFIX}/accounts/fields`,
      undefined,
      {
        signal
      }
    )
  }

  static async getAccountsFiltersValues(params: {
    field: string
    signal?: AbortSignal
  }): Promise<BaseRequestReturnType<CustomerRecordsFieldsValueResponse>> {
    const { signal, field } = params
    return BirdieRequest.get<CustomerRecordsFieldsValueResponse>(
      `${PREFIX}/accounts/fields/${field}`,
      undefined,
      {
        signal
      }
    )
  }

  static async getCustomerUsersFiltersFields(params: {
    signal?: AbortSignal
  }): Promise<BaseRequestReturnType<CustomerRecordsFieldsResponse>> {
    const { signal } = params
    return BirdieRequest.get<CustomerRecordsFieldsResponse>(`${PREFIX}/users/fields`, undefined, {
      signal
    })
  }

  static async getCustomerUsersFiltersValues(params: {
    field: string
    signal?: AbortSignal
  }): Promise<BaseRequestReturnType<CustomerRecordsFieldsValueResponse>> {
    const { signal, field } = params
    return BirdieRequest.get<CustomerRecordsFieldsValueResponse>(
      `${PREFIX}/users/fields/${field}`,
      undefined,
      {
        signal
      }
    )
  }

  static async getIndustries(options: {
    orgId: string
    signal?: AbortSignal
  }): Promise<BaseRequestReturnType<IndustriesResponse>> {
    const { orgId, signal } = options
    return BirdieRequest.get<IndustriesResponse>(`${PREFIX}/accounts/industries`, undefined, {
      signal,
      headers: {
        'Birdie-Organization-Id': orgId
      }
    })
  }

  static async getPlans(options: {
    orgId: string
    signal?: AbortSignal
  }): Promise<BaseRequestReturnType<PlansResponse>> {
    const { orgId, signal } = options
    return BirdieRequest.get<PlansResponse>(`${PREFIX}/accounts/plans`, undefined, {
      signal,
      headers: {
        'Birdie-Organization-Id': orgId
      }
    })
  }

  static async getCustomerTypes(options: {
    orgId: string
    signal?: AbortSignal
  }): Promise<BaseRequestReturnType<CustomerTypesResponse>> {
    const { orgId, signal } = options
    return BirdieRequest.get<CustomerTypesResponse>(
      `${PREFIX}/accounts/customer-types`,
      undefined,
      {
        signal,
        headers: {
          'Birdie-Organization-Id': orgId
        }
      }
    )
  }

  static async getSalesPipelineStage(options: {
    orgId: string
    signal?: AbortSignal
  }): Promise<BaseRequestReturnType<SalesPipelineStagesResponse>> {
    const { orgId, signal } = options
    return BirdieRequest.get<SalesPipelineStagesResponse>(
      `${PREFIX}/accounts/sales-pipeline-stages`,
      undefined,
      {
        signal,
        headers: {
          'Birdie-Organization-Id': orgId
        }
      }
    )
  }

  static async deleteAccount(accountId: string): Promise<BaseRequestReturnType<void>> {
    return BirdieRequest.del<void>(`${PREFIX}/accounts/${accountId}`)
  }

  static async getUsers(
    params: GetAudiencePayload & { raw?: false },
    signal?: AbortSignal
  ): Promise<BaseRequestReturnType<UsersResponseData>>

  static async getUsers(
    params: GetAudiencePayload & { raw: true },
    signal?: AbortSignal
  ): Promise<
    BaseRequestReturnType<{
      total: number
      users: UsersRequestItem[]
      nextPage?: string
      prevPage?: string
    }>
  >

  static async getUsers(
    params: GetAudiencePayload & { raw?: boolean },
    signal?: AbortSignal
  ): Promise<
    BaseRequestReturnType<{
      total: number
      users: CustomerRecordsUserData[] | UsersRequestItem[]
      nextPage?: string
      prevPage?: string
    }>
  > {
    const { orgId, ...rest } = params
    const [error, data] = await BirdieRequest.get<UsersResponse>(`${PREFIX}/users`, rest, {
      signal,
      headers: {
        'Birdie-Organization-Id': orgId
      },
      paramsSerializer: {
        indexes: null
      }
    })

    if (error) return [error, undefined]

    const users: CustomerRecordsUserData[] | UsersRequestItem[] = params.raw
      ? data.users
      : data.users.map(this.parseUserItemToData)

    return [
      undefined,
      {
        total: data.total,
        users,
        nextPage: data.next_page,
        prevPage: data.prev_page
      }
    ]
  }

  static async deleteUser(userId: string): Promise<BaseRequestReturnType<void>> {
    return BirdieRequest.del<void>(`${PREFIX}/users/${userId}`)
  }
}
