import FlexContainer from '@/components/atoms/flex-container'
import { styled, typefaceVariants } from '@/theme'

export const QuestionItem = styled(FlexContainer, {
  mb: '$xs',

  '& label': {
    mb: '$micro'
  },

  '& h2': {
    mb: '$xxs'
  },

  '& > div:first-child': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box'
  },

  variants: {
    noMargin: {
      true: {
        margin: '0'
      }
    }
  }
})

export const HelperText = styled('p', {
  color: '$dark',
  ...typefaceVariants.subtitleRegularXXXS,
  // mt: '$xs',

  '& a': {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '$dark'
  }
})
