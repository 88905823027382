import { AllMetricsKey, MetricKey } from '@/types/metrics'
import { DEFAULT_ALLOWED_METRICS_PER_SOURCE } from '@/utils/metrics'
import { persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

// TODO change hiddenMetrics to be a allow list

export const defaultHiddenMetrics = [
  'count:feedback_share',
  'count:impact_score',
  'count:net_sentiment_score',
  'support_ticket:support_ticket_share',
  'nps:nps_share',
  'nps:nps_count',
  'nps:nps_promoters_share',
  'nps:nps_neutrals_share',
  'nps:nps_detractors_share',
  'nps:nps_detractors_share_responses',
  'nps:nps_promoters_share_responses',
  'nps:nps_neutrals_share_responses',
  'nps:nps_promoters_count',
  'nps:nps_neutrals_count',
  'nps:nps_detractors_count',
  'nps:nps_promoters_over_responses_with_comments',
  'nps:nps_neutrals_over_responses_with_comments',
  'review:review',
  'review:review_share',
  'review:review_count',
  'review:review_satisfied_count',
  'review:review_neutral_count',
  'review:review_unsatisfied_count',
  'csat:csat_share',
  'csat:csat_count',
  'csat:csat_satisfied_count',
  'csat:csat_neutral_count',
  'csat:csat_unsatisfied_count',
  'issue:issue_share',
  'social_media_post:social_media_post_share',
  'transcript:transcript_share',
  'tnps',
  'tnps:ticket_nps_score',
  'tnps:ticket_nps_share',
  'tcsat',
  'tcsat:ticket_csat_score',
  'tcsat:ticket_csat_share'
]

interface HideMetricsState {
  allowedMetricsBySource: Record<MetricKey, AllMetricsKey[]>

  setAllowedMetrics: (allowedMetricsBySource: Record<MetricKey, AllMetricsKey[]>) => void
  toggleAllowedMetrics: (source: string, metric: AllMetricsKey) => void
  getAllAllowedMetrics: (
    allowedMetricsBySource: Record<MetricKey, AllMetricsKey[]>
  ) => AllMetricsKey[]
}

/** persisted at local storage */
export const useHiddenMetricsStore = createWithEqualityFn<HideMetricsState>()(
  persist(
    (set, get) => ({
      allowedMetricsBySource: DEFAULT_ALLOWED_METRICS_PER_SOURCE,

      setAllowedMetrics: allowedMetricsBySource => set({ allowedMetricsBySource }),
      toggleAllowedMetrics: (source, metric) => {
        const { allowedMetricsBySource } = get()
        const allowedMetrics = allowedMetricsBySource[source]

        if (allowedMetrics) {
          const newAllowedMetrics = allowedMetrics.includes(metric)
            ? allowedMetrics.filter(allowed => allowed !== metric)
            : [...allowedMetrics, metric]
          set(() => ({
            allowedMetricsBySource: {
              ...allowedMetricsBySource,
              [source]: newAllowedMetrics
            }
          }))
        } else {
          set(() => ({
            allowedMetricsBySource: {
              ...allowedMetricsBySource,
              [source]: [metric]
            }
          }))
        }
      },
      getAllAllowedMetrics: allowedMetricsBySource => {
        return Object.values(allowedMetricsBySource).flat(1)
      }
    }),
    { name: 'hidden-metrics-store', version: 5 }
  ),
  shallow
)

export default useHiddenMetricsStore
