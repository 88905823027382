import FlexContainer from '@/components/atoms/flex-container'
import { CSS } from '@/theme'
import { StepIndicator } from './Step.styles'

export interface StepsIndicatorProps {
  step: number
  maxSteps: number
  css?: CSS
}

const StepsIndicator = ({ step, maxSteps, css }: StepsIndicatorProps) => {
  return (
    <FlexContainer css={css} gap="micro">
      {Array(maxSteps)
        .fill(0)
        .map((_, index) => (
          <StepIndicator filled={index <= step} key={index} />
        ))}
    </FlexContainer>
  )
}

export default StepsIndicator
