import FlexContainer from '@/components/atoms/flex-container'
import { CSS, styled, typefaceVariants } from '@/theme'
import { PopoverClose } from '@radix-ui/react-popover'

export const PopoverContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$xs',
  width: 398,
  maxHeight: '72vh',
  padding: '$xxs',
  pb: '$xxxs',
  overflowY: 'hidden'
})

export const SectionTitle = styled('h4', {
  ...typefaceVariants.subtitleSemiBoldMicro,
  color: '$neutraLowPure'
})

export const filterItemCSS: CSS = {
  all: 'unset',
  px: '$micro',
  py: '$nano',
  br: '$micro',
  boxSizing: 'border-box',
  gap: '$micro',
  color: '$neutralLowPure',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& > .options': {
    opacity: 0
  },

  '& .ph, & .ph-bold': {
    fontSize: '$xxs'
  },

  '&:hover': {
    background: '$brandPrimaryLight',
    color: '$brandPrimaryPure',

    '& > .options': {
      opacity: 1
    }
  },
  '&:active': {
    '& > .options': {
      opacity: 1
    }
  }
}

export const FilterItemContainer = styled(FlexContainer, {
  ...filterItemCSS,
  '&[disabled]': {
    cursor: 'not-allowed',
    color: '$neutralLowLight'
  }
})

export const FilterList = styled(FlexContainer, {
  maxHeight: 'min(500px, calc(100vh - 460px))',
  mx: '-$micro',
  overflowY: 'auto'
})

export const BlankButton = styled(PopoverClose, {
  all: 'unset',
  cursor: 'pointer',
  padding: '$micro',
  br: '$nano',
  color: '$neutralLowPure',
  fontWeight: '$semibold',
  fontSize: '$xxxs',
  lineHeight: '$xs',
  mx: '-$micro',

  '&:hover': {
    background: '$pajaritoLight',
    color: '$pajarito'
  }
})

export const AppliedBadge = styled('div', {
  padding: '$nano $micro',
  borderRadius: '$micro',

  display: 'flex',
  alignItems: 'center',
  gap: '$nano',

  fontSize: '$micro',
  color: '$brandPrimaryPure',
  backgroundColor: '$brandPrimaryLight'
})
