import { styled } from '@/theme'

export const Highlight = styled('mark', {
  cursor: 'pointer',
  padding: '1px 0px 2px',
  transition: 'background-color .3s',

  variants: {
    sentiment: {
      positive: {
        backgroundColor: '$feedbackPositiveMedium',
        textDecorationColor: '$feedbackPositivePure'
      },
      negative: {
        backgroundColor: '$feedbackNegativeMedium',
        textDecorationColor: '$feedbackNegativePure'
      },
      neutral: {
        backgroundColor: '$neutralHighPure',
        textDecorationColor: '$neutralLowLight'
      },
      hidden: {
        backgroundColor: 'transparent',
        textDecorationColor: 'transparent'
      }
    },
    mode: {
      expanded: {
        textDecoration: 'none'
      },
      compact: {
        backgroundColor: 'unset !important',
        textDecorationLine: 'underline',
        textDecorationStyle: 'dashed',
        textUnderlineOffset: 4
      }
    }
  }
})
