import { CheckCircle } from '@phosphor-icons/react'
import { useNavigate, useParams } from 'react-router-dom'

import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import useLetterCase from '@/hooks/useLetterCase'
import useIntegrationsStore from '@/store/useIntegrationsStore'
import { formatConnectorName, resizeLogoUrl } from '../utils'
import { Card, ConnectedTag, Container, Image } from './IntegrationSelection.styles'
import useUser from '@/hooks/useUser'
import { useTranslation } from 'react-i18next'

function IntegrationSelection() {
  const navigate = useNavigate()
  const params = useParams()

  const { t } = useTranslation()
  const integrations = useIntegrationsStore(state => state.integrations)

  const { capitalizeFirst } = useLetterCase()

  const ownIntegrations = integrations.filter(
    integration => integration.connector.id === params.connectorId
  )

  const { userPermissions } = useUser()
  const isManager = userPermissions.source.includes('manager')

  if (ownIntegrations.length === 0) return null

  const connector = ownIntegrations[0].connector
  const connectorName = formatConnectorName(connector.name)

  return (
    <FlexContainer css={{ gap: 20 }} direction="column">
      <FlexContainer css={{ gap: 16, height: 40 }} justifyContent="spaceBetween">
        <Text as="h2" typeface="titleBoldXS">
          {`${connectorName} integrations`}
        </Text>

        <Button
          disabled={!isManager}
          onClick={() => navigate(`/settings/integrations/${connector.id}/new`)}
          type="button"
        >
          {`Create new ${connectorName} integration`}
        </Button>
      </FlexContainer>

      <Container>
        {ownIntegrations.map(integration => (
          <Card key={integration.id}>
            <FlexContainer css={{ width: '100%' }} justifyContent="spaceBetween">
              <Image
                alt=""
                height={40}
                round={Boolean(integration.metadata?.app_image)}
                src={integration.metadata?.app_image ?? resizeLogoUrl(connector.logo_url, 40)}
              />

              <ConnectedTag>
                <CheckCircle size={16} weight="bold" />
                {t('connected')}
              </ConnectedTag>
            </FlexContainer>
            <Text as="h3" typeface="titleBoldXXS">
              {capitalizeFirst(integration.name)}
            </Text>
            <Button
              css={{ marginTop: 'auto' }}
              fullWidth
              onClick={() => navigate(`/settings/integrations/${connector.id}/${integration.id}`)}
              size="small"
              type="button"
              variant="secondary"
            >
              {t('viewDetails')}
            </Button>
          </Card>
        ))}
      </Container>
    </FlexContainer>
  )
}

export default IntegrationSelection
