import Bone from '@/components/atoms/bone'
import FlexContainer from '@/components/atoms/flex-container'

const OpportunityListSkeleton = () => (
  <FlexContainer direction="column" gap="nano">
    <Bone css={{ height: 60 }} />
    <Bone css={{ height: 60 }} />
    <Bone css={{ height: 60 }} />
    <Bone css={{ height: 60 }} />
    <Bone css={{ height: 60 }} />
  </FlexContainer>
)

export default OpportunityListSkeleton
