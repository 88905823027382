import { styled } from '@/theme'
import { slideAndFadeOpen } from '@/theme/animations'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'

export const StyledTrigger = styled(DropdownMenuPrimitive.Trigger, {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  gap: '$micro',
  cursor: 'pointer',

  boxSizing: 'border-box',
  '&:hover': {
    backgroundColor: 'greenLight'
  },

  '.caret': {
    transition: '.2s',
    minWidth: 16,
    color: '$neutralHighLight'
  },

  '&[data-state="open"]': {
    '.caret': {
      transform: 'rotate(180deg)'
    }
  },

  variants: {
    opportunityPlan: {
      true: {
        '&:hover': {
          backgroundColor: 'transparent'
        },
        '.caret': {
          ml: '$nano'
        },
        '.email': {
          color: '$brandPrimaryMedium'
        }
      }
    }
  }
})

export const StyledContent = styled(DropdownMenuPrimitive.Content, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$xxxs',
  minWidth: 240,
  color: '$dark',
  borderRadius: 8,
  bAll: '$neutralHighPure',
  background: '$white',
  py: '$xxs',
  overflow: 'hidden',
  zIndex: 9999,
  position: 'relative',
  boxSizing: 'border-box',
  boxShadow: '$softShadow',
  ...slideAndFadeOpen
})

export const StyledSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,
  backgroundColor: '$neutralHighPure'
})

export const StyledItem = styled(DropdownMenuPrimitive.Item, {
  all: 'unset',
  px: '$xxxs',
  mx: '$nano',
  cursor: 'pointer',
  borderRadius: 8,

  '&:focus-visible': {
    backgroundColor: '$greenLight'
  },

  '&:hover': {
    backgroundColor: '$greenLight'
  }
})

export const UserIcon = styled('div', {
  size: 32,
  minWidth: 32,
  borderRadius: 48,
  backgroundColor: '$feedbackInformativeMedium',
  color: '$neutralLowPure',
  fontStyle: 'normal',
  fontWeight: '$semibold',
  fontSize: '$xxxs',
  lineHeight: '$xs',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  variants: {
    opportunityPlan: {
      true: {
        backgroundColor: '$feedbackInformativePure',
        color: '$neutralHighLight'
      },
      false: {}
    }
  },

  defaultVariants: {
    opportunityPlan: false
  }
})
