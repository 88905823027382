import ToastMessagesProvider from '@/components/molecules/ToastMessagesProvider'
import { useUIStore } from '@/store'
import { useEffect } from 'react'
import SideExplore from '@/components/molecules/side-explore/SideExplore'
import FiltersHeader from '@/components/molecules/filters/FiltersHeader'
import useUser from '@/hooks/useUser'
import NoPermission from '@/components/molecules/no-permission/NoPermission'
import { ExploreContainer, FeedContainer, PageContainer } from './styles'
import FeedTabsContent from '@/components/molecules/feed/FeedTabsContent'
import { KeywordClusters } from '@/components/molecules/feed/keywords'
import KeywordsActionBar from '@/components/molecules/feed/keywords/KeywordsActionBar'
import useSegment from '@/hooks/useSegment'

interface Props {
  useKeywordTitle?: boolean
}

function KeywordsPage({ useKeywordTitle }: Props) {
  const { setPageTitle } = useUIStore()
  const { track } = useSegment()

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen only on useKeywordTitle change
  useEffect(() => {
    setPageTitle(useKeywordTitle ? 'Keywords' : 'Topics')
    track('explore_user_keywords_tab')
  }, [useKeywordTitle])

  const { userPermissions, currentUser } = useUser()
  if (userPermissions.topic.length === 0 && currentUser) {
    return <NoPermission />
  }

  return (
    <PageContainer>
      <FiltersHeader />
      <ExploreContainer>
        <SideExplore />
        <FeedContainer>
          <FeedTabsContent id="keywords" noDefaultScroll>
            <KeywordClusters useKeywordTitle={useKeywordTitle} />
            <KeywordsActionBar />
          </FeedTabsContent>
        </FeedContainer>
        <ToastMessagesProvider />
      </ExploreContainer>
    </PageContainer>
  )
}

export default KeywordsPage
