import Loader from '@/components/atoms/loader'
import { styled } from '@/theme'

const Box = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  alignItems: 'center',
  justifyContent: 'center',
  padding: 16,

  variants: {
    card: {
      true: {
        background: '#FFFFFF',
        bAll: '$stroke',
        borderTop: 'none'
      }
    }
  }
})

const ScrollLoader = ({ card }: { card?: boolean }) => (
  <Box card={card}>
    <Loader />
  </Box>
)

export default ScrollLoader
