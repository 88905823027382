import { useFiltersStore } from '@/store'
import useSelectedFilters from '../filters/useSelectedFilters'
import AnalyticsService from '@/services/AnalyticsService'
import { useQuery } from '@tanstack/react-query'
import { AnalyticsRequests } from '@/types/analytics'
import usePagination from '../usePagination'
import { getTrendingTopicMax } from '@/components/molecules/analytics/trending-topics/trendingTopicsUtils'
import { shallow } from 'zustand/shallow'
import { TrendingTopicType } from '@/types/analytics/TrendingTopic'

const useTrendingTopics = (type: TrendingTopicType) => {
  const { dateRange } = useFiltersStore(
    state => ({
      dateRange: state.dateRange
    }),
    shallow
  )

  const toFeedbackFilterSchema = useFiltersStore(state => state.toAnalyticsFeedbackFilterSchema)
  const { allSelectedFilters } = useSelectedFilters()

  const queryFn = async () => {
    const feedbackFilter = toFeedbackFilterSchema()

    const payload: AnalyticsRequests.TrendingTopicsPayload = {
      feedback_search_schema: feedbackFilter,
      count_by: 'ingested_feedback_id',
      search_text: '',
      return_all: false,
      exact_count: false,
      sentiment_count: true,
      sentiment_exact_count: false
    }

    const requests = {
      emerging: AnalyticsService.emergingTopics,
      topIssues: AnalyticsService.topIssues,
      topRequests: AnalyticsService.topRequests
    }

    const [error, data] = await requests[type](payload)

    if (error) {
      throw error
    }

    return data
  }

  const queryDisabled = type === 'emerging' && dateRange === null

  const { data, isLoading } = useQuery({
    queryKey: ['analytics', 'trending-topics', type, allSelectedFilters],
    queryFn,
    enabled: !queryDisabled
  })

  const { paginatedArray, nextPage, hasNextPage } = usePagination({
    array: data ?? [],
    pageSize: 5
  })

  const max = getTrendingTopicMax(paginatedArray)

  return {
    allTopics: data,
    topics: paginatedArray,
    nextPage,
    hasNextPage,
    max,
    isLoading
  }
}

export default useTrendingTopics
