import Breadcrumb from '@/components/atoms/breadcrumb'
import { AnalyticsFlexContainer } from '@/components/molecules/analytics/Analytics.styles'
import TopicCustomersIndicator from '@/components/molecules/analytics/topic-details/TopicCustomersIndicator'
import TopicFeedbackIndicator from '@/components/molecules/analytics/topic-details/TopicFeedbackIndicator'
import TopicRelatedFeedback from '@/components/molecules/analytics/topic-details/TopicRelatedFeedback'
import TopicSummaryAndCauses from '@/components/molecules/analytics/topic-details/TopicSummaryAndCauses'
import AnalyticsTrendLine from '@/components/molecules/analytics/trend-line/AnalyticsTrendLine'
import usePeriods from '@/hooks/usePeriods'
import useSegment from '@/hooks/useSegment'
import { useFiltersStore } from '@/store'
import { trendingTopicTypeMap } from '@/utils/trendingTopics'
import { CalendarBlank, ChartLine, MagnifyingGlass } from '@phosphor-icons/react'
import { useEffect } from 'react'

const IndicatorComponent = trendingTopicTypeMap.emerging.IndicatorComponent

const ExploreFeedbackPage = () => {
  const { track } = useSegment()

  const searchTerm = useFiltersStore(state => state.search)
  const datePeriod = useFiltersStore(state => state.datePeriod)
  const { getPeriod } = usePeriods()

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen once
  useEffect(() => {
    track('explore_user_go_to_analytics_topic_drilldown')
  }, [])

  const currentCrumb = (() => {
    if (searchTerm.length) return { text: <q>{searchTerm}</q>, icon: <MagnifyingGlass /> }

    const period = getPeriod(datePeriod)
    return { text: period.name, icon: <CalendarBlank /> }
  })()

  return (
    <>
      <Breadcrumb.Root>
        <Breadcrumb.Item to="/analytics">
          <ChartLine />
          <span>Explore feedback</span>
        </Breadcrumb.Item>
        <Breadcrumb.Divider />
        <Breadcrumb.Item active>
          {currentCrumb.icon}
          {currentCrumb.text}
        </Breadcrumb.Item>
      </Breadcrumb.Root>

      <AnalyticsFlexContainer justifyContent="stretch">
        <IndicatorComponent asExploreFeedbackItem topicId="" />
        <TopicFeedbackIndicator asExploreFeedbackItem topicId="" />
        <TopicCustomersIndicator asExploreFeedbackItem topicId="" />
      </AnalyticsFlexContainer>

      <TopicSummaryAndCauses asExploreFeedbackItem topicId="" type={'emerging'} />

      <AnalyticsTrendLine asExploreFeedbackItem topicId="" />

      <TopicRelatedFeedback asExploreFeedbackItem topicId="" topicName="" topicType={'emerging'} />
    </>
  )
}

export default ExploreFeedbackPage
