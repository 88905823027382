import { styled, typefaceVariants } from '@/theme'

export const TableContainer = styled('section', {
  bAll: '$neutralHighPure',
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  padding: 24,
  bc: '$neutralHighLight'
})

export const Table = styled('table', {
  borderSpacing: 0,
  tableLayout: 'fixed',
  width: '100%'
})

export const TableHeader = styled('th', {
  ...typefaceVariants.subtitleSemiBoldXXXS,
  padding: '8px 0',
  textAlign: 'start'
})

export const TableCell = styled('td', {
  ...typefaceVariants.paragraphRegularMicro,
  bTop: '$neutralHighPure',
  padding: '8px 0'
})
