// export { default } from './useFiltersStore'
import { createWithEqualityFn } from 'zustand/traditional'
import createFiltersStore from './createFiltersStore'
import { shallow } from 'zustand/shallow'

const useFiltersStore = createWithEqualityFn(createFiltersStore, shallow)
const useFeedFiltersStore = createWithEqualityFn(createFiltersStore, shallow)

export default useFiltersStore
export { useFeedFiltersStore }

export type { UseFiltersState } from './useFiltersStore.types'
