import { styled, typefaceVariants } from '@/theme'
import { CheckCircle, IconProps, Info, WarningCircle, X, XCircle } from '@phosphor-icons/react'

export const Container = styled('div', {
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  gap: '$xxs',
  padding: '$xxs',

  variants: {
    color: {
      yellow: {
        backgroundColor: '$feedbackWarningMedium'
      },
      green: {
        backgroundColor: '$feedbackPositiveMedium'
      },
      red: {
        backgroundColor: '$feedbackNegativeMedium'
      },
      purple: {
        backgroundColor: '$feedbackInformativeMedium'
      }
    },
    fullWidth: {
      true: {
        width: '100%'
      },
      false: {
        width: 'auto'
      }
    }
  },

  defaultVariants: {
    color: 'yellow'
  }
})

export const Title = styled('p', typefaceVariants.titleBoldXXS)
export const Description = styled('p', {
  ...typefaceVariants.paragraphRegularMicro,
  color: '$neutralLowMedium'
})

const StyledButton = styled('button', {
  backgroundColor: 'transparent',
  border: 0,
  cursor: 'pointer',
  marginLeft: 'auto',
  size: 24
})

export const CloseButton = (props: React.HTMLAttributes<HTMLButtonElement>) => (
  <StyledButton {...props}>
    <X size={24} weight="bold" />
  </StyledButton>
)

const iconSize = 32
export const InformativeIcon = (props: IconProps) => <Info size={iconSize} {...props} />
export const NegativeIcon = (props: IconProps) => <XCircle size={iconSize} {...props} />
export const PositiveIcon = (props: IconProps) => <CheckCircle size={iconSize} {...props} />
export const WarningIcon = (props: IconProps) => <WarningCircle size={iconSize} {...props} />
