import { useEffect } from 'react'
import { shallow } from 'zustand/shallow'

import SaveFiltersButton from '@/components/molecules/saved-filters/SaveFiltersButton'
import useFilters from '@/hooks/filters/useFilters'
import useFiltersCount from '@/hooks/filters/useFiltersCount'
import useCopyToClipboard from '@/hooks/useCopyToClipboard'
import { useFiltersStore, useUIStore } from '@/store'
import useSavedFiltersStore from '@/store/useSavedFiltersStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import { CSS } from '@/theme'
import AppliedFilters from '../AppliedFilters'
import AppliedFiltersNew from '../AppliedFiltersNew/AppliedFiltersNew'
import { FiltersAreaContainer, FiltersAreaInnerContent } from './FiltersArea.styles'
import FiltersAreaHeader from './FiltersAreaHeader'
import useUser from '@/hooks/useUser'
import useSavedFilters from '@/hooks/useSavedFilters'
import { FilterRequests } from '@/types/filters'
import { useSearchParams } from 'react-router-dom'
import { FILTER_QUERY_PARAM } from '@/utils/filters'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import AreaOfInterestFilters from '../AppliedFiltersNew/AreaOfInterestFilters'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import FlexContainer from '@/components/atoms/flex-container'
import Button from '@/components/atoms/button'
import UpdateAreaButton from '../../saved-filters/UpdateAreaButton'
import useLogging from '@/hooks/useLogging'
import { ExploreFloatingButton } from '../../side-explore/SideExplore.styles'
import { X } from '@phosphor-icons/react'
import { SavedFilterContent } from '@/types/filters/Filters'
import { useTranslation } from 'react-i18next'
import useFilterEntityStore from '@/store/useFiltersStore/useFilterEntityStore'

interface FiltersAreaProps {
  css?: CSS
  newFeed?: boolean
  opportunityPlan?: boolean
}

function FiltersArea({ css, opportunityPlan, newFeed = false }: FiltersAreaProps) {
  const collapsed = useUIStore(state => !state.isFiltersOpen, shallow)
  const closeFilters = useUIStore(state => state.closeFilters)

  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { logException } = useLogging({ context: 'filters-area' })

  const useStore = newFeed ? useFeedFiltersStore : useFiltersStore

  const { t } = useTranslation()

  const clearAndApply = useStore(state => state.clearAndApply)
  const clearButKeepAreaOfInterest = useStore(state => state.clearButKeepAreaOfInterest)
  const setSearch = useStore(state => state.setSearch)
  const setFiltersByURL = useStore(state => state.setFiltersByURL)

  const setCurrentSavedFilter = useSavedFiltersStore(state => state.setCurrentSavedFilter)

  const { reloadData } = useFilters()
  const { totalCount } = useFiltersCount({ newFeed })
  const { removeHashFromUrl } = useSavedFilters({ newFeed })

  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)

  const editMode = useFilterEntityStore(state => state.editMode)
  const setEditMode = useFilterEntityStore(state => state.setEditMode)

  const [copyToClipboard, copyStatus, resetStatus] = useCopyToClipboard()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (copyStatus === 'copied') {
      addSuccessToast({ text: t('theLinkWasSuccessfullyCopied'), onClose: resetStatus })
    }

    if (copyStatus === 'error') {
      const message = t('failedToCopyToClipboard')
      logException(new Error(message), { message })
      addErrorToast({ text: message, onClose: resetStatus })
    }
  }, [copyStatus, addErrorToast, addSuccessToast, logException, resetStatus, t])

  useEffect(() => {
    const filtersBase64 = searchParams.get(FILTER_QUERY_PARAM)
    const version = searchParams.get('version') // if v2 send to opportunty plan exploration

    if (filtersBase64 && version !== 'v2') {
      const decodedFiltersString = atob(filtersBase64)
      const parsedFilters = JSON.parse(decodedFiltersString)
      const filters: FilterRequests.FeedbackFilter = Object.keys(parsedFilters).includes('filter')
        ? parsedFilters
        : { filters: parsedFilters }
      setFiltersByURL(filters)
      reloadData()
    }
  }, [reloadData, searchParams, setFiltersByURL])

  const onCopyClick = () => {
    copyToClipboard(window.location.href)
  }

  const onClearClick = () => {
    removeHashFromUrl()
    setCurrentSavedFilter(undefined)

    if (!currentInterestArea) {
      setSearch('')
      clearAndApply(true)
      reloadData()
      return
    }

    clearButKeepAreaOfInterest(currentInterestArea)
  }

  const { userPermissions } = useUser()
  const showSaveButton =
    totalCount > 0 &&
    userPermissions.filters.includes('manager') &&
    (newFeed ? !currentInterestArea : true)

  const AppliedFiltersComponent = newFeed ? AppliedFiltersNew : AppliedFilters

  const { applyFilterContent } = useSavedFilters({ newFeed: true })
  const onCancelClick = () => {
    if (!currentInterestArea) return
    setEditMode(false)
    applyFilterContent(currentInterestArea.content as SavedFilterContent[])
  }

  return (
    <FiltersAreaContainer collapsed={collapsed} css={css} opportunityPlan={opportunityPlan}>
      <FiltersAreaInnerContent direction="column">
        {newFeed && !editMode && <AreaOfInterestFilters />}
        <FiltersAreaHeader
          newFeed={newFeed}
          onClearClick={onClearClick}
          onCopyClick={onCopyClick}
        />
        <AppliedFiltersComponent />
        {editMode ? (
          <FlexContainer alignItems="center" css={{ my: '$xs' }} justifyContent="spaceBetween">
            <Button onClick={onCancelClick} size="small" variant="flat">
              {t('cancel')}
            </Button>
            <UpdateAreaButton />
          </FlexContainer>
        ) : (
          <>
            {showSaveButton && (
              <SaveFiltersButton css={{ alignSelf: 'flex-end', my: '$xs' }} newFeed={newFeed} />
            )}
          </>
        )}
      </FiltersAreaInnerContent>
      {!collapsed && (
        <ExploreFloatingButton
          colorScheme="tinted"
          css={{ padding: '$nano', top: '30px', right: '3px' }}
          onClick={closeFilters}
        >
          <X size={16} weight="regular" />
        </ExploreFloatingButton>
      )}
    </FiltersAreaContainer>
  )
}

export default FiltersArea
