import { SegmentationRequests } from '@/types/segmentation'
import RequestHandler from './RequestHandlers/NewRequest'

export default class SegmentationService {
  static PREFIX = '/segmentations'

  static async getAll(
    params: SegmentationRequests.GetSegmentationsParams,
    options?: { signal?: AbortSignal }
  ) {
    return await RequestHandler.get<SegmentationRequests.GetSegmentationsResponse>(
      this.PREFIX,
      params,
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async addSegmentation(
    payload: SegmentationRequests.AddSegmentationPayload,
    options?: { signal?: AbortSignal }
  ) {
    return await RequestHandler.post<SegmentationRequests.AddSegmentationResponse>(
      this.PREFIX,
      payload,
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async getOne(
    params: SegmentationRequests.GetSegmentationByIdParams,
    options?: { signal?: AbortSignal }
  ) {
    return await RequestHandler.get<SegmentationRequests.GetSegmentationByIdResponse>(
      `${this.PREFIX}/${params.segmentation_id}`,
      params,
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async updateSegmentation(
    segmentationId: string,
    payload: SegmentationRequests.UpdateSegmentationPayload,
    options?: { signal?: AbortSignal }
  ) {
    return await RequestHandler.put<void>(`${this.PREFIX}/${segmentationId}`, payload, {
      signal: options?.signal,
      paramsSerializer: {
        indexes: null
      }
    })
  }

  static async deleteSegmentation(segmentationId: string, options?: { signal?: AbortSignal }) {
    return await RequestHandler.del<void>(`${this.PREFIX}/${segmentationId}`, {
      signal: options?.signal
    })
  }
}
