import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface FilterEntityStore {
  editMode: boolean
  setEditMode: (editMode: boolean) => void
}

const useFilterEntityStore = createWithEqualityFn<FilterEntityStore>(
  set => ({
    editMode: false,
    setEditMode: editMode => set(() => ({ editMode }))
  }),
  shallow
)

export default useFilterEntityStore
