import { useUIStore } from '@/store'
import { styled } from '@/theme'
import { useEffect } from 'react'
import TopicGroupCollapse from './topic-groups/TopicGroupCollapse'
import { useTopics } from '@/hooks/useTopics/useTopics'
import useClassification from '@/hooks/useClassification'
import Divider from '@/components/atoms/divider'
import UngroupedCheckbox from './topic-groups/UngroupedCheck'

const ClassificationAreaContainer = styled('aside', {
  display: 'flex',
  flexDirection: 'column',
  opacity: 1,
  transition: '.2s',

  gap: '$xxs',
  ml: '$sm',
  pr: '$nano',

  flexGrow: 2,
  overflow: 'hidden',
  minHeight: 280,

  '& .select-container-opts': {
    gap: '$nano',
    minHeight: '$md'
  },

  variants: {
    collapsed: {
      true: {
        opacity: 0
      }
    }
  }
})

const ClassificationArea = () => {
  const { classificationCollapsed, isFiltersOpen } = useUIStore()

  const { loadTopicGroups } = useTopics()
  const { loadAllThemes } = useClassification()

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen once
  useEffect(() => {
    loadTopicGroups()
    loadAllThemes()
  }, [])

  return (
    <ClassificationAreaContainer
      collapsed={classificationCollapsed}
      css={{ pointerEvents: isFiltersOpen ? 'none' : 'unset' }}
    >
      <TopicGroupCollapse />
      <Divider
        css={{ marginRight: 54, marginLeft: 0, my: '$micro' }}
        line="dashed"
        orientation="horizontal"
      />
      <UngroupedCheckbox />
    </ClassificationAreaContainer>
  )
}

export default ClassificationArea
