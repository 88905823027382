function useQueryString() {
  const makeQueryString = (url: string, query: Record<string, unknown>): string => {
    const queryLine =
      '?' +
      Object.entries(query)
        .filter(entry => entry[1] !== null)
        .map(([k, v]) => `${encodeURIComponent(String(k))}=${encodeURIComponent(String(v))}`)
        .join('&')
    return url + queryLine
  }

  return {
    makeQueryString
  }
}

export default useQueryString
