import Button from '@/components/atoms/button'
import Dialog from '@/components/atoms/dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { useTranslation } from 'react-i18next'
import BetaBadge from '@/components/atoms/badge/BetaBadge'
import useSegment from '@/hooks/useSegment'
import { useNavigate } from 'react-router-dom'
import { useMemo } from 'react'
import shortUUID from 'short-uuid'
import { OpportunityItem } from '@/types/opportunity/Opportunity'
import Survey from '@/assets/img/survey.png'

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  setOpportunityIdToCalibrate: OpportunityItem | null
}

const CalibrateOpportunityDialog = ({ open, onOpenChange, setOpportunityIdToCalibrate }: Props) => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const navigate = useNavigate()
  const translator = useMemo(() => shortUUID(), [])

  const shortOpportunityId = useMemo(
    () => translator.fromUUID(setOpportunityIdToCalibrate?.id ?? ''),
    [setOpportunityIdToCalibrate?.id, translator]
  )
  const onAddClick = () => {
    onOpenChange(false)
    navigate(`/opportunity/${shortOpportunityId}/calibration`)
    track('calibrate_opp_dialog_now')
  }

  const onLaterClick = () => {
    onOpenChange(false)
    track('calibrate_opp_dialog_later')
  }

  return (
    <Dialog
      contentProps={{ css: { maxHeight: '90vh' } }}
      align="center"
      closeIcon
      modal
      onOpenChange={onOpenChange}
      open={open}
    >
      <FlexContainer direction="column" gap="xs">
        <FlexContainer gap="xxs">
          <Text as="h1" typeface="titleBoldXXS">
            {t('calibrateOpportunityDialogTitle')}
          </Text>
          <BetaBadge />
        </FlexContainer>

        <FlexContainer direction="column" gap="xxs">
          <Text as="p" fontSize="xxxs">
            {t('calibrateOpportunityDialogBody1')}
          </Text>
          <Text as="p" fontSize="xxxs">
            {t('calibrateOpportunityDialogBody2')}
          </Text>
          <Text as="p" fontSize="xxxs">
            {t('calibrateOpportunityDialogBody3')}
          </Text>
          <FlexContainer justifyContent="center">
            <img
              alt="A git showing how to leave feedback for the Birdie team."
              src={Survey}
              width={'55%'}
            />
          </FlexContainer>
        </FlexContainer>

        <Divider line="solid" orientation="horizontal" />
        <FlexContainer direction="column" gap="xxs">
          <Button fullWidth onClick={onAddClick} size="small">
            {t('calibrateOpportunityDialogConfirm')}
          </Button>

          <Button fullWidth onClick={onLaterClick} size="small" variant="flat">
            {t('calibrateOpportunityDialogCancel')}
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Dialog>
  )
}

export default CalibrateOpportunityDialog
