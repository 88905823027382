import { styled } from '@/theme'
import Card from '../../card/Card'
import Tooltip from '@/components/atoms/tooltip'
import FlexContainer from '@/components/atoms/flex-container'

const Root = styled(Card.Root, {
  display: 'flex',
  gap: '$sm',
  flex: 1,

  variants: {
    taller: {
      true: {
        height: 132
      },
      false: {
        height: 120
      }
    },
    stretch: {
      true: {
        flexGrow: 2
      },
      false: {
        flexGrow: 1
      }
    }
  },

  defaultVariants: {
    taller: false,
    stretch: false
  }
})

const Column = styled(FlexContainer, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$micro'
})

const Title = styled(Card.Title, {})

const NumberContainer = styled('div', {
  display: 'flex',
  gap: '$nano',
  alignItems: 'baseline'
})

const BigNumber = styled('h3', {
  fontSize: '$md',
  fontWeight: '$semibold',
  color: 'CurrentColor'
})

const Paragraph = styled('p', {
  fontSize: '$xxxs',
  fontWeight: '$regular',
  lineHeight: '130%',

  em: {
    fontStyle: 'normal'
  },

  span: {
    color: '$neutralLowLight'
  }
})

const FallbackParagraph = styled('p', {
  color: '$neutralLowLight',
  fontSize: '$xxxs',
  fontWeight: '$regular',
  lineHeight: '130%',
  textAlign: 'center',
  width: '100%',
  cursor: 'default'
})

interface FallbackProps {
  children?: React.ReactNode
}
const Fallback = ({ children }: FallbackProps) => (
  <Tooltip
    side="bottom"
    text={
      children ?? (
        <>
          Select a different time <br /> period to see comparison
        </>
      )
    }
    variant="small"
  >
    <FallbackParagraph>----</FallbackParagraph>
  </Tooltip>
)

const Header = styled(Card.Header, {
  variants: {
    spaceBetween: {
      true: {
        justifyContent: 'space-between'
      }
    }
  }
})

const IndicatorCard = {
  Root,
  Column,
  Header,
  IconBox: Card.IconBox,
  Title,
  NumberContainer,
  BigNumber,
  FallbackParagraph,
  Paragraph,
  Fallback
}

export default IndicatorCard
