import { useState } from 'react'
import Input from '@/components/atoms/input'
import Button from '@/components/atoms/button'
import Dialog from '@/components/atoms/dialog'
import FlexContainer from '@/components/atoms/flex-container'

interface Props {
  open?: boolean
  onSetLink?: (link: string) => void
  onOpenChange?: (open: boolean) => void
}

const LinkPopup = ({ open = false, onSetLink, onOpenChange }: Props) => {
  const [link, setLink] = useState<string>('')

  const onAddLinkClick = () => {
    onSetLink?.(link)
    setLink('')
  }

  return (
    <Dialog align="center" closeIcon onOpenChange={onOpenChange} open={open} title="Add an URL">
      <FlexContainer direction="column" fullWidth gap="micro">
        <Input
          onChange={e => setLink(e.currentTarget.value)}
          onKeyUp={e => e.key === 'Enter' && onAddLinkClick()}
          placeholder="https://example.com"
          value={link}
        />
        <Button fullWidth onClick={onAddLinkClick} size="small" small>
          Link
        </Button>
      </FlexContainer>
    </Dialog>
  )
}

export default LinkPopup
