import Input, { TextArea } from './Input'

import { styled } from '@/theme'
export default Input
export { TextArea }

export const InputErrorMessage = styled('p', {
  fontStyle: 'normal',
  fontWeight: '$regular',
  fontSize: '$micro',
  lineHeight: 1.15,
  color: '$feedbackNegativePure'
})
