import { SegmentationItem } from '@/types/segmentation/Segmentation'
import { RawSegmentationData } from '@/types/segmentation/SegmentationRequests'
import { stringToDate } from './date'

export const mapSegmentation = (item: RawSegmentationData): SegmentationItem => ({
  segmentationId: item.segmentation_id,
  name: item.name,
  description: item.description,
  filterId: item.filter_id,
  content: item.content,
  context: item.context,
  createdAt: stringToDate(item.created_at),
  createdBy: item.created_by,
  updatedAt: stringToDate(item.updated_at),
  updatedBy: item.updated_by
})
