import KeywordService from '@/services/KeywordService'
import TopicService from '@/services/TopicService'
import { useKeywordsStore } from '@/store'
import useToastMessageStore from '@/store/useToastMessageStore'
import { KeywordPayload } from '@/types/classification/TopicRequests'
import {
  KeywordBase,
  KeywordTopic,
  KeywordWithTopicIds,
  TopicRelationEvent
} from '@/types/keywords'
import { useCallback } from 'react'
import useClassification, { REFRESH_THEMES_MAX_DELAY } from '../useClassification'
import useEvents from '../useEvents'
import useTopicsService from '../useTopics/useTopicsService'
import { useFeedbacks } from '../feedback/useFeedbacks'
import useLogging from '../useLogging'

interface CreateKeywordAndAddRelationParams {
  keywordText: string
  topics: Array<{
    topicId: string
    name: string
  }>
  mergedTopicParents?: Array<{
    topicId: string
    name: string
  }>
}

interface ApplyTopicsToKeywordsParams {
  keywords: KeywordWithTopicIds[]
  selectedTopics: KeywordTopic[]
  removedTopics: string[]
  enableDispatch?: boolean
}

function useKeywordsRelations() {
  const updateKeywordsTopicIds = useKeywordsStore(state => state.updateKeywordsTopicIds)
  const getKeywordById = useKeywordsStore(state => state.getKeywordById)
  const removeRelationsFromKeywords = useKeywordsStore(state => state.removeRelationsFromKeywords)

  const { addKeywordTopicToAllFeedbackStores } = useFeedbacks()

  const { refreshThemes, getTopicById } = useClassification()

  const addToast = useToastMessageStore(state => state.addToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addWarningToast = useToastMessageStore(state => state.addWarningToast)

  const { logException } = useLogging({ context: 'keywords-relations' })

  const { addRelation, addRelationBatch } = useTopicsService()

  const { dispatch } = useEvents<TopicRelationEvent>()

  async function addMultipleRelations(keywordHashes: string[], topicId: string) {
    const removeToast = addLoadingToast({ text: 'Adding relations...' })
    try {
      const keywords = keywordHashes
        .map(getKeywordById)
        .filter(Boolean)
        .map(keyword => ({
          keyword_hash: keyword?.keywordHash,
          keyword_text: keyword?.text
        })) as KeywordPayload[]

      await addRelationBatch(topicId, keywords)
      await refreshThemes()

      updateKeywordsTopicIds({ ids: keywordHashes, topicId })

      removeToast()

      addSuccessToast({ text: 'Keywords added' })

      dispatch('update-keywords', {
        topicId,
        keywordHash: '',
        text: '',
        topicIds: [topicId],
        topics: [{ topicId, name: '' }]
      })
    } catch (error) {
      console.error(error)
      removeToast()
      const message = 'Failed to add some keywords to subtopic'
      logException(error, { message })

      addErrorToast({ text: message })
      throw new Error()
    }
  }

  async function addSingleRelation(keywordHash: string, topicId: string) {
    const toastId = `adding-${topicId}-${keywordHash}`
    const removeToast = addLoadingToast({ id: toastId, text: 'Adding keyword...' })

    try {
      await addRelation(topicId, keywordHash)
      refreshThemes()
      updateKeywordsTopicIds({ ids: keywordHash, topicId })

      addToast({ text: 'Keyword added', status: 'success', open: true, duration: 2500 })
    } catch (error) {
      console.error(error)
      const message = 'Failed to add keyword to subtopic'
      logException(error, { message })
      addToast({ text: message, status: 'error', open: true })
      removeToast()
      throw new Error()
    }

    removeToast()
  }

  async function createKeywordAndAddRelations({
    keywordText,
    topics,
    mergedTopicParents
  }: CreateKeywordAndAddRelationParams) {
    const removeToast = addLoadingToast({
      text: 'Creating new keyword...'
    })

    try {
      const keywordResponse = await KeywordService.createKeyword(keywordText)

      await Promise.all(
        topics.map(async ({ topicId }) => {
          await addRelation(topicId, keywordResponse.keyword_hash)
          updateKeywordsTopicIds({ ids: keywordResponse.keyword_hash, topicId })
        })
      )

      addKeywordTopicToAllFeedbackStores({
        text: keywordText,
        topics,
        keywordHash: keywordResponse.keyword_hash,
        sentiment: null,
        intention: []
      })

      if (mergedTopicParents?.length) {
        addKeywordTopicToAllFeedbackStores({
          text: keywordText,
          topics: mergedTopicParents,
          keywordHash: keywordResponse.keyword_hash,
          sentiment: null,
          intention: []
        })
      }

      removeToast()

      addSuccessToast({
        text: 'Keyword added'
      })

      setTimeout(() => {
        refreshThemes()
      }, REFRESH_THEMES_MAX_DELAY)

      return {
        keywordHash: keywordResponse.keyword_hash,
        text: keywordResponse.text
      } as KeywordBase
    } catch (e) {
      removeToast()

      if ((e as Error).message === 'already_existent_relation_error') {
        const message = 'The given topic already has the given keyword'
        logException(e, { message })
        addWarningToast({
          text: message,
          duration: 4000
        })
        return
      }

      if ((e as Error).message === 'keyword_cannot_created_exceeds_expansion_limit') {
        const message = 'The number of keywords created exceeds the limit'
        logException(e, { message })
        throw new Error('expansion_limit')
      }

      console.error(e)
      const message = 'Failed to create keyword'
      logException(e, { message })
      addErrorToast({ text: message })

      throw new Error()
    }
  }

  const applyTopicsToKeywords = useCallback(
    async ({
      keywords,
      selectedTopics,
      removedTopics,
      enableDispatch = true
    }: ApplyTopicsToKeywordsParams) => {
      if (keywords.length === 1) {
        const { keywordHash, text, topicIds, topics } = keywords[0]

        if (removedTopics.length) {
          await TopicService.removeKeywordRelationBatch(
            removedTopics.map(topicId => ({ topic_id: topicId, keyword_hash: [keywordHash] }))
          )
          enableDispatch &&
            removedTopics.forEach(topicId => {
              removeRelationsFromKeywords({ ids: keywordHash, topicId })
              dispatch('delete-relation', {
                keywordHash,
                topicId,
                text,
                topicIds: topicIds.filter(keywordTopicId => keywordTopicId !== topicId),
                topics: topics.filter(topic => topic.topicId !== topicId)
              })
            })
        }

        if (selectedTopics.length) {
          await Promise.all(
            selectedTopics.map(async topic => {
              const fullkeywords = keywords.map(keyword => ({
                keyword_hash: keyword.keywordHash,
                keyword_text: keyword.text
              })) as KeywordPayload[]
              const { name, topicId } = topic
              await addRelationBatch(topic.topicId, fullkeywords)

              updateKeywordsTopicIds({
                ids: keywordHash,
                topicId,
                topicName: name
              })

              enableDispatch &&
                dispatch('update-keywords', {
                  topicId,
                  keywordHash,
                  text,
                  topicIds: [...topicIds, topicId],
                  topics: [...topics, { name: name || '', topicId }]
                })
            })
          )
        }
      } else {
        const topicsToCreateRelation = keywords.reduce(
          (acc, currentKeyword) => {
            const _acc: { [x: string]: KeywordWithTopicIds[] } = { ...acc }
            const keyword = keywords.find(kw => kw.keywordHash === currentKeyword.keywordHash)

            if (keyword) {
              const { topicIds: keywordTopics } = keyword

              selectedTopics
                .filter(
                  selectedTopic =>
                    !keywordTopics.find(keywordTopic => keywordTopic === selectedTopic.topicId)
                )
                .forEach(selectedTopic => {
                  _acc[selectedTopic.topicId]
                    ? _acc[selectedTopic.topicId].push(currentKeyword)
                    : (_acc[selectedTopic.topicId] = [currentKeyword])
                })
            }

            return _acc
          },
          {} as { [x: string]: KeywordWithTopicIds[] }
        )

        await Promise.all(
          Object.keys(topicsToCreateRelation).map(async topicId => {
            const fullkeywords = topicsToCreateRelation[topicId].map(keyword => ({
              keyword_hash: keyword.keywordHash,
              keyword_text: keyword.text
            })) as KeywordPayload[]

            await addRelationBatch(topicId, fullkeywords)
            const topic = getTopicById(topicId)
            updateKeywordsTopicIds({
              ids: topicsToCreateRelation[topicId].map(kw => kw.keywordHash),
              topicId,
              topicName: topic?.topicName
            })
          })
        )
      }
    },
    [dispatch, removeRelationsFromKeywords, updateKeywordsTopicIds, addRelationBatch, getTopicById]
  )

  return {
    addMultipleRelations,
    addSingleRelation,
    createKeywordAndAddRelations,
    applyTopicsToKeywords
  }
}

export default useKeywordsRelations
