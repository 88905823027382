import { SavedFilter } from '@/types/filters/Filters'
import { create } from 'zustand'

interface SavedFiltersState {
  savedFilters: SavedFilter[]
  currentSavedFilter?: SavedFilter
  filterName: string
  isEditing: boolean

  setSavedFilters: (savedFilters: SavedFilter[]) => void
  setCurrentSavedFilter: (current: SavedFilter | undefined) => void
  setFilterName: (name: string) => void
  setIsEditing: (isEditing: boolean) => void
}

const useSavedFiltersStore = create<SavedFiltersState>(set => ({
  savedFilters: [],
  currentSavedFilter: undefined,
  filterName: '',
  isEditing: false,

  setSavedFilters: savedFilters => set(() => ({ savedFilters })),

  setCurrentSavedFilter: currentSavedFilter =>
    set(() => ({ currentSavedFilter, filterName: currentSavedFilter?.name || '' })),

  setFilterName: filterName => set(() => ({ filterName })),
  setIsEditing: isEditing => set(() => ({ isEditing }))
}))

export default useSavedFiltersStore
