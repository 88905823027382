import { SegmentationItemWithMetrics } from '@/types/segmentation/Segmentation'
import AreaOfInterestList from '../area-of-interest/AreaOfInterestList/AreaOfInterestList'
import FlexContainer from '@/components/atoms/flex-container'
import Checkbox from '@/components/atoms/checkbox'
import Text from '@/components/atoms/text'
import { useMemo } from 'react'
import shortUUID from 'short-uuid'
import { StyledLink } from '@/components/atoms/table-v2'

interface Props {
  data: SegmentationItemWithMetrics
  onSegmentationClick: (segmentation: SegmentationItemWithMetrics) => void
  showCheckbox?: boolean
  checked?: boolean
  checkboxDisabled?: boolean
  onCheckedChange?: (checked: boolean) => void
  disabled?: boolean
}

const SegmentationNameCell = ({
  data,
  onSegmentationClick,
  showCheckbox,
  checked,
  checkboxDisabled,
  onCheckedChange,
  disabled = false
}: Props) => {
  const handleClick = () => {
    if (disabled) return
    onSegmentationClick(data)
  }

  // TODO: do the toLink with short UUID
  const translator = useMemo(() => shortUUID(), [])
  const shortSegmentationId = useMemo(
    () => translator.fromUUID(data.segmentationId),
    [data.segmentationId, translator]
  )

  const toLink = disabled ? '' : `/segment/${shortSegmentationId}`

  return (
    <AreaOfInterestList.LongCellContainer
      alignItems="start"
      css={{
        position: 'relative',
        padding: '$xxs',
        pl: 36,
        textAlign: 'left',
        maxWidth: '100% !important'
      }}
      direction="column"
      fullWidth
      justifyContent="flexStart"
    >
      <FlexContainer alignItems="start" gap="nano">
        {showCheckbox && (
          <Checkbox
            checked={checked}
            disabled={checkboxDisabled}
            onCheckedChange={onCheckedChange}
            value={'checkbox-' + data.segmentationId}
          />
        )}
        <StyledLink aria-disabled={disabled} onClick={handleClick} to={toLink}>
          <Text typeface="subtitleRegularMicro">{data.name}</Text>
        </StyledLink>
      </FlexContainer>
    </AreaOfInterestList.LongCellContainer>
  )
}

export default SegmentationNameCell
