import FilterSubMenu from '../../AddFilterMenu/FilterSubMenu'

import { ReactNode, useState } from 'react'
import { FilterItem, NumericRangeOption } from '@/types/filters/Filters'
import { NumericFilterContent } from '../../NumericGenericFilter'
import useCustomerRecordsNumericFilter from './useCustomerRecordsNumericGenericFilter'
import { CustomerRecordType } from '@/types/manage-customers/CustomerRecordsFilters'

const defaultLabels: Record<NumericRangeOption, string> = {
  all: 'All values',
  between: 'Range',
  is: 'Exactly value',
  null: 'Empty values'
}

interface Props {
  filter: FilterItem
  labels?: Record<NumericRangeOption, string>
  icon?: ReactNode
  recordType: CustomerRecordType
  newFeed?: boolean
}

const CustomerRecordsNumericFilterSubmenu = ({
  filter,
  icon,
  labels = defaultLabels,
  recordType,
  newFeed = false
}: Props) => {
  const [open, setOpen] = useState(false)

  const {
    exactValue,
    exactValueText,
    isDisabled,
    isExactValueOpen,
    isLoading,
    isRangeOpen,
    max,
    min,
    onApply,
    onOptionChange,
    onRangeChange,
    onValueChange,
    rangeText,
    rangeValue,
    selectedOption
  } = useCustomerRecordsNumericFilter({
    ...filter,
    filterKey: filter.key,
    enabled: open,
    recordType,
    newFeed
  })

  return (
    <FilterSubMenu
      icon={icon}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onApply={onApply}
      onOpenChange={setOpen}
      open={open}
      title={filter.displayName}
    >
      <NumericFilterContent
        exactValue={exactValue}
        exactValueText={exactValueText}
        isExactValueOpen={isExactValueOpen}
        isRangeOpen={isRangeOpen}
        labels={labels}
        max={max}
        min={min}
        onOptionChange={onOptionChange}
        onRangeChange={onRangeChange}
        onValueChange={onValueChange}
        rangeText={rangeText}
        rangeValue={rangeValue}
        selectedOption={selectedOption}
      />
    </FilterSubMenu>
  )
}

export default CustomerRecordsNumericFilterSubmenu
