import { styled } from '@/theme'
import IllustrationSvg from '@/assets/drawings/bird_pointing.svg'
import Lottie from 'lottie-react'
import loadingBouncingAnimation from '@/assets/lottie/loading_bouncing.json'

const Container = styled('div', {
  borderRadius: 8,
  width: '100%',
  height: 440,
  background: '$neutralHighMedium',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative'
})

const LottieWrapper = styled('div', {
  position: 'absolute',
  width: 165,
  top: 80,
  right: 'calc(50% - 128px)'
})

const LoadingText = styled('span', {
  fontSize: '$xxs',
  color: '$neutralLowLight',
  position: 'absolute',
  top: 100,
  right: 'calc(50% - 126px)'
})

const LoadingChart = () => {
  return (
    <Container>
      <LoadingText>Updating your chart...</LoadingText>
      <img
        alt="An illustration of a blue bird poiting to a loading animation"
        src={IllustrationSvg}
      />
      <LottieWrapper>
        <Lottie animationData={loadingBouncingAnimation} loop />
      </LottieWrapper>
    </Container>
  )
}

export default LoadingChart
