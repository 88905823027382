import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'

export const Container = styled(FlexContainer, {
  bAll: '$greyBorder',
  br: '5px'
})

export const InnerLeftContainer = styled(FlexContainer, {
  bRight: '$greyBorder',
  pAll: '$xxxs $xxs',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$xxs'
})

export const InnerRightContainer = styled(FlexContainer, {
  flexDirection: 'column',
  flex: 1,

  '& p': {
    pAll: '$xxxs $xxs',
    bBottom: '$greyBorder',
    boxSizing: 'border-box'
  },

  '& p:last-child': {
    border: 'none',
    // TODO remove this when user name field is avaliable
    display: 'flex',
    alignItems: 'center',
    gap: '$nano',
    flex: 1
  }
})

export const IconContainer = styled('div', {
  position: 'relative',

  '& .check-icon': {
    position: 'absolute',
    right: '-8px',
    bottom: '-4px',
    color: '$green'
  }
})
