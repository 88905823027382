import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'

export const UsersTopbarContent = styled(FlexContainer, {
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '$micro',
  userSelect: 'none',

  variants: {
    disabled: {
      true: {
        cursor: 'default',

        '& .add-user-icon': {
          cursor: 'default'
        }
      },
      false: {
        cursor: 'pointer',

        '& .add-user-icon': {
          cursor: 'pointer'
        }
      }
    }
  }
})

export const MultipleUsersTopbar = styled(FlexContainer, {
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: '$neutralLowPure',

  '& .user-inital-circle': {
    marginRight: '-8px',
    border: '1px solid $neutralHighLight'
  },

  '& .add-user-icon': {
    background: '$white',
    zIndex: 6,
    border: '1px solid $grey',
    borderRadius: '36px',
    padding: 6,
    marginRight: '-8px'
  },

  '& .extra-users-count': {
    zIndex: 3,
    background: '$dark'
  },

  variants: {
    home: {
      true: {
        '& .user-inital-circle': {
          color: '$neutralHighLight',
          background: '$brandPrimaryMedium'
        },

        '& .add-user-icon': {
          color: '$brandPrimaryPure',
          background: '$neutralHighLight'
        },

        '& .extra-users-count': {
          color: '$brandPrimaryPure',
          background: '$feedbackWarningMedium'
        }
      },
      false: {}
    }
  },

  defaultVariants: {
    home: false
  }
})

export const UserInitial = styled(FlexContainer, {
  borderRadius: '50%',
  boxSizing: 'border-box',
  justifyContent: 'center',
  alignItems: 'center',
  size: 36,
  background: '$pajarito',
  padding: 6,
  color: '$white',
  fontWeight: 700,
  fontSize: 14,
  marginRight: 8,
  textTransform: 'uppercase'
})
