import useUser from '@/hooks/useUser'
import { styled } from '@/theme'

const Badge = styled('div', {
  background: '$neutralLowLight',
  borderRadius: 8,
  color: '$neutralHighLight',
  fontSize: '$micro',
  fontWeight: '$semibold',
  padding: '6px 8px'
})

const UserPlanBadge = () => {
  const { userPlan, currentUserPlan } = useUser()

  if (userPlan !== 'all' || currentUserPlan === null) return null

  const planName = currentUserPlan === 'opportunity' ? 'Opportunity plan' : 'Topic plan'
  return <Badge>{planName}</Badge>
}

export default UserPlanBadge
