import { RequestReturnType } from '@/services/RequestHandlers/NewRequest'
import { RawAreaError } from '@/types/area/AreaRequests'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { AllMetricsKey, MetricItem, MetricsRequests, RawMetric } from '@/types/metrics'
import {
  OpportunityItemWithMergedContext,
  OpportunityItemWithMetrics
} from '@/types/opportunity/Opportunity'
import {
  buildFilterListWithMergedContextOpp,
  getOppsMetrics,
  getOptimizedOppsMetrics,
  getRawMetricListWithAreaError
} from '@/utils/opportunityQueryUtils'
import { OPPORTUNITY_METRICS_SUPPORTED_NAMES } from '@/utils/opportunityUtils'

interface Params {
  opps: OpportunityItemWithMetrics[]
  sortingMetric?: MetricsRequests.MetricListPayloadItem
  currentFilterContext?: string
  errorMap: Record<string, RawAreaError>
  startDate?: string
  endDate?: string
  allMetricItems: Record<AllMetricsKey, MetricItem>
}

const getMetricToSortOpportunitesFn = async ({
  opps,
  sortingMetric,
  currentFilterContext,
  errorMap,
  startDate,
  endDate,
  allMetricItems
}: Params): Promise<RequestReturnType<OpportunityItemWithMergedContext[]>> => {
  let oppsMetrics: RawMetric[][]

  const filterList: FeedbackListQueryParams[] = buildFilterListWithMergedContextOpp(
    opps,
    {
      currentFilterContext
    },
    errorMap
  )

  const actualSortingMetric = sortingMetric ?? allMetricItems.count.metric

  if (OPPORTUNITY_METRICS_SUPPORTED_NAMES.includes(actualSortingMetric.name)) {
    const responses = await getOptimizedOppsMetrics({
      filterList,
      sortingMetric,
      startDate,
      endDate
    })
    const someResultWithError = responses.find(response => response[0])
    if (someResultWithError && someResultWithError[0]) {
      return [someResultWithError[0], undefined]
    }

    const data = responses.flatMap(response => response[1]) as RawMetric[][]
    oppsMetrics = data
  } else {
    const responses = await getOppsMetrics({ filterList, sortingMetric, startDate, endDate })
    const someResultWithError = responses.find(response => response[0])
    if (someResultWithError && someResultWithError[0]) {
      return [someResultWithError[0], undefined]
      // onError(
      //   someResultWithError[0],
      //   `Failed to fetch metric "${actualSortingMetric.name}/${actualSortingMetric.label}" to sort opportunities`
      // )

      // throw someResultWithError[0]
    }

    const data = responses.flatMap(response => response[1]) as RawMetric[][]
    oppsMetrics = data
  }

  const newOpps = opps.map((item, index) => {
    return {
      ...item,
      metrics: getRawMetricListWithAreaError(item.id, oppsMetrics[index], errorMap)
    }
  }) as OpportunityItemWithMetrics[]

  return [undefined, newOpps]
}

export default getMetricToSortOpportunitesFn
