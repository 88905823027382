import { FilterField, FilterItem } from '@/types/filters/Filters'
import { useMemo } from 'react'
import { FEED_FILTERS_BLACKLIST } from '@/utils/filters'
import useFieldsQuery from '../useFieldsQuery'
import { getFeedbackKindPrettyName } from '@/utils/feedback'
import { snakeToTitle } from '@/utils/letterCase'

const formatFilterTitle = (field: FilterField): string => {
  if (field.description) return field.description
  const splittedFieldName = field.name.split('.')
  const prefixFormatted = getFeedbackKindPrettyName(splittedFieldName[0])
  const remainingFormatted = splittedFieldName
    .slice(1)
    .map(name => snakeToTitle(name))
    .join(' ')
  return `${prefixFormatted} ${remainingFormatted}`
}

const fieldToFilterItem = (field: FilterField): FilterItem => ({
  key: field.name,
  type: field.type,
  displayName: formatFilterTitle(field),
  name: field.name,
  path: field.path,
  description: field.description
})

const useFeedFilters = () => {
  const { data, isLoading } = useFieldsQuery()

  const allFilters: FilterItem[] = useMemo(() => {
    if (!data) return []

    return data
      .map(fieldToFilterItem)
      .filter(filter => !FEED_FILTERS_BLACKLIST.includes(filter.name))
  }, [data])

  const customFieldsFilters: FilterItem[] = useMemo(
    () => allFilters.filter(filter => filter.name.startsWith('custom_fields')),
    [allFilters]
  )

  const feedbackDetailsFilters: FilterItem[] = useMemo(
    () => allFilters.filter(filter => !filter.name.startsWith('custom_fields')),
    [allFilters]
  )

  const feedbackTypeFilter: FilterItem | null = useMemo(() => {
    const responseFilter = data?.find(field => field.name === 'source_alias')
    if (!responseFilter) return null

    return fieldToFilterItem(responseFilter)
  }, [data])

  return {
    // allFilters,
    customFieldsFilters,
    feedbackDetailsFilters,
    feedbackTypeFilter,
    isLoading
  }
}

export default useFeedFilters
