// reference: https://codesandbox.io/s/objective-shape-8r4utm?file=/src/CalendarGrid.js

import { AriaCalendarGridProps, useCalendarGrid } from '@react-aria/calendar'
import { getWeeksInMonth } from '@internationalized/date'
import { useLocale } from '@react-aria/i18n'

import type { CalendarState } from '@react-stately/calendar'
import type { DateDuration } from '@internationalized/date'

import { CalendarCell } from './CalendarCell'
import { styled } from '@/theme'

const StyledTable = styled('table', {
  margin: 0
})

const Week = styled('th', {
  fontSize: 10,
  fontWeight: 'bold',
  color: '$dark',
  paddingBottom: '8px'
})

export interface CalendarGridProps extends AriaCalendarGridProps {
  state: CalendarState
  offset?: DateDuration
}

export function CalendarGrid({ state, ...props }: CalendarGridProps) {
  const { locale } = useLocale()
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state)

  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale)
  const weeks = [...new Array(weeksInMonth).keys()]

  return (
    <StyledTable {...gridProps} cellPadding="0" cellSpacing={0}>
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            <Week key={index}>{day}</Week>
          ))}
        </tr>
      </thead>

      <tbody>
        {weeks.map(weekIndex => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) =>
                date ? <CalendarCell date={date} key={i} state={state} /> : <td key={i} />
              )}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}
