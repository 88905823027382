import { keyframes } from '@stitches/react'

const slideUpAndFade = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateY(2px)'
  },
  '100%': {
    opacity: 1,
    transform: 'translateY(0)'
  }
})

const slideRightAndFade = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateX(-2px)'
  },
  '100%': {
    opacity: 1,
    transform: 'translateX(0)'
  }
})

const slideDownAndFade = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateY(-2px)'
  },
  '100%': {
    opacity: 1,
    transform: 'translateY(0)'
  }
})

const slideLeftAndFade = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateX(2px)'
  },
  '100%': {
    opacity: 1,
    transform: 'translateX(0)'
  }
})

export const slideAndFadeOpen = {
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="open"], &[data-state="delayed-open"]': {
      '&[data-side="top"]': {
        animationName: slideDownAndFade
      },
      '&[data-side="right"]': {
        animationName: slideRightAndFade
      },
      '&[data-side="bottom"]': {
        animationName: slideUpAndFade
      },
      '&[data-side="left"]': {
        animationName: slideLeftAndFade
      }
    }
  }
}

const growKeyframes = keyframes({
  '0%': {
    transform: 'scaleX(0)'
  },
  '100%': {
    transform: 'scaleX(1)'
  }
})

export const growAnimation = {
  '@media (prefers-reduced-motion: no-preference)': {
    transformOrigin: 'left',
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    animationName: growKeyframes
  }
}

const growAndFadeKeyframes = keyframes({
  '0%': {
    transform: 'scale(.5)',
    // background: 'red',
    opacity: 0
  },
  '100%': {
    transform: 'scale(1)',
    // background: 'transparent',
    opacity: 1
  }
})

export const growAndFadeAnimation = {
  '@media (prefers-reduced-motion: no-preference)': {
    transformOrigin: 'left top',
    animationDuration: '300ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    animationName: growAndFadeKeyframes
  }
}

export const slideDown = keyframes({
  from: { height: 0 },
  to: {
    height: 'var(--radix-accordion-content-height)'
  }
})

export const slideUp = keyframes({
  from: {
    height: 'var(--radix-accordion-content-height)'
  },
  to: { height: 0 }
})

export const slideDownWithOpacity = keyframes({
  from: { opacity: 0, height: 0 },
  to: {
    opacity: 1,
    height: 'var(--radix-accordion-content-height)'
  }
})

export const slideUpWithOpacity = keyframes({
  from: {
    opacity: 1,
    height: 'var(--radix-accordion-content-height)'
  },
  to: { opacity: 0, height: 0 }
})
