import { ChangeEvent, InputHTMLAttributes, useRef, useState } from 'react'
import { Download } from '@phosphor-icons/react'

import { CSS, colors } from '@/theme'
import Text from '../text'
import { UploadButton, UploadContainer, UploadInput } from './UploadFile.styles'
import { useTranslation } from 'react-i18next'

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 5000000 // 5mb

export interface UploadFileProps extends InputHTMLAttributes<HTMLInputElement> {
  description?: string
  onClickButton?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  /** When true the component will not handle input events */
  overrideDefaultController?: boolean
  onFileSelected?: (value: File[]) => void
  maxFileSizeInBytes?: number
  details?: string
  css?: CSS
}

const UploadFile = ({
  onFileSelected,
  onClickButton,
  overrideDefaultController,
  css,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...props
}: UploadFileProps) => {
  const fileInputField = useRef<HTMLInputElement>(null)
  const [files, setFiles] = useState<File[]>([])

  const { t } = useTranslation()
  const handleUploadBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (overrideDefaultController) {
      onClickButton && onClickButton(e)
      return
    }
    fileInputField.current?.click()
  }

  const addNewFiles = (selectedFiles: FileList) => {
    const newFiles: File[] = []
    for (const file of selectedFiles) {
      if (file.size <= maxFileSizeInBytes) {
        newFiles.push(file)
      }
    }
    return [...files, ...newFiles]
  }

  const handleNewFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (overrideDefaultController && props.onChange) {
      return props.onChange(e)
    }

    const { files: newFiles } = e.target
    if (newFiles?.length) {
      const updatedFiles = addNewFiles(newFiles)
      setFiles(updatedFiles)
      onFileSelected && onFileSelected(updatedFiles)
    }
  }

  return (
    <UploadContainer css={css}>
      <UploadButton onClick={handleUploadBtnClick} type="button">
        <Download color={colors.brandPrimaryPure} size={80} weight="fill" />
        <Text color="brandPrimaryPure" typeface="subtitleRegularXXXS">
          {t('selectACsvFileToImport')}
        </Text>
      </UploadButton>
      <UploadInput {...props} onChange={handleNewFileUpload} ref={fileInputField} type="file" />
    </UploadContainer>
  )
}

export default UploadFile
