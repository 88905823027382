import Button from '@/components/atoms/button'
import Dialog from '@/components/atoms/dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { useTranslation } from 'react-i18next'
import useSegment from '@/hooks/useSegment'
import { useLocation, useNavigate } from 'react-router-dom'

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
}

const CancelCalibrationDialog = ({ open, onOpenChange }: Props) => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const navigate = useNavigate()
  const location = useLocation()
  const basePath = location.pathname.split('/calibration')[0]

  const onGiveUpClick = () => {
    onOpenChange(false)
    navigate(basePath)
    track('calibrate_opp_giveup_dialog_giveup')
  }

  const onBackToCalibrationClick = () => {
    onOpenChange(false)
    track('calibrate_opp_giveup_dialog_back')
  }

  const onCloseClick = () => {
    onOpenChange(false)
    track('calibrate_opp_giveup_dialog_close')
  }

  return (
    <Dialog
      align="center"
      closeIcon
      modal
      onClose={onCloseClick}
      onOpenChange={onOpenChange}
      open={open}
    >
      <FlexContainer direction="column" fullHeight gap="xs">
        <FlexContainer>
          <Text as="h1" typeface="titleBoldXXS">
            {t('calibrateOpportunityDialogGiveupTitle')}
          </Text>
        </FlexContainer>

        <FlexContainer direction="column" gap="xxs">
          <Text as="p" fontSize="xxxs">
            {t('calibrateOpportunityDialogGiveupBody1')}
          </Text>
          <Text as="p" fontSize="xxxs">
            {t('calibrateOpportunityDialogGiveupBody2')}
          </Text>
        </FlexContainer>

        <Divider line="solid" orientation="horizontal" />
        <FlexContainer direction="column" gap="xxs">
          <Button fullWidth onClick={onGiveUpClick} size="small">
            {t('calibrateOpportunityDialogGiveupConfirm')}
          </Button>

          <Button fullWidth onClick={onBackToCalibrationClick} size="small" variant="flat">
            {t('calibrateOpportunityDialogGiveupCancel')}
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Dialog>
  )
}

export default CancelCalibrationDialog
