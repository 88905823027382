import { Clock, TrendUp } from '@phosphor-icons/react'
import Card from '../../card/Card'
import useTrendLine from '@/hooks/analytics/useTrendLine'
import EChart from '@/components/atoms/echart'
import { EChartOption } from 'echarts'
import { useMemo } from 'react'
import ChartSkeleton from '../skeletons/ChartSkeleton'
import FlexContainer from '@/components/atoms/flex-container'
import Select from '@/components/atoms/select'
import getChartOptions from '../../dashboard/chartOptions'
import { TimeSeriesChartProps } from '@/types/analytics/Compare'
import { TimeSeriesField } from '@/types/analytics/AnalyticsRequests'
import { colors } from '@/theme'
import useComparisonText from '@/hooks/useComparisonText'
import { useFiltersStore } from '@/store'

interface Props {
  topicId?: string
  asExploreFeedbackItem?: boolean
}

const AnalyticsTrendLine = ({ topicId, asExploreFeedbackItem = false }: Props) => {
  const {
    data,
    isLoading,
    field,
    fieldOptions,
    onFieldChange,
    interval,
    intervalOptions,
    onIntervalChange,
    metric,
    metricOptions,
    onMetricChange,
    chartType,
    chartTypeOptions,
    onChartTypeChange,
    volumeBy
  } = useTrendLine({ topicId, asExploreFeedbackItem })

  const { label } = useComparisonText()

  const { datePeriod } = useFiltersStore(state => ({
    datePeriod: state.datePeriod
  }))

  const compare = field === TimeSeriesField.All

  const timeSeries = useMemo(() => {
    if (compare) {
      return data?.values ?? []
    }

    return (
      data?.values
        .sort(
          (a, b) =>
            b.data.reduce((acc, cur) => cur.countFeedback + acc, 0) -
            a.data.reduce((acc, cur) => cur.countFeedback + acc, 0)
        )
        .slice(0, 10) ?? []
    )
  }, [data, compare])

  const timeSeriesProps: TimeSeriesChartProps = useMemo(
    () => ({
      intervalOption: interval,
      metricOption: metric,
      timeSeries,
      volumeBy,
      compare
    }),
    [compare, interval, metric, timeSeries, volumeBy]
  )

  const grid: EChartOption.Grid = {
    top: '16%',
    left: '5%',
    right: '5%',
    bottom: '1%',
    containLabel: true
  }

  const legend: EChartOption.Legend = useMemo(
    () => ({
      width: 1000,
      height: 60,
      top: 0,
      padding: 0,
      data: (compare
        ? [
            {
              name: label.previous,
              icon: 'roundRect',
              itemStyle: {
                borderWidth: 1,
                borderType: 'dashed',
                borderColor: colors.brandPrimaryMedium,
                color: 'transparent'
              }
            },
            {
              name: label.current,
              icon: 'roundRect'
            }
          ]
        : // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          undefined) as any
    }),
    [compare, label.current, label.previous]
  )

  const lineSeries = useMemo(() => {
    if (compare) {
      const currentTimeSeriesData = timeSeries[0]?.data
      if (!currentTimeSeriesData) {
        return []
      }

      const comparisonTimeSeriesData = timeSeries[1]?.data
      if (!comparisonTimeSeriesData) {
        return []
      }

      const series: EChartOption.Series[] = [
        {
          data: currentTimeSeriesData.map(metrics =>
            metric === 'count' ? metrics.countFeedback : metrics.percentageFeedback
          ),
          type: 'line',
          name: label.current,
          itemStyle: {
            color: colors.brandPrimaryPure
          },
          showSymbol: false
        },
        {
          data: comparisonTimeSeriesData.map(metrics =>
            metric === 'count' ? metrics.countFeedback : metrics.percentageFeedback
          ),
          type: 'line',
          name: label.previous,
          itemStyle: {
            color: colors.brandPrimaryTransparentModerate
          },
          lineStyle: {
            type: 'dashed'
          },
          showSymbol: false
        }
      ]

      if (datePeriod === 'allTime') return series.slice(0, 1)
      return series
    }

    return timeSeries.length
      ? timeSeries.map(
          (serie): EChartOption.Series => ({
            data: serie.data.map(metrics =>
              metric === 'count' ? metrics.countFeedback : metrics.percentageFeedback
            ),
            type: 'line',
            name: serie.fieldValue,
            showSymbol: false
          })
        )
      : [{ data: [], type: 'line' }]
  }, [compare, datePeriod, label.current, label.previous, metric, timeSeries])

  const lineChartOptions: EChartOption = useMemo(
    () =>
      getChartOptions({
        timeSeriesProps,
        grid,
        xAxis: {
          boundaryGap: [],
          axisTick: {
            show: false
          }
        },
        legend,
        enableDataZoom: false,
        disableSortSeries: compare,
        series: [...lineSeries]
      }),
    [lineSeries, compare, legend, timeSeriesProps]
  )

  const barSeries = useMemo(() => {
    const baseSerieProps = {
      type: 'bar',
      barGap: '5%',
      barCategoryGap: '10%',
      barMaxWidth: 40
    }
    if (compare) {
      const currentTimeSeriesData = timeSeries[0]?.data
      if (!currentTimeSeriesData) {
        return []
      }

      const comparisonTimeSeriesData = timeSeries[1]?.data
      if (!comparisonTimeSeriesData) {
        return []
      }

      const series: EChartOption.Series[] = [
        {
          data: comparisonTimeSeriesData.map(metrics =>
            metric === 'count' ? metrics.countFeedback : metrics.percentageFeedback
          ),
          ...baseSerieProps,
          itemStyle: {
            borderType: 'dashed',
            borderColor: '#81A3AE',
            borderWidth: 1,
            color: '#FFFFFF'
          },
          name: `${label.previous}`
        },
        {
          data: currentTimeSeriesData.map(metrics =>
            metric === 'count' ? metrics.countFeedback : metrics.percentageFeedback
          ),
          ...baseSerieProps,
          name: `${label.current}`
        }
      ]

      if (datePeriod === 'allTime') return series.slice(1)
      return series
    }

    return timeSeries.length
      ? timeSeries.map(
          (serie): EChartOption.Series => ({
            data: serie.data.map(metrics =>
              metric === 'count' ? metrics.countFeedback : metrics.percentageFeedback
            ),
            ...baseSerieProps,
            name: serie.fieldValue
          })
        )
      : [{ data: [], type: 'bar' }]
  }, [compare, datePeriod, label.current, label.previous, metric, timeSeries])

  const barChartOptions: EChartOption = useMemo(
    () =>
      getChartOptions({
        timeSeriesProps,
        grid: {
          ...grid,
          left: '10%',
          right: '10%'
        },
        legend,
        xAxis: {
          boundaryGap: [],
          axisTick: {
            show: false
          }
        },
        enableDataZoom: false,
        series: [...barSeries],
        disableSortSeries: compare
      }),
    [barSeries, compare, legend, timeSeriesProps]
  )

  const chartOptions = chartType === 'bar' ? barChartOptions : lineChartOptions

  return (
    <Card.Root color="brandPrimaryPure" css={{ height: 380 }} direction="column" gap="xs">
      <FlexContainer alignItems="center" justifyContent="spaceBetween">
        <Card.Header>
          <Card.IconBox>
            <TrendUp />
          </Card.IconBox>
          <Card.Title>Trend line</Card.Title>
        </Card.Header>
        <FlexContainer alignItems="center" gap="xxs">
          <Select
            hideCaret
            onValueChange={onFieldChange}
            options={fieldOptions}
            showSelectedIcon
            small
            value={field}
          />
          <Select
            hideCaret
            onValueChange={onMetricChange}
            options={metricOptions}
            showSelectedIcon
            small
            value={metric}
          />
          <Select
            hideCaret
            leftIcon={<Clock size={16} />}
            onValueChange={onIntervalChange}
            options={intervalOptions}
            small
            value={interval}
          />
          <Select
            hideCaret
            onValueChange={onChartTypeChange}
            options={chartTypeOptions}
            showSelectedIcon
            small
            value={chartType}
          />
        </FlexContainer>
      </FlexContainer>
      {isLoading ? (
        <ChartSkeleton height={280} />
      ) : (
        <EChart
          height="300px"
          key={field}
          notMerge={compare}
          option={chartOptions}
          style={{ marginLeft: -52, marginRight: -32 }}
        />
      )}
    </Card.Root>
  )
}

export default AnalyticsTrendLine
