import React, { forwardRef, useCallback, useEffect, useMemo } from 'react'
import reactStringReplace from 'react-string-replace'

import useLetterCase from '@/hooks/useLetterCase'
import useTextSelection from '@/hooks/useTextSelection'

import {
  FeedbackContainer,
  FeedbackItemSeparator,
  FeedbackSearchCountBanner
} from './FeedbackItem.styles'
import { FeedbackItemProps } from './FeedbackItem.types'
import FeedbackItemHeader from './FeedbackItemHeader'
import { shallow } from 'zustand/shallow'
import { ArrowSquareOut, Chats } from '@phosphor-icons/react'
import Chip from '@/components/atoms/chip'
import FeedbackTopicChip from './FeedbackTopicChip'
import useParse from '@/hooks/useParse'
import Divider from '@/components/atoms/divider'
import { useFiltersStore } from '@/store'
import Button from '@/components/atoms/button'

const SATISFACTION_KINDS = ['csat', 'nps', 'review', 'reviews']
const checkIsEmptyFeedback = (type: string, text?: string) => {
  if (!SATISFACTION_KINDS.includes(type)) return false

  if (!text) return true

  return text.trim().length === 0 || ['blank', '"blank"'].includes(text)
}

const FeedbackItem = forwardRef<HTMLElement, FeedbackItemProps>(
  ({ css, className, ...props }, ref) => {
    const { shortUrls } = useLetterCase()
    const { parseLinks } = useParse()

    const filteredKeywordTopicList = useMemo(
      () => props.keywordTopicList.filter(keywordTopic => keywordTopic.topics?.length > 0),
      [props.keywordTopicList]
    )

    const getHighlight = useCallback(
      (sourceText: string | undefined) => {
        if (!sourceText) return []

        let replacedString: string | React.ReactNode[] = sourceText

        replacedString = shortUrls(replacedString)
        replacedString = parseLinks(replacedString)

        replacedString = replacedString.replace(/(\n{3,})/g, '\n\n')

        replacedString = reactStringReplace(replacedString, '\n', (match, index, offset) => (
          <br key={match + index + offset} />
        )) as React.ReactNode[]

        replacedString = reactStringReplace(
          replacedString,
          /<em>(.*?)<\/em>/gimu,
          (searchResults, index, offset) => (
            <em key={`${searchResults}-${index}-${offset}`}>{searchResults}</em>
          )
        ) as React.ReactNode[]

        return replacedString
      },
      [parseLinks, shortUrls]
    )

    const highlightedTitle = useMemo(() => getHighlight(props.title), [props.title, getHighlight])
    const highlightedText = useMemo(() => getHighlight(props.text), [props.text, getHighlight])

    const [titleWithSelection, titleRef, onSelectTitle] = useTextSelection<HTMLHeadingElement>(
      highlightedTitle,
      filteredKeywordTopicList
    )
    const [textWithSelection, textRef, onSelectText] = useTextSelection<HTMLParagraphElement>(
      highlightedText,
      filteredKeywordTopicList
    )

    const ticketCount = props.ticket?.count && props.ticket.count > 1 ? props.ticket.count - 1 : 0
    const repliesCount = props.isThreadItem ? 0 : ticketCount

    const feedbackIndex =
      props.enableHighlightOnFocus && props.index !== undefined ? `${props.index + 1}` : undefined

    useEffect(() => {
      if (props.isHighlighted && feedbackIndex) {
        const feedbackElement = document.getElementById(feedbackIndex)
        if (!feedbackElement) {
          return
        }

        feedbackElement.scrollIntoView({ behavior: 'smooth' })
      }
    }, [props.isHighlighted, feedbackIndex])

    const searchText = useFiltersStore(state => state.search, shallow)
    const searchCount = props.ticket?.searchCount ?? 0

    const feedbackType = (props.dataType || props.feedbackType || '').toLowerCase()
    const isEmptySatisfactionFeedback = checkIsEmptyFeedback(feedbackType, props.text)

    return (
      <FeedbackContainer
        className={className}
        css={css}
        hasReplies={false}
        id={feedbackIndex}
        isArchived={props.archived}
        isSelected={props?.selectedId === props.feedbackId || props.isHighlighted}
        ref={ref}
      >
        <FeedbackItemHeader {...props} />

        <FeedbackItemSeparator />

        {repliesCount > 0 && (
          <>
            <Chip
              color="$brandPrimaryLight"
              css={{
                color: '$brandPrimaryPure',
                fontWeight: '$semibold',
                fontSize: '$xxxs',
                cursor: 'pointer'
              }}
              icon={<Chats size={16} weight="fill" />}
              label={`${repliesCount} ${repliesCount > 1 ? 'Messages' : 'Message'}`}
              onClick={props.onRepliesClick}
              showDeleteIcon={false}
            />
          </>
        )}

        {highlightedTitle.length > 0 && (
          <h4 onMouseUp={onSelectTitle} ref={titleRef}>
            {titleWithSelection}
          </h4>
        )}
        {highlightedText.length > 0 && (
          <p
            className={isEmptySatisfactionFeedback ? 'muted-feedback-text' : ''}
            onMouseUp={onSelectText}
            ref={textRef}
          >
            {isEmptySatisfactionFeedback ? 'No comments left by user.' : textWithSelection}
          </p>
        )}

        <FeedbackTopicChip {...props} />

        {searchCount > 0 && props.showConversationSearch && (
          <>
            <Divider line="solid" orientation="horizontal" />
            <FeedbackSearchCountBanner>
              <p>
                We found{' '}
                <strong>
                  {searchCount} {searchCount === 1 ? 'message' : 'messages'} with{' '}
                  <q>{searchText}</q>{' '}
                </strong>
                in the conversation.
              </p>
              <Button onClick={props.onConversationSearchClick} size="small" variant="flat">
                <ArrowSquareOut />
                <span>See all</span>
              </Button>
            </FeedbackSearchCountBanner>
          </>
        )}
      </FeedbackContainer>
    )
  }
)

export default FeedbackItem
