import { GenericFeatureToggle } from './types'
import GenericFeatureToggleForm, { ParamsFormProps } from './GenericFeatureToggleForm'
import { CalibrationParamsData } from '@/types/params/CalibrationParams'

const featureToggles: GenericFeatureToggle<keyof CalibrationParamsData>[] = [
  {
    label: 'Enable calibration',
    name: 'enabled'
  }
]

const CalibrationForm = ({ data, onSave, onReset }: ParamsFormProps<CalibrationParamsData>) => (
  <GenericFeatureToggleForm
    data={data}
    featureToggleList={featureToggles}
    onReset={onReset}
    onSave={onSave}
    title="Calibration"
  />
)

export default CalibrationForm
