import { MagnifyingGlass } from '@phosphor-icons/react'

import Input from '@/components/atoms/input'
import { styled, typefaceVariants } from '@/theme'

export const SearchIcon = styled(MagnifyingGlass, {
  color: '$neutralLowLight',
  height: '100%',
  left: '$micro',
  position: 'absolute'
})

export const SearchInput = styled(Input, {
  ...typefaceVariants.subtitleRegularXXS,
  boxSizing: 'border-box',
  paddingLeft: '$md',
  width: 480
})

export const Separator = styled('hr', {
  backgroundColor: '$neutralHighPure',
  border: 0,
  height: '1px',
  width: '100%'
})
