import useUser from './useUser'
import { useCallback } from 'react'
import AudienceService from '@/services/AudienceService'
import useToastMessageStore from '@/store/useToastMessageStore'
import useCustomerUsersStore from '@/store/useCustomerUsersStore'
import { InfiniteData, QueryFunctionContext, useQueryClient } from '@tanstack/react-query'
import { UsersResponse } from '@/types/manage-customers'
import useLogging from './useLogging'

const useCustomerUsers = () => {
  const { currentUser } = useUser()
  const queryClient = useQueryClient()

  const push = useCustomerUsersStore(state => state.push)
  const setTotal = useCustomerUsersStore(state => state.setTotal)
  const setLoading = useCustomerUsersStore(state => state.setLoading)
  const setError = useCustomerUsersStore(state => state.setError)
  const removeUser = useCustomerUsersStore(state => state.removeUser)

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)

  const { logException } = useLogging({ context: 'customer-users' })

  const loadCustomerUsers = useCallback(
    async (params: QueryFunctionContext<[string, { q: string; perPage?: number }]>) => {
      setLoading(true)

      const { pageParam, signal, queryKey } = params
      const { q, perPage } = queryKey[1]
      const [error, data] = await AudienceService.getUsers(
        {
          orgId: currentUser?.organization_id || '',
          per_page: perPage ?? 50,
          cursor: pageParam,
          q
        },
        signal
      )
      if (error) {
        setLoading(false)
        if (error.isCanceledError) return undefined
        setTotal(0)
        setError(Error(error.key))

        const message = 'Failed to fetch for users.'
        logException(error, { message })
        addErrorToast({
          text: message,
          duration: 4500
        })
        throw error
      }

      setLoading(false)
      setError(null)
      push(data.users)
      setTotal(data.total)

      return { ...data, nextPage: pageParam && !data.users.length ? undefined : data.nextPage }
    },
    [currentUser, addErrorToast, logException, push, setLoading, setError, setTotal]
  )

  const deleteUser = async (userId: string) => {
    const removeToast = addLoadingToast({ text: 'Deleting user...' })
    const [error] = await AudienceService.deleteUser(userId)
    if (error) {
      removeToast()
      const message = 'Failed to delete user.'
      logException(error, { message })
      addErrorToast({
        text: message,
        duration: 4500
      })
      return
    }

    queryClient.setQueriesData(['customer-users'], (oldData?: InfiniteData<UsersResponse>) => {
      if (!oldData || !oldData.pages.length) return oldData

      const pages = oldData.pages.map(page => ({
        ...page,
        users: page.users.filter(user => user.id !== userId)
      }))
      pages[0].total = pages[0].total - 1

      return { ...oldData, pages }
    })
    removeUser(userId)
    removeToast()
    addSuccessToast({ text: 'User deleted.' })
  }

  return { loadCustomerUsers, deleteUser }
}

export default useCustomerUsers
