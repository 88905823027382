import FlexContainer from '@/components/atoms/flex-container'
import HomeMetricItem from './HomeMetricItem'
import Bone from '@/components/atoms/bone'
import AreaOfInterestList from '@/components/molecules/area-of-interest/AreaOfInterestList/AreaOfInterestList'
import useExplorationMetrics from '@/hooks/metrics/useExplorationMetrics'
import useCollections from '@/hooks/collections/useCollections'
import { useCallback, useMemo } from 'react'
import { getParamsFromFilterContent } from '@/utils/filters'
import { SavedFilterContent } from '@/types/filters/Filters'
import { MetricKey } from '@/types/metrics'
import { styled } from '@/theme'
import useCarouselArrows from '@/hooks/useCarouselArrows'
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'
import HomeArrowIndicator from './HomeArrowIndicator'
import useSegment from '@/hooks/useSegment'

const OuterContainer = styled(FlexContainer, {
  alignSelf: 'end',
  position: 'relative',
  scrollBehavior: 'smooth',
  zIndex: 1
})

const InnerContainer = styled(FlexContainer, {
  gap: '$md',
  width: '100%',
  maxWidth: 'calc(100vw - $navigationBarWidth - 312px)',
  overflow: 'auto hidden',
  scrollBehavior: 'smooth'
})

const OverallMetrics = () => {
  const { track } = useSegment()
  const {
    hasScroll,
    onScroll: onCarouselScroll,
    showLeftArrow,
    showRightArrow,
    viewportRef,
    updateHasScroll
  } = useCarouselArrows({
    rightOffset: 8,
    leftOffset: 8
  })

  const { contentData } = useCollections()

  const filters = useMemo(() => {
    const rawContent = contentData?.content
    const content = Array.isArray(rawContent) ? (rawContent as SavedFilterContent[]) : []

    return {
      ...getParamsFromFilterContent(content),
      context: contentData?.context
    }
  }, [contentData])

  const { metrics, isLoading, satisfactionCountItems } = useExplorationMetrics({
    filters: filters ? [{ ...filters }] : []
  })
  const loading = useMemo(() => isLoading, [isLoading])

  const getSatisfactionMetricValue = useCallback(
    (metricKey: MetricKey) => {
      const metricItem = satisfactionCountItems.find(metric => metric.metricKey === metricKey)
      if (!metricItem) return undefined

      return metricItem.currentValue
    },
    [satisfactionCountItems]
  )

  useDidUpdateEffect(() => {
    updateHasScroll()
  }, [metrics])

  const scrollTo = (direction: 'right' | 'left') => {
    if (!viewportRef.current) return

    if (direction === 'right' && showRightArrow) {
      viewportRef.current.scrollLeft += 232
      track('area_and_opps_metrics_scroll', { scroll: 'right' })
    }

    if (direction === 'left' && showLeftArrow) {
      viewportRef.current.scrollLeft -= 232
      track('area_and_opps_metrics_scroll', { scroll: 'left' })
    }
  }

  const onScroll = () => {
    onCarouselScroll()
    if (viewportRef.current && hasScroll) {
      track('area_and_opps_metrics_scroll', { scroll: 'scrollbar' })
    }
  }

  return (
    <OuterContainer>
      <HomeArrowIndicator
        visible={hasScroll && showLeftArrow}
        direction="left"
        onClick={() => scrollTo('left')}
      />

      <InnerContainer className="scroll-on-hover-container" ref={viewportRef} onScroll={onScroll}>
        {loading && (
          <>
            <Bone className="scroll-on-hover-child" css={{ width: 130, height: 70 }} />
            <Bone className="scroll-on-hover-child" css={{ width: 130, height: 70 }} />
            <Bone className="scroll-on-hover-child" css={{ width: 130, height: 70 }} />
            <Bone className="scroll-on-hover-child" css={{ width: 130, height: 70 }} />
          </>
        )}
        {!loading &&
          metrics.map(metric => (
            <HomeMetricItem
              css={{ minWidth: 122 }}
              key={metric.metricKey}
              title={metric.title}
              countMetricValue={getSatisfactionMetricValue(metric.metricKey)}
              value={metric.currentValue}
              variation={
                <AreaOfInterestList.Variation
                  css={{ fontSize: '$xxxs' }}
                  formatter={metric.formatter}
                  type={metric.variationType}
                  value={metric.variation}
                />
              }
            />
          ))}
      </InnerContainer>

      <HomeArrowIndicator
        visible={hasScroll && showRightArrow}
        direction="right"
        onClick={() => scrollTo('right')}
      />
    </OuterContainer>
  )
}

export default OverallMetrics
