import { useState } from 'react'
import FilterSubMenu from '../AddFilterMenu/FilterSubMenu'

import useStringGenericFilter from './useStringGenericFilter'
import StringFilterContent from './StringFilterContent'
import SearchInputForFilters from '../../SearchInputFilter'
import useDebounce from '@/hooks/useDebounce'

const ENABLED_SEARCH_TYPES = ['string', 'schema_fields_string', 'custom_fields_string']

export interface GenericFiltersSubSectionProps {
  previousTitle: string
  title: string
  type: string
  name: string
  filterKey: string
}

const StringFilterSubMenu = ({
  previousTitle,
  title,
  type,
  name,
  filterKey
}: GenericFiltersSubSectionProps) => {
  const [open, setOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const debouncedSearch = useDebounce(searchText, 500)

  const {
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isChecked,
    selectOption,
    hasChanges,
    apply,
    options,
    isShowMoreDisabled
  } = useStringGenericFilter({ type, name, filterKey, enabled: open, searchText: debouncedSearch })

  return (
    <FilterSubMenu
      isDisabled={!hasChanges}
      isLoading={isLoading}
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      previousTitle={previousTitle}
      searchComponent={
        ENABLED_SEARCH_TYPES.includes(type) ? (
          <SearchInputForFilters
            css={{ my: '$micro' }}
            onChange={e => setSearchText(e.currentTarget.value)}
            placeholder="Search"
            size="small"
            small
            value={searchText}
          />
        ) : undefined
      }
      title={title}
    >
      <StringFilterContent
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isChecked={isChecked}
        isFetchingNextPage={isFetchingNextPage}
        isShowMoreDisabled={isShowMoreDisabled}
        options={options}
        selectOption={selectOption}
      />
    </FilterSubMenu>
  )
}

export default StringFilterSubMenu
