import { Warning } from '@phosphor-icons/react'
import { integerFormatter, percentageFormatter, percentageScoreTransformer } from './formatters'
import { IssueMetricKey, MetricItem } from '@/types/metrics'
import i18n from '../../plugins/i18n/i18n'
// import { defaultHiddenMetrics } from '@/store/useHiddenMetricsStore'

// export const ISSUE_METRICS_KEYS: IssueMetricKey[] = ['issue', 'issue_share']

export const issueTimeSeries: Record<IssueMetricKey, MetricItem> = {
  issue: {
    label: i18n.t('count'),
    selectedLabel: i18n.t('issuesCount'),
    icon: Warning,
    mainMetricType: 'count',
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: `issue_count`,
      filter: { source_alias: 'issue' }
    }
  },
  issue_share: {
    label: i18n.t('percentCount'),
    selectedLabel: i18n.t('issuesPercentCount'),
    icon: Warning,
    mainMetricType: 'share',
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: `issue_share`,
      filter: { source_alias: 'issue' }
    }
  }
}

export const ISSUE_METRICS_KEYS = Object.keys(issueTimeSeries) as IssueMetricKey[]

export const ISSUE_DEFAULT_METRICS = ['issue']
