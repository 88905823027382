import { Fragment, useEffect } from 'react'
import { CaretLeft } from '@phosphor-icons/react'
import { Link, Outlet, useLocation } from 'react-router-dom'

import ErrorBoundary from '@/components/atoms/error-boundary'
import ServerError from '@/components/molecules/server-error'
import useErrorScreens from '@/hooks/useErrorScreens'
import useIntegrationsStore from '@/store/useIntegrationsStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import { styled } from '@/theme'
import useLogging from '@/hooks/useLogging'
import { useTranslation } from 'react-i18next'

const Breadcrumbs = styled('nav', {
  alignItems: 'center',
  display: 'flex',
  fontSize: '$xxxs',
  marginBottom: '$xs',
  gap: 4
})

const GoBack = styled(Link, {
  color: 'inherit',
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline'
  }
})

const Integrations = () => {
  const { pathname } = useLocation()
  const { logException } = useLogging({ context: 'integrations' })

  const { t } = useTranslation()
  const breadcrumbs = useIntegrationsStore(state => state.breadcrumbs)
  const loadConnectors = useIntegrationsStore(state => state.loadConnectors)
  const loadIntegrations = useIntegrationsStore(state => state.loadIntegrations)

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { checkServerError, hasServerError } = useErrorScreens()

  useEffect(() => {
    Promise.all([loadConnectors(), loadIntegrations()]).catch(error => {
      const message = t('errorInLoadIntegrationsData')
      logException(error, { message })
      addErrorToast({ text: message })
      checkServerError(error)
    })
  }, [addErrorToast, checkServerError, loadConnectors, loadIntegrations, logException, t])

  if (hasServerError) return <ServerError />

  return (
    <ErrorBoundary>
      {pathname !== '/settings/integrations' ? (
        <Breadcrumbs>
          <CaretLeft size={16} weight="bold" />
          {breadcrumbs.map(({ to, name }, index) => (
            <Fragment key={to}>
              <GoBack to={to}>{name}</GoBack>
              {index + 1 >= breadcrumbs.length ? '' : '/'}
            </Fragment>
          ))}
        </Breadcrumbs>
      ) : null}

      <Outlet />
    </ErrorBoundary>
  )
}

export default Integrations
