import { DeleteDialog } from '@/components/atoms/dialog'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  onConfirmPublish: () => void
}

const PublishCollectionAlert = ({ open, onOpenChange, onConfirmPublish }: Props) => {
  const { t } = useTranslation()

  return (
    <DeleteDialog
      cancelText={t('cancel')}
      confirmText={t('publish')}
      description={t(
        'thisCollectionIsPrivateAndOnlyYouCanAccessItOnceYouPublishItTheCollectionWillBecomeAvailableToTheEntireOrganization'
      )}
      isDeleting={false}
      onConfirmDelete={onConfirmPublish}
      onOpenChange={onOpenChange}
      open={open}
      title={t('publishThisCollectionQuestion')}
    />
  )
}

export default PublishCollectionAlert
