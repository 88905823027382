import FeedbackService from '@/services/FeedbackService'
import { useQuery } from '@tanstack/react-query'

const useFeedbackDetails = (feedbackId?: string) => {
  const queryFunction = async () => {
    if (!feedbackId) throw new Error('feedbackId is required')

    const [error, response] = await FeedbackService.getFeedbackItem(feedbackId)

    if (error) throw error
    return response
  }

  const query = useQuery({
    queryKey: ['feedback-search', 'details', feedbackId],
    queryFn: queryFunction,
    enabled: !!feedbackId
  })

  return query
}

export default useFeedbackDetails
