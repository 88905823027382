export const buildSafeSearchParams = (params: unknown) => {
  const searchParams = new URLSearchParams()
  if (!params) return searchParams
  for (const [key, value] of Object.entries(params)) {
    if (value !== null && value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach(item => {
          if (typeof item === 'object') {
            searchParams.append(key, JSON.stringify(item))
          } else {
            searchParams.append(key, item)
          }
        })
      } else if (typeof value === 'object') {
        searchParams.append(key, JSON.stringify(value))
      } else {
        searchParams.append(key, value as string)
      }
    }
  }

  return searchParams
}
