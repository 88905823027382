import { styled } from '@/theme'
import SorryBirdImage from '@/assets/drawings/sorry_bird.png'

const KeywordLimitContainer = styled('div', {
  padding: '$xs',
  gap: '$xxxs',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontSize: '$micro',
  color: '$neutralLowLight',

  p: {
    width: '100%'
  }
})

const KeywordLimit = () => {
  const imageDescription = 'A drawing of a red bird holding a sign with "Sorry" written on it.'
  return (
    <KeywordLimitContainer>
      <p>We&apos;re unable to extend a subtopic to more than 10,000 keywords.</p>
      <img alt={imageDescription} src={SorryBirdImage} />
      <p>Please select a longer keyword.</p>
    </KeywordLimitContainer>
  )
}

export default KeywordLimit
