import Input from '@/components/atoms/input'
import { shallow } from 'zustand/shallow'
import useSavedFiltersStore from '@/store/useSavedFiltersStore'
import { useEffect, useRef, useState } from 'react'
import { colors, styled, typefaceVariants } from '@/theme'
import Tooltip from '@/components/atoms/tooltip'
import useSavedFilters from '@/hooks/useSavedFilters'
import Text from '@/components/atoms/text'
import { X } from '@phosphor-icons/react'
import useUser from '@/hooks/useUser'

const SavedFilterName = styled('button', {
  all: 'unset',
  color: '$neutralLowPure',
  // maxWidth: 315,
  width: '100%',
  cursor: 'pointer',
  height: 40,
  pl: '0px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  gap: '$micro',
  '&:disabled': {
    cursor: 'default'
  },

  '& .clear-view-button': {
    visibility: 'hidden',
    lineHeight: 0
    // flex: 1
  },

  '&:hover': {
    '& .clear-view-button': {
      visibility: 'visible',
      backgroundColor: 'transparent',
      border: 0,
      cursor: 'pointer',
      height: 16,
      minWidth: '$xxs'
    }
  },

  variants: {
    placeholder: {
      true: {
        color: '$neutralLowLight'
      }
    }
    // hasChanges: {
    //   true: {
    //     maxWidth: 270
    //   }
    // }
  }
})

const StyledInput = styled(Input, {
  position: 'relative',
  fontSize: '$xxs',
  pl: '0px',
  pr: '$micro',
  ...typefaceVariants.subtitleRegularXXXS
})

interface Props {
  newFeed?: boolean
}

function SavedFiltersInput({ newFeed = false }: Props) {
  const filterName = useSavedFiltersStore(state => state.filterName, shallow)
  const setFilterName = useSavedFiltersStore(state => state.setFilterName)

  const isEditing = useSavedFiltersStore(state => state.isEditing, shallow)
  const setIsEditing = useSavedFiltersStore(state => state.setIsEditing)

  const currentName = useSavedFiltersStore(state => state.currentSavedFilter?.name, shallow)
  const { saveView, onStartBlankFilter } = useSavedFilters({ newFeed })

  const [enterPressed, setEnterPressed] = useState(false)

  const { userPermissions } = useUser()
  const isManager = userPermissions.filters.includes('manager')

  const inputRef = useRef<HTMLInputElement>(null)
  const clickedRef = useRef(false)

  const nameFallback = filterName || currentName || ''
  const placeholder = isManager
    ? 'Enter a name before saving your filters.'
    : 'You can use a saved filters by selecting one in the menu'

  useEffect(() => {
    if (inputRef.current && isEditing) {
      inputRef.current.focus()
      !clickedRef.current && inputRef.current.select()
    }
  }, [isEditing])

  useEffect(() => {
    if (enterPressed) {
      saveView(false)
      setEnterPressed(false)
    }
  }, [enterPressed, saveView])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    return () => setIsEditing(false)
  }, [])

  const onBlur = () => {
    setIsEditing(false)
    clickedRef.current = false
  }

  const onKeyUp: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      setIsEditing(false)

      setEnterPressed(filterName.length > 0 && filterName !== currentName)
      clickedRef.current = false
    }

    if (e.key === 'Escape') {
      setIsEditing(false)
      setFilterName(currentName ?? '')
      clickedRef.current = false
    }
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterName(event.currentTarget.value)
  }

  const onClickClearView = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    onStartBlankFilter(true)
  }

  const onClickPlaceHolder = () => {
    if (!isManager) return
    setIsEditing(true)
    clickedRef.current = true
  }

  const showTooltip = !filterName.length
  if (isEditing)
    return (
      <Tooltip open={showTooltip} side="bottom" text="Name the filters set before saving it.">
        <StyledInput
          autoFocus
          borderBottom
          maxLength={150}
          onBlur={onBlur}
          onChange={onChange}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          ref={inputRef}
          value={filterName}
        />
      </Tooltip>
    )

  return (
    <SavedFilterName
      disabled={!isManager}
      // hasChanges={hasChanges}
      onClick={onClickPlaceHolder}
      placeholder={!nameFallback.length}
    >
      <Text truncate typeface={'subtitleRegularXXXS'}>
        {nameFallback || placeholder}
      </Text>
      {nameFallback && (
        <div className="clear-view-button" onClick={onClickClearView}>
          <X color={colors.neutralLowLight} size={16} weight="bold" />
        </div>
      )}
    </SavedFilterName>
  )
}

export default SavedFiltersInput
