import { TopicCategory } from '@/types/classification'
import { ThemeItemData, TopicItemData } from '@/types/classification/Theme'
import { KeywordSuggestion, KeywordSuggestionMap } from '@/types/keywords'
import { persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface PersistedClassificationState {
  selectedCategory: TopicCategory
  setSelectedCategory: (category: TopicCategory) => void
}

export const usePersistedClassificationStore = createWithEqualityFn<PersistedClassificationState>()(
  persist(
    set => ({
      selectedCategory: 'OTHER',
      setSelectedCategory: category => set({ selectedCategory: category })
    }),
    { name: 'classifcation-store' }
  ),
  shallow
)

const unclassifiedTopics = {
  themeId: '',
  themeName: 'unclassified',
  topics: [],
  frequency: 0,
  sentimentMetrics: {
    positiveCount: 0,
    negativeCount: 0,
    negativePercentage: 0,
    netSentiment: 0,
    neutralCount: 0,
    neutralPercentage: 0,
    positivePercentage: 0
  }
}

interface ClassificationState {
  productAreaThemes: ThemeItemData[]
  otherThemes: ThemeItemData[]
  unclassifiedProductArea: ThemeItemData
  unclassifiedOtherThemes: ThemeItemData
  setThemesByCategory: (params: { themes: ThemeItemData[]; topicCategory: TopicCategory }) => void
  setThemes: (params: { productAreaThemes: ThemeItemData[]; otherThemes: ThemeItemData[] }) => void
  replaceThemes: (themes: ThemeItemData[]) => void
  pushTheme: (theme: ThemeItemData, category?: TopicCategory) => void
  pushTopic: (params: { topic: TopicItemData; themeId: string; category?: TopicCategory }) => void
  updateTheme: (params: { themeId: string; partialTheme: Partial<ThemeItemData> }) => void
  updateTopic: (params: {
    themeId: string
    topicId: string
    partialTopic: Partial<TopicItemData>
  }) => void
  removeTheme: (themeId: string) => void
  removeTopic: (params: { themeId: string; topicId: string }) => void

  keywordsSuggestions: KeywordSuggestionMap
  setSuggestionsForTopic: (params: { topicId: string; suggestions: KeywordSuggestion[] }) => void
  removeSuggestionFromTopic: (params: { topicId: string; keywordHash: string }) => void

  expandedTheme: ThemeItemData | null
  setExpandedTheme: (theme: ThemeItemData | null) => void

  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void

  isTopicsVerified: boolean
  setIsTopicsVerified: (isTopicsVerified: boolean) => void
}

const useClassificationStore = createWithEqualityFn<ClassificationState>(
  (set, get) => ({
    productAreaThemes: [],
    otherThemes: [],
    unclassifiedProductArea: unclassifiedTopics,
    unclassifiedOtherThemes: unclassifiedTopics,
    setThemesByCategory: ({ themes, topicCategory }) =>
      set(() => {
        const unclassified = themes.find((theme: ThemeItemData) => theme.themeId === null)

        const filteredThemes = themes.filter((theme: ThemeItemData) => theme.themeId !== null)
        return topicCategory === 'PRODUCT_AREA'
          ? {
              isLoading: false,
              unclassifiedTopics: unclassified,
              productAreaThemes: filteredThemes
            }
          : { isLoading: false, unclassifiedTopics: unclassified, otherThemes: filteredThemes }
      }),
    setThemes: ({ productAreaThemes, otherThemes }) =>
      set(() => {
        const unclassifiedProductArea = productAreaThemes.find(theme => theme.themeId === null)
        const filteredProductAreaThemes = productAreaThemes.filter(theme => theme.themeId !== null)
        const unclassifiedOtherThemes = otherThemes.find(theme => theme.themeId === null)
        const filteredOtherThemes = otherThemes.filter(theme => theme.themeId !== null)

        return {
          isLoading: false,
          unclassifiedProductArea,
          unclassifiedOtherThemes,
          productAreaThemes: filteredProductAreaThemes,
          otherThemes: filteredOtherThemes
        }
      }),
    replaceThemes: themes => {
      const { selectedCategory } = usePersistedClassificationStore.getState()
      const field: keyof ClassificationState =
        selectedCategory === 'PRODUCT_AREA' ? 'productAreaThemes' : 'otherThemes'
      const topics = themes.length ? themes.flatMap(theme => theme.topics) : []

      set(state => ({
        [field]: state[field].map(theme => {
          const themeFound = themes.find(newTheme => newTheme.themeId === theme.themeId)
          return themeFound
            ? {
                ...themeFound,
                topics: themeFound.topics.map(topic => {
                  const topicFound = topics.find(newTopic => newTopic.topicId === topic.topicId)
                  return topicFound ?? topic
                })
              }
            : theme
        })
      }))
    },
    pushTheme: (theme, categoryProperty) => {
      const { selectedCategory } = usePersistedClassificationStore.getState()
      const category = categoryProperty ?? selectedCategory
      const field: keyof ClassificationState =
        category === 'PRODUCT_AREA' ? 'productAreaThemes' : 'otherThemes'
      set(state => ({
        [field]: [...state[field], theme]
      }))
    },
    pushTopic: ({ topic, themeId, category: categoryProperty }) => {
      const { selectedCategory } = usePersistedClassificationStore.getState()
      const category = categoryProperty ?? selectedCategory
      const field: keyof ClassificationState =
        category === 'PRODUCT_AREA' ? 'productAreaThemes' : 'otherThemes'
      set(state => ({
        expandedTheme:
          state.expandedTheme && state.expandedTheme.themeId === themeId
            ? {
                ...state.expandedTheme,
                topics: [...state.expandedTheme?.topics, topic]
              }
            : state.expandedTheme,
        [field]: state[field].map(
          (theme): ThemeItemData =>
            theme.themeId === themeId
              ? {
                  ...theme,
                  topics: [...theme.topics, topic]
                }
              : theme
        )
      }))
    },
    updateTheme: ({ themeId, partialTheme }) => {
      const { selectedCategory } = usePersistedClassificationStore.getState()
      const field: keyof ClassificationState =
        selectedCategory === 'PRODUCT_AREA' ? 'productAreaThemes' : 'otherThemes'
      set(state => ({
        [field]: state[field].map(theme =>
          theme.themeId === themeId ? { ...theme, ...partialTheme } : theme
        )
      }))
    },
    updateTopic: ({ themeId, topicId, partialTopic }) => {
      const { selectedCategory } = usePersistedClassificationStore.getState()
      const field: keyof ClassificationState =
        selectedCategory === 'PRODUCT_AREA' ? 'productAreaThemes' : 'otherThemes'
      set(state => ({
        expandedTheme: state.expandedTheme
          ? {
              ...state.expandedTheme,
              topics: state.expandedTheme.topics.map(topic =>
                topic.topicId === topicId ? { ...topic, ...partialTopic } : topic
              )
            }
          : null,
        [field]: state[field].map(theme =>
          theme.themeId === themeId
            ? {
                ...theme,
                topics: theme.topics.map(topic =>
                  topic.topicId === topicId ? { ...topic, ...partialTopic } : topic
                )
              }
            : theme
        )
      }))
    },
    removeTheme: themeId => {
      const { selectedCategory } = usePersistedClassificationStore.getState()
      const field: keyof ClassificationState =
        selectedCategory === 'PRODUCT_AREA' ? 'productAreaThemes' : 'otherThemes'
      set(state => ({ [field]: state[field].filter(theme => theme.themeId !== themeId) }))
    },
    removeTopic: ({ themeId, topicId }) => {
      const { selectedCategory } = usePersistedClassificationStore.getState()
      const field: keyof ClassificationState =
        selectedCategory === 'PRODUCT_AREA' ? 'productAreaThemes' : 'otherThemes'
      set(state => ({
        expandedTheme: state.expandedTheme
          ? {
              ...state.expandedTheme,
              topics: state.expandedTheme.topics.filter(topic => topic.topicId !== topicId)
            }
          : null,
        [field]: state[field].map(theme =>
          theme.themeId === themeId
            ? {
                ...theme,
                topics: theme.topics.filter(topic => topic.topicId !== topicId)
              }
            : theme
        )
      }))
    },

    keywordsSuggestions: {},
    setSuggestionsForTopic: ({ topicId, suggestions }) =>
      set(state => ({
        keywordsSuggestions: {
          ...state.keywordsSuggestions,
          [topicId]: suggestions
        }
      })),
    removeSuggestionFromTopic: ({ topicId, keywordHash }) => {
      const { keywordsSuggestions } = get()
      if (keywordsSuggestions[topicId]) {
        set(state => ({
          keywordsSuggestions: {
            ...state.keywordsSuggestions,
            [topicId]: keywordsSuggestions[topicId].filter(
              suggestion => suggestion.keywordHash !== keywordHash
            )
          }
        }))
      }
    },

    expandedTheme: null,
    setExpandedTheme: themeId => set(() => ({ expandedTheme: themeId })),

    isLoading: false,
    setIsLoading: isLoading => set(() => ({ isLoading })),

    isTopicsVerified: false,
    setIsTopicsVerified: isTopicsVerified => set(() => ({ isTopicsVerified }))
  }),
  shallow
)

export default useClassificationStore
