import IntentionChip from '@/components/atoms/chip/IntentionChip'
import EChart from '@/components/atoms/echart'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { colors, styled } from '@/theme'
import { Reason, ReasonsTrend } from '@/types/reasons'
import { EChartOption, TooltipComponentFormatterCallbackParams } from 'echarts'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '../scroll-area'
import FeedbackItem from '../feedback-item'
import { useFeedbacks } from '@/hooks/feedback/useFeedbacks'
import { getDefaultSearchPayload } from '@/services/FeedbackService'
import ReasonsService from '@/services/ReasonsService'
import { useReasonsFeedbackStore } from '@/store/useFeedbackStore'
import { shallow } from 'zustand/shallow'
import { Feedback } from '@/types/feedbacks'
import { SearchPayload } from '@/types/feedbacks/FeedbackRequests'
import InfiniteScroll from 'react-infinite-scroll-component'
import ScrollLoader from '../feed/ScrollLoader'
import ChartSkeleton from '../analytics/skeletons/ChartSkeleton'
import { dateShortMonthFormat } from '@/utils/date'
import { parseAbsoluteToLocal } from '@internationalized/date'
import { useFiltersStore } from '@/store'
import Button from '@/components/atoms/button'
import { Chats, ChatText, Sparkle } from '@phosphor-icons/react'
import SummaryLoading from './SummaryLoading'
import useLetterCase from '@/hooks/useLetterCase'
import Tooltip from '@/components/atoms/tooltip'
import useSegment from '@/hooks/useSegment'
import useReasonSummaryState from '@/store/useReasonSummaryStore'

const ReasonsDetailsContainer = styled('div', {
  display: 'flex',
  height: '100%',
  width: '100%',
  flexGrow: 2,
  flexDirection: 'column',
  overflow: 'hidden',
  boxSizing: 'border-box',
  gap: '$xs',
  pl: '$sm',
  pt: '$sm'
})

const ReasonFeedbacksScrollArea = styled(ScrollAreaRoot, {
  minHeight: 1,
  pr: '$sm'
})

const SummaryContainer = styled('div', {
  alignSelf: 'flex-start',
  backgroundColor: '$neutralHighMedium',
  borderRadius: 4,
  padding: 16,
  width: '-webkit-fill-available'
})

const FeedbackCountContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  fontSize: 14,
  fontWeight: '$semibold',
  gap: '$nano'
})

interface ReasonDetailsProps extends Reason {
  keywordHashes: string[]
  trendSeries: ReasonsTrend
  isLoadingChart: boolean
  onOpenThread: (feedback: Feedback) => void
}

const ReasonDetails = ({
  name,
  trendSeries,
  intention,
  reasons,
  frequency,
  onOpenThread,
  isLoadingChart
}: ReasonDetailsProps) => {
  const { track } = useSegment()
  const { capitalizeFirst } = useLetterCase()
  const PAGE_SIZE = 100

  const {
    filteredFeedbacksTopics,
    total,
    archive,
    load: loadFeedbacks,
    isLoading,
    hasMore
  } = useFeedbacks({
    archived: false,
    store: 'reasons',
    pageSize: PAGE_SIZE
  })

  const { selectedFeedback } = useReasonsFeedbackStore(
    state => ({
      selectedFeedback: state.selectedFeedback
    }),
    shallow
  )
  const setSelectedFeedback = useReasonsFeedbackStore(state => state.setSelectedFeedback)

  const [isLoadingSummary, setIsLoadingSummary] = useState(false)
  const [isSummaryWithError, setIsSummaryWithError] = useState(false)

  const { dateRange, volumeBy } = useFiltersStore(
    state => ({
      dateRange: state.dateRange,
      volumeBy: state.volumeBy
    }),
    shallow
  )

  const payload: SearchPayload = useMemo(() => {
    const postedAt: PostedAt = dateRange
      ? {
          gte: dateRange.start.toString(),
          lte: dateRange.end.toString()
        }
      : {}

    const { filter_keyword: filterKeyword, ...remainingPayload } = getDefaultSearchPayload()
    const newFilterKeyword = { ...filterKeyword }
    newFilterKeyword.reason_hashes = reasons
    remainingPayload.filter.feedback_keyword_classes = intention ? [capitalizeFirst(intention)] : []
    remainingPayload.filter.posted_at = postedAt

    return { ...remainingPayload, filter_keyword: newFilterKeyword }
  }, [reasons, capitalizeFirst, dateRange, intention])

  const stateKey = useMemo(() => JSON.stringify(payload), [payload])

  const summaries = useReasonSummaryState(state => state.summaries, shallow)
  const summary = useMemo(() => summaries[stateKey], [stateKey, summaries])
  const setSummary = useReasonSummaryState(state => state.setSummary)

  function onFeedbacksScrollNext() {
    if (filteredFeedbacksTopics.length < PAGE_SIZE) return
    loadFeedbacks(payload)
  }

  const controller = useRef(new AbortController())

  async function fetchSummary() {
    track('findings_view_summary')
    setIsLoadingSummary(true)
    try {
      const response = await ReasonsService.summary({ feedback_filter: payload, focus: name })
      setIsLoadingSummary(false)
      setSummary(stateKey, response)
    } catch {
      setIsLoadingSummary(false)
      setIsSummaryWithError(true)
      setSummary(stateKey, undefined)
    }
  }

  useEffect(() => {
    if (frequency) {
      loadFeedbacks(payload)
    }

    return function cleanup() {
      controller.current.abort()
      controller.current = new AbortController()
    }
  }, [frequency, payload, loadFeedbacks])

  const showSummaryLoading = isLoadingSummary || isSummaryWithError
  const showSummaryButton = !isLoadingSummary && !summary

  function onSummaryRetry() {
    setIsSummaryWithError(false)
    fetchSummary()
  }

  function onSummaryCancel() {
    setSummary(stateKey, undefined)
    setIsSummaryWithError(false)
  }

  const options: EChartOption = useMemo(
    () => ({
      color: [colors.purple],
      grid: { show: false, top: 4, right: 12, left: 12, bottom: '5%', containLabel: true },
      legend: { show: false },
      xAxis: {
        show: true,
        type: 'time',
        boundaryGap: false,
        splitNumber: 2,
        axisTick: { show: false },
        axisLine: {
          lineStyle: {
            type: [2, 3],
            dashOffset: 5,
            color: colors.neutralLowLight
          }
        },
        axisLabel: {
          color: colors.neutralLowMedium,
          fontFamily: 'Lexend',
          align: 'center',
          formatter: {
            year: '{MMM}/{yyyy}',
            month: '{MMM}/{yyyy}'
          }
        }
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      } as any, // EChartOption type is not complete
      yAxis: { show: false },
      tooltip: {
        show: true,
        trigger: 'axis',
        formatter: ((params: TooltipComponentFormatterCallbackParams[]) => {
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          const [date, value] = (params[0] as any).data
          const formattedDate =
            dateShortMonthFormat(parseAbsoluteToLocal(`${date}Z`).toDate()) ?? date
          return `<strong>${formattedDate}</strong> <br> ${value} feedback`
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        }) as any,
        textStyle: {
          fontFamily: 'Lexend',
          fontWeight: 'normal'
        }
      },
      series: [
        {
          data: trendSeries.map(metrics => [metrics.postedAt, metrics.frequency]),
          type: 'line',
          showSymbol: false,
          connectNulls: false
        }
      ]
    }),
    [trendSeries]
  )

  const renderFeedbackCount = () => {
    if (isLoading) return null
    if (volumeBy === 'feedback-record') {
      return (
        <FeedbackCountContainer>
          <ChatText size={20} /> <span>{frequency} feedback</span>
        </FeedbackCountContainer>
      )
    }

    return (
      <FeedbackCountContainer>
        <Chats size={20} /> <span>{total} tickets</span>
      </FeedbackCountContainer>
    )
  }

  return (
    <ReasonsDetailsContainer>
      <FlexContainer css={{ mr: '$sm' }} justifyContent="spaceBetween">
        <IntentionChip intention={intention} />
        {showSummaryButton && (
          <Tooltip text="Summarize finding">
            <Button
              css={{ padding: '0 8px', height: 'auto' }}
              onClick={fetchSummary}
              type="button"
              variant="flat"
            >
              <Sparkle size={20} />
            </Button>
          </Tooltip>
        )}
      </FlexContainer>
      <Text css={{ mr: '$sm' }} typeface="titleBoldSmall">
        {name}
      </Text>

      <ReasonFeedbacksScrollArea>
        <ScrollAreaViewport id="scroll-reasons-feedback">
          <FlexContainer css={{ mb: '$xxs' }} direction="column" gap="xs">
            {showSummaryLoading && (
              <SummaryLoading
                error={isSummaryWithError}
                onCancel={onSummaryCancel}
                onRetry={onSummaryRetry}
              />
            )}
            {Boolean(summary) && (
              <SummaryContainer>
                <Text fontSize="xxxs" lineHeight="xl">
                  {summary}
                </Text>
              </SummaryContainer>
            )}

            {isLoadingChart ? (
              <ChartSkeleton height={120} />
            ) : (
              <EChart height="120px" option={options} style={{ maxWidth: '100%' }} />
            )}
            {renderFeedbackCount()}
          </FlexContainer>
          <InfiniteScroll
            dataLength={filteredFeedbacksTopics.length}
            endMessage={<FlexContainer css={{ mb: 4 }} />}
            hasMore={hasMore}
            loader={isLoading ? <ScrollLoader /> : ''}
            next={onFeedbacksScrollNext}
            scrollThreshold="350px"
            scrollableTarget="scroll-reasons-feedback"
          >
            <FlexContainer direction="column" gap="xxs">
              {filteredFeedbacksTopics.map(feedback => (
                <FeedbackItem
                  key={feedback.feedbackId}
                  {...feedback}
                  archived={false}
                  onArchive={archive}
                  onRepliesClick={() => onOpenThread(feedback)}
                  onSelect={setSelectedFeedback}
                  selectedId={selectedFeedback?.feedbackId}
                />
              ))}
            </FlexContainer>
          </InfiniteScroll>
        </ScrollAreaViewport>
        <ScrollAreaScrollbar>
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </ReasonFeedbacksScrollArea>
    </ReasonsDetailsContainer>
  )
}

export default ReasonDetails
