import { FilterBoolean } from '@/types/filters/Filters'
import { useMemo, useState } from 'react'
import {
  BooleanFilterContent,
  useBooleanGenericFilter
} from '../../FiltersSidebar/BooleanGenericFilter'
import { FilterItemDropdown } from '../../AppliedFilters/FilterItem'
import { snakeToTitle } from '@/utils/letterCase'

interface Props {
  filter: FilterBoolean
  buttonDisabled?: boolean
}

function FilterItemDropdownBoolean({ filter, buttonDisabled }: Props) {
  const [open, setOpen] = useState(false)

  const { onApply, onOptionChange, selectedOption, isDisabled } = useBooleanGenericFilter({
    filterKey: filter.key,
    type: filter.type,
    name: filter.name,
    newFeed: true
  })

  const valueString = useMemo(() => {
    if (filter.value === null) return 'All'

    return filter.value ? 'Checked' : 'Unchecked'
  }, [filter])

  const tooltipText = useMemo(() => {
    return `Is <b>${snakeToTitle(filter.name)}</b> > <b>${valueString}</b>`
  }, [filter, valueString])

  return (
    <FilterItemDropdown
      buttonDisabled={buttonDisabled}
      content={valueString}
      contentType="label"
      disabled={isDisabled}
      dropdown={
        <BooleanFilterContent onOptionChange={onOptionChange} selectedOption={selectedOption} />
      }
      onApply={onApply}
      onOpenChange={setOpen}
      open={open}
      title={snakeToTitle(filter.name)}
      tooltip={tooltipText}
    />
  )
}

export default FilterItemDropdownBoolean
