import TopicService from '@/services/TopicService'
import { useUserStore } from '@/store'
import { KeywordPayload } from '@/types/classification/TopicRequests'
import { useCallback } from 'react'
import useSegment from '../useSegment'

function useTopicsService() {
  const { track } = useSegment()
  const organizationId = useUserStore(state => state.currentUser?.organization_id || '')

  const addRelation = useCallback(
    async (topicId: string, keywordId: string) => {
      await TopicService.addKeywordRelation(topicId, keywordId)
      track('explore_user_keyword_topic_association')
    },
    [track]
  )

  const addRelationBatch = useCallback(
    async (topicId: string, keywords: KeywordPayload[]) => {
      const payload = keywords.map(keyword => ({ ...keyword, organization_id: organizationId }))
      await TopicService.addKeywordRelationBatch(topicId, payload)
      track('explore_user_keyword_topic_association')
    },
    [organizationId, track]
  )

  const removeRelation = useCallback(async (topicId: string, keywordId: string) => {
    await TopicService.removeKeywordRelation(topicId, keywordId)
  }, [])

  const addRelationFromSuggestion = useCallback(
    async (topicId: string, keywordId: string) => {
      await addRelation(topicId, keywordId)
    },
    [addRelation]
  )

  return {
    addRelation,
    removeRelation,
    addRelationFromSuggestion,
    addRelationBatch
  }
}

export default useTopicsService
