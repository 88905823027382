import useEnumAdvancedFilter from '@/hooks/advancedFilters/useEnumAdvancedFilter'
import { intentionQueryValueToIntention } from '@/utils/intention'
import { FilterItemDropdown } from '../../AppliedFilters/FilterItem'
import { FeedbackIntentionContent } from '../../FiltersSidebar/FeedbackIntention'
import { capitalizeFirst } from '@/utils/letterCase'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

interface Props {
  value: string[]
  index: number
  disabled?: boolean
}

const formatIntentionValue = (value: string) => intentionQueryValueToIntention[value] ?? value

const IntentionFilterApplied = ({ value, index, disabled = false }: Props) => {
  const initialValue = value.map(v => formatIntentionValue(v))
  const { t } = useTranslation()

  const { update, selectOption, isChecked } = useEnumAdvancedFilter({
    name: 'intention',
    enableQuery: false,
    initialValue,
    index,
    path: 'intention'
  })

  const count = value.length
  const valueTextArray = initialValue.map(v => `<b>${capitalizeFirst(v)}</b>`).join(t('or'))
  const text = i18n.t('isValueTextArray', { valueTextArray })

  return (
    <FilterItemDropdown
      buttonDisabled={disabled}
      content={count}
      contentType="count"
      disabled={disabled}
      dropdown={<FeedbackIntentionContent isChecked={isChecked} selectOption={selectOption} />}
      onApply={update}
      title={t('feedbackIntention')}
      tooltip={text}
    />
  )
}

export default IntentionFilterApplied
