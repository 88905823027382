import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'

import { useFiltersStore } from '@/store'
import { FilterTopicOption } from '@/types/filters/Filters'
import FilterItem from '../FilterItem'
import FilterItemDropdownUsers from '../FilterItem/FilterItemDropdownUsers'
import { snakeToTitle } from '@/utils/letterCase'
import { UsersThree } from '@phosphor-icons/react'
import { useFeedFiltersStore } from '@/store/useFiltersStore'

interface Props {
  newFeed?: boolean
}

function AppliedCustomerUsersStringFilters({ newFeed = false }: Props = { newFeed: false }) {
  const useStore = newFeed ? useFeedFiltersStore : useFiltersStore

  const removeUsersFilter = useStore(state => state.removeUsersFilter)
  const users = useStore(state => state.usersStringFilters, shallow)

  const usersApplied = useMemo(() => {
    return Object.entries(users)
      .map(([key, value]) => {
        if (!value.selected.length) return null

        const text = key === 'ingestedId' ? 'Id' : snakeToTitle(key)
        return { id: key, text, values: value.selected }
      })
      .filter(Boolean) as (FilterTopicOption & { values: string[] })[]
  }, [users])

  const onRemoveUserField = (fieldId: string) => () => {
    removeUsersFilter({ filterName: fieldId, apply: true })
  }

  return [
    ...usersApplied.map(filter => (
      <FilterItem
        customIcon={<UsersThree />}
        editButton={
          <FilterItemDropdownUsers
            filter={{ id: filter.id, text: filter.text }}
            key={filter.id}
            newFeed={newFeed}
            selectedValues={filter.values}
          />
        }
        key={filter.id}
        name={'Users/' + filter.text}
        onDelete={onRemoveUserField(filter.id)}
      />
    ))
  ].filter(Boolean) as JSX.Element[]
}

export default AppliedCustomerUsersStringFilters
