import AreaService from '@/services/AreaService'
import DefaultErrorHandler from '@/services/DefaultError'
import MetricsService from '@/services/MetricsService'
import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import { RawAreaError } from '@/types/area/AreaRequests'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { MetricItem, MetricsRequests, RawMetric } from '@/types/metrics'
import { MetricListPayloadItem } from '@/types/metrics/MetricsRequests'
import {
  OpportunityItemWithMergedContext,
  OpportunityItemWithMetrics
} from '@/types/opportunity/Opportunity'
import { delay } from './delay'
import { RequestReturnType } from '@/services/RequestHandlers/NewRequest'
import { buildMetricsChunks, fetchMetricsListInChunks } from '@/hooks/metrics/metricsQueryUtils'

interface GetOppsMetricsParams {
  filterList: FeedbackListQueryParams[]
  onError?: (error: DefaultErrorHandler) => void
  sortingMetric?: MetricItem['metric']
  metricList?: MetricListPayloadItem[]
  onProgress?: (chunkLength: number, completed: number) => void
  startDate?: string
  endDate?: string
}

const filterAreasIdsPerCollection = (
  relations: string[],
  currentCollectionId?: string,
  areas?: BaseInterestArea[]
) => {
  // the context of the opps should be of the relations of areas present in the collection
  if (currentCollectionId && areas) {
    const areasIds = areas.map(area => area.id)
    return relations.filter(relation => areasIds.includes(relation))
  }

  return relations
}

/**
 * since the org metrics are being returned from the endpoint when a error occurrs with the area
 * we set the area error on the metrics
 */
export const getRawMetricListWithAreaError = (
  oppId: string,
  rawMetrics: RawMetric[],
  errorMap: Record<string, RawAreaError>
) => {
  const areaError = errorMap[oppId]
  if (!areaError) return rawMetrics

  return rawMetrics.map(rawMetric => {
    return {
      ...rawMetric,
      error: {
        message: areaError.message,
        code: areaError.code,
        field: areaError.details?.field,
        isFromArea: true
      }
    } as RawMetric
  })
}

const buildMetricsOppsInChunks = buildMetricsChunks

export const getOptimizedOppsMetrics = async ({
  onProgress,
  onError,
  startDate,
  endDate,
  ...params
}: GetOppsMetricsParams) => {
  const { metricsToUse, chunks } = buildMetricsOppsInChunks({ ...params, chunkSize: 200 })

  if (!metricsToUse) {
    const emptyMetricsErrors = new DefaultErrorHandler(Error('No metrics to use'))
    onError?.(emptyMetricsErrors)
    throw emptyMetricsErrors
  }

  let completed = 0
  const promises = chunks.map(async (chunk, index) => {
    const metricsPayload: MetricsRequests.MetricsPayload = {
      filter_list: chunk.filters,
      metric_list: metricsToUse,
      posted_at_gte: startDate,
      posted_at_lt: endDate
    }

    await delay(250 * index)
    return MetricsService.opportunitiesMetrics(metricsPayload).then(result => {
      onProgress?.(chunks.length, completed)
      completed++

      return result
    })
  })

  return Promise.all(promises)
}

export const getOppsMetrics = fetchMetricsListInChunks

export const getMergedAreasContexts = async (
  opps: OpportunityItemWithMetrics[],
  errorMap: Record<string, RawAreaError>
): Promise<RequestReturnType<(string | null)[]>> => {
  const [mergedContextError, mergedContextResponse] = await AreaService.getMergedAreas({
    areas: opps.map(opp => ({
      identifier: opp.id,
      areas_ids: filterAreasIdsPerCollection(opp.relations)
    }))
  })

  if (mergedContextError) {
    return [mergedContextError, undefined]
  }

  return [
    undefined,
    mergedContextResponse.map(area => {
      if (area.error) {
        errorMap[area.identifier] = area.error
      }
      return area.context
    })
  ]
}

export const buildFilterList = (
  opps: OpportunityItemWithMetrics[],
  contextData: { currentFilterContext?: string; contexts: (string | null)[] },
  errorMap: Record<string, RawAreaError>
) => {
  const { contexts, currentFilterContext } = contextData

  return opps.map((opp, index) => {
    if (errorMap[opp.id]) return { opportunity_id: opp.id }

    return {
      opportunity_id: opp.id,
      context: currentFilterContext ?? contexts[index] ?? ''
    }
  })
}

export const buildFilterListWithMergedContextOpp = (
  opps: OpportunityItemWithMergedContext[],
  contextData: { currentFilterContext?: string },
  errorMap: Record<string, RawAreaError>
) => {
  const { currentFilterContext } = contextData

  return opps.map(opp => {
    if (errorMap[opp.id]) return { opportunity_id: opp.id }

    return {
      opportunity_id: opp.id,
      context: currentFilterContext ?? opp.mergedContext
    }
  })
}
