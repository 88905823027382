import { useCallback, useMemo } from 'react'

import { useFiltersStore } from '@/store'
import FilterItem, { FilterItemDropdown } from '../FilterItem'
import { UsersThree } from '@phosphor-icons/react'
import { getFormattedDateRange } from '@/utils/date'
import RangeCalendar from '@/components/atoms/range-calendar'
import { DateValue, parseDate } from '@internationalized/date'
import { queryClient } from '@/plugins/reactQueryClient'
import useLetterCase from '@/hooks/useLetterCase'
import { BaseRequestReturnType } from '@/services/RequestHandlers/BaseRequestHandler'
import { CustomerRecordsDateFieldValue } from '@/types/manage-customers/CustomerRecordsFilters'
import { useFeedFiltersStore } from '@/store/useFiltersStore'

interface Props {
  newFeed?: boolean
}

function AppliedCustomerUsersDates({ newFeed = false }: Props = { newFeed: false }) {
  const { snakeToTitle } = useLetterCase()

  const useStore = newFeed ? useFeedFiltersStore : useFiltersStore

  const removeUsersDateFilter = useStore(state => state.removeUsersDateFilter)
  const changeUsersDateFilter = useStore(state => state.changeUsersDateFilter)
  const applyUsers = useStore(state => state.applyUsers)
  const dateFilters = useStore(state => state.usersDateFilters)

  const usersApplied = useMemo(() => {
    return dateFilters
      .filter(filter => filter.selected)
      .map(filter => ({
        key: filter.key,
        title: snakeToTitle(filter.key),
        value: getFormattedDateRange(filter.selected as RangeValue<DateValue>)
      }))
  }, [dateFilters, snakeToTitle])

  const checkHasChange = useCallback(
    (filterKey: string) => {
      const dateFilter = dateFilters.find(filter => filter.key === filterKey)
      if (!dateFilter) return false

      return JSON.stringify(dateFilter.draftSelected) !== JSON.stringify(dateFilter.selected)
    },
    [dateFilters]
  )

  const getDefaultRange = useCallback((filterKey: string) => {
    const result = queryClient.getQueryData([
      'filters-customer-users-values',
      filterKey
    ]) as BaseRequestReturnType<CustomerRecordsDateFieldValue>

    if (!result) return null
    const [error, range] = result
    if (error) return null
    if (!range) return null

    const startDate = parseDate(range.start_date)
    const endDate = parseDate(range.end_date)
    return { start: startDate, end: endDate } as RangeValue<DateValue>
  }, [])

  const getRangeValue = (filterKey: string) => {
    const dateFilter = dateFilters.find(filter => filter.key === filterKey)
    if (!dateFilter) return null

    return dateFilter.draftSelected
  }

  const onRemovePlanDate = (filterKey: string) => () => {
    removeUsersDateFilter({ key: filterKey, apply: true })
  }

  const onChange = (filterKey: string) => (value: RangeValue<DateValue>) => {
    changeUsersDateFilter({ key: filterKey, value, apply: false })
  }

  return [
    ...usersApplied.map(filter => (
      <FilterItem
        customIcon={<UsersThree />}
        editButton={
          <FilterItemDropdown
            content={filter.value}
            contentCss={{ width: 'auto' }}
            contentType="count"
            disabled={!checkHasChange(filter.key)}
            dropdown={
              <RangeCalendar
                defaultValue={getDefaultRange(filter.key)}
                onChange={onChange(filter.key)}
                value={getRangeValue(filter.key) ?? undefined}
              />
            }
            onApply={applyUsers}
            title={filter.title}
            tooltip={filter.value}
          />
        }
        key={filter.key}
        name={'Users/' + filter.title}
        onDelete={onRemovePlanDate(filter.key)}
      />
    ))
  ] as JSX.Element[]
}

export default AppliedCustomerUsersDates
