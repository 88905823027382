import FlexContainer from '@/components/atoms/flex-container'
import useInitiativesFiltersStore from '@/store/initiatives/useInitiativesFiltersStore'
import { shallow } from 'zustand/shallow'
import Search from '../search'
import { CheckboxSelect, SelectOption } from '@/components/atoms/select'
import { useUserStore } from '@/store'
import { UserIcon } from '../user-cell/UserCell'
import { InitiativeStatus } from '@/types/initiatives/Initiatives'
import { KeyboardEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import useDebounce from '@/hooks/useDebounce'
import { initiativeStatusOptions } from './utils/iniativeStatus'
import Button from '@/components/atoms/button'
import { useTranslation } from 'react-i18next'
import CreateInitiativeDialog from './modals/CreateInitiativeDialog'
import { useInitiatives } from '@/hooks/initiatives/useInitiatives'

const InitiativesFilters = () => {
  const { search, owner, assignee, status } = useInitiativesFiltersStore(
    state => ({
      search: state.search,
      owner: state.owner,
      assignee: state.assignee,
      status: state.status
    }),
    shallow
  )
  const { t } = useTranslation()

  const users = useUserStore(state => state.users)
  const userOptions: SelectOption<string>[] = useMemo(
    () =>
      users
        .filter(user => !user.first_access)
        .map(user => ({
          text: user.name,
          value: user.user_id,
          icon: <UserIcon user={user} />
        })),
    [users]
  )

  /** Search */
  const setSearch = useInitiativesFiltersStore(state => state.setSearch)

  const [text, setText] = useState(search)
  const debouncedSearch = useDebounce(text, 750)

  useEffect(() => {
    setSearch(debouncedSearch)
  }, [debouncedSearch, setSearch])

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value)
  }
  const onSearchClear = () => {
    setText('')
    setSearch('')
  }

  const onPressEnter: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      setSearch(text)
    }
  }

  /** Owner */
  const setOwner = useInitiativesFiltersStore(state => state.setOwner)
  const onOwnerChange = (value: string[]) => {
    setOwner(value)
  }

  /** Assignee */
  const setAssignee = useInitiativesFiltersStore(state => state.setAssignee)
  const onAssigneeChange = (value: string[]) => {
    setAssignee(value)
  }

  /** Status */
  const setInitiativeStatus = useInitiativesFiltersStore(state => state.setInitiativeStatus)
  const onStatusChange = (value: string[]) => {
    setInitiativeStatus(value as InitiativeStatus[])
  }

  /** Create Initiative */
  const [createInitiativeOpen, setCreateInitiativeOpen] = useState(false)
  const onCreateInitiativeClick = useCallback(() => {
    setCreateInitiativeOpen(true)
  }, [])

  const onCreateInitiative = useCallback((name: string) => {
    createInitiative({ name })
  }, [])

  const { createInitiative } = useInitiatives({ enabled: false })

  return (
    <FlexContainer gap="xxs">
      <Search
        clearable
        expandOnFocus
        onChange={onSearchChange}
        onClear={onSearchClear}
        placeholder="Search initiatives"
        onKeyDown={onPressEnter}
        size="small"
        small
        value={text}
      />
      <CheckboxSelect
        options={userOptions}
        label={t('owner')}
        onChange={onOwnerChange}
        checked={owner}
      />
      <CheckboxSelect
        options={userOptions}
        label={t('assignee')}
        onChange={onAssigneeChange}
        checked={assignee}
      />
      <CheckboxSelect
        options={initiativeStatusOptions}
        label="Status"
        onChange={onStatusChange}
        checked={status}
      />
      <Button onClick={onCreateInitiativeClick} small>
        {t('createInitiative')}
      </Button>

      {createInitiativeOpen && (
        <CreateInitiativeDialog
          open={createInitiativeOpen}
          onOpenChange={setCreateInitiativeOpen}
          onCreate={onCreateInitiative}
        />
      )}
    </FlexContainer>
  )
}

export default InitiativesFilters
