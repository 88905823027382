import { forwardRef } from 'react'
import { FunnelSimple } from '@phosphor-icons/react'

import Tooltip from '@/components/atoms/tooltip'
import useParse from '@/hooks/useParse'
import { AppliedFiltersButton, AppliedFiltersText } from './FilterItem.styles'

export interface FilterItemButtonProps {
  content: string | number
  contentType: 'count' | 'label'
  onClick?(): void
  tooltip: string
  disabled?: boolean
}

function FilterItemButton(
  { content, contentType, tooltip, disabled = false, ...props }: FilterItemButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const { parseAndSanitize } = useParse()

  return (
    <Tooltip side="bottom" text={parseAndSanitize(tooltip)} variant="small">
      <AppliedFiltersButton css={{ cursor: 'pointer' }} {...props} disabled={disabled} ref={ref}>
        {contentType === 'count' ? <FunnelSimple /> : null}
        <AppliedFiltersText disabled={disabled}>{content}</AppliedFiltersText>
      </AppliedFiltersButton>
    </Tooltip>
  )
}

export default forwardRef(FilterItemButton)
