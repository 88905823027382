// based on: https://codepen.io/jmak/pen/bGPzrQ

import { colors, CSS, keyframes, styled } from '@/theme'

const spinnerColor = colors.neutralLowPure

const spinFade = keyframes({
  '0%': {
    background: spinnerColor
  },
  '75%': {
    background: spinnerColor
  },
  '100%': {
    background: 'transparent'
  }
})

const SpinnerCenter = styled('div', {
  fontSize: 40,
  position: 'relative',
  display: 'inline-block',
  bottom: '0.1em',
  width: '1em',
  height: '1em'
})

const spinnerBladeVariations: Record<string, CSS> = {}
Array(8)
  .fill(0)
  .forEach((_, index) => {
    spinnerBladeVariations[`&:nth-child(${index + 1})`] = {
      animationDelay: `${0.125 * index}s`,
      transform: `rotate(${45 * index}deg)`
    }
  })

const SpinnerBlade = styled('div', {
  position: 'absolute',
  left: '0.4629em',
  bottom: 0,
  width: '0.075em',
  height: '0.2em',
  borderRadius: 4,
  backgroundColor: 'transparent',
  transformOrigin: 'center -0.2222em',

  animation: `${spinFade} 1s infinite cubic-bezier(0, 1.37, 0, 0.87)`,

  ...spinnerBladeVariations
})

const Spinner = () => (
  <SpinnerCenter>
    {Array(8)
      .fill(0)
      .map((_, index) => (
        <SpinnerBlade key={index} />
      ))}
  </SpinnerCenter>
)

export default Spinner
