import NotFoundIllustration from '@/assets/drawings/not_found.png'
import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function NotFoundPage() {
  const { t } = useTranslation()
  return (
    <FlexContainer alignItems="center" css={{ paddingTop: 72 }} direction="column" gap="xs">
      <FlexContainer alignItems="center" direction="column" gap="micro">
        <Text
          as="h2"
          color="brandPrimaryPure"
          css={{ fontSize: '$lg', lineHeight: 1.25, fontWeight: '$bold' }}
        >
          Oops!
        </Text>
        <Text css={{ fontSize: '$xxs', lineHeight: '$lg', maxWidth: 495, textAlign: 'center' }}>
          {t('pageNotFoundMaybeThisPageDoesNotExistAnymore')}
        </Text>
      </FlexContainer>

      <Link to="/">
        <Button type="button" variant="exception">
          {t('goToHome')}
        </Button>
      </Link>
      <img
        alt="A blue bird holding a 404 error sign."
        height={363}
        src={NotFoundIllustration}
        width={576}
      />
    </FlexContainer>
  )
}

export default NotFoundPage
