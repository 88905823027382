import { StringFilterContentProps } from '../../StringGenericFilter/StringFilterContent'
import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import { ScrollAreaScrollbar, ScrollAreaThumb } from '@radix-ui/react-scroll-area'
import { FilterSubMenuScrollArea } from '../../AddFilterMenu/AddFilterMenu.styles'
import FiltersSkeleton from '../../AddFilterMenu/FiltersSkeleton'
import { ScrollAreaViewport } from '@/components/molecules/scroll-area'
import Checkbox from '@/components/atoms/checkbox'
import { useTranslation } from 'react-i18next'

interface Props extends Omit<StringFilterContentProps, 'options'> {
  options: { id: string; text: string }[]
}

const CustomerRecordsIdFilterContent = ({
  fetchNextPage,
  hasNextPage,
  isChecked,
  isFetchingNextPage,
  isShowMoreDisabled,
  options,
  selectOption
}: Props) => {
  const { t } = useTranslation()
  return (
    <FilterSubMenuScrollArea>
      <ScrollAreaViewport css={{ maxHeight: 320 }}>
        <FlexContainer direction="column" gap="micro">
          {options.map(option => (
            <Checkbox
              checked={isChecked(option.id)}
              defaultChecked={isChecked(option.id)}
              key={option.id}
              onCheckedChange={checked => selectOption(checked, option.id)}
              text={option.text}
              value={option.id}
            />
          ))}
        </FlexContainer>
        {isFetchingNextPage && <FiltersSkeleton />}
        {hasNextPage && (
          <Button
            disabled={isShowMoreDisabled}
            onClick={() => fetchNextPage()}
            size="micro"
            variant="link"
          >
            {t('showMore')}
          </Button>
        )}
      </ScrollAreaViewport>
      <ScrollAreaScrollbar orientation="vertical">
        <ScrollAreaThumb />
      </ScrollAreaScrollbar>
    </FilterSubMenuScrollArea>
  )
}

export default CustomerRecordsIdFilterContent
