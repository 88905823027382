import { MetricItem, TooltipCardHeader } from './AnalyticsTooltip.styles'

interface AnalyticsTooltipHeaderProps {
  title: string
  totalCount?: number
  totalPercentage?: number
}

const AnalyticsTooltipHeader = ({
  title,
  totalCount,
  totalPercentage
}: AnalyticsTooltipHeaderProps) => (
  <TooltipCardHeader>
    <h4>{title}</h4>
    <MetricItem type="number">{totalCount}</MetricItem>
    <MetricItem>{totalPercentage ? Math.round(totalPercentage) + '%' : ' '}</MetricItem>
  </TooltipCardHeader>
)

export default AnalyticsTooltipHeader
