import Button from '@/components/atoms/button'
import { useState } from 'react'
import useUser from '@/hooks/useUser'
import { CSVBoxButton } from '@csvbox/react'
import { CSVBOX_ACCOUNTS_SHEET_KEY, CSVBOX_USERS_SHEET_KEY } from '@/config'

interface Props {
  importSheet: 'accounts' | 'users'
  onImport: (result: boolean, data: unknown) => void
}

const ImportButton = ({ importSheet, onImport }: Props) => {
  const [uploadReady, setUploadReady] = useState(false)
  const { currentUser } = useUser()

  return (
    <CSVBoxButton
      licenseKey={importSheet === 'accounts' ? CSVBOX_ACCOUNTS_SHEET_KEY : CSVBOX_USERS_SHEET_KEY}
      onImport={onImport}
      onReady={() => setUploadReady(true)}
      render={(launch: () => void) => (
        <Button disabled={!uploadReady} onClick={launch}>
          New import
        </Button>
      )}
      user={{ user_id: currentUser?.user_id, organization_id: currentUser?.organization_id }}
    />
  )
}

export default ImportButton
