import useStringFilterSelection from '@/hooks/filters/useStringFilterSelection'
import { NEW_FEEDBACK_INTENTION_KEY } from '@/utils/filters'

const useFeedbackIntentionSelection = () => {
  const { selectOption, isChecked, hasChanges, apply, appliedOptions } = useStringFilterSelection({
    name: 'intention',
    filterKey: NEW_FEEDBACK_INTENTION_KEY,
    type: 'string',
    newFeed: true
  })

  return {
    selectOption,
    isChecked,
    hasChanges,
    appliedOptions,
    apply
  }
}

export default useFeedbackIntentionSelection
