import { BaseChatMessage, ChatCountData, FeedbackChatMessage } from '@/types/feedbacks/Feedback'
import { ChatModel } from '@/types/feedbacks/FeedbackRequests'
import { FilterItem } from '@/types/filters/Filters'
import { PromptTemplate, defaultModel } from '@/utils/chat'
import { StateCreator } from 'zustand'

interface ChatState {
  chatData: ChatCountData | undefined
  isLoadingChatData: boolean
  setChatData: (chatData?: ChatCountData) => void
  setIsLoadingChatData: (isLoadingChatData: boolean) => void

  fieldsToUse: FilterItem[]
  setFieldsToUse: (fields: FilterItem[]) => void

  history: FeedbackChatMessage[]
  setHistory: (chatData: FeedbackChatMessage[]) => void
  addNewMessageToHistory: (message: BaseChatMessage | FeedbackChatMessage) => void
  editMessageContent: (id: string, newContent: string) => void
  removeMessageById: (id: string) => void

  model: ChatModel
  setModel: (model: ChatModel) => void

  /** set order_by to feedback_hash, turning the list of feedback random in chat */
  randomFeedbacks: boolean
  setRandomFeedbacks: (random: boolean) => void

  /** control the size param send in chat payload */
  feedbackSize: number | undefined
  setFeedbackSize: (feedbackSize: number) => void

  initialPrompt: PromptTemplate | undefined
  setInitialPrompt: (prompt: PromptTemplate | undefined) => void
}

const createChatStore: StateCreator<ChatState> = set => ({
  chatData: undefined,
  isLoadingChatData: false,
  fieldsToUse: [],
  history: [],
  model: defaultModel,
  randomFeedbacks: true,
  feedbackSize: 0,
  initialPrompt: undefined,

  setChatData: chatData => set(() => ({ chatData })),
  setIsLoadingChatData: isLoadingChatData => set(() => ({ isLoadingChatData })),
  setFieldsToUse: fieldsToUse => set(() => ({ fieldsToUse })),

  setHistory: history => set(() => ({ history })),
  addNewMessageToHistory: message => {
    set(state => ({
      history: [
        ...state.history,
        {
          ...message,
          message: (message as FeedbackChatMessage)?.message ?? message.content,
          id: `${message.role}${state.history.length + 1}`,
          postedAt: (message as FeedbackChatMessage)?.postedAt
            ? (message as FeedbackChatMessage)?.postedAt
            : new Date().toISOString()
        }
      ]
    }))
  },
  editMessageContent: (id, newContent) => {
    set(state => ({
      history: state.history.map(item =>
        item.id === id ? { ...item, content: newContent, message: newContent } : item
      )
    }))
  },
  removeMessageById: id => {
    set(state => ({
      history: state.history.filter(message => message.id !== id)
    }))
  },

  setModel: model => set(() => ({ model })),

  setRandomFeedbacks: randomFeedbacks => set(() => ({ randomFeedbacks })),

  setFeedbackSize: feedbackSize => set(() => ({ feedbackSize })),

  setInitialPrompt: initialPrompt => set(() => ({ initialPrompt }))
})

export default createChatStore
