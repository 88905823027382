import { Intention, Reason, ReasonsRequests } from '@/types/reasons'
import Request from './Request'
import { ReasonResult } from '@/types/reasons/ReasonsRequests'
import NewRequest from './RequestHandlers/NewRequest'

export default class ReasonsService {
  static PREFIX = '/reasons'

  static searchResultToReason(reason: ReasonResult): Reason {
    return {
      id: reason.cluster_name + reason.intention,
      clusterId: reason.cluster_id || '',
      name: reason.cluster_name.charAt(0).toUpperCase() + reason.cluster_name.slice(1),
      intention: reason.intention.toLowerCase() as Intention,
      frequency: reason.total_frequency,
      trend: reason.trend,
      trendLast30: reason.trend_last_30,
      trendUntil30: reason.trend_until_30,
      reasons: reason.reasons_list,
      reasonsTexts: reason.reasons_list_text || []
    }
  }

  static async search(payload: ReasonsRequests.SearchPayload) {
    const fullPayload = { ...payload }

    return await Request.post<ReasonsRequests.SearchResponse>(
      `${this.PREFIX}/cluster_search`,
      fullPayload
    )
  }

  static async trend(payload: ReasonsRequests.TrendPayload) {
    const fullPayload = { ...payload }

    return NewRequest.post<ReasonsRequests.TrendResponse>(
      `${this.PREFIX}/trending_series`,
      fullPayload
    )
  }

  static async getClusterReason(
    payload: ReasonsRequests.GetClusterReasonPayload,
    signal?: AbortSignal
  ) {
    const fullPayload = { ...payload }

    return NewRequest.post<ReasonsRequests.SearchResponse>(
      `${this.PREFIX}/get_cluster_reason`,
      fullPayload,
      { signal }
    )
  }

  static async summary(payload: ReasonsRequests.SummaryPayload) {
    const response = await Request.post<ReasonsRequests.SummaryResponse>(
      `${this.PREFIX}/cluster/summary`,
      payload
    )

    return response.long_summary
  }

  static async deleteReasonFromCluster(params: ReasonsRequests.DeleteReasonFromClusterParams) {
    return NewRequest.del(
      `/cluster/delete_cluster_reason/${params.cluster_type}/${params.cluster_id}/${params.keyword_hash}`
    )
  }
}
