import { MetricCell } from '@/components/molecules/area-of-interest/AreaOfInterestList/AreaOfInterestTable/MetricCell'
import useSourcesQuery from '@/hooks/useSourcesQuery'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { MetricKey, RawMetric } from '@/types/metrics'
import { ColumnDef, VisibilityState } from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useMetricListPayload from './useMetricListPayload'
import useAllMetrics from './useAllMetricItems'
import { MetricCustomizationValue, MetricListPayloadItem } from '@/types/metrics/MetricsRequests'
import useAllowedMetrics from './useAllowedMetrics'

interface Params<T> {
  filterFn: (item: T) => FeedbackListQueryParams
  metricFor: 'area' | 'org' | 'opportunity' | 'segmentation'
}

const useMetricsTableColumns = <T extends { metrics?: RawMetric[] }>({
  filterFn,
  metricFor
}: Params<T>) => {
  const { values: sourceAliasValues } = useSourcesQuery({
    enabled: true,
    sourceMode: 'sourceAlias'
  })

  const { allAllowedMetricsList } = useAllowedMetrics()

  const { allMetricItems, allMetricItemsList, parseRawMetric, getMetricDisplayName } =
    useAllMetrics()

  const { t } = useTranslation()

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(() => {
    const columns: VisibilityState = {}

    allMetricItemsList.forEach(metricItem => {
      columns[metricItem.key] = allAllowedMetricsList.includes(metricItem.key)
    })

    return columns
  })

  const { getMetricKeys, handleCustomMetricVariables, getMetricAndAddShareFilter } =
    useMetricListPayload()

  useEffect(() => {
    setColumnVisibility(prev => {
      const newColumnVisibility = { ...prev }

      allMetricItemsList.forEach(metricItem => {
        newColumnVisibility[metricItem.key] = allAllowedMetricsList.includes(metricItem.key)
      })

      return newColumnVisibility
    })
  }, [allAllowedMetricsList, allMetricItemsList])

  const metricColumns = useMemo(() => {
    const overallKey = 'count' as MetricKey
    const overallKeys = getMetricKeys(overallKey)

    const columnMinSize = 100
    const columnMaxSize = 120

    const overallSubColumns: ColumnDef<T>[] = (overallKeys ?? []).map(key => {
      const overallMetricItem = allMetricItems[key]
      let metricPayloadItem: MetricListPayloadItem = getMetricAndAddShareFilter(key)
      if (
        metricPayloadItem.name === 'custom' &&
        metricPayloadItem.customization &&
        metricPayloadItem.customization.variables
      ) {
        const newVariables: MetricCustomizationValue['variables'] = handleCustomMetricVariables(
          metricPayloadItem.customization.variables,
          metricFor
        )

        metricPayloadItem = {
          ...metricPayloadItem,
          customization: {
            ...metricPayloadItem.customization,
            variables: newVariables
          }
        }
      }

      return {
        id: key,
        header: overallMetricItem.abbrLabel ?? overallMetricItem.label,
        maxSize: columnMaxSize,
        sortDescFirst: true,
        footer: props => props.column.id,
        accessorFn: row =>
          parseRawMetric({
            groupKey: overallKey,
            columKey: key,
            rawMetrics: row.metrics
          })?.currentValue,
        cell: props => {
          const metricData = parseRawMetric({
            groupKey: overallKey,
            columKey: key,
            rawMetrics: props.row.original.metrics
          })

          const transformedValue =
            metricData.currentValue && overallMetricItem.numberTransformer(metricData.currentValue)

          const formattedValue = transformedValue && overallMetricItem.formatter(transformedValue)

          const filter = filterFn(props.row.original)

          return (
            <MetricCell
              columnName={overallMetricItem.abbrLabel ?? overallMetricItem.label}
              filter={filter}
              metricPayloadItem={metricPayloadItem}
              formatter={overallMetricItem.formatter}
              groupKey={overallKey}
              metricKey={key}
              numberTransformer={overallMetricItem.numberTransformer}
              rawMetric={metricData.metric}
              tooltipName="Overall"
              tooltipType="count"
              value={formattedValue}
            />
          )
        }
      }
    })

    const columns: ColumnDef<T>[] = [
      {
        id: overallKey,
        header: t('overall'),
        columns: overallSubColumns,
        minSize: columnMinSize,
        maxSize: columnMaxSize,
        footer: props => props.column.id
      }
    ]

    const CONVERSATION_TYPES = ['issue', 'social_media_post', 'transcript']
    const SATISFACTION_TYPES = ['csat', 'nps', 'review']

    const orderedSources = [
      ...new Set([
        ...sourceAliasValues.filter(source => source === 'support_ticket'),
        ...sourceAliasValues.filter(source => SATISFACTION_TYPES.includes(source)),
        ...sourceAliasValues.filter(source => CONVERSATION_TYPES.includes(source)),
        ...sourceAliasValues
      ])
    ]
    orderedSources.forEach(source => {
      const metricOption = source as MetricKey

      const metricKeys = getMetricKeys(metricOption)
      const metricDisplayName = getMetricDisplayName(metricOption)

      if (!metricKeys) {
        console.warn(`Source/Metric ${metricOption} not implemented`)
        return
      }

      const subColumns: ColumnDef<T>[] = metricKeys.map(key => {
        const metricItem = allMetricItems[key]

        let metricPayloadItem: MetricListPayloadItem = getMetricAndAddShareFilter(key)
        if (
          metricPayloadItem.name === 'custom' &&
          metricPayloadItem.customization &&
          metricPayloadItem.customization.variables
        ) {
          const newVariables: MetricCustomizationValue['variables'] = handleCustomMetricVariables(
            metricPayloadItem.customization.variables,
            metricFor
          )

          metricPayloadItem = {
            ...metricPayloadItem,
            customization: {
              ...metricPayloadItem.customization,
              variables: newVariables
            }
          }
        }

        return {
          id: key,
          header: metricItem?.abbrLabel ?? metricItem?.label,
          maxSize: columnMaxSize,
          sortDescFirst: true,
          footer: props => props.column.id,
          accessorFn: row =>
            parseRawMetric({
              groupKey: metricOption,
              columKey: key,
              rawMetrics: row.metrics
            })?.currentValue,
          cell: props => {
            const metricData = parseRawMetric({
              groupKey: metricOption,
              columKey: key,
              rawMetrics: props.row.original.metrics
            })

            let tooltipType: 'score' | 'count' = 'count'
            let scoreType: 'score' | 'nps' | 'csat' = 'score'
            if (metricOption === 'nps') {
              scoreType = 'nps'
              tooltipType = 'score'
            }
            if (metricOption === 'csat') {
              scoreType = 'csat'
              tooltipType = 'score'
            }

            if (metricOption === 'review') {
              tooltipType = 'score'
            }

            if (metricData.metric?.unit === 'currency') {
              tooltipType = 'count'
            }

            const transformedValue =
              metricData.currentValue && metricItem?.numberTransformer(metricData.currentValue)

            const formattedValue =
              transformedValue &&
              metricItem?.formatter(transformedValue, metricData.metric?.currency)

            const filter = filterFn(props.row.original)

            return (
              <MetricCell
                columnName={metricItem?.abbrLabel ?? metricItem?.label}
                filter={filter}
                metricPayloadItem={metricPayloadItem}
                formatter={metricItem?.formatter}
                groupKey={metricOption}
                metricKey={key}
                numberTransformer={metricItem?.numberTransformer}
                rawMetric={metricData.metric}
                scoreType={scoreType}
                tooltipName={metricDisplayName}
                tooltipType={tooltipType}
                value={formattedValue}
              />
            )
          }
        }
      })

      columns.push({
        id: metricOption,
        header: metricDisplayName,
        columns: subColumns,
        minSize: columnMinSize,
        maxSize: columnMaxSize,
        footer: props => props.column.id
      })
    })

    return columns
  }, [
    sourceAliasValues,
    filterFn,
    t,
    getMetricKeys,
    allMetricItems,
    parseRawMetric,
    handleCustomMetricVariables,
    metricFor,
    getMetricAndAddShareFilter,
    getMetricDisplayName
  ])

  return {
    metricColumns,
    columnVisibility,
    setColumnVisibility
  }
}

export default useMetricsTableColumns
