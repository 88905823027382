import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from './locales/en/translation.json'
import ptBR from './locales/pt-BR/translation.json'

export const resources = {
  en: {
    translation: en
  },
  'pt-BR': {
    translation: ptBR
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false, // turn on for debugging
    fallbackLng: 'en',
    resources,
    detection: {
      order: ['navigator', 'querystring', 'htmlTag', 'path', 'subdomain']
    }
  })

export default i18n
