import React from 'react'

import FlexContainer from '@/components/atoms/flex-container'
import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import EmptyFilters from '../EmptyFilters'
import AppliedCustomFieldFilters from './AppliedCustomFieldFilters'
import AppliedDateFilters from './AppliedDateFilters'
import AppliedFeedbackDetailFilters from './AppliedFeedbackDetailFilters'
import AppliedFeedbackIntentionFilters from './AppliedFeedbackIntentionFilters'
import AppliedFeedbackTypeFilters from './AppliedFeedbackTypeFilters'
import AppliedOtherTopicFilters from './AppliedOtherTopicFilters'
import AppliedProductAreaFilters from './AppliedProductAreaFilters'
import AppliedRatingFilters from './AppliedRatingFilters'
import AppliedSearchFilters from './AppliedSearchFilters'
import AppliedSentimentFilters from './AppliedSentimentFilters'
import { ScrollAreaRoot } from './AppliedFilters.styles'
import {
  AppliedAccountsStringFilters,
  AppliedAccountDateFilters,
  AppliedAccountNumericFilters,
  AppliedCustomerUsersStringFilters,
  AppliedCustomerUsersDateFilters,
  AppliedCustomerUsersNumericFilters,
  AppliedAccountBooleanFilters,
  AppliedCustomerUsersBooleanFilters
} from './AppliedCustomerRecords'
import AppliedUngroupedTopics from './AppliedUngroupedTopics'
import AndSeparator from '../AndSeparator'
import useScrollFade from '@/hooks/useScrollFade'

function AppliedFilters() {
  const { viewportRef, fade, onScroll } = useScrollFade()

  const filterItemsArray = [
    AppliedFeedbackIntentionFilters(),
    AppliedSentimentFilters(),
    AppliedRatingFilters(),
    AppliedProductAreaFilters(),
    AppliedOtherTopicFilters(),
    AppliedUngroupedTopics(),
    AppliedFeedbackTypeFilters(),
    AppliedFeedbackDetailFilters(),
    AppliedCustomFieldFilters(),
    AppliedDateFilters(),
    AppliedSearchFilters(),
    AppliedAccountsStringFilters(),
    AppliedAccountDateFilters(),
    AppliedAccountNumericFilters(),
    AppliedAccountBooleanFilters(),
    AppliedCustomerUsersStringFilters(),
    AppliedCustomerUsersDateFilters(),
    AppliedCustomerUsersNumericFilters(),
    AppliedCustomerUsersBooleanFilters()
  ].flat()

  if (filterItemsArray.length === 0) return <EmptyFilters />

  return (
    <ScrollAreaRoot fade={fade}>
      <ScrollAreaViewport onScroll={onScroll} ref={viewportRef}>
        <FlexContainer direction="column" gap="nano">
          {filterItemsArray.map((filterItem, index) => (
            <React.Fragment key={index}>
              {index > 0 ? <AndSeparator /> : null}
              {filterItem}
            </React.Fragment>
          ))}
        </FlexContainer>
      </ScrollAreaViewport>
      <ScrollAreaScrollbar>
        <ScrollAreaThumb />
      </ScrollAreaScrollbar>
    </ScrollAreaRoot>
  )
}

export default AppliedFilters
