import { TrendingTopicItemData } from '@/types/analytics/Analytics'

export const getTrendingTopicMax = (topics?: TrendingTopicItemData[]) =>
  Math.max(...(topics?.map(topic => topic.current) ?? [1]))

export const getEmptyCardsArray = (topics: TrendingTopicItemData[]) =>
  topics.length < 5 ? Array(5 - topics.length).fill(0) : []

export const scrollOnList = (elementId: string) => {
  const element = document.getElementById(elementId)
  if (element) {
    setTimeout(() => {
      element.scrollBy({ top: 350, behavior: 'smooth' })
    }, 200)
  }
}
