import FiltersService from '@/services/FiltersService'
import { AdvancedFilterContent, FilterNode } from '@/types/filters/AdvancedFilters'
import { SegmentationItem, SegmentationItemWithMetrics } from '@/types/segmentation/Segmentation'
import { advancedFilterToContent, mergeAdvancedFilters } from '@/utils/advancedFilter'

interface Params {
  segmentations: (SegmentationItem | SegmentationItemWithMetrics)[]
  currentFilters: FilterNode
}

const getSegmentationFiltersCombinedFn = async ({ segmentations, currentFilters }: Params) => {
  const baseContent: AdvancedFilterContent = advancedFilterToContent(currentFilters)

  const combinedFilters = segmentations.map(seg => {
    const segContent = seg.content[0].values.filter

    const combinedFilters = mergeAdvancedFilters([segContent, baseContent], '$and')
    return { identifier: seg.segmentationId, filter: combinedFilters }
  })

  const [error, responseData] = await FiltersService.multipleAdvancedFilterContext({
    filters: combinedFilters
  })

  if (error) throw error
  return responseData
}

export default getSegmentationFiltersCombinedFn
