import { styled } from '@/theme'

const CollectionGrid = styled('div', {
  display: 'grid',
  mt: '$xs',
  gridTemplateColumns: 'repeat(6, 1fr)',
  gridTemplateRows: 'repeat(4, 1fr)',
  gap: '$micro',

  '@bp5': {
    gridTemplateColumns: 'repeat(5, 1fr)'
  },

  '@bp4': {
    gridTemplateColumns: 'repeat(4, 1fr)'
  },

  '@bp3': {
    gridTemplateColumns: 'repeat(3, 1fr)'
  },

  '@bp2': {
    gridTemplateColumns: 'repeat(2, 1fr)'
  },

  '@bp1': {
    gridTemplateColumns: 'repeat(1, 1fr)'
  }
})

export default CollectionGrid
