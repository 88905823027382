// import CloudflareKVService from '@/services/CloudflareKVService'
import DashboardStateRequest from '@/services/RequestHandlers/DashboardStateRequest'
import useUser from '../useUser'
import useDashboardStore from '@/store/useDashboardStore'
import { CHARTS_COUNT_LIMIT } from '@/utils/dashboard'
import { shallow } from 'zustand/shallow'
import { DashboardChart, DashboardChartRequest } from '@/types/dashboard'
import useToastMessageStore from '@/store/useToastMessageStore'
import { parseDate } from '@internationalized/date'
import useLogging from '../useLogging'

interface DashboardState {
  lastTimeSaved: number
  charts: DashboardChartRequest[]
}

const useDashboardState = () => {
  const { currentUser } = useUser()
  const { lastTimeSaved, charts, savedCharts } = useDashboardStore(
    state => ({
      lastTimeSaved: state.lastTimeSaved,
      charts: state.charts,
      savedCharts: state.savedCharts
    }),
    shallow
  )
  const setLastTimeSaved = useDashboardStore(state => state.setLastTimeSaved)
  const setCharts = useDashboardStore(state => state.setCharts)
  const setSavedCharts = useDashboardStore(state => state.setSavedCharts)

  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)

  const { logException } = useLogging({ context: 'dashboard-state' })

  const getSavedState = async () => {
    if (!currentUser) {
      return
    }

    const [error, data] = await DashboardStateRequest.get<DashboardState>(
      currentUser.organization_id
    )
    if (error) {
      console.error(error.message)
      const message = 'Failed to get saved dashboard.'
      logException(error, { message })
      addErrorToast({ text: message })
      return
    }

    if (!data) {
      return
    }

    const mappedCharts: DashboardChart[] = data.charts.map(chart => ({
      ...chart,
      dateRange: chart.dateRange
        ? { start: parseDate(chart.dateRange.start), end: parseDate(chart.dateRange.end) }
        : null,
      saved: true
    }))

    setCharts(mappedCharts)
    setLastTimeSaved(new Date(data.lastTimeSaved))
  }

  const saveChart = async (id: string) => {
    if (!currentUser) {
      return
    }

    const chartToSaveIndex = charts.findIndex(chart => chart.id === id)
    if (chartToSaveIndex === -1) {
      const message = 'Failed to save chart.'
      logException(new Error(message), { message })
      addErrorToast({ text: message })
      return
    }

    const chartToSave: DashboardChart = { ...charts[chartToSaveIndex], saved: true }
    let newSavedCharts: DashboardChart[] = []
    if (savedCharts.find(chart => chart.id === id)) {
      newSavedCharts = savedCharts.map(chart => (chart.id === id ? chartToSave : chart))
    } else {
      const tempSavedCharts: (DashboardChart | null)[] = []
      charts.forEach((chart, index) => {
        const chartFound = savedCharts.find(savedChart => savedChart.id === chart.id)
        if (chartFound) {
          tempSavedCharts.push(chartFound)
        } else {
          tempSavedCharts.push(index === chartToSaveIndex ? chartToSave : null)
        }
      })
      newSavedCharts = tempSavedCharts.filter(chart => chart !== null) as DashboardChart[]
    }

    const nowDate = new Date(Date.now())
    const removeLoading = addLoadingToast({ text: 'Saving chart...' })
    const mappedCharts = newSavedCharts.map(chart => {
      let startDate: string | null = null
      let endDate: string | null = null

      if (chart.dateRange) {
        startDate = new Date(
          chart.dateRange.start.year,
          chart.dateRange.start.month - 1,
          chart.dateRange.start.day
        )
          .toISOString()
          .slice(0, 10)
        endDate = new Date(
          chart.dateRange.end.year,
          chart.dateRange.end.month - 1,
          chart.dateRange.end.day
        )
          .toISOString()
          .slice(0, 10)
      }

      return {
        ...chart,
        dateRange: chart.dateRange ? { start: startDate, end: endDate } : null
      }
    })
    const [error] = await DashboardStateRequest.post(
      currentUser.organization_id,
      JSON.stringify({
        lastTimeSaved: nowDate.getTime(),
        charts: mappedCharts
      })
    )
    removeLoading()

    if (error) {
      console.error(error.message)
      const message = 'Failed to save chart.'
      logException(error, { message })
      addErrorToast({ text: message })
      return
    }

    setCharts(charts.map(chart => (chart.id === id ? { ...chart, saved: true } : chart)))
    setLastTimeSaved(nowDate)
    setSavedCharts(newSavedCharts)
    addSuccessToast({ text: 'Chart saved.' })
  }

  const removeChart = async (id: string) => {
    if (!currentUser) {
      return
    }

    const newSavedCharts = savedCharts.filter(chart => chart.id !== id)

    const nowDate = new Date(Date.now())
    const [error] = await DashboardStateRequest.post(
      currentUser.organization_id,
      JSON.stringify({ lastTimeSaved: nowDate.getTime(), charts: newSavedCharts })
    )

    if (error) {
      console.error(error.message)
      const message = 'Failed to save dashboard.'
      addErrorToast({ text: message })
      return
    }

    setCharts(charts.filter(chart => chart.id !== id))
    setLastTimeSaved(nowDate)
    setSavedCharts(newSavedCharts)
  }

  const limitReached = charts.length >= CHARTS_COUNT_LIMIT

  return {
    charts,
    lastTimeSaved,
    getSavedState,
    saveChart,
    removeChart,
    limitReached
  }
}

export default useDashboardState
