import useLogging from '@/hooks/useLogging'
import { queryClient } from '@/plugins/reactQueryClient'
import FeedService from '@/services/FeedService'
import useToastMessageStore from '@/store/useToastMessageStore'
import { RelatedFeedbackLabelBaseItem } from '@/types/feed/FeedRequests'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

export const MOVE_FEEDBACK_QUERY_KEY = 'move-feedback-to'

interface Params {
  feedbackIdToMove: string | null
  moveTo: 'area' | 'opportunity'
  enabled: boolean
}

const useMoveFeedbackQuery = ({ feedbackIdToMove, moveTo, enabled }: Params) => {
  const { logException } = useLogging({ context: 'useMoveFeedbackQuery' })
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const isEnabled = enabled && !!feedbackIdToMove

  const queryKey = useMemo(
    () => [MOVE_FEEDBACK_QUERY_KEY, { moveTo, feedbackIdToMove }],
    [moveTo, feedbackIdToMove]
  )

  const queryFn = async () => {
    if (!feedbackIdToMove) return

    const [error, data] =
      moveTo === 'area'
        ? await FeedService.getAreasByFeedbackId(feedbackIdToMove)
        : await FeedService.getOpportunitiesByFeedbackId(feedbackIdToMove)

    if (error) {
      logException(error.errorInstance)
      addErrorToast({ text: error.message ?? (error.errorInstance as Error).message })
      throw error
    }

    return data
  }

  const { data, isLoading } = useQuery({
    queryFn,
    queryKey,
    enabled: isEnabled,
    retry: false
  })

  const labels = useMemo(() => {
    return data ?? []
  }, [data])

  const moveFeedbackMutation = useMutation({
    mutationFn: async (newLabels: RelatedFeedbackLabelBaseItem[]) => {
      if (!feedbackIdToMove) return
      const [error] =
        moveTo === 'area'
          ? await FeedService.updateFeedbackIdAreas(feedbackIdToMove, {
              currentAreas: newLabels,
              previousAreas: labels
            })
          : await FeedService.updateFeedbackIdOpportunities(feedbackIdToMove, {
              currentOpportunities: newLabels,
              previousOpportunities: labels
            })

      if (error) {
        logException(error.errorInstance)
        addErrorToast({ text: error.message ?? (error.errorInstance as Error).message })
        throw error
      }

      return { newLabels }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      queryClient.invalidateQueries(['feedback-search'], { exact: false })
      addSuccessToast({ text: 'Feedback successfully moved.' })
    }
  })

  return {
    data,
    labels,
    isLoading: isEnabled ? isLoading : false,
    moveFeedbackMutation
  }
}

export default useMoveFeedbackQuery
