import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'

export const FilterGroupContainer = styled(FlexContainer, {
  flexDirection: 'column',
  gap: '$micro',
  width: '100%',

  variants: {
    root: {
      true: {},
      false: {
        borderRadius: 8,
        padding: '$micro',
        background: '$brandPrimaryLight50',
        bAll: '$neutralHighPure'
      }
    }
  }
})

export const FilterGroupRowContainer = styled(FlexContainer, {
  position: 'relative',
  gap: '$micro',
  width: '100%'
})

export const FilterGroupRowCell = styled(FlexContainer, {
  minWidth: 100,
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: 32,

  '& > span': {
    fontSize: '$xxxs'
  }
})

export const DropdownButton = styled(Button, {
  gap: '$micro',
  height: 32,
  boxSizing: 'border-box',
  width: '100%',
  px: '$xxs !important',
  justifyContent: 'flex-start',
  background: '$neutralHighLight',

  '& > .text': {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    maxWidth: 160,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  '& > .caret': {
    ml: 'auto',
    transition: '.2s',
    transform: 'rotate(0)'
  },

  '&[data-state="open"] > .caret': {
    transform: 'rotate(180deg)'
  }
})
