import type { OptionsMenuItem } from '@/components/atoms/options-menu'
import useCollections from '@/hooks/collections/useCollections'
import useSegment from '@/hooks/useSegment'
import useUser from '@/hooks/useUser'
import type { Collection } from '@/types/collection'
import { Lock, PencilSimple, Share, TrashSimple } from '@phosphor-icons/react'
import { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useCollectionOptions = (collection: Collection) => {
  const { track } = useSegment()
  const { currentUser } = useUser()

  const location = useLocation()
  const { t } = useTranslation()

  const from = useMemo(() => {
    if (location.pathname.includes('home')) return 'home'
    if (location.pathname.includes('areas-and-opportunities')) return 'areas-and-opps'
    return location.pathname
  }, [location.pathname])

  const {
    favoriteCollection,
    unfavoriteCollection,
    removeCollection,
    toggleCollectionVisibility,
    editCollection
  } = useCollections({
    enabled: false
  })

  const toggleFavorite = () => {
    track('collection_favorite', {
      entity: collection.type,
      favorite: !collection.favorite,
      from
    })
    if (collection.favorite) {
      unfavoriteCollection(collection.collectionId)
    } else {
      favoriteCollection(collection.collectionId)
    }
  }

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const onEditClick = useCallback(() => {
    setIsEditModalOpen(true)
  }, [])
  const confirmEdit = (name: string, filterIds: string[]) => {
    editCollection({ collection, name, filterIds })
    setIsEditModalOpen(false)
  }

  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
  const onDeleteClick = useCallback(() => {
    setIsDeleteAlertOpen(true)
  }, [])
  const confirmDelete = () => {
    removeCollection(collection.collectionId)
    setIsDeleteAlertOpen(false)
  }

  const [isPublishAlertOpen, setIsPublishAlertOpen] = useState(false)
  const onPublishClick = useCallback(() => {
    setIsPublishAlertOpen(true)
  }, [])
  const confirmPublish = () => {
    toggleCollectionVisibility({ collection, action: 'publish' })
    setIsPublishAlertOpen(false)
  }

  const [isUnpublishAlertOpen, setIsUnpublishAlertOpen] = useState(false)
  const onUnpublishClick = useCallback(() => {
    setIsUnpublishAlertOpen(true)
  }, [])
  const confirmUnpublish = () => {
    toggleCollectionVisibility({ collection, action: 'unpublish' })
    setIsUnpublishAlertOpen(false)
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const menuOptions: OptionsMenuItem[] = useMemo(() => {
    const options: OptionsMenuItem[] = [
      {
        text: t('edit'),
        icon: <PencilSimple />,
        onClick: onEditClick
      },
      {
        text: t('delete'),
        icon: <TrashSimple />,
        onClick: onDeleteClick
      }
    ]

    if (collection.visibility === 'private') {
      options.push({
        text: t('publish'),
        icon: <Share />,
        onClick: onPublishClick
      })
    } else if (collection.createdBy === currentUser?.user_id) {
      options.push({
        text: t('unpublish'),
        icon: <Lock />,
        onClick: onUnpublishClick
      })
    }

    return options
  }, [collection, currentUser, onEditClick, onDeleteClick, onPublishClick, onUnpublishClick, t])

  return {
    isMenuOpen,
    setIsMenuOpen,
    menuOptions,
    toggleFavorite,
    isEditModalOpen,
    setIsEditModalOpen,
    confirmEdit,
    isDeleteAlertOpen,
    setIsDeleteAlertOpen,
    confirmDelete,
    isPublishAlertOpen,
    setIsPublishAlertOpen,
    confirmPublish,
    isUnpublishAlertOpen,
    setIsUnpublishAlertOpen,
    confirmUnpublish
  }
}

export default useCollectionOptions
