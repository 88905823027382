import * as DialogPrimitive from '@radix-ui/react-dialog'
import * as Styled from './Dialog.styles'
import useHideIntercom from '@/hooks/useHideIntercom'
import { useUIStore } from '@/store'
import useSignupStore from '@/store/useSignupStore'
import { useEffect } from 'react'
import { shallow } from 'zustand/shallow'
import FlexContainer from '../flex-container'
import Button from '../button'
import OnboardingSuccessDrawing from '@/assets/drawings/onboarding_success_drawing.png'

const OnboardingSuccessDialog = () => {
  const open = useUIStore(state => state.showOnboardingSuccess, shallow)
  const setOpen = useUIStore(state => state.setShowOnboardingSuccess)

  const handleIntercom = useHideIntercom()

  useEffect(() => {
    handleIntercom(open)

    return () => handleIntercom(false)
  }, [open, handleIntercom])

  const companyName = useSignupStore(state => state.companyName, shallow)

  return (
    <DialogPrimitive.Root open={open}>
      <DialogPrimitive.Portal>
        <Styled.DialogOverlay css={{ pointerEvents: 'none' }} />
        <Styled.OnboardingSuccessDialogContent>
          <FlexContainer alignItems="center" css={{ height: '100%' }} justifyContent="spaceEvenly">
            <FlexContainer direction="column">
              <h1>Awesome!</h1>
              <p>You&apos;ve successfully created a {companyName} workspace on Birdie. </p>
              <Button css={{ marginTop: '$xs' }} onClick={() => setOpen(false)} variant="exception">
                Let&apos;s get started
              </Button>
            </FlexContainer>
            <img
              alt="A black woman talking into a speaker with a bird standing on the speaker with a lightbulb above. At the side, a computer with a strawberry image on the screen."
              src={OnboardingSuccessDrawing}
            />
          </FlexContainer>
        </Styled.OnboardingSuccessDialogContent>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}

export default OnboardingSuccessDialog
