import useLocalStorage from '@/hooks/useLocalStorage'
import { defaultTextReplacementFormData } from '@/pages/text-replacement'
import {
  TEXT_REPLACEMENT_PARAMS_KEY,
  TextReplacementFormData
} from '@/types/params/TextReplacementParams'
import { createContext, ReactNode, useEffect, useMemo } from 'react'

const TextReplacementContext = createContext({})

interface Props {
  children: ReactNode
}

const TextReplacementProvider = ({ children }: Props) => {
  const [textReplacementData] = useLocalStorage<TextReplacementFormData>(
    TEXT_REPLACEMENT_PARAMS_KEY,
    defaultTextReplacementFormData
  )
  const { dynamicText, enable } = textReplacementData

  const dynamicTextMap: Record<string, string> = useMemo(
    () => dynamicText.reduce((map, item) => ({ ...map, [item.original]: item.replacement }), {}),
    [dynamicText]
  )

  useEffect(() => {
    if (!enable) return

    const replaceTextNodes = (node: Node) => {
      try {
        if (node.nodeType === Node.TEXT_NODE) {
          const originalText = node.nodeValue
          const replacedText = Object.keys(dynamicTextMap).reduce((text, key) => {
            const regex = new RegExp(`\\b${key}\\b([.,!?;:]*)`, 'gi')
            return typeof text === 'string'
              ? text.replace(regex, dynamicTextMap[key] ? dynamicTextMap[key] + '$1' : '')
              : text
          }, originalText)
          if (replacedText !== originalText) {
            node.nodeValue = replacedText
          }
        } else {
          node.childNodes.forEach(replaceTextNodes)
        }
      } catch (e) {
        console.error(e)
      }
    }

    const observer = new MutationObserver(mutationsList => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach(replaceTextNodes)
        }
      }
    })

    observer.observe(document.body, { childList: true, subtree: true })

    return () => {
      observer.disconnect()
    }
  }, [dynamicTextMap, enable])

  return (
    <TextReplacementContext.Provider value={{ dynamicTextMap }}>
      {children}
    </TextReplacementContext.Provider>
  )
}

export { TextReplacementContext, TextReplacementProvider }
