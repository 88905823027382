import React, { useRef, useState } from 'react'

import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import EmptyFilters from '../EmptyFilters'
import { ScrollAreaRoot } from '../AppliedFilters/AppliedFilters.styles'
import AppliedSearchFilters from './AppliedSearchFilters'
import AppliedDateFilters from './AppliedDateFilters'
import AppliedFeedbackIntentionFilters from './AppliedFeedbackIntentionFilters'
import AppliedSentimentFilters from './AppliedSentimentFilters'
import AppliedFeedbackTypeFilters from './AppliedFeedbackTypeFilters'
import AppliedGenericFilters from './AppliedGenericFilters'
import {
  AppliedAccountsStringFilters,
  AppliedAccountDateFilters,
  AppliedAccountNumericFilters,
  AppliedCustomerUsersStringFilters,
  AppliedCustomerUsersDateFilters,
  AppliedCustomerUsersNumericFilters,
  AppliedAccountBooleanFilters,
  AppliedCustomerUsersBooleanFilters
} from '../AppliedFilters/AppliedCustomerRecords'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { InterestAreaFilterMode } from '@/hooks/filters/useInterestAreaFilterSpliter'
import { useTranslation } from 'react-i18next'
import useFilterEntityStore from '@/store/useFiltersStore/useFilterEntityStore'

function checkHasScroll(element: HTMLElement | null) {
  if (!element) return false
  return element.scrollHeight > element.clientHeight
}

function checkScrollToBottom(element: HTMLElement | null) {
  if (!element) return false
  return element.scrollHeight <= element.clientHeight + element.scrollTop
}

function AppliedFilters() {
  const viewportRef = useRef<HTMLDivElement>(null)
  const [fade, setFade] = useState(false)
  const { currentInterestArea } = useCurrentInterestAreaStore()
  const editMode = useFilterEntityStore(state => state.editMode)
  const splitMode: InterestAreaFilterMode = editMode ? 'all' : 'onlyDefaultFilters'

  const filterItemsArray = [
    AppliedFeedbackIntentionFilters({ splitMode }),
    AppliedSentimentFilters({ splitMode }),
    AppliedFeedbackTypeFilters({ splitMode }),
    AppliedGenericFilters({ splitMode }),
    AppliedDateFilters(),
    AppliedSearchFilters({ splitMode }),
    AppliedAccountsStringFilters({ newFeed: true }),
    AppliedAccountDateFilters({ newFeed: true }),
    AppliedAccountNumericFilters({ newFeed: true }),
    AppliedAccountBooleanFilters({ newFeed: true }),
    AppliedCustomerUsersStringFilters({ newFeed: true }),
    AppliedCustomerUsersDateFilters({ newFeed: true }),
    AppliedCustomerUsersNumericFilters({ newFeed: true }),
    AppliedCustomerUsersBooleanFilters({ newFeed: true })
  ].flat()

  const { t } = useTranslation()

  if (filterItemsArray.length === 0 && !currentInterestArea) return <EmptyFilters />

  const AndSeparator = (
    <Text color="neutralLowLight" css={{ fontSize: '$micro', lineHeight: '$xs' }}>
      {t('AND')}
    </Text>
  )

  if (checkHasScroll(viewportRef.current)) {
    const shouldFade = !checkScrollToBottom(viewportRef.current)
    if (fade !== shouldFade) setFade(shouldFade)
  } else if (fade) {
    setFade(false)
  }

  function onScroll() {
    const scrollToBottom = checkScrollToBottom(viewportRef.current)
    setFade(!scrollToBottom)
  }

  return (
    <ScrollAreaRoot fade={fade}>
      <ScrollAreaViewport onScroll={onScroll} ref={viewportRef}>
        <FlexContainer direction="column" gap="nano">
          {filterItemsArray.map((filterItem, index) => (
            <React.Fragment key={index}>
              {index > 0 ? AndSeparator : null}
              {filterItem}
            </React.Fragment>
          ))}
        </FlexContainer>
      </ScrollAreaViewport>
      <ScrollAreaScrollbar>
        <ScrollAreaThumb />
      </ScrollAreaScrollbar>
    </ScrollAreaRoot>
  )
}

export default AppliedFilters
