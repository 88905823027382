import Button from '@/components/atoms/button'
import ErrorBoundary from '@/components/atoms/error-boundary'
import FlexContainer from '@/components/atoms/flex-container'
import Loader from '@/components/atoms/loader'
import Text from '@/components/atoms/text'
import NoPermission from '@/components/molecules/no-permission/NoPermission'
import { QuestionItem } from '@/components/molecules/upload-csv'
import FormUpload from '@/components/molecules/upload-csv/FormUpload'
import UploadItemCard from '@/components/molecules/upload-csv/UploadItemCard'
import useLocalStorage from '@/hooks/useLocalStorage'
import useLogging from '@/hooks/useLogging'
import useUser from '@/hooks/useUser'
import useIntegrationsStore from '@/store/useIntegrationsStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import { styled } from '@/theme'
import { UPLOAD_PARAMS_KEY, UploadFormData } from '@/types/params/UploadParams'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Container = styled(FlexContainer, {
  maxWidth: '800px',
  flexDirection: 'column',
  gap: '$xxs',
  flex: 1,

  variants: {
    center: {
      true: {
        width: '100%'
      }
    }
  }
})

const UploadSettings = () => {
  const { logException } = useLogging({ context: 'upload-history-data' })
  const [isLoading, setIsLoading] = useState(true)
  const [showUploadForm, setShowUploadForm] = useState(false)

  const integrations = useIntegrationsStore(state => state.integrations)
  const loadIntegrations = useIntegrationsStore(state => state.loadIntegrations)

  const addToast = useToastMessageStore(state => state.addToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const { t } = useTranslation()

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    const loadAllData = async () => {
      setIsLoading(true)
      try {
        await loadIntegrations().catch(error => {
          console.error(error)
          const message = t('failedToLoadImportHistory')
          logException(error, { message })
          addErrorToast({
            text: message
          })
        })

        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        const message = t('failedToLoadImportHistoryAndFeedbackTypes')
        logException(error, { message })
        addErrorToast({ text: message })
      }
    }

    loadAllData()
  }, [])

  const reloadIntegrations = async () => {
    setIsLoading(true)
    try {
      await loadIntegrations()

      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      const message = t('failedToLoadImportHistory')
      logException(error, { message })
      addToast({
        id: 'error-upload-history',
        status: 'error',
        text: message,
        open: true
      })
    }
  }

  const csvboxHistory = useMemo(() => {
    const getTime = (date?: Date) => {
      return !date ? 0 : date.getTime()
    }
    return integrations
      .filter(integration => integration.connector.connector_type === 'file')
      .sort((a, b) => {
        return (
          getTime(new Date(b.last_task?.updated_at || '')) -
          getTime(new Date(a.last_task?.updated_at || ''))
        )
      })
  }, [integrations])

  const onSubmitUpload = async () => {
    reloadIntegrations()
    setShowUploadForm(false)
  }

  const { userPermissions, currentUser } = useUser()
  const isManager = userPermissions.source.includes('manager')

  const [uploadParams] = useLocalStorage<UploadFormData>(UPLOAD_PARAMS_KEY, {
    enableImportCustomers: false,
    enableImportData: false
  })

  if ((!isManager && currentUser) || !uploadParams.enableImportData) {
    return <NoPermission />
  }

  if (isLoading) {
    return (
      <Container alignItems="center" as="main" center justifyContent="center">
        <Loader />
        <Text>{t('loading')}</Text>
      </Container>
    )
  }

  if (!csvboxHistory.length || showUploadForm) {
    return (
      <ErrorBoundary>
        <Container as="main">
          <FlexContainer justifyContent="spaceBetween">
            <QuestionItem direction="column" noMargin>
              <Text as="h2" color="dark" typeface="titleBoldXXS">
                {t('importYourFeedbackFile')}
              </Text>
              <Text as="label" color="dark" typeface="subtitleRegularXXXS">
                {t('whatIsTheTypeOfFeedbackYouWantToImport')}
              </Text>
            </QuestionItem>
            {showUploadForm && (
              <Button onClick={() => setShowUploadForm(false)} variant="secondary">
                {t('cancel')}
              </Button>
            )}
          </FlexContainer>
          <FormUpload onSubmit={onSubmitUpload} resetAfterSubmit showSampleFile />
        </Container>
      </ErrorBoundary>
    )
  }

  return (
    <ErrorBoundary>
      <Container>
        <FlexContainer justifyContent="spaceBetween">
          <QuestionItem direction="column" noMargin>
            <Text as="h2" color="dark" typeface="titleBoldXXS">
              {t('importHistory')}
            </Text>
            <Text as="label" color="dark" typeface="subtitleRegularXXXS">
              {t('belowYouCanSeeYourImportHistory')}
            </Text>
          </QuestionItem>
          <Button onClick={() => setShowUploadForm(true)}>Import Feedback</Button>
        </FlexContainer>

        <FlexContainer
          css={{
            gap: '$xxs',
            mt: '$xs',
            maxHeight: 'calc(100vh - 250px)',
            overflow: 'hidden auto',
            pr: '$nano'
          }}
          direction="column"
        >
          {csvboxHistory.map(item => (
            <UploadItemCard item={item} key={item.id} />
          ))}
        </FlexContainer>
      </Container>
    </ErrorBoundary>
  )
}

export default UploadSettings
