export interface SummaryParamsData {
  enabled: boolean
  model?: string
  focus?: string
  max_words?: string
  prompt?: string
  sample_per_kind?: string
}

export const SUMMARY_PARAMS_KEY = 'summary_params_key'
