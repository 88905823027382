import EmptyIllustration from '@/assets/drawings/dashboard_empty.png'
import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import useUser from '@/hooks/useUser'
import { styled } from '@/theme'
import { useTranslation } from 'react-i18next'

const Illustration = styled('img', {
  width: 556
})

interface Props {
  onAddNewChartClick: () => void
}

const EmptyDashboard = ({ onAddNewChartClick }: Props) => {
  const { t } = useTranslation()
  const { userPermissions } = useUser()
  const isManager = userPermissions.tracking.includes('manager')

  const secondLineMessage = isManager
    ? t('addOneAndPlantASeedOnIt')
    : t('askYourOrganizationAdminToCreateCharts')

  return (
    <FlexContainer
      alignItems="center"
      css={{ pt: '$xxl', width: '100%' }}
      direction="column"
      gap="xs"
    >
      <Illustration
        alt="An illustration of a bird and a small plant in black and white."
        src={EmptyIllustration}
      />
      <Text align="center">
        {t('oopsNoChartsHere')}
        <br />
        {secondLineMessage}
      </Text>
      {isManager && <Button onClick={onAddNewChartClick}>{t('addANewChart')}</Button>}
    </FlexContainer>
  )
}

export default EmptyDashboard
