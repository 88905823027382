import Text from '@/components/atoms/text'
import UserIndicator from '../../user-indicator/UserIndicator'
import { useUIStore } from '@/store'
import ManageUser from '../../manage-users/ManageUsersTopbar'
import FlexContainer from '@/components/atoms/flex-container'
import UserPlanBadge from './UserPlanBadge'
import { StyledHeader } from './TopBar.styles'

function TopBarTopicPlan() {
  const { pageTitle } = useUIStore()

  return (
    <StyledHeader className="topbar">
      <Text as="h1" color="neutralLowPure" fontSize="xxs" fontWeight="regular" lineHeight="default">
        {pageTitle}
      </Text>
      <FlexContainer alignItems="center" gap="xs">
        <UserPlanBadge />
        <ManageUser />
        <UserIndicator />
      </FlexContainer>
    </StyledHeader>
  )
}

export default TopBarTopicPlan
