import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'

export const ExploreContainer = styled('div', {
  display: 'flex',
  pr: 0,
  boxSizing: 'border-box',
  height: '100%',
  flexGrow: 1,
  overflow: 'hidden'
})

export const PageContainer = styled(FlexContainer, {
  height: '100%',
  flexDirection: 'column'
})

export const FeedContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: `0 $feedContainerPadding`,
  pt: '$xs'
})
