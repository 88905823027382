import useLetterCase from '@/hooks/useLetterCase'
import { useFiltersStore } from '@/store'
import { FilterBoolean } from '@/types/filters/Filters'
import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'
import FilterItem from '../FilterItem'
import FilterItemDropdownCustomerRecordsBoolean from '../FilterItem/FilterItemDropdownCustomerRecordsBoolean'
import { useFeedFiltersStore } from '@/store/useFiltersStore'

interface Props {
  newFeed?: boolean
}

function AppliedAccountBooleanFilters({ newFeed = false }: Props = { newFeed: false }) {
  const { snakeToTitle } = useLetterCase()

  const useStore = newFeed ? useFeedFiltersStore : useFiltersStore

  const apply = useStore(state => state.applyAccounts)
  const booleanFilters = useStore(state => state.accountBooleanFilters, shallow)
  const removeBooleanFilter = useStore(state => state.removeAccountBooleanFilter)

  const appliedBooleanFilters = useMemo(
    () => booleanFilters.filter(filter => filter.value !== null),
    [booleanFilters]
  )

  function removeBooleanFilterOption(filter: FilterBoolean) {
    removeBooleanFilter(filter.key)
    apply()
  }

  return [
    ...appliedBooleanFilters.map(filter => (
      <FilterItem
        editButton={
          <FilterItemDropdownCustomerRecordsBoolean filter={filter} recordType="accounts" />
        }
        key={filter.key}
        name={`Accounts/${snakeToTitle(filter.name)}`}
        onDelete={() => removeBooleanFilterOption(filter)}
      />
    ))
  ]
}

export default AppliedAccountBooleanFilters
