import TooltipCard from '@/components/atoms/tooltip-card'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  text: ReactNode
}

const GenericTooltip = ({ children, text }: Props) => {
  return (
    <TooltipCard.Root delayDuration={300} disableHoverableContent={false}>
      <TooltipCard.Trigger asChild>{children}</TooltipCard.Trigger>
      <TooltipPrimitive.Portal>
        <TooltipCard.Content
          onPointerDownOutside={e => {
            e.preventDefault()
          }}
          side="bottom"
          sideOffset={8}
        >
          <TooltipCard.TextContent>{text}</TooltipCard.TextContent>
        </TooltipCard.Content>
      </TooltipPrimitive.Portal>
    </TooltipCard.Root>
  )
}

export default GenericTooltip
