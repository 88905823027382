import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { useFiltersStore } from '@/store'
import { FilterDatetimeValue } from '@/types/filters/Filters'
import { Period } from '@/types/periods'
import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'
import DateFilter from '../../DateFilter'

export interface DatetimeFilterSectionProps {
  title: string
  type: string
  name: string
  filterKey: string
}

const DatetimeFilterSection = ({ title, type, name, filterKey }: DatetimeFilterSectionProps) => {
  const datetimeFilters = useFiltersStore(state => state.datetimeFilters, shallow)
  const setDatetimeFilter = useFiltersStore(state => state.setDatetimeFilter)

  const storedFilter = useMemo(
    () => datetimeFilters.find(filter => filter.key === filterKey),
    [datetimeFilters, filterKey]
  )
  const appliedRange = storedFilter?.value ?? null
  const appliedPeriod = storedFilter?.period ?? 'allTime'

  const setDateRange = (range: FilterDatetimeValue, period?: Period) => {
    const actualPeriod = period ?? 'custom'
    setDatetimeFilter(
      {
        key: filterKey,
        name,
        type,
        value: range,
        period: actualPeriod
      },
      true
    )
  }

  return (
    <FlexContainer
      alignItems="center"
      css={{ px: '$xxxs', py: '$nano' }}
      justifyContent="spaceBetween"
    >
      <Text typeface="subtitleRegularXXXS">{title}</Text>
      <DateFilter
        appliedPeriod={appliedPeriod}
        appliedRange={appliedRange}
        setDateRange={setDateRange}
      />
    </FlexContainer>
  )
}

export default DatetimeFilterSection
