import { ArrowFatLinesRight, CheckCircle } from '@phosphor-icons/react'

import FlexContainer from '@/components/atoms/flex-container'
import { TableCell } from '@/components/atoms/table'
import Text from '@/components/atoms/text'
import Tooltip from '@/components/atoms/tooltip'
import { colors } from '@/theme'
import { IntegrationFieldContainer } from './FieldsMatchedSection.styles'

interface FieldsMatchedRowProps {
  displayValue: string
  fieldName: string
  tooltipValue: string
}

function FieldsMatchedRow(props: FieldsMatchedRowProps) {
  return (
    <tr>
      <TableCell>
        <Tooltip offset={2} side="top" text={props.tooltipValue} variant="small">
          <IntegrationFieldContainer>
            <Text css={{ whiteSpace: 'nowrap' }} typeface="subtitleRegularXXXS">
              {props.displayValue}
            </Text>
          </IntegrationFieldContainer>
        </Tooltip>
      </TableCell>

      <TableCell>
        <FlexContainer justifyContent="center">
          <ArrowFatLinesRight size={24} weight="fill" />
        </FlexContainer>
      </TableCell>

      <TableCell>
        <Text typeface="subtitleRegularXXXS">{props.fieldName}</Text>
      </TableCell>

      <TableCell aria-hidden>
        <FlexContainer justifyContent="center">
          <CheckCircle color={colors.feedbackPositivePure} size={24} />
        </FlexContainer>
      </TableCell>
    </tr>
  )
}

export default FieldsMatchedRow
