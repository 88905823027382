import Bone from '@/components/atoms/bone'
import FlexContainer from '@/components/atoms/flex-container'

interface ListSkeletonProps {
  count?: number
  itemHeight?: string | number
  gap?: number
}

const ListSkeleton = ({ count = 5, itemHeight = 40, gap = 4 }: ListSkeletonProps) => (
  <FlexContainer css={{ width: '100%', gap }} direction="column">
    {Array(count)
      .fill(null)
      .map((_, index) => (
        <Bone css={{ width: '100%', height: itemHeight }} key={index} />
      ))}
  </FlexContainer>
)

export default ListSkeleton
