import { styled } from '@/theme'
import { growAnimation } from '@/theme/animations'

export const VolumeBarContainer = styled('div', {
  width: '100%',
  color: '$pajaritoDarker',
  fontSize: 14,
  borderRadius: 4,

  variants: {
    clickable: {
      true: {
        cursor: 'pointer'
      }
    },
    isOutside: {
      true: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,

        '& > span': {
          fontSize: 12,
          fontWeight: 500
        }
      }
    }
  }
})

export const Bar = styled('div', {
  position: 'relative',
  height: 24,
  backgroundColor: '$greenBackground',
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,

  display: 'flex',
  alignItems: 'center',
  paddingLeft: 4,

  transitionProperty: 'width',
  transitionDuration: '400ms',
  transitionTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',

  variants: {
    fullRounded: {
      true: {
        borderRadius: 20,
        paddingLeft: 0
      }
    },
    height: {
      default: {
        height: 24
      },
      medium: {
        height: 12
      },
      small: {
        height: 8
      }
    }
  },

  ...growAnimation
})
