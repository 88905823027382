import { HandPalm } from '@phosphor-icons/react'

import FilterSubMenu from '../AddFilterMenu/FilterSubMenu'
import useFeedbackIntentionSelection from './useFeedbackIntentionSelection'
import FeedbackIntentionContent from './FeedbackIntentionContent'
import { useTranslation } from 'react-i18next'

const FeedbackIntentionSubMenu = () => {
  const { selectOption, isChecked, hasChanges, apply } = useFeedbackIntentionSelection()
  const { t } = useTranslation()

  return (
    <FilterSubMenu
      icon={<HandPalm />}
      isDisabled={!hasChanges}
      onApply={apply}
      title={t('feedbackIntention')}
    >
      <FeedbackIntentionContent isChecked={isChecked} selectOption={selectOption} />
    </FilterSubMenu>
  )
}

export default FeedbackIntentionSubMenu
