import { useState } from 'react'
import { CheckCircle, IconContext, Info, WarningCircle, X, XCircle } from '@phosphor-icons/react'

import FlexContainer from '../flex-container'
import Text from '../text'
import { CloseButton, Container } from './NotificationBanner.styles'

export interface NotificationBannerProps {
  description?: string
  status: 'informative' | 'negative' | 'positive' | 'warning'
  title: string
  persistent?: boolean
  onClose?: React.MouseEventHandler<HTMLButtonElement>
}

function NotificationBanner(props: NotificationBannerProps) {
  const [open, setOpen] = useState(true)

  function getStatusIcon() {
    switch (props.status) {
      case 'informative':
        return <Info />
      case 'negative':
        return <XCircle />
      case 'positive':
        return <CheckCircle />
      case 'warning':
        return <WarningCircle />
      default:
        return null
    }
  }

  const close: React.MouseEventHandler<HTMLButtonElement> = e => {
    props.onClose?.(e)
    setOpen(false)
  }

  return open ? (
    <Container multiline={Boolean(props.description)} role="alert" status={props.status}>
      <IconContext.Provider value={{ size: 32 }}>{getStatusIcon()}</IconContext.Provider>
      <FlexContainer direction="column">
        <Text as="strong" typeface="titleBoldXXS">
          {props.title}
        </Text>
        {props.description ? (
          <Text data-testid="notification-banner-description" typeface="paragraphRegularMicro">
            {props.description}
          </Text>
        ) : null}
      </FlexContainer>
      {!props.persistent && (
        <CloseButton aria-label="Close notification" onClick={close} type="button">
          <X size={24} weight="bold" />
        </CloseButton>
      )}
    </Container>
  ) : null
}

export default NotificationBanner
