import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import BirdieEmptyCarrier from '@/assets/drawings/bird_empty_carrier1.5x.png'
import ImportButton from '../ImportButton'
import sampleFile from '@/assets/samples/Customer Record Sample - Account.csv'
import { useTranslation } from 'react-i18next'

interface Props {
  onImport: (result: boolean, data: unknown) => void
}

const EmptyUsers = ({ onImport }: Props) => {
  const { t } = useTranslation()
  return (
    <FlexContainer
      alignItems="center"
      css={{ maxWidth: 800 }}
      direction="column"
      gap="xs"
      justifyContent="center"
    >
      <img alt="A bird carrying a empty card" height={200} src={BirdieEmptyCarrier} width={192} />
      <Text
        color="neutralLowPure"
        css={{ textAlign: 'center', maxWidth: '680px' }}
        fontSize="xxs"
        fontWeight="regular"
        lineHeight="xxl"
      >
        {t(
          'youStillDontHaveAnyUserDataGetOurTemplateAndImportYourUserDataToGetEvenMoreInDepthFeedbackSegmentationAndAnalysis'
        )}{' '}
        <Text
          as="a"
          color="neutralLowPure"
          download
          fontSize="xxs"
          fontWeight="regular"
          href={sampleFile}
          lineHeight="xxl"
          target="_blank"
        >
          {t('checkOurTemplate')}
        </Text>
        .{' '}
      </Text>
      <ImportButton importSheet="users" onImport={onImport} />
    </FlexContainer>
  )
}

export default EmptyUsers
