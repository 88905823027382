import i18n from '../plugins/i18n/i18n'

export const defaultModel = 'openai'
export const modelName = {
  openai: i18n.t('fasterButConsideringASmallerVolumeOfFeedback'),
  anthropic: i18n.t('slowerButConsideringABiggerVolumeOfFeedback')
}

export type PromptTemplate = {
  name: string
  message: string
  prompt: string
}
