import useDatetimeFiltersQuery from '@/hooks/filters/useDatetimeFiltersQuery'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import { FilterDatetimeValue } from '@/types/filters/Filters'
import { Period } from '@/types/periods'
import { DateValue, getLocalTimeZone, now, parseDate } from '@internationalized/date'
import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'

interface Params {
  filterKey: string
  name: string
  enabled: boolean
}

const useDatetimeGenericFilter = ({ filterKey, name, enabled }: Params) => {
  const { data, isLoading } = useDatetimeFiltersQuery({ name, enabled })

  const minDate: DateValue = useMemo(
    () => parseDate(data?.startDate?.split('T')?.[0] ?? '2000-01-01'),
    [data]
  )
  const maxDate: DateValue = useMemo(
    () => (data ? parseDate(data?.endDate?.split('T')?.[0]) : now(getLocalTimeZone())),
    [data]
  )

  const datetimeFilters = useFeedFiltersStore(state => state.datetimeFilters, shallow)
  const setDatetimeFilters = useFeedFiltersStore(state => state.setDatetimeFilter)

  const storedFilter = useMemo(
    () => datetimeFilters.find(filter => filter.key === filterKey),
    [datetimeFilters, filterKey]
  )

  const appliedRange = storedFilter?.value ?? null
  const selectedRange = storedFilter?.draftValue ?? null
  const appliedPeriod = storedFilter?.period ?? 'allTime'

  const setDateRange = (range: FilterDatetimeValue, period?: Period) => {
    const actualPeriod = period ?? 'custom'
    setDatetimeFilters(
      {
        key: filterKey,
        name,
        type: 'datetime',
        value: range,
        period: actualPeriod
      },
      false
    )
  }

  const onApply = () => {
    setDatetimeFilters(
      {
        key: filterKey,
        name,
        type: 'datetime',
        value: storedFilter?.draftValue ?? null,
        period: storedFilter?.draftPeriod ?? 'custom'
      },
      true
    )
  }

  const hasChanges = useMemo(
    () => JSON.stringify(storedFilter?.draftValue) !== JSON.stringify(storedFilter?.value),
    [storedFilter]
  )

  return {
    minDate,
    maxDate,
    appliedRange,
    selectedRange,
    appliedPeriod,
    setDateRange,
    isLoading,
    hasChanges,
    onApply
  }
}

export default useDatetimeGenericFilter
