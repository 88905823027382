import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'

import { useFiltersStore } from '@/store'
import { FilterDatetime, FilterNumeric, FilterString } from '@/types/filters/Filters'
import FilterItem, {
  FilterItemDropdownDatetime,
  FilterItemDropdownNumeric,
  FilterItemDropdownString
} from '../FilterItem'

function AppliedCustomFieldFilters() {
  const apply = useFiltersStore(state => state.apply)
  const datetimeFilters = useFiltersStore(state => state.datetimeFilters, shallow)
  const numericFilters = useFiltersStore(state => state.numericFilters, shallow)
  const removeDatetimeFilter = useFiltersStore(state => state.removeDatetimeFilter)
  const removeNumericFilter = useFiltersStore(state => state.removeNumericFilter)
  const removeStringFilter = useFiltersStore(state => state.removeStringFilter)
  const stringFilters = useFiltersStore(state => state.stringFilters, shallow)

  const appliedDatetimeFilters = useMemo(() => {
    const customFieldDatetimeTypes = ['custom_fields_datetime']

    return datetimeFilters.filter(filter => {
      return filter.value !== null && customFieldDatetimeTypes.includes(filter.type)
    })
  }, [datetimeFilters])

  const appliedNumericFilters = useMemo(() => {
    const customFieldNumericTypes = ['custom_fields_integer', 'custom_fields_number']

    return numericFilters.filter(filter => {
      return customFieldNumericTypes.includes(filter.type) && filter.option !== 'all'
    })
  }, [numericFilters])

  const appliedStringFilters = useMemo(() => {
    const customFieldStringTypes = ['custom_fields_string']

    return stringFilters.filter(filter => {
      return filter.selected.length > 0 && customFieldStringTypes.includes(filter.type)
    })
  }, [stringFilters])

  function removeDatetimeFilterOptions(filter: FilterDatetime) {
    removeDatetimeFilter(filter.key)
    apply()
  }

  function removeNumericFilterOptions(filter: FilterNumeric) {
    removeNumericFilter(filter.key)
    apply()
  }

  function removeStringFilterOptions(filter: FilterString) {
    filter.selected.forEach(option => removeStringFilter(filter.key, option))
    apply()
  }

  return [
    ...appliedDatetimeFilters.map(filter => (
      <FilterItem
        editButton={<FilterItemDropdownDatetime filter={filter} />}
        key={filter.key}
        name="Custom fields"
        onDelete={() => removeDatetimeFilterOptions(filter)}
      />
    )),
    ...appliedNumericFilters.map(filter => (
      <FilterItem
        editButton={<FilterItemDropdownNumeric filter={filter} />}
        key={filter.key}
        name="Custom fields"
        onDelete={() => removeNumericFilterOptions(filter)}
      />
    )),
    ...appliedStringFilters.map(filter => (
      <FilterItem
        editButton={<FilterItemDropdownString filter={filter} />}
        key={filter.key}
        name="Custom fields"
        onDelete={() => removeStringFilterOptions(filter)}
      />
    ))
  ]
}

export default AppliedCustomFieldFilters
