import { CSS } from '@/theme'
import { forwardRef, Ref } from 'react'
import FlexContainer from '../flex-container'
import Tooltip from '../tooltip'
import { SliderProps } from './SimpleSlider'
import { StyledRange, StyledSlider, StyledThumb, StyledTrack } from './Slider.styles'

const tooltipCSS: CSS = {
  padding: '$nano $xxxs'
}

interface Props extends SliderProps {
  css?: CSS
  minPlaceholder?: string
  maxPlaceholder?: string
  placeholderAt?: 'bottom' | 'sides'
}

const Slider = forwardRef(
  (
    {
      range = true,
      min,
      max,
      minStepsBetweenThumbs = 1,
      value,
      css,
      orientation = 'horizontal',
      minPlaceholder,
      maxPlaceholder,
      placeholderAt = 'bottom',
      ...props
    }: Props,
    forwardRef: Ref<HTMLSpanElement>
  ) => {
    const minValueText = value?.[0]?.toString() ?? ''
    const maxValueText = value?.[1]?.toString() ?? ''

    const tooltipSide = orientation === 'horizontal' ? 'bottom' : 'right'

    return (
      <FlexContainer css={css} direction={placeholderAt === 'bottom' ? 'column' : 'row'}>
        {placeholderAt === 'sides' && (
          <FlexContainer css={{ fontSize: 14 }} justifyContent="spaceBetween">
            <span>{minPlaceholder || min}</span>
          </FlexContainer>
        )}

        <StyledSlider
          max={max}
          min={min}
          minStepsBetweenThumbs={minStepsBetweenThumbs}
          orientation={orientation}
          ref={forwardRef}
          value={value}
          {...props}
        >
          <StyledTrack>
            <StyledRange />
          </StyledTrack>
          <Tooltip css={tooltipCSS} side={tooltipSide} text={minValueText}>
            <StyledThumb />
          </Tooltip>
          {range && (
            <Tooltip css={tooltipCSS} side={tooltipSide} text={maxValueText}>
              <StyledThumb />
            </Tooltip>
          )}
        </StyledSlider>
        {placeholderAt === 'sides' && (
          <FlexContainer css={{ fontSize: 14 }} justifyContent="spaceBetween">
            <span>{maxPlaceholder || max}</span>
          </FlexContainer>
        )}

        {placeholderAt === 'bottom' && (
          <FlexContainer css={{ fontSize: 14 }} justifyContent="spaceBetween">
            <span>{minPlaceholder || min}</span>
            <span>{maxPlaceholder || max}</span>
          </FlexContainer>
        )}
      </FlexContainer>
    )
  }
)

Slider.displayName = 'Slider'

export default Slider
