import FlexContainer from '@/components/atoms/flex-container'
import FeedbackOptions from './FeedbackOptions'

function FeedbackChatOptions() {
  // const { total, isLoading } = useFeedbacks({ store: 'chat' })
  // const { chatData, isLoadingChatData } = useChatStore(
  //   state => ({ chatData: state.chatData, isLoadingChatData: state.isLoadingChatData }),
  //   shallow
  // )

  return (
    <>
      <FlexContainer
        alignItems="center"
        css={{
          '& svg': { minWidth: 18, mb: '-3px', ml: '$nano', cursor: 'pointer' },
          maxWidth: 375
        }}
        gap="nano"
      >
        {/* {!isLoading && !isLoadingChatData && (
          <Text
            as="p"
            color="neutralLowLight"
            fontSize="micro"
            fontWeight="regular"
            lineHeight="lg"
          >
            <>
              Showing the first <strong>{chatData?.feedbackTakenIntoAccount || 0} feedback </strong>{' '}
              considered by Birdie Assistant out of <strong>{total}</strong> that matches the
              filter/search.
              <Popover
                buttonChildren={<Info color={colors.neutralLowLight} size={18} />}
                customButton
              >
                <FlexContainer
                  css={{
                    padding: '$xxs',
                    width: '480px',
                    maxHeight: 'var(--radix-popover-content-available-height)'
                  }}
                  direction="column"
                  gap="micro"
                >
                  <Text as="p" fontSize="xxxs" fontWeight="regular" lineHeight="xl">
                    {`The assistant relies on a concept known as a "context window", which defines how
                    much information it can use to generate responses. We carefully choose a subset
                    of feedback that fits within this context window to uphold accuracy and
                    efficiency based on the assistant's preferred settings. This approach guarantees
                    that the assistant can deliver insightful responses grounded in pertinent
                    information, all while respecting the context window's constraints. This method
                    balances performance and responsiveness, ultimately improving the user
                    experience. Applying the proper filters to narrow the context window is key to
                    getting accurate responses.`}
                  </Text>
                </FlexContainer>
              </Popover>
            </>
          </Text>
        )} */}
      </FlexContainer>
      <FeedbackOptions showSortBy={false} withResultCounter={false} />
    </>
  )
}

export default FeedbackChatOptions
