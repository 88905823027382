import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Input from '@/components/atoms/input'
import { SelecteableCardItem, IconSelectableCard } from '@/components/atoms/selectable-card'
import Text from '@/components/atoms/text'
import useSignupStore from '@/store/useSignupStore'
import { colors } from '@/theme'
import {
  Browsers,
  BugBeetle,
  ChartBar,
  ChartPie,
  ChatCircleDots,
  CircleWavyQuestion,
  CurrencyCircleDollar,
  Heart,
  UserFocus
} from '@phosphor-icons/react'
import { useState } from 'react'
import { shallow } from 'zustand/shallow'
import { UseCasesGrid } from './Signup.styles'
import { useTranslation, Trans } from 'react-i18next'

const OnboardingUseCase = () => {
  const { firstName, useCase } = useSignupStore(
    state => ({ firstName: state.firstName, useCase: state.useCase }),
    shallow
  )
  const { t } = useTranslation()

  const setUseCase = useSignupStore(state => state.setUseCase)
  const setOnboardingStep = useSignupStore(state => state.setOnboardingStep)

  const useCases: SelecteableCardItem[] = [
    {
      icon: <Heart />,
      iconColor: colors.feedbackNegativePure,
      text: t('discoverHowToIncreaseUserSatisfactionRatings')
    },
    {
      icon: <ChartBar />,
      iconColor: colors.feedbackPositivePure,
      text: t('discoverWhatDrivesHigherSatisfactionRatings')
    },
    {
      icon: <UserFocus />,
      iconColor: colors.neutralLowPure,
      text: t('discoverHowToBeatTheCompetition')
    },
    {
      icon: <CurrencyCircleDollar />,
      iconColor: colors.feedbackWarningPure,
      text: t('understandHowToReduceChurn')
    },
    {
      icon: <ChartPie />,
      iconColor: colors.feedbackInformativePure,
      text: t('understandTheMostRelevantCustomerProblems')
    },
    {
      icon: <Browsers />,
      iconColor: colors.brandPrimaryPure,
      text: t('understandTheMostRequestedFeatures')
    },
    {
      icon: <CircleWavyQuestion />,
      iconColor: colors.feedbackPositivePure,
      text: t('understandTheMostFrequentQuestions')
    },
    {
      icon: <BugBeetle />,
      iconColor: colors.feedbackInformativePure,
      text: t('discoverBugsAndUsabilityIssuesFaster')
    },
    {
      icon: <ChatCircleDots />,
      iconColor: colors.feedbackNegativePure,
      text: t('other')
    }
  ]

  const isInputActive = useCase === 'Other'
  const [useCaseInput, setUseCaseInput] = useState('')

  const isContinueDisabled = !useCase

  function onContinueClick() {
    if (isInputActive && useCaseInput.length > 0) {
      setUseCase(useCaseInput)
    }
    setOnboardingStep(2)
  }

  return (
    <FlexContainer css={{ width: '100%', paddingRight: 100 }} direction="column" gap="xs">
      <div>
        <Text as="h1" typeface="titleRegularSmall">
          <Trans
            components={{ strong: <strong /> }}
            i18nKey="mainGoalMessage"
            values={{ firstName }}
          />
        </Text>
        <Text as="p" typeface="paragraphRegularMicro">
          {t('chooseTheMostImportantObjectiveNowThisAnswerWillHelpUsToRecommendFeaturesForYou')}
        </Text>
      </div>

      <UseCasesGrid>
        {useCases.map((item, index) => (
          <IconSelectableCard
            {...item}
            format="horizontal"
            key={index}
            onClick={() => setUseCase(item.text)}
            selected={item.text === useCase}
          />
        ))}
      </UseCasesGrid>

      {isInputActive && (
        <Input
          css={{ width: 472 }}
          onChange={e => setUseCaseInput(e.currentTarget.value)}
          placeholder={t('describeYourOtherGoalOptional')}
          small
          value={useCaseInput}
        />
      )}

      <Button disabled={isContinueDisabled} onClick={onContinueClick} variant="exception">
        {t('continue')}
      </Button>
    </FlexContainer>
  )
}

export default OnboardingUseCase
