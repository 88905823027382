import OptionsMenu from '@/components/atoms/options-menu'
import { ChatText, Chats } from '@phosphor-icons/react'
import { ReactNode } from 'react'
import Button from '@/components/atoms/button'
import { shallow } from 'zustand/shallow'
import { VolumeByType } from '@/types/filters/Filters'
import { useFiltersStore } from '@/store'
import useSegment from '@/hooks/useSegment'

const volumeByIcons: Record<VolumeByType, ReactNode> = {
  'feedback-record': <ChatText size={24} weight="regular" />,
  ticket: <Chats size={24} weight="regular" />
}

function AnalyticsOptions() {
  const { track } = useSegment()

  const { volumeBy } = useFiltersStore(state => ({ volumeBy: state.volumeBy }), shallow)
  const setVolumeBy = useFiltersStore(state => state.setVolumeBy, shallow)

  const onVolumeByClick = (value: VolumeByType) => {
    track('explore_user_volume_by', { volume_type: value })
    setVolumeBy(value)
  }

  return (
    <>
      <OptionsMenu
        customTrigger={
          <Button size="square" variant="flat-bordered">
            {volumeByIcons[volumeBy]}
          </Button>
        }
        options={[
          {
            text: 'Volume by feedback record',
            icon: volumeByIcons['feedback-record'],
            bold: volumeBy === 'feedback-record',
            onClick: () => onVolumeByClick('feedback-record')
          },
          {
            text: 'Volume by ticket',
            icon: volumeByIcons.ticket,
            bold: volumeBy === 'ticket',
            onClick: () => onVolumeByClick('ticket')
          }
        ]}
      />
    </>
  )
}

export default AnalyticsOptions
