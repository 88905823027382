import useFiltersStore, { useFeedFiltersStore } from '@/store/useFiltersStore'
import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'

interface Params {
  newFeed?: boolean
}

const useFiltersToText = ({ newFeed = true }: Params = { newFeed: true }) => {
  const useStore = newFeed ? useFeedFiltersStore : useFiltersStore

  const { search, stringFilters, numericFilters, textFilters, booleanFilters } = useStore(
    state => ({
      search: state.search,
      stringFilters: state.stringFilters,
      numericFilters: state.numericFilters,
      textFilters: state.textFilters,
      booleanFilters: state.booleanFilters
    }),
    shallow
  )

  const stringFiltersTextArray = useMemo(
    () => stringFilters.map(filter => `${filter.key}=${filter.selected.join(', ')}`),
    [stringFilters]
  )

  const numericFiltersTextArray = useMemo(
    () =>
      numericFilters
        .map(filter => {
          if (!filter.value) return null
          if (filter.option === 'between') {
            return `${filter.key} between ${filter.value[0]} and ${filter.value[1]}`
          }
          return `${filter.key}=${filter.value[0]}`
        })
        .filter(Boolean),
    [numericFilters]
  )

  const textFiltersTextArray = useMemo(
    () => textFilters.map(filter => `${filter.key}=${filter.value}`),
    [textFilters]
  )

  const booleanFiltersTextArray = useMemo(
    () =>
      booleanFilters
        .map(filter => {
          if (filter.value === null) return null
          if (filter.value) return `${filter.key}=true`
          return `${filter.key}=false`
        })
        .filter(Boolean),
    [booleanFilters]
  )

  const stringFiltersText = useMemo(
    () => stringFiltersTextArray.join(' and '),
    [stringFiltersTextArray]
  )
  const numericFiltersText = useMemo(
    () => numericFiltersTextArray.join(' and '),
    [numericFiltersTextArray]
  )
  const textFiltersText = useMemo(() => textFiltersTextArray.join(' and '), [textFiltersTextArray])
  const booleanFiltersText = useMemo(
    () => booleanFiltersTextArray.join(' and '),
    [booleanFiltersTextArray]
  )

  const allFiltersText = useMemo(
    () =>
      [
        ...stringFiltersTextArray,
        ...numericFiltersTextArray,
        ...textFiltersTextArray,
        ...booleanFiltersTextArray,
        search
      ].join(' and '),
    [
      stringFiltersTextArray,
      numericFiltersTextArray,
      textFiltersTextArray,
      booleanFiltersTextArray,
      search
    ]
  )

  return {
    allFiltersText,
    searchText: search,
    stringFiltersText,
    numericFiltersText,
    textFiltersText,
    booleanFiltersText
  }
}

export default useFiltersToText
