import { CSS } from '@/theme'
import IconButton from './IconButton'

export interface IconToggleButtonProps {
  children: React.ReactNode
  active: boolean
  onActiveChange: (active: boolean) => void
  css?: CSS
  className?: string
  disabled?: boolean
}

const IconToggleButton = ({
  children,
  active,
  onActiveChange,
  css,
  className,
  disabled = false
}: IconToggleButtonProps) => {
  const onClick = () => {
    onActiveChange(!active)
  }

  return (
    <IconButton
      active={active}
      className={className}
      css={css}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </IconButton>
  )
}

export default IconToggleButton
