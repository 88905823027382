import { CSS, styled } from '@/theme'

const activeStyle: CSS = {
  border: '2px solid $brandPrimaryPure',
  outline: 'none',
  boxShadow: '$soft',

  '.hidden': {
    opacity: 1
  }
}

export const CardContainer = styled('article', {
  background: '$neutralHighLight',
  borderRadius: '$xs',
  padding: '$xs $xxs',
  color: '$brandPrimaryMedium',
  fontSize: '$xxxs',
  border: '2px solid $neutralHighLight',
  transition: '.2s',
  cursor: 'pointer',

  '.hidden': {
    opacity: 0,
    transition: '.2s'
  },

  '&:hover, &:active': activeStyle,

  variants: {
    open: {
      true: activeStyle
    }
  }
})

export const CollectionTypeChip = styled('span', {
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  borderRadius: '$micro',
  padding: '$nano $micro',
  bAll: 'currentColor',
  fontSize: '$micro',
  textTransform: 'capitalize',
  width: 'fit-content'
})
