import FilterSubMenu from '../../AddFilterMenu/FilterSubMenu'

import { Buildings } from '@phosphor-icons/react'
import FlexContainer from '@/components/atoms/flex-container'
import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import { FilterSubMenuScrollArea } from '../../AddFilterMenu/AddFilterMenu.styles'
import AccountIdFilterSubmenu from './AccountIdFilterSubmenu'
import AccountStringFilterSubmenu from './AccountStringFilterSubmenu'
import AccountDateFilterSubmenu from './AccountDateFilterSubmenu'
import useAccountsFilters from '@/hooks/filters/useAccountsFilters'
import useFiltersByType from '../common/useFiltersByType'
import CustomerRecordsNumericFilterSubmenu from '../common/CustomerRecordsNumericFilterSubmenu'
import CustomerRecordsBooleanFilterSubmenu from '../common/CustomerRecordsBooleanFilterSubmenu'

interface AccountsFiltersSectionProps {
  enabled: boolean
  newFeed?: boolean
}

const AccountsFiltersSection = ({ enabled, newFeed = false }: AccountsFiltersSectionProps) => {
  const { fields, isLoading } = useAccountsFilters()

  const { idFilters, stringFilters, numberFilters, datetimeFilters, booleanFilters } =
    useFiltersByType(fields)

  if (!enabled) return <></>

  return (
    <FilterSubMenu hideButton icon={<Buildings />} isLoading={isLoading} title="Accounts">
      <FilterSubMenuScrollArea css={{ mx: '-$micro' }}>
        <ScrollAreaViewport css={{ maxHeight: 320 }}>
          <FlexContainer direction="column">
            {idFilters.map(filter => (
              <AccountIdFilterSubmenu filter={filter} key={filter.key} newFeed={newFeed} />
            ))}

            {stringFilters.map(filter => (
              <AccountStringFilterSubmenu
                filter={filter}
                key={filter.key + filter.name}
                newFeed={newFeed}
              />
            ))}

            {datetimeFilters.map(filter => (
              <AccountDateFilterSubmenu filter={filter} key={filter.key} newFeed={newFeed} />
            ))}

            {numberFilters.map(filter => (
              <CustomerRecordsNumericFilterSubmenu
                filter={filter}
                key={filter.key}
                newFeed={newFeed}
                recordType="accounts"
              />
            ))}

            {booleanFilters.map(filter => (
              <CustomerRecordsBooleanFilterSubmenu
                filter={filter}
                key={filter.key}
                newFeed={newFeed}
                recordType="accounts"
              />
            ))}
          </FlexContainer>
        </ScrollAreaViewport>
        <ScrollAreaScrollbar orientation="vertical">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </FilterSubMenuScrollArea>
    </FilterSubMenu>
  )
}

export default AccountsFiltersSection
