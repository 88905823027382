import { styled } from '@/theme'

export const ChipContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '$pajarito',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: 1.5,
  borderRadius: 8,
  flexGrow: 0,
  gap: '$micro',
  padding: '$nano $micro',
  backgroundColor: '$greenBackground',
  boxSizing: 'border-box',

  width: '100%',
  maxWidth: 110,
  justifyContent: 'space-between',

  '@media(min-width: 1200px)': {
    maxWidth: 130
  },

  '& .label': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  '.icon': {
    cursor: 'pointer',
    position: 'relative',
    top: 2
  },

  '.check': {
    color: '$pajarito'
  },

  '.delete': {
    color: '$red',
    display: 'none'
  },

  '&:hover': {
    '.check': { display: 'none' },
    '.delete': { display: 'block' }
  },

  '.icon.delete.dismissed': {
    display: 'block'
  },

  '.icon.check.dismissed': {
    display: 'none'
  },

  variants: {
    topicKeyword: {
      true: {
        width: 'fit-content',
        maxWidth: 300,
        gap: '$nano',
        flexGrow: 0,

        borderRadius: 20,
        fontWeight: 'normal',
        padding: '2px 8px',
        '& svg': { size: 18 }
      }
    },

    ghost: {
      true: {
        background: 'transparent',
        bAll: '$stroke'
      }
    },

    disabled: {
      true: {
        backgroundColor: '$stroke',
        color: '$grey',
        border: 'none'
      }
    }
  }
})
