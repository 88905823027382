import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { Intention } from '@/types/reasons'
import { intentionIcons } from '@/utils/intention'
import { IconContext } from '@phosphor-icons/react'
import { colors } from '@/theme'

const emptyTextMap: Partial<Record<Intention, string>> = {
  compliment:
    'There are no Findings related to compliments. Please review the selected filters and dates.',
  issue: 'There are no Findings related to issues. Please review the selected filters and dates.',
  request:
    'There are no Findings related to requests. Please review the selected filters and dates.',
  question:
    'There are no Findings related to questions. Please review the selected filters and dates.'
}

interface Props {
  intention: Intention
}

const EmptyReasons = ({ intention }: Props) => {
  return (
    <FlexContainer alignItems="center" direction="column" fullHeight justifyContent={'center'}>
      <IconContext.Provider value={{ size: 46, weight: 'fill', color: colors.neutralLowLight }}>
        {intentionIcons[intention]}
      </IconContext.Provider>
      <Text
        color="neutralLowLight"
        css={{ textAlign: 'center' }}
        fontSize="micro"
        fontWeight="regular"
        lineHeight="xxl"
      >
        {emptyTextMap[intention]}
      </Text>
    </FlexContainer>
  )
}

export default EmptyReasons
