import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { Intention } from '@/types/reasons'
import { intentionIcons, intentionTextColor } from '@/utils/intention'
import { IconContext } from '@phosphor-icons/react'
import { Content, DiscoveryContainer, List } from './Discovery.styles'
import EmptyReasons from './EmptyReasons'
import ListSkeleton from '../analytics/skeletons/ListSkeleton'
import { MergeFindingProps } from './Discovery.types'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const titleMap: Partial<Record<Intention, string>> = {
  compliment: 'Most relevant compliments',
  issue: 'Most relevant issues',
  request: 'Most relevant requests',
  question: 'Most relevant questions'
}

interface Props extends MergeFindingProps {
  data: string
  isLoading: boolean
  isRefetching?: boolean
  intention: Intention
  refetch?: () => void
}

const DiscoveryItemMarkdown = ({ data, intention, isLoading, isRefetching }: Props) => {
  const intentionColor = intentionTextColor[intention]

  return (
    <DiscoveryContainer key={intention}>
      <Content direction="column" gap="xs">
        {(isLoading || isRefetching) && (
          <FlexContainer
            alignItems="center"
            direction="column"
            fullHeight
            justifyContent="spaceAround"
          >
            <ListSkeleton count={1} gap={16} itemHeight={16} />
            <ListSkeleton count={5} gap={16} itemHeight={16} />
          </FlexContainer>
        )}
        {!isLoading && !isRefetching && !data.length && <EmptyReasons intention={intention} />}

        {!isLoading && !isRefetching && data.length > 0 && (
          <>
            <FlexContainer alignItems="center" gap="micro">
              <IconContext.Provider value={{ size: 20, weight: 'fill', color: intentionColor }}>
                {intentionIcons[intention]}
              </IconContext.Provider>
              <Text as="p" color="neutralLowPure" fontSize="xxs" fontWeight="bold">
                {titleMap[intention]}
              </Text>
            </FlexContainer>
            <List css={{ bAll: intentionColor }} direction="column" fullHeight isMarkdown>
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{data}</ReactMarkdown>
            </List>
          </>
        )}
      </Content>
    </DiscoveryContainer>
  )
}

export default DiscoveryItemMarkdown
