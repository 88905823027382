import { AccessRequests } from '@/types/access'
import RequestHandler from './RequestHandlers/NewRequest'

export default class AccessService {
  static PREFIX = '/access'

  static async logAccess(
    payload: AccessRequests.LogAccessPayload,
    options?: { signal?: AbortSignal }
  ) {
    return await RequestHandler.post(this.PREFIX, payload, options)
  }
}
