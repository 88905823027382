import ServerErrorIllustration from '@/assets/drawings/server_error.png'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'

function ServerError() {
  return (
    <FlexContainer alignItems="center" css={{ paddingTop: 72 }} direction="column" gap="xs">
      <FlexContainer alignItems="center" direction="column" gap="micro">
        <Text
          as="h2"
          color="brandPrimaryPure"
          css={{ fontSize: '$lg', lineHeight: 1.25, fontWeight: '$bold' }}
        >
          Sorry, unexpected error.
        </Text>
        <Text css={{ fontSize: '$xxs', lineHeight: '$lg', maxWidth: 495, textAlign: 'center' }}>
          We are already working to solve this problem. Be back soon.
        </Text>
      </FlexContainer>

      <img
        alt="Two birds hold pieces of a screen, while a third bird gives instructions on how to fix the screen."
        height={545}
        src={ServerErrorIllustration}
        width={576}
      />
    </FlexContainer>
  )
}

export default ServerError
