import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import RedirectPage from './redirect'

function AuthMiddleware({ children }: { children: React.ReactNode }) {
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  // biome-ignore lint/correctness/useExhaustiveDependencies: only on isAuthenticated change
  useEffect(() => {
    if (!isAuthenticated) {
      const from = window.location.pathname
      const searchParams = window.location.search
        ? window.location.search + `&from=${from}`
        : `?from=${from}`
      loginWithRedirect({
        redirectUri: window.location.origin + '/redirect' + searchParams
      })
    }
  }, [isAuthenticated])

  if (!isAuthenticated) {
    return <RedirectPage />
  }

  return <>{children}</>
}

export default AuthMiddleware
