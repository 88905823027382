import { FloatingButton } from '@/components/atoms/button/FloatingButton'
import FlexContainer from '@/components/atoms/flex-container'
import { savedViewBarHeight, styled, topBarHeight } from '@/theme'

const classificationContainerWidth = 400

export const ClassificationAreaContainer = styled('div', {
  maxWidth: classificationContainerWidth,
  minWidth: classificationContainerWidth,
  display: 'flex',
  flexDirection: 'column',
  transition: '.2s',
  bRight: '$neutralHighPure',
  opacity: 1,
  position: 'relative',
  pt: '$xs',

  '.collapse-icon': {
    transform: 'rotate(0)',
    transition: '.2s'
  },

  variants: {
    collapsed: {
      true: {
        maxWidth: 0,
        minWidth: 0,
        pl: '$sm',
        '.collapse-icon': {
          transform: 'rotate(180deg)'
        }
      }
    }
  }
})

export const ExploreFloatingButton = styled(FloatingButton, {
  mr: '-$xxs',
  mt: '-$xs',
  ml: 'auto',
  top: 32,
  right: '7px',
  zIndex: 2
})

export const SideExplorerInnerContent = styled(FlexContainer, {
  // height: '100%',
  maxHeight: `calc(100vh - ${savedViewBarHeight + topBarHeight}px)`,
  overflow: 'hidden'
})
