import FlexContainer from '@/components/atoms/flex-container'
import ExplorationSourceDistribution from '../../analytics/indicators/ExplorationSourceDistribution'
import { SourceDistributionItem } from '@/types/analytics/Sources'
import { styled } from '@/theme'
import { chartColors } from '@/theme/colors'
import useMetricsQuery from '@/hooks/metrics/useMetricsQuery'
import useFeedQueryParams from '@/hooks/feedback/new/useFeedQueryParams'
import useSourcesQuery from '@/hooks/useSourcesQuery'
import { useMemo } from 'react'
import { MetricItem, MetricKey, MetricsRequests } from '@/types/metrics'
import { useTranslation } from 'react-i18next'
import useOpportunityStore from '@/store/useOpportunityStore'
import useAllMetrics from '@/hooks/metrics/useAllMetricItems'

const DistributionContainer = styled(FlexContainer, {
  bc: '$neutralHighMedium',
  py: '$xs',
  px: '$sm',
  br: '$micro'
})

interface Props {
  currentSource?: SourceDistributionItem[]
  onClickSource: (value: SourceDistributionItem | undefined) => void
}

const FeedbackSourcesDistribution = ({ currentSource, onClickSource }: Props) => {
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)

  const { queryParams } = useFeedQueryParams()

  const { t } = useTranslation()

  const { values: sourceAliasValues, isLoading: isLoadingSources } = useSourcesQuery({
    enabled: false,
    sourceMode: 'sourceAlias'
  })

  const { allMetricItems, getMetricDisplayName, getMainMetricByMainType } = useAllMetrics()

  const allCountMetrics = useMemo(() => {
    const _map: Partial<Record<MetricKey, MetricItem>> = {}

    sourceAliasValues.forEach(sourceValue => {
      const metricItem = getMainMetricByMainType({
        source: sourceValue,
        mainMetricType: 'count'
      })

      if (!metricItem) return
      _map[sourceValue] = metricItem
    })

    return _map
  }, [sourceAliasValues, getMainMetricByMainType])

  const metricList = useMemo(() => {
    const countMetric = allMetricItems.count.metric
    const list: MetricsRequests.MetricsPayload['metric_list'] = [
      {
        ...countMetric,
        include_previous_value: false
      }
    ]

    sourceAliasValues.forEach(source => {
      const metricOption = source as MetricKey
      const metricItem = allCountMetrics[metricOption]
      if (metricItem) {
        const metric = metricItem.metric
        list.push({
          ...metric,
          include_previous_value: false
        })
      }
    })

    return list
  }, [sourceAliasValues, allCountMetrics, allMetricItems])

  const filters = useMemo(() => {
    return {
      ...queryParams,
      opportunity_id: currentOpportunity?.id
    }
  }, [queryParams, currentOpportunity])

  const { data: metricsData, isLoading } = useMetricsQuery({
    filters: filters ? [{ ...filters }] : [],
    enabled: true,
    sources: sourceAliasValues,
    metricList
  })

  const sourceResults = useMemo(() => {
    let total = 0
    const sourceDistribution: SourceDistributionItem[] = []

    if (!metricsData) return { total, sourceDistribution }
    const metricResult = metricsData[0]

    if (!metricResult) return { total, sourceDistribution }

    metricResult.forEach(metric => {
      if (metric.label === allMetricItems.count.metric.label) {
        total = metric.current_value
        return
      }

      let metricKey = ''

      sourceAliasValues.forEach(sourceValue => {
        const item = allCountMetrics[sourceValue]
        if (item && item.metric.label === metric.label) {
          metricKey = sourceValue as MetricKey
        }
      })

      if (metric.current_value > 0) {
        sourceDistribution.push({
          count: metric.current_value,
          key: metricKey,
          name: getMetricDisplayName(metricKey),
          percentage: total !== 0 ? (metric.current_value / total) * 100 : 0
        })
      }
    })

    const sortedSources = [...sourceDistribution].sort((a, b) => b.count - a.count)
    const sourcesWithColor = sortedSources.map((source, index) => ({
      ...source,
      color: chartColors[index] ?? undefined
    }))

    return { total, sourceDistribution: sourcesWithColor }
  }, [metricsData, allCountMetrics, sourceAliasValues, allMetricItems, getMetricDisplayName])

  if (isLoading || isLoadingSources) return <></>

  return (
    <DistributionContainer>
      <ExplorationSourceDistribution
        css={{ gap: '$xs' }}
        distributionContainerCss={{ minHeight: 24, gap: '$nano' }}
        highlightSource={currentSource}
        onClickSource={onClickSource}
        resetButtonCss={{ color: '$neutralLowPure' }}
        showIcon={false}
        showResetButton
        sources={sourceResults.sourceDistribution}
        titlePrefix={t('sourcesPrefix')}
        total={sourceResults.total}
      />
    </DistributionContainer>
  )
}

export default FeedbackSourcesDistribution
