import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import InitiativesFilters from '@/components/molecules/initiatives/InitiativesFilters'
import InitiativesTable from '@/components/molecules/initiatives/table/InitiativesTable'
import PageContentScroll from '@/components/molecules/layout/PageContentScroll'
import ToastMessagesProvider from '@/components/molecules/ToastMessagesProvider'
import { useInitiatives } from '@/hooks/initiatives/useInitiatives'
import useConfigureAllowedMetrics from '@/hooks/metrics/useConfigureAllowedMetrics'

const AllInitiativesPage = () => {
  useConfigureAllowedMetrics()

  const { initiatives } = useInitiatives()

  return (
    <PageContentScroll>
      <FlexContainer justifyContent="spaceBetween">
        <Text as="h1" fontWeight="bold" fontSize="xs">
          Initiatives
        </Text>
        {initiatives.length > 0 && <InitiativesFilters />}
      </FlexContainer>

      <InitiativesTable />
      <ToastMessagesProvider />
    </PageContentScroll>
  )
}

export default AllInitiativesPage
