import { useEffect, useState } from 'react'
import * as Collapsible from '@radix-ui/react-collapsible'
import {
  CaretRight,
  CheckCircle,
  Clock,
  IconContext,
  WarningCircle,
  XCircle
} from '@phosphor-icons/react'

import Bone from '@/components/atoms/bone'
import { CollapsibleContentAnimated } from '@/components/atoms/collapsible'
import FlexContainer from '@/components/atoms/flex-container'
import { Table, TableCell, TableContainer, TableHeader } from '@/components/atoms/table'
import Text from '@/components/atoms/text'
import Tooltip from '@/components/atoms/tooltip'
import IntegrationService from '@/services/IntegrationServices'
import { colors } from '@/theme'
import { ExtractionTask, IntegrationSource } from '@/types/integrations'
import { formatDateTime } from '@/utils/date'
import { CollapsibleButton } from '../IntegrationForm/IntegrationForm.styles'
import { useTranslation } from 'react-i18next'

interface IntegrationHistoryProps {
  integration: IntegrationSource | undefined
}

function IntegrationHistory(props: IntegrationHistoryProps) {
  const [history, setHistory] = useState<ExtractionTask[]>()
  const { t } = useTranslation()

  function getStatusIcon(status: ExtractionTask['status']) {
    switch (status) {
      case 'finished':
        return <CheckCircle aria-label="finished" color={colors.feedbackPositivePure} />
      case 'error':
        return <XCircle aria-label="error" color={colors.feedbackNegativePure} />
      case 'canceled':
        return <WarningCircle aria-label="canceled" color={colors.neutralLowMedium} />
      case 'running':
        return <Clock aria-label="running" color={colors.neutralLowMedium} />
      default:
        return null
    }
  }

  useEffect(() => {
    if (props.integration) {
      IntegrationService.listTasks(props.integration.id)
        .then(data => setHistory(data))
        .catch(() => setHistory([]))
    }
  }, [props.integration])

  return history ? (
    <Collapsible.Root>
      <FlexContainer css={{ gap: 24 }} direction="column">
        <Collapsible.Trigger asChild>
          <CollapsibleButton type="button">
            <CaretRight size={16} weight="bold" />
            <Text typeface="titleBoldXXS">{t('integrationHistory')}</Text>
          </CollapsibleButton>
        </Collapsible.Trigger>
        <CollapsibleContentAnimated asChild>
          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <TableHeader css={{ width: 280 }}>{t('feedbackRecords')}</TableHeader>
                  <TableHeader css={{ width: 280 }}>{t('integrationDate')}</TableHeader>
                  <TableHeader css={{ textAlign: 'center' }}>{t('updateStatus')}</TableHeader>
                </tr>
              </thead>
              <tbody>
                {history.map(entry => (
                  <tr key={entry.id}>
                    <TableCell role="cell">{entry.records ?? 0}</TableCell>
                    <TableCell role="cell">{formatDateTime(entry.created_at)}</TableCell>
                    <TableCell data-status={entry.status} role="cell">
                      <FlexContainer justifyContent="center">
                        <IconContext.Provider value={{ size: 24 }}>
                          {entry.status === 'canceled' ? (
                            <Tooltip text={t('integrationWasCanceledDuringUpdate')} variant="small">
                              {getStatusIcon(entry.status)}
                            </Tooltip>
                          ) : (
                            getStatusIcon(entry.status)
                          )}
                        </IconContext.Provider>
                      </FlexContainer>
                    </TableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        </CollapsibleContentAnimated>
      </FlexContainer>
    </Collapsible.Root>
  ) : (
    <Bone css={{ height: 20, width: 192 }} data-testid="integration-history-skeleton" />
  )
}

export default IntegrationHistory
