import * as Sentry from '@sentry/react'
import useUser from './useUser'
import useSegment from './useSegment'
import { APP_ENVIRONEMNT } from '@/config'
import { ExceptionParams, extractErrorStatusCode } from '@/utils/errors'
import { useCallback } from 'react'
import DefaultErrorHandler from '@/services/DefaultError'

interface Params {
  context?: string
}

const useLogging = ({ context }: Params) => {
  const { track } = useSegment()
  const { currentUser } = useUser()

  const logException = useCallback(
    (error: unknown, { message, priority, tags }: ExceptionParams = {}) => {
      if (APP_ENVIRONEMNT !== 'production') return

      const code = extractErrorStatusCode(error)

      const errorTags = {
        errorMessage: (error as DefaultErrorHandler).message ?? '',
        errorCode: (error as DefaultErrorHandler).code ?? '',
        errorStatusCode: (error as DefaultErrorHandler).statusCode?.toString() ?? '',
        errorJSON: JSON.stringify(error) ?? ''
      }

      Sentry.withScope(scope => {
        scope.setTransactionName(message)
        Sentry.captureException(error, {
          tags: {
            context,
            message,
            priority,
            code,
            ...tags,
            ...errorTags
          },
          user: {
            id: currentUser?.user_id,
            username: currentUser?.email,
            organizationId: currentUser?.organization_id
          }
        })
      })

      track('error', { context, message, ...tags, ...errorTags })
    },
    [track, context, currentUser]
  )

  return { logException }
}

export default useLogging
