import useUngroupedTopicsFilters from './hooks/useUngroupedTopicsFilters'
import FilterSubMenu from '../AddFilterMenu/FilterSubMenu'
import UngroupedTopicFilterContent from './UngroupedTopicFilterContent'
import { useTranslation } from 'react-i18next'

const UngroupedTopicFilterSubmenu = () => {
  const { hasChanges, apply } = useUngroupedTopicsFilters({
    searchQuery: '',
    enabled: false
  })
  const { t } = useTranslation()

  return (
    <FilterSubMenu
      css={{ py: '$nano' }}
      isDisabled={!hasChanges}
      onApply={apply}
      title={t('ungroupedTopics')}
    >
      <UngroupedTopicFilterContent />
    </FilterSubMenu>
  )
}

export default UngroupedTopicFilterSubmenu
