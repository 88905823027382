import { ParamsBase } from '@/types'
import {
  BaseCustomFieldMapping,
  CreateCustomFieldPayload,
  CreateFieldsConfigPayload,
  CustomField,
  CustomFieldMapping,
  CustomFieldRecordType,
  FieldConfig,
  GetCustomFieldMappingsParams,
  GetCustomFieldMappingsResponse,
  GetCustomFieldsTypesResponse,
  UpdateCustomFieldPayload
} from '@/types/integrations'
import { feedbackRiverClient } from './clients'
import BirdieRequest from './RequestHandlers/BirdieRequest'

export default class CustomFieldsService {
  static PREFIX = '/api'
  static PREFIX_V2 = '/ingestion/mappings'

  static async listCustomFields(organizationId: string, options?: ParamsBase) {
    const params = new URLSearchParams()
    if (options) {
      Object.keys(options).forEach(key => {
        if (options[key]) {
          params.append(key, `${options[key]}`)
        }
      })
    }

    params.append('organization_id', organizationId)

    const { data } = await feedbackRiverClient.get<CustomField[]>(
      `${this.PREFIX}/field/list?${params.toString()}`
    )

    return data
  }

  static async createCustomField(payload: CreateCustomFieldPayload) {
    const { data } = await feedbackRiverClient.post<CustomField>(`${this.PREFIX}/field/`, payload)

    return data
  }

  static async updateCustomField(payload: UpdateCustomFieldPayload) {
    const { data } = await feedbackRiverClient.put<CustomField>(`${this.PREFIX}/field/`, payload)

    return data
  }

  static async updateCustomFieldBatch(payload: UpdateCustomFieldPayload[]) {
    const { data } = await feedbackRiverClient.put<CustomField[]>(
      `${this.PREFIX}/field/batch`,
      payload
    )

    return data
  }

  static async deleteCustomField(fieldId: string) {
    const { data } = await feedbackRiverClient.delete<unknown>(`${this.PREFIX}/field/${fieldId}`)

    return data
  }

  /* Custom Field Config */
  static async createFieldsConfigBatch(payload: CreateFieldsConfigPayload[]) {
    const { data } = await feedbackRiverClient.post<FieldConfig[]>(
      `${this.PREFIX}/field_config/batch`,
      payload
    )

    return data
  }

  static async deleteFieldsConfigBatch(fieldIds: string[]) {
    const fieldsIdsString = fieldIds.join(',')

    const { data } = await feedbackRiverClient.delete<unknown>(
      `${this.PREFIX}/field_config/batch/${fieldsIdsString}`
    )

    return data
  }

  // V2 (NEW INGESTER)
  static async getCustomFieldsTypes() {
    return BirdieRequest.get<GetCustomFieldsTypesResponse[]>('/ingestion/mapping_types')
  }

  static async getCustomFieldsMappings(
    orgId: string,
    query: {
      perPage?: number
      cursor?: string
      recordType?: CustomFieldRecordType
      source?: string
    },
    options?: {
      signal?: AbortSignal
    }
  ) {
    const queryParams: GetCustomFieldMappingsParams = {
      record_type: query.recordType,
      source: query.source,
      per_page: query.perPage,
      cursor: query.cursor
    }

    return BirdieRequest.get<GetCustomFieldMappingsResponse>(
      `${this.PREFIX_V2}/${orgId}`,
      queryParams,
      {
        signal: options?.signal
      }
    )
  }

  static async getCustomFieldById(orgId: string, customFieldId: string) {
    return BirdieRequest.get<CustomFieldMapping>(`${this.PREFIX_V2}/${orgId}/${customFieldId}`)
  }

  static async postCustomFieldMapping(orgId: string, customField: BaseCustomFieldMapping) {
    return BirdieRequest.post<CustomFieldMapping>(`${this.PREFIX_V2}/${orgId}`, customField)
  }

  static async putCustomFieldMapping(
    orgId: string,
    customFieldId: string,
    customField: BaseCustomFieldMapping
  ) {
    return BirdieRequest.put<CustomFieldMapping>(
      `${this.PREFIX_V2}/${orgId}/${customFieldId}`,
      customField
    )
  }

  static async delCustomFieldMapping(orgId: string, customFieldId: string) {
    return BirdieRequest.del<void>(`${this.PREFIX_V2}/${orgId}/${customFieldId}`)
  }
}
