import { CSS } from '@/theme'
import { HTMLAttributes, ReactNode } from 'react'
import { Card } from './SelectableCard.styles'

export interface SelectableCardProps extends HTMLAttributes<HTMLDivElement> {
  selected?: boolean
  children?: ReactNode
  css?: CSS
}

const SelectableCard = ({ selected, children, css, ...props }: SelectableCardProps) => {
  return (
    <Card css={css} selected={selected} {...props}>
      {children}
    </Card>
  )
}

export default SelectableCard
