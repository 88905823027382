import Text from '@/components/atoms/text'
import { colors } from '@/theme'
import { KeywordTopic } from '@/types/keywords'
import { ThemeItemData, TopicItemData } from '@/types/classification/Theme'
import { CaretLeft, Copy } from '@phosphor-icons/react'
import {
  memo,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import * as S from './KeywordEditor.styles'
import SearchResultSection from './SearchResultSection'
import ThemesSection from './ThemesSection'
import TopicSearch from './TopicSearch'
import TopicsSection from './TopicsSection'
import useClassification, { CLASSIFICATION_LABELS } from '@/hooks/useClassification'
import { TopicCategory } from '@/types/classification'
import CategoriesSection from './CategoriesSection'
import useSegment from '@/hooks/useSegment'
import KeywordLimit from '../keyword-creator/KeywordLimit'
import useUser from '@/hooks/useUser'
import Button from '@/components/atoms/button'

/**
 * Called with the ids of topics to add
 *
 * If is single keyword, the already applied topics are excluded by default
 *
 * Second parameter is an array with the ids of topics removed by the user, if single keyword
 * multi keyword is empty array
 *
 */
type OnApplyStatus = 'error' | 'expansion_limit' | 'success'
export type OnApplyCallback = (
  selectedTopics: KeywordTopic[],
  removedTopics: string[]
) => Promise<OnApplyStatus>

export interface KeywordEditorProps {
  /** Topics already applied for the keyword, single keyword only */
  appliedTopics: KeywordTopic[]
  renderApplyButton: (onApply: () => void, isDisabled: boolean) => ReactNode
  renderCancelButton: (onCancel: () => void) => ReactNode
  /** If true the user can add AND remove topics from a keyword, if false can only add topics */
  singleKeyword?: boolean
  /** Called when user clicks on Apply with the selected topics and the removed */
  onApply: OnApplyCallback
  onCancel?: () => void
  onClipboardClick?: MouseEventHandler<HTMLButtonElement>
  newKeyword?: boolean
  isOverflowing?: boolean
}

const KeywordEditor = ({
  appliedTopics,
  singleKeyword,
  onApply,
  onCancel,
  newKeyword = false,
  onClipboardClick,
  renderApplyButton,
  renderCancelButton,
  isOverflowing
}: KeywordEditorProps) => {
  const { track } = useSegment()

  const { userPermissions } = useUser()
  const isManager = userPermissions.topic.includes('manager')
  const showNewExperience = userPermissions['new-topic'].length > 0

  const [selectedTopics, setSelectedTopics] = useState<KeywordTopic[]>(
    singleKeyword ? appliedTopics : []
  )
  const [removedTopics, setRemovedTopics] = useState<KeywordTopic[]>([])

  const [searchQuery, setSearch] = useState('')
  const searchRef = useRef('')

  const [currentList, setCurrentList] = useState<
    'categories' | 'themes' | 'topics' | 'searching' | 'merged'
  >('categories')
  const [currentCategory, setCurrentCategory] = useState<TopicCategory>('PRODUCT_AREA')
  const [currentTheme, setTheme] = useState<ThemeItemData | undefined>()
  const [mergedTopic, setMergedTopic] = useState<Partial<TopicItemData> | undefined>()

  const categories: { category: TopicCategory; name: string }[] = [
    { category: 'PRODUCT_AREA', name: 'Product areas' },
    { category: 'OTHER', name: 'Other topics' }
  ]
  const currentCategoryName = currentCategory === 'OTHER' ? 'Other topics' : 'Product areas'

  const {
    productAreaThemes,
    otherThemes,
    themes: allThemes,
    classificationLabelsMode
  } = useClassification()

  const themes = useMemo(
    () => (currentCategory === 'OTHER' ? otherThemes : productAreaThemes),
    [currentCategory, productAreaThemes, otherThemes]
  )

  const topics: TopicItemData[] = useMemo(() => {
    const _topics = allThemes.find(theme => theme.themeId === currentTheme?.themeId)?.topics ?? []
    if (!showNewExperience) return _topics.filter(topic => !topic.isMerged)

    return _topics
  }, [currentTheme, allThemes, showNewExperience])

  useEffect(() => {
    if (singleKeyword) {
      setSelectedTopics(appliedTopics)
    } else {
      setSelectedTopics([])
    }
  }, [appliedTopics, singleKeyword])

  const topicsToAdd = useMemo(
    () =>
      singleKeyword
        ? selectedTopics.filter(
            topic => !appliedTopics.find(appliedTopic => appliedTopic.topicId === topic.topicId)
          )
        : selectedTopics,
    [selectedTopics, appliedTopics, singleKeyword]
  )

  const [showLimitError, setShowLimitError] = useState(false)
  const [isApplyLoading, setIsApplyLoading] = useState(false)

  const handleOnApply = useCallback(async () => {
    track('add_topic_to_folder-apply')
    setIsApplyLoading(true)
    const status = await onApply(
      topicsToAdd,
      removedTopics.map(topic => topic.topicId)
    )
    if (status === 'expansion_limit') {
      setShowLimitError(true)
    } else {
      setIsApplyLoading(false)
    }
  }, [topicsToAdd, removedTopics, onApply, track])

  const isApplyDisabled =
    (topicsToAdd.length === 0 && removedTopics.length === 0) ||
    (newKeyword && selectedTopics.length === 0) ||
    isApplyLoading

  const onSearch = (value: string) => {
    if (searchQuery.length === 0 && value.length > 0) {
      track('add_topic_to_folder-search')
    }
    setSearch(value)
    searchRef.current = value
    setCurrentList(value.length ? 'searching' : 'categories')
  }

  const onClickCategory = (category: TopicCategory) => (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setCurrentList('themes')
    setCurrentCategory(category)
    setSearch('')
  }

  const onClickTheme = (theme: ThemeItemData) => (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setCurrentList('topics')
    setTheme(theme)
    setSearch('')
  }

  const onClickMergedTopic =
    (topic: Partial<TopicItemData>) => (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      setCurrentList('merged')
      setMergedTopic(topic)
      setSearch('')
    }

  const returnToList = () => {
    if (currentList === 'themes') {
      setCurrentList('categories')
      return
    }

    if (currentList === 'topics') {
      setCurrentList('themes')
      setTheme(undefined)
    }

    if (currentList === 'merged') {
      setMergedTopic(undefined)
      if (currentTheme) {
        setCurrentList('topics')
      } else if (searchRef.current.length) {
        setSearch(searchRef.current)
        setCurrentList('searching')
        searchRef.current = ''
      } else {
        setCurrentList('categories')
      }
    }
  }

  const onOpenChange = (open: boolean) => {
    if (!open) {
      setCurrentList('themes')
      setTheme(undefined)
      setSelectedTopics(singleKeyword ? appliedTopics : [])
      setRemovedTopics([])
      onCancel?.()
    }
  }

  const handleOnCancel = () => {
    onOpenChange(false)
    setShowLimitError(false)
    setIsApplyLoading(false)
    onCancel?.()
  }

  const onCheckTopic = (topic: KeywordTopic) => (checked: boolean) => {
    setSelectedTopics(prev =>
      checked ? [...prev, topic] : prev.filter(prevTopic => prevTopic.topicId !== topic.topicId)
    )

    if (singleKeyword) {
      const removed = appliedTopics.find(appliedTopic => appliedTopic.topicId === topic.topicId)
      if (!checked) {
        if (removed) {
          setRemovedTopics(prevRemoved => [...prevRemoved, removed])
        }
      } else {
        setRemovedTopics(prevRemoved =>
          prevRemoved.filter(removedTopic => removedTopic.topicId !== topic.topicId)
        )
      }
    }
  }

  const isChecked = (topicId: string) =>
    !!selectedTopics.find(selectedTopic => selectedTopic.topicId === topicId)

  const labels: Record<CLASSIFICATION_LABELS, { title: string; heading: string }> = {
    'topic-group': {
      title: 'Add to topic',
      heading: 'Add keyword to:'
    },
    'default-topic': {
      title: 'Add to subtopic',
      heading: 'Add topic to:'
    }
  }

  const currentHeading = (() => {
    if (currentList === 'categories') return labels[classificationLabelsMode].heading
    if (currentList === 'searching') return 'Results: '

    let name = ''
    name = currentTheme ? currentTheme.themeName : currentCategoryName

    if (currentList === 'merged' && !!mergedTopic && mergedTopic.topicName)
      name = mergedTopic.topicName

    return (
      <>
        <CaretLeft color={colors.grey} size={14} weight="bold" />
        <Text color="grey" title={name} truncate typeface="textRegularSmall">
          {name}
        </Text>
      </>
    )
  })()

  return (
    <S.Container>
      {isManager ? (
        <>
          <S.Header>
            <span>{labels[classificationLabelsMode].title}</span>
            {newKeyword && (
              <Button
                css={{ size: 24, background: '$neutralHighLight' }}
                onClick={onClipboardClick}
                size="square"
                variant="flat-bordered"
              >
                <Copy size={14} />
              </Button>
            )}
          </S.Header>

          {showLimitError ? (
            <KeywordLimit />
          ) : (
            <>
              <TopicSearch onSearch={onSearch} searchQuery={searchQuery} />

              <S.SectionTitleHeading>
                <Text
                  as="button"
                  color="grey"
                  css={{
                    cursor: currentList !== 'categories' ? 'pointer' : undefined
                  }}
                  onClick={returnToList}
                  typeface="textRegularSmall"
                >
                  {currentHeading}
                </Text>
              </S.SectionTitleHeading>

              {currentList === 'categories' && (
                <CategoriesSection
                  categories={categories}
                  isOverflowing={isOverflowing}
                  onClickCategory={onClickCategory}
                />
              )}

              {currentList === 'themes' && (
                <ThemesSection
                  isOverflowing={isOverflowing}
                  onClickTheme={onClickTheme}
                  themes={themes}
                  topicCategory={currentCategory}
                />
              )}

              {currentList === 'topics' && !!currentTheme && (
                <TopicsSection
                  isChecked={isChecked}
                  isOverflowing={isOverflowing}
                  onCheckTopic={onCheckTopic}
                  onClickMergedTopic={onClickMergedTopic}
                  themeId={currentTheme.themeId ?? ''}
                  topicCategory={currentCategory}
                  topics={topics}
                />
              )}

              {currentList === 'merged' && !!mergedTopic && (
                <TopicsSection
                  hideAddTopic
                  isChecked={isChecked}
                  isOverflowing={isOverflowing}
                  onCheckTopic={onCheckTopic}
                  onClickMergedTopic={onClickMergedTopic}
                  themeId={currentTheme?.themeId ?? ''}
                  topicCategory={currentCategory}
                  topics={(mergedTopic.mergedTopicList || []).map(topic => ({
                    topicId: topic.id,
                    topicName: topic.name,
                    frequency: 0,
                    sentimentMetrics: {
                      positiveCount: 0,
                      negativeCount: 0,
                      neutralCount: 0,
                      positivePercentage: 0,
                      negativePercentage: 0,
                      neutralPercentage: 0,
                      netSentiment: 0
                    }
                  }))}
                />
              )}

              {currentList === 'searching' && (
                <SearchResultSection
                  isChecked={isChecked}
                  onCheckTopic={onCheckTopic}
                  onClickMergedTopic={onClickMergedTopic}
                  onClickTheme={onClickTheme}
                  searchQuery={searchQuery}
                  themes={allThemes}
                />
              )}
            </>
          )}
        </>
      ) : null}
      {isManager ? (
        <S.Footer>
          {renderApplyButton(handleOnApply, isApplyDisabled)}
          {renderCancelButton(handleOnCancel)}
        </S.Footer>
      ) : null}
    </S.Container>
  )
}

export default memo(KeywordEditor)
