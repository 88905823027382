import { useFeedbacks } from '@/hooks/feedback/useFeedbacks'
import { useKeywordsStore } from '@/store'
import useTopicsStore from '@/store/useTopicsStore'

import { styled, typefaceVariants } from '@/theme'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { shallow } from 'zustand/shallow'
import { useTranslation } from 'react-i18next'

const ResultsCount = styled('p', {
  ...typefaceVariants.paragraphRegularMicro,
  color: '$neutralLowLight',

  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  height: 20
})

function ResultsCounter() {
  const clusters = useKeywordsStore(state => state.data, shallow)
  const totalTopics = useTopicsStore(state => state.total)
  const { total } = useFeedbacks()

  const { pathname } = useLocation()
  const tab = pathname.replace('/explore/', '')
  const { t } = useTranslation()

  const keywordsCounters = useMemo(() => {
    const totalKeywords = clusters.flatMap(cluster => cluster.keywordList).length
    const totalClusters = clusters.length

    return { totalClusters, totalKeywords }
  }, [clusters])

  if (tab.includes('topic-management')) {
    return <ResultsCount>{t('showingTopics', { totalTopics })}</ResultsCount>
  }

  if (tab.includes('topics')) {
    return (
      <ResultsCount>
        {t('showingClusters', {
          totalClusters: keywordsCounters.totalClusters,
          totalKeywords: keywordsCounters.totalKeywords
        })}
      </ResultsCount>
    )
  }

  if (tab.includes('feedback')) {
    return <ResultsCount>{t('showingFeedback', { total })}</ResultsCount>
  }

  return <ResultsCount />
}

export default ResultsCounter
