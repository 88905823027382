import { styled } from '@/theme'

export const FloatingButton = styled('div', {
  size: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$xs',
  bAll: '$stroke',
  position: 'absolute',
  cursor: 'pointer',

  variants: {
    colorScheme: {
      tinted: {
        color: '$neutralHighLight',
        background: '$brandPrimaryPure',

        '&:hover': {
          background: '$brandPrimaryMedium'
        }
      },
      default: {
        color: '$neutralLowLight',
        background: '$neutralHighLight',

        '&:hover': {
          background: '$neutralHighMedium'
        }
      }
    }
  },

  defaultVariants: {
    colorScheme: 'default'
  }
})
