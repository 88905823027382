import { UserPermissions } from '@/types/auth'

enum OfferCode {
  Core = 'CORE-BIRDIE-99358',
  Brain = 'BRAIN-BIRDIE-99358',
  Experimental = 'BETA-BIRDIE-99358',
  Opportunities = 'OPPS-BIRDIE-99358'
}

export type OfferKey = 'core' | 'brain' | 'experimental' | 'opportunities'

export const offers: Record<OfferKey, string> = {
  core: '9a112694-25b1-11ee-be56-0242ac120002',
  brain: '8fb6d888-25b1-11ee-be56-0242ac120002',
  experimental: '404ca9f2-20b2-11ee-be56-0242ac120002',
  opportunities: 'f6b244bd-3d42-411f-b161-50e999d3c405'
}

export const offersCode: Record<OfferKey, string> = {
  core: OfferCode.Core,
  brain: OfferCode.Brain,
  experimental: OfferCode.Experimental,
  opportunities: OfferCode.Opportunities
}

export const testUser: UserPermissions = {
  discovery: ['all'],
  topic: ['view', 'manager', 'findings'],
  analytics: [
    'basic',
    'other-topics',
    'over-time_f-intention',
    'over-time_f-type',
    'over-time_subtopic',
    'over-time_topic',
    'product-area'
  ],
  feedback: ['view', 'manager', 'add-to', 'chat'],
  source: ['view', 'manager'],
  explore: ['filters_other-topics', 'filters_product-areas'],
  user: ['view', 'manager'],
  filters: ['view', 'manager'],
  tableau: ['view'],
  tracking: ['view', 'manager'],
  'new-topic': ['view'],
  drilldown: ['view'],
  root_causes: ['view'],
  summary: ['view'],
  areas: ['manager'],
  notifications: ['manager'],
  opportunities: ['manager'],
  raw_message: ['view']
}
