import RangeCalendar from '@/components/atoms/range-calendar'
import FilterSubMenu from '../AddFilterMenu/FilterSubMenu'
import useDatetimeGenericFilter from './useDatetimeGenericFilter'
import { useState } from 'react'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { isAreaOfInterestFilter } from '@/utils/areaOfInterest'

interface Props {
  title: string
  previousTitle: string
  name: string
  filterKey: string
}

const DatetimeFilterSubMenuNew = ({ title, previousTitle, name, filterKey }: Props) => {
  const [open, setOpen] = useState(false)

  const { selectedRange, maxDate, minDate, isLoading, setDateRange, hasChanges, onApply } =
    useDatetimeGenericFilter({
      enabled: open,
      filterKey,
      name
    })

  const areaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)

  if (
    isAreaOfInterestFilter({
      key: filterKey,
      values: [selectedRange?.start.toString() || '', selectedRange?.end.toString() || ''],
      areaOfInterest
    })
  )
    return <></>

  return (
    <FilterSubMenu
      contentCss={{ width: 'auto' }}
      isDisabled={!hasChanges}
      isLoading={isLoading}
      onApply={onApply}
      onOpenChange={setOpen}
      open={open}
      previousTitle={previousTitle}
      title={title}
    >
      <RangeCalendar
        maxValue={maxDate}
        minValue={minDate}
        onChange={setDateRange}
        value={selectedRange}
      />
    </FilterSubMenu>
  )
}

export default DatetimeFilterSubMenuNew
