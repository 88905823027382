import { useUIStore } from '@/store'
import { styled } from '@/theme'
import { shallow } from 'zustand/shallow'
import FlexContainer from '../flex-container'
import Text from '../text'
import Loader from './Loader'
import { useTranslation } from 'react-i18next'

const LoaderContainer = styled(FlexContainer, {
  position: 'fixed',
  height: '100vh',
  width: '100vw',
  background: '$neutralHighMedium',
  opacity: 0.85,
  zIndex: 999999,
  top: 0,
  left: 0
})

const FullScreenLoader = () => {
  const show = useUIStore(state => state.showFullScreenLoader, shallow)
  const fullScreenMessage = useUIStore(state => state.fullScreenMessage, shallow)
  const { t } = useTranslation()
  if (!show) return <></>

  return (
    <LoaderContainer
      alignItems="center"
      direction="column"
      gap="xxxs"
      id="screen-load"
      justifyContent="center"
    >
      <Loader />
      <Text fontSize="xs" fontWeight="semibold">
        {fullScreenMessage || t('loading')}
      </Text>
    </LoaderContainer>
  )
}

export default FullScreenLoader
