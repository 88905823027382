import {
  styled,
  typefaceVariants,
  CSS,
  colorVariant,
  fontSizeVariant,
  fontWeightsVariant,
  lineHeightVariant,
  textAlignVariant
} from '@/theme'
import { VariantProps } from '@stitches/react'

const StyledText = styled('span', {
  variants: {
    typeface: typefaceVariants,
    color: colorVariant,
    fontSize: fontSizeVariant,
    fontWeight: fontWeightsVariant,
    lineHeight: lineHeightVariant,
    align: textAlignVariant,

    truncate: {
      true: {},
      false: {}
    },

    maxLines: {
      1: {},
      2: {}
    },

    textTransform: {
      capitalize: {
        textTransform: 'capitalize'
      },
      uppercase: {
        textTransform: 'uppercase'
      },
      lowercase: {
        textTransform: 'lowercase'
      }
    }
  },

  compoundVariants: [
    {
      truncate: true,
      maxLines: 1,
      css: {
        display: 'block',
        whiteSpace: 'nowrap',
        wordWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    {
      truncate: true,
      maxLines: 2,
      css: {
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
        lineClamp: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  ],

  defaultVariants: {
    typeface: 'textBodyRegularRegular',
    maxLines: 1
  }
})

type TextVariants = VariantProps<typeof StyledText>

type TextProps<C extends React.ElementType> = {
  as?: C
  css?: CSS
  maxWidth?: number | string
} & TextVariants

export type Props<C extends React.ElementType = 'span'> = React.PropsWithChildren<TextProps<C>> &
  React.ComponentPropsWithoutRef<C>

const Text = <C extends React.ElementType = 'span'>({
  as,
  children,
  color,
  typeface,
  css,
  maxWidth,
  ...rest
}: Props<C>) => {
  return (
    <StyledText as={as} color={color} css={{ maxWidth, ...css }} typeface={typeface} {...rest}>
      {children}
    </StyledText>
  )
}

export default Text
