import useUser from '@/hooks/useUser'
import { queryClient } from '@/plugins/reactQueryClient'
import CustomFieldsService from '@/services/CustomFieldsServices'
import { GetCustomFieldMappingsResponse } from '@/types/integrations'
import { useMutation, InfiniteData } from '@tanstack/react-query'
import useCustomFieldsV2Query from './useCustomFieldsV2Query'
import useToastMessageStore from '@/store/useToastMessageStore'
import useLogging from '@/hooks/useLogging'
import { useTranslation } from 'react-i18next'

interface Options {
  onSuccess?: () => void
  onError?: () => void
}

const useDeleteCustomFieldMutation = ({ onSuccess, onError }: Options) => {
  const { currentUser } = useUser()
  const { logException } = useLogging({ context: 'delete-custom-field' })
  const { t } = useTranslation()
  const { queryKey: getCustomFieldsKey } = useCustomFieldsV2Query({ enabled: false })

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const mutation = useMutation({
    mutationKey: ['delete-custom-field-v2'],
    mutationFn: async (customFieldId: string) => {
      if (!customFieldId.length) throw Error('Invalid id to delete.')

      const [error] = await CustomFieldsService.delCustomFieldMapping(
        currentUser?.organization_id || '',
        customFieldId
      )

      if (error) throw error

      return customFieldId
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: getCustomFieldsKey })

      const prevCustomFields =
        queryClient.getQueryData<InfiniteData<GetCustomFieldMappingsResponse>>(getCustomFieldsKey)

      return { prevCustomFields }
    },
    onSuccess: data => {
      queryClient.setQueryData<InfiniteData<GetCustomFieldMappingsResponse>>(
        getCustomFieldsKey,
        old => {
          if (!old) return

          return {
            ...old,
            pages: old.pages.map(page => {
              if (page.mappings) {
                return {
                  ...page,
                  mappings: page.mappings.filter(field => field.id !== data)
                }
              }
              return page
            })
          }
        }
      )

      onSuccess?.()
      addSuccessToast({ text: t('customFieldDeletedSuccessfully') })
    },
    onError: (error, _, context) => {
      // const errorMessage = (error as DefaultErrorHandler).key
      queryClient.setQueryData(getCustomFieldsKey, context?.prevCustomFields)
      const message = t('failedToDeleteThisCustomField')
      logException(error, { message })

      onError?.()
      addErrorToast({
        text: message
      })
    }
  })

  return mutation
}

export default useDeleteCustomFieldMutation
