import { useUIStore } from '@/store'
import { useEffect, useState } from 'react'
import ToastMessage from '@/components/molecules/ToastMessagesProvider'
import FlexContainer from '@/components/atoms/flex-container'
// import { useQuery } from '@tanstack/react-query'
import TableauService from '@/services/TableauService'
import DashboardTabsSkeleton from '@/components/molecules/dashboards/DashbaordsTabsSkeleton'
import { TableauItem } from '@/types/tableau/Tableau'
import { NavigationButton } from '@/components/atoms/button'
import useUser from '@/hooks/useUser'
import NoPermission from '@/components/molecules/no-permission/NoPermission'
import TableauVizByUrl from '@/components/atoms/tableau/TableauVizByUrl'
import ChartList from '@/components/molecules/dashboard/ChartList'
import ErrorBoundary from '@/components/atoms/error-boundary'

function DashboardsAndTrackingPage() {
  const { setPageTitle, setShowFullScreenLoader } = useUIStore()

  const { userPermissions, currentUser, currentUserPlan } = useUser()
  const canViewTableau = userPermissions.tableau.length > 0
  const canViewTracking = userPermissions.tracking.length > 0 && currentUserPlan === 'topic'

  const [selectedGraphic, setSelectedGraphic] = useState<TableauItem | null>(null)
  // const queryFunction = () => TableauService.graphics()

  // const { data, isLoading } = useQuery({
  //   queryKey: ['dashboards'],
  //   queryFn: queryFunction
  // })
  const [graphics, setGraphics] = useState<TableauItem[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const loadGraphics = async () => {
    const result = await TableauService.graphics()
    setGraphics(result)
    setShowFullScreenLoader(false)
    setIsLoading(false)
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen once
  useEffect(() => {
    setPageTitle('Dashboards')
    loadGraphics()
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: should only react to graphics
  useEffect(() => {
    if (!canViewTracking && selectedGraphic === null && graphics && graphics.length > 0) {
      setSelectedGraphic(graphics[0])
    }
  }, [graphics])

  if (!canViewTableau && !canViewTracking && currentUser) {
    return <NoPermission />
  }

  return (
    <>
      <FlexContainer css={{ gap: '$nano', height: '100%', ml: '$navigationBarWidth' }}>
        <FlexContainer as="aside" css={{ gap: 8, padding: '40px 0 32px 40px' }} direction="column">
          {canViewTracking && (
            <NavigationButton
              active={selectedGraphic === null}
              onClick={() => setSelectedGraphic(null)}
            >
              Tracking
            </NavigationButton>
          )}
          {canViewTableau &&
            (isLoading ? (
              <DashboardTabsSkeleton />
            ) : (
              graphics.map(item => (
                <NavigationButton
                  active={selectedGraphic?.tableauId === item.tableauId}
                  key={item.tableauId}
                  onClick={() => setSelectedGraphic(item)}
                >
                  {item.name}
                </NavigationButton>
              ))
            ))}
        </FlexContainer>
        <FlexContainer
          as="main"
          css={{ overflowY: 'scroll', padding: '0 $xxxs', width: '100%' }}
          direction="column"
        >
          <ErrorBoundary>
            {selectedGraphic === null ? (
              <ChartList />
            ) : (
              <TableauVizByUrl
                css={{ padding: '$xs', boxSizing: 'border-box' }}
                height={selectedGraphic.height}
                hideMenu
                url={selectedGraphic.path}
              />
            )}
          </ErrorBoundary>
        </FlexContainer>
      </FlexContainer>

      <ToastMessage />
    </>
  )
}

export default DashboardsAndTrackingPage
