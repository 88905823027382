import { Camera } from '@phosphor-icons/react'
import { integerFormatter, percentageFormatter, percentageScoreTransformer } from './formatters'
import { MetricItem, SocialMediaMetricKey } from '@/types/metrics'
import i18n from '../../plugins/i18n/i18n'

export const socialMediaTimeSeries: Record<SocialMediaMetricKey, MetricItem> = {
  social_media_post: {
    label: i18n.t('count'),
    selectedLabel: i18n.t('socialMediaCount'),
    icon: Camera,
    mainMetricType: 'count',
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: `social_media_post_count`,
      filter: { source_alias: 'social_media_post' }
    }
  },
  social_media_post_share: {
    label: i18n.t('percentCount'),
    selectedLabel: i18n.t('socialMediaPercentCount'),
    icon: Camera,
    mainMetricType: 'share',
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: `social_media_post_share`,
      filter: { source_alias: 'social_media_post' }
    }
  }
}

export const SOCIAL_MEDIA_METRICS_KEYS = Object.keys(
  socialMediaTimeSeries
) as SocialMediaMetricKey[]

export const SOCIAL_MEDIA_DEFAULT_METRICS = ['social_media_post']
