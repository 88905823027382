import { IconContext } from '@phosphor-icons/react'
import { ReactNode } from 'react'
import Tooltip from '../tooltip'

export interface NavigationItemProps {
  title: string
  children: ReactNode
  disabled?: boolean
  home?: boolean
  opportunityPlan?: boolean
}

function NavigationItem({ title, children }: NavigationItemProps) {
  return (
    <IconContext.Provider value={{ color: 'currentColor', size: 32 }}>
      <Tooltip offset={28} side="right" text={title}>
        {children}
      </Tooltip>
    </IconContext.Provider>
  )
}

export default NavigationItem
