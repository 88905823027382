import { CSS, styled } from '@/theme'
import Checkbox from './Checkbox'

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',

  backgroundColor: '$white',
  gap: '$micro',

  variants: {
    variant: {
      border: {
        padding: '8px 16px',
        borderRadius: '8px',
        border: 'solid 1px $stroke'
      },
      clear: {
        padding: '0 8px'
      }
    }
  },

  defaultVariants: {
    variant: 'clear'
  }
})

export interface CheckboxGroupOption {
  text: string
  value: string
  disabled?: boolean
}

export interface CheckboxGroupProps {
  options: CheckboxGroupOption[]
  values: string[]
  onChange?: (value: string[]) => void
  css?: CSS
}

export const CheckboxGroup = ({ options, values, onChange, css }: CheckboxGroupProps) => {
  const handleChange = (value: string, checked: boolean | 'indeterminate') => {
    if (onChange) {
      onChange(checked ? [...values, value] : values.filter(prevValue => prevValue !== value))
    }
  }

  return (
    <Container css={css}>
      {options.map(option => (
        <Checkbox
          key={option.value}
          {...option}
          checked={values.includes(option.value)}
          onCheckedChange={checked => handleChange(option.value, checked)}
        />
      ))}
    </Container>
  )
}

export default CheckboxGroup
