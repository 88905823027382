import { ChipContainer } from './TopicChip.styles'
import { CSS } from '@/theme'
import { Check, X } from '@phosphor-icons/react'

export interface TopicChipProps {
  label: string
  color?: string
  dismissable?: boolean
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>

  css?: CSS
  onClick?: React.MouseEventHandler<HTMLSpanElement>
  ghost?: boolean
  disabled?: boolean
}

const TopicChip = ({
  label,
  color,
  css,
  dismissable = true,
  ghost,
  disabled,
  onClick,
  onMouseEnter,
  onMouseLeave
}: TopicChipProps) => {
  return (
    <ChipContainer
      css={{
        backgroundColor: color,
        ...css
      }}
      disabled={disabled}
      ghost={ghost}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span className="label" title={label}>
        {label}
      </span>

      {dismissable && (
        <>
          <span className="icon check">
            <Check size={12} weight="bold" />
          </span>

          <span className="icon delete" onClick={onClick}>
            <X size={12} weight="bold" />
          </span>
        </>
      )}
    </ChipContainer>
  )
}

export default TopicChip
