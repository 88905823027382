import { CSS } from '@/theme'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { ReactNode } from 'react'
import FlexContainer, { FlexVariants } from '../flex-container'
import { TextProps } from '../text'
import RadioItem from './RadioItem'

export interface RadioGroupOption {
  value: string
  text: ReactNode
}

export interface RadioGroupProps extends RadioGroupPrimitive.RadioGroupProps, FlexVariants {
  options: RadioGroupOption[]
  css?: CSS
  itemGap?: FlexVariants['gap']
  itemDirection?: FlexVariants['direction']
  textProps?: TextProps
}

const RadioGroup = ({
  options,
  direction = 'column',
  justifyContent,
  alignItems,
  gap,
  itemGap,
  itemDirection,
  css,
  textProps,
  ...props
}: RadioGroupProps) => {
  return (
    <RadioGroupPrimitive.Root {...props}>
      <FlexContainer css={{ css }} direction={direction} gap={gap}>
        {options.map((option, index) => {
          const id = 'r' + index

          return (
            <RadioItem
              alignItems={alignItems}
              direction={itemDirection}
              gap={itemGap}
              justifyContent={justifyContent}
              key={option.value + id}
              text={option.text}
              textProps={textProps}
              value={option.value}
            />
          )
        })}
      </FlexContainer>
    </RadioGroupPrimitive.Root>
  )
}

export default RadioGroup
