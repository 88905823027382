import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useTranslation } from 'react-i18next'
import { Sentiment } from '@/utils/sentiments'
import useSentimentFilter from '@/hooks/advancedFilters/useSentimentFilter'
import { FilterItemButton } from '../../AppliedFilters/FilterItem'
import { DropdownMenuContent } from '@/components/atoms/options-menu'
import Text from '@/components/atoms/text'
import SentimetnFilterSelection from './SentimentFilterSelection'

interface Props {
  value: Sentiment
  index: number
  disabled?: boolean
}

const SentimentFilterApplied = ({ value, index, disabled = false }: Props) => {
  const { t } = useTranslation()

  const { update, selectedOption, setSelectedOption } = useSentimentFilter({
    initialValue: value,
    index
  })

  const label = value ? t(value) : ' '

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild disabled={disabled}>
        <FilterItemButton content={label} contentType="label" disabled={disabled} tooltip={label} />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenuContent align="start" css={{ width: 320 }} sideOffset={4}>
          <Text typeface="subtitleSemiBoldMicro" css={{ padding: '$micro' }}>
            {t('sentiment')}
          </Text>
          <SentimetnFilterSelection
            onApply={update}
            isDisabled={disabled}
            value={selectedOption}
            onValueChange={setSelectedOption}
          />
        </DropdownMenuContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default SentimentFilterApplied
