import { styled } from '@/theme'
import { useButton } from '@react-aria/button'
import { useRef } from 'react'

import type { AriaButtonProps } from '@react-aria/button'

const StyledButton = styled('button', {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$pajarito',
  size: 24,
  cursor: 'pointer',

  borderRadius: '100%',
  bAll: 'transparent',

  alignSelf: 'center',

  '&:disabled': {
    color: '$neutralLowLight',
    pointerEvents: 'none'
  },

  '&:hover': {
    background: '$greenLight',
    bAll: '$stroke'
  }
})

const CalendarButton = (props: AriaButtonProps) => {
  const ref = useRef(null)
  const { buttonProps } = useButton(props, ref)

  return (
    <StyledButton ref={ref} {...buttonProps}>
      {props.children}
    </StyledButton>
  )
}

export default CalendarButton
