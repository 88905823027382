import FlexContainer from '@/components/atoms/flex-container'
import Select, { SelectOption } from '@/components/atoms/select'
import Text from '@/components/atoms/text'
import NoPermission from '@/components/molecules/no-permission/NoPermission'
import { NotificationsContainer } from '@/components/molecules/notifications/Notifications.styles'
import NotificationsSkeleton from '@/components/molecules/notifications/NotificationsSkeleton'
import SlackBanner from '@/components/molecules/notifications/SlackBanner'
import AreaNotificationModal from '@/components/molecules/notifications/opportunity-plan/AreaNotificationModal'
import DeleteNotificationModal from '@/components/molecules/notifications/opportunity-plan/DeleteNotificationModal'
import EmptyNotificationsCard from '@/components/molecules/notifications/opportunity-plan/EmptyNotificationsCard'
import Search from '@/components/molecules/search'
import useDebounce from '@/hooks/useDebounce'
import useSlackChannels from '@/hooks/useSlackChannels'
import useUser from '@/hooks/useUser'
import { useUserStore } from '@/store'
import { OppPlanNotificationWithType } from '@/types/notifications/Notifications'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { shallow } from 'zustand/shallow'
import { useTranslation } from 'react-i18next'
import useAlerts from '@/hooks/alerts/useAlerts'
import { AlertWithType } from '@/types/alerts/Alerts'
import AlertsTable from '@/components/molecules/notifications/opportunity-plan/AlertsTable'
import useAlertList from '@/hooks/alerts/useAlertList'

const AlertsManagement = () => {
  const [deleteDialogMode, setDeleteDialogMode] = useState<'normal' | 'unsubscribe'>('normal')
  const [alertToDelete, setAlertToDelete] = useState<AlertWithType | undefined>()

  const [currentAlert, setCurrentAlert] = useState<AlertWithType | undefined>()

  const [createdByFilter, setCreatedByFilter] = useState<string | undefined>('')
  const [typeFilter, setTypeFilter] = useState<'area-interest' | 'opportunity' | ''>('')
  const [nameFilter, setNameFilter] = useState('')

  const debouncedNameFilter = useDebounce(nameFilter, 500)

  const { alerts, isLoading } = useAlertList({ enabled: true })
  const { slackChannels, isInitialLoading } = useSlackChannels()

  const { updateAlert, removeAlert, isRemoveLoading } = useAlerts({ enabled: false })

  const { userPermissions, currentUser } = useUser()
  const users = useUserStore(state => state.users, shallow)

  const onOpenDeleteDialogChange = (open: boolean) => {
    setAlertToDelete(open ? alertToDelete : undefined)
  }

  const { t } = useTranslation()

  const onClickSubscribe = useCallback(
    (alert: AlertWithType) => {
      const emailConfig = alert.notificationConfig.find(config => config.type === 'email')
      if (!emailConfig) return

      const isSubscribed = emailConfig.value.includes(currentUser?.email ?? '')
      const subScribeValue = isSubscribed
        ? emailConfig.value.filter(email => email !== currentUser?.email)
        : [currentUser?.email ?? '', ...emailConfig.value]

      if (!subScribeValue.length) {
        setDeleteDialogMode('unsubscribe')
        setAlertToDelete(alert)
        return
      }

      const notificationChannels =
        alert.notificationConfig.find(config => config.type === 'slack')?.value ?? []

      updateAlert({
        originalAlert: alert,
        channels: notificationChannels,
        emails: subScribeValue,
        name: alert.description,
        schedule: alert.notificationSchedule
      })
    },
    [currentUser, updateAlert]
  )

  const onClickDelete = useCallback((alert: AlertWithType) => {
    setDeleteDialogMode('normal')
    setAlertToDelete(alert)
  }, [])

  const onDelete = () => {
    if (!alertToDelete) return

    removeAlert(alertToDelete.id, {
      onSettled: () => {
        setAlertToDelete(undefined)
      }
    })
  }

  const createdByOptions = useMemo(() => {
    return [
      { text: t('createdBy'), value: '' },
      ...users.map(user => ({ text: user.name, value: user.user_id }) as SelectOption)
    ]
  }, [users, t])

  const showSlackBanner = !slackChannels?.length && !isInitialLoading

  const navigate = useNavigate()

  if (userPermissions.notifications.length === 0 && currentUser) {
    return <NoPermission />
  }

  return (
    <NotificationsContainer v2>
      {showSlackBanner && (
        <SlackBanner
          onClick={() =>
            navigate('/settings/notification_channels', { state: { openSlackConfig: true } })
          }
        />
      )}
      <FlexContainer justifyContent="spaceBetween">
        <FlexContainer direction="column" gap="micro">
          <Text as="h1" typeface="titleBoldXS">
            {t('manageNotifications')}
          </Text>
          <Text as="p" typeface="paragraphRegularMicro">
            {t('hereAreYouCanManageAllYourAccountNotificationsInOnePlace')}
          </Text>
        </FlexContainer>

        <FlexContainer gap="xxs" justifyContent="flexEnd">
          <FlexContainer css={{ height: 32 }}>
            <Search
              clearable
              expandOnFocus
              onChange={e => setNameFilter(e.currentTarget.value)}
              onClear={() => setNameFilter('')}
              placeholder={t('searchNotification')}
              size="small"
              small
              value={nameFilter}
            />
          </FlexContainer>

          <Select
            onValueChange={setCreatedByFilter}
            options={createdByOptions}
            small
            value={createdByFilter}
          />

          <Select
            onValueChange={value =>
              setTypeFilter(value as '' | OppPlanNotificationWithType['type'])
            }
            options={[
              { text: t('entity'), value: '' },
              { text: t('areaOfInterest'), value: 'area-interest' },
              { text: t('opportunity'), value: 'opportunity' }
            ]}
            small
            value={typeFilter}
          />
        </FlexContainer>
      </FlexContainer>

      {isLoading ? (
        <NotificationsSkeleton />
      ) : (
        <>
          {alerts.length === 0 ? (
            <EmptyNotificationsCard />
          ) : (
            <AlertsTable
              createdByFilter={createdByFilter}
              nameFilter={debouncedNameFilter}
              alerts={alerts}
              onClickDelete={onClickDelete}
              onClickName={setCurrentAlert}
              onClickSubscribe={onClickSubscribe}
              typeFilter={typeFilter}
            />
          )}
        </>
      )}

      <AreaNotificationModal
        defaultAlert={currentAlert}
        defaultNotificationType={currentAlert?.type}
        hideTrigger
        open={!!currentAlert}
        setOpen={value => setCurrentAlert(value ? currentAlert : undefined)}
      />

      <DeleteNotificationModal
        deleteMode={deleteDialogMode}
        isDeleting={isRemoveLoading}
        notification={alertToDelete}
        onConfirmDelete={onDelete}
        onOpenChange={onOpenDeleteDialogChange}
        open={!!alertToDelete}
      />
    </NotificationsContainer>
  )
}

export default AlertsManagement
