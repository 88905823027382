import { ScrollAreaRoot } from '@/components/molecules/scroll-area'
import { styled } from '@/theme'

export const DetailItem = styled('div', {
  '& code, & pre': {
    fontFamily: '$default'
  },
  '& > p': {
    fontSize: '$xxxs',
    lineHeight: '$md',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word'
  }
})

export const DetailTitle = styled('p', {
  fontSize: '$xxxs',
  color: '$grey',
  fontWeight: '$regular',
  lineHeight: '$md'
})

export const DetailInnerTitle = styled('p', {
  fontSize: '$xxxs',
  color: '$neutralLowPure',
  fontWeight: '$regular',
  lineHeight: '$md'
})

export const DetailsScrollArea = styled(ScrollAreaRoot, {
  overflow: 'hidden',
  height: 'fit-content',
  px: '$xs',
  py: '$xxxs'
})

export const DetailsHeader = styled('header', {
  px: '$xs',
  py: '$xxxs',
  backgroundColor: '$neutralHighMedium',
  display: 'flex',
  alignItems: 'center',
  gap: '$micro',
  br: 6,
  '& svg': {
    cursor: 'pointer'
  }
})
