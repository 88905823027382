import Checkbox from '@/components/atoms/checkbox'
import DistributionBar from '@/components/atoms/distribuition-bar'
import OptionsMenu, { OptionsMenuItem } from '@/components/atoms/options-menu'
import Text from '@/components/atoms/text'
import useClassification from '@/hooks/useClassification'
import { styled } from '@/theme'
import { TopicCategory } from '@/types/classification'
import { ThemeItemData } from '@/types/classification/Theme'
import { CaretRight } from '@phosphor-icons/react'
import { useState } from 'react'
import NameInput from '../input/NameInput'
import MoveTopicMenu from '../move-topic/MoveTopicMenu'
import FlexContainer from '@/components/atoms/flex-container'
import useUser from '@/hooks/useUser'

const ThemeItemContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  height: 24,
  width: '100%'
})

const ThemeItemContainerOuter = styled(ThemeItemContainer, {
  borderRadius: 8,
  padding: 4,
  margin: -4,
  paddingRight: 16,
  width: 342,
  overflow: 'visible',

  variants: {
    noHover: {
      true: {
        '&:hover': {
          background: 'transparent'
        }
      }
    }
  },

  '.theme-options': {
    position: 'relative',
    zIndex: 100,
    overflow: 'visible',
    opacity: 0
  },

  '&:hover': {
    '.theme-options': {
      opacity: 1
    }
  }
})

const ThemeItemInner = styled(ThemeItemContainer, {
  gap: 8,
  color: '$neutralLowLight',
  justifyContent: 'end',
  width: 120,
  marginLeft: 'auto'
})

const RoundedNumber = styled('span', {
  padding: '0 8px',
  marginRight: 32,
  borderRadius: 40,
  height: 'fit-content',
  backgroundColor: '$neutralHighMedium'
})

const SelectedTopicsCount = styled('span', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$brandPrimaryPure',
  color: '$neutralHighLight',
  size: 16,
  borderRadius: '$nano',
  marginLeft: '$micro',
  fontSize: '$micro',
  fontWeight: 500
})

interface Props extends ThemeItemData {
  onClickRemove: (themeId: string) => void
}

const ThemeItem = ({ onClickRemove, ...props }: Props) => {
  const { themeId, themeName, sentimentMetrics, frequency } = props
  const {
    selectTheme,
    unselectTheme,
    getThemeSelectedState,
    expandTheme,
    selectedCategory,
    renameTheme,
    moveTheme,
    isUnclassifiedSelected,
    getSelectedTopicCountByTheme,
    refreshThemes
  } = useClassification()

  const [isEditActive, setIsEditActive] = useState(false)
  const [openOptions, setOpenOptions] = useState(false)

  const { userPermissions } = useUser()
  const isManager = userPermissions.topic.includes('manager')

  const actualThemeId = themeId ?? 'unclassified'
  const isUnclassified = !themeId

  const percentage = {
    negativePercentage: sentimentMetrics.negativePercentage + '%',
    positivePercentage: sentimentMetrics.positivePercentage + '%',
    neutralPercentage: sentimentMetrics.neutralPercentage + '%'
  }

  const checkedState = getThemeSelectedState(actualThemeId)

  const onMoveTheme = (newCategory: string) => {
    moveTheme({ themeId, category: newCategory as TopicCategory })
  }

  function onCheck(checked: boolean) {
    if (checkedState === 'indeterminate') {
      unselectTheme(actualThemeId)
      return
    }

    if (checked) {
      selectTheme(actualThemeId)
      return
    }

    unselectTheme(actualThemeId)
  }

  function onClick() {
    if (isUnclassified) {
      return
    }

    expandTheme(props)
    refreshThemes()
  }

  function onEditClick() {
    setIsEditActive(true)
  }

  function onEditConfirm(value: string) {
    renameTheme({ themeId, themeName: value })
    setIsEditActive(false)
  }

  function onEditCancel() {
    setIsEditActive(false)
  }

  const options: OptionsMenuItem[] = [
    {
      text: 'Rename',
      onClick: onEditClick
    },
    {
      text: 'Delete',
      onClick: () => {
        setOpenOptions(false)
        onClickRemove(actualThemeId)
      }
    },
    {
      text: 'Move to...',
      customSubOption: (
        <MoveTopicMenu defaultSelected={selectedCategory} mode={'topic'} onApply={onMoveTheme} />
      )
    }
  ]

  const selectedTopicsCount = getSelectedTopicCountByTheme(themeId)

  if (isEditActive) {
    return (
      <NameInput
        confirmLabel="Apply"
        initialValue={themeName}
        onCancel={onEditCancel}
        onConfirm={onEditConfirm}
      />
    )
  }

  return (
    <ThemeItemContainerOuter noHover={isUnclassified}>
      <Checkbox
        checked={checkedState}
        disabled={!isUnclassified && isUnclassifiedSelected}
        onCheckedChange={onCheck}
        value={actualThemeId}
      />

      <ThemeItemContainer
        css={{ cursor: isUnclassified ? 'default' : 'pointer' }}
        onClick={onClick}
      >
        <Text as="span" maxWidth={200} title={themeName} truncate typeface="subtitleRegularXXXS">
          {themeName}
        </Text>
        {selectedTopicsCount > 0 && (
          <SelectedTopicsCount>{selectedTopicsCount}</SelectedTopicsCount>
        )}

        <ThemeItemInner>
          {isUnclassified ? (
            <RoundedNumber>
              <Text color="neutralLowLight" typeface="paragraphRegularMicro">
                {frequency}
              </Text>
            </RoundedNumber>
          ) : (
            <>
              <DistributionBar
                css={{ marginRight: 'auto', width: 28 }}
                height="small"
                {...percentage}
                outlined={false}
              />
              <Text color="neutralLowLight" typeface="paragraphRegularMicro">
                {frequency}
              </Text>
              <FlexContainer>
                <CaretRight size={12} weight="bold" />
              </FlexContainer>
              <FlexContainer css={{ width: 28.8, flexShrink: 0 }}>
                {isManager ? (
                  <span className="theme-options">
                    <OptionsMenu
                      onOpenChange={setOpenOptions}
                      open={openOptions}
                      options={options}
                      stopPropagation
                    />
                  </span>
                ) : null}
              </FlexContainer>
            </>
          )}
        </ThemeItemInner>
      </ThemeItemContainer>
    </ThemeItemContainerOuter>
  )
}

export default ThemeItem
