import SegmentationService from '@/services/SegmentationService'
import useToastMessageStore from '@/store/useToastMessageStore'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useLogging from '../useLogging'
import { useQuery } from '@tanstack/react-query'

const SEGMENT_KEY_PREFIX = 'segment'

interface Params {
  segmentationId: string
  enabled?: boolean
}

const useSingleSegmentationQuery = ({ segmentationId, enabled }: Params) => {
  const { t } = useTranslation()

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const { logException } = useLogging({ context: 'single-segment-query' })

  const segmentQueryKey = useMemo(() => [SEGMENT_KEY_PREFIX, segmentationId], [segmentationId])

  const segmentationQueryFn = async () => {
    if (!segmentationId || segmentationId.length === 0) {
      throw new Error('No segmentation id provided')
    }

    const [error, data] = await SegmentationService.getOne({
      segmentation_id: segmentationId,
      areas: ['all'],
      opportunities: ['all']
    })

    if (error) {
      logException(error, {
        message: 'Failed to get the segment data',
        tags: { segmentation_id: segmentationId }
      })
      addErrorToast({ text: t('getSegmentErrorMessage') })
      throw error
    }

    return data
  }

  const query = useQuery({
    queryKey: segmentQueryKey,
    queryFn: segmentationQueryFn,
    enabled
  })

  return { ...query, queryKey: segmentQueryKey }
}

export default useSingleSegmentationQuery
