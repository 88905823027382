import IconToggleButton from '@/components/atoms/icon-button/IconToggleButton'
import {
  ArrowUUpLeft,
  ArrowUUpRight,
  Link,
  LinkBreak,
  ListBullets,
  ListNumbers,
  TextBolder,
  TextHFour,
  TextHThree,
  TextHTwo,
  TextItalic,
  TextStrikethrough,
  TextUnderline
} from '@phosphor-icons/react'
import { Editor } from '@tiptap/react'
import { EditorToolbarContainer } from './TextEditor.styles'
import Divider from '@/components/atoms/divider'
import { TextHOne } from '@phosphor-icons/react/dist/ssr'
import LinkPopup from './LinkPopup'
import { useCallback, useState } from 'react'

interface EditorToolbarProps {
  editor: Editor | null
}

const EditorToolbar = ({ editor }: EditorToolbarProps) => {
  const [showLinkPopup, setShowLinkPopup] = useState(false)

  const linkState = editor?.isActive('link') ?? false
  const onLinkClick = useCallback(() => {
    if (!editor) return

    setShowLinkPopup(true)
  }, [editor])

  const onSetLink = useCallback(
    (link: string) => {
      if (!editor) return

      if (!link) {
        editor.chain().focus().unsetLink().run()
        setShowLinkPopup(false)
        return
      }

      editor.chain().focus().extendMarkRange('link').setLink({ href: link }).run()
      setShowLinkPopup(false)
    },
    [editor]
  )

  if (!editor) return null
  return (
    <>
      <EditorToolbarContainer>
        <IconToggleButton
          active={editor.isActive('bold')}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          onActiveChange={() => editor.chain().focus().toggleBold().run()}
        >
          <TextBolder />
        </IconToggleButton>
        <IconToggleButton
          active={editor.isActive('italic')}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          onActiveChange={() => editor.chain().focus().toggleItalic().run()}
        >
          <TextItalic />
        </IconToggleButton>
        <IconToggleButton
          active={editor.isActive('underline')}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
          onActiveChange={() => editor.chain().focus().toggleUnderline().run()}
        >
          <TextUnderline />
        </IconToggleButton>
        <IconToggleButton
          active={editor.isActive('strike')}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          onActiveChange={() => editor.chain().focus().toggleStrike().run()}
        >
          <TextStrikethrough />
        </IconToggleButton>
        <Divider line="solid" orientation="vertical" />
        <IconToggleButton
          active={editor.isActive('heading', { level: 1 })}
          disabled={!editor.can().chain().focus().toggleHeading({ level: 1 }).run()}
          onActiveChange={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        >
          <TextHOne />
        </IconToggleButton>
        <IconToggleButton
          active={editor.isActive('heading', { level: 2 })}
          disabled={!editor.can().chain().focus().toggleHeading({ level: 2 }).run()}
          onActiveChange={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        >
          <TextHTwo />
        </IconToggleButton>
        <IconToggleButton
          active={editor.isActive('heading', { level: 3 })}
          disabled={!editor.can().chain().focus().toggleHeading({ level: 3 }).run()}
          onActiveChange={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        >
          <TextHThree />
        </IconToggleButton>
        <IconToggleButton
          active={editor.isActive('heading', { level: 4 })}
          disabled={!editor.can().chain().focus().toggleHeading({ level: 4 }).run()}
          onActiveChange={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        >
          <TextHFour />
        </IconToggleButton>
        <Divider line="solid" orientation="vertical" />
        <IconToggleButton
          active={editor.isActive('bulletList')}
          disabled={!editor.can().chain().focus().toggleBulletList().run()}
          onActiveChange={() => editor.chain().focus().toggleBulletList().run()}
        >
          <ListBullets />
        </IconToggleButton>
        <IconToggleButton
          active={editor.isActive('orderedList')}
          disabled={!editor.can().chain().focus().toggleOrderedList().run()}
          onActiveChange={() => editor.chain().focus().toggleOrderedList().run()}
        >
          <ListNumbers />
        </IconToggleButton>
        <Divider line="solid" orientation="vertical" />
        <IconToggleButton active={linkState} onActiveChange={onLinkClick}>
          <Link />
        </IconToggleButton>
        <IconToggleButton
          active={false}
          disabled={!linkState}
          onActiveChange={() => editor.chain().focus().unsetLink().run()}
        >
          <LinkBreak />
        </IconToggleButton>
        <Divider line="solid" orientation="vertical" />
        <IconToggleButton
          active={false}
          disabled={!editor.can().chain().focus().undo().run()}
          onActiveChange={() => editor.chain().focus().undo().run()}
        >
          <ArrowUUpLeft />
        </IconToggleButton>
        <IconToggleButton
          active={false}
          disabled={!editor.can().chain().focus().redo().run()}
          onActiveChange={() => editor.chain().focus().redo().run()}
        >
          <ArrowUUpRight />
        </IconToggleButton>
      </EditorToolbarContainer>

      <LinkPopup onOpenChange={setShowLinkPopup} onSetLink={onSetLink} open={showLinkPopup} />
    </>
  )
}

export default EditorToolbar
