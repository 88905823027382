import { colors } from '@/theme'
import { GenericFilter, NumberFilterValue } from '@/types/filters/AdvancedFilters'

const sentimentColor = {
  positive: colors.feedbackPositivePure,
  negative: colors.feedbackNegativePure,
  neutral: colors.neutralLowLight
}

const sentimentBackgroundColor = {
  positive: colors.feedbackPositiveLight,
  negative: colors.feedbackNegativeLight,
  neutral: colors.neutralHighMedium
}

export function getSentimentName(sentiment: number | null): SimpleSentiment {
  if (sentiment === null) return 'neutral'
  if (sentiment > 0) return 'positive'
  if (sentiment < 0) return 'negative'
  return 'neutral'
}

export function getSentimentValue(sentiment: SimpleSentiment): number {
  if (sentiment === 'positive') return 1
  if (sentiment === 'negative') return -1
  if (sentiment === 'neutral') return 0
  return 0
}

export function getSentimentColor(sentiment: number | null) {
  const sentimentName = getSentimentName(sentiment ?? 0)

  return sentimentColor[sentimentName]
}

export function getSentimentBackgroundColor(sentiment: number | null) {
  const sentimentName = getSentimentName(sentiment ?? 0)

  return sentimentBackgroundColor[sentimentName]
}

export enum Sentiment {
  OnlyNegative = 'onlyNegative',
  MostlyNegative = 'mostlyNegative',
  AnyNegative = 'anyNegative',

  OnlyNeutral = 'onlyNeutral',
  MostlyNeutral = 'mostlyNeutral',
  AnyNeutral = 'anyNeutral',

  OnlyPositive = 'onlyPositive',
  MostlyPositive = 'mostlyPositive',
  AnyPositive = 'anyPositive'
}

export const positiveSentiments = [
  Sentiment.OnlyPositive,
  Sentiment.MostlyPositive,
  Sentiment.AnyPositive
]
export const negativeSentiments = [
  Sentiment.OnlyNegative,
  Sentiment.MostlyNegative,
  Sentiment.AnyNegative
]
export const neutralSentiments = [
  Sentiment.OnlyNeutral,
  Sentiment.MostlyNeutral,
  Sentiment.AnyNeutral
]

export const sentimentToFilter = (sentiment: Sentiment): GenericFilter => {
  const sentimentToFilterMapping: Record<Sentiment, GenericFilter> = {
    [Sentiment.OnlyNegative]: {
      name: 'negative_ratio',
      type: 'number',
      value: { $eq: 1 },
      path: 'negative_ratio',
      status: 'valid',
      option: 'negative'
    },
    [Sentiment.MostlyNegative]: {
      name: 'negative_ratio',
      type: 'number',
      value: { $gte: 0.5, $lte: 1 },
      path: 'negative_ratio',
      status: 'valid',
      option: 'negative'
    },
    [Sentiment.AnyNegative]: {
      name: 'sentiment',
      type: 'enum',
      value: ['NEGATIVE'],
      path: 'sentiment',
      status: 'valid',
      option: 'negative'
    },

    [Sentiment.OnlyNeutral]: {
      name: 'neutral_ratio',
      type: 'number',
      value: { $eq: 1 },
      path: 'neutral_ratio',
      status: 'valid',
      option: 'neutral'
    },
    [Sentiment.MostlyNeutral]: {
      name: 'neutral_ratio',
      type: 'number',
      value: { $gte: 0.5, $lte: 1 },
      path: 'neutral_ratio',
      status: 'valid',
      option: 'neutral'
    },
    [Sentiment.AnyNeutral]: {
      name: 'sentiment',
      type: 'enum',
      value: ['NEUTRAL'],
      path: 'sentiment',
      status: 'valid',
      option: 'neutral'
    },

    [Sentiment.OnlyPositive]: {
      name: 'positive_ratio',
      type: 'number',
      value: { $eq: 1 },
      path: 'positive_ratio',
      status: 'valid',
      option: 'positive'
    },
    [Sentiment.MostlyPositive]: {
      name: 'positive_ratio',
      type: 'number',
      value: { $gte: 0.5, $lte: 1 },
      path: 'positive_ratio',
      status: 'valid',
      option: 'positive'
    },
    [Sentiment.AnyPositive]: {
      name: 'sentiment',
      type: 'enum',
      value: ['POSITIVE'],
      path: 'sentiment',
      status: 'valid',
      option: 'positive'
    }
  }

  return sentimentToFilterMapping[sentiment]
}

export function filterToSentiment(filter: GenericFilter): Sentiment | undefined {
  if (!filter.value) return
  const selected = filter.value as string[]

  if (filter.name === 'sentiment' && selected.length === 1) {
    if (selected[0] === 'POSITIVE') return Sentiment.AnyPositive
    if (selected[0] === 'NEGATIVE') return Sentiment.AnyNegative
    if (selected[0] === 'NEUTRAL') return Sentiment.AnyNeutral
  }

  const value = filter.value as NumberFilterValue
  if (filter.name === 'negative_ratio') {
    if (value.$eq === 1) return Sentiment.OnlyNegative
    if (value.$gte === 0.5 && value.$lte === 1) return Sentiment.MostlyNegative
  }
  if (filter.name === 'positive_ratio') {
    if (value.$eq === 1) return Sentiment.OnlyPositive
    if (value.$gte === 0.5 && value.$lte === 1) return Sentiment.MostlyPositive
  }
  if (filter.name === 'neutral_ratio') {
    if (value.$eq === 1) return Sentiment.OnlyNeutral
    if (value.$gte === 0.5 && value.$lte === 1) return Sentiment.MostlyNeutral
  }

  return
}

export const sentimentFilterNames = ['positive_ratio', 'neutral_ratio', 'negative_ratio']
