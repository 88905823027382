import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { CSS, colors, styled, typefaceVariants } from '@/theme'
import React, { Ref } from 'react'
import { CaretRight, IconContext, IconProps } from '@phosphor-icons/react'
import { slideDown, slideUp } from '@/theme/animations'

const StyledAccordion = AccordionPrimitive.Root

const StyledItem = styled(AccordionPrimitive.Item, {
  overflow: 'hidden'
})

const StyledHeader = styled(AccordionPrimitive.Header, {
  all: 'unset',
  display: 'flex'
})

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  all: 'unset',
  ...typefaceVariants.subtitleSemiBoldMicro,
  color: '$neutralLowPure',

  flex: 1,
  display: 'flex',
  alignItems: 'center',
  gap: '$micro',
  py: '$micro',
  cursor: 'pointer',

  '.trigger-icon': {
    transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)'
  },
  '&[data-state="open"]': {
    '.trigger-icon': {
      transform: 'rotate(90deg)'
    }
  }
})

const StyledContent = styled(AccordionPrimitive.Content, {
  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`
  }
})

const Container = styled('div', {
  paddingTop: 0
})

export const Accordion = StyledAccordion

export const AccordionItem = StyledItem
export const AccordionTrigger = React.forwardRef(
  (
    { children, css, ...props }: AccordionPrimitive.AccordionTriggerProps & { css?: CSS },
    forwardedRef: Ref<HTMLButtonElement>
  ) => (
    <StyledHeader>
      <StyledTrigger {...props} css={css} ref={forwardedRef}>
        {children}
      </StyledTrigger>
    </StyledHeader>
  )
)
AccordionTrigger.displayName = 'AccordionTrigger'

export const AccordionContent = React.forwardRef(
  (
    { children, css, ...props }: AccordionPrimitive.AccordionContentProps & { css?: CSS },
    forwardedRef: Ref<HTMLDivElement>
  ) => (
    <StyledContent {...props} ref={forwardedRef}>
      <Container css={css}>{children}</Container>
    </StyledContent>
  )
)
AccordionContent.displayName = 'AccordionContent'

export const AccordionCaret = (props: IconProps) => (
  <IconContext.Provider value={{ color: colors.neutralLowPure, size: 12, weight: 'bold' }}>
    <CaretRight className="trigger-icon" {...props} />
  </IconContext.Provider>
)

export const AccordionCardItem = styled(StyledItem, {
  padding: '$xs $xxs',
  borderRadius: 8,
  bAll: '$neutralHighPure',
  gap: '$xs'
})
