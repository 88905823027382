import Dialog from '@/components/atoms/dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import useAccountsFilters from '@/hooks/filters/useAccountsFilters'
import useCustomerUsersFilters from '@/hooks/filters/useCustomerUsersFilters'
import useFeedFilters from '@/hooks/filters/useFeedFilters'
import useLetterCase from '@/hooks/useLetterCase'
import useSegment from '@/hooks/useSegment'
import { useFeedbackSearchChatStore } from '@/store/useChatStore'
import { ChatModel } from '@/types/feedbacks/FeedbackRequests'
import { FilterItem } from '@/types/filters/Filters'
import { useMemo, useState } from 'react'
import { shallow } from 'zustand/shallow'
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '../scroll-area'
import {
  Accordion,
  AccordionCaret,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '../accordion'
import Button from '@/components/atoms/button'
import Checkbox from '@/components/atoms/checkbox'
import RadioGroup from '@/components/atoms/radio-group'
import { modelName } from '@/utils/chat'
import { styled } from '@/theme'
import { useTranslation } from 'react-i18next'

const SettingsScrollArea = styled(ScrollAreaRoot, {
  overflow: 'hidden'
})

const SettingsAccordion = styled(Accordion, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$xs'
})

const SettingsAccordionContent = styled(AccordionContent, {
  pl: '$xs'
  // pt: '$xs'
})

const FIELDS_TO_INCLUDE = ['title', 'posted_at']

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  selectedFields: FilterItem[]
  setSelectedFields: (value: FilterItem[]) => void
}

const AssistantSettings = ({ open, onOpenChange, selectedFields, setSelectedFields }: Props) => {
  const { track } = useSegment()

  const { customFieldsFilters, feedbackDetailsFilters, feedbackTypeFilter } = useFeedFilters()
  const { fields: accountFields } = useAccountsFilters()
  const { fields: userFields } = useCustomerUsersFilters()

  const [tempFields, setTempFields] = useState(selectedFields)

  const { snakeToTitle } = useLetterCase()

  const onDialogOpenChange = (value: boolean) => {
    if (!value) {
      setTempFields(selectedFields)
    }
    onOpenChange(value)
  }

  const { t } = useTranslation()

  const model = useFeedbackSearchChatStore(state => state.model, shallow)
  const setModel = useFeedbackSearchChatStore(state => state.setModel)
  const [tempModel, setTempModel] = useState<ChatModel>(model)

  const onSave = () => {
    if (tempModel !== model) {
      setModel(tempModel)
      track('explore_user_chat_refinement_options', {
        options: [tempModel, 'random']
      })
    }
    if (
      JSON.stringify(tempFields.sort((a, b) => a.name.localeCompare(b.name))) !==
      JSON.stringify(selectedFields.sort((a, b) => a.name.localeCompare(b.name)))
    ) {
      setSelectedFields(tempFields)
      track('explore_user_chat_refinement_options_fields', {
        fields: tempFields.map(field => field.name)
      })
    }
    onOpenChange(false)
  }

  const coreFieldFilters = useMemo(() => {
    const filters = [...feedbackDetailsFilters]
    if (feedbackTypeFilter) {
      filters.push(feedbackTypeFilter)
    }
    return [...filters].sort((a, b) => a.displayName.localeCompare(b.displayName))
  }, [feedbackDetailsFilters, feedbackTypeFilter])

  const customFieldFilters = useMemo(() => {
    return [...customFieldsFilters].sort((a, b) => a.displayName.localeCompare(b.displayName))
  }, [customFieldsFilters])

  const allFilters = useMemo(() => {
    return [...coreFieldFilters, ...customFieldFilters]
  }, [coreFieldFilters, customFieldFilters])

  const findByKey = (key: string) => {
    if (FIELDS_TO_INCLUDE.includes(key)) {
      return { displayName: snakeToTitle(key), key, name: key, type: 'string' } as FilterItem
    }
    return allFilters.find(field => field.key === key)
  }

  const customerRecordFields = useMemo(() => {
    return [...accountFields, ...userFields].sort((a, b) =>
      a.displayName.localeCompare(b.displayName)
    )
  }, [accountFields, userFields])

  const findCustomerRecordFieldByKey = (key: string) => {
    return customerRecordFields.find(field => field.key === key)
  }

  const isChecked = (key: string) => tempFields.find(field => field.key === key) !== undefined

  const onCheckChange = (key: string) => (checked: boolean) => {
    const field = findByKey(key)
    setTempFields(
      checked && field ? [...tempFields, field] : tempFields.filter(temp => temp.key !== key)
    )
  }

  const onCheckCustomerRecordFieldChange = (key: string) => (checked: boolean) => {
    const field = findCustomerRecordFieldByKey(key)
    setTempFields(
      checked && field ? [...tempFields, field] : tempFields.filter(temp => temp.key !== key)
    )
  }

  const clearCoreField = () => {
    setTempFields(
      tempFields.filter(
        field =>
          !coreFieldFilters.find(filter => filter.key === field.key) &&
          !FIELDS_TO_INCLUDE.includes(field.key)
      )
    )
  }

  // const clearCustomFields = () => {
  //   setTempFields(
  //     tempFields.filter(field => !customFieldFilters.find(filter => filter.key === field.key))
  //   )
  // }

  const clearCustomerRecordFields = () => {
    setTempFields(
      tempFields.filter(field => !customerRecordFields.find(filter => filter.key === field.key))
    )
  }

  return (
    <Dialog
      align="right"
      closeIcon
      modal={false}
      onOpenChange={onDialogOpenChange}
      open={open}
      useFullHeight
    >
      <FlexContainer css={{ padding: '$xs $sm' }} direction="column" fullHeight gap="xs">
        <Text as="h2" typeface="titleBoldXXS">
          {t('settings')}
        </Text>
        <Divider line="solid" />
        <SettingsScrollArea>
          <ScrollAreaViewport>
            <SettingsAccordion type="multiple">
              <AccordionItem css={{ display: 'unset' }} value="context">
                <AccordionTrigger>
                  <AccordionCaret size={16} />
                  <Text as="h3" fontSize="xxs" fontWeight="semibold">
                    {t('addSpecificContext')}
                  </Text>
                </AccordionTrigger>
                <SettingsAccordionContent>
                  <FlexContainer direction="column" gap="xs">
                    <Text as="p" color="neutralLowMedium" fontSize="xxxs">
                      {t(
                        'selectFieldsToSegmentYourAnalysisIntoASpecificContextBeAwareThatMoreFieldsAddedMeansLessFeedbackAvailableForAnalysis'
                      )}
                    </Text>
                    <FlexContainer direction="column">
                      <FlexContainer justifyContent="spaceBetween">
                        <Text as="p" fontSize="xxxs" fontWeight="semibold">
                          {t('coreFields')}
                        </Text>
                        <Button onClick={clearCoreField} size="small" variant="flat">
                          {t('clearAll')}
                        </Button>
                      </FlexContainer>
                      <FlexContainer css={{ ml: '$nano' }} direction="column" gap="micro">
                        {FIELDS_TO_INCLUDE.map(field => (
                          <Checkbox
                            checked={!!isChecked(field)}
                            key={field}
                            onCheckedChange={onCheckChange(field)}
                            text={snakeToTitle(field)}
                            value={field}
                          />
                        ))}
                        {coreFieldFilters.map(filter => (
                          <Checkbox
                            checked={!!isChecked(filter.key)}
                            key={filter.key}
                            onCheckedChange={onCheckChange(filter.key)}
                            text={filter.displayName}
                            value={filter.key}
                          />
                        ))}
                      </FlexContainer>
                    </FlexContainer>

                    {customerRecordFields.length > 0 && (
                      <FlexContainer direction="column">
                        <FlexContainer justifyContent="spaceBetween">
                          <Text as="p" fontSize="xxxs" fontWeight="semibold">
                            {t('customerRecordsFields')}
                          </Text>
                          <Button onClick={clearCustomerRecordFields} size="small" variant="flat">
                            {t('clearAll')}
                          </Button>
                        </FlexContainer>
                        <FlexContainer css={{ ml: '$nano' }} direction="column" gap="micro">
                          {customerRecordFields.map(filter => (
                            <Checkbox
                              checked={!!isChecked(filter.key)}
                              key={filter.key}
                              onCheckedChange={onCheckCustomerRecordFieldChange(filter.key)}
                              text={filter.displayName}
                              value={filter.key}
                            />
                          ))}
                        </FlexContainer>
                      </FlexContainer>
                    )}

                    {/* <FlexContainer direction="column">
                      <FlexContainer justifyContent="spaceBetween">
                        <Text as="p" fontSize="xxxs" fontWeight="semibold">
                          Custom fields:
                        </Text>
                        <Button onClick={clearCustomFields} size="small" variant="flat">
                          Clear all
                        </Button>
                      </FlexContainer>
                      <FlexContainer css={{ ml: '$nano' }} direction="column" gap="micro">
                        {customFieldFilters.map(filter => (
                          <Checkbox
                            checked={!!isChecked(filter.key)}
                            key={filter.key}
                            onCheckedChange={onCheckChange(filter.key)}
                            text={filter.displayName}
                            value={filter.key}
                          />
                        ))}
                      </FlexContainer>
                    </FlexContainer> */}
                  </FlexContainer>
                </SettingsAccordionContent>
              </AccordionItem>
              <Divider line="solid" />
              <AccordionItem value="model">
                <AccordionTrigger>
                  <AccordionCaret size={16} />

                  <Text as="h3" fontSize="xxs" fontWeight="semibold">
                    {t('speedModel')}
                  </Text>
                </AccordionTrigger>
                <SettingsAccordionContent>
                  <RadioGroup
                    gap="xxxs"
                    onValueChange={value => setTempModel(value as ChatModel)}
                    options={[
                      { text: modelName.openai, value: 'openai' },
                      { text: modelName.anthropic, value: 'anthropic' }
                    ]}
                    textProps={{ fontSize: 'xxxs', color: 'neutralLowMedium' }}
                    value={tempModel}
                  />
                </SettingsAccordionContent>
              </AccordionItem>
              <Divider line="solid" />
            </SettingsAccordion>
          </ScrollAreaViewport>
          <ScrollAreaScrollbar orientation="vertical">
            <ScrollAreaThumb />
          </ScrollAreaScrollbar>
        </SettingsScrollArea>
        <FlexContainer css={{ mt: 'auto' }} onClick={onSave}>
          <Button>{t('saveChanges')}</Button>
        </FlexContainer>
      </FlexContainer>
    </Dialog>
  )
}

export default AssistantSettings
