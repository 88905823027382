import AreaOfInterestList from '../../area-of-interest/AreaOfInterestList/AreaOfInterestList'
import Text from '@/components/atoms/text'
import NewOpportunity from '../../exploration/Opportunities/NewOpportunity'
import shortUUID from 'short-uuid'
import { useMemo } from 'react'
import Checkbox, { CheckboxState } from '@/components/atoms/checkbox'
import FlexContainer from '@/components/atoms/flex-container'
import { useTranslation } from 'react-i18next'
import { OpportunityItemWithMetrics } from '@/types/opportunity/Opportunity'
import { StyledLink } from '@/components/atoms/table-v2'

interface Props {
  data: OpportunityItemWithMetrics
  onOpportunityClick: (opportunity: OpportunityItemWithMetrics) => void
  showCheckbox?: boolean
  checked?: boolean
  checkboxDisabled?: boolean
  onCheckedChange?: (checked: CheckboxState) => void
  disabled?: boolean
}

const OpportunityNameCell = ({
  data,
  onOpportunityClick,
  showCheckbox,
  checked,
  checkboxDisabled,
  onCheckedChange,
  disabled
}: Props) => {
  const { t } = useTranslation()
  const handleClick = () => {
    if (disabled) return
    onOpportunityClick(data)
  }

  const subtitle = useMemo(() => {
    const nAreas = data.relations.length
    if (nAreas === 0) return ''
    if (data.area) return data.area.name
    return `${t('multipleAreas')} (${nAreas})`
  }, [data.relations, data.area, t])

  const translator = useMemo(() => shortUUID(), [])

  const shortOpportunityId = useMemo(
    () => translator.fromUUID(data.id ?? ''),
    [data.id, translator]
  )

  const toLink = useMemo(
    () => (disabled ? '' : `/opportunity/${shortOpportunityId}`),
    [shortOpportunityId, disabled]
  )

  return (
    <AreaOfInterestList.LongCellContainer
      alignItems="start"
      css={{
        position: 'relative',
        padding: '$micro $xxs',
        pl: 36,
        textAlign: 'left',
        maxWidth: '100% !important'
      }}
      direction="column"
      fullWidth
      justifyContent="flexStart"
    >
      {data.new && <NewOpportunity.Badge css={{ whiteSpace: 'pre', maxHeight: 16, mb: '$nano' }} />}
      <FlexContainer alignItems="start" gap="nano">
        {showCheckbox && (
          <Checkbox
            checked={checked}
            disabled={checkboxDisabled}
            onCheckedChange={onCheckedChange}
            value={'checkbox-' + data.id}
          />
        )}
        <StyledLink
          aria-disabled={data.relations.length === 0 || disabled}
          onClick={handleClick}
          to={toLink}
        >
          <Text typeface="subtitleRegularMicro">{data.name}</Text>
        </StyledLink>
      </FlexContainer>
      <Text
        css={{ ml: showCheckbox ? 27 : 0 }}
        color="neutralLowLight"
        typeface="subtitleRegularNano"
      >
        {subtitle}
      </Text>
    </AreaOfInterestList.LongCellContainer>
  )
}

export default OpportunityNameCell
