import { useQuery } from '@tanstack/react-query'
import useBasicAreaOfInterestQuery from './useBasicAreaOfInterestQuery'
import { useCallback, useMemo, useState } from 'react'
import { AreaOfInterestData } from '@/types/area/AreaOfInterest'
import useToastMessageStore from '@/store/useToastMessageStore'
import useLogging from '../useLogging'
import { useTranslation } from 'react-i18next'
import useSortingMetric from '../metrics/useSortingMetric'
import useAppliedDate from '../useAppliedDate'
import getMetricsForAreasFn from './getMetricsForAreasFn'
import useAdvancedFilters from '../advancedFilters/useAdvancedFilters'
import useOpportunityStore from '@/store/useOpportunityStore'
import { shallow } from 'zustand/shallow'
import useMetricListPayload from '../metrics/useMetricListPayload'
import { queryClient } from '@/plugins/reactQueryClient'
import useDidUpdateEffect from '../useDidUpdateEffect'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import useSingleSegmentationQuery from '../segmentation/useSingleSegmentationQuery'

const PAGE_SIZE = 10

const AREAS_OF_SEGMENT_SORTING_METRIC_KEY_PREFIX = 'sort-areas-of-segment'
const AREAS_OF_SEGMENT_ALL_METRICS_KEY_PREFIX = 'get-all-metrics-for-segmentation'

const INVALID_METRIC_TABLE_COLUMNS = ['', 'name']

interface Params {
  segmentationId?: string
  enabled?: boolean
  sortColumn?: string
  sortDirection?: 'asc' | 'desc'
}

const useAreasOfSegment = ({
  segmentationId,
  enabled = true,
  sortColumn = 'count',
  sortDirection = 'desc'
}: Params) => {
  const { t } = useTranslation()

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const { logException } = useLogging({ context: 'areas-of-segment' })

  const { dateRange, datePeriod, startDate, endDate } = useAppliedDate()

  const { filters: currentFilters } = useAdvancedFilters()
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity, shallow)

  const { areas: allAreas, isLoading: isLoadingAllAreas } = useBasicAreaOfInterestQuery({
    enabled
  })

  const { addShareFiltersToMetrics, getMetricList } = useMetricListPayload()

  const allowedMetricBySource = useHiddenMetricsStore(state => state.allowedMetricsBySource)

  const metricList = useMemo(() => {
    return addShareFiltersToMetrics(
      getMetricList({ includePreviousValue: false, metricFor: 'opportunity' })
    )
  }, [getMetricList, addShareFiltersToMetrics])

  const [currentPage, setCurrentPage] = useState(0)

  const {
    data: segmentationData,
    isLoading: isSegmentationDataLoading,
    queryKey: areasOfSegmentQueryKey
  } = useSingleSegmentationQuery({
    segmentationId: segmentationId ?? '',
    enabled: enabled && !!segmentationId && !!allAreas
  })

  const segmentationAreas = useMemo(() => {
    if (!segmentationData) return []
    const segAreas = segmentationData.areas
    const mappedAreas = segAreas.map((area): AreaOfInterestData => {
      const areaFound = allAreas.find(a => a.id === area.id)
      if (areaFound) {
        return {
          ...areaFound,
          metrics: [],
          opportunities: []
        }
      }

      // very improbable for this to happen
      return {
        id: area.id,
        filterId: area.id,
        name: area.name,
        description: '',
        content: area.content ?? [],
        context: area.context,
        createdBy: '',
        opportunityCount: 0,
        metrics: [],
        opportunities: [],
        useInUnmappedArea: false,
        advanced: false,
        error: null
      }
    })

    return mappedAreas
  }, [segmentationData, allAreas])

  const sortingMetric = useSortingMetric(sortColumn)

  const sortingAreasOfSegmentMetricQueryKey = useMemo(
    () => [
      AREAS_OF_SEGMENT_SORTING_METRIC_KEY_PREFIX,
      { segmentationAreas, datePeriod, dateRange, sortColumn }
    ],
    [segmentationAreas, datePeriod, dateRange, sortColumn]
  )

  const {
    data: allAreasOfSegmentWithSortMetric,
    isLoading: isLoadingAllAreasOfSegmentWithSortMetric
  } = useQuery({
    queryKey: sortingAreasOfSegmentMetricQueryKey,
    queryFn: async () => {
      if (!segmentationAreas) return []

      const [error, data] = await getMetricsForAreasFn({
        areas: segmentationAreas,
        metricList: [sortingMetric],
        startDate,
        endDate,
        currentFilters,
        opportunityId: currentOpportunity?.id
      })

      if (error) {
        logException(error, {
          message: `Failed to fetch metric "${sortingMetric.name}/${sortingMetric.label}" to sort areas`,
          tags: {
            segmentationId: segmentationId ?? '',
            sortingMetricName: sortingMetric.name,
            sortingMetricLabel: sortingMetric.label,
            startDate: startDate ?? '',
            endDate: endDate ?? ''
          }
        })
        addErrorToast({ text: t('fetchAreasSortMetricErrorMessage') })
        throw error
      }

      return data
    },
    enabled:
      enabled &&
      segmentationAreas &&
      segmentationAreas.length > 0 &&
      !INVALID_METRIC_TABLE_COLUMNS.includes(sortColumn ?? 'count')
  })

  const allSegmentationAreaSorted = useMemo(() => {
    let newAreas: AreaOfInterestData[] = []

    if (INVALID_METRIC_TABLE_COLUMNS.includes(sortColumn ?? 'count')) {
      if (!segmentationAreas) return newAreas
      newAreas = [...segmentationAreas]

      if (sortColumn === 'name') {
        newAreas.sort((a, b) =>
          sortDirection === 'desc' ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name)
        )
      }
    } else {
      if (!allAreasOfSegmentWithSortMetric) return newAreas
      newAreas = [...allAreasOfSegmentWithSortMetric]

      newAreas.sort((a, b) => {
        const valueA =
          a.metrics.find(metric => metric.label === sortingMetric.label)?.current_value ?? 0
        const valueB =
          b.metrics.find(metric => metric.label === sortingMetric.label)?.current_value ?? 0

        return sortDirection === 'desc' ? valueB - valueA : valueA - valueB
      })
    }

    return newAreas
  }, [allAreasOfSegmentWithSortMetric, sortColumn, sortDirection, segmentationAreas, sortingMetric])

  const areasOfSegmentWithAllMetricsQueryKey = useMemo(() => {
    const key = [
      AREAS_OF_SEGMENT_ALL_METRICS_KEY_PREFIX,
      {
        currentPage,
        sortedAreaIds: allSegmentationAreaSorted.map(area => area.id),
        metricList,
        startDate,
        endDate
      }
    ]
    return key
  }, [allSegmentationAreaSorted, currentPage, metricList, startDate, endDate])

  const { data: areasOfSegmentWithAllMetrics, isLoading: isLoadingAreasOfSegmentWithAllMetrics } =
    useQuery({
      queryKey: areasOfSegmentWithAllMetricsQueryKey,
      queryFn: async () => {
        if (!allSegmentationAreaSorted) return []
        if (allSegmentationAreaSorted.length === 0) return []

        const startPage = currentPage * PAGE_SIZE
        const endPage = (currentPage + 1) * PAGE_SIZE
        const currentSlice = allSegmentationAreaSorted.slice(startPage, endPage)

        const [error, fetchedSlice] = await getMetricsForAreasFn({
          areas: currentSlice,
          metricList,
          startDate,
          endDate,
          currentFilters,
          opportunityId: currentOpportunity?.id
        })

        if (error) {
          logException(error, {
            message: `Failed to fetch metrics for areas`,
            tags: {
              segmentationId: segmentationId ?? '',
              metricList: JSON.stringify(metricList.map(metric => metric.label)),
              startDate: startDate ?? '',
              endDate: endDate ?? ''
            }
          })
          addErrorToast({ text: t('fetchMetricsErrorMessage') })
          throw error
        }

        const newAllAreas = [...allSegmentationAreaSorted]
        // Substitute the current slice with the new, with all the page metrics
        newAllAreas.splice(startPage, PAGE_SIZE, ...fetchedSlice)

        // update the list with sorted metric, prevents the other metrics from blinking
        queryClient.setQueryData(sortingAreasOfSegmentMetricQueryKey, newAllAreas)

        return newAllAreas
      },
      enabled: enabled && allSegmentationAreaSorted?.length > 0
    })

  const loadNextPage = useCallback(() => {
    setCurrentPage(prevPage => prevPage + 1)
  }, [])

  const areasOfSegment = useMemo(() => {
    let _areas: AreaOfInterestData[] = []

    if (segmentationAreas && segmentationAreas.length > 0) {
      _areas = [...segmentationAreas]
    }

    if (allSegmentationAreaSorted && allSegmentationAreaSorted.length > 0) {
      _areas = [...allSegmentationAreaSorted]
    }

    if (areasOfSegmentWithAllMetrics && areasOfSegmentWithAllMetrics.length > 0) {
      _areas = [...areasOfSegmentWithAllMetrics]
    }

    return _areas.slice(0, (currentPage + 1) * PAGE_SIZE)
  }, [allSegmentationAreaSorted, areasOfSegmentWithAllMetrics, currentPage, segmentationAreas])

  const hasMore =
    areasOfSegmentWithAllMetrics &&
    areasOfSegmentWithAllMetrics.length > (currentPage + 1) * PAGE_SIZE

  const resetPage = useCallback(() => {
    setCurrentPage(0)
  }, [])

  useDidUpdateEffect(() => {
    resetPage()
  }, [allowedMetricBySource])

  return {
    areasOfSegment,
    isSegmentationDataLoading,
    isLoadingAllAreas,
    isLoadingAreasOfSegmentWithAllMetrics,
    isLoadingAllAreasOfSegmentWithSortMetric,
    isLoading:
      isSegmentationDataLoading ||
      isLoadingAllAreas ||
      isLoadingAreasOfSegmentWithAllMetrics ||
      isLoadingAllAreasOfSegmentWithSortMetric,
    loadNextPage,
    hasMore,
    queryKey: areasOfSegmentQueryKey,
    segmentationAreas,
    allSegmentationAreaSorted,
    resetPage
  }
}

export default useAreasOfSegment
