import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'

import { useFiltersStore } from '@/store'
import { FilterDatetime, FilterNumeric, FilterString } from '@/types/filters/Filters'
import {
  FEEDBACK_DATA_TYPE_KEY,
  FEEDBACK_INTENTION_KEY,
  FEEDBACK_TYPE_KEY,
  SENTIMENT_KEY
} from '@/utils/filters'
import FilterItem, {
  FilterItemDropdownDatetime,
  FilterItemDropdownNumeric,
  FilterItemDropdownString
} from '../FilterItem'

function AppliedFeedbackDetailFilters() {
  const apply = useFiltersStore(state => state.apply)
  const datetimeFilters = useFiltersStore(state => state.datetimeFilters, shallow)
  const numericFilters = useFiltersStore(state => state.numericFilters, shallow)
  const removeDatetimeFilter = useFiltersStore(state => state.removeDatetimeFilter)
  const removeNumericFilter = useFiltersStore(state => state.removeNumericFilter)
  const removeStringFilter = useFiltersStore(state => state.removeStringFilter)
  const stringFilters = useFiltersStore(state => state.stringFilters, shallow)

  const appliedDatetimeFilters = useMemo(() => {
    const feedbackDetailDatetimeTypes = ['schema_fields_datetime']

    return datetimeFilters.filter(filter => {
      return filter.value !== null && feedbackDetailDatetimeTypes.includes(filter.type)
    })
  }, [datetimeFilters])

  const appliedNumericFilters = useMemo(() => {
    const feedbackDetailNumericTypes = ['schema_fields_integer', 'schema_fields_number']

    return numericFilters.filter(filter => {
      return feedbackDetailNumericTypes.includes(filter.type) && filter.option !== 'all'
    })
  }, [numericFilters])

  const appliedStringFilters = useMemo(() => {
    const feedbackDetailStringTypes = ['string', 'schema_fields_string']
    const excludedFieldKeys = [
      FEEDBACK_INTENTION_KEY,
      SENTIMENT_KEY,
      FEEDBACK_TYPE_KEY,
      FEEDBACK_DATA_TYPE_KEY
    ]

    return stringFilters.filter(filter => {
      return (
        filter.selected.length > 0 &&
        feedbackDetailStringTypes.includes(filter.type) &&
        !excludedFieldKeys.includes(filter.key)
      )
    })
  }, [stringFilters])

  function removeDatetimeFilterOptions(filter: FilterDatetime) {
    removeDatetimeFilter(filter.key)
    apply()
  }

  function removeNumericFilterOptions(filter: FilterNumeric) {
    removeNumericFilter(filter.key)
    apply()
  }

  function removeStringFilterOptions(filter: FilterString) {
    filter.selected.forEach(option => removeStringFilter(filter.key, option))
    apply()
  }

  return [
    ...appliedDatetimeFilters.map(filter => (
      <FilterItem
        editButton={<FilterItemDropdownDatetime filter={filter} />}
        key={filter.key}
        name="Feedback details"
        onDelete={() => removeDatetimeFilterOptions(filter)}
      />
    )),
    ...appliedNumericFilters.map(filter => (
      <FilterItem
        editButton={<FilterItemDropdownNumeric filter={filter} />}
        key={filter.key}
        name="Feedback details"
        onDelete={() => removeNumericFilterOptions(filter)}
      />
    )),
    ...appliedStringFilters.map(filter => (
      <FilterItem
        editButton={<FilterItemDropdownString filter={filter} />}
        key={filter.key}
        name="Feedback details"
        onDelete={() => removeStringFilterOptions(filter)}
      />
    ))
  ]
}

export default AppliedFeedbackDetailFilters
