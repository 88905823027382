import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'

export const ParamsContainer = styled(FlexContainer, {
  flexDirection: 'column',
  padding: '20px 18px',
  paddingLeft: 28,
  gap: 8,
  overflow: 'auto'
})

export const ParamsInputContainer = styled(FlexContainer, {
  flexDirection: 'column',
  gap: 4,
  width: '100%',
  maxWidth: 260,
  marginBottom: 4,

  '& > input': {
    width: 'auto'
  },

  variants: {
    maxWidth: {
      default: {},
      large: {
        maxSize: 540
      }
    }
  },

  defaultVariants: {
    maxWidth: 'default'
  }
})

export const SliderContainer = styled(FlexContainer, {
  alignItems: 'center',
  gap: 8,
  boxSizing: 'border-box'
})

export const ParamsButtonContainer = styled(FlexContainer, {
  maxWidth: 260,
  gap: 6,
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: 10,

  variants: {
    maxWidth: {
      default: {},
      large: {
        maxSize: 540
      }
    }
  },

  defaultVariants: {
    maxWidth: 'default'
  }
})
