const copyHTMLToClipboard = (element?: HTMLElement | null) => {
  if (!element) return

  const range = document.createRange()
  range.selectNodeContents(element)

  const selection = window.getSelection()

  selection?.removeAllRanges()
  selection?.addRange(range)

  // use deprecated execCommand because it preserve formatting like tables to past in documents
  // cant find a workaround using clipboard api
  document.execCommand('copy')
  selection?.removeAllRanges()
}

export default copyHTMLToClipboard
