import Popover from '@/components/atoms/popover'
import { Fragment, memo, useState } from 'react'
import * as S from './KeywordTopicPopover.styles'
import { FeedbackTopicChipType } from './FeedbackItem.types'
import FlexContainer from '@/components/atoms/flex-container'
import { Close } from '@radix-ui/react-popover'
import useToastMessageStore from '@/store/useToastMessageStore'
import { Intention } from '@/types/reasons'
import { IntentionWithText } from '@/components/atoms/intention'
import { IconContext } from '@phosphor-icons/react'
import { useFeedbacks } from '@/hooks/feedback/useFeedbacks'
import FeedbackService from '@/services/FeedbackService'
import useLetterCase from '@/hooks/useLetterCase'
import { INTENTION_LIST } from '@/utils/intention'
import useSegment from '@/hooks/useSegment'
import useLogging from '@/hooks/useLogging'

interface Props {
  children: JSX.Element
  topic: FeedbackTopicChipType
  feedbackId: string
  feedbackSourceId: string
  defaultOpen?: boolean
  disabled: boolean
}

const ChangeIntention = ({
  children,
  topic,
  defaultOpen,
  feedbackId,
  disabled,
  ...props
}: Props) => {
  const { track } = useSegment()

  const [open, setOpen] = useState(defaultOpen)
  const onOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      track('feedback_user_topic_detail-edit-intention')
    }
    setOpen(isOpen)
  }

  const addToast = useToastMessageStore(state => state.addToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const { logException } = useLogging({ context: 'change-intention' })

  const { updateIntentionAllFeedbackStores } = useFeedbacks()

  const { capitalizeFirst } = useLetterCase()

  const topicIntention = topic.intentions[0]

  const updateIntention = (newIntention: Intention) => async () => {
    const isSameIntention = topicIntention === newIntention

    if (isSameIntention) {
      addToast({ text: 'Intention already applied.', status: 'default' })
      return
    }

    const removeToast = addLoadingToast({
      text: 'Applying new intention to topic...'
    })

    try {
      const { keywordHash, keywordText } = topic
      await FeedbackService.updateIntention({
        feedbackId,
        keywordHash,
        keywordText,
        intentions: [capitalizeFirst(newIntention)]
      })

      updateIntentionAllFeedbackStores(feedbackId, topic.keywordHash, newIntention)

      removeToast()
      addSuccessToast({
        text: 'Topic intention was edited successfully.'
      })
    } catch (error) {
      removeToast()
      const message = 'Failed to edit topic intention.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  }

  if (disabled) return children

  return (
    <Popover
      active={false}
      buttonChildren={children}
      customButton
      defaultOpen={defaultOpen}
      iconButtonCss={{ padding: 0, height: 'auto' }}
      modal
      onOpenChange={onOpenChange}
      open={open}
      text
      {...props}
    >
      <S.Container changeSentiment>
        <FlexContainer direction="column">
          <IconContext.Provider value={{ size: 16, weight: 'fill' }}>
            {INTENTION_LIST.map(intention => (
              <Fragment key={intention}>
                <Close asChild key={intention} onClick={updateIntention(intention)}>
                  <IntentionWithText
                    css={{ ...S.changeItemCss }}
                    gap="xxxs"
                    intention={intention}
                    textProps={{
                      fontSize: 'micro',
                      lineHeight: 'lg',
                      fontWeight: topicIntention === intention ? 'bold' : 'regular'
                    }}
                  />
                </Close>
                <S.SentimentItemSeparator />
              </Fragment>
            ))}
          </IconContext.Provider>
        </FlexContainer>
      </S.Container>
    </Popover>
  )
}

export default memo(ChangeIntention)
