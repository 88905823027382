import { ReactNode } from 'react'
import * as HoverCard from '@radix-ui/react-hover-card'
import { AnalyticsMetrics } from '@/types/analytics'
import { Smiley, SmileyMeh, SmileySad } from '@phosphor-icons/react'
import FrequencyBar from './FrequencyBar'
import { MetricItem, TooltipCardItem, TooltipHoverCard } from './AnalyticsTooltip.styles'
import AnalyticsTooltipHeader from './AnalyticsTooltipHeader'

interface SentimentsTooltipProps extends AnalyticsMetrics {
  children: ReactNode
  title: string
  totalPercentage?: number
  align?: 'end' | 'center' | 'start'
  showHeader?: boolean
  openDelay?: number
  closeDelay?: number
  sideOffset?: number
}

const SentimentsTooltip = ({
  title,
  children,
  align = 'end',
  showHeader = true,
  openDelay,
  closeDelay,
  sideOffset = 8,
  ...metrics
}: SentimentsTooltipProps) => (
  <HoverCard.Root closeDelay={closeDelay} openDelay={openDelay}>
    <HoverCard.Trigger asChild>{children}</HoverCard.Trigger>
    <HoverCard.Portal>
      <TooltipHoverCard align={align} sideOffset={sideOffset}>
        {showHeader && (
          <AnalyticsTooltipHeader
            title={title}
            totalCount={metrics.feedbackCount}
            totalPercentage={metrics.totalPercentage}
          />
        )}
        <TooltipCardItem>
          <MetricItem align="left" type="positive">
            <Smiley size={14} />
            <span>Positive</span>
          </MetricItem>
          <FrequencyBar
            hideNumber
            percentage={metrics.positivePercentage}
            size="small"
            type="positive"
          />
          <MetricItem type="number">{metrics.positiveCount}</MetricItem>
          <MetricItem type="percentage">{Math.round(metrics.positivePercentage)}%</MetricItem>
        </TooltipCardItem>

        <TooltipCardItem>
          <MetricItem align="left" type="negative">
            <SmileySad size={14} />
            <span>Negative</span>
          </MetricItem>
          <FrequencyBar
            hideNumber
            percentage={metrics.negativePercentage}
            size="small"
            type="negative"
          />

          <MetricItem type="number">{metrics.negativeCount}</MetricItem>
          <MetricItem type="percentage">{Math.round(metrics.negativePercentage)}%</MetricItem>
        </TooltipCardItem>

        <TooltipCardItem>
          <MetricItem align="left" type="neutral">
            <SmileyMeh size={14} />
            <span>Neutral</span>
          </MetricItem>
          <FrequencyBar
            hideNumber
            percentage={metrics.neutralPercentage}
            size="small"
            type="neutral"
          />
          <MetricItem type="number">{metrics.neutralCount}</MetricItem>
          <MetricItem type="percentage">{Math.round(metrics.neutralPercentage)}%</MetricItem>
        </TooltipCardItem>
      </TooltipHoverCard>
    </HoverCard.Portal>
  </HoverCard.Root>
)

export default SentimentsTooltip
