import useEnumAdvancedFilter from '@/hooks/advancedFilters/useEnumAdvancedFilter'
import { EnumFilterType } from '@/types/filters/AdvancedFilters'
import { capitalizeFirst } from '@/utils/letterCase'
import { useState } from 'react'
import { FilterItemDropdown } from '../../AppliedFilters/FilterItem'
import SearchInputForFilters from '../../SearchInputFilter'
import StringFilterContent from '../../FiltersSidebar/StringGenericFilter/StringFilterContentNew'
import FiltersSkeleton from '../../FiltersSidebar/AddFilterMenu/FiltersSkeleton'
import { getFeedbackKindPrettyName } from '@/utils/feedback'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

interface Props {
  title: string
  name: string
  path: string
  value: string[]
  index: number
  type: EnumFilterType
  disabled?: boolean
}

const EnumFilterApplied = ({ title, name, path, value, index, type, disabled = false }: Props) => {
  const [open, setOpen] = useState(false)

  const { isLoading, isChecked, selectOption, options, update } = useEnumAdvancedFilter({
    name,
    path,
    enableQuery: open,
    type,
    index,
    initialValue: value
  })

  const [searchText, setSearchText] = useState('')
  const filteredOptions = options.filter(option =>
    option.toLowerCase().includes(searchText.toLowerCase())
  )
  const { t } = useTranslation()

  const count = value.length
  const formatter = name === 'kind' ? getFeedbackKindPrettyName : capitalizeFirst
  const valueTextArray = value.map(v => `<b>${formatter(v)}</b>`).join(t('or'))
  const text = i18n.t('isValueTextArray', { valueTextArray })

  return (
    <FilterItemDropdown
      buttonDisabled={disabled}
      content={count}
      contentType="count"
      disabled={isLoading || disabled}
      dropdown={
        <>
          <SearchInputForFilters
            css={{ my: '$micro' }}
            onChange={e => setSearchText(e.currentTarget.value)}
            placeholder="Search"
            size="small"
            small
            value={searchText}
          />
          <StringFilterContent
            isChecked={isChecked}
            options={filteredOptions}
            selectOption={selectOption}
            textFormatter={formatter}
          />
          {isLoading && <FiltersSkeleton />}
        </>
      }
      onApply={update}
      onOpenChange={setOpen}
      open={open}
      title={title}
      tooltip={text}
    />
  )
}

export default EnumFilterApplied
