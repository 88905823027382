import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'

import { useFiltersStore } from '@/store'
import { FilterNumeric } from '@/types/filters/Filters'
import FilterItem from '../FilterItem'
import useLetterCase from '@/hooks/useLetterCase'
import FilterItemDropdownCustomerRecordsNumeric from '../FilterItem/FilterItemDropdownCustomerRecordsNumeric'
import { useFeedFiltersStore } from '@/store/useFiltersStore'

interface Props {
  newFeed?: boolean
}

function AppliedCustomerUsersNumericFilters({ newFeed = false }: Props = { newFeed: false }) {
  const { snakeToTitle } = useLetterCase()

  const useStore = newFeed ? useFeedFiltersStore : useFiltersStore

  const apply = useStore(state => state.applyUsers)
  const numericFilters = useStore(state => state.usersNumericFilters, shallow)
  const removeNumericFilter = useStore(state => state.removeUsersNumericFilter)

  const appliedNumericFilters = useMemo(() => {
    return numericFilters.filter(filter => filter.option !== 'all')
  }, [numericFilters])

  function removeNumericFilterOptions(filter: FilterNumeric) {
    removeNumericFilter(filter.key)
    apply()
  }

  return [
    ...appliedNumericFilters.map(filter => (
      <FilterItem
        editButton={
          <FilterItemDropdownCustomerRecordsNumeric filter={filter} recordType="customerUsers" />
        }
        key={filter.key}
        name={`Users/${snakeToTitle(filter.name)}`}
        onDelete={() => removeNumericFilterOptions(filter)}
      />
    ))
  ]
}

export default AppliedCustomerUsersNumericFilters
