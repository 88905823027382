import { FilterItem } from '@/types/filters/Filters'
import { CustomerRecordType } from '@/types/manage-customers/CustomerRecordsFilters'
import { ReactNode } from 'react'
import useCustomerRecordsBooleanGenericFilter from './useCustomerRecordsBooleanFilter'
import FilterSubMenu from '../../AddFilterMenu/FilterSubMenu'
import { BooleanFilterContent } from '../../BooleanGenericFilter'

interface Props {
  filter: FilterItem
  icon?: ReactNode
  recordType: CustomerRecordType
  newFeed?: boolean
}

const CustomerRecordsBooleanFilterSubmenu = ({
  filter,
  icon,
  recordType,
  newFeed = false
}: Props) => {
  const { onApply, onOptionChange, selectedOption, isDisabled } =
    useCustomerRecordsBooleanGenericFilter({
      filterKey: filter.key,
      type: filter.type,
      name: filter.name,
      recordType,
      newFeed
    })

  return (
    <FilterSubMenu icon={icon} isDisabled={isDisabled} onApply={onApply} title={filter.displayName}>
      <BooleanFilterContent onOptionChange={onOptionChange} selectedOption={selectedOption} />
    </FilterSubMenu>
  )
}

export default CustomerRecordsBooleanFilterSubmenu
