import { ArrowFatLinesRight } from '@phosphor-icons/react'

import Bone from '@/components/atoms/bone'
import FlexContainer from '@/components/atoms/flex-container'
import { TableCell } from '@/components/atoms/table'

function FieldsMatchedRowSkeleton() {
  return (
    <tr>
      <TableCell>
        <Bone css={{ height: 42.1, width: 324 }} />
      </TableCell>

      <TableCell>
        <FlexContainer justifyContent="center">
          <ArrowFatLinesRight size={24} weight="fill" />
        </FlexContainer>
      </TableCell>

      <TableCell>
        <Bone css={{ height: 16.1, width: 80 }} />
      </TableCell>

      <TableCell aria-hidden />
    </tr>
  )
}

export default FieldsMatchedRowSkeleton
