import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import { isAreaOfInterestFilter } from '@/utils/areaOfInterest'
import { useMemo } from 'react'

export type InterestAreaFilterMode = 'onlyAreaOfInterestFilters' | 'onlyDefaultFilters' | 'all'

interface Params {
  mode: InterestAreaFilterMode
}

const useInterestAreaFilterSpliter = ({ mode }: Params) => {
  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)

  const _stringFilters = useFeedFiltersStore(state => state.stringFilters)
  const _datetimeFilters = useFeedFiltersStore(state => state.datetimeFilters)
  const _numericFilters = useFeedFiltersStore(state => state.numericFilters)
  const _textFilters = useFeedFiltersStore(state => state.textFilters)
  const _booleanFilters = useFeedFiltersStore(state => state.booleanFilters)

  const stringFilters = useMemo(() => {
    if (mode === 'all' || !currentInterestArea) return _stringFilters

    return _stringFilters.filter(filter =>
      mode === 'onlyAreaOfInterestFilters'
        ? isAreaOfInterestFilter({
            key: filter.key,
            values: filter.selected,
            areaOfInterest: currentInterestArea
          })
        : !isAreaOfInterestFilter({
            key: filter.key,
            values: filter.selected,
            areaOfInterest: currentInterestArea
          })
    )
  }, [_stringFilters, mode, currentInterestArea])

  const datetimeFilters = useMemo(() => {
    if (mode === 'all' || !currentInterestArea) return _datetimeFilters

    return _datetimeFilters.filter(filter => {
      const values = [filter.value?.start.toString() || '', filter.value?.end.toString() || '']

      return mode === 'onlyAreaOfInterestFilters'
        ? isAreaOfInterestFilter({
            key: filter.key,
            values,
            areaOfInterest: currentInterestArea
          })
        : !isAreaOfInterestFilter({
            key: filter.key,
            values,
            areaOfInterest: currentInterestArea
          })
    })
  }, [_datetimeFilters, mode, currentInterestArea])

  const numericFilters = useMemo(() => {
    if (mode === 'all' || !currentInterestArea) return _numericFilters

    return _numericFilters.filter(filter =>
      mode === 'onlyAreaOfInterestFilters'
        ? isAreaOfInterestFilter({
            key: filter.key,
            values: filter.value,
            areaOfInterest: currentInterestArea
          })
        : !isAreaOfInterestFilter({
            key: filter.key,
            values: filter.value,
            areaOfInterest: currentInterestArea
          })
    )
  }, [_numericFilters, mode, currentInterestArea])

  const textFilters = useMemo(() => {
    if (mode === 'all' || !currentInterestArea) return _textFilters

    return _textFilters.filter(filter =>
      mode === 'onlyAreaOfInterestFilters'
        ? isAreaOfInterestFilter({
            key: filter.key,
            values: filter.value,
            areaOfInterest: currentInterestArea
          })
        : !isAreaOfInterestFilter({
            key: filter.key,
            values: filter.value,
            areaOfInterest: currentInterestArea
          })
    )
  }, [_textFilters, mode, currentInterestArea])

  const booleanFilters = useMemo(() => {
    if (mode === 'all' || !currentInterestArea) return _booleanFilters

    return _booleanFilters.filter(filter =>
      mode === 'onlyAreaOfInterestFilters'
        ? isAreaOfInterestFilter({
            key: filter.key,
            values: filter.value,
            areaOfInterest: currentInterestArea
          })
        : !isAreaOfInterestFilter({
            key: filter.key,
            values: filter.value,
            areaOfInterest: currentInterestArea
          })
    )
  }, [_booleanFilters, mode, currentInterestArea])

  return { stringFilters, datetimeFilters, numericFilters, textFilters, booleanFilters }
}

export default useInterestAreaFilterSpliter
