import { useQuery } from '@tanstack/react-query'
import useAccountsStore from '@/store/useAccountsStore'
import { useMemo } from 'react'
import { snakeToTitle } from '@/utils/letterCase'
import { FilterItem } from '@/types/filters/Filters'
import { CustomerRecordsFieldTypes } from '@/types/manage-customers/CustomerRecordsFilters'
import FieldsService from '@/services/FieldsService'
import { FieldsRequests } from '@/types/fields'

const parseName: Record<string, string> = {
  name: 'Account name',
  ingested_id: 'Account id'
}

const REMOVED_FIELDS = ['ingested_at']

export const mapAccountsTypes: Record<CustomerRecordsFieldTypes, string> = {
  list: 'accounts-enum',
  enum: 'accounts-enum',
  id: 'accounts-id',
  unique: 'accounts-id',
  number: 'accounts-number',
  date: 'accounts-date',
  datetime: 'accounts-datetime',
  boolean: 'accounts-boolean',
  money: 'accounts-number'
}

const formatFilterTitle = (field: FieldsRequests.FieldsResponse[number]): string => {
  const parsedName = parseName[field.name]
  if (parsedName) return parsedName

  const splittedFieldName = field.name.split('.')
  const filtersFormatted = splittedFieldName.map(name => snakeToTitle(name)).join(' ')

  return filtersFormatted
}

interface UseFiltersConfig {
  refetch?: boolean
}

const defaultConfig: UseFiltersConfig = {
  refetch: true
}

const useAccountsFilters = ({ refetch = true }: UseFiltersConfig = defaultConfig) => {
  const hasAccounts = useAccountsStore(state => state.hasAccounts)

  const { data, isLoading } = useQuery({
    queryKey: ['filters-accounts-fields'],
    queryFn: async queryParams => FieldsService.fields('audience_account', queryParams),
    refetchOnMount: refetch,
    enabled: hasAccounts
  })

  const fields: FilterItem[] = useMemo(() => {
    if (!data) return []
    const [error, accountsFieldsData] = data
    if (error) return []
    if (!accountsFieldsData) return []

    return accountsFieldsData
      .filter(field => !REMOVED_FIELDS.includes(field.name))
      .map(filter => ({
        key: filter.name,
        type: mapAccountsTypes[filter.type as CustomerRecordsFieldTypes],
        displayName: formatFilterTitle(filter),
        name: filter.name,
        path: filter.path
      }))
  }, [data])

  return {
    fields,
    isLoading,
    mapAccountsTypes
  }
}

export default useAccountsFilters
