import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import { FilterNode, GenericFilter } from '@/types/filters/AdvancedFilters'
import React, { useMemo } from 'react'
import EmptyFilters from '../EmptyFilters'
import useScrollFade from '@/hooks/useScrollFade'
import { ScrollAreaScrollbar, ScrollAreaThumb, ScrollAreaViewport } from '../../scroll-area'
import { ScrollAreaRoot } from '../AppliedFilters/AppliedFilters.styles'
import FlexContainer from '@/components/atoms/flex-container'
import AndSeparator from '../AndSeparator'
import AppliedAdvancedFilterItem from './AppliedAdvancedFilterItem'
import useFilterEntityStore from '@/store/useFiltersStore/useFilterEntityStore'
// import AppliedDateFilter from './AppliedDateFilter'

const AppliedAdvancedFilters = () => {
  const { filters, removeFilter } = useAdvancedFilters()
  const { viewportRef, fade, onScroll } = useScrollFade()

  const isEditing = useFilterEntityStore(state => state.editMode)

  const filterList = useMemo(() => {
    if (isEditing) return []
    if (filters.operator !== '$and') return []
    return (filters.value as (GenericFilter | FilterNode)[]).map(filter =>
      !filter.isFromEntity ? filter : null
    ) as (GenericFilter | FilterNode | null)[]
  }, [filters, isEditing])

  const onRemoveClick = (index: number) => {
    removeFilter({ index })
  }

  if (isEditing) return null

  if (filterList.length === 0) return <EmptyFilters />

  return (
    <ScrollAreaRoot fade={fade}>
      <ScrollAreaViewport onScroll={onScroll} ref={viewportRef}>
        <FlexContainer direction="column" gap="nano">
          {/* <AppliedDateFilter /> */}
          {filterList.map((filterItem, index) => (
            <React.Fragment key={index}>
              {index > 0 && filterItem ? <AndSeparator /> : null}
              <AppliedAdvancedFilterItem
                filter={filterItem}
                index={index}
                onRemoveClick={() => onRemoveClick(index)}
              />
            </React.Fragment>
          ))}
        </FlexContainer>
      </ScrollAreaViewport>
      <ScrollAreaScrollbar orientation="vertical">
        <ScrollAreaThumb />
      </ScrollAreaScrollbar>
    </ScrollAreaRoot>
  )
}

export default AppliedAdvancedFilters
