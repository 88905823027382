import { createStitches, CSS as StitchesCSS } from '@stitches/react'
import { colors, colorVariant } from './colors'
import { fontSizes, fontSizeVariant } from './fontSizes'
import { fontWeights, fontWeightsVariant } from './fontWeights'
import { lineHeights, lineHeightVariant } from './lineHeights'
import { radii, space } from './space'
import utils from './utils'

export { text, typefaceVariants } from './typography'
export type { TypeFaceOptions } from './typography'

export { colors, colorVariant }
export { fontSizeVariant }
export { fontWeightsVariant }
export { lineHeightVariant }

export const navigationBarWidth = 72
export const savedViewBarHeight = 40
export const topBarHeight = 60
export const topBarHomeHeight = 72
export const feedContainerPadding = 32

// export const mainWidth = 864
// export const rightBarWidth = 500

export const { styled, css, globalCss, keyframes, config, theme } = createStitches({
  prefix: 'birdie',
  theme: {
    colors,
    fontSizes,
    fontWeights,
    lineHeights,
    radii,
    shadows: {
      softShadow: '0px 3px 18px 0px rgba(30, 30, 30, 0.12)',
      searchBarHover: '0px 4px 8px rgba(33, 37, 41, 0.04)',
      searchBarFocus: '0px 0px 6px rgba(33, 37, 41, 0.15)',
      leftBlueBorder: `inset 2px 0 0 0 ${colors.pajaritoBrighter}`,
      componentShadowLarge: '0px 4px 8px rgba(0, 0, 0, 0.3), 0px 12px 24px rgba(0, 0, 0, 0.2)',
      soft: '0px 4px 16px rgba(30, 30, 30, 0.16)',
      medium: '0px 12px 24px rgba(30, 30, 30, 0.16)'
    },
    sizes: {
      navigationBarWidth: navigationBarWidth + 'px',
      topBarHeight: topBarHeight + 'px',
      feedContainerPadding: feedContainerPadding + 'px',
      ...space
    },
    space: {
      ...space,
      navigationBarWidth: navigationBarWidth + 'px',
      topBarHeight: topBarHeight + 'px',
      feedContainerPadding: feedContainerPadding + 'px'
    },
    fonts: {
      default: 'Lexend'
    },
    letterSpacings: {
      default: '0%'
    }
  },
  media: {
    bp1: '(max-width: 640px)',
    bp2: '(max-width: 768px)',
    bp3: '(max-width: 1024px)',
    bp4: '(max-width: 1440px)',
    bp5: '(max-width: 1600px)',
    // bp4: '(max-width: 1439px)',
    short: '(max-height: 768px)'
  },
  utils
})

export const SCROLLBAR_SIZE = 4

export const globalStyles = globalCss({
  '*': {
    margin: 0,
    padding: 0
  },
  body: {
    display: 'flex',
    fontFamily: "'Lexend', sans-serif",
    '-webkit-font-smoothing': 'antialiased',
    width: '100vw',
    height: '100vh',
    // overflowX: 'hidden',
    overflow: 'hidden',
    color: '$neutralLowPure'
  },

  '.intercom-namespace': {
    '.intercom-messenger-frame': {
      transformOrigin: 'left bottom !important'
    }
  },

  '.intercom-lightweight-app-messenger': {
    transformOrigin: 'left bottom !important'
  },

  '.scroll-on-hover-container': {
    visibility: 'hidden',

    '&:hover, &:focus': {
      visibility: 'visible'
    }
  },

  '.scroll-on-hover-child': {
    visibility: 'visible'
  },

  '::-webkit-scrollbar': {
    width: SCROLLBAR_SIZE,
    height: SCROLLBAR_SIZE + 2
  },
  '::-webkit-scrollbar-thumb': {
    background: '$neutralHighPure',
    borderRadius: SCROLLBAR_SIZE
  },

  '::-webkit-scrollbar-track': {
    borderRadius: SCROLLBAR_SIZE
  }
})

export type CSS = StitchesCSS<typeof config>

type TextAlignVariant = 'left' | 'center' | 'right'
export const textAlignVariant: Record<TextAlignVariant, CSS> = {
  left: { textAlign: 'left' },
  center: { textAlign: 'center' },
  right: { textAlign: 'right' }
}
