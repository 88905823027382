import OnboardingSuccessDialog from '@/components/atoms/dialog/OnboardingSuccessDialog'
import { FullScreenLoader } from '@/components/atoms/loader'
import useScrollToTop from '@/hooks/useScrollToTop'
import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import NavigationBarTopicPlan from './navigation-bar/NavigationBarTopicPlan'
import TopBarTopicPlan from './top-bar/TopBarTopicPlan'
import { MainContainer } from './styles'
import PageErrorBoundary from '@/components/atoms/error-boundary/PageErrorBoundary'

function LayoutTopicPlan() {
  const { pathname } = useLocation()
  useScrollToTop([pathname])

  return (
    <React.Fragment>
      <NavigationBarTopicPlan />
      <TopBarTopicPlan />
      <MainContainer>
        <PageErrorBoundary>
          <Outlet />
        </PageErrorBoundary>
      </MainContainer>
      <FullScreenLoader />
      <OnboardingSuccessDialog />
    </React.Fragment>
  )
}

export default LayoutTopicPlan
