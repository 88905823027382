import { useChatFeedbackStore } from '@/store/useFeedbackStore'
import { useFeedbacks } from './useFeedbacks'
import { shallow } from 'zustand/shallow'
import { useFiltersStore, useKeywordsStore } from '@/store'
import useSelectedFilters from '../filters/useSelectedFilters'
import useChatStore from '@/store/useChatStore'
import useChat from '../useChat'
import { useCallback, useEffect } from 'react'
import useDidUpdateEffect from '../useDidUpdateEffect'
import { useLocation } from 'react-router-dom'
import { Feedback } from '@/types/feedbacks'

interface Params {
  disableAutomaticLoad?: boolean
}

const defaultParams: Params = { disableAutomaticLoad: false }

const useChatFeedback = ({ disableAutomaticLoad = false }: Params = defaultParams) => {
  const { feedbacks, isLoading, archive, unarchive, error } = useFeedbacks({
    archived: false,
    store: 'chat'
  })

  const resetKeywords = useKeywordsStore(state => state.reset)

  const { selectedFeedback } = useChatFeedbackStore(
    state => ({
      selectedFeedback: state.selectedFeedback
    }),
    shallow
  )
  const setSelectedFeedback = useChatFeedbackStore(state => state.setSelectedFeedback)

  const filters = useFiltersStore(
    state => ({
      dateRange: state.dateRange,
      orderBy: state.orderBy,
      search: state.search,
      filtersByURL: state.filtersByURL,
      accounts: Object.values(state.accountsStringFilters).flatMap(value => value.selected),
      accountsDatefilters: state.accountsDateFilters.filter(filter => filter.selected !== null),
      accountsNumericfilters: state.accountNumericFilters
        .filter(filter => filter.option !== 'all')
        .map(filter => ({ key: filter.key, value: filter.value, option: filter.option })),
      accountsBooleanFilters: state.accountBooleanFilters.filter(filter => filter.value !== null),
      users: Object.values(state.usersStringFilters).flatMap(value => value.selected),
      usersDatefilters: state.usersDateFilters.filter(filter => filter.selected !== null),
      usersNumericfilters: state.usersNumericFilters
        .filter(filter => filter.option !== 'all')
        .map(filter => ({ key: filter.key, value: filter.value, option: filter.option })),
      usersBooleanFilters: state.usersBooleanFilters.filter(filter => filter.value !== null)
    }),
    shallow
  )

  const {
    selectedNumericFilters,
    selectedDatetimeFilters,
    selectedStringFilters,
    selectedTopicFilters
  } = useSelectedFilters()

  const { fieldsToUse, model, randomFeedbacks } = useChatStore(
    state => ({
      fieldsToUse: state.fieldsToUse,
      model: state.model,
      randomFeedbacks: state.randomFeedbacks
    }),
    shallow
  )

  const { getChatFeedbacks } = useChat()

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    if (disableAutomaticLoad) {
      return
    }

    resetKeywords()
    if (feedbacks.length === 0) {
      getChatFeedbacks()
    }
  }, [])

  useDidUpdateEffect(
    () => {
      if (disableAutomaticLoad) {
        return
      }

      getChatFeedbacks()
    },
    [
      filters,
      selectedNumericFilters,
      selectedDatetimeFilters,
      selectedStringFilters,
      selectedTopicFilters,
      fieldsToUse,
      model,
      randomFeedbacks
    ],
    true
  )

  const loadMore = () => {
    feedbacks.length && getChatFeedbacks()
  }

  const getFeedbackItemKey = useCallback(
    (feedback: Feedback) => feedback.feedbackId + feedback.keywordTopicList.length,
    []
  )

  const { hash } = useLocation()

  const isHighlighted = (index: number) => {
    return hash.replace('#', '') === `${index}`
  }

  return {
    feedbacks,
    error,
    isLoading,
    selectedFeedback,
    getChatFeedbacks,
    loadMore,
    isHighlighted,
    getFeedbackItemKey,
    archive,
    unarchive,
    setSelectedFeedback
  }
}

export default useChatFeedback
