import i18n from '@/plugins/i18n/i18n'
import { FolderOpen, LightbulbFilament, Users } from '@phosphor-icons/react'

export type Entity = 'area' | 'segment' | 'opportunity'

export const entityMap: Record<Entity, { name: string; icon: JSX.Element }> = {
  area: { name: i18n.t('area'), icon: <FolderOpen /> },
  segment: { name: i18n.t('segment'), icon: <Users /> },
  opportunity: { name: i18n.t('opportunity'), icon: <LightbulbFilament /> }
}
