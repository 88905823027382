import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'

import useClassification from '@/hooks/useClassification'
import { useFiltersStore } from '@/store'
import { FilterTopicOption } from '@/types/filters/Filters'
import FilterItem, { FilterItemDropdownTopic } from '../FilterItem'
import { useTranslation } from 'react-i18next'

function AppliedProductAreaFilters() {
  const apply = useFiltersStore(state => state.apply)
  const productAreaThemes = useFiltersStore(state => state.productAreaThemes.selected, shallow)
  const productAreaTopics = useFiltersStore(state => state.productAreaTopics.selected, shallow)
  const setDraftProductArea = useFiltersStore(state => state.setDraftProductArea)
  const toggleUnclassifiedTopics = useFiltersStore(state => state.toggleUnclassifiedTopics)
  const unclassifiedTopics = useFiltersStore(state => state.unclassifiedTopics.selected, shallow)
  const { t } = useTranslation()

  const { themes, getTopicById } = useClassification()

  const appliedFilters = useMemo(() => {
    if (unclassifiedTopics.includes('PRODUCT_AREA')) {
      return [{ id: 'PRODUCT_AREA', text: t('unclassified') }] as FilterTopicOption[]
    }

    const _topics = productAreaTopics
      .map(topicId => {
        const topic = getTopicById(topicId)
        if (topic) {
          return { id: topic.topicId, text: topic.topicName } as FilterTopicOption
        }
        return null
      })
      .filter(Boolean) as FilterTopicOption[]

    const _themes = productAreaThemes
      .map(themeId => {
        const theme = themes.find(theme => theme.themeId === themeId)
        if (theme) {
          return { id: theme.themeId, text: theme.themeName } as FilterTopicOption
        }
        return null
      })
      .filter(Boolean) as FilterTopicOption[]

    return [..._themes, ..._topics]
  }, [productAreaTopics, productAreaThemes, unclassifiedTopics, themes, getTopicById, t])

  function onRemoveProductArea() {
    const hasUnclassified = appliedFilters.some(filter => filter.id === 'PRODUCT_AREA')

    if (hasUnclassified) {
      toggleUnclassifiedTopics('PRODUCT_AREA')
    } else {
      setDraftProductArea('theme', [])
      setDraftProductArea('topic', [])
    }

    apply()
  }

  return [
    appliedFilters.length > 0 ? (
      <FilterItem
        editButton={<FilterItemDropdownTopic category="PRODUCT_AREA" filter={appliedFilters} />}
        name="Product areas"
        onDelete={onRemoveProductArea}
        key="product-areas"
      />
    ) : null
  ].filter(Boolean) as JSX.Element[]
}

export default AppliedProductAreaFilters
