import { PropertyValue } from '@stitches/react'

const border = {
  /** border */
  bAll: (color: PropertyValue<'color'>) => ({
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: color
  }),
  bTop: (color: PropertyValue<'color'>) => ({
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: color
  }),
  bRight: (color: PropertyValue<'color'>) => ({
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
    borderRightColor: color
  }),
  bBottom: (color: PropertyValue<'color'>) => ({
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: color
  }),
  bLeft: (color: PropertyValue<'color'>) => ({
    borderLeftWidth: '1px',
    borderLeftStyle: 'solid',
    borderLeftColor: color
  })
}

const padding = {
  pAll: (value: PropertyValue<'padding'>) => ({
    padding: value
  }),
  pl: (value: PropertyValue<'paddingLeft'>) => ({
    paddingLeft: value
  }),
  pr: (value: PropertyValue<'paddingRight'>) => ({
    paddingRight: value
  }),
  pt: (value: PropertyValue<'paddingTop'>) => ({
    paddingTop: value
  }),
  pb: (value: PropertyValue<'paddingBottom'>) => ({
    paddingBottom: value
  }),
  px: (value: PropertyValue<'padding'>) => ({
    paddingLeft: value,
    paddingRight: value
  }),
  py: (value: PropertyValue<'padding'>) => ({
    paddingTop: value,
    paddingBottom: value
  })
}

const margin = {
  m: (value: PropertyValue<'margin'>) => ({
    margin: value
  }),
  ml: (value: PropertyValue<'marginLeft'>) => ({
    marginLeft: value
  }),
  mr: (value: PropertyValue<'marginRight'>) => ({
    marginRight: value
  }),
  mt: (value: PropertyValue<'marginTop'>) => ({
    marginTop: value
  }),
  mb: (value: PropertyValue<'marginBottom'>) => ({
    marginBottom: value
  }),
  mx: (value: PropertyValue<'margin'>) => ({
    marginLeft: value,
    marginRight: value
  }),
  my: (value: PropertyValue<'margin'>) => ({
    marginTop: value,
    marginBottom: value
  })
}

const borderRadius = {
  br: (value: PropertyValue<'borderRadius'>) => ({
    borderRadius: value
  }),
  brtl: (value: PropertyValue<'borderTopLeftRadius'>) => ({
    borderTopLeftRadius: value
  }),
  brtr: (value: PropertyValue<'borderTopRightRadius'>) => ({
    borderTopRightRadius: value
  }),
  brbl: (value: PropertyValue<'borderBottomLeftRadius'>) => ({
    borderBottomLeftRadius: value
  }),
  brbr: (value: PropertyValue<'borderBottomRightRadius'>) => ({
    borderBottomRightRadius: value
  })
}

const background = {
  bc: (value: PropertyValue<'backgroundColor'>) => ({
    backgroundColor: value
  })
}

const utils = {
  ...border,
  ...padding,
  ...margin,
  ...borderRadius,
  ...background,

  // sizes
  size: (value: PropertyValue<'width' | 'height'>) => ({
    width: value,
    height: value
  }),
  maxSize: (value: PropertyValue<'width' | 'height'>) => ({
    maxWidth: value,
    maxHeight: value
  }),
  minSize: (value: PropertyValue<'width' | 'height'>) => ({
    minWidth: value,
    minHeight: value
  }),
  absoluteSize: (value: PropertyValue<'width' | 'height'>) => ({
    maxWidth: value,
    maxHeight: value,
    minWidth: value,
    minHeight: value
  })
}

export default utils
