import FilterSubMenu from '../AddFilterMenu/FilterSubMenu'

import { ReactNode, useState } from 'react'
import { NumericRangeOption } from '@/types/filters/Filters'
import NumericFilterContent from './NumericFilterContent'
import useNumericGenericFilter from './useNumericGenericFilterNew'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { isAreaOfInterestFilter } from '@/utils/areaOfInterest'
import i18n from '../../../../../plugins/i18n/i18n'

const defaultLabels: Record<NumericRangeOption, string> = {
  all: i18n.t('allValues'),
  between: i18n.t('range'),
  is: i18n.t('exactlyValue'),
  null: i18n.t('emptyValues')
}

interface Props {
  title: string
  previousTitle?: string
  name: string
  filterKey: string
  labels?: Record<NumericRangeOption, string>
  icon?: ReactNode
}

const NumericFilterSubMenu = ({
  title,
  previousTitle,
  name,
  filterKey,
  icon,
  labels = defaultLabels
}: Props) => {
  const [open, setOpen] = useState(false)

  const {
    exactValue,
    exactValueText,
    isDisabled,
    isExactValueOpen,
    isLoading,
    isRangeOpen,
    max,
    min,
    onApply,
    onOptionChange,
    onRangeChange,
    onValueChange,
    rangeText,
    rangeValue,
    selectedOption
  } = useNumericGenericFilter({ filterKey, name, enabled: open })

  const areaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)

  if (
    isAreaOfInterestFilter({
      key: filterKey,
      values: rangeValue,
      areaOfInterest
    })
  )
    return <></>

  return (
    <FilterSubMenu
      icon={icon}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onApply={onApply}
      onOpenChange={setOpen}
      open={open}
      previousTitle={previousTitle}
      title={title}
    >
      <NumericFilterContent
        exactValue={exactValue}
        exactValueText={exactValueText}
        isExactValueOpen={isExactValueOpen}
        isRangeOpen={isRangeOpen}
        labels={labels}
        max={max}
        min={min}
        onOptionChange={onOptionChange}
        onRangeChange={onRangeChange}
        onValueChange={onValueChange}
        rangeText={rangeText}
        rangeValue={rangeValue}
        selectedOption={selectedOption}
      />
    </FilterSubMenu>
  )
}

export default NumericFilterSubMenu
