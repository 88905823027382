import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { useMemo } from 'react'
import FullTopicSearchResultItem from './FullTopicSearchResultItem'
import useAllTopicsSearch from '../hooks/useAllTopicsSearch'
import { TopicData } from '@/types/classification'
import { TopicGroupResultItem, AllTopicSearchResult } from './AllTopicSearchResults.types'
import { useTranslation } from 'react-i18next'

export interface FullTopicSearchResultsProps {
  searchQuery: string
}

const FullTopicSearchResults = ({ searchQuery }: FullTopicSearchResultsProps) => {
  const { t } = useTranslation()

  const { topics, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading, isShowMoreDisabled } =
    useAllTopicsSearch({ searchQuery })

  const normalizedResults = useMemo(() => {
    const ungroupedTopics: TopicData[] = []

    const _normalizedTopicGroups = [] as TopicGroupResultItem[]

    topics.forEach(topic => {
      if (!topic.themeList?.length) {
        ungroupedTopics.push(topic)
        return
      }

      topic.themeList.forEach(theme => {
        const topicGroupIndex = _normalizedTopicGroups.findIndex(
          topicGroup => topicGroup.id === theme.themeId
        )
        if (topicGroupIndex === -1) {
          _normalizedTopicGroups.push({
            id: theme.themeId,
            name: theme.themeName,
            category: theme.themeCategory,
            childTopics: [topic]
          })
        } else {
          _normalizedTopicGroups[topicGroupIndex].childTopics.push(topic)
        }
      })
    })

    return { topicGroups: _normalizedTopicGroups, ungroupedTopics } as AllTopicSearchResult
  }, [topics])

  return (
    <FlexContainer css={{ mt: '$nano' }} direction="column" gap={'xxxs'}>
      <Text as="p" fontSize="micro">
        {t('showingResultsFor', { searchQuery })}
      </Text>
      <FullTopicSearchResultItem
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        isShowMoreDisabled={isShowMoreDisabled}
        result={normalizedResults}
      />
    </FlexContainer>
  )
}

export default FullTopicSearchResults
