import { create } from 'zustand'
import { CustomField } from '@/types/integrations'
import CustomFieldsService from '@/services/CustomFieldsServices'
import useUserStore from './useUserStore'

interface IntegrationState {
  customFields: CustomField[]
  isLoading: boolean
  loadCustomFields(options?: { concat?: boolean }): Promise<void>
  removeCustomField(fieldId: string): Promise<void>
  updateCustomField(id: string, properties: Record<string, unknown>): Promise<void>
}

const useCustomFieldsStore = create<IntegrationState>((set, get) => ({
  customFields: [],
  isLoading: false,

  async loadCustomFields(options = {}) {
    try {
      set({ isLoading: true })

      const organizationId = useUserStore.getState().currentUser?.organization_id
      if (!organizationId) throw new Error('organization_id is undefined')

      const data = await CustomFieldsService.listCustomFields(organizationId)

      set(state => ({ customFields: options.concat ? [...state.customFields, ...data] : data }))
    } finally {
      set({ isLoading: false })
    }
  },

  async removeCustomField(fieldId) {
    await CustomFieldsService.deleteCustomField(fieldId)
    set(state => ({
      customFields: state.customFields.filter(field => field.id !== fieldId)
    }))
  },

  async updateCustomField(id, properties) {
    const { customFields } = get()
    const original = customFields.find(field => field.id === id)

    set(state => ({
      customFields: state.customFields.map(field => {
        return field.id === id ? { ...field, ...properties } : field
      })
    }))

    try {
      const updatedCustomField = await CustomFieldsService.updateCustomField({ id, ...properties })

      set(state => ({
        customFields: state.customFields.map(field => {
          return field.id === id ? updatedCustomField : field
        })
      }))
    } catch {
      if (!original) return

      set(state => ({
        customFields: state.customFields.map(field => {
          return field.id === id ? original : field
        })
      }))
    }
  }
}))

export default useCustomFieldsStore
