import useFeedbackSearch from '@/hooks/feedback/new/useFeedbackSearch'
import FeedError from '../../FeedError'
import ScrollLoader from '../../ScrollLoader'
import NoResults from '../../NoResults'
import InfiniteScroll from 'react-infinite-scroll-component'
import FlexContainer from '@/components/atoms/flex-container'
import { FeedbackListInnerContainer, feedbackItemCSS } from '../../feedbacks/Feedbacks.styles'
import FeedbackItem from './feedback-item/FeedbackItem'
import FeedbackDetails from './feedback-details/FeedbackDetails'
import { Suspense, lazy, useState } from 'react'
import { NewFeedback } from '@/types/feedbacks/Feedback'

const FeedbackConversation = lazy(() => import('./feedback-conversation/FeedbackConversation'))

function FeedbackList() {
  const {
    feedbackList,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    error,
    selectedFeedback,
    setSelectedFeedback
  } = useFeedbackSearch()

  const [messagesOpen, setMessagesOpen] = useState<NewFeedback | null>(null)

  function openMessagesModal(feedback: NewFeedback) {
    // TODO: track
    setMessagesOpen(feedback)
  }

  function onMessagesModalClose() {
    setMessagesOpen(null)
  }

  if (error && feedbackList.length === 0) {
    return <FeedError />
  }

  if (isLoading) {
    return <ScrollLoader />
  }

  if (feedbackList.length === 0) {
    return <NoResults archived={false} />
  }

  return (
    <>
      <InfiniteScroll
        dataLength={feedbackList.length}
        endMessage={<FlexContainer css={{ mb: 96 }} />}
        hasMore={hasNextPage ?? false}
        loader={isFetchingNextPage ? <ScrollLoader /> : ''}
        next={fetchNextPage}
        scrollThreshold="250px"
        scrollableTarget="scroll-feedback"
      >
        <FeedbackListInnerContainer>
          {feedbackList.map(item => (
            <FeedbackItem
              key={item.id}
              {...item}
              css={{
                ...feedbackItemCSS,
                '&:first-of-type': {
                  mt: '$xxs'
                }
              }}
              onMessagesClick={() => openMessagesModal(item)}
              onSelectFeedback={() => setSelectedFeedback(item)}
            />
          ))}
        </FeedbackListInnerContainer>
      </InfiniteScroll>
      <FeedbackDetails feedback={selectedFeedback} onClose={() => setSelectedFeedback(null)} />

      <Suspense>
        {messagesOpen && (
          <FeedbackConversation feedback={messagesOpen} onOpenChange={onMessagesModalClose} />
        )}
      </Suspense>
    </>
  )
}

export default FeedbackList
