import {
  FeedbackDate,
  FeedbackHeader,
  FeedbackRating as FeedbackRatingContainer,
  FeedbackType,
  Group
} from '@/components/molecules/feedback-item/FeedbackItem.styles'
import { FeedbackItemProps } from './FeedbackItem.types'
import { dateShortMonthFormat } from '@/utils/date'
import { FeedbackKindName, ReviewFields } from '@/types/feedbacks/FeedbackKindFields'
import OptionsMenu, { OptionsMenuItem } from '@/components/atoms/options-menu'
import { ListDashes, Star, PushPin, X, ArrowRight } from '@phosphor-icons/react'
import { colors } from '@/theme'
import FlexContainer from '@/components/atoms/flex-container'
import useUser from '@/hooks/useUser'
import IconButton from '@/components/atoms/icon-button'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const SATISFACTION_KINDS: FeedbackKindName[] = ['csat', 'nps', 'review']
const FeedbackRating = (props: FeedbackItemProps) => {
  if (!SATISFACTION_KINDS.includes(props.kind.name)) {
    return <></>
  }

  return (
    <FeedbackRatingContainer>
      : {(props.kind.fields as ReviewFields).rating}
      {props.kind.name === 'review' && <Star size={16} weight="fill" />}
    </FeedbackRatingContainer>
  )
}

const FeedbackItemHeader = (props: FeedbackItemProps) => {
  const { t } = useTranslation()
  const { userPermissions } = useUser()
  const isManager = userPermissions.areas.includes('manager')

  const feedackType = props.sourceAlias.sourceAliasPrettyName

  const menuOptions = useMemo(() => {
    const options: OptionsMenuItem[] = [
      {
        text: t('details'),
        icon: <ListDashes color={colors.neutralLowPure} size={16} />,
        onClick: () => {
          props.onSelectFeedback?.()
        }
      }
    ]

    if (props.isOpportunityFeedback && isManager) {
      options.push(
        {
          text: t('moveTo'),
          icon: <ArrowRight />,
          onClick: () => {
            props.onClickMoveTo?.()
          }
        },
        {
          text: t('removeInaccurate'),
          icon: <X />,
          onClick: () => {
            props.onRemoveFeedback?.()
          }
        }
      )
    }

    if (props.isAreaFeedback && !props.isOpportunityFeedback && isManager) {
      options.push(
        {
          text: t('moveTo'),
          icon: <ArrowRight />,
          onClick: () => {
            props.onClickMoveTo?.()
          }
        },
        {
          text: t('removeInaccurate'),
          icon: <X />,
          onClick: () => {
            props.onRemoveFeedback?.()
          }
        }
      )
    }

    return options
  }, [
    isManager,
    t,
    props.isOpportunityFeedback,
    props.isAreaFeedback,
    props.onSelectFeedback,
    props.onRemoveFeedback,
    props.onClickMoveTo
  ])

  const date = props.postedAt ?? props.messages?.firstPostedAt ?? props.updatedAt

  return (
    <FeedbackHeader>
      <Group>
        {props.enableHighlightOnFocus && props.index !== undefined && (
          <span title={`Index: ${props.index + 1}`}>({props.index + 1})</span>
        )}
        <FlexContainer>
          <FeedbackType>{feedackType}</FeedbackType>
          <FeedbackRating {...props} />
        </FlexContainer>
      </Group>
      <Group>
        {props.showPin && (
          <IconButton size="small" onClick={() => props.onPinToggle?.(!props.isPinned)}>
            <PushPin weight={props.isPinned ? 'fill' : 'regular'} />
          </IconButton>
        )}
        {date ? <FeedbackDate>{dateShortMonthFormat(date, false)}</FeedbackDate> : null}
        <OptionsMenu options={menuOptions} size="small" stopPropagation />
      </Group>
    </FeedbackHeader>
  )
}

export default FeedbackItemHeader
