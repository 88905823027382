import { useNavigate } from 'react-router-dom'
import Text from '@/components/atoms/text'
import FlexContainer from '@/components/atoms/flex-container'
import { HOME_CARD_WIDTH, HomeCard } from './ManageCustomers.styles'
import AccountsCardImage from '@/assets/drawings/accounts_card_image1.5x.png'
import UsersCardImage from '@/assets/drawings/users_card_image1.5x.png'
import Button from '@/components/atoms/button'
import templateAccounts from '@/assets/samples/Customer Record Sample - Account.csv'
import templateUsers from '@/assets/samples/Customer Record Sample - User.csv'
import { useTranslation } from 'react-i18next'
import i18n from '../../../plugins/i18n/i18n'

const cards = [
  {
    title: i18n.t('accounts'),
    description: i18n.t(
      'importDataAboutYourAccountsSuchAsArrPlanTierEtcForEnhancedFeedbackSegmentationThisIsSuitedForB2bCompanies'
    ),
    imageSrc: AccountsCardImage,
    imageAlt: 'Three people discussing ideas around a dashboard.',
    templateLink: templateAccounts,
    detailsPath: 'accounts',
    ctaText: i18n.t('goToAccountList')
  },
  {
    title: i18n.t('users'),
    description: i18n.t(
      'importDataAboutYourUsersSuchAsArrPlanTierEtcForEnhancedFeedbackSegmentationThisIsSuitedForB2bAndB2cCompanies'
    ),
    imageSrc: UsersCardImage,
    imageAlt: 'People with different ideas connected with dashed line.',
    templateLink: templateUsers,
    detailsPath: 'users',
    ctaText: i18n.t('goToUserList')
  }
]

const HomeManageCustomers = () => {
  const navigate = useNavigate()

  const { t } = useTranslation()

  return (
    <FlexContainer direction="column" gap="xs">
      <Text typeface="titleBoldXS">Import customers</Text>
      <FlexContainer gap="xs">
        {cards.map(card => (
          <HomeCard key={card.title}>
            <img alt={card.imageAlt} height={189} src={card.imageSrc} width={HOME_CARD_WIDTH} />
            <Text color="neutralLowPure" typeface="titleBoldXXS">
              {card.title}
            </Text>
            <Text
              className="description"
              color="neutralLowPure"
              fontSize="xxxs"
              fontWeight="regular"
              lineHeight="xl"
            >
              {card.description}
            </Text>

            <Text
              as="a"
              className="template-link"
              color="brandPrimaryPure"
              download
              fontSize="xxxs"
              fontWeight="regular"
              href={card.templateLink}
              lineHeight="xs"
              target="_blank"
            >
              {t('downloadCsvTemplate')}
            </Text>
            <Button fullWidth onClick={() => navigate(card.detailsPath)}>
              {card.ctaText}
            </Button>
          </HomeCard>
        ))}
      </FlexContainer>
    </FlexContainer>
  )
}

export default HomeManageCustomers
