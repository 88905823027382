import { useFeedbacks } from '@/hooks/feedback/useFeedbacks'
import { useFiltersStore, useKeywordsDetailsFeedbackStore } from '@/store'
import { Feedback } from '@/types/feedbacks'
import { Suspense, lazy, useEffect, useState } from 'react'
import { shallow } from 'zustand/shallow'
import Card from '../../card/Card'
import { Bug, ChatText, HandsClapping, Megaphone } from '@phosphor-icons/react'
import ScrollLoader from '../../feed/ScrollLoader'
import Text from '@/components/atoms/text'
import InfiniteScroll from 'react-infinite-scroll-component'
import FlexContainer from '@/components/atoms/flex-container'
import FeedbackItem from '../../feedback-item/FeedbackItem'
import useSelectedFilters from '@/hooks/filters/useSelectedFilters'
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'
import { TrendingTopicType } from '@/types/analytics/TrendingTopic'
import Select, { SelectOption } from '@/components/atoms/select'
import { useTranslation } from 'react-i18next'

const FeedbackDetails = lazy(() => import('@/components/molecules/feedback-details/index'))
const FeedbackThread = lazy(() => import('@/components/molecules/feed/feedbacks/FeedbackThread'))

interface Props {
  topicId: string
  topicName: string
  topicType: TrendingTopicType
  asExploreFeedbackItem?: boolean
}

const pageSize = 50

type FilterOption = 'issue' | 'request' | 'compliment' | 'all'

const TopicRelatedFeedback = ({
  topicId,
  topicName,
  topicType,
  asExploreFeedbackItem = false
}: Props) => {
  const toRequestPayload = useFiltersStore(state => state.toFeedbackRequestPayload)
  const { t } = useTranslation()

  const { feedbacks, isLoading, total, hasMore, load, reset, archive } = useFeedbacks({
    archived: false,
    store: 'topicRelated',
    pageSize
  })

  let defaultFilterOption: FilterOption = 'all'
  if (topicType === 'topIssues') {
    defaultFilterOption = 'issue'
  }
  if (topicType === 'topRequests') {
    defaultFilterOption = 'request'
  }

  const [filterOption, setFilterOption] = useState<FilterOption>(defaultFilterOption)
  const filterSelectOptions: SelectOption<FilterOption>[] = [
    { text: t('issuesOnly'), value: 'issue', icon: <Bug /> },
    { text: t('requestsOnly'), value: 'request', icon: <Megaphone /> },
    { text: t('complimentsOnly'), value: 'compliment', icon: <HandsClapping /> },
    { text: t('allFeedback'), value: 'all', icon: <ChatText /> }
  ]

  const onFilterOptionChange = (value: string) => {
    reset()
    setFilterOption(value as FilterOption)
  }

  const getTopicFeedback = async () => {
    try {
      const { filter, ...payload } = { ...toRequestPayload(0, pageSize, false) }
      const newFilters = { ...filter }

      if (!asExploreFeedbackItem) {
        newFilters.topic_groups = [[topicId]]
      }
      newFilters.feedback_keyword_classes = filterOption === 'all' ? [] : [filterOption]

      await load({ ...payload, filter: newFilters })
    } catch (error) {
      console.error(error)
      reset()
    }
  }

  const filters = useFiltersStore(
    state => ({
      dateRange: state.dateRange,
      orderBy: state.orderBy,
      search: state.search,
      filtersByURL: state.filtersByURL,
      accounts: Object.values(state.accountsStringFilters).flatMap(value => value.selected),
      accountsDateFilters: state.accountsDateFilters.filter(filter => filter.selected !== null),
      accountsNumericFilters: state.accountNumericFilters
        .filter(filter => filter.option !== 'all')
        .map(filter => ({ key: filter.key, value: filter.value, option: filter.option })),
      accountsBooleanFilters: state.accountBooleanFilters.filter(filter => filter.value !== null),
      users: Object.values(state.usersStringFilters).flatMap(value => value.selected),
      usersDateFilters: state.usersDateFilters.filter(filter => filter.selected !== null),
      usersNumericFilters: state.usersNumericFilters
        .filter(filter => filter.option !== 'all')
        .map(filter => ({ key: filter.key, value: filter.value, option: filter.option })),
      usersBooleanFilters: state.usersBooleanFilters.filter(filter => filter.value !== null)
    }),
    shallow
  )

  const {
    selectedNumericFilters,
    selectedDatetimeFilters,
    selectedStringFilters,
    selectedTopicFilters
  } = useSelectedFilters()

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    if (feedbacks.length === 0) {
      getTopicFeedback()
    }

    return () => {
      reset()
    }
  }, [])

  useDidUpdateEffect(
    () => {
      getTopicFeedback()
    },
    [
      topicId,
      filterOption,
      filters,
      selectedDatetimeFilters,
      selectedStringFilters,
      selectedTopicFilters,
      selectedNumericFilters
    ],
    true
  )

  const { selectedFeedback } = useKeywordsDetailsFeedbackStore(
    state => ({
      selectedFeedback: state.selectedFeedback
    }),
    shallow
  )
  const setSelectedFeedback = useKeywordsDetailsFeedbackStore(state => state.setSelectedFeedback)

  const loadMore = () => {
    feedbacks.length && getTopicFeedback()
  }

  const [feedbackThreadOpen, setFeedbackThreadOpen] = useState<Feedback | null>(null)

  const titleFeedbackCount = total !== 0 ? `${total} feedback` : 'Feedback'

  const title = (() => {
    if (!asExploreFeedbackItem)
      return (
        <>
          {titleFeedbackCount} {t('relatedTo')} <q>{topicName}</q>
        </>
      )

    if (filters.search.length)
      return (
        <>
          {titleFeedbackCount} {t('relatedTo')} <q>{filters.search}</q>
        </>
      )

    return <>{titleFeedbackCount}</>
  })()

  return (
    <>
      <Card.Root
        color="brandPrimaryPure"
        direction="column"
        gap="xxs"
        id="list-root-topic-related-feedback"
      >
        <FlexContainer alignItems="center" justifyContent="spaceBetween">
          <Card.Header>
            <Card.IconBox>
              <ChatText />
            </Card.IconBox>
            <Card.Title>{title}</Card.Title>
          </Card.Header>

          <Select
            hideCaret
            onValueChange={onFilterOptionChange}
            options={filterSelectOptions}
            showSelectedIcon
            small
            value={filterOption}
          />
        </FlexContainer>

        {isLoading && !feedbacks.length && <ScrollLoader />}

        {!feedbacks.length && !isLoading && (
          <Text as="p" css={{ textAlign: 'center', marginTop: 24 }}>
            {t('noFeedback')}
          </Text>
        )}

        <InfiniteScroll
          dataLength={feedbacks.length}
          endMessage={<FlexContainer css={{ mb: 96 }} />}
          hasMore={hasMore}
          loader={isLoading && feedbacks.length ? <ScrollLoader /> : ''}
          next={loadMore}
          scrollThreshold="250px"
          scrollableTarget="scroll-analytics"
        >
          <FlexContainer css={{ pr: '$micro', mb: '$xxxs' }} direction="column" gap="xxs">
            {feedbacks.map(feedback => (
              <FeedbackItem
                key={feedback.feedbackId}
                {...feedback}
                onArchive={archive}
                onRepliesClick={() => setFeedbackThreadOpen(feedback)}
                onSelect={setSelectedFeedback}
                selectedId={selectedFeedback?.feedbackId}
              />
            ))}
          </FlexContainer>
        </InfiniteScroll>
      </Card.Root>

      <Suspense>
        <FeedbackDetails store="topicRelated" />
      </Suspense>

      <Suspense>
        {feedbackThreadOpen && (
          <FeedbackThread
            feedback={feedbackThreadOpen}
            onOpenChange={() => setFeedbackThreadOpen(null)}
            ticketId={feedbackThreadOpen?.ticket?.id ?? ''}
          />
        )}
      </Suspense>
    </>
  )
}

export default TopicRelatedFeedback
