import TooltipCard from '@/components/atoms/tooltip-card'
import { ReactNode } from 'react'

const delayDuration = 300
const side = 'bottom'
const offset = 8
const disableHoverableContent = false

interface Props {
  children: ReactNode
  open?: boolean
  onOpenChange?: (open: boolean) => void
  title: string
  bulletPoints: string[]
  bottomText?: string
}

const GenericTooltip = ({
  children,
  open,
  onOpenChange,
  title,
  bulletPoints,
  bottomText
}: Props) => {
  return (
    <TooltipCard.Root
      delayDuration={delayDuration}
      disableHoverableContent={disableHoverableContent}
      onOpenChange={onOpenChange}
      open={open}
    >
      <TooltipCard.Trigger asChild>{children}</TooltipCard.Trigger>
      <TooltipCard.Content
        onPointerDownOutside={e => {
          e.preventDefault()
        }}
        side={side}
        sideOffset={offset}
      >
        <TooltipCard.Container>
          <TooltipCard.TextContent css={{ maxWidth: 240 }}>
            <TooltipCard.Text>{title}</TooltipCard.Text>

            <TooltipCard.List>
              {bulletPoints.map((bulletPoint, index) => (
                <TooltipCard.ListItem key={bulletPoint + index}>{bulletPoint}</TooltipCard.ListItem>
              ))}
            </TooltipCard.List>

            {!!bottomText && <TooltipCard.Text>{bottomText}</TooltipCard.Text>}
          </TooltipCard.TextContent>
        </TooltipCard.Container>
      </TooltipCard.Content>
    </TooltipCard.Root>
  )
}

export default GenericTooltip
