const usePromiseSync = () => {
  function getMarkedTimestamp(key: string) {
    const freshTimestamp = sessionStorage.getItem('sync' + key)
    return freshTimestamp
  }

  function markTimestamp(key: string) {
    const timestamp = new Date().getTime().toString()
    sessionStorage.setItem('sync' + key, timestamp)
    return timestamp
  }

  async function sync<T = unknown>(
    key: string,
    promise: Promise<T>,
    callback: (result: T) => void
  ) {
    const timestamp = markTimestamp(key)
    const result = await promise
    const markedTimestamp = getMarkedTimestamp(key)
    if (markedTimestamp === timestamp) {
      callback(result)
    }
  }

  return { sync }
}

export default usePromiseSync
