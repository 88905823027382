import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'

export const GRID_TEMPLATE =
  'minmax(215px, 2.5fr) minmax(180px, 2fr) minmax(120px, 1.2fr) minmax(80px, 0.8fr) minmax(120px, 1.2fr)'
export const FIRST_CELL_PADDING = 24

export const TableRow = styled('div', {
  display: 'grid',
  gridTemplateColumns: GRID_TEMPLATE,
  bBottom: '$stroke',
  background: '$white',
  minHeight: 48,

  'span.options': { opacity: 0 },

  '&:hover': {
    background: '$greyLight',
    'span.options': { opacity: 1 }
  },

  '.expand-icon': {
    padding: 0
  },

  variants: {
    isClicked: {
      true: {
        background: '$greyLight',
        boxShadow: '$leftBlueBorder',
        'span.options': { opacity: 1 }
      },
      false: {}
    },

    isExpanded: {
      true: {
        '.expand-icon': {
          transform: 'rotate(0deg)',
          transition: '.2s'
        }
      },
      false: {
        '.expand-icon': {
          transform: 'rotate(-90deg)'
        }
      }
    }
  }
})

export const TableCell = styled('div', {
  display: 'flex',
  alignItems: 'center',
  // bRight: '$stroke',
  padding: '4px',
  fontSize: '$micro',
  color: '$grey',

  '&:last-of-type': {
    borderRight: 'none'
  },

  '&:first-of-type': {
    paddingLeft: FIRST_CELL_PADDING
  },

  variants: {
    wrap: {
      true: {
        flexWrap: 'wrap'
      }
    },

    justifyEnd: {
      true: {
        justifyContent: 'flex-end'
      }
    },
    justifyCenter: {
      true: {
        justifyContent: 'center'
      }
    },
    netSentiment: {
      true: {
        div: {
          cursor: 'pointer'
        }
      }
    }
  }
})

export const TopicsCounter = styled('div', {
  background: 'transparent',
  bAll: '$stroke',
  color: '$pajarito',
  padding: '4px 8px',
  borderRadius: 4,
  cursor: 'pointer',

  variants: {
    disabled: {
      true: {
        backgroundColor: '$stroke',
        border: 'none',
        color: '$grey',
        pointerEvents: 'none'
      }
    }
  }
})

export const TableCellGap = styled('span', {
  minWidth: 54
})

export const ActionsBar = styled(FlexContainer, {
  height: 60,
  width: '100%',
  minWidth: 400,
  pAll: '$xs',
  boxSizing: 'border-box',

  position: 'fixed',
  bottom: 20,
  right: 24,
  zIndex: 3,

  background: '$pajarito',
  color: '$white',
  borderRadius: 12,
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: '$componentShadowLarge',

  transition: '.2s',

  '& p': {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    cursor: 'pointer',
    borderRadius: 6,

    '&:hover': {
      color: '$grey'
    }
  },

  variants: {
    expanded: {
      true: {
        right: 32,
        maxWidth: 'calc(100vw - 180px)'
      },
      false: {
        maxWidth: 'calc(100vw - 572px)'
      }
    }
  }
})

export const KeywordTopicsContainer = styled(FlexContainer, {
  gap: '$micro',
  alignItems: 'center',
  minHeight: '100%',
  minWidth: '100%',

  '& .plus-container': {
    visibility: 'hidden'
  },

  '& .plus-container.open': {
    visibility: 'visible',
    width: 'auto'
  },

  '&:hover': {
    '& .plus-container': {
      visibility: 'visible',
      width: 'auto'
    }
  },

  '& .plus-container:hover': {
    visibility: 'visible',
    width: 'auto'
  }
})
