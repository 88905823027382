import { useCallback, useMemo } from 'react'

import {
  baseAllMetricItems,
  baseAllMetricsGroupedByMetricKey,
  baseMetricKeyToAllMetricsKeys,
  buildMetricKey,
  parseBaseRawMetric
} from '@/utils/metrics'
import {
  AllMetricsKey,
  GetColumnValueFn,
  MetricItem,
  MetricKey,
  RawMetricUnit
} from '@/types/metrics'
import useSourcesQuery from '../useSourcesQuery'
import { shallow } from 'zustand/shallow'
import { useUserStore } from '@/store'
import { useTranslation } from 'react-i18next'
import { snakeToTitle } from '@/utils/letterCase'
import { getFeedbackKindPrettyName } from '@/utils/feedback'
import {
  currencyFormater,
  integerFormatter,
  npsPercentageFormater,
  percentageFormatter,
  percentageScoreTransformer,
  scoreFormatter
} from '@/utils/metrics/formatters'
import {
  Camera,
  ChatText,
  Gauge,
  Icon,
  Megaphone,
  Newspaper,
  Stack,
  Star,
  ThumbsUp,
  Warning
} from '@phosphor-icons/react'
import i18n from '@/plugins/i18n/i18n'

const mapRawUnitToFormater: Record<
  RawMetricUnit,
  { formatter: MetricItem['formatter']; numberTransformer: MetricItem['numberTransformer'] }
> = {
  count: {
    formatter: integerFormatter,
    numberTransformer: (value: number) => value
  },
  percentage: {
    formatter: npsPercentageFormater,
    numberTransformer: percentageScoreTransformer
  },
  score: { formatter: scoreFormatter, numberTransformer: percentageScoreTransformer },
  rating: {
    formatter: integerFormatter,
    numberTransformer: (value: number) => Number(value.toPrecision(2))
  },
  currency: { formatter: currencyFormater, numberTransformer: (value: number) => value }
}

const mapSourceToIcon: Record<MetricKey, Icon> = {
  count: ChatText,
  feedback_share: ChatText,
  nps: ThumbsUp,
  csat: Gauge,
  issue: Warning,
  review: Star,
  social_media_post: Camera,
  support_ticket: Megaphone,
  tcsat: Megaphone,
  tnps: Megaphone,
  transcript: Newspaper
}

const kindKeys: MetricKey[] = [
  'count',
  'csat',
  'feedback_share',
  'issue',
  'nps',
  'nps',
  'review',
  'social_media_post',
  'support_ticket',
  'tcsat',
  'tnps',
  'transcript'
]

const addSourcesToFilter = (metricItem: MetricItem, sources: string[]): MetricItem['metric'] => {
  let filter: MetricItem['metric']['filter']
  let share_filter: MetricItem['metric']['share_filter']

  if (metricItem.metric.filter && metricItem.metric.filter.source_alias) {
    filter = {
      ...metricItem.metric.filter,
      source_alias: sources
    }
  } else {
    filter = metricItem.metric.filter
  }

  if (metricItem.metric.share_filter && metricItem.metric.share_filter.source_alias) {
    share_filter = {
      ...metricItem.metric.share_filter,
      source_alias: sources
    }
  } else {
    share_filter = metricItem.metric.share_filter
  }

  return { ...metricItem.metric, filter, share_filter }
}

const createBaseMetricItems = (sourceAlias: string) => {
  const formatedSourceAlias = getFeedbackKindPrettyName(sourceAlias)

  const countMetricKey = `${sourceAlias}_count` as AllMetricsKey
  const count: MetricItem & { metricKey: AllMetricsKey } = {
    metricKey: countMetricKey,
    label: i18n.t('count'),
    selectedLabel: `${formatedSourceAlias}: ${i18n.t('count')}`,
    icon: Stack,
    mainMetricType: 'count',
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: `${countMetricKey}`,
      filter: {
        source_alias: sourceAlias
      }
    }
  }

  const shareMetricKey = `${sourceAlias}_share` as AllMetricsKey
  const share: MetricItem & { metricKey: AllMetricsKey } = {
    metricKey: shareMetricKey,
    label: i18n.t('percentCount'),
    selectedLabel: `${formatedSourceAlias}: ${i18n.t('percentCount')}`,
    icon: Stack,
    mainMetricType: 'share',
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: `${shareMetricKey}`,
      filter: {
        source_alias: sourceAlias
      }
    }
  }

  return [count, share]
}

const useAllMetrics = () => {
  const organization = useUserStore(state => state.organization, shallow)

  const { values: kindValues } = useSourcesQuery({
    enabled: true,
    sourceMode: 'kind'
  })

  const { values: sourceAliasValues } = useSourcesQuery({
    enabled: true,
    sourceMode: 'sourceAlias'
  })

  const { t, i18n } = useTranslation()

  const orgSourceAliasMapping = useMemo(() => {
    return organization?.config?.sourceAlias
  }, [organization?.config?.sourceAlias])

  const orgMetrics = useMemo(() => {
    if (!organization?.config?.metrics) return undefined
    if (!kindValues.length) return undefined

    const { metrics } = organization.config

    const list: ({ source: MetricKey; metricKey: string } & MetricItem)[] = []

    Object.entries(metrics).forEach(([metricKey, value]) => {
      const source =
        (kindValues.find(sourceValue => sourceValue === metricKey.split('_')[0]) as MetricKey) ??
        ('count' as MetricKey)

      const displayLabel = i18n.exists(value.label)
        ? // biome-ignore lint/suspicious/noExplicitAny: will be valid
          t(value.label as any)
        : snakeToTitle(value.label)

      const metricPayLoadItem: MetricItem = {
        label: displayLabel,
        selectedLabel: `${getFeedbackKindPrettyName(source)}:${displayLabel}`,
        formatter: mapRawUnitToFormater[value.unit].formatter,
        numberTransformer: mapRawUnitToFormater[value.unit].numberTransformer,
        icon: mapSourceToIcon[source] ?? ChatText,
        metric: {
          label: `org_metric_${source}_${metricKey}_${value.label}`,
          name: value.name,
          customization: {
            ...value.customization,
            unit: value.unit
          }
        }
      }

      list.push({ source, metricKey, ...metricPayLoadItem })
    })

    return list
  }, [organization, kindValues, t, i18n.exists])

  const sourceAliasMetrics = useMemo(() => {
    const list: MetricItem[] = []

    sourceAliasValues.forEach(sourceAlias => {
      // only create base metrics for unknown source aliases
      if (baseAllMetricItems[sourceAlias]) return

      if (orgSourceAliasMapping && orgSourceAliasMapping[sourceAlias]) {
        const sourceAliasMapping = orgSourceAliasMapping[sourceAlias]
        const baseMetrics = baseAllMetricsGroupedByMetricKey[sourceAliasMapping.kind]

        if (baseMetrics) {
          const newMetrics = Object.entries(baseMetrics)
            .map(([key, metricItem]) => {
              if (!metricItem) return null

              const metricKey = buildMetricKey({
                source: sourceAlias,
                key,
                kind: sourceAliasMapping.kind
              })

              const newMetricItem: MetricItem = {
                ...metricItem,
                source: sourceAlias,
                metricKey: metricKey,
                baseKind: sourceAliasMapping.kind,
                selectedLabel: `${sourceAliasMapping.label}: ${metricItem?.label}`,
                metric: {
                  ...metricItem.metric,
                  label: `${metricKey}_label_${metricItem.metric.label}`,
                  filter: {
                    ...metricItem.metric.filter,
                    source_alias: metricItem.metric.filter?.source_alias
                      ? sourceAlias
                      : metricItem.metric.filter?.source_alias
                  },
                  share_filter: {
                    ...metricItem.metric.share_filter,
                    source_alias: metricItem.metric.share_filter?.source_alias
                      ? sourceAlias
                      : metricItem.metric.share_filter?.source_alias
                  }
                }
              }

              return newMetricItem
            })
            .filter(Boolean) as MetricItem[]

          list.push(...newMetrics)
        }
      } else {
        const metrics = createBaseMetricItems(sourceAlias)
        list.push(...metrics.map(metric => ({ ...metric, source: sourceAlias })))
      }
    })

    return list
  }, [sourceAliasValues, orgSourceAliasMapping])

  const allMetricItems = useMemo(() => {
    const allMetrics: Record<AllMetricsKey, MetricItem> = { ...baseAllMetricItems }

    orgMetrics?.forEach(orgMetric => {
      allMetrics[orgMetric.metricKey] = { ...orgMetric }
    })

    if (sourceAliasMetrics.length > 0) {
      sourceAliasMetrics.forEach(sourceAliasMetric => {
        if (sourceAliasMetric.source && !allMetrics[sourceAliasMetric.source]) {
          allMetrics[sourceAliasMetric.source] = { ...sourceAliasMetric }
        }
        if (sourceAliasMetric.metricKey) {
          allMetrics[sourceAliasMetric.metricKey] = { ...sourceAliasMetric }
        }
      })
    }

    Object.entries(allMetrics).forEach(([key, metricItem]) => {
      const metricKey = key as AllMetricsKey
      const adjustedMetricItem: MetricItem = {
        ...metricItem,
        metric:
          metricKey === 'impact_score'
            ? addSourcesToFilter(metricItem, sourceAliasValues)
            : metricItem.metric
      }

      allMetrics[metricKey] = { ...adjustedMetricItem }
    })

    return allMetrics
  }, [orgMetrics, sourceAliasMetrics, sourceAliasValues])

  const metricKeyToAllMetricsKeys = useMemo(() => {
    const _metricKeys: Record<MetricKey, AllMetricsKey[]> = {
      ...baseMetricKeyToAllMetricsKeys
    }

    orgMetrics?.forEach(orgMetric => {
      _metricKeys[orgMetric.source] = [..._metricKeys[orgMetric.source], orgMetric.metricKey]
    })

    sourceAliasMetrics.forEach(sourceAliasMetric => {
      if (sourceAliasMetric.source && sourceAliasMetric.metricKey) {
        _metricKeys[sourceAliasMetric.source] = _metricKeys[sourceAliasMetric.source]
          ? [..._metricKeys[sourceAliasMetric.source], sourceAliasMetric.metricKey]
          : [sourceAliasMetric.metricKey]
      }
    })

    return _metricKeys
  }, [orgMetrics, sourceAliasMetrics])

  const allMetricItemsList = useMemo(() => {
    return Object.entries(allMetricItems).map(([key, value]) => ({
      key,
      ...value
    })) as (MetricItem & { key: AllMetricsKey })[]
  }, [allMetricItems])

  const allMetricsGroupedByMetricKey = useMemo(() => {
    const _metricsGrouped: Record<MetricKey, Partial<Record<AllMetricsKey, MetricItem>>> = {
      ...baseAllMetricsGroupedByMetricKey
    }

    orgMetrics?.forEach(orgMetric => {
      _metricsGrouped[orgMetric.source] = {
        ..._metricsGrouped[orgMetric.source],
        [orgMetric.metricKey]: { ...orgMetric }
      }
    })

    sourceAliasMetrics.forEach(sourceAliasMetric => {
      if (sourceAliasMetric.source && sourceAliasMetric.metricKey) {
        _metricsGrouped[sourceAliasMetric.source] = {
          ..._metricsGrouped[sourceAliasMetric.source],
          [sourceAliasMetric.metricKey]: { ...sourceAliasMetric }
        }
      }
    })

    Object.entries(_metricsGrouped).forEach(([source, metrics]) => {
      const adjustedMetrics: Partial<Record<AllMetricsKey, MetricItem>> = {}

      Object.entries(metrics).forEach(([metricKey, metricItem]) => {
        if (!metricItem) return
        const adjustedMetricItem: MetricItem = {
          ...metricItem,
          metric:
            metricKey === 'impact_score'
              ? addSourcesToFilter(metricItem, sourceAliasValues)
              : metricItem.metric
        }

        adjustedMetrics[metricKey] = adjustedMetricItem
      })

      _metricsGrouped[source] = adjustedMetrics
    })

    return _metricsGrouped
  }, [orgMetrics, sourceAliasMetrics, sourceAliasValues])

  const getMetricByColumn = useCallback(
    (column: string) => {
      const metric = allMetricItems[column]
      return metric
    },
    [allMetricItems]
  )

  const parseRawMetric: GetColumnValueFn = useCallback(
    params => {
      return parseBaseRawMetric({ ...params, allMetricItems, metricKeyToAllMetricsKeys })
    },
    [allMetricItems, metricKeyToAllMetricsKeys]
  )

  const getMetricDisplayName = useCallback(
    (metricKey: string) => {
      if (orgSourceAliasMapping && orgSourceAliasMapping[metricKey]) {
        const sourceAliasMapping = orgSourceAliasMapping[metricKey]
        return sourceAliasMapping.label
      }

      return getFeedbackKindPrettyName(metricKey)
    },
    [orgSourceAliasMapping]
  )

  const getSourceAliasKind = useCallback(
    (source: string | undefined): MetricKey | null => {
      if (!source) return null
      if (source === 'feedback_share') return 'count'

      const isKind = kindKeys.includes(source)
      if (isKind) return source

      if (orgSourceAliasMapping && orgSourceAliasMapping[source]) {
        const sourceAliasMapping = orgSourceAliasMapping[source]
        return sourceAliasMapping.kind as MetricKey
      }

      return null
    },
    [orgSourceAliasMapping]
  )

  const getMainMetricsMapByMainType = useCallback(
    (mainMetricTypes: MetricItem['mainMetricType'][]) => {
      const itemsMap: Record<string, MetricItem> = {}

      allMetricItemsList.forEach(metricItem => {
        if (metricItem.mainMetricType && mainMetricTypes.includes(metricItem.mainMetricType)) {
          const key =
            sourceAliasValues.find(
              sourceValue => sourceValue === (metricItem.source ?? metricItem.key)
            ) ?? metricItem.key

          itemsMap[key] = metricItem
        }
      })

      return itemsMap
    },
    [allMetricItemsList, sourceAliasValues]
  )

  const getMainMetricByMainType = useCallback(
    (options: { source: MetricKey; mainMetricType: MetricItem['mainMetricType'] }) => {
      const { source, mainMetricType } = options
      const metricItems = allMetricsGroupedByMetricKey[source]
      if (!metricItems) return undefined

      return Object.values(metricItems).find(
        metricItem => metricItem?.mainMetricType === mainMetricType
      )
    },
    [allMetricsGroupedByMetricKey]
  )

  return {
    orgMetrics,
    allMetricItems,
    allMetricItemsList,
    metricKeyToAllMetricsKeys,
    allMetricsGroupedByMetricKey,
    getMetricByColumn,
    parseRawMetric,
    getMetricDisplayName,
    getMainMetricsMapByMainType,
    getSourceAliasKind,
    getMainMetricByMainType
  }
}

export default useAllMetrics
