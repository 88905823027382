import FlexContainer from '@/components/atoms/flex-container'
import { ProgressBar } from '@/components/atoms/progress-bar'
import Text from '@/components/atoms/text'
import { useTranslation } from 'react-i18next'
import Button from '@/components/atoms/button'
import i18n from '@/plugins/i18n/i18n'
import { Check, X } from '@phosphor-icons/react'
import { MarkdownText } from '@/components/atoms/markdown-text'
import Card from '@/components/molecules/card'
import useOpportunityCalibration from '@/hooks/opportunity/useOpportunityCalibration'
import useOpportunityCalibrationStore from '@/store/useOpportunityCalibrationStore'
import useOpportunityStore from '@/store/useOpportunityStore'
import { shallow } from 'zustand/shallow'
import { useCallback, useMemo, useState } from 'react'
import CancelCalibrationDialog from './CancelCalibrationDialog'
import { LoadingCalibrationQuestionsCard } from './CalibrateRoundQuestionCard'
import CalibrateSteps from './CalibrateSteps'
import { useNavigate } from 'react-router-dom'
import shortUUID from 'short-uuid'
import CalibrationBetweenQuestionItem from './CalibrationBetweenQuestionItem'
import useSegment from '@/hooks/useSegment'
import CalibrationReviewDialog from './CalibrationReviewDialog'
import useUserStore from '@/store/useUserStore'
import useOpportunityMutations from '@/hooks/opportunity/useOpportunityMutations'
import { OpportunityStatus } from '@/types/opportunity/Opportunity'

interface CardProps {
  text?: string
  onYesClick: (response: boolean) => void
  onNoClick: (response: boolean) => void
}

const CalibrationQuestionCard = ({ text, onYesClick, onNoClick }: CardProps) => (
  <Card.Root
    css={{ minHeight: '300px' }}
    alignItems="start"
    color="brandPrimaryPure"
    direction="column"
    gap="xxs"
  >
    <Card.Header css={{ justifyContent: 'space-between', width: '100%', marginLeft: '4px' }}>
      <Card.Title>{i18n.t('calibrateOpportunityQuestionCardTitle')}</Card.Title>
      <FlexContainer gap="micro" style={{ marginRight: '4px' }}>
        <Button small variant={'positive'} onClick={() => onYesClick(true)}>
          <Check />
          {i18n.t('yes')}
        </Button>
        <Button small variant={'danger'} onClick={() => onNoClick(false)}>
          <X />
          {i18n.t('no')}
        </Button>
      </FlexContainer>
    </Card.Header>
    <MarkdownText>{text ?? ''}</MarkdownText>
  </Card.Root>
)

const CalibrateQuestionsModule = () => {
  const { t } = useTranslation()
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity, shallow)
  const currentUser = useUserStore(state => state.currentUser)
  const organization = useUserStore(state => state.organization)
  const { updateOpportunity } = useOpportunityMutations()

  const { track } = useSegment()

  const navigate = useNavigate()
  const translator = useMemo(() => shortUUID(), [])
  const resetStore = useOpportunityCalibrationStore(state => state.resetStore)

  const onFinishClick = useCallback(() => {
    resetStore()
    track('calibrate_opp_finish')
    const shortOpportunityId = translator.fromUUID(currentOpportunity?.id ?? '')
    navigate(`/opportunity/${shortOpportunityId}`)
  }, [track, translator, currentOpportunity, navigate, resetStore])

  const {
    feedbackSample,
    finishCalibrationRound,
    isLoading,
    isFetching,
    isRefetching,
    isFinishingCalibrationRound,
    updateOpportunityPosCalibration,
    isUpdatingOpportunityPosCalibration
  } = useOpportunityCalibration(currentOpportunity)

  const isSomethingLoading = useMemo(() => {
    return (
      isLoading ||
      isFetching ||
      isRefetching ||
      isFinishingCalibrationRound ||
      isUpdatingOpportunityPosCalibration
    )
  }, [
    isLoading,
    isFetching,
    isRefetching,
    isFinishingCalibrationRound,
    isUpdatingOpportunityPosCalibration
  ])

  const [feedbackDisplayedIndex, setFeedbackDisplayedIndex] = useState<number>(0)
  const feedbackDisplayedId = feedbackSample?.feedback_ids_sampled[feedbackDisplayedIndex]
  const currentDisplayedFeedback = feedbackSample?.feedbacks.find(
    feedback => feedback.id === feedbackDisplayedId
  )

  const setCurrentUserCalibration = useOpportunityCalibrationStore(
    state => state.setCurrentUserCalibration
  )
  const currentRound = useOpportunityCalibrationStore(state => state.currentRound)
  const setCurrentRound = useOpportunityCalibrationStore(state => state.setCurrentRound)

  const max = feedbackSample?.feedback_ids_sampled.length
  const currentStep = feedbackDisplayedIndex + 1

  const handleYesNoClick = (response: boolean) => {
    if (!currentDisplayedFeedback) return
    const newUserAnswer = {
      feedback_id: currentDisplayedFeedback.id,
      response: response
    }
    setCurrentUserCalibration([newUserAnswer])

    if (!max) return
    setFeedbackDisplayedIndex(prevIndex => prevIndex + 1)
    if (feedbackDisplayedIndex === max - 1) {
      setIsQuestionsAnswered(true)
    }
  }

  const [isCancelCalibrationOpen, setIsCancelCalibrationOpen] = useState(false)

  const isReviewRequiredOpen =
    feedbackSample?.status === 'not_enougth_feedback_to_support_the_opportunity'
  const isCalibrationStatusFinish = feedbackSample?.status === 'finished'

  const onCancelClick = () => {
    setIsCancelCalibrationOpen(true)
    track('calibrate_opp_cancel')
  }

  const [isQuestionsAnswered, setIsQuestionsAnswered] = useState(false)
  const [isCalibrationFinished, setIsCalibrationFinished] = useState(false)

  const onNextRoundClick = () => {
    setCurrentRound()
    setIsQuestionsAnswered(false)
    finishCalibrationRound()
    setFeedbackDisplayedIndex(0)
    track('calibrate_opp_next_round')
  }

  const onRecordCalibrationClick = () => {
    setCurrentRound()
    updateOpportunityPosCalibration()
    track('calibrate_opp_next_round')
    setIsCalibrationFinished(true)
    if (!currentOpportunity) return
    updateOpportunity({ id: currentOpportunity.id, status: OpportunityStatus.Quantified })
  }

  const onOpenDetailsClick = useCallback(() => {
    resetStore()
    track('calibrate_opp_open_opp_details')
    const shortOpportunityId = translator.fromUUID(currentOpportunity?.id ?? '')
    navigate(`/opportunity/${shortOpportunityId}`)
  }, [track, translator, navigate, currentOpportunity?.id, resetStore])

  return (
    <>
      {isSomethingLoading ? (
        <LoadingCalibrationQuestionsCard
          isCalibrationFinished={isCalibrationFinished}
          loadStep={currentRound}
          duration={90}
        />
      ) : isQuestionsAnswered || isCalibrationStatusFinish ? (
        <>
          {/* <CalibrateSteps currentRound={currentRound} /> */}
          <CalibrationBetweenQuestionItem
            onNextRoundClick={onNextRoundClick}
            onRecordCalibrationClick={onRecordCalibrationClick}
            onOpenDetailsClick={onOpenDetailsClick}
            currentRound={currentRound}
            feedbackSampleStatus={feedbackSample?.status}
            isCalibrationFinished={isCalibrationFinished}
          />
          <FlexContainer justifyContent="end" gap="micro">
            {!isCalibrationFinished && (
              <Button variant="flat" small onClick={onCancelClick}>
                {t('calibrateOpportunityCancel')}
              </Button>
            )}
            <Button onClick={onFinishClick} disabled={!isCalibrationFinished} small>
              {t('calibrateOpportunityFinish')}
            </Button>
          </FlexContainer>
        </>
      ) : (
        <>
          <CalibrateSteps currentRound={currentRound} />
          <FlexContainer direction="column" gap="xxxs">
            <FlexContainer direction="column">
              <ProgressBar.Container alignItems="center" direction="row" fullWidth gap="micro">
                <Text fontWeight="semibold" fontSize="xxxs">
                  {t('calibrateOpportunityQuestions')}
                </Text>
                <Text color="neutralLowLight" fontWeight="regular" fontSize="micro">
                  {currentStep}/{max}
                </Text>

                <ProgressBar.Bar
                  css={{ backgroundColor: '$brandPrimaryMedium' }}
                  max={max}
                  value={currentStep}
                />
              </ProgressBar.Container>
              <Text fontWeight="light" fontSize="xxxs">
                {t('calibrateOpportunityQuestionsDesc')}
              </Text>
            </FlexContainer>
            <CalibrationQuestionCard
              onYesClick={handleYesNoClick}
              onNoClick={handleYesNoClick}
              text={currentDisplayedFeedback?.text}
            />
          </FlexContainer>

          <FlexContainer justifyContent="end" gap="micro">
            <Button variant="flat" small onClick={onCancelClick}>
              {t('calibrateOpportunityCancel')}
            </Button>
            <Button onClick={onFinishClick} disabled={true} small>
              {t('calibrateOpportunityFinish')}
            </Button>
          </FlexContainer>
        </>
      )}

      {isCancelCalibrationOpen && (
        <CancelCalibrationDialog
          open={isCancelCalibrationOpen}
          onOpenChange={setIsCancelCalibrationOpen}
        />
      )}

      {isReviewRequiredOpen && currentOpportunity && (
        <CalibrationReviewDialog
          open={isReviewRequiredOpen}
          userEmail={currentUser?.email}
          userCompanyName={organization?.name}
          opportunity={currentOpportunity}
        />
      )}
    </>
  )
}

export default CalibrateQuestionsModule
