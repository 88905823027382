import useSavedFiltersStore from '@/store/useSavedFiltersStore'
import { useEffect, useMemo } from 'react'
import { NotificationData } from '@/types/notifications/Notifications'
import useUser from './useUser'
import NotificationsService from '@/services/NotificationsService'
import useClassification from './useClassification'
import useSavedFilters from './useSavedFilters'
import { useQuery } from '@tanstack/react-query'
import useToastMessageStore from '@/store/useToastMessageStore'
import { queryClient } from '@/plugins/reactQueryClient'
import useManageNotificationsStore from '@/store/useManageNotificationsStore'
import { shallow } from 'zustand/shallow'
import useLogging from './useLogging'
import { SavedFilterContent } from '@/types/filters/Filters'

const useManageNotifications = () => {
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)

  const { logException } = useLogging({ context: 'manage-notifications' })

  const { currentUser } = useUser()

  const savedFilters = useSavedFiltersStore(state => state.savedFilters)

  const { fetchAll } = useSavedFilters()
  const { verifyAllTopicAndThemes, themes } = useClassification()

  const { updatingNotifications, disableAll } = useManageNotificationsStore(
    state => ({
      updatingNotifications: state.updatingNotifications,
      disableAll: state.disableAll
    }),
    shallow
  )

  const pushUpdatingNotification = useManageNotificationsStore(
    state => state.pushUpdatingNotification
  )
  const removeUpdatingNotification = useManageNotificationsStore(
    state => state.removeUpdatingNotification
  )
  const resetUpdatingNotifications = useManageNotificationsStore(
    state => state.resetUpdatingNotifications
  )
  const setDisableAll = useManageNotificationsStore(state => state.setDisableAll)

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen once
  useEffect(() => {
    verifyAllTopicAndThemes()
    resetUpdatingNotifications()
    setDisableAll(false)
  }, [])

  const fetchSavedFilters = async () => {
    if (savedFilters.length === 0) {
      await fetchAll()
    }
  }

  const notificationsQuery = async () => {
    const [[error, data]] = await Promise.all([
      NotificationsService.getNotifications(),
      fetchSavedFilters()
      // verifyAllTopicAndThemes()
    ])
    if (error) {
      logException(error, { message: 'Failed to fetch notifications' })
      throw error
    }

    return data
  }

  const queryKey = ['notifications', savedFilters, themes]

  const { data, isLoading, isError, isRefetching, refetch, ...otherQueryProps } = useQuery({
    queryKey,
    queryFn: notificationsQuery
  })

  const { getFeedbackFilterFromContent } = useSavedFilters()

  const notifications = useMemo(
    () =>
      savedFilters.map((filter): NotificationData => {
        const notificationMatch = data?.find(n => n.description === filter.name)
        return (
          notificationMatch ?? {
            id: filter.filterId,
            description: filter.name,
            filter: getFeedbackFilterFromContent(filter.content as SavedFilterContent[]),
            toggleDisabled: (filter.content as SavedFilterContent[]).some(
              c => ['topic', 'theme'].includes(c.type) && themes.length === 0
            ),
            enabled: false,
            notificationConfig: [{ type: 'email', value: currentUser ? [currentUser?.email] : [] }],
            notificationSchedule: 'weekly',
            version: 'v1',
            createdBy: ''
          }
        )
      }),
    [savedFilters, data, themes, currentUser, getFeedbackFilterFromContent]
  )

  const onChange = async (notification: NotificationData) => {
    const removeToast = addLoadingToast({ text: 'Updating notification...' })
    const notificationId = notification.description
    pushUpdatingNotification(notificationId)
    if (!notification.id) {
      const [error, response] = await NotificationsService.registerNotification(notification)

      if (error) {
        console.error(error)
        const message = 'Failed to update notification'
        logException(error, { message })
        addErrorToast({ text: message })
        removeToast()
        removeUpdatingNotification(notificationId)
        return
      }

      await queryClient.cancelQueries({ queryKey })
      queryClient.setQueryData(queryKey, data ? [...data, response] : [response])

      addSuccessToast({ text: 'Notification updated' })
      removeToast()
      removeUpdatingNotification(notificationId)
      return
    }

    const [error, response] = await NotificationsService.updateNotification(notification)

    if (error) {
      console.error(error)
      const message = 'Failed to update notification'
      logException(error, { message })
      addErrorToast({ text: message })
      removeToast()
      removeUpdatingNotification(notificationId)
      return
    }

    await queryClient.cancelQueries({ queryKey })
    const updatedData = data?.map(n => (n.id === notification.id ? response : n)) ?? []
    queryClient.setQueryData(queryKey, updatedData)

    addSuccessToast({ text: 'Notification updated' })
    removeToast()
    removeUpdatingNotification(notificationId)
  }

  const removeChannelFromAllNotifications = async (channelName: string) => {
    const promises = notifications.map(async notification => {
      const isChannelInNotification = notification.notificationConfig.some(
        config => config.type === 'slack' && config.value.includes(channelName)
      )

      if (!isChannelInNotification) {
        return
      }

      const newNotification: NotificationData = {
        ...notification,
        notificationConfig: notification.notificationConfig.map(config => {
          if (config.type !== 'slack') {
            return config
          }

          return {
            type: 'slack',
            value: config.value.filter(channel => channel !== channelName)
          }
        })
      }

      await NotificationsService.updateNotification(newNotification)
      await queryClient.cancelQueries({ queryKey })
      const updatedData = data?.map(n => (n.id === notification.id ? newNotification : n)) ?? []

      queryClient.setQueryData(queryKey, updatedData)
    })

    setDisableAll(true)
    await Promise.all(promises)
    setDisableAll(false)
  }

  const isNotificationDisabled = (notification: NotificationData) =>
    updatingNotifications.includes(notification.id) || disableAll || notification.toggleDisabled

  return {
    // query
    notifications,
    isLoading,
    isError,
    isRefetching,
    refetch,
    ...otherQueryProps,

    onChange,
    removeChannelFromAllNotifications,
    isNotificationDisabled
  }
}

export default useManageNotifications
