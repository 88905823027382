import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'
import { ScrollAreaRoot } from '../scroll-area'

export const AnalyticsFlexContainer = styled(FlexContainer, {
  width: '100%',
  maxWidth: 1128,
  gap: '$xs',
  variants: {
    smallGap: {
      true: {
        gap: '$xxxs'
      }
    },
    opportunityPlan: {
      true: {
        px: '$xs',
        pt: '$xs',
        maxWidth: 'inherit'
      },
      false: {}
    }
  }
})

export const AnalyticsScrollArea = styled(ScrollAreaRoot, {
  overflow: 'hidden',
  bc: '$neutralHighMedium',
  bottom: 0,
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',

  variants: {
    opportunityPlan: {
      true: {
        bc: '$brandPrimaryLight'
      },
      false: {}
    }
  }
})
