import FlexContainer from '@/components/atoms/flex-container'
import Popover from '@/components/atoms/popover'
import Text from '@/components/atoms/text'
import useCollections from '@/hooks/collections/useCollections'
import { Collection } from '@/types/collection'
import { useMemo, useState } from 'react'
import CollectionBadge from '../CollectionBadge'
import { CaretDown } from '@phosphor-icons/react'
import { CollectionSelectPopoverContent, CollectionSelectSectionTitle } from './styles'
import AllAreasCollectionItem from './AllAreasCollectionItem'
import CollectionItem from './CollectionItem'
import Divider from '@/components/atoms/divider'
import Search from '../../search'
import { useTranslation } from 'react-i18next'

const CollectionSelect = () => {
  const { collections, changeCollection, currentCollection } = useCollections()
  const { t } = useTranslation()

  const currentCollectionName = currentCollection?.name ?? t('allOrganizationAreas')
  const currentCollectionVisibility = currentCollection?.visibility ?? 'public'

  const [searchInput, setSearchInput] = useState('')
  const showSearchInput = collections.length > 10

  const showAllAreasCollection = useMemo(
    () => 'all organization areas'.includes(searchInput.toLowerCase()),
    [searchInput]
  )

  const filteredCollections = useMemo(() => {
    if (!searchInput) return collections
    return collections.filter(collection =>
      collection.name.toLowerCase().includes(searchInput.toLowerCase())
    )
  }, [collections, searchInput])

  const areaCollections = useMemo(
    () => filteredCollections.filter(collection => collection.type === 'area_interest'),
    [filteredCollections]
  )
  const opportunityCollections = useMemo(
    () => filteredCollections.filter(collection => collection.type === 'opportunity'),
    [filteredCollections]
  )

  const hasAreaCollections = areaCollections.length > 0 || showAllAreasCollection
  const hasOpportunityCollections = opportunityCollections.length > 0
  const hasBothCollections = hasAreaCollections && hasOpportunityCollections
  const noResults = !hasAreaCollections && !hasOpportunityCollections

  const [isSelectOpen, setIsSelectOpen] = useState(false)

  const onCollectionClick = (collection: Collection) => {
    changeCollection(collection)
    setIsSelectOpen(false)
  }

  const onAllAreasClick = () => {
    changeCollection(null)
    setIsSelectOpen(false)
  }

  return (
    <Popover
      buttonChildren={
        <FlexContainer alignItems="center" css={{ cursor: 'pointer' }} gap="micro">
          <Text as="h1" fontSize="xxs" fontWeight="bold">
            {currentCollectionName}
          </Text>
          <CollectionBadge visibility={currentCollectionVisibility} />
          {collections.length > 0 && <CaretDown size={16} />}
        </FlexContainer>
      }
      customButton
      onOpenChange={setIsSelectOpen}
      open={isSelectOpen}
    >
      <CollectionSelectPopoverContent align="start" alignOffset={-8} sideOffset={16}>
        {showSearchInput && (
          <Search
            onChange={e => setSearchInput(e.currentTarget.value)}
            placeholder={t('search')}
            size="small"
            small
            value={searchInput}
          />
        )}
        {hasAreaCollections && (
          <FlexContainer direction="column" gap="xxs">
            <CollectionSelectSectionTitle>{t('areaCollections')}</CollectionSelectSectionTitle>
            <FlexContainer direction="column" gap="nano">
              {showAllAreasCollection && <AllAreasCollectionItem onClick={onAllAreasClick} />}
              {areaCollections.map(collection => (
                <CollectionItem
                  collection={collection}
                  key={collection.collectionId}
                  onClick={onCollectionClick}
                />
              ))}
            </FlexContainer>
          </FlexContainer>
        )}
        {hasBothCollections && (
          <Divider css={{ mt: '$micro', mb: '$xxxs' }} line="solid" orientation="horizontal" />
        )}
        {hasOpportunityCollections && (
          <FlexContainer direction="column" gap="xxs">
            <CollectionSelectSectionTitle>
              {t('opportunityCollections')}
            </CollectionSelectSectionTitle>
            <FlexContainer direction="column" gap="nano">
              {opportunityCollections.map(collection => (
                <CollectionItem
                  collection={collection}
                  key={collection.collectionId}
                  onClick={onCollectionClick}
                />
              ))}
            </FlexContainer>
          </FlexContainer>
        )}
        {noResults && (
          <Text color="neutralLowLight" fontSize="xxxs">
            {t('noResultsFound')}
          </Text>
        )}
      </CollectionSelectPopoverContent>
    </Popover>
  )
}

export default CollectionSelect
