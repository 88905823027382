import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface Item {
  id: string
}

interface RemoveOpportunityFeedbackState<T = Item> {
  itemToRemove: T | null
  setItemToRemove: (feedback: T | null) => void

  removingItems: string[]
  pushRemovingItem: (itemId: string) => void
  removeRemovingItem: (itemId: string) => void
  setRemovingItems: (removingItems: string[]) => void
}

const useRemovingItemsStore = createWithEqualityFn<RemoveOpportunityFeedbackState>(
  set => ({
    itemToRemove: null,
    setItemToRemove: feedback => set({ itemToRemove: feedback }),

    removingItems: [],
    pushRemovingItem: itemId => set(state => ({ removingItems: [...state.removingItems, itemId] })),
    removeRemovingItem: itemId =>
      set(state => ({
        removingItems: state.removingItems.filter(id => id !== itemId)
      })),
    setRemovingItems: removingItems => set({ removingItems })
  }),
  shallow
)

export default useRemovingItemsStore
