import React, { ReactNode } from 'react'
import { CSS } from '@/theme'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import FlexContainer from '../flex-container'
import { IconContext } from '@phosphor-icons/react'
import * as Styled from './Tabs.styles'
import Tooltip from '../tooltip'

export interface TabItem {
  text: string
  value: string
  icon?: ReactNode
  disabled?: boolean
  triggerCss?: CSS
  tooltipText?: ReactNode
}

export interface TabsProps extends TabsPrimitive.TabsProps {
  children: ReactNode
  options?: ReactNode
  tabs: TabItem[]
  width?: string | number
  label?: string
  size?: 'small' | 'regular' | 'large'
  stretch?: boolean
  fixed?: boolean
  line?: boolean
  onTabClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  css?: CSS
  triggerType?: 'default' | 'link'
}

const Tabs: React.FC<TabsProps> = ({
  width,
  label,
  children,
  options,
  tabs,
  size,
  stretch = true,
  css,
  fixed,
  line,
  triggerType = 'default',
  ...props
}) => {
  return (
    <Styled.TabsRoot {...props} css={{ width, ...css }}>
      <Styled.TabsList aria-label={label} fixed={fixed} line={line}>
        <IconContext.Provider value={{ size: 20 }}>
          {tabs.map(tab => {
            const triggerProps = {
              className: tab.value,
              css: tab.triggerCss,
              disabled: tab.disabled,
              line,
              onClick: props.onTabClick,
              size,
              stretch,
              to: tab.value,
              value: tab.value
            }
            const trigger =
              triggerType === 'default' ? (
                <Styled.TabsTrigger key={tab.value} {...triggerProps}>
                  {tab.icon && tab.icon}
                  <h2>{tab.text}</h2>
                </Styled.TabsTrigger>
              ) : (
                <Styled.TabsLinkTrigger key={tab.value} {...triggerProps} to={tab.value}>
                  {tab.icon && tab.icon}
                  <h2>{tab.text}</h2>
                </Styled.TabsLinkTrigger>
              )

            if (tab.tooltipText) {
              return (
                <Tooltip
                  css={{ maxWidth: 170 }}
                  key={tab.value}
                  side="bottom"
                  text={tab.tooltipText}
                  variant="small"
                >
                  {trigger}
                </Tooltip>
              )
            }

            return trigger
          })}
          {Boolean(options) && (
            <FlexContainer alignItems="center" css={{ marginLeft: 'auto' }}>
              {options}
            </FlexContainer>
          )}
        </IconContext.Provider>
      </Styled.TabsList>

      {children}
    </Styled.TabsRoot>
  )
}

Tabs.defaultProps = {
  width: 'unset',
  label: 'Tabs',
  size: 'regular'
}

export default Tabs
