import { useMemo } from 'react'
import useMetricsQuery from '@/hooks/metrics/useMetricsQuery'
import {
  MetricItem,
  MetricKey,
  MetricsRequests,
  RawMetricError,
  RawMetricUnit
} from '@/types/metrics'
import useSourcesQuery from '@/hooks/useSourcesQuery'
import { percentageScoreTransformer } from '@/utils/metrics/formatters'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { useTranslation } from 'react-i18next'
import useAllMetrics from './useAllMetricItems'
import useAllowedMetrics from './useAllowedMetrics'

const baseExplorationMetricsMainTypes: Record<MetricKey, MetricItem['mainMetricType']> = {
  count: 'count',
  csat: 'score',
  nps: 'score',
  review: 'avg',
  feedback_share: 'share',
  issue: 'count',
  social_media_post: 'count',
  support_ticket: 'count',
  tcsat: 'count',
  tnps: 'count',
  transcript: 'count'
}

const baseExplorationShareMetricsMainTypes: Record<MetricKey, MetricItem['mainMetricType']> = {
  count: 'count',
  csat: 'score',
  nps: 'score',
  review: 'avg',
  feedback_share: 'share',
  issue: 'share',
  social_media_post: 'share',
  support_ticket: 'share',
  tcsat: 'share',
  tnps: 'share',
  transcript: 'share'
}
const SATISFACTION_KINDS: MetricKey[] = ['csat', 'nps', 'review']

type VariationType = 'percentage' | 'points' | 'count'
type MetricResult = {
  metricKey: MetricKey
  title: string
  value: number
  currentValue: string
  previousValue: string
  variation: number
  variationType: VariationType
  metricError?: RawMetricError
} & MetricItem

interface Props {
  filters: FeedbackListQueryParams[]
  useShare?: boolean
}

const useExplorationMetrics = ({ filters = [{}], useShare }: Props) => {
  const { allMetricItems, getMetricDisplayName, getSourceAliasKind, getMainMetricByMainType } =
    useAllMetrics()

  const { isSourceAllowed } = useAllowedMetrics()

  const { values: sourcesValues, isLoading: isLoadingSources } = useSourcesQuery({
    enabled: true,
    sourceMode: 'sourceAlias'
  })

  const { t } = useTranslation()

  const allowedSources = useMemo(() => {
    const metricKeys = ['count', ...sourcesValues].filter(isSourceAllowed)
    return metricKeys
  }, [sourcesValues, isSourceAllowed])

  const baseExplorationMetrics = useMemo(() => {
    const _map: Record<string, MetricItem> = { count: allMetricItems.count }

    allowedSources.forEach(sourceValue => {
      const kind = getSourceAliasKind(sourceValue) ?? 'count'

      const metricItem = getMainMetricByMainType({
        source: sourceValue,
        mainMetricType: baseExplorationMetricsMainTypes[kind]
      })

      if (!metricItem) return
      _map[sourceValue] = metricItem
    })

    return _map
  }, [allowedSources, allMetricItems, getSourceAliasKind, getMainMetricByMainType])

  const baseExplorationMetricsShare = useMemo(() => {
    const _map: Record<string, MetricItem> = { count: allMetricItems.count }

    allowedSources.forEach(sourceValue => {
      const kind = getSourceAliasKind(sourceValue) ?? 'count'

      const metricItem = getMainMetricByMainType({
        source: sourceValue,
        mainMetricType: baseExplorationShareMetricsMainTypes[kind]
      })

      if (!metricItem) return
      _map[sourceValue] = metricItem
    })

    return _map
  }, [allowedSources, allMetricItems, getSourceAliasKind, getMainMetricByMainType])

  const satisfactionCountMetrics: Partial<Record<MetricKey, MetricItem>> = useMemo(() => {
    const _map: Partial<Record<MetricKey, MetricItem>> = {}

    allowedSources.forEach(sourceValue => {
      const kind = getSourceAliasKind(sourceValue) ?? 'count'
      if (!SATISFACTION_KINDS.includes(kind)) return

      const metricItem = getMainMetricByMainType({
        source: sourceValue,
        mainMetricType: 'count'
      })

      if (!metricItem) return
      _map[sourceValue] = metricItem
    })

    return _map
  }, [allowedSources, getMainMetricByMainType, getSourceAliasKind])

  const baseItems = useMemo(() => {
    return useShare ? baseExplorationMetricsShare : baseExplorationMetrics
  }, [useShare, baseExplorationMetricsShare, baseExplorationMetrics])

  const homeMetricList = useMemo(() => {
    const list: MetricsRequests.MetricsPayload['metric_list'] = [
      baseExplorationMetrics.count.metric
    ]

    allowedSources.forEach(source => {
      const metricOption = source as MetricKey

      const metricItem = baseItems[metricOption] ?? allMetricItems[metricOption]

      if (metricItem) {
        const kind = getSourceAliasKind(metricOption)
        const countMetric = satisfactionCountMetrics[metricOption]

        if (kind && SATISFACTION_KINDS.includes(kind) && countMetric) {
          list.push(metricItem.metric, countMetric.metric)
        } else {
          list.push(metricItem.metric)
        }
      }
    })

    return list
  }, [
    allowedSources,
    baseItems,
    satisfactionCountMetrics,
    baseExplorationMetrics,
    allMetricItems,
    getSourceAliasKind
  ])

  const { data, isLoading } = useMetricsQuery({
    filters,
    sources: sourcesValues,
    metricList: homeMetricList,
    enabled: filters.length > 0
  })

  const metrics = useMemo(() => {
    const homeMetricsData = data?.[0] ?? []

    const _metrics = allowedSources.map(baseMetricKey => {
      const baseMetric = baseItems[baseMetricKey]

      if (!baseMetric) return
      const rawMetric = homeMetricsData.find(
        rawMetric => rawMetric.label === baseMetric.metric.label
      )

      if (!rawMetric) return null

      const getValue = (value: number) => {
        const transformed = baseMetric.numberTransformer(value)
        return baseMetric.formatter(transformed)
      }

      const title = baseMetricKey === 'count' ? t('overall') : getMetricDisplayName(baseMetricKey)
      let variationType: VariationType = 'count'

      const percentageUnits: RawMetricUnit[] = ['count', 'percentage']
      const absUnits: RawMetricUnit[] = ['rating']
      const pointsUnits: RawMetricUnit[] = ['score']

      if (percentageUnits.includes(rawMetric.unit)) variationType = 'percentage'
      if (absUnits.includes(rawMetric.unit)) variationType = 'count'
      if (pointsUnits.includes(rawMetric.unit)) variationType = 'points'

      const getVariation = () => {
        if (variationType === 'percentage')
          return percentageScoreTransformer(rawMetric.percentage_variation)

        if (variationType === 'points')
          return percentageScoreTransformer(rawMetric.absolute_variation)

        return baseMetric.numberTransformer(rawMetric.absolute_variation)
      }

      return {
        ...baseMetric,
        title,
        metricKey: baseMetricKey,
        currentValue: getValue(rawMetric.current_value),
        previousValue: getValue(rawMetric.previous_value),
        variation: getVariation(),
        value: baseMetric.numberTransformer(rawMetric.current_value),
        variationType,
        metricError: rawMetric.error
      } as MetricResult
    })

    return _metrics.filter(Boolean) as MetricResult[]
  }, [data, baseItems, t, allowedSources, getMetricDisplayName])

  const satisfactionCountItems = useMemo(() => {
    const homeMetricsData = data?.[0] ?? []

    const metrics = sourcesValues.map(baseMetricKey => {
      const baseMetric = satisfactionCountMetrics[baseMetricKey]

      if (!baseMetric) return null

      const rawMetric = homeMetricsData.find(
        rawMetric => rawMetric.label === baseMetric.metric.label
      )

      if (!rawMetric) return null

      const getValue = (value: number) => {
        const transformed = baseMetric.numberTransformer(value)
        return baseMetric.formatter(transformed)
      }

      return {
        ...baseMetric,
        metricKey: baseMetricKey,
        currentValue: getValue(rawMetric.current_value),
        value: baseMetric.numberTransformer(rawMetric.current_value)
      } as MetricResult
    })

    return metrics.filter(Boolean) as MetricResult[]
  }, [data, satisfactionCountMetrics, sourcesValues])

  return { isLoading: isLoading || isLoadingSources, metrics, satisfactionCountItems }
}

export default useExplorationMetrics
