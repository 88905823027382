import Button from '@/components/atoms/button'
import Dialog from '@/components/atoms/dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import TopicChip from '@/components/atoms/topic-chip'
import useTopicsStore from '@/store/useTopicsStore'
import { ChatCenteredText, GitMerge, Info, Notepad } from '@phosphor-icons/react'
import { useEffect, useMemo, useState } from 'react'
import { shallow } from 'zustand/shallow'
import {
  MergedTopicCount,
  MergedTopicCountContainer,
  MergedTopicInput,
  ModalContainer,
  ModalSection
} from './MergeTopicsModal.styles'
import MergingTopicRow from './MergingTopicRow'
import TopicService, { topicErrors } from '@/services/TopicService'
import { TopicData, TopicThemeItem } from '@/types/classification'
import useToastMessageStore from '@/store/useToastMessageStore'
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'
import { useFiltersStore } from '@/store'
import { TopicCountingPayload } from '@/types/classification/TopicRequests'
import Tooltip from '@/components/atoms/tooltip'
import useLogging from '@/hooks/useLogging'
import { makeUniqueArray } from '@/utils/array'

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  onSuccesMerge: () => void
}

const MergeTopicsModal = ({ open, onOpenChange, onSuccesMerge }: Props) => {
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const { logException } = useLogging({ context: 'merge-topics-modal' })

  const toTopicStatsPayload = useFiltersStore(state => state.toTopicStatsPayload)

  const { topics } = useTopicsStore(
    state => ({
      topics: state.checkedTopics
    }),
    shallow
  )
  const storeMergeTopic = useTopicsStore(state => state.mergeTopics)

  const [isMerging, setIsMerging] = useState(false)

  const [topicInput, setTopicInput] = useState(topics[0]?.topicName ?? '')
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTopicInput(e.target.value)
  }

  const [topicsToMerge, setTopicsToMerge] = useState(topics.map(topic => topic.topicId))
  const [isCountingFrequency, setIsCountingFrequency] = useState(false)
  const [estimatedFrequency, setEstimatedFrequency] = useState(topics[0]?.totalFrequency ?? 0)

  useEffect(() => {
    if (open && topics.length) {
      const maxFrequency = Math.max(...topics.map(topic => topic.totalFrequency))
      const highestFrequencyTopic =
        topics.find(topic => topic.totalFrequency === maxFrequency) || topics[0]

      setTopicInput(highestFrequencyTopic?.topicName ?? '')
      setTopicsToMerge(topics.map(topic => topic.topicId))
      setEstimatedFrequency(highestFrequencyTopic?.totalFrequency ?? 0)
      setIsCountingFrequency(true)
    }
  }, [open, topics])

  useEffect(() => {
    if (topicsToMerge.length <= 1) {
      setTimeout(() => {
        onOpenChange(false)
      }, 400)
    }
  }, [topicsToMerge, onOpenChange])

  useDidUpdateEffect(() => {
    let controller: AbortController | null = null
    const countFrequency = async () => {
      if (topicsToMerge.length && open) {
        setIsCountingFrequency(true)
        controller = new AbortController()

        const topicsPayload = toTopicStatsPayload(0, 200)
        const payload: TopicCountingPayload = {
          topic_list: topicsToMerge,
          feedback_search_schema: topicsPayload.feedback_search_schema,
          count_by: 'ingested_feedback_id',
          exact_count: false,
          sentiment_count: false,
          sentiment_exact_count: false
        }

        const [error, data] = await TopicService.getTopicsCount(
          topicsToMerge,
          false,
          payload,
          controller.signal
        )
        setIsCountingFrequency(false)
        if (error) {
          logException(error, { message: 'Failed to count topics frequency' })
          !error.isCanceledError && setEstimatedFrequency(topics[0]?.totalFrequency ?? 0)
          return
        }

        setEstimatedFrequency(data.frequency)
      }
    }

    countFrequency()

    return () => {
      if (controller) controller.abort()
    }
  }, [topicsToMerge, open])

  const isIncludedToMerge = (topicId: string) => topicsToMerge.includes(topicId)

  const onClickMergingRow = (topic: TopicData) => () => {
    if (isIncludedToMerge(topic.topicId)) {
      setTopicsToMerge(prevTopics => prevTopics.filter(prevTopic => prevTopic !== topic.topicId))
    } else {
      setTopicsToMerge(prevTopics => [...prevTopics, topic.topicId])
    }
  }

  const filteredThemes = useMemo(() => {
    const _filtered = topics
      .filter(topic => Boolean(topic.themeList))
      .flatMap(topic => topic.themeList) as TopicThemeItem[]

    return makeUniqueArray('themeId', _filtered)
  }, [topics])

  const disabled = !topicInput.trim().length || topicsToMerge.length < 2 || isMerging

  const onMerge = async () => {
    if (disabled) return
    setIsMerging(true)
    const [error, data] = await TopicService.mergeTopic(topicInput.trim(), topicsToMerge)

    if (error) {
      setIsMerging(false)
      const message = 'Failed to merge topics.'
      logException(error, { message })
      addErrorToast({
        text: topicErrors[error.key] || error.message || message,
        duration: 3000
      })

      return
    }

    setIsMerging(false)
    storeMergeTopic({
      newTopicId: data.topic_id,
      newTopicName: data.name,
      mergedTopics: topicsToMerge,
      newFrequency: estimatedFrequency
    })
    addSuccessToast({ text: 'Topics successfully merged.' })
    onSuccesMerge()
    onOpenChange(false)
  }

  return (
    <Dialog
      align="top-center"
      closeIcon
      modal={false}
      onOpenChange={onOpenChange}
      open={open}
      width="medium"
    >
      <ModalContainer>
        <Text as="h1" typeface="titleBoldXXS">
          Merge topics
        </Text>
        <FlexContainer direction="column" gap="xxs">
          <ModalSection>
            <Notepad />
            <h2>Topics you want to merge into 1 topic:</h2>
          </ModalSection>
          <FlexContainer
            css={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: 135 }}
            direction="column"
            gap="micro"
          >
            {topics.map(topic => (
              <MergingTopicRow
                key={topic.topicId}
                onClick={onClickMergingRow(topic)}
                removed={!isIncludedToMerge(topic.topicId)}
                topic={topic}
              />
            ))}
          </FlexContainer>
        </FlexContainer>
        <Divider line="solid" />
        <FlexContainer direction="column" gap="xxs">
          <ModalSection>
            <GitMerge />
            <h2>Merged topic will be:</h2>
          </ModalSection>
          <FlexContainer
            alignItems="center"
            css={{ position: 'relative' }}
            justifyContent="flexEnd"
          >
            <MergedTopicInput onChange={onChangeInput} value={topicInput} />
            {!isCountingFrequency && (
              <MergedTopicCountContainer>
                <Tooltip
                  css={{ textAlign: 'center' }}
                  side="bottom"
                  text="This number is not necessary a sum of merged topics. Some topics may coexist within the same feedback"
                  variant="small"
                >
                  <Info weight="fill" />
                </Tooltip>
                <MergedTopicCount>
                  <ChatCenteredText />
                  <span>{estimatedFrequency}</span>
                </MergedTopicCount>
              </MergedTopicCountContainer>
            )}
          </FlexContainer>
          {filteredThemes.length > 0 && (
            <>
              <ModalSection>
                <h2>Topic groups associated with merged topic:</h2>
              </ModalSection>
              <FlexContainer
                alignItems="center"
                css={{ overflow: 'auto', maxHeight: 100 }}
                gap="micro"
                wrap="wrap"
              >
                {filteredThemes.map(theme => (
                  <TopicChip
                    css={{ width: 'fit-content' }}
                    dismissable={false}
                    ghost
                    key={theme.themeId}
                    label={theme.themeName}
                  />
                ))}
              </FlexContainer>
            </>
          )}
        </FlexContainer>
        <Divider line="solid" />
        <FlexContainer alignItems="center" gap="micro">
          <Button disabled={disabled} onClick={onMerge} size="small">
            Merge topics
          </Button>
          <Button onClick={() => onOpenChange(false)} size="small" variant="flat">
            Cancel
          </Button>
        </FlexContainer>
      </ModalContainer>
    </Dialog>
  )
}

export default MergeTopicsModal
