import Bone from '@/components/atoms/bone'

interface ClassificationSkeletonProps {
  size?: number
}

function ClassificationSkeleton({ size = 6 }: ClassificationSkeletonProps) {
  return (
    <>
      {Array(size)
        .fill(null)
        .map((_, index) => (
          <Bone css={{ height: 24, width: 316, borderRadius: 4 }} key={index} />
        ))}
    </>
  )
}

export default ClassificationSkeleton
