import useEvents from '@/hooks/useEvents'
import FilterItem, { FilterItemButton } from '../../AppliedFilters/FilterItem'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import {
  AppliedFiltersDefaultProps,
  defaultAppliedFilterProps,
  disabledAreaFilterTooltipText
} from '../AppliedFilters.types'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { useMemo } from 'react'

function AppliedSearchFilters(props: AppliedFiltersDefaultProps = defaultAppliedFilterProps) {
  const { splitMode } = props
  const search = useFeedFiltersStore(state => state.search)
  const areaSearch = useFeedFiltersStore(state => state.areaSearch)
  const setSearch = useFeedFiltersStore(state => state.setSearch)
  const setAreaSearch = useFeedFiltersStore(state => state.setAreaSearch)

  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)

  const { dispatch } = useEvents()

  const isAreaSearch = !currentInterestArea ? false : splitMode === 'onlyDefaultFilters'
  const disabled = currentInterestArea && splitMode === 'onlyAreaOfInterestFilters'

  function removeFilter() {
    let setFn = setSearch
    if (!currentInterestArea) return setFn('')

    if (isAreaSearch) setFn = setAreaSearch
    setFn('')
  }

  function onClick() {
    dispatch('focus-search-filter', {})
  }

  const currentSearch = useMemo(() => {
    if (!currentInterestArea) return search

    if (isAreaSearch) return areaSearch

    return search
  }, [isAreaSearch, search, areaSearch, currentInterestArea])

  return [
    currentSearch.length > 0 ? (
      <FilterItem
        disabled={disabled}
        disabledTooltipText={disabledAreaFilterTooltipText}
        editButton={
          <FilterItemButton
            content={currentSearch}
            contentType="label"
            disabled={disabled}
            onClick={onClick}
            tooltip={currentSearch}
          />
        }
        name="Search"
        onDelete={removeFilter}
        key="search"
      />
    ) : null
  ].filter(Boolean) as JSX.Element[]
}

export default AppliedSearchFilters
