import { styled } from '@/theme'
import { growAnimation } from '@/theme/animations'

const FrequencyBarContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '$neutralLowLight',
  gap: 8,
  fontWeight: 400,
  fontSize: 12
})

const Bar = styled('div', {
  borderRadius: 8,
  // flex: 1,
  ...growAnimation,

  variants: {
    size: {
      regular: {
        height: 8
      },
      small: {
        height: 4
      }
    },
    type: {
      default: {
        backgroundColor: '$feedbackInformativePure'
      },
      muted: {
        backgroundColor: '#E4E4E4'
      },
      positive: {
        backgroundColor: '$feedbackPositivePure'
      },
      neutral: {
        backgroundColor: '$neutralLowLight'
      },
      negative: {
        backgroundColor: '$feedbackNegativePure'
      }
    }
  },

  defaultVariants: {
    type: 'default',
    size: 'regular'
  }
})

interface FrequencyBarProps {
  max?: number
  size?: 'regular' | 'small'
  type?: 'default' | 'muted' | 'positive' | 'neutral' | 'negative'
  hideNumber?: boolean
  percentage: number
}

function FrequencyBar({
  percentage,
  max,
  type = 'default',
  size = 'regular',
  hideNumber = false
}: FrequencyBarProps) {
  const widthPercentage = max ? (percentage / max) * 100 : percentage

  return (
    <FrequencyBarContainer>
      <Bar css={{ width: widthPercentage + '%' }} size={size} type={type} />
      {!hideNumber && <span>{Math.round(percentage)}%</span>}
    </FrequencyBarContainer>
  )
}

export default FrequencyBar
