import Button from '@/components/atoms/button'
import Popover from '@/components/atoms/popover'
import { Close } from '@radix-ui/react-popover'
import { memo, ReactNode, useRef, useState } from 'react'
import TopicGroupEditor, { TopicGroupEditorProps } from './TopicGroupEditor'
import useIsInViewport from '@/hooks/useIsInViewport'
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'

interface Props
  extends Omit<
    TopicGroupEditorProps,
    'renderApplyButton' | 'renderCancelButton' | 'isOverflowing'
  > {
  children: ReactNode
  customButton?: boolean
  defaultOpen?: boolean
}

const TopicGroupEditorPopover = ({
  children,
  customButton,
  defaultOpen,
  onCancel,
  ...props
}: Props) => {
  const [open, setOpen] = useState(defaultOpen)
  const [isOverflowing, setIsOverflowing] = useState(false)

  const onOpenChange = (open: boolean) => {
    if (!open) {
      onCancel?.()
    }
    setOpen(open)
  }
  const buttonRef = useRef<HTMLButtonElement>(null)

  const isInViewPort = useIsInViewport(buttonRef)

  useDidUpdateEffect(() => {
    if (open && !isInViewPort) {
      // IS OUTSIDE OF VIEWPORT
      setIsOverflowing(true)
    } else if (!open) {
      setIsOverflowing(false)
    }
  }, [open, isInViewPort])

  return (
    <Popover
      active={false}
      buttonChildren={children}
      customButton={customButton}
      defaultOpen={defaultOpen}
      iconButtonCss={{ padding: 0, height: 'auto', minWidth: 'auto' }}
      modal
      onOpenChange={onOpenChange}
      open={open}
      text
    >
      <TopicGroupEditor
        {...props}
        isOverflowing={isOverflowing}
        onCancel={onCancel}
        renderApplyButton={(handleOnApply, isDisabled) => (
          <Button disabled={isDisabled} onClick={handleOnApply} ref={buttonRef} size="small">
            Apply
          </Button>
        )}
        renderCancelButton={handleOnCancel => (
          <Close asChild>
            <Button onClick={handleOnCancel} size="small" variant="flat">
              Cancel
            </Button>
          </Close>
        )}
      />
    </Popover>
  )
}

export default memo(TopicGroupEditorPopover)
