import { useState } from 'react'
import { FilterItemDropdown } from '../../AppliedFilters/FilterItem'
import StringFilterContent from '../../FiltersSidebar/StringGenericFilter/StringFilterContentNew'
import FiltersSkeleton from '../../FiltersSidebar/AddFilterMenu/FiltersSkeleton'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'
import useEnumAdvancedFilter from '@/hooks/advancedFilters/useEnumAdvancedFilter'
import useAllMetrics from '@/hooks/metrics/useAllMetricItems'

interface Props {
  value: string[]
  index: number
  disabled?: boolean
}

const SourceFilterApplied = ({ value, index, disabled = false }: Props) => {
  const [open, setOpen] = useState(false)

  const { getMetricDisplayName } = useAllMetrics()

  const { selectOption, isChecked, options, isLoading, update } = useEnumAdvancedFilter({
    name: 'source_alias',
    path: 'source_alias',
    enableQuery: open,
    index,
    initialValue: value
  })

  const { t } = useTranslation()

  const count = value.length
  const valueTextArray = value.map(v => `<b>${getMetricDisplayName(v)}</b>`).join(t('or'))
  const text = i18n.t('isValueTextArray', { valueTextArray })

  return (
    <FilterItemDropdown
      buttonDisabled={disabled}
      content={count}
      contentType="count"
      disabled={isLoading || disabled}
      dropdown={
        <>
          <StringFilterContent
            isChecked={isChecked}
            options={options}
            selectOption={selectOption}
            textFormatter={getMetricDisplayName}
          />
          {isLoading && <FiltersSkeleton />}
        </>
      }
      onApply={update}
      onOpenChange={setOpen}
      open={open}
      title={t('source')}
      tooltip={text}
    />
  )
}

export default SourceFilterApplied
