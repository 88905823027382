import { RequestReturnType } from '@/services/RequestHandlers/NewRequest'
import { AreaOfInterestData } from '@/types/area/AreaOfInterest'
import { FilterNode } from '@/types/filters/AdvancedFilters'
import { MetricsRequests, RawMetric } from '@/types/metrics'
import getAreaFiltersCombinedFn from './getAreaFiltersCombinedFn'
import { fetchMetricsListInChunks } from '../metrics/metricsQueryUtils'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'

interface Params {
  areas: AreaOfInterestData[]
  metricList: MetricsRequests.MetricListPayloadItem[]
  startDate?: string
  endDate?: string
  currentFilters?: FilterNode
  opportunityId?: string
  onProgress?: (chunkLength: number, completed: number) => void
}

const getMetricsForAreasFn = async ({
  areas,
  metricList,
  startDate,
  endDate,
  currentFilters,
  opportunityId,
  onProgress
}: Params): Promise<RequestReturnType<AreaOfInterestData[]>> => {
  let filterList: FeedbackListQueryParams[]

  if (currentFilters) {
    const contextsData = await getAreaFiltersCombinedFn({
      areas,
      currentFilters
    })

    filterList = contextsData.map(item => ({
      context: item.context,
      opportunity_id: opportunityId
    }))
  } else {
    filterList = areas.map(area => ({
      context: area.context,
      opportunity_id: opportunityId
    }))
  }

  const responses = await fetchMetricsListInChunks({
    filterList,
    metricList,
    startDate,
    endDate,
    onProgress
  })
  const someResultWithError = responses.find(response => response[0])
  if (someResultWithError && someResultWithError[0]) {
    throw [someResultWithError[0], undefined]
  }

  const areasMetrics = responses.flatMap(response => response[1]) as RawMetric[][]

  const newAreas = areas.map((item, index) => {
    return {
      ...item,
      metrics: areasMetrics[index]
    }
  }) as AreaOfInterestData[]

  return [undefined, newAreas]
}

export default getMetricsForAreasFn
