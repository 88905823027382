import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Spinner from '@/components/atoms/loader/Spinner'
import Text from '@/components/atoms/text'
import { styled } from '@/theme'
import { WarningCircle } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'

const LoadingContainer = styled('div', {
  background: '$neutralHighMedium',
  bAll: '$neutralLowLight',
  padding: '8px 12px',
  borderRadius: 4,

  display: 'flex',
  alignItems: 'center',
  gap: 32
})

export interface SummaryLoadingProps {
  error?: boolean
  onRetry?: () => void
  onCancel?: () => void
}

const SummaryLoading = ({ error, onRetry, onCancel }: SummaryLoadingProps) => {
  const onRetryClick = () => {
    onRetry?.()
  }

  const onCancelClick = () => {
    onCancel?.()
  }
  const { t } = useTranslation()

  if (error) {
    return (
      <LoadingContainer>
        <WarningCircle size={40} weight="light" />
        <FlexContainer direction="column">
          <Text color="neutralLowLight" fontSize="xxxs">
            {t('somethingWentWrongWhileSummarizing')}
          </Text>
          <FlexContainer alignItems="center" gap="micro">
            <Button fontWeight="bold" onClick={onRetryClick} variant="link">
              {t('retry')}
            </Button>
            <span> - </span>
            <Button fontWeight="regular" onClick={onCancelClick} variant="link">
              {t('cancel')}
            </Button>
          </FlexContainer>
        </FlexContainer>
      </LoadingContainer>
    )
  }

  return (
    <LoadingContainer>
      <Spinner />
      <FlexContainer direction="column">
        <Text fontSize="xxxs">{t('summarizing')}</Text>
        <Text color="neutralLowLight" fontSize="xxxs">
          {t('sitTightWeAreWorkingToGenerateASummaryForYouItWillBeReadyInAFewSeconds')}
        </Text>
      </FlexContainer>
    </LoadingContainer>
  )
}

export default SummaryLoading
