import Input, { InputErrorMessage } from '@/components/atoms/input'
import Select, { SelectOption } from '@/components/atoms/select'
import { useMemo } from 'react'
import { FilterComponentProps } from './types'
import { GenericFilter, TextFilterOption } from '@/types/filters/AdvancedFilters'
import useQueryValidation from '@/hooks/useQueryValidation'
import { WarningCircle } from '@phosphor-icons/react'
import FlexContainer from '@/components/atoms/flex-container'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

const selectOptions: SelectOption<TextFilterOption>[] = [
  {
    value: 'contains',
    text: i18n.t('contains')
  },
  {
    value: 'not_contains',
    text: i18n.t('doesNotContain')
  }
]

const TextFilterRow = ({ filter, onFilterChange }: FilterComponentProps) => {
  const value = useMemo(() => {
    if (typeof filter.value !== 'string') {
      return filter.value as string
    }

    if ((filter.value as string).startsWith('!(') && (filter.value as string).endsWith(')')) {
      return (filter.value as string).slice(2, -1)
    }

    return filter.value as string
  }, [filter])

  const option = useMemo(() => {
    if (typeof filter.value !== 'string') {
      return 'contains'
    }

    if ((filter.value as string).startsWith('!(') && (filter.value as string).endsWith(')')) {
      return 'not_contains'
    }

    return 'contains'
  }, [filter])

  useQueryValidation(isValid => {
    onFilterChange({ ...filter, status: isValid ? 'valid' : 'invalid' })
  }, value)

  const onValueChange = (v: string) => {
    const newValue = option === 'not_contains' ? `!(${v})` : v
    const newFilter: GenericFilter = { ...filter, value: newValue, status: 'validating' }
    onFilterChange(newFilter)
  }

  const onOptionChange = (v: TextFilterOption) => {
    const newValue = v === 'not_contains' ? `!(${value})` : value
    const newFilter: GenericFilter = { ...filter, value: newValue }
    onFilterChange(newFilter)
  }
  const { t } = useTranslation()

  return (
    <>
      <Select
        onValueChange={onOptionChange}
        options={selectOptions}
        small
        value={option}
        width="100%"
      />
      <FlexContainer css={{ position: 'relative' }} direction="column" fullWidth gap="nano">
        <Input
          css={{ height: 32 }}
          error={filter.status === 'invalid'}
          onChange={e => onValueChange(e.currentTarget.value)}
          placeholder={t('value')}
          value={value}
        />

        {filter.status === 'invalid' && (
          <InputErrorMessage>
            <FlexContainer alignItems="center" gap="nano">
              <WarningCircle size={14} />
              <span>{t('invalidText')}</span>
            </FlexContainer>
          </InputErrorMessage>
        )}
      </FlexContainer>
    </>
  )
}

export default TextFilterRow
