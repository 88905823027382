import { useCallback, useMemo } from 'react'
import useCollections from '../collections/useCollections'
import { getParamsFromFilterContent } from '@/utils/filters'
import { SavedFilterContent } from '@/types/filters/Filters'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useFeedQueryParams from '../feedback/new/useFeedQueryParams'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { MetricCustomizationValue, MetricListPayloadItem } from '@/types/metrics/MetricsRequests'
import {
  AREA_METRICS,
  buildMetricKey,
  COLLECTION_METRICS,
  NPS_TIMESERIES_METRICS_KEYS,
  OVERALL_METRIC_KEYS,
  SUPPORT_TICKET_TIMESERIES_METRICS_KEYS
} from '@/utils/metrics'
import { BreakdownOption } from '@/types/time-series/TimeSeries'
import { AllMetricsKey, MetricKey, TicketsSatisfactionMetricKey } from '@/types/metrics'
import {
  TICKET_CSAT_METRIC_KEYS,
  TICKET_NPS_METRIC_KEYS,
  TICKET_SATISFACTION_METRICS_KEYS
} from '@/utils/metrics/ticketsSatisfactionMetrics'
import useTicketSurveyType from '../useTicketSurveyType'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import useSourcesQuery from '../useSourcesQuery'
import { shallow } from 'zustand/shallow'
import { useUserStore } from '@/store'
import useAllMetrics from './useAllMetricItems'

const useMetricListPayload = () => {
  const { contentData } = useCollections()
  const { currentInterestArea } = useCurrentInterestAreaStore()

  const { queryParams } = useFeedQueryParams()

  const organization = useUserStore(state => state.organization, shallow)

  const { hasTicketSurveyType, surveyTypeIsCSAT, surveyTypeIsNPS } = useTicketSurveyType({
    enabled: true
  })

  const allowedMetricsBySource = useHiddenMetricsStore(state => state.allowedMetricsBySource)

  const { values: sourceAliasValues } = useSourcesQuery({
    enabled: true,
    sourceMode: 'sourceAlias'
  })

  const { allMetricItems, allMetricItemsList, metricKeyToAllMetricsKeys, orgMetrics } =
    useAllMetrics()

  const collectionShareFilter = useMemo(() => {
    const rawContent = contentData?.content
    const content = Array.isArray(rawContent) ? (rawContent as SavedFilterContent[]) : []

    return {
      ...getParamsFromFilterContent(content),
      context: contentData?.context,
      label: undefined,
      'posted_at.lt': undefined,
      'posted_at.gte': undefined
    }
  }, [contentData])

  const areaShareFilter = useMemo(() => {
    if (!currentInterestArea) return {}

    return {
      context: currentInterestArea?.context,
      label: undefined,
      'posted_at.lt': undefined,
      'posted_at.gte': undefined
    } as FeedbackListQueryParams
  }, [currentInterestArea])

  const isCollectionMetric = useCallback((metricKey: AllMetricsKey) => {
    return COLLECTION_METRICS.includes(metricKey) || metricKey.includes('over_collection')
  }, [])

  const isAreaMetric = useCallback((metricKey: AllMetricsKey) => {
    return AREA_METRICS.includes(metricKey) || metricKey.includes('over_area')
  }, [])

  const getMetricKeys = useCallback(
    (
      metricKey: MetricKey,
      options?: {
        breakdown?: BreakdownOption
        specificBreakdowns?: BreakdownOption[]
        enableShareOverFilters?: boolean
        useTimeseriesMetrics?: boolean
        returnAll?: boolean
        returnCollectionAndAreaMetrics?: boolean
      }
    ) => {
      // all keys are on the list by default
      let list: AllMetricsKey[] = metricKeyToAllMetricsKeys[metricKey]

      if (!list) return

      if (options?.returnAll) return list
      const metricItem = allMetricItems[metricKey]
      // if (metricItem?.baseKind === 'support_ticket' || metricKey === 'support_ticket') {
      //   console.log({ metricItem, list })
      // }
      if (metricKey === 'support_ticket' || metricItem?.baseKind === 'support_ticket') {
        const supportTicketMetrics = options?.useTimeseriesMetrics
          ? SUPPORT_TICKET_TIMESERIES_METRICS_KEYS
          : list

        const hasContactRatePremise =
          Object.keys(organization?.config?.activeUsersByDate ?? {}).length > 0

        let supportTicketsList = supportTicketMetrics

        const contactRateMetric =
          metricKey === 'support_ticket'
            ? 'ticket_contact_rate'
            : buildMetricKey({
                key: 'ticket_contact_rate',
                source: metricKey,
                kind: 'support_ticket'
              })

        const ticketCostMetric =
          metricKey === 'support_ticket'
            ? 'support_ticket_cost'
            : buildMetricKey({
                key: 'support_ticket_cost',
                source: metricKey,
                kind: 'support_ticket'
              })

        const potentialSavingsMetric =
          metricKey === 'support_ticket'
            ? 'support_ticket_potential_ticket_savings'
            : buildMetricKey({
                key: 'support_ticket_potential_ticket_savings',
                source: metricKey,
                kind: 'support_ticket'
              })

        if (!options?.useTimeseriesMetrics) {
          supportTicketsList = supportTicketsList.filter(key => key !== ticketCostMetric)
        } else {
          supportTicketsList = supportTicketsList
            .filter(key => key !== potentialSavingsMetric)
            .map(key => {
              return metricKey === 'support_ticket'
                ? key
                : buildMetricKey({
                    key,
                    source: metricKey,
                    kind: 'support_ticket'
                  })
            })
        }

        if (!hasContactRatePremise) {
          supportTicketsList = supportTicketsList.filter(key => key !== contactRateMetric)
        }

        const ticketSatisfactionKeys = TICKET_SATISFACTION_METRICS_KEYS.map(key =>
          metricKey === 'support_ticket'
            ? key
            : buildMetricKey({ key, source: metricKey, kind: 'support_ticket' })
        )
        const tcsatMetricKeys = TICKET_CSAT_METRIC_KEYS.map(key =>
          metricKey === 'support_ticket'
            ? key
            : buildMetricKey({ key, source: metricKey, kind: 'support_ticket' })
        )
        const tnpsMetricKeys = TICKET_NPS_METRIC_KEYS.map(key =>
          metricKey === 'support_ticket'
            ? key
            : buildMetricKey({ key, source: metricKey, kind: 'support_ticket' })
        )

        if (!hasTicketSurveyType) {
          supportTicketsList = supportTicketsList.filter(
            key => !ticketSatisfactionKeys.includes(key as TicketsSatisfactionMetricKey)
          )
        } else if (!surveyTypeIsCSAT) {
          supportTicketsList = supportTicketsList.filter(
            key => !tcsatMetricKeys.includes(key as TicketsSatisfactionMetricKey)
          )
        } else if (!surveyTypeIsNPS) {
          supportTicketsList = supportTicketsList.filter(
            key => !tnpsMetricKeys.includes(key as TicketsSatisfactionMetricKey)
          )
        }

        list = supportTicketsList
      }
      if (
        (metricKey === 'nps' || metricItem?.baseKind === 'nps') &&
        options?.useTimeseriesMetrics &&
        !options.returnAll
      ) {
        if (orgMetrics && orgMetrics.length) {
          list = [
            ...NPS_TIMESERIES_METRICS_KEYS,
            ...orgMetrics
              .filter(orgMetric => orgMetric.source === metricKey)
              .map(orgMetric => orgMetric.metricKey)
          ]
        } else {
          list = NPS_TIMESERIES_METRICS_KEYS
        }

        list = list.map(key => {
          return metricKey === 'nps' ? key : buildMetricKey({ key, source: metricKey, kind: 'nps' })
        })
      }

      if (
        metricKey === 'count' &&
        options?.breakdown &&
        options?.specificBreakdowns?.includes(options.breakdown) &&
        options?.enableShareOverFilters
      ) {
        const subMetrics = [...OVERALL_METRIC_KEYS]
        const defaultFeedbackShareIndex = subMetrics.findIndex(
          metricKey => metricKey === 'feedback_share'
        )

        subMetrics.splice(defaultFeedbackShareIndex + 1, 0, 'feedback_share_group')
        list = subMetrics
      }

      if (!contentData && !options?.returnCollectionAndAreaMetrics) {
        list = list.filter(item => !isCollectionMetric(item))
      }

      if (!currentInterestArea && !options?.returnCollectionAndAreaMetrics) {
        list = list.filter(item => !isAreaMetric(item))
      }

      return list
    },
    [
      organization,
      contentData,
      currentInterestArea,
      hasTicketSurveyType,
      surveyTypeIsCSAT,
      surveyTypeIsNPS,
      metricKeyToAllMetricsKeys,
      orgMetrics,
      allMetricItems,
      isCollectionMetric,
      isAreaMetric
    ]
  )

  const getMetricAndAddShareFilter = useCallback(
    (metricKey: AllMetricsKey): MetricListPayloadItem => {
      const metricItem = allMetricItems[metricKey]

      if (isCollectionMetric(metricKey) && contentData) {
        return {
          ...metricItem.metric,
          share_filter: {
            ...metricItem.metric.share_filter,
            ...collectionShareFilter,
            label: undefined,
            'posted_at.lt': undefined,
            'posted_at.gte': undefined
          }
        }
      }

      if (isAreaMetric(metricKey) && currentInterestArea) {
        return {
          ...metricItem.metric,
          share_filter: {
            ...metricItem.metric.share_filter,
            ...areaShareFilter,
            label: undefined,
            'posted_at.lt': undefined,
            'posted_at.gte': undefined
          }
        }
      }

      if (metricKey === 'feedback_share_group') {
        return {
          ...metricItem.metric,
          share_filter: {
            ...metricItem.metric.share_filter,
            ...queryParams,
            label: undefined,
            'posted_at.lt': undefined,
            'posted_at.gte': undefined
          }
        }
      }

      return {
        ...metricItem.metric
      }
    },
    [
      isAreaMetric,
      isCollectionMetric,
      areaShareFilter,
      collectionShareFilter,
      queryParams,
      contentData,
      currentInterestArea,
      allMetricItems
    ]
  )

  const addShareFiltersToMetrics = useCallback(
    (metricList: MetricListPayloadItem[]) => {
      const metricListWithShareFilters: (MetricListPayloadItem | null)[] = metricList.map(
        metricPayloadItem => {
          const metricItem = allMetricItemsList.find(
            item => item.metric.label === metricPayloadItem.label
          )
          if (!metricItem)
            return {
              ...metricPayloadItem
            }

          if (isCollectionMetric(metricItem.key)) {
            if (!contentData) return null
            return {
              ...metricPayloadItem,
              share_filter: {
                ...metricPayloadItem.share_filter,
                ...collectionShareFilter,
                label: undefined,
                'posted_at.lt': undefined,
                'posted_at.gte': undefined
              }
            }
          }

          if (isAreaMetric(metricItem.key)) {
            if (!currentInterestArea) return null
            return {
              ...metricPayloadItem,
              share_filter: {
                ...metricPayloadItem.share_filter,
                ...areaShareFilter,
                label: undefined,
                'posted_at.lt': undefined,
                'posted_at.gte': undefined
              }
            }
          }

          if (metricItem.key === 'feedback_share_group') {
            return {
              ...metricPayloadItem,
              share_filter: {
                ...metricPayloadItem.share_filter,
                ...queryParams,
                label: undefined,
                'posted_at.lt': undefined,
                'posted_at.gte': undefined
              }
            }
          }

          return {
            ...metricPayloadItem
          }
        }
      )

      return metricListWithShareFilters.filter(Boolean) as MetricListPayloadItem[]
    },
    [
      areaShareFilter,
      collectionShareFilter,
      queryParams,
      contentData,
      currentInterestArea,
      allMetricItemsList,
      isAreaMetric,
      isCollectionMetric
    ]
  )

  const handleCustomMetricVariables = useCallback(
    (
      variables: MetricCustomizationValue['variables'],
      metricFor: 'area' | 'org' | 'opportunity' | 'segmentation'
    ) => {
      if (!variables) return variables
      const newVariables: MetricCustomizationValue['variables'] = {}

      Object.entries(variables).forEach(([key, variableFilter]) => {
        if (metricFor === 'org') {
          newVariables[key] = {
            ...variableFilter,
            context: variableFilter.context === 'organization' ? null : variableFilter.context,
            opportunity_id: undefined
          }
        }

        if (metricFor === 'area') {
          newVariables[key] = {
            ...variableFilter,
            context: variableFilter.context === 'organization' ? null : variableFilter.context,
            opportunity_id: undefined
          }
        }

        if (metricFor === 'opportunity') {
          newVariables[key] = {
            ...variableFilter,
            context: variableFilter.context === 'organization' ? null : variableFilter.context
          }
        }

        if (metricFor === 'segmentation') {
          newVariables[key] = {
            ...variableFilter,
            context: variableFilter.context === 'organization' ? null : variableFilter.context
          }
        }
      })

      return newVariables
    },
    []
  )

  const getMetricList = useCallback(
    (params: {
      includePreviousValue?: boolean
      customSources?: string[]
      metricFor: 'area' | 'org' | 'opportunity'
    }) => {
      const { includePreviousValue, customSources, metricFor } = params
      let list: MetricListPayloadItem[] = []

      const overallMetricKeys = getMetricKeys('count')

      const allowedCountKeys = allowedMetricsBySource.count ?? []
      overallMetricKeys?.forEach(overallKey => {
        if (allowedCountKeys.includes(overallKey)) {
          const metricItem = allMetricItems[overallKey]

          if (!metricItem) return
          list.push({
            ...metricItem.metric,
            include_previous_value: includePreviousValue ?? false
          })
        }
      })

      const sources = customSources ?? sourceAliasValues
      sources.forEach(source => {
        const metricKey = source as MetricKey
        const sourceMetricKeys = getMetricKeys(metricKey)

        const allowedKeys = allowedMetricsBySource[metricKey] ?? []

        if (!sourceMetricKeys) return

        sourceMetricKeys.forEach(sourceKey => {
          if (allowedKeys.includes(sourceKey)) {
            const metricItem = allMetricItems[sourceKey]

            if (!metricItem) return
            list.push({
              ...metricItem.metric,
              include_previous_value: includePreviousValue ?? false
            })
          }
        })
      })

      list = list.map(metric => {
        if (metric.name === 'custom' && metric.customization && metric.customization.variables) {
          const newVariables: MetricCustomizationValue['variables'] = handleCustomMetricVariables(
            metric.customization.variables,
            metricFor
          )

          return {
            ...metric,
            customization: {
              ...metric.customization,
              variables: newVariables
            }
          }
        }

        return metric
      })

      return list
    },
    [
      allowedMetricsBySource,
      sourceAliasValues,
      getMetricKeys,
      allMetricItems,
      handleCustomMetricVariables
    ]
  )

  return {
    addShareFiltersToMetrics,
    getMetricKeys,
    getMetricAndAddShareFilter,
    getMetricList,
    handleCustomMetricVariables
  }
}

export default useMetricListPayload
