import Tabs, { TabItem } from '@/components/atoms/tabs'
import { CSS } from '@/theme'
import { Clock, Folders, Heart } from '@phosphor-icons/react'
import { TabsContent } from '@radix-ui/react-tabs'
import CollectionGrid from './CollectionsGrid'
import useCollections from '@/hooks/collections/useCollections'
import { useMemo, useState } from 'react'
import Search from '../../search'
import FlexContainer from '@/components/atoms/flex-container'
import NoResults from '../../area-of-interest/AreaOfInterestList/NoResults'
import CollectionCard from './CollectionCard'
import AllAreasCollectionCard from './AllAreasCollectionCard'
import { useTranslation } from 'react-i18next'

const triggerCss: CSS = {
  '&:hover': {
    h2: {
      fontWeight: '$regular'
    }
  },
  '&[data-state="active"]': {
    color: '$neutralLowPure',
    background: 'transparent',
    bBottom: '$neutralLowPure',
    h2: {
      fontWeight: '$semibold'
    }
  },
  '&[data-disabled]': {
    pointerEvents: 'auto'
  }
}

type CollectionTabName = 'recent' | 'all' | 'favorites'

const CollectionsTabs = () => {
  const { collections, recentCollections, isLoading } = useCollections({ recent: true })

  const { t } = useTranslation()
  const [searchInput, setSearchInput] = useState('')
  const filteredCollections = useMemo(() => {
    if (!searchInput) return collections
    return collections.filter(collection =>
      collection.name.toLowerCase().includes(searchInput.toLowerCase())
    )
  }, [collections, searchInput])

  const favoriteCollections = useMemo(
    () => collections.filter(collection => collection.favorite),
    [collections]
  )

  const [currentTab, setCurrentTab] = useState<CollectionTabName>('recent')

  const tabs: TabItem[] = useMemo(() => {
    const tabItems: TabItem[] = []

    if (recentCollections.length > 0) {
      tabItems.push({
        text: t('recentlyOpened'),
        value: 'recent',
        icon: <Clock size={16} />,
        triggerCss
      })
    } else {
      setCurrentTab('all')
    }

    tabItems.push({
      text: t('allCollections'),
      value: 'all',
      icon: <Folders size={16} />,
      triggerCss
    })

    tabItems.push({
      text: t('favorites'),
      value: 'favorites',
      icon: <Heart size={16} />,
      disabled: favoriteCollections.length === 0,
      triggerCss
    })

    return tabItems
  }, [recentCollections, favoriteCollections, t])

  const showAllAreasCollection = useMemo(
    () => 'all organization areas'.includes(searchInput.toLowerCase()),
    [searchInput]
  )

  return (
    <Tabs
      css={{ overflowY: 'visible' }}
      onValueChange={value => setCurrentTab(value as CollectionTabName)}
      options={
        currentTab === 'all' && (
          <FlexContainer alignItems="center" gap="xxs">
            <Search
              clearable
              disabled={false}
              expandOnFocus
              onChange={e => setSearchInput(e.currentTarget.value)}
              onClear={() => setSearchInput('')}
              placeholder={t('search')}
              size="small"
              small
              value={searchInput}
            />
          </FlexContainer>
        )
      }
      stretch={false}
      tabs={tabs}
      value={currentTab}
    >
      <TabsContent asChild value="recent">
        <CollectionGrid>
          {recentCollections.map(collection => (
            <CollectionCard key={collection.collectionId} {...collection} />
          ))}
        </CollectionGrid>
      </TabsContent>

      <TabsContent asChild value="all">
        {filteredCollections.length === 0 && !showAllAreasCollection && !isLoading ? (
          <NoResults />
        ) : (
          <CollectionGrid>
            {showAllAreasCollection && <AllAreasCollectionCard />}
            {filteredCollections.map(collection => (
              <CollectionCard key={collection.collectionId} {...collection} />
            ))}
          </CollectionGrid>
        )}
      </TabsContent>

      <TabsContent asChild value="favorites">
        <CollectionGrid>
          {favoriteCollections.map(collection => (
            <CollectionCard key={collection.collectionId} {...collection} />
          ))}
        </CollectionGrid>
      </TabsContent>
    </Tabs>
  )
}

export default CollectionsTabs
