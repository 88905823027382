import { styled, CSS } from '@/theme'
import Popover, { PopoverProps } from './Popover'

const popoverFlexSpace: CSS = {
  display: 'flex',
  alignItems: 'center',

  padding: 16
}

export const PopoverHeader = styled('header', {
  ...popoverFlexSpace,
  gap: 8,
  justifyContent: 'space-between',
  paddingRight: 12,
  bBottom: '$neutralHighPure',
  borderTopRightRadius: 6,
  borderTopLeftRadius: 6,

  variants: {
    grey: {
      true: {
        background: '$neutralHighMedium',
        display: 'block',
        pAll: 0
      }
    }
  }
})

export const PopoverFooter = styled('footer', {
  ...popoverFlexSpace,

  gap: '$micro',
  justifyContent: 'flex-end',
  bTop: '$neutralHighPure'
})

export default Popover
export type { PopoverProps }
