import useClassification from '@/hooks/useClassification'
// import useSegment from '@/hooks/useSegment'
import { useUIStore } from '@/store'
import { styled } from '@/theme'
import { useEffect } from 'react'
import DefaultContent from '@/components/molecules/layout/DefaultContent'
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import DiscoveryWithAssistant from '@/components/molecules/discovery/DiscoveryWithAssistant'

const DiscoveryScrollArea = styled(ScrollAreaRoot, {
  overflow: 'hidden',
  background: 'transparent',
  bottom: 0,
  height: '100%',

  boxSizing: 'border-box'
})

function DiscoveryWithAssistantPage() {
  const { verifyAllTopicAndThemes } = useClassification()
  const { setPageTitle } = useUIStore()

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    setPageTitle('Discovery (With Assistant)')
    verifyAllTopicAndThemes()
  }, [])

  return (
    <DefaultContent searchPlaceholder="Search to discover">
      <DiscoveryScrollArea>
        <ScrollAreaViewport id="discovery-viewport">
          <DiscoveryWithAssistant />
        </ScrollAreaViewport>
        <ScrollAreaScrollbar orientation="vertical">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </DiscoveryScrollArea>
    </DefaultContent>
  )
}

export default DiscoveryWithAssistantPage
