import { Gauge } from '@phosphor-icons/react'
import {
  integerFormatter,
  percentageFormatter,
  percentageScoreTransformer,
  scoreFormatter
} from './formatters'
import { CSATMetricKey, MetricItem } from '@/types/metrics'
import i18n from '../../plugins/i18n/i18n'

const unsatisfiedFilter = { 'csat.rating': [0, 1, 2] }
const neutralsFilter = { 'csat.rating': [3] }
const satisfiedFilter = { 'csat.rating': [4, 5] }

export const csatTimeSeries: Record<CSATMetricKey, MetricItem> = {
  csat: {
    label: i18n.t('score'),
    selectedLabel: i18n.t('csatScore'),
    icon: Gauge,
    mainMetricType: 'score',
    formatter: scoreFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'csat_score',
      label: 'csat_score',
      filter: { source_alias: 'csat' }
    }
  },
  csat_share: {
    label: i18n.t('percentCount'),
    selectedLabel: i18n.t('csatPercentCount'),
    icon: Gauge,
    mainMetricType: 'share',
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: `csat_share`,
      filter: { source_alias: 'csat' }
    }
  },
  csat_count: {
    label: i18n.t('count'),
    selectedLabel: i18n.t('csatCount'),
    icon: Gauge,
    formatter: integerFormatter,
    mainMetricType: 'count',
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: `csat_count`,
      filter: { source_alias: 'csat' }
    }
  },
  csat_share_unsatisfied_with_signal: {
    label: '% Unsatisfied over all comments',
    selectedLabel: 'CSAT: % Unsatisfied over all comments',
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    icon: Gauge,
    metric: {
      name: 'feedback_share',
      label: 'csat_unsatisfied_over_reviews_with_comments',
      filter: { source_alias: 'csat', ...unsatisfiedFilter, has_signal: 'true' },
      share_filter: { source_alias: 'csat', has_signal: 'true' }
    }
  },
  csat_unsatisfied_count: {
    label: 'Unsatisfied count',
    selectedLabel: 'CSAT: Unsatisfied count',
    icon: Gauge,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: 'csat_unsatisfied_count',
      filter: { source_alias: 'csat', ...unsatisfiedFilter }
    }
  },
  csat_neutral_count: {
    label: 'Neutral count',
    selectedLabel: 'CSAT: Neutral count',
    icon: Gauge,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: 'csat_neutral_count',
      filter: {
        source_alias: 'csat',
        ...neutralsFilter
      }
    }
  },
  csat_satisfied_count: {
    label: 'Satisfied count',
    selectedLabel: 'CSAT: Satisfied count',
    icon: Gauge,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: 'csat_satisfied_count',
      filter: {
        source_alias: 'csat',
        ...satisfiedFilter
      }
    }
  },
  csat_avg_rating: {
    label: i18n.t('avgRating'),
    selectedLabel: i18n.t('csatAvgRating'),
    icon: Gauge,
    mainMetricType: 'avg',
    formatter: integerFormatter,
    numberTransformer: (value: number) => Number(value.toPrecision(2)),
    metric: {
      name: 'csat_avg_rating',
      label: 'CSAT avg rating',
      filter: { source_alias: 'csat' }
    }
  }
}

export const CSAT_METRICS_KEYS = Object.keys(csatTimeSeries) as CSATMetricKey[]

export const CSAT_DEFAULT_METRICS = ['csat', 'csat_share_unsatisfied_with_signal']
