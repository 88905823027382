import {
  FilterBoolean,
  FilterDatetime,
  FilterNumeric,
  FilterString,
  FilterText
} from '@/types/filters/Filters'
import FilterItem from '../../AppliedFilters/FilterItem'
import {
  FilterItemDropdownBoolean,
  FilterItemDropdownDatetime,
  FilterItemDropdownNumeric,
  FilterItemDropdownString,
  FilterItemDropdownText
} from '../FilterItem'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import { useMemo } from 'react'
import { FEED_FILTERS_BLACKLIST } from '@/utils/filters'
import {
  AppliedFiltersDefaultProps,
  defaultAppliedFilterProps,
  disabledAreaFilterTooltipText
} from '../AppliedFilters.types'
import useInterestAreaFilterSpliter from '@/hooks/filters/useInterestAreaFilterSpliter'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'

function AppliedGenericFilters(props: AppliedFiltersDefaultProps = defaultAppliedFilterProps) {
  const { splitMode } = props
  const apply = useFeedFiltersStore(state => state.apply)
  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)

  const { stringFilters, datetimeFilters, textFilters, numericFilters, booleanFilters } =
    useInterestAreaFilterSpliter({
      mode: splitMode
    })

  const removeDatetimeFilter = useFeedFiltersStore(state => state.removeDatetimeFilter)
  const removeNumericFilter = useFeedFiltersStore(state => state.removeNumericFilter)
  const removeStringFilter = useFeedFiltersStore(state => state.removeStringFilter)
  const removeTextFilter = useFeedFiltersStore(state => state.removeTextFilter)
  const removeBooleanFilters = useFeedFiltersStore(state => state.removeBooleanFilter)
  const appliedDatetimeFilters = useMemo(
    () =>
      datetimeFilters.filter(
        filter =>
          (filter.value !== null || filter.period !== 'allTime') &&
          !FEED_FILTERS_BLACKLIST.includes(filter.name)
      ),
    [datetimeFilters]
  )

  const appliedNumericFilters = useMemo(
    () =>
      numericFilters.filter(
        filter =>
          (filter.option !== 'all' || filter.value !== null) &&
          !FEED_FILTERS_BLACKLIST.includes(filter.name)
      ),
    [numericFilters]
  )

  const appliedStringFilters = useMemo(
    () =>
      stringFilters.filter(
        filter => filter.selected.length > 0 && !FEED_FILTERS_BLACKLIST.includes(filter.name)
      ),
    [stringFilters]
  )

  const appliedTextFilters = textFilters

  const appliedBooleanFilters = useMemo(
    () =>
      booleanFilters.filter(
        filter => filter.value !== null && !FEED_FILTERS_BLACKLIST.includes(filter.name)
      ),
    [booleanFilters]
  )

  function removeDatetimeFilterOptions(filter: FilterDatetime) {
    removeDatetimeFilter(filter.key)
    apply()
  }

  function removeNumericFilterOptions(filter: FilterNumeric) {
    removeNumericFilter(filter.key)
    apply()
  }

  function removeStringFilterOptions(filter: FilterString) {
    filter.selected.forEach(option => removeStringFilter(filter.key, option))
    apply()
  }

  function removeTextFilterOptions(filter: FilterText) {
    removeTextFilter(filter.key)
  }

  function removeBooleanFilterOptions(filter: FilterBoolean) {
    removeBooleanFilters(filter.key)
    apply()
  }

  const getName = (filterKey: string) =>
    filterKey.startsWith('custom_fields') ? 'Custom fields' : 'Feedback details'
  const disabled = currentInterestArea && splitMode === 'onlyAreaOfInterestFilters'

  return [
    ...appliedDatetimeFilters.map(filter => (
      <FilterItem
        disabled={disabled}
        disabledTooltipText={disabledAreaFilterTooltipText}
        editButton={<FilterItemDropdownDatetime buttonDisabled={disabled} filter={filter} />}
        key={filter.key}
        name={getName(filter.key)}
        onDelete={() => removeDatetimeFilterOptions(filter)}
      />
    )),
    ...appliedNumericFilters.map(filter => (
      <FilterItem
        disabled={disabled}
        disabledTooltipText={disabledAreaFilterTooltipText}
        editButton={<FilterItemDropdownNumeric buttonDisabled={disabled} filter={filter} />}
        key={filter.key}
        name={getName(filter.key)}
        onDelete={() => removeNumericFilterOptions(filter)}
      />
    )),
    ...appliedStringFilters.map(filter => (
      <FilterItem
        disabled={disabled}
        disabledTooltipText={disabledAreaFilterTooltipText}
        editButton={<FilterItemDropdownString buttonDisabled={disabled} filter={filter} />}
        key={filter.key}
        name={getName(filter.key)}
        onDelete={() => removeStringFilterOptions(filter)}
      />
    )),
    ...appliedTextFilters.map(filter => (
      <FilterItem
        disabled={disabled}
        disabledTooltipText={disabledAreaFilterTooltipText}
        editButton={<FilterItemDropdownText buttonDisabled={disabled} filter={filter} />}
        key={filter.key}
        name={getName(filter.key)}
        onDelete={() => removeTextFilterOptions(filter)}
      />
    )),
    ...appliedBooleanFilters.map(filter => (
      <FilterItem
        disabled={disabled}
        disabledTooltipText={disabledAreaFilterTooltipText}
        editButton={<FilterItemDropdownBoolean buttonDisabled={disabled} filter={filter} />}
        key={filter.key}
        name={getName(filter.key)}
        onDelete={() => removeBooleanFilterOptions(filter)}
      />
    ))
  ]
}

export default AppliedGenericFilters
