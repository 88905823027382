import { useEffect, useMemo, useState } from 'react'

import { Table, TableHeader } from '@/components/atoms/table'
import Text from '@/components/atoms/text'
import { getValueFromPath } from '@/components/molecules/integrations/utils'
import useLetterCase from '@/hooks/useLetterCase'
import IntegrationService from '@/services/IntegrationServices'
import { FormStepSection } from '../../IntegrationForm.styles'
import FieldsMatchedRow from './FieldsMatchedRow'
import FieldsMatchedRowSkeleton from './FieldsMatchedRowSkeleton'
import FieldsMatchedEditableRow from './FieldsMatchedEditableRow'
import { useTranslation } from 'react-i18next'

interface FieldsMatchedSectionProps {
  connectorId: string
  connectorName: string
  integrationSample: Record<string, unknown>
  customCoreFields: Record<string, string | null>
  setCustomCoreFields: React.Dispatch<React.SetStateAction<Record<string, string | null>>>
}

function FieldsMatchedSection(props: FieldsMatchedSectionProps) {
  const { snakeToTitle, camelToSnakeCase } = useLetterCase()
  const { t } = useTranslation()

  const [schema, setSchema] = useState<Record<string, string>>()

  useEffect(() => {
    IntegrationService.getConnectorSchema(props.connectorId).then(schema => setSchema(schema))
  }, [props.connectorId])

  const fieldsMatch = useMemo(() => {
    if (!schema) return []

    const options = Object.entries(schema).map(([key, pathString]) => {
      const path = pathString.split('|')
      const value = getValueFromPath(props.integrationSample, path)

      const displayText = `${path.length > 1 ? `... | ` : ''}${path.at(-1)}: ${value}`
      const tooltipText = `${path.join(' | ')}: ${value}`

      return {
        key,
        pathString,
        fieldName: snakeToTitle(key),
        displayValue: displayText,
        tooltipValue: tooltipText
      }
    })

    return options
  }, [schema, props.integrationSample, snakeToTitle])

  const showEditableRow = (fieldKey: string) => {
    const EDITABLE_KEYS = ['author_id', 'account_id']
    const normalizedKey = camelToSnakeCase(fieldKey)

    return EDITABLE_KEYS.includes(normalizedKey)
  }

  const getValue = (matchKey: string, matchPath: string) => {
    const currentField = props.customCoreFields[matchKey]

    if (!currentField && currentField !== null) return matchPath
    if (currentField === null) return 'null'

    const fullPath = fieldsMatch.find(field => field.pathString.includes(currentField))
    return fullPath?.pathString || currentField
  }

  return (
    <FormStepSection>
      <Text as="h2" css={{ lineHeight: 1.25 }} typeface="titleBoldXXS">
        {t('fieldsMatched')}
      </Text>

      <Table css={{ tableLayout: 'fixed', width: '100%' }}>
        <thead>
          <tr>
            <TableHeader css={{ width: 324 }}>
              {props.connectorName} {t('fields')}
            </TableHeader>
            <TableHeader css={{ width: 56 }} />
            <TableHeader css={{ width: 324 }}>{t('birdieFields')}</TableHeader>
            <TableHeader aria-hidden />
          </tr>
        </thead>
        <tbody>
          {fieldsMatch.length !== 0
            ? fieldsMatch.map((match, index) => {
                if (showEditableRow(match.key)) {
                  return (
                    <FieldsMatchedEditableRow
                      customOptions={fieldsMatch.map(field => ({
                        key: field.pathString,
                        displayValue: field.displayValue,
                        tooltipValue: field.tooltipValue
                      }))}
                      fieldKey={match.key}
                      fieldName={match.fieldName}
                      key={`${index}_${match.fieldName}`}
                      onChange={value =>
                        props.setCustomCoreFields(prev => ({
                          ...prev,
                          [match.key]: value === 'null' ? null : value
                        }))
                      }
                      tooltipValue={match.tooltipValue}
                      value={getValue(match.key, match.pathString)}
                    />
                  )
                }
                return (
                  <FieldsMatchedRow
                    displayValue={match.displayValue}
                    fieldName={match.fieldName}
                    key={`${index}_${match.fieldName}`}
                    tooltipValue={match.tooltipValue}
                  />
                )
              })
            : Array.from({ length: 4 }, (_, index) => <FieldsMatchedRowSkeleton key={index} />)}
        </tbody>
      </Table>
    </FormStepSection>
  )
}

export default FieldsMatchedSection
