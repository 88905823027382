import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { CaretDown } from '@phosphor-icons/react'
import {
  StyledContent,
  StyledItem,
  StyledSeparator,
  StyledTrigger,
  UserIcon
} from './UserIndicator.styles'
import Text from '@/components/atoms/text'
import useUser from '@/hooks/useUser'
import FlexContainer from '@/components/atoms/flex-container'
import UserPlans from './UserPlans'
import { useTranslation } from 'react-i18next'

interface Props {
  opportunityPlan?: boolean
}

function UserIndicator({ opportunityPlan = false }: Props) {
  const { logout, currentUser } = useUser()
  const { t } = useTranslation()

  function onLogoutClick() {
    logout()
  }

  const firstName = (() => {
    const name = currentUser?.name.trim()
    const espaceIndex = name?.indexOf(' ') || -1
    return name?.substring(0, espaceIndex > -1 ? espaceIndex : name.length) || ''
  })()

  return (
    <DropdownMenuPrimitive.Root>
      <StyledTrigger opportunityPlan={opportunityPlan}>
        <UserIcon opportunityPlan={opportunityPlan}>{currentUser?.name.trim().at(0)}</UserIcon>
        <FlexContainer css={{ maxWidth: 125 }} direction="column">
          <Text
            fontSize="micro"
            fontWeight="semibold"
            lineHeight="md"
            title={currentUser?.name}
            truncate
          >
            {firstName}
          </Text>
          <Text
            className="email"
            color={opportunityPlan ? 'brandPrimaryMedium' : undefined}
            fontSize="micro"
            fontWeight="regular"
            lineHeight="sm"
            title={currentUser?.email}
            truncate
          >
            {currentUser?.email}
          </Text>
        </FlexContainer>
        <CaretDown className="caret" size={16} weight="bold" />
      </StyledTrigger>
      <DropdownMenuPrimitive.Portal>
        <StyledContent align="end" sideOffset={4}>
          <FlexContainer css={{ px: '$xxs' }} direction="column" gap="micro">
            <Text
              color="neutralLowPure"
              fontSize="micro"
              fontWeight="semibold"
              lineHeight="md"
              title={currentUser?.name}
              truncate
            >
              {currentUser?.name}
            </Text>
            <Text
              color="neutralLowLight"
              fontSize="micro"
              fontWeight="regular"
              lineHeight="sm"
              title={currentUser?.email}
              truncate
            >
              {currentUser?.email}
            </Text>
          </FlexContainer>
          <StyledSeparator />
          <UserPlans />
          <StyledItem onClick={onLogoutClick}>
            <Text color="neutralLowPure" fontSize="micro" fontWeight="regular">
              {t('signOut')}
            </Text>
          </StyledItem>
        </StyledContent>
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Root>
  )
}

export default UserIndicator
