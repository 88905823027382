import { CheckedState } from '@radix-ui/react-checkbox'
import Checkbox from '@/components/atoms/checkbox'
import FlexContainer from '@/components/atoms/flex-container'
import { FilterSubMenuScrollArea } from '../AddFilterMenu/AddFilterMenu.styles'
import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'

export interface StringFilterContentProps {
  isChecked: (value: string) => boolean
  selectOption: (checked: CheckedState, option: string) => void
  options: string[]
  textFormatter?: (text: string) => string
}

const StringFilterContent = ({
  isChecked,
  selectOption,
  options,
  textFormatter
}: StringFilterContentProps) => {
  return (
    <FilterSubMenuScrollArea>
      <ScrollAreaViewport css={{ maxHeight: 320 }}>
        <FlexContainer direction="column" gap="micro">
          {options.map(option => (
            <Checkbox
              checked={isChecked(option)}
              defaultChecked={isChecked(option)}
              key={option}
              onCheckedChange={checked => selectOption(checked, option)}
              text={textFormatter ? textFormatter(option) : option}
              value={option}
            />
          ))}
        </FlexContainer>
      </ScrollAreaViewport>
      <ScrollAreaScrollbar orientation="vertical">
        <ScrollAreaThumb />
      </ScrollAreaScrollbar>
    </FilterSubMenuScrollArea>
  )
}

export default StringFilterContent
