import { useCallback, useMemo } from 'react'
import { BreakdownOption, TimeSeriesIntervalOption } from '@/types/time-series/TimeSeries'
import { mapBreakdownToBaseFilter } from './useComparativeTableMetrics'
import { FilterItem } from '@/types/filters/Filters'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import useCollections from '@/hooks/collections/useCollections'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { ONGOING_STATUS } from '@/utils/opportunityUtils'
import useSourcesQuery from '@/hooks/useSourcesQuery'
import useMetricTimeSeriesQuery from '../../metrics-trendline/useMetricTimeSeriesQuery'
import useOpportunityStore from '@/store/useOpportunityStore'
import useAdvancedAreasFiltersCombined from '@/hooks/areaOfInterest/useAdvancedAreasFiltersCombined'
import useBasicAreaOfInterestQuery from '@/hooks/areaOfInterest/useBasicAreaOfInterestQuery'
import useCompetitorsModeStore from '@/store/useCompetitorsModeStore'
import useBaseMetricFilters from '@/hooks/feedback/new/useBaseMetricsFilters'
import useOpportunitiesSortedByMetric from '@/hooks/opportunity/useOpportunitiesSortedByMetric'
import { AllMetricsKey } from '@/types/metrics'
import useOpportunitiesContexts from '@/hooks/opportunity/useOpportunitiesContexts'
import useAdvancedFiltersStore from '@/store/useFiltersStore/useAdvancedFiltersStore'
import useBasicSegmentationsQuery from '@/hooks/segmentation/useBasicSegmentationsQuery'
import useSegmentationFiltersCombined from '@/hooks/segmentation/useSegmentationFiltersCombined'

const BREAKDOWNS_TO_FETCH_GROUP: BreakdownOption[] = [
  'feedbackDetail',
  'customField',
  'account',
  'user'
]

interface Props {
  interval: TimeSeriesIntervalOption
  rowBreakdown: BreakdownOption
  columnBreakdown: BreakdownOption
  favorites: boolean
  useAppliedFilters?: boolean
  selectedField?: FilterItem | null
  metric?: AllMetricsKey
}

const useComparativeTableFilters = ({
  rowBreakdown,
  columnBreakdown,
  useAppliedFilters,
  selectedField,
  interval,
  metric
}: Props) => {
  const { values: sources, isLoading: isLoadingSources } = useSourcesQuery({
    enabled: true,
    sourceMode: 'sourceAlias'
  })

  const currentAreaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)

  const { currentCollection } = useCollections()

  const isFetchingContext = useAdvancedFiltersStore(state => state.isFetchingContext)

  const { areas: advancedAreas, isLoading: isLoadingAdvAreas } = useBasicAreaOfInterestQuery({
    enabled: true,
    collectionId: currentCollection?.collectionId
  })

  const { competitorsModeEnabled } = useCompetitorsModeStore(state => ({
    competitorsModeEnabled: state.competitorsModeEnabled
  }))

  const isOpportunityBreakdown = rowBreakdown === 'opportunity' || columnBreakdown === 'opportunity'

  const { opportunities, isLoading: isLoadingOpportunities } = useOpportunitiesSortedByMetric({
    enabled: isOpportunityBreakdown,
    areaId: currentAreaOfInterest?.id,
    collectionId: currentCollection?.collectionId,
    limit: 200,
    onlyOngoing: true,
    sortDirection: 'desc',
    metric,
    useAppliedFilters,
    useReviewCompetitors: competitorsModeEnabled
  })

  const filteredOpportunities = useMemo(
    () => opportunities.filter(opportunity => ONGOING_STATUS.includes(opportunity.status)),
    [opportunities]
  )

  const { data: combinedAreasContexts, isLoading: isLoadingAreaContexts } =
    useAdvancedAreasFiltersCombined({
      areas: advancedAreas,
      enabled: (useAppliedFilters && rowBreakdown === 'areas') || columnBreakdown === 'areas'
    })

  const { data: baseFiltersData, isLoading: isLoadingBaseQueryParams } = useBaseMetricFilters({
    useAppliedFilters,
    useReviewCompetitors: competitorsModeEnabled
  })

  const baseQueryParams = useMemo(() => {
    return baseFiltersData as FeedbackListQueryParams | undefined
  }, [baseFiltersData])

  const areaFilters = useMemo(() => {
    if (useAppliedFilters && combinedAreasContexts) {
      return combinedAreasContexts.map(areaContext => ({
        context: areaContext.context
      }))
    }

    return advancedAreas.map(area => ({
      context: area.context
    })) as FeedbackListQueryParams[]
  }, [combinedAreasContexts, useAppliedFilters, advancedAreas])

  const isSegmentBreakdown = rowBreakdown === 'segment' || columnBreakdown === 'segment'

  const { segmentations, isLoading: isLoadingSegmentations } = useBasicSegmentationsQuery({
    enabled: isSegmentBreakdown
  })

  const { data: combinedSegmentationsContexts, isLoading: isLoadingSegmentationCombinedContexts } =
    useSegmentationFiltersCombined({
      segmentations,
      enabled: useAppliedFilters && isSegmentBreakdown
    })

  const segmentationFilters = useMemo(() => {
    if (useAppliedFilters && combinedSegmentationsContexts) {
      return combinedSegmentationsContexts.map(segmentationContext => ({
        context: segmentationContext.context
      }))
    }

    return segmentations.map(segmentation => ({
      context: segmentation.context
    }))
  }, [useAppliedFilters, combinedSegmentationsContexts, segmentations])

  const { opportunitiesWithContext, isLoading: isLoadingOppsContexts } = useOpportunitiesContexts({
    enabled: isOpportunityBreakdown && !useAppliedFilters && !isLoadingOpportunities,
    opportunities: filteredOpportunities,
    areasId: currentAreaOfInterest && [currentAreaOfInterest?.id],
    collectionId: currentCollection?.collectionId
  })

  const isLoadingOppsResources = isLoadingOpportunities || isLoadingOppsContexts

  const opportunitiesFilters = useMemo(() => {
    return opportunitiesWithContext.map(opportunity => {
      const oppParams = {
        ...(useAppliedFilters ? baseQueryParams : {}),
        context: useAppliedFilters ? baseQueryParams?.context : opportunity.mergedContext,
        opportunity_id: opportunity.id
      } as FeedbackListQueryParams

      return oppParams
    }) as FeedbackListQueryParams[]
  }, [opportunitiesWithContext, useAppliedFilters, baseQueryParams])

  const npsSatisfactionFilters = useMemo(() => {
    const detractors: FeedbackListQueryParams & Record<string, ParamValue | number> = {
      ...(useAppliedFilters ? baseQueryParams : {}),
      'nps.rating.lte': '6'
    }
    const neutrals: FeedbackListQueryParams & Record<string, ParamValue | number> = {
      ...(useAppliedFilters ? baseQueryParams : {}),
      'nps.rating.gte': '7',
      'nps.rating.lte': '8'
    }

    const promoters: FeedbackListQueryParams & Record<string, ParamValue | number> = {
      ...(useAppliedFilters ? baseQueryParams : {}),
      'nps.rating.gte': '9'
    }

    return [detractors, neutrals, promoters]
  }, [baseQueryParams, useAppliedFilters])

  const csatSatisfactionFilters = useMemo(() => {
    const unsatisfied: FeedbackListQueryParams & Record<string, ParamValue | number> = {
      ...(useAppliedFilters ? baseQueryParams : {}),
      'csat.rating.lte': '2'
    }
    const neutrals: FeedbackListQueryParams & Record<string, ParamValue | number> = {
      ...(useAppliedFilters ? baseQueryParams : {}),
      'csat.rating.gte': '3',
      'csat.rating.lte': '3'
    }

    const satisfied: FeedbackListQueryParams & Record<string, ParamValue | number> = {
      ...(useAppliedFilters ? baseQueryParams : {}),
      'csat.rating.gte': '4'
    }

    return [unsatisfied, neutrals, satisfied]
  }, [baseQueryParams, useAppliedFilters])

  const reviewSatisfactionFilters = useMemo(() => {
    const unsatisfied: FeedbackListQueryParams & Record<string, ParamValue | number> = {
      ...(useAppliedFilters ? baseQueryParams : {}),
      'review.rating.lte': '2'
    }
    const neutrals: FeedbackListQueryParams & Record<string, ParamValue | number> = {
      ...(useAppliedFilters ? baseQueryParams : {}),
      'review.rating.gte': '3',
      'review.rating.lte': '3'
    }

    const satisfied: FeedbackListQueryParams & Record<string, ParamValue | number> = {
      ...(useAppliedFilters ? baseQueryParams : {}),
      'review.rating.gte': '4'
    }

    return [unsatisfied, neutrals, satisfied]
  }, [baseQueryParams, useAppliedFilters])

  const getGroup = useCallback(
    (breakdownOption: BreakdownOption) => {
      if (!BREAKDOWNS_TO_FETCH_GROUP.includes(breakdownOption)) {
        return
      }

      if (breakdownOption === 'account') {
        return 'account.' + selectedField?.name
      }

      if (breakdownOption === 'user') {
        return 'user.' + selectedField?.name
      }

      if (['customField', 'feedbackDetail'].includes(breakdownOption)) {
        return selectedField?.name
      }

      return breakdownOption
    },
    [selectedField]
  )

  const rowGroup = useMemo(() => getGroup(rowBreakdown), [getGroup, rowBreakdown])
  const columnGroup = useMemo(() => getGroup(columnBreakdown), [getGroup, columnBreakdown])

  const metricFor = useMemo(() => {
    if (rowBreakdown === 'opportunity' || columnBreakdown === 'opportunity') return 'opportunity'
    if (rowBreakdown === 'areas' || columnBreakdown === 'areas') return 'org'

    return 'org'
  }, [rowBreakdown, columnBreakdown])

  const { data: rowGroupData, isLoading: isLoadingRowGroupData } = useMetricTimeSeriesQuery({
    filters: baseQueryParams ? [baseQueryParams] : [],
    interval,
    group: rowGroup,
    metric: 'count',
    enabled:
      BREAKDOWNS_TO_FETCH_GROUP.includes(rowBreakdown) && !!selectedField && !!baseQueryParams,
    metricFor
  })

  const { data: columnGroupData, isLoading: isLoadingColumnGroupData } = useMetricTimeSeriesQuery({
    filters: baseQueryParams ? [baseQueryParams] : [],
    interval,
    group: columnGroup,
    metric: 'count',
    enabled:
      BREAKDOWNS_TO_FETCH_GROUP.includes(columnBreakdown) && !!selectedField && !!baseQueryParams,
    metricFor
  })

  const currentGroup = useMemo(() => {
    return rowGroup ?? columnGroup
  }, [rowGroup, columnGroup])

  const fieldFilters = useMemo(() => {
    const _filters: FeedbackListQueryParams[] = []

    const group = rowGroup ?? columnGroup

    if (!group) return _filters

    const groupResponse = rowGroupData ?? columnGroupData
    if (!groupResponse) return _filters

    const groupData = Object.values(groupResponse?.[0]?.[0]?.group ?? {})[0]
    if (!groupData) return _filters

    const filterValues = groupData.map(item => item.name)
    if (!filterValues.length) return _filters

    filterValues.forEach(value => {
      _filters.push({ [group]: value })
    })

    return _filters
  }, [rowGroupData, columnGroupData, rowGroup, columnGroup])

  const breakdownFilters = useMemo(() => {
    return {
      areas: areaFilters,
      opportunity: opportunitiesFilters,
      segment: segmentationFilters,
      npsSatisfactionFilters,
      csatSatisfactionFilters,
      reviewSatisfactionFilters,
      base: baseQueryParams ? [baseQueryParams] : [],
      all: baseQueryParams ? [baseQueryParams] : [],
      fields: fieldFilters
    }
  }, [
    areaFilters,
    opportunitiesFilters,
    segmentationFilters,
    npsSatisfactionFilters,
    reviewSatisfactionFilters,
    csatSatisfactionFilters,
    baseQueryParams,
    fieldFilters
  ])

  const fieldFilterValues = useMemo(() => {
    return currentGroup && fieldFilters.length > 0
      ? (fieldFilters
          .map(filter => {
            const item = (filter as Record<string, string | string[]>)[currentGroup]
            const value = Array.isArray(item)
              ? item.join(',')
              : typeof item === 'string'
                ? item
                : null

            if (!value) return null
            return { name: value, id: `${currentGroup}.${value}` }
          })
          .filter(Boolean) as { name: string; id: string }[])
      : []
  }, [currentGroup, fieldFilters])

  /**
   * map each breakdown to its values, these are viewed in the table (as columns or rows)
   * and must match the filters in `useComparativeTableMetrics`'s mapBreakdownToFilters
   */
  const mapBreakdownToValues: Record<BreakdownOption, { name: string; id: string }[]> =
    useMemo(() => {
      const npsSatisfactionNames = ['Detractors', 'Neutrals', 'Promoters']
      const reviewCsatSatisfactionNames = ['Unsatisfied', 'Neutrals', 'Satisfied']
      return {
        intention: [
          ...mapBreakdownToBaseFilter.intention.map(filter => ({
            name: filter.intention as string,
            id: filter.intention as string
          })),
          { name: 'No intention', id: 'no_aspect' }
        ],
        sentiment: mapBreakdownToBaseFilter.sentiment.map(filter => ({
          name: filter.sentiment as string,
          id: filter.sentiment as string
        })),
        source_alias: sources.map(sourceName => ({
          name: sourceName,
          id: sourceName
        })),
        areas: advancedAreas.map(area => ({ name: area.name, id: area.id })),
        opportunity: filteredOpportunities.map(opportunity => ({
          name: opportunity.name,
          id: opportunity.id
        })),
        segment: segmentations.map(segment => ({ name: segment.name, id: segment.segmentationId })),
        satisfaction: npsSatisfactionNames.map(satisfactionName => ({
          name: satisfactionName,
          id: satisfactionName
        })),
        satisfaction_by_nps: npsSatisfactionNames.map(satisfactionName => ({
          name: satisfactionName,
          id: satisfactionName
        })),
        satisfaction_by_csat: reviewCsatSatisfactionNames.map(satisfactionName => ({
          name: satisfactionName,
          id: satisfactionName
        })),
        satisfaction_by_review: reviewCsatSatisfactionNames.map(satisfactionName => ({
          name: satisfactionName,
          id: satisfactionName
        })),
        all: [
          {
            id: currentOpportunity?.id ?? currentAreaOfInterest?.id ?? '',
            name: currentOpportunity ? 'This opportunity' : 'This area'
          }
        ],
        org: [{ id: 'org', name: 'Organization' }],
        account: fieldFilterValues,
        customField: fieldFilterValues,
        feedbackDetail: fieldFilterValues,
        user: fieldFilterValues,
        date: []
      }
    }, [
      advancedAreas,
      filteredOpportunities,
      segmentations,
      sources,
      currentOpportunity,
      currentAreaOfInterest,
      fieldFilterValues
    ])

  const isLoadingGroupData =
    (!!rowGroup && isLoadingRowGroupData) || (!!columnGroup && isLoadingColumnGroupData)

  const isLoadingResources =
    isLoadingAdvAreas ||
    isLoadingSegmentations ||
    isLoadingSegmentationCombinedContexts ||
    isLoadingGroupData ||
    isLoadingSources ||
    isLoadingAreaContexts ||
    isLoadingOppsResources ||
    isLoadingBaseQueryParams ||
    isFetchingContext

  return {
    isLoadingGroupData,
    isLoadingResources,
    mapBreakdownToValues,
    breakdownFilters,
    fieldFilters,
    fieldFilterValues,
    currentGroup,
    getGroup
  }
}

export default useComparativeTableFilters
