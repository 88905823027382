import Text from '@/components/atoms/text'
import { TopicCategory } from '@/types/classification'
import RadioGroup from '@/components/atoms/radio-group'

interface Props {
  selectedCategory: TopicCategory
  categories: { category: TopicCategory; name: string }[]
  onChangeCategory: (category: string) => void
}

const SelectCategory = ({ selectedCategory, categories, onChangeCategory }: Props) => {
  return (
    <form>
      <RadioGroup
        gap="xxs"
        itemDirection="rowReverse"
        justifyContent="spaceBetween"
        onValueChange={onChangeCategory}
        options={categories.map(category => ({
          value: category.category,
          text: (
            <Text color="brandPrimaryPure" typeface="subtitleSemiBoldXXXS">
              {category.name}
            </Text>
          )
        }))}
        value={selectedCategory}
      />
    </form>
  )
}

export default SelectCategory
