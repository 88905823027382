import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'

export const InviteOptionsContainer = styled(FlexContainer, {
  gap: '$xxs',
  alignItems: 'center',
  pb: '$xs',

  variants: {
    bordered: {
      true: {
        borderBottom: '1px solid #E4E4E4',
        mb: '$xs'
      }
    },
    noPadding: {
      true: {
        padding: 0
      }
    },
    // TODO remove this when add users were avaliable
    disabled: {
      true: {
        color: '$grey'
      }
    }
  }
})

export const InviteByEmailContainer = styled(FlexContainer, {
  boxSizing: 'border-box',
  gap: '$xxs',
  width: '100%',

  '& input': {
    boxSizing: 'border-box'
  }
})

export const InputIconContainer = styled('div', {
  position: 'relative',
  width: '100%',

  '& input': {
    paddingLeft: '28px'
  },

  '& svg': {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)'
  }
})

export const InviteButton = styled(Button, {
  fontSize: 14,
  padding: '12px 18px',
  paddingBottom: 10,

  variants: {
    text: {
      true: {
        background: '$greenLight'
      }
    }
  }
})
