import ErrorBoundary from '@/components/atoms/error-boundary'
import { TabsLinkContent } from '@/components/atoms/tabs'
import { useUIStore } from '@/store'
import { styled } from '@/theme'
import { ReactNode } from 'react'
import { shallow } from 'zustand/shallow'
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '../scroll-area'

const variants = {
  variants: {
    expand: {
      true: {
        // width: `calc(100% - 179px) !important`
      }
    },
    fullWidth: {
      true: {
        // width: `calc(100% - 516px)`
      },
      false: {
        // width: `calc(100% - 550px)`
      }
    }
  },

  compoundVariants: [
    {
      expand: true,
      fullWidth: true,
      css: {
        // width: `calc(100% - 150px)`
      }
    }
  ]
}

const Frame = styled('div', {
  bottom: 0,
  borderWidth: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',

  ...variants,

  defaultVariants: {
    fullWidth: false,
    expand: false
  }
})

const FeedScrollArea = styled(ScrollAreaRoot, {
  overflow: 'hidden',
  background: 'transparent',
  bottom: 0,
  height: '100%',

  ...variants,

  defaultVariants: {
    fullWidth: false,
    expand: false
  }
})

interface FeedTabsContentProps {
  id: string
  noDefaultScroll?: boolean
  children: ReactNode
  fullWidth?: boolean
}

const FeedTabsContent = ({
  children,
  fullWidth = false,
  id,
  noDefaultScroll
}: FeedTabsContentProps) => {
  const isExpanded = useUIStore(state => state.classificationCollapsed, shallow)

  return (
    <ErrorBoundary>
      <TabsLinkContent>
        <Frame expand={isExpanded} fullWidth={fullWidth}>
          {noDefaultScroll ? (
            children
          ) : (
            <FeedScrollArea expand={isExpanded} fullWidth={fullWidth}>
              <ScrollAreaViewport id={`scroll-${id}`}>{children}</ScrollAreaViewport>
              <ScrollAreaScrollbar orientation="vertical">
                <ScrollAreaThumb />
              </ScrollAreaScrollbar>
            </FeedScrollArea>
          )}
        </Frame>
      </TabsLinkContent>
    </ErrorBoundary>
  )
}

export default FeedTabsContent
