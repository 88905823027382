import { TopicCategory } from '@/types/classification'
import FilterSubMenu from '../AddFilterMenu/FilterSubMenu'

import useTopicsFilter from './useTopicsFilter'
import TopicsFilterContent from './TopicsFilterContent'

export interface TopicsSubMenuProps {
  category: TopicCategory
}

const TopicsFilterSubMenu = ({ category }: TopicsSubMenuProps) => {
  const {
    filteredThemes,
    getThemeCheckedState,
    hasChanges,
    isTopicChecked,
    isUnclassifiedChecked,
    onApply,
    onThemeCheck,
    onTopicCheck,
    onUnclassifiedCheck
  } = useTopicsFilter({ category })
  const itemTitle = category === 'PRODUCT_AREA' ? 'Product areas' : 'Other topics'

  return (
    <FilterSubMenu
      css={{ py: '$nano' }}
      isDisabled={!hasChanges}
      onApply={onApply}
      title={itemTitle}
    >
      <TopicsFilterContent
        filteredThemes={filteredThemes}
        getThemeCheckedState={getThemeCheckedState}
        hasChanges={hasChanges}
        isTopicChecked={isTopicChecked}
        isUnclassifiedChecked={isUnclassifiedChecked}
        itemTitle={itemTitle}
        onApply={onApply}
        onThemeCheck={onThemeCheck}
        onTopicCheck={onTopicCheck}
        onUnclassifiedCheck={onUnclassifiedCheck}
      />
    </FilterSubMenu>
  )
}

export default TopicsFilterSubMenu
