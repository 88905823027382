import { styled } from '@/theme'

export const Container = styled('article', {
  bAll: '$neutralHighPure',
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  gap: '$xxs',
  padding: '$xs',
  bc: '$neutralHighLight'
})

export const ConnectedTag = styled('span', {
  alignItems: 'center',
  color: '$feedbackPositivePure',
  display: 'flex',
  fontSize: '$micro',
  fontWeight: '$bold',
  gap: 8,
  lineHeight: '$xxl'
})

export const Description = styled('p', {
  color: '$neutralLowLight',
  fontSize: '$xxxs',
  lineHeight: 1.7
})
