import Checkbox from '@/components/atoms/checkbox'
import Dialog, { DeleteDialog } from '@/components/atoms/dialog'
import FlexContainer from '@/components/atoms/flex-container'
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import Text from '@/components/atoms/text'
import useClassification from '@/hooks/useClassification'
import { TopicCategory } from '@/types/classification'
import { Accordion } from '@/components/molecules/accordion'
import { TopicRow } from './TopicItem.styles'
import TopicItem from './TopicItem'
import { MagnifyingGlass, Plus } from '@phosphor-icons/react'
import IconButton from '@/components/atoms/icon-button'
import { useMemo, useState } from 'react'
import SearchInput from '../input/SearchInput'
import NameInput from '../input/NameInput'
import { styled } from '@/theme'
import ClassificationFooter from '../ClassificationFooter'
import useUser from '@/hooks/useUser'

const TopicListScrollArea = styled(ScrollAreaRoot, {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  background: 'transparent',
  height: '72.5vh'
})

const TopicsModal = () => {
  const { userPermissions } = useUser()
  const isManager = userPermissions.topic.includes('manager')

  const {
    expandedTheme,
    expandTheme,
    selectedCategory,
    selectedTopicCount,
    selectTheme,
    unselectTheme,
    addTopic,
    getThemeSelectedState
  } = useClassification()
  const [addingTopicStatus, setAddingTopicStatus] = useState<'top' | 'bottom' | null>(null)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [topicToDelete, setTopicToDelete] = useState<string | undefined>()

  const { removeTopic } = useClassification()

  const themeId = expandedTheme?.themeId ?? ''

  const titleTextMap: Record<TopicCategory, string> = {
    OTHER: 'Managing topic',
    PRODUCT_AREA: 'Managing Product Area'
  }
  const titleText = titleTextMap[selectedCategory]

  function onTopAddClick() {
    setIsSearchOpen(false)
    setSearchInput('')
    setAddingTopicStatus('top')
  }

  function onBottomAddTopic() {
    setAddingTopicStatus('bottom')
  }

  function onAddCancel() {
    setAddingTopicStatus(null)
  }

  function onAddConfirm(value: string) {
    addTopic(value, themeId)
    setAddingTopicStatus(null)
  }

  function onSearchClick() {
    setAddingTopicStatus(null)
    setSearchInput('')
    setIsSearchOpen(value => !value)
  }

  const themeSelectedState = getThemeSelectedState(themeId)
  const isThemeSelected = themeSelectedState !== 'indeterminate' && !!themeSelectedState

  const actualSelectedTopicCount =
    isThemeSelected && !!expandTheme ? expandedTheme?.topics.length : selectedTopicCount
  const topicCountMessage =
    actualSelectedTopicCount +
    ' Subtopic' +
    (actualSelectedTopicCount === 1 ? '' : 's') +
    ' selected'

  function onAllCheck(checked: boolean) {
    if (themeSelectedState === 'indeterminate') {
      unselectTheme(themeId)
      return
    }

    if (checked) {
      selectTheme(themeId)
      return
    }

    unselectTheme(themeId)
  }

  const filteredTopics = useMemo(() => {
    if (!expandedTheme) {
      return []
    }

    return expandedTheme.topics.filter(topic =>
      topic.topicName.toLowerCase().includes(searchInput.toLowerCase())
    )
  }, [expandedTheme, searchInput])

  const onOpenDeleteChange = (open: boolean) => {
    setTopicToDelete(prevTopic => (open ? prevTopic : undefined))
  }

  const onConfirmDelete = () => {
    setTopicToDelete(undefined)
    topicToDelete && removeTopic(topicToDelete)
  }

  if (!expandedTheme) return <></>

  return (
    <Dialog
      align="left"
      closeIcon
      enableInteractionsOutside
      modal={false}
      noBackdrop
      onOpenChange={open => expandTheme(open ? expandedTheme : null)}
      open={Boolean(expandedTheme)}
      useFullHeight
    >
      <Text
        color="neutralLowLight"
        css={{
          display: 'block',
          marginTop: 24,
          marginLeft: 32,
          marginBottom: 16
        }}
        typeface="paragraphRegularMicro"
      >
        {titleText}
      </Text>
      <FlexContainer alignItems="center" css={{ marginLeft: 32, marginRight: 12, marginBottom: 8 }}>
        <Text
          color="neutralLowPure"
          title={expandedTheme?.themeName}
          truncate
          typeface="subtitleSemiBoldXXXS"
        >
          {expandedTheme?.themeName}
        </Text>
        <FlexContainer alignItems="center" css={{ marginLeft: 'auto', gap: 8 }}>
          <IconButton active={isSearchOpen} onClick={onSearchClick}>
            <MagnifyingGlass weight="bold" />
          </IconButton>
          {isManager ? (
            <IconButton onClick={onTopAddClick}>
              <Plus weight="bold" />
            </IconButton>
          ) : null}
        </FlexContainer>
      </FlexContainer>

      {isSearchOpen && (
        <SearchInput
          css={{ padding: '0 32px' }}
          onChange={e => setSearchInput(e.currentTarget.value)}
          value={searchInput}
        />
      )}
      {addingTopicStatus === 'top' && (
        <NameInput
          css={{ paddingLeft: 32, paddingRight: 24 }}
          onCancel={onAddCancel}
          onConfirm={onAddConfirm}
          placeholder="Type subtopic name"
        />
      )}

      <FlexContainer direction="column">
        <TopicRow>
          <Checkbox checked={themeSelectedState} onCheckedChange={onAllCheck} value="all" />
          <Text typeface="subtitleRegularXXXS">All</Text>
          <Text color="neutralLowLight" css={{ marginLeft: 'auto' }} typeface="subtitleRegularXXXS">
            {topicCountMessage}
          </Text>
        </TopicRow>

        <TopicListScrollArea type="scroll">
          <ScrollAreaViewport>
            <Accordion asChild type="multiple">
              <>
                {filteredTopics.map(topic => (
                  <TopicItem
                    key={topic.topicId}
                    onClickRemove={setTopicToDelete}
                    {...topic}
                    isThemeSelected={isThemeSelected}
                  />
                ))}
              </>
            </Accordion>
            <ClassificationFooter
              addingTopicStatus={addingTopicStatus}
              mode="topic"
              onAddCancel={onAddCancel}
              onAddConfirm={onAddConfirm}
              onBottomAddTopic={onBottomAddTopic}
            />
          </ScrollAreaViewport>
          <ScrollAreaScrollbar orientation="vertical">
            <ScrollAreaThumb />
          </ScrollAreaScrollbar>
        </TopicListScrollArea>
      </FlexContainer>

      {topicToDelete && (
        <DeleteDialog
          description="Removing this subtopic also removes the associated keywords."
          isDeleting={false}
          onConfirmDelete={onConfirmDelete}
          onOpenChange={onOpenDeleteChange}
          open={!!topicToDelete}
        />
      )}
    </Dialog>
  )
}

export default TopicsModal
