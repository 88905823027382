import FlexContainer from '@/components/atoms/flex-container'
import { useTranslation } from 'react-i18next'
import Text from '@/components/atoms/text'

const MoveFeedbackNoResults = () => {
  const { t } = useTranslation()

  return (
    <FlexContainer justifyContent="center" alignItems="center">
      <Text fontSize="xxxs" color="neutralLowPure">
        {t('noResultsFound')}
      </Text>
    </FlexContainer>
  )
}

export default MoveFeedbackNoResults
