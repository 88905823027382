import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import useMetricsTableColumns from '@/hooks/metrics/useMetricsTableColumns'
import useOpportunityMutations from '@/hooks/opportunity/useOpportunityMutations'
import useSegment from '@/hooks/useSegment'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import useRemovingItemsStore from '@/store/useRemoveItemsStore'
import useSegmentationStore from '@/store/useSegmentationStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import {
  OpportunityItem,
  OpportunityItemWithMetrics,
  OpportunityStatus
} from '@/types/opportunity/Opportunity'
import { getParamsFromFilterContent } from '@/utils/filters'
import {
  getStatusName,
  ONGOING_STATUS,
  OPPORTUNITY_STATUS_OPTIONS,
  StatusFilter
} from '@/utils/opportunityUtils'
import {
  ColumnDef,
  ColumnFiltersState,
  ColumnSort,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  OnChangeFn,
  SortingState,
  useReactTable
} from '@tanstack/react-table'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MoveOppToSuccessMessage } from '../exploration/Opportunities/OpportunitiesList/OpportunitiesList.styles'
import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import useAreaOfInterest from '@/hooks/areaOfInterest/useAreaOfInterest'
import useUser from '@/hooks/useUser'
import FlexContainer from '@/components/atoms/flex-container'
import OpportunityStatusCell from '../opportunities/cells/OpportunityStatusCell'
import Select from '@/components/atoms/select'
import OpportunityOptions from '../exploration/Opportunities/OpportunityTable/OpportunityOptions'
import useOpportunitiesOfSegment from '@/hooks/opportunity/useOpportunitiesOfSegment'
import { getHeaderCss } from '../opportunities/opportunityTableUtils'
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'
import Button from '@/components/atoms/button'
import { ArrowDown, ArrowUp, LightbulbFilament } from '@phosphor-icons/react'
import Card from '../card'
import TableSkeleton from '../skeleton/TableSkeleton'
import TableV2 from '@/components/atoms/table-v2'
import { colors } from '@/theme'
import RenameOpportunityDialog from '../exploration/Opportunities/OpportunitiesList/RenameOpportunityDialog'
import NewOpportunity from '../exploration/Opportunities/NewOpportunity'
import OpportunitiesListFilters from '../exploration/Opportunities/OpportunitiesList/OpportunityListFilters'
import OpportunityNameCell from '../opportunities/cells/OpportunityNameCell'

const OpportunitiesOfSegment = () => {
  const { t } = useTranslation()
  const { track } = useSegment()

  const currentSegmentation = useSegmentationStore(state => state.currentSegmentation)

  const allowedMetricsBySource = useHiddenMetricsStore(state => state.allowedMetricsBySource)

  const [sorting, setSorting] = useState<ColumnSort[]>([{ id: 'count', desc: true }])
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<StatusFilter>('ongoing')
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    { id: 'status', value: 'ongoing' },
    { id: 'name', value: false }
  ])
  const [filterNew, setFilterNew] = useState(false)

  const { applyOpportunityFilters } = useAdvancedFilters()

  const onOpportunityClick = useCallback(
    (opportunity: OpportunityItemWithMetrics) => {
      track('opportunity_access', {
        opportunity_name: opportunity.name,
        from: 'segment'
      })

      applyOpportunityFilters({
        opportunity,
        areaIds: [],
        clearAllFilters: true
      })
    },
    [applyOpportunityFilters, track]
  )

  const { metricColumns, columnVisibility, setColumnVisibility } =
    useMetricsTableColumns<OpportunityItemWithMetrics>({
      filterFn: item => ({
        ...getParamsFromFilterContent(item.area?.content ?? []),
        context: item.area?.context,
        opportunity_id: item.id
      }),
      metricFor: 'opportunity'
    })

  const { updateOpportunity, isUpdatingOpportunity, moveOpportunity, isMovingOpportunity } =
    useOpportunityMutations()

  const onStatusChange = useCallback(
    (opportunity: OpportunityItemWithMetrics) => (newStatus: OpportunityStatus) => {
      if (opportunity.status === newStatus || !newStatus.length) return
      updateOpportunity({
        id: opportunity.id,
        name: opportunity.name,
        status: newStatus
      })
      const newStatusName = getStatusName(newStatus)

      track('opportunity_change_status', {
        opportunity_name: opportunity.name,
        opportunity_status: newStatusName
      })
    },
    [track, updateOpportunity]
  )

  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const removeToast = useToastMessageStore(state => state.removeToast)
  const { pushRemovingItem } = useRemovingItemsStore()

  const { setArea } = useAreaOfInterest()

  const onConfirmMoveTo = useCallback(
    (opportunity: OpportunityItemWithMetrics) => (newArea: BaseInterestArea) => {
      const removeLoadingToast = addLoadingToast({ text: t('movingOpportunity') })
      pushRemovingItem(opportunity.id)

      moveOpportunity(
        {
          opportunity,
          currentAreaId: opportunity.relations[0] ?? undefined,
          newAreaId: newArea.id
        },
        {
          onSettled: () => removeLoadingToast(),
          onSuccess: () => {
            const toastId = `opp-move-toast-${opportunity.id}`
            track('segment_move_opportunity_to', {
              to: newArea.name,
              from: opportunity.relations[0] ?? '',
              name: opportunity.name
            })
            addSuccessToast({
              id: toastId,
              children: (
                <MoveOppToSuccessMessage
                  areaName={newArea.name}
                  onClick={() => {
                    setArea(newArea)
                    removeToast(toastId)
                  }}
                />
              ),
              duration: 4000
            })
          }
        }
      )
    },
    [
      addLoadingToast,
      pushRemovingItem,
      t,
      addSuccessToast,
      removeToast,
      setArea,
      moveOpportunity,
      track
    ]
  )

  const [opportunityToRename, setOportunityToRename] = useState<
    OpportunityItemWithMetrics | undefined
  >()

  const onConfirmRenameOpportunity = useCallback(
    (newName: string) => {
      if (!opportunityToRename) return
      if (opportunityToRename.name === newName || !newName.length) return

      updateOpportunity({
        id: opportunityToRename.id,
        name: newName.trim(),
        status: opportunityToRename.status
      })
    },
    [opportunityToRename, updateOpportunity]
  )

  const { userPermissions } = useUser()
  const isManager = userPermissions.opportunities.includes('manager')

  const opportunityColumns: ColumnDef<OpportunityItemWithMetrics>[] = useMemo(() => {
    const columns: ColumnDef<OpportunityItemWithMetrics>[] = [
      {
        accessorKey: 'name',
        id: 'name',
        header: () => (
          <FlexContainer alignItems="center" css={{ ml: 21 }} gap="nano">
            <span>{t('opportunities')}</span>
          </FlexContainer>
        ),
        enableHiding: false,
        minSize: 400,
        sortDescFirst: true,
        filterFn: (row, _, filterValue) => {
          const onlyNew = filterValue as boolean

          if (onlyNew) return row.original.new

          return true
        },
        cell: ({ row }) => (
          <OpportunityNameCell
            disabled
            data={row.original}
            onOpportunityClick={onOpportunityClick}
          />
        ),
        footer: ({ column }) => column.id
      },
      ...metricColumns,
      {
        accessorKey: 'status',
        id: 'status',
        header: t('status'),
        minSize: 120,
        maxSize: 175,
        enableSorting: true,
        filterFn: (row, _, filterValue) => {
          const status = filterValue as StatusFilter

          if (status === 'ongoing') {
            return ONGOING_STATUS.includes(row.original.status)
          }

          if (status === 'dismissed') {
            return row.original.status === OpportunityStatus.Dismissed
          }

          return true
        },
        cell: ({ row }) => {
          const opportunity = row.original

          const isDraft = opportunity.status === OpportunityStatus.Draft
          if (isDraft || !isManager) {
            return <OpportunityStatusCell status={opportunity.status} />
          }

          return (
            <FlexContainer css={{ padding: '$xxs' }}>
              <Select
                defaultValue={opportunity.status}
                disabled={isUpdatingOpportunity || isMovingOpportunity}
                onValueChange={onStatusChange(opportunity)}
                options={OPPORTUNITY_STATUS_OPTIONS}
                small
                value={opportunity.status}
                width={140}
              />
            </FlexContainer>
          )
        },
        footer: ({ column }) => column.id
      }
    ]

    if (isManager) {
      columns.push({
        id: 'options',
        minSize: 40,
        maxSize: 40,
        enableSorting: false,
        cell: data => {
          return (
            <OpportunityOptions
              data={data.row.original}
              onConfirmMoveTo={onConfirmMoveTo(data.row.original)}
              onRename={() => setOportunityToRename(data.row.original)}
            />
          )
        }
      })
    }

    return columns
  }, [
    metricColumns,
    isUpdatingOpportunity,
    isMovingOpportunity,
    onStatusChange,
    isManager,
    onOpportunityClick,
    onConfirmMoveTo,
    t
  ])

  const getRowId = useCallback((row: OpportunityItem) => {
    return row.id + '&&' + row.filterId + '&&' + row.relations.length
  }, [])

  const {
    opportunities,
    isLoadingMergedOpportunities,
    isLoadingOpportunities,
    isLoadingAllAreas,
    isLoadingOpportunitiesWithAllMetrics,
    isLoadingOpportunitiesWithSortMetric,
    isLoadingSegmentation,
    hasMore,
    loadNextPage,
    resetPage
  } = useOpportunitiesOfSegment({
    segmentationId: currentSegmentation?.segmentationId,
    sortColumn: sorting[0]?.id ?? 'count',
    sortDirection: sorting[0] ? (sorting[0]?.desc ? 'desc' : 'asc') : 'desc'
  })

  const onSortingChange: OnChangeFn<SortingState> = useCallback(
    updater => {
      setSorting(updater)
      resetPage
    },
    [resetPage]
  )

  const isInitialTableLoading =
    isLoadingOpportunities ||
    isLoadingAllAreas ||
    isLoadingSegmentation ||
    isLoadingMergedOpportunities
  const isLoadMoreDisabled = isInitialTableLoading || isLoadingOpportunitiesWithSortMetric
  const isSomethingLoading =
    isInitialTableLoading ||
    isLoadingOpportunitiesWithAllMetrics ||
    isLoadingOpportunitiesWithSortMetric

  const table = useReactTable<OpportunityItemWithMetrics>({
    data: opportunities,
    columns: opportunityColumns,
    manualFiltering: false,
    manualSorting: true,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange,
    enableColumnResizing: false,
    enableHiding: true,
    enableSorting: !isSomethingLoading,
    state: {
      columnVisibility,
      columnFilters,
      sorting,
      columnPinning: {
        left: ['name'],
        right: ['status', 'options']
      }
    }
  })

  const headerCss = useCallback(getHeaderCss, [])

  const newOppsCount = useMemo(() => {
    return opportunities.filter(opp => opp.new).length
  }, [opportunities])

  useDidUpdateEffect(() => {
    setSorting([{ id: 'count', desc: true }])
  }, [allowedMetricsBySource])

  const renderFooter = () => {
    if (hasMore) {
      return (
        <FlexContainer alignItems="center" css={{ mb: 0 }} fullWidth justifyContent="center">
          <Button disabled={isLoadMoreDisabled} onClick={loadNextPage} variant="link">
            <ArrowDown />
            <span>{t('loadMore')}</span>
          </Button>
        </FlexContainer>
      )
    }
  }

  const onSetFilterNew = useCallback((checked: boolean) => {
    setFilterNew(checked)
    setColumnFilters(prevFilters => {
      return prevFilters.map(prevFilter => {
        if (prevFilter.id === 'name') {
          return { ...prevFilter, value: checked }
        }

        return prevFilter
      })
    })
  }, [])

  const onSetSelectedStatusFilter = useCallback((status: StatusFilter) => {
    setSelectedStatusFilter(status)
    setColumnFilters(prevFilters => {
      return prevFilters.map(prevFilter => {
        if (prevFilter.id === 'status') {
          return { ...prevFilter, value: status }
        }

        return prevFilter
      })
    })
  }, [])

  if (!currentSegmentation) return null

  return (
    <Card.Root color="brandPrimaryPure" direction="column" gap="xs">
      <Card.Header css={{ justifyContent: 'space-between' }}>
        <FlexContainer alignItems="center" gap="micro">
          <FlexContainer alignItems="center" gap="nano">
            <Card.IconBox>
              <LightbulbFilament />
            </Card.IconBox>
            <Card.Title>{t('opportunitiesOfSegmentTitle')}</Card.Title>
          </FlexContainer>
          <NewOpportunity.Filter
            checked={filterNew}
            count={newOppsCount}
            onCheckedChange={onSetFilterNew}
          />
        </FlexContainer>
        <OpportunitiesListFilters
          selectedStatus={selectedStatusFilter}
          setSelectedStatus={onSetSelectedStatusFilter}
        />
      </Card.Header>
      <FlexContainer css={{ maxHeight: 500, overflowY: 'auto' }}>
        {isInitialTableLoading ? (
          <TableSkeleton />
        ) : (
          <TableV2
            enableSort
            footer={renderFooter()}
            sortIndicator={<ArrowUp color={colors.brandPrimaryPure} size={16} />}
            table={table}
            thContainerProps={headerCss}
            dimmed
          />
        )}
      </FlexContainer>

      {!!opportunityToRename && (
        <RenameOpportunityDialog
          onClose={() => setOportunityToRename(undefined)}
          onSave={onConfirmRenameOpportunity}
          opportunityName={opportunityToRename.name}
        />
      )}
    </Card.Root>
  )
}

export default OpportunitiesOfSegment
