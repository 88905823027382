import { AnalyticsFlexContainer } from '../Analytics.styles'
import EmergingTopics from './EmergingTopics'
import TopIssues from './TopIssues'
import TopRequests from './TopRequests'

const TrendingTopics = () => {
  return (
    <AnalyticsFlexContainer justifyContent="stretch">
      <EmergingTopics />
      <TopIssues />
      <TopRequests />
    </AnalyticsFlexContainer>
  )
}

export default TrendingTopics
