import { useEffect, useState } from 'react'
import { ArrowClockwise } from '@phosphor-icons/react'
import { useNavigate, useParams } from 'react-router-dom'

import { DeleteDialog } from '@/components/atoms/dialog'
import IconButton from '@/components/atoms/icon-button'
import OptionsMenu, { OptionsMenuProps } from '@/components/atoms/options-menu'
import Text from '@/components/atoms/text'
import useLetterCase from '@/hooks/useLetterCase'
import useSegment from '@/hooks/useSegment'
import IntegrationService from '@/services/IntegrationServices'
import useIntegrationsStore from '@/store/useIntegrationsStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import { isAxiosError } from '@/types'
import { FormStepContainer } from '../IntegrationForm/IntegrationForm.styles'
import { formatConnectorName, resizeLogoUrl } from '../utils'
import {
  IconButtonCss,
  Image,
  SectionContainer,
  SectionLeftContainer,
  SectionRightContainer
} from './IntegrationDetails.styles'
import IntegrationDetailsSkeleton from './IntegrationDetailsSkeleton'
import IntegrationHistory from '../IntegrationHistory'
import useUser from '@/hooks/useUser'
import useLogging from '@/hooks/useLogging'
import { useTranslation } from 'react-i18next'

interface IntegrationDetailsProps {
  integrationId?: string | undefined
}

function IntegrationDetails(props: IntegrationDetailsProps) {
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()

  const addBreadcrumbs = useIntegrationsStore(state => state.addBreadcrumbs)
  const integrations = useIntegrationsStore(state => state.integrations)
  const reloadIntegration = useIntegrationsStore(state => state.reloadIntegration)
  const removeBreadcrumbs = useIntegrationsStore(state => state.removeBreadcrumbs)
  const removeExtraction = useIntegrationsStore(state => state.removeExtraction)

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const { capitalizeFirst } = useLetterCase()
  const { track } = useSegment()
  const { logException } = useLogging({ context: 'integration-details' })

  const integrationId = params.integrationId ?? props.integrationId
  const integration = integrations.find(integration => integration.id === integrationId)

  const [openOptionsMenu, setOpenOptionsMenu] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [isRemovingExtraction, setIsRemovingExtraction] = useState(false)

  const isRunning = integration?.last_task.status === 'running'

  async function deleteIntegration() {
    if (!integration) return
    try {
      setIsRemovingExtraction(true)
      await removeExtraction(integration.id)

      addSuccessToast({ text: t('integrationWasDisconnectedSuccessfully') })

      navigate('/settings/integrations', { replace: true })
    } catch (error) {
      const message = t('failedToDisconnectIntegration')
      logException(error, { message })
      addErrorToast({ text: message })
    } finally {
      setIsRemovingExtraction(false)
      setOpenDeleteDialog(false)
    }
  }

  function onEditOptionClick() {
    navigate(`/settings/integrations/${integration?.connector.id}/${integration?.id}/edit`)
  }

  async function onRunIntegrationClick() {
    if (!integration) return
    try {
      await IntegrationService.refreshIntegration(integration.id)

      track('sources_user_integration_start', {
        connector_name: integration.connector.name,
        extraction_id: integration.id
      })

      addSuccessToast({ text: t('theIntegrationIsRunningNow') })
    } catch (err) {
      if (
        isAxiosError(err) &&
        err.response?.data.message?.includes('is already running and cannot be refreshed')
      ) {
        const message = t('integrationIsAlreadyRunning')
        logException(err, { message })
        return addErrorToast({ text: message })
      }

      const message = t('failedToRunIntegration')
      logException(err, { message })
      addErrorToast({ text: message })
    } finally {
      reloadIntegration(integration.id)
    }
  }

  async function onCancelRunningClick() {
    if (!integration) return
    try {
      await IntegrationService.cancelTask(integration.last_task.id)

      track('sources_user_integration_stop', {
        connector_name: integration.connector.name,
        extraction_id: integration.id
      })

      addSuccessToast({ text: t('theIntegrationProcessWasCanceled') })
    } catch (err) {
      if (
        isAxiosError(err) &&
        err.response?.data.message?.includes('is already stopped and cannot be canceled')
      ) {
        const message = t('integrationIsAlreadyStoppedAndCannotBeCanceled')
        logException(err, { message })
        return addErrorToast({ text: message })
      }
      const message = t('failedToCancelRunningProcess')
      logException(err, { message })
      addErrorToast({ text: message })
    } finally {
      reloadIntegration(integration.id)
    }
  }

  function onDisconnectOptionClick() {
    // https://github.com/radix-ui/primitives/issues/1088
    setOpenOptionsMenu(false)
    setOpenDeleteDialog(true)
  }

  useEffect(() => {
    if (!integration) return
    const interval = setInterval(() => reloadIntegration(integration.id), 15 * 60 * 1000)

    return function cleanup() {
      clearInterval(interval)
    }
  }, [integration, reloadIntegration])

  useEffect(() => {
    if (!integration?.connector.metadata.multiple_extractions) return

    addBreadcrumbs([
      {
        to: `/settings/integrations/${integration.connector.id}/`,
        name: `${formatConnectorName(integration.connector.name)} integrations`
      }
    ])

    return function cleanup() {
      removeBreadcrumbs()
    }
  }, [addBreadcrumbs, integration?.connector, removeBreadcrumbs])

  const optionsMenuOptions: OptionsMenuProps['options'] = [
    ...(isRunning
      ? [{ text: t('cancelRunningProcess'), onClick: onCancelRunningClick }]
      : [
          { text: t('edit'), onClick: onEditOptionClick },
          { text: t('runIntegrationNow'), onClick: onRunIntegrationClick }
        ]),
    { text: t('disconnect'), onClick: onDisconnectOptionClick }
  ]

  const { userPermissions } = useUser()
  const isManager = userPermissions.source.includes('manager')

  return (
    <>
      <FormStepContainer>
        {integration ? (
          <SectionContainer>
            <SectionLeftContainer>
              <Image
                alt=""
                height={64}
                round={Boolean(integration.metadata?.app_image)}
                src={
                  integration.metadata?.app_image ??
                  resizeLogoUrl(integration.connector.logo_url, 64)
                }
              />

              <Text css={{ lineHeight: '$xs' }} typeface="titleBoldXXS">
                {integration.metadata?.app_image
                  ? capitalizeFirst(integration.name)
                  : formatConnectorName(integration.connector.name)}
              </Text>
            </SectionLeftContainer>
            <SectionRightContainer css={{ paddingRight: 64 }}>
              <Text typeface="subtitleRegularXXXS">
                {t('integrationName')} <b>{capitalizeFirst(integration.name)}</b>
              </Text>
              {isManager && (
                <OptionsMenu
                  buttonStyle={IconButtonCss}
                  onOpenChange={setOpenOptionsMenu}
                  open={openOptionsMenu}
                  options={optionsMenuOptions}
                />
              )}
            </SectionRightContainer>
            <SectionRightContainer>
              <Text typeface="subtitleRegularXXXS">
                {t('status')} <b>{capitalizeFirst(integration.last_task.status)}</b>
              </Text>
              {isRunning ? (
                <IconButton
                  css={IconButtonCss}
                  onClick={() => reloadIntegration(integration.id)}
                  title={t('refresh')}
                  type="button"
                >
                  <ArrowClockwise size={24} />
                </IconButton>
              ) : null}
            </SectionRightContainer>
            <SectionRightContainer>
              <Text typeface="subtitleRegularXXXS">
                {t('lastUpdate')} <b>{capitalizeFirst(integration.last_sync ?? 'never')}</b>
              </Text>
            </SectionRightContainer>
          </SectionContainer>
        ) : (
          <IntegrationDetailsSkeleton />
        )}

        <IntegrationHistory integration={integration} />
      </FormStepContainer>

      <DeleteDialog
        confirmText="Disconnect"
        description={
          <>
            {t('doYouReallyWantToDisconnectThisIntegration')}
            <br />
            {t('youCanConnectItLater')}
          </>
        }
        isDeleting={isRemovingExtraction}
        onConfirmDelete={deleteIntegration}
        onOpenChange={setOpenDeleteDialog}
        open={openDeleteDialog}
        title={t('disconnectIntegration')}
      />
    </>
  )
}

export default IntegrationDetails
