import { styled } from '@/theme'

export const Card = styled('div', {
  padding: '$xxs $xs',
  borderRadius: '$micro',
  boxSizing: 'border-box',
  gap: '$xxs',
  fontSize: '$xxxs',
  lineHeight: 1.39,
  cursor: 'pointer',
  background: '$neutralHighLight',
  bAll: '$neutralHighPure',
  transition: '.2s',

  '&:hover': {
    boxShadow: '$soft'
  },

  variants: {
    selected: {
      true: {
        bAll: '$brandPrimaryPure',
        outline: '1px solid $brandPrimaryPure',
        boxShadow: '$soft',
        p: {
          fontWeight: '$bold'
        }
      },
      false: {
        bAll: '$neutralHighPure',
        fontWeight: '$regular'
      }
    }
  },

  defaultVariants: {
    selected: false
  }
})

export const IconCard = styled(Card, {
  boxShadow: '$soft',
  background: '$neutralHighLight',

  transition: '.2s',
  transform: 'scale(1)',

  '&:hover': {
    transform: 'scale(1.05)'
  },

  p: {
    flex: 1,
    boxSizing: 'border-box',
    alignItems: 'flex-start',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '&::after': {
      content: 'attr(data-text)',
      height: 0,
      visibility: 'hidden',
      overflow: 'hidden',
      userSelect: 'none',
      fontWeight: '$bold'
    }
  },

  variants: {
    format: {
      horizontal: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        height: 96,
        width: 308,
        paddingRight: '$sm'
      },
      square: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        size: 128,

        p: {
          flex: 0
        }
      }
    },
    selected: {
      true: {},
      false: {}
    }
  },

  defaultVariants: {
    format: 'horizontal'
  }
})
