import { useCallback, useState } from 'react'

type CopyStatus = 'idle' | 'copied' | 'error'
type ReturnType = [(text: string) => Promise<void>, CopyStatus, () => void]

const useCopyToClipboard = (): ReturnType => {
  const [status, setStatus] = useState<'idle' | 'copied' | 'error'>('idle')

  const copyToClipboard = useCallback(async (text: string) => {
    if (!navigator.clipboard) {
      setStatus('error')
      return
    }

    try {
      await navigator.clipboard.writeText(text)
      setStatus('copied')
    } catch {
      setStatus('error')
    }
  }, [])

  const reset = useCallback(() => setStatus('idle'), [])

  return [copyToClipboard, status, reset]
}

export default useCopyToClipboard
