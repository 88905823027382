import Checkbox from '@/components/atoms/checkbox'
import Text from '@/components/atoms/text'
import { TopicItemData } from '@/types/classification/Theme'
import { TopicAccordionItem, TopicInfoRow } from './TopicItem.styles'
import { AccordionTrigger, AccordionContent } from '@/components/molecules/accordion'
import FlexContainer from '@/components/atoms/flex-container'
import DistributionBar from '@/components/atoms/distribuition-bar'
import { useCallback, useMemo, useState } from 'react'
import { KeywordSuggestion, KeywordWithTopicIds } from '@/types/keywords'
import useKeywordsRelations from '@/hooks/useKeywords/useKeywordsRelations'
import useToastMessageStore from '@/store/useToastMessageStore'
import useTopicsService from '@/hooks/useTopics/useTopicsService'
import { useFeedbackStore, useKeywordsStore } from '@/store'
import TopicContent from './TopicContent'
import useClassification from '@/hooks/useClassification'
import OptionsMenu, { OptionsMenuItem } from '@/components/atoms/options-menu'
import NameInput from '../input/NameInput'
import MoveTopicMenu from '../move-topic/MoveTopicMenu'
import useUser from '@/hooks/useUser'

interface TopicItemProps extends TopicItemData {
  isThemeSelected: boolean
  onClickRemove: (topic: string) => void
  onSelectTopic?: () => void
  onUnselectTopic?: () => void
}

const TopicItem = ({
  topicId,
  topicName,
  sentimentMetrics,
  frequency,
  isThemeSelected,
  onSelectTopic,
  onUnselectTopic,
  onClickRemove
}: TopicItemProps) => {
  const {
    selectTopic,
    unselectTopic,
    isTopicSelected,
    refreshThemes,
    renameTopic,
    moveTopic,
    expandedTheme,
    fetchKeywordsForTopic
  } = useClassification()

  function onCheck(checked: boolean) {
    if (checked) {
      selectTopic(topicId)
      onSelectTopic?.()
      return
    }

    onUnselectTopic?.()
    unselectTopic(topicId)
  }

  const isSelected = isTopicSelected(topicId) || isThemeSelected

  const [isLoadingKeywords, setIsLoadingKeywords] = useState(false)
  const [keywords, setKeywords] = useState<KeywordWithTopicIds[]>([])
  const [updateSuggestions, setUpdateSuggestions] = useState(false)

  const [isEditActive, setIsEditActive] = useState(false)
  const [openOptions, setOpenOptions] = useState(false)

  const addToast = useToastMessageStore(state => state.addToast)
  const removeToast = useToastMessageStore(state => state.removeToast)

  const { removeRelation } = useTopicsService()

  const { createKeywordAndAddRelations } = useKeywordsRelations()

  const removeRelationsFromKeywords = useKeywordsStore(state => state.removeRelationsFromKeywords)
  const removeKeywordTopicFromFeedback = useFeedbackStore(
    state => state.removeKeywordTopicFromFeedback
  )

  const { userPermissions } = useUser()
  const isManager = userPermissions.topic.includes('manager')

  const pushKeyword = (keyword: KeywordWithTopicIds) => {
    setKeywords(keywordsState => [...keywordsState, keyword])
  }

  const pushKeywordIfExists = (keyword: KeywordWithTopicIds) => {
    setKeywords(keywordsState => {
      if (!keywordsState.find(keywordState => keywordState.keywordHash === keyword.keywordHash)) {
        return [...keywordsState, keyword]
      }

      return keywordsState
    })
  }

  const changeKeywordRelation = (keyword: KeywordWithTopicIds) => {
    setKeywords(keywordsState =>
      keywordsState.map(keywordState =>
        keywordState.keywordHash === keyword.keywordHash ? keyword : keywordState
      )
    )
  }

  const removeKeyword = (keywordHash: string) => {
    setKeywords(keywordsState =>
      keywordsState.filter(keyword => keyword.keywordHash !== keywordHash)
    )
  }

  const loadKeywords = useCallback(() => {
    setIsLoadingKeywords(true)
    fetchKeywordsForTopic(topicId)
      .then(keywords => {
        setIsLoadingKeywords(false)
        setKeywords(keywords)
      })
      .catch(() => setIsLoadingKeywords(false))
  }, [fetchKeywordsForTopic, topicId])

  async function onAddKeyword(keywordText: string) {
    try {
      const createdKeyword = await createKeywordAndAddRelations({
        keywordText,
        topics: [{ topicId, name: topicName }]
      })

      setUpdateSuggestions(true)
      if (createdKeyword) {
        pushKeyword({
          text: keywordText,
          keywordHash: createdKeyword.keywordHash,
          topicIds: [topicId],
          topics: [{ topicId, name: '' }]
        })
      } else {
        loadKeywords()
      }
    } catch (e) {
      console.error(e)
    }
  }

  function onAddSuggestion(keywordSuggestion: KeywordSuggestion) {
    setKeywords(prevKeywords => [
      ...prevKeywords,
      { ...keywordSuggestion, topicIds: [topicId], topics: [{ topicId, name: '' }] }
    ])
  }

  const onRemoveRelationFromTopic = async (keywordHash: string) => {
    const toastId = `removing-${keywordHash}-${topicId}`
    try {
      addToast({ id: toastId, text: 'Removing relation...', status: 'loading', open: true })
      await removeRelation(topicId, keywordHash)
      await refreshThemes()

      removeKeyword(keywordHash)
      removeRelationsFromKeywords({ ids: keywordHash, topicId })
      removeKeywordTopicFromFeedback(keywordHash, topicId)
      removeToast(toastId)

      addToast({
        id: `remove-relation-success-${keywordHash}#${topicId}`,
        text: 'Keyword removed from subtopic successfully',
        status: 'success',
        open: true,
        duration: 2500
      })
    } catch (error) {
      console.error(error)

      removeToast(toastId)
      addToast({
        id: `remove-relation-${keywordHash}#${topicId}`,
        open: true,
        text: 'Failed to remove keyword from subtopic',
        status: 'error'
      })
    }
  }

  function onTopicOpen() {
    loadKeywords()
    // refreshThemes()
  }

  const onCheckboxClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
  }

  const onEditClick = useCallback(() => {
    setIsEditActive(true)
  }, [])

  function onEditConfirm(value: string) {
    renameTopic({ topicId, topicName: value })
    setIsEditActive(false)
  }

  function onEditCancel() {
    setIsEditActive(false)
  }

  const onMoveTopic = useCallback(
    (themeIdDestination: string) => {
      moveTopic({ topic: { topicName, topicId, frequency, sentimentMetrics }, themeIdDestination })
    },
    [topicName, topicId, frequency, sentimentMetrics, moveTopic]
  )

  const options: OptionsMenuItem[] = useMemo(
    () => [
      {
        text: 'Rename',
        onClick: onEditClick
      },
      {
        text: 'Delete',
        onClick: () => {
          setOpenOptions(false)
          onClickRemove(topicId)
        }
      },
      {
        text: 'Move to...',
        customSubOption: (
          <MoveTopicMenu
            defaultSelected={expandedTheme?.themeId || ''}
            mode={'subtopic'}
            onApply={onMoveTopic}
          />
        )
      }
    ],
    [expandedTheme?.themeId, onClickRemove, onEditClick, onMoveTopic, topicId]
  )

  const percentage = {
    negativePercentage: sentimentMetrics.negativePercentage + '%',
    positivePercentage: sentimentMetrics.positivePercentage + '%',
    neutralPercentage: sentimentMetrics.neutralPercentage + '%'
  }

  return (
    <TopicAccordionItem value={topicId}>
      {isEditActive ? (
        <NameInput
          confirmLabel="Apply"
          initialValue={topicName}
          onCancel={onEditCancel}
          onConfirm={onEditConfirm}
        />
      ) : (
        <AccordionTrigger>
          <FlexContainer alignItems="center" css={{ width: '100%' }}>
            <Checkbox
              checked={isSelected}
              onCheckedChange={onCheck}
              onClick={onCheckboxClick}
              value={topicId}
            />
            <Text maxWidth={200} title={topicName} truncate typeface="subtitleRegularXXXS">
              {topicName}
            </Text>
            <TopicInfoRow>
              <DistributionBar
                {...percentage}
                css={{ marginRight: 'auto', width: 28 }}
                height="small"
                outlined={false}
              />
              <Text color="neutralLowLight" typeface="paragraphRegularMicro">
                {frequency}
              </Text>
              {isManager ? (
                <span className="topic-options">
                  <OptionsMenu
                    onOpenChange={setOpenOptions}
                    open={openOptions}
                    options={options}
                    stopPropagation
                  />
                </span>
              ) : null}
            </TopicInfoRow>
          </FlexContainer>
        </AccordionTrigger>
      )}

      <AccordionContent>
        <FlexContainer direction="column" gap="micro">
          <TopicContent
            changeKeywordRelation={changeKeywordRelation}
            keywords={keywords}
            loadingKeywords={isLoadingKeywords}
            onAddKeyword={onAddKeyword}
            onAddSuggestion={onAddSuggestion}
            onLoadKeywords={loadKeywords}
            onOpen={onTopicOpen}
            onRemoveKeyword={onRemoveRelationFromTopic}
            pushKeywordIfExists={pushKeywordIfExists}
            removeKeywordState={removeKeyword}
            setIsLoadingKeywords={setIsLoadingKeywords}
            setUpdateSuggestions={setUpdateSuggestions}
            topicId={topicId}
            topicName={topicName}
            updateSuggestions={updateSuggestions}
          />
        </FlexContainer>
      </AccordionContent>
    </TopicAccordionItem>
  )
}

export default TopicItem
