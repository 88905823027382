import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DeleteDialog } from '@/components/atoms/dialog'
import FlexContainer from '@/components/atoms/flex-container'
import OptionsMenu, { OptionsMenuProps } from '@/components/atoms/options-menu'
import { TableCell } from '@/components/atoms/table'
import useLetterCase from '@/hooks/useLetterCase'
import { formatDateTime } from '@/utils/date'
import { OptionsMenuButton } from './CustomFieldsTableRow.styles'
import { CustomFieldMapping } from '@/types/integrations'
import useDeleteCustomFieldMutation from './useDeleteCustomFieldsMutation'
import { useTranslation } from 'react-i18next'

interface CustomFieldsTableRowProps {
  customField: CustomFieldMapping
  isManager: boolean
}

function CustomFieldsTableRowV2({ customField, isManager }: CustomFieldsTableRowProps) {
  const navigate = useNavigate()

  const { capitalizeFirst } = useLetterCase()
  const { t } = useTranslation()

  const [openOptionsMenu, setOpenOptionsMenu] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const deleteMutation = useDeleteCustomFieldMutation({
    onSuccess: () => setOpenDeleteDialog(false),
    onError: () => setOpenDeleteDialog(false)
  })

  async function deleteCustomField() {
    deleteMutation.mutate(customField.id)
  }

  function handleEditOptionClick() {
    // https://github.com/radix-ui/primitives/issues/1088
    setOpenOptionsMenu(false)
    navigate(`/settings/custom_fields/${customField.id}/edit`)
  }

  function handleDeleteOptionClick() {
    // https://github.com/radix-ui/primitives/issues/1088
    setOpenOptionsMenu(false)
    setOpenDeleteDialog(true)
  }

  const optionsMenuOptions: OptionsMenuProps['options'] = [
    { text: t('edit'), onClick: handleEditOptionClick },
    { text: t('delete'), onClick: handleDeleteOptionClick }
  ]

  function renderDeleteDialog() {
    return (
      <DeleteDialog
        confirmText={t('delete')}
        description={t(
          "doYouReallyWantToDeleteThisCustomFieldYou'reOnlyDeletingThisCustomFieldYourDataIsSafe"
        )}
        isDeleting={deleteMutation.isLoading}
        onConfirmDelete={deleteCustomField}
        onOpenChange={setOpenDeleteDialog}
        open={openDeleteDialog}
        title={t('deleteCustomFieldQuestion')}
      />
    )
  }

  return (
    <tr>
      <TableCell>{customField.to.name}</TableCell>
      <TableCell>{capitalizeFirst(customField.to.type)}</TableCell>
      <TableCell>{formatDateTime(customField.created_at.replace('Z', ''))}</TableCell>
      <TableCell>
        <FlexContainer justifyContent="center">
          {isManager && (
            <OptionsMenu
              buttonStyle={OptionsMenuButton}
              onOpenChange={setOpenOptionsMenu}
              open={openOptionsMenu}
              options={optionsMenuOptions}
            />
          )}
        </FlexContainer>
      </TableCell>

      {renderDeleteDialog()}
    </tr>
  )
}

export default CustomFieldsTableRowV2
