import AudienceService from '@/services/AudienceService'
import { CustomerRecordsStringFieldValue } from '@/types/manage-customers/CustomerRecordsFilters'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

interface Params {
  filterKey: string
  enabled: boolean
}

const useAccountStringFilter = ({ filterKey, enabled }: Params) => {
  const { data, isLoading } = useQuery({
    queryKey: ['filters-accounts-values', filterKey],
    queryFn: async params =>
      AudienceService.getAccountsFiltersValues({ field: filterKey, ...params }),
    enabled,
    refetchOnMount: false,
    retry: false
  })

  const options = useMemo(() => {
    if (!data) return []
    const [error, result] = data
    if (error) return []

    const values = (result as CustomerRecordsStringFieldValue).values ?? []

    return values
  }, [data])

  return {
    isLoading,
    options
  }
}

export default useAccountStringFilter
