import { checkHasHorizontalScroll, checkScrollToRight } from '@/utils/scroll'
import { useCallback, useEffect, useRef, useState } from 'react'

interface Options {
  rightOffset?: number
  leftOffset?: number
}

const useCarouselArrows = ({ rightOffset = 10, leftOffset = 8 }: Options) => {
  const viewportRef = useRef<HTMLDivElement>(null)
  const [hasScroll, setHasScroll] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(false)
  const [showLeftArrow, setShowLeftArrow] = useState(false)

  useEffect(() => {
    setHasScroll(checkHasHorizontalScroll(viewportRef.current))
  })

  const onScrollToRight = useCallback(() => {
    if (!viewportRef.current) return
    const scrolledToRight = checkScrollToRight(viewportRef.current, rightOffset)
    setShowRightArrow(!scrolledToRight)
  }, [rightOffset])

  const onScrollToLeft = useCallback(() => {
    if (!viewportRef.current) return
    setShowLeftArrow(viewportRef.current.scrollLeft > leftOffset)
  }, [leftOffset])

  useEffect(() => {
    if (hasScroll) {
      onScrollToRight()

      onScrollToLeft()
    }
  }, [hasScroll, onScrollToLeft, onScrollToRight])

  function onScroll() {
    if (!viewportRef.current) return
    const canScroll = checkHasHorizontalScroll(viewportRef.current) ?? hasScroll
    setHasScroll(canScroll)

    onScrollToRight()

    onScrollToLeft()
  }

  const updateHasScroll = () => {
    setHasScroll(checkHasHorizontalScroll(viewportRef.current))
  }

  return {
    viewportRef,
    hasScroll,
    showRightArrow,
    showLeftArrow,
    onScroll,
    updateHasScroll
  }
}

export default useCarouselArrows
