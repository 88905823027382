import { styled } from '@/theme'
import { createCalendar } from '@internationalized/date'
import { CalendarProps, useCalendar } from '@react-aria/calendar'
import { useDateFormatter, useLocale } from '@react-aria/i18n'
import { useCalendarState } from '@react-stately/calendar'
import type { DateValue } from '@react-types/calendar'
import { useMemo, useRef } from 'react'
import CalendarButton from './CalendarButton'
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons'
import FlexContainer from '../flex-container'
import { CalendarGrid } from './CalendarGrid'

const CalendarContainer = styled('div', {
  display: 'flex',
  userSelect: 'none',
  fontSize: 14,
  gap: 16,
  height: '280px',
  color: '$neutralLowPure',
  padding: '$xxs'
})

const DatePickerCalendar = (props: CalendarProps<DateValue>) => {
  const ref = useRef(null)

  const { locale } = useLocale()
  const state = useCalendarState({
    ...props,
    locale,
    createCalendar
  })
  const { calendarProps, prevButtonProps, nextButtonProps } = useCalendar(props, state)

  const monthDateFormatter = useDateFormatter({
    month: 'long',
    year: 'numeric',
    timeZone: state.timeZone
  })

  const month = useMemo(
    () => monthDateFormatter.format(state.visibleRange.start.toDate(state.timeZone)),
    [state, monthDateFormatter]
  )

  return (
    <CalendarContainer {...calendarProps} ref={ref}>
      <CalendarButton {...prevButtonProps}>
        <ChevronLeftIcon height={20} width={20} />
      </CalendarButton>
      <FlexContainer alignItems="center" direction="column" fullHeight gap="xxs">
        <h4>{month}</h4>
        <CalendarGrid state={state} />
      </FlexContainer>
      <CalendarButton {...nextButtonProps}>
        <ChevronRightIcon height={20} width={20} />
      </CalendarButton>
    </CalendarContainer>
  )
}

export default DatePickerCalendar
