import { Item } from './KeywordEditor.styles'
import Text from '@/components/atoms/text'
import { MouseEvent } from 'react'
import { CaretRight } from '@phosphor-icons/react'
import { colors } from '@/theme'
import { TopicCategory } from '@/types/classification'
import * as S from './KeywordEditor.styles'

interface Props {
  categories: { category: TopicCategory; name: string }[]
  onClickCategory: (category: TopicCategory) => (e: MouseEvent<HTMLButtonElement>) => void
  isOverflowing?: boolean
}

const CategoriesSection = ({ categories, onClickCategory, isOverflowing }: Props) => {
  return (
    <S.Content fixedHeight={false} isOverflowing={isOverflowing}>
      {categories.map(({ category, name }) => (
        <Item key={category} onClick={onClickCategory(category)}>
          <Text color="pajarito" maxWidth={200} truncate typeface="textSemiboldSmall">
            {name}
          </Text>
          <CaretRight color={colors.grey} size={14} weight="bold" />
        </Item>
      ))}
    </S.Content>
  )
}

export default CategoriesSection
