import FeedbackService from '@/services/FeedbackService'
import { QueryFunctionContext, useInfiniteQuery } from '@tanstack/react-query'
import { useCallback } from 'react'

interface Params {
  name: string
  path: string
  searchText?: string
  enabled?: boolean
}

const useUniqueFiltersQuery = ({ name, path, searchText = '', enabled = true }: Params) => {
  const queryFunction = useCallback(
    async (params: QueryFunctionContext) => {
      const { pageParam, signal } = params

      const currentCursor = pageParam

      const [error, response] = await FeedbackService.getFeedbackUniqueValues(
        {
          name,
          path,
          cursor: currentCursor,
          search: searchText
        },
        { signal }
      )

      if (error) throw error

      return response
    },
    [name, searchText, path]
  )

  const query = useInfiniteQuery({
    queryKey: ['feed-filters', 'unique', name, { searchText }],
    queryFn: queryFunction,
    getNextPageParam: lastPage => lastPage?.nextPage,
    enabled
  })

  return query
}

export default useUniqueFiltersQuery
