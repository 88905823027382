import { createWithEqualityFn } from 'zustand/traditional'

import createInfiniteScrollStore, { InifiniteScrollSlice } from './createInfiniteScrollSlice'
import { CustomerRecordsUserData, FeedbackUserResult } from '@/types/manage-customers'
import { shallow } from 'zustand/shallow'
import AudienceService from '@/services/AudienceService'

interface CustomerUsersState {
  detailedUser: CustomerRecordsUserData | undefined
  hasCustomerUsers: boolean
  usersFeedbacksResults: FeedbackUserResult[]

  setDetailedUser: (user?: CustomerRecordsUserData) => void
  removeUser: (userId: string) => void
  setHasCustomerUsers: (hasCustomerUsers: boolean) => void
  checkHasCustomerUsers: (organizationId?: string) => Promise<boolean>
  setUsersFeedbacksResults: (usersFeedbacksResults: FeedbackUserResult[]) => void
}

const usersInfiniteScrollSlice = createInfiniteScrollStore<CustomerRecordsUserData>()

const useCustomerUsersStore = createWithEqualityFn<
  InifiniteScrollSlice<CustomerRecordsUserData> & CustomerUsersState
>()(
  (set, ...args) => ({
    ...usersInfiniteScrollSlice(set, ...args),
    detailedUser: undefined,
    hasCustomerUsers: false,
    usersFeedbacksResults: [],

    setDetailedUser: user => set(() => ({ detailedUser: user })),
    removeUser: userId => set(state => ({ data: state.data.filter(user => user.id !== userId) })),
    setHasCustomerUsers: hasCustomerUsers => set(() => ({ hasCustomerUsers })),

    checkHasCustomerUsers: async organizationId => {
      const [get] = args
      const { hasCustomerUsers } = get()
      if (hasCustomerUsers) return true

      const [error, data] = await AudienceService.getUsers({
        orgId: organizationId || '',
        per_page: 0
      })
      if (error) return false

      const hasData = data.total > 0

      set(() => ({ hasCustomerUsers: hasData }))

      return hasData
    },

    setUsersFeedbacksResults: usersFeedbacksResults => set(() => ({ usersFeedbacksResults }))
  }),
  shallow
)

export default useCustomerUsersStore
