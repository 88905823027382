import useAreasOfSegment from '@/hooks/areaOfInterest/useAreasOfSegment'
import useSegmentationStore from '@/store/useSegmentationStore'
import { useTranslation } from 'react-i18next'
import Card from '../card'
import { ArrowDown, ArrowUp, FolderOpen } from '@phosphor-icons/react'
import useSegment from '@/hooks/useSegment'
import useNavitagateTo from '@/hooks/useNavigateTo'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import { useCallback, useMemo, useState } from 'react'
import {
  ColumnDef,
  ColumnFiltersState,
  ColumnSort,
  getCoreRowModel,
  OnChangeFn,
  SortingState,
  useReactTable
} from '@tanstack/react-table'
import shortUUID from 'short-uuid'
import { AreaOfInterestData } from '@/types/area/AreaOfInterest'
import useAreaOfInterest from '@/hooks/areaOfInterest/useAreaOfInterest'
import FlexContainer from '@/components/atoms/flex-container'
import useAreaOfInterestOptions from '../area-of-interest/useAreaOfInterestOptions'
import SimpleAreaNameCell from '../area-of-interest/AreaOfInterestList/AreaOfInterestTable/SimpleAreaNameCell'
import useMetricsTableColumns from '@/hooks/metrics/useMetricsTableColumns'
import useUser from '@/hooks/useUser'
import AreaOfInterestOptions from '../area-of-interest/AreaOfInterestList/AreaOfInterestTable/AreaOfInterestOptions'
import { getHeaderCss } from '../opportunities/opportunityTableUtils'
import Button from '@/components/atoms/button'
import TableSkeleton from '../skeleton/TableSkeleton'
import TableV2 from '@/components/atoms/table-v2'
import { colors } from '@/theme'
import { DeleteDialog } from '@/components/atoms/dialog'
import RenameAreaModal from '../area-of-interest/RenameAreaModal'
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'
import HiddenMetricsModal from '../home/HiddenMetricsModal'

const AreasOfSegment = () => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { navigateTo: navigate } = useNavitagateTo()

  const currentSegmentation = useSegmentationStore(state => state.currentSegmentation)

  const allowedMetricsBySource = useHiddenMetricsStore(state => state.allowedMetricsBySource)

  const [sorting, setSorting] = useState<ColumnSort[]>([{ id: 'count', desc: true }])

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    { id: 'name', value: true }
  ])

  const { setArea } = useAreaOfInterest()

  const { metricColumns, columnVisibility, setColumnVisibility } =
    useMetricsTableColumns<AreaOfInterestData>({
      filterFn: area => ({
        context: area.context
      }),
      metricFor: 'area'
    })

  const { userPermissions } = useUser()
  const isManager = userPermissions.areas.includes('manager')

  const translator = useMemo(() => shortUUID(), [])

  const onAreaClick = useCallback(
    (area: AreaOfInterestData) => {
      track('area_access', { area_name: area.name, from: 'segment' })
      setArea(area)

      const shortAreaId = translator.fromUUID(area.id)
      navigate(`/area/${shortAreaId}`)
    },
    [track, setArea, translator, navigate]
  )

  const {
    onClickUpdateUseInUnmapped,
    isDeleting,
    onConfirmDelete,
    onOpenDeleteChange,
    isDeleteModalOpen,
    onDeleteAreaClick,
    onRenameAreaClick,
    onConfirmRename,
    onOpenRenameChange,
    isRenameModalOpen,
    areaToRename
  } = useAreaOfInterestOptions()

  const areaColumns: ColumnDef<AreaOfInterestData>[] = useMemo(() => {
    const columns: ColumnDef<AreaOfInterestData>[] = [
      {
        accessorKey: 'name',
        id: 'name',
        header: () => (
          <FlexContainer alignItems="center" css={{ ml: 21 }} gap="nano">
            <span>{t('areas')}</span>
          </FlexContainer>
        ),
        enableHiding: false,
        minSize: 400,
        maxSize: 420,
        sortDescFirst: true,
        cell: props => (
          <SimpleAreaNameCell disabled data={props.row.original} onAreaClick={onAreaClick} />
        ),
        footer: props => props.column.id
      },
      ...metricColumns
    ]

    if (isManager) {
      columns.push({
        id: 'options',
        minSize: 40,
        maxSize: 40,
        enableSorting: false,
        cell: props => (
          <AreaOfInterestOptions
            data={props.row.original}
            onClickUpdateUseInUnmapped={onClickUpdateUseInUnmapped}
            onDeleteAreaClick={onDeleteAreaClick}
            onRenameAreaClick={onRenameAreaClick}
          />
        )
      })
    }

    return columns
  }, [
    t,
    onAreaClick,
    metricColumns,
    isManager,
    onClickUpdateUseInUnmapped,
    onDeleteAreaClick,
    onRenameAreaClick
  ])

  const getRowId = useCallback((row: AreaOfInterestData) => row.id, [])

  const {
    areasOfSegment,
    isLoadingAllAreas,
    isSegmentationDataLoading,
    isLoadingAllAreasOfSegmentWithSortMetric,
    isLoading,
    hasMore,
    loadNextPage,
    resetPage
  } = useAreasOfSegment({
    segmentationId: currentSegmentation?.segmentationId,
    sortColumn: sorting[0]?.id ?? 'count',
    sortDirection: sorting[0] ? (sorting[0].desc ? 'desc' : 'asc') : 'desc'
  })

  const onSortingChange: OnChangeFn<SortingState> = useCallback(
    updater => {
      setSorting(updater)
      resetPage()
    },
    [resetPage]
  )

  const isInitialTableLoading = isLoadingAllAreas || isSegmentationDataLoading
  const isLoadMoreDisabled = isInitialTableLoading || isLoadingAllAreasOfSegmentWithSortMetric
  const isSomethingLoading = isLoading

  const table = useReactTable<AreaOfInterestData>({
    data: areasOfSegment,
    columns: areaColumns,
    manualFiltering: true,
    manualSorting: true,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange,
    enableColumnResizing: false,
    enableHiding: true,
    enableRowSelection: false,
    enableSorting: !isSomethingLoading,
    state: {
      columnVisibility,
      columnFilters,
      sorting,
      columnPinning: {
        left: ['name'],
        right: ['options']
      }
    }
  })

  const headerCss = useCallback(getHeaderCss, [])

  useDidUpdateEffect(() => {
    setSorting([{ id: 'count', desc: true }])
  }, [allowedMetricsBySource])

  const renderFooter = () => {
    if (hasMore) {
      return (
        <FlexContainer alignItems="center" css={{ mb: 0 }} fullWidth justifyContent="center">
          <Button disabled={isLoadMoreDisabled} onClick={loadNextPage} variant="link">
            <ArrowDown />
            <span>{t('loadMore')}</span>
          </Button>
        </FlexContainer>
      )
    }
  }

  if (!currentSegmentation) return null

  return (
    <Card.Root color="brandPrimaryPure" direction="column" gap="xs">
      <Card.Header css={{ justifyContent: 'space-between' }}>
        <FlexContainer alignItems="center" gap="nano">
          <Card.IconBox>
            <FolderOpen />
          </Card.IconBox>
          <Card.Title>{t('areasOfSegmentTitle')}</Card.Title>
        </FlexContainer>
        <HiddenMetricsModal borderedAndSmall />
      </Card.Header>
      <FlexContainer css={{ maxHeight: 500, overflowY: 'auto' }}>
        {isInitialTableLoading ? (
          <TableSkeleton />
        ) : (
          <TableV2
            enableSort
            footer={renderFooter()}
            sortIndicator={<ArrowUp color={colors.brandPrimaryPure} size={16} />}
            table={table}
            thContainerProps={headerCss}
            dimmed
          />
        )}
      </FlexContainer>

      {isDeleteModalOpen && (
        <DeleteDialog
          cancelText={t('cancel')}
          confirmText={t('delete')}
          description={t(
            'deletingWillPermanentlyEraseThisAreaOfInterestAndAnyDataAssociatedLikeChartsAndOpportunitiesPleaseProceedWithCaution'
          )}
          isDeleting={isDeleting}
          onConfirmDelete={onConfirmDelete}
          onOpenChange={onOpenDeleteChange}
          open={isDeleteModalOpen}
          title={t('deleteAreaOfInterestQuestion')}
        />
      )}
      {isRenameModalOpen && (
        <RenameAreaModal
          onOpenChange={onOpenRenameChange}
          onRename={onConfirmRename}
          open={isRenameModalOpen}
          previousName={areaToRename?.name ?? ''}
        />
      )}
    </Card.Root>
  )
}

export default AreasOfSegment
