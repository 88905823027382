import FiltersService from '@/services/FiltersService'
import { AreaOfInterestData } from '@/types/area/AreaOfInterest'
import { AdvancedFilterContent, FilterNode } from '@/types/filters/AdvancedFilters'
import { advancedFilterToContent, mergeAdvancedFilters } from '@/utils/advancedFilter'

interface Params {
  areas: AreaOfInterestData[]
  currentFilters: FilterNode
}

const getAreaFiltersCombinedFn = async ({ areas, currentFilters }: Params) => {
  const baseContent: AdvancedFilterContent = advancedFilterToContent(currentFilters)

  const combinedFilters = areas.map(area => {
    const areaContent = area.content[0]?.values.filter
    const filtersToCombine = areaContent ? [areaContent, baseContent] : [baseContent]
    const combinedFilters = mergeAdvancedFilters(filtersToCombine, '$and')

    return { identifier: area.id, filter: combinedFilters }
  })

  const [error, responseData] = await FiltersService.multipleAdvancedFilterContext({
    filters: combinedFilters
  })

  if (error) throw error
  return responseData
}

export default getAreaFiltersCombinedFn
