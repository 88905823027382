import * as S from './TopicGroupEditor.styles'
import Text from '@/components/atoms/text'
import Switch from '@/components/atoms/switch'
import { useMemo } from 'react'
import FlexContainer from '@/components/atoms/flex-container'
import { TopicDataWithCategory, TopicThemeItem } from '@/types/classification'
import { sortByKeyString } from '@/utils/array'
import { useTranslation } from 'react-i18next'
interface Props {
  searchQuery: string
  topicGroups: TopicDataWithCategory[]
  isTopicGroupChecked: (topicId: string) => boolean
  onCheckTopicGroup: (theme: TopicThemeItem) => (checked: boolean) => void
  isOverflowing?: boolean
}

const SearchResultSection = ({
  searchQuery,
  topicGroups,
  isTopicGroupChecked,
  onCheckTopicGroup,
  isOverflowing
}: Props) => {
  const { t } = useTranslation()
  const results = useMemo(() => {
    const filtered = topicGroups.filter(
      group => group.topicName.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    )
    return filtered.sort(sortByKeyString('topicName'))
  }, [searchQuery, topicGroups])

  if (!results.length)
    return (
      <FlexContainer alignItems="center" css={{ marginBottom: 4 }} justifyContent="center">
        <Text color="grey" typeface="textRegularXSmall">
          {t('noResults')}
        </Text>
      </FlexContainer>
    )

  return (
    <S.Content fixedHeight isOverflowing={isOverflowing}>
      {results.map(result => {
        return (
          <S.Item key={result.topicId}>
            <Text color="pajarito" title={result.topicName} truncate typeface="textSemiboldSmall">
              {result.topicName}
            </Text>
            <Switch
              checked={isTopicGroupChecked(result.topicId || '')}
              onCheckedChange={onCheckTopicGroup({
                themeCategory: result.category,
                themeId: result.topicId,
                themeName: result.topicName
              })}
              value={result.topicId}
            />
          </S.Item>
        )
      })}
    </S.Content>
  )
}

export default SearchResultSection
