import { HandPalm } from '@phosphor-icons/react'

import FilterSubMenu from '../AddFilterMenu/FilterSubMenu'
import useFeedbackIntentionSelection from './useFeedbackIntentionSelectionNew'
import FeedbackIntentionContent from './FeedbackIntentionContent'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { isAreaOfInterestFilter } from '@/utils/areaOfInterest'
import { useTranslation } from 'react-i18next'

const FeedbackIntentionSubMenu = () => {
  const { selectOption, isChecked, hasChanges, apply, appliedOptions } =
    useFeedbackIntentionSelection()

  const { t } = useTranslation()

  const areaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  // if (isAreaOfInterestFilter('intention', appliedOptions)) return <></>
  if (
    isAreaOfInterestFilter({
      key: 'intention',
      values: appliedOptions,
      areaOfInterest
    })
  )
    return <></>

  return (
    <FilterSubMenu
      icon={<HandPalm />}
      isDisabled={!hasChanges}
      onApply={apply}
      title={t('feedbackIntention')}
    >
      <FeedbackIntentionContent isChecked={isChecked} selectOption={selectOption} />
    </FilterSubMenu>
  )
}

export default FeedbackIntentionSubMenu
