import { colors, CSS } from '@/theme'
import { Component, ErrorInfo, ReactNode } from 'react'
import FlexContainer from '../flex-container'
import Text from '../text'
import { XCircle } from '@phosphor-icons/react'
import { sentryCaptureException } from '@/utils/errors'
import i18n from '../../../../src/plugins/i18n/i18n'

export interface ErrorBoundaryProps {
  children?: ReactNode
  title?: string
  message?: string
  details?: string
  action?: ReactNode
  css?: CSS
  className?: string
  alwaysShowError?: boolean
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  public state: State = {
    hasError: false
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    sentryCaptureException(error, {
      context: 'error-boundary',
      message: this.props.details ?? i18n.t('somethingWentWrong')
    })
    console.error('Uncaught error handled by ErrorBoundary:', error, errorInfo)
  }

  public render() {
    const { alwaysShowError } = this.props
    if (this.state.hasError || alwaysShowError) {
      const { title, message, details, action, css, className } = this.props
      return (
        <FlexContainer
          alignItems="center"
          className={className}
          css={{ width: '100%', ...css }}
          direction="column"
          justifyContent="center"
        >
          <XCircle color={colors.red} size={80} />
          {title && (
            <Text as="h3" typeface="textH2MediumHeading">
              {title}
            </Text>
          )}
          <Text as="h4" color="red" typeface="textH3SmallHeading">
            {message || 'Oops!'}
          </Text>
          <Text as="h5" typeface="textBodySmallRegular">
            {details || i18n.t('somethingWentWrong')}
          </Text>
          {action}
        </FlexContainer>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
