import { useFiltersStore } from '@/store'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import { RadioBooleanOption } from '@/types/filters/Filters'
import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'

interface Params {
  filterKey: string
  type: string
  name: string
  newFeed?: boolean
}

const useBooleanGenericFilter = ({ filterKey, type, name, newFeed = false }: Params) => {
  const defaultBooleanFilters = useFiltersStore(state => state.booleanFilters, shallow)
  const defaultSetBooleanFilter = useFiltersStore(state => state.setBooleanFilter)

  const newFeedBooleanFilters = useFeedFiltersStore(state => state.booleanFilters, shallow)
  const newFeedSetBooleanFilter = useFeedFiltersStore(state => state.setBooleanFilter)

  const booleanFilters = newFeed ? newFeedBooleanFilters : defaultBooleanFilters
  const setBooleanFilter = newFeed ? newFeedSetBooleanFilter : defaultSetBooleanFilter

  const storedFilter = useMemo(
    () => booleanFilters.find(filter => filter.key === filterKey),
    [booleanFilters, filterKey]
  )

  const selectedOption: RadioBooleanOption = useMemo(() => {
    const value = storedFilter?.draftValue ?? null
    if (value === null) {
      return 'all'
    } else if (value) {
      return 'true'
    } else {
      return 'false'
    }
  }, [storedFilter?.draftValue])

  const onOptionChange = (option: string) => {
    if (option === 'all') {
      setBooleanFilter({ key: filterKey, name, type, value: null })
    } else if (option === 'true') {
      setBooleanFilter({ key: filterKey, name, type, value: true })
    } else {
      setBooleanFilter({ key: filterKey, name, type, value: false })
    }
  }

  const onApply = () => {
    setBooleanFilter({ key: filterKey, name, type, value: storedFilter?.draftValue ?? null }, true)
  }

  const isDisabled = useMemo(
    () => storedFilter?.draftValue === storedFilter?.value,
    [storedFilter?.draftValue, storedFilter?.value]
  )

  return {
    selectedOption,
    onOptionChange,
    onApply,
    isDisabled
  }
}

export default useBooleanGenericFilter
