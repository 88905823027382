import { useEffect, useMemo, useState } from 'react'
import { useArchivedFeedbackStore, useFeedbackStore, useUIStore } from '@/store'
import ToastMessage from '@/components/molecules/ToastMessagesProvider'
import FlexContainer from '@/components/atoms/flex-container'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import useSegment from '@/hooks/useSegment'
import { useKeywords } from '@/hooks/useKeywords/useKeywords'
import useSavedFiltersStore from '@/store/useSavedFiltersStore'
import useSavedFilters from '@/hooks/useSavedFilters'
import useUser from '@/hooks/useUser'
import NoPermission from '@/components/molecules/no-permission/NoPermission'
import { useTranslation } from 'react-i18next'
import Tabs, { TabItem } from '@/components/atoms/tabs'

function Settings() {
  const { track } = useSegment()

  const { t } = useTranslation()

  const location = useLocation()
  const navigate = useNavigate()

  const { setPageTitle } = useUIStore()

  const resetFeedbacks = useFeedbackStore(state => state.reset)
  const resetArchivedFeedbacks = useArchivedFeedbackStore(state => state.reset)
  const { reset: resetKeywords } = useKeywords()

  const setCurrentSavedFilter = useSavedFiltersStore(state => state.setCurrentSavedFilter)
  const { removeHashFromUrl } = useSavedFilters()
  const { removeHashFromUrl: removeNewFeedHashFromUrl } = useSavedFilters({ newFeed: true })

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    track('explore_user_go_to_sources_page')
    setPageTitle('Settings')

    resetFeedbacks()
    resetArchivedFeedbacks()
    resetKeywords()
    setCurrentSavedFilter(undefined)
    removeHashFromUrl()
    removeNewFeedHashFromUrl()

    if (location.pathname === '/settings') {
      navigate('/settings/organization')
    }
  }, [])

  const { userPermissions, currentUser } = useUser()
  const isManager = userPermissions.source.includes('manager')
  const canViewImportCustomers = userPermissions.source.includes('customer_records')
  const canViewAreaNotifications = userPermissions.areas.includes('manager')

  const [currentTab, setCurrentTab] = useState('organization')
  const tabs = useMemo(() => {
    const _tabs: TabItem[] = [
      { text: t('organization'), value: 'organization' },
      { text: t('integrations'), value: 'integrations' }
    ]

    if (isManager) {
      _tabs.push({ text: t('importFeedback'), value: 'import_data' })
    }

    if (canViewImportCustomers) {
      _tabs.push({ text: t('importCustomers'), value: 'import_customers' })
    }

    _tabs.push({ text: t('customFields'), value: 'custom_fields' })

    if (canViewAreaNotifications) {
      _tabs.push({ text: t('manageNotifications'), value: 'manage_notifications' })
      _tabs.push({ text: t('notificationChannels'), value: 'notification_channels' })
    }

    return _tabs
  }, [isManager, canViewImportCustomers, canViewAreaNotifications, t])

  if (userPermissions.source.length === 0 && currentUser) {
    return <NoPermission />
  }

  return (
    <>
      <Tabs
        css={{
          overflowY: 'visible',
          height: '100%',
          ml: '$navigationBarWidth',
          pt: '$xs',
          px: '$xs'
        }}
        onValueChange={setCurrentTab}
        orientation="horizontal"
        stretch={false}
        tabs={tabs}
        triggerType="link"
        value={currentTab}
      >
        <FlexContainer
          as="main"
          css={{ overflowY: 'scroll', py: '$sm', width: '100%' }}
          direction="column"
        >
          <Outlet />
        </FlexContainer>
      </Tabs>

      <ToastMessage />
    </>
  )
}

export default Settings
