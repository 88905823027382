import useEnumFiltersQuery from '@/hooks/filters/useEnumFiltersQuery'
import useStringFilterSelection from '@/hooks/filters/useStringFilterSelection'
import { useMemo } from 'react'

interface Params {
  filterKey: string
  name: string
  enabled: boolean
}

const useStringGenericFilter = ({ filterKey, name, enabled }: Params) => {
  const { data, isLoading } = useEnumFiltersQuery({ name, enabled })

  const { isChecked, selectOption, hasChanges, selectedOptions, apply } = useStringFilterSelection({
    name,
    filterKey,
    type: 'enum',
    newFeed: true
  })

  const options = useMemo(() => {
    if (!data) return []

    return data.values ?? []
  }, [data])

  return {
    isLoading,
    isChecked,
    selectOption,
    selectedOptions,
    hasChanges,
    apply,
    options
  }
}

export default useStringGenericFilter
