import FlexContainer from '@/components/atoms/flex-container'
import IconButton from '@/components/atoms/icon-button'
import Input, { InputErrorMessage } from '@/components/atoms/input'
import { WarningCircle, X } from '@phosphor-icons/react'
import i18n from '../../../../../plugins/i18n/i18n'

interface Props {
  value: string
  onValueChange: (value: string) => void
  onClearClick: () => void
  placeholder?: string
  error?: string
}

const TextFilterContent = ({
  value,
  onValueChange,
  onClearClick,
  placeholder = i18n.t('typeFilterValue'),
  error
}: Props) => {
  return (
    <FlexContainer alignItems="baseline" gap="micro">
      <FlexContainer direction="column" fullWidth gap="nano">
        <Input
          error={Boolean(error)}
          onChange={e => onValueChange(e.currentTarget.value)}
          placeholder={placeholder}
          value={value}
        />
        {Boolean(error) && (
          <InputErrorMessage>
            <FlexContainer alignItems="center" gap="nano">
              <WarningCircle size={14} />
              <span>{error}</span>
            </FlexContainer>
          </InputErrorMessage>
        )}
      </FlexContainer>
      <IconButton onClick={onClearClick} size="small">
        <X />
      </IconButton>
    </FlexContainer>
  )
}

export default TextFilterContent
