import Divider from '@/components/atoms/divider'
import AssociatedOpportunityModule from '@/components/molecules/initiatives/AssociatedOpportunityModule'
import InitiativeHeader from '@/components/molecules/initiatives/InitiativeHeader'
import InitiativeOverview from '@/components/molecules/initiatives/InitiativeOverview'
import PinnedEvidenceModule from '@/components/molecules/initiatives/pinned-feedback/PinnedEvidenceModule'
import PageContentScroll from '@/components/molecules/layout/PageContentScroll'
import ToastMessagesProvider from '@/components/molecules/ToastMessagesProvider'
import useConfigureAllowedMetrics from '@/hooks/metrics/useConfigureAllowedMetrics'
import usePageLink from '@/hooks/usePageLink'
import { styled } from '@/theme'

const LightDivider = styled(Divider, {
  borderColor: '#05495F1A'
})

const InitiativePage = () => {
  usePageLink()

  useConfigureAllowedMetrics()

  return (
    <PageContentScroll
      css={{
        pl: 'calc(56px + $navigationBarWidth)',
        pr: '56px'
      }}
    >
      <InitiativeHeader />
      <LightDivider />
      <InitiativeOverview />
      <LightDivider />
      <AssociatedOpportunityModule />
      <PinnedEvidenceModule />

      <ToastMessagesProvider />
    </PageContentScroll>
  )
}

export default InitiativePage
