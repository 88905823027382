import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import { FilterItem } from '@/types/filters/Filters'
import { ReactNode } from 'react'
import { FilterSubMenuScrollArea } from './AddFilterMenu.styles'
import FilterSubMenu from './FilterSubMenu'
import { DatetimeFilterSubMenuNew } from '../DatetimeGenericFilter'
import NumericFilterSubMenu from '../NumericGenericFilter/NumericFilterSubMenuNew'
import StringFilterSubMenu from '../StringGenericFilter/StringFilterSubMenuNew'
import { BooleanFilterSubMenu } from '../BooleanGenericFilter'
import { TextFilterSubMenuNew } from '../TextGenericFilter'
import UniqueFilterSubMenuNew from '../UniqueGenericFilter/UniqueFilterSubMenuNew'

export interface GenericFiltersProps {
  filters: FilterItem[]
  isLoading?: boolean
  icon: ReactNode
  title: string
}

const FilterItemComponent = (props: FilterItem & { title: string; filterKey: string }) => {
  if (props.type.includes('datetime') || props.type.includes('date')) {
    return (
      <DatetimeFilterSubMenuNew
        filterKey={props.filterKey}
        name={props.name}
        previousTitle={props.title}
        title={props.displayName}
      />
    )
  }

  if (props.type.includes('number') || props.type.includes('integer')) {
    return (
      <NumericFilterSubMenu
        filterKey={props.filterKey}
        name={props.name}
        previousTitle={props.title}
        title={props.displayName}
      />
    )
  }

  if (props.type.includes('text')) {
    return (
      <TextFilterSubMenuNew
        filterKey={props.filterKey}
        name={props.name}
        previousTitle={props.title}
        title={props.displayName}
      />
    )
  }

  if (props.type.includes('unique')) {
    return (
      <UniqueFilterSubMenuNew
        filterKey={props.filterKey}
        name={props.name}
        path={props.path ?? props.filterKey}
        previousTitle={props.title}
        title={props.displayName}
      />
    )
  }

  if (props.type.includes('boolean')) {
    return (
      <BooleanFilterSubMenu
        filterKey={props.filterKey}
        name={props.name}
        newFeed
        previousTitle={props.title}
        title={props.displayName}
        type={props.type}
      />
    )
  }

  return (
    <StringFilterSubMenu
      filterKey={props.filterKey}
      name={props.name}
      previousTitle={props.title}
      title={props.displayName}
    />
  )
}

const GenericFiltersSection = ({
  filters,
  isLoading = false,
  icon,
  title
}: GenericFiltersProps) => {
  return (
    <FilterSubMenu compact hideButton icon={icon} isLoading={isLoading} title={title}>
      <FilterSubMenuScrollArea>
        <ScrollAreaViewport css={{ maxHeight: 320 }}>
          {filters.map((filter, index) => (
            <FilterItemComponent
              {...filter}
              filterKey={filter.key}
              key={filter.key + filter.name + index}
              title={title}
            />
          ))}
        </ScrollAreaViewport>
        <ScrollAreaScrollbar orientation="vertical">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </FilterSubMenuScrollArea>
    </FilterSubMenu>
  )
}

export default GenericFiltersSection
