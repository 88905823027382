import { useState } from 'react'

interface Params<T> {
  array: T[]
  pageSize: number
  sliced?: boolean
}

function usePagination<T = unknown>({ array, pageSize, sliced = false }: Params<T>) {
  const [page, setPage] = useState(0)

  const hasNextPage = array.length > (page + 1) * pageSize
  const hasPrevPage = page > 0

  const nextPage = () => {
    if (!hasNextPage) return
    setPage(prev => prev + 1)
  }

  const prevPage = () => {
    if (!hasPrevPage) return
    setPage(prev => prev - 1)
  }

  const resetPage = () => {
    setPage(0)
  }

  let paginatedArray: T[] = []

  if (sliced) {
    const start = page * pageSize
    const end = start + pageSize
    paginatedArray = array.slice(start, end)
  } else {
    paginatedArray = array.slice(0, (page + 1) * pageSize)
  }

  return {
    page,
    setPage,
    paginatedArray,
    nextPage,
    prevPage,
    resetPage,
    hasNextPage,
    hasPrevPage
  }
}

export default usePagination
