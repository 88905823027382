import FlexContainer from '@/components/atoms/flex-container'
import { TopicKeywordChip } from '@/components/atoms/topic-chip'
import useSegment from '@/hooks/useSegment'
import { useKeywordsStore } from '@/store'
import useToastMessageStore from '@/store/useToastMessageStore'
import { colors, styled } from '@/theme'
import { KeywordSuggestion, KeywordWithTopicIds, TopicRelationEvent } from '@/types/keywords'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb
} from '@/components/molecules/scroll-area'
import { ScrollAreaViewport } from '@radix-ui/react-scroll-area'
import Button from '@/components/atoms/button'
import { MagicWand, Plus, X } from '@phosphor-icons/react'
import KeywordsSkeleton from './KeywordsSkeleton'
import Text from '@/components/atoms/text'
import Chip from '@/components/atoms/chip'
import useClassification, { REFRESH_THEMES_MIN_DELAY } from '@/hooks/useClassification'
import NameInput from '../input/NameInput'
import OptionsMenu, { OptionsMenuItem } from '@/components/atoms/options-menu'
import useEvents from '@/hooks/useEvents'
import IconButton from '@/components/atoms/icon-button'
import KeywordEditorSubmenu from '../../keyword-editor/KeywordEditorSubmenu'
import useKeywordsRelations from '@/hooks/useKeywords/useKeywordsRelations'
import { OnApplyCallback } from '../../keyword-editor/KeywordEditor'
import useUser from '@/hooks/useUser'
import useLogging from '@/hooks/useLogging'

const TopicContentScrollArea = styled(ScrollAreaRoot, {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  background: 'transparent',

  maxHeight: 150
})

const TopicContentScrollAreaViewport = styled(ScrollAreaViewport, {
  paddingLeft: 48,
  paddingBottom: 24,

  width: '100%',
  boxSizing: 'border-box'
})

const Fade = styled('div', {
  position: 'relative',
  zIndex: 2,
  height: 30,
  marginTop: -35,
  width: 380,
  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 41.07%, #FFFFFF 90.16%);'
})

const ButtonGroup = styled('div', {
  marginLeft: 40,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  zIndex: 4,
  top: -8
})

const KeywordChipContainer = styled(FlexContainer, {
  alignItems: 'center',
  gap: 8,

  '.chip-options': {
    pr: '$nano',
    position: 'relative',
    zIndex: 100,
    // overflow: 'visible',
    opacity: 0
  },

  '&:hover': {
    '.chip-options': {
      opacity: 1
    }
  }
})

const TopicKeywordContainer = styled(KeywordChipContainer, {
  position: 'relative',
  bLeft: '$neutralHighPure',
  pl: 4,
  ml: -24,
  pt: '$micro',

  '&::before': {
    position: 'relative',
    left: -4,
    content: '',
    background: '$neutralHighPure',
    width: 8,
    height: 1
  },

  '&:last-child': {
    bLeft: 'transparent',
    // mt: -4,
    '&::before': {
      position: 'relative',
      background: 'transparent',
      width: 8,
      height: 44,
      bLeft: '$neutralHighPure',
      bBottom: '$neutralHighPure',
      mt: -22,
      top: -12,
      left: -5
    }
  }
})

interface TopicContentProps {
  topicId: string
  topicName: string
  updateSuggestions: boolean
  setUpdateSuggestions: (value: boolean) => void
  onAddKeyword: (value: string) => Promise<void>
  onRemoveKeyword: (value: string) => Promise<void>
  onAddSuggestion: (suggestion: KeywordSuggestion) => void
  keywords: KeywordWithTopicIds[]
  loadingKeywords: boolean
  onOpen: () => void
  onLoadKeywords: () => void
  setIsLoadingKeywords: (value: React.SetStateAction<boolean>) => void
  removeKeywordState: (keywordHash: string) => void
  changeKeywordRelation: (keyword: KeywordWithTopicIds) => void
  pushKeywordIfExists: (keyword: KeywordWithTopicIds) => void
}

const TopicContent = (props: TopicContentProps) => {
  const { track } = useSegment()
  const { logException } = useLogging({ context: 'topic-content' })

  useEffect(props.onOpen, [])

  const { refreshThemes, removeTopicKeywordSuggestion } = useClassification()

  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false)
  const [isAddingSuggestion, setIsAddingSuggestion] = useState(false)
  const [isAddingKeyword, setIsAddingKeyword] = useState(false)
  const [isSuggestionsLoadedOnce, setIsSuggestionsLoadedOnce] = useState(false)
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false)

  const addToast = useToastMessageStore(state => state.addToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const removeToast = useToastMessageStore(state => state.removeToast)
  const { userPermissions } = useUser()
  const isManager = userPermissions.topic.includes('manager')

  const {
    keywordSuggestions: allKeywordsSuggestions,
    addTopicKeywordRelation,
    loadSuggestionsForTopic
  } = useClassification()

  const updateKeywordsTopicIds = useKeywordsStore(state => state.updateKeywordsTopicIds)

  const { applyTopicsToKeywords } = useKeywordsRelations()

  const { on, removeListener, dispatch } = useEvents<TopicRelationEvent>()

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen once
  useEffect(() => {
    const updateKeywordsCallback = (event: TopicRelationEvent) => {
      if (event.topicId === props.topicId) {
        props.onLoadKeywords()
      }
    }
    on('update-keywords', updateKeywordsCallback)

    on('add-relation', event => {
      const { topicId, ...keyword } = event
      if (topicId === props.topicId) {
        props.pushKeywordIfExists(keyword)
      }
    })

    on('change-relation', event => {
      const { topicId, ...keyword } = event
      props.changeKeywordRelation(keyword)
    })

    on('delete-relation', event => {
      if (event.topicId === props.topicId) {
        props.removeKeywordState(event.keywordHash)
      }
    })

    return () => {
      removeListener('update-keywords')
      removeListener('delete-relation')
      removeListener('change-relation')
      removeListener('add-relation')
    }
  }, [])

  const keywordsSuggestions = allKeywordsSuggestions[props.topicId] ?? []
  // const isEmptyKeywords = props.keywords.length === 0
  const isEmptySuggestions =
    (!keywordsSuggestions || keywordsSuggestions.length === 0) &&
    !isLoadingSuggestions &&
    isSuggestionsLoadedOnce

  const loadSuggestions = useCallback(async () => {
    setIsSuggestionsLoadedOnce(true)
    setIsLoadingSuggestions(true)
    setIsSuggestionsOpen(true)

    track('explore_user_ask_for_keyword_suggestions')

    await loadSuggestionsForTopic(props.topicId).finally(() => {
      setIsLoadingSuggestions(false)
    })
  }, [props.topicId, loadSuggestionsForTopic, track])

  async function addSuggestion(suggestion: KeywordSuggestion) {
    if (isAddingSuggestion || isLoadingSuggestions) return
    const addingSuggestionId = `${suggestion.keywordHash}#${props.topicId}`
    try {
      setIsAddingSuggestion(true)
      addToast({
        id: addingSuggestionId,
        text: 'Adding keyword suggestion...',
        status: 'loading',
        open: true
      })

      await addTopicKeywordRelation({ topicId: props.topicId, keyword: suggestion })
      updateKeywordsTopicIds({
        ids: suggestion.keywordHash,
        topicId: props.topicId,
        topicName: props.topicName
      })

      track('explore_user_keyword_topic_association')

      refreshThemes()

      addToast({
        id: `add-success-${suggestion.keywordHash}`,
        text: 'Keyword suggestion added successfully',
        status: 'success',
        open: true
      })

      props.onAddSuggestion?.(suggestion)
    } catch (error) {
      const message = `Failed to add suggestion "${suggestion.text}" to subtopic "${props.topicName}"`
      logException(error, { priority: 'low', message })
      addToast({
        id: `add-fail-${suggestion.keywordHash}`,
        text: message,
        status: 'error',
        duration: 2500,
        open: true
      })
    } finally {
      removeToast(addingSuggestionId)
      setIsAddingSuggestion(false)
    }
  }

  function onAddKeywordClick() {
    setIsAddingKeyword(true)
  }

  function onCancelAddKeyword() {
    setIsAddingKeyword(false)
  }

  function onConfirmAddKeyword(value: string) {
    setIsAddingKeyword(false)
    props.onAddKeyword(value)
  }

  function onRemoveKeywordSuggestionClick(suggestion: KeywordSuggestion) {
    removeTopicKeywordSuggestion({ topicId: props.topicId, keywordHash: suggestion.keywordHash })
  }

  const onSuggestionsButtonClick = isSuggestionsOpen
    ? () => setIsSuggestionsOpen(false)
    : loadSuggestions

  let suggestionsButtonText = isSuggestionsOpen
    ? `Hide keyword suggestions (${keywordsSuggestions.length})`
    : 'Show keyword suggestions'

  if (isLoadingSuggestions) {
    suggestionsButtonText = 'Loading keyword suggestions...'
  }

  const onApplyAddToTopic = useCallback<(keyword: KeywordWithTopicIds) => OnApplyCallback>(
    keyword => async (selectedTopics, removedTopics) => {
      const removeToast = addLoadingToast({
        text: 'Applying changes to keywords relations...'
      })

      try {
        await applyTopicsToKeywords({
          keywords: [keyword],
          selectedTopics,
          removedTopics,
          enableDispatch: false
        })

        selectedTopics.forEach(({ topicId }) => {
          const topicIds = [...new Set([...keyword.topicIds, topicId])]
          dispatch('change-relation', {
            topicId,
            ...keyword,
            topicIds
          })
          dispatch('add-relation', {
            topicId,
            ...keyword,
            topicIds
          })
        })

        removedTopics.forEach(topicId => {
          const topicIds = keyword.topicIds.filter(keywordTopicId => keywordTopicId !== topicId)
          dispatch('change-relation', {
            topicId,
            ...keyword,
            topicIds
          })
          dispatch('delete-relation', {
            topicId,
            ...keyword,
            topicIds
          })
        })

        setTimeout(() => {
          refreshThemes()
          removeToast()
          addSuccessToast({
            text: 'All changes applied.'
          })
        }, REFRESH_THEMES_MIN_DELAY)

        return 'success'
      } catch (error) {
        console.error(error)
        const message = 'Failed to apply all changes.'
        logException(error, { priority: 'low', message })
        removeToast()
        addErrorToast({ text: message })
        await refreshThemes()
        return 'error'
      }
    },
    [
      applyTopicsToKeywords,
      refreshThemes,
      addErrorToast,
      addSuccessToast,
      addLoadingToast,
      logException,
      dispatch
    ]
  )

  const options: (keyword: KeywordWithTopicIds) => OptionsMenuItem[] = useMemo(
    () => keyword => [
      {
        text: 'Add to subtopic',
        customSubOption: (
          <KeywordEditorSubmenu
            appliedTopics={keyword.topics}
            onApply={onApplyAddToTopic(keyword)}
            singleKeyword
          />
        )
      }
    ],
    [onApplyAddToTopic]
  )

  if (props.loadingKeywords)
    return (
      <FlexContainer css={{ gap: '$micro', mb: '$xs' }} direction="column">
        <KeywordsSkeleton size={4} />
      </FlexContainer>
    )

  return (
    <>
      <TopicContentScrollArea>
        <TopicContentScrollAreaViewport>
          <FlexContainer css={{ background: 'transparent' }} direction="column">
            {props.keywords?.map(keyword => (
              <TopicKeywordContainer key={keyword.keywordHash}>
                <TopicKeywordChip
                  dismissable={isManager}
                  key={keyword.keywordHash}
                  label={keyword.text}
                  onClick={() => props.onRemoveKeyword(keyword.keywordHash)}
                />
                {isManager ? (
                  <span className="chip-options">
                    <OptionsMenu options={options(keyword)} stopPropagation />
                  </span>
                ) : null}
              </TopicKeywordContainer>
            ))}
          </FlexContainer>
        </TopicContentScrollAreaViewport>
        <ScrollAreaScrollbar orientation="vertical">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </TopicContentScrollArea>
      <Fade />
      {isManager ? (
        <ButtonGroup>
          {isAddingKeyword ? (
            <NameInput
              css={{ width: 320 }}
              onCancel={onCancelAddKeyword}
              onConfirm={onConfirmAddKeyword}
              placeholder="Type a keyword"
            />
          ) : (
            <Button micro onClick={onAddKeywordClick} text>
              <Plus />
              <span>Add Keyword</span>
            </Button>
          )}

          <Button micro onClick={onSuggestionsButtonClick} text>
            <MagicWand />
            <span>{suggestionsButtonText}</span>
          </Button>
        </ButtonGroup>
      ) : null}
      {isSuggestionsOpen &&
        (isLoadingSuggestions ? (
          <FlexContainer css={{ gap: 8, marginBottom: 24 }} direction="column">
            <KeywordsSkeleton size={4} />
          </FlexContainer>
        ) : (
          <>
            {isEmptySuggestions && (
              <Text css={{ padding: 4, marginLeft: 32 }} typeface="uiComponentsSmallRegular">
                No suggestions found
              </Text>
            )}
            <TopicContentScrollArea>
              <TopicContentScrollAreaViewport>
                <FlexContainer css={{ gap: 8, background: 'transparent' }} direction="column">
                  {keywordsSuggestions.map(suggestion => (
                    <KeywordChipContainer key={suggestion.keywordHash}>
                      <Chip
                        dashed
                        deleteIcon={<Plus weight="bold" />}
                        disabled={isAddingSuggestion}
                        label={suggestion.text}
                        onDelete={() => addSuggestion(suggestion)}
                      />
                      <IconButton
                        className="chip-options"
                        onClick={() => onRemoveKeywordSuggestionClick(suggestion)}
                      >
                        <X color={colors.red} />
                      </IconButton>
                    </KeywordChipContainer>
                  ))}
                </FlexContainer>
              </TopicContentScrollAreaViewport>
              <ScrollAreaScrollbar orientation="vertical">
                <ScrollAreaThumb />
              </ScrollAreaScrollbar>
            </TopicContentScrollArea>
            <Fade />
          </>
        ))}
    </>
  )
}

export default TopicContent
