import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import Card from '../card'
import { Users } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'
import SegmentationsTable from './SegmentationsTable'
import FlexContainer from '@/components/atoms/flex-container'
import HiddenMetricsModal from '../home/HiddenMetricsModal'

const AreaRelatedSegments = () => {
  const { t } = useTranslation()

  const currentArea = useCurrentInterestAreaStore(state => state.currentInterestArea)
  if (!currentArea) return null

  return (
    <Card.Root color="brandPrimaryPure" direction="column" gap="xs">
      <Card.Header css={{ justifyContent: 'space-between' }}>
        <FlexContainer alignItems="center" gap="nano">
          <Card.IconBox>
            <Users />
          </Card.IconBox>
          <Card.Title>{t('areaRelatedSegmentsTitle')}</Card.Title>
        </FlexContainer>
        <HiddenMetricsModal borderedAndSmall />
      </Card.Header>
      <SegmentationsTable areaId={currentArea.id} />
    </Card.Root>
  )
}

export default AreaRelatedSegments
