import Bone from '@/components/atoms/bone'

interface ChartSkeletonProps {
  height?: string | number
}

const ChartSkeleton = ({ height = 140 }: ChartSkeletonProps) => (
  <Bone css={{ width: '100%', height }} />
)

export default ChartSkeleton
