import { colors } from '@/theme'
import { chartColors } from '@/theme/colors'
import { IntervalOption, TimeSeriesValue } from '@/types/analytics/Analytics'
import { TimeSeriesChartProps } from '@/types/analytics/Compare'
import { dateShortMonthFormat, intervalFormat } from '@/utils/date'
import { formatPercentage } from '@/utils/numbers'
import { EChartOption } from 'echarts'
import moment from 'moment'

interface ChartOptionsParams {
  timeSeriesProps: TimeSeriesChartProps
  xAxis?: EChartOption.XAxis
  grid?: EChartOption.Grid
  legend?: EChartOption.Legend
  enableDataZoom?: boolean
  series: EChartOption.Series[]
  dataZoomRange?: [number, number]
  disableSortSeries?: boolean
}

export const defaultMetrics = {
  postedAt: '',
  countFeedback: 0,
  countFeedbackWithoutFilter: 0,
  percentageFeedback: 0
}

export const getXAxisData = (timeSeries: TimeSeriesValue[], interval: IntervalOption) => {
  const timeSeriesData = timeSeries[0]?.data
  if (!timeSeriesData) {
    return []
  }

  return timeSeriesData.map(metrics => intervalFormat(moment(metrics.postedAt), interval))
}

const getChartOptions = ({
  timeSeriesProps: { intervalOption, metricOption, timeSeries, volumeBy, compare },
  xAxis,
  series,
  grid,
  legend,
  enableDataZoom = true,
  dataZoomRange = [0, 100],
  disableSortSeries = false
}: ChartOptionsParams): EChartOption => {
  const sortedTimeSeries = disableSortSeries
    ? [...timeSeries]
    : [...timeSeries].sort((a, b) => a.fieldValue.localeCompare(b.fieldValue))

  const xAxisData = getXAxisData(sortedTimeSeries, intervalOption)

  return {
    color: chartColors,

    legend: {
      show: true,
      icon: 'roundRect',
      itemWidth: 12,
      itemHeight: 12,
      textStyle: { fontFamily: 'Lexend' },
      ...legend
    },
    grid: {
      show: false,
      // top: '10%',
      left: 80,
      right: 80,
      // bottom: '5%',
      containLabel: false,
      ...grid
    },
    xAxis: {
      show: true,
      type: 'category',
      splitNumber: 4,
      offset: 0,
      boundaryGap: false,
      axisLabel: {
        color: colors.neutralLowMedium,
        fontFamily: 'Lexend',
        align: 'center', // EChartOption type is not complete
        showMinLabel: true,
        showMaxLabel: true
      },
      data: xAxisData,
      ...xAxis
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: metricOption === 'count' ? '{value}' : '{value}%'
      }
    },
    dataZoom: [
      {
        show: enableDataZoom,
        type: 'slider',
        start: dataZoomRange[0],
        end: dataZoomRange[1],
        fillerColor: colors.brandPrimaryTransparentWeak,
        borderColor: colors.brandPrimaryTransparentWeak,
        backgroundColor: colors.brandPrimaryLight,
        textStyle: {
          fontFamily: 'Lexend'
        },
        dataBackground: {
          lineStyle: {
            color: colors.neutralLowLight
          }
        },
        selectedDataBackground: {
          lineStyle: {
            color: colors.neutralLowLight
          }
        },
        handleStyle: {
          borderColor: colors.brandPrimaryTransparentWeak
        },
        moveHandleStyle: {
          color: colors.brandPrimaryTransparentWeak,
          backgroundColor: colors.brandPrimaryLight,
          borderColor: colors.brandPrimaryTransparentWeak
        },
        emphasis: {
          handleStyle: {
            borderColor: colors.brandPrimaryTransparentModerate
          },
          moveHandleStyle: {
            color: colors.brandPrimaryTransparentModerate,
            backgroundColor: colors.brandPrimaryMedium,
            borderColor: colors.brandPrimaryTransparentModerate
          }
        },
        realtime: true
      } as EChartOption.DataZoom
    ],
    tooltip: {
      show: true,
      trigger: 'axis',
      appendToBody: true,
      // padding: 0,
      borderColor: colors.neutralHighPure,
      formatter: ((params: EChartOption.Tooltip.Format[]) => {
        if (compare) {
          const title = 'All feedback'

          const current = sortedTimeSeries[0]?.data
          const currentItem = current[params[0].dataIndex ?? 0] ?? defaultMetrics
          const previous = sortedTimeSeries[1]?.data
          const previousItem = previous[params[0].dataIndex ?? 0] ?? defaultMetrics

          const currentPostedAt = currentItem.postedAt
            ? dateShortMonthFormat(moment(currentItem.postedAt).toDate())
            : ''
          const previousPostedAt = previousItem.postedAt
            ? dateShortMonthFormat(moment(previousItem.postedAt).toDate())
            : ''

          const currentLine = /* html */ `
            <div>
              <div style="
                font-family: 'Lexend', sans-serif;
                display: flex;
                gap: 4px;
                align-items: center;
                font-size: 12px;
              ">
                <div style="
                  width: 12px; 
                  height: 12px; 
                  border-radius: 2px; 
                  background: ${colors.brandPrimaryPure}
                "></div>
                <span>${currentPostedAt}<span/> 
              </div>
              <div style="margin-left: 12px;">
                ${currentItem.countFeedback} (${formatPercentage(currentItem.percentageFeedback)} %)
              </div>
            </div>
          `

          const previousLine = /* html */ `
            <div>
              <div style="
                font-family: 'Lexend', sans-serif;
                display: flex;
                gap: 4px;
                align-items: center;
                font-size: 12px;
              ">
                <div style="
                  width: 12px; 
                  height: 12px; 
                  border-radius: 2px; 
                  border: 1px dashed ${colors.brandPrimaryMedium};
                "></div>
                <span>${previousPostedAt}<span/> 
              </div>
              <div style="margin-left: 12px;">
                ${previousItem.countFeedback} (${formatPercentage(previousItem.percentageFeedback)} %)
              </div>
            </div>
          `

          return /* html */ `
            <div style="
              font-family: 'Lexend', sans-serif; 
              color: ${colors.neutralLowPure};
              display: flex;
              flex-direction: column;
              gap: 8px;
            ">
              <p>${title}</p>
             ${currentLine}
             ${previousLine}
            </div>
          `
        }

        const name = params[0]?.name ?? ''

        const header = /* html */ `
                <div style="
                  font-family: 'Lexend', sans-serif;
                  display: grid;
                  grid-template-columns: 2fr 0.5fr 1fr;
                  gap: 16px;
                  align-items: center;
                  font-size: 14px;
                  font-weight: 400;
                ">
                  <div></div>
                  <div style="text-align: right; width: 40px;">%</div>
                  <div style="text-align: right; font-weight: 600;">Volume</div>
                </div>
              `
        const sortedParams = [...params].sort(
          (a, b) => ((b?.value as number) ?? 0) - ((a?.value as number) ?? 0)
        )
        const lines = sortedParams.map((item, index) => {
          const metrics =
            sortedTimeSeries[item.seriesIndex ?? index]?.data[item.dataIndex ?? 0] ?? defaultMetrics
          const marker = item.marker
          return /* html */ `
                  <div style="
                    font-family: 'Lexend', sans-serif;
                    display: grid;
                    grid-template-columns: 2fr 0.5fr 1fr;
                    gap: 16px;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 400;
                  ">
                    <div>${marker} ${item.seriesName}</div>
                    <div style="text-align: right; width: 40px;">${formatPercentage(
                      metrics.percentageFeedback
                    )}%</div>
                    <div style="text-align: right; font-weight: 600;">${metrics.countFeedback}</div>
                  </div>
                `
        })

        const htmlLines = lines.join('')

        const item = sortedTimeSeries[0].data[params[0].dataIndex ?? 0]

        const total = item.countFeedbackWithoutFilter
        const postedAt = item.postedAt

        const initialDate = moment(postedAt)
        const interval = intervalOption.at(1) as moment.unitOfTime.DurationConstructor
        const finalDateOfRange = moment(postedAt).add(1, interval).subtract(1, 'day')
        const nowDate = moment(Date.now())
        const finalDate = finalDateOfRange.isSameOrAfter(nowDate) ? nowDate : finalDateOfRange

        const feedbackName = volumeBy === 'ticket' ? 'tickets' : 'feedback'

        const dateContent =
          intervalOption === IntervalOption.Day
            ? name
            : `${initialDate.format('DD/MMM/YY')} to ${finalDate.format('DD/MMM/YY')}`

        const totalHtml = /* html */ `
                <div style="display: flex; justify-content: space-between; margin-bottom: 8px; gap: 24px;">
                  <div><strong>${total} ${feedbackName}</strong></div>
                  <div style="font-size: 12px;">${dateContent}</div>
                </div>
              `

        return /* html */ `
                <div style="
                  width: 100%;
                  font-family: 'Lexend', sans-serif;
                  display: flex;
                  flex-direction: column;
                ">
                  ${totalHtml}
                  ${header}
                  ${htmlLines}
                </div>
              `
      }) as EChartOption.Tooltip.Formatter,

      textStyle: {
        fontFamily: 'Lexend',
        fontWeight: 'normal',
        fontStyle: 'normal'
      }
    },
    series: disableSortSeries
      ? [...series]
      : series.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
  }
}

export default getChartOptions
