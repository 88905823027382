import FlexContainer from '@/components/atoms/flex-container'
import { styled } from '@/theme'
import BirdEmptySettings from '@/assets/drawings/bird_empty_settings.png'
import Text from '@/components/atoms/text'
import { useTranslation } from 'react-i18next'

const Card = styled(FlexContainer, {
  bc: '$neutralHighLight',
  br: 8,
  bAll: '$neutralHighPure',
  height: 443,
  width: '100%',
  gap: '$micro',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
})

const EmptyNotificationsCard = () => {
  const { t } = useTranslation()
  return (
    <Card>
      <img height={251} src={BirdEmptySettings} width={398} />
      <Text color="neutralLowPure" fontSize={'xs'} fontWeight={'bold'}>
        {t('youHaventSetUpAnyNotificationsYet')}
      </Text>
      <Text color="neutralLowPure" fontSize={'xxs'} fontWeight={'regular'}>
        {t('toSetUpANotificationClickOnTheBellButtonInsideAnAreaOrOpportunity')}
      </Text>
    </Card>
  )
}

export default EmptyNotificationsCard
